import SmartLabel from 'fusioncharts-smartlabel';
import {toCanvas} from 'qrcode';
import {promisify} from 'util';
import {tryPreloadingFonts} from 'utils/try-preloading-fonts';

const toCanvasPromisified = promisify(toCanvas);

/**
 * Build a downloadable share image with the guide title and instruction title embedded in the QR code.
 */
const styles = {
  width: 2000,
  height: 1429,
  paddingLeft: 1246,
  paddingTop: 393,
  qrCodeSize: 645,
  textLeft: 113,
  textSubtitleTop: 620,
  textTop: 540,
  textTitleBase: 620,
  fontSize: 58, // In pixels
  lineSeparation: 80,
  fontFamily: 'Lato',
  boldWeight: 600,
  normalWeight: 400,
  lineHeight: 1.2,
  maxTitleWidth: 900
};

export const buildShareImage = async ({title, type, qrCodeUrl}) => {
  const backgroundImage = await new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = error => reject(error);
    image.src = require('img/qr-background/qr-background.png');
  });

  const {
    fontFamily,
    fontSize,
    height,
    lineSeparation,
    paddingLeft,
    paddingTop,
    qrCodeSize,
    textLeft,
    textSubtitleTop,
    boldWeight,
    normalWeight,
    width
  } = styles;

  const canvas = buildCanvas(width, height);
  const canvasCtx = canvas.getContext('2d');
  canvasCtx.drawImage(backgroundImage, 0, 0, width, height);

  const qrCanvas = await toCanvasPromisified(qrCodeUrl, {margin: 0, width: qrCodeSize});
  canvasCtx.drawImage(qrCanvas, paddingLeft, paddingTop, qrCodeSize, qrCodeSize);

  canvasCtx.fillStyle = 'white';

  const titleFont = `${normalWeight} ${fontSize}px ${fontFamily}`;
  const subtitleFont = `${boldWeight} ${fontSize}px ${fontFamily}`;

  await tryPreloadingFonts(titleFont, subtitleFont);

  canvasCtx.font = titleFont;
  drawTitle(canvasCtx, title, styles);

  canvasCtx.font = subtitleFont;
  canvasCtx.fillText(type, textLeft, textSubtitleTop + lineSeparation);

  return canvas;
};

const buildCanvas = (width, height) => {
  const canvas = document.createElement('canvas');
  canvas.setAttribute('width', width);
  canvas.style.width = width;
  canvas.setAttribute('height', height);
  canvas.style.height = height;
  return canvas;
};

const drawTitle = (canvasCtx, text, styles) => {
  const {textLeft, textTitleBase, maxTitleWidth} = styles;
  const sl = new SmartLabel(canvasCtx, true);
  const {fontSize, fontFamily, titleWeight, lineHeight} = styles;
  sl.setStyle({
    lineHeight,
    fontSize: `${fontSize}px`,
    fontFamily,
    fontWeight: titleWeight
  });
  const labelObj = sl.getSmartText(text, maxTitleWidth, fontSize * lineHeight * 2);
  const lines = SmartLabel.textToLines(labelObj).lines.reverse();
  lines.forEach((line, i) => {
    canvasCtx.fillText(line, textLeft, textTitleBase - fontSize * lineHeight * i, maxTitleWidth);
  });
};
