import React from 'react';
import {action, observable, computed, makeObservable} from 'mobx';
import store from 'stores/store';

//helpers
import newUserForm, {createLocalisedForm as createNewUserLocalisedForm} from 'stores/forms/new-user-form';
import TeamRolesModel from 'stores/models/new-user-team-roles';
import notification from 'utils/notification-utils';
import {sanitizeSimpleObject} from 'utils/object-utils';

//lodash
import omit from 'lodash/omit';

//models
import Steps from 'stores/models/steps';
import NewUserCreate from 'components/NewUserCreate';
import NewUserTeamRoles from 'components/NewUserTeamRoles';

class NewUserPage {
  @observable loading = false;
  @observable form = newUserForm;
  @observable isPlatformAdmin = false;
  @observable stepsStore;
  translations = {};
  @observable teams = [];
  @observable isCurrentPickerValid = false;
  @observable teamRoles = [];

  @action
  setTeams = teams => {
    this.teams = teams;
    // if teams exist, start with at least one role picker for the user
    if (this.teams.length > 0 && !this.teamRoles.length) {
      this.teamRoles.push(new TeamRolesModel());
    }
  };

  @action
  addTeamRolePicker = () => {
    this.teamRoles.push(new TeamRolesModel());
    this.isCurrentPickerValid = false;
  };

  @action
  updateCurrentPickerValidity = bool => {
    this.isCurrentPickerValid = bool;
  };

  @action
  removeTeamRolePicker = index => {
    this.teamRoles.splice(index, 1);

    if (!this.teamRoles.length) {
      this.addTeamRolePicker();
    }
  };

  @action
  filterTeams = teamId => {
    const filteredIds = teamId ? this.selectedIds.filter(id => id !== teamId) : this.selectedIds;
    return this.teams.filter(team => !filteredIds.includes(team.id));
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get isAddTeamRoleEnabled() {
    if (!this.teams.length || this.teamRoles.length >= this.teams.length) return false;
    return this.teamRoles.every(teamRole => {
      return teamRole.form.isValid;
    });
  }

  @computed
  get teamRoleSelection() {
    return this.teamRoles.filter(teamRole => teamRole.form.isValid).map(teamRole => teamRole.form.values());
  }

  @computed
  get selectedIds() {
    return this.teamRoles.map(teamRole => {
      return teamRole.form.values().teamId;
    });
  }

  @computed
  get disableNextButton() {
    const values = this.form.values();
    const hasEmailOrPhone = values.email || values.phoneNumber?.length > 4;
    return !hasEmailOrPhone;
  }

  @action
  setSteps = editMode => {
    this.stepsStore = new Steps({
      steps: [
        {
          key: 'createNewUser',
          path: 'create-new-user',
          component: <NewUserCreate />,
          form: this.form,
          checked: false
        },
        {
          key: 'addNewUserToTeam',
          path: 'add-new-user-to-team',
          component: <NewUserTeamRoles />,
          checked: false
        }
      ],
      editMode
    });
  };

  @action
  reset = onReset => {
    this.form.reset();
    this.teamRoles = [];
    this.loading = false;
    onReset && onReset();
  };

  @action setLoading = val => (this.loading = val);

  @computed
  get isSelectionValid() {
    if (this.teamRoles.length === 1) {
      const {form} = this.teamRoles[0];

      //only platform admin should be able to save user without assigning them to a team
      return form.isValid || (store.auth.user.isPlatformAdmin && (form.isValid || form.isEmpty));
    }

    return this.teamRoleSelection.length && this.teamRoles.every(({form}) => form.isValid || form.isEmpty);
  }

  @computed
  get isValid() {
    return this.form.isValid && !!this.isSelectionValid;
  }

  @computed
  get showSaveButton() {
    return this.stepsStore.currentStepIndex === this.stepsStore.steps.length - 1;
  }

  @computed get showCancelButton() {
    return this.stepsStore.currentStepIndex === 0;
  }

  @action
  togglePlatformAdmin = () => {
    this.isPlatformAdmin = !this.isPlatformAdmin;
  };

  @action
  submit = async ({createUserMutation, goToUsersList}) => {
    const values = this.form.values();
    let newUser = omit(values, ['confirmPassword', 'contactMethod']);
    newUser = {...newUser, isPlatformAdmin: this.isPlatformAdmin};
    newUser = sanitizeSimpleObject(newUser, ['fullName']);

    const newUserTeamRoles = (this.teamRoleSelection[0]?.role ? this.teamRoleSelection.slice() : []).map(value => ({
      ...value,
      skillRoleId: value.skillRoleId || null
    }));

    this.setLoading(true);

    try {
      await createUserMutation({
        newUser,
        newUserTeamRoles
      });
      notification.success(this.translations.createSuccess);
      this.reset();
      goToUsersList();
    } catch (e) {
      this.setLoading(false);
      const {graphQLErrors} = e;
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err.extensions.code === 'MAX_EDITORS') {
            return notification.error(this.translations.createFailureMaxEditors);
          }
        }
      }
      notification.error(this.translations.createFailure);
      // Log more verbose error in the console.
      // TODO(karl): send to Sentry instead?
      console.error(e);
    }
  };

  setTranslations(translations) {
    this.translations = translations;
    this.form = createNewUserLocalisedForm(this.translations);
  }
}

export default NewUserPage;
