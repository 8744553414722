import {defineMessages} from 'react-intl';

const messages = defineMessages({
  nameYourGuide: {
    id: 'EditGuide.nameYourGuide',
    defaultMessage: 'Name your guide'
  },
  tags: {
    id: 'EditGuide.tags',
    defaultMessage: 'Tags'
  },
  visibleTo: {
    id: 'EditGuide.visibleTo',
    defaultMessage: 'Visible to'
  },
  saveDraft: {
    id: 'EditGuide.saveDraft',
    defaultMessage: 'Save draft'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  publish: {
    id: 'EditGuide.publish',
    defaultMessage: 'Publish'
  },
  publishUpdates: {
    id: 'EditGuide.publishUpdates',
    defaultMessage: 'Publish updates'
  },
  publishOldVersion: {
    id: 'EditGuide.publishOldVersion',
    defaultMessage: 'Publish old version'
  },
  unpublishGuide: {
    id: 'EditGuide.unpublishGuide',
    defaultMessage: 'Unpublish guide'
  },
  setAsDraft: {
    id: 'EditGuide.setAsDraft',
    defaultMessage: 'Set as draft'
  },
  editImage: {
    id: 'EditGuide.editImage',
    defaultMessage: 'Edit Image'
  },
  viewDraftVersionSuccess: {
    id: 'GuideVersions.viewDraftVersionSuccess',
    defaultMessage: 'You are now viewing the draft version of your guide'
  }
});

export default messages;
