import {defineMessages} from 'react-intl';

const messages = defineMessages({
  translationsSuccess: {
    id: 'SignOffTranslations.success',
    defaultMessage: 'Translations have been saved.'
  },
  translationsFailure: {
    id: 'SignOffTranslations.failure',
    defaultMessage: 'Failed to save the translations.'
  },
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  languageSelect: {
    id: 'EditCampaign.languageSelect',
    defaultMessage: 'Choose language'
  },
  addTranslations: {
    id: 'addTranslations',
    defaultMessage: 'Add translations for the sign-off "{title}"'
  },
  defaultLanguage: {
    id: 'common.forms.defaultLanguage',
    defaultMessage: 'Default Language'
  },
  question: {
    id: 'EditCampaign.question',
    defaultMessage: 'Question'
  },
  translateQuestion: {
    id: 'EditCampaign.translateQuestion',
    defaultMessage: 'Translate question...'
  },
  pleaseSelectLanguage: {
    id: 'EditCampaign.pleaseSelectLanguage',
    defaultMessage: 'Please select language'
  },
  option: {
    id: 'option',
    defaultMessage: 'Option'
  },
  translateOption: {
    id: 'EditCampaign.translateOption',
    defaultMessage: 'Translate option...'
  },
  followUpQuestion: {
    id: 'EditCampaign.followUpQuestion',
    defaultMessage: 'Follow Up Question'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  complete: {
    id: 'complete',
    defaultMessage: 'complete'
  }
});

export default messages;
