import React, {Component} from 'react';
import {inject} from 'mobx-react';

//components
import InstructionDetails from 'components/InstructionDetails';

//components
import LeftMenuWrapper from 'ui-components/Layout/LeftMenuWrapper';
import EditInstructionMenu from 'components/EditInstructionMenu';
import StepList from 'components/StepList';

@inject('store')
class EditInstructionComponent extends Component {
  state = {
    canCollapse: window.innerWidth <= 820,
    leftBarCollapsed: window.innerWidth <= 820
  };

  toggleCollapse = () => this.setState(state => ({leftBarCollapsed: !state.leftBarCollapsed}));

  render() {
    const {store} = this.props;
    const {canCollapse, leftBarCollapsed} = this.state;
    const {editInstructionDetailsPage: page} = store;
    const {loading, currentInstruction, guide} = page;
    const {defaultLocale} = guide;

    if (loading) return null;

    return (
      <LeftMenuWrapper
        canCollapse={canCollapse}
        collapsed={leftBarCollapsed}
        padding={false}
        navBarPadding={false}
        nav={<EditInstructionMenu />}
        navTitle={guide.title}
        onClick={canCollapse && this.toggleCollapse}
      >
        <InstructionDetails locale={defaultLocale} />
        <StepList steps={currentInstruction.steps} locale={currentInstruction.locale} />
      </LeftMenuWrapper>
    );
  }
}

export default EditInstructionComponent;
