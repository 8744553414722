import {observable, action, makeObservable} from 'mobx';

class EditThemeIconDialog {
  @observable opened = false;
  @observable icon = {};
  @observable form = null;

  allowVideos = false;
  allowedFormats = null;
  acceptedTypes = 'image/svg+xml';
  translations = {};

  @action
  open = ({icon = {}, form}) => {
    this.icon = icon;
    this.form = form;
    this.opened = true;
  };

  @action
  reset = () => {
    this.opened = false;
    this.icon = {};
  };

  saveIcon = () => {
    let iconField = null;
    this.form.$('icons').map(item => {
      if (item.$('id').get('value') === this.icon.id) {
        iconField = item;
      }
    });

    if (iconField) {
      iconField.$('svg').set('value', this.icon.svg);
    } else {
      this.form.$('icons').add({id: this.icon.id, svg: this.icon.svg});
    }

    this.reset();
  };

  @action
  setIcon = iconSvg => {
    this.icon = {...this.icon, svg: iconSvg};
  };

  constructor() {
    makeObservable(this);
  }
}

export default EditThemeIconDialog;
