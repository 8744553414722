import React, {useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer, Observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';
import flowRight from 'lodash/flowRight';
import isEmpty from 'lodash/isEmpty';

// queries
import {EnabledUsersLite} from 'api/user/queries';

//mutations
import {CreateTeamUsers} from 'api/team/mutations';

//options
import {userNamesOptions} from 'api/user/query-options';
import {createTeamUsersOptions} from 'api/team/mutation-options';

//components
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import GuideApprovalSelector from 'components/GuideApprovalSelector';

//messages
import messages from './messages';

const NewTeamApproversViewConnector = ({store, data, intl, ...props}) => {
  const {
    auth: {user},
    teamPage,
    router
  } = store;

  const {formatMessage} = intl;

  const {
    userNamesQuery: {loading: userNamesQueryIsLoading, error}
  } = props;

  const {canManageTeams} = user;

  useEffect(() => {
    if (!canManageTeams) {
      router.goTo(views.root, {});
    }
    teamPage.setNewForm();
  }, [canManageTeams]);

  const approvalSelectorMessages = {
    loading: formatMessage(messages.loading),
    requireGuideApproval: formatMessage(messages.activateGuideApproval),
    guideApprovalRequirementExplanation: formatMessage(messages.guideApprovalRequirementExplanationV2),
    setApproverOrder: formatMessage(messages.setApproverOrder),
    guidesApprovedInOrder: formatMessage(messages.guidesApprovedInOrderV2),
    addApprover: formatMessage(messages.addApprover),
    approver: formatMessage(messages.approver),
    select: formatMessage(messages.select)
  };

  const showLoading = userNamesQueryIsLoading || error;

  const onRequireGuideApprovalChange = value => {
    if (value) {
      teamPage.setGuideApproval('PARALLEL');
    } else {
      teamPage.setGuideApproval(null);
    }
  };

  const onSelectApproverOrderChange = value => {
    if (value) {
      teamPage.setGuideApproval('SEQUENTIAL');
    } else {
      if (teamPage.guideApproval !== null) {
        teamPage.setGuideApproval('PARALLEL');
      }
    }
  };

  const onApproverIdsChange = async approverIds => {
    teamPage.setApproverIds(approverIds);
  };

  return (
    <Observer>
      {() =>
        showLoading ? (
          <LoadingMessage hasFailed={error}>
            <FormattedMessage {...messages.loading} />
          </LoadingMessage>
        ) : (
          <GuideApprovalSelector
            disabled={!canManageTeams || isEmpty(teamPage.approverCandidates)}
            users={teamPage.approverCandidates}
            messages={approvalSelectorMessages}
            requireGuideApproval={teamPage.isGuideApprovalEnabled}
            onRequireGuideApprovalChange={onRequireGuideApprovalChange}
            onSelectApproverOrderChange={onSelectApproverOrderChange}
            onApproverIdsChange={onApproverIdsChange}
          />
        )
      }
    </Observer>
  );
};

const decorate = flowRight([
  inject('store'),
  graphql(CreateTeamUsers, createTeamUsersOptions),
  graphql(EnabledUsersLite, userNamesOptions),
  observer,
  injectIntl
]);

const NewTeamApproversView = decorate(NewTeamApproversViewConnector);

export {NewTeamApproversViewConnector};
export default NewTeamApproversView;
