import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {TEAM_MEMBER_ROLE} from 'shared/enums';

//styled-components
import {RolePicker} from './styles';

//messages
import messages from './messages';

@inject('store')
@observer
class RolePickerComponent extends Component {
  render() {
    const {
      allowClear = false,
      className,
      dataCy,
      defaultValue,
      disabled,
      field,
      form,
      intl: {formatMessage},
      label,
      placeholder,
      onChange,
      style,
      roles,
      selectedValue
    } = this.props;

    const platformRoles = [TEAM_MEMBER_ROLE.ADMIN, TEAM_MEMBER_ROLE.EDITOR, TEAM_MEMBER_ROLE.VIEWER].map(role => {
      return {name: role, id: role};
    });

    return (
      <RolePicker
        dataCy={dataCy}
        style={style}
        selectedValue={selectedValue}
        allowClear={allowClear}
        className={className}
        defaultValue={defaultValue}
        disabled={disabled}
        field={field}
        form={form}
        label={label}
        options={roles || platformRoles}
        onChange={onChange}
        placeholder={placeholder || formatMessage(messages.placeholder)}
      />
    );
  }
}

export default injectIntl(RolePickerComponent);
