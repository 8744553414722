import {generateStep} from './step';

export const generateSteps = async (steps, pdfMessages, additionalInfo, guideBaseUrl, intl) => {
  if (steps.length > 0) {
    const stepsContent = [];

    for (let i = 0; i < steps.length; i++) {
      const step = await generateStep(steps[i], i + 1, steps.length, pdfMessages, additionalInfo, guideBaseUrl, intl);

      stepsContent.push(step);
    }

    return stepsContent;
  }
};
