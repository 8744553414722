import {defineMessages} from 'react-intl';

const messages = defineMessages({
  fontFamily: {
    id: 'EditTheme.fontFamily',
    defaultMessage: 'Font Family'
  },
  changeRegularFont: {
    id: 'EditTheme.changeRegularFont',
    defaultMessage: 'Change regular font'
  },
  changeBoldFont: {
    id: 'EditTheme.changeBoldFont',
    defaultMessage: 'Change bold font'
  },
  changeItalicFont: {
    id: 'EditTheme.changeItalicFont',
    defaultMessage: 'Change italic font'
  },
  changeBoldItalicFont: {
    id: 'EditTheme.changeBoldItalicFont',
    defaultMessage: 'Change bold italic font'
  },
  customFontFallback: {
    id: 'EditTheme.customFontFallback',
    defaultMessage: 'Custom font fallback'
  }
});

export default messages;
