export const useTokenState = store => {
  const {
    tokenPage: {tokens, currentToken, addToken, removeToken, fetchTokens, resetCurrentToken}
  } = store;

  return {
    tokens,
    currentToken,
    addToken,
    removeToken,
    fetchTokens,
    resetCurrentToken
  };
};
