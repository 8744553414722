import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

import {CheckUserDeletion, User} from 'api/user/queries';
import {checkUserArchivingOptions, getUserQueryOptions} from 'api/user/query-options';

import OnlyPlatformAdminDialog from 'components/DeleteUserDialog/OnlyPlatformAdminDialog';
import HasPendingApprovalsDialog from 'components/DeleteUserDialog/HasPendingApprovalsDialog';
import OnlyApproverDialog from 'components/DeleteUserDialog/OnlyApproverDialog';
import HasAssignedGuidesDialog from 'components/DeleteUserDialog/HasAssignedGuidesDialog';
import LoadingUserDialog from 'components/DeleteUserDialog/LoadingUserDialog';
import ErrorFetchingUserDialog from 'components/DeleteUserDialog/ErrorFetchingUserDialog';

import {deleteUserConstraints} from 'components/DeleteUserDialog/constants';

import CanArchiveUserDialog from './CanArchiveUserDialog';

const {
  USER_IS_ONLY_PLATFORM_ADMIN,
  USER_HAS_PENDING_APPROVAL,
  USER_IS_ONLY_APPROVER_IN_TEAM,
  USER_HAS_GUIDES_ASSIGNED_TO
} = deleteUserConstraints;

@inject('store')
@graphql(CheckUserDeletion, checkUserArchivingOptions)
@graphql(User, getUserQueryOptions)
@observer
class ArchiveUserDialog extends Component {
  render() {
    const {
      store: {
        archiveUserDialog: {opened, close, userId}
      },
      checkUserArchivingQuery: {
        error: errorCheckUserArchiving,
        loading: loadingCheckUserArchiving,
        checkUserDeletionConstraints: checkUserArchivingConstraints
      },
      getUserQuery: {error: errorGetUser, loading: loadingGetUser, user}
    } = this.props;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const showDialog = params.hideDialog ? false : opened;

    if (loadingCheckUserArchiving || loadingGetUser) {
      return <LoadingUserDialog isOpen={showDialog} />;
    }

    if (errorCheckUserArchiving || errorGetUser) {
      return <ErrorFetchingUserDialog isOpen={showDialog} onCancel={close} />;
    }

    if (!checkUserArchivingConstraints.hasConstraint) {
      return <CanArchiveUserDialog isOpen={showDialog} user={user} />;
    }

    const {kind, payload} = checkUserArchivingConstraints.userDeletionConstraint;
    const teams = payload ? payload.teams : [];
    const guides = payload ? payload.guides : [];

    const mappedDialogs = {
      [USER_IS_ONLY_PLATFORM_ADMIN]: <OnlyPlatformAdminDialog isArchiving isOpen={showDialog} onCancel={close} />,
      [USER_HAS_PENDING_APPROVAL]: (
        <HasPendingApprovalsDialog isArchiving isOpen={showDialog} teams={teams} user={user} onCancel={close} />
      ),
      [USER_IS_ONLY_APPROVER_IN_TEAM]: (
        <OnlyApproverDialog isArchiving isOpen={showDialog} teams={teams} user={user} onCancel={close} />
      ),
      [USER_HAS_GUIDES_ASSIGNED_TO]: (
        <HasAssignedGuidesDialog
          isArchiving
          isOpen={showDialog}
          guides={guides}
          user={user}
          onCancel={close}
          userId={userId}
        />
      )
    };

    return mappedDialogs[kind];
  }
}

export default ArchiveUserDialog;
