import React, {ReactElement} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import Dialog from 'ui-components/Dialog';
import {StyledText} from './styles';

import messages from './messages';
import {useMutation} from '@apollo/client';
import {DeleteSkillTask} from 'api/skills/queries';
import notificationUtils from 'utils/notification-utils';
import {refreshSkillsTasks} from 'api/learning-activities/refresh-queries';

type DeleteLearningActivityDialogProps = {
  intl: any;
  store: any;
};

const DeleteLearningActivityDialog = (props: DeleteLearningActivityDialogProps): ReactElement => {
  const {
    intl: {formatMessage},
    store
  } = props;

  const [deleteLearningActivitiesQuery] = useMutation<{
    deleteSkillTask;
  }>(DeleteSkillTask, {
    onCompleted: ({deleteSkillTask}) => {
      if (!deleteSkillTask.result) {
        return notificationUtils.error(formatMessage(messages.errorDeletingLearningActivity));
      }
      notificationUtils.success(formatMessage(messages.successDeletingLearningActivity));
      store.setIsDeleteDialogOpen(false);
    },
    refetchQueries: [refreshSkillsTasks()]
  });

  return (
    <Dialog
      title={formatMessage(messages.deleteLearningActivity)}
      isOpen={store.isDeleteDialogOpen}
      size={'small'}
      actions={[
        {
          label: formatMessage(messages.delete),
          design: 'text',
          onClick: () => {
            deleteLearningActivitiesQuery({
              variables: {
                id: store.learningActivityToBeDeleted.id
              }
            });
          },
          style: {background: '#C33F38'}
        }
      ]}
      onCancel={() => store.setIsDeleteDialogOpen(false)}
    >
      <StyledText>{formatMessage(messages.confirmDelete, {count: store.skillProfilesCount})}</StyledText>
    </Dialog>
  );
};

export default injectIntl(inject('store')(observer(DeleteLearningActivityDialog)));
