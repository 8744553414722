import React from 'react';
import moment from 'moment';
import get from 'lodash/get';

import {DATE_FORMAT, TIME_FORMAT} from './constants';

import CampaignAnswer from 'components/CampaignAnswer';
import {
  EMPTY_ANSWER_SYMBOL,
  renderWithEmptyFallBack,
  renderCampaignAnswerText,
  renderCampaignFolloUpAnswerText
} from 'utils/campaign-answer-utils';

const getGetterWithFallback = path => value => renderWithEmptyFallBack(get(value, path));

const renderTeams = teams =>
  teams
    .map(({team: {name}} = {team: {}}) => name)
    .filter(Boolean)
    .join('; ');

const columnsConfig = {
  SIGN_OFF_RESULT_ID: {
    id: 'SIGN_OFF_RESULT_ID',
    name: 'Sign-off Result ID',
    valueAccessor: getGetterWithFallback('id')
  },
  TEAM_USER: {
    id: 'TEAM_USER',
    name: 'Team (User)',
    valueAccessor: campaignAnswer => {
      const userTeams = get(campaignAnswer, 'loggedInUser.teams', []);
      return userTeams.length > 0 ? renderTeams(userTeams) : EMPTY_ANSWER_SYMBOL;
    }
  },
  SUBMITTED_NAME: {
    id: 'SUBMITTED_NAME',
    name: 'Submitted name',
    valueAccessor: getGetterWithFallback('user')
  },
  LOGGED_IN_USER: {
    id: 'LOGGED_IN_USER',
    name: 'Logged in user',
    valueAccessor: getGetterWithFallback('loggedInUser.fullName')
  },
  SSO_ID: {
    id: 'SSO_ID',
    name: 'SSO id',
    valueAccessor: getGetterWithFallback('loggedInUser.ssoId')
  },
  WORKSPACE: {
    id: 'WORKSPACE',
    name: 'Workspace (Result)',
    valueAccessor: getGetterWithFallback('domain.name')
  },
  DATE: {
    id: 'DATE',
    name: 'Date',
    valueAccessor: campaignAnswer => {
      const {submittedAt} = campaignAnswer;
      const formatted = submittedAt ? moment(submittedAt).format(DATE_FORMAT) : EMPTY_ANSWER_SYMBOL;
      return formatted;
    }
  },
  TIME: {
    id: 'TIME',
    name: 'Time',
    valueAccessor: campaignAnswer => {
      const {submittedAt} = campaignAnswer;
      const formatted = submittedAt ? moment(submittedAt).format(TIME_FORMAT) : EMPTY_ANSWER_SYMBOL;
      return formatted;
    }
  },
  TEAM_GUIDE: {
    id: 'TEAM_GUIDE',
    name: 'Team (Guide)',
    valueAccessor: getGetterWithFallback('guide.ownedBy.name')
  },
  GUIDE: {
    id: 'GUIDE',
    name: 'Guide',
    valueAccessor: getGetterWithFallback('guide.title')
  },
  GUIDE_ID: {
    id: 'GUIDE_ID',
    name: 'Guide ID',
    valueAccessor: getGetterWithFallback('guide.id')
  },
  GUIDE_VERSION: {
    id: 'GUIDE_VERSION',
    name: 'Guide Version',
    valueAccessor: () => 'TBD'
  },
  GUIDE_VERSION_ID: {
    id: 'GUIDE_VERSION_ID',
    name: 'Guide Version Id',
    valueAccessor: () => 'TBD'
  },
  TOPIC: {
    id: 'TOPIC',
    name: 'Topic',
    valueAccessor: getGetterWithFallback('topic.title')
  },
  TOPIC_ID: {
    id: 'TOPIC_ID',
    name: 'Topic ID',
    valueAccessor: getGetterWithFallback('instruction.topicId')
  },
  INSTRUCTION: {
    id: 'INSTRUCTION',
    name: 'Instruction',
    valueAccessor: getGetterWithFallback('instruction.title')
  },
  INSTRUCTION_ID: {
    id: 'INSTRUCTION_ID',
    name: 'Instruction ID',
    valueAccessor: getGetterWithFallback('instruction.id')
  },
  SIGN_OFF_ID: {
    id: 'SIGN_OFF_ID',
    name: 'Sign-off ID',
    valueAccessor: getGetterWithFallback('campaign.id')
  },
  SIGN_OFF: {
    id: 'SIGN_OFF',
    name: 'Sign-off',
    valueAccessor: getGetterWithFallback('campaign.title')
  },
  SIGN_OFF_QUESTION: {
    id: 'SIGN_OFF_QUESTION',
    name: 'Sign-off question',
    valueAccessor: (campaignAnswer, locale) =>
      getGetterWithFallback(`campaign.question.questionTranslations.${locale}`)(campaignAnswer)
  },
  SIGN_OFF_ANSWER_RAW: {
    id: 'SIGN_OFF_ANSWER_RAW',
    name: 'Sign-off Answer (Raw)',
    valueAccessor: getGetterWithFallback('answer')
  },
  SIGN_OFF_ANSWER: {
    id: 'SIGN_OFF_ANSWER',
    name: 'Sign-off Answer',
    valueAccessor: (campaignAnswer, locale, translations, payload) => {
      const {answer, campaign} = campaignAnswer;
      const {renderer} = payload;
      const isForReact = renderer === 'html';
      return isForReact ? (
        <CampaignAnswer answer={answer} campaign={campaign} locale={locale} translations={translations} />
      ) : (
        renderCampaignAnswerText({
          answer,
          campaign,
          locale,
          translations
        })
      );
    }
  },
  FOLLOW_UP_QUESTION: {
    id: 'FOLLOW_UP_QUESTION',
    name: 'Follow-up question',
    valueAccessor: (campaignAnswer, locale) =>
      campaignAnswer.followUpQuestion
        ? getGetterWithFallback(`followUpQuestion.questionTranslations.${locale}`)(campaignAnswer)
        : EMPTY_ANSWER_SYMBOL
  },
  FOLLOW_UP_ANSWER: {
    id: 'FOLLOW_UP_ANSWER',
    name: 'Follow-up answer',
    valueAccessor: campaignAnswer => {
      const followUpQuestion = get(campaignAnswer, 'followUpQuestion');
      const followUpAnswer = get(campaignAnswer, 'followUpAnswer');
      return renderCampaignFolloUpAnswerText({followUpQuestion, followUpAnswer});
    }
  }
};

export default columnsConfig;
