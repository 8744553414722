import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';
import {dialogStyle, StyledHighlightedInfo} from 'components/DeleteUserDialog/styles';

import {DisableUser} from 'api/user/mutations';
import {archiveUserOptions} from 'api/user/mutation-options';

import messages from 'components/DeleteUserDialog/messages';
import messagesUserList from 'views/UsersList/messages';

@inject('store')
@graphql(DisableUser, archiveUserOptions)
@observer
class CanArchiveUserDialog extends Component {
  componentDidMount = () => {
    const {
      user: {teams},
      intl: {formatMessage},
      store: {
        archiveUserDialog: {setTranslations, setTeamIds}
      }
    } = this.props;

    const teamsIds = teams && teams.map(team => team.team.id);
    const translations = mapValues(pick(messages, ['userArchivedSuccess', 'userArchivedError']), message =>
      formatMessage(message)
    );

    setTeamIds(teamsIds);
    setTranslations(translations);
  };

  render() {
    const {
      user: {fullName},
      isOpen,
      disableUserWithResultMutation,
      store: {
        archiveUserDialog: {archiveUser, close}
      }
    } = this.props;

    const title = <FormattedMessage {...messagesUserList.deactivateUser} />;
    const content = (
      <StyledHighlightedInfo>
        <FormattedMessage
          {...messagesUserList.disableUserConfirm}
          values={{
            fullName: <b>{fullName}</b>
          }}
        />
      </StyledHighlightedInfo>
    );

    const actions = [
      {
        label: <FormattedMessage {...messagesUserList.deactivateUser} />,
        onClick: () => archiveUser(disableUserWithResultMutation),
        disabled: false
      },
      {
        label: <FormattedMessage {...messages.cancel} />,
        onClick: close,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel
        onCancel={close}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {content}
      </Dialog>
    );
  }
}

export default injectIntl(CanArchiveUserDialog);
