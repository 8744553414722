import {defineMessages} from 'react-intl';

const messages = defineMessages({
  version: {
    id: 'EditGuide.version',
    defaultMessage: 'Version {version}'
  },
  draftVersion: {
    id: 'EditGuide.draftVersion',
    defaultMessage: 'Draft version'
  },
  liveVersion: {
    id: 'EditGuide.liveVersion',
    defaultMessage: 'Live version'
  },
  approvalVersion: {
    id: 'EditGuide.approvalVersion',
    defaultMessage: 'Version under approval'
  },
  liveReview: {
    id: 'common.labels.liveReview',
    defaultMessage: 'Live review version'
  }
});

export default messages;
