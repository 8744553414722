import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ThemesView.title',
    defaultMessage: 'Themes'
  },
  themeDescription: {
    id: 'common.description.themeDescription',
    defaultMessage: 'The branding options of a workspace, including color, logo, and font.'
  },
  name: {
    id: 'ThemesView.name',
    defaultMessage: 'Name'
  },
  colors: {
    id: 'ThemesView.colors',
    defaultMessage: 'Colors'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  searchThemes: {
    id: 'ThemesView.searchThemes',
    defaultMessage: 'Search themes'
  },
  noThemes: {
    id: 'ThemesView.noThemes',
    defaultMessage: 'There are no Themes created yet.'
  },
  noFilteredThemes: {
    id: 'ThemesView.noFilteredThemes',
    defaultMessage: 'No Themes found for {searchTerm}'
  }
});

export default messages;
