import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//styled-components
import {Grid, GridItem, GridItemGrowing, SectionLabel, StyledButton} from './styles';

//messages
import messages from './messages';

//components
import SkillProfileRolesDrawer from 'components/SkillProfileRolesDrawer';
import SelectedJobTitles from 'components/SkillProfiles/SelectedJobTitles';

@inject('store')
@observer
class SkillProfileCreateAssign extends Component {
  handleRolesChange(selected) {
    const {
      store: {
        skillProfilePage: {setSelectedJobTitles}
      }
    } = this.props;

    setSelectedJobTitles(selected);
  }

  render() {
    const {
      intl: {formatMessage},
      store: {
        skillProfilePage: {isRolesDrawerOpened, openRolesDrawer, closeRolesDrawer}
      }
    } = this.props;

    return (
      <>
        <Grid>
          <GridItem>
            <SectionLabel>{formatMessage(messages.assignSkillProfileToRole)}</SectionLabel>
          </GridItem>
          <GridItemGrowing>
            <SelectedJobTitles />
            <StyledButton onClick={openRolesDrawer} iconId="add" label={formatMessage(messages.addTeamAndJobTitle)} />
          </GridItemGrowing>
        </Grid>

        <SkillProfileRolesDrawer
          isOpen={isRolesDrawerOpened}
          onClose={closeRolesDrawer}
          onChange={selectedTeamRoles => this.handleRolesChange(selectedTeamRoles)}
        />
      </>
    );
  }
}

export default injectIntl(SkillProfileCreateAssign);
