import styled from 'styled-components';

import Spinner from 'shared/components/Spinner';

import {commonPalette} from 'shared/styles/palette';

export const StyledSpinner = styled(Spinner)`
  margin: auto;
`;

export const Subtitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
`;

export const DialogContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CampaignsWrapper = styled.div`
  color: ${commonPalette.txBlack};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OptionWrapper = styled.div`
  flex: 1;
  cursor: pointer;
`;

export const CampaignTitle = styled.div`
  margin-left: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const RemoveCampaignButton = styled.div`
  color: ${commonPalette.red};
  margin: 45px 0 15px;
  cursor: pointer;
  border-bottom: 1px solid ${commonPalette.red};
`;

export const PreviewWrapper = styled.div`
  text-decoration: underline;
  cursor: pointer;
  padding-left: 10px;
`;

export const ErrorText = styled.div`
  color: ${commonPalette.red};
`;

export const dialogStyle = {
  width: 900
};
