import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {guideCardMetrics} from 'styles/metrics';
import {NoResultsFound} from 'styles/styled-components/shared-components';

export const GuidesList = styled.div`
  ${flex.horizontal};
  ${flex.wrap};
  align-items: stretch;
  justify-content: flex-start;
  margin: 0 0 36px;
`;

export const GuidesListItem = styled.div`
  height: 100%;
  margin: 0 12px;
`;

export const GuidesListItemWrapper = styled.div`
  margin: 12px 0;
  width: 25%;

  @media (max-width: 1020px) {
    width: 33.33%;
  }

  @media (max-width: 830px) {
    width: 50%;
  }

  @media (max-width: 570px) {
    width: 100%;
  }
`;

export const StyledNoResultsFound = styled(NoResultsFound)`
  margin-left: ${guideCardMetrics.gutterY / 2}px;
`;
