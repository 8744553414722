import styled from 'styled-components';

//helpers
import {headerSize} from 'styles/vars';
import {baseLevelWidth, collapsedLevelWidth} from 'styles/sidebar';

export const StickySidebarWrapper = styled.div`
  ${p => p.wrapperStyle};
  width: ${p => (p.isOpen ? baseLevelWidth : collapsedLevelWidth)}px;
`;

export const StickySidebar = styled.div`
  bottom: 0;
  position: fixed;
  top: ${headerSize}px;
  width: inherit;
`;
