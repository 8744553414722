import React from 'react';
import {action, observable, makeObservable} from 'mobx';
import Steps from 'stores/models/steps';
import GapOverview from 'views/SmartSkillsTeams/GapOverview';
import LevelOverview from 'views/SmartSkillsTeams/LevelOverview';

class SmartSkillsTeamsPage {
  @observable stepStore;
  @observable translations = {};

  constructor() {
    makeObservable(this);
    this.setSteps(true);
  }

  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  setSteps = editMode => {
    const basicStepsConfig = [
      {
        key: 'gapOverview',
        path: 'gap',
        component: <GapOverview />
      },
      {
        key: 'levelsOverview',
        path: 'levels',
        component: <LevelOverview />,
        checked: false
      }
    ];

    this.stepsStore = new Steps({
      steps: basicStepsConfig,
      editMode
    });
  };
}

export default SmartSkillsTeamsPage;
