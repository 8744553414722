import styled from 'styled-components';
import $Button from '../../ui-components/Button';
import $Icon from '../../ui-components/Icon';
import {commonPalette} from 'shared/styles/palette';
import {noSelect, ellipsis} from 'shared/utils/sc-utils';

export const SelectWrapper = styled.div`
  display: ${({display}) => (display ? display : 'flex')};
  flex-direction: ${p => (p.underlined ? 'row' : 'column')};
  align-items: ${p => (p.underlined ? 'center' : 'flex-start')};
  position: relative; // to preserve popover position while scrolling
  width: 100%;
`;

export const Button = styled($Button)``;

export const Icon = styled($Icon)``;

export const Select = styled.div`
  width: ${({width}) => (width ? `${width}px` : '100%')};
  height: 40px;
  border: 1px solid ${commonPalette.borderGrey};
  padding: 22px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.2s easy-in-out;
  cursor: pointer;
  background-color: #fff;
  overflow: hidden;

  ${p =>
    p.disabled &&
    `
    background-color: ${commonPalette.lightGrey};
    color: ${commonPalette.txLightGrey};
    cursor: default;
  `}

  ${p =>
    p.showSearch &&
    `
    cursor: text;
  `}

  ${() => Button} {
    > svg {
      margin: 0 0 0 10px;
      transform: rotate(0deg);
      transition: transform 0.2s linear;

      ${p =>
        p.open &&
        `
        transform: rotate(180deg);
        transition: transform 0.2s linear;
      `}
    }
  }
`;

export const OptionsWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
`;

export const Option = styled.div`
  ${noSelect}
  ${ellipsis}
  height: 40px;
  font-size: 15px;
  padding: 0 2px;
  text-align: left;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 100ms linear;
  color: ${p => (p.selected && !p.popover ? commonPalette.sgGreen : commonPalette.txBlack)};
  font-weight: ${p => (p.selected || !p.popover ? 400 : 300)};

  ${p =>
    p.disabled &&
    `
    color: ${commonPalette.txLightGrey};
    cursor: default;
  `}

  &:hover {
    background-color: ${commonPalette.lightGrey};
  }

  .icon {
    margin: 0 0 0 auto;
    ${p => !p.popover && `color: ${commonPalette.sgGreen};`}
  }

  ${p =>
    p.popover &&
    `

    &:after {
      content:"";
      position: absolute;
      bottom: 0;
      left: 16px;
      right: 16px;
      border-bottom: 1px solid #e0e0e0;
    }

    &:last-child:after {
      border-bottom: none;
    }
  `}

  ${() => Icon} {
    margin-right: 8px;
  }
`;

export const CardLevelNum = styled.div`
  align-items: center;
  background: #496270;
  border-radius: 3px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  height: 20px;
  justify-content: center;
  width: 20px;
  margin-right: 12px;
`;

export const CardLevel = styled.div`
  align-items: center;
  background: #fff;
  display: flex;
  flex-direction: row;
  font-size: 15px;
  padding: 2px 12px;
  &:hover {
    background-color: ${commonPalette.lightGrey};
  }
`;

export const StatusDiv = styled.div`
  background: #d2f0ff;
  border-radius: 4px;
  color: #426372;
  padding: 3px;
  margin-left: 12px;
`;

export const InputField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 15px;
`;

export const InputText = styled.div`
  ${({hasSelectedOption}) => (!hasSelectedOption ? 'opacity: 0.5;' : '')};
`;
