import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

export const InfoText = styled.p`
  margin-bottom: 30px;
  color: ${commonPalette.txGrey};
`;

export const WrapperDiv = styled.div`
  margin: 15px 0 0 20px;
`;
