import styled, {css} from 'styled-components';

import $Button from 'ui-components/Button';
import $Icon from 'ui-components/Layout/Icon';

import {commonPalette, activityLogColors} from 'shared/styles/palette';
import {transparentize} from 'shared/utils/color-utils';

export const GuideActivityEvent = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  ${({eventType}) => {
    if (eventType) {
      const colorMap = {
        guide_published: activityLogColors.green,
        guide_approval_approved: activityLogColors.lightGreen,
        guide_approval_approved_and_published: activityLogColors.green
      };

      return `background-color: ${colorMap[eventType] ? colorMap[eventType] : 'none'};`;
    }
  }}
  ${({showDivider}) =>
    showDivider ? `border-bottom: 1px solid ${transparentize(commonPalette.txLightGrey, 0.2)};` : ''}
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventWrapper = styled.div`
  flex-grow: 1;
`;

export const Message = styled.div`
  background: ${commonPalette.bannerDefault};
  // border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: ${commonPalette.iconDefault};
  padding: 3px 16px 5px;
  margin-top: 10px;
  width: fit-content;
`;

export const TimeLabel = styled.div`
  display: flex;
  align-items: center;

  ${() => Label} {
    color: ${commonPalette.txBlack};
    margin: 0 20px 0 0;
  }

  > svg {
    color: ${commonPalette.iconDefault};
    margin-right: 10px;
  }
`;

export const Label = styled.div`
  color: ${commonPalette.txGrey};
  margin-top: 10px;
`;

export const PersonWrapper = styled.span`
  color: ${commonPalette.txBlack};
`;

const linkStyles = css`
  border-radius: 4px;
  display: inline-block;
  color: ${commonPalette.sgGreen};
  text-decoration: underline;
  cursor: pointer;
  line-height: 20px;
  padding: 12px 8px;
  &:hover {
    background: ${commonPalette.interactionHover};
    color: ${commonPalette.sgGreen};
    text-decoration: underline;
  }
  &:active {
    background: ${commonPalette.interactionPressed};
    text-decoration: underline;
  }
  &[disabled] {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
    text-decoration: underline;
  }
`;

export const AsideButton = styled($Button)`
  ${linkStyles}
`;

export const ChevronIcon = styled($Icon).attrs(() => ({
  name: 'chevron-right',
  size: 20
}))`
  color: ${({hidden}) => (hidden ? 'transparent' : commonPalette.txGrey)};
  margin-bottom: 3px;
  margin-right: 15px;
  margin-top: 15px;
  transform: rotate(${props => (props.open ? '90deg' : '0deg')});
  transition: transform 0.3s ease-out;
  cursor: ${({hidden}) => (hidden ? 'default' : 'pointer')};
`;

export const StyledLink = styled.a`
  ${linkStyles}
`;

export const EventHeader = styled.div`
  display: flex;
  flow-direction: row;
  justify-content: space-between;
`;

export const LinkSection = styled.div``;
