import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {DragSource, DropTarget} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {inject} from 'mobx-react';

//components
import InstructionCard from '../InstructionCard';

//mutations
import {RepositionTopicsAndInstructions} from 'api/topic/mutations';

//options
import {repositionTopicsAndInstructions} from 'api/topic/mutation-options';

//drag-and-drop
import {DND_TYPE, source, target} from './dnd';

@inject('store')
@graphql(RepositionTopicsAndInstructions, repositionTopicsAndInstructions)
@DragSource(DND_TYPE, source, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
@DropTarget(DND_TYPE, target, connect => ({
  connectDropTarget: connect.dropTarget()
}))
export default class DraggableInstructionCardComponent extends Component {
  componentDidMount() {
    const {connectDragPreview} = this.props;

    connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
  }

  render() {
    const {connectDragSource, connectDropTarget, isDragging} = this.props;

    return (
      <InstructionCard
        {...this.props}
        dragHandleRef={connectDragSource}
        hostRef={connectDropTarget}
        isGhostly={isDragging}
      />
    );
  }
}
