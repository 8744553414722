import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import $Select from 'ui-components/Select';

export const ItemPrimary = styled.div`
  font-size: 16px;
  color: ${commonPalette.sgGreen};
  margin-top: 20px;
`;

export const ItemSecondary = styled.div`
  font-size: 16px;
`;

export const Select = styled($Select)`
  margin-top: 5px;
  color: ${commonPalette.txBlack};
`;
