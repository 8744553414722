import styled, {css} from 'styled-components';

//helpers
import flex from 'styles/flex';
import {dropShadow, baseLevelWidth} from 'styles/menu';
import {scrollbars} from 'shared/utils/sc-utils';
import {headerSize} from 'styles/vars';
import {commonPalette} from 'shared/styles/palette';

//components
import $Link from 'shared/components/Link';
import Icon from 'ui-components/Layout/Icon';

const verticalFlexWithPadding = css`
  ${flex.vertical};
  flex-shrink: 0;
`;

export const MenuWrapper = styled.div`
  ${dropShadow};
  ${flex.vertical};
  z-index: 3;

  ${p =>
    p.isOpen &&
    `
    ${scrollbars};
  `}
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  width: ${baseLevelWidth}px;
  ${p =>
    !p.isOpen &&
    `
  left: -${baseLevelWidth}px;
  visibility: hidden;
  `}
  transition: all 0.5s ease-in-out;
`;

export const MenuHeader = styled.div`
  height: ${headerSize}px;
  display: flex;
  align-items: center;
`;

export const MenuIcon = styled(Icon)`
  margin-left: 30px;
  cursor: pointer;
`;

export const PlatformLanguageIcon = styled(Icon)`
  margin-right: 8px;
  margin-bottom: 2px;
`;

export const StyledLogoLink = styled($Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 8rem;
  user-select: none;
  height: 25px;
  &:hover {
    opacity: 0.7;
  }
  margin-left: 20px;
`;

export const MenuItems = styled.div`
  ${verticalFlexWithPadding}
  padding: 15px 28px;
`;

export const Logo = styled.img`
  height: 25px;
`;

export const StyledHelpCenterLink = styled.div``;

export const StyledPlatformLanguageButton = styled.button`
  display: flex;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  &:hover {
    color: ${commonPalette.btnGreen};
  }
  border: none;
  background-color: white;
  font-family: Lato;
  padding-bottom: 5px;
  margin-top: 20px;
`;

export const StyledVersionLink = styled.a`
  font-size: 12px;
  line-height: 16px;
  color: ${commonPalette.lighterGray};
  text-decoration-line: underline;
  width: 100%;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${commonPalette.btnGreen};
  }
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 10px;
`;

export const MenuWrapperBottom = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  flex-direction: column;
  padding: 15px 28px;
`;

export const MenuWrapperTop = styled.div`
  position: relative;
`;

export const SkillsDiv = styled.div`
  margin: 0 -16px;
  background: white;
  border-radius: 3px;
  border: 1px rgb(41 135 132 / 0.25) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  display: inline-flex;
`;

export const SmartSkillsIcon = styled(Icon)`
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 2px;
  color: ${commonPalette.btnGreen};
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;
`;

export const TextDiv = styled.div`
  color: ${commonPalette.txBlack};
  font-size: 12px;
  font-family: Lato;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
`;

export const StyledLink = styled.a`
  margin: 12px;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:active {
    color: inherit;
  }
  &:focus {
    text-decoration: none;
  }
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstDiv = styled.div`
  color: ${commonPalette.txBlack};
  font-size: 14px;
  font-family: Lato;
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
`;

export const SecondDiv = styled.div`
  color: ${commonPalette.txBlack};
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  line-height: 20px;
  word-wrap: break-word;
`;

export const ExternalLinkIcon = styled(Icon)`
  margin-bottom: 2px;
  color: ${commonPalette.txGrey};
`;

export const IconButton = styled.button`
  width: 35px;
  height: 35px;
  border: none;
  background-color: ${commonPalette.smartSkillsGreen};
  border-radius: 50%;
  cursor: pointer;
`;

export const Spacer = styled.div`
  flex-grow: 1;
`;
