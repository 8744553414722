import image2base64 from 'image-to-base64/browser';

export const generateImage = async (url, metadata) => {
  let jpegUrl = url.replace('http://', 'https://');

  if (jpegUrl.endsWith('.png')) {
    jpegUrl = jpegUrl.replace('.png', '.jpeg');
  } else if (jpegUrl.endsWith('.bmp')) {
    jpegUrl = jpegUrl.replace('.bmp', '.jpeg');
  } else if (jpegUrl.endsWith('.svg')) {
    jpegUrl = jpegUrl.replace('.svg', '.jpeg');
  }

  const doesMediaMetadataExists = metadata && ['contain', 'crop', 'focus', 'rotation'].some(field => field in metadata);

  if (doesMediaMetadataExists) {
    const {crop, focus, rotation, contain} = metadata;

    jpegUrl = getCrop43url(jpegUrl, validateCrop(crop), focus, rotation, contain);
  } else {
    jpegUrl = jpegUrl.replace('/upload/', '/upload/a_exif/ar_1.33,c_crop,w_3000,h_3000/');
  }

  const encodedContent = await image2base64(jpegUrl);

  return `data:image/jpeg;base64,${encodedContent}`;
};

export const validateCrop = crop => {
  if (
    crop &&
    typeof crop.w === 'number' &&
    typeof crop.h === 'number' &&
    typeof crop.x === 'number' &&
    typeof crop.y === 'number'
  ) {
    return crop;
  }
};

export const getCrop43url = (mediaUrl, crop_p, focus_p = {kx: 0.5, ky: 0.5}, rotation, contain = false) => {
  const shouldSwapAspectRatio = rotation === 90 || rotation === 270;

  const cropContain = shouldSwapAspectRatio ? 'ar_3:4,c_pad,w_3000,h_3000' : 'ar_4:3,c_pad,w_3000,h_3000';
  const cropCover = shouldSwapAspectRatio ? 'ar_3:4,c_crop,w_3000,h_3000' : 'ar_4:3,c_crop,w_3000,h_3000';

  const suffix = `a_exif/c_fit,w_1000,h_1000/f_auto,q_auto:best/a_${rotation === undefined ? 'exif' : rotation}`;

  const url = mediaUrl.replace('http://', 'https://');

  if (contain) {
    if (crop_p) {
      const baseCrop = `x_${crop_p.x},y_${crop_p.y},w_${crop_p.w},h_${crop_p.h},c_crop`;
      return url.replace('upload/', `upload/${baseCrop}/${cropContain}/${suffix}/`);
    }

    return url.replace('/upload/', `/upload/${cropContain}/${suffix}/`);
  }

  if (!crop_p) {
    return url.replace('/upload/', `/upload/${cropCover}/${suffix}/`);
  }

  const crop = {
    _w: crop_p.w,
    _h: crop_p.h,
    _x: crop_p.x,
    _y: crop_p.y
  };

  const focus = [focus_p.kx, focus_p.ky];

  const checkRatioW = crop._w / 4;
  const checkRatioH = crop._h / 3;

  let containerWidth, containerHeight;

  if (checkRatioW < checkRatioH) {
    containerWidth = crop._w;
    containerHeight = (crop._w / 4) * 3;
  } else {
    containerWidth = (crop._h / 3) * 4;
    containerHeight = crop._h;
  }

  const ratioW = crop._w / containerWidth;
  const ratioH = crop._h / containerHeight;
  const ratio = Math.min(ratioW, ratioH);

  const destHeight = crop._h / ratio;
  const destWidth = crop._w / ratio;

  const maxY = containerHeight - destHeight;
  const maxX = containerWidth - destWidth;

  const absDestX = containerWidth / 2 - destWidth * focus[0];
  const absDestY = containerHeight / 2 - destHeight * focus[1];

  let destX;
  if (absDestX < maxX) {
    destX = maxX;
  } else if (absDestX < 0) {
    destX = absDestX;
  } else {
    destX = 0;
  }
  let destY;
  if (absDestY < maxY) {
    destY = maxY;
  } else if (absDestY < 0) {
    destY = absDestY;
  } else {
    destY = 0;
  }

  const crop43 = {
    _x: Math.round(crop._x + Math.abs(destX)),
    _y: Math.round(crop._y + Math.abs(destY)),
    _w: Math.round(containerWidth),
    _h: Math.round(containerHeight)
  };

  const mediaUrl43 = url.replace(
    'upload/',
    `upload/x_${crop43._x},y_${crop43._y},w_${crop43._w},h_${crop43._h},c_crop/${suffix}/`
  );

  return mediaUrl43;
};
