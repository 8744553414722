import {defineMessages} from 'react-intl';

const messages = defineMessages({
  translateGuide: {
    id: 'translateGuide',
    defaultMessage: 'Choose translation method'
  },
  autoTranslate: {
    id: 'autoTranslate',
    defaultMessage: 'Auto-translate'
  },
  translateManually: {
    id: 'translateManually',
    defaultMessage: 'Translate manually'
  }
});

export default messages;
