import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  title: {
    id: 'Users.title',
    defaultMessage: 'Users'
  },
  fullName: {
    id: 'common.forms.fullName',
    defaultMessage: 'Full name'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  contactMethod: {
    id: 'common.forms.contactMethod',
    defaultMessage: 'Contact Method'
  },
  role: {
    id: 'common.forms.role',
    defaultMessage: 'Role'
  },
  organization: {
    id: 'EditUser.organization',
    defaultMessage: 'Organization'
  },
  viewDetails: {
    id: 'common.actions.viewDetails',
    defaultMessage: 'View Details'
  },
  platformAdmin: {
    id: 'Users.platformAdmin',
    defaultMessage: 'Platform Admin'
  },
  platformAdminDescription: {
    id: 'common.description.platformAdmin',
    defaultMessage: 'This user has authority to manage the entire Platform.'
  },
  impersonate: {
    id: 'Users.impersonate',
    defaultMessage: 'Impersonate'
  },
  showUserRoleViewer: {
    id: 'Users.showUserRoleViewer',
    defaultMessage: 'Show user role type: Viewer'
  },
  disableUser: {
    id: 'Users.disableUser',
    defaultMessage: 'Disable User'
  },
  disableUserConfirm: {
    id: 'Users.disableUserAreYouSure',
    defaultMessage:
      'Are you sure you want to deactivate the user {fullName}? They will lose access to the platform. You can reactivate them at any time.'
  },
  disableUserConfirmSso: {
    id: 'Users.disableUserConfirmSso',
    defaultMessage: 'The user will no longer be able to access this platform.'
  },
  disableUserSuccess: {
    id: 'Users.disableUserSuccess',
    defaultMessage: 'User has been disabled.'
  },
  disableUserFailure: {
    id: 'Users.disableUserFailure',
    defaultMessage: 'Failed to disable user.'
  },
  searchWords: {
    id: 'Users.searchWords',
    defaultMessage: 'Search for name, email or SSO id'
  },
  noUsers: {
    id: 'Users.noUsers',
    defaultMessage: 'There are no Users created yet.'
  },
  noFilteredUsers: {
    id: 'Users.noFilteredUsers',
    defaultMessage: 'No Users found for {searchTerm}'
  },
  status: {
    id: 'Users.status',
    defaultMessage: 'Status'
  },
  deactivateUser: {
    id: 'deactivateUser',
    defaultMessage: 'Deactivate user'
  },
  reactivateUser: {
    id: 'reactivateUser',
    defaultMessage: 'Reactivate user'
  },
  deleteUser: {
    id: 'deleteUser',
    defaultMessage: 'Delete user'
  },
  cannotDeactivateOnlyApproverTitle: {
    id: 'Users.cannotDeactivateOnlyApproverTitle',
    defaultMessage: 'Cannot deactivate user'
  },
  actions: {
    id: 'Users.actions',
    defaultMessage: 'Actions'
  },
  invited: {
    id: 'Users.invited',
    defaultMessage: 'Invited'
  },
  invitedDescription: {
    id: 'Users.invitedDescription',
    defaultMessage: "Invite sent but user hasn't  activated account yet"
  },
  deactivated: {
    id: 'Users.deactivated',
    defaultMessage: 'Deactivated'
  },
  deactivatedDescription: {
    id: 'Users.deactivatedDescription',
    defaultMessage: 'This user has been deactivated'
  },
  onlyApproverInTeam: {
    id: 'Users.onlyApproverInTeam',
    defaultMessage:
      '{fullName} is the only approver in one or more teams. Assign at least one other approver or turn off guide approval and try again.'
  }
});

export default messages;
