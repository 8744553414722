import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import get from 'lodash/get';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';

//messages
import messages from './messages';

//subscription
import {APP_SUBSCRIPTION} from 'api/app/subscriptions';

@inject('store')
@graphql(APP_SUBSCRIPTION)
@observer
class VersionMismatchDialogComponent extends Component {
  resetting = false;
  componentDidUpdate() {
    const {versionMismatchDialog: dialog} = this.props.store;
    const clientVersion = process.env.VERSION;
    const serverVersion = get(this.props.data, 'app.version');

    if (this.props.data.loading || this.resetting || serverVersion === clientVersion) {
      this.resetting = false;
      return;
    }
    dialog.open();
  }

  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {versionMismatchDialog: dialog} = store;

    return (
      <Dialog
        actions={() => [
          {
            label: formatMessage(messages.refresh),
            onClick: () => {
              location.reload(true);
            }
          },
          {
            label: formatMessage(messages.continue),
            design: 'text',
            onClick: () => {
              // Close dialog and show it again after 5 minutes
              this.resetting = true;
              dialog.reset();
              setTimeout(dialog.open, 5 * 60 * 1000);
            }
          }
        ]}
        isOpen={dialog.opened}
        title={formatMessage(messages.title)}
      >
        <FormattedMessage {...messages.info} />
      </Dialog>
    );
  }
}

export default injectIntl(VersionMismatchDialogComponent);
