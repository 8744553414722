import React, {Component} from 'react';
import axios from 'axios';
import {observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//lodash
import uniqueId from 'lodash/uniqueId';

//helpers
import {CLOUDINARY_API_KEY, CLOUDINARY_URL} from 'shared/constants';
import {readFileAsDataURL} from 'shared/utils/file-utils';

//queries
import {Platform} from 'api/platform/queries';
import {SignMedia} from 'api/media/mutations';
import {signMediaOptions} from 'api/media/mutation-options';

//components
import Icon from 'ui-components/Layout/Icon';
import Spinner from 'shared/components/Spinner';

//styled-components
import {Actions, ChangeButton, Preview, RemoveButton, UploadFont, UploadFontActiveStyle} from './styles';

@graphql(Platform)
@graphql(SignMedia, signMediaOptions)
@observer
class UploadFontComponent extends Component {
  @observable loading = false;

  dropzone = null;
  fontFamilyStub = uniqueId('UploadFont');

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {caption, className} = this.props;

    const showPreview = !this.loading && this.field.value;

    return (
      <UploadFont
        _ref={ref => (this.dropzone = ref)}
        accept="application/font-woff"
        activeStyle={UploadFontActiveStyle}
        className={className}
        disableClick={true}
        multiple={false}
        onDrop={this.handleDropzoneDrop}
      >
        {this.loading && <Spinner color="#fff" />}
        {showPreview && <style>{this.previewCss}</style>}
        {showPreview && <Preview fontFamilyStub={this.fontFamilyStub} />}
        {!this.loading && (
          <Actions>
            <ChangeButton onClick={this.handleChangeClick}>{caption}</ChangeButton>
            {this.field.value && (
              <RemoveButton onClick={this.handleRemoveClick}>
                <Icon name="trashbin" />
              </RemoveButton>
            )}
          </Actions>
        )}
      </UploadFont>
    );
  }

  get field() {
    const {form, field} = this.props;
    return form.$(field);
  }

  get folder() {
    const {
      data: {platformForCms}
    } = this.props;
    return platformForCms.name;
  }

  get previewCss() {
    return `
      @font-face {
        font-family: ${this.fontFamilyStub};
        src: url(${this.field.value}) format('woff');
      }
    `;
  }

  get timestamp() {
    return Math.round(Date.now() / 1000);
  }

  handleChangeClick = () => {
    if (this.dropzone) {
      this.dropzone.open();
    }
  };

  handleDropzoneDrop = ([file]) => {
    if (!file) {
      return;
    }

    this.upload(file);
  };

  handleRemoveClick = () => {
    this.field.clear();
  };

  upload = async file => {
    const {signMedia} = this.props;

    this.loading = true;

    const params = {
      folder: this.folder,
      invalidate: false,
      overwrite: false,
      public_id: file.name.slice(0, file.name.lastIndexOf('.')),
      timestamp: this.timestamp
    };

    const {data} = await signMedia(params);
    const dataUrl = await readFileAsDataURL(file).promise;

    this.uploadRaw(dataUrl, data)
      .then(({data}) => {
        this.field.sync(data.secure_url);
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  };

  uploadRaw = (dataUrl, {sign}) => {
    const params = {
      api_key: CLOUDINARY_API_KEY,
      file: dataUrl,
      folder: this.folder,
      resource_type: 'raw',
      timestamp: sign && sign.timestamp,
      upload_preset: 'unsigned'
    };

    return axios.post(CLOUDINARY_URL, params);
  };
}

export default UploadFontComponent;
