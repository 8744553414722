import {action, observable, makeObservable} from 'mobx';

class AddNewLanguageDialog {
  @observable opened = false;
  @observable selectedLanguage = undefined;

  @action
  reset = () => {
    this.opened = false;
    this.setSelectedLanguage(undefined);
  };

  @action
  open = () => (this.opened = true);

  @action setSelectedLanguage = language => {
    this.selectedLanguage = language;
  };

  constructor() {
    makeObservable(this);
  }
}

export default AddNewLanguageDialog;
