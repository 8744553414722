import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Dialog from 'ui-components/Dialog';

/**
 * simple dialog to be used with only icon, text and close button
 */

@inject('store')
@injectIntl
@observer
class WarningDialog extends Component {
  render() {
    const {
      store: {
        warningDialog: {opened, cancel, content}
      }
    } = this.props;

    return (
      <Dialog isOpen={opened} actions={[]} onCancel={cancel} title={content.title} titleIconId="warning">
        {content.message}
      </Dialog>
    );
  }
}

export default WarningDialog;
