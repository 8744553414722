import React from 'react';
import {
  Container,
  BackButton,
  BackIcon,
  Header,
  HeaderSubContent,
  HeaderSubContentItem,
  HeaderSubContentItemLabel,
  HeaderSubContentItemValue,
  RightContent,
  FlexRow,
  RoleDiv
} from './styles';
import {inject} from 'mobx-react';
import views from 'config/views';
import type Store from 'stores/store';

export type SmartSkillsPagerHeaderProps = {
  store?: typeof Store;
  title: string;
  subtitle?: string;
  showBackButton: boolean;
  rightContent?: JSX.Element;
  bottomLine?: {label: string; value: string}[];
};

function SmartSkillsPageHeader({
  store,
  title,
  subtitle,
  showBackButton,
  rightContent,
  bottomLine
}: SmartSkillsPagerHeaderProps) {
  const onClickBack = () => {
    const {
      router,
      router: {params}
    } = store as typeof Store;

    if (router.currentRoute.id === views.smartSkillsTeam.id) {
      router.goTo(views.smartSkills, {tab: 'gap'});
    } else if (router.currentRoute.id === views.smartSkillsUser.id) {
      router.goTo(views.smartSkillsTeam, {teamId: params.teamId});
    } else {
      router.goTo(views.guides, {});
    }
  };

  return (
    <Container>
      {showBackButton && (
        <BackButton onClick={onClickBack}>
          <BackIcon />
        </BackButton>
      )}

      <div>
        <Header>{title}</Header>
        {subtitle && (
          <FlexRow>
            <RoleDiv>Role</RoleDiv>
            <div>{subtitle}</div>
          </FlexRow>
        )}

        {bottomLine && (
          <HeaderSubContent>
            {bottomLine.map(({label, value}) => (
              <HeaderSubContentItem key={label}>
                <HeaderSubContentItemLabel>{label}</HeaderSubContentItemLabel>
                <HeaderSubContentItemValue>{value}</HeaderSubContentItemValue>
              </HeaderSubContentItem>
            ))}
          </HeaderSubContent>
        )}
      </div>

      <RightContent>{rightContent}</RightContent>
    </Container>
  );
}

export default inject('store')(SmartSkillsPageHeader);
