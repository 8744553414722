import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {action, observable, makeObservable, toJS} from 'mobx';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import Icon from 'ui-components/Layout/Icon';
import {FormattedMessage} from 'components/FormattedComponents';
import ActionMenu from 'components/ActionMenu';

import {INFO_CENTER_URL} from 'config/constants';
import views from 'config/views';

import {
  Header,
  Logo,
  StyledLogoLink,
  Popover,
  Wrapper,
  UserName,
  MenuItem,
  Menu,
  HeaderRight,
  HeaderLeft,
  MenuButton,
  StopImpersonateButton,
  MenuIcon,
  StyledLink
} from './styles';
import messages from './messages';

@inject('store')
@observer
class HeaderComponent extends Component {
  @observable languagePopoverVisible;
  @observable userPopoverVisible;
  @observable privacyPolicyPopoverVisible;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  handleLanguageVisibleChange = visible => {
    this.languagePopoverVisible = visible;
  };

  @action
  handleUserVisibleChange = visible => {
    this.userPopoverVisible = visible;
  };

  @action
  handleOpenPrivacyPolicyLink = visible => {
    this.privacyPolicyPopoverVisible = visible;
  };

  editUser = () => {
    const {store} = this.props;
    this.handleUserVisibleChange(false);
    store.router.goTo(views.editProfile, {});
  };

  logout = () => {
    const {store} = this.props;
    this.handleUserVisibleChange(false);
    store.router.goTo(views.logout, {});
  };

  goToPrivacyLink = () => {
    const {store} = this.props;
    this.handleUserVisibleChange(false);
    store.router.goTo(views.privacy, {});
  };

  stopImpersonate = () => {
    const domain = '.' + window.location.hostname.split('.').slice(1).join('.');
    document.cookie = `impersonate=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
    window.location.href = `${window.location.origin}/admin`;
  };

  componentDidMount = () => {
    const {
      store: {
        app: {handleGoesOffline, handleGoesBackOnline, setTranslations}
      },
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(pick(messages, ['goesOffline', 'goesBackOnline']), message =>
      formatMessage(message)
    );

    setTranslations(translations);

    window.addEventListener('offline', handleGoesOffline);
    window.addEventListener('online', handleGoesBackOnline);
  };

  render() {
    const {
      store,
      logoUrl,
      intl: {formatMessage}
    } = this.props;
    const {auth, app, unsavedChangesDialog, router} = store;
    const {user} = auth;
    const showLoggedUserMenu = user && !user.isViewer;

    const selectedLocale = store.platform.selectedLocale;
    const availableLanguages = store.platform.availableLanguages || [];

    const actionMenuItems = toJS(app.actionMenuItems);
    const showActionMenu = router.currentRoute?.actionMenu && !!actionMenuItems.length;
    const showStopImpersonate = document.cookie.indexOf('impersonate=') > -1;

    return (
      <Header>
        <Wrapper>
          <HeaderLeft>
            {user && <MenuIcon data-cy="nav-menu" name="menu" onClick={() => app.toggleNavMenu()} />}
            <StyledLogoLink store={store} route={views.guides}>
              {logoUrl && <Logo src={logoUrl} />}
            </StyledLogoLink>
          </HeaderLeft>

          <HeaderRight>
            {showStopImpersonate && (
              <StopImpersonateButton label={formatMessage(messages.stopImpersonating)} onClick={this.stopImpersonate} />
            )}
            <StyledLink href={INFO_CENTER_URL} target="_blank">
              <Icon name={'help-icon'} size={20} aria-label={'help-icon'} />
            </StyledLink>
            {showLoggedUserMenu && (
              <Popover
                visible={this.userPopoverVisible}
                onVisibleChange={this.handleUserVisibleChange}
                content={
                  <Menu>
                    <UserName>{user.fullName}</UserName>
                    <MenuItem onClick={this.editUser}>
                      <FormattedMessage {...messages.account} />
                    </MenuItem>
                    <MenuItem onClick={this.goToPrivacyLink}>
                      <FormattedMessage {...messages.privacy} />
                    </MenuItem>
                    <MenuItem onClick={this.logout} data-cy="logout">
                      <FormattedMessage {...messages.logout} />
                    </MenuItem>
                  </Menu>
                }
              >
                <MenuButton selected={this.userPopoverVisible} data-cy="user">
                  <Icon name={'user'} size={20} aria-label={'user-icon'} />
                </MenuButton>
              </Popover>
            )}
            {!user && availableLanguages.length > 1 && (
              <Popover
                visible={this.languagePopoverVisible}
                onVisibleChange={this.handleLanguageVisibleChange}
                content={
                  <Menu isScrollable={availableLanguages.length > 8}>
                    {availableLanguages.map(locale => (
                      <MenuItem
                        key={locale.locale}
                        onClick={() => {
                          unsavedChangesDialog.check().then(() => {
                            store.platform.setCurrentLanguage(locale.locale);
                            this.languagePopoverVisible = false;
                          });
                        }}
                        selected={selectedLocale.short === locale.short}
                      >
                        {locale.language}
                        {selectedLocale.short === locale.short && <Icon name={'checkmark'} size={16} />}
                      </MenuItem>
                    ))}
                  </Menu>
                }
              >
                <MenuButton selected={this.languagePopoverVisible}>
                  <Icon name={'language'} size={20} aria-label={'language-icon'} />
                </MenuButton>
              </Popover>
            )}
            {!user && (
              <Popover
                visible={this.privacyPolicyPopoverVisible}
                onVisibleChange={this.handleOpenPrivacyPolicyLink}
                content={
                  <Menu onClick={() => this.handleOpenPrivacyPolicyLink(false)}>
                    <MenuItem onClick={this.goToPrivacyLink}>
                      <FormattedMessage {...messages.privacy} />
                    </MenuItem>
                  </Menu>
                }
              >
                <MenuButton selected={this.languagePopoverVisible}>
                  <Icon name={'hamburger'} size={20} aria-label={'hamburger-menu-icon'} />
                </MenuButton>
              </Popover>
            )}
          </HeaderRight>
          {showActionMenu && <ActionMenu />}
        </Wrapper>
      </Header>
    );
  }
}

export default injectIntl(HeaderComponent);
