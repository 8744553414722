import filter from 'lodash/filter';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import without from 'lodash/without';
import {action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import enhanceWithClickOutside from 'react-click-outside';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Button from 'ui-components/Button';

//hocs
import withControlledValue from 'shared/hocs/withControlledValue';
import withViewValue from 'shared/hocs/withViewValue';

//styles
import {ButtonLabel, Combobox, StyledTwinPanelPicker} from './styles';

//messages
import messages from './messages';

@withControlledValue
@withViewValue
@enhanceWithClickOutside
@observer
class ComboboxComponent extends Component {
  static defaultProps = {
    buttonFormatter: (allValues, props) => {
      const {labelAccessor, options, valueAccessor} = props;

      const values = intersection(allValues, options.map(valueAccessor));

      if (!values || values.length === 0) {
        return <FormattedMessage {...messages.filterAny} values={{total: options.length}} />;
      }

      const labels = values.map(value => {
        const option = find(options, option => valueAccessor(option) === value);
        return labelAccessor(option);
      });

      return <FormattedMessage {...messages.filterSelectedOptions} values={{total: labels.length}} />;
    },
    modelValue: Object.freeze([]),
    options: Object.freeze([]),
    viewValue: Object.freeze([]),
    alignPopover: 'right'
  };

  static propTypes = {
    buttonFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    labelAccessor: PropTypes.func,
    labelFormatter: PropTypes.func,
    modelValue: PropTypes.array,
    options: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.number,
    title: PropTypes.string,
    valueAccessor: PropTypes.func,
    viewValue: PropTypes.array,
    alignPopover: PropTypes.oneOf(['left', 'right'])
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable shouldShowDropdown = false;
  @observable buttonXAxisCoordinate;

  @action
  hideDropdown = ({cancelChanges = false} = {}) => {
    const {rollbackViewValue} = this.props;

    this.shouldShowDropdown = false;

    if (cancelChanges) {
      rollbackViewValue();
    }
  };

  @action
  showDropdown = e => {
    this.shouldShowDropdown = true;

    // coordinates of the button that was clicked to open the combobox
    const parentButton = e.currentTarget.getBoundingClientRect();
    const buttonParentXAxis = parentButton.left;

    if (buttonParentXAxis) {
      this.buttonXAxisCoordinate = buttonParentXAxis;
    }
  };

  handleButtonClick = e => {
    if (this.shouldShowDropdown) {
      this.hideDropdown({cancelChanges: true});
    } else {
      this.showDropdown(e);
    }
  };

  // This handler is picked up by the onClickOutside decorator:
  handleClickOutside = () => {
    this.hideDropdown({cancelChanges: true});
  };

  handleCommit = () => {
    const {commitViewValue, options, setViewValue, viewValue} = this.props;

    if (viewValue.length === options.length) {
      setViewValue([]);
    }

    commitViewValue();
    this.hideDropdown();
  };

  handleDeselect = values => {
    const {setViewValue, viewValue} = this.props;
    setViewValue(without(viewValue, ...values));
  };

  handleSelect = values => {
    const {setViewValue, viewValue} = this.props;
    setViewValue([...viewValue, ...values]);
  };

  render() {
    const {
      buttonProps,
      className,
      labelAccessor,
      labelFormatter,
      options,
      rows,
      style,
      title,
      valueAccessor,
      viewValue,
      alignPopover,
      dataTestId,
      customMessages
    } = this.props;

    const selectedOptions = filter(options, option => viewValue.includes(valueAccessor(option)));

    return (
      <Combobox className={className} style={style}>
        <Button
          {...buttonProps}
          label={
            <ButtonLabel data-testid={dataTestId} isOpen={this.shouldShowDropdown}>
              {this.buttonLabel}
            </ButtonLabel>
          }
          onClick={this.handleButtonClick}
          underlined
        />
        {this.shouldShowDropdown && (
          <StyledTwinPanelPicker
            options={options}
            labelAccessor={labelAccessor}
            labelFormatter={labelFormatter}
            onCommit={this.handleCommit}
            onDeselect={this.handleDeselect}
            onSelect={this.handleSelect}
            rows={rows}
            selectedOptions={selectedOptions}
            title={title}
            valueAccessor={valueAccessor}
            alignPopover={alignPopover}
            customMessages={customMessages}
            buttonXAxisCoordinate={this.buttonXAxisCoordinate}
          />
        )}
      </Combobox>
    );
  }

  get buttonLabel() {
    const {buttonFormatter, modelValue} = this.props;

    if (typeof buttonFormatter === 'function') {
      return buttonFormatter(modelValue, this.props);
    }

    return buttonFormatter;
  }
}

export default ComboboxComponent;
