import {useMutation} from '@apollo/client';
import {useCallback} from 'react';
import Raven from 'raven-js';

import {DELETE_TOKEN, GENERATE_TOKEN} from '../api/platform/mutations';

export const useTokenOperations = (addToken, removeToken, notificationStrategies) => {
  const [createAccessTokenMutation] = useMutation(GENERATE_TOKEN);
  const [revokeAccessTokenMutation] = useMutation(DELETE_TOKEN);

  const generateToken = useCallback(async () => {
    try {
      const {data} = await createAccessTokenMutation();
      if (data?.createAccessToken) {
        addToken(data.createAccessToken);
        notificationStrategies.createOperationSuccess();
      }
    } catch (err) {
      notificationStrategies.createOperationFailure();
      Raven.captureException(err);
    }
  }, [createAccessTokenMutation, addToken]);

  const deleteToken = useCallback(
    async jti => {
      try {
        const {data} = await revokeAccessTokenMutation({variables: {jti}});
        if (data?.revokeAccessToken.result) {
          removeToken(jti);
          notificationStrategies.deleteOperationSuccess();
        }
      } catch (err) {
        notificationStrategies.deleteOperationFailure();
        Raven.captureException(err);
      }
    },
    [revokeAccessTokenMutation, removeToken]
  );

  return {
    generateToken,
    deleteToken
  };
};
