import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkUniqueJobTitleName} from 'utils/validation-utils';
import {debounce} from 'lodash';

const asyncRules = {
  checkUniqueJobTitleName: debounce((value, attr, key, passes) => {
    return checkUniqueJobTitleName(value, passes);
  }, 500)
};

const plugins = dvrWithAsync(asyncRules);

const fields = [
  {
    name: 'name',
    rules: 'required|string|checkUniqueJobTitleName'
  }
];

export default new MobxReactForm({fields}, {plugins});
