import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//lodash
import omit from 'lodash/omit';

//helpers
import injectScrollParent from 'decorators/injectScrollParent';
import {scrollToBottom, scrollToTop} from 'shared/utils/dom-utils';
import {stepNoteTypes} from 'shared/utils/instruction-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {Content, ContentItem, StyledInfoIcon, Title, WritingTips} from './styles';

//messages
import messages from './messages';

//constants
const scrollBehavior = 'smooth';

@injectScrollParent
class WritingTipsComponent extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  };

  // NB. This property is never rendered,
  // so there is no need to make it a part of a state
  // or an observable.
  hasScrolledIntoView = false;

  toggleScrollIntoView = () => {
    const {getScrollParent} = this.props;

    const scrollParent = getScrollParent();

    if (this.hasScrolledIntoView) {
      scrollToTop(scrollParent, scrollBehavior);
    } else {
      scrollToBottom(scrollParent, scrollBehavior);
    }

    this.hasScrolledIntoView = !this.hasScrolledIntoView;
  };

  render() {
    const {type, intl} = this.props;
    const {formatMessage} = intl;

    const forwardedProps = omit(this.props, 'children', 'dangerouslySetInnerHTML');

    const _type = type.toLowerCase();
    const titleKey = `${_type}Title`;
    const contentKey = `${_type}Content`;

    const contentLines = formatMessage(messages[contentKey]).split('\n');

    const isCustomType = type === stepNoteTypes.CUSTOM;
    const contentLinesSecondary = formatMessage(messages.customContentSecondary).split('\n');

    return (
      <WritingTips {...forwardedProps}>
        <Title onClick={this.toggleScrollIntoView}>
          <StyledInfoIcon />
          <FormattedMessage {...messages[titleKey]} />
        </Title>
        <Content>
          {contentLines.map((line, index) => (
            <ContentItem key={index}>{line}</ContentItem>
          ))}
        </Content>
        {isCustomType && (
          <div>
            <Title onClick={this.toggleScrollIntoView}>
              <StyledInfoIcon />
              <FormattedMessage {...messages.customTitleSecondary} />
            </Title>
            <Content>
              {contentLinesSecondary.map((line, index) => (
                <ContentItem key={index}>{line}</ContentItem>
              ))}
            </Content>
          </div>
        )}
      </WritingTips>
    );
  }
}

export default injectIntl(WritingTipsComponent);
