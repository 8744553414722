import {defineMessages} from 'react-intl';

const messages = defineMessages({
  publishedGuideToWorkspace: {
    id: 'GuideActivity.publishedGuideToWorkspace',
    defaultMessage: 'Published to {workspaceCount, plural, one {workspace} other {workspaces}} {workspaces}.'
  },
  unassignedFromWorkspaces: {
    id: 'unassignedFromWorkspaces',
    defaultMessage: 'Unassigned from workspaces:'
  },
  assignedToWorkspaces: {
    id: 'assignedToWorkspaces',
    defaultMessage: 'Assigned to workspaces:'
  },
  approvalApprovedAndPublishedTo: {
    id: 'GuideActivity.approvalApprovedAndPublishedTo',
    defaultMessage: 'Published to {workspacesCount, plural, one {workspace} other {workspaces}} {workspaces}'
  },
  and: {
    id: 'and',
    defaultMessage: 'and'
  }
});

export default messages;
