import {observable, action, makeObservable, computed} from 'mobx';
import store from 'stores/store';
import views from 'config/views';
import notification from 'utils/notification-utils';
import {smartTrim} from 'utils/validation-utils';

class SkillsPage {
  @observable selectedLearningActivities = [];
  @observable isActivitiesDrawerOpened = false;
  @observable learningActivities = null;
  @observable isDetailedViewSelected = false;
  @observable selectedLearningActivity = null;
  @observable skillForEditing = null;
  @observable skillNameForEditing = null;
  @observable skillNameExists = false;
  @observable validSkillName = true;
  @observable skillProfilesCount = null;
  @observable isSkillProfileDialogOpened = false;
  @observable editSkillMutation = null;
  @observable backFromLearnings = false;
  @observable createSkillMutation = null;
  @observable deleteSkillMutation = null;
  @observable selectedFrequencyOption = null;
  @observable skillName = '';
  @observable skillTasksLoading = false;
  @observable skillTasksLoadingError = false;
  @observable totalSkillTasksCount = 0;
  @observable skillTasksLimit = 50;
  @observable skillTasksOffset = 0;
  @observable skillTasksSortBy = [{field: 'created_at', order: 'desc'}];
  @observable skillTasksFilters = {
    title: {
      contains: ''
    },
    tags: {
      in: []
    }
  };
  @observable
  translations = {};
  @observable newLearningActivityTitle = null;
  @observable selectedTagsIds = [];
  @observable localSelectedTags = [];
  @observable drawerSelectedTags = [];
  @observable drawerLocalSelectedTags = [];
  @observable skillToDelete = null;
  @observable deleteSkillDialogOpened = false;

  constructor() {
    makeObservable(this);
  }

  setTranslations = translations => {
    this.translations = translations;
  };

  @action setIsActivitiesDrawerOpened = value => {
    this.isActivitiesDrawerOpened = value;
  };

  @action setIsDetailedViewSelected = value => {
    this.isDetailedViewSelected = value;
  };

  @action setSelectedLearningActivity = value => {
    this.selectedLearningActivity = value;
  };

  @action setEditSkillMutation = value => {
    this.editSkillMutation = value;
  };

  @action setCreateSkillMutation = value => {
    this.createSkillMutation = value;
  };

  @action setDeleteSkillMutation = value => {
    this.deleteSkillMutation = value;
  };

  @action setSkillForEditing = value => {
    this.skillForEditing = value;
    this.skillNameForEditing = value.name;
  };

  @action setSearchTerm = value => {
    this.skillTasksFilters.title.contains = value;
  };

  @action setSkillProfilesCount = value => {
    this.skillProfilesCount = value;
  };

  @action setIsSkillProfileDialogOpened = value => {
    this.isSkillProfileDialogOpened = value;
  };

  @action deleteAllActivities = () => {
    this.selectedLearningActivities = [];
  };

  @action setLearningActivities = learningActivities => {
    this.learningActivities = learningActivities;
  };

  @action removeSelectedLearningActivity = item => {
    this.selectedLearningActivities = this.selectedLearningActivities.filter(
      learningActivity => learningActivity.id !== item.id
    );
  };

  @action addSelectedLearningActivity = item => {
    this.selectedLearningActivities.push(item);
  };

  @action setSelectedLearningActivities = selectedActivities => {
    this.selectedLearningActivities = selectedActivities;
  };

  @action updateSkillTasksOffset = offset => (this.skillTasksOffset = offset);

  @action setTotalSkillTasksCount = count => (this.totalSkillTasksCount = count);

  @action updateSkillTasks = skillTasks => {
    this.learningActivities = [...this.learningActivities, ...skillTasks];
  };

  @action updateSkillTasksLoadingState = val => (this.skillTasksLoading = val);

  @action updateSkillTasksLoadingError = val => (this.skillTasksLoadingError = val);

  @action setSkillName = skillName => {
    this.skillForEditing.name = skillName;
  };

  @action setCreateSkillName = skillName => {
    this.skillName = skillName;
  };

  @action setSkillNameExists = value => {
    this.skillNameExists = value;
  };

  @action resetDrawer = () => {
    this.setIsActivitiesDrawerOpened(false);
    this.setIsDetailedViewSelected(false);
    this.setSelectedLearningActivity(null);
  };

  @action updateNewLearningActivityTitle = title => {
    this.newLearningActivityTitle = title;
  };

  @action
  setSelectedTags = tags => {
    this.selectedTagsIds = tags;
  };

  @action
  setLocalSelectedTags = tags => {
    this.localSelectedTags = tags;
  };

  @action
  setDrawerSelectedTags = tags => {
    this.drawerSelectedTags = tags;
  };

  @action
  setDrawerLocalSelectedTags = tags => {
    this.drawerLocalSelectedTags = tags;
  };

  @action
  filterByTag = () => {
    this.setDrawerSelectedTags(this.drawerLocalSelectedTags);
    this.skillTasksFilters.tags.in = this.drawerSelectedTags.map(tag => tag.id);
  };

  @action
  resetFilters = () => {
    this.setDrawerSelectedTags([]);
    this.setDrawerLocalSelectedTags([]);
    this.skillTasksFilters.tags.in = [];
  };

  @action
  createSkill = async () => {
    const skillTasksIds = this.selectedLearningActivities?.map(skillTask => skillTask.id);

    this.setCreateSkillName(smartTrim(this.skillName));

    try {
      // {graphql} from '@apollo/client/react/hoc/graphql' method;
      await this.createSkillMutation({
        name: this.skillName,
        skillTasksIds,
        expirationMonths: this.selectedFrequencyOption !== 0 ? this.selectedFrequencyOption : null,
        tagsIds: this.selectedTagsIds
      });

      notification.success(this.translations.createdSkill);
      this.deleteAllActivities();
      this.setSelectedFrequencyOption(null);
      store.router.goTo(views.skills, {});
    } catch (error) {
      if (error.message === 'skill_name_already_exists') {
        notification.error(this.translations.skillAlreadyExists);
        this.setSkillNameExists(true);
      } else {
        notification.error(this.translations.failedCreateSkill);
      }
    }
  };

  @action editSkill = async () => {
    const skillTaskIds = this.selectedLearningActivities.map(skillTask => skillTask.id);
    this.setSkillName(smartTrim(this.skillForEditing.name));

    try {
      await this.editSkillMutation({
        skill: {
          id: this.skillForEditing.id,
          name: this.skillForEditing.name,
          expirationMonths: this.skillForEditing.expirationMonths !== 0 ? this.skillForEditing.expirationMonths : null
        },
        skillTaskIds,
        tagsIds: this.selectedTagsIds
      });
      notification.success(this.translations.editedSkill);
      this.deleteAllActivities();
      this.setSkillNameExists(false);
      this.setIsSkillProfileDialogOpened(false);
      this.editSelectedFrequencyOption(null);
      store.router.goTo(views.skills, {});
    } catch (error) {
      if (error.message === 'skill_name_already_exists') {
        notification.error(this.translations.skillAlreadyExists);
        this.setSkillNameExists(true);
      } else {
        notification.error(this.translations.failedEditSkill);
      }
    }
  };

  @action setSelectedFrequencyOption = value => {
    this.selectedFrequencyOption = value;
  };

  @action editSelectedFrequencyOption = value => {
    this.skillForEditing.expirationMonths = value;
  };

  @action
  updateBackFromLearnings = value => {
    this.backFromLearnings = value;
  };

  @action
  setSkillToDelete = value => {
    this.skillToDelete = value;
  };

  @action
  setDeleteSkillDialogOpened = value => {
    this.deleteSkillDialogOpened = value;
  };

  @action
  resetDeleteSkillDialog = () => {
    this.deleteSkillDialogOpened = false;
    this.skillToDelete = null;
  };

  @computed
  get showInfiniteLoader() {
    return this.learningActivities?.length !== this.totalSkillTasksCount;
  }

  @computed
  get hasSelectedLearningActivities() {
    return this.selectedLearningActivities.length;
  }

  deleteSkill = async () => {
    if (!this.skillToDelete) {
      return;
    }

    // {useMutation} from '@apollo/client' method
    await this.deleteSkillMutation({
      variables: {
        id: this.skillToDelete.id
      },
      refetchQueries: ['skillsPaginated']
    })
      .then(() => {
        notification.success(this.translations.deletedSkill);
      })
      .catch(() => {
        notification.error(this.translations.errorDeletingSkill);
      });
    this.resetDeleteSkillDialog();
  };
}
export default SkillsPage;
