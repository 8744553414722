import {User, UserInfo, UsersPaginated} from 'api/user/queries';

export const refreshProfile = () => ({
  query: UserInfo
});

export const refreshUser = id => ({
  query: User,
  variables: {
    id: id
  }
});

export const refreshUsers = () => ({
  query: UsersPaginated
});
