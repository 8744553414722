import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import get from 'lodash/get';

import views from 'config/views';
import {ensureDraftVersion} from 'utils/versioning-utils';
import notification from 'utils/notification-utils';

import {
  ArchiveInstruction,
  CloneInstruction,
  PublishInstruction,
  SetInstructionToDraft,
  MoveInstruction,
  RequireSignature,
  RemoveSignature
} from 'api/instruction/mutations';

import {
  archiveInstructionInGuideOptions,
  cloneInstructionInListOptions,
  publishInstructionInGuideOptions,
  setInstructionToDraftInGuideOptions,
  moveInstructionOptions,
  requireSignatureOptions,
  removeSignatureOptions
} from 'api/instruction/mutation-options';

import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuLink
} from 'styles/styled-components/dropdown-styled-components';

import {FormattedMessage} from 'components/FormattedComponents';
import UntranslatedIndicator from 'components/UntranslatedIndicator';

import {TranslationItemWrapper} from './styles';
import {getTranslateRouteAndParams} from 'utils/translate-route';
import {SHARE_DIALOG_TYPE} from 'config/enums';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@graphql(ArchiveInstruction, archiveInstructionInGuideOptions)
@graphql(CloneInstruction, cloneInstructionInListOptions)
@graphql(PublishInstruction, publishInstructionInGuideOptions)
@graphql(SetInstructionToDraft, setInstructionToDraftInGuideOptions)
@graphql(MoveInstruction, moveInstructionOptions)
@graphql(RequireSignature, requireSignatureOptions)
@graphql(RemoveSignature, removeSignatureOptions)
@observer
class InstructionMenuComponent extends Component {
  static propTypes = {
    editAction: PropTypes.func,
    instruction: PropTypes.object.isRequired,
    onArchive: PropTypes.func,
    onClone: PropTypes.func,
    shouldShowUntranslatedIcon: PropTypes.bool
  };

  archive = ensureDraftVersion(() => {
    const {
      archiveInstructionInGuideMutation: mutation,
      instruction: {id, title},
      store: {archiveDialog},
      onArchive,
      translationsMessages: {archive: translations}
    } = this.props;

    archiveDialog.open({mutation, translations, title, params: {id}, onArchive});
  }, this);

  clone = ensureDraftVersion(() => {
    const {
      cloneInstructionMutation,
      instruction: {id, topicId},
      onClone,
      translationsMessages: {clone}
    } = this.props;

    cloneInstructionMutation({id, topicId})
      .then(({data}) => {
        notification.success(clone.cloneSuccess);

        if (typeof onClone === 'function') {
          onClone(data.cloneInstruction);
        }

        trackEvent(EVENT_TYPES.DUPLICATE_INSTRUCTION, {instructionId: id});
      })
      .catch(() => {
        notification.error(clone.cloneError);
      });
  }, this);

  publish = ensureDraftVersion(() => {
    const {
      publishInstructionMutation,
      instruction: {id},
      translationsMessages: {publish}
    } = this.props;

    publishInstructionMutation({id})
      .then(() => {
        notification.success(publish.showSuccess);

        trackEvent(EVENT_TYPES.SHOW_INSTRUCTION, {instructionId: id});
      })
      .catch(() => {
        notification.error(publish.showFailure);
      });
  }, this);

  unpublish = ensureDraftVersion(() => {
    const {
      setInstructionToDraftMutation,
      instruction: {id},
      translationsMessages: {unpublish}
    } = this.props;

    setInstructionToDraftMutation({id})
      .then(() => {
        notification.success(unpublish.hideSuccess);

        trackEvent(EVENT_TYPES.HIDE_INSTRUCTION, {instructionId: id});
      })
      .catch(() => {
        notification.error(unpublish.hideFailure);
      });
  }, this);

  moveTo = () => {
    const {
      store: {moveToDialog},
      instruction: {id},
      moveInstructionMutation,
      translationsMessages: {moveTo: translations}
    } = this.props;

    moveToDialog.open({
      id,
      translations,
      moveToMutation: moveInstructionMutation
    });

    trackEvent(EVENT_TYPES.MOVE_INSTRUCTION, {instructionId: id});
  };

  share = () => {
    const {
      instruction,
      isChecklist,
      store: {editGuidePage, editInstructionDetailsPage, shareDialog: dialog, router},
      translationsMessages: {
        share: {title}
      }
    } = this.props;
    const {id: instructionId, slug: instructionSlug} = instruction;

    const page = router.currentRoute.path === views.editGuide.path ? editGuidePage : editInstructionDetailsPage;
    const guideId = page.guide.id;

    dialog.open({
      itemTitle: instruction.title,
      path: `guide/${guideId}/${instructionId}`,
      qrBasename: instructionSlug,
      title,
      type: isChecklist ? SHARE_DIALOG_TYPE.CHECKLIST : SHARE_DIALOG_TYPE.INSTRUCTION
    });

    const eventData = {
      title: instruction.title,
      guideId,
      instructionId,
      type: isChecklist ? SHARE_DIALOG_TYPE.CHECKLIST : SHARE_DIALOG_TYPE.INSTRUCTION
    };

    trackEvent(EVENT_TYPES.SHARE_INSTRUCTION, eventData);
  };

  applySignOff = () => {
    const {
      store: {applyCampaignDialog},
      instruction
    } = this.props;
    const appliedFromInstruction = true;

    applyCampaignDialog.open([instruction], appliedFromInstruction, instruction.campaign);

    trackEvent(EVENT_TYPES.APPLY_SIGNOFF_INSTRUCTION);
  };

  requireSignature = () => {
    const {
      requireSignatureMutation,
      translationsMessages: {items},
      instruction: {id}
    } = this.props;

    requireSignatureMutation({id})
      .then(() => {
        notification.success(items.signatureRequired.defaultMessage);
      })
      .catch(() => {
        notification.error(items.somethingWentWrong.defaultMessage);
      });
  };

  removeSignature = () => {
    const {
      removeSignatureMutation,
      translationsMessages: {items},
      instruction: {id}
    } = this.props;

    removeSignatureMutation({id})
      .then(() => {
        notification.success(items.signatureRemoved.defaultMessage);
      })
      .catch(() => {
        notification.error(items.somethingWentWrong.defaultMessage);
      });
  };

  setAlertRecipients = () => {
    const {
      store: {checklistAlertRecipientsDialog},
      instruction: checklist
    } = this.props;

    checklistAlertRecipientsDialog.open({
      id: checklist.id,
      title: checklist.title
    });

    trackEvent(EVENT_TYPES.CHECKLIST_SET_ALERT_RECIPIENTS);
  };

  render() {
    const {
      canTranslate,
      instruction,
      store,
      shouldShowUntranslatedIcon,
      translationsMessages: {items},
      isChecklist,
      guideDefaultLocale
    } = this.props;
    const {publishStatus} = instruction;
    const {
      editGuidePage,
      editInstructionDetailsPage,
      router,
      auth: {
        user: {canManageCampaigns}
      },
      platform: {hasChecklistsSignatureEnabled, hasConfigurableAlertsRecipients}
    } = store;
    const {id: guideId} = router.params;

    const canAssignAlertRecipients = hasConfigurableAlertsRecipients && editGuidePage.canAssignAlertRecipients;

    const page = router.currentRoute.path === views.editGuide.path ? editGuidePage : editInstructionDetailsPage;

    const showHide = publishStatus === 'PUBLISHED';
    const availableTranslations = get(page, 'guide.availableTranslations', []);
    const isDraftVersion = !get(router, 'queryParams.v', false);
    const canShare = get(page.guide, 'canShare', false) && publishStatus === 'PUBLISHED';
    const showTranslate = canTranslate && isDraftVersion;
    const requireSignature = instruction.requireSignature;

    const {params: translateParams, route: translateRoute} = getTranslateRouteAndParams(
      guideDefaultLocale,
      availableTranslations,
      {guideId, topicId: instruction.topicId, instructionId: instruction.id}
    );
    return (
      // The Dropdown component tries to amend its child props.
      // To avoid disrupting this we want to forward the props
      // the actual underlying DropdownMenu component it expects.
      <DropdownMenu {...this.props}>
        {canShare && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.share}>
              <FormattedMessage {...items.share} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {canShare && <DropdownMenuDivider />}
        {isChecklist && canAssignAlertRecipients && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.setAlertRecipients}>
              <FormattedMessage {...items.setAlertRecipients} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          {showHide && (
            <DropdownMenuButton onClick={this.unpublish}>
              <FormattedMessage {...items.hide} />
            </DropdownMenuButton>
          )}
          {!showHide && (
            <DropdownMenuButton onClick={this.publish}>
              <FormattedMessage {...items.show} />
            </DropdownMenuButton>
          )}
        </DropdownMenuItem>
        {!isChecklist && canManageCampaigns && (
          <DropdownMenuItem>
            <DropdownMenuButton data-cy="menu-apply-signoff" onClick={this.applySignOff}>
              <FormattedMessage {...items.applySignOff} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {isChecklist && hasChecklistsSignatureEnabled && !requireSignature && (
          <DropdownMenuItem>
            <DropdownMenuButton data-testid="require-signature" onClick={this.requireSignature}>
              <FormattedMessage {...items.requireSignature} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {isChecklist && hasChecklistsSignatureEnabled && requireSignature && (
          <DropdownMenuItem>
            <DropdownMenuButton data-testid="remove-signature" onClick={this.removeSignature}>
              <FormattedMessage {...items.removeSignature} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {isDraftVersion && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.clone}>
              <FormattedMessage {...items.duplicate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showTranslate && <DropdownMenuDivider />}
        {showTranslate && (
          <DropdownMenuItem>
            <DropdownMenuLink params={translateParams} store={store} route={translateRoute}>
              <TranslationItemWrapper>
                <FormattedMessage {...items.translate} />
                {shouldShowUntranslatedIcon && <UntranslatedIndicator size={16} content="!" />}
              </TranslationItemWrapper>
            </DropdownMenuLink>
          </DropdownMenuItem>
        )}
        <DropdownMenuDivider />
        {isDraftVersion && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.moveTo}>
              <FormattedMessage {...items.moveTo} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <DropdownMenuButton onClick={this.archive}>
            <FormattedMessage {...items.delete} />
          </DropdownMenuButton>
        </DropdownMenuItem>
      </DropdownMenu>
    );
  }
}

export default InstructionMenuComponent;
