import styled from 'styled-components';
import Dropzone from 'react-dropzone';

//helpers
import colors from 'styles/colors';
import flex from 'styles/flex';
import {swallowingStyled} from 'styles/styled-components/utils';

//components
import {TransparentButton} from 'styles/styled-components/shared-components';

export const Actions = styled.div`
  ${flex.horizontal};
`;

export const ChangeButton = styled(TransparentButton).attrs({
  type: 'button'
})``;

export const Preview = styled.div`
  font-family: ${p => p.fontFamilyStub};
  font-size: 24px;
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &::after {
    content: 'The quick brown fox jumps over the lazy dog';
  }
`;

export const RemoveButton = styled(TransparentButton).attrs({
  type: 'button'
})``;

export const UploadFont = swallowingStyled(Dropzone, {
  swallowProps: []
})``;

export const UploadFontActiveStyle = {
  border: `3px dashed ${colors.primary}`
};
