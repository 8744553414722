export const DND_TYPE = 'SWIPEABLE_STEP';

//lodash
import throttle from 'lodash/throttle';

export const source = {
  beginDrag: props => {
    const {onBeginDrag, step} = props;

    if (typeof onBeginDrag === 'function') {
      onBeginDrag();
    }

    return {
      preview: step,
      sourceId: step.id
    };
  },
  endDrag: props => {
    const {onEndDrag, repositionStepsMutation, store} = props;
    const {dragDropSteps: page} = store;

    if (typeof onEndDrag === 'function') {
      onEndDrag();
    }

    page.submit(repositionStepsMutation);
  }
};

export const target = {
  // Changing the position of the current step also makes the SwipeableSteps
  // component to scroll to keep the step in the center of the screen. While
  // this allows automatic scrolling, we don't want it to happen to fast. That's
  // why this handler is throttled.
  hover: throttle(
    (props, monitor) => {
      const {step, store} = props;
      const {dragDropSteps: page} = store;

      const item = monitor.getItem();
      const {sourceId} = item;

      if (sourceId !== step.id) {
        page.moveStep(sourceId, step.id);
      }
    },
    500,
    {trailing: false}
  )
};
