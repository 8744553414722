import styled from 'styled-components';

import $Button from 'ui-components/Button';

import {commonPalette} from 'shared/styles/palette';
import colors from 'styles/colors';

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: ${colors.txBlack};
  font-style: italic;
  margin-bottom: 0.875rem;
`;

export const TextGrey = styled(Text)`
  color: ${commonPalette.txGrey};
`;

export const StyledUnderlinedButton = styled($Button).attrs({
  underlined: true
})`
  padding-top: 1rem;
  padding-bottom: 1.5rem;
`;

export const InstructionalIconsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InstructionalIconListWrapper = styled.div`
  flex: 1;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background: rgba(236, 236, 236, 1);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(163, 163, 163, 1);
  }
`;

export const StepNotesCaptionWrapper = styled.div`
  flex: 0;
`;

export const StepNotesNewIconWrapper = styled.div`
  flex: 0;
`;
