import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'GuideVersions.title',
    defaultMessage: 'Guide versions'
  },
  viewVersion: {
    id: 'GuideVersions.viewVersion',
    defaultMessage: 'View version'
  },
  version: {
    id: 'GuideVersions.version',
    defaultMessage: 'Version {version} ({date})'
  },
  liveVersion: {
    id: 'GuideVersions.liveVersion',
    defaultMessage: 'Live version ({date})'
  },
  draft: {
    id: 'GuideVersions.draft',
    defaultMessage: 'Draft version'
  },
  approval: {
    id: 'GuideVersions.approval',
    defaultMessage: 'Version under approval | Review pending'
  },
  liveReview: {
    id: 'common.labels.liveReview',
    defaultMessage: 'Live review version'
  },
  viewDraftVersionSuccess: {
    id: 'GuideVersions.viewDraftVersionSuccess',
    defaultMessage: 'You are now viewing the draft version of your guide'
  },
  viewLiveVersionSuccess: {
    id: 'GuideVersions.viewLiveVersionSuccess',
    defaultMessage: 'You are now viewing the live version of your guide'
  },
  viewApprovalVersionSuccess: {
    id: 'GuideVersions.viewApprovalVersionSuccess',
    defaultMessage: 'You are now viewing the version of the guide under approval'
  },
  viewVersionSuccess: {
    id: 'GuideVersions.viewVersionSuccess',
    defaultMessage: 'You are now viewing version {version} of your guide'
  }
});

export default messages;
