import {action, observable, makeObservable} from 'mobx';

class AuthErrorDialog {
  @observable opened = false;

  @action
  reset = () => {
    this.opened = false;
  };

  @action open = user => {
    this.user = user;
    this.opened = true;
  };

  constructor() {
    makeObservable(this);
  }
}

export default AuthErrorDialog;
