//mobx form
import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

const plugins = {dvr: dvr(validatorjs)};

const fields = [
  {
    name: 'userId',
    rules: 'required|string'
  },
  {
    name: 'role',
    rules: 'required|string'
  },
  {
    name: 'skillRoleId',
    rules: 'string'
  },
  {
    name: 'isApprover',
    rules: 'boolean',
    value: false
  }
];

class UserTeamRolesModel {
  constructor() {
    this.form = new MobxReactForm({fields}, {plugins});
  }
}

export default UserTeamRolesModel;
