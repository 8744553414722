import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;

  border-radius: 2px;
  background: #fff;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.1), 0 -1px 4px 0 rgba(0, 0, 0, 0.1);
`;

export const CardHeading = styled.div`
  font-weight: 600;
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 24px;
`;

export const LegendsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const SausagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
`;
