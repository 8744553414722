import styled from 'styled-components';

//components
import $TwinPanelPicker from 'shared/components/TwinPanelPicker';

//derivative constants
const arrowSize = 8;
const width = 470;

export const ButtonLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  &::after {
    border-color: currentColor transparent;
    border-style: solid;
    border-width: ${p => (p.isOpen ? '0 4px 4px' : '4px 4px 0')};
    content: '';
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
  }
`;

export const Combobox = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  position: relative;
`;

const getTwinPanelArrowLeftOffset = buttonXAxisCoordinate => {
  const twinPanelOffset = getTwinPanelLeftOffset(buttonXAxisCoordinate);
  return -1 * twinPanelOffset + 14;
};

const getTwinPanelLeftOffset = buttonXAxisCoordinate => {
  if (!buttonXAxisCoordinate) return 0;

  // get data about wrapper component
  const elem = document.querySelector('.FilterContainersWrapper');
  const bounds = elem.getBoundingClientRect();
  const containerWidth = bounds.width;
  const containerXOffset = bounds.left;

  // calculate X axis coordinates in reference to wrapper component
  const clickOffsetWithinFilterContainer = buttonXAxisCoordinate - containerXOffset;

  if (clickOffsetWithinFilterContainer + width > containerWidth) {
    // adding 20 extra padding from the left side of the component
    return -1 * (clickOffsetWithinFilterContainer + width - containerWidth + 20);
  }

  return 0;
};

export const StyledTwinPanelPicker = styled($TwinPanelPicker)`
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 11px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.15);
  left: ${props => getTwinPanelLeftOffset(props.buttonXAxisCoordinate)}px;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  width: ${width}px;
  z-index: 9999;

  &::before {
    background: #fff;
    border-left: 1px solid #cfcfcf;
    border-top: 1px solid #cfcfcf;
    border-radius: 1px;
    content: '';
    height: ${arrowSize}px;
    left: 14px;
    left: ${props => getTwinPanelArrowLeftOffset(props.buttonXAxisCoordinate)}px;
    position: absolute;
    top: ${-arrowSize / 2 - 1}px;
    transform: rotate(45deg);
    width: ${arrowSize}px;
    z-index: -1;
    @media (max-width: 300px) {
      left: ${({alignPopover}) => (alignPopover === 'left' ? `${width / 2 + 14}px` : '14px')};
    }
  }

  @media (max-width: 450px) {
    width: 300px;
    margin-left: 100px;
  }

  @media (max-width: 375px) {
    width: 300px;
    margin-left: 170px;
  }

  @media (max-width: 300px) {
    ${({alignPopover}) => (alignPopover === 'left' ? `margin-left: -${width / 2}px;` : '')}
  }
`;
