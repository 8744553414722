import React, {Component} from 'react';
import {DragLayer} from 'react-dnd';

//components
import InstructionItem from '../InstructionItem';
import TopicItem from '../TopicItem';

//styled-components
import {PositionedBox} from './styles';

@DragLayer(monitor => ({
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType()
}))
export default class CustomDragLayer extends Component {
  render() {
    const {currentOffset, isDragging, item, itemType} = this.props;

    if (!currentOffset || !isDragging) {
      return null;
    }

    const {x, y} = currentOffset;
    const {preview} = item;

    const transform = `translate(${x}px, ${y}px)`;

    return (
      <div>
        <PositionedBox
          ref={this.inheritParentWidth}
          itemType={itemType}
          style={{
            WebkitTransform: transform,
            transform
          }}
        >
          {itemType === 'INSTRUCTION' && <InstructionItem design="opaque" instruction={preview} />}
          {itemType === 'TOPIC' && <TopicItem design="opaque" topic={preview} isChildrenDragAndDropDisabled={true} />}
        </PositionedBox>
      </div>
    );
  }

  inheritParentWidth = self => {
    if (self instanceof HTMLElement) {
      self.style.width = `${self.parentNode.offsetWidth}px`;
    }
  };
}
