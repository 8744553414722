import React, {useEffect, useMemo, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {useLazyQuery, useMutation} from '@apollo/client';

import {
  NameCell,
  StyledSubText,
  StyledText,
  TableActions,
  TableActionsHeader,
  TableInfoDiv,
  TableOverlay,
  DropwdownItem,
  FiltersWrapper,
  StyledMenu
} from './styles';
import debounce from 'lodash/debounce';
import kebabCase from 'lodash/kebabCase';

import {SkillsPaginated} from 'api/skills/queries';
import {EVENT_TYPES} from 'api/tracking/constants';

import {trackEvent} from 'utils/tracking/event-tracking';
import messages from './messages';
import views from 'config/views';
import {getReskillingFrequencyMessage} from 'config/constants';

// components
import {FormattedMessage} from 'components/FormattedComponents';
import SkillsListMenu from 'components/SkillsListMenu';
import {Filters} from 'components/Filters/Filters';
import TagPicker from 'components/TagPicker';
import TagsChips from 'components/TagsChips';
import FiltersChips from 'components/FiltersChips';
import {CellWrapper, HeaderWrapper} from 'ui-components/Layout/DataTable/styles';
import SearchBar from 'ui-components/Layout/SearchBar';
import Button from 'ui-components/Button';
import PaginationLoading from 'ui-components/PaginationLoading';
import InfoBox from 'ui-components/InfoBox';
import Dialog from 'ui-components/Dialog';
import {DeleteSkill} from 'api/skills/mutations';
import DataTable from 'ui-components/Layout/DataTable';
import Icon from 'ui-components/Layout/Icon';

const MAX_SELECTED = 10;
const PAGE_SIZE = 50;

const NoSkillsFoundComponent = props => {
  const {
    intl: {formatMessage},
    store: {router}
  } = props;

  return (
    <TableInfoDiv>
      <Icon data-cy="smart-skills" name="smart-skills" size={25} />
      <StyledText>{formatMessage(messages.noSkills)}</StyledText>
      <StyledSubText>{formatMessage(messages.clickAddSkillButton)}</StyledSubText>
      <Button
        secondary
        size={50}
        label={formatMessage(messages.createSkill)}
        onClick={() => {
          router.goTo(views.createSkills, {});
        }}
      />
    </TableInfoDiv>
  );
};

const NoSkillsFoundComponentDecorated = injectIntl(inject('store')(NoSkillsFoundComponent));

const NoDataFoundComponent = () => {
  return (
    <>
      <TableInfoDiv absolutePosition>
        <FormattedMessage {...messages.noSkillsFound} />
      </TableInfoDiv>
      <TableOverlay />
    </>
  );
};

const HeaderCellComponent = ({value}) => (
  <CellWrapper paddingLeft data-cy={value.split(' ').join('-')}>
    <NameCell title={value}>{value}</NameCell>
  </CellWrapper>
);

const TagsCellComponent = ({value}) => {
  return (
    <CellWrapper paddingLeft>
      <TagsChips tags={value} />
    </CellWrapper>
  );
};

const LearningsCountComponent = ({value: skillTasks}) => (
  <CellWrapper paddingLeft>{skillTasks.totalCount ?? 0}</CellWrapper>
);

type SkillsTabProps = {
  store: any;
  intl: InjectedIntl;
};

const SkillsTab = (props: SkillsTabProps) => {
  const {
    intl: {formatMessage},
    store: {
      skillsPage: {
        setLocalSelectedTags,
        localSelectedTags,
        deleteSkillDialogOpened,
        skillToDelete,
        deleteSkill,
        resetDeleteSkillDialog,
        setDeleteSkillMutation
      },
      router
    }
  } = props;

  const [pages, setPages] = useState<number | null>(null);
  const [totalCount, setTotalCount] = useState<number | null>(null);
  const [filtered, setFiltered] = useState({id: 'name', value: '', tags: []});
  const [searchTerm, setSearchTerm] = useState('');
  const [userHasSearched, setUserHasSearched] = useState(false);
  const [loading, setLoading] = useState(true);

  const [skillsPaginatedQuery, {data: skillsData, error}] = useLazyQuery(SkillsPaginated, {
    variables: {
      limit: PAGE_SIZE,
      offset: 0,
      sortBy: [{field: 'name', order: 'asc'}],
      filters: {
        name: {contains: ''},
        tags: {in: []}
      }
    },
    fetchPolicy: 'network-only',
    onCompleted: () => setLoading(false)
  });
  const data = skillsData?.skillsPaginated?.results || [];
  const [deleteSkillMutation] = useMutation(DeleteSkill);
  setDeleteSkillMutation(deleteSkillMutation);

  const fetchData = async ({page, sorted, filtered}) => {
    setLoading(true);
    const nameFilter = filtered?.value || '';
    const tagFilter = filtered?.tags?.map(tag => tag.id) || [];
    skillsPaginatedQuery({
      variables: {
        limit: PAGE_SIZE,
        offset: page * PAGE_SIZE,
        sortBy: sorted.map(sortByItem => {
          return {field: sortByItem.id, order: sortByItem.desc ? 'desc' : 'asc'};
        }),
        filters: {
          name: {contains: nameFilter},
          tags: {in: tagFilter}
        }
      }
    });
  };

  const handleUpdateSearchTerm = e => {
    e.preventDefault();
    e.stopPropagation();
    const newValue = e.target?.value.substring(0, 50);
    setSearchTerm(newValue);

    return updateSearchTerm(newValue);
  };

  const updateSearchTerm = useMemo(() => {
    return debounce(newValue => {
      if (!newValue.length || (newValue.length >= 3 && newValue.length <= 50)) {
        setFiltered({id: 'name', value: newValue, tags: filtered.tags});
        setUserHasSearched(true);
        trackEvent(EVENT_TYPES.SEARCH_SKILL, {value: newValue});
      }
    }, 300);
  }, []);

  const areFiltersActive = () => {
    return !!filtered?.value || !!filtered?.tags?.length;
  };

  const filterByTag = () => {
    setFiltered({...filtered, tags: localSelectedTags});
    setUserHasSearched(true);
  };

  const resetFilters = () => {
    setFiltered({...filtered, tags: []});
    setUserHasSearched(false);
    setLocalSelectedTags([]);
  };

  const removeSelectedTag = tag => {
    const tags = localSelectedTags.filter(i => i.id !== tag.id);
    setLocalSelectedTags(tags);
    setFiltered({...filtered, tags});
  };

  useEffect(() => {
    fetchData({
      page: 0,
      sorted: [
        {
          id: 'name',
          desc: false
        }
      ],
      filtered
    });

    return () => {
      const {
        store: {
          skillsPage: {setLocalSelectedTags}
        }
      } = props;

      setLocalSelectedTags([]);
    };
  }, []);

  useEffect(() => {
    if (skillsData) {
      const {totalCount} = skillsData.skillsPaginated;
      setTotalCount(totalCount);
      setPages(Math.ceil(totalCount / PAGE_SIZE));
    }
  }, [skillsData]);

  if (error) {
    return <>{formatMessage(messages.error)}</>;
  }

  const ActionsCellComponent = ({original: skill}) => {
    return (
      <StyledMenu
        dataCy={`menu-skills-list-${kebabCase(skill.name)}`}
        dropdownMenu={<SkillsListMenu skill={skill} />}
      />
    );
  };

  const columns = [
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.skillName)}</HeaderWrapper>,
      accessor: 'name',
      resizable: true,
      Cell: HeaderCellComponent
    },

    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.filterByTag)}</HeaderWrapper>,
      accessor: 'tags',
      Cell: TagsCellComponent,
      resizable: false,
      sortable: false,
      width: 250
    },

    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.repeatEvery)}</HeaderWrapper>,
      accessor: 'expirationMonths',
      Cell: ({value}) => (
        <CellWrapper paddingLeft>
          {value ? getReskillingFrequencyMessage(formatMessage, messages, value) : formatMessage(messages.none)}
        </CellWrapper>
      ),
      resizable: false,
      width: 210
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.learnings)}</HeaderWrapper>,
      accessor: 'skillTasks',
      Cell: LearningsCountComponent,
      resizable: false,
      sortable: false,
      width: 100
    },
    {
      Cell: ActionsCellComponent,
      sortable: false,
      width: 40
    }
  ];
  const noSkillsData = totalCount === 0 && !userHasSearched && !loading;
  const showTableActions = !!data?.length || areFiltersActive();

  return (
    <>
      {showTableActions && (
        <TableActionsHeader>
          <FiltersWrapper>
            <SearchBar
              placeholder={formatMessage(messages.searchByName)}
              onChange={handleUpdateSearchTerm}
              value={searchTerm}
            />
            <Filters
              filtersTitle={formatMessage(messages.filtersTitle)}
              formatMessage={formatMessage}
              onApply={filterByTag}
              onReset={resetFilters}
              totalFiltersApplied={filtered?.tags?.length}
            >
              {localSelectedTags.length >= MAX_SELECTED && (
                <InfoBox content={formatMessage(messages.tenTags)} type="informational" size="small" />
              )}

              <DropwdownItem>
                <StyledText>{formatMessage(messages.filterByTag)}</StyledText>
                <TagPicker
                  selectedTagsIds={localSelectedTags.map(tag => tag.id)}
                  setLocalSelectedTags={setLocalSelectedTags}
                  localSelectedTags={localSelectedTags}
                  showCheckBoxSelector
                  disableCreate
                  isSkillTagsPicker={true}
                />
              </DropwdownItem>
            </Filters>
          </FiltersWrapper>

          <TableActions>
            <Button
              secondary
              size={50}
              label={formatMessage(messages.createSkill)}
              iconId={'create'}
              onClick={() => {
                router.goTo(views.createSkills, {});
              }}
            />
          </TableActions>
        </TableActionsHeader>
      )}
      <FiltersChips items={filtered?.tags} removeItem={removeSelectedTag}></FiltersChips>
      {noSkillsData ? (
        <NoSkillsFoundComponentDecorated />
      ) : (
        <DataTable
          defaultSorted={[
            {
              id: 'name',
              desc: false
            }
          ]}
          hoverEffect
          data={data}
          paddingRows={5}
          columns={columns}
          noDataMessage={''}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          pages={pages}
          loading={loading}
          getTrGroupProps={(_, rowInfo) => ({
            onClick: () => {
              router.goTo(views.editSkills, {id: rowInfo.original.id});
            }
          })}
          onFetchData={fetchData}
          LoadingComponent={() => <PaginationLoading loading={loading} message={messages.loading} />}
          NoDataComponent={NoDataFoundComponent}
          filtered={filtered}
        />
      )}
      {deleteSkillDialogOpened && (
        <Dialog
          title={formatMessage(messages.deleteSkill, {name: skillToDelete.name})}
          isOpen={deleteSkillDialogOpened}
          size={'small'}
          actions={[
            {
              label: formatMessage(messages.deleteEverywhere),
              design: 'text',
              onClick: () => deleteSkill(deleteSkillMutation),
              style: {background: '#C33F38'}
            }
          ]}
          onCancel={() => resetDeleteSkillDialog(null)}
        >
          <StyledText>{formatMessage(messages.deleteDialog, {count: skillToDelete.skillProfilesCount})}</StyledText>
        </Dialog>
      )}
    </>
  );
};

export default injectIntl(inject('store')(observer(SkillsTab)));
