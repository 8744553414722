import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const Pagination = styled.div`
  padding: 0px;
  color: ${commonPalette.txtGrey};
  display: flex;
  justify-content: center;
  margin: 10px auto 0;
  gap: 20px;
  button {
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 5px;

    padding: 10px 20px;
    border: 1px solid rgb(163 163 163 / 0.3);
    border-radius: 3px;

    background-color: rgb(163 163 163 / 0.1);

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
      pointer-events: none;
    }
  }

  span {
    align-self: center;
  }
`;
export const StyledPaginationInput = styled.input`
  width: 70px;
  text-align: center;

  border: 1px solid rgb(0 0 0 / 0.1);
  background: white;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
`;
export const CenterPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
`;
