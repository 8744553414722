import {action, observable, makeObservable} from 'mobx';

class ConnectNewWorkspace {
  @observable opened = false;

  @action
  reset = () => {
    this.opened = false;
  };

  @action
  open = () => {
    this.opened = true;
  };

  constructor() {
    makeObservable(this);
  }
}

export default ConnectNewWorkspace;
