import React from 'react';
import {truncate} from 'lodash';
import {InjectedIntl, injectIntl} from 'react-intl';

import {FormattedMessage} from 'components/FormattedComponents';

import {getEventConfig, shouldGetTopicLink, incrementNumber} from './../utils';
import messages from './../messages';
import {FlexRow, EventHeader, LinkSection, EventWrapper} from './../styles';
import {Person} from './../Person';
import {TopicLink} from './../Links/TopicLink';
import {ActivityEvent} from '../types';

export type TopicEventProps = {
  activity: ActivityEvent;
  canLink: boolean;
  intl: InjectedIntl;
  router: any;
  checkTopicLinkIsValid: any;
  Chevron: () => React.JSX.Element;
  TimeAndDate: () => React.JSX.Element;
  Info: (props: any) => React.JSX.Element;
};

const getDisplaySettings = (visible, collapsible) => {
  if (visible === true && collapsible === true) {
    return messages.showAndCollapse;
  }
  if (visible === true && collapsible === false) {
    return messages.show;
  }
  if (visible === false && collapsible === false) {
    return messages.hide;
  }
  return messages.none;
};

const TopicEvent = (props: TopicEventProps) => {
  const {
    activity,
    canLink,
    intl: {formatMessage},
    router,
    checkTopicLinkIsValid,
    Chevron,
    TimeAndDate,
    Info
  } = props;

  const {
    eventType,
    versionId,
    beforePosition,
    afterPosition,
    position,
    displaySettings,
    topicTitle,
    guide,
    producedBy
  } = activity;
  const topicLink = shouldGetTopicLink(eventType) ? TopicLink : undefined;
  const eventConfig = getEventConfig(eventType, !!topicTitle, topicLink);

  return (
    <FlexRow>
      <Chevron />
      <EventWrapper>
        <EventHeader>
          <TimeAndDate />
          <LinkSection>
            {canLink && eventConfig.LinkComponent && versionId && (
              <eventConfig.LinkComponent
                {...activity}
                router={router}
                linkValidityChecks={{
                  checkTopicLinkIsValid
                }}
              >
                <FormattedMessage {...messages.view} />
              </eventConfig.LinkComponent>
            )}
          </LinkSection>
        </EventHeader>
        <Info>
          <FormattedMessage
            {...eventConfig.message}
            values={{
              person: <Person person={producedBy} />,
              topicTitle: topicTitle ? truncate(topicTitle, {length: 300}) : formatMessage(messages.defaultTopic),
              beforePosition: incrementNumber(beforePosition),
              afterPosition: incrementNumber(afterPosition),
              position: incrementNumber(position),
              displaySettings: formatMessage(
                getDisplaySettings(displaySettings?.visible, displaySettings?.collapsible)
              ),
              guide
            }}
          />
        </Info>
      </EventWrapper>
    </FlexRow>
  );
};

export default injectIntl(TopicEvent);
