import {defineMessages} from 'react-intl';

const messages = defineMessages({
  resetAll: {
    id: 'GuideListFilters.resetAll',
    defaultMessage: 'Reset All'
  },
  filters: {
    id: 'GuideListFilters.filters',
    defaultMessage: 'Filters'
  }
});

export default messages;
