import gql from 'graphql-tag';

export const CreateCampaign = gql`
  mutation createCampaign($campaign: CampaignCreate!) {
    createCampaign(campaign: $campaign) {
      id
    }
  }
`;

export const EditCampaign = gql`
  mutation editCampaign($id: String!, $campaign: CampaignEdit!) {
    editCampaign(id: $id, campaign: $campaign) {
      id
    }
  }
`;

export const ApplyCampaign = gql`
  mutation ($campaignId: String!, $instructionIds: [String]!) {
    applyCampaignToInstructions(campaignId: $campaignId, instructionIds: $instructionIds) {
      result
      error
    }
  }
`;

export const RemoveCampaign = gql`
  mutation ($instructionIds: [String]!) {
    removeCampaignFromInstructions(instructionIds: $instructionIds) {
      result
      error
    }
  }
`;

export const SetCampaignStatus = gql`
  mutation setCampaignStatus($id: String!, $status: String!) {
    setCampaignStatus(id: $id, status: $status) {
      result
    }
  }
`;
