import React, {Component} from 'react';
import {groupBySkillSet} from 'views/SmartSkillsUser/utils';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {NextIcon, StatusIndicator, SkillsetDropdownIcon, Table, Td, Th, Tr, TrCollapsable} from './styles';
import LevelsDropdown from './LevelsDropdown';
import messages from './messages';
import Icon from 'ui-components/Icon';
import SkillStatusLabel from 'components/SmartsSkillsStatusLabel';

@inject('store')
class SmartSkillsUserSkillMatrix extends Component {
  state = {
    skillSetCollapsed: {}
  };

  toggleSkillSet = skillSetId => {
    this.setState(previousState => ({
      skillSetCollapsed: {
        ...previousState.skillSetCollapsed,
        [skillSetId]: !previousState.skillSetCollapsed[skillSetId]
      }
    }));
  };

  /**
   * Sum up all NON-approved skills in the skill set
   * @param skills
   * @returns {number}
   */
  calculateGapCountBySkillSet(skills) {
    return skills.filter(i => i.status !== 'APPROVED').length;
  }

  render() {
    const {
      intl: {formatMessage},
      skillLevels,
      userSkillMatrix,
      onDrawerOpen
    } = this.props;
    const sortedSkillLevels = skillLevels.slice();
    const groupedBySkillSet = groupBySkillSet(userSkillMatrix);

    const formattedSkillLevels = sortedSkillLevels?.map(
      level => `${level.position} - ${level.name}${level.description ? ' - ' + level.description : ''}`
    );

    const popoverContent = formattedSkillLevels || formatMessage(messages.unableToLoadSkillLevels);

    const isBinary = sortedSkillLevels.length === 2;

    return (
      <Table>
        <thead>
          <Tr>
            <Th left width="40%"></Th>
            <Th>
              {formatMessage(messages.currentLevel)}
              <Icon
                iconId="info"
                popoverContent={popoverContent}
                popoverTitle="Level details:"
                showDash={false}
                style={{marginLeft: '4px'}}
              />
            </Th>
            {isBinary ? null : <Th>{formatMessage(messages.requiredLevel)}</Th>}
            <Th left>{formatMessage(messages.status)}</Th>
            <Th></Th>
          </Tr>
        </thead>
        {Object.values(groupedBySkillSet).map(({skillSet, skills}) => (
          <tbody key={skillSet.id}>
            <TrCollapsable>
              <Td colSpan={isBinary ? 3 : 4} onClick={() => this.toggleSkillSet(skillSet.id)}>
                <SkillsetDropdownIcon open={this.state.skillSetCollapsed[skillSet.id]} />
                {skillSet.name}
              </Td>
              <Td>{this.calculateGapCountBySkillSet(skills)} Gaps</Td>
            </TrCollapsable>
            {skills.map(skill => (
              <Tr key={skill.id} style={{display: this.state.skillSetCollapsed[skillSet.id] ? 'none' : 'table-row'}}>
                <Td align="left">{skill.name}</Td>
                <Td>
                  <LevelsDropdown skill={skill} skillLevels={sortedSkillLevels} />
                </Td>
                {isBinary ? null : (
                  <Td>
                    <StatusIndicator>{skill.requiredLevel?.position}</StatusIndicator>
                  </Td>
                )}
                <Td align="left" style={{paddingLeft: '8px'}}>
                  <SkillStatusLabel status={skill.status} />
                </Td>

                <Td onClick={() => onDrawerOpen(skill)} align="right" style={{cursor: 'pointer', paddingRight: '12px'}}>
                  <NextIcon data-testid="select-skill" />
                </Td>
              </Tr>
            ))}
          </tbody>
        ))}
      </Table>
    );
  }
}

export default injectIntl(SmartSkillsUserSkillMatrix);
