import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};
const fields = [
  {name: 'maxEditors', rules: 'numeric'},
  {name: 'maxPublishedGuides', rules: 'numeric'},
  {name: 'maxDomains', rules: 'numeric'},
  {name: 'nativeLatestVersion', rules: 'string'}
];

export default new MobxReactForm({fields}, {plugins});
