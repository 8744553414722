import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import moment from 'moment';
import get from 'lodash/get';
import {findLiveVersion} from 'utils/versioning-utils';
import {commonPalette} from 'shared/styles/palette';

//components
import MultiDomainPicker from 'components/MultiDomainPicker';
import {Dialog} from 'ui-components';
import Field from 'ui-components/Field';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import {DatePicker} from 'antd';

//data
import {DomainsLite} from 'api/domain/queries';
import {domainsOptions} from 'api/domain/query-options';
import {GetWorkspacesForGuide} from 'api/guide/queries';
import {domainsForGuidesOptions} from 'api/guide/query-options';

// event tracking
import {EVENT_TYPES} from 'api/tracking/constants';
import {trackEvent} from 'utils/tracking/event-tracking';

import {DATE_FORMAT} from 'config/constants';

//styles
import {InnerWrapper} from './styles';

//messages
import messages from './messages';

@inject('store')
@graphql(DomainsLite, domainsOptions)
@graphql(GetWorkspacesForGuide, domainsForGuidesOptions)
@observer
class PublishGuideDialogComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {store, guideDomainsQuery, domainsQuery} = nextProps;
    const {domainsForGuide, loading: guideDomainsLoading} = guideDomainsQuery;
    const {domains, loading} = domainsQuery;

    if (!loading && domains) {
      store.publishGuideDialog.setDomains(domains);
    }

    if (!guideDomainsLoading && domainsForGuide) {
      store.publishGuideDialog.setVisibleToValues(domainsForGuide);
    }
  }

  getVersion = (isUnderApproval, versions) => {
    if (isUnderApproval) {
      return 'review';
    } else {
      return get(findLiveVersion(versions), 'version', null);
    }
  };

  getContentAndTitle = ({
    isViewingLiveVersionUnderApproval,
    multiDomain,
    showPublishUpdatesLabel,
    publishMode,
    approveAndPublishMode,
    showPublishOldVersionLabel,
    guidePublished
  }) => {
    const isPublishUpdatesInPublishMode = showPublishUpdatesLabel && (publishMode || approveAndPublishMode);
    const isPublishOldVersionInPublishMode = publishMode && showPublishOldVersionLabel;
    const isMessagesForNonPublishMode = !publishMode && !approveAndPublishMode;
    const defaultPublishButtonLabel = isViewingLiveVersionUnderApproval ? messages.updateGuide : messages.publishGuide;

    let publishButtonLabel;
    let bodyMessage;

    switch (true) {
      case isPublishUpdatesInPublishMode:
        publishButtonLabel = messages.publishUpdates;
        bodyMessage = multiDomain
          ? messages.multiPublishGuideUpdatesMessage
          : messages.singlePublishGuideUpdatesMessage;
        break;

      case isPublishOldVersionInPublishMode:
        publishButtonLabel = messages.publishOldVersion;
        bodyMessage = guidePublished ? messages.publishOlderVersion : messages.publishOlderVersionWithoutLive;
        break;

      case isMessagesForNonPublishMode:
        publishButtonLabel = defaultPublishButtonLabel;
        bodyMessage = messages.unpublishLive;
        break;

      default:
        publishButtonLabel = defaultPublishButtonLabel;
        bodyMessage = multiDomain ? messages.multiPublishGuideMessage : messages.singlePublishGuideMessage;
        break;
    }

    return {publishButtonLabel, bodyMessage};
  };

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {publishGuideDialog: dialog, editGuidePage, platform} = store;
    const {hasMultiDomainEnabled: multiDomain} = platform;
    const {
      opened,
      mode,
      reset,
      publishGuide,
      unpublishGuide,
      submitGuideApprovalAndPublish,
      isDraftVersion,
      domains,
      visibleToValues,
      visibleToNames,
      updateVisibleTo,
      canChangeVisibleTo,
      canPublish,
      canSetReminder,
      expiryDate,
      setExpiryDate,
      publishing
    } = dialog;
    const {versions, guide, isUnderApproval, isViewingLiveVersionUnderApproval} = editGuidePage;
    const publishMode = mode === 'PUBLISH';
    const approveAndPublishMode = mode === 'APPROVE_AND_PUBLISH';
    const guidePublished = get(guide, 'publishStatus') === 'PUBLISHED';
    const shouldListDomains = (publishMode || approveAndPublishMode) && multiDomain;
    const version = this.getVersion(isViewingLiveVersionUnderApproval, versions);
    const showPublishUpdatesLabel = isDraftVersion && guidePublished && !isViewingLiveVersionUnderApproval;
    const showPublishOldVersionLabel = !isDraftVersion && !isUnderApproval;

    const {publishButtonLabel, bodyMessage} = this.getContentAndTitle({
      isViewingLiveVersionUnderApproval,
      multiDomain,
      showPublishUpdatesLabel,
      publishMode,
      approveAndPublishMode,
      showPublishOldVersionLabel,
      guidePublished
    });

    const mainActionMessage = publishMode || approveAndPublishMode ? publishButtonLabel : messages.unpublishGuide;

    const dialogTitle = isViewingLiveVersionUnderApproval
      ? formatMessage(messages.updateReminder)
      : formatMessage(mainActionMessage);

    return (
      <Dialog
        actions={[
          {
            label: publishing ? null : formatMessage(mainActionMessage),
            design: 'solid',
            dataCy: 'publish-guide-confirm',
            disabled: !canPublish,
            loading: publishing,
            onClick: () => {
              if (publishMode) {
                publishGuide();

                trackEvent(EVENT_TYPES.PUBLISH_GUIDE, {guideId: guide.id});
              } else if (approveAndPublishMode) {
                submitGuideApprovalAndPublish();
              } else {
                unpublishGuide();
              }
            }
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={dialogTitle}
        dialogStyle={{overflow: 'visible'}}
        bodyStyle={{overflow: 'visible'}}
      >
        <InnerWrapper>
          {!isViewingLiveVersionUnderApproval && <FormattedMessage {...bodyMessage} values={{version}} />}
          {shouldListDomains && canChangeVisibleTo && (
            <MultiDomainPicker
              dataCy="visible-to-picker"
              minSize={1}
              onChange={updateVisibleTo}
              options={domains.slice()}
              selectedValues={visibleToValues.slice()}
              onGuideLevel={true}
            />
          )}
          {shouldListDomains && !canChangeVisibleTo && (
            <ul>
              {visibleToNames.map(workspace => (
                <li key={workspace.id}>- {workspace}</li>
              ))}
            </ul>
          )}
          {canSetReminder && (
            <Field
              title={formatMessage(messages.nextReminder)}
              styles={{Title: {color: commonPalette.txBlack}}}
              inputComponent={
                <DatePicker
                  showToday={false}
                  disabledDate={current => current && current < moment().endOf('day')}
                  format={DATE_FORMAT}
                  value={expiryDate}
                  onChange={moment => setExpiryDate(moment)}
                />
              }
            />
          )}
        </InnerWrapper>
      </Dialog>
    );
  }
}

export default injectIntl(PublishGuideDialogComponent);
