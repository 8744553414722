import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';

// lodash
import find from 'lodash/find';

//messages
import messages from './messages';

//styled-components
import {
  Text,
  ThemeStepNotesIconsWrapper,
  ThemeStepNotesIconsCaptionWrapper,
  ThemeStepNotesIconsListWrapper
} from './styles';

//components
import InstructionalIconsList from 'components/InstructionalIconsList';

@inject('store')
@observer
class ThemeInstructionalIcons extends Component {
  onEditIcon = ({icon}, e) => {
    const {
      themePage: {instructionalIconsForm: form}
    } = this.props.store;

    e.preventDefault();
    const {editThemeIconDialog} = this.props.store;
    editThemeIconDialog.open({icon, form});
  };

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {themePage} = store;
    const {instructionalIconsForm: form, platformIcons} = themePage;

    // merge platform icons with theme icons (if any)
    const themeIcons = form.$('icons').values();
    const iconsList = toJS(platformIcons).map(platformIcon => {
      return {...platformIcon, ...find(themeIcons, {id: platformIcon.id})};
    });

    return (
      <ThemeStepNotesIconsWrapper>
        <ThemeStepNotesIconsCaptionWrapper>
          <Text>{formatMessage(messages.changeThemeStepNote)}</Text>
        </ThemeStepNotesIconsCaptionWrapper>

        <ThemeStepNotesIconsListWrapper>
          <InstructionalIconsList isThemeStylingView={true} icons={iconsList} onEditIcon={this.onEditIcon} />
        </ThemeStepNotesIconsListWrapper>
      </ThemeStepNotesIconsWrapper>
    );
  }
}

export default injectIntl(ThemeInstructionalIcons);
