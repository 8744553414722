import views from 'config/views';

export const getTranslateRouteAndParams = (currentGuideDefaultLanguage, availableLanguages, params) => {
  const availableTargetLanguages = availableLanguages.filter(locale => locale !== currentGuideDefaultLanguage);
  let route = getSubRoute(params);
  if (availableTargetLanguages.length === 0) {
    route = views.translateGuide;
  }
  return {
    route,
    params: {
      ...params,
      id: params.guideId,
      locale: availableTargetLanguages?.[0]
    }
  };
};

/**
 *
 * @param params An object containing the guideId, topicId, instructionId, stepId, and noteId.
 * @returns The key of the route to go to based on the presence of the ids in the params object.
 */
export const getSubRoute = params => {
  const {topicId, instructionId, stepId, noteId} = invalidateBrokenIdChain(params);
  if (noteId) return views.translateHintLocale;
  if (stepId) return views.translateStepLocale;
  if (instructionId) return views.translateInstructionLocale;
  if (topicId) return views.translateTopicLocale;
  return views.translateLocale;
};

/**
 *
 * @param params An object containing the guideId, topicId, instructionId, stepId, and noteId.
 * @description To prevent from routing the user to an invalid link, this function ensures that all no id is present without its parent id.
 * For example, if the user has a link to a note, but the note's parent step id is missing, the user will be routed to the instruction instead,
 * which is the furthest correct chain of ids (guide -> topic -> instruction).
 *
 * @returns Returns a new object with the same properties as the input object, but with the broken id chain removed.
 */
export const invalidateBrokenIdChain = params => {
  const {guideId, topicId, instructionId, stepId, noteId} = params;
  const nextParams = {guideId};
  if (topicId) nextParams.topicId = topicId;
  if (topicId && instructionId) nextParams.instructionId = instructionId;
  if (topicId && instructionId && stepId) nextParams.stepId = stepId;
  if (topicId && instructionId && stepId && noteId) nextParams.noteId = noteId;
  return nextParams;
};
