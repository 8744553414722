import {Popover} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

//styles
import {Badge, EnhancedIcon, StyledBadgeIcon, StyledIcon} from './styles';

const EnhancedIconComponent = ({badge, name, size, tooltip, tooltipPlacement, ...forwardedProps}) => {
  const hostElement = (
    <EnhancedIcon size={size} {...forwardedProps}>
      <StyledIcon name={name} size={size} />
      {badge && (badge === true ? <StyledBadgeIcon baseSize={size} /> : <Badge baseSize={size}>{badge}</Badge>)}
    </EnhancedIcon>
  );

  if (tooltip) {
    return (
      <Popover
        arrowPointAtCenter
        content={tooltip}
        overlayStyle={{maxWidth: 220}}
        placement={tooltipPlacement}
        trigger="hover"
      >
        {hostElement}
      </Popover>
    );
  }

  return hostElement;
};

EnhancedIconComponent.defaultProps = {
  badge: false,
  size: 24,
  valign: 'middle'
};

EnhancedIconComponent.propTypes = {
  badge: PropTypes.bool,
  size: PropTypes.number,
  tooltip: PropTypes.node
};

export default EnhancedIconComponent;
