import React from 'react';
import {UserSkillStatus} from './enums';
import messages from './messages';
import {injectIntl, InjectedIntlProps} from 'react-intl';
interface SkillStatusLabelProps extends InjectedIntlProps {
  status: UserSkillStatus;
}

const SkillStatusLabel: React.FC<SkillStatusLabelProps> = ({status, intl: {formatMessage}}) => {
  const getStatusLabel = () => {
    switch (status) {
      case UserSkillStatus.APPROVED:
        return formatMessage(messages.approved);
      case UserSkillStatus.COMPLETED:
        return formatMessage(messages.waitingApproval);
      case UserSkillStatus.IN_PROGRESS:
        return formatMessage(messages.inProgress);
      case UserSkillStatus.EXPIRED:
        return formatMessage(messages.expired);
      default:
        return formatMessage(messages.notStarted);
    }
  };
  return <>{getStatusLabel()}</>;
};

export default injectIntl(SkillStatusLabel);
export * from './enums';
