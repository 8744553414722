// - This component is based on https://github.com/nodejh/react-draft-wysiwyg/blob/master/src/controls/Link/index.js
// - We had an issue with the blank space been added after the link, so this component is not adding a blank space after the link
// - If the library fix this issue we can drop this one and use the default one again

import {EditorState, Modifier} from 'draft-js';
import {getSelectionText, getEntityRange, getSelectionEntity} from 'draftjs-utils';
import React, {useState, useCallback} from 'react';
import linkifyIt from 'linkify-it';

const CustomLinkOpener = ({editorState, onChange}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState('');
  const [openInNewWindow, setOpenInNewWindow] = useState(false);

  // Retrieve current values of the selected link entity
  const getCurrentValues = useCallback(() => {
    const currentEntity = editorState ? getSelectionEntity(editorState) : undefined;
    const contentState = editorState.getCurrentContent();
    const currentValues = {};

    // If there's a link entity, populate currentValues with its data and text
    if (currentEntity && contentState.getEntity(currentEntity).get('type') === 'LINK') {
      currentValues.link = {};
      const entityRange = getEntityRange(editorState, currentEntity);
      currentValues.link.target = contentState.getEntity(currentEntity).get('data').url;
      currentValues.link.targetOption = contentState.getEntity(currentEntity).get('data').targetOption;
      currentValues.link.title = entityRange && entityRange.text;
    }
    currentValues.selectionText = getSelectionText(editorState);
    return currentValues;
  }, [editorState]);

  // Add or update the link entity in the editor
  const add = useCallback(
    e => {
      e.preventDefault();
      const currentEntity = editorState ? getSelectionEntity(editorState) : undefined;
      let selection = editorState.getSelection();

      if (!selection.isCollapsed()) {
        // Adjust the selection if there's a link entity
        if (currentEntity) {
          const entityRange = getEntityRange(editorState, currentEntity);
          const isBackward = selection.getIsBackward();
          if (isBackward) {
            selection = selection.merge({
              anchorOffset: entityRange.end,
              focusOffset: entityRange.start
            });
          } else {
            selection = selection.merge({
              anchorOffset: entityRange.start,
              focusOffset: entityRange.end
            });
          }
        }
        // Create a new entity for the link with the updated URL and target option
        const entityKey = editorState
          .getCurrentContent()
          .createEntity('LINK', 'MUTABLE', {
            url,
            targetOption: openInNewWindow ? '_blank' : '_self'
          })
          .getLastCreatedEntityKey();
        // Replace the selected text with the new title text and apply the link entity
        const contentState = Modifier.replaceText(
          editorState.getCurrentContent(),
          selection,
          `${title}`,
          editorState.getCurrentInlineStyle(),
          entityKey
        );
        const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
        // Update the selection to span the full length of the new title
        const newSelectionWithFullTitle = newEditorState.getSelection().merge({
          anchorOffset: selection.getStartOffset(),
          focusOffset: selection.getStartOffset() + title.length
        });
        // Update the editor state with the new content and selection
        onChange(EditorState.forceSelection(newEditorState, newSelectionWithFullTitle));

        setIsOpen(false);
        setUrl('');
        setTitle('');
        setOpenInNewWindow(false);
      }
    },
    [editorState, onChange, url, title, openInNewWindow]
  );

  // Toggle the link modal and populate inputs with current values
  const toggle = useCallback(() => {
    const {link, selectionText} = getCurrentValues();
    setUrl(link?.target || '');
    setTitle(selectionText || '');
    setOpenInNewWindow(link?.targetOption === '_blank');
    setIsOpen(prev => !prev);
  }, [getCurrentValues]);

  const cancel = useCallback(e => {
    e.preventDefault();
    setIsOpen(false);
    setUrl('');
    setTitle('');
    setOpenInNewWindow(false);
  }, []);

  const handleChange = useCallback(e => {
    const {target} = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const {name} = target;

    if (name === 'url') {
      const links = linkifyIt().match(value);
      const linkifiedTarget = links && links[0] ? links[0].url : value;
      setUrl(linkifiedTarget);
    } else if (name === 'title') setTitle(value);
    else if (name === 'openInNewWindow') setOpenInNewWindow(value);
  }, []);

  return (
    <div className="rdw-options-wrapper rdw-link-wrapper">
      <div onClick={toggle} className="rdw-option-wrapper">
        <img
          src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjk2Ny45NUEzLjIyNiAzLjIyNiAwIDAgMCAxMS42Ny4wMDJjLS44NyAwLTEuNjg2LjMzNy0yLjI5Ny45NDhMNy4xMDUgMy4yMThBMy4yNDcgMy4yNDcgMCAwIDAgNi4yNCA2LjI0YTMuMjI1IDMuMjI1IDAgMCAwLTMuMDIyLjg2NUwuOTUgOS4zNzNhMy4yNTMgMy4yNTMgMCAwIDAgMCA0LjU5NCAzLjIyNiAzLjIyNiAwIDAgMCAyLjI5Ny45NDhjLjg3IDAgMS42ODYtLjMzNiAyLjI5OC0uOTQ4TDcuODEyIDExLjdhMy4yNDcgMy4yNDcgMCAwIDAgLjg2NS0zLjAyMyAzLjIyNSAzLjIyNSAwIDAgMCAzLjAyMi0uODY1bDIuMjY4LTIuMjY3YTMuMjUyIDMuMjUyIDAgMCAwIDAtNC41OTV6TTcuMTA1IDEwLjk5M0w0LjgzNyAxMy4yNmEyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NSAyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LS42NTUgMi4yNTIgMi4yNTIgMCAwIDEgMC0zLjE4bDIuMjY4LTIuMjY4YTIuMjMyIDIuMjMyIDAgMCAxIDEuNTktLjY1NWMuNDMgMCAuODQxLjEyIDEuMTk1LjM0M0w0Ljc3MiA5LjQzOGEuNS41IDAgMSAwIC43MDcuNzA3bDEuOTM5LTEuOTM4Yy41NDUuODY4LjQ0MiAyLjAzLS4zMTMgMi43ODV6bTYuMTU1LTYuMTU1bC0yLjI2OCAyLjI2N2EyLjIzMyAyLjIzMyAwIDAgMS0xLjU5LjY1NWMtLjQzMSAwLS44NDEtLjEyLTEuMTk1LS4zNDNsMS45MzgtMS45MzhhLjUuNSAwIDEgMC0uNzA3LS43MDdMNy40OTkgNi43MWEyLjI1MiAyLjI1MiAwIDAgMSAuMzEzLTIuNzg1bDIuMjY3LTIuMjY4YTIuMjMzIDIuMjMzIDAgMCAxIDEuNTktLjY1NSAyLjIzMyAyLjIzMyAwIDAgMSAyLjI0NiAyLjI0NWMwIC42MDMtLjIzMiAxLjE2OC0uNjU1IDEuNTl6IiBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4="
          alt="link"
        />
      </div>
      {isOpen ? (
        <div style={{zIndex: '100', background: 'transparent', width: '100px', height: '100px'}}>
          <div className="rdw-link-modal">
            <label className="rdw-link-modal-label" htmlFor="linkTitle">
              Link Title
            </label>
            <input id="linkTitle" className="rdw-link-modal-input" name="title" value={title} onChange={handleChange} />
            <label className="rdw-link-modal-label" htmlFor="linkTarget">
              Link Target
            </label>
            <input id="linkTarget" className="rdw-link-modal-input" name="url" value={url} onChange={handleChange} />
            <label className="rdw-link-modal-target-option" htmlFor="openInNewWindow">
              <input
                id="openInNewWindow"
                type="checkbox"
                name="openInNewWindow"
                onChange={handleChange}
                checked={openInNewWindow}
              />
              <span>Open link in new window</span>
            </label>
            <span className="rdw-link-modal-buttonsection">
              <button className="rdw-link-modal-btn" onClick={add}>
                Add
              </button>
              <button className="rdw-link-modal-btn" onClick={cancel}>
                Cancel
              </button>
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CustomLinkOpener;
