import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//styled-components
import {WrapperDiv, InfoText} from './styles';

// queries
import {DomainsLite} from 'api/domain/queries';

// options
import {domainsOptions} from 'api/domain/query-options';

// components
import Field from 'ui-components/Field';
import MultiDomainPicker from 'components/MultiDomainPicker';

// messages
import messages from './messages';

@inject('store')
@graphql(DomainsLite, domainsOptions)
@observer
class NewConnectTeamToWorkspace extends Component {
  componentDidMount() {
    const {domainsQuery, store} = this.props;
    const {domains} = domainsQuery;
    const {teamPage} = store;

    if (domains) {
      teamPage.pickDefaultDomain(domains);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {domainsQuery, store} = nextProps;
    const {domains, loading} = domainsQuery;
    const {teamPage} = store;

    if (!loading && domains !== this.props.domainsQuery.domains) {
      teamPage.pickDefaultDomain(domains);
    }
  }

  render() {
    const {
      domainsQuery: {domains = []},
      intl: {formatMessage},
      store: {
        teamPage: {teamWorkspaceForm}
      }
    } = this.props;
    const {giveTeamAccess} = messages;

    return (
      <WrapperDiv>
        <InfoText>{formatMessage(giveTeamAccess)}</InfoText>
        <Field
          size="small"
          inputComponent={<MultiDomainPicker options={domains} form={teamWorkspaceForm} field="domainIds" />}
        />
      </WrapperDiv>
    );
  }
}

export default injectIntl(NewConnectTeamToWorkspace);
