import styled from 'styled-components';
import Button from 'ui-components/Button';

export const PickerRow = styled.div`
  display: flex;
  align-items: center;
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'remove',
  solidIcon: false
})`
  margin-left: 30px;
`;
