import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 32px;
  font-weight: 400;
  line-height: 1.21875;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
  padding-bottom: 30px;
`;

export const SpinnerContainer = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LiveBoardContainer = styled.div`
  display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
  & > iframe {
    min-height: 720px;
  }
`;

export const StyledContainer = styled.div`
  flex: 1;
  max-width: 1020px;
  margin: 30px auto;
  padding: 0 ${p => (p.hideTitle ? '0' : '20px')};
`;

export const WrapperContainer = styled.div`
  display: flex;
`;
