import {defineMessages} from 'react-intl';

const messages = defineMessages({
  updateSuccess: {
    id: 'StepNotesIconDialog.updateSuccess',
    defaultMessage: 'Step Note succesfully updated'
  },
  updateFailure: {
    id: 'StepNotesIconDialog.updateFailure',
    defaultMessage: 'Failed to edit the Step Note'
  },
  uploadSuccess: {
    id: 'StepNotesIconDialog.uploadSuccess',
    defaultMessage: 'Step Note Icon succesfully uploaded'
  },
  uploadFailure: {
    id: 'StepNotesIconDialog.uploadFailure',
    defaultMessage: 'Failed to upload the Step Note Icon'
  },
  toggleIconVisibilitySuccess: {
    id: 'StepNotesIconsList.toggleIconVisibilitySuccess',
    defaultMessage: 'Step Note visibility changed successfully'
  },
  toggleIconVisibilityFailure: {
    id: 'StepNotesIconsList.toggleIconVisibilityFailure',
    defaultMessage: 'Failed to change the visibility of the Step Note'
  },
  stepNoteIcon: {
    id: 'StepNotesIconsList.stepNoteIcon',
    defaultMessage: 'Icon'
  },
  stepNoteName: {
    id: 'StepNotesIconsList.stepNoteName',
    defaultMessage: 'Name'
  },
  labelNotTranslated: {
    id: 'StepNotesIconsList.labelNotTranslated',
    defaultMessage: 'Not translated'
  },
  customTag: {
    id: 'StepNotesIconsList.customTag',
    defaultMessage: 'Custom'
  },
  tipLabel: {
    id: 'commons.label.tip',
    defaultMessage: 'Tip'
  },
  fixLabel: {
    id: 'commons.label.fix',
    defaultMessage: 'Fix'
  },
  warningLabel: {
    id: 'commons.label.warning',
    defaultMessage: 'Warning'
  },
  customLabel: {
    id: 'commons.label.custom',
    defaultMessage: 'Alternative'
  }
});

export default messages;
