import React from 'react';
import {Observer, inject} from 'mobx-react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {client} from 'utils/apollo-client';

import {FormattedMessage} from 'components/FormattedComponents';
import ExpandableRowRecursiveTable from 'components/ExpandableRowRecursiveTable';
import messages from './messages';
import {AllChildrenTeamByParentId} from 'api/team/queries';
import {allTeamChildrenOptions} from 'api/team/query-options';
import {canManageTeam} from './utils';
import {find, get} from 'lodash';
import {TEAM_MEMBER_ROLE} from 'shared/enums';
import views from 'config/views';

import {ArchiveTeam} from 'api/team/mutations';
import ArchiveTeamReassign from './ArchiveTeamReassign';
import {commonPalette} from 'shared/styles/palette';
import {refreshAllParentTeams, refreshAllTeamChildren} from 'api/team/refresh-queries';
import {refreshProfile} from 'api/user/refresh-queries';

import LoadingMessage from 'components/LoadingMessage';
import {LoadingWrapper, TableOverlay} from './styles';

const LoadingComponent = () => {
  return (
    <>
      <LoadingWrapper>
        <LoadingMessage>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      </LoadingWrapper>
      <TableOverlay />
    </>
  );
};

type TeamsListContainerProps = {
  loading: boolean;
  error: any;
  intl: InjectedIntl;
  teams: any[];
  store: any;
  searchTerm: string;
};

const TeamsListContainer = ({loading, error, intl, teams = [], store, searchTerm}: TeamsListContainerProps) => {
  const {formatMessage} = intl;

  const {
    auth: {user},
    platform: {
      name: platformName,
      developmentFeatureFlags: {teamLevels},
      hasSkillsEnabled
    }
  } = store;

  const canSeeLevels = hasSkillsEnabled && teamLevels;

  if (loading) {
    return <LoadingComponent />;
  }

  if (error) {
    return formatMessage(messages.error);
  }

  const subTeamColumn = canSeeLevels
    ? [
        {
          key: 1,
          title: formatMessage(messages.subTeams),
          dataField: 'children',
          platformData: teams.length,
          dataCy: 'sub-teams-column'
        }
      ]
    : undefined;

  const noDataMessage = searchTerm
    ? formatMessage(messages.noFilteredTeams, {searchTerm})
    : formatMessage(messages.noTeams);

  const handleClickOnSettings = id => {
    const goToEditTeam = id => {
      store.router.goTo(views.editTeam, {id, tab: 'name'});
    };

    const goToTeamDetails = id => {
      store.router.goTo(views.viewTeamDetails, {id, tab: 'users'});
    };

    const {
      auth: {
        user: {isIPA, teams, isPlatformAdmin}
      }
    } = store;

    const isTeamAdmin =
      get(
        find(teams, userTeam => get(userTeam, 'team.id') === id),
        'role'
      ) === TEAM_MEMBER_ROLE.ADMIN;

    const shouldGoToEditTeam = isIPA || isPlatformAdmin || isTeamAdmin;

    shouldGoToEditTeam ? goToEditTeam(id) : goToTeamDetails(id);
  };

  const archive = params => {
    const {reassignGuideDialog} = store;

    return client.mutate({
      mutation: ArchiveTeam,
      refetchQueries: [refreshProfile(), refreshAllParentTeams(), refreshAllTeamChildren()],
      variables: {
        ...params,
        ...(reassignGuideDialog.reassignTeamContent
          ? {
              reassignToUserId: reassignGuideDialog.newAssigneeId,
              reassignToTeamId: reassignGuideDialog.selectedTeamId,
              comment: reassignGuideDialog.comment
            }
          : {})
      }
    });
  };

  const archiveTeam = (team, formatMessage) => {
    const {reassignGuideDialog, archiveDialog} = store;

    const body = (
      <Observer>
        {() => (
          <FormattedMessage
            {...(reassignGuideDialog.teams.length
              ? messages.archiveTeamContentBody
              : messages.archiveTeamBodyNoReassign)}
          />
        )}
      </Observer>
    );

    const translations = {
      action: formatMessage(messages.deleteTeam),
      confirmation: formatMessage(messages.deleteTeamConfirm),
      body,
      archiveSuccess: formatMessage(messages.archiveTeamSuccess),
      archiveFailure: formatMessage(messages.archiveTeamFailure)
    };

    archiveDialog.open({
      type: 'team',
      params: {id: team.id},
      mutation: () => archive({id: team.id}),
      translations,
      style: {
        bodyStyle: {overflow: 'initial'},
        dialogStyle: {overflow: 'auto'},
        itemPrimary: {color: commonPalette.txBlack, fontSize: 14}
      },
      itemPrimary: formatMessage(messages.team),
      itemSecondary: team.name,
      content: <ArchiveTeamReassign team={team} />
    });
  };

  return (
    <ExpandableRowRecursiveTable
      title={formatMessage(messages.teamName)}
      onClick={handleClickOnSettings}
      data={teams}
      noDataMessage={noDataMessage}
      childrenQuery={AllChildrenTeamByParentId}
      childrenQueryOptions={allTeamChildrenOptions}
      actionIcon="remove"
      action={i => archiveTeam(i, formatMessage)}
      actionPopover={formatMessage(messages.deleteTeam)}
      showAction={team => canManageTeam(user, team)}
      queryOptions={team => ({teamId: team.id})}
      sortable={true}
      extraColumns={subTeamColumn}
      platformName={platformName}
      enableExpand={canSeeLevels && !searchTerm}
      indexed={true}
    />
  );
};

export default injectIntl(
  inject('store')(TeamsListContainer as React.FunctionComponent<Omit<TeamsListContainerProps, 'store'>>)
);
