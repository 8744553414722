import React from 'react';
import {AUTO_TRANSLATE_DIALOGS_MAP} from 'stores/dialogs/auto-translation-dialog';
import SelectLanguageDialogContent from './SelectLanguageDialogContent';
import messages from './messages';

export const getSelectLanguageDialogConfig = ({autoTranslationDialog}, formatMessage) => ({
  title: formatMessage(messages.chooseLanguage),
  content: <SelectLanguageDialogContent formatMessage={formatMessage} />,
  actionsProps: {
    actions: [
      {
        loading: autoTranslationDialog.loading,
        disabled: !autoTranslationDialog.selectedLanguageToTranslate || autoTranslationDialog.loading,
        label: 'Auto-translate',
        onClick: () => {
          autoTranslationDialog.startAutoTranslation();
          autoTranslationDialog.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translationInProgress);
        }
      }
    ],
    onCancel: () => {
      autoTranslationDialog.reset();
    }
  },
  overflow: 'unset',
  overflowBody: 'unset',
  customHeaderStyle: {paddingBottom: 20}
});
