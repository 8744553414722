import gql from 'graphql-tag';

export const CreateGuide = gql`
  mutation createGuide($guide: GuideCreate!) {
    createGuide(guide: $guide) {
      id
    }
  }
`;

export const UpdateGuide = gql`
  mutation updateGuide($id: String!, $guide: GuideUpdate!) {
    updateGuide(id: $id, guide: $guide) {
      id
    }
  }
`;

export const ArchiveGuide = gql`
  mutation archiveGuide($id: String!) {
    archiveGuide(id: $id) {
      archived
    }
  }
`;

export const PublishGuide = gql`
  mutation publishGuide($id: String!, $versionId: String, $domains: [String], $expiryDate: Date) {
    publishGuide(id: $id, versionId: $versionId, domains: $domains, expiryDate: $expiryDate) {
      publishStatus
    }
  }
`;

export const RequestGuideApproval = gql`
  mutation requestGuideApproval($id: String!, $versionId: String, $comment: String) {
    requestGuideApproval(id: $id, versionId: $versionId, comment: $comment) {
      result
    }
  }
`;

export const SubmitGuideApprovalReview = gql`
  mutation submitGuideApprovalReview($id: String!, $review: GuideReview!, $domains: [String], $expiryDate: Date) {
    submitGuideApprovalReview(id: $id, review: $review, domains: $domains, expiryDate: $expiryDate) {
      result
    }
  }
`;

export const SubmitLiveVersionReview = gql`
  mutation submitLiveVersionReview($id: String!, $review: GuideReview!, $domains: [String], $expiryDate: Date) {
    submitLiveVersionReview(id: $id, review: $review, domains: $domains, expiryDate: $expiryDate) {
      result
    }
  }
`;

export const WithdrawGuideApproval = gql`
  mutation withdrawGuideApproval($id: String!) {
    withdrawGuideApproval(id: $id) {
      result
    }
  }
`;

export const SetGuideToDraft = gql`
  mutation setGuideToDraft($id: String!) {
    setGuideToDraft(id: $id) {
      publishStatus
    }
  }
`;

export const CopyToGuide = gql`
  mutation cloneGuide($id: String!, $teamId: String!) {
    cloneGuide(id: $id, teamId: $teamId) {
      id
    }
  }
`;

export const ReassignGuide = gql`
  mutation reassignGuide($id: String!, $newAssigneeId: String!, $newTeamId: String!, $comment: String!) {
    reassignGuide(id: $id, newAssigneeId: $newAssigneeId, newTeamId: $newTeamId, comment: $comment) {
      result
    }
  }
`;

export const AddTranslationsToGuide = gql`
  mutation addTranslationsToGuide($id: String!, $translations: [String]!) {
    addTranslationsToGuide(id: $id, translations: $translations) {
      result
    }
  }
`;

export const RemoveTranslationFromGuide = gql`
  mutation removeTranslationFromGuide($id: String!, $translation: String!) {
    removeTranslationFromGuide(id: $id, translation: $translation) {
      result
    }
  }
`;

export const UpdateGuideMedia = gql`
  mutation updateGuideMedia($id: String!, $locale: String!, $mediaId: String) {
    updateGuideMedia(id: $id, mediaId: $mediaId, locale: $locale) {
      id
    }
  }
`;

export const RestoreVersion = gql`
  mutation restoreVersion($guideId: String!, $versionId: String!) {
    restoreVersion(guideId: $guideId, versionId: $versionId)
  }
`;

export const ShareToBeekeeper = gql`
  mutation shareToBeekeeper($guideId: String!, $instructionId: String, $domainSlug: String) {
    shareToBeekeeper(guideId: $guideId, instructionId: $instructionId, domainSlug: $domainSlug) {
      result
    }
  }
`;
