import {action, observable, computed, makeObservable} from 'mobx';
import omit from 'lodash/omit';

//helpers
import notification from 'utils/notification-utils';
import store from 'stores/store';

//form
import NewTopicForm from 'stores/forms/new-topic-form';

class NewTopicPage {
  form = new NewTopicForm();
  //observables
  @observable loading = false;
  @observable opened = false;
  @observable translations = {};
  droppedItem = null;

  //actions
  @action reset = () => {
    this.form.reset();
    this.setLoading(false);
    this.setOpened(false);
  };

  @action setOpened = opened => (this.opened = opened);

  @action setDroppedItem = item => (this.droppedItem = item);

  @action setLoading = val => (this.loading = val);

  @action submit = createTopicMutation => {
    this.setLoading(true);
    const topic = omit(this.form.values(), ['hide']);
    topic.guideId = store.router.params.id;

    createTopicMutation({topic}).then(() => {
      this.reset();
      notification.success(this.translations.createSuccess);
    });
  };

  @action cancel = () => {
    this.reset();
  };

  @action setTranslations = translations => {
    this.translations = translations;
  };

  constructor() {
    makeObservable(this);
  }

  //computed
  @computed get saveButtonEnabled() {
    return this.loading === false && this.form.isValid;
  }
}

export default NewTopicPage;
