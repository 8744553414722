import {defineMessages} from 'react-intl';

const messages = defineMessages({
  nothingToSelect: {
    id: 'common.forms.nothingToSelect',
    defaultMessage: 'Nothing to select'
  }
});

export default messages;
