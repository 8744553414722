import {defineMessages} from 'react-intl';

const messages = defineMessages({
  image: {
    id: 'image',
    defaultMessage: 'Image'
  },
  video: {
    id: 'common.video',
    defaultMessage: 'Video'
  }
});

export default messages;
