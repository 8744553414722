import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {toJS} from 'mobx';
import {observer, inject} from 'mobx-react';
import {injectIntl} from 'react-intl';

//helpers
import notification from 'utils/notification-utils';
import {commonPalette} from 'shared/styles/palette';

//components
import MediaUpload from './MediaUpload';
import MediaGallery from './MediaGallery';
import MediaActions from './MediaActions';
import MediaPreview from './MediaPreview';
import MediaShapes from './MediaShapes';
import MediaProperties from './MediaProperties';
import MediaEdit from './MediaEdit';
import MediaOverlay from './MediaOverlay';
import Dialog from 'ui-components/Dialog';
import {FormattedMessage} from 'shared/components/FormattedComponents';

// TODO: Remove next block. This will be done on the preview component
import {MEDIA_GALLERY_STEPS, MEDIA_PREVIEW_TYPE, MEDIA_GALLERY_FILTER_LEVEL} from 'config/enums';

import {InnerWrapper, LeftSide, RightSide, Note, Label, Content, ButtonGroup, UploadContentWrap} from './styles';

//messages
import messages from './messages';

//Queries
import {MediaGallery as MediaGalleryQuery, MediaGalleryPaginated} from 'api/media/queries';
import {getSingleMediaOptions, getMediaGalleryPaginatedOptions} from 'api/media/query-options';

// Mutations
import {EditMedia} from '../../api/media/mutations';
import {editMediaOptions} from 'api/media/mutation-options';

@inject('store')
@graphql(MediaGalleryPaginated, getMediaGalleryPaginatedOptions)
@graphql(MediaGalleryQuery, getSingleMediaOptions)
@graphql(EditMedia, editMediaOptions)
@observer
class MediaPickerDialogComponent extends Component {
  componentDidMount() {
    window.addEventListener('keydown', this.handleBackSpace);
  }

  componentWillUnmount() {
    const {
      store: {mediaPickerDialog}
    } = this.props;

    mediaPickerDialog.reset();
    mediaPickerDialog.setCursor(null);
    window.removeEventListener('keydown', this.handleBackSpace);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      mediaGalleryPaginatedQuery: {mediaPaginated, loading: isMediaPaginatedLoading, refetch: mediaPaginatedRefetch},
      mediaGalleryQuery: {media, loading: isSingleMediaLoading} = {},
      store: {mediaPickerDialog}
    } = nextProps;

    if (mediaPaginated) {
      const {fetchingNewImages} = mediaPickerDialog;
      const {cursor, results} = mediaPaginated;

      const isMediaLoading = isMediaPaginatedLoading || fetchingNewImages;

      if (!isMediaLoading && results) {
        mediaPickerDialog.setMediaGallery(results, mediaPaginatedRefetch);
        mediaPickerDialog.setCursor(cursor);
      }
    }

    if (this.props.mediaGalleryQuery.media !== nextProps.mediaGalleryQuery.media) {
      if (!isSingleMediaLoading && media && media[0]) {
        mediaPickerDialog.setSelectedMedia(media[0]);
      }
    }
  }

  handleBackSpace = event => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      const {store} = this.props;
      const {mediaPickerDialog} = store;
      mediaPickerDialog.handleBackSpace();
    }
  };

  fetchMore = () => {
    const {
      mediaGalleryPaginatedQuery,
      store: {mediaPickerDialog}
    } = this.props;
    const {fetchMore, loading} = mediaGalleryPaginatedQuery;

    if (loading || !mediaPickerDialog.cursor || mediaPickerDialog.fetchingNewImages) {
      return;
    }

    if (!mediaPickerDialog.fetchingNewImages) {
      mediaPickerDialog.setFetchingNewImages(true);
    }

    fetchMore({
      variables: {cursor: mediaPickerDialog.cursor},
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      updateQuery: (previousResult, {fetchMoreResult}) => {
        const {cursor, results} = fetchMoreResult.mediaPaginated;

        mediaPickerDialog.setCursor(cursor);
        mediaPickerDialog.setFetchingNewImages(false);

        const currentMediaGallery = toJS(mediaPickerDialog.mediaGallery);

        return results.length
          ? {
              mediaPaginated: {
                __typename: fetchMoreResult.mediaPaginated.__typename,
                results: [...currentMediaGallery, ...results],
                cursor
              }
            }
          : previousResult;
      }
    });
  };

  changeLevel = level => {
    const {
      store: {router, mediaPickerDialog},
      mediaGalleryQuery: {refetch}
    } = this.props;

    mediaPickerDialog.setGalleryLevel(level);

    if (level === MEDIA_GALLERY_FILTER_LEVEL.GUIDE) {
      refetch({guideId: router.params.id, instructionId: undefined});
    } else if (level === MEDIA_GALLERY_FILTER_LEVEL.INSTRUCTION) {
      refetch({guideId: undefined, instructionId: mediaPickerDialog.instructionId});
    }
  };

  updateMedia = () => {
    const {
      intl: {formatMessage},
      store: {mediaPickerDialog: dialog}
    } = this.props;
    const {selectedMedia, updateMedia, showUpdateNotifications} = dialog;
    const translations = {
      success: formatMessage(messages.success),
      error: formatMessage(messages.error),
      saveMessageSuccess: formatMessage(messages.mediaSaveSuccess),
      mediaSaveError: formatMessage(messages.mediaSaveError)
    };

    const updateMediaSuccess = () => {
      notification.success(translations.saveMessageSuccess);
      let unassignedMedia = JSON.parse(window.localStorage.getItem('unassignedMedia'));
      for (const media of unassignedMedia) {
        if (media?.id === selectedMedia?.id) {
          unassignedMedia = unassignedMedia.filter(item => item.id !== media.id);
          window.localStorage.setItem('unassignedMedia', JSON.stringify(unassignedMedia));
        }
      }
    };

    const updateMediaFailure = () => {
      notification.error(translations.mediaSaveError);
    };

    updateMedia(selectedMedia).then(
      showUpdateNotifications && updateMediaSuccess,
      showUpdateNotifications && updateMediaFailure
    );

    dialog.propagateSelectedMedia();
  };

  syncSelectedMedia = async () => {
    const {
      store: {mediaPickerDialog}
    } = this.props;

    const {selectedMedia} = mediaPickerDialog;

    if (!selectedMedia) {
      return;
    }

    const mediaItem = await mediaPickerDialog.fetchMediaGalleryItemById(selectedMedia.id);
    mediaPickerDialog.setSelectedMedia(mediaItem);
  };

  ejectMediaManipulationView = async () => {
    const {
      store: {mediaPickerDialog: dialog}
    } = this.props;

    await this.syncSelectedMedia();

    if (dialog.overlay) {
      dialog.setPreviewType(MEDIA_PREVIEW_TYPE.STEP_OVERVIEW);
    }

    dialog.setStep(MEDIA_GALLERY_STEPS.UPLOAD);
  };

  render() {
    const {
      intl: {formatMessage},
      editMedia,
      store: {mediaPickerDialog: dialog},
      mediaGalleryPaginatedQuery
    } = this.props;
    const {selectedMedia, updateMedia, resetToOriginalMedia, propagateSelectedMedia} = dialog;

    const buttons = (dialog.edit || dialog.overlay) && (
      <ButtonGroup
        primary={{
          label: formatMessage(messages.save),
          onClick: () => dialog.saveMedia(editMedia, this.ejectMediaManipulationView)
        }}
        secondary={{
          label: formatMessage(messages.cancel),
          onClick: this.ejectMediaManipulationView
        }}
      />
    );

    return (
      <Dialog
        actions={false}
        bodyStyle={{height: '100%', padding: 0, color: commonPalette.txBlack, marginRight: 0}}
        dialogStyle={{height: '100%', padding: 0}}
        isOpen={dialog.opened}
        onCancel={resetToOriginalMedia}
        size="large"
      >
        <InnerWrapper>
          <LeftSide>
            <Content>
              <Label>
                <FormattedMessage {...this.leftLabelMessage} />
                {dialog.overlay && (
                  <Note>
                    <FormattedMessage {...messages.visibleOnStepsAndInstructions} />
                  </Note>
                )}
              </Label>
              {dialog.edit && <MediaEdit media={selectedMedia} />}
              {dialog.overlay && <MediaOverlay media={selectedMedia} />}
              {dialog.upload && (
                <UploadContentWrap>
                  <MediaUpload />
                  <MediaGallery
                    mediaGalleryPaginatedQuery={mediaGalleryPaginatedQuery}
                    fetchMore={this.fetchMore}
                    changeLevel={this.changeLevel}
                  />
                </UploadContentWrap>
              )}
            </Content>
            {dialog.upload && (
              <ButtonGroup
                primary={{
                  label: dialog.loading ? '' : updateMedia ? formatMessage(messages.save) : formatMessage(messages.add),
                  onClick: updateMedia ? this.updateMedia : propagateSelectedMedia,
                  disabled: dialog.loading,
                  loading: dialog.loading
                }}
                secondary={{
                  label: formatMessage(messages.cancel),
                  onClick: resetToOriginalMedia,
                  disabled: dialog.loading
                }}
              />
            )}
            {buttons}
          </LeftSide>
          <RightSide>
            <Content>
              <Label>
                <FormattedMessage {...this.rightLabelMessage} />
              </Label>
              {dialog.overlay ? <MediaShapes /> : <MediaPreview />}
              {dialog.upload && <MediaProperties selectedId={selectedMedia?.id} />}
              {dialog.upload && <MediaActions editMediaMutation={editMedia} />}
            </Content>
          </RightSide>
        </InnerWrapper>
      </Dialog>
    );
  }

  get leftLabelMessage() {
    const {
      store: {mediaPickerDialog: dialog}
    } = this.props;

    if (dialog.edit) {
      return messages.imageEdit;
    }

    if (dialog.overlay) {
      return messages.imageOverlay;
    }

    return messages.selectImage;
  }

  get rightLabelMessage() {
    const {
      store: {mediaPickerDialog: dialog}
    } = this.props;

    if (!dialog.upload) {
      return dialog.overlay ? messages.shapes : messages.imagePreview;
    }

    return messages.details;
  }
}

export default injectIntl(MediaPickerDialogComponent);
