import gql from 'graphql-tag';

export const CreateAlertRecipientMutation = gql`
  mutation createAlertRecipient($alertRecipient: AlertRecipientInput!) {
    createAlertRecipient(alertRecipient: $alertRecipient) {
      id
    }
  }
`;

export const EditAlertRecipientMutation = gql`
  mutation updateAlertRecipient($alertRecipient: AlertRecipientEditInput!) {
    updateAlertRecipient(alertRecipient: $alertRecipient) {
      result
      error
    }
  }
`;

export const UpdateChecklistAlertRecipientsMutation = gql`
  mutation updateChecklistAlertRecipients($checklistId: String!, $alertRecipientIds: [String!]!) {
    updateChecklistAlertRecipients(checklistId: $checklistId, alertRecipientIds: $alertRecipientIds) {
      result
    }
  }
`;
