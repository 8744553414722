import {defineMessages} from 'react-intl';

const messages = defineMessages({
  defaultTopic: {
    id: 'EditGuide.defaultTopic',
    defaultMessage: 'Instructions'
  },
  instructionCount: {
    id: 'EditGuide.instructionCount',
    defaultMessage: '{count, number} {count, plural, one {instruction} other {instructions}}'
  }
});

export default messages;
