import {defineMessages} from 'react-intl';

const messages = defineMessages({
  useSuggestion: {
    id: 'useSuggestion',
    defaultMessage: 'Use suggestion'
  },
  keepOriginal: {
    id: 'keepOriginal',
    defaultMessage: 'Keep original'
  },
  userVerbPlusNoun: {
    id: 'userVerbPlusNoun',
    defaultMessage: 'Use verb + noun. Add enough details'
  },
  suggestion: {
    id: 'suggestion',
    defaultMessage: 'Suggestion'
  },
  original: {
    id: 'original',
    defaultMessage: 'Original'
  },
  processing: {
    id: 'processing',
    defaultMessage: 'Processing...'
  },
  reviewingYourText: {
    id: 'reviewingYourText',
    defaultMessage: 'Reviewing your text'
  },
  createStepSuccess: {
    id: 'EditStep.createSuccess',
    defaultMessage: 'Step has been created.'
  },
  createCheckSuccess: {
    id: 'createCheckSuccess',
    defaultMessage: 'Check has been created.'
  },
  createStepError: {
    id: 'EditStep.createError',
    defaultMessage: 'Error creating step.'
  },
  createCheckError: {
    id: 'createCheckError',
    defaultMessage: 'Error creating check.'
  },
  saveStepSucess: {
    id: 'saveStepSucess',
    defaultMessage: 'Step suggestions saved'
  },
  saveCheckSucess: {
    id: 'saveCheckSucess',
    defaultMessage: 'Check suggestions saved'
  },
  updateStepError: {
    id: 'EditStep.updateError',
    defaultMessage: 'Error updating step.'
  },
  updateCheckError: {
    id: 'updateCheckError',
    defaultMessage: 'Error updating check.'
  },
  ok: {
    id: 'common.actions.okay',
    defaultMessage: 'Okay'
  },
  noSuggestion: {
    id: 'noSuggestion',
    defaultMessage: 'No Suggestion'
  },
  looksGreat: {
    id: 'looksGreat',
    defaultMessage: 'Looks great. No need for improvements.'
  },
  textImprovementSuggestion: {
    id: 'textImprovementSuggestion',
    defaultMessage: 'Text Improvement Suggestion'
  }
});

export default messages;
