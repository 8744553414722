import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Popover} from 'antd';
import {inject, observer} from 'mobx-react';

import views from 'config/views';

import messages from './messages';
import {
  GuideTitleSection,
  GuideInfoWrapper,
  StyledExpandIcon,
  StyledCollapseIcon,
  InstructionsLengthWrapper,
  InstructionWrapper,
  StyledLink
} from './styles';

@inject('store')
@observer
class GuideExpander extends Component {
  state = {
    isExpanded: false
  };

  toggleIsExpanded = e => {
    if (!e.target.getAttribute('href')) {
      this.setState(prevState => ({isExpanded: !prevState.isExpanded}));
    }
  };

  render() {
    const {
      guide: {id, title, topics},
      intl: {formatMessage}
    } = this.props;
    const {isExpanded} = this.state;

    const instructions = [];
    topics.forEach(topic => {
      topic.instructions.forEach(instruction => instructions.push({...instruction, guideId: id, topicId: topic.id}));
    });

    return (
      <div>
        <GuideTitleSection onClick={this.toggleIsExpanded}>
          <Popover content={formatMessage(messages.guideHover)} arrowPointAtCenter placement={'top'}>
            <StyledLink href={views.editGuide.replaceUrlParams({id})} target="_blank">
              {title}
            </StyledLink>
          </Popover>
          <GuideInfoWrapper>
            <InstructionsLengthWrapper>
              {formatMessage(messages.instructionsLengthPlural, {count: instructions.length})}
            </InstructionsLengthWrapper>
            {isExpanded ? <StyledExpandIcon /> : <StyledCollapseIcon />}
          </GuideInfoWrapper>
        </GuideTitleSection>
        {isExpanded &&
          instructions.map(({id: instructionId, guideId, topicId, title: instructionTitle}) => (
            <InstructionWrapper key={instructionId}>
              <Popover content={formatMessage(messages.instructionHover)} arrowPointAtCenter placement={'top'}>
                <StyledLink
                  href={views.editInstruction.replaceUrlParams({
                    id: guideId,
                    topicId: topicId,
                    instructionId
                  })}
                  target="_blank"
                >
                  {instructionTitle}
                </StyledLink>
              </Popover>
            </InstructionWrapper>
          ))}
      </div>
    );
  }
}

export default injectIntl(GuideExpander);
