import React from 'react';
import {action, computed, observable, makeObservable} from 'mobx';
import Steps from 'stores/models/steps';
import GeneralInformation from 'views/CreateLearningActivity/generalInformation';
import notification from 'utils/notification-utils';
import {form} from '../forms/learning-activity-form';
import store from 'stores/store';
import views from 'config/views';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import {smartTrim} from 'utils/validation-utils';

class LearningActivityGeneralInfo {
  @observable stepStore;
  @observable translations = {};
  @observable newLearningActivityForm = form;
  @observable validGuideId = true;
  @observable editLearningId = null;
  @observable skillCount = 0;
  @observable isSaveDialogOpen = false;
  @observable workspaces = [];
  showSaveButton = true;
  isNewLearningActivityFormEmpty = true;
  @observable previousPage = '';
  formFields = ['title', 'content', 'resourceUrl', 'tagsIds'];
  @observable initialForm = {};
  @observable selectedTagsIds = [];
  @observable localSelectedTags = [];
  @observable filterSelectedTagsIds = [];
  @observable filterLocalSelectedTags = [];
  @observable skillProfilesCount = 0;
  @observable isDeleteDialogOpen = false;
  @observable learningActivityToBeDeleted = {};

  constructor() {
    makeObservable(this);
    this.setSteps(true);
  }

  setTranslations = translations => {
    this.translations = translations;
  };

  @computed
  get canSubmit() {
    return this.newLearningActivityForm.isValid && this.formHasChanges;
  }

  @computed
  get formHasChanges() {
    const formValues = omitBy(pick(this.newLearningActivityForm.values(), this.formFields), item => !item);
    const initialFormValues = omitBy(pick(this.initialForm, this.formFields), item => !item);
    return !isEqual(formValues, initialFormValues);
  }

  @computed
  get onlyTagsChanged() {
    const formValues = omitBy(pick(this.newLearningActivityForm.values(), this.formFields), item => !item);
    const initialFormValues = omitBy(pick(this.initialForm, this.formFields), item => !item);
    const changedObjects = Object.keys(formValues).reduce((diff, key) => {
      if (formValues[key] === initialFormValues[key]) return diff;
      return {
        ...diff,
        [key]: initialFormValues[key]
      };
    }, {});
    const changedFields = this.formFields.filter(field => changedObjects[field]);
    return changedFields.length === 1 && changedFields.includes('tagsIds');
  }

  @action
  setLearningActivityToBeDeleted = learningActivity => {
    this.learningActivityToBeDeleted = learningActivity;
  };

  @action
  setSkillProfilesCount = count => {
    this.skillProfilesCount = count;
  };

  @action
  setIsDeleteDialogOpen = val => {
    this.isDeleteDialogOpen = val;
  };

  @action
  setSelectedTags = tags => {
    this.selectedTagsIds = tags;
  };

  @action
  setLocalSelectedTags = tags => {
    this.localSelectedTags = tags;
    this.newLearningActivityForm.update({
      tagsIds: tags.map(tag => tag.id)
    });
  };

  @action
  setFilterSelectedTags = tags => {
    this.filterSelectedTagsIds = tags;
  };

  @action
  setFilterLocalSelectedTags = tags => {
    this.filterLocalSelectedTags = tags;
  };

  @action
  setSteps = editMode => {
    this.stepsStore = new Steps({
      steps: [
        {
          key: 'generalInformation',
          path: 'general',
          component: <GeneralInformation />
        }
      ],
      editMode
    });
  };

  @action
  updateValidGuideId = isValid => {
    this.validGuideId = isValid;
  };

  @action
  setLearningId = id => {
    this.editLearningId = id;
  };

  @action
  createLearningActivity = async mutation => {
    const {learningActivityTags} = store.platform.developmentFeatureFlags;
    if (this.newLearningActivityForm.isValid && this.validGuideId) {
      const values = this.newLearningActivityForm.values();
      values.resourceType = values.resourceType ? values.resourceType : null;
      values.title = smartTrim(values.title);
      values.tagsIds = learningActivityTags ? values.tagsIds : [];
      try {
        const {
          data: {
            createSkillTask: {title}
          }
        } = await mutation({newSkillTask: values});
        notification.success(this.translations.successCreateLearningActivity);
        this.newLearningActivityForm.reset();
        this.navigateBack(title);
      } catch (error) {
        notification.error(this.translations.failedCreateLearningActivity);
      }
    }
  };

  @action
  editLearningActivity = async mutation => {
    const {learningActivityTags} = store.platform.developmentFeatureFlags;
    if (this.newLearningActivityForm.isValid && this.validGuideId) {
      const values = this.newLearningActivityForm.values();
      values.resourceType = values.resourceType ? values.resourceType : null;
      values.title = smartTrim(values.title);
      values.tagsIds = learningActivityTags ? values.tagsIds : [];
      try {
        await mutation({skillTask: {id: this.editLearningId, ...values}});
        notification.success(this.translations.successEditLearningActivity);
        this.newLearningActivityForm.reset();
        this.navigateBack();
      } catch (error) {
        notification.error(this.translations.failedCreateLearningActivity);
      }
    }
  };

  @action reset = () => {
    this.newLearningActivityForm.reset();
    this.setSkillCount(0);
    this.updateValidGuideId(true);
    this.updateWorkspaces([]);
    this.updateIsNewLearningActivityFormEmpty(true);
    this.setLocalSelectedTags([]);
    this.setSelectedTags([]);
  };

  navigateBack = newLearningActivityTitle => {
    if (this.previousPage === views.createSkills) {
      store.skillsPage.updateBackFromLearnings(true);
      store.skillsPage.updateNewLearningActivityTitle(newLearningActivityTitle);
      store.router.goTo(views.createSkills, {});
    } else if (this.previousPage === views.editSkills) {
      store.skillsPage.updateBackFromLearnings(true);
      store.skillsPage.updateNewLearningActivityTitle(newLearningActivityTitle);
      store.router.goTo(views.editSkills, {id: store.skillsPage.skillForEditing.id});
    } else {
      store.router.goTo(views.learningActivities, {});
    }
  };

  @action
  updateLearningActivityForm = ({title, content, resourceUrl, resourceType, tags}) => {
    this.newLearningActivityForm.update({
      title,
      content,
      resourceUrl,
      resourceType,
      tagsIds: tags?.map(tag => tag.id)
    });
    this.updateIsNewLearningActivityFormEmpty(false);
  };

  @action
  setSkillCount = count => {
    this.skillCount = count;
  };

  @action
  toggleSaveDialog = value => {
    this.isSaveDialogOpen = value;
  };

  @action
  updateWorkspaces = value => {
    this.workspaces = value;
  };

  updateIsNewLearningActivityFormEmpty = value => {
    this.isNewLearningActivityFormEmpty = value;
  };

  @action
  updatePreviousPage = value => {
    this.previousPage = value;
  };

  @action
  setInitialForm = form => {
    const {tags} = form;
    this.initialForm = tags ? {...omitBy(form, 'tags'), tagsIds: tags.map(tag => tag.id)} : form;
  };
}

export default LearningActivityGeneralInfo;
