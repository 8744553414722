import Raven from 'raven-js';
import {GUIDE_FILTER_OPTIONS, CUSTOM_FILTER_OPTIONS, STATUS_OPTIONS, SORT_BY_OPTIONS} from 'shared/enums';

export const filtersKeys = {
  CUSTOM_FILTERS: 'customFilters',
  SELECT_STATUS: 'selectStatus',
  SORT_BY: 'sortBy',
  FILTER_OPTION: 'filterOption',
  SEARCH_TERM: 'searchTerm'
};

export const FILTERS_KEY = 'swipeguide_cms_filters';

const {CUSTOM_FILTERS, SELECT_STATUS, SORT_BY, FILTER_OPTION, SEARCH_TERM} = filtersKeys;
const {TAG, TEAM, ASSIGNEE, WORKSPACE, LANGUAGE} = CUSTOM_FILTER_OPTIONS;

export const defaultCustomFilters = {
  [TAG]: [],
  [TEAM]: [],
  [ASSIGNEE]: [],
  [WORKSPACE]: [],
  [LANGUAGE]: []
};

export const defaultAllFilters = {
  ...defaultCustomFilters,
  [SEARCH_TERM]: '',
  [SELECT_STATUS]: STATUS_OPTIONS.ALL,
  [FILTER_OPTION]: GUIDE_FILTER_OPTIONS.ALL_GUIDES,
  [SORT_BY]: SORT_BY_OPTIONS.PUBLISHED_TITLE
};

const getFilterValue = (filterKey, filters) =>
  filters && filters[filterKey] ? filters[filterKey] : defaultAllFilters[filterKey];

export const getFiltersFromLocalStorage = filterKey => {
  let filters;

  try {
    const fitersString = localStorage.getItem(FILTERS_KEY);
    filters = JSON.parse(fitersString) || defaultAllFilters;
  } catch (error) {
    localStorage.removeItem(FILTERS_KEY);
    Raven.captureException(error);
  }

  if (!filterKey) {
    return filters;
  }

  return filterKey === CUSTOM_FILTERS
    ? {
        [TAG]: getFilterValue(TAG, filters),
        [TEAM]: getFilterValue(TEAM, filters),
        [ASSIGNEE]: getFilterValue(ASSIGNEE, filters),
        [WORKSPACE]: getFilterValue(WORKSPACE, filters),
        [LANGUAGE]: getFilterValue(LANGUAGE, filters)
      }
    : getFilterValue(filterKey, filters);
};

export const setFiltersToLocalStorage = (filterName, value) => {
  let filters;

  try {
    const fitersString = localStorage.getItem(FILTERS_KEY);
    filters = JSON.parse(fitersString) || defaultAllFilters;
  } catch (error) {
    localStorage.removeItem(FILTERS_KEY);
    Raven.captureException(error);
  }

  filters[filterName] = value;

  if (filterName === FILTER_OPTION && value !== GUIDE_FILTER_OPTIONS.ALL_GUIDES) {
    filters = {...filters, ...defaultCustomFilters};
  }

  localStorage.setItem(FILTERS_KEY, JSON.stringify(filters));
};

export const removeEntityId = (entity, entityId, store) => {
  const {
    allGuidesPage: {setCustomFilter}
  } = store;
  let filters;

  try {
    const fitersString = localStorage.getItem(FILTERS_KEY);
    filters = JSON.parse(fitersString) || defaultAllFilters;
  } catch (error) {
    localStorage.removeItem(FILTERS_KEY);
    Raven.captureException(error);
  }

  filters[entity] = filters[entity].filter(id => id !== entityId);
  localStorage.setItem(FILTERS_KEY, JSON.stringify(filters));

  const newFilters = getFiltersFromLocalStorage(CUSTOM_FILTERS);
  setCustomFilter(newFilters);
};
