import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import noop from 'lodash/noop';
import without from 'lodash/without';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//styles
import {MultiDomainPicker, StyledCheckbox, StyledSelect} from './styles';

//messages
import messages from './messages';

@observer
class MultiDomainPickerComponent extends Component {
  static defaultProps = {
    minSize: 0,
    onChange: noop
  };

  static propTypes = {
    dataCy: PropTypes.string,
    field: PropTypes.string,
    form: PropTypes.object,
    label: PropTypes.node,
    minSize: PropTypes.number,
    onChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    selectedValues: PropTypes.array
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get currentValue() {
    const {field, form, selectedValues} = this.props;

    if (form && field) {
      return form.values()[field];
    }

    return selectedValues;
  }

  @computed
  get isDefaultDomainSelected() {
    const {currentValue} = this;
    const {options} = this.props;

    if (!options.length) {
      return false;
    }

    return currentValue.some(domainId => {
      const domain = options.find(domain => domain.id === domainId);
      return domain.isDefault;
    });
  }

  optionFormatter = domain => {
    const {intl} = this.props;
    const {formatMessage} = intl;

    if (domain.isDefault) {
      return domain.name + ' ' + formatMessage(messages.main);
    }

    return domain.name;
  };

  toggleVisibleToDefaultDomain = () => {
    const {currentValue} = this;
    const {field, form, minSize, onChange, options} = this.props;

    const defaultDomain = options.find(domain => domain.isDefault);

    if (!defaultDomain) {
      return;
    }

    let newValue;

    if (!currentValue.includes(defaultDomain.id)) {
      newValue = [...currentValue, defaultDomain.id];
    } else if (currentValue.length > minSize) {
      newValue = without(currentValue, defaultDomain.id);
    } else {
      return;
    }

    if (form && field) {
      form.$(field).sync(newValue);
    }

    onChange(newValue);
  };

  render() {
    const {currentValue} = this;
    const {dataCy, field, form, intl, label, minSize, onChange, options, selectedValues, onGuideLevel} = this.props;
    const {formatMessage} = intl;

    const caption = onGuideLevel ? messages.visibleOnGuideLevel : messages.visibleOnTeamsLevel;

    return (
      <MultiDomainPicker>
        <StyledSelect
          dataCy={dataCy}
          field={field}
          form={form}
          label={label || formatMessage(messages.workspaces)}
          onChange={onChange}
          optionFormatter={this.optionFormatter}
          options={options}
          placeholder={formatMessage(messages.selectWorkspaces)}
          selectedValues={selectedValues}
          showSearch
        />
        <StyledCheckbox
          caption={formatMessage(caption)}
          checked={this.isDefaultDomainSelected}
          disabled={this.isDefaultDomainSelected && currentValue.length <= minSize}
          onChange={this.toggleVisibleToDefaultDomain}
        />
      </MultiDomainPicker>
    );
  }
}

export default injectIntl(MultiDomainPickerComponent);
