import styled from 'styled-components';
import $Field from 'ui-components/Field';
import $Button from 'ui-components/Button';
import {commonPalette} from 'shared/styles/palette';
import $EnhancedIconComponent from 'ui-components/Layout/EnhancedIcon';

export const Field = styled($Field)``;

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 60px;
  border-bottom: 1px solid #eee;
  padding-block: 32px;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GridItemGrowing = styled(GridItem)`
  flex-grow: 1;
`;

export const ListTopBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: space-between;
  color: ${commonPalette.txGrey};
  padding-right: 8px;
  font-size: 9pt;
`;

export const SectionLabel = styled.h3`
  font-size: 16px;
  font-weight: 600;
  width: 250px;
`;

export const SectionDescription = styled.p`
  color: ${commonPalette.txGrey};
  font-size: 14px;
  font-weight: 400;
  width: 250px;
`;

export const StyledButton = styled($Button).attrs({
  underlined: true
})`
  text-decoration: none;
  color: ${commonPalette.btnGreen};
  &:hover,
  :focus {
    text-decoration: none;
  }
`;

export const StyledButtonHover = styled($Button).attrs({
  underlined: true
})`
  text-decoration: none;
  color: ${commonPalette.txGrey};
  &:hover,
  :focus {
    color: ${commonPalette.red};
    text-decoration: none;
  }
`;

export const SkillListTopBar = styled(ListTopBar)`
  gap: 20px;
`;

export const RequiredLevelLabelWrapper = styled.div`
  padding-right: 30px;
`;

export const StyledEnhancedIcon = styled($EnhancedIconComponent)`
  top: -3px;
`;
