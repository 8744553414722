import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'TranslateTags.title',
    defaultMessage: 'Tags Translations'
  },
  translating: {
    id: 'TranslateTags.translating',
    defaultMessage: 'Translating'
  },
  importSuccess: {
    id: 'TranslateTags.importSuccess',
    defaultMessage: 'Tag translations have been imported.'
  },
  importMissingTagIds: {
    id: 'TranslateTags.importMissingTagIds',
    defaultMessage: 'Rows with missing tag ids were ignored'
  },
  importFailure: {
    id: 'TranslateTags.importFailure',
    defaultMessage: 'Failed to upload tag translations.'
  },
  import: {
    id: 'common.actions.import',
    defaultMessage: 'Import'
  },
  export: {
    id: 'common.actions.export',
    defaultMessage: 'Export'
  },
  defaultLanguage: {
    id: 'TranslateTagsView.defaultLanguage',
    defaultMessage: 'Default language'
  },
  sourceText: {
    id: 'TranslateTagsView.sourceText',
    defaultMessage: 'Source text'
  },
  targetLanguage: {
    id: 'TranslateTags.targetLanguage',
    defaultMessage: 'Target language'
  }
});

export default messages;
