import GetLoginForm from 'stores/forms/login-form';
import {observable, action, makeObservable} from 'mobx';
import axios from 'axios';

//helpers
import store from 'stores/store';
import views from 'config/views';
import notification from 'utils/notification-utils';

//lodash
import assign from 'lodash/assign';

import {UserInfo} from 'api/user/queries';
import * as RedirectionUtils from 'shared/utils/redirection';

class AuthStore {
  constructor() {
    makeObservable(this);
  }

  loginForm = GetLoginForm();

  handleRedirection() {
    const {router} = store;
    RedirectionUtils.redirectToQueryParam({router, cms: true});
  }

  @observable user = undefined;
  @observable loading = false;
  @observable authing = true;
  @observable errorResponse = null;

  @action setLoading = val => (this.loading = val);
  @action setAuthing = val => (this.authing = val);
  @action setErrorResponse = val => (this.errorResponse = val);

  @action
  submitLoginForm = () => {
    this.setErrorResponse(null);
    this.setLoading(true);

    const formPayload = {
      ...this.loginForm.values(),
      isCmsLogin: true
    };

    return axios
      .post('/account/login', formPayload)
      .then(async ({status}) => {
        if (status === 200) {
          // Redirect if there's a redirect query param. Useful when user comes from viewer
          this.handleRedirection();

          //** TODO: Return full user on login or fetch 'me' */
          // this.user = data;
          await this.getCurrentUser();
          await store.platform.fetchPlatformFlags();

          //only redirect to my-swipeguides page if user is on the login page
          if (['login', 'root'].indexOf(store.router.currentRoute.id) != -1) {
            store.router.goTo(views.guides, {});
          }
        }
      })
      .catch(this.handleLoginError)
      .finally(this.setLoading(false));
  };

  @action
  impersonate = userId => {
    axios
      .post(`/ipa/impersonate/${userId}`)
      .then(() => {
        window.location.href = `${window.location.origin}/admin`;
      })
      .catch(() => {
        notification.error('Impersonation failed.');
      });
  };

  @action
  handleLoginError = ({response = {}}) => {
    this.setErrorResponse(response);
    this.setLoading(false);
  };

  @action
  logout = () => {
    return axios.get('/account/logout').then(({status, data}) => {
      if (status === 200) {
        if (data.result === 'redirect') {
          window.location.href = data.url;
        } else {
          this.setUser(null);
          this.setAuthing(false);
          store.router.goTo(views.login, {});
          location.reload();
        }
      }
    });
  };

  @action updateUser = user => (this.user = assign(this.user, user));

  @action setUser = user => (this.user = user);

  @action
  getCurrentUser = () => {
    return axios
      .post('/graphql', {
        query: UserInfo.loc.source.body
      })
      .then(({data: {data}}) => {
        if (data.me) {
          this.setUser(data.me);
        }
        const {analytics} = store;
        analytics.enablePosthog('CMS');
        this.setAuthing(false);
        if (this.user && !this.user.isEditor) {
          store.authErrorDialog.open(this.user);
        }
      })
      .catch(() => {
        this.setAuthing(false);
      });
  };
}

export default AuthStore;
