import React, {Component} from 'react';
import moment from 'moment';
import {
  CardLevelNum,
  DetailsTable,
  DetailsTableRow,
  DetailsTableSpacer,
  DrawerTitle,
  ExpiredDateWrapper,
  StyledChip
} from './styles';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {SkillForUser} from 'api/skill-matrix/queries';
import {skillForUserOptions} from 'api/skill-matrix/query-options';
import {capitalize} from 'lodash';
import {injectIntl} from 'react-intl';
import messages from './messages';
import {DATE_FORMAT} from '../../config/constants';

@inject('store')
@graphql(SkillForUser, skillForUserOptions)
@observer
class AssessedDetails extends Component {
  render() {
    const {
      skillForUserQuery,
      intl: {formatMessage}
    } = this.props;
    const {skillForUser: skill, loading} = skillForUserQuery;

    if (loading) {
      return <DrawerTitle>{formatMessage(messages.loadingMedia)}</DrawerTitle>;
    }

    return (
      <DetailsTable>
        <DetailsTableRow>
          <div>{formatMessage(messages.skill)}</div>
          <div>{skill.name}</div>
        </DetailsTableRow>
        <DetailsTableSpacer />
        <DetailsTableRow>
          <div>{formatMessage(messages.levelReached)}</div>
          <div>
            <span style={{marginRight: '8px'}}>{capitalize(skill.currentLevel?.name)}</span>
            <CardLevelNum>{skill.currentLevel?.position}</CardLevelNum>
          </div>
        </DetailsTableRow>
        <DetailsTableSpacer />
        <DetailsTableRow>
          <div>{formatMessage(messages.approvedBy)}</div>
          <div>{skill.approvedBy}</div>
        </DetailsTableRow>
        <DetailsTableSpacer />
        <DetailsTableRow>
          <div>{formatMessage(messages.approved)}</div>
          <div>{moment(skill.approvedAt).format(DATE_FORMAT)}</div>
        </DetailsTableRow>
        <DetailsTableSpacer />
        <DetailsTableRow>
          <div>{formatMessage(messages.expires)}</div>
          <ExpiredDateWrapper>
            {skill.status === 'EXPIRED' && <StyledChip>{formatMessage(messages.expired)}</StyledChip>}
            {skill.expiresAt ? moment(skill.expiresAt).format(DATE_FORMAT) : formatMessage(messages.never)}
          </ExpiredDateWrapper>
        </DetailsTableRow>
      </DetailsTable>
    );
  }
}

export default injectIntl(AssessedDetails);
