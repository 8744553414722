import {toDataURL} from 'qrcode';

import {alignmentType, colors, blackBorderLayout, noBorderLayout} from '../constants';
import messages from '../messages';

export const getMediaNotAvailable = async (
  additionalInfo,
  pdfMessages,
  stepNoteInfo,
  guideBaseUrl,
  intl,
  mediaType
) => {
  const {topic, instruction, currentStep} = additionalInfo;
  const {formatMessage} = intl;

  const viewerLink = `${guideBaseUrl}/${topic.slug}/${instruction.slug}/${currentStep}`;

  const qrAndLink = stepNoteInfo
    ? [
        {
          image: await toDataURL(viewerLink),
          fit: [stepNoteInfo ? 60 : 75, stepNoteInfo ? 60 : 75],
          width: 80,
          alignmentType: alignmentType.LEFT,
          margin: [-3, 5, 0, 0]
        },
        {
          text: viewerLink,
          link: viewerLink,
          color: colors.BLUE,
          width: stepNoteInfo ? 90 : 130,
          alignmentType: alignmentType.LEFT,
          margin: [0, 5, 0, 0],
          fontSize: 10
        }
      ]
    : {
        table: {
          headerRows: 0,
          body: [
            [
              {
                columns: [
                  {
                    image: await toDataURL(viewerLink),
                    fit: [stepNoteInfo ? 60 : 75, stepNoteInfo ? 60 : 75],
                    width: 80,
                    alignmentType: alignmentType.LEFT,
                    margin: [-20, -10, 0, 0]
                  },
                  {
                    text: viewerLink,
                    link: viewerLink,
                    color: colors.BLUE,
                    width: stepNoteInfo ? 90 : 130,
                    alignmentType: alignmentType.LEFT,
                    margin: [-20, -7, 0, 0],
                    fontSize: 10
                  }
                ]
              }
            ]
          ]
        },
        layout: noBorderLayout
      };

  return {
    table: {
      headerRows: 0,
      widths: stepNoteInfo ? [150] : [229],
      body: [
        [
          {
            columns: [
              [
                {
                  text: pdfMessages.pdfMediaItemHeader,
                  bold: true,
                  margin: [0, 0, 0, 10],
                  fontSize: 10
                },
                {
                  text: formatMessage(messages.pdfMediaItemDescription, {mediaType}),
                  width: stepNoteInfo ? 70 : 100,
                  fontSize: 10
                },
                qrAndLink
              ]
            ]
          }
        ]
      ]
    },
    unbreakable: true,
    layout: blackBorderLayout,
    margin: stepNoteInfo ? [stepNoteInfo.isWarning ? -60 : 0, 5, 0, 5] : [11, 0, 0, 0]
  };
};
