import styled from 'styled-components';

//helpers
import {borderRadius, cardShadowCss} from 'shared/styles/vars';
import {headerSize} from 'styles/vars';

//components
import $Icon from 'ui-components/Layout/Icon';
import $Link from 'shared/components/Link';
import $StepControls from 'components/StepControls';

//constants
const instructionDetailsHeight = 109; // view mode, approximate
const writingTipsHintHeight = 70;

const topHeightSubtrahend = headerSize + instructionDetailsHeight + writingTipsHintHeight;

export const Content = styled.div`
  flex-grow: 1;
  min-height: ${p => p.minHeight}px;
  position: relative;

  @media (max-width: 880px) {
    // Editor's portrait mode on narrow screens
    min-height: ${p => p.extendedMinHeight}px;
  }
`;

export const GoToStepOverlay = styled.div`
  bottom: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
`;

export const GoToNextStepOverlay = styled(GoToStepOverlay)`
  right: 0;
`;

export const GoToPreviousStepOverlay = styled(GoToStepOverlay)`
  left: 0;
`;

export const Header = styled.header`
  padding: 20px 30px 20px 45px;
`;

export const NewStepPlaceholder = styled.div`
  ${cardShadowCss(false)};
  background: #fff;
  border-radius: ${borderRadius.desktop}px 0 0 ${borderRadius.desktop}px;
  display: inline-block;
  position: relative;
  vertical-align: top;

  .stepNumber {
    left: 0;
    top: 2rem;
    transform: translateX(-50%);
  }
`;

export const OverviewIcon = styled($Icon).attrs({
  name: 'overview',
  size: 30
})`
  margin-right: 10px;
  vertical-align: -9px;
`;

export const OverviewLink = styled($Link)`
  color: rgba(0, 0, 0, 0.65) !important;
  display: inline-block;
  padding: 6px 16px 6px 6px;
  transition: 0.2s color ease-out;

  &:hover {
    color: #000 !important;
    transition: none;
  }
`;

export const PlusIcon = styled($Icon).attrs({
  name: 'plus',
  size: 24
})`
  left: ${p => (p.availableWidth - p.size) / 2}px;
  margin-top: ${p => p.size / -2}px;
  position: absolute;
  top: 50%;
`;

export const StepWrapper = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  white-space: normal;
`;

export const StyledStepControls = styled($StepControls)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: ${p => p.cardSpacing}px;
  z-index: 9999;

  ${p =>
    p.isRight
      ? `
    right: ${-p.cardSpacing / 2}px;
  `
      : `
    left: ${-p.cardSpacing / 2}px;
  `}
`;

export const SwipeableSteps = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SwipeableContainer = styled.div`
  transition: 0.3s transform cubic-bezier(0.1, 0, 0.25, 1);
  white-space: nowrap;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${topHeightSubtrahend}px);
  overflow: hidden;
  padding: 0 0 60px;
`;
