import {QueryHookOptions} from '@apollo/client';
import get from 'lodash/get';

export const teamsOptions = {
  options: {
    fetchPolicy: 'network-only'
  },
  name: 'teamsQuery'
};
export const teamsPaginatedOptions = {
  options: ({store}) => {
    const {offset, limit, searchTerm} = store.reassignGuideDialog;

    return {
      variables: {
        offset,
        limit,
        searchTerm,
        sortBy: 'NAME',
        includePermissionCheck: false
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'teamsPaginatedQuery'
};

export const teamsLiteOptions = {
  options: {
    fetchPolicy: 'network-only'
  },
  name: 'teamsQuery'
};

export const allParentTeamsOptions = {
  options: {
    fetchPolicy: 'network-only',
    variables: {}
  },
  name: 'parentTeamsQuery'
};

export const allTeamChildrenOptions = {
  options: ({teamId}): QueryHookOptions => {
    return {
      variables: {
        teamId
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'allTeamChildrenQuery'
};

export const paginatedTeamsLiteOptions = {
  options: ({paginatedTeamsVariables}): QueryHookOptions => {
    const {offset, limit, searchTerm} = paginatedTeamsVariables;
    return {
      variables: {
        offset,
        limit,
        searchTerm,
        sortBy: 'name',
        includePermissionCheck: false
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'teamsPaginatedQuery'
};

export const teamOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  }
};

export const teamUserRolesOptions = {
  options: ({store}): QueryHookOptions => {
    const {teamId} = store.router.params;
    return {
      variables: {
        teamId,
        includeChildrenTeamPermissions: true
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'teamUserRolesQuery'
};

export const teamWithGuideCountOptions = {
  options: params => {
    const {team} = params;

    const id = get(team, 'id');
    return {
      variables: {
        id
      },
      options: {
        fetchPolicy: 'network-only'
      }
    };
  },
  name: 'teamWithGuideCountQuery'
};
