import {AUTO_TRANSLATE_DIALOGS_MAP} from 'stores/dialogs/auto-translation-dialog';
import messages from './messages';
import views from 'config/views';

export const getTranslateGuideDialogConfig = (store, formatMessage) => ({
  title: formatMessage(messages.translateGuide),
  actionsProps: {
    actions: [
      {
        label: formatMessage(messages.autoTranslate),
        onClick: () => {
          const guideId = store.autoTranslationDialog.guideId;
          store.autoTranslationDialog.fetchTranslationRate(guideId);
          store.autoTranslationDialog.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.selectLanguage);
        }
      }
    ],
    customCancelButtonText: messages.translateManually,
    onCancel: () => {
      const guideId = store.autoTranslationDialog.guideId;
      store.autoTranslationDialog.reset();
      store.router.goTo(views.translateGuide, {id: guideId});
    }
  },
  onRequestClose: () => {
    store.autoTranslationDialog.reset();
  },
  customHeaderStyle: {paddingBottom: '10px'},
  content: ''
});
