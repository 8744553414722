import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

const strategies = {
  [EVENT_TYPES.SCALE_SELECTION]: store => () => {
    store.mediaPickerDialog.toggleContain();
    trackEvent(EVENT_TYPES.SCALE_SELECTION);
  },
  [EVENT_TYPES.ROTATE_IMAGE]: store => () => {
    store.mediaPickerDialog.rotateImage();
    trackEvent(EVENT_TYPES.ROTATE_IMAGE);
  },
  [EVENT_TYPES.IMAGE_BACKGROUND_COLOR]: () => {
    trackEvent(EVENT_TYPES.IMAGE_BACKGROUND_COLOR);
  }
};

export default strategies;
