import {Action} from '@thoughtspot/visual-embed-sdk';

export const DEFAULT_ANALYTICS_PAGE_SLUG = 'adoption';

// Adoption menu item
export const TS_PLATFORM_LIVEBOARD_ID = 'b7ad283c-4c98-4f90-80d9-3e9563963f27';
export const TS_WORKSPACE_LIVEBOARD_ID = '377251e5-d554-4c89-a95d-45a72cde88d5';
export const TS_CHECKLISTS_LIVEBOARD_ID = '3f925fe9-2144-4d84-95e1-2dbf0f4e165a';

export const VIEW_CONFIG_MAP = {
  platform: {
    liveboardId: TS_PLATFORM_LIVEBOARD_ID,
    sectionTitleMessageKey: 'adoption',
    tabTitleMessageKey: 'platformAdoption'
  },
  workspace: {
    liveboardId: TS_WORKSPACE_LIVEBOARD_ID,
    sectionTitleMessageKey: 'adoption',
    tabTitleMessageKey: 'workspaceAdoption'
  },
  checklists: {
    liveboardId: TS_CHECKLISTS_LIVEBOARD_ID,
    sectionTitleMessageKey: 'checklists',
    tabTitleMessageKey: 'checklists'
  }
};

export const THOUGHTSPOT_LIVEBOARD_HIDDEN_ACTIONS = [
  Action.AddFilter,
  Action.ConfigureFilter,
  Action.AddToFavorites,
  Action.AnswerDelete,
  Action.Schedule,
  Action.SchedulesList,
  Action.RequestAccess,
  Action.Share,
  Action.ShareViz,
  Action.Present,
  Action.CopyLink,
  Action.CopyAndEdit,
  Action.CopyToClipboard,
  Action.ExportTML,
  Action.ImportTML,
  Action.UpdateTML,
  Action.MakeACopy,
  Action.LiveboardInfo,
  Action.Pin,
  Action.ResetLayout,
  Action.ToggleSize,
  Action.ShowUnderlyingData,
  Action.Edit,
  Action.EditACopy,
  Action.EditTML,
  Action.EditTitle,
  Action.Explore
];
