import {computed, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {findDOMNode} from 'react-dom';
import {injectIntl} from 'react-intl';

//lodash
import every from 'lodash/every';
import get from 'lodash/get';
import includes from 'lodash/includes';

//helpers
import {INSTRUCTION_EDIT_MODE, MEDIA_PREVIEW_TYPE} from 'config/enums';
import views from 'config/views';
import {bindField, slugify, syncTitleWithSlug} from 'shared/utils/input-utils';
import {ensureDraftVersion} from 'utils/versioning-utils';
import {INSTRUCTION_TYPE} from 'shared/enums';

//components
import Field from 'ui-components/Field';
import InstructionMenu from 'components/InstructionMenu';
import ButtonGroup from 'ui-components/ButtonGroup';
import {EditableText} from 'shared/styles/styled-components/field-styled-components';
import Select from 'ui-components/Select';

//styles
import {
  Cover,
  InnerWrapperLink,
  InnerWrapperStatic,
  InstructionCardForm,
  InstructionCardStatic,
  StyledCardToolbar,
  StyledDragHandle,
  StyledMediaPicker,
  StyledSlug,
  StyledUnderlinedTextbox,
  Title,
  StyledPencilIcon,
  StyledButton,
  FieldWrapper,
  ChecklistIcon,
  StyledChecklistType
} from './styles';

//mutations
import {CreateInstruction, UpdateInstruction, UpdateInstructionMedia} from 'api/instruction/mutations';

//mutation options
import {
  createInstructionOptions,
  updateInstructionInGuideOptions,
  updateInstructionMediaOptions
} from 'api/instruction/mutation-options';

//queries
import {ChecklistTemplates} from 'api/checklist-templates/queries';

//query options
import {checklistTemplatesOptions} from 'api/checklist-templates/query-options';

//messages
import messages from './messages';

@inject('store')
@graphql(CreateInstruction, createInstructionOptions)
@graphql(UpdateInstruction, updateInstructionInGuideOptions)
@graphql(UpdateInstructionMedia, updateInstructionMediaOptions)
@graphql(ChecklistTemplates, checklistTemplatesOptions)
@observer
class InstructionCardComponent extends Component {
  static propTypes = {
    dragHandleRef: PropTypes.func,
    hostRef: PropTypes.func,
    instruction: PropTypes.shape({
      id: PropTypes.string,
      media: PropTypes.object,
      stepsNumber: PropTypes.number,
      title: PropTypes.string,
      topicId: PropTypes.string,
      checklistTemplateId: PropTypes.string
    }),
    isGhostly: PropTypes.bool,
    onDragHandleMouseDown: PropTypes.func
  };

  static defaultProps = {
    instruction: {},
    isGhostly: false
  };

  titleFieldOptions = {
    onChangeReaction: event => {
      const {store} = this.props;
      const {editInstructionPage: page} = store;

      syncTitleWithSlug(event, page.form);
    }
  };

  updateMedia = media => {
    const {
      instruction: {id},
      updateInstructionMediaMutation
    } = this.props;
    const mediaId = media ? media.id : null;
    return updateInstructionMediaMutation({
      id,
      mediaId
    });
  };

  /**
   * After clone move to the newly created intruction
   */
  onClone = clonedInstruction => {
    const {store} = this.props;
    const {router} = store;
    const {topicId, id} = router.params;

    router.goTo(views.editInstruction, {id, topicId, instructionId: clonedInstruction.id});
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  changeTemplate = selectedTemplate => {
    const {store} = this.props;
    const {editInstructionPage: page} = store;
    page.setChecklistTemplateId(selectedTemplate);
  };

  render() {
    if (this.shouldBeEditable) {
      return this.renderEditor();
    }

    return this.renderViewer();
  }

  renderEditor() {
    const {intl, store, checklistTemplatesQuery} = this.props;
    const {formatMessage} = intl;
    const {editInstructionPage: page, platform} = store;
    const {isChecklist} = page;
    const {checklistScoring} = platform.developmentFeatureFlags;
    const checklistTemplates = get(checklistTemplatesQuery, 'checklistTemplates.results', []);
    const editMode = page.instructionId !== null;

    return (
      <InstructionCardForm onKeyDown={this.handleKeyDown} onSubmit={this.handleFormSubmit}>
        <InnerWrapperStatic data-cy="create-instruction-card">
          <Cover>
            <StyledMediaPicker
              backgroundUrl={page.selectedMediaUrl}
              backgroundMetadata={page.selectedMediaMetadata}
              form={page.form}
              field="mediaId"
              iconSize={50}
              previewType={MEDIA_PREVIEW_TYPE.INSTRUCTION_OVERVIEW}
              setCoverUrl={page.setSelectedMediaUrl}
              setMetadata={page.setSelectedMediaMetadata}
              focus={true}
            />
          </Cover>
          <FieldWrapper>
            {isChecklist && <ChecklistIcon />}
            <Field
              inputComponent={
                <StyledUnderlinedTextbox
                  autoFocus
                  placeholder={formatMessage(isChecklist ? messages.yourChecklist : messages.yourInstruction)}
                  {...bindField(page.form, 'title', this.titleFieldOptions)}
                />
              }
              form={page.form}
              field="title"
              data-cy="input-instruction-title"
            />
          </FieldWrapper>
          <StyledSlug
            form={page.form}
            field="slug"
            placeholder={slugify(formatMessage(isChecklist ? messages.yourChecklist : messages.yourInstruction))}
          />
          {checklistScoring && checklistTemplates.length > 1 && isChecklist && !editMode && (
            <Select
              options={checklistTemplates}
              placeholder={formatMessage(messages.selectChecklistTemplate)}
              defaultValue={formatMessage(messages.selectChecklistTemplate)}
              selectedValue={page.checklistTemplateId}
              onChange={this.changeTemplate}
              style={{width: 200, paddingBottom: 20}}
            />
          )}
          <ButtonGroup
            primary={{
              label: formatMessage(messages.save),
              disabled: !page.canSubmit,
              loading: page.submitting,
              type: 'submit',
              dataCy: isChecklist ? 'save-checklist' : 'save-instruction'
            }}
            secondary={{
              label: formatMessage(messages.cancel),
              disabled: page.submitting,
              onClick: page.stopEditing
            }}
          />
        </InnerWrapperStatic>
        <StyledDragHandle isDisabled />
      </InstructionCardForm>
    );
  }

  renderViewer() {
    const {dragHandleRef, instruction, isGhostly, onDragHandleMouseDown, store, intl} = this.props;
    const {id, publishStatus, media, providedTranslations, stepsNumber, title, topicId, checklistTemplate} =
      instruction;
    const {dragDropItems, editInstructionPage: page, editGuidePage, router, saveAsDraftDialog, platform} = store;
    const isChecklist = instruction.type === INSTRUCTION_TYPE.CHECKLIST;
    const isPublished = publishStatus === 'PUBLISHED';
    const {formatMessage} = intl;
    const {guide} = editGuidePage;
    const guideDefaultLocale = get(guide, 'defaultLocale');
    const canTranslate = guide && guide.canTranslate;
    const isFullyTranslated = every(get(guide, 'availableTranslations'), translation =>
      includes(providedTranslations, translation)
    );
    const {checklistScoring} = platform.developmentFeatureFlags;
    const isDefaultTemplate =
      checklistTemplate?.type === 'BINARY' ? formatMessage(messages.default) : formatMessage(messages.custom);
    const templateType =
      checklistTemplate?.type === 'BINARY'
        ? formatMessage(messages.percentageBased)
        : formatMessage(messages.scoreBased);

    const hasMedia = Boolean(media);
    const templateLabel = [isDefaultTemplate, checklistTemplate?.name, templateType].join(' ');

    const translationsMessages = {
      archive: {
        removeInstruction: formatMessage(isChecklist ? messages.removeChecklist : messages.removeInstruction),
        confirmation: formatMessage(isChecklist ? messages.confirmationChecklist : messages.confirmationInstruction, {
          title
        }),
        archiveFailure: formatMessage(
          isChecklist ? messages.archiveChecklistFailure : messages.archiveInstructionFailure
        ),
        archiveSuccess: formatMessage(
          isChecklist ? messages.archiveChecklistSuccess : messages.archiveInstructionSuccess
        ),
        action: formatMessage(isChecklist ? messages.removeChecklist : messages.removeInstruction)
      },
      clone: {
        cloneSuccess: formatMessage(isChecklist ? messages.cloneChecklistSuccess : messages.cloneInstructionSuccess),
        cloneError: formatMessage(isChecklist ? messages.cloneChecklistError : messages.cloneInstructionError)
      },
      publish: {
        showSuccess: formatMessage(isChecklist ? messages.showChecklistSuccess : messages.showInstructionSuccess),
        showFailure: formatMessage(isChecklist ? messages.showChecklistFailure : messages.showInstructionFailure)
      },
      unpublish: {
        hideSuccess: formatMessage(isChecklist ? messages.hideChecklistSuccess : messages.hideInstructionSuccess),
        hideFailure: formatMessage(isChecklist ? messages.hideChecklistFailure : messages.hideInstructionFailure)
      },
      moveTo: {
        title: formatMessage(isChecklist ? messages.moveChecklistTitle : messages.moveInstructionTitle),
        buttonLabel: formatMessage(isChecklist ? messages.moveChecklistButton : messages.moveInstructionButton),
        moveToSuccess: formatMessage(isChecklist ? messages.moveChecklistSuccess : messages.moveInstructionSuccess),
        moveToFailure: formatMessage(isChecklist ? messages.moveChecklistFailure : messages.moveInstructionFailure),
        defaultLanguagesMustMatch: formatMessage(messages.defaultLanguagesMustMatch)
      },
      share: {
        title: formatMessage(isChecklist ? messages.shareChecklist : messages.shareInstruction)
      },
      items: {
        share: messages.share,
        translate: messages.translate,
        hide: messages.hide,
        show: messages.show,
        duplicate: messages.duplicate,
        moveTo: messages.moveTo,
        delete: messages.delete,
        applySignOff: messages.applySignOff,
        requireSignature: messages.requireSignature,
        removeSignature: messages.removeSignature,
        somethingWentWrong: messages.somethingWentWrong,
        signatureRequired: messages.signatureRequired,
        signatureRemoved: messages.signatureRemoved,
        setAlertRecipients: messages.setAlertRecipients
      }
    };
    const dataCyEntityCard = isChecklist ? `checklist-card-${title.split(' ').join('-')}` : 'instruction-card';
    const dataCyEntityTitle = isChecklist ? `checklist-title-${title.split(' ').join('-')}` : 'instruction-title';

    // NB: Draggable elements inside anchors and buttons
    // do not work if Firefox. That's why the drag handle
    // has to be outside the link component.
    //
    // https://bugzilla.mozilla.org/show_bug.cgi?id=568313

    return (
      <InstructionCardStatic
        className="sync-card-hover-with-child-component"
        ref={this.handleHostInnerRef}
        isGhostly={isGhostly}
        data-cy={title}
      >
        <InnerWrapperLink
          isInvisible={isGhostly}
          params={{
            id: router.params.id,
            instructionId: id,
            topicId
          }}
          queryParams={router.queryParams}
          store={store}
          route={views.editInstruction}
          data-cy={dataCyEntityCard}
        >
          <Cover onClick={this.avoidNavigation && this.handleMediaPickerClick}>
            <StyledMediaPicker
              backgroundUrl={hasMedia ? media.url : null}
              backgroundMetadata={hasMedia ? media.metadata : null}
              shouldShowOverlayShapes
              canOpen={() => saveAsDraftDialog.check()}
              iconSize={50}
              field="mediaId"
              form={page.form}
              mediaId={hasMedia ? media.id : null}
              updateMedia={this.updateMedia}
              previewType={MEDIA_PREVIEW_TYPE.INSTRUCTION_OVERVIEW}
              setCoverUrl={page.setSelectedMediaUrl}
              setMetadata={page.setSelectedMediaMetadata}
            />
          </Cover>
          <Title data-cy={dataCyEntityTitle}>
            <EditableText onClick={this.handleTitleClick}>
              {isChecklist && <ChecklistIcon />}
              {title}
              <StyledPencilIcon />
            </EditableText>
          </Title>
          {checklistScoring && isChecklist && <StyledChecklistType>{templateLabel}</StyledChecklistType>}
          <StyledButton
            secondary
            label={formatMessage(isChecklist ? messages.editNChecks : messages.editNSteps, {count: stepsNumber})}
          />
          <StyledCardToolbar
            dropdownMenu={
              <InstructionMenu
                guideDefaultLocale={guideDefaultLocale}
                instruction={instruction}
                canTranslate={canTranslate}
                onClone={this.onClone}
                translationsMessages={translationsMessages}
                shouldShowUntranslatedIcon={!isFullyTranslated}
                isChecklist={isChecklist}
              />
            }
            title={title}
            visibilityIcon={isPublished ? 'visible' : 'hidden'}
            shouldShowUntranslatedIcon={!isFullyTranslated}
            instruction={instruction}
            isChecklist={isChecklist}
          />
        </InnerWrapperLink>
        <StyledDragHandle
          forwardedRef={dragHandleRef}
          isDisabled={dragDropItems.submitting}
          isInvisible={isGhostly}
          onMouseDown={onDragHandleMouseDown}
          className="drag-handle"
          data-cy="drag-handle"
        />
      </InstructionCardStatic>
    );
  }

  avoidNavigation = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleHostInnerRef = ref => {
    const {hostRef} = this.props;

    if (typeof hostRef === 'function') {
      const node = findDOMNode(ref);
      hostRef(node);
    }
  };

  handleFormSubmit = event => {
    const {createInstructionMutation, store, updateInstructionMutation} = this.props;
    const {editInstructionPage: page} = store;

    event.preventDefault();
    page.submit(createInstructionMutation, updateInstructionMutation);
  };

  handleKeyDown = event => {
    const {store} = this.props;
    const {editInstructionPage: page} = store;

    if (event.key === 'Escape') {
      page.stopEditing();
    }
  };

  handleTitleClick = event => {
    this.avoidNavigation(event);
    this.startEditing();
  };

  startEditing = ensureDraftVersion(() => {
    const {instruction, store} = this.props;
    const {editInstructionPage: page} = store;

    page.startEditing(instruction);
  }, this);

  handleMediaPickerClick = event => {
    const {instruction, store} = this.props;
    const {editInstructionPage: page} = store;

    if (page.instructionId) page.startEditing(instruction);
    this.avoidNavigation(event);
  };

  @computed
  get shouldBeEditable() {
    const {instruction, store} = this.props;
    const {editInstructionPage: page} = store;

    const isCreating = !instruction.id;
    const isUpdating = instruction.id === page.instructionId && page.mode === INSTRUCTION_EDIT_MODE.UPDATE;

    return isCreating || isUpdating;
  }
}

export default injectIntl(InstructionCardComponent);
