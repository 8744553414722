import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from 'utils/observer-graphql';
import get from 'lodash/get';

//helpers
import views from 'config/views';
import {INSTRUCTION_TYPE} from 'shared/enums';
import {SHARE_DIALOG_TYPE} from 'config/enums';
import {ensureDraftVersion} from 'utils/versioning-utils';
import notification from 'utils/notification-utils';

//queries
import {GetGuideForCmsInstruction} from 'api/guide/queries';
import {GetInstructionForEdit} from 'api/instruction/queries';

//query-options
import {getGuideOptions} from 'api/guide/query-options';
import {getInstructionForEditOptions} from 'api/instruction/query-options';

//mutations
import {
  ArchiveInstruction,
  CloneInstruction,
  PublishInstruction,
  SetInstructionToDraft
} from 'api/instruction/mutations';

//options
import {
  archiveInstructionInGuideOptions,
  cloneInstructionInListOptions,
  publishInstructionInGuideOptions,
  setInstructionToDraftInGuideOptions
} from 'api/instruction/mutation-options';

//components
import EditInstruction from 'components/EditInstruction';
import NotFoundMessage from 'components/NotFoundMessage';

//messages
import messages from './messages';
import {getTranslateRouteAndParams} from 'utils/translate-route';

@inject('store')
@graphql(GetGuideForCmsInstruction, getGuideOptions)
@graphql(GetInstructionForEdit, {
  ...getInstructionForEditOptions,
  name: 'instructionData'
})
@graphql(ArchiveInstruction, archiveInstructionInGuideOptions)
@graphql(CloneInstruction, cloneInstructionInListOptions)
@graphql(PublishInstruction, publishInstructionInGuideOptions)
@graphql(SetInstructionToDraft, setInstructionToDraftInGuideOptions)
@observer
class EditInstructionViewComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {data, instructionData, store} = nextProps;
    const {guideForCms: guide, loading, refetch: refetchGuide} = data;
    const {instructionForCms: instruction, loading: loadingInstruction, refetch: refetchInstruction} = instructionData;
    const {editInstructionDetailsPage, dragDropItems, saveAsDraftDialog, router} = store;

    const isActivityLogOpened = store.router.queryParams?.activityLog;
    if (isActivityLogOpened && !loading) {
      store.versionHistoryDrawer.open({
        guideId: guide.id,
        guideTitle: guide.title,
        draftVersionOid: guide.draftVersionOid,
        reviewingVersionOid: guide.reviewingVersionOid
      });
    }

    const isGuideReady = Boolean(!loading && guide);
    const isInstructionReady = Boolean(!loadingInstruction && instruction);
    if (isGuideReady) {
      editInstructionDetailsPage.setGuide(guide);
      editInstructionDetailsPage.setRefetchGuide(refetchGuide);
      dragDropItems.setTopics(guide.topics);
      saveAsDraftDialog.setCurrentVersionId(guide.versionId);
    }

    if (!loading) {
      editInstructionDetailsPage.setGuideLoading(false);
    }
    if (!loadingInstruction) {
      if (instruction) {
        editInstructionDetailsPage.setInstruction(instruction);
      } else {
        const {id} = router.params;
        router.goTo(views.editGuide, {id}, router.queryParams);
      }
    }
    if (!editInstructionDetailsPage.refetchInstructionQuery) {
      editInstructionDetailsPage.setRefetchInstruction(refetchInstruction);
    }
    if (isInstructionReady && isGuideReady) {
      this.setActionMenu(guide);
    } else {
      store.app.resetActionMenuItems();
    }
  }

  share = () => {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {editInstructionDetailsPage: page, shareDialog: dialog} = store;
    const {isChecklist} = page;
    const {id: instructionId, slug: instructionSlug, title} = page.currentInstruction;
    const guideId = page.guide.id;

    dialog.open({
      itemTitle: title,
      path: `guide/${guideId}/${instructionId}`,
      qrBasename: instructionSlug,
      title: formatMessage(isChecklist ? messages.shareChecklist : messages.shareInstruction),
      type: isChecklist ? SHARE_DIALOG_TYPE.CHECKLIST : SHARE_DIALOG_TYPE.INSTRUCTION
    });
  };

  isInstructionPublished = () => {
    const {
      store: {editInstructionDetailsPage}
    } = this.props;
    const {currentInstruction} = editInstructionDetailsPage;
    return currentInstruction && currentInstruction.publishStatus === 'PUBLISHED';
  };

  getParams = () => {
    const {store} = this.props;
    const {editInstructionDetailsPage} = store;
    const {currentInstruction: instruction, guide} = editInstructionDetailsPage;
    const guideDefaultLocale = get(guide, 'defaultLocale', null);
    const {params: translateParams, route: translateRoute} = getTranslateRouteAndParams(
      guideDefaultLocale,
      guide.availableTranslations,
      {
        guideId: guide.id,
        topicId: instruction?.topicId,
        instructionId: instruction?.id
      }
    );
    return {translateParams, translateRoute};
  };

  unpublish = ensureDraftVersion(() => {
    const {
      setInstructionToDraftMutation,
      store: {editInstructionDetailsPage},
      intl
    } = this.props;
    const {currentInstruction: instruction, isChecklist} = editInstructionDetailsPage;
    const {formatMessage} = intl;

    setInstructionToDraftMutation({id: instruction.id})
      .then(() => {
        notification.success(
          formatMessage(isChecklist ? messages.hideChecklistSuccess : messages.hideInstructionSuccess)
        );
      })
      .catch(() => {
        notification.error(
          formatMessage(isChecklist ? messages.hideChecklistFailure : messages.hideInstructionFailure)
        );
      });
  }, this);

  publish = ensureDraftVersion(() => {
    const {
      publishInstructionMutation,
      store: {editInstructionDetailsPage},
      intl
    } = this.props;
    const {currentInstruction: instruction, isChecklist} = editInstructionDetailsPage;
    const {formatMessage} = intl;

    publishInstructionMutation({id: instruction.id})
      .then(() => {
        notification.success(
          formatMessage(isChecklist ? messages.showChecklistSuccess : messages.showInstructionSuccess)
        );
      })
      .catch(() => {
        notification.error(
          formatMessage(isChecklist ? messages.showChecklistFailure : messages.showInstructionFailure)
        );
      });
  }, this);

  clone = ensureDraftVersion(() => {
    const {
      cloneInstructionMutation,
      store: {editInstructionDetailsPage},
      intl
    } = this.props;
    const {currentInstruction: instruction} = editInstructionDetailsPage;
    const {id, topicId} = instruction;
    const {formatMessage} = intl;

    cloneInstructionMutation({id, topicId})
      .then(({data}) => {
        notification.success(formatMessage(messages.cloneSuccess));
        if (typeof this.onClone === 'function') {
          this.onClone(data.cloneInstruction);
        }
      })
      .catch(() => {
        notification.error(formatMessage(messages.cloneError));
      });
  }, this);

  archive = ensureDraftVersion(() => {
    const {
      archiveInstructionInGuideMutation: mutation,
      store: {editInstructionDetailsPage},
      store,
      intl
    } = this.props;
    const {currentInstruction: instruction} = editInstructionDetailsPage;
    const {formatMessage} = intl;
    const {id, title} = instruction;
    const {archiveDialog} = store;

    const translations = {
      removeInstruction: formatMessage(messages.removeInstruction),
      confirmation: formatMessage(messages.confirmation, {title}),
      archiveFailure: formatMessage(messages.archiveFailure),
      archiveSuccess: formatMessage(messages.archiveSuccess),
      action: formatMessage(messages.removeInstruction)
    };

    archiveDialog.open({
      mutation,
      translations,
      title,
      params: {id},
      onArchive: this.onArchive
    });
  }, this);

  /**
   * After archive get back to guide overview
   */
  onArchive = () => {
    const {store} = this.props;
    const {router} = store;
    const {id} = router.params;

    router.goTo(views.editGuide, {id});
  };

  handleTitleClick = ensureDraftVersion(() => {
    const {store} = this.props;
    const {editInstructionDetailsPage: page} = store;

    page.setEditMode(true);
  }, this);

  onClone = clonedInstruction => {
    const {store} = this.props;
    const {router, editInstructionDetailsPage} = store;
    const {topicId, id} = router.params;

    router.goTo(views.editInstruction, {
      id,
      topicId,
      instructionId: clonedInstruction.id
    });
    editInstructionDetailsPage.refetchInstructionQuery({
      id: clonedInstruction.id
    });
  };

  onApplySignOff = () => {
    const {
      store: {
        applyCampaignDialog,
        editInstructionDetailsPage: {currentInstruction: instruction}
      }
    } = this.props;

    const appliedFromInstruction = true;
    applyCampaignDialog.open([instruction], appliedFromInstruction, instruction.campaign);
  };

  openVersionHistory = () => {
    const {
      store: {versionHistoryDrawer},
      data: {guideForCms: guide}
    } = this.props;
    const {id: guideId, title: guideTitle, draftVersionOid, reviewingVersionOid} = guide;

    versionHistoryDrawer.open({
      guideId,
      guideTitle,
      draftVersionOid,
      reviewingVersionOid
    });
  };

  setActionMenu = guide => {
    const {
      store: {
        app,
        router,
        auth: {
          user: {canManageCampaigns}
        },
        editInstructionDetailsPage
      }
    } = this.props;
    const canTranslate = guide && guide.canTranslate;
    const isDraftVersion = !get(router, 'queryParams.v', false);
    const canShare = get(guide, 'canShare', false) && this.isInstructionPublished();
    const isPlainInstruction = editInstructionDetailsPage.currentInstruction.type === INSTRUCTION_TYPE.INSTRUCTION;
    const {translateParams, translateRoute} = this.getParams();

    const actionMenuItems = [
      {
        id: 'share',
        onClick: this.share,
        disabled: !canShare
      },
      {
        id: 'applySignOff',
        disabled: !isPlainInstruction || !canManageCampaigns,
        onClick: this.onApplySignOff
      },
      {
        id: 'translate',
        disabled: !canTranslate || !isDraftVersion,
        view: translateRoute.id,
        params: translateParams
      },
      {
        id: 'hide',
        disabled: !this.isInstructionPublished(),
        onClick: this.unpublish
      },
      {
        id: 'show',
        disabled: this.isInstructionPublished(),
        onClick: this.publish
      },
      {
        id: 'activity',
        onClick: this.openVersionHistory
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  componentWillUnmount() {
    const {
      store: {editInstructionDetailsPage, app}
    } = this.props;
    editInstructionDetailsPage.exitView();
    app.resetActionMenuItems();
  }

  render() {
    const {data, store} = this.props;
    const {guideForCms: guide} = data;
    const {editInstructionDetailsPage: page} = store;
    const {guideLoading} = page;

    if (guideLoading) return null;

    if (!guide) {
      return <NotFoundMessage />;
    }

    return <EditInstruction />;
  }
}

export default injectIntl(EditInstructionViewComponent);
