import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createJobTitle: {
    id: 'createJobTitle',
    defaultMessage: 'Create Job Title'
  },
  editJobTitle: {
    id: 'editJobTitle',
    defaultMessage: 'Edit Job Title'
  },
  jobTitleName: {
    id: 'jobTitleName',
    defaultMessage: 'Job Title Name'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  create: {
    id: 'common.actions.create',
    defaultMessage: 'Create'
  },
  successJobTitleCreation: {
    id: 'successJobTitleCreation',
    defaultMessage: 'Successfully created new Job Title "{name}"'
  },
  failJobTitleCreation: {
    id: 'failJobTitleCreation',
    defaultMessage: 'Failed to create new Job Title. Please try again.'
  },
  successJobTitleEdit: {
    id: 'successJobTitleEdit',
    defaultMessage: 'Successfully updated Job Title "{name}"'
  },
  failJobTitleEdit: {
    id: 'failJobTitleEdit',
    defaultMessage: 'Failed to update Job Title. Please try again.'
  }
});

export default messages;
