import {defineMessages} from 'react-intl';

const messages = defineMessages({
  counter: {
    id: 'ExampleImageSlider.counter',
    defaultMessage: 'Example {current} of {total}'
  },
  singleCounter: {
    id: 'ExampleImageSlider.singleCounter',
    defaultMessage: 'Example 1'
  }
});

export default messages;
