import React from 'react';
import {PillWrapper, IconWrapper} from './styles';
import FontAwesomeIcon from 'ui-components/Layout/FontAwesomeIcon';

const PillButton = ({title, icon, onClick, dataCy}) => {
  return (
    <PillWrapper onClick={onClick} dataCy={dataCy}>
      {icon !== undefined && (
        <IconWrapper>
          <FontAwesomeIcon actionId={icon} />
        </IconWrapper>
      )}
      <span data-cy={dataCy}>{title}</span>
    </PillWrapper>
  );
};

export default PillButton;
