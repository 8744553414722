import styled from 'styled-components';

//helpers
import {topicCardMetrics} from 'styles/metrics';

export const NewTopic = styled.div`
  padding: 0 ${topicCardMetrics.dragHandleWidth}px;
  @media (max-width: 720px) {
    padding: 0;
  }
`;
