import styled from 'styled-components';
import flex from 'styles/flex';

//components
import $MediaPicker from 'components/MediaPicker';
import {TransparentButton} from 'styles/styled-components/shared-components';

//constants
const width = 140;
const height = 70;

export const MediaPicker = styled($MediaPicker).attrs({
  backgroundAspectRatio: width / height,
  backgroundSizes: `${width}px`
})`
  height: ${height}px;
  width: ${width}px;
`;

export const UploadAndChangeButton = styled.div`
  ${flex.horizontal} ${flex.centerHorizontalV};
`;

export const ChangeButton = styled(TransparentButton)`
  margin-left: 15px;
`;
