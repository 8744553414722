import {defineMessages} from 'react-intl';

const messages = defineMessages({
  shareInstruction: {
    id: 'EditInstruction.shareInstruction',
    defaultMessage: 'Share instruction'
  },
  shareChecklist: {
    id: 'shareChecklist',
    defaultMessage: 'Share checklist'
  },
  hideInstructionSuccess: {
    id: 'EditInstruction.hideSuccess',
    defaultMessage: 'Instruction will be hidden.'
  },
  hideChecklistSuccess: {
    id: 'hideChecklistSuccess',
    defaultMessage: 'Checklist will be hidden.'
  },
  hideInstructionFailure: {
    id: 'EditInstruction.hideFailure',
    defaultMessage: 'Error setting your instruction to be hidden.'
  },
  hideChecklistFailure: {
    id: 'hideChecklistFailure',
    defaultMessage: 'Error setting your checklist to be hidden.'
  },
  showInstructionSuccess: {
    id: 'EditInstruction.showSuccess',
    defaultMessage: 'Instruction will be shown.'
  },
  showChecklistSuccess: {
    id: 'showChecklistSuccess',
    defaultMessage: 'Checklist will be shown.'
  },
  showInstructionFailure: {
    id: 'EditInstruction.showFailure',
    defaultMessage: 'Error setting your instruction to be shown.'
  },
  showChecklistFailure: {
    id: 'showChecklistFailure',
    defaultMessage: 'Error setting your checklist to be shown.'
  },
  cloneSuccess: {
    id: 'EditInstruction.cloneSuccess',
    defaultMessage: 'Instruction has been duplicated.'
  },
  cloneError: {
    id: 'EditInstruction.cloneError',
    defaultMessage: 'Error duplicating instruction.'
  },
  removeInstruction: {
    id: 'EditInstruction.removeInstruction',
    defaultMessage: 'Remove instruction'
  },
  confirmation: {
    id: 'EditInstruction.confirmation',
    defaultMessage: 'Are you sure you want to remove your instruction ‘{title}’?'
  },
  archiveSuccess: {
    id: 'EditInstruction.archiveSuccess',
    defaultMessage: 'Instruction has been removed.'
  },
  archiveFailure: {
    id: 'EditInstruction.archiveFailure',
    defaultMessage: 'Error removing instruction.'
  },
  applySignOff: {
    id: 'common.actions.applySignOff',
    defaultMessage: 'Apply sign-off'
  }
});

export default messages;
