import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {Popover} from 'antd';
import PropTypes from 'prop-types';

import {StyledUntranslatedIndicator, StyledContent} from './styles';
import messages from './messages';

class UntranslatedIndicator extends Component {
  static propTypes = {
    size: PropTypes.number.isRequired,
    content: PropTypes.string,
    position: PropTypes.string,
    right: PropTypes.number,
    top: PropTypes.number
  };

  render() {
    const {
      size,
      content,
      intl: {formatMessage},
      ...restProps
    } = this.props;

    if (content) {
      return (
        <Popover arrowPointAtCenter content={formatMessage(messages.notTransalted)} placement="top" trigger="hover">
          <StyledUntranslatedIndicator size={size} data-testid="indicator-wrapper" {...restProps}>
            <StyledContent data-testid="content-wrapper">{content}</StyledContent>
          </StyledUntranslatedIndicator>
        </Popover>
      );
    }

    return <StyledUntranslatedIndicator size={size} data-testid="indicator-wrapper" {...restProps} />;
  }
}

export default injectIntl(UntranslatedIndicator);
