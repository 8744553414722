import {defineMessages} from 'react-intl';

const messages = defineMessages({
  introText: {
    id: 'WebhookUrlSetting.introText',
    defaultMessage: `Ask your IT team to set up a secure endpoint that sends instant notifications to your preferred channel, such as Microsoft Teams or webhook.`
  },
  webhookUrlFieldLabel: {
    id: 'WebhookUrlSetting.webhookUrlFieldLabel',
    defaultMessage: 'Webhook URL'
  },
  submitButtonLabel: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  webhookUrlUpdateSuccess: {
    id: 'webhookUrlUpdateSuccess',
    defaultMessage: 'Webhook URL updated'
  },
  webhookUrlUpdateFailure: {
    id: 'webhookUrlUpdateFailure',
    defaultMessage: 'Failed to update webhook URL'
  }
});

export default messages;
