import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  title: {
    id: 'WorkspacesView.title',
    defaultMessage: 'Workspaces'
  },
  workspaceDescription: {
    id: 'common.description.workspaceDescriptionV2',
    defaultMessage:
      'A Workspace is a centralized hub of Guides tailored to a specific area of operations (eg. one site, one line, or one department).'
  },
  edit: {
    id: 'WorkspacesView.edit',
    defaultMessage: 'Edit'
  },
  name: {
    id: 'common.forms.name',
    defaultMessage: 'Name'
  },
  managementTeam: {
    id: 'common.forms.managementTeam',
    defaultMessage: 'Management Team'
  },
  managementTeamDescription: {
    id: 'common.description.managementTeamDescription',
    defaultMessage: 'The team responsible for managing the workspace, such as the name, URL, credentials, and branding.'
  },
  defaultTeam: {
    id: 'common.forms.defaultTeam',
    defaultMessage: 'Default Team'
  },
  defaultTeamDescription: {
    id: 'common.description.defaultTeamDescription',
    defaultMessage:
      ' New users are added to this Team when logging in to a Workspace with their Company Account (SSO) for the first time.'
  },
  overview: {
    id: 'common.forms.overview',
    defaultMessage: 'Overview'
  },
  teams: {
    id: 'common.title.teams',
    defaultMessage: 'Teams'
  },
  protectedContent: {
    id: 'WorkspacesView.protectedContent',
    defaultMessage: 'Protected Content'
  },
  archiveDefaultDomain: {
    id: 'WorkspacesView.archiveDefaultDomain',
    defaultMessage: "Default workspace cant't be removed."
  },
  archiveWorkspace: {
    id: 'WorkspacesView.archiveWorkspace',
    defaultMessage: 'Remove workspace'
  },
  archiveWorkspaceConfirm: {
    id: 'WorkspacesView.archiveWorkspaceConfirm',
    defaultMessage: 'You are about to remove this workspace.'
  },
  archiveWorkspaceBody: {
    id: 'WorkspacesView.archiveWorkspaceBody',
    defaultMessage: 'No one will be able to access it.'
  },
  archiveWorkspaceSuccess: {
    id: 'WorkspacesView.archiveWorkspaceSuccess',
    defaultMessage: 'Workspace has been removed.'
  },
  archiveWorkspaceFailure: {
    id: 'WorkspacesView.archiveWorkspaceFailure',
    defaultMessage: 'Error removing workspace.'
  },
  warning: {
    id: 'common.warning',
    defaultMessage: 'Warning'
  },
  searchWorkspaces: {
    id: 'WorkspacesView.searchWorkspaces',
    defaultMessage: 'Search workspaces'
  },
  noWorkspaces: {
    id: 'WorkspacesView.noWorkspaces',
    defaultMessage: 'There are no Workspaces created yet.'
  },
  noFilteredWorkspaces: {
    id: 'WorkspacesView.noFilteredWorkspaces',
    defaultMessage: 'No Workspaces found for {searchTerm}'
  }
});

export default messages;
