import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';

import StepsTabs from 'components/StepsTabs';
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';

import {Team} from 'api/team/queries';
import {teamOptions} from 'api/team/query-options';
import {EditTeam, SaveGuideApprovalSettings} from 'api/team/mutations';
import {editTeamOptions, saveGuideApprovalSettingsOptions} from 'api/team/mutation-options';

import messages from './messages';

@inject('store')
@graphql(Team, teamOptions)
@graphql(EditTeam, editTeamOptions)
@graphql(SaveGuideApprovalSettings, saveGuideApprovalSettingsOptions)
@observer
class EditTeamView extends Component {
  componentWillUnmount() {
    const {
      store: {
        editTeamPage: {stepsStore}
      }
    } = this.props;

    stepsStore.stopListeningToRouteChange();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store: {
        editTeamPage: {setTeam, setGuideApproval, guideApprovalEnabledForPlatform, setGuideApprovalFlag},
        platform: {guideApproval}
      },
      data: {team}
    } = nextProps;

    if (team) {
      setTeam(team);
      setGuideApproval(team.guideApproval);
    }
    if (guideApprovalEnabledForPlatform === null) {
      setGuideApprovalFlag(guideApproval);
    }
  }

  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {editTeamPage, platform} = store;
    const {guideApproval} = platform;
    const {setSteps, setGuideApprovalFlag, reset} = editTeamPage;

    reset();
    setGuideApprovalFlag(guideApproval);
    setSteps();
    editTeamPage.stepsStore.startListeningToRouteChange();

    const translations = mapValues(
      pick(messages, [
        'teamDetails',
        'users',
        'guideApproval',
        'notifications',
        'workspaces',
        'teamUpdateSuccess',
        'teamUpdateFailure'
      ]),
      message => formatMessage(message)
    );

    editTeamPage.setTranslations(translations);
  }

  componentDidMount() {
    const {editTeamMutation, saveGuideApprovalSettingsMutation, store} = this.props;
    const {editTeamPage} = store;
    editTeamPage.setEditTeamMutation(editTeamMutation);
    editTeamPage.setSaveGuideApprovalSettingsMutation(saveGuideApprovalSettingsMutation);
  }

  render() {
    const {
      store: {editTeamPage}
    } = this.props;
    const {loading, stepsStore, team, goToTeamsPage, form} = editTeamPage;

    if (!stepsStore) {
      return null;
    }

    return (
      <Container>
        {team?.name && <ViewTitle title={team.name} data-cy={'team-name-edit'} />}
        <StepsTabs
          mode={'edit'}
          loading={loading}
          form={form}
          stepsStore={stepsStore}
          page={editTeamPage}
          customSecondaryButtonText={messages.backToTeams}
          cancel={goToTeamsPage}
          omitPrimaryButton={true}
          getCurrentStepSpecificConfig={editTeamPage.getCurrentStepSpecificConfig}
        />
      </Container>
    );
  }
}

export default injectIntl(EditTeamView);
