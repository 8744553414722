import {observable, action, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

//helpers
import {TOPIC_TITLE_TYPE} from 'config/enums';
import {bindField, syncTitleWithSlug} from 'shared/utils/input-utils';

//components
import Field from 'ui-components/Field';
import ButtonGroup from 'ui-components/ButtonGroup';
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {
  StyledRadio,
  StyledSlug,
  StyledTitleTypePreview,
  StyledUnderlinedTextbox,
  TitleTypePicker,
  TitleTypePickerOptions,
  TopicDetails
} from './styles';

//messages
import messages from './messages';

@inject('store')
@observer
class TopicDetailsComponent extends Component {
  @observable titleOption = TOPIC_TITLE_TYPE.VISIBLE;

  titleFieldOptions = {
    onChangeReaction: e => syncTitleWithSlug(e, this.props.topicStore.form)
  };

  cancel = () => {
    const {topicStore} = this.props;
    topicStore.reset();
  };

  @action setTitleOption = option => {
    const {
      topicStore: {form}
    } = this.props;
    if (option === TOPIC_TITLE_TYPE.VISIBLE) {
      form.$('collapsible').sync(false);
      form.$('visible').sync(true);
    }

    if (option === TOPIC_TITLE_TYPE.COLLAPSIBLE) {
      form.$('collapsible').sync(true);
      form.$('visible').sync(true);
    }

    if (option === TOPIC_TITLE_TYPE.HIDDEN) {
      form.$('collapsible').sync(false);
      form.$('visible').sync(false);
    }

    this.titleOption = option;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  UNSAFE_componentWillMount() {
    const {intl, topic, topicStore, mode} = this.props;
    const {formatMessage} = intl;

    const translations = mapValues(pick(messages, ['createSuccess', 'updateError', 'updateSuccess']), message =>
      formatMessage(message)
    );

    topicStore.setTranslations(translations);

    if (mode === 'new') {
      return;
    }
    this.titleOption = topicStore.getInitialTitleOption(topic);
  }

  handleFormSubmit = event => {
    const {topicStore, submitMutation} = this.props;

    event.preventDefault();
    topicStore.submit(submitMutation);
  };

  handleKeyDown = event => {
    const {topicStore} = this.props;

    if (event.key === 'Escape') {
      topicStore.cancel();
    }
  };

  render() {
    const {
      intl: {formatMessage},
      topic,
      topicStore
    } = this.props;
    const {form} = topicStore;

    if (topic) {
      form.$('id').sync(topic.id);
    }

    return (
      <TopicDetails onKeyDown={this.handleKeyDown} onSubmit={this.handleFormSubmit}>
        <Field
          field="title"
          form={form}
          inputComponent={
            <StyledUnderlinedTextbox
              autoFocus
              placeholder={formatMessage(messages.newTopic)}
              {...bindField(form, 'title', this.titleFieldOptions)}
            />
          }
        />
        <StyledSlug field="slug" form={form} placeholder={formatMessage(messages.newTopic)} />
        <TitleTypePicker>
          <StyledTitleTypePreview type={this.titleOption} />
          <TitleTypePickerOptions>
            <StyledRadio
              checked={this.titleOption === TOPIC_TITLE_TYPE.VISIBLE}
              onChange={() => this.setTitleOption(TOPIC_TITLE_TYPE.VISIBLE)}
            >
              <FormattedMessage {...messages.showTitle} />
            </StyledRadio>
            <StyledRadio
              checked={this.titleOption === TOPIC_TITLE_TYPE.COLLAPSIBLE}
              onChange={() => this.setTitleOption(TOPIC_TITLE_TYPE.COLLAPSIBLE)}
            >
              <FormattedMessage {...messages.collapseTitle} />
            </StyledRadio>
            <StyledRadio
              checked={this.titleOption === TOPIC_TITLE_TYPE.HIDDEN}
              onChange={() => this.setTitleOption(TOPIC_TITLE_TYPE.HIDDEN)}
            >
              <FormattedMessage {...messages.hideTitle} />
            </StyledRadio>
          </TitleTypePickerOptions>
        </TitleTypePicker>
        <ButtonGroup
          primary={{
            label: formatMessage(messages.saveTopic),
            onClick: this.handleFormSubmit,
            disabled: !topicStore.saveButtonEnabled,
            loading: topicStore.loading,
            type: 'submit',
            dataCy: 'save-topic'
          }}
          secondary={{
            label: formatMessage(messages.cancel),
            onClick: topicStore.cancel
          }}
        />
      </TopicDetails>
    );
  }
}

export default injectIntl(TopicDetailsComponent);
