import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import constant from 'lodash/constant';

//helpers
import notification from 'utils/notification-utils';

//data
import {SetCampaignStatus} from 'api/campaign/mutations';
import {setCampaignStatusOptions} from 'api/campaign/mutation-options';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';

//styles
import {Paragraph} from './styles';

//messages
import messages from './messages';

/**
 * A dialog component used for campaign status change prompts.
 */
@inject('store')
@injectIntl
@graphql(SetCampaignStatus, setCampaignStatusOptions)
@observer
class ChangeCampaignStatusDialog extends Component {
  /**
   * Triggers when the user hits the cancel button, clicks outside the dialog,
   * or presses the Escape key.
   */
  handleCancel = () => {
    const {store} = this.props;
    const {changeCampaignStatusDialog: dialog} = store;
    const {isSubmitting, reset} = dialog;

    /*
     * Disabling the cancel button is not enough as this handler will also
     * trigger when the user clicks outside the dialog or presses the Escape
     * key.
     */
    if (isSubmitting) {
      return;
    }

    reset();
  };

  /**
   * Triggers when the user hits the submit button.
   */
  handleSubmit = () => {
    const {intl, setCampaignStatusMutation, store} = this.props;
    const {formatMessage} = intl;
    const {changeCampaignStatusDialog: dialog} = store;
    const {isActivate, isSubmitting, setIsSubmitting, campaign, targetStatus} = dialog;

    /* Just for consistency with the `handleCancel` handler. */
    if (isSubmitting) {
      return;
    }

    const successMessage = messages[isActivate ? 'activateSuccess' : 'deactivateSuccess'];
    const failureMessage = messages[isActivate ? 'activateFailure' : 'deactivateFailure'];

    /*
     * Disallow closing the dialog and clicking neither the submit nor the
     * cancel buttons.
     */
    setIsSubmitting(true);

    setCampaignStatusMutation({
      id: campaign.id,
      status: targetStatus
    })
      .then(() => {
        notification.success(formatMessage(successMessage));

        /*
         * Close the dialog and reset its state, reverting `isSubmitting` back
         * to `false`.
         */
        dialog.reset();
      })
      .catch(() => {
        notification.error(formatMessage(failureMessage));

        /*
         * Just revert `isSubmitting` back to `false` without closing the
         * dialog, allowing the user to retry hitting the submit button.
         */
        setIsSubmitting(false);
      });
  };

  openConfirmDeactivateDialog = () => {
    const {changeCampaignStatusDialog, confirmDeactivateDialog} = this.props.store;

    changeCampaignStatusDialog.toggleVisibility();
    confirmDeactivateDialog.open(this.handleSubmit, this.handleCancel);
  };

  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {changeCampaignStatusDialog: dialog} = store;
    const {isActivate, isSubmitting, opened, campaign} = dialog;
    const instructionCount = opened && campaign.numInstructionsAppliedTo;

    const descriptionMessagesConfig = {
      activated: constant('activateDescriptionTitle'),
      notActivated: () => (instructionCount ? 'deactivateDescription' : 'deactivateDescription0Instructions')
    };

    const descriptionMessageKey = isActivate
      ? descriptionMessagesConfig.activated()
      : descriptionMessagesConfig.notActivated();

    const descriptionMessage = messages[descriptionMessageKey];
    const submitMessage = messages[isActivate ? 'activateSubmit' : 'continueDeactivateSubmit'];
    const titleMessage = messages[isActivate ? 'activateTitle' : 'deactivateTitle'];

    /*
     * A getter functions instead of a plain object is used to disable the
     * cancel button while the form is being submitted, so that it could not be
     * closed in the middle of the process.
     */
    const getActions = cancelAction => [
      {
        label: isSubmitting ? '' : formatMessage(submitMessage),
        loading: isSubmitting,
        onClick: isActivate ? this.handleSubmit : this.openConfirmDeactivateDialog,
        dataTestId: 'primary-button'
      },
      {
        ...cancelAction,
        disabled: isSubmitting
      }
    ];

    return (
      <Dialog
        actions={getActions}
        isOpen={opened}
        onCancel={this.handleCancel}
        title={formatMessage(titleMessage)}
        titleIconId="warning"
      >
        <Paragraph>
          <FormattedMessage {...descriptionMessage} values={{instructionCount}} />
        </Paragraph>
        {isActivate && (
          <Paragraph>
            <FormattedMessage {...messages.activateDescriptionSubtitle} />
          </Paragraph>
        )}
      </Dialog>
    );
  }
}

export default ChangeCampaignStatusDialog;
