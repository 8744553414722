import {action, computed, observable, makeObservable} from 'mobx';

//helpers
import {INSTRUCTION_EDIT_MODE} from 'config/enums';
import {INSTRUCTION_TYPE} from 'shared/enums';
import {get} from 'lodash';

//forms
import EditInstructionForm from 'stores/forms/edit-instruction-form';

class EditInstructionPage {
  @observable instructionId = null;
  @observable selectedMediaUrl = null;
  @observable selectedMediaMetadata = null;
  @observable submitting = false;
  @observable topicId = null;
  @observable checklistTemplateId = null;
  form = EditInstructionForm;

  @action
  reset = () => {
    this.instructionId = null;
    this.selectedMediaUrl = null;
    this.selectedMediaMetadata = null;
    this.submitting = false;
    this.topicId = null;
    this.checklistTemplateId = null;

    this.form.reset();
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get isChecklist() {
    return this.type === INSTRUCTION_TYPE.CHECKLIST;
  }

  @computed
  get canSubmit() {
    return this.form.isValid && !this.submitting;
  }

  @computed
  get mode() {
    if (!this.topicId) {
      return null;
    }

    if (!this.instructionId) {
      return INSTRUCTION_EDIT_MODE.CREATE;
    }

    if (this.instructionId) {
      return INSTRUCTION_EDIT_MODE.UPDATE;
    }

    return null;
  }

  @action
  setSelectedMediaUrl = value => {
    this.selectedMediaUrl = value;
  };

  @action
  setChecklistTemplateId = value => {
    this.checklistTemplateId = value;
  };

  @action
  setSelectedMediaMetadata = value => {
    this.selectedMediaMetadata = value;
  };

  @action
  setSubmitting = value => {
    this.submitting = value;
  };

  @action
  startEditing = instruction => {
    const {id, media, slug, title, topicId, type} = instruction;

    this.instructionId = id;
    this.selectedMediaUrl = get(media, 'url', '');
    this.selectedMediaMetadata = get(media, 'metadata', {});
    this.topicId = topicId;
    this.type = type;

    this.form.update({
      mediaId: media ? media.id : '',
      slug,
      title
    });
  };

  @action
  startEditingNewInstruction = ({topicId, isChecklist} = {}) => {
    this.instructionId = null;
    this.selectedMediaUrl = null;
    this.selectedMediaMetadata = null;
    this.topicId = topicId;
    this.type = isChecklist ? INSTRUCTION_TYPE.CHECKLIST : INSTRUCTION_TYPE.INSTRUCTION;
    this.checklistTemplateId = null;

    this.form.reset();
  };

  @action
  stopEditing = () => {
    this.instructionId = null;
    this.selectedMediaUrl = null;
    this.selectedMediaMetadata = null;
    this.topicId = null;
    this.checklistTemplateId = null;

    this.form.reset();
  };

  @action
  submit = (createMutation, updateMutation) => {
    if (!this.canSubmit) {
      return;
    }

    this.setSubmitting(true);

    this.mutate(createMutation, updateMutation)
      .then(() => {
        this.stopEditing();
        this.setSubmitting(false);
      })
      .catch(() => {
        this.setSubmitting(false);
      });
  };

  mutate = (createMutation, updateMutation) => {
    const instruction = this.form.values();
    instruction.mediaId = instruction.mediaId || null;

    if (this.mode === INSTRUCTION_EDIT_MODE.CREATE) {
      instruction.topicId = this.topicId;
      instruction.type = this.type;
      instruction.checklistTemplateId = (this.isChecklist && this.checklistTemplateId) || null;

      return createMutation({instruction});
    }

    return updateMutation({
      id: this.instructionId,
      instruction
    });
  };
}

export default EditInstructionPage;
