import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer, Observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import flowRight from 'lodash/flowRight';

//data
import {AvailableLanguages} from 'api/platform/queries';
import {availableLanguagesOptions} from 'api/platform/query-options';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

//messages
import messages from './messages';

//components
import PlatformLanguageSelector from './PlatformLanguageSelector';

const PlatformLanguageSelectionDialogConnector = ({store, intl, ...props}) => {
  const {
    availableLanguagesQuery: {loading, error, availableLanguages}
  } = props;

  const {formatMessage} = intl;

  const {app, unsavedChangesDialog, platform} = store;

  const [selectedLocale, setSelectedLocale] = useState(platform.selectedLocale.locale);

  const onSave = async () => {
    await unsavedChangesDialog.check();
    if (platform.selectedLocale.locale !== selectedLocale) {
      platform.setCurrentLanguage(selectedLocale);
    }
    app.isPlatformLanguageDialogOpen = false;
    trackEvent(EVENT_TYPES.SELECT_PLATFORM_LANGUAGE, {language: platform.activeLocale});
  };

  const onCancel = () => {
    app.isPlatformLanguageDialogOpen = false;
  };

  const shouldRender = !loading && !error;

  return (
    <Observer>
      {() =>
        shouldRender && (
          <PlatformLanguageSelector
            title={formatMessage(messages.title)}
            saveButtonLabel={formatMessage(messages.saveButtonLabel)}
            isOpen={app.isPlatformLanguageDialogOpen}
            currentLocale={selectedLocale}
            languageOptions={availableLanguages}
            onSave={onSave}
            onChange={setSelectedLocale}
            onCancel={onCancel}
          />
        )
      }
    </Observer>
  );
};

const decorate = flowRight([
  inject('store'),
  graphql(AvailableLanguages, availableLanguagesOptions),
  observer,
  injectIntl
]);

const PlatformLanguageSelectionDialog = decorate(PlatformLanguageSelectionDialogConnector);

export default PlatformLanguageSelectionDialog;
