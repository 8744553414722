import styled from 'styled-components';

import FiltersContainer from 'components/FiltersContainer';
import DataTable from 'ui-components/Layout/DataTable';
import $Combobox from 'ui-components/Combobox';
import {CellWrapper as DefaultCellWrapper} from 'ui-components/Layout/DataTable/styles';
import {commonPalette} from 'shared/styles/palette';

export const InfoWrapper = styled.span`
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

export const ExtraInfoWrapper = styled(InfoWrapper)`
  color: ${commonPalette.txLightGrey};
`;

export const StyledTable = styled(DataTable)`
  .rt-table {
    overflow: auto !important;
  }

  .rt-tbody {
    overflow: unset !important;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }
`;

export const StyledCombobox = styled($Combobox).attrs({
  buttonProps: {
    style: {
      textAlign: 'left'
    }
  }
})``;

export const FiltersWrapper = styled(FiltersContainer)`
  margin-bottom: 30px;
`;

export const CellWrapper = styled(DefaultCellWrapper)`
  display: block;
  flex: unset;
`;
