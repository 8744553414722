import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  selectTeam: {
    id: 'EditUser.selectTeam',
    defaultMessage: 'Team'
  },
  selectRole: {
    id: 'EditUser.selectRole',
    defaultMessage: 'Role'
  },
  noJobTitle: {
    id: 'common.forms.noJobTitle',
    defaultMessage: 'No Job Title'
  },
  selectJobTitle: {
    id: 'UserRole.selectJobTitle',
    defaultMessage: 'Job Title'
  },
  addToAnotherTeam: {
    id: 'EditUser.addToAnotherTeam',
    defaultMessage: 'Add user to another team'
  },
  noAvailableTeams: {
    id: 'EditUser.noAvailableTeams',
    defaultMessage: 'There are no available teams'
  }
});

export default messages;
