import gql from 'graphql-tag';

export const CreateTeam = gql`
  mutation createTeam($newTeam: TeamCreate!, $teamMembers: [NewTeamMemberCreate], $domainIds: [String]) {
    createTeam(newTeam: $newTeam, teamMembers: $teamMembers, domainIds: $domainIds) {
      name
      id
    }
  }
`;

export const EditTeam = gql`
  mutation editTeam($editTeam: TeamEdit!) {
    editTeam(editTeam: $editTeam) {
      name
      id
    }
  }
`;

export const ArchiveTeam = gql`
  mutation archiveTeam($id: String!, $reassignToTeamId: String, $reassignToUserId: String, $comment: String) {
    archiveTeam(id: $id, reassignToTeamId: $reassignToTeamId, reassignToUserId: $reassignToUserId, comment: $comment) {
      result
    }
  }
`;

export const AddDomainsToTeam = gql`
  mutation addDomainsToTeam($teamId: String!, $domainIds: [String]!) {
    addDomainsToTeam(teamId: $teamId, domainIds: $domainIds) {
      result
    }
  }
`;

export const RemoveTeamFromDomain = gql`
  mutation removeTeamFromDomain($teamId: String!, $domainId: String!) {
    removeTeamFromDomain(teamId: $teamId, domainId: $domainId) {
      result
    }
  }
`;

export const CreateTeamUsers = gql`
  mutation createTeamMembers($createTeamMembers: [TeamMemberCreate]) {
    createTeamMembers(createTeamMembers: $createTeamMembers) {
      result
    }
  }
`;

export const UpdateTeamUserRole = gql`
  mutation updateTeamMemberRole($updateTeamMemberRole: TeamMemberCreate, $confirmCancellingPendingApprovals: Boolean) {
    updateTeamMemberRole(
      updateTeamMemberRole: $updateTeamMemberRole
      confirmCancellingPendingApprovals: $confirmCancellingPendingApprovals
    ) {
      role
    }
  }
`;

export const UpdateTeamUserRoleWithResult = gql`
  mutation updateTeamMemberRoleWithResult(
    $updateTeamMemberRole: TeamMemberCreate
    $confirmCancellingPendingApprovals: Boolean
  ) {
    updateTeamMemberRoleWithResult(
      updateTeamMemberRole: $updateTeamMemberRole
      confirmCancellingPendingApprovals: $confirmCancellingPendingApprovals
    ) {
      result
      error
    }
  }
`;

export const SaveGuideApprovalSettings = gql`
  mutation saveGuideApprovalSettings(
    $teamId: String
    $guideApproval: GuideApproval
    $approverIds: [String]
    $confirmCancellingPendingApprovals: Boolean
  ) {
    saveGuideApprovalSettings(
      teamId: $teamId
      guideApproval: $guideApproval
      approverIds: $approverIds
      confirmCancellingPendingApprovals: $confirmCancellingPendingApprovals
    ) {
      result
    }
  }
`;

export const RemoveTeamMember = gql`
  mutation removeTeamMember($teamId: String, $userId: String, $confirmCancellingPendingApprovals: Boolean) {
    removeTeamMember(
      teamId: $teamId
      userId: $userId
      confirmCancellingPendingApprovals: $confirmCancellingPendingApprovals
    ) {
      result
      error
    }
  }
`;
