import gql from 'graphql-tag';

export const Domains = gql`
  query domains($adminOfManagementTeamsOnly: Boolean) {
    domains(adminOfManagementTeamsOnly: $adminOfManagementTeamsOnly) {
      id
      name
      slug
      isDefault
      managementTeam {
        id
        name
      }
      defaultTeam {
        id
        name
      }
    }
  }
`;

export const DomainsLite = gql`
  query domainsLite($adminOfManagementTeamsOnly: Boolean) {
    domains(adminOfManagementTeamsOnly: $adminOfManagementTeamsOnly) {
      id
      name
      slug
      isDefault
    }
  }
`;

export const DomainsIncludingArchived = gql`
  query domainsIncludingArchived {
    domainsIncludingArchived {
      id
      name
    }
  }
`;

export const DomainsForAnalytics = gql`
  query domainsForAnalytics {
    domainsForAnalytics {
      id
      name
      slug
      isDefault
    }
  }
`;

export const Domain = gql`
  query domain($id: String!) {
    domain(id: $id) {
      id
      name
      slug
      hasPassword
      theme {
        id
        name
      }
      isDefault
      loginOptions
      teams {
        id
        name
      }
      defaultTeam {
        id
      }
      managementTeam {
        id
      }
      xMRealityTeam {
        id
        name
      }
      dispatchSiteId
      allowedIpRanges
    }
  }
`;
