import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {ResetPasswordView, AlreadyLoggedIn} from './styles';

//components
import ResetPasswordForm from 'components/ResetPasswordForm';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@observer
class ResetPasswordComponent extends Component {
  render() {
    const {store} = this.props;

    return (
      <ResetPasswordView>
        {store.auth.user ? (
          <AlreadyLoggedIn>
            <FormattedMessage {...messages.alreadyLoggedIn} />
          </AlreadyLoggedIn>
        ) : (
          <ResetPasswordForm />
        )}
      </ResetPasswordView>
    );
  }
}

export default ResetPasswordComponent;
