import React, {ChangeEvent, KeyboardEvent} from 'react';
import {Pagination as PaginationWrapper, CenterPaginationWrapper, StyledPaginationInput} from './styles';
import {LeftButton, RightButton} from 'ui-components/Layout/DataTable/styles';

function getSafePage(page: number | '', currentPage: number, totalPages: number) {
  if (Number.isNaN(page)) {
    page = currentPage;
  }
  return Math.min(Math.max(page as number, 0), totalPages);
}

type PaginationProps = {
  totalPages: number;
  currentPage: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  nextPageTitle?: string;
  prevPageTitle?: string;
};

const Pagination = (props: PaginationProps) => {
  const {prevPageTitle, nextPageTitle, totalPages, currentPage, setPageIndex} = props;

  const isPrevDisabled = currentPage === 0;
  const isNextDisabled = currentPage >= totalPages - 1;

  const [pageInput, setPageInput] = React.useState<string>(String(currentPage + 1));

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const val = e.target.value.replace(/^0+/, '');

    const p = +val;
    if (val === '') {
      setPageInput('');
      return;
    }
    setPageInput(String(getSafePage(p, currentPage, totalPages)));
  };

  const changePage = (page: number) => {
    page = getSafePage(page, currentPage, totalPages);
    if (currentPage !== page) {
      setPageIndex(page);
    }
  };

  const applyPage = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    changePage(pageInput === '' ? currentPage : +pageInput - 1);
  };

  return (
    <PaginationWrapper>
      <LeftButton
        style={{backgroundColor: 'white'}}
        title={prevPageTitle}
        disabled={isPrevDisabled}
        onClick={() => setPageIndex(currentPage - 1)}
        iconId={'angle-left'}
      />

      <CenterPaginationWrapper>
        <StyledPaginationInput
          onKeyPress={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              applyPage(e);
            }
          }}
          type={'number'}
          value={pageInput}
          min="1"
          max={totalPages}
          onChange={onChange}
          onBlur={applyPage}
        />
        /<span>{totalPages}</span>
      </CenterPaginationWrapper>

      <RightButton
        style={{backgroundColor: 'white'}}
        title={nextPageTitle}
        disabled={isNextDisabled}
        onClick={() => setPageIndex(currentPage + 1)}
        iconId={'angle-right'}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
