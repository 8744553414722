import styled from 'styled-components';
import $DataTable from 'ui-components/Layout/DataTable';
import Button from 'ui-components/Button';
import Field from 'ui-components/Field';
import {commonPalette} from 'shared/styles/palette';

export const TeamNameField = styled(Field).attrs({
  styles: {Input: {fontSize: '32px', fontWeight: 300}},
  inline: true
})`
  margin-bottom: 40px;
  width: fit-content;
`;

export const ContentWrapper = styled.div`
  height: 100%;
`;

export const DataTable = styled($DataTable)`
  .ReactTable {
    margin-bottom: 1rem !important;
  }

  .rt-table {
    overflow: auto !important;
  }

  .rt-tbody {
    overflow: unset !important;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }

  .rt-tr-group:hover {
    ${() => RemoveButton} {
      opacity: 1;
    }
  }
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'close'
})`
  cursor: pointer;
`;

export const NameWrapper = styled.div``;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Url = styled.div`
  color: ${commonPalette.txGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
`;

export const StyledLink = styled.a`
  color: ${commonPalette.txGrey};

  :hover,
  :focus {
    color: ${commonPalette.txGrey};
  }
`;
