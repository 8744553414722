import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Chip, ContentWrapper, Label, LabelWrapper, RemoveButton} from './styles';
import {Popover} from 'antd';
import Icon from '../Icon';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import {styleable} from 'shared/decorators';

@styleable
export default class ChipComponent extends Component {
  static defaultProps = {
    iconOnRight: false
  };

  static propTypes = {
    dataCy: PropTypes.string,
    iconId: PropTypes.string,
    iconOnRight: PropTypes.bool,
    isTypeFar: PropTypes.bool,
    label: PropTypes.string,
    popoverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    popoverTitle: PropTypes.string,
    onRemove: PropTypes.func,
    style: PropTypes.object,
    maxLabelLength: PropTypes.number
  };

  renderChip() {
    const {dataCy, label, iconId, iconOnRight, onRemove, isTypeFar, maxLabelLength} = this.props;

    return (
      <Chip data-cy={dataCy || iconId} {...this.props}>
        <ContentWrapper>
          <LabelWrapper iconOnRight={iconOnRight} maxLabelLength={maxLabelLength}>
            {iconId && <Icon iconId={iconId} solid={!isTypeFar} />}
            <Label>{label}</Label>
          </LabelWrapper>
          {onRemove && <RemoveButton iconId="close" onClick={onRemove} />}
        </ContentWrapper>
      </Chip>
    );
  }

  render() {
    const {popoverTitle, popoverContent} = this.props;

    if (popoverTitle || popoverContent) {
      let content;

      if (popoverContent && isString(popoverContent)) {
        content = popoverContent;
      } else if (popoverContent && isArray(popoverContent)) {
        content = popoverContent.map((item, key) => {
          return <p key={key}>- {item}</p>;
        });
      }

      return (
        <Popover title={popoverTitle} content={content} arrowPointAtCenter>
          {this.renderChip()}
        </Popover>
      );
    }

    return this.renderChip();
  }
}
