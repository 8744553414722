import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {DropTarget} from 'react-dnd';
import {findDOMNode} from 'react-dom';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import NewTopic from 'components/NewTopic';

//styles
import {NewTopicCard, PlusIcon, Title} from './styles';

//messages
import messages from './messages';

//drag-and-drop
import {target} from './dnd';
import {DND_TYPE as INSTRUCTION_DND_TYPE} from 'components/TopicList/DraggableInstructionCard/dnd';

@inject('store')
@DropTarget(INSTRUCTION_DND_TYPE, target, connect => ({
  connectDropTarget: connect.dropTarget()
}))
@observer
class NewTopicCardComponent extends Component {
  handleHostInnerRef = ref => {
    const {connectDropTarget} = this.props;
    const node = findDOMNode(ref);
    connectDropTarget(node);
  };

  render() {
    const {store} = this.props;
    const {newTopicPage: page} = store;

    if (page.opened) {
      return <NewTopic />;
    }

    return (
      <NewTopicCard onClick={this.props.onClick} ref={this.handleHostInnerRef} data-cy="new-topic-placeholder">
        <Title>
          <PlusIcon />
          <FormattedMessage {...messages.title} />
        </Title>
      </NewTopicCard>
    );
  }
}

export default NewTopicCardComponent;
