import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {boxShadow} from 'styles/shadows';

export const MarkdownEditor = styled.div`
  .rdw-editor-wrapper {
    ${flex.vertical};
    cursor: text;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .rdw-editor-toolbar {
    background: none;
    border-radius: 4px;
    border: 0;
    box-shadow: ${boxShadow.floatingToolbar};
    display: block; // IE11 won't handle flex here
    font-size: 14px;
    margin: 0;
    padding: 0;
    position: relative;
    white-space: nowrap;
    z-index: 0;
  }

  .rdw-editor-toolbar-inner {
    border-radius: 4px;
    overflow-x: auto;
  }

  .rdw-editor-main {
    border: 0;
    display: block;
    outline: 0;
    resize: none;
    width: 100%;
  }
`;
