import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';
import {commonPalette} from 'shared/styles/palette';

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 32px;
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 32
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 120px;
  position: absolute;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 150px;
  align-self: stretch;
  width: 100%;
  ${({hasBorderBottom}) => (hasBorderBottom ? 'margin-top: 6px' : '')};
  ${({hasBorderTop}) => (hasBorderTop ? 'border-top: 1px solid #eee;' : '')};
  ${({hasBorderBottom}) => (hasBorderBottom ? 'border-bottom: 1px solid #eee;' : '')};
  @media (max-width: 820px) {
    gap: 170px;
  }
  @media (max-width: 740px) {
    gap: 110px;
  }
`;
export const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const StyledText = styled.div`
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
`;

export const StyledSubText = styled.div`
  color: var(--neutrals-grey, ${commonPalette.txGrey});
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 4px;
  background-color: #eee;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 20px;
`;

export const StyledList = styled.ul`
  list-style-type: none;
  margin-left: 15px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-left: 100px;
  ${({hasMoreSelectedTags}) => (hasMoreSelectedTags ? 'margin-bottom: 100px' : 'margin-bottom: 50px')};
`;
