import styled from 'styled-components';

//helpers
import {visuallyHidden} from 'shared/utils/sc-utils';

export const Filename = styled.span`
  margin-left: 32px;
`;

export const HiddenFileInput = styled.input.attrs({
  type: 'file'
})`
  ${visuallyHidden};
`;
