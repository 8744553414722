import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {Table, Th, Tr, SortingComponent, StickyHeader, Td, TrCollapsable, StyledIcon} from './styles';
import ExpandableRow from './ExpandableRow';
import Button from 'ui-components/Button';
import messages from './messages';
import {Column, DataItem, Sort} from './types';
import {getSortedData} from './utils';

type ExpandableRowRecursiveTableProps = {
  title: string;
  data: DataItem[];
  noDataMessage?: string;
  action?: (item: DataItem) => void;
  onClick: (itemId: string) => void;
  childrenQuery: any;
  childrenQueryOptions: any;
  actionIcon: string;
  actionPopover: string;
  queryOptions: (item: DataItem) => any;
  showAction?: (item: DataItem) => boolean;
  sortable?: boolean;
  platformName?: string;
  extraColumns?: Column[];
  enableExpand: boolean;
  intl: any;
  indexed?: boolean;
};

const ExpandableRowRecursiveTable = ({
  title,
  data,
  noDataMessage,
  action,
  onClick,
  childrenQuery,
  childrenQueryOptions,
  actionIcon,
  actionPopover,
  queryOptions,
  showAction,
  sortable,
  platformName,
  extraColumns,
  enableExpand = true,
  intl,
  indexed = false
}: ExpandableRowRecursiveTableProps) => {
  const [sorting, setSorting] = useState<Sort>('asc');
  const {formatMessage} = intl;

  const handleSorting = () => {
    if (sortable) {
      if (sorting === 'asc') {
        setSorting('desc');
      } else {
        setSorting('asc');
      }
    }
  };

  return (
    <Table style={{marginTop: '20px'}}>
      <StickyHeader>
        <Tr>
          <Th left width="50px"></Th>
          <Th left onClick={handleSorting} sortable={sortable} data-testid={`sorting-button`}>
            {title}
            {sortable && <SortingComponent sorting={sorting} />}
          </Th>
          {extraColumns?.length &&
            extraColumns.map(c => (
              <Th key={c.key} width="150px" style={{minWidth: '150px'}} data-cy={c.dataCy ?? ''}>
                {c.title}
              </Th>
            ))}
          <Th right width="70px" style={{minWidth: '70px'}}>
            {formatMessage(messages.actions)}
          </Th>
        </Tr>
      </StickyHeader>
      <tbody>
        {!data ||
          (data.length === 0 && (
            <Tr>
              <Td>{noDataMessage}</Td>
            </Tr>
          ))}
        {!!data?.length && !!platformName && (
          <TrCollapsable header={true}>
            <Td width="50px">
              <StyledIcon name="earth" />
            </Td>
            <Td left>{platformName}</Td>
            {extraColumns?.length && extraColumns.map(c => <Td key={c.key}>{c.platformData}</Td>)}
            <Td right></Td>
          </TrCollapsable>
        )}
        {getSortedData(data, sorting).map((item: DataItem, index: number) => (
          <ExpandableRow
            data-testid="row"
            childrenQuery={childrenQuery}
            childrenQueryOptions={childrenQueryOptions}
            onClick={onClick}
            key={item.id}
            item={item}
            isParent={item.children}
            showAction={showAction}
            queryOptions={queryOptions}
            action={
              action
                ? (i: DataItem) => (
                    <Button
                      iconId={actionIcon}
                      onClick={e => {
                        e.stopPropagation();
                        action(i);
                      }}
                      popoverContent={actionPopover}
                      popoverPlacement={'bottom'}
                      iconStyle={{color: '#666', fontSize: 12}}
                      data-cy={`${actionPopover.split(' ').join('-')}-${item.name.split(' ').join('-')}`}
                    />
                  )
                : undefined
            }
            depth={1}
            extraColumnsFields={extraColumns?.map(column => column.dataField) ?? undefined}
            enableExpand={enableExpand}
            sorting={sorting}
            index={index}
            indexed={indexed}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default injectIntl(ExpandableRowRecursiveTable);
