import {defineMessages} from 'react-intl';

const messages = defineMessages({
  setRequiredLevel: {
    id: 'setRequiredLevel',
    defaultMessage: 'Set Required Level'
  }
});

export default messages;
