import {defineMessages} from 'react-intl';

const messages = defineMessages({
  actions: {
    id: 'MediaActions.actions',
    defaultMessage: 'Actions'
  },
  removeImage: {
    id: 'MediaActions.removeImage',
    defaultMessage: 'Remove image'
  },
  imageEdit: {
    id: 'MediaActions.imageEdit',
    defaultMessage: 'Edit image'
  },
  addShapes: {
    id: 'MediaActions.addShapes',
    defaultMessage: 'Add shapes'
  },
  notAvailable: {
    id: 'MediaActions.notAvailable',
    defaultMessage: 'No actions available'
  },
  enableAudio: {
    id: 'MediaActions.enableAudio',
    defaultMessage: 'Enable audio'
  },
  successMessage: {
    id: 'MediaActions.successMessage',
    defaultMessage: 'Audio setting updated!'
  },
  errorMessage: {
    id: 'MediaActions.errorMessage',
    defaultMessage: 'Error updating audio setting!'
  },
  visibleOnStepsAndInstructions: {
    id: 'MediaActions.visibleOnStepsAndInstructions',
    defaultMessage: 'Visible on steps and instructions'
  },
  duplicateImage: {
    id: 'duplicateImage',
    defaultMessage: 'Duplicate image'
  },
  withoutShapes: {
    id: 'withoutShapes',
    defaultMessage: 'Without shapes'
  }
});

export default messages;
