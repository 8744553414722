import styled from 'styled-components';
import $MenuItem from '../MenuItem';

export const Submenu = styled.div`
  margin: 7.5px 0 0 9px;
  border-left: 1px solid #ececec;
  position: relative;
`;

export const SubmenuItemWrapper = styled.div`
  padding-left: 16px;

  & > * {
    height: 20px;
    margin-bottom: 20px;
  }

  & > last-child {
    margin-bottom: 0;
  }
`;

export const SelectedIndicator = styled.div`
  height: 20px;
  border-left: 2px solid #298784;
  margin-left: -1px;
  position: absolute;
  top: ${p => p.index * 20 * 2}px;
  transition: all 0.2s ease-in-out;
`;

export const SubmenuItem = styled($MenuItem)`
  height: 20px;
`;
