import {OperationVariables, useLazyQuery} from '@apollo/client';
import React, {useCallback} from 'react';

export const useAbortableQuery: typeof useLazyQuery = (query, options) => {
  const abortControllerRef = React.useRef(new AbortController());
  const [loadQuery, queryResults] = useLazyQuery(query, options);

  const cancellableLoadQuery = useCallback(
    (options: Partial<OperationVariables> | undefined) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      return loadQuery({
        ...options,
        context: {
          ...options?.context,
          queryDeduplication: false,
          fetchOptions: {
            ...options?.context?.fetchOptions,
            signal: abortControllerRef.current.signal
          }
        }
      });
    },
    [loadQuery]
  );
  return [cancellableLoadQuery, queryResults];
};
