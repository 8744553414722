import React from 'react';

import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';
import {renderCampaignAnswerText} from 'utils/campaign-answer-utils';

import StarAnswer from './StarAnswer';

interface CampaignAnswerProps {
  answer: string;
  campaign: {
    question: {
      kind: string;
    };
  };
  locale: string;
  translations: unknown;
}

const CampaignAnswer: React.FC<CampaignAnswerProps> = ({answer, campaign, locale, translations}) => {
  const {kind} = campaign.question;

  const isStarKind = kind === CAMPAIGN_QUESTION_TYPE_ENUMS.STAR;

  return isStarKind ? (
    <StarAnswer answer={answer} />
  ) : (
    <span>{renderCampaignAnswerText({answer, campaign, locale, translations})}</span>
  );
};

export default CampaignAnswer;
