import gql from 'graphql-tag';

export const CreateStep = gql`
  mutation createStep($step: StepCreate!, $isDuplicate: Boolean) {
    createStep(step: $step, isDuplicate: $isDuplicate) {
      id
    }
  }
`;

export const DeleteStep = gql`
  mutation deleteStep($id: String!) {
    deleteStep(id: $id) {
      result
    }
  }
`;

export const UpdateStep = gql`
  mutation updateStep($id: String!, $step: StepUpdate!) {
    updateStep(id: $id, step: $step) {
      id
    }
  }
`;

export const RepositionSteps = gql`
  mutation repositionSteps($stepsAndPositions: [StepPosition]!) {
    repositionSteps(stepsAndPositions: $stepsAndPositions) {
      result
    }
  }
`;
