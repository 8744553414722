import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';

import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';
import Field from 'ui-components/Field';
import Spinner from 'shared/components/Spinner';
import {commonPalette} from 'shared/styles/palette';

import {TeamsLite} from 'api/team/queries';
import {teamsLiteOptions} from 'api/team/query-options';
import {CopyToGuide} from 'api/guide/mutations';
import {copyToGuide} from 'api/guide/mutation-options';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import messages from './messages';
import {WrapperSpinner, StyledInfo, TeamSelect, dialogStyle} from './styles';

@inject('store')
@graphql(TeamsLite, teamsLiteOptions)
@graphql(CopyToGuide, copyToGuide)
@observer
class CopyToDialog extends Component {
  UNSAFE_componentWillMount() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {copyToDialog} = store;

    const translations = mapValues(pick(messages, ['copyToGuideSuccess', 'copyToGuideFailure']), message =>
      formatMessage(message)
    );

    copyToDialog.setTranslations(translations);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      teamsQuery: {teams},
      store: {
        copyToDialog: {setTeams}
      }
    } = nextProps;

    if (teams) {
      setTeams(teams);
    }
  }

  handleCopyGuideClick = () => {
    const {
      store: {
        copyToDialog: {teamCopyToForm, copyGuide}
      },
      copyGuideMutaion
    } = this.props;

    copyGuide(copyGuideMutaion);

    trackEvent(EVENT_TYPES.COPY_GUIDE, {teamId: teamCopyToForm.values().teamId});
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        copyToDialog: {opened, reset, teams, loading, teamCopyToForm, copyingGuide}
      }
    } = this.props;

    if (loading) {
      return (
        <Dialog actions={() => []} isOpen={opened} onCancel={reset} dialogStyle={dialogStyle}>
          <WrapperSpinner>
            <Spinner color={'#fff'} />
          </WrapperSpinner>
        </Dialog>
      );
    }

    const showTeamPicker = teams && teams.length > 0;

    return (
      <Dialog
        actions={[
          {
            label: copyingGuide ? <Spinner color={'#fff'} size={25} /> : formatMessage(messages.save),
            onClick: this.handleCopyGuideClick,
            disabled: !teamCopyToForm.values().teamId || copyingGuide
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={formatMessage(messages.copyThisGuide)}
        bodyStyle={{color: commonPalette.txBlack}}
        buttonGroupStyle={{marginTop: 5}}
      >
        <StyledInfo>{formatMessage(messages.copyToInfo)}</StyledInfo>
        {showTeamPicker ? (
          <Field
            inputComponent={
              <TeamSelect
                label={formatMessage(messages.team)}
                form={teamCopyToForm}
                field="teamId"
                options={teams}
                showSearch={true}
                teamsLength={teams.length}
              />
            }
          />
        ) : (
          <FormattedMessage {...messages.noTeamsAvailableCopy} />
        )}
      </Dialog>
    );
  }
}

export default injectIntl(CopyToDialog);
