/* eslint-disable indent */
import React, {Component, useCallback, useEffect, useState} from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick, debounce} from 'lodash';

import ViewTitle from 'ui-components/Layout/ViewTitle';
import SearchBar from 'ui-components/Layout/SearchBar';
import Container from 'ui-components/Layout/Container';
import Chip from 'ui-components/Chip';
import UsersListMenu from 'components/UsersListMenu';

import {UsersPaginated, UsersPaginatedQueryData} from 'api/user/queries';
import {useAbortableQuery} from 'hooks/useAbortableQuery';

import views from 'config/views';

import messages from './messages';
import {DataTable, StyledMenu, CheckIcon, CenteredWrapper} from './styles';
import {HeaderWrapper, CellWrapper, StyledText} from 'ui-components/Layout/DataTable/styles';
import {maxNumberOfRowsPerPage} from 'ui-components/Layout/DataTable/constants';
import PaginationLoading from 'ui-components/PaginationLoading';
import {maskPhoneNumber} from 'utils/phone-number';

@inject('store')
@observer
class UsersListView extends Component<{
  data: {
    usersPaginated?: {
      results: {
        id: string;
        email: string;
        phoneNumber: string;
        fullName: string;
        isPlatformAdmin: boolean;
        isDisabled: boolean;
        ssoId: string;
        lastLoginAt: string;
      }[];
    };
    loading: boolean;
    error: unknown;
  };

  sorted: {id: string; desc: boolean}[] | null;
  onSortedChange: (sort: any) => void;
  currentPage: number;
  onPageChange: (page: number) => void;
  searchFilter?: string | null;
  searchTerm?: string | null;
  onSearchTermChange: (searchTerm: string) => void;
  store?: any;
  intl: InjectedIntl;
  totalPages: number | null;
}> {
  state = {
    showUserRoleViewers: false
  };

  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      router
    } = store;
    const {editUserPage} = store;
    const {canManageUsers, canViewUsers} = user;
    if (!canManageUsers && !canViewUsers) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, ['disableSuccess', 'disableFailure', 'enableSuccess', 'enableFailure']),
      message => message && formatMessage(message)
    );

    editUserPage.setTranslations(translations);
  }

  componentDidMount() {
    const {store} = this.props;
    const {
      auth: {user},
      app
    } = store;
    const {canManageUsers} = user;

    const actionMenuItems = [
      {
        id: 'create',
        view: 'newUser'
      }
    ];

    if (canManageUsers) {
      app.setActionMenuItems(actionMenuItems);
    }
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  private editUser(user) {
    const {store} = this.props;
    store.router.goTo(views.editUser, {id: user.id});
  }

  private readonly getUserStatus = user => {
    const {
      intl: {formatMessage}
    } = this.props;

    if (user.isDisabled) {
      return (
        <Chip
          borderGrey
          dataCy={`deactivated-${user.fullName.split(' ').join('-')}`}
          iconId="disable"
          label={formatMessage(messages.deactivated)}
          popoverContent={formatMessage(messages.deactivatedDescription)}
        />
      );
    }

    if (!user.lastLoginAt) {
      return (
        <Chip
          borderGrey
          dataCy={`invited-${user.fullName.split(' ').join('-')}`}
          iconId="time"
          isTypeFar
          label={formatMessage(messages.invited)}
          popoverContent={formatMessage(messages.invitedDescription)}
        />
      );
    }

    return <CheckIcon />;
  };

  private readonly hasActions = (authUser, listUser) => {
    const isIPA = authUser.isIPA;
    const isPlatformAdmin = authUser.isPlatformAdmin;
    const canInvite = authUser.canManageUsers;
    const isActive = !listUser.isDisabled;
    const hasLoggedIn = !!listUser.lastLoginAt;
    const canDisableUser = (isIPA || isPlatformAdmin) && isActive;
    const canEnableUser = (isIPA || isPlatformAdmin) && !isActive;
    const canDeleteUser = isIPA || isPlatformAdmin;
    const canResendInvite = canInvite && isActive && !hasLoggedIn;

    return canDisableUser || canEnableUser || canDeleteUser || canResendInvite;
  };

  private readonly handleOnSearchTermChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {onSearchTermChange} = this.props;
    onSearchTermChange(e.target.value);
  };

  private readonly getNoDataText = (formatMessage, searchFilter?: string | null) =>
    searchFilter
      ? formatMessage(messages.noFilteredUsers, {searchTerm: searchFilter})
      : formatMessage(messages.noUsers);

  render() {
    const {
      data,
      intl: {formatMessage},
      store,
      totalPages,
      currentPage,
      onPageChange,
      onSortedChange,
      searchTerm,
      searchFilter,
      sorted
    } = this.props;

    const {
      auth: {user}
    } = store;

    const {usersPaginated, loading} = data;
    const users = usersPaginated?.results || [];

    const availableActionsForList = users.filter(listUser => this.hasActions(user, listUser)).length > 0;

    const noUsersMessage = !loading && !users.length ? this.getNoDataText(formatMessage, searchFilter) : null;

    const columns = [
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.fullName)}</HeaderWrapper>,
        accessor: 'fullName',
        resizable: true,
        width: 250,
        Cell: ({value, original: {isDisabled}}) => (
          <CellWrapper paddingLeft disabled={isDisabled}>
            <span title={value}>{value}</span>
          </CellWrapper>
        )
      },
      {
        Header: <StyledText marginLeft={20}>{formatMessage(messages.contactMethod)}</StyledText>,
        accessor: (row: {email: string; phoneNumber: string}) => {
          if (row.phoneNumber) {
            return maskPhoneNumber(row.phoneNumber);
          }
          return row.email;
        },
        resizable: true,
        id: 'email',
        width: 250,
        Cell: ({value, original: {isDisabled, phoneNumber}}) => (
          <CellWrapper disabled={isDisabled}>
            <StyledText marginLeft={20}>
              <span data-cy={phoneNumber} title={value}>
                {value}
              </span>
            </StyledText>
          </CellWrapper>
        )
      },
      {
        Header: '',
        accessor: 'isPlatformAdmin',
        Cell: x =>
          x.original.isPlatformAdmin && (
            <CellWrapper paddingLeft>
              <Chip
                lightGreen
                dataCy={`platform-admin-${x.original.fullName.split(' ').join('-')}`}
                label={formatMessage(messages.platformAdmin)}
                popoverContent={formatMessage(messages.platformAdminDescription)}
              />
            </CellWrapper>
          ),
        sortable: false,
        width: 150
      },
      {
        Header: <StyledText centered>{formatMessage(messages.status)}</StyledText>,
        Cell: x => (
          <CellWrapper paddingLeft>
            <CenteredWrapper>{this.getUserStatus(x.original)}</CenteredWrapper>
          </CellWrapper>
        ),
        sortable: false,
        width: 150
      },
      ...(availableActionsForList
        ? [
            {
              Header: <StyledText centered>{formatMessage(messages.actions)}</StyledText>,
              Cell: x =>
                this.hasActions(user, x.original) && (
                  <StyledMenu
                    dataCy={`menu-users-list-${x.original.fullName.split(' ').join('-')}`}
                    dropdownMenu={<UsersListMenu user={x.original} />}
                  />
                ),
              sortable: false,
              width: 60
            }
          ]
        : [])
    ];

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.title)}
          right={
            <SearchBar
              placeholder={formatMessage(messages.searchWords)}
              onChange={this.handleOnSearchTermChanged}
              value={searchTerm}
              width={250}
            />
          }
        />
        {noUsersMessage && <div>{noUsersMessage}</div>}
        {!noUsersMessage && (
          <DataTable
            loading={loading}
            data={users}
            columns={columns}
            manual
            pages={totalPages}
            page={currentPage}
            hoverEffect
            sorted={
              sorted || {
                id: 'fullName',
                desc: false
              }
            }
            getTrGroupProps={(_, rowInfo) => ({
              onClick: () => this.editUser(rowInfo.original)
            })}
            LoadingComponent={() => <PaginationLoading loading={loading} message={messages.loading} />}
            onSortedChange={onSortedChange}
            onPageChange={onPageChange}
          />
        )}
      </Container>
    );
  }
}

const UsersListViewWithQuery = observer(({intl}: {intl: InjectedIntl}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [previousData, setPreviousData] = useState<UsersPaginatedQueryData | null>(null);
  const [sortOrder, setSortOrder] = useState<{field: string; order: 'asc' | 'desc'}[]>([]);

  const debouncedUpdateSearchFilter = useCallback(
    debounce((newSearchTerm: string | null) => {
      setCurrentPage(0);
      setSearchFilter(newSearchTerm);
    }, 300),
    [setSearchFilter, setCurrentPage]
  );

  const [usersPaginatedQuery, {data, loading, error}] = useAbortableQuery<UsersPaginatedQueryData>(UsersPaginated, {
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    if (data?.usersPaginated?.results) {
      setPreviousData(data);
    }
  }, [data]);

  useEffect(() => {
    usersPaginatedQuery({
      variables: {
        limit: maxNumberOfRowsPerPage,
        offset: currentPage * maxNumberOfRowsPerPage,
        ...(searchFilter && {
          filters: {
            or: {
              email: {contains: searchFilter},
              ssoId: {eq: searchFilter},
              fullName: {contains: searchFilter}
            }
          }
        }),
        ...(sortOrder.length && {sortBy: sortOrder})
      }
    });
  }, [sortOrder, currentPage, searchFilter]);

  const totalPages = previousData?.usersPaginated.totalCount
    ? Math.ceil(previousData?.usersPaginated.totalCount / maxNumberOfRowsPerPage)
    : null;

  const handlePageChange = useCallback(
    (page: number) => {
      const newPage = Math.max(0, Math.min(page, totalPages ?? 0));
      setCurrentPage(newPage);
    },
    [totalPages]
  );

  const handleSortedChange = useCallback(
    (
      sort: {
        id: string;
        desc: boolean;
      }[]
    ) => {
      setSortOrder(sort.map(({id, desc}) => ({field: id, order: desc ? 'desc' : 'asc'})));
    },
    []
  );

  const handleSearchTermChange = useCallback(
    (newSearchTerm: string) => {
      const MIN_SEARCH_TERM_LENGTH = 3;
      const MAX_SEARCH_TERM_LENGTH = 50;

      if (newSearchTerm.length >= MAX_SEARCH_TERM_LENGTH) {
        newSearchTerm = newSearchTerm.substring(0, MAX_SEARCH_TERM_LENGTH);
      }
      setSearchTerm(newSearchTerm);

      const newSearchFilter = newSearchTerm.length >= MIN_SEARCH_TERM_LENGTH ? newSearchTerm.trim() : null;

      debouncedUpdateSearchFilter(newSearchFilter);
    },
    [setSearchFilter, setSearchTerm]
  );

  return (
    <UsersListView
      data={{usersPaginated: previousData?.usersPaginated, loading, error}}
      intl={intl}
      totalPages={totalPages}
      onSortedChange={handleSortedChange}
      currentPage={currentPage}
      onPageChange={handlePageChange}
      searchFilter={searchFilter}
      searchTerm={searchTerm}
      onSearchTermChange={handleSearchTermChange}
      sorted={sortOrder?.map(({field, order}) => ({id: field, desc: order === 'desc'})) || []}
    />
  );
});

export default injectIntl(UsersListViewWithQuery);
