import {useCallback, useContext, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import {MobXProviderContext} from 'mobx-react';
import {countBy, omit} from 'lodash';

import {SkillLevels, UserSkillMatrix, UserSkillMatrixData, UserSkillMatrixVariables} from 'api/skill-matrix/queries';
import {userSkillMatrixOptions} from 'api/skill-matrix/query-options';
import {TeamUserRoles} from 'api/team/queries';
import {teamUserRolesOptions} from 'api/team/query-options';

export const useSmartSkillsUserQueries = () => {
  const {store} = useContext(MobXProviderContext);

  const teamUserRolesQuery = useQuery(TeamUserRoles, teamUserRolesOptions.options({store}));
  const userSkillMatrixQuery = useQuery<UserSkillMatrixData, UserSkillMatrixVariables>(
    UserSkillMatrix,
    userSkillMatrixOptions.options({store})
  );
  const skillLevelsQuery = useQuery(SkillLevels);

  return {
    teamUserRolesQuery,
    userSkillMatrixQuery,
    skillLevelsQuery
  };
};

export const APPROVED = 'APPROVED';
export const NO_LEVEL = 'NO LEVEL';

const getLevelPosition = (skill: UserSkillMatrixData['userSkillMatrix'][number]) => skill.currentLevel?.position ?? -1;

const getLevelsOverview = (userSkillMatrix: UserSkillMatrixData['userSkillMatrix']) => {
  const sortedSkills = [...userSkillMatrix].sort((a, b) => getLevelPosition(b) - getLevelPosition(a));
  return Object.entries(countBy(sortedSkills, skill => skill.currentLevel?.name ?? NO_LEVEL)).map(([level, count]) => ({
    level,
    count
  }));
};

export const useSmartSkillsUserOverview = (params: {
  userSkillMatrix: UserSkillMatrixData['userSkillMatrix'];
  skillId?: string;
  team?: any;
  userId?: string;
}) => {
  const {userSkillMatrix, userId, skillId, team} = params;
  const {totalApprovedSkills, totalGaps, levelsOverview} = useMemo(
    () => ({
      totalApprovedSkills: userSkillMatrix.filter(({status}) => status === APPROVED).length,
      totalGaps: userSkillMatrix.filter(({status}) => status !== APPROVED).length,
      levelsOverview: getLevelsOverview(userSkillMatrix)
    }),
    [userSkillMatrix]
  );
  const selectedSkill = useMemo(() => {
    if (typeof skillId !== 'string') return null;
    return userSkillMatrix.find(skill => skill.id === skillId);
  }, [skillId, userSkillMatrix]);

  const teamUser = useMemo(() => team?.teamMembers.find(element => element.user.id === userId), [team]);

  return {
    totalApprovedSkills,
    totalGaps,
    levelsOverview,
    selectedSkill,
    teamUser
  };
};

export const useSmartSkillsUserRouter = () => {
  const {store} = useContext(MobXProviderContext);
  const {router} = store;

  const handleSelectSkill = useCallback(
    (skill: {id: string}) => {
      router.goTo(router.currentRoute, router.params, {...router.queryParams, skillId: skill.id});
    },
    [router]
  );

  const handleCloseDrawer = useCallback(() => {
    const queryParamsWithoutSkillId = omit(router.queryParams, 'skillId');
    router.goTo(router.currentRoute, router.params, queryParamsWithoutSkillId);
  }, [router]);

  return {
    handleSelectSkill,
    handleCloseDrawer,
    userId: router.params?.userId,
    skillId: router.queryParams?.skillId
  };
};
