import {defineMessages} from 'react-intl';

const messages = defineMessages({
  checklist: {
    id: 'checklist',
    defaultMessage: 'Checklist'
  },
  noChecklistsSubmitted: {
    id: 'noChecklistsSubmitted',
    defaultMessage: 'There are no Checklists submitted yet.'
  },
  guide: {
    id: 'guide',
    defaultMessage: 'Guide'
  },
  date: {
    id: 'date',
    defaultMessage: 'Date'
  },
  dateAndTime: {
    id: 'dateAndTime',
    defaultMessage: 'Date and time'
  },
  submittedName: {
    id: 'SignOffResults.submittedName',
    defaultMessage: 'Submitted name'
  },
  score: {
    id: 'score',
    defaultMessage: 'Score'
  },
  noFilteredChecklists: {
    id: 'noFilteredChecklists',
    defaultMessage: 'No checklists found for ‘{searchTerm}’'
  },
  viewResults: {
    id: 'viewResults',
    defaultMessage: 'View results'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  errorChecklists: {
    id: 'errorChecklists',
    defaultMessage: 'An error occurred while loading the checklist results.'
  },
  today: {
    id: 'SignoffDateFilters.today',
    defaultMessage: 'Today'
  },
  yesterday: {
    id: 'SignoffDateFilters.yesterday',
    defaultMessage: 'Yesterday'
  },
  last7days: {
    id: 'SignoffDateFilters.last7days',
    defaultMessage: 'Last 7 days'
  },
  last30days: {
    id: 'SignoffDateFilters.last30days',
    defaultMessage: 'Last 30 days'
  },
  checklistExportTriggerSuccess: {
    id: 'checklistExportTriggerSuccess',
    defaultMessage: 'CSV export started. You will receive an email with a download link.'
  },
  checklistExportTriggerFailure: {
    id: 'checklistExportTriggerFailute',
    defaultMessage: "Couldn't export results"
  },
  filterByChecklist: {
    id: 'filterByChecklist',
    defaultMessage: 'Filter by checklist'
  },
  filterByGuide: {
    id: 'filterByGuide',
    defaultMessage: 'Filter by Guide'
  },
  filterByWorkspace: {
    id: 'filterByWorkspace',
    defaultMessage: 'Filter by workspace'
  },
  filterByTeam: {
    id: 'GuideListFilters.filterByTeam',
    defaultMessage: 'Filter by Team'
  },
  workspace: {
    id: 'Share.workspace',
    defaultMessage: 'Workspace'
  },
  team: {
    id: 'common.forms.team',
    defaultMessage: 'Team'
  }
});

export default messages;
