import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {getPasswordComplexityChecker} from 'utils/validation-utils';

const getPlugins = () => {
  const checkPasswordComplexity = getPasswordComplexityChecker();

  return dvrWithAsync({
    checkPasswordComplexity: (value, _attr, _key, passes) => checkPasswordComplexity(value, passes)
  });
};

const fields = [
  {
    name: 'newPassword',
    rules: 'required|string|checkPasswordComplexity'
  },
  {
    name: 'newPasswordConfirm',
    rules: 'required'
  }
];

export default () => new MobxReactForm({fields}, {plugins: getPlugins()});
