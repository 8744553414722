import {defineMessages} from 'react-intl';

const messages = defineMessages({
  copyThisGuide: {
    id: 'copyThisGuide',
    defaultMessage: 'Copy this guide'
  },
  team: {
    id: 'common.forms.team',
    defaultMessage: 'Team'
  },
  noTeamsAvailableCopy: {
    id: 'noTeamsAvailableCopy',
    defaultMessage: 'There are no teams available to copy.'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  copyToInfo: {
    id: 'copyToInfo',
    defaultMessage:
      'Select the team that can edit and publish the copy of the guide. The new guide will be assigned to you'
  },
  copyToGuideSuccess: {
    id: 'copyToGuideSuccess',
    defaultMessage: 'Guide copied successfully.'
  },
  copyToGuideFailure: {
    id: 'copyToGuideFailure',
    defaultMessage: 'Error copying guide.'
  }
});

export default messages;
