import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

//helpers
import {MEDIA_PREVIEW_DEVICE, MEDIA_PREVIEW_TYPE} from 'config/enums';
import {EAGER_CACHE} from 'shared/media-config';
import {
  generateOptionString,
  getCloudinaryImageUrl,
  getCloudinaryVideoUrl,
  getExtensionFromUrl,
  getFixedExtensionFromUrl,
  getThumbnail,
  isVideoUrl
} from 'shared/utils/media-utils';

//components
import {ToggleOption} from 'styles/styled-components/form-styled-components';
import Icon from 'ui-components/Layout/Icon';
import {FormattedMessage} from 'components/FormattedComponents';
import CloudinaryPictureWithControls from 'shared/components/CloudinaryPictureWithControls';
import {GuideCardDesktop, GuideCardMobile} from 'shared/components/GuideCard';
import {InstructionCoverDesktop, InstructionCoverMobile} from 'shared/components/InstructionCover';
import {InstructionRowDesktop, InstructionRowMobile} from 'shared/components/InstructionRow';
import {StepCardDesktop, StepCardMobile} from 'shared/components/StepCard';
import {StepOverviewCardDesktop, StepOverviewCardMobile} from 'shared/components/StepOverviewCard';
import {VideoPlayerDesktop} from 'shared/components/VideoPlayer';

//styled-components
import {
  DualPreview,
  DualPreviewLandscape,
  DualPreviewPortrait,
  LargeLabel,
  MediaPreview,
  NotSelected,
  Select,
  SinglePreview,
  SmallLabel,
  Toggle,
  Toolbar
} from './styles';

//messages
import messages from './messages';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@observer
class MediaPreviewComponent extends Component {
  render() {
    const {
      store: {mediaPickerDialog: dialog}
    } = this.props;
    const {selectedMedia, previewDevice, previewType} = dialog;
    const shouldPreviewBeShown = Boolean(selectedMedia);

    return (
      <MediaPreview>
        {!shouldPreviewBeShown && (
          <NotSelected>
            <FormattedMessage {...messages.notSelected} />
          </NotSelected>
        )}

        {shouldPreviewBeShown && (
          <Toolbar>
            <LargeLabel>
              <FormattedMessage {...messages.viewer} />
            </LargeLabel>
            <SmallLabel>
              <FormattedMessage {...messages.type} />
            </SmallLabel>
            <Select
              onChange={this.handleTypeChange}
              options={this.previewTypeOptions}
              selectedValue={previewType}
              style={{maxWidth: 300}}
            />
            {this.hasMobile(previewType) && (
              <Toggle defaultValue={previewDevice} onChange={this.handleDeviceChange}>
                <ToggleOption value={MEDIA_PREVIEW_DEVICE.DESKTOP}>
                  <Icon size={18} name="preview-desktop" />
                </ToggleOption>
                <ToggleOption value={MEDIA_PREVIEW_DEVICE.MOBILE}>
                  <Icon size={18} name="preview-mobile" />
                </ToggleOption>
              </Toggle>
            )}
          </Toolbar>
        )}

        {shouldPreviewBeShown && this.renderPreview(previewType, previewDevice)}
      </MediaPreview>
    );
  }

  handleDeviceChange = ({target: {value: previewDevice}}) => {
    const {store} = this.props;
    store.mediaPickerDialog.setPreviewDevice(previewDevice);

    trackEvent(EVENT_TYPES.MEDIA_EDITOR_DEVICE_PREVIEW, {previewDevice});
  };

  handleTypeChange = previewType => {
    const {store} = this.props;
    store.mediaPickerDialog.setPreviewType(previewType);

    trackEvent(EVENT_TYPES.MEDIA_EDITOR_VIEWER_PREVIEW, {previewType});
  };

  hasMobile = previewType =>
    // There are somewhat different desktop and mobile versions
    // of the GuideCard component, though here in the preview
    // they look exactly the same.
    previewType !== MEDIA_PREVIEW_TYPE.GUIDE_OVERVIEW && previewType !== MEDIA_PREVIEW_TYPE.FULL_IMAGE;

  renderFullImage = () => {
    if (isVideoUrl(this.imageUrl)) {
      const type = getExtensionFromUrl(this.imageUrl);
      const options = generateOptionString(EAGER_CACHE['video'][0]);

      return (
        <SinglePreview>
          <VideoPlayerDesktop
            poster={getThumbnail(this.imageUrl)}
            src={getCloudinaryVideoUrl(this.imageUrl, type, options)}
            type={`video/${getFixedExtensionFromUrl(this.imageUrl)}`}
          />
        </SinglePreview>
      );
    }

    return (
      <SinglePreview>
        <CloudinaryPictureWithControls
          shouldShowSpinnerWhileLoading={true}
          sizes="260px"
          src={getCloudinaryImageUrl(this.imageUrl)}
          metadata={this.metadata}
        />
      </SinglePreview>
    );
  };

  renderPreview = (type, device) => {
    const {store} = this.props;

    if (type === MEDIA_PREVIEW_TYPE.GUIDE_OVERVIEW) {
      const GuideCard = device === MEDIA_PREVIEW_DEVICE.DESKTOP ? GuideCardDesktop : GuideCardMobile;

      return (
        <SinglePreview>
          <GuideCard imageSizes="260px" guide={this.exampleGuide} shouldShowSpinnerWhileLoading={true} />
        </SinglePreview>
      );
    }

    if (type === MEDIA_PREVIEW_TYPE.INSTRUCTION_OVERVIEW || type === MEDIA_PREVIEW_TYPE.INSTRUCTION_COVER) {
      const InstructionRow = device === MEDIA_PREVIEW_DEVICE.DESKTOP ? InstructionRowDesktop : InstructionRowMobile;

      return <InstructionRow instruction={this.exampleInstruction} shouldShowSpinnerWhileLoading={true} />;
    }

    if (type === MEDIA_PREVIEW_TYPE.STEP) {
      const StepCard = device === MEDIA_PREVIEW_DEVICE.DESKTOP ? StepCardDesktop : StepCardMobile;

      return (
        <DualPreview>
          <DualPreviewPortrait ratio={[2, 3]} scale={0.5}>
            <StepCard
              imageSizes="calc(50vw / 3 - 38px)"
              isCMS
              orientation="portrait"
              step={this.exampleStep}
              shouldShowSpinnerWhileLoading={true}
            />
          </DualPreviewPortrait>
          <DualPreviewLandscape ratio={[8, 3]} scale={0.5}>
            <StepCard
              imageSizes="calc(50vw / 3 - 38px)"
              isCMS
              orientation="landscape"
              step={this.exampleStep}
              shouldShowSpinnerWhileLoading={true}
            />
          </DualPreviewLandscape>
        </DualPreview>
      );
    }

    if (type === MEDIA_PREVIEW_TYPE.INSTRUCTION_COVER) {
      const InstructionCover =
        device === MEDIA_PREVIEW_DEVICE.DESKTOP ? InstructionCoverDesktop : InstructionCoverMobile;

      return (
        <DualPreview>
          <DualPreviewPortrait ratio={[2, 3]} scale={0.5}>
            <InstructionCover
              {...this.exampleInstructionCover}
              aspectRatio={2 / 3}
              imageSizes="calc(50vw / 3 - 38px)"
              shouldShowSpinnerWhileLoading={true}
            />
          </DualPreviewPortrait>
          <DualPreviewLandscape ratio={[8, 3]} scale={0.5}>
            <InstructionCover
              {...this.exampleInstructionCover}
              aspectRatio={8 / 3}
              imageSizes="calc(100vw / 3 - 76px)"
              shouldShowSpinnerWhileLoading={true}
            />
          </DualPreviewLandscape>
        </DualPreview>
      );
    }

    if (type === MEDIA_PREVIEW_TYPE.STEP_OVERVIEW) {
      const StepOverviewCard =
        device === MEDIA_PREVIEW_DEVICE.DESKTOP ? StepOverviewCardDesktop : StepOverviewCardMobile;

      return (
        <SinglePreview>
          <StepOverviewCard
            imageSizes="260px"
            step={this.exampleStep}
            shouldShowSpinnerWhileLoading={true}
            store={store}
          />
        </SinglePreview>
      );
    }

    return this.renderFullImage();
  };

  get exampleGuide() {
    const {intl} = this.props;
    const {formatMessage} = intl;

    return {
      imageUrl: this.imageUrl,
      metadata: this.metadata,
      title: formatMessage(messages.guideTitle)
    };
  }

  get exampleInstruction() {
    const {intl} = this.props;
    const {formatMessage} = intl;

    return {
      imageUrl: this.imageUrl,
      stepsNumber: 5,
      metadata: this.metadata,
      title: formatMessage(messages.instructionTitle)
    };
  }

  get exampleInstructionCover() {
    const {intl} = this.props;
    const {formatMessage} = intl;

    return {
      guideTitle: formatMessage(messages.guideTitle),
      imageUrl: this.imageUrl,
      metadata: this.metadata,
      instructionTitle: formatMessage(messages.instructionTitle)
    };
  }

  get exampleStep() {
    const {intl} = this.props;
    const {formatMessage} = intl;

    return {
      imageUrl: this.imageUrl,
      instructionHtml: formatMessage(messages.stepContent),
      number: 1,
      metadata: this.metadata
    };
  }

  get metadata() {
    const {store} = this.props;
    const {selectedMedia} = store.mediaPickerDialog;

    return selectedMedia ? selectedMedia.metadata : null;
  }

  get imageUrl() {
    const {store} = this.props;
    const {selectedMedia} = store.mediaPickerDialog;

    return selectedMedia ? selectedMedia.url : null;
  }

  get previewTypeOptions() {
    const {intl} = this.props;
    const {formatMessage} = intl;

    return [
      {
        id: MEDIA_PREVIEW_TYPE.GUIDE_OVERVIEW,
        name: formatMessage(messages.guideOverview)
      },
      {
        id: MEDIA_PREVIEW_TYPE.INSTRUCTION_OVERVIEW,
        name: formatMessage(messages.instructionOverview)
      },
      {
        id: MEDIA_PREVIEW_TYPE.STEP,
        name: formatMessage(messages.step)
      },
      {
        id: MEDIA_PREVIEW_TYPE.STEP_OVERVIEW,
        name: formatMessage(messages.stepOverview)
      },
      {
        id: MEDIA_PREVIEW_TYPE.INSTRUCTION_COVER,
        name: formatMessage(messages.instructionCover)
      },
      {
        id: MEDIA_PREVIEW_TYPE.FULL_IMAGE,
        name: formatMessage(messages.fullImage)
      }
    ];
  }
}

export default injectIntl(MediaPreviewComponent);
