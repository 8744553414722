import styled from 'styled-components';
import FontAwesome from 'react-fontawesome';
import {cond} from 'shared/utils/sc-utils';

const padding = 10;

export const InputWithIcon = styled.div`
  width: 100%;
`;

export const InputAndIcon = styled.div`
  height: 50px;
  position: relative;
  ${p => p.styles};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0 ${padding + 30}px 0 ${padding}px;
  font-size: 16px;
  font-weight: 300;
  color: #4a4c4d;
  border: solid 1px #dedede;
  outline: none;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px 0px #5a95ff;
  }

  ${p => cond(p.error, 'box-shadow: 0px 0px 2px 0px red;')};
`;

export const Icon = styled(FontAwesome)`
  position: absolute;
  right: ${padding + 2}px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 22px;
  font-size: 20px !important;
  color: #dedede;
`;

export const StyledError = styled.div`
  color: #ff6161;
  height: 18px;
  max-height: 18px;
  margin-top: 3px;
  font-size: 13px;
  opacity: ${p => (p.visible ? 1 : 0)};
`;
