import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  title: {
    id: 'AddWorkspaceTeams.title',
    defaultMessage: 'Add Teams to Workspace'
  },
  subtitle: {
    id: 'AddTeamWorkspace.subtitle',
    defaultMessage: 'Give this Team access to view and publish to the following Workspace(s).'
  },
  selectTeam: {
    id: 'AddWorkspaceTeams.selectTeam',
    defaultMessage: 'Select a team'
  },
  addTeamPicker: {
    id: 'AddWorkspaceTeams.addTeamPicker',
    defaultMessage: 'Add another team'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  addTeamSuccess: {
    id: 'AddWorkspaceTeams.addTeamSuccess',
    defaultMessage: 'Team has been added to the workspace'
  },
  addTeamFailure: {
    id: 'AddWorkspaceTeams.addTeamFailure',
    defaultMessage: 'Failed to add team to the workspace'
  },
  noTeamsAvailable: {
    id: 'AddWorkspaceTeams.noTeamsAvailable',
    defaultMessage: 'No teams available to add to this workspace'
  }
});

export default messages;
