import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkUniqueTeamName} from 'utils/validation-utils';
import {debounce} from 'lodash';

const asyncRules = {
  checkUniqueTeamName: debounce((value, attr, key, passes) => {
    return checkUniqueTeamName(value, passes);
  }, 500)
};

const plugins = dvrWithAsync(asyncRules);

const fields = [
  {
    name: 'name',
    rules: 'required|string|checkUniqueTeamName'
  },
  {
    name: 'guideApproval',
    rules: 'string|in:PARALLEL,SEQUENTIAL'
  },
  {
    name: 'parentId',
    rules: 'string'
  }
];

export default new MobxReactForm({fields}, {plugins});
