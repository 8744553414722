import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//messages
import messages from './messages';

//styled-components
import {Form, FontFamilySelector, UploadFont, Select} from './styles';

//components
import Field from 'ui-components/Field';

@inject('store')
@observer
class ThemeFontSettings extends Component {
  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      platform: {canUseCustomFonts},
      themePage
    } = store;
    const {fontSettingsForm: form, fontsForSelect} = themePage;

    const showCustomFontFields = canUseCustomFonts && form.$('fontFamily').value === 'Custom';

    return (
      <Form>
        <FontFamilySelector>
          <Select
            label={formatMessage(messages.fontFamily)}
            form={form}
            field="fontFamily"
            options={fontsForSelect}
            defaultValue={form.$('fontFamily').value}
            style={{width: 255}}
            dataCy="font-family"
          />

          {showCustomFontFields && (
            <UploadFont caption={formatMessage(messages.changeRegularFont)} form={form} field="customFontRegularUrl" />
          )}

          {showCustomFontFields && (
            <UploadFont caption={formatMessage(messages.changeBoldFont)} form={form} field="customFontBoldUrl" />
          )}

          {showCustomFontFields && (
            <UploadFont caption={formatMessage(messages.changeItalicFont)} form={form} field="customFontItalicUrl" />
          )}

          {showCustomFontFields && (
            <UploadFont
              caption={formatMessage(messages.changeBoldItalicFont)}
              form={form}
              field="customFontBoldItalicUrl"
            />
          )}

          {showCustomFontFields && (
            <Field
              form={form}
              field="customFontFallback"
              title={formatMessage(messages.customFontFallback)}
              size="small"
            />
          )}
        </FontFamilySelector>
      </Form>
    );
  }
}

export default injectIntl(ThemeFontSettings);
