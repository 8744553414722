import gql from 'graphql-tag';

export const CreateInstruction = gql`
  mutation createInstruction($instruction: InstructionCreate!) {
    createInstruction(instruction: $instruction) {
      id
    }
  }
`;

export const UpdateInstruction = gql`
  mutation updateInstruction($id: String!, $instruction: InstructionUpdate!) {
    updateInstruction(id: $id, instruction: $instruction) {
      id
    }
  }
`;

export const ArchiveInstruction = gql`
  mutation archiveInstruction($id: String!) {
    archiveInstruction(id: $id) {
      archived
    }
  }
`;

export const PublishInstruction = gql`
  mutation publishInstruction($id: String!) {
    publishInstruction(id: $id) {
      publishStatus
    }
  }
`;

export const SetInstructionToDraft = gql`
  mutation setInstructionToDraft($id: String!) {
    setInstructionToDraft(id: $id) {
      publishStatus
    }
  }
`;

export const CloneInstruction = gql`
  mutation cloneInstruction($id: String!, $topicId: String!) {
    cloneInstruction(id: $id, topicId: $topicId) {
      id
    }
  }
`;

export const SetInstructionCover = gql`
  mutation setInstructionCover($id: String!, $mediaId: String!, $locale: String!) {
    setInstructionCover(id: $id, mediaId: $mediaId, locale: $locale)
  }
`;

export const UpdateInstructionMedia = gql`
  mutation updateInstructionMedia($id: String!, $locale: String!, $mediaId: String) {
    updateInstructionMedia(id: $id, mediaId: $mediaId, locale: $locale) {
      id
    }
  }
`;

export const MoveInstruction = gql`
  mutation moveInstruction($id: String!, $guideId: String!) {
    moveInstruction(id: $id, guideId: $guideId) {
      result
    }
  }
`;

export const RequireSignature = gql`
  mutation requireSignature($id: String!) {
    requireSignature(id: $id) {
      result
    }
  }
`;

export const RemoveSignature = gql`
  mutation removeSignature($id: String!) {
    removeSignature(id: $id) {
      result
    }
  }
`;
