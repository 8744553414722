import styled from 'styled-components';

//components
import $StepControls from 'components/StepControls';

//helpers
import flex from 'styles/flex';
import {stepCardMetrics} from 'styles/metrics';

export const StepOverview = styled.div`
  margin-top: 40px;
  padding: 50px 20px 50px 50px;
  padding-top: 30px;
  position: relative;
`;

export const InnerWrapper = styled.div`
  ${flex.horizontal};
  ${flex.wrap};
  margin: ${-stepCardMetrics.gutterY}px 0 0 ${-stepCardMetrics.gutterX}px;
`;

export const StepOverviewItem = styled.div`
  flex-shrink: 0;
  margin: ${stepCardMetrics.gutterY}px 0 0 ${stepCardMetrics.gutterX}px;
  position: relative;
`;

export const StyledStepControls = styled($StepControls)`
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
  width: ${stepCardMetrics.gutterX}px;
`;
