import styled from 'styled-components';
import $Icon from 'react-fontawesome';

export const FeedbacksPaginatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 24px 0;
`;

export const FeedbacksPaginator = styled.div`
  display: inline-block;
`;

export const Button = styled.button`
  display: inline-block;
  border: none;
  background: none;
  width: 32px;
  color: #000000;
  opacity: 0.3;

  &:not([disabled]) {
    cursor: pointer;
    opacity: 0.6;
    &:hover {
      opacity: 1;
    }
  }
  &:focus {
    outline: none;
  }
`;

export const Page = styled(Button)`
  opacity: 1;
  font-weight: bold;
  &:not([disabled]) {
    opacity: 0.5;
    font-weight: normal;
  }
`;

export const Icon = styled($Icon)``;
