import React from 'react';
import {StyledChip, StyledChipContainer} from './styles';
import {Popover} from 'antd';

interface Tag {
  title: string;
  id: string;
}

interface Props {
  tags: Tag[];
}

const TagsChips: React.FC<Props> = ({tags}) => {
  const showFirstTag: boolean = tags?.length > 0;
  const showSecondTag: boolean = tags?.length > 1;
  const showCounter: boolean = tags?.length > 2;
  const tagsList = tags?.map(tag => tag.title).join(', ');
  return (
    <div
      onClick={e => {
        e.stopPropagation();
      }}
      role="button"
    >
      {/* getPopupContainer necessary because scrolling happens on a container and not on the page */}
      <Popover content={tagsList} placement="topLeft" getPopupContainer={trigger => trigger.parentElement!}>
        <StyledChipContainer>
          {showFirstTag && <StyledChip label={tags[0].title.toUpperCase()} maxLabelLength={75} />}
          {showSecondTag && <StyledChip label={tags[1].title.toUpperCase()} maxLabelLength={75} />}
          {showCounter && <StyledChip label={tags.length - 2 + '+'} />}
        </StyledChipContainer>
      </Popover>
    </div>
  );
};

export default TagsChips;
