import {defineMessages} from 'react-intl';

const messages = defineMessages({
  currentLevel: {
    id: 'SmartSkills.currentLevel',
    defaultMessage: 'Current Level'
  },
  requiredLevel: {
    id: 'SmartSkills.requiredLevel',
    defaultMessage: 'Required Level'
  },
  gap: {
    id: 'SmartSkills.gap',
    defaultMessage: 'Gap'
  },
  gaps: {
    id: 'SmartSkills.gaps',
    defaultMessage: 'Gaps'
  },
  levels: {
    id: 'SmartSkills.levels',
    defaultMessage: 'Levels'
  },
  levelSetTitle: {
    id: 'SmartSkills.levelSetTitle',
    defaultMessage: 'Set Skill Level'
  },
  levelSetSuccess: {
    id: 'SmartSkills.levelSetSuccess',
    defaultMessage: 'Skill Level set'
  },
  levelSetError: {
    id: 'SmartSkills.levelSetError',
    defaultMessage: 'Cannot set Skill Level'
  },
  members: {
    id: 'SmartSkills.members',
    defaultMessage: 'Members'
  },
  noMatrix: {
    id: 'SmartSkills.noMatrix',
    defaultMessage: 'There is no Skill Matrix for this Team.'
  },
  nextPage: {
    id: 'SmartSkills.nextPage',
    defaultMessage: 'Next user page'
  },
  prevPage: {
    id: 'SmartSkills.prevPage',
    defaultMessage: 'Previous user page'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  skillSets: {
    id: 'SmartSkills.skillSets',
    defaultMessage: 'Skills'
  },
  statsApproved: {
    id: 'SmartSkills.stats.approved',
    defaultMessage: 'Approved'
  },
  status: {
    id: 'SmartSkills.status',
    defaultMessage: 'Status'
  },
  unableToLoadSkillLevels: {
    id: 'SmartSkills.unableToLoadSkillLevels',
    defaultMessage: 'Unable to load required level details'
  }
});

export default messages;
