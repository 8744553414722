import gql from 'graphql-tag';

// Should be used only by editor
export const GuidesList = gql`
  query pagedGuidesForCms(
    $offset: Int
    $limit: Int
    $publishStatus: PublishStatus
    $searchTerm: String
    $sortBy: SortByOption
    $guideFilter: GuideFilterOption
    $customFilter: CustomFilter
  ) {
    pagedGuidesForCms(
      offset: $offset
      limit: $limit
      publishStatus: $publishStatus
      searchTerm: $searchTerm
      sortBy: $sortBy
      guideFilter: $guideFilter
      customFilter: $customFilter
    ) {
      guides {
        id
        publishStatus
        title
        media {
          id
          url
          metadata
        }
        slug
        locale
        availableTranslations
        providedTranslations
        ownedBy {
          id
          name
        }
        assignedToUser {
          fullName
        }
        canReassign
        canShare
        canEdit
        canTranslate
        canChangeVisibleTo
        canPublish
        canCopy
        isUnderApproval
        isLiveVersionExpired
        isLiveVersionRejected
        draftVersionOid
        reviewingVersionOid
        domains {
          isDefault
        }
      }
      nextOffset
    }
  }
`;

export const GuidesListLite = gql`
  query pagedGuidesForCmsLite($publishStatus: PublishStatus, $limit: Int, $offset: Int, $searchTerm: String) {
    pagedGuidesForCms(publishStatus: $publishStatus, limit: $limit, offset: $offset, searchTerm: $searchTerm) {
      guides {
        id
        title
      }
    }
  }
`;

// Lite version of GuidesList, used for Campaign guide selection
export const AllGuidesLite = gql`
  query guidesForCmsLite($forSignOffs: Boolean) {
    guidesForCms(forSignOffs: $forSignOffs) {
      id
      title
    }
  }
`;

export const GetGuideActivity = gql`
  query GetGuideActivity($guideId: String!, $cursor: String, $eventCategories: [EventCategory!]!) {
    activityLogPaginated(
      cursor: $cursor
      limit: 100
      filters: {guideId: {eq: $guideId}, eventCategory: {in: $eventCategories}}
    ) {
      nextCursor
      results {
        eventType
        producedAt
        comment
        newAssignee {
          fullName
        }
        guideId
        versionId
        producedBy {
          fullName
        }
        parentGuide {
          title
        }
        workspaces {
          name
        }

        ... on ChecklistChecksRepositionedGuideEvent {
          beforePosition
          afterPosition
          topicId
          instructionId
          instructionTitle
          stepId
        }
        ... on CheckContentUpdatedGuideEvent {
          before
          after
          topicId
          instructionId
          instructionTitle
          position
        }
        ... on InstructionStepsRepositionedGuideEvent {
          beforePosition
          afterPosition
          topicId
          instructionId
          instructionTitle
          stepId
        }

        ... on StepContentUpdatedGuideEvent {
          before
          after
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }

        ... on StepNoteContentUpdatedGuideEvent {
          beforeNote {
            icon {
              type
            }
            content
          }
          afterNote {
            icon {
              type
            }
            content
          }
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }

        ... on StepVisualUpdatedGuideEvent {
          beforeMedia {
            url
          }
          afterMedia {
            url
          }
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }

        ... on StepNoteVisualUpdatedGuideEvent {
          beforeNoteMedia {
            icon {
              type
            }
            media {
              url
            }
          }
          afterNoteMedia {
            icon {
              type
            }
            media {
              url
            }
          }
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }

        ... on CheckVisualUpdatedGuideEvent {
          beforeMedia {
            url
          }
          afterMedia {
            url
          }
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }

        ... on StepCreatedGuideEvent {
          content
          topicId
          instructionId
          instructionTitle
          stepId
        }

        ... on CheckCreatedGuideEvent {
          content
          topicId
          instructionId
          instructionTitle
          stepId
        }

        ... on InstructionCreatedGuideEvent {
          content
          topicId
          instructionId
          instructionTitle
          topicTitle
        }
        ... on InstructionContentUpdatedGuideEvent {
          before
          after
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionClonedGuideEvent {
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionSetToDraftGuideEvent {
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionPublishedGuideEvent {
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionArchivedGuideEvent {
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionSignOffEnabledGuideEvent {
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionSignOffDisabledGuideEvent {
          topicId
          instructionId
          instructionTitle
        }
        ... on InstructionVisualUpdatedGuideEvent {
          beforeMedia {
            url
          }
          afterMedia {
            url
          }
          topicId
          instructionId
          instructionTitle
        }
        ... on GuideDocumentsRepositionedGuideEvent {
          beforePosition
          afterPosition
          topicId
          instructionId
          instructionTitle
        }
        ... on GuideChecklistsRepositionedGuideEvent {
          beforePosition
          afterPosition
          topicId
          instructionId
          instructionTitle
        }

        ... on InstructionMovedToTopicGuideEvent {
          beforeTopic
          afterTopic
          topicId
          instructionId
          instructionTitle
          topicTitle
        }
        ... on ChecklistMovedToTopicGuideEvent {
          beforeTopic
          afterTopic
          topicId
          instructionId
          instructionTitle
          topicTitle
        }

        ... on ChecklistCreatedGuideEvent {
          content
          topicId
          instructionId
          instructionTitle
          topicTitle
        }

        ... on ChecklistClonedGuideEvent {
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistPublishedGuideEvent {
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistArchivedGuideEvent {
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistSetToDraftGuideEvent {
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistContentUpdatedGuideEvent {
          before
          after
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistVisualUpdatedGuideEvent {
          beforeMedia {
            url
          }
          afterMedia {
            url
          }
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistMovedToGuideEvent {
          guide
          topic
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistMovedFromGuideEvent {
          guide
          topic
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistTagsAddedGuideEvent {
          tags
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistTagsRemovedGuideEvent {
          tags
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistRequireSignatureGuideEvent {
          topicId
          instructionId
          instructionTitle
        }

        ... on ChecklistRemoveSignatureGuideEvent {
          topicId
          instructionId
          instructionTitle
        }

        ... on InstructionTagsAddedGuideEvent {
          tags
          topicId
          instructionId
          instructionTitle
        }

        ... on InstructionTagsRemovedGuideEvent {
          tags
          topicId
          instructionId
          instructionTitle
        }

        ... on InstructionMovedToGuideEvent {
          guide
          topic
          topicId
          instructionId
          instructionTitle
        }

        ... on InstructionMovedFromGuideEvent {
          guide
          topic
          topicId
          instructionId
          instructionTitle
        }
        ... on StepDuplicatedGuideEvent {
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }
        ... on StepArchivedGuideEvent {
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }
        ... on CheckDuplicatedGuideEvent {
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }
        ... on CheckArchivedGuideEvent {
          topicId
          instructionId
          instructionTitle
          position
          stepId
        }

        ... on TopicCreatedGuideEvent {
          content
          topicTitle
          topicId
        }
        ... on TopicContentUpdatedGuideEvent {
          before
          after
          topicId
        }
        ... on GuideTopicsRepositionedGuideEvent {
          beforePosition
          afterPosition
          topicTitle
        }
        ... on TopicSettingsUpdatedGuideEvent {
          displaySettings {
            visible
            collapsible
          }
          topicTitle
          topicId
        }
        ... on TopicMovedFromGuideEvent {
          guide
          topicTitle
          topicId
        }
        ... on TopicMovedToGuideEvent {
          guide
          topicTitle
          topicId
        }
        ... on TopicArchivedGuideEvent {
          topicTitle
        }
        ... on GuideVersionRestoredAsDraftGuideEvent {
          beforeVersion {
            title
          }
        }
        ... on GuideApprovalRequestedGuideEvent {
          reviewers {
            fullName
          }
          guideApproval
        }
        ... on GuideRepublishedGuideEvent {
          beforeVersion {
            title
          }
          publishedVersion {
            title
          }
        }
        ... on GuidePublishedGuideEvent {
          publishedVersion {
            title
          }
        }
        ... on GuideApprovalApprovedAndPublishedGuideEvent {
          publishedVersion {
            title
          }
        }
        ... on GuideWorkspacesUpdatedGuideEvent {
          beforeWorkspaces {
            name
          }
          afterWorkspaces {
            name
          }
        }
        ... on GuideApprovalPartiallyApprovedGuideEvent {
          reviewers {
            fullName
          }
          approvers {
            fullName
          }
          guideApproval
        }

        ... on GuideContentUpdatedGuideEvent {
          before
          after
        }

        ... on GuideVisualUpdatedGuideEvent {
          beforeMedia {
            url
          }
          afterMedia {
            url
          }
        }

        ... on GuideTranslationsAddedGuideEvent {
          translations
        }

        ... on GuideTranslationsUpdatedGuideEvent {
          translations
        }

        ... on GuideDraftAutoCreatedGuideEvent {
          beforeVersion {
            title
          }
        }

        ... on GuideDefaultLanguageUpdatedGuideEvent {
          before
          after
        }

        ... on ChecklistAlertRecipientsAddedGuideEvent {
          topicId
          instructionId
          instructionTitle
          count
        }

        ... on ChecklistAlertRecipientsRemovedGuideEvent {
          topicId
          instructionId
          instructionTitle
          count
        }
        ... on GuideTagsAddedGuideEvent {
          tags
        }
        ... on ChecklistTagsRemovedGuideEvent {
          tags
        }
      }
    }
  }
`;

export const GetGuide = gql`
  query guideForCms($id: String!, $versionId: String) {
    guideForCms(id: $id, versionId: $versionId) {
      id
      title
      media {
        id
        url
        metadata
      }
      tags {
        id
        title
        badge
      }
      slug
      defaultLocale
      locale
      availableTranslations
      providedTranslations
      ownedBy {
        id
        guideApproval
        reminderCycle
      }
      assignedToUser {
        fullName
      }
      publishStatus
      totalPublishedInstructionsNumber
      totalDraftInstructionsNumber
      topics {
        id
        title
        slug
        providedTranslations
        visible
        collapsible
        position
        instructions {
          id
          publishStatus
          slug
          type
          requireSignature
          providedTranslations
          topicId
          title
          stepsNumber
          campaign {
            id
          }
          media {
            id
            url
            metadata
          }
          hasAlertRecipients
          checklistTemplate {
            id
            type
            name
          }
        }
      }
      canChangeVisibleTo
      canReassign
      canShare
      canTranslate
      canPublish
      canApprove
      canApproveLiveVersion
      hasBeenApprovedByMe
      canSubmitFinalLiveVersionApproval
      canEdit
      versionId
      versions {
        _id
        version
        createdAt
      }
      draftVersionOid
      reviewingVersionOid
      isUnderApproval
      isLiveVersionExpired
      isLiveVersionRejected
      expiryDate
    }
  }
`;

export const GetGuideForCmsInstruction = gql`
  query guideForCmsInstruction($id: String!, $versionId: String) {
    guideForCms(id: $id, versionId: $versionId) {
      id
      title
      defaultLocale
      availableTranslations
      publishStatus
      ownedBy {
        id
        guideApproval
      }
      topics {
        id
        slug
        title
        instructions {
          id
          title
          topicId
          type
        }
      }
      canTranslate
      canShare
      versionId
      draftVersionOid
      reviewingVersionOid
      versions {
        _id
        version
        createdAt
      }
      isUnderApproval
      isLiveVersionExpired
    }
  }
`;

export const GetDomainsForGuide = gql`
  query getDomainsForGuide($id: String!) {
    guideForCms(id: $id) {
      id
      domains {
        id
        name
        slug
        isDefault
      }
    }
  }
`;
//Rename it to GetDomainsForGuide once changed on all the places where its used
export const GetWorkspacesForGuide = gql`
  query domainsForGuide($id: String!) {
    domainsForGuide(id: $id) {
      id
      name
      slug
      isDefault
    }
  }
`;

export const GetGuideForTranslationView = gql`
  query guideForTranslationView($id: String!, $versionId: String) {
    guideForCms(id: $id, versionId: $versionId) {
      id
      title
      media {
        id
        url
        metadata
      }
      availableTranslations
      defaultLocale
      canTranslate
      versionId
      versions {
        _id
        version
        createdAt
      }
    }
  }
`;

export const GetDraftGuide = gql`
  query draftGuideForCms($id: String!) {
    draftGuideForCms(id: $id) {
      lastEditedAt
    }
  }
`;

export const GetGuideAssignee = gql`
  query guideDetails($id: String!) {
    guideDetails(id: $id) {
      assignedToUser {
        id
      }
    }
  }
`;

export const getLastPublishedGuideByLocaleQuery = (guideId, versionId, locale) =>
  `
query lastPublishedGuideByLocale {
  guideForCms(
    id: "${guideId}"
    versionId: "${versionId}"
    locale: "${locale}"
  ) {
    id
    title
    slug
    topics {
      title
      slug
      id
      visible
      instructions {
        type
        publishStatus
        title
        slug
        id
        steps {
          notes {
            iconId
            mandatory
            contentHtml
            media {
              url
              metadata
            }
          }
          instructionHtml
          media {
            url
            metadata
          }
        }
      }
    }
    media {
      url
      metadata
    }
    locale
    domains {
      id
      name
      slug
      isDefault
    }
  }
}
`;

export const getLanguageInfo = gql`
  query getLanguageInfo($guideId: String!, $versionId: String!) {
    guideForCms(id: $guideId, versionId: $versionId) {
      availableTranslations
      defaultLocale
    }
  }
`;

export const GuideDetails = gql`
  query guideDetails($id: String!) {
    guideDetails(id: $id) {
      title
      tags {
        id
        title
        badge
      }
      totalPublishedInstructionsNumber
      totalDraftInstructionsNumber
      defaultLocale
      availableTranslations
      created_at
      lastEditedAt
      lastPublishedAt
      assignedToUser {
        fullName
      }
      ownedBy {
        name
      }
      createdByUser {
        fullName
      }
      parentGuide {
        id
        title
        publishStatus
      }
      domains {
        id
        name
        slug
        isDefault
      }
      expiryDate
      publishedVersion {
        version
      }
    }
  }
`;
