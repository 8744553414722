import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

@observer
class DrawerComponent extends Component {
  render() {
    const {children, label, isOpen, close, noOverlay} = this.props;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={label}
        onRequestClose={close}
        style={{
          overlay: {
            backgroundColor: noOverlay ? 'unset' : 'rgba(0, 0, 0, 0.1)'
          },
          content: {
            color: '#000',
            top: '0',
            left: 'auto',
            right: '0',
            bottom: '0',
            padding: '20px 16px',
            marginRight: '0'
          }
        }}
      >
        {children}
      </Modal>
    );
  }
}

export default DrawerComponent;
