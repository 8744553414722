export const DND_TYPE = 'INSTRUCTION';

export const target = {
  hover: (props, monitor) => {
    const {topic, store} = props;
    const {dragDropItems: page} = store;

    const item = monitor.getItem();
    const {sourcePosition, sourceTopicId} = item;

    const position = topic.instructions.length;
    const topicId = topic.id;

    if (sourceTopicId !== topicId) {
      page.moveInstruction(sourceTopicId, sourcePosition, topicId, position);

      // Note: we're mutating the monitor item here! Generally it's better
      // to avoid mutations, but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.sourcePosition = position;
      item.sourceTopicId = topicId;
    }
  }
};
