import React, {useEffect, useRef} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

import flowRight from 'lodash/flowRight';

// utils
import notification from 'utils/notification-utils';

// hooks
import {useTokenState} from 'hooks/useTokenState';
import {useTokenOperations} from 'hooks/useTokenOperations';
import {useCurrentPathname} from 'hooks/useCurrentPathname';

// components
import Button from 'ui-components/Button';
import TokenList from './List';

//styled-components
import {ClipboardButton, Divider, TextBox, Title, Info, Description} from './styles';

// messages
import messages from './messages';

const TokenComponent = ({store, intl}) => {
  const {formatMessage} = intl;

  const {tokens, currentToken, addToken, removeToken, fetchTokens, resetCurrentToken} = useTokenState(store);

  const notificationStrategies = {
    createOperationSuccess: () => notification.success(formatMessage(messages.createOperationSuccess)),
    createOperationFailure: () => notification.error(formatMessage(messages.createOperationFailure)),
    deleteOperationSuccess: () => notification.success(formatMessage(messages.deleteOperationSuccess)),
    deleteOperationFailure: () => notification.error(formatMessage(messages.deleteOperationFailure))
  };

  const {generateToken, deleteToken} = useTokenOperations(addToken, removeToken, notificationStrategies);

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  const lastCopiedTokenRef = useRef(null);

  useEffect(() => {
    lastCopiedTokenRef.current = null; // Reset the last copied token ref when currentToken changes
  }, [currentToken.token]);

  const currentPath = useCurrentPathname();

  useEffect(() => {
    resetCurrentToken();
  }, [currentPath]);

  const handleCopySuccess = () => {
    if (currentToken.token !== lastCopiedTokenRef.current) {
      notification.success(formatMessage(messages.copyTokenSuccess));
      lastCopiedTokenRef.current = currentToken.token;
    }
  };

  return (
    <>
      <Description>{formatMessage(messages.description)}</Description>
      {currentToken.token && (
        <Info>
          {formatMessage(messages.newToken)}
          <TextBox>{currentToken.token}</TextBox>
          <ClipboardButton component="a" data-clipboard-text={currentToken.token} onSuccess={handleCopySuccess}>
            <Button underlined solidIcon={false} iconId="duplicate" label={formatMessage(messages.copyToken)} />
          </ClipboardButton>
          {formatMessage(messages.copy)}
        </Info>
      )}

      <Button iconId="add" label={formatMessage(messages.submit)} onClick={generateToken} />

      <Divider />

      {tokens?.length > 0 && <Title>{formatMessage(messages.title)}</Title>}

      <TokenList tokens={tokens} onDeleteToken={deleteToken} message={formatMessage(messages.delete)} />
    </>
  );
};

export default flowRight([inject('store'), injectIntl, observer])(TokenComponent);
