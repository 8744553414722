import React from 'react';
import {AUTO_TRANSLATE_DIALOGS_MAP} from 'stores/dialogs/auto-translation-dialog';
import messages from './messages';
import AddNewLanguageDialogContent from './AddNewLanguageDialogContent';

export const getAddNewLanguageDialogConfig = ({autoTranslationDialog}, formatMessage) => ({
  title: formatMessage(messages.addNewGuideLanguage),
  content: <AddNewLanguageDialogContent formatMessage={formatMessage} />,
  actionsProps: {
    actions: [
      {
        loading: autoTranslationDialog.loading,
        disabled: !autoTranslationDialog.selectedLanguageToAdd || autoTranslationDialog.loading,
        label: formatMessage(messages.add),
        onClick: () => {
          autoTranslationDialog.onAddTranslationToGuide(autoTranslationDialog.selectedLanguageToAdd);
        }
      }
    ],
    onCancel: () => {
      autoTranslationDialog.setSelectedLanguageToAdd(undefined);
      autoTranslationDialog.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.selectLanguage);
    }
  },
  overflow: 'unset',
  overflowBody: 'unset',
  customHeaderStyle: {paddingBottom: 25}
});
