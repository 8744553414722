import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';

//styled-components
import {ImageOverlayWrapper, Container, OverlayCanvas, HiddenIFrame} from './styles';

//components
import CloudinaryPicture from 'shared/components/CloudinaryPicture';
import Toolbar from './OverlayToolbar';

//constants
import {ASPECT_RATIO} from 'shared/components/Overlay/constants';

@inject('store')
@observer
class MediaOverlayComponent extends Component {
  componentDidMount() {
    const {
      store: {mediaOverlayDialog, mediaPickerDialog}
    } = this.props;
    const {iFrameElement, setupKonva, propagateResize} = mediaOverlayDialog;
    const {selectedMedia, addShape, updateShapeData} = mediaPickerDialog;
    if (!iFrameElement) return;
    setupKonva(selectedMedia, addShape, updateShapeData);
    window.addEventListener('resize', propagateResize);
  }

  componentWillUnmount() {
    const {
      store: {mediaOverlayDialog}
    } = this.props;
    const {propagateResize} = mediaOverlayDialog;

    window.removeEventListener('resize', propagateResize);
  }

  render() {
    const {mediaPickerDialog, mediaOverlayDialog} = this.props.store;
    const {selectedMedia, setShapeColor, setThickness} = mediaPickerDialog;
    const {
      toggleShowToolbarColorPicker,
      showToolbarColorPicker,
      setToolbarColor,
      selectedColor,
      selectedThickness,
      setToolbarShape,
      setToolbarThickness,
      selectedShapeEnum,
      editMode,
      selectedShapePosition: position,
      setIFrameElement
    } = mediaOverlayDialog;

    if (!selectedMedia) {
      return null;
    }

    return (
      <ImageOverlayWrapper>
        <Toolbar
          selectedColor={selectedColor}
          selectedShape={selectedShapeEnum}
          selectedThickness={selectedThickness}
          editMode={editMode}
          position={position}
          colorPickerOpen={showToolbarColorPicker}
          setToolbarColor={setToolbarColor}
          setShape={setToolbarShape}
          toggleColorPicker={toggleShowToolbarColorPicker}
          setShapeColor={setShapeColor}
          setShapeThickness={setThickness}
          setToolbarThickness={setToolbarThickness}
        />
        <Container>
          <HiddenIFrame ref={setIFrameElement} />
          <CloudinaryPicture src={selectedMedia.url} metadata={selectedMedia.metadata} aspectRatio={ASPECT_RATIO} />
          <OverlayCanvas id="overlay-canvas"></OverlayCanvas>
        </Container>
      </ImageOverlayWrapper>
    );
  }
}

export default injectIntl(MediaOverlayComponent);
