import {defineMessages} from 'react-intl';

const messages = defineMessages({
  reassignThisGuide: {
    id: 'ReassignGuide.reassignThisGuide',
    defaultMessage: 'Reassign this guide'
  },
  reassign: {
    id: 'common.actions.reassign',
    defaultMessage: 'Reassign'
  },
  reassignSuccess: {
    id: 'ReassignGuide.reassignSuccess',
    defaultMessage: 'Guide has been reassigned.'
  },
  reassignError: {
    id: 'ReassignGuide.reassignError',
    defaultMessage: 'Error reassigning a guide.'
  }
});

export default messages;
