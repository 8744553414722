export const TIME_TYPE = {
  SECONDS: 'seconds',
  HOURS: 'hours',
  DAYS: 'days',
  WEEKS: 'weeks',
  MONTHS: 'months'
};

const {HOURS, DAYS, WEEKS, MONTHS} = TIME_TYPE;

export const timeOptions = [
  {
    id: HOURS,
    name: HOURS
  },
  {
    id: DAYS,
    name: DAYS
  },
  {
    id: WEEKS,
    name: WEEKS
  },
  {
    id: MONTHS,
    name: MONTHS
  }
];

export const SECONDS_NUMBER = {
  IN_HOUR: 3600,
  IN_DAY: 86400,
  IN_WEEK: 604800,
  IN_MONTH: 2628000
};

export const OPTION_TYPE = {
  ALWAYS: 'Always',
  CUSTOM: 'Custom'
};
