import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditUser.title',
    defaultMessage: 'Edit User'
  },
  titleDisabled: {
    id: 'EditUser.titleDisabled',
    defaultMessage: 'Disabled user'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  notFound: {
    id: 'EditUser.notFound',
    defaultMessage: 'User not found.'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  fullName: {
    id: 'EditUser.fullName',
    defaultMessage: 'Full name'
  },
  organization: {
    id: 'EditUser.organization',
    defaultMessage: 'Organization'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  backToOverview: {
    id: 'common.actions.backToOverview',
    defaultMessage: 'Back to overview'
  },
  platformAdmin: {
    id: 'EditUser.platformAdmin',
    defaultMessage: 'Platform Admin'
  },
  setPlatformAdminDescription: {
    id: 'common.description.setPlatformAdmin',
    defaultMessage: 'This will give the user the authority to manage the entire Platform.'
  },
  disable: {
    id: 'EditUser.disable',
    defaultMessage: 'Disable this user'
  },
  enable: {
    id: 'EditUser.enable',
    defaultMessage: 'Enable this user'
  },
  updateSuccess: {
    id: 'EditUser.updateSuccess',
    defaultMessage: 'User has been updated.'
  },
  updateFailure: {
    id: 'EditUser.updateFailure',
    defaultMessage: 'Failed to edit user.'
  },
  disableSuccess: {
    id: 'EditUser.disableSuccess',
    defaultMessage: 'User has been disabled.'
  },
  disableFailure: {
    id: 'EditUser.disableFailure',
    defaultMessage: 'Failed to disable user.'
  },
  enableSuccess: {
    id: 'EditUser.enableSuccess',
    defaultMessage: 'User has been enabled.'
  },
  enableFailure: {
    id: 'EditUser.enableFailure',
    defaultMessage: 'Failed to enable user.'
  },
  noTeams: {
    id: 'EditUser.noTeams',
    defaultMessage: 'This User has not been added to any Team yet.'
  },
  ssoIdInfo: {
    id: 'EditUser.ssoIdInfo',
    defaultMessage: 'This is the id for this user in an external SSO authentication service.'
  },
  team: {
    id: 'EditUser.selectTeam',
    defaultMessage: 'Team'
  },
  role: {
    id: 'EditUser.selectRole',
    defaultMessage: 'Role'
  },
  jobTitle: {
    id: 'EditUser.selectJobTitle',
    defaultMessage: 'Job Title'
  }
});

export default messages;
