import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditWorkspace.title',
    defaultMessage: 'Edit Workspace'
  },
  name: {
    id: 'common.forms.name',
    defaultMessage: 'Name'
  },
  workspaceUrl: {
    id: 'EditWorkspace.workspaceUrl',
    defaultMessage: 'Workspace URL'
  },
  workspaceUrlDescription: {
    id: 'common.description.workspaceUrlDescription',
    defaultMessage: 'This will be the URL link for your workspace.'
  },
  password: {
    id: 'common.forms.password',
    defaultMessage: 'Password'
  },
  confirmPassword: {
    id: 'common.forms.confirmPassword',
    defaultMessage: 'Confirm password'
  },
  passwordMismatch: {
    id: 'common.forms.passwordMismatch',
    defaultMessage: 'The passwords must match'
  },
  theme: {
    id: 'common.forms.theme',
    defaultMessage: 'Theme'
  },
  themeDescription: {
    id: 'common.description.themeDescription',
    defaultMessage: 'The branding options of a workspace, including color, logo, and font.'
  },
  enableXMReality: {
    id: 'NewWorkspace.enableXMReality',
    defaultMessage: 'Enable XM Reality remote call support on this workspace'
  },
  remoteSupportTeam: {
    id: 'NewWorkspace.remoteSupportTeam',
    defaultMessage: 'Remote Support Team'
  },
  managementTeam: {
    id: 'EditWorkspace.managementTeam',
    defaultMessage: 'Management Team'
  },
  managementTeamDescription: {
    id: 'common.description.managementTeamDescription',
    defaultMessage: 'The team responsible for managing the workspace, such as the name, URL, credentials, and branding.'
  },
  defaultTeamForNewUsers: {
    id: 'NewWorkspace.defaultTeamForNewUsers',
    defaultMessage: 'Default Team for New Users'
  },
  defaultTeamDescription: {
    id: 'common.description.defaultTeamDescription',
    defaultMessage:
      ' New users are added to this Team when logging in to a Workspace with their Company Account (SSO) for the first time.'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  editSuccess: {
    id: 'EditWorkspace.editSuccess',
    defaultMessage: 'Workspace has been updated.'
  },
  editFailure: {
    id: 'EditWorkspace.editFailure',
    defaultMessage: 'Failed to update workspace.'
  },
  protectedContent: {
    id: 'EditWorkspace.protectedContent',
    defaultMessage: 'Protected content'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  signInOptions: {
    id: 'EditWorkspace.signInOptions',
    defaultMessage: 'Sign in options'
  },
  companyAccount: {
    id: 'EditWorkspace.companyAccount',
    defaultMessage: 'Company account (Single Sign On)'
  },
  emailAndPassword: {
    id: 'EditWorkspace.emailAndPassword',
    defaultMessage: 'Email and password'
  },
  emailPhoneAndPassword: {
    id: 'EditWorkspace.emailPhoneAndPassword',
    defaultMessage: 'Email/Phone Number and Password'
  },
  workspacePassword: {
    id: 'EditWorkspace.workspacePassword',
    defaultMessage: 'Workspace password'
  },
  platformSetting: {
    id: 'EditWorkspace.platformSetting',
    defaultMessage: 'Platform setting'
  },
  associatedSite: {
    id: 'NewWorkspace.associatedSite',
    defaultMessage: 'Associated Site'
  },
  selectSite: {
    id: 'NewWorkspace.selectSite',
    defaultMessage: 'Select site'
  },
  ipRangesInvalid: {
    id: 'ipRangesInvalid',
    defaultMessage: 'Invalid IP ranges'
  }
});

export default messages;
