import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pageNotFound: {
    id: 'NotFoundMessage.pageNotFound',
    defaultMessage: 'Page not found'
  }
});

export default messages;
