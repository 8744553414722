import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pdfCreated: {
    id: 'Pdf.Created',
    defaultMessage: 'Created'
  },
  pdfVersion: {
    id: 'Pdf.Version',
    defaultMessage: 'Version'
  },
  pdfToc: {
    id: 'pdfToc',
    defaultMessage: 'Table of Contents'
  },
  pdfStep: {
    id: 'pdfStep',
    defaultMessage: 'Step'
  },
  pdfInstruction: {
    id: 'pdfInstruction',
    defaultMessage: 'Instruction'
  },
  warning: {
    id: 'common.warning',
    defaultMessage: 'Warning'
  },
  pdfTip: {
    id: 'pdfTip',
    defaultMessage: 'Tip'
  },
  pdfFix: {
    id: 'pdfFix',
    defaultMessage: 'Fix'
  },
  pdfAlternative: {
    id: 'pdfAlternative',
    defaultMessage: 'Alternative'
  },
  pdfUpToDate: {
    id: 'pdfUpToDate',
    defaultMessage: 'Stay up to date with the online version'
  },
  pdfReferences: {
    id: 'pdfReferences',
    defaultMessage: 'External references'
  },
  pdfMediaItemHeader: {
    id: 'pdfMediaItemHeader',
    defaultMessage: 'Content not available as a PDF'
  },
  pdfMediaItemDescription: {
    id: 'pdfMediaItemDescription',
    defaultMessage: 'This content is an online {mediaType}. Follow the link below to view it.'
  },
  topic: {
    id: 'EditTopic.topic',
    defaultMessage: 'Topic'
  },
  pdfGoTo: {
    id: 'pdfGoTo',
    defaultMessage: 'Go to'
  },
  pdfErrorLanguageNoteSupported: {
    id: 'pdfErrorLanguageNoteSupported',
    defaultMessage: 'This language is not supported yet for PDF export.'
  },
  pdfCheck: {
    id: 'pdfCheck',
    defaultMessage: 'Check'
  }
});

export default messages;
