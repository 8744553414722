import {action, observable, computed, makeObservable} from 'mobx';
import Raven from 'raven-js';

import notification from 'utils/notification-utils';

class ApplyCampaignfDialog {
  @observable opened = false;
  @observable selectedCampaign;
  @observable instructions = [];
  @observable appliedFromInstruction;
  @observable translations;

  @action
  reset = () => {
    this.opened = false;
    this.selectedCampaign = null;
    this.instructions = [];
    this.appliedFromInstruction = null;
  };

  @action toggleDialogVisibility = () => {
    this.opened = !this.opened;
  };

  @action
  setSelectedCampaign = selectedCampaign => {
    this.selectedCampaign = selectedCampaign;
  };

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action open = (instructions, appliedFromInstruction, appliedCampaign) => {
    // If we don't pass the instructions array here we will not open the dialog
    try {
      instructions.forEach(instruction => {
        if (typeof instruction !== 'object') {
          notification.error(`Wrong arguments passed: ${instructions}`);
          throw new Error(instructions);
        }
      });
    } catch (err) {
      Raven.captureException(new Error(`Wrong arguments passed: ${instructions}`));
      return;
    }

    this.setSelectedCampaign(appliedCampaign);
    this.setInstructions(instructions);
    this.appliedFromInstruction = appliedFromInstruction;
    this.opened = true;
  };

  @action setInstructions = instructions => {
    this.instructions = instructions;
  };

  @action
  submit = async (applyCampaignToInstructionsMutation, overrideExistingCampaigns) => {
    const {id: campaignId} = this.selectedCampaign;

    const instructionIds = overrideExistingCampaigns
      ? this.instructions.map(instruction => instruction.id)
      : this.instructions
          .filter(instruction => !instruction.campaign || this.appliedFromInstruction)
          .map(instruction => instruction.id);

    if (instructionIds.length === 0) {
      notification.error(this.translations.campaignApplyTo0Instructions);
      this.reset();
      return;
    }

    await applyCampaignToInstructionsMutation({campaignId, instructionIds})
      .then(() => {
        notification.success(
          this.appliedFromInstruction ? this.translations.campaignApplySuccess : this.translations.campaignsApplySuccess
        );
      })
      .catch(() => {
        notification.error(this.translations.campaignsApplyError);
      });

    this.reset();
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get showRemoveButton() {
    return this.instructions.some(instruction => instruction.campaign);
  }

  setStore = store => {
    this.store = store;
  };
}

export default ApplyCampaignfDialog;
