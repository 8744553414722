import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject} from 'mobx-react';
import examples from 'config/example-images';

//messages
import messages from './messages';

//styled-components
import {Form, Title, TitleAndLink, Link, ColorPickers, Text} from './styles';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import ColorPicker from 'components/ColorPicker';

@inject('store')
class ThemeColors extends Component {
  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {themePage, examplesDialog} = store;
    const {colorsForm: form} = themePage;

    return (
      <Form>
        <TitleAndLink>
          <Title>
            <FormattedMessage {...messages.colors} />
          </Title>
          <Link
            label={formatMessage(messages.showExample)}
            onClick={() => examplesDialog.open(examples.themeColors, formatMessage(messages.colorsExample))}
          />
        </TitleAndLink>

        <ColorPickers>
          <ColorPicker form={form} field="mainColor" label={formatMessage(messages.mainColor)} number={1} />

          <ColorPicker form={form} field="buttonColor" label={formatMessage(messages.buttonColor)} number={3} />
        </ColorPickers>

        <Text>
          <FormattedMessage {...messages.stepNoteColors} />
        </Text>
      </Form>
    );
  }
}

export default injectIntl(ThemeColors);
