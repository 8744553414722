import {inject} from 'mobx-react';
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import get from 'lodash/get';

//helpers
import {ensureDraftVersion} from 'utils/versioning-utils';
import {TOPIC_TITLE_TYPE, SHARE_DIALOG_TYPE} from 'config/enums';
import notification from 'utils/notification-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import UntranslatedIndicator from 'components/UntranslatedIndicator';

import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuLink,
  DropdownSubmenu
} from 'styles/styled-components/dropdown-styled-components';
import {Radio} from 'styles/styled-components/form-styled-components';

//mutations
import {DeleteTopic, UpdateTopic, MoveTopic} from 'api/topic/mutations';
import {deleteTopic, updateTopic, moveTopicOptions} from 'api/topic/mutation-options';

//messages
import messages from './messages';

import {TranslationItemWrapper} from './styles';
import {getTranslateRouteAndParams} from 'utils/translate-route';

@inject('store')
@graphql(DeleteTopic, deleteTopic)
@graphql(UpdateTopic, updateTopic)
@graphql(MoveTopic, moveTopicOptions)
class TopicMenuComponent extends Component {
  archive = ensureDraftVersion(() => {
    const {deleteTopicMutation: mutation, intl, store, topic} = this.props;
    const {formatMessage} = intl;
    const {archiveDialog: dialog} = store;
    const {id, title} = topic;

    const translations = {
      action: formatMessage(messages.action),
      archiveFailure: formatMessage(messages.archiveFailure),
      archiveSuccess: formatMessage(messages.archiveSuccess),
      confirmation: formatMessage(messages.confirmation, {title})
    };

    dialog.open({params: {id}, mutation, title, translations});
  }, this);

  edit = ensureDraftVersion(() => {
    const {intl, store, topic} = this.props;
    const {formatMessage} = intl;
    const {editTopicPage: page} = store;

    const translations = {
      error: formatMessage(messages.error),
      success: formatMessage(messages.success),
      updateError: formatMessage(messages.updateError),
      updateSuccess: formatMessage(messages.updateSuccess)
    };

    page.startEditing(topic, translations);
  }, this);

  updateTopic = ensureDraftVersion(option => {
    const {intl, updateTopicMutation: mutation, topic} = this.props;
    const {formatMessage} = intl;
    const {id, slug, title} = topic;

    const topicUpdate = {slug, title};

    if (option === TOPIC_TITLE_TYPE.VISIBLE) {
      topicUpdate.collapsible = false;
      topicUpdate.visible = true;
    }

    if (option === TOPIC_TITLE_TYPE.COLLAPSIBLE) {
      topicUpdate.collapsible = true;
      topicUpdate.visible = true;
    }

    if (option === TOPIC_TITLE_TYPE.HIDDEN) {
      topicUpdate.collapsible = false;
      topicUpdate.visible = false;
    }

    mutation({id, topic: topicUpdate})
      .then(() => {
        notification.success(formatMessage(messages.updateSuccess));
      })
      .catch(() => {
        notification.error(formatMessage(messages.updateError));
      });
  }, this);

  moveTo = () => {
    const {
      intl: {formatMessage},
      store: {moveToDialog},
      topic: {id},
      moveTopicMutation
    } = this.props;

    const translations = {
      title: formatMessage(messages.moveTopicTitle),
      buttonLabel: formatMessage(messages.moveTopicButton),
      moveToSuccess: formatMessage(messages.moveTopicSuccess),
      moveToFailure: formatMessage(messages.moveTopicFailure),
      defaultLanguagesMustMatch: formatMessage(messages.defaultLanguagesMustMatch)
    };

    moveToDialog.open({
      id,
      translations,
      moveToMutation: moveTopicMutation
    });
  };

  share = () => {
    const {
      topic,
      intl: {formatMessage},
      store: {
        editGuidePage: {guide},
        shareDialog
      }
    } = this.props;

    shareDialog.open({
      path: `guide/${guide.id}/topic/${topic.id}`,
      qrBasename: topic.slug,
      itemTitle: topic.title,
      title: formatMessage(messages.shareTopic),
      type: SHARE_DIALOG_TYPE.TOPIC
    });
  };

  render() {
    const {canTranslate, intl, store, topic, shouldShowUntranslatedIcon} = this.props;
    const {formatMessage} = intl;
    const {editGuidePage, editTopicPage: page, router} = store;
    const {id: guideId} = router.params;

    const titleType = page.getInitialTitleOption(topic);
    const guideDefaultLocale = get(editGuidePage, 'guide.defaultLocale');

    const isDraftVersion = !get(router, 'queryParams.v', false);
    const canShare = get(editGuidePage.guide, 'canShare', false);
    const {params: translateParams, route: translateRoute} = getTranslateRouteAndParams(
      guideDefaultLocale,
      editGuidePage.guide.availableTranslations,
      {guideId, topicId: topic.id}
    );

    return (
      // The Dropdown component tries to amend its child props.
      // To avoid disrupting this we want to forward the props
      // the actual underlying DropdownMenu component it expects.
      <DropdownMenu {...this.props}>
        {canShare && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.share}>
              <FormattedMessage {...messages.share} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {canTranslate && (
          <DropdownMenuItem>
            <DropdownMenuLink params={translateParams} store={store} route={translateRoute}>
              <TranslationItemWrapper>
                <FormattedMessage {...messages.translate} />
                {shouldShowUntranslatedIcon && <UntranslatedIndicator size={16} content="!" />}
              </TranslationItemWrapper>
            </DropdownMenuLink>
          </DropdownMenuItem>
        )}
        <DropdownMenuDivider />
        <DropdownMenuItem>
          <DropdownMenuButton onClick={this.edit}>
            <FormattedMessage {...messages.edit} />
          </DropdownMenuButton>
        </DropdownMenuItem>
        <DropdownSubmenu key="options" title={formatMessage(messages.options)}>
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.updateTopic(TOPIC_TITLE_TYPE.VISIBLE)}>
              <Radio checked={titleType === TOPIC_TITLE_TYPE.VISIBLE} />
              <FormattedMessage {...messages.showTitle} />
            </DropdownMenuButton>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.updateTopic(TOPIC_TITLE_TYPE.COLLAPSIBLE)}>
              <Radio checked={titleType === TOPIC_TITLE_TYPE.COLLAPSIBLE} />
              <FormattedMessage {...messages.collapseTitle} />
            </DropdownMenuButton>
          </DropdownMenuItem>
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.updateTopic(TOPIC_TITLE_TYPE.HIDDEN)}>
              <Radio checked={titleType === TOPIC_TITLE_TYPE.HIDDEN} />
              <FormattedMessage {...messages.hideTitle} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        </DropdownSubmenu>
        <DropdownMenuDivider />
        {isDraftVersion && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.moveTo}>
              <FormattedMessage {...messages.moveTo} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        <DropdownMenuDivider />
        <DropdownMenuItem>
          <DropdownMenuButton onClick={this.archive}>
            <FormattedMessage {...messages.delete} />
          </DropdownMenuButton>
        </DropdownMenuItem>
      </DropdownMenu>
    );
  }
}

export default injectIntl(TopicMenuComponent);
