import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

//components
import ExampleImagesSlider from 'components/ExampleImagesSlider';
import Dialog from 'ui-components/Dialog';

@inject('store')
@observer
class ExamplesDialogComponent extends Component {
  render() {
    const {store} = this.props;
    const {examplesDialog: dialog} = store;

    const {title, images} = dialog;

    return (
      <Dialog
        actions={false}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        size="medium"
        title={title}
        bodyStyle={{paddingBottom: 0}}
      >
        {images && <ExampleImagesSlider images={images} />}
      </Dialog>
    );
  }
}

export default ExamplesDialogComponent;
