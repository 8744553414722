import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import moment from 'moment';

import Dialog from 'ui-components/Dialog';
import Spinner from 'shared/components/Spinner';

import {GuideDetails} from 'api/guide/queries';
import {getGuideDetailsOptions} from 'api/guide/query-options';
import {DATE_TIME_FORMAT} from 'config/constants';
import {sortByPropName} from 'utils/array-utils';
import {formatLanguages, getLanguageByLocale} from 'utils/languages-utils';

import {
  DetailsRow,
  DetailsSection,
  StyledTag,
  DetailsLeftCell,
  DetailsRightCell,
  StyledWorkspace,
  StyledIcon,
  WorkspaceWrapper,
  WrapperSpinner,
  StyledErrorDetails,
  dialogStyle
} from './styles';
import messages from './messages';

@inject('store')
@graphql(GuideDetails, getGuideDetailsOptions)
@observer
class GuideDetailsDialog extends Component {
  UNSAFE_componentWillReceiveProps = nextProps => {
    const {guideQuery} = nextProps;
    const {
      store: {
        guideDetailsDialog: {setGuide, setError, setLoading}
      }
    } = this.props;

    const {error, loading, guideDetails} = guideQuery;

    if (loading) {
      setLoading(true);
      return;
    }

    if (error) {
      setError(error);
      return;
    }

    if (guideDetails) {
      const guide = guideQuery.guideDetails;
      setGuide(guide);
      return;
    }
  };

  render() {
    const {
      store: {
        guideDetailsDialog: {opened, reset, guide, error, loading},
        platform: {availableLanguagesDetails}
      },
      intl: {formatMessage}
    } = this.props;
    const {
      instructions,
      instructionCount,
      visibleCount,
      defaultLanguageMessage,
      translatedIn,
      team,
      assignedTo,
      createdAt,
      lastEditAt,
      lastPublishAt,
      createdByMessage,
      visibleToMessage,
      errorDetailsGuide,
      originalParent,
      version,
      expirationDate
    } = messages;

    if (error) {
      return (
        <Dialog actions={() => []} isOpen={opened} onCancel={reset}>
          <StyledErrorDetails>{formatMessage(errorDetailsGuide)}</StyledErrorDetails>
        </Dialog>
      );
    }

    if (loading && !guide) {
      return (
        <Dialog actions={() => []} isOpen={opened} onCancel={reset} dialogStyle={dialogStyle}>
          <WrapperSpinner>
            <Spinner color={'#fff'} />
          </WrapperSpinner>
        </Dialog>
      );
    }

    const {
      title,
      tags,
      totalPublishedInstructionsNumber,
      totalDraftInstructionsNumber,
      defaultLocale,
      availableTranslations,
      created_at,
      lastEditedAt,
      lastPublishedAt,
      assignedToUser,
      ownedBy,
      domains,
      createdByUser,
      parentGuide,
      expiryDate,
      publishedVersion
    } = guide;

    const defaultLanguage = getLanguageByLocale(defaultLocale, availableLanguagesDetails);
    const translatedInLanguages = formatLanguages(availableTranslations, availableLanguagesDetails).filter(
      language => language.name !== defaultLanguage
    );

    return (
      <Dialog actions={() => []} isOpen={opened} onCancel={reset} title={title} customHeaderStyle={{paddingBottom: 5}}>
        {tags.length > 0 && (
          <DetailsSection>
            {tags
              .slice()
              .sort(sortByPropName('title'))
              .map(({id, title, badge}) => (
                <StyledTag key={id}>
                  {badge && <StyledIcon iconId="badge" marginTop={5} />}
                  {title}
                </StyledTag>
              ))}
          </DetailsSection>
        )}

        <DetailsSection>
          {publishedVersion && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(version)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>{publishedVersion.version}</DetailsRightCell>
            </DetailsRow>
          )}
          {expiryDate && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(expirationDate)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>{moment(expiryDate).format(DATE_TIME_FORMAT)}</DetailsRightCell>
            </DetailsRow>
          )}
        </DetailsSection>

        <DetailsSection>
          <DetailsRow>
            <DetailsLeftCell>
              {formatMessage(instructions)}
              {':'}
            </DetailsLeftCell>
            <DetailsRightCell>
              {formatMessage(instructionCount, {
                count: totalPublishedInstructionsNumber + totalDraftInstructionsNumber
              })}
              {' / '}
              {formatMessage(visibleCount, {count: totalPublishedInstructionsNumber})}
            </DetailsRightCell>
          </DetailsRow>

          <DetailsRow>
            <DetailsLeftCell>
              {formatMessage(defaultLanguageMessage)}
              {':'}
            </DetailsLeftCell>
            <DetailsRightCell>{defaultLanguage}</DetailsRightCell>
          </DetailsRow>

          <DetailsRow>
            <DetailsLeftCell>
              {formatMessage(translatedIn)}
              {':'}
            </DetailsLeftCell>
            <DetailsRightCell>
              {translatedInLanguages.length === 0
                ? '-'
                : translatedInLanguages.map(({locale, name}, index) => (
                    <span key={locale}>
                      {name}
                      {index !== translatedInLanguages.length - 1 && ', '}
                    </span>
                  ))}
            </DetailsRightCell>
          </DetailsRow>
        </DetailsSection>

        <DetailsSection>
          <DetailsRow>
            <DetailsLeftCell>
              {formatMessage(team)}
              {':'}
            </DetailsLeftCell>
            <DetailsRightCell>
              <StyledIcon iconId="users" />
              {ownedBy.name}
            </DetailsRightCell>
          </DetailsRow>

          {assignedToUser && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(assignedTo)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>
                <StyledIcon iconId="user" marginRight={15} />
                {assignedToUser.fullName}
              </DetailsRightCell>
            </DetailsRow>
          )}

          {domains.length > 0 && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(visibleToMessage)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell display="flex">
                {domains.map(({name, id, isDefault}) => (
                  <WorkspaceWrapper key={id}>
                    <StyledIcon iconId="city" />
                    <StyledWorkspace>{`${name} ${isDefault ? '(main)' : ''}`}</StyledWorkspace>
                  </WorkspaceWrapper>
                ))}
              </DetailsRightCell>
            </DetailsRow>
          )}
        </DetailsSection>

        <DetailsSection>
          {createdByUser && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(createdByMessage)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>
                <StyledIcon iconId="user" marginRight={15} />
                {createdByUser.fullName}
              </DetailsRightCell>
            </DetailsRow>
          )}

          <DetailsRow>
            <DetailsLeftCell>
              {formatMessage(createdAt)}
              {':'}
            </DetailsLeftCell>
            <DetailsRightCell>{moment(created_at).format(DATE_TIME_FORMAT)}</DetailsRightCell>
          </DetailsRow>

          {lastEditedAt && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(lastEditAt)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>{moment(lastEditedAt).format(DATE_TIME_FORMAT)}</DetailsRightCell>
            </DetailsRow>
          )}

          {lastPublishedAt && (
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(lastPublishAt)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>{moment(lastPublishedAt).format(DATE_TIME_FORMAT)}</DetailsRightCell>
            </DetailsRow>
          )}
        </DetailsSection>

        {parentGuide && (
          <DetailsSection>
            <DetailsRow>
              <DetailsLeftCell>
                {formatMessage(originalParent)}
                {':'}
              </DetailsLeftCell>
              <DetailsRightCell>
                <p>{parentGuide.title}</p>
              </DetailsRightCell>
            </DetailsRow>
          </DetailsSection>
        )}
      </Dialog>
    );
  }
}

export default injectIntl(GuideDetailsDialog);
