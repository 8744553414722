export const extractLinksFromHtml = htmlContent => {
  const splitedContent = htmlContent.split('<a href="');

  splitedContent.splice(0, 1);

  const onlyContentThatContainALink = splitedContent.filter(content => content.includes('" target'));

  const links = onlyContentThatContainALink.map(content => content.split('" target')[0]);

  return links;
};

export const extractLinkMapFromStep = step => {
  const {instructionHtml, notes: stepNotes} = step;

  const mandatoryLinks = [];
  const contentLinks = extractLinksFromHtml(instructionHtml).map(link => ({link, isStepNoteLink: false}));
  const stepNotesLinks = [];

  stepNotes &&
    stepNotes.forEach(stepNote => {
      const links = extractLinksFromHtml(stepNote.contentHtml).map(link => ({link, isStepNoteLink: true}));

      if (links.length > 0) {
        if (stepNote.mandatory) {
          mandatoryLinks.push(...links);
        } else {
          stepNotesLinks.push(...links);
        }
      }
    });

  const links = [...mandatoryLinks, ...contentLinks, ...stepNotesLinks];

  return links.map(linkMeta => ({...linkMeta, annotated: false}));
};

export const getSvgRoundedNumber = number => `
  <svg width="22px" height="22px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Artboard</title>
      <desc>Created with Sketch.</desc>
      <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect id="Rectangle" fill="#000000" x="0" y="0" width="15" height="15" rx="3"></rect>
          <text id="1" font-family="Lato" font-size="12" font-weight="normal" fill="#FFFFFF">
              <tspan x="4" y="11.5">${number}</tspan>
          </text>
      </g>
  </svg>
`;
