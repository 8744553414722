import gql from 'graphql-tag';

export const UserInfo = gql`
  query userInfoForCms {
    me {
      id
      fullName
      email
      phoneNumber
      isPlatformAdmin
      canViewUsers
      canManageUsers
      canManageTeams
      canViewTeams
      canManageDomains
      canManageThemes
      canViewAnalytics
      canManageCampaigns
      canCreateTags
      isIPA
      isSsoLogin
      canManageSkills
      themes {
        id
        name
        mainColor
        buttonColor
      }
      teams {
        team {
          name
          id
          domains {
            slug
            managementTeam {
              id
            }
          }
        }
        role
        isApprover
      }
      isEditor
      isTeamAdmin
    }
  }
`;

export const User = gql`
  query user($email: String, $id: String) {
    user(email: $email, id: $id) {
      id
      email
      phoneNumber
      fullName
      isPlatformAdmin
      canManageUser
      ssoId
      teams {
        team {
          name
          id
        }
        role
        skillRole {
          id
          name
        }
      }
    }
  }
`;

export const UserMemberOfTeam = gql`
  query userMemberOfTeam($teamId: String, $userId: String) {
    userMemberOfTeam {
      role
      team {
        id
      }
    }
  }
`;

export const EnabledUsersLite = gql`
  query enabledUsers {
    enabledUsers {
      fullName
      id
      email
      phoneNumber
    }
  }
`;

export type UsersPaginatedQueryData = {
  usersPaginated: {
    results: {
      id: string;
      email: string;
      phoneNumber: string;
      fullName: string;
      isPlatformAdmin: boolean;
      isDisabled: boolean;
      ssoId: string;
      lastLoginAt: string;
    }[];
    totalCount: number;
  };
};

export const UsersPaginated = gql`
  query usersPaginated($offset: Int, $limit: Int, $filters: UserFilters, $sortBy: [UserSortBy!]) {
    usersPaginated(offset: $offset, limit: $limit, filters: $filters, sortBy: $sortBy) {
      results {
        id
        email
        fullName
        phoneNumber
        isPlatformAdmin
        isDisabled
        ssoId
        lastLoginAt
      }
      totalCount
    }
  }
`;

export const GetTSAuthenticationToken = `#graphql
  query getAuthenticationToken {
    getAuthenticationToken {
      token
    }
  }
`;

export const Assignees = gql`
  query assignees {
    assignees {
      id
      fullName
    }
  }
`;

export const ValidateResetPasswordLink = gql`
  query isPasswordResetLinkValid($token: String!) {
    isPasswordResetLinkValid(token: $token)
  }
`;

export const ValidateSetPasswordLink = gql`
  query isPasswordSetLinkValid($token: String!) {
    isPasswordSetLinkValid(token: $token)
  }
`;

export const getTeamsIdsForUserQuery = id => `
  query teamsIdForuser {
    user(id: "${id}") {
      teams {
        team {
          id
        }
      }
    }
  }
`;

export const CheckUserDeletion = gql`
  query checkUserDeletionConstraints($userId: String!) {
    checkUserDeletionConstraints(userId: $userId) {
      hasConstraint
      userDeletionConstraint {
        ... on IUserDeletionConstraint {
          kind
        }
        ... on UserHasPendingApprovalConstraint {
          payload {
            teams {
              id
              name
            }
          }
        }
        ... on UserIsOnlyApproverInTeamConstraint {
          payload {
            teams {
              id
              name
            }
          }
        }
        ... on UserHasGuidesAssignedToConstraint {
          payload {
            guides {
              id
              title
            }
          }
        }
      }
    }
  }
`;
