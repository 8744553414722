import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';

import {FormattedMessage} from 'components/FormattedComponents';
import UntranslatedIndicator from 'components/UntranslatedIndicator';

import {Dropdown} from 'styles/styled-components/dropdown-styled-components';

import {PublishInstruction, SetInstructionToDraft} from 'api/instruction/mutations';
import {publishInstructionInGuideOptions, setInstructionToDraftInGuideOptions} from 'api/instruction/mutation-options';

import {styleable} from 'shared/decorators';

import notification from 'utils/notification-utils';
import {ensureDraftVersion} from 'utils/versioning-utils';

import {
  CardToolbar,
  DropdownToggle,
  StyledEnhancedIcon,
  StyledKebabIcon,
  StyledSignOffIcon,
  StyledSignatureIcon,
  StyledAlertRecipientsIcon
} from './styles';
import messages from './messages';
import {getMessages} from './utils';

@inject('store')
@graphql(PublishInstruction, publishInstructionInGuideOptions)
@graphql(SetInstructionToDraft, setInstructionToDraftInGuideOptions)
@styleable
class CardToolbarComponent extends Component {
  static defaultProps = {
    shouldShowUntranslatedIcon: false,
    visibilityIcon: false
  };

  static propTypes = {
    dropdownMenu: PropTypes.node,
    shouldShowUntranslatedIcon: PropTypes.bool,
    visibilityIcon: PropTypes.oneOf(['hidden', 'visible', false])
  };

  preventNavigation = event => {
    const {onClickMenu} = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (onClickMenu) {
      onClickMenu();
    }
  };

  publish = ensureDraftVersion(() => {
    const {
      instruction: {id},
      publishInstructionMutation,
      intl: {formatMessage},
      isChecklist
    } = this.props;
    const translations = getMessages(messages, formatMessage, isChecklist);

    return publishInstructionMutation({id})
      .then(() => {
        notification.success(translations.publish.showSuccess);
      })
      .catch(() => {
        notification.error(translations.publish.showFailure);
      });
  }, this);

  unpublish = ensureDraftVersion(() => {
    const {
      instruction: {id},
      setInstructionToDraftMutation,
      intl: {formatMessage},
      isChecklist
    } = this.props;
    const translations = getMessages(messages, formatMessage, isChecklist);

    setInstructionToDraftMutation({id})
      .then(() => {
        notification.success(translations.unpublish.showSuccess);
      })
      .catch(() => {
        notification.error(translations.unpublish.showFailure);
      });
  }, this);

  onSignOffIconClick = () => {
    const {
      store: {applyCampaignDialog},
      instruction
    } = this.props;
    const appliedFromInstruction = true;

    applyCampaignDialog.open([instruction], appliedFromInstruction, instruction.campaign);
  };

  onAlertRecipientClick = () => {
    const {
      store: {checklistAlertRecipientsDialog},
      instruction
    } = this.props;

    checklistAlertRecipientsDialog.open({
      id: instruction.id,
      title: instruction.title
    });
  };

  render() {
    const {
      dropdownMenu,
      shouldShowUntranslatedIcon,
      tooltipPlacement,
      visibilityIcon,
      isChecklist,
      dataCy,
      intl: {formatMessage},
      instruction,
      title,
      store: {
        platform: {hasChecklistsSignatureEnabled}
      }
    } = this.props;
    const translations = getMessages(messages, formatMessage, isChecklist);
    // To redo the condition with real data
    const showSignOffIcon = !isChecklist && instruction && instruction.campaign;
    const showRequireSignatureIcon =
      isChecklist && instruction && instruction.requireSignature && hasChecklistsSignatureEnabled;
    const showhasAlertRecipientsIcon = isChecklist && instruction.hasAlertRecipients;

    return (
      <CardToolbar onClick={this.preventNavigation} data-cy={dataCy}>
        {showRequireSignatureIcon && <StyledSignatureIcon />}
        {showSignOffIcon && <StyledSignOffIcon onClick={this.onSignOffIconClick} />}
        {showhasAlertRecipientsIcon && (
          <StyledAlertRecipientsIcon data-cy="alert-recipient-icon" onClick={this.onAlertRecipientClick} />
        )}
        {visibilityIcon === 'hidden' && (
          <StyledEnhancedIcon
            onClick={this.publish}
            name="invisible-eye"
            size={24}
            tooltip={<FormattedMessage {...translations.icons.clickToShow} />}
            tooltipPlacement={tooltipPlacement}
          />
        )}
        {visibilityIcon === 'visible' && (
          <StyledEnhancedIcon
            showOnlyOnHover
            onClick={this.unpublish}
            name="eye"
            size={24}
            tooltip={<FormattedMessage {...translations.icons.clickToHide} />}
            tooltipPlacement={tooltipPlacement}
          />
        )}
        {dropdownMenu && (
          <Dropdown overlay={dropdownMenu}>
            <DropdownToggle aria-label="campaign card menu toggle" data-cy={`open-${title}`}>
              <StyledKebabIcon />
              {shouldShowUntranslatedIcon && <UntranslatedIndicator size={9} position="absolute" top={0} right={-7} />}
            </DropdownToggle>
          </Dropdown>
        )}
      </CardToolbar>
    );
  }
}

export default injectIntl(CardToolbarComponent);
