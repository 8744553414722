import React from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import messages from './messages';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import {LoaderContainer, SkillsDiv, StyledText, StyledSubText, Table, Th, Tr} from './styles';
import views from 'config/views';

//components
import Icon from 'ui-components/Layout/Icon';
import LoadingMessage from 'components/LoadingMessage';
import Button from 'ui-components/Button';
import SkillProfileRow from './SkillProfileRow';
import Pagination from 'ui-components/Pagination';

import type {SkillProfile} from './index';
import {SkillLevels} from 'api/skill-matrix/queries';
import {useQuery} from '@apollo/client';

type SkillProfileTableProps = {
  loading: boolean;
  error: any;
  data: SkillProfile[];
  pageIndex: number;
  pageSize: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  totalCount: number;
  isFiltered: boolean;
  intl: {
    formatMessage: (message: {id: string; defaultMessage: string}) => string;
  };
};

const LoadingState = () => (
  <LoaderContainer>
    <LoadingMessage></LoadingMessage>
  </LoaderContainer>
);

const EmptyState = inject('store')(
  injectIntl<{
    store?: any;
  }>(props => {
    const {
      intl: {formatMessage},
      store: {router}
    } = props;

    return (
      <SkillsDiv>
        <Icon name="folder" size={25} />
        <StyledText>{formatMessage(messages.noSkillProfiles)}</StyledText>
        <StyledSubText>{formatMessage(messages.skillProfilesDescription)}</StyledSubText>
        <Button
          secondary
          size={50}
          label={formatMessage(messages.createSkillProfile)}
          iconId={'create'}
          onClick={() => {
            router.goTo(views.skillProfilesManage, {id: 'new'});
          }}
        />
      </SkillsDiv>
    );
  })
);

export const NoDataState = () => {
  return (
    <SkillsDiv>
      <FormattedMessage {...messages.noSkillProfilesFound} />
    </SkillsDiv>
  );
};

const ErrorState = () => {
  return (
    <SkillsDiv>
      <FormattedMessage {...messages.error} />
    </SkillsDiv>
  );
};

const SkillProfilesTable = injectIntl((props: SkillProfileTableProps) => {
  const {
    loading,
    error,
    data,
    pageIndex,
    pageSize,
    setPageIndex,
    totalCount,
    isFiltered,
    intl: {formatMessage}
  } = props;

  const skillLevelsQuery = useQuery(SkillLevels);
  const {data: {skillLevels} = {}} = skillLevelsQuery;

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState />;
  }

  if (!totalCount) {
    if (isFiltered) {
      return <NoDataState />;
    }

    return <EmptyState />;
  }

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <>
      {data.length > 0 && (
        <Table style={{marginTop: '20px'}}>
          <thead style={{position: 'sticky', top: 100, background: 'white', zIndex: 100}}>
            <Tr>
              <Th left width="250px" style={{minWidth: '250px'}}>
                {formatMessage(messages.skillProfile) + ` (${totalCount})`}
              </Th>
              <Th width="20px" right>
                {formatMessage(messages.skills)}
              </Th>
              <Th width="100px" right>
                {formatMessage(messages.teams)}
              </Th>
              <Th width="120px" right>
                {formatMessage(messages.jobTitles)}
              </Th>
              <Th width="20px" right>
                {}
              </Th>
            </Tr>
          </thead>
          {data.map(({id, name, skills = {}, teams, rolesCount}) => (
            <SkillProfileRow
              key={id}
              id={id}
              name={name}
              skills={skills}
              teams={teams}
              rolesCount={rolesCount}
              skillLevels={skillLevels}
            />
          ))}
        </Table>
      )}

      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={pageIndex}
          setPageIndex={setPageIndex}
          prevPageTitle={formatMessage(messages.prevPage)}
          nextPageTitle={formatMessage(messages.nextPage)}
        />
      )}
    </>
  );
});

export default SkillProfilesTable;
