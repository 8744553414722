import {defineMessages} from 'react-intl';

const messages = defineMessages({
  show: {
    id: 'show',
    defaultMessage: 'Show'
  },
  eventTypes: {
    id: 'eventTypes',
    defaultMessage: 'Event Types'
  }
});

export default messages;
