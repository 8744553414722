import React, {Component} from 'react';
import {IntlProvider} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {reaction, toJS} from 'mobx';
import views from 'config/views';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {ThemeProvider} from 'styled-components';
import Intercom from 'react-intercom';
import {isEmpty, get} from 'lodash';
import {library} from '@fortawesome/fontawesome-svg-core';
import {LocaleProvider} from 'antd';
import enGB from 'antd/es/locale-provider/en_GB';

//styles
import {InnerWrapper, MainColumn, ScrollingContainer, Cover} from './styles';

//components
import Header from 'components/Header';
import NavMenu from 'components/NavMenu';
import Dialogs from 'components/Dialogs';
import Spinner from 'shared/components/Spinner';
import {RouteTracker} from 'components/RouteTracker';

//data
import {Platform, AvailableLanguages} from 'api/platform/queries';
import {availableLanguagesOptions} from 'api/platform/query-options';

//utils
import {scrollbarWidth, scrollToTop} from 'shared/utils/dom-utils';
import {getShowIntercom, getIntercomProps} from 'utils/intercom-utils';
import {shouldRestrictAccessToRoute, userHasCorrectFlags} from './app-helpers';

//icons
import {iconsForLibrary} from 'styles/icons';

library.add(...iconsForLibrary);

@inject('store')
@graphql(Platform)
@graphql(AvailableLanguages, availableLanguagesOptions)
@observer
class AppComponent extends Component {
  scrollingContainerRef = null;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data && nextProps.data.platformForCms) {
      const {
        store: {app, platform},
        availableLanguagesQuery
      } = nextProps;
      platform.setAvailableLanguagesDetails(availableLanguagesQuery.availableLanguages);
      const {theme} = nextProps.data.platformForCms.domain;
      platform.setSubdomain(nextProps.data.platformForCms.subdomain);
      app.setTheme(theme);
      platform.setAssetsFolder(nextProps.data.platformForCms.assetsFolder);
      platform.setIconsShowHintLabel(nextProps.data.platformForCms.showStepNoteIconLabel);
      platform.setDefaultDomainSlug(nextProps.data.platformForCms.defaultDomain);
      platform.setPlatformSlug(nextProps.data.platformForCms.subdomain);
      platform.setWebhookUrl(nextProps.data.platformForCms.webhookUrl);
    }
  }

  UNSAFE_componentWillMount = async () => {
    const {
      store: {auth, router, platform}
    } = this.props;

    auth.getCurrentUser();
    platform.fetchAvailableLanguages();

    platform.fetchPlatformFlags();
    await platform.fetchDevelopmentFeatureFlags();

    platform.startAutomaticallyLogoutListener();

    reaction(() => router.currentRoute, this.handleCurrentViewChange);
  };

  componentDidMount() {
    const ieWarning = document.getElementById('ie-warning');

    if (ieWarning) {
      ieWarning.style.right = `${scrollbarWidth}px`;
    }
  }

  componentWillUnmount() {
    const {
      store: {
        app: {handleGoesOffline, handleGoesBackOnline}
      }
    } = this.props;

    window.removeEventListener('online', handleGoesBackOnline);
    window.removeEventListener('offline', handleGoesOffline);
  }

  // Workaround for an iOS bug: https://app.clubhouse.io/swipe-guide/story/3822
  //
  // Sometimes iOS Safari is unable to render the scrolling container's contents
  // because it has both "-webkit-overflow-scrolling: touch" and "position: relative"
  // styles simultaneously, and those don't play together well.
  //
  // To work around it, we turn off the overflow scrolling style for a single moment
  // to let iOS draw the elements under normal conditions.
  //
  forceRedrawWithRegularScrolling = scrollingContainer => {
    scrollingContainer.style.webkitOverflowScrolling = 'auto';
    window.requestAnimationFrame(() => {
      scrollingContainer.style.webkitOverflowScrolling = '';
    });
  };

  handleCurrentViewChange = () => {
    const {
      store: {platform}
    } = this.props;

    const scrollingContainer = get(this, 'scrollingContainerRef.wrappedInstance.scrollParentRef');

    platform.startAutomaticallyLogoutListener();

    if (scrollingContainer) {
      this.forceRedrawWithRegularScrolling(scrollingContainer);
      scrollToTop(scrollingContainer);
    }
  };

  setScrollingContainerRef = scrollingContainerRef => {
    this.scrollingContainerRef = scrollingContainerRef;
  };

  render() {
    const {store, data} = this.props;
    const {loading: dataLoading} = data;
    const {
      router,
      auth,
      platform,
      app: {theme, isActionMenuOpen},
      cookiePreference: {showCookiePreferenceDialog}
    } = store;
    const {user} = auth;
    const {activeLocale, localeMessages} = platform;
    const showIntercom = getShowIntercom({
      platform,
      isActionMenuOpen
    });

    const themeObject = toJS(theme);
    const loading =
      !platform.loaded ||
      auth.authing ||
      dataLoading ||
      !themeObject ||
      !platform.availableLanguages ||
      isEmpty(localeMessages);
    const showCurrentComponent = !loading && router.currentRoute;
    const showHeader = !loading && themeObject;
    const showNavMenu = !loading && themeObject && user;

    const shouldRestrictAccess = shouldRestrictAccessToRoute({
      user: auth.user,
      currentRoute: router.currentRoute
    });

    const loggedInUserComponent = userHasCorrectFlags(auth.user, router.currentRoute)
      ? router.currentRoute?.component
      : views.root.component;

    const currentComponent = shouldRestrictAccess ? views.login.component : loggedInUserComponent;

    const {intercomID, ...intercomAttributes} = getIntercomProps({
      platform,
      user
    });

    if (loading) {
      return null;
    }

    const logoUrl = get(themeObject, 'logoMedia.url', null);
    return (
      <IntlProvider locale={activeLocale} messages={localeMessages[activeLocale]}>
        <ThemeProvider theme={themeObject}>
          <RouteTracker />
          <LocaleProvider locale={enGB}>
            <ScrollingContainer ref={this.setScrollingContainerRef}>
              {showHeader && <Header logoUrl={logoUrl} />}
              {showNavMenu && <NavMenu logoUrl={logoUrl} />}
              <InnerWrapper>
                {loading && <Spinner center centerAbs />}
                {showCurrentComponent && <MainColumn>{currentComponent}</MainColumn>}
                {isActionMenuOpen && <Cover />}
              </InnerWrapper>
              <Dialogs showCookiePreferenceDialog={showCookiePreferenceDialog} />
              {showIntercom && <Intercom appID={intercomID} {...intercomAttributes} />}
            </ScrollingContainer>
          </LocaleProvider>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

export default AppComponent;
