import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';
import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';

const plugins = {dvr: dvr(validatorjs)};

const fields = [
  {
    name: 'title',
    rules: 'required|string'
  },
  {
    name: 'defaultLocale',
    rules: 'required|string',
    value: 'en_US'
  },
  {
    name: 'kind',
    rules: 'required|string',
    value: CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO
  },
  {
    name: 'question',
    rules: 'required|string'
  },
  {
    name: 'numOfRating',
    rules: 'required_if:kind,NUMBER|required_if:kind,STAR|required_if:kind,TEXT|between:1,100|integer'
  },
  {
    name: 'translationLocale',
    rules: 'string'
  },
  {
    name: 'translation',
    rules: 'string'
  }
];

export default new MobxReactForm({fields}, {plugins});

export const createCampaignQuestionForm = () => new MobxReactForm({fields}, {plugins});
