import React from 'react';
import {observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {useQuery} from '@apollo/client';
import {SkillProfilesDialogProps, SkillProfileType} from './types';

//styles
import {
  dialogStyle,
  Title,
  HeaderWrapper,
  CloseButton,
  Description,
  SkillProfilesWrapper,
  LoaderContainer
} from './styles';

//messages
import messages from './messages';

//components
import {Dialog} from 'ui-components';
import {useStore} from 'hooks/useStore';
import SkillProfileCard from 'components/SkillProfileCard';
import Button from 'ui-components/Button';

// data
import {SkillLevels} from 'api/skills/queries';
import LoadingMessage from 'components/LoadingMessage';

const LoadingState = () => (
  <LoaderContainer>
    <LoadingMessage></LoadingMessage>
  </LoaderContainer>
);

const SkillProfilesDialog = ({intl}: SkillProfilesDialogProps) => {
  const store = useStore();
  const {skillProfilesDialog} = store;
  const {opened, close, skillName, skillProfiles} = skillProfilesDialog;
  const {formatMessage} = intl;
  const {data, loading} = useQuery(SkillLevels);

  if (loading) {
    return <LoadingState />;
  }

  const isBinary = data?.skillLevels.length === 2;

  return (
    <Dialog dialogStyle={dialogStyle} actions={[]} hideActions={true} isOpen={opened} onCancel={close}>
      <HeaderWrapper>
        <Title>{skillName}</Title>
        <CloseButton onClick={close} />
      </HeaderWrapper>
      <Description>{formatMessage(messages.connectedSkillProfiles, {number: skillProfiles?.length})}</Description>
      <SkillProfilesWrapper>
        {skillProfiles?.map((skillProfile: SkillProfileType) => (
          <SkillProfileCard skillProfile={skillProfile} isBinary={isBinary} key={skillProfile.id} />
        ))}
      </SkillProfilesWrapper>
      <Button label={formatMessage(messages.cancel)} onClick={close} secondary />
    </Dialog>
  );
};

export default injectIntl(observer(SkillProfilesDialog));
