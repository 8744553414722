import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';

import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const COMMENT_FIELD = 'comment';

const fields = [
  {
    name: COMMENT_FIELD,
    label: COMMENT_FIELD,
    rules: 'string|max:512'
  }
];

export const getComment = form => form.$(COMMENT_FIELD).value;
export const commentForm = () => new MobxReactForm({fields}, {plugins});
