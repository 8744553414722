import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

//components
import Dialog from 'ui-components/Dialog';

import messages from './messages';

import {Text} from './styles';

@inject('store')
@observer
class IconsLimitDialogComponent extends Component {
  render() {
    const {store, intl} = this.props;
    const {formatMessage} = intl;
    const {iconsLimitDialog: dialog} = store;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.ok),
            design: 'text',
            onClick: () => dialog.reset()
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        size="small"
        title={formatMessage(messages.title)}
        bodyStyle={{paddingBottom: 0}}
        titleIconId="warning"
        showCancel={false}
      >
        <Text>
          {formatMessage(messages.limitExceeded)}
          <br />
          {formatMessage(messages.disableIcon)}
        </Text>
      </Dialog>
    );
  }
}

export default injectIntl(IconsLimitDialogComponent);
