import {useScrollToAnchor} from 'hooks/useScrollToAnchor';
import {useStore} from 'hooks/useStore';
import {observer} from 'mobx-react';

const ScrollToRouteAnchor = observer(() => {
  const {router} = useStore();
  const anchor = router.queryParams?.l;
  useScrollToAnchor(anchor);
  return null;
});

export default ScrollToRouteAnchor;
