import {generateStepNote} from './stepNote';

import {correctHtmlContent} from '../utils';

export const generateStepNotes = async (notes, pdfMessages, additionalLinkInfo, guideBaseUrl, intl, linksMap) => {
  const correctedHtmlNotes = notes.map(note => ({
    ...note,
    contentHtml: correctHtmlContent(note.contentHtml, linksMap)
  }));

  const stepNotes = [];

  for (let i = 0; i < correctedHtmlNotes.length; i++) {
    const stepNote = await generateStepNote(correctedHtmlNotes[i], pdfMessages, additionalLinkInfo, guideBaseUrl, intl);

    if (stepNote) {
      stepNotes.push(stepNote);
    }
  }

  return stepNotes;
};
