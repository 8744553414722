import styled from 'styled-components';

export const AnalyticsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
`;

export const SpinnerContainer = styled.div`
  background-color: transparent;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
`;

export const AnalyticsContent = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 1s ease-out;

  ${p =>
    p.isLoading &&
    `
    opacity: 0.2;
  `}
`;
