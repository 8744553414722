import {defineMessages} from 'react-intl';

const messages = defineMessages({
  belongsToTeam: {
    id: 'belongsToTeam',
    defaultMessage: 'Belongs to team'
  },
  selectBelongsToTeam: {
    id: 'selectBelongsToTeam',
    defaultMessage: 'Select a Team to which this one belongs to'
  }
});

export default messages;
