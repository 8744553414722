import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {commonPalette} from 'shared/styles/palette';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

//components
import Dialog from 'ui-components/Dialog';
import ReassignGuide from 'components/ReassignGuide';

//data
import {TeamsPaginatedWithMembers} from 'api/team/queries';
import {teamsPaginatedOptions} from 'api/team/query-options';
import {ReassignGuide as ReassignGuideMutation} from 'api/guide/mutations';
import {reassignGuideOptions} from 'api/guide/mutation-options';
import {GetGuideAssignee} from 'api/guide/queries';
import {getGuideAssigneeOptions} from 'api/guide/query-options';

//messages
import messages from './messages';

@inject('store')
@observer
@graphql(TeamsPaginatedWithMembers, teamsPaginatedOptions)
@graphql(GetGuideAssignee, getGuideAssigneeOptions)
@graphql(ReassignGuideMutation, reassignGuideOptions)
@observer
class ReassignGuideDialog extends Component {
  UNSAFE_componentWillMount() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {reassignGuideDialog} = store;

    const translations = mapValues(pick(messages, ['reassignError', 'reassignSuccess']), message =>
      formatMessage(message)
    );

    reassignGuideDialog.setTranslations(translations);
  }

  componentDidUpdate() {
    const {
      teamsPaginatedQuery: {teamsPaginated, loading},
      data: {guideDetails, loading: guideLoading},
      store
    } = this.props;

    const {reassignGuideDialog: dialog} = store;

    if (loading || guideLoading) {
      dialog.setLoading(true);
    }

    if (teamsPaginated && !loading) {
      if (teamsPaginated.offset === 0) {
        dialog.setTeams(teamsPaginated.results, [], teamsPaginated.nextOffset !== null);
      } else {
        dialog.pushTeams(teamsPaginated.results, [], teamsPaginated.nextOffset !== null);
      }
      dialog.setLoading(false);
    }

    if (guideDetails && !guideLoading) {
      const {assignedToUser} = guideDetails;
      dialog.setLoading(false);
      dialog.setGuideAssignee(assignedToUser.id);
    }
  }

  onScrollToBottom = () => {
    const {teamsPaginatedQuery, store} = this.props;
    store.reassignGuideDialog.onScrollToBottom(teamsPaginatedQuery);
  };

  onSearch = value => {
    const {reassignGuideDialog} = this.props.store;
    reassignGuideDialog.setSearchTerm(value);
  };

  componentWillUnmount() {
    const {reassignGuideDialog} = this.props.store;
    reassignGuideDialog.resetPagination();
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      reassignGuideMutation
    } = this.props;
    const {reassignGuideDialog: dialog} = store;
    const enableSubmit = (dialog.newAssigneeId ? true : false) && dialog.comment;
    const {reassigning} = dialog;

    return (
      <Dialog
        actions={[
          {
            label: reassigning ? null : formatMessage(messages.reassign),
            disabled: !enableSubmit,
            loading: reassigning,
            onClick: () => dialog.reassignGuideSubmit(reassignGuideMutation),
            type: 'submit'
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        title={formatMessage(messages.reassignThisGuide)}
        bodyStyle={{color: commonPalette.txBlack}}
      >
        <ReassignGuide onScrollToBottom={this.onScrollToBottom} onSearch={this.onSearch} />
      </Dialog>
    );
  }
}

export default injectIntl(ReassignGuideDialog);
