import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {computed, toJS, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import get from 'lodash/get';
import find from 'lodash/find';
import {client} from 'utils/apollo-client';

//helpers
import notification from 'utils/notification-utils';
import messages from './messages';

//components
import Icon from 'ui-components/Icon';

//utils
import {stepNoteTypes} from 'shared/utils/instruction-utils';

import {
  StyledContainer,
  StyledDataTable,
  StyledCustomTag,
  StyledIcon,
  StyledEnhancedIcon,
  CellInner,
  SvgIconWrapper,
  IconLabel,
  Link
} from './styles';

//queries
import {NoteIconCheckAffectedGuides} from 'api/platform/queries';

//mutations
import {ToggleIconVisiblity} from 'api/platform/mutations';
import {toggleIconVisiblityOptions} from 'api/platform/mutation-options';

@inject('store')
@graphql(ToggleIconVisiblity, toggleIconVisiblityOptions)
@observer
class InstructionalIconsList extends Component {
  UNSAFE_componentWillMount = () => {
    const {
      store: {instructionalIconDialog},
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(
      pick(messages, ['updateSuccess', 'updateFailure', 'uploadSuccess', 'uploadFailure']),
      message => formatMessage(message)
    );

    instructionalIconDialog.setTranslations(translations);
  };

  getRowClassName = item => {
    return !item.isEnabled && '-row-disabled';
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get enabledIcons() {
    const {icons} = this.props;
    return icons.filter(icon => icon.isEnabled === true) || [];
  }

  toggleVisibilityOnClick = async (icon, e) => {
    e.preventDefault();

    const {iconsLimitDialog} = this.props.store;

    if (!icon.isEnabled && this.enabledIcons.length === 5) {
      iconsLimitDialog.open();
      return;
    }

    const {id: iconId, isEnabled} = icon;

    if (isEnabled) {
      await this.checkAffectedGuidesQuery(iconId);
      return;
    }

    this.toggleIconVisibility(iconId);
  };

  toggleIconVisibility = iconId => {
    const {
      toggleIconVisiblityMutation,
      intl: {formatMessage}
    } = this.props;

    toggleIconVisiblityMutation({iconId})
      .then(() => notification.success(formatMessage(messages.toggleIconVisibilitySuccess)))
      .catch(() => notification.error(formatMessage(messages.toggleIconVisibilityFailure)));
  };

  checkAffectedGuidesQuery = async iconId => {
    const {
      intl: {formatMessage},
      store: {noteIconsAffectedGuidesDialog}
    } = this.props;

    try {
      const {data} = await client.query({
        query: NoteIconCheckAffectedGuides,
        variables: {
          iconId
        },
        fetchPolicy: 'network-only'
      });

      const {checkAffectedGuides: affectedGuides} = data;

      if (affectedGuides && affectedGuides.length) {
        noteIconsAffectedGuidesDialog.open({
          affectedGuides,
          toggleIconVisibilityConfirm: this.toggleIconVisibility.bind(this, iconId)
        });
        return;
      }

      this.toggleIconVisibility(iconId);
    } catch (e) {
      notification.error(formatMessage(messages.toggleIconVisibilityFailure));
    }
  };

  getIconLabel = (type, labelTranslations = []) => {
    const {
      store: {
        platform: {currentLanguage}
      },
      intl: {formatMessage}
    } = this.props;

    if (!type) {
      return '';
    }

    if (!this.isCustomIcon(type) || labelTranslations.length === 0) {
      return formatMessage(messages[type.toLowerCase() + 'Label']);
    }

    const currentLanguageTranslation = find(labelTranslations, {locale: currentLanguage});
    if (currentLanguageTranslation) {
      return get(currentLanguageTranslation, 'translation');
    }

    return get(labelTranslations, '[0].translation');
  };

  hasMissingTranslations = (labelTranslations = []) => {
    const {
      store: {
        platform: {availableLanguages = []}
      }
    } = this.props;

    const availableLabels = labelTranslations.filter(label => label.translation);
    return availableLabels.length === toJS(availableLanguages).length;
  };

  isCustomIcon = type => type === stepNoteTypes.CUSTOM;

  render() {
    const {
      intl: {formatMessage},
      store,
      isThemeStylingView = false,
      icons,
      onEditIcon
    } = this.props;

    const {
      themePage: {colorsForm}
    } = store;

    // on theme-page inherit buttonColor from the selected theme
    const themeColor = isThemeStylingView && get(colorsForm.values(), 'buttonColor');

    const columns = [
      {
        Header: formatMessage(messages.stepNoteIcon),
        accessor: 'svg',
        Cell: p => {
          return (
            <SvgIconWrapper
              dangerouslySetInnerHTML={{__html: p.value}}
              isWarning={p.original.type === stepNoteTypes.WARNING}
              themeColor={themeColor}
            />
          );
        },
        width: 120,
        sortable: false
      },
      {
        Header: formatMessage(messages.stepNoteName),
        accessor: 'labelTranslations',
        Cell: p => {
          return <IconLabel>{this.getIconLabel(p.original.type, p.value)}</IconLabel>;
        },
        width: '20%',
        sortable: false
      },
      {
        accessor: 'edit',
        Cell: p => {
          return (
            <CellInner>
              {(isThemeStylingView || (!isThemeStylingView && this.isCustomIcon(p.original.type))) && (
                <Link
                  onClick={onEditIcon.bind(this, {
                    icon: {...p.original, shownLabel: this.getIconLabel(p.original.type, p.original.labelTranslations)},
                    isThemeStylingView
                  })}
                  data-cy="edit-step-note-button"
                >
                  <StyledIcon iconId="edit" size={48} />
                </Link>
              )}
            </CellInner>
          );
        },
        width: 30,
        maxWidth: 30,
        minWidth: 30,
        sortable: false
      }
    ];

    if (!isThemeStylingView) {
      columns.splice(2, 0, {
        accessor: 'type',
        Cell: p => {
          return this.isCustomIcon(p.value) ? (
            <StyledCustomTag>
              <Icon iconId="user" solid={false} />
              {formatMessage(messages.customTag)}
            </StyledCustomTag>
          ) : null;
        },
        width: '20%',
        sortable: false
      });

      columns.splice(3, 0, {
        accessor: 'labelTranslations',
        Cell: p => {
          return (
            this.isCustomIcon(p.original.type) &&
            !this.hasMissingTranslations(p.value) && (
              <CellInner>
                <StyledEnhancedIcon
                  badge={true}
                  name="globe-translations"
                  size={16}
                  tooltip={formatMessage(messages.labelNotTranslated)}
                  tooltipPlacement={'bottom'}
                />
              </CellInner>
            )
          );
        },
        width: 30,
        maxWidth: 30,
        minWidth: 30,
        style: {marginRight: 0},
        sortable: false
      });

      columns.splice(4, 0, {
        accessor: 'isEnabled',
        Cell: p => {
          return (
            this.isCustomIcon(p.original.type) && (
              <CellInner>
                <Link
                  onClick={this.toggleVisibilityOnClick.bind(this, p.original)}
                  className={'toggle-visibility-button'}
                  data-cy="toggle-visibility-button"
                >
                  <StyledIcon iconId={p.value ? 'show' : 'hide'} solid={false} size={48} />
                </Link>
              </CellInner>
            )
          );
        },
        width: 30,
        maxWidth: 30,
        minWidth: 30,
        style: {marginRight: 0},
        sortable: false
      });
    }

    return (
      <StyledContainer>
        <StyledDataTable
          data={icons}
          getTrProps={(state, rowInfo) => ({
            className: this.getRowClassName(rowInfo.original)
          })}
          columns={columns}
        />
      </StyledContainer>
    );
  }
}

export default injectIntl(InstructionalIconsList);
