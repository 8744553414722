import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject} from 'mobx-react';

//helpers
import {styleable} from 'shared/decorators';
import {find} from 'lodash';

//components
import Select from 'ui-components/Select';

//messages
import messages from './messages';

@inject('store')
@styleable
class TranslationPickerComponent extends Component {
  render() {
    const {
      changeLanguage,
      intl,
      selectedLanguage,
      placeholder,
      store: {platform}
    } = this.props;
    const {formatMessage} = intl;
    const availableTranslations = this.props.availableTranslations;
    const {availableLanguages} = platform;

    const formatAvailableTranslations = locale => {
      const language = find(availableLanguages, {locale});
      return language
        ? {
            name: `${language.language} (${language.locale})`,
            id: language.locale
          }
        : {};
    };
    const translationsToSelect =
      (availableTranslations && availableTranslations.map(formatAvailableTranslations)) || [];

    return (
      <Select
        className="translations-picker"
        options={translationsToSelect}
        placeholder={placeholder || formatMessage(messages.selectPlaceholder)}
        selectedValue={selectedLanguage}
        onChange={changeLanguage}
        style={{width: 160}}
      />
    );
  }
}

export default injectIntl(TranslationPickerComponent);
