import React, {ReactElement} from 'react';
import Icon from 'ui-components/Icon';
import {SkillLevel} from 'views/SmartSkillsTeam/types';
interface PositionProps {
  position?: number;
  skillLevels: SkillLevel[];
}

export default function Position({position, skillLevels}: Readonly<PositionProps>): ReactElement {
  const isBinary = skillLevels.length === 2;
  if (isBinary) {
    const isLowest = skillLevels[0].position === position;
    if (isLowest || !position) {
      return <Icon dataCy="cross-icon" iconId="close" />;
    }
    return <Icon dataCy="check-icon" iconId="checkmark" />;
  }
  if (position != null) {
    return <>{position}</>;
  }
  return <>{'-'}</>;
}
