import {defineMessages} from 'react-intl';

const messages = defineMessages({
  tagCreationRole: {
    id: 'tagCreation',
    defaultMessage: 'Tags can be created by:'
  },
  tagCreation: {
    id: 'PlatformSettings.tagCreation',
    defaultMessage: 'Tag Management'
  }
});

export default messages;
