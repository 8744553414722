import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import MediaFeedbackCheck from './MediaFeedbackCheck';
import ChecklistOptionBinaryBagde from 'shared/components/ChecklistOptionBinaryBadge';
import ChecklistOptionScoreBagde from 'shared/components/ChecklistOptionScoreBadge';

import messages from './messages';
import {
  ResultStatus,
  CheckContent,
  CheckIndex,
  ResultContent,
  ResultHeader,
  StyledStepContent,
  ChecksHeaderTitle,
  ChecksHeaderSubtitle,
  FeedbackContent,
  StyledTable
} from './styles';
import {HeaderWrapper, CellWrapper} from 'ui-components/Layout/DataTable/styles';

import {SectionTitle} from '../styles';

@inject('store')
@observer
class SessionResultsSection extends Component {
  render() {
    const {
      intl: {formatMessage},
      store: {
        checklistsPage: {currentChecklistSessionItems: items, checklistType},
        platform: {
          developmentFeatureFlags: {checklistScoring}
        }
      }
    } = this.props;

    const columns = [
      {
        Header: () => (
          <HeaderWrapper>
            <ChecksHeaderTitle>{formatMessage(messages.checks)}</ChecksHeaderTitle>
            <ChecksHeaderSubtitle>
              {formatMessage(messages.totalChecks, {checksCount: items.length})}
            </ChecksHeaderSubtitle>
          </HeaderWrapper>
        ),
        accessor: 'checks',
        resizable: true,
        width: 250,
        sortable: false,
        Cell: ({original, index}) => (
          <CellWrapper>
            <CheckContent>
              <CheckIndex>{index + 1}</CheckIndex>
              <StyledStepContent
                data-cy={`check-content-${index + 1}`}
                dangerouslySetInnerHTML={{__html: original.step.instructionHtml}}
                isCompact={true}
                noPadding={true}
              />
            </CheckContent>
          </CellWrapper>
        )
      },
      {
        Header: () => (
          <HeaderWrapper>
            <ResultHeader>{formatMessage(messages.result)}</ResultHeader>
          </HeaderWrapper>
        ),
        accessor: 'result',
        width: checklistScoring && checklistType === 'SCORE' ? 200 : 100,
        sortable: false,
        Cell: ({original}) => (
          <CellWrapper>
            <ResultContent>
              {!checklistScoring && <ResultStatus value={original.value} />}
              {checklistScoring && original?.templateOption === null && <ChecklistOptionScoreBagde />}
              {checklistScoring && checklistType === 'BINARY' && original?.templateOption !== null && (
                <ChecklistOptionBinaryBagde value={!!original.templateOption?.value} />
              )}
              {checklistScoring && checklistType === 'SCORE' && original?.templateOption !== null && (
                <ChecklistOptionScoreBagde label={original.templateOption?.option} />
              )}
            </ResultContent>
          </CellWrapper>
        )
      },
      {
        Header: <HeaderWrapper>{formatMessage(messages.feedback)}</HeaderWrapper>,
        accessor: 'feedback',
        resizable: true,
        sortable: false,
        style: {whiteSpace: 'unset'},
        Cell: ({original, index}) => (
          <CellWrapper>
            <FeedbackContent data-cy={`feedback-text-${index + 1}`} title={original.feedback}>
              {original.feedback}
            </FeedbackContent>
          </CellWrapper>
        )
      },
      {
        Header: <HeaderWrapper>{formatMessage(messages.media)}</HeaderWrapper>,
        accessor: 'image',
        width: 160,
        sortable: false,
        Cell: ({original}) => original.media && <MediaFeedbackCheck check={original} />
      }
    ];

    return (
      <div>
        <SectionTitle>{formatMessage(messages.sessionResults)}</SectionTitle>
        <StyledTable data={items} columns={columns} noDataMessage={formatMessage(messages.noChecks)} />
      </div>
    );
  }
}

export default injectIntl(SessionResultsSection);
