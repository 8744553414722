import {defineMessages} from 'react-intl';

const messages = defineMessages({
  close: {
    id: 'common.actions.close',
    defaultMessage: 'Close'
  },
  apply: {
    id: 'common.actions.apply',
    defaultMessage: 'Apply'
  }
});

export default messages;
