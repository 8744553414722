import PropTypes from 'prop-types';
import React, {Component} from 'react';

//styles
import {StickySidebar, StickySidebarWrapper} from './styles';

class StickySidebarComponent extends Component {
  static defaultProps = {
    isOpen: true
  };

  static propTypes = {
    isOpen: PropTypes.bool,
    wrapperStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  };

  render() {
    const {isOpen, wrapperStyle, ...forwardedProps} = this.props;

    return (
      <StickySidebarWrapper isOpen={isOpen} wrapperStyle={wrapperStyle}>
        <StickySidebar {...forwardedProps} />
      </StickySidebarWrapper>
    );
  }
}

export default StickySidebarComponent;
