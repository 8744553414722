import PropTypes from 'prop-types';
import React from 'react';

//components
import AbstractIcon from 'shared/components/AbstractIcon';

//known icons
import icons from './icons.json';

const IconComponent = props => <AbstractIcon {...props} icons={icons} />;

IconComponent.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons))
};

export default IconComponent;
