import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';
import mutationNames from 'api/theme/constants';
import {graphql} from '@apollo/client/react/hoc/graphql';

// components
import StepsTabs from 'components/StepsTabs';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';

//queries
import {TeamsLite} from 'api/team/queries';
import {IconsList} from 'api/platform/queries';

//mutations
import {CreateTheme} from 'api/theme/mutations';

//options
import {createThemeOptions} from 'api/theme/mutation-options';
import {teamsLiteOptions} from 'api/team/query-options';
import {iconsListEnabledOptions} from 'api/platform/query-options';

//components
import views from 'config/views';

//messages
import messages from './messages';

@inject('store')
@graphql(CreateTheme, createThemeOptions)
@graphql(TeamsLite, teamsLiteOptions)
@graphql(IconsList, iconsListEnabledOptions)
@observer
class NewThemeView extends Component {
  static defaultProps = {
    store: null,
    data: null
  };

  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {
        auth: {
          user: {canManageThemes, isPlatformAdmin}
        },
        themePage,
        router: {goTo}
      },
      store
    } = this.props;

    if (!canManageThemes) {
      goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, [
        'nameAndLogo',
        'colors',
        'stepNoteIcons',
        'fontSettings',
        'actionSuccess',
        'actionFailure',
        'default'
      ]),
      message => formatMessage(message)
    );

    themePage.setTranslations(translations);
    themePage.setNameAndLogoForm(isPlatformAdmin);
    themePage.setSteps(false);
    themePage.stepsStore.startListeningToRouteChange();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store,
      teamsQuery: {teams = [], loading},
      iconsListEnabledQuery: {icons = [], loading: loadingIcons}
    } = nextProps;
    const {themePage} = store;

    if (!loading && teams) {
      themePage.setTeams(teams);
    }

    if (!loadingIcons && icons) {
      themePage.setPlatformIcons(icons);
    }
  }

  componentWillUnmount() {
    const {
      store: {
        themePage: {reset, stepsStore}
      }
    } = this.props;
    reset();
    stepsStore.stopListeningToRouteChange();
  }

  render() {
    const {
      store,
      store: {
        themePage,
        themePage: {createTheme, stepsStore}
      },
      intl: {formatMessage}
    } = this.props;
    const createThemeMutation = this.props[mutationNames.CREATE_THEME];

    const formSubmit = () => {
      createTheme(createThemeMutation);
      store.router.goTo(views.themes, {});
    };
    const loading = false;
    const error = false;

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <StepsTabs
          mode={'create'}
          loading={loading}
          error={error}
          stepsStore={stepsStore}
          page={themePage}
          formSubmit={formSubmit}
          cancel={() => store.router.goTo(views.themes, {})}
        />
      </Container>
    );
  }
}

export default injectIntl(NewThemeView);
