import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick, isEmpty} from 'lodash';

import Select from 'ui-components/Select';
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import {FormattedMessage} from 'components/FormattedComponents';

import {Campaign} from 'api/campaign/queries';
import {campaignOptions} from 'api/campaign/query-options';
import {EditCampaign} from 'api/campaign/mutations';
import {editCampaignOptions} from 'api/campaign/mutation-options';

import views from 'config/views';

import messages from './messages';
import {
  TranslationContentWrapper,
  LanguagePickerWrapper,
  DefaultLanguageQuestionWrapper,
  TranslationField,
  Title,
  StyledSpinner,
  SelectWrapper,
  StyledButtonGroup
} from './styles';

@inject('store')
@graphql(Campaign, campaignOptions)
@graphql(EditCampaign, editCampaignOptions)
@observer
class CampaignTranslateView extends Component {
  componentDidMount = () => {
    const {
      intl: {formatMessage},
      store
    } = this.props;

    const {
      translateCampaignPage: {setTranslations, setAvailableLanguages},
      auth: {
        user: {canManageCampaigns}
      },
      platform: {availableLanguages, multipleLocales},
      router
    } = store;

    if (!canManageCampaigns) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, ['translationsSuccess', 'translationsFailure', 'complete']),
      message => formatMessage(message)
    );

    setTranslations(translations);

    if (multipleLocales) {
      setAvailableLanguages(availableLanguages);
    }
  };

  componentDidUpdate = () => {
    const {
      store: {
        translateCampaignPage: {setCampaign, campaign, wasTranslated, setWasTranslated}
      },
      data: {campaign: campaignData}
    } = this.props;

    if ((!campaign && campaignData) || wasTranslated) {
      setCampaign(campaignData);
      setWasTranslated(false);
    }
  };

  componentWillUnmount = () => {
    const {
      store: {
        translateCampaignPage: {resetCampaign, campaign}
      }
    } = this.props;

    if (campaign) {
      resetCampaign();
    }
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        translateCampaignPage: {campaign}
      }
    } = this.props;

    if (!campaign) {
      return (
        <Container>
          <ViewTitle title={formatMessage(messages.translate)} />
          <StyledSpinner />
        </Container>
      );
    }

    const {
      store: {translateCampaignPage},
      editCampaignMutation
    } = this.props;
    const {
      questionForm,
      languageOptions,
      defaultLanguage,
      selectedLocale,
      setTranslationLanguage,
      submit,
      resetCampaign
    } = translateCampaignPage;
    const {
      defaultLocale,
      title,
      question: {questionTranslations, textTypeOptions, followUps}
    } = campaign;

    const questionText = questionTranslations[defaultLocale];
    const questionFormValues = questionForm.values();

    // Adding the placeholder text for the select, but not displaying it on the options list
    const options = [
      {id: 'placeholder', name: formatMessage(messages.languageSelect), disabled: true, hidden: true},
      ...languageOptions
    ];

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.translate)}
          subtitle={formatMessage(messages.addTranslations, {title})}
        />

        <LanguagePickerWrapper>
          <DefaultLanguageQuestionWrapper>
            <Title>
              <FormattedMessage {...messages.defaultLanguage} />
            </Title>
            <h4>{defaultLanguage}</h4>
          </DefaultLanguageQuestionWrapper>

          <SelectWrapper>
            <Select
              autofocus={true}
              options={options}
              onChange={setTranslationLanguage}
              selectedValue={questionFormValues.translationLocale}
            />
          </SelectWrapper>
        </LanguagePickerWrapper>

        <TranslationContentWrapper>
          <DefaultLanguageQuestionWrapper>
            <Title>
              <FormattedMessage {...messages.question} />
            </Title>
            <h4>{questionText}</h4>
          </DefaultLanguageQuestionWrapper>

          <TranslationField
            size="small"
            placeholder={
              selectedLocale ? formatMessage(messages.translateQuestion) : formatMessage(messages.pleaseSelectLanguage)
            }
            dataTestId={selectedLocale && 'input-question-translate'}
            field="translation"
            form={translateCampaignPage[`question${selectedLocale}`]}
            disabled={!selectedLocale}
          />
        </TranslationContentWrapper>

        {textTypeOptions &&
          !isEmpty(textTypeOptions) &&
          textTypeOptions.map((item, index) => (
            <TranslationContentWrapper key={index}>
              <DefaultLanguageQuestionWrapper>
                <Title>{`${formatMessage(messages.option)} ${index + 1}`}</Title>
                <h4>{item[defaultLocale]}</h4>
              </DefaultLanguageQuestionWrapper>

              <TranslationField
                size="small"
                placeholder={
                  selectedLocale
                    ? formatMessage(messages.translateOption)
                    : formatMessage(messages.pleaseSelectLanguage)
                }
                field="translation"
                dataTestId={`input-text-option-${index}-translate`}
                form={translateCampaignPage[`textTypeOption${index}${selectedLocale}`]}
                disabled={!selectedLocale}
              />
            </TranslationContentWrapper>
          ))}

        {!isEmpty(followUps) &&
          followUps.map((followUp, index) => (
            <TranslationContentWrapper key={index}>
              <DefaultLanguageQuestionWrapper>
                <Title>
                  <FormattedMessage {...messages.followUpQuestion} />
                </Title>
                <h4>{followUp.questionTranslations[defaultLocale]}</h4>
              </DefaultLanguageQuestionWrapper>

              <TranslationField
                size="small"
                placeholder={
                  selectedLocale
                    ? formatMessage(messages.translateQuestion)
                    : formatMessage(messages.pleaseSelectLanguage)
                }
                field="translation"
                dataTestId={`input-followup-${index}-translate`}
                form={translateCampaignPage[`followUpQuestion${index}${selectedLocale}`]}
                disabled={!selectedLocale}
              />
            </TranslationContentWrapper>
          ))}

        <StyledButtonGroup
          primary={{
            label: formatMessage(messages.save),
            onClick: () => {
              submit(editCampaignMutation);
            },
            disabled: !selectedLocale
          }}
          secondary={{
            label: formatMessage(messages.cancel),
            onClick: resetCampaign
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(CampaignTranslateView);
