import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import mutationNames from 'api/user/constants';
import notification from 'utils/notification-utils';

import {enableUserOptions, inviteUserOptions} from 'api/user/mutation-options';
import {EnableUser, InviteUser} from 'api/user/mutations';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuDivider,
  DropdownMenuItem
} from 'styles/styled-components/dropdown-styled-components';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

//messages
import messages from './messages';

@inject('store')
@graphql(EnableUser, enableUserOptions)
@graphql(InviteUser, inviteUserOptions)
@observer
class UsersListMenuComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {editUserPage} = store;
    const translations = mapValues(
      pick(messages, ['disableSuccess', 'disableFailure', 'enableSuccess', 'enableFailure']),
      message => formatMessage(message)
    );

    editUserPage.setTranslations(translations);
  }

  impersonate = () => {
    const {
      user,
      store: {
        auth: {impersonate}
      }
    } = this.props;
    impersonate(user.id);
  };

  resendInvite = async () => {
    const inviteUserMutation = this.props[mutationNames.INVITE_USER];
    const {
      user,
      intl: {formatMessage}
    } = this.props;

    try {
      await inviteUserMutation({email: user.email, phoneNumber: user.phoneNumber});
      notification.success(
        formatMessage(messages.inviteSentSuccess, {
          fullName: user.fullName
        })
      );

      trackEvent(EVENT_TYPES.RESENT_INVITE);
    } catch (e) {
      notification.error(
        formatMessage(messages.inviteSentFailure, {
          fullName: user.fullName
        })
      );
    }
  };

  disableUser = async () => {
    const {store, user} = this.props;
    const {archiveUserDialog} = store;

    archiveUserDialog.open(user.id);
  };

  enableUser = () => {
    const enableUserMutation = this.props[mutationNames.ENABLE_USER];
    const {store, user} = this.props;
    const {editUserPage: page} = store;
    page.enableUser(enableUserMutation, user.id);
  };

  deleteUser = () => {
    const {store, user} = this.props;
    const {deleteUserDialog} = store;

    deleteUserDialog.open(user.id);
  };

  render() {
    const {
      user,
      store: {
        auth: {user: authUser}
      }
    } = this.props;

    const isIPA = authUser.isIPA;
    const isPlatformAdmin = authUser.isPlatformAdmin;
    const canInvite = authUser.canManageUsers;
    const isActive = !user.isDisabled;
    const hasLoggedIn = !!user.lastLoginAt;
    const showFirstDelimter = isIPA;
    const canDisableUser = (isIPA || isPlatformAdmin) && isActive;
    const canEnableUser = (isIPA || isPlatformAdmin) && !isActive;
    const canDeleteUser = isIPA || isPlatformAdmin;
    const canResendInvite = canInvite && isActive && !hasLoggedIn;

    return (
      <DropdownMenu {...this.props} data-cy="users-list-menu">
        {isIPA && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.impersonate}>
              <FormattedMessage {...messages.impersonate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {canResendInvite && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.resendInvite}>
              <FormattedMessage {...messages.resendInvite} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {canDisableUser && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.disableUser}>
              <FormattedMessage {...messages.deactivate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {canEnableUser && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.enableUser}>
              <FormattedMessage {...messages.activate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showFirstDelimter && <DropdownMenuDivider />}
        {canDeleteUser && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.deleteUser}>
              <FormattedMessage {...messages.delete} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
      </DropdownMenu>
    );
  }
}

export default injectIntl(UsersListMenuComponent);
