import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  display: flex;
  height: 380px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  background: white;
  z-index: 2;
  position: absolute;
  width: 100%;
`;

export const TableOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
`;
