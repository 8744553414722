import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';
import {commonPalette} from 'shared/styles/palette';

const colors = {
  grey: commonPalette.txGrey
};

const NAME_ANGLE = 315;

export const Table = styled.table`
  border-collapse: collapse;
`;

export const StickyHeader = styled.thead`
  position: sticky;
  top: 100px;
  background: white;
  height: 120px;
  z-index: 10;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
`;

export const Th = styled.th`
  padding: 8px;
  text-align: ${props => (props.left ? 'left' : 'center')};
  font-weight: normal;
  font-size: 12px;
  color: ${colors.grey};
  cursor: ${props => (props.hover ? 'pointer' : '')};
  max-width: ${props => props.width};

  &:hover > div {
    text-decoration: ${props => (props.hover ? 'underline' : '')};
  }
`;

export const StickyTh = styled(Th)`
  transform: translate(0px, 35px);
`;

export const RotatedHeader = styled.div`
  transform-origin: top left;
  transform: rotate(${NAME_ANGLE}deg);
`;

export const Td = styled.td`
  height: 40px;
  text-align: ${props => props.align || 'center'};
  cursor: ${props => props.cursor};
  &:hover {
    color: ${props => (props.cursor ? commonPalette.btnGreen : '')};
  }
`;

export const Tr = styled.tr`
  text-align: center;

  &:not(:last-child) > td {
    border-bottom: 1px solid #ededed;
  }

  > th:first-child {
    padding-left: 12px;
  }

  // Skill name
  > td:first-child {
    padding-left: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
  }
`;

export const TrCollapsable = styled(Tr)`
  background: #f9f9f9;

  td {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
  }

  > td:first-child {
    cursor: pointer;
    font-weight: bold;
    border-left: 1px solid #ededed;
    text-align: left;
  }

  > td:last-child {
    border-right: 1px solid #ededed;
  }
`;

const TABLE_MARGIN_TOP = 50;
const LEGEND_MARGIN_BOTTOM = 24;
const TABLE_HEADER_HEIGHT_HALF = 36 / 2;

const NAME_MAX_WIDTH = (TABLE_HEADER_HEIGHT_HALF + LEGEND_MARGIN_BOTTOM + TABLE_MARGIN_TOP) / Math.cos(NAME_ANGLE);

export const Role = styled.div`
  color: ${colors.grey};
  white-space: nowrap;
  width: ${NAME_MAX_WIDTH}px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: -6px;
  @media (max-width: 730px) {
    font-size: 10px;
  }

  @media (max-width: 600px) {
    font-size: 9px;
  }
`;

export const Name = styled.div`
  font-weight: bold;
  white-space: nowrap;
  width: ${NAME_MAX_WIDTH}px;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 730px) {
    font-size: 10px;
  }
  @media (max-width: 600px) {
    font-size: 9px;
  }
`;

export const SkillsetDropdownIcon = styled(Icon).attrs(() => ({
  name: 'chevron-down',
  size: 16
}))`
  color: ${colors.grey};
  margin-bottom: 3px;
  margin-right: 5px;
  transform: rotate(${props => (props.open ? '0deg' : '180deg')});
  transition: transform 0.3s ease-out;
`;

export const StatusIndicator = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: ${props => {
    switch (props.variant) {
      case 'green':
        return '#009D9A';
      case 'red':
        return '#CE131C';
      case 'orange':
        return '#E87021';
      default:
        return '#EDEDED';
    }
  }};
  color: ${props => (props.variant ? '#fff' : '#000')};
  font-size: 12px;
`;

export const PrevIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 20
}))`
  color: ${colors.grey};
`;

export const NextIcon = styled(Icon).attrs(() => ({
  name: 'chevron-right',
  size: 20
}))`
  color: ${colors.grey};
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  float: right;
  color: ${colors.grey};

  span {
    margin-right: 5px;
  }

  button {
    border: none;
    background: none;
    cursor: pointer;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
`;

export const LevelDropdownIcon = styled(Icon).attrs(() => ({
  name: 'chevron-down',
  size: 16
}))`
  position: absolute;
  right: -20px;
  top: 4px;
  color: ${colors.grey};
`;

export const NotAssignedIcon = styled(Icon).attrs(() => ({
  name: 'line',
  size: 10
}))`
  transform: rotate(45deg);
  color: ${colors.grey};
`;
