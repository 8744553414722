import {action, observable, makeObservable} from 'mobx';

//helpers
import ChangePasswordForm, {
  createLocalisedForm as createChangePasswordLocalisedForm
} from 'stores/forms/change-password-form';
import notification from 'utils/notification-utils';

class ChangePasswordDialog {
  @observable loading = false;
  @observable opened = false;

  form = ChangePasswordForm;
  translations = {};

  @action
  reset = () => {
    this.form.reset();
    this.loading = false;
    this.opened = false;
  };

  @action setLoading = loading => (this.loading = loading);

  @action
  open = () => {
    this.opened = true;
  };

  @action
  submit = updatePasswordMutation => {
    const {oldPassword, newPassword} = this.form.values();

    this.setLoading(true);

    const updatePassword = {oldPassword, newPassword};

    updatePasswordMutation({updatePassword})
      .then(() => {
        this.reset();
        notification.success(this.translations.updateSuccess);
        window.location.href = `${window.location.origin}/admin`;
      })
      .catch(() => {
        this.loading = false;
        notification.error(this.translations.updateFailure);
      });
  };

  constructor() {
    makeObservable(this);
  }

  setTranslations(translations) {
    this.translations = translations;
    this.form = createChangePasswordLocalisedForm(translations);
  }
}

export default ChangePasswordDialog;
