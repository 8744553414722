import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

export const StyledUntranslatedIndicator = styled.div`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  background: ${commonPalette.red};
  border-radius: 50%;
  margin: 0 5px;
  text-align: center;
  ${({position}) => position && `position: ${position};`};
  ${({top}) => (top || top === 0) && `top: ${top}px;`};
  ${({right}) => (right || right === 0) && `right: ${right}px;`};
`;

export const StyledContent = styled.div`
  color: ${commonPalette.white};
  font-size: 11px;
  font-weight: bold;
  padding-bottom: 1px;
`;
