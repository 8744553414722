import React from 'react';
import {observable, action, makeObservable} from 'mobx';
import Raven from 'raven-js';

//helpers
import notification from 'utils/notification-utils';
import {getHoursFromDaysOption, getDaysOptionFromHours} from 'utils/auto-removal-interval-utils';

//components
import StepNoteIcons from 'components/InstructionalIcons';
import PlatformGeneralSettings from 'components/PlatformGeneralSettings';
import WebhookUrlSetting from 'components/WebhookUrlSetting';
import SkillLevels from 'components/SkillLevelsSettings';
import Token from 'components/Token';

//forms
import tagCreationRoleForm from 'stores/forms/tag-creation-role-form';
import guidesAutoRemovalForm from 'stores/forms/guides-auto-removal-form';

//models
import Steps from 'stores/models/steps';

const defaultAutoRemovalOption = '90_DAYS';

class platformSettings {
  @observable store = null;
  translations = {};
  @observable stepsStore;
  showCancelButton = false;
  showSaveButton = false;
  tagCreationRoleForm = tagCreationRoleForm;
  guidesAutoRemovalForm = guidesAutoRemovalForm;
  @observable autoRemoveGuides = false;
  @observable autoRemoveGuidesLoading = false;
  @observable isAdminLoginOptionUpdateLoading = false;

  @action
  setStore = store => {
    this.store = store;
  };

  @action
  setAutoRemoveGuides = autoRemoveGuides => (this.autoRemoveGuides = autoRemoveGuides);

  @action
  setAutoRemoveGuidesLoading = autoRemoveGuidesLoading => (this.autoRemoveGuidesLoading = autoRemoveGuidesLoading);
  @action
  setAdminLoginOptionUpdateLoading = isAdminLoginOptionUpdateLoading =>
    (this.isAdminLoginOptionUpdateLoading = isAdminLoginOptionUpdateLoading);

  @action
  onTimeIntervalChange = (setNativeAppAutoRemovalIntervalMutation, fromCheckbox) => {
    const {autoRemovalInterval} = this.guidesAutoRemovalForm.values();
    const value = fromCheckbox && this.autoRemoveGuides ? 0 : getHoursFromDaysOption(autoRemovalInterval);

    this.setAutoRemoveGuidesLoading(true);

    setNativeAppAutoRemovalIntervalMutation({value})
      .then(() => {
        if (fromCheckbox) {
          this.setAutoRemoveGuides(!this.autoRemoveGuides);
          this.setTimeIntervalFormValue(defaultAutoRemovalOption);
          this.autoRemoveGuides
            ? notification.success(this.translations.autoRemovalTurnedOffSuccess)
            : notification.success(this.translations.autoRemovalTurnedOnSuccess);
        }

        notification.success(this.translations.changedDaysSuccessMessage);
      })
      .catch(error => {
        if (fromCheckbox) {
          this.autoRemoveGuides
            ? notification.error(this.translations.autoRemovalTurnedOffError)
            : notification.error(this.translations.autoRemovalTurnedOnError);
        }

        notification.error(this.translations.changedDaysErrorMessage);
        Raven.captureException(error);
      })
      .finally(() => {
        this.setAutoRemoveGuidesLoading(false);
      });
  };

  @action
  onUpdateAdminLoginOptions = (
    updateAdminLoginOptionsMutation,
    loginOptions,
    isChecked,
    translatedNotifications,
    translationPrefix
  ) => {
    this.setAdminLoginOptionUpdateLoading(true);

    updateAdminLoginOptionsMutation({
      loginOptions
    })
      .then(() => {
        const notificationMessageId = isChecked
          ? `${translationPrefix}OptionTurnedOnSuccess`
          : `${translationPrefix}OptionTurnedOffSuccess`;

        notification.success(translatedNotifications[notificationMessageId]);
      })
      .catch(error => {
        const notificationMessageId = isChecked
          ? `${translationPrefix}OptionTurnedOnError`
          : `${translationPrefix}OptionTurnedOffError`;

        notification.error(translatedNotifications[notificationMessageId]);
        Raven.captureException(error);
      })
      .finally(() => {
        this.setAdminLoginOptionUpdateLoading(false);
      });
  };

  @action
  setExistingNativeAppAutoRemovalInterval = nativeAppAutoRemovalInterval => {
    const autoRemovalIntervalValue = nativeAppAutoRemovalInterval
      ? getDaysOptionFromHours(nativeAppAutoRemovalInterval)
      : defaultAutoRemovalOption;

    this.setTimeIntervalFormValue(autoRemovalIntervalValue);
    this.setAutoRemoveGuides(!!nativeAppAutoRemovalInterval);
  };

  setTimeIntervalFormValue = timeInterval => this.guidesAutoRemovalForm.$('autoRemovalInterval').sync(timeInterval);

  @action
  setSteps = editMode => {
    const {platform: platformStore = {}} = this.store || {};

    const basicStepsConfig = [
      {
        key: 'general',
        path: 'general',
        component: (
          <PlatformGeneralSettings
            tagCreationRoleForm={this.tagCreationRoleForm}
            guidesAutoRemovalForm={this.guidesAutoRemovalForm}
          />
        )
      },
      {
        key: 'stepNotes',
        path: 'step-notes',
        component: <StepNoteIcons />,
        checked: false
      }
    ];

    if (platformStore.hasApiAccess) {
      const apiAccessStepConfig = {
        key: 'apiAccess',
        path: 'api-access',
        component: <Token />
      };
      basicStepsConfig.push(apiAccessStepConfig);
    }

    const webhookStepConfig = {
      key: 'alerts',
      path: 'alerts',
      component: <WebhookUrlSetting />
    };

    const extraSteps =
      platformStore.hasChecklistEscalation && !platformStore.hasConfigurableAlertsRecipients ? [webhookStepConfig] : [];
    const skillsSteps = platformStore.hasSkillsEnabled
      ? [
          {
            key: 'skillLevels',
            path: 'skill-levels',
            component: <SkillLevels />,
            checked: false
          }
        ]
      : [];

    this.stepsStore = new Steps({
      steps: [...basicStepsConfig, ...skillsSteps, ...extraSteps],
      editMode
    });
  };

  @action
  setTagCreationRoleForm = platform => {
    const defaultRole = platform.tagCreationRoles || 'PLATFORM_ADMIN';
    this.tagCreationRoleForm.update({tagCreationRole: defaultRole.toString()});
  };

  setTranslations = translations => (this.translations = {...this.translations, ...translations});

  toggleStepNoteNames = mutation => {
    const mutationSuccess = () => {
      notification.success(this.translations.toggleStepNoteNameSuccess);
    };

    const mutationError = () => {
      notification.error(this.translations.toggleStepNoteNameFailure);
    };

    mutation().then(mutationSuccess).catch(mutationError);
  };

  updateTagCreationRole = (mutation, optionsForHook, role) => {
    const {tagCreationRole} = role;

    const mutationSuccess = () => {
      notification.success(this.translations.tagCreationRoleUpdateSuccess);
    };

    const mutationError = () => {
      notification.error(this.translations.tagCreationRoleUpdateFailure);
    };
    mutation(optionsForHook({role: tagCreationRole}))
      .then(mutationSuccess)
      .catch(mutationError);
  };

  updateWebhookUrl = async (mutation, url) => {
    try {
      const result = await mutation({url});

      if (result.data.updateWebhookUrl.result) {
        notification.success(this.translations.webhookUrlUpdateSuccess);
      } else {
        throw Error(result.data.updateWebhookUrl.error);
      }
    } catch (e) {
      Raven.captureException(e);
      notification.error(this.translations.webhookUrlUpdateFailure);
    }
  };

  constructor() {
    makeObservable(this);
  }
}

export default platformSettings;
