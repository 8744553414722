import BasePlatformStore from 'shared/stores/platform';
import {addLocaleData} from 'react-intl';
import {observable, action, computed, makeObservable} from 'mobx';
import axios from 'axios';
import {PlatformFlags} from 'api/platform/queries';
import store from 'stores/store';
import Raven from 'raven-js';

class PlatformStore extends BasePlatformStore {
  @observable canManageCampaigns;
  @observable hasMultiDomainEnabled;
  @observable canColorContent;
  @observable canUseBasicAnalytics;
  @observable canUseCustomFonts;
  @observable canUseLiveChat;
  @observable currentLanguage;
  @observable enforceAdminSSO;
  @observable subdomain;
  @observable guideApproval;
  @observable name;
  @observable canExportToPdf;
  @observable hasBeekeeperIntegrationEnabled;
  @observable canUseAutoTranslations;
  @observable hasNativeApp;
  @observable nativeAppAutoRemovalInterval;
  @observable hasConfigurableAlertsRecipients;
  @observable webhookUrl;

  automaticalLogoutMetadata = {
    intervalId: undefined,
    pingInterval: 1000 * 60 * 5
  };

  /* START TEMPORARY DUPLICATE BETWEEN MOBILE & CLIENT */
  @observable availableLanguages;

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this);
  }

  async automaticalLogoutListener() {
    const {auth} = store;
    const {user} = auth;

    if (!user) {
      this.disposeAutomaticallyLogoutListener();
      return;
    }

    await this.fetchPlatformFlags();

    const {shouldLogout} = this;

    if (shouldLogout) {
      auth.logout();
      this.disposeAutomaticallyLogoutListener();
    }
  }

  startAutomaticallyLogoutListener = () => {
    const {intervalId, pingInterval} = this.automaticalLogoutMetadata;
    const shouldAttachListeners = intervalId === undefined;

    if (!shouldAttachListeners) {
      return;
    }

    this.automaticalLogoutMetadata.intervalId = window.setInterval(
      () => this.automaticalLogoutListener(),
      pingInterval
    );
  };

  @action
  setAssetsFolder = assetsFolder => {
    this.assetsFolder = assetsFolder;
  };

  @action
  setIconsShowHintLabel = showStepNoteIconLabel => {
    this.showStepNoteIconLabel = showStepNoteIconLabel;
  };

  @action
  setSubdomain = subdomain => {
    this.subdomain = subdomain;
  };

  @action
  setAvailablelanguages = val => {
    this.availableLanguages = val;
    this.initIntlProvider();
  };

  @action
  initIntlProvider = () => {
    const localeData = [];
    const localeMessages = {};

    this.availableLanguages.forEach(language => {
      const shortLocale = this.getLocalMapping(language.locale);
      try {
        const messages = require(`../../translations/${shortLocale}.json`);
        localeMessages[shortLocale] = this.flattenMessages(messages);
        localeData.push(...require(`react-intl/locale-data/${shortLocale}`));
      } catch (err) {
        localeMessages[shortLocale] = {};
      }
    });

    addLocaleData(localeData);
    this.localeMessages = localeMessages;
  };

  @action
  setCurrentLanguage = val => {
    this.currentLanguage = val;
    this.setLocalLanguage(val);
  };

  @computed
  get selectedLocale() {
    return this.availableLanguages.find(lang => lang.locale === this.currentLanguage);
  }

  @computed
  get activeLocale() {
    return this.currentLanguage ? this.currentLanguage.substring(0, 2) : this.browserLocale.substring(0, 2);
  }

  @computed
  get multipleLocales() {
    return this.availableLanguages && this.availableLanguages.length > 1;
  }

  /* END TEMPORARY DUPLICATE BETWEEN MOBILE & CLIENT */

  fetchPlatformFlags = () => {
    // FIXME: Using axios to perform graphql queries completely misses error handling
    return axios
      .post('/graphql', {
        query: PlatformFlags.loc.source.body
      })
      .then(({data: {data}}) => {
        const {platformForCms} = data;
        const {
          isContentProtected,
          canManageCampaigns,
          hasMultiDomainEnabled,
          canColorContent,
          canUseMediaInStepHints,
          canUseBasicAnalytics,
          canUseCustomFonts,
          canUseLiveChat,
          sso,
          enforceAdminSSO,
          guideApproval,
          name,
          canExportToPdf,
          hasFeedbackEnabled,
          hasBeekeeperIntegrationEnabled,
          hasChecklistsEnabled,
          canUseXmReality,
          canUseAutoTranslations,
          hasNativeApp,
          nativeAppAutoRemovalInterval,
          hasChecklistEscalation,
          hasAiStepContentSuggestions,
          hasSkillsEnabled,
          hasConfigurableAlertsRecipients,
          hasApiAccess,
          hasChecklistsSignatureEnabled,
          hasDispatchEnabled,
          hasDispatchDocumentsSyncEnabled
        } = platformForCms;

        this.setPlatformFlags({
          isContentProtected,
          canManageCampaigns,
          hasMultiDomainEnabled,
          canColorContent,
          canUseMediaInStepHints,
          canUseBasicAnalytics,
          canUseCustomFonts,
          canUseLiveChat,
          sso,
          enforceAdminSSO,
          guideApproval,
          name,
          canExportToPdf,
          hasFeedbackEnabled,
          hasBeekeeperIntegrationEnabled,
          hasChecklistsEnabled,
          canUseXmReality,
          canUseAutoTranslations,
          hasNativeApp,
          nativeAppAutoRemovalInterval,
          hasChecklistEscalation,
          hasAiStepContentSuggestions,
          hasSkillsEnabled,
          hasConfigurableAlertsRecipients,
          hasApiAccess,
          hasChecklistsSignatureEnabled,
          hasDispatchEnabled,
          hasDispatchDocumentsSyncEnabled
        });
        this.setLoaded(true);
      })
      .catch(error => {
        Raven.captureException(error);
      });
  };

  @computed
  get shouldLogout() {
    const {enforceAdminSSO} = this;
    const {user} = store.auth;

    if (enforceAdminSSO === undefined || user === undefined) {
      return false;
    }

    return !user.isSsoLogin && enforceAdminSSO;
  }

  // Flatten nested messages object structure, base on:
  // https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#flatten-messages-object
  flattenMessages = (nestedMessages, prefix) =>
    Object.keys(nestedMessages).reduce((messages, key) => {
      const value = nestedMessages[key];
      const prefixedKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'string') {
        messages[prefixedKey] = value;
      } else {
        Object.assign(messages, this.flattenMessages(value, prefixedKey));
      }

      return messages;
    }, {});

  disposeAutomaticallyLogoutListener() {
    const {intervalId} = this.automaticalLogoutMetadata;

    if (typeof intervalId === 'number') {
      clearInterval(intervalId);
      this.automaticalLogoutMetadata.intervalId = undefined;
    }
  }

  setWebhookUrl = webhookUrl => {
    this.webhookUrl = webhookUrl;
  };
}

export default PlatformStore;
