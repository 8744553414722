import styled from 'styled-components';
import {Button as $Button} from 'styles/styled-components/shared-components';

export const Card = styled.div`
  background: #ffffff;
  padding: 16px 20px;
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.56);
`;
export const CampaignQuestion = styled.div``;
export const CampaignQuestionLabel = styled.div`
  opacity: 0.6;
`;
export const CampaignTitle = styled.h1`
  margin-bottom: 1rem;
`;
export const CardTitle = styled.div`
  font-size: 16px;
`;
export const CardValue = styled.div`
  font-size: 72px;
  font-weight: 600;
  color: ${p => (p.color ? p.color : '#000000')};
`;

export const GridRow = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 24px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      flex: 1;
    }
  }
`;

export const StackSpace = styled.div`
  height: 24px;
`;

export const Button = styled($Button)``;
