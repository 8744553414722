export default {
  CREATE_TEAM: 'createTeamMutation',
  EDIT_TEAM: 'editTeamMutation',
  CREATE_TEAM_USERS: 'createTeamUsersMutation',
  REMOVE_TEAM_USER: 'removeTeamMemberMutation',
  UPDATE_TEAM_USER: 'updateTeamMemberRoleWithResult',
  ARCHIVE_TEAM: 'archiveTeamMutation',
  ADD_DOMAINS: 'addDomainsToTeamMutation',
  REMOVE_TEAM_FROM_DOMAIN: 'removeTeamFromDomainMutation',
  SAVE_APPROVAL_SETTINGS: 'saveGuideApprovalSettingsMutation'
};
