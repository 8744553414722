import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

//components
import {Title as $Title} from 'shared/styles/styled-components/field-styled-components';

export const MediaActions = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const Action = styled.div`
  cursor: pointer;
  color: #428aff;
`;

export const Title = styled($Title)`
  margin-bottom: 1rem;
`;

export const Note = styled.div`
  color: ${commonPalette.txtGrey};
  letter-spacing: 0.33px;
  text-transform: uppercase;
  margin-left: 8px;
  display: inline;
  font-size: 10px;
`;
