import React from 'react';
import {observer} from 'mobx-react';
import {injectIntl, InjectedIntl} from 'react-intl';
import {useStore} from 'hooks/useStore';
import views from 'config/views';

import messages from './messages';

import {StyledCard, FlexColumn, PrimaryText, SecondaryText, LevelButton, ChevronIcon, FlexRow} from './styles';
import {SkillProfileType} from 'components/SkillProfilesDialog/types';

type SkillProfileCardType = {
  isBinary?: boolean;
  intl: InjectedIntl;
  skillProfile: SkillProfileType;
};
const getRolesMessage = (skillRoles: {id: string; name: string}[], formatMessage: any) => {
  if (skillRoles?.length === 0) {
    return formatMessage(messages.assignedToTeam);
  } else {
    const roleNames = skillRoles?.map(role => role.name);
    const rolesString = `${roleNames?.join(', ')}`;
    return formatMessage(messages.assginedToRoles, {roles: rolesString});
  }
};

const SkillProfileCard = ({skillProfile, intl, isBinary}: SkillProfileCardType) => {
  const {formatMessage} = intl;
  const store = useStore();
  const {skillProfilesDialog} = store;
  const {close} = skillProfilesDialog;

  return (
    <StyledCard
      onClick={() => {
        store.router.goTo(views.skillProfilesManage, {id: skillProfile.id}, {teamId: store.router.params.teamId});
        close();
      }}
    >
      <FlexColumn>
        <PrimaryText>{skillProfile.name}</PrimaryText>
        <SecondaryText>{getRolesMessage(skillProfile.skillRoles, formatMessage)}</SecondaryText>
      </FlexColumn>
      <FlexRow>
        {!isBinary && (
          <LevelButton>{skillProfile.requiredLevel.position + ' - ' + skillProfile.requiredLevel.name}</LevelButton>
        )}
        <ChevronIcon />
      </FlexRow>
    </StyledCard>
  );
};
export default injectIntl(observer(SkillProfileCard));
