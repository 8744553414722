import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

export const List = styled.div`
  color: ${commonPalette.txBlack};
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
