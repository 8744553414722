import styled from 'styled-components';
import $DataTable from 'ui-components/Layout/DataTable';
import $CardToolbar from 'components/CardToolbar';

export const DataTable = styled($DataTable)`
  margin-top: 15px;
  width: 100%;
`;

export const StyledMenu = styled($CardToolbar)`
  position: relative;
  top: 12px;
  left: 16px;
`;

export const LoaderContainer = styled.div`
  margin-top: 200px;
`;
