import {action, observable, makeObservable} from 'mobx';
import Raven from 'raven-js';

import notification from 'utils/notification-utils';

class DeleteUserDialog {
  @observable opened = false;
  @observable userId;
  @observable translations = {};
  @observable teamIds = [];

  @action
  setUserId = userId => (this.userId = userId);

  @action
  setOpened = opened => (this.opened = opened);

  @action
  setTranslations = translations => (this.translations = translations);

  @action
  setTeamIds = teamIds => (this.teamIds = teamIds);

  @action
  open = userId => {
    this.setUserId(userId);
    this.setOpened(true);
  };

  @action
  close = () => {
    this.setUserId(null);
    this.setOpened(false);
    this.setTeamIds([]);
  };

  @action
  deleteUser = async deleteUserMutation => {
    try {
      const id = this.userId;
      const {
        auth: {user, logout}
      } = this.store;

      const result = await deleteUserMutation({id});

      if (!result.data.deleteUser.result) {
        throw new Error(`An error occured while deleting the user: ${id}`);
      }

      notification.success(this.translations.userDeletedSuccess);

      if (id === user.id) {
        logout();
      }
    } catch (error) {
      notification.error(this.translations.userDeletedError);
      Raven.captureException(error);
    } finally {
      this.close();
    }
  };

  setStore = store => {
    this.store = store;
  };

  constructor() {
    makeObservable(this);
  }
}

export default DeleteUserDialog;
