export const recommendContentMutationOptions = {
  props: ({mutate}) => ({
    recommendContentMutation: variables => {
      const {content, locale} = variables;

      return mutate({
        variables: {
          content,
          locale
        }
      });
    }
  })
};
