import React from 'react';
import PropTypes from 'prop-types';

import Select from 'ui-components/Select';
import Button from 'ui-components/Button';

import {
  TeamMembershipCardLabelContainer,
  TeamMembershipCardContainer,
  SelectWrapper,
  StyledOrderingButtons
} from './styles';

const usersOptionsToSelectOptions = usersOptions =>
  usersOptions.map(({userId, displayName}) => ({id: userId, name: displayName}));

const TeamMembershipCard = ({
  label,
  emptyOptionPlaceHolder,
  usersOptions,
  selectedUserId,
  allowClear,
  disabled,
  showCloseButton,
  onChange,
  onClose,
  onMoveUp,
  onMoveDown,
  cardPosition,
  cardsLength,
  selectApproverOrder
}) => (
  <TeamMembershipCardContainer role="group">
    <SelectWrapper>
      <Select
        label={
          <TeamMembershipCardLabelContainer disabled={disabled}>{`${label} ${
            selectApproverOrder ? cardPosition + 1 : ''
          }`}</TeamMembershipCardLabelContainer>
        }
        options={usersOptionsToSelectOptions(usersOptions)}
        selectedValue={selectedUserId}
        allowClear={allowClear}
        placeholder={emptyOptionPlaceHolder}
        onChange={onChange}
        disabled={disabled}
      />
    </SelectWrapper>
    {selectApproverOrder && (
      <StyledOrderingButtons marginRight={!showCloseButton}>
        {cardPosition > 0 && <Button underlined iconId="arrow-up" role="button" aria-label="up" onClick={onMoveUp} />}
        {cardPosition < cardsLength - 1 && (
          <Button underlined iconId="arrow-down" role="button" aria-label="down" onClick={onMoveDown} />
        )}
      </StyledOrderingButtons>
    )}
    {!disabled && showCloseButton && (
      <Button underlined iconId="close" role="button" aria-label="close" onClick={onClose} />
    )}
  </TeamMembershipCardContainer>
);

TeamMembershipCard.propTypes = {
  label: PropTypes.string,
  emptyOptionPlaceHolder: PropTypes.string,
  usersOptions: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      userId: PropTypes.string.isRequired
    })
  ),
  selectedUserId: PropTypes.string,
  allowClear: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  cardPosition: PropTypes.number,
  cardsLength: PropTypes.number,
  selectApproverOrder: PropTypes.bool
};

TeamMembershipCard.defaultProps = {
  emptyOptionPlaceHolder: '-- select --',
  usersOptions: [],
  allowClear: false,
  showCloseButton: true,
  disabled: false,
  onChange: () => {},
  onClose: () => {},
  onMoveUp: () => {},
  onMoveDown: () => {},
  selectApproverOrder: false
};

export default TeamMembershipCard;
