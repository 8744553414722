import React, {Component} from 'react';

//components
import ProfileSettings from 'components/ProfileSettings';
import FormContainer from 'ui-components/Layout/FormContainer';

class EditProfileViewComponent extends Component {
  render() {
    return (
      <FormContainer>
        <ProfileSettings />
      </FormContainer>
    );
  }
}

export default EditProfileViewComponent;
