import {defineMessages} from 'react-intl';

const messages = defineMessages({
  instructionsLengthPlural: {
    id: 'GuideExpanded.instructionsLengthPlural',
    defaultMessage: 'on {count} {count, plural, one {instruction} other {instructions}}'
  },
  instructionHover: {
    id: 'GuideExpanded.instructionHover',
    defaultMessage: 'Open instruction'
  },
  guideHover: {
    id: 'GuideExpanded.guideHover',
    defaultMessage: 'Open guide'
  }
});

export default messages;
