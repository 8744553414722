import {commonPalette} from 'shared/styles/palette';

export const zIndex = {
  menuBarSticky: 997,
  versionsDrawer: 998
};

export const borderRadius = {
  default: 4
};

export const headerSize = 100;

export const flexGrid = (colCount, gridGap, mediaQuery, marginBottom) => {
  return `
    @media ${mediaQuery} {
      display: flex;
      flex-wrap: wrap;
      margin-left: ${(-1 * gridGap) / 2}rem;
      margin-right: ${(-1 * gridGap) / 2}rem;
      margin-bottom: ${marginBottom}rem;
       & > * {
        width: ${100 / colCount}%;
        max-width: ${100 / colCount}%;
        padding: ${gridGap / 2}rem;
        box-sizing: border-box;
      }
    }
  `;
};

export const flexGridMinMax = (colCount, gridGap, mediaQuery, marginBottom, maxWidth) => {
  return `
    @media ${mediaQuery} {
      display: flex;
      flex-wrap: wrap;
      margin-left: ${(-1 * gridGap) / 2}rem;
      margin-right: ${(-1 * gridGap) / 2}rem;
      margin-bottom: ${marginBottom}rem;
       & > * {
        padding: ${gridGap / 2}rem;
        box-sizing: border-box;
        max-width: ${maxWidth};
        width: 100%;
        flex-basis: auto;
        flex-grow: 1;
      }
    }
  `;
};

export const customScrollBar = `
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background: ${commonPalette.borderGrey};
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${commonPalette.txGrey};
  }
`;

export const svgElementsFillCurrentColor = `
circle,
ellipse,
line,
path,
polygon,
polyline,
rect {
  fill: currentColor !important;
}`;
