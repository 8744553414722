import {get} from 'lodash';

export const getInstructionForEditOptions = {
  options: ({store: {router}}) => {
    const {instructionId} = router.params;
    const versionId = get(router, 'queryParams.v', null);
    const variables = {
      id: instructionId
    };
    if (versionId) {
      variables.versionId = versionId;
    }
    return {
      fetchPolicy: 'network-only',
      variables
    };
  }
};
