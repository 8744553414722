export const DND_TYPE = 'INSTRUCTION';

export const source = {
  beginDrag: props => {
    const {instruction, position} = props;

    // sourcePosition/Topic keep mutating while we drag the
    // instruction between topics. There's initialSource if
    // we need to know from where the drag started
    return {
      initialSourceTopicId: instruction.topicId,
      initialSourcePosition: position,
      preview: instruction,
      sourceId: instruction.id,
      sourcePosition: position,
      sourceTopicId: instruction.topicId
    };
  },
  endDrag: (props, monitor) => {
    const {repositionTopicsAndInstructionsMutation, store} = props;
    const {dragDropItems: page, newTopicPage} = store;
    const item = monitor.getDropResult();

    // Check if it was a drop on new topic
    if (item && item.createTopic) {
      newTopicPage.setDroppedItem(item);
      return newTopicPage.setOpened(true);
    }

    page.submit(repositionTopicsAndInstructionsMutation);
  },
  canDrag: props => !props.store.dragDropItems.submitting,
  isDragging: (props, monitor) => {
    // As the original component may be unmounted during dragging (if moved
    // from one topic to another) a custom isDragging method has to be defined.
    //
    // https://react-dnd.github.io/react-dnd/docs-drag-source.html#specification-methods

    // We're checking for the instruction to be provided so it won't break
    // because of the newly created instructions

    return props.instruction && props.instruction.id === monitor.getItem().sourceId;
  }
};

export const target = {
  hover: (props, monitor) => {
    const {instruction, position, store} = props;
    const {id, topicId} = instruction;
    const {dragDropItems: page} = store;

    const item = monitor.getItem();
    const {sourceId, sourcePosition, sourceTopicId} = item;

    if (sourceId !== id) {
      page.moveInstruction(sourceTopicId, sourcePosition, topicId, position);

      // Note: we're mutating the monitor item here! Generally it's better
      // to avoid mutations, but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.sourcePosition = position;
      item.sourceTopicId = topicId;
    }
  }
};
