import styled from 'styled-components';

//components
import $StepCard from '../StepCard';

//helpers
import {stepCardMetrics} from 'styles/metrics';

export const PositionedBox = styled.div`
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 9999;
`;

export const StyledStepCard = styled($StepCard)`
  transform: translate(${stepCardMetrics.gutterX}px, 0);
`;
