import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {has, isEmpty, get} from 'lodash';

import {FormattedMessage} from 'components/FormattedComponents';

//helpers
import notification from 'utils/notification-utils';
import {sanitizeNestedFields} from 'utils/validation-utils';

import {
  StyledDialog,
  UploadIconDropzone,
  DropboxCaption,
  IconTranslationText,
  UploadIconActiveStyle,
  StyledDivider,
  IconTranslationForm,
  IconTranslationTextWrapper,
  IconTranslationInputWrapper,
  IconTranslationValidationWrapper,
  IconTranslationInput,
  IconTranslationWrapper,
  IconTranslationTextAsterisk,
  IconTranslationFormWrapper,
  UploadSvgIconPreview,
  Text,
  TextGrey,
  TextUnderlined,
  StyledUploadIcon
} from './styles.js';

//helpers
import {readFileAsText} from 'shared/utils/file-utils';
import {stepNoteResourceAllowed, svgIconFileLimit} from 'shared/utils/media-utils';

//messages
import messages from './messages';
import {graphql} from '@apollo/client/react/hoc/graphql';

//mutations
import {UploadIcon, EditIcon} from 'api/platform/mutations';
import {uploadIconOptions, editIconOptions} from 'api/platform/mutation-options';

@inject('store')
@graphql(UploadIcon, uploadIconOptions)
@graphql(EditIcon, editIconOptions)
@observer
class InstructionalIconDialog extends Component {
  errorUploadingFile = ({type, data}) => {
    const {formatMessage} = this.props.intl;
    const message = formatMessage(messages[type], data);
    notification.error(message);
  };

  onDrop = async ([file]) => {
    const {
      store: {instructionalIconDialog: dialog}
    } = this.props;
    const {allowVideos, acceptedTypes, setIcon} = dialog;

    if (file) {
      const {error} = stepNoteResourceAllowed(file, allowVideos, acceptedTypes);

      if (error) {
        return this.errorUploadingFile(error);
      }

      try {
        const svgContent = await readFileAsText(file).promise;
        setIcon(svgContent);
      } catch (e) {
        return this.errorUploadingFile({
          type: 'corruptContent'
        });
      }
    }
  };

  onDropRejected = ([file]) => {
    const {
      store: {instructionalIconDialog: dialog}
    } = this.props;
    const {allowVideos, acceptedTypes} = dialog;

    if (file) {
      const {error} = stepNoteResourceAllowed(file, allowVideos, acceptedTypes);
      this.errorUploadingFile(error);
    }
  };

  submitForm = async () => {
    const {
      uploadIconMutation,
      editIconMutation,
      store: {instructionalIconDialog}
    } = this.props;

    const {submit, icon, form} = instructionalIconDialog;

    const isEditMutation = has(icon, 'id');
    const mutation = isEditMutation ? editIconMutation : uploadIconMutation;

    const {translations: labelTranslations, icon: svg} = form.values();

    await submit(mutation, isEditMutation, {
      icon: {labelTranslations: sanitizeNestedFields(labelTranslations, 'translation'), svg},
      ...(isEditMutation && {iconId: get(icon, 'id')})
    });
  };

  render() {
    const {
      styles = {},
      className,
      store,
      intl: {formatMessage}
    } = this.props;
    const {instructionalIconDialog} = store;
    const {opened, reset, loading, acceptedTypes, form, getLanguageLabel, icon, isValid} = instructionalIconDialog;

    const translations = form.$('translations') || [];
    const hasIcon = !isEmpty(icon);

    const modalTitle = hasIcon ? formatMessage(messages.editStepNoteIcon) : formatMessage(messages.addNewStepNoteIcon);

    return (
      <StyledDialog
        actions={[
          {
            label: loading ? '' : 'Save',
            design: 'text',
            dataCy: 'save-icon',
            onClick: this.submitForm,
            disabled: !isValid || loading,
            loading: loading
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={modalTitle}
      >
        <Text size={'small'} secondary>
          {formatMessage(messages.uploadIcon)}
        </Text>
        <UploadIconDropzone
          {...loading}
          disableClick={loading}
          styles={styles.UploadIconDropzone}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          accept={acceptedTypes.join(',')}
          multiple={false}
          activeStyle={UploadIconActiveStyle}
          loadingImage={loading}
          className={className}
          data-cy="drag-and-drop-icon"
          maxSize={svgIconFileLimit}
        >
          {hasIcon ? (
            <UploadSvgIconPreview dangerouslySetInnerHTML={{__html: icon.svg}}></UploadSvgIconPreview>
          ) : (
            <div>
              <StyledUploadIcon name="upload-cloud" size={38} />
              <Text>
                <FormattedMessage {...messages.dropboxContentSvgOnly} />
              </Text>
              <TextGrey>
                <FormattedMessage {...messages.dropboxContentCaption} />
              </TextGrey>
              <TextUnderlined>
                <FormattedMessage {...messages.dropboxContentClickHere} />
              </TextUnderlined>
            </div>
          )}
        </UploadIconDropzone>

        <DropboxCaption size={'small'} secondary>
          <FormattedMessage {...messages.dropboxCaptionOnlySvg} />
          <br />
          <FormattedMessage {...messages.dropboxCaptionThemeColors} />
        </DropboxCaption>

        <Text size={'small'} secondary>
          <FormattedMessage {...messages.defineNameCaption} />
        </Text>

        <IconTranslationFormWrapper>
          <IconTranslationWrapper>
            <IconTranslationTextWrapper>
              <IconTranslationText>
                <FormattedMessage {...messages.headerLanguages} />
              </IconTranslationText>
            </IconTranslationTextWrapper>

            <IconTranslationInputWrapper>
              <IconTranslationText>
                <FormattedMessage {...messages.headerTranslations} />
              </IconTranslationText>
            </IconTranslationInputWrapper>

            <IconTranslationValidationWrapper></IconTranslationValidationWrapper>
          </IconTranslationWrapper>

          <StyledDivider />

          <IconTranslationForm>
            {translations.map((i, index) => {
              return (
                <IconTranslationWrapper key={index}>
                  <IconTranslationTextWrapper>
                    <IconTranslationText>
                      {getLanguageLabel(i.$('locale').get('value'))}
                      {index === 0 && <IconTranslationTextAsterisk> *</IconTranslationTextAsterisk>}
                    </IconTranslationText>
                  </IconTranslationTextWrapper>

                  <IconTranslationInputWrapper>
                    <IconTranslationInput
                      autofocus={false}
                      form={i}
                      field={'translation'}
                      onChange={e => {
                        const val = e.target.value || '';
                        i.$('translation').set('value', val.charAt(0).toUpperCase() + val.slice(1));
                      }}
                    />
                  </IconTranslationInputWrapper>

                  <IconTranslationValidationWrapper></IconTranslationValidationWrapper>
                </IconTranslationWrapper>
              );
            })}
          </IconTranslationForm>
        </IconTranslationFormWrapper>
      </StyledDialog>
    );
  }
}

export default injectIntl(InstructionalIconDialog);
