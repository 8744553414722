import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import views from 'config/views';
import enhanceWithClickOutside from 'react-click-outside';
import {SMART_SKILLS_COM_LINK} from '../../config/constants';

//lodash
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

//components
import MenuItem from './MenuItem';
import Submenu from './Submenu';

//styles
import {
  MenuWrapper,
  MenuHeader,
  MenuIcon,
  StyledLogoLink,
  MenuItems,
  Logo,
  StyledHelpCenterLink,
  StyledVersionLink,
  StyledPlatformLanguageButton,
  PlatformLanguageIcon,
  MenuWrapperBottom,
  MenuWrapperTop,
  SkillsDiv,
  SmartSkillsIcon,
  FlexRow,
  TextDiv,
  StyledLink,
  FlexColumn,
  FirstDiv,
  SecondDiv,
  ExternalLinkIcon,
  IconButton,
  Spacer
} from './styles';

//messages
import messages from './messages';

//consts
import {INFO_CENTER_URL, RELEASE_NOTES_URL} from 'config/constants';

@inject('store')
@enhanceWithClickOutside
@observer
class NavMenuComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      store: {app}
    } = this.props;
    this.setTranslations(this.props);
    app.startListeningToRouteChange();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTranslations(nextProps);
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.stopListeningToRouteChange();
  }

  handleClickOutside = () => {
    const {
      store: {app}
    } = this.props;
    const {isNavMenuOpened, toggleNavMenu} = app;

    if (isNavMenuOpened) toggleNavMenu();
  };

  setTranslations(props) {
    const {
      intl: {formatMessage},
      store: {app}
    } = props;

    const translations = mapValues(
      pick(messages, [
        'guides',
        'userManagement',
        'analytics',
        'settings',
        'guideOverview',
        'allGuides',
        'tags',
        'checklistResults',
        'signoffs',
        'signoffResults',
        'usage',
        'top10',
        'allInstructions',
        'teams',
        'users',
        'jobTitles',
        'themes',
        'workspaces',
        'platform',
        'adoption',
        'helpCenter',
        'version',
        'thoughtspot',
        'platformAdoption',
        'workspaceAdoption',
        'skillsMatrix',
        'smartSkills',
        'skills',
        'skillProfiles',
        'learningActivities',
        'learningHub',
        'checklistAlerts'
      ]),
      message => formatMessage(message)
    );

    app.setTranslations(translations);
  }

  render() {
    const {store, logoUrl, intl} = this.props;
    const {app, platform} = store;
    const {
      menuItems,
      isNavMenuOpened,
      selectedNavMenuItem,
      selectedNavSubMenuItem,
      selectedSubmenuItemIndex,
      translations,
      openPlatformLanguageDialog
    } = app;
    const {formatMessage} = intl;
    const {hasSkillsEnabled} = platform;
    const clientVersion = process.env.VERSION;

    return (
      <MenuWrapper isOpen={isNavMenuOpened}>
        <MenuWrapperTop>
          <MenuHeader>
            <MenuIcon data-cy="close" name="close" size={25} onClick={() => app.toggleNavMenu()} />
            {logoUrl && (
              <StyledLogoLink store={store} route={views.guides}>
                <Logo src={logoUrl} />
              </StyledLogoLink>
            )}
          </MenuHeader>
          <MenuItems>
            {map(menuItems, item => {
              const isItemSelected = selectedNavMenuItem && selectedNavMenuItem.id === item.id;
              return (
                <div key={item.id}>
                  <MenuItem selected={isItemSelected} item={item} data-cy={item.id} />
                  {isItemSelected && item.sections && (
                    <Submenu
                      sections={item.sections}
                      selectedSubMenuItem={selectedNavSubMenuItem}
                      selectedSubmenuItemIndex={selectedSubmenuItemIndex}
                    />
                  )}
                </div>
              );
            })}
          </MenuItems>
        </MenuWrapperTop>

        <MenuWrapperBottom>
          {!hasSkillsEnabled && (
            <SkillsDiv>
              <StyledLink target="blank" href={SMART_SKILLS_COM_LINK}>
                <FlexRow>
                  <IconButton>
                    <SmartSkillsIcon name={'smart-skills-navigation'} size={18} />
                  </IconButton>
                  <FlexColumn>
                    <FirstDiv>{formatMessage(messages.introducing)}</FirstDiv>
                    <SecondDiv>{formatMessage(messages.smartSkills)}</SecondDiv>
                  </FlexColumn>
                  <Spacer />
                  <ExternalLinkIcon name={'open-in-new'} size={15} />
                </FlexRow>
                <TextDiv>{formatMessage(messages.skillsDevelopment)}</TextDiv>
              </StyledLink>
            </SkillsDiv>
          )}
          <StyledPlatformLanguageButton data-cy={'language'} onClick={() => openPlatformLanguageDialog()}>
            <PlatformLanguageIcon name={'language'} size={18} />
            {formatMessage(messages.platformLanguage)}
          </StyledPlatformLanguageButton>
          <StyledHelpCenterLink>
            <MenuItem item={{href: INFO_CENTER_URL, icon: 'help-icon', caption: translations.helpCenter}} />
          </StyledHelpCenterLink>
          <StyledVersionLink href={RELEASE_NOTES_URL} target="_blank">
            {translations.version} {clientVersion}
          </StyledVersionLink>
        </MenuWrapperBottom>
      </MenuWrapper>
    );
  }
}

export default injectIntl(NavMenuComponent);
