import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Container from 'ui-components/Layout/Container';
import LoadingMessage from 'components/LoadingMessage';
import ViewTitle from 'ui-components/Layout/ViewTitle';

import {ChecklistAnswer} from 'api/checklists/queries';
import {checklistAnswerOptions} from 'api/checklists/query-options';

import views from 'config/views';

import SessionDetailsSection from './SessionDetailsSection';
import SessionResultsSection from './SessionResultsSection';

import messages from './messages';
import {BackToChecklistsWrapper, BackIcon, WrapperText} from './styles';

@inject('store')
@graphql(ChecklistAnswer, checklistAnswerOptions)
@observer
class ChecklistSessionDetails extends Component {
  UNSAFE_componentWillMount = () => {
    const {
      store: {
        platform: {hasChecklistsEnabled},
        router: {goTo}
      }
    } = this.props;

    if (!hasChecklistsEnabled) {
      goTo(views.guides);
    }
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {
      data,
      store: {
        checklistsPage: {setCurrentChecklistSessionDetails, setChecklistType}
      }
    } = nextProps;

    if (data.checklistAnswer) {
      setCurrentChecklistSessionDetails(data.checklistAnswer);
      setChecklistType(data.checklistAnswer?.instruction?.checklistTemplate?.type);
    }
  };

  render() {
    const {store} = this.props;
    const {
      data: {loading, error},
      store: {
        checklistsPage: {currentChecklistSessionDetails},
        router: {goTo}
      },
      intl: {formatMessage}
    } = this.props;

    const backToChecklists = (
      <BackToChecklistsWrapper onClick={() => goTo(views.checklists, {tab: 'results'})} data-cy="back-to-checklists">
        <BackIcon />
        <WrapperText>{formatMessage(messages.backToChecklists)}</WrapperText>
      </BackToChecklistsWrapper>
    );

    if (loading) {
      return <LoadingMessage>{formatMessage(messages.loading)}</LoadingMessage>;
    }

    if (error) {
      return (
        <Container>
          {backToChecklists}
          {formatMessage(messages.errorChecklists)}
        </Container>
      );
    }

    const {
      instruction: {title}
    } = currentChecklistSessionDetails;

    return (
      <Container>
        {backToChecklists}
        <ViewTitle title={title} />
        <SessionDetailsSection />
        <SessionResultsSection />
      </Container>
    );
  }
}

export default injectIntl(ChecklistSessionDetails);
