import {defineMessages} from 'react-intl';

const messages = defineMessages({
  create: {
    id: 'common.actions.create',
    defaultMessage: 'Create'
  },
  settings: {
    id: 'common.actions.settings',
    defaultMessage: 'Success'
  },
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  merge: {
    id: 'common.actions.merge',
    defaultMessage: 'Merge'
  },
  share: {
    id: 'common.actions.share',
    defaultMessage: 'Share'
  },
  reassign: {
    id: 'common.actions.reassign',
    defaultMessage: 'Reassign'
  },
  activity: {
    id: 'EditGuide.activity',
    defaultMessage: 'View Activity'
  },
  versionHistory: {
    id: 'EditGuide.versionHistory',
    defaultMessage: 'Version history'
  },
  feedback: {
    id: 'EditGuide.feedback',
    defaultMessage: 'View Feedback'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  duplicate: {
    id: 'common.actions.duplicate',
    defaultMessage: 'Duplicate'
  },
  remove: {
    id: 'common.actions.remove',
    defaultMessage: 'Remove'
  },
  hide: {
    id: 'common.actions.hide',
    defaultMessage: 'Hide'
  },
  show: {
    id: 'common.actions.show',
    defaultMessage: 'Show'
  },
  newStep: {
    id: 'common.actions.newStep',
    defaultMessage: 'New Step'
  },
  newInstruction: {
    id: 'common.actions.newInstruction',
    defaultMessage: 'New Instruction'
  },
  newTopic: {
    id: 'common.actions.newTopic',
    defaultMessage: 'New Topic'
  },
  unpublish: {
    id: 'common.actions.unpublish',
    defaultMessage: 'Unpublish'
  },
  exportToPdf: {
    id: 'common.actions.exportToPdf',
    defaultMessage: 'Export to PDF'
  },
  applySignOff: {
    id: 'common.actions.applySignOff',
    defaultMessage: 'Apply sign-off'
  }
});

export default messages;
