import {defineMessages} from 'react-intl';

const messages = defineMessages({
  sessionResults: {
    id: 'sessionResults',
    defaultMessage: 'Session Results'
  },
  checks: {
    id: 'checks',
    defaultMessage: 'Checks'
  },
  result: {
    id: 'result',
    defaultMessage: 'Result'
  },
  feedback: {
    id: 'feedback',
    defaultMessage: 'Feedback'
  },
  media: {
    id: 'common.media',
    defaultMessage: 'Media'
  },
  image: {
    id: 'image',
    defaultMessage: 'Image'
  },
  video: {
    id: 'common.video',
    defaultMessage: 'Video'
  },
  totalChecks: {
    id: 'totalChecks',
    defaultMessage: 'Total checks: {checksCount}'
  },
  noChecks: {
    id: 'noChecks',
    defaultMessage: 'No Checklist results have been submitted.'
  }
});

export default messages;
