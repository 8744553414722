import {refreshPlatform, refreshIconsList, refreshPlatformFlags} from 'api/platform/refresh-queries';
import {refreshProfile} from 'api/user/refresh-queries';

export const uploadIconOptions = {
  props: ({mutate}) => ({
    uploadIconMutation: params =>
      mutate({
        variables: params,
        refetchQueries: [refreshPlatform(), refreshIconsList()]
      })
  })
};

export const editIconOptions = {
  props: ({mutate}) => ({
    editIconMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshPlatform(), refreshIconsList()]
      })
  })
};

export const toggleIconVisiblityOptions = {
  props: ({mutate}) => ({
    toggleIconVisiblityMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshPlatform(), refreshIconsList()]
      })
  })
};

export const toggleStepNoteNameOptions = {
  props: ({mutate}) => ({
    toggleStepNoteNameMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshPlatform()]
      })
  })
};

// useMutation hook is used to call function
export const updateTagCreationRoleOptionsForHook = variables => ({
  variables,
  refetchQueries: [refreshProfile(), refreshPlatform()]
});

export const setNativeAppAutoRemovalIntervalOptions = {
  props: ({mutate}) => ({
    setNativeAppAutoRemovalInterval: variables =>
      mutate({
        variables,
        refetchQueries: [refreshPlatformFlags()]
      })
  })
};

export const updateAdminLoginSettingsOptions = {
  props: ({mutate}) => ({
    updateAdminLoginOptions: variables =>
      mutate({
        variables,
        refetchQueries: [refreshPlatformFlags()]
      })
  })
};

export const updateWebhookUrlOptions = {
  props: ({mutate}) => ({
    updateWebhookUrlMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshPlatform()]
      })
  })
};
