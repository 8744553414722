import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';
import $Button from 'ui-components/Button';
import {commonPalette} from 'shared/styles/palette';
import FontAwesomeIcon from '../../ui-components/Layout/FontAwesomeIcon';
import $InfiniteScroll from 'shared/components/InfiniteScroll';

export const StyledInfiniteScroll = styled($InfiniteScroll)`
  margin-top: 30px;
`;

export const ErrorWrapper = styled.div`
  text-align: center;
`;
export const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: ${props => (props.hasSkillTasks ? '20px' : '')};
  margin-top: ${props => (!props.hasSkillTasks ? '195px' : '')};
`;

export const FixedContainer = styled.div`
  position: sticky;
  top: -20px;
  padding-top: 5px;
  background: var(--surface-default, ${commonPalette.white});
  width: 500px;
`;

export const StyledLearningList = styled.div`
  color: ${commonPalette.txBlack};
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Activity = styled.div`
  display: flex;
  flex-direction: row;
  height: 44px;
  padding: 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid #d9d9d9;
  background: var(--surface-default, ${commonPalette.white});
`;

export const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid var(--grey-400, #dce0e8);
  background: var(--white, ${commonPalette.white});
`;

export const ActivitiesList = styled.div`
  display: flex;
  width: 500px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: ${commonPalette.lightGrey};
  border-top: 1px solid #eee;
  overflow: scroll;
  margin-bottom: 18px;
`;

export const StyledActivitiesDiv = styled.div`
  color: ${commonPalette.txBlack};
  width: 100%;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({isSelectable}) => (isSelectable ? ' cursor: pointer;' : '')}
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  ${({hasGap}) => (hasGap ? 'gap: 240px;' : '')}
  @media (max-width: 450px) {
    ${({hasGap}) => (hasGap ? 'gap: 100px;' : '')}
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
`;

export const SearchIcon = styled(FontAwesomeIcon).attrs({
  actionId: 'search',
  backgroundColor: `${commonPalette.txGrey}`
})`
  font-size: 10px;
  background-color: ${commonPalette.txGrey};
`;

export const InputDiv = styled.div`
  display: flex;
  height: 44px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid #d9d9d9;
  background: var(--surface-default, ${commonPalette.white});
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const DrawerWrapper = styled.div`
  background: ${commonPalette.white};
  position: relative;
  max-width: 90vw;
  width: 500px;
`;

export const DrawerTitleButton = styled.button`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 40px;
  margin-bottom: 10px;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;

export const StyledButton = styled($Button).attrs({
  underlined: true
})`
  padding-bottom: 1.5rem;
  text-decoration: none;
  height: 1%;
  color: ${commonPalette.btnGreen};
  &:hover,
  :focus {
    text-decoration: none;
  }
`;

export const StyledSubText = styled.div`
  color: var(--neutrals-grey, ${commonPalette.txGrey});
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${({isEmptyState}) => (isEmptyState ? '' : 'width: 60%')}
`;

export const BackIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 32
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const DropwdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  min-width: 300px;
`;

export const StyledText = styled.div`
  color: #1f271b;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 2px;
`;
