import {SkillLevels} from './queries';

export const createSkillOptions = {
  props: ({mutate}) => ({
    createSkillMutation: variables =>
      mutate({
        variables,
        refetchQueries: ['skillsPaginated']
      })
  })
};

export const editSkillLevelOptions = {
  props: ({mutate}) => ({
    editSkillLevelMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [{query: SkillLevels}]
      });
    }
  })
};

export const editSkillOptions = {
  props: ({mutate}) => ({
    editSkillMutation: variables =>
      mutate({
        variables,
        refetchQueries: ['skillsPaginated']
      })
  })
};

const skillProfileMutationOptions = mutationName => ({
  props: ({mutate}) => ({
    [mutationName]: variables =>
      mutate({
        variables
      })
  })
});

export const saveSkillProfileOptions = skillProfileMutationOptions('saveSkillProfileMutation');
export const updateSkillProfileOptions = skillProfileMutationOptions('updateSkillProfileMutation');
export const updateSkillProfileTeamsOptions = skillProfileMutationOptions('updateSkillProfileTeamsMutation');
