export const getGuideOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    const versionId = store.router.queryParams?.v || null;
    const variables = {id};
    if (versionId) {
      variables.versionId = versionId;
    }

    return {
      variables,
      fetchPolicy: 'network-only'
    };
  }
};

export const getGuideAssigneeOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    const {reassignGuideDialog} = store;
    const guideId = reassignGuideDialog.guideId || id;
    return {
      variables: {
        id: guideId
      },
      fetchPolicy: 'network-only'
    };
  }
};

export const myGuidesOptions = {
  options: ({store, pagedGuides = true}) => {
    const {
      allGuidesPage: {form}
    } = store;

    return {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        ...(pagedGuides === true && {limit: 10}),
        sortBy: store.allGuidesPage.sortBy,
        guideFilter: store.allGuidesPage.selectedFilter,
        customFilter: store.allGuidesPage.customFilterPayload,
        publishStatus: store.allGuidesPage.selectedStatusPayload,
        searchTerm: form.values().searchTerm
      }
    };
  }
};

export const getDraftGuideOptions = {
  options: ({store}) => {
    const {id} = store.router.params;

    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  }
};

export const domainsForGuidesOptions = {
  options: ({store: {shareDialog, publishGuideDialog, router}}) => {
    const guideFromRouterId = router.params.id;
    const guideFromShareId = shareDialog.guide && shareDialog.guide.id;
    const guideFromPublishId = publishGuideDialog.guide && publishGuideDialog.guide.id;
    const id = guideFromRouterId || guideFromPublishId || guideFromShareId;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'guideDomainsQuery'
};

export const getGuideDetailsOptions = {
  options: ({
    store: {
      guideDetailsDialog: {guideId: id}
    }
  }) => {
    return {
      variables: {
        id
      },
      fetchPolicy: 'no-cache'
    };
  },
  name: 'guideQuery'
};

export const getGuideDomainsDetailsOptions = {
  options: ({
    store: {
      guideDetailsDialog: {guideId: id}
    }
  }) => {
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'domainsQuery'
};

export const guidesLiteForSignOffsOptions = {
  options: () => {
    return {
      variables: {forSignOffs: true},
      fetchPolicy: 'network-only'
    };
  },
  name: 'guidesListLiteQuery'
};

export const guidesLiteForChecklistsOptions = {
  options: () => {
    return {
      variables: {forSignOffs: false},
      fetchPolicy: 'network-only'
    };
  },
  name: 'guidesListLiteQuery'
};
