import {defineMessages} from 'react-intl';

const messages = defineMessages({
  smartSkills: {
    id: 'SmartSkills.smartSkills',
    defaultMessage: 'Skills Matrix'
  },
  gapOverview: {
    id: 'SmartSkills.gapOverview',
    defaultMessage: 'Gap Overview'
  },
  gap: {
    id: 'SmartSkills.gap',
    defaultMessage: 'Gap'
  },
  levelsDistribution: {
    id: 'SmartSkills.levelsDistribution',
    defaultMessage: 'Levels Distribution'
  },
  levelsOverview: {
    id: 'SmartSkills.levelsOverview',
    defaultMessage: 'Levels Overview'
  },
  members: {
    id: 'SmartSkills.members',
    defaultMessage: 'Members'
  },
  overview: {
    id: 'SmartSkills.overview',
    defaultMessage: 'Overview'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  statsApproved: {
    id: 'SmartSkills.stats.approved',
    defaultMessage: 'Approved'
  },
  statsExisting: {
    id: 'SmartSkills.stats.expiring',
    defaultMessage: 'Expiring'
  },
  teamName: {
    id: 'common.form.teamName',
    defaultMessage: 'Team'
  },
  nextPage: {
    id: 'SmartSkills.nextPage',
    defaultMessage: 'Next user page'
  },
  prevPage: {
    id: 'SmartSkills.prevPage',
    defaultMessage: 'Previous user page'
  },
  noData: {
    id: 'common.noData',
    defaultMessage: 'No data available'
  },
  error: {
    id: 'common.error',
    defaultMessage: 'Error loading Skill Matrix'
  }
});

export default messages;
