import React from 'react';
import {observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import {Wrapper, Message, CenteredWrapper} from './styles';

import Icon from 'ui-components/Layout/Icon';
import Spinner from 'shared/components/Spinner';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@observer
class LoadingMessage extends React.Component {
  @observable showRefresh = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.showRefresh = true;
    }, 20000);
  }
  render() {
    const {hasFailed, children, marginTop} = this.props;
    const message = children ? children : 'loading';

    if (hasFailed) {
      return (
        <Wrapper marginTop={marginTop}>
          <CenteredWrapper>
            <Icon name={'warning'} />
            <Message>
              <FormattedMessage {...messages.failure} />
            </Message>
          </CenteredWrapper>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        {this.showRefresh ? (
          <CenteredWrapper>
            <Message>
              <FormattedMessage {...messages.timeout} />
            </Message>
          </CenteredWrapper>
        ) : (
          <CenteredWrapper>
            <Spinner />
            <Message>{message}</Message>
          </CenteredWrapper>
        )}
      </Wrapper>
    );
  }
}
export default LoadingMessage;
