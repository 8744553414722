import type {QueryHookOptions} from '@apollo/client';
import type {UserSkillMatrixData, UserSkillMatrixVariables} from './queries';

export const skillForUserOptions = {
  options: ({skill, store}): QueryHookOptions => {
    const id = skill.id;
    const {userId, teamId} = store.router.params;
    return {
      fetchPolicy: 'network-only',
      variables: {
        id,
        userId,
        teamId
      }
    };
  },
  name: 'skillForUserQuery'
};

export const userSkillMatrixOptions = {
  options: ({store}): QueryHookOptions<UserSkillMatrixData, UserSkillMatrixVariables> => {
    const {teamId, userId} = store.router.params;
    return {
      fetchPolicy: 'network-only',
      variables: {
        teamId,
        userId
      }
    };
  },
  name: 'userSkillMatrixQuery'
};

export const teamSkillMatrixOptions = {
  options: ({store}) => {
    const {teamId} = store.router.params;
    return {
      fetchPolicy: 'no-cache',
      variables: {
        teamId
      }
    };
  },
  name: 'teamSkillMatrixQuery'
};

export const skillMatrixOptions = {
  options: {fetchPolicy: 'network-only'},
  name: 'skillMatrixQuery'
};

export const SkillRolesOptions = {
  name: 'skillRolesQuery'
};

export const skillLevelsOptions = {
  name: 'skillLevelsQuery'
};
