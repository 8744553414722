import {generateTopic} from './topic';

import {publishStatus} from '../constants';

export const generateTopics = async (guide, pdfMessages, guideBaseUrl, intl) => {
  const {topics} = guide;

  if (topics.length > 0) {
    const topicsContent = [];

    for (let i = 0; i < topics.length; i++) {
      if (topics[i].instructions.length > 0) {
        let shouldDisplay = false;

        topics[i].instructions.forEach(instruction => {
          if (instruction.publishStatus === publishStatus.PUBLISHED) {
            shouldDisplay = true;
          }
        });

        if (shouldDisplay) {
          const topic = await generateTopic(topics[i], pdfMessages, guide, guideBaseUrl, intl);

          topicsContent.push(topic);
        }
      }
    }

    return topicsContent;
  }
};
