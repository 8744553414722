import React, {ReactElement, useState, useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';
import views from 'config/views';
import {useMutation, useQuery} from '@apollo/client';
import {AlertRecipientsQuery, AlertRecipientQuery} from 'api/alerts-recipients/queries';
import {EditAlertRecipientMutation} from 'api/alerts-recipients/mutations';
import notification from 'utils/notification-utils';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';
import Field from 'ui-components/Field';
import Select from 'ui-components/Select';
import ButtonGroup from 'ui-components/ButtonGroup';

import messages from './messages';
import {Grid, StyledDiv} from './styles';

const EditAlertRecipient = (props): ReactElement => {
  const [selectedWebhook, setSelectedWebhook] = useState<'webhooks' | 'microsoftTeams' | null>();
  const {
    intl: {formatMessage},
    store
  } = props;
  const {checklistAlerts} = store;
  const {form} = checklistAlerts;
  const editAlertRecipientId = store.router.params?.id;

  const {data} = useQuery(AlertRecipientQuery, {
    variables: {
      id: editAlertRecipientId
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      const {name, configuration} = data.alertRecipient;
      const {webhooks, webhooksMsTeams} = configuration;
      form.update({name, webhookUrl: webhooks[0]?.url, webhookTeamsUrl: webhooksMsTeams[0]?.url});

      if (webhooks?.[0]?.url) {
        setSelectedWebhook('webhooks');
      } else if (webhooksMsTeams?.[0]?.url) {
        setSelectedWebhook('microsoftTeams');
      } else {
        setSelectedWebhook(null);
      }
    }
  }, [data]);

  const options = [
    {id: 'microsoftTeams', name: 'Microsoft Teams'},
    {id: 'webhooks', name: formatMessage(messages.customWebhook)}
  ];

  const [mutateFunction, {loading}] = useMutation(EditAlertRecipientMutation, {
    refetchQueries: [{query: AlertRecipientsQuery}]
  });

  const submitForm = async () => {
    const {name, webhookUrl, webhookTeamsUrl} = form.values();

    try {
      await mutateFunction({
        variables: {
          alertRecipient: {
            id: editAlertRecipientId,
            name,
            configuration: {
              ...(selectedWebhook === 'webhooks' && webhookUrl && {webhooks: [{url: webhookUrl}]}),
              ...(selectedWebhook === 'microsoftTeams' &&
                webhookTeamsUrl && {webhooksMsTeams: [{url: webhookTeamsUrl}]})
            }
          }
        }
      });

      store.router.goTo(views.checklistAlerts, {tab: 'recipients'});
      form.reset();
      notification.success(formatMessage(messages.editedAlertRecipient));
    } catch (e) {
      notification.error(formatMessage(messages.editAlertRecipientFailed));
    }
  };

  function cancel() {
    store.router.goTo(views.checklistAlerts, {tab: 'recipients'});
    form.reset();
  }
  const {webhookUrl, webhookTeamsUrl} = form.values();

  return (
    <Container>
      <ViewTitle title={formatMessage(messages.editAlertRecipient)} />
      <Grid>
        <Field
          autofocus={true}
          size="medium"
          title={formatMessage(messages.alertRecipientName)}
          field="name"
          form={form}
        />
      </Grid>
      <StyledDiv>{formatMessage(messages.deliveryMethods)}</StyledDiv>
      <Select
        selectedValue={selectedWebhook}
        label={formatMessage(messages.methodType)}
        options={options}
        placeholder={formatMessage(messages.selectAlertMethod)}
        defaultValue={formatMessage(messages.selectAlertMethod)}
        onChange={option => setSelectedWebhook(option)}
        style={{marginBottom: 10}}
      />
      {(selectedWebhook === 'webhooks' || (webhookUrl !== '' && selectedWebhook !== 'microsoftTeams')) && (
        <Field
          size="medium"
          title={formatMessage(messages.webhookUrlFieldLabel)}
          placeholder={formatMessage(messages.copyPasteUrl)}
          field="webhookUrl"
          form={form}
        />
      )}
      {(selectedWebhook === 'microsoftTeams' || (webhookTeamsUrl !== '' && selectedWebhook !== 'webhooks')) && (
        <Field
          size="medium"
          title={formatMessage(messages.teamsWebhookUrlFieldLabel)}
          placeholder={formatMessage(messages.copyPasteUrl)}
          field="webhookTeamsUrl"
          form={form}
        />
      )}
      <ButtonGroup
        forColorPicker
        primary={{
          label: loading ? '' : formatMessage(messages.save),
          disabled: !form.isValid || loading,
          onClick: submitForm,
          loading: loading
        }}
        secondary={{
          label: formatMessage(messages.cancel),
          onClick: () => {
            cancel();
          }
        }}
      ></ButtonGroup>
    </Container>
  );
};
export default injectIntl(inject('store')(observer(EditAlertRecipient)));
