import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {action, computed, observable, makeObservable} from 'mobx';
import {client} from 'utils/apollo-client';
import uniqBy from 'lodash/uniqBy';

//custom components
import Select from 'ui-components/Select';

//queries
import {TagsPaginated, TagsByIds} from 'api/tag/queries';

//messages
import messages from './messages';
import debounce from 'lodash/debounce';

@inject('store')
@observer
class TagPickerComponent extends Component {
  @observable tags = [];
  @observable newTags = [];
  @observable input = '';
  @observable tagsLoading = false;
  @observable searchTerm = '';
  @observable page = 0;
  @observable limit = 50;
  @observable totalCount = 0;
  @observable canFetchMore = false;

  async componentDidMount() {
    const {selectedTagsIds, setLocalSelectedTags} = this.props;
    const data = await this.fetchData();
    const tags = data?.tags;
    if (!tags) return;
    const notLoadedTagIds = selectedTagsIds.filter(tagId => !tags.some(tag => tag.id === tagId));
    const localSelectedTags = [];
    tags.forEach(tag => {
      if (selectedTagsIds.includes(tag.id)) {
        localSelectedTags.push(tag);
      }
    });
    const {tagsByIds} = await this.fetchTagsByIds(notLoadedTagIds);
    this.setTags([...tags, ...tagsByIds]);
    setLocalSelectedTags([...localSelectedTags, ...tagsByIds]);
  }

  @action fetchTagsByIds = async tagIds => {
    this.setTagsLoading(true);
    try {
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: TagsByIds,
        variables: {
          tagIds
        }
      });
      const {tagsByIds} = response.data;
      this.setTagsLoading(false);
      return {tagsByIds};
    } catch (error) {
      this.setTagsLoading(false);
      console.error(error);
    }
  };

  @action setTotalCount = val => (this.totalCount = val);

  @action updateSearchTerm = debounce(async newValue => {
    this.setSearchTerm(newValue);
    this.setPage(0);
    const {tags} = await this.fetchData();
    this.setTags(tags);
  }, 300);

  @action setPage = val => (this.page = val);

  @action setSearchTerm = val => (this.searchTerm = val);

  @action onSearch = input => {
    this.input = input;
    this.updateSearchTerm(input);
  };

  @action tagChangeHandler = tagsIds => {
    const {setSelectedTags, locale, setLocalSelectedTags} = this.props;
    const updatedTags = tagsIds.filter(tagId => tagId !== 'create');
    let newTag;

    if (tagsIds.includes('create')) {
      newTag = {
        title: this.input.trim(),
        defaultLocale: locale,
        locale,
        id: `new-${this.input.trim()}`
      };
      updatedTags.push(newTag.id);
      this.newTags.push(newTag);
    }
    setLocalSelectedTags(updatedTags.map(tagId => this.allTags.find(t => t.id === tagId)));
    if (setSelectedTags) {
      setSelectedTags(updatedTags, newTag);
    }
    if (this.searchTerm !== '') this.onSearch('');
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get allTags() {
    const {localSelectedTags} = this.props;
    return uniqBy([...this.tags, ...localSelectedTags, ...this.newTags], i => i.id);
  }

  @computed get tagsTitles() {
    return this.allTags.map(t => t.title.toLowerCase().trim());
  }

  @computed get newTagOption() {
    const {
      intl: {formatMessage}
    } = this.props;
    return {
      id: 'create',
      title: formatMessage(messages.createTagFor, {input: this.input}),
      style: {
        color: '#298784',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '14px'
      }
    };
  }

  @computed get tagsWithNewTagOption() {
    const {disableCreate} = this.props;
    return disableCreate ? this.allTags : [this.newTagOption].concat(this.allTags);
  }

  @computed get canManageBadges() {
    const {
      store: {
        auth: {user}
      }
    } = this.props;
    return user && user.isPlatformAdmin;
  }

  @computed get tagsOptions() {
    const {localSelectedTags} = this.props;
    if (!this.tags.length && this.input.length < 3) return localSelectedTags;
    if (this.input.length < 3 || this.tagsTitles.includes(this.input.toLowerCase().trim())) return this.allTags;
    else return this.tagsWithNewTagOption;
  }

  @computed get tagsOptionsForSkills() {
    const {selectedTagsIds, isSkillTagsPicker} = this.props;
    if (!isSkillTagsPicker) return this.tagsOptions;
    return this.tagsOptions.map(tag => ({...tag, disabled: selectedTagsIds.includes(tag.id) ? false : true}));
  }

  @action setCanFetchMore = val => (this.canFetchMore = val);

  @action setTagsLoading = val => (this.tagsLoading = val);

  @action fetchData = async () => {
    this.setTagsLoading(true);
    try {
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: TagsPaginated,
        variables: {
          offset: this.page * this.limit,
          limit: this.limit,
          filters: {
            title: {
              contains: this.searchTerm
            }
          },
          sortBy: [{field: 'title', order: 'asc'}]
        }
      });
      const {tagsPaginated} = response.data;
      const {results: tags, totalCount, nextOffset} = tagsPaginated;
      this.setTagsLoading(false);
      this.setTotalCount(totalCount);
      this.setCanFetchMore(nextOffset);
      return {tags, totalCount, nextOffset};
    } catch (error) {
      this.setTagsLoading(false);
      console.error(error);
    }
  };

  @action setTags = tags => {
    const {locale} = this.props;
    return (this.tags = !locale
      ? tags
      : tags.map(t => {
          const tag = {...t};
          const {translations, defaultLocale} = tag;
          if (!translations) return tag;

          tag.title = translations[locale] ? translations[locale].title : translations[defaultLocale].title;

          if (tag.badge) {
            tag.iconId = 'badge';
            if (!this.canManageBadges) tag.disabled = true;
          }

          return tag;
        }));
  };

  @action loadMoreTags = async () => {
    if (this.canFetchMore) {
      this.page = this.setPage(this.page + 1);
      const {tags} = await this.fetchData();
      this.setTags([...this.tags, ...tags]);
    }
  };

  render() {
    const {
      intl: {formatMessage},
      style,
      selectedTagsIds,
      showCheckBoxSelector,
      localSelectedTags
    } = this.props;

    return (
      <Select
        allowClear
        showSearch
        dataCy="tag-picker"
        optionFormatter="title"
        options={selectedTagsIds.length < 10 ? this.tagsOptions : this.tagsOptionsForSkills}
        onChange={this.tagChangeHandler}
        onSearch={this.onSearch}
        placeholder={formatMessage(messages.placeholder)}
        selectedValues={localSelectedTags}
        onScrollToBottom={this.loadMoreTags}
        style={style}
        lazyLoad
        showCheckBoxSelector={showCheckBoxSelector}
      />
    );
  }
}

export default injectIntl(TagPickerComponent);
