import React, {useCallback, useEffect, useState} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';

import {toJS} from 'mobx';
import messages from './messages';
import {Header, PageOptions, SearchBarContainer, SortContainer, CreateButtonContainer} from './styles';
import views from 'config/views';
import {SkillProfilesPaginated} from 'api/skills/queries';

//components
import Container from 'ui-components/Layout/Container';
import Button from 'ui-components/Button';
import Select from 'ui-components/Select';
import SearchBar from 'ui-components/Layout/SearchBar';
import {useAbortableQuery} from 'hooks/useAbortableQuery';
import {debounce} from 'lodash';
import SkillProfilesTable from './SkillProfilesTable';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

const sortByOptions = [
  {id: 'name', name: messages.nameAz.defaultMessage},
  {id: 'created_at', name: messages.mostRecent.defaultMessage}
];

type Order = 'asc' | 'desc';

const usePaginationParameters = (
  refetch: (parameters: {
    pageIndex: number;
    sorted: {field: string; order: 'asc' | 'desc'}[];
    filtered: string;
  }) => unknown
) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [sorted, setSorted] = useState<{field: string; order: Order}[]>([{field: 'name', order: 'asc'}]);
  const [filtered, setFiltered] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  useEffect(() => {
    refetch({
      pageIndex,
      sorted,
      filtered
    });
  }, [pageIndex, sorted, filtered]);

  // Reset pagination when filters or sorting change
  useEffect(() => {
    setPageIndex(0);
  }, [filtered, sorted]);

  return {
    pageIndex,
    setPageIndex,
    sorted,
    setSorted,
    filtered,
    setFiltered,
    searchTerm,
    setSearchTerm
  };
};

export type SkillProfile = {
  id: string;
  name: string;
  created_at: string;
  skills: {
    totalCount: number;
  };
  teams: {
    totalCount: number;
  };
  rolesCount: number;
};

type SkillProfilesQueryData = {
  results: SkillProfile[];
  totalCount: number;
};

export const SkillProfiles = props => {
  const {
    intl: {formatMessage},
    store: {router},
    pageSize = 50
  } = props;

  const [skillProfilesQuery, {data, loading, error}] = useAbortableQuery<{
    skillProfilesPaginated: SkillProfilesQueryData;
  }>(SkillProfilesPaginated, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false
  });

  const fetchData: Parameters<typeof usePaginationParameters>[0] = useCallback(
    ({filtered, pageIndex, sorted}) => {
      skillProfilesQuery({
        variables: {
          limit: pageSize,
          offset: pageIndex * pageSize,
          sortBy: sorted,
          filters: {
            name: {contains: filtered}
          }
        }
      });
    },
    [skillProfilesQuery]
  );

  const {pageIndex, setPageIndex, sorted, setSorted, filtered, setFiltered} = usePaginationParameters(fetchData);

  const totalCount = data?.skillProfilesPaginated.totalCount || 0;
  const skillProfiles = data?.skillProfilesPaginated.results || [];

  const handleUpdateSearchTerm = e => {
    e.preventDefault();
    e.stopPropagation();
    const newValue = e.target?.value.substring(0, 50);
    updateSearchTerm(newValue);
  };

  const handleSortBy = sortBy => {
    let order: Order = 'asc';

    if (sortBy === 'created_at') {
      order = 'desc';
    }

    setSorted([{field: sortBy, order}]);

    trackEvent(EVENT_TYPES.SKILL_PROFILES_SORT, {sortBy});
  };

  const updateSearchTerm = debounce((newValue: string) => {
    if (!newValue.length || newValue.length >= 3) {
      setFiltered(newValue);
    }
  }, 300);

  const hidePageOptions = filtered.length === 0 && totalCount === 0;

  return (
    <Container style={{paddingBottom: '50px', height: 'auto'}}>
      <Header>{formatMessage(messages.skillProfiles)}</Header>

      {!hidePageOptions && (
        <PageOptions>
          <SearchBarContainer>
            <SearchBar
              placeholder={formatMessage(messages.searchByName)}
              onChange={handleUpdateSearchTerm}
              maxLength={50}
            />
          </SearchBarContainer>
          <CreateButtonContainer>
            <Button
              secondary
              size={50}
              label={formatMessage(messages.createSkillProfile)}
              iconId={'create'}
              onClick={() => {
                router.goTo(views.skillProfilesManage, {id: 'new'});
              }}
            />
          </CreateButtonContainer>
          <SortContainer>
            <Select
              underlined
              allowClear={false}
              label={formatMessage(messages.sortBy)}
              options={toJS(sortByOptions)}
              selectedValue={sorted[0].field}
              onChange={handleSortBy}
            />
          </SortContainer>
        </PageOptions>
      )}

      <SkillProfilesTable
        loading={loading}
        error={error}
        pageSize={pageSize}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        data={skillProfiles}
        totalCount={totalCount}
        isFiltered={!!filtered}
      />
    </Container>
  );
};

export default inject('store')(injectIntl(SkillProfiles));
