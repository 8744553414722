import React from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import Dialog from 'ui-components/Dialog';
import messages from './messages';

@inject('store')
@observer
class SkillsProfilesDialog extends React.Component {
  render() {
    const {
      intl: {formatMessage},
      store: {skillsPage}
    } = this.props;
    const {setIsSkillProfileDialogOpened, isSkillProfileDialogOpened, editSkill} = skillsPage;

    const actions = [
      {
        label: formatMessage(messages.saveChanges),
        onClick: () => editSkill(),
        disabled: false
      },
      {
        label: formatMessage(messages.cancel),
        onClick: () => setIsSkillProfileDialogOpened(false),
        disabled: false
      }
    ];

    return (
      <Dialog
        isOpen={isSkillProfileDialogOpened}
        title={formatMessage(messages.saveChanges) + ' ?'}
        actions={actions}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
        onCancel={() => setIsSkillProfileDialogOpened(false)}
      >
        {formatMessage(messages.usedSkill)}
      </Dialog>
    );
  }
}

export default injectIntl(SkillsProfilesDialog);
