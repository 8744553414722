export const COLUMN_SIZES = [12, 6, 4, 3];
export const ROOT_PATH = '/admin';
export const DEFAULT_TOPIC_SLUG = '-';
export const termsAndConditionsUrl = 'https://swipeguide.com/terms-and-conditions';

// new EU Intercom workspaces
export const INTERCOM_PROD_ID = 'ww6dtsin';
export const INTERCOM_TEST_ID = 'a1z5eiex';

export const TROUBLESHOOTING_URL =
  'https://support.leading2lean.com/hc/en-us/articles/33919767651085-SwipeGuide-Troubleshooting-signing-in-to-your-Platform-CMS-Editor';
export const INFO_CENTER_URL = 'https://support.leading2lean.com/hc/en-us';
export const RELEASE_NOTES_URL =
  'https://support.leading2lean.com/hc/en-us/community/topics/34877102607757-Swipeguide-Changelog';

export const NEW_PATH_PARAM = 'new';
export const EDIT_MODE = 'edit';
export const CREATE_MODE = 'create';

export const DATE_FORMAT = 'l';
export const DATE_FORMAT_ISO8601 = 'YYYY-MM-DD';

export const TIME_FORMAT = 'LT';
export const TIME_FORMAT_ISO8601 = 'HH:mm';

export const DATE_TIME_FORMAT = 'l LT';

export const EXPORT_DATE_FORMAT = 'DD-M-YYYY';

export const EMPTY_ANSWER_SYMBOL = '-';

export const SMART_SKILLS_COM_LINK =
  'https://www.swipeguide.com/smart-skills-management?utm_source=cms&utm_id=main_nav';

export const getReskillingFrequencyMessage = (formatMessage, messages, months) => {
  switch (months) {
    case 6: {
      return formatMessage(messages.everySixMonths);
    }
    case 12: {
      return formatMessage(messages.everyOneYear);
    }
    case 24: {
      return formatMessage(messages.everyTwoYears);
    }
    default:
      return formatMessage(messages.none);
  }
};

export const getReskillingFrequencyOptions = (formatMessage, messages) => {
  return [
    {id: 0, name: formatMessage(messages.noReskilling)},
    {id: 6, name: formatMessage(messages.sixMonths)},
    {id: 12, name: formatMessage(messages.oneYear)},
    {id: 24, name: formatMessage(messages.twoYears)}
  ];
};

export const expandableEventsList = [
  'step_content_updated',
  'check_content_updated',
  'step_created',
  'check_created',
  'step_visual_updated',
  'step_note_visual_updated',
  'step_note_content_updated',
  'check_visual_updated',
  'instruction_created',
  'instruction_content_updated',
  'instruction_visual_updated',
  'checklist_created',
  'checklist_content_updated',
  'checklist_visual_updated',
  'topic_created',
  'topic_content_updated',
  'guide_published',
  'guide_approval_requested',
  'guide_content_updated',
  'guide_visual_updated',
  'guide_workspaces_updated',
  'guide_approval_partially_approved',
  'guide_approval_approved_and_published'
];
