import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';

//components
import {RangePicker, Field, StyledRangeError} from './styles';

//messages
import messages from './messages';

@observer
class RangePickerComponent extends Component {
  render() {
    const {
      intl: {formatMessage},
      form,
      showRangeError,
      autofocus = false,
      minRating,
      maxRating,
      isRangeValid
    } = this.props;

    return (
      <RangePicker>
        <Field
          autofocus={autofocus}
          size="small"
          type="number"
          min={minRating}
          max={maxRating}
          title={formatMessage(messages.rangeFrom)}
          placeholder={formatMessage(messages.rangeFromPlaceholder)}
          field="rangeFrom"
          form={form}
        />

        <Field
          size="small"
          type="number"
          min={minRating}
          max={maxRating}
          title={formatMessage(messages.rangeTo)}
          placeholder={formatMessage(messages.rangeToPlaceholder)}
          field="rangeTo"
          form={form}
        />

        {showRangeError && (
          <StyledRangeError>
            {!isRangeValid ? formatMessage(messages.rangeNotValid) : formatMessage(messages.rangeOverlap)}
          </StyledRangeError>
        )}
      </RangePicker>
    );
  }
}

export default injectIntl(RangePickerComponent);
