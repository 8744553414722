import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  teamName: {
    id: 'WorkspaceTeamView.teamName',
    defaultMessage: 'Team Name'
  },
  removeTeamSuccess: {
    id: 'WorkspaceTeamView.removeTeamSuccess',
    defaultMessage: 'Team has been removed from the Workspace'
  },
  removeTeamFailure: {
    id: 'WorkspaceTeamView.removeTeamFailure',
    defaultMessage: 'Cannot remove team from the Workspace'
  },
  backToOverview: {
    id: 'common.actions.backToOverview',
    defaultMessage: 'Back to overview'
  },
  managementTeam: {
    id: 'common.forms.managementTeam',
    defaultMessage: 'Management Team'
  },
  managementTeamDescription: {
    id: 'common.description.managementTeamDescription',
    defaultMessage: 'The team responsible for managing the workspace, such as the name, URL, credentials, and branding.'
  },
  defaultTeamForNewUsers: {
    id: 'NewWorkspace.defaultTeamForNewUsers',
    defaultMessage: 'Default Team for New Users'
  },
  defaultTeamDescription: {
    id: 'common.description.defaultTeamDescription',
    defaultMessage:
      ' New users are added to this Team when logging in to a Workspace with their Company Account (SSO) for the first time.'
  },
  removeTeam: {
    id: 'WorkspaceTeamView.removeTeam',
    defaultMessage: 'Remove team from workspace'
  },
  removeTeamConfirm: {
    id: 'WorkspaceTeamView.removeTeamConfirm',
    defaultMessage: 'You are about to remove this Team from the Workspace.'
  },
  removeTeamBody: {
    id: 'WorkspaceTeamView.removeTeamBody',
    defaultMessage:
      'It will no longer be able to publish to this workspace and access this workspace if it has protected content.'
  },
  noTeams: {
    id: 'WorkspaceTeamView.noTeams',
    defaultMessage: 'There are no Teams Added to this Workspace yet.'
  }
});

export default messages;
