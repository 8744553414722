import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//components
import InlineNotification from 'shared/components/InlineNotification';
import Dialog from 'ui-components/Dialog';

//messages
import messages from './messages';

//styles
import {ItemPrimary, ItemSecondary} from './styles';

@inject('store')
@observer
class ArchiveDialogComponent extends Component {
  render() {
    const {store} = this.props;
    const {archiveDialog: dialog} = store;
    const {translations, itemPrimary, itemSecondary, content, style = {}} = dialog;

    const disabled = (dialog.type == 'team' && !dialog.enableSubmit) || false;

    return (
      <Dialog
        actions={[
          {
            label: translations.action,
            onClick: this.handleSubmit,
            disabled
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        title={translations.action}
        titleIconId="warning"
        dialogStyle={style.dialogStyle}
        bodyStyle={style.bodyStyle}
      >
        <InlineNotification type="error" isCondensed>
          {translations.confirmation}
          {translations.body && <br />}
          {translations.body}
        </InlineNotification>
        {itemPrimary && <ItemPrimary style={style.itemPrimary}>{itemPrimary}</ItemPrimary>}
        {itemSecondary && <ItemSecondary>{itemSecondary}</ItemSecondary>}
        {content}
      </Dialog>
    );
  }

  handleSubmit = () => {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {archiveDialog: dialog} = store;

    dialog.archive({
      error: formatMessage(messages.error),
      success: formatMessage(messages.success)
    });
  };
}

export default injectIntl(ArchiveDialogComponent);
