import React, {Component} from 'react';

import {Wrapper, BarWrapper, Bar, RatingLabel, BarLabelWrapper, LabelWrapper} from './styles';

class BarRatingsComponent extends Component {
  // getBarLabel = (shiftXAxis, i) => shiftXAxis ? (i + 1) : i;

  render() {
    const {ratingsPerScore, colorRange, labelsX} = this.props;
    const highestRating = Math.max(...ratingsPerScore);
    return (
      <Wrapper>
        {ratingsPerScore.map((rating, i) => {
          return (
            <BarLabelWrapper width={100 / ratingsPerScore.length} key={`${rating}${i}`}>
              <BarWrapper>
                <Bar height={(rating / highestRating) * 100} bgColor={colorRange[i]}>
                  <RatingLabel>{rating}</RatingLabel>
                </Bar>
              </BarWrapper>
              <LabelWrapper>{labelsX && labelsX[i] ? labelsX[i] : i + 1}</LabelWrapper>
            </BarLabelWrapper>
          );
        })}
      </Wrapper>
    );
  }
}

export default BarRatingsComponent;
