import React from 'react';
import {InjectedIntl, injectIntl} from 'react-intl';
import {kebabCase} from 'lodash';

import {WorkspaceWrapper} from './styles';
import messages from './messages';
import {Workspace} from '../types';

type WorkspacesProps = {
  workspaces?: Workspace[];
  intl: InjectedIntl;
};

const Workspaces = (props: WorkspacesProps) => {
  const {workspaces = [], intl} = props;
  return (
    <span>
      {workspaces.map((workspace, index) => (
        <React.Fragment key={kebabCase(workspace.name)}>
          <WorkspaceWrapper>{workspace.name}</WorkspaceWrapper>
          {index === workspaces.length - 2
            ? ` ${intl.formatMessage(messages.and)} `
            : index < workspaces.length - 1 && ', '}
        </React.Fragment>
      ))}
    </span>
  );
};

export default injectIntl(Workspaces);
