import {observable, action, computed, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import React, {Component} from 'react';
import {FormattedMessage} from 'components/FormattedComponents';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

//lodash
import omit from 'lodash/omit';

//styles
import {Wrapper, NoDataWrapper, LeftButton, RightButton} from './styles';

//config
import {maxNumberOfRowsPerPage} from './constants';

//messages
import messages from './messages';

// Make a table re-run its getProps, getTrProps and similar functions
// when their underlying observables change:
const ReactTableWithObserver = observer(ReactTable);

@observer
class DataTable extends Component {
  @observable _currentPage = this.props.currentPage || 0;
  @observable _prevDataLength = null;
  @observable _prevSearchTerm = this.props.searchTerm;

  constructor(props) {
    super(props);
    makeObservable(this);

    // Set up a reaction to track changes and update observables
    reaction(
      () => [this.props.data?.length, this.props.searchTerm],
      ([dataLength, searchTerm]) => {
        if (this._prevDataLength !== dataLength || this._prevSearchTerm !== searchTerm) {
          this.resetCurrentPageAndPrevValues(dataLength, searchTerm);
        }
      }
    );
  }

  @computed
  get currentPage() {
    return this._currentPage;
  }

  @action
  resetCurrentPageAndPrevValues(dataLength, searchTerm) {
    this._currentPage = 0;
    this._prevDataLength = dataLength;
    this._prevSearchTerm = searchTerm;
  }

  @action
  setCurrentPage(currentPage) {
    this._currentPage = currentPage;
  }

  extendPropsGetter = (targetGetter, sourceGetter) => {
    if (!targetGetter) {
      return sourceGetter;
    }

    if (!sourceGetter) {
      return targetGetter;
    }

    return (...params) => {
      const target = targetGetter(...params) || {};
      const source = sourceGetter(...params) || {};

      return Object.assign(target, source, {
        className: [target.className, source.className].filter(Boolean).join(' ')
      });
    };
  };

  render() {
    const {
      className,
      data,
      getTheadThProps,
      getTrProps,
      primaryAction,
      sortable,
      noDataMessage,
      paddingRows,
      rowBottomBorder,
      hoverEffect,
      NoDataComponent,
      LoadingComponent,
      manual,
      pages
    } = this.props;

    const forwardedProps = omit(this.props, ['className', 'primaryAction', 'currentPage']);

    const extraProps = {};

    if (sortable === false) {
      extraProps.getTheadThProps = this.extendPropsGetter(getTheadThProps, () => ({tabIndex: null}));
    }

    if (primaryAction) {
      extraProps.getTrProps = this.extendPropsGetter(getTrProps, (state, rowInfo) => {
        return {
          onClick: () => primaryAction(rowInfo.row._original),
          className: 'hasOnClick'
        };
      });
    }

    const showTable = (!manual && data && data.length > 0) || manual;

    const serverPaginationProps = {
      pageSize: maxNumberOfRowsPerPage,
      showPagination: pages > 1
    };

    const clientPaginationProps = {
      pageSize: data.length < maxNumberOfRowsPerPage ? data.length : maxNumberOfRowsPerPage,
      page: this.currentPage,
      showPagination: data.length > maxNumberOfRowsPerPage
    };

    return (
      <Wrapper
        className={className}
        paddingRows={paddingRows}
        rowBottomBorder={rowBottomBorder}
        hoverEffect={hoverEffect}
      >
        {!LoadingComponent && !data && <FormattedMessage {...messages.loading} />}
        {!NoDataComponent && data && data.length === 0 && (
          <NoDataWrapper>{noDataMessage ? noDataMessage : <FormattedMessage {...messages.empty} />}</NoDataWrapper>
        )}
        {showTable && (
          <ReactTableWithObserver
            PreviousComponent={props => <LeftButton {...props} iconId={'angle-left'} />}
            NextComponent={props => <RightButton {...props} iconId="angle-right" />}
            {...forwardedProps}
            style={{borderBottom: 'none'}}
            pageText=""
            ofText="/"
            showPageSizeOptions={false}
            resizable={false}
            minRows={0}
            {...extraProps}
            {...(manual ? serverPaginationProps : clientPaginationProps)}
            onPageChange={page =>
              this.props.onPageChange ? this.props.onPageChange?.(page) : this.setCurrentPage(page)
            }
            {...this.props.page}
          />
        )}
      </Wrapper>
    );
  }
}

export default DataTable;
