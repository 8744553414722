import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';
import {customScrollBar} from 'styles/vars';

export const Text = styled.p`
  color: ${commonPalette.txBlack};
  line-height: 1.125rem;
`;

export const AffectedGuidesList = styled.div`
  display: block;
  margin-top: 1rem;
  max-height: 10rem;
  overflow-y: scroll;
  border-top: 1px solid ${commonPalette.borderGrey};
  padding: 0.5rem;

  ${customScrollBar}
`;

export const AffectedGuidesListItem = styled.a`
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: ${commonPalette.txBlack};
  text-decoration: underline;
`;
