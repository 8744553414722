import React, {ReactElement, useState} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';
import views from 'config/views';
import {useMutation} from '@apollo/client';
import {AlertRecipientsQuery} from 'api/alerts-recipients/queries';
import {CreateAlertRecipientMutation} from 'api/alerts-recipients/mutations';
import notification from 'utils/notification-utils';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';
import Field from 'ui-components/Field';
import Select from 'ui-components/Select';
import ButtonGroup from 'ui-components/ButtonGroup';

import messages from './messages';
import {Grid, StyledDiv} from './styles';

const CreateAlertRecipient = (props): ReactElement => {
  const [selectedWebhook, setSelectedWebhook] = useState<string>();
  const {
    intl: {formatMessage},
    store: {checklistAlerts}
  } = props;
  const {form} = checklistAlerts;

  const options = [
    {id: 'microsoftTeams', name: 'Microsoft Teams'},
    {id: 'webhooks', name: formatMessage(messages.customWebhook)}
  ];

  const [mutateFunction, {loading}] = useMutation(CreateAlertRecipientMutation, {
    refetchQueries: [{query: AlertRecipientsQuery}]
  });

  const submitForm = async () => {
    const {store} = props;
    const {router, checklistAlerts} = store;
    const {form} = checklistAlerts;

    const {name, webhookUrl, webhookTeamsUrl} = form.values();

    try {
      await mutateFunction({
        variables: {
          alertRecipient: {
            name,
            configuration: {
              ...(webhookUrl && {webhooks: [{url: webhookUrl}]}),
              ...(webhookTeamsUrl && {webhooksMsTeams: [{url: webhookTeamsUrl}]})
            }
          }
        }
      });

      router.goTo(views.checklistAlerts, {tab: 'recipients'});
      form.reset();
      notification.success(formatMessage(messages.createdAlertRecipient));
    } catch (e) {
      notification.error(formatMessage(messages.createAlertRecipientFailed));
    }
  };

  function cancel() {
    const {store} = props;
    const {router, checklistAlerts} = store;
    const {form} = checklistAlerts;
    router.goTo(views.checklistAlerts, {tab: 'recipients'});
    form.reset();
  }

  return (
    <Container>
      <ViewTitle title={formatMessage(messages.newAlertRecipient)} />
      <Grid>
        <Field
          autofocus={true}
          size="medium"
          title={formatMessage(messages.alertRecipientName)}
          field="name"
          form={form}
        />
      </Grid>
      <StyledDiv>{formatMessage(messages.deliveryMethods)}</StyledDiv>
      <Select
        selectedValue={selectedWebhook}
        label={formatMessage(messages.methodType)}
        options={options}
        placeholder={formatMessage(messages.selectAlertMethod)}
        defaultValue={formatMessage(messages.selectAlertMethod)}
        onChange={option => setSelectedWebhook(option)}
        style={{marginBottom: 10}}
      />
      {selectedWebhook === 'webhooks' && (
        <Field
          size="medium"
          title={formatMessage(messages.webhookUrlFieldLabel)}
          placeholder={formatMessage(messages.copyPasteUrl)}
          field="webhookUrl"
          form={form}
        />
      )}
      {selectedWebhook === 'microsoftTeams' && (
        <Field
          size="medium"
          title={formatMessage(messages.teamsWebhookUrlFieldLabel)}
          placeholder={formatMessage(messages.copyPasteUrl)}
          field="webhookTeamsUrl"
          form={form}
        />
      )}
      <ButtonGroup
        forColorPicker
        primary={{
          label: loading ? '' : formatMessage(messages.create),
          disabled: !form.isValid || loading,
          onClick: submitForm,
          loading: loading
        }}
        secondary={{
          label: formatMessage(messages.cancel),
          onClick: () => {
            cancel();
          }
        }}
      ></ButtonGroup>
    </Container>
  );
};
export default injectIntl(inject('store')(observer(CreateAlertRecipient)));
