import React, {useMemo} from 'react';

import TokenItem from '../Item';

import {List} from './styles';

const TokenList = ({tokens, onDeleteToken, message}) => {
  const tokenItems = useMemo(
    () => tokens?.map(token => <TokenItem key={token.jti} token={token} onDelete={onDeleteToken} label={message} />),
    [tokens, onDeleteToken]
  );

  return <List>{tokenItems}</List>;
};

export default TokenList;
