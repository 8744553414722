import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import ElementResizeListener from 'shared/components/ElementResizeListener';

import {EmptyImage} from './styles';

@inject('store')
@observer
class EmptyImageComponent extends Component {
  render() {
    const {
      store: {
        allGuidesPage: {emptyImageDivWidth, setEmptyImageDivWidth}
      }
    } = this.props;

    return (
      <ElementResizeListener onResize={({width}) => setEmptyImageDivWidth(width)}>
        <EmptyImage width={emptyImageDivWidth} />
      </ElementResizeListener>
    );
  }
}

export default EmptyImageComponent;
