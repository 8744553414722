import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import get from 'lodash/get';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';

//messages
import messages from './messages';

@inject('store')
@observer
class AuthErrorDialogComponent extends Component {
  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {
      authErrorDialog: dialog,
      auth: {logout}
    } = store;

    // pick first domain user can access
    const domain = get(dialog.user, 'teams[0].team.domains[0]');
    let action;
    if (domain) {
      const redirectUrl = window.location.origin.replace('//', `//${domain.slug}-`);
      action = {
        label: formatMessage(messages.viewContent),
        design: 'text',
        href: redirectUrl
      };
    } else {
      // Show logout button when user still have no access to a domain
      action = {
        label: formatMessage(messages.logoutViewer),
        design: 'text',
        onClick: logout
      };
    }

    const isVirtualLogin = dialog.user.id === '0';

    const title = isVirtualLogin ? messages.cannotAccessCMS : messages.authenticationError;
    const subtitle = isVirtualLogin ? messages.adminAccountCMS : messages.accountOnlyInfo;

    return (
      <Dialog actions={() => [action]} isOpen={dialog.opened} title={formatMessage(title)}>
        <FormattedMessage {...subtitle} />
      </Dialog>
    );
  }
}

export default injectIntl(AuthErrorDialogComponent);
