import styled from 'styled-components';

//helpers
import flex from 'styles/flex';

//components
import $Field from 'ui-components/Field';

export const Slug = styled.div`
  ${flex.horizontal};
`;

export const Prefix = styled.div`
  flex-shrink: 0;
`;

export const StyledField = styled($Field).attrs({
  className: 'Slug_Field'
})`
  ${flex.allowOverflowX};
  flex-grow: 1; // IE11
`;
