export const removalRightsOptions = [
  {
    name: 'No Reminders',
    id: '0'
  },
  {
    name: 'Every Year',
    id: '1'
  },
  {
    name: 'Every 2 Years',
    id: '2'
  },
  {
    name: 'Every 3 Years',
    id: '3'
  }
];
