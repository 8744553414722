import styled from 'styled-components';
import {noSelect} from 'shared/utils/sc-utils';

export const DropdownWrapper = styled.div``;

export const DropdownItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  opacity: ${p => (p.expand ? 1 : 0)};
  top: ${p => (p.expand ? 150 + p.index * 70 : 150)}px;
  right: 25px;
  transition: all 0.2s ease-in-out;
`;

export const Label = styled.div`
  height: 25px;
  margin-right: 20px;
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 5px 6px 0 rgba(51, 51, 48, 0.25);
  font-size: 12px;
  line-height: 15px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  ${noSelect}
`;
