import styled from 'styled-components';
import $DragHandle from 'components/DragHandle';
import $Icon from 'ui-components/Layout/Icon';
import {commonPalette} from 'shared/styles/palette';
import {boxShadow} from 'styles/shadows';
import {truncate} from 'shared/styles/partials';

//constants
const dragHandleWidth = 45;

export const ShapeRow = styled.div`
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 1px 0;
  display: flex;
  width: 100%;
  margin: -1px 0 0;
  padding: 0 20px 0 0;

  ${p =>
    p.isSelected &&
    `
    background: #ededed;
  `}

  ${p =>
    p.design === 'default' &&
    `
    &:hover {
      background: #ededed;
    }
  `}

  ${p =>
    p.design === 'ghostly' &&
    `
    background: ${commonPalette.ghostlyGreen};
    filter: blur(4px);
  `}

  ${p =>
    p.design === 'highlight' &&
    `
    background: ${commonPalette.ghostlyGreen};
  `}

  ${p =>
    p.design === 'opaque' &&
    `
    background: ${commonPalette.darkestGray};
    border-color: transparent;
    box-shadow: ${boxShadow.draggable};
  `}
`;

export const DragHandleWrapper = styled.div`
  color: #c9cbcb;
  width: fit-content;
  display: flex;
  align-items: center;
  height: 51px;
  ${p =>
    p.isInvisible &&
    `
    opacity: 0;
  `}
`;

export const DragHandle = styled($DragHandle)`
  height: 100%;
  width: ${dragHandleWidth}px;
`;

export const ShapeDetails = styled.div`
  width: 100%;
  display: block;

  ${p =>
    p.collapsed &&
    `
    display: flex;
    height: 51px;
    align-items: center;  
    position: relative;
    cursor: pointer;
  `}

  ${p =>
    p.isInvisible &&
    `
    opacity: 0;
  `}
`;

export const IconWrapper = styled.div``;

export const Icon = styled(IconWrapper.withComponent($Icon))`
  text-align: center;
  min-width: 24px;

  ${p =>
    p.type === 'shape' &&
    `
    margin-right: 12px;
  `}

  ${p =>
    p.type === 'thickness' &&
    `
    margin-right: 8px;
  `}

  ${p =>
    p.type === 'close' &&
    `
    position: absolute;
    right: 0;
  `}

  ${p =>
    p.type === 'edit' &&
    `
    flex: 0;
  `}
`;

export const Label = styled.div`
  ${truncate}
  display: flex;
  align-items: center;
  text-align: left;
  width: ${p => (!p.colorLabel || p.collapsed) && '100px'};
  max-height: ${p => (p.colorLabel && !p.collapsed ? '35px' : '51px')};

  ${p =>
    p.collapsed &&
    !p.colorLabel &&
    `
    flex: 1;
  `}

  ${p =>
    p.colorLabel &&
    p.collapsed &&
    `
    @media (max-width: 1100px) {
      display: none;
    }
  `}
`;

export const ColorLabelWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Color = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 8px;
  background-color: ${p => p.color};
  border: 1px solid #000;
`;

export const Header = styled.div`
  font-weight: bold;
  display: flex;
  height: 51px;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

export const DetailRow = styled.div`
  padding-left: 36px;
  display: flex;
  height: 51px;
  justify-content: flex-start;
  align-items: center;

  .ant-select-arrow::before {
    content: '\f0d7' !important;
    color: #333330 !important;
  }
`;

export const ColorPicker = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  height: 35px;
  width: fit-content;
  display: flex;
  padding: 5px 18px 5px 18px;
  background-color: #fff;
  align-items: center;
  margin-right: 10px;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 20px 0;
`;

export const DeleteButton = styled.button`
  background-color: #fff;
  color: #000;
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 1px;
  height: 35px;
  padding: 0 15px;
`;
