import {defineMessages} from 'react-intl';

const messages = defineMessages({
  apply: {
    id: 'common.actions.apply',
    defaultMessage: 'Apply'
  },
  resetFilters: {
    id: 'resetFilters',
    defaultMessage: 'Reset Filters'
  }
});

export default messages;
