import React from 'react';
import {Observer, inject} from 'mobx-react';
import messages from './messages';
import {ERRORS_TYPE_MAP} from 'stores/dialogs/auto-translation-dialog';

const ERROR_MESSAGE_MAP = {
  [ERRORS_TYPE_MAP.errorTranslatingGuide]: messages.canNotTranslateGuide,
  [ERRORS_TYPE_MAP.errorAddingNewLanguage]: messages.canNotAddLanguage,
  [ERRORS_TYPE_MAP.errorFetchingLanguages]: messages.canNotSelectLanguage
};

const TranslationErrorDialogContent = ({formatMessage, store}) => {
  const errorMessage = ERROR_MESSAGE_MAP[store.autoTranslationDialog.getErrorType()];
  return <Observer>{() => <p>{formatMessage(errorMessage)}</p>}</Observer>;
};

export default inject('store')(TranslationErrorDialogContent);
