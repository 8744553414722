import {removeFieldsFromObject} from 'utils/object-utils';

export const normalizeCampaignToBeDuplicated = campaignData => {
  const cleanCampaignData = removeFieldsFromObject(campaignData, ['id', '__typename']);

  const {title, ...otherFields} = cleanCampaignData;

  return {
    title: `Copy of ${title}`,
    ...otherFields
  };
};
