import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {DEFAULT_TOPIC_SLUG} from 'config/constants';

//lodash
import find from 'lodash/find';
import without from 'lodash/without';

//helpers
import {styleable} from 'shared/decorators';
import {scrollTo} from 'shared/utils/dom-utils';
import injectScrollParent from 'decorators/injectScrollParent';
import {INSTRUCTION_EDIT_MODE} from 'config/enums';
import {isDraftVersionId, ensureDraftVersion} from 'utils/versioning-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import NewTopicCard from 'components/NewTopicCard';
import CustomDragLayer from './CustomDragLayer';
import DraggableTopicCard from './DraggableTopicCard';
import TopicCard from './TopicCard';

//styles
import {GhostlyInstructionList, HugeCaption, StyledButton, TopicList, ButtonsWrapper} from './styles';

import messages from './messages';

@inject('store')
@injectScrollParent
@observer
@styleable
class TopicListComponent extends Component {
  bottomSpacerElement = null;
  topSpacerElement = null;

  constructor(props) {
    super(props);
    const {store} = props;
    const {editInstructionPage, editTopicPage, newTopicPage} = store;
    editInstructionPage.reset();
    editTopicPage.reset();
    newTopicPage.reset();

    this.setTopics(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setTopics(nextProps);
  }

  setTopics = props => {
    const {store, topics} = props;
    const {dragDropItems: page} = store;

    page.setTopics(topics);
  };

  openCreateNew = ensureDraftVersion(() => {
    const {store} = this.props;
    const {newTopicPage} = store;

    newTopicPage.setDroppedItem(null);
    newTopicPage.setOpened(true);
  }, this);

  renderCustomTopic(topic) {
    const {store} = this.props;
    const {editGuidePage} = store;
    const {currentVersionId} = editGuidePage;

    const sharedProps = {
      key: topic.id,
      topic
    };

    if (!isDraftVersionId(currentVersionId)) {
      return <TopicCard {...sharedProps} onDragHandleMouseDown={this.handleTopicDragHandleMouseDown} />;
    }

    return (
      <DraggableTopicCard
        {...sharedProps}
        onBeginDrag={element => {
          this.toggleTopicsAndScrollElementIntoView(element, true);
        }}
        onEndDrag={element => {
          this.toggleTopicsAndScrollElementIntoView(element, false);
        }}
      />
    );
  }

  createEntity = ({topicId, isChecklist}) => {
    const {store} = this.props;
    const {editInstructionPage} = store;

    editInstructionPage.startEditingNewInstruction({topicId, isChecklist});
  };

  handleTopicDragHandleMouseDown = event => {
    const {store} = this.props;
    const {saveAsDraftDialog} = store;

    event.preventDefault(); // so the focus stays inside the dialog
    saveAsDraftDialog.check();
  };

  scrollIntoView = (element, offsetX, offsetY) => {
    const {getScrollParent} = this.props;
    const scrollParent = getScrollParent();
    const {left, top} = element.getBoundingClientRect();

    const maxScrollTop = scrollParent.scrollHeight - scrollParent.offsetHeight;
    const newScrollLeft = scrollParent.scrollLeft + left - offsetX;
    const newScrollTop = scrollParent.scrollTop + top - offsetY;

    this.bottomSpacerElement.style.height = newScrollTop > maxScrollTop ? `${newScrollTop - maxScrollTop}px` : 0;

    this.topSpacerElement.style.height = newScrollTop < 0 ? `${Math.abs(newScrollTop)}px` : 0;

    scrollTo(scrollParent, {
      left: newScrollLeft,
      top: newScrollTop
    });
  };

  toggleTopicsAndScrollElementIntoView = (element, shouldCollapse) => {
    const {store} = this.props;
    const {dragDropItems} = store;

    const {left, top} = element.getBoundingClientRect();

    // This should be done asyncronously as otherwise the immediate
    // layout change would end the drag
    window.requestAnimationFrame(() => {
      dragDropItems.setAreTopicsCollapsed(shouldCollapse);
      this.scrollIntoView(element, left, top);
    });
  };

  get creatingInstruction() {
    const {store} = this.props;
    const {editInstructionPage} = store;

    return editInstructionPage.mode === INSTRUCTION_EDIT_MODE.CREATE;
  }

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {
      dragDropItems,
      newTopicPage,
      platform: {hasChecklistsEnabled}
    } = store;
    const {topics} = dragDropItems;
    const defaultTopic = find(topics, {slug: DEFAULT_TOPIC_SLUG});
    const customTopics = without(topics, defaultTopic);

    const isEmpty =
      !this.creatingInstruction &&
      !newTopicPage.opened &&
      (!defaultTopic || !defaultTopic.instructions.length) &&
      !customTopics.length;

    const showDefaultTopic =
      defaultTopic && !(newTopicPage.opened && !defaultTopic.instructions.length && !customTopics.length);

    if (isEmpty) {
      return (
        <TopicList isEmpty>
          <HugeCaption>
            <FormattedMessage {...messages.empty} />
          </HugeCaption>
          <ButtonsWrapper>
            <StyledButton
              onClick={() => this.openCreateNew()}
              dataCy="create-first-topic"
              label={formatMessage(messages.createFirstTopic)}
            />
            <StyledButton
              onClick={() => this.createEntity({topicId: defaultTopic.id, isChecklist: false})}
              dataCy="create-first-instruction"
              label={formatMessage(messages.createFirstInstruction)}
            />
            {hasChecklistsEnabled && (
              <StyledButton
                onClick={() => this.createEntity({topicId: defaultTopic.id, isChecklist: true})}
                dataCy="create-first-checklist"
                label={formatMessage(messages.createFirstChecklist)}
              />
            )}
          </ButtonsWrapper>
          <GhostlyInstructionList />
        </TopicList>
      );
    }

    return (
      <TopicList>
        <div
          ref={ref => {
            this.topSpacerElement = ref;
          }}
        />
        {showDefaultTopic && <TopicCard topic={defaultTopic} />}
        {customTopics.map(topic => this.renderCustomTopic(topic))}
        <NewTopicCard onClick={this.openCreateNew} />
        <CustomDragLayer />
        <div
          ref={ref => {
            this.bottomSpacerElement = ref;
          }}
        />
      </TopicList>
    );
  }
}

export default injectIntl(TopicListComponent);
