import React from 'react';
import {injectIntl} from 'react-intl';
import {useMutation} from '@apollo/client';
import {inject, observer} from 'mobx-react';

import {EditTeam} from 'api/team/mutations';
import {getEditTeamOptionsForHook} from 'api/team/mutation-options';

import messages from './messages';

//styles
import {StyledField} from './styles';
import ButtonGroup from 'ui-components/ButtonGroup';
import ParentTeamSelector from 'components/ParentTeamSelector';

const EditTeamDetails = props => {
  const {
    intl: {formatMessage},
    store: {
      editTeamPage,
      platform: {
        developmentFeatureFlags: {teamLevels}
      },
      auth: {
        user: {canManageSkills}
      }
    },
    shouldRenderSaveButton
  } = props;
  const {form, updateTeam, paginatedTeamsVariables} = editTeamPage;
  const [editTeamMutation] = useMutation(EditTeam);
  const canSelectTeam = canManageSkills && teamLevels;

  return (
    <div style={{maxWidth: '500px'}}>
      <StyledField size="small" autofocus={true} title={formatMessage(messages.teamName)} form={form} field="name" />
      {canSelectTeam && (
        <ParentTeamSelector teamPage={editTeamPage} paginatedTeamsVariables={paginatedTeamsVariables} />
      )}
      {shouldRenderSaveButton && (
        <ButtonGroup
          primary={{
            label: formatMessage(messages.save),
            disabled: !form.isValid,
            onClick: () => {
              updateTeam(editTeamMutation, getEditTeamOptionsForHook);
            }
          }}
          style={{marginTop: '43px', marginLeft: '20px'}}
        />
      )}
    </div>
  );
};

export default injectIntl(inject('store')(observer(EditTeamDetails)));
