import styled from 'styled-components';

//helpers
import {commonPalette} from 'shared/styles/palette';
import {boxShadow} from 'styles/shadows';
import typefaces from 'shared/styles/typefaces';
import {truncate} from 'shared/styles/partials';

//components
import $Link from 'shared/components/Link';
import $DragHandle from 'components/DragHandle';
import $Icon from 'ui-components/Layout/Icon';

//constants
const dragHandleWidth = 45;

export const InstructionItem = styled.div`
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 1px 0;
  height: 51px;
  margin: -1px 0 0;
  position: relative;

  ${p =>
    p.design === 'default' &&
    `
    &:hover {
      background: #ededed;
    }
  `}

  ${p =>
    p.design === 'ghostly' &&
    `
    background: ${commonPalette.ghostlyGreen};
    filter: blur(4px);
  `}

  ${p =>
    p.design === 'highlight' &&
    `
    background: ${commonPalette.ghostlyGreen};
  `}

  ${p =>
    p.design === 'opaque' &&
    `
    background: ${commonPalette.darkestGray};
    border-color: transparent;
    box-shadow: ${boxShadow.draggable};
  `}

  ${p =>
    p.isCurrent &&
    `
    background: #ededed;

    &::after {
      background: ${p.theme.mainColor};
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 4px;
    }
  `}
`;

export const InnerWrapperLink = styled($Link)`
  display: flex;
  padding-left: ${dragHandleWidth}px;
  position: relative;
  color: ${commonPalette.txBlack};

  &:hover {
    color: ${commonPalette.txBlack};
  }

  ${p =>
    p.isInvisible &&
    `
    // NB. In Microsoft Edge, opacity won't be applied
    // to its absolute-positioned children unless the
    // element is positioned itself

    opacity: 0;
  `}
`;

export const StyledDragHandle = styled($DragHandle)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${dragHandleWidth}px;

  ${p =>
    p.isInvisible &&
    `
    opacity: 0;
  `}
`;

export const Title = styled.div`
  ${truncate};
  ${typefaces.sidebar.item};
  padding: 15px 20px 15px 0;
`;

export const ChecklistIcon = styled($Icon).attrs({
  name: 'checklist',
  size: 26
})`
  display: block;
  flex-shrink: 0;
  margin: auto 6px auto -8px;
`;
