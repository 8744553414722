export const signMediaOptions = {
  props: ({mutate}) => ({
    signMedia: params =>
      mutate({
        variables: {params}
      })
  })
};

export const cacheMediaOptions = {
  props: ({mutate}) => ({
    cacheMedia: params =>
      mutate({
        variables: {
          publicId: params.publicId,
          resourceType: params.resourceType,
          eagerOptions: params.eagerOptions
        }
      })
  })
};

export const saveMediaOptions = {
  props: ({mutate}) => ({
    saveMedia: params =>
      mutate({
        variables: {
          url: params.url
        }
      })
  })
};

export const editMediaOptions = {
  props: ({mutate}) => ({
    editMedia: variables =>
      mutate({
        variables
      })
  })
};
