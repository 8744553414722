import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {bindField} from 'shared/utils/input-utils';

//components
import Field from 'ui-components/Field';
import ButtonGroup from 'ui-components/ButtonGroup';
import TagPicker from 'components/TagPicker';

//styles
import {ChecklistIcon, FieldWrapper, HeaderCard, StyledTitleUnderlinedTextbox} from './styles';

//mutations
import {UpdateInstruction, UpdateInstructionMedia} from 'api/instruction/mutations';

//options
import {updateInstructionInGuideOptions, updateInstructionMediaOptions} from 'api/instruction/mutation-options';

//messages
import messages from './messages';

@inject('store')
@graphql(UpdateInstruction, updateInstructionInGuideOptions)
@graphql(UpdateInstructionMedia, updateInstructionMediaOptions)
@observer
class InstructionDetailsEditMode extends Component {
  componentWillUnmount() {
    const {
      store: {editInstructionDetailsPage}
    } = this.props;
    editInstructionDetailsPage.setLocalSelectedTags([]);
  }

  handleResize = ({height}) => {
    const {store} = this.props;
    const {detailsCard} = store;

    if (detailsCard.cardHeight !== height) {
      detailsCard.setCardHeight(height);
    }
  };

  render() {
    const {intl, store, updateInstructionMutation} = this.props;
    const {formatMessage} = intl;
    const {
      editInstructionDetailsPage: page,
      detailsCard,
      auth: {user}
    } = store;
    const {cardHeight, wrapperHeight} = detailsCard;
    const {
      form,
      saveButtonEnabled,
      setSelectedTags,
      selectedTagsIds,
      currentInstruction,
      cancel,
      isChecklist,
      localSelectedTags,
      setLocalSelectedTags
    } = page;

    const canCreateTags = user && user.canCreateTags;

    const translations = {
      success: formatMessage(messages.success),
      error: formatMessage(messages.error),
      successMessage: formatMessage(messages.instructionUpdated),
      errorMessage: formatMessage(messages.errorUpdadingInstruction)
    };

    return (
      <HeaderCard
        wrapperHeight={wrapperHeight}
        cardHeight={cardHeight}
        onResize={this.handleResize}
        data-cy="edit-details-card"
      >
        <FieldWrapper>
          {isChecklist && <ChecklistIcon />}
          <Field
            field="title"
            form={form}
            inputComponent={
              <StyledTitleUnderlinedTextbox
                autoFocus
                placeholder={formatMessage(messages.yourInstruction)}
                {...bindField(form, 'title', this.titleFieldOptions)}
              />
            }
          />
        </FieldWrapper>
        <Field
          title={formatMessage(messages.tags)}
          inputComponent={
            <TagPicker
              className="tag-picker"
              selectedTagsIds={selectedTagsIds}
              setSelectedTags={setSelectedTags}
              locale={currentInstruction.locale}
              style={{OptionsWrapper: {maxHeight: 150}}}
              disableCreate={!canCreateTags}
              localSelectedTags={localSelectedTags}
              setLocalSelectedTags={setLocalSelectedTags}
            />
          }
          className="short"
        />
        <ButtonGroup
          primary={{
            label: formatMessage(messages.saveInstruction),
            onClick: () => page.submit(updateInstructionMutation, translations),
            disabled: !saveButtonEnabled,
            loading: page.loading,
            type: 'submit'
          }}
          secondary={{
            label: formatMessage(messages.cancel),
            onClick: cancel
          }}
        />
      </HeaderCard>
    );
  }
}

export default injectIntl(InstructionDetailsEditMode);
