import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  backToChecklists: {
    id: 'backToChecklists',
    defaultMessage: 'Back to Checklists'
  },
  errorChecklists: {
    id: 'errorChecklists',
    defaultMessage: 'An error occurred while loading the checklist results.'
  }
});

export default messages;
