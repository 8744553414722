import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import AutoRemovalGuidesSettings from 'components/AutoRemovalGuidesSettings';
import TagCreationRole from 'components/TagCreationRole';
import AdminLoginOptions from 'components/AdminLoginOptions';

import {SettingsWrapper} from './styles';

@inject('store')
@observer
class PlatformGeneralSettings extends Component {
  render() {
    const {
      tagCreationRoleForm,
      guidesAutoRemovalForm,
      store: {
        platform: {hasNativeApp, sso}
      }
    } = this.props;

    return (
      <SettingsWrapper>
        {sso && <AdminLoginOptions />}
        <TagCreationRole tagCreationRoleForm={tagCreationRoleForm} />
        {hasNativeApp && <AutoRemovalGuidesSettings guidesAutoRemovalForm={guidesAutoRemovalForm} />}
      </SettingsWrapper>
    );
  }
}

export default PlatformGeneralSettings;
