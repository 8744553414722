import store from 'stores/store';
import {ApolloClient, split, ApolloLink, from, createHttpLink, InMemoryCache} from '@apollo/client';
import {SubscriptionClient} from 'subscriptions-transport-ws';
import get from 'lodash/get';
import {GRAPHQL_URL, GRAPHQL_SUBSCRIPTIONS_URL} from 'shared/constants';
import {getMainDefinition} from '@apollo/client/utilities';

// Create an http link:
const simpleHttpLink = createHttpLink({
  uri: GRAPHQL_URL,
  credentials: 'same-origin'
});

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (response.errors) {
      response.errors.forEach(error => {
        const code = get(error, 'extensions.code');
        if (code === 'UNAUTHENTICATED') {
          store.sessionExpiredDialog.open();
        }
      });

      store.auth.getCurrentUser();
    }

    return response;
  });
});

const httpLink = from([afterwareLink, simpleHttpLink]);

// Create a WebSocket link:
const wsLink = new SubscriptionClient(GRAPHQL_SUBSCRIPTIONS_URL, {
  reconnect: true,
  connectionCallback: async error => {
    if (error?.message === 'must authenticate') {
      // Reset the WS connection for it to carry the new cookie
      wsLink.close();
    }
  }
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({query}) => {
    const {kind, operation} = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const cache = new InMemoryCache({
  typePolicies: {
    StepNoteTranslationItem: {
      keyFields: ['id', 'stepId']
    }
  }
});

const client = new ApolloClient({
  link,
  cache
});

export {client};
