import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

//styled-components
import {LogoutView} from './styles';

@inject('store')
@observer
class LogoutViewComponent extends Component {
  componentDidMount() {
    const {store} = this.props;
    store.auth.logout();
  }

  render() {
    return <LogoutView></LogoutView>;
  }
}

export default LogoutViewComponent;
