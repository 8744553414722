import React from 'react';
import {Container, Label, Sausage} from './styles';

export default function SmartSkillsSausageBar({value, label, color, outline, percentage, preserveBackground}) {
  return (
    value > 0 && (
      <Container value={value} style={{flexGrow: value}} percentage={percentage}>
        {label && <Label>{label}</Label>}
        <Sausage color={color} outline={outline} percentage={percentage} preserveBackground={preserveBackground}>
          {value}
          {percentage > 0 && '%'}
        </Sausage>
      </Container>
    )
  );
}
