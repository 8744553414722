import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import DataTable from 'ui-components/Layout/DataTable';
import Container from 'ui-components/Layout/Container';
import LoadingMessage from 'components/LoadingMessage';
import SearchBar from 'ui-components/Layout/SearchBar';

//data
import {UserInfo} from 'api/user/queries';

//styled-components
import {ThemeColorPreview, StyledDiv} from './styles';

import views from 'config/views';

//messages
import messages from './messages';
import {HeaderWrapper, CellWrapper, StyledText} from 'ui-components/Layout/DataTable/styles';

@inject('store')
@graphql(UserInfo)
@observer
class ThemesViewComponent extends Component {
  state = {
    searchTerm: ''
  };

  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      auth: {
        user: {canManageThemes}
      },
      router
    } = store;

    if (!canManageThemes) {
      router.goTo(views.root, {});
    }
  }

  componentDidMount() {
    const {store} = this.props;
    const {app} = store;
    const actionMenuItems = [
      {
        id: 'create',
        view: 'newTheme'
      }
    ];
    app.setActionMenuItems(actionMenuItems);
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  editTheme(theme) {
    const {store} = this.props;
    store.router.goTo(views.editTheme, {id: theme.id, tab: 'name-and-logo'});
  }

  updateSearchTerm = e => {
    this.setState({searchTerm: e.target.value.toLowerCase().trim()});
  };

  renderThemePreview(theme) {
    return (
      <div>
        <ThemeColorPreview color={theme.mainColor} />
        <ThemeColorPreview color={theme.buttonColor} />
      </div>
    );
  }

  render() {
    const {
      intl: {formatMessage},
      data: {me, loading, error}
    } = this.props;
    const {searchTerm} = this.state;

    if (loading || error) {
      return <LoadingMessage hasFailed={error}>{formatMessage(messages.loading)}</LoadingMessage>;
    }

    if (!me) {
      return <StyledDiv>{formatMessage(messages.noThemes)}</StyledDiv>;
    }

    const myThemes = me.themes && me.themes.filter(theme => theme.name.toLowerCase().includes(searchTerm));

    const noThemesMessage =
      !loading && !me.themes.length
        ? formatMessage(messages.noThemes)
        : searchTerm
        ? formatMessage(messages.noFilteredThemes, {searchTerm})
        : null;

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.themeDescription)}
          right={<SearchBar placeholder={formatMessage(messages.searchThemes)} onChange={this.updateSearchTerm} />}
        />
        <DataTable
          data={myThemes}
          noDataMessage={noThemesMessage}
          hoverEffect
          defaultSorted={[
            {
              id: 'name',
              desc: false
            }
          ]}
          getTrProps={(_, rowInfo) => ({
            onClick: () => this.editTheme(rowInfo.original)
          })}
          columns={[
            {
              Header: <HeaderWrapper paddingLeft>{formatMessage(messages.name)}</HeaderWrapper>,
              accessor: 'name',
              resizable: true,
              Cell: ({value}) => <CellWrapper paddingLeft>{<span title={value}>{value}</span>}</CellWrapper>
            },
            {
              Header: <StyledText marginLeft={20}>{formatMessage(messages.colors)}</StyledText>,
              accessor: 'id',
              Cell: p => (
                <CellWrapper>
                  <StyledText marginLeft={20}>
                    {this.renderThemePreview(myThemes.find(x => x.id === p.value))}
                  </StyledText>
                </CellWrapper>
              ),
              sortable: false
            }
          ]}
        />
      </Container>
    );
  }
}

export default injectIntl(ThemesViewComponent);
