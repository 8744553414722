import {observable, action, makeObservable} from 'mobx';
import Raven from 'raven-js';

import {normalizeCampaignToBeDuplicated} from 'components/CampaignMenu/utils';

import views from 'config/views';

import notification from 'utils/notification-utils';

class CampaignsPage {
  @observable currentInstructionId = null;
  @observable currentCampaignId = null;
  @observable currentCampaignReport = null;
  @observable currentCampaignFeedback = null;
  @observable instructionsList = null;
  @observable currentFeedbackPage = 1;
  @observable totalFeedbackPages = null;
  @observable translations = {};
  @observable showFeedbacksPagination = false;
  @observable currentCampaignIdMenuOpen = null;
  @observable searchTerm = '';
  @observable signOff;
  @observable campaignToDuplicate;

  reportData = null;
  feedbackData = null;
  feedbacksPerPage = 5;

  @action
  setCampaignToDuplicate = campaignToDuplicate => {
    this.campaignToDuplicate = normalizeCampaignToBeDuplicated(campaignToDuplicate);
  };

  @action
  setSignOff = signOff => {
    this.signOff = signOff;
  };

  @action
  setSearchTerm = searchTerm => {
    this.searchTerm = searchTerm;
  };

  @action
  setCurrentCampaignIdMenuOpen = currentCampaignIdMenuOpen => {
    this.currentCampaignIdMenuOpen = currentCampaignIdMenuOpen;
  };

  @action
  duplicateCampaign = async (duplicateCampaignMutation, campaign) => {
    try {
      const result = await duplicateCampaignMutation({campaign});
      const {id} = result.data.createCampaign;

      this.store.router.goTo(views.editCampaign, {id, tab: 'questions'});

      notification.success(this.translations.signOffDuplicatedSucces);
    } catch (err) {
      Raven.captureException(err);

      notification.success(this.translations.signOffDuplicatedError);
    }

    this.setCurrentCampaignIdMenuOpen(null);
  };

  @action
  openCampaignReport = campaignId => {
    this.currentCampaignId = campaignId;
    this.currentCampaignReport = null;
    this.currentCampaignFeedback = null;
    this.currentFeedbackPage = 1;
    this.totalFeedbackPages = null;
  };

  @action
  setCurrentCampaignReport = reportData => {
    this.reportData = reportData;
    this.currentCampaignReport = this.reportData.campaignReport;
    this.totalFeedbackPages = Math.ceil(this.currentCampaignReport.feedbacksLength / this.feedbacksPerPage);
    this.showFeedbacksPagination = this.totalFeedbackPages > 1;
    if (this.instructionsList) {
      return;
    }
    this.instructionsList = this.currentCampaignReport && this.currentCampaignReport.instructions;
  };

  @action
  setCurrentCampaignFeedback = feedback => {
    this.feedbackData = feedback;
    this.currentCampaignFeedback = this.feedbackData.campaignFeedback;
  };

  @action
  setCurrentInstructionId = instructionId => {
    this.currentInstructionId = instructionId || null;
    this.reportData.refetch({instructionId: this.currentInstructionId});
    this.feedbackData.refetch({instructionId: this.currentInstructionId});
  };

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  nextFeedbackPage = () => {
    this.currentFeedbackPage += 1;
    this.feedbackData.refetch({feedbackOffset: (this.currentFeedbackPage - 1) * this.feedbacksPerPage});
  };

  @action
  previousFeedbackPage = () => {
    this.currentFeedbackPage -= 1;
    this.feedbackData.refetch({feedbackOffset: (this.currentFeedbackPage - 1) * this.feedbacksPerPage});
  };

  @action
  setFeedbackPage = pageNumber => {
    this.currentFeedbackPage = pageNumber;
    this.feedbackData.refetch({feedbackOffset: (this.currentFeedbackPage - 1) * this.feedbacksPerPage});
  };

  setStore = store => {
    this.store = store;
  };

  constructor() {
    makeObservable(this);
  }
}

export default CampaignsPage;
