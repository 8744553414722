import styled, {css} from 'styled-components';
import flex from 'styles/flex';
import colors from 'styles/colors';
import {cond} from 'shared/utils/sc-utils';
import {swallowingStyled} from 'styles/styled-components/utils';
import {commonPalette} from 'shared/styles/palette';
import {svgElementsFillCurrentColor} from 'styles/vars';

import $Dialog from 'ui-components/Dialog';

//external
import Dropzone from 'react-dropzone';

export const overlayClassName = 'c-upload-image__overlay';

export const UploadIconDropzone = swallowingStyled(Dropzone, {
  swallowProps: ['imageThumbnail', 'styles', 'loadingImage']
})`
    ${flex.vertical}
    ${flex.centerVertical}
    transition: all 150ms linear;
    width: 100%;
    height: 148px;
    background-color: white;
    position: relative;
    text-align: center;
    color: ${commonPalette.txBlack};
    border: 1px dashed rgb(108,108,108);
    margin-top:15px;
    margin-bottom:15px;
  
    ${p => cond(p.imageThumbnail, `background: url('${p.imageThumbnail}') center / cover no-repeat;`)}
  
    ${p =>
      cond(
        p.loadingImage === true,
        css`
          .${overlayClassName} {
            opacity: 0.8;
          }
        `
      )}
  
    ${p =>
      cond(
        p.loadingImage === false,
        css`
          &:hover {
            .${overlayClassName} {
              cursor: pointer;
              opacity: 0.5;
            }
          }
        `
      )}
  
    ${p =>
      cond(
        !p.imageThumbnail && !p.loadingImage,
        css`
          &:hover {
            cursor: pointer;
          }
        `
      )}
  
    ${p => p.styles}
  `;

export const UploadIconActiveStyle = {
  border: `3px dashed ${colors.primary}`
};

export const UploadSvgIconPreview = styled.div`
  ${p => !p.isWarning && p.themeColor && `color: ${p.themeColor};`}
  ${p => p.isWarning && `color: ${commonPalette.orange};`}
  > svg {
    width: auto;
    height: 100px;
    fill: currentColor !important;

    ${svgElementsFillCurrentColor}
  }
`;

export const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.33;
  color: ${colors.txGrey};
`;

export const Caption = styled(Text)`
  font-style: italic;
`;

export const StyledDialog = styled($Dialog)``;
