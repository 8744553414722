import styled from 'styled-components';

import DataTable from 'ui-components/Layout/DataTable';

import {StepContentDesktop} from 'shared/components/StepContent';

import {commonPalette, checklistColors} from 'shared/styles/palette';

export const ResultContent = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

export const ResultHeader = styled.div`
  text-align: center;
`;

export const ResultStatus = styled.div`
  margin-top: 3px;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  align-self: flex-start;
  background-color: ${({value}) =>
    value ? checklistColors.green : typeof value === 'boolean' ? checklistColors.red : checklistColors.white};
  ${({value}) => (typeof value === 'object' ? `border: 1px solid ${commonPalette.borderGrey};` : '')}
`;

export const CheckContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  align-self: flex-start;
`;

export const CheckIndex = styled.div`
  margin-right: 20px;
  align-self: flex-start;
`;

export const StyledStepContent = styled(StepContentDesktop)`
  max-height: 80px;
  margin-top: -2px;
`;

export const ChecksHeaderTitle = styled.div`
  margin-right: 15px;
`;

export const ChecksHeaderSubtitle = styled.div`
  font-size: 10px;
  align-self: flex-end;
`;

export const FeedbackContent = styled.div`
  align-self: flex-start;
  ${({cursorPointer}) => (cursorPointer ? 'cursor: pointer;' : '')}
`;

export const StyledTable = styled(DataTable)`
  .rt-table {
    overflow: auto !important;
  }

  .rt-tbody {
    overflow: unset !important;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }
`;
