import React, {Component} from 'react';
import {DragLayer} from 'react-dnd';
import PropTypes from 'prop-types';

//components
import StepCard from '../StepCard';

//styled-components
import {PositionedBox} from './styles';

@DragLayer(monitor => ({
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType()
}))
export default class CustomDragLayer extends Component {
  static propTypes = {
    layout: PropTypes.object.isRequired
  };

  render() {
    const {currentOffset, isDragging, item, itemType, layout} = this.props;

    if (!currentOffset || !isDragging) {
      return null;
    }

    const {x, y} = currentOffset;
    const {preview} = item;

    const transform = `translate(${x}px, ${y}px)`;

    return (
      <div>
        <PositionedBox
          itemType={itemType}
          style={{
            WebkitTransform: transform,
            transform
          }}
        >
          {itemType === 'SWIPEABLE_STEP' && <StepCard layout={layout} step={preview} />}
        </PositionedBox>
      </div>
    );
  }
}
