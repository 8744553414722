import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

//lodash
import {mapValues, pick} from 'lodash';

//components
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import StepsTabs from 'components/StepsTabs';
import LoadingMessage from 'components/LoadingMessage';

//data
import {Campaign} from 'api/campaign/queries';
import {campaignOptions} from 'api/campaign/query-options';

//messages
import messages from './messages';

//mutations
import {EditCampaign} from 'api/campaign/mutations';

//options
import {editCampaignOptions} from 'api/campaign/mutation-options';

@inject('store')
@graphql(EditCampaign, editCampaignOptions)
@graphql(Campaign, campaignOptions)
@observer
class EditCampaignView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      editCampaignPage: campaignPage,
      auth: {user},
      router
    } = store;

    if (!user.canManageCampaigns) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, [
        'preview',
        'questions',
        'editSuccess',
        'editFailure',
        'saveChangesTitle',
        'saveChangesContent',
        'save',
        'discardChangesAction',
        'close',
        'cancel',
        'saveAnyway'
      ]),
      message => formatMessage(message)
    );

    campaignPage.setSteps();
    campaignPage.setTranslations(translations);
    campaignPage.stepsStore.startListeningToRouteChange();
  }

  componentDidMount() {
    this.setActionMenuItems();
  }
  componentDidUpdate() {
    this.setActionMenuItems();
  }

  goToPreview = () => {
    const {
      store: {editCampaignPage: campaignPage}
    } = this.props;
    campaignPage.stepsStore.goToPreview();
  };

  exitPreview = () => {
    history.back();
  };

  setActionMenuItems = () => {
    const {
      store: {app, editCampaignPage: campaignPage}
    } = this.props;
    const isPreview = campaignPage.stepsStore.currentStep.key === 'preview';

    const actionMenuItems = [
      {
        id: 'preview',
        disabled: isPreview,
        onClick: this.goToPreview
      },
      {
        id: 'back',
        disabled: !isPreview,
        onClick: this.exitPreview,
        secondary: true
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {store, data} = nextProps;
    const {editCampaignPage: campaignPage, campaignPreviewDialog} = store;
    const {campaign, loading: loadingCampaign} = data;

    if (loadingCampaign) return;

    if (!campaign) {
      store.router.goTo(views.campaigns, {});
      return;
    }

    campaignPage.startEdit(campaign);
    campaignPreviewDialog.setCampaign(campaign);
  }

  componentWillUnmount() {
    const {store} = this.props;
    const {editCampaignPage: campaignPage, app, campaignPreviewDialog} = store;
    campaignPage.reset();
    campaignPage.stepsStore.stopListeningToRouteChange();
    app.resetActionMenuItems();
    campaignPreviewDialog.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      data,
      editCampaignMutation
    } = this.props;
    const {editCampaignPage: campaignPage} = store;
    const {loading, error} = data;
    const {stepsStore, submit} = campaignPage;
    const {currentStep} = stepsStore;

    const isPreview = currentStep.key === 'preview';

    const formSubmit = () => {
      submit(editCampaignMutation);
      store.router.goTo(views.campaigns, {});
    };

    if (!currentStep) {
      return <span />;
    }

    if (loading || error) return <LoadingMessage hasFailed={error}>{formatMessage(messages.loading)}</LoadingMessage>;

    if (isPreview && currentStep) return currentStep.component;

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <StepsTabs
          canSubmit={campaignPage.isValid}
          mode={'edit'}
          stepsStore={stepsStore}
          page={campaignPage}
          formSubmit={formSubmit}
          cancel={() => store.router.goTo(views.campaigns, {})}
        />
      </Container>
    );
  }
}

export default injectIntl(EditCampaignView);
