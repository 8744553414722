import React from 'react';
import {observable, action, computed, toJS, reaction, makeObservable} from 'mobx';
import moment from 'moment';
import map from 'lodash/map';
import times from 'lodash/times';
import isEmpty from 'lodash/isEmpty';

import {posthogCapture} from 'shared/utils/posthog-utils';
import Raven from 'raven-js';

//utils

import notification from 'utils/notification-utils';
import {sleep} from 'utils/general';
import store from 'stores/store';

import {client} from 'utils/apollo-client';
import {TriggerChecklistExport} from 'api/checklists/queries';

import Steps from 'stores/models/steps';
import ChecklistsResults from 'components/ChecklistsResults';
import ThoughtspotView from 'views/ThoughtspotView';

class CheckListResultsPage {
  @observable loaders = {
    exportLoader: false
  };
  @observable dateFilterId = 'today';

  @observable
  oldestChecklistAnswerDateEntry = moment();

  // Default state is today
  generatedDateFilters = [{id: 'today', name: 'Today'}];

  @observable
  checklistFilterIds = null;

  @observable
  guideFilterIds = null;

  @observable
  workspaceFilterIds = null;

  @observable
  teamFilterIds = null;

  @observable
  translations;

  @observable
  defaultFilters;

  @observable
  stepsStore;

  @action
  setDateFilterId = selectedDateFilterId => {
    this.dateFilterId = selectedDateFilterId;
  };

  @action
  setChecklistFilterIds = checklistIds => {
    this.checklistFilterIds = checklistIds;
  };

  @action
  setGuideFilterIds = guideFilterIds => {
    this.guideFilterIds = guideFilterIds;
  };

  @action
  setWorkspaceFilterIds = workspaceFilterIds => {
    this.workspaceFilterIds = workspaceFilterIds;
  };

  @action
  setTeamFilterIds = teamFilterIds => {
    this.teamFilterIds = teamFilterIds;
  };

  @action
  setTranslations = translations => {
    this.translations = {...this.translations, ...translations};
  };

  @action
  setDefaultFilters = filters => {
    this.defaultFilters = filters;
  };

  @action
  setOldestDateEntry = oldestDateEntry => {
    if (oldestDateEntry.submittedAt) {
      this.oldestChecklistAnswerDateEntry = moment(oldestDateEntry.submittedAt);
      this.generateMonthsOptions();
    }
  };

  menuItems = {
    DOWNLOAD: {
      id: 'download',
      disabled: false,
      onClick: () => this.triggerExportProcessing()
    },
    SPINNER: {
      id: 'spinner',
      isSpinning: true,
      disabled: false
    }
  };

  @action
  triggerExportProcessing = async () => {
    try {
      this.loaders.exportLoader = true;

      const {data} = await client.query({
        query: TriggerChecklistExport,
        fetchPolicy: 'network-only',
        variables: this.triggerChecklistExportQueryVariables
      });

      const {result, error} = data.checklistAnswersExportTrigger;

      if (!result && error) {
        throw new Error(error);
      }

      posthogCapture('Export Checklist Results');
      notification.success(this.translations.checklistExportTriggerSuccess);
    } catch (err) {
      Raven.captureException(err);
      notification.error(this.translations.checklistExportTriggerFailure);
    } finally {
      await sleep(2000); // adding delay so that the user is not clicking multiple times
      this.loaders.exportLoader = false;
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed get shouldShowExportAction() {
    const {
      checklistsPage: {filteredChecklistAnswers},
      checklistResultsPage: {stepsStore}
    } = store;

    return filteredChecklistAnswers.length > 0 && stepsStore?.currentStepIndex == 1;
  }

  @action
  init = params => {
    this.loaders.exportLoader = false;
    this.dateFilterId = 'today';

    this.disposeExportStateChangeReaction = reaction(
      () => {
        const {
          shouldShowExportAction,
          loaders: {exportLoader}
        } = this;

        return [exportLoader, shouldShowExportAction];
      },
      ([exportLoader, shouldShowExportAction]) => {
        const {menuItems} = this;
        const {app} = store;
        if (exportLoader) {
          app.setActionMenuItems([menuItems.SPINNER]);
          return;
        }

        if (shouldShowExportAction) {
          app.setActionMenuItems([menuItems.DOWNLOAD]);
        } else {
          app.setActionMenuItems([]);
        }
      },
      {fireImmediately: true}
    );

    if (this.stepsStore?.steps && params?.tab) {
      const index = this.stepsStore.steps.findIndex(x => x.key === params.tab);
      this.stepsStore.goToTab(index);
    }
  };

  @action
  resetFilters() {
    this.setDateFilterId('today');
    this.setChecklistFilterIds([]);
    this.setGuideFilterIds([]);
    this.setWorkspaceFilterIds([]);
    this.setTeamFilterIds([]);
  }

  @action
  dispose = () => {
    const {app} = store;

    this.loaders.exportLoader = false;
    app.setActionMenuItems([]);
    this.disposeExportStateChangeReaction();
  };

  @computed get dateFilterPayload() {
    const {dateFilterId} = this;
    let startDate = moment();
    let endDate = moment();

    switch (dateFilterId) {
      case 'today':
        startDate = startDate.startOf('day');
        break;
      case 'yesterday':
        startDate.subtract(1, 'days').startOf('day');
        endDate = startDate.clone().endOf('day');
        break;
      case 'last7days':
        startDate.subtract(6, 'days').startOf('day');
        break;
      case 'last30days':
        startDate.subtract(29, 'days').startOf('day');
        break;
      default: {
        const dateFilterValue = this.generatedDateFilters.find(filterOption => filterOption.id === dateFilterId).name;

        if (dateFilterValue) {
          startDate = moment(dateFilterValue, 'MMMM YYYY').startOf('month');
          endDate = moment(dateFilterValue, 'MMMM YYYY').endOf('month');
        }

        break;
      }
    }

    return {
      startDate: moment.utc(startDate).toDate(),
      endDate: moment.utc(endDate).toDate()
    };
  }

  @computed get checklistIdsFilterPayload() {
    const checklistFilterIds = toJS(this.checklistFilterIds);
    const isSet = Array.isArray(checklistFilterIds);
    const shouldApplyFilter = isSet && !isEmpty(checklistFilterIds);
    return {
      checklistIds: shouldApplyFilter ? checklistFilterIds : null
    };
  }

  @computed get guideIdsFilterPayload() {
    const guideFilterIds = toJS(this.guideFilterIds);
    const isSet = Array.isArray(guideFilterIds);
    const shouldApplyFilter = isSet && !isEmpty(guideFilterIds);
    return {
      guideIds: shouldApplyFilter ? guideFilterIds : null
    };
  }

  @computed get workspaceIdsFilterPayload() {
    const workspaceFilterIds = toJS(this.workspaceFilterIds);
    const isSet = Array.isArray(workspaceFilterIds);
    const shouldApplyFilter = isSet && !isEmpty(workspaceFilterIds);
    return {
      domainIds: shouldApplyFilter ? workspaceFilterIds : null
    };
  }

  @computed get teamIdsFilterPayload() {
    const teamFilterIds = toJS(this.teamFilterIds);
    const isSet = Array.isArray(teamFilterIds);
    const shouldApplyFilter = isSet && !isEmpty(teamFilterIds);
    return {
      teamIds: shouldApplyFilter ? teamFilterIds : null
    };
  }

  @computed get checkListAnswersQueryVariables() {
    return {
      ...this.dateFilterPayload,
      ...this.checklistIdsFilterPayload,
      ...this.guideIdsFilterPayload,
      ...this.workspaceIdsFilterPayload,
      ...this.teamIdsFilterPayload
    };
  }

  @computed get triggerChecklistExportQueryVariables() {
    const {
      checklistsPage: {searchTerm}
    } = store;

    return {
      ...this.checkListAnswersQueryVariables,
      ...(searchTerm && {searchTerm})
    };
  }

  generateMonthsOptions = () => {
    const filterDateStart = this.oldestChecklistAnswerDateEntry.clone().startOf('month');
    const filterDateEnd = moment().startOf('month');

    const totalNumberOfMonths = filterDateEnd.diff(filterDateStart, 'month') + 1;
    const generatedMonths = map(times(totalNumberOfMonths), (_, index) =>
      filterDateEnd.clone().subtract(index, 'month').format('MMMM YYYY')
    );

    const monthsFilterOptions = generatedMonths.map((entry, index) => {
      return {id: `${index}`, name: entry};
    });

    const defaultFilterOptions = Object.entries(toJS(this.defaultFilters)).map(arrElement => ({
      id: `${arrElement[0]}`,
      name: arrElement[1]
    }));

    const allDateFilterOptions = defaultFilterOptions.concat(monthsFilterOptions);
    this.generatedDateFilters = allDateFilterOptions;
    return allDateFilterOptions;
  };

  @action
  setSteps = () => {
    this.stepsStore = new Steps({
      steps: [
        {
          key: 'overview',
          path: 'overview',
          component: <ThoughtspotView hideTitle />,
          checked: true
        },
        {
          key: 'results',
          path: 'results',
          component: <ChecklistsResults />,
          checked: false
        }
      ],
      editMode: true
    });
  };
}

export default CheckListResultsPage;
