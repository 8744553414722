import styled from 'styled-components';
import $DataTable from 'ui-components/Layout/DataTable';
import {commonPalette} from 'shared/styles/palette';

export const StyledText = styled.div`
  color: #1f271b;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const NoSkillTextContainer = styled.div`
  display: flex;
  height: 680px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

export const StyledSubText = styled.div`
  color: ${commonPalette.txtGrey};
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const DataTable = styled($DataTable)``;
