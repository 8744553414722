import {getSvgRoundedNumber} from '../utils';

export const getLinkList = (linksMap, pdfMessages, guide) => {
  const {goTo, step: stepText} = pdfMessages;

  let links = linksMap.map(linkObj => linkObj.link);

  if (links.length > 0) {
    const guideId = guide.id;

    links = links.map(link => {
      const isSlugLink = link.includes(`/guide/${guide.slug}/`);
      const isShareLink = link.includes(`share/guide/${guideId}`);

      if (isShareLink) {
        const instructionId = link.split(`share/guide/${guideId}/`)[1];

        if (instructionId) {
          let instructionTitle, linkTopic;

          guide.topics.forEach(topic => {
            const instruction = topic.instructions.find(instr => instr.id === instructionId);

            if (instruction) {
              instructionTitle = instruction.title;
              linkTopic = topic;
            }
          });

          if (linkTopic) {
            return {
              link,
              linkText: `${goTo}: ${linkTopic.visible ? ` ${linkTopic.title}  › ` : ''} ${instructionTitle}`
            };
          }
        }
      }

      if (isSlugLink) {
        const linkInfo = link.split('/guide/')[1];
        const [, topicSlug, instructionSlug, stepNumber] = linkInfo.split('/');

        const topic = guide.topics.find(topic => topic.slug === topicSlug);

        if (topic) {
          const instruction = topic.instructions.find(instruction => instruction.slug === instructionSlug);

          return {
            link,
            linkText: instruction
              ? `${goTo}: ${topic.visible ? ` ${topic.title}  › ` : ''} ${instruction.title} ${
                  stepNumber && stepNumber > 0 ? ` ›  ${stepText} ${stepNumber}` : ''
                }`
              : link
          };
        }
      }

      return {link};
    });
  }

  const linkListHeader =
    links.length > 0
      ? {
          text: pdfMessages.pdfReferences,
          bold: true,
          fontSize: 10,
          margin: [0, 10, 0, -5]
        }
      : {};

  const linksListItems = links.map((link, i) => [
    {svg: getSvgRoundedNumber(i + 1), width: 12, margin: [0, 10, 0, 10]},
    {
      text: link.linkText || link.link,
      link: link.link,
      margin: [20, -22, 0, 0],
      fontSize: 10
    }
  ]);

  return [linkListHeader, linksListItems];
};
