import React, {useContext, useEffect, useCallback, useMemo} from 'react';
import mapValues from 'lodash/mapValues';
import {injectIntl} from 'react-intl';
import pick from 'lodash/pick';
import {GuideActivityLog, StyledInfiniteScroll} from './styles';
import {GetGuideActivity} from 'api/guide/queries';
import {MobXProviderContext, observer} from 'mobx-react';
import LoadingMessage from 'components/LoadingMessage';

import {FormattedMessage} from 'components/FormattedComponents';

import Activities from './Activities';
import messages from './messages';
import {useLazyQuery} from '@apollo/client';

const useGetActivityQuery = ({guideId, eventCategories}) => {
  const [guideActivityQuery, {data, loading, error, fetchMore: baseFetchMore}] = useLazyQuery(GetGuideActivity, {
    variables: {
      guideId,
      eventCategories
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });

  const nextCursor = data?.activityLogPaginated?.nextCursor;
  const results = data?.activityLogPaginated?.results ?? null;

  const fetchMore = useCallback(() =>
    baseFetchMore(
      {
        variables: {
          cursor: data?.activityLogPaginated?.nextCursor
        },
        updateQuery: (prev, {fetchMoreResult}) => {
          if (!prev?.activityLogPaginated) {
            // Prevent the app from crashing if the query before fetchMore has been called again
            // Related to https://github.com/apollographql/apollo-feature-requests/issues/107
            return prev;
          }
          const {results, nextCursor} = fetchMoreResult.activityLogPaginated;
          return {
            ...prev,
            activityLogPaginated: {
              ...prev.activityLogPaginated,
              results: [...prev.activityLogPaginated.results, ...results],
              nextCursor
            }
          };
        }
      },
      [data, loading, baseFetchMore]
    )
  );

  return {
    guideActivityQuery,
    nextCursor,
    results,
    loading,
    error,
    fetchMore
  };
};

const GuideActivityLogComponent = props => {
  const {
    intl: {formatMessage}
  } = props;

  const {
    store: {
      versionHistoryDrawer: {guideId, activeFilterKeys, setTranslations, getVersionQueryParam}
    }
  } = useContext(MobXProviderContext);

  const {guideActivityQuery, results, nextCursor, loading, error, fetchMore} = useGetActivityQuery({
    guideId,
    eventCategories: activeFilterKeys
  });

  const activitiesWithVersion = useMemo(() => {
    return results?.map(activity => {
      const version = getVersionQueryParam(activity.versionId);
      return {
        ...activity,
        v: version
      };
    });
  }, [results]);

  useEffect(() => {
    const translations = mapValues(
      pick(messages, ['contentChanges', 'publishedVersions', 'approvalUpdates']),
      message => formatMessage(message)
    );
    setTranslations(translations);
  }, []);

  useEffect(() => {
    guideActivityQuery();
  }, [activeFilterKeys]);

  if ((!results && loading) || error) {
    return (
      <LoadingMessage hasFailed={error} marginTop={true}>
        <FormattedMessage {...messages.loading} />
      </LoadingMessage>
    );
  }

  return (
    <GuideActivityLog>
      <Activities activities={activitiesWithVersion} />
      {nextCursor && <StyledInfiniteScroll onBottom={fetchMore} isLoading={loading} threshold={300} />}
    </GuideActivityLog>
  );
};

export default injectIntl(observer(GuideActivityLogComponent));
