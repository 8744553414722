import {observable, action, computed, makeObservable} from 'mobx';

import axios from 'axios';

//helpers
import notification from 'utils/notification-utils';
import store from 'stores/store';
import IpaPanelForm from 'stores/forms/ipa-panel-form';

class IPAStore {
  constructor() {
    makeObservable(this);
  }

  form = IpaPanelForm;
  @observable subdomain;
  @observable error = null;
  @observable loading = null;
  @observable platformBody = {};

  // numeric values
  @observable maxEditors = null;
  @observable maxDomains = null;
  @observable maxPublishedGuides = null;

  // boolean values
  @observable basicAnalytics = false;
  @observable checklists = false;
  @observable colorContent = false;
  @observable customFonts = false;
  @observable feedback = false;
  @observable hasRever = false;
  @observable guideApproval = false;
  @observable liveChat = false;
  @observable versioning = false;
  @observable multiDomain = false;
  @observable stepHintMedia = false;
  @observable canExportToPdf = false;
  @observable autoTranslations = false;
  @observable protectedContent = false;
  @observable sentimentMeasuring = false;
  @observable checklistEscalation = false;
  @observable aiStepContentSuggestions = false;
  @observable apiAccess = false;
  @observable skills = false;
  @observable configurableAlertsRecipients = false;
  @observable checklistsSignature = false;

  // object values
  @observable canUseXmReality = false;
  @observable sso = false;
  @observable beekeeper = false;

  // requires different dev set up
  @observable nativeApp = false;
  @observable nativeLatestVersion = '';
  @observable nativeIosInReview = false;
  @observable nativeAndroidInReview = false;

  // languages
  @observable enabledLanguages = [];

  @action toggleBasicAnalytics = () => (this.basicAnalytics = !this.basicAnalytics);
  @action toggleColorContent = () => (this.colorContent = !this.colorContent);
  @action toggleCustomFonts = () => (this.customFonts = !this.customFonts);
  @action toggleFeedback = () => (this.feedback = !this.feedback);
  @action toggleHasRever = () => (this.hasRever = !this.hasRever);
  @action toggleGuideApproval = () => (this.guideApproval = !this.guideApproval);
  @action toggleLiveChat = () => (this.liveChat = !this.liveChat);
  @action toggleChecklists = () => (this.checklists = !this.checklists);

  @action toggleMultiDomain = () => (this.multiDomain = !this.multiDomain);
  @action toggleStepHintMedia = () => (this.stepHintMedia = !this.stepHintMedia);
  @action toggleCanExportToPdf = () => (this.canExportToPdf = !this.canExportToPdf);
  @action toggleProtectedContent = () => (this.protectedContent = !this.protectedContent);
  @action toggleSentimentMeasuring = () => (this.sentimentMeasuring = !this.sentimentMeasuring);
  @action toggleChecklistEscalation = () => (this.checklistEscalation = !this.checklistEscalation);
  @action toggleAiStepContentSuggestions = () => (this.aiStepContentSuggestions = !this.aiStepContentSuggestions);

  @action toggleNativeIosInReview = () => (this.nativeIosInReview = !this.nativeIosInReview);
  @action toggleNativeAndroidInReview = () => (this.nativeAndroidInReview = !this.nativeAndroidInReview);

  @action toggleAutoTranslations = () => {
    this.autoTranslations = !this.autoTranslations;
  };
  @action toggleVersioning = () => {
    this.versioning = !this.versioning;
    if (!this.versioning) {
      this.checklists = false;
    }
  };
  @action toggleChecklistsSignature = () => {
    this.checklistsSignature = !this.checklistsSignature;
  };

  @action toggleApiAccess = () => {
    this.apiAccess = !this.apiAccess;
  };
  @action toggleSkills = () => {
    this.skills = !this.skills;
  };
  @action toggleConfigurableAlertsRecipients = () => {
    this.configurableAlertsRecipients = !this.configurableAlertsRecipients;
  };

  @action toggleLanguage = ({locale, short, language}) => {
    const index = this.enabledLanguages.findIndex(lang => lang.locale === locale);
    if (index === -1) {
      this.enabledLanguages.push({locale, short, language});
    } else {
      this.enabledLanguages.splice(index, 1);
    }
  };

  @computed
  get isFormValid() {
    return this.form.isValid;
  }

  // these are only values that the user can change in the UI. the rest are in the original platform body
  @computed get platformFeaturesConfig() {
    return {
      checklists: this.checklists,
      colorContent: this.colorContent,
      customFonts: this.customFonts,
      feedback: this.feedback,
      hasRever: this.hasRever,
      guideApproval: this.guideApproval,
      liveChat: this.liveChat,
      beekeeper: this.beekeeper,
      sentimentMeasuring: this.sentimentMeasuring,
      maxEditors: this.maxEditors,
      maxDomains: this.maxDomains,
      versioning: this.versioning,
      multiDomain: this.multiDomain,
      stepHintMedia: this.stepHintMedia,
      canExportToPdf: this.canExportToPdf,
      autoTranslations: this.autoTranslations,
      protectedContent: this.protectedContent,
      maxPublishedGuides: this.maxPublishedGuides,
      checklistEscalation: this.checklistEscalation,
      aiStepContentSuggestions: this.aiStepContentSuggestions,
      apiAccess: this.apiAccess,
      skills: this.skills,
      configurableAlertsRecipients: this.configurableAlertsRecipients,
      checklistsSignature: this.checklistsSignature,
      nativeApp: this.nativeApp,
      nativeLatestVersion: this.nativeLatestVersion,
      nativeIosInReview: this.nativeIosInReview,
      nativeAndroidInReview: this.nativeAndroidInReview
    };
  }

  @computed get platformLanguagesConfig() {
    return this.enabledLanguages;
  }

  getPlatform = async subdomain => {
    this.subdomain = subdomain;
    try {
      this.error = null;
      this.loading = true;
      const {
        data: {platform}
      } = await axios.get(`/ipa/platforms/${subdomain}`);
      this.setPlatformBody(platform);
      const {features} = platform;
      const {availableLanguages, nativeLatestVersion, nativeIosInReview, nativeAndroidInReview} = platform;
      this.loading = false;
      const {
        basicAnalytics,
        checklists,
        colorContent,
        customFonts,
        feedback,
        hasRever,
        guideApproval,
        liveChat,
        beekeeper,
        nativeApp,
        maxEditors,
        maxDomains,
        versioning,
        multiDomain,
        stepHintMedia,
        autoTranslations,
        canExportToPdf,
        protectedContent,
        maxPublishedGuides,
        sentimentMeasuring,
        canUseXmReality,
        sso,
        checklistEscalation,
        aiStepContentSuggestions,
        skills,
        configurableAlertsRecipients,
        apiAccess,
        checklistsSignature
      } = features;
      this.setPlatformFlags({
        basicAnalytics,
        checklists,
        colorContent,
        customFonts,
        feedback,
        hasRever,
        guideApproval,
        liveChat,
        beekeeper,
        nativeApp,
        maxEditors,
        maxDomains,
        versioning,
        multiDomain,
        stepHintMedia,
        canExportToPdf,
        autoTranslations,
        protectedContent,
        maxPublishedGuides,
        sentimentMeasuring,
        canUseXmReality,
        sso,
        checklistEscalation,
        aiStepContentSuggestions,
        skills,
        configurableAlertsRecipients,
        apiAccess,
        checklistsSignature,
        nativeLatestVersion,
        nativeIosInReview,
        nativeAndroidInReview
      });
      this.setEnabledLanguages({
        availableLanguages
      });

      return platform;
    } catch (error) {
      this.loading = false;
      this.error = error;
      notification.error('Could not fetch platform details');
    }
  };

  @action
  setPlatformFlags = ({
    basicAnalytics,
    checklists,
    colorContent,
    customFonts,
    feedback,
    hasRever,
    guideApproval,
    liveChat,
    beekeeper,
    nativeApp,
    maxEditors,
    maxDomains,
    versioning,
    multiDomain,
    stepHintMedia,
    canExportToPdf,
    autoTranslations,
    protectedContent,
    maxPublishedGuides,
    sentimentMeasuring,
    canUseXmReality,
    sso,
    checklistEscalation,
    aiStepContentSuggestions,
    skills,
    configurableAlertsRecipients,
    apiAccess,
    checklistsSignature,
    nativeLatestVersion,
    nativeIosInReview,
    nativeAndroidInReview
  }) => {
    this.basicAnalytics = basicAnalytics;
    this.checklists = checklists;
    this.colorContent = colorContent;
    this.customFonts = customFonts;
    this.feedback = feedback;
    this.hasRever = hasRever;
    this.guideApproval = guideApproval;
    this.liveChat = liveChat;
    this.beekeeper = beekeeper;
    this.nativeApp = nativeApp;
    this.maxEditors = maxEditors;
    this.maxDomains = maxDomains;
    this.versioning = versioning;
    this.multiDomain = multiDomain;
    this.stepHintMedia = stepHintMedia;
    this.canExportToPdf = canExportToPdf;
    this.autoTranslations = autoTranslations;
    this.protectedContent = protectedContent;
    this.maxPublishedGuides = maxPublishedGuides;
    this.sentimentMeasuring = sentimentMeasuring;
    this.canUseXmReality = canUseXmReality;
    this.sso = sso;
    this.checklistEscalation = checklistEscalation;
    this.aiStepContentSuggestions = aiStepContentSuggestions;
    this.skills = skills;
    this.configurableAlertsRecipients = configurableAlertsRecipients;
    this.apiAccess = apiAccess;
    this.checklistsSignature = checklistsSignature;
    this.nativeLatestVersion = nativeLatestVersion;
    this.nativeIosInReview = nativeIosInReview;
    this.nativeAndroidInReview = nativeAndroidInReview;

    this.form.$('maxEditors').sync(this.platformFeaturesConfig.maxEditors);
    this.form.$('maxDomains').sync(this.platformFeaturesConfig.maxDomains);
    this.form.$('maxPublishedGuides').sync(this.platformFeaturesConfig.maxPublishedGuides);
    this.form.$('nativeLatestVersion').sync(this.platformFeaturesConfig.nativeLatestVersion);
  };

  @action
  setEnabledLanguages = platformLanguages => {
    this.enabledLanguages.replace(platformLanguages.availableLanguages);
  };

  @action
  setPlatformBody = body => {
    this.platformBody = body;
  };

  @action
  updatePlatform = async () => {
    const {maxPublishedGuides, maxEditors, maxDomains, nativeLatestVersion} = this.form.values();
    const maxPublishedGuidesNumber = maxPublishedGuides > 0 ? parseInt(maxPublishedGuides, 10) : 0;
    const maxEditorsNumber = maxEditors > 0 ? parseInt(maxEditors, 10) : 0;
    const maxDomainsNumber = maxDomains > 0 ? parseInt(maxDomains, 10) : 0;

    this.platformBody.features = {
      ...this.platformBody.features,
      ...this.platformFeaturesConfig,
      ...{maxPublishedGuides: maxPublishedGuidesNumber},
      ...{maxEditors: maxEditorsNumber},
      ...{maxDomains: maxDomainsNumber}
    };
    this.platformBody.availableLanguages = this.platformLanguagesConfig;
    this, (this.platformBody.nativeAndroidInReview = this.nativeAndroidInReview);
    this.platformBody.nativeIosInReview = this.nativeIosInReview;
    this.platformBody.nativeLatestVersion = nativeLatestVersion;

    try {
      this.loading = true;
      await axios.put(`/ipa/platforms/${this.subdomain}`, this.platformBody);
      this.loading = false;
      await Promise.all([
        this.getPlatform(this.subdomain),
        store.platform.fetchPlatformFlags(),
        store.auth.getCurrentUser()
      ]);
      notification.success('Platform features updated!');
    } catch (error) {
      this.loading = false;
      this.error = error;
      notification.error('Could not update platform details');
    }
  };
}

export default IPAStore;
