import styled from 'styled-components';
import $FollowUpKindPicker from 'components/FollowUpKindPicker';
import $Icon from 'ui-components/Layout/Icon';

export const FollowUpQuestion = styled.div`
  position: relative;
  max-width: 420px;
  border-radius: 3px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
  padding: 16px 16px 0px 16px;
  margin-top: 16px;
  background: #ffffff;
`;

export const Icon = styled($Icon).attrs({
  size: 14
})`
  position: absolute;
  right: 21px;
  top: 21px;
  cursor: pointer;
`;

export const FollowUpKindPicker = styled($FollowUpKindPicker)``;

export const Title = styled.div`
  margin-bottom: 20px;
`;
