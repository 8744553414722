import {defineMessages} from 'react-intl';

const messages = defineMessages({
  sessionDetails: {
    id: 'sessionDetails',
    defaultMessage: 'Session Details'
  },
  checklist: {
    id: 'checklist',
    defaultMessage: 'Checklist'
  },
  dateAndTime: {
    id: 'dateAndTime',
    defaultMessage: 'Date and time'
  },
  score: {
    id: 'score',
    defaultMessage: 'Score'
  },
  user: {
    id: 'user',
    defaultMessage: 'User'
  },
  guide: {
    id: 'guide',
    defaultMessage: 'Guide'
  },
  signature: {
    id: 'signature',
    defaultMessage: 'Signature'
  },
  checklistTemplateType: {
    id: 'checklistTemplateType',
    defaultMessage: 'Checklist Type'
  }
});

export default messages;
