import {action, observable, makeObservable, toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import Raven from 'raven-js';

//lodash
import invoke from 'lodash/invoke';

//helpers
import {styleable} from 'shared/decorators';
import {isDesktop} from 'shared/utils/browser-utils';
import {toggleClass} from 'shared/utils/dom-utils';

//components
import EditorColorPicker from 'components/EditorColorPicker';
import {FormattedMessage} from 'components/FormattedComponents';
import MarkdownEditor from './MarkdownEditor';

//styles
import {ButtonGroup, EditorWrapper, StyledEditor} from './styles';

//messages
import messages from './messages';
import CustomLinkOpener from './customLinkOpener';

@inject('store')
@observer
@styleable
export default class EditorComponent extends Component {
  static defaultProps = {
    autoFocus: false,
    readOnly: false,
    shouldCollapseMargins: false,
    shouldHideFormattingTools: false,
    shouldHideToolbarOnBlur: false,
    shouldSaveOnReturn: false
  };

  static propTypes = {
    autoFocus: PropTypes.bool,
    editorRef: PropTypes.func,
    editorStyle: PropTypes.object,
    onBlur: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onEscape: PropTypes.func,
    onFocus: PropTypes.func,
    onSave: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    shouldCollapseMargins: PropTypes.bool,
    shouldHideFormattingTools: PropTypes.bool,
    shouldHideToolbarOnBlur: PropTypes.bool,
    shouldSaveOnReturn: PropTypes.bool,
    value: PropTypes.string
  };

  constructor(props) {
    super(props);
    makeObservable(this);
    this.contentState = this.parseContentState(this.props.value);
  }

  @observable.ref contentState = null;
  @observable currentValue = this.props.value;
  editorRef = null;
  hostRef = null;

  componentDidMount() {
    this.checkAutoFocus(this.props, {});
    this.setContentState(this.parseContentState(this.props.value));
  }

  checkAutoFocus = (nextProps, prevProps) => {
    // Do nothing if the auto-focus is disabled or the editor is read-only and thus not focusable:
    if (!nextProps.autoFocus || nextProps.readOnly) {
      return;
    }

    // Do nothing if neither the auto-focus nor the read-only properties have changed:
    if (nextProps.autoFocus === prevProps.autoFocus && nextProps.readOnly === prevProps.readOnly) {
      return;
    }

    // Ensure that the component is rendered with the updated props and then focus:
    window.requestAnimationFrame(() => {
      if (this.editorRef) {
        this.editorRef.focus();
      }
    });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.value !== this.currentValue) {
      this.setContentState(this.parseContentState(nextProps.value));
    }

    this.checkAutoFocus(nextProps, this.props);
  };

  get toolbar() {
    const {shouldHideFormattingTools, store} = this.props;
    const {platform} = store;
    const {canColorContent} = platform;

    if (shouldHideFormattingTools) {
      return {
        options: []
      };
    }

    const toolbar = {
      options: ['inline', 'list', 'link'],
      inline: {
        className: 'rdw-options-wrapper',
        options: ['bold', 'italic', 'underline']
      },
      list: {
        className: 'rdw-options-wrapper',
        options: ['unordered', 'ordered']
      },
      link: {
        className: 'rdw-options-wrapper',
        options: ['unlink'],
        showOpenOptionOnHover: false
      }
    };

    if (canColorContent) {
      toolbar.options.push('colorPicker');
      toolbar.colorPicker = {
        className: 'rdw-options-wrapper',
        component: EditorColorPicker
      };
    }

    return toolbar;
  }

  handleEditorBlur = () => {
    this.toggleHasFocus(false);
    invoke(this.props, 'onBlur');
  };

  handleEditorFocus = () => {
    this.toggleHasFocus(true);
    invoke(this.props, 'onFocus');
  };

  handleCancelClick = () => {
    invoke(this.props, 'onCancel');
  };

  handleEditorContentStateChange = contentState => {
    this.currentValue = JSON.stringify(contentState);
    invoke(this.props, 'onChange', {
      target: {
        value: this.currentValue
      }
    });
  };

  handleEditorEscape = () => {
    invoke(this.props, 'onEscape');
  };

  handleEditorReturn = event => {
    const {shouldSaveOnReturn} = this.props;

    if (event.shiftKey === shouldSaveOnReturn) {
      return 'not-handled';
    }

    invoke(this.props, 'onSave');
    return 'handled';
  };

  handleSaveClick = () => {
    invoke(this.props, 'onSave');
  };

  parseContentState = string => (string ? JSON.parse(string) : null);

  @action
  setContentState = contentState => {
    this.contentState = contentState;
  };

  setEditorRef = ref => {
    this.editorRef = ref;
    invoke(this.props, 'editorRef', ref);
  };

  setHostRef = ref => {
    this.hostRef = ref;
  };

  /**
   * Toggling the host's className via React will cause
   * react-draft-wysiwyg to close any open editor's modals
   */
  toggleHasFocus = value => {
    if (this.hostRef) {
      toggleClass(this.hostRef, 'has-focus', value);
    }
  };

  handleImproveTextClick = () => {
    const {handleImproveTextClick, value} = this.props;

    handleImproveTextClick(value);
  };

  render() {
    const {
      editorStyle,
      onCancel,
      onClick,
      onSave,
      placeholder,
      readOnly,
      shouldCollapseMargins,
      shouldHideToolbarOnBlur,
      handleImproveTextClick,
      style,
      value,
      shouldHideFormattingTools
    } = this.props;
    const saveChangesDataCy = readOnly ? 'save-changes' : 'save-changes-active';

    let valueContent = '';
    try {
      if (value.trim().length > 0) {
        const valueObject = JSON.parse(value);
        valueObject.blocks.forEach(block => (valueContent += block.text));
      }
    } catch (err) {
      Raven.captureException(err);
    }

    const toolbarCustomButtons = [
      !shouldHideFormattingTools && <CustomLinkOpener key={'link-opener'} />,
      <ButtonGroup
        editorStyle={editorStyle}
        {...(handleImproveTextClick && {
          secondary: {
            iconId: 'magic',
            label: <FormattedMessage {...messages.improveText} />,
            onClick: this.handleImproveTextClick,
            transition: false,
            disabled: valueContent.trim().length === 0
          }
        })}
      />,
      <ButtonGroup
        editorStyle={editorStyle}
        {...(onSave && {
          primary: {
            label: <FormattedMessage {...messages.save} />,
            onClick: this.handleSaveClick,
            transition: false,
            dataCy: saveChangesDataCy
          }
        })}
        {...(onCancel &&
          !handleImproveTextClick && {
            secondary: {
              label: <FormattedMessage {...messages.cancel} />,
              onClick: this.handleCancelClick,
              transition: false
            }
          })}
      />
    ].filter(i => i);

    const sharedEditorProps = {
      contentState: this.contentState,
      editorRef: this.setEditorRef,
      editorStyle: toJS(editorStyle),
      handleReturn: this.handleEditorReturn,
      onContentStateChange: this.handleEditorContentStateChange,
      onEscape: this.handleEditorEscape,
      onFocus: this.handleEditorFocus,
      placeholder: placeholder,
      readOnly: readOnly,
      spellCheck: true,
      toolbarCustomButtons: onCancel && onSave && toolbarCustomButtons,
      toolbarOnFocus: shouldHideToolbarOnBlur
    };

    return (
      <EditorWrapper elementRef={this.setHostRef} onBlur={this.handleEditorBlur} onClick={onClick} style={style}>
        {isDesktop() ? (
          <StyledEditor
            {...sharedEditorProps}
            shouldCollapseMargins={shouldCollapseMargins}
            toolbar={this.toolbar}
            stripPastedStyles={true}
          />
        ) : (
          <MarkdownEditor {...sharedEditorProps} />
        )}
      </EditorWrapper>
    );
  }
}
