import {defineMessages} from 'react-intl';

const messages = defineMessages({
  saveAsDraft: {
    id: 'GuideVersions.saveAsDraft',
    defaultMessage: 'Save as draft'
  },
  saveAsDraftSuccess: {
    id: 'GuideVersions.saveAsDraftSuccess',
    defaultMessage: 'Your draft was replaced succesfully'
  },
  saveAsDraftWarning: {
    id: 'GuideVersions.saveAsDraftWarning',
    defaultMessage:
      'Saving this older version as a draft will replace your current draft last updated on the {draftDate}.'
  },
  saveAsDraftError: {
    id: 'GuideVersions.saveAsDraftError',
    defaultMessage: 'Your draft could not be replaced. Please contact customer support!'
  },
  goToDraft: {
    id: 'GuideVersions.goToDraft',
    defaultMessage: 'Go to draft'
  },
  goToDraftWarning: {
    id: 'GuideVersions.goToDraftWarning',
    defaultMessage: 'This guide is under approval, this version is locked.'
  }
});

export default messages;
