import {action, observable, makeObservable} from 'mobx';
import {compact} from 'lodash';

import campaignQuestionForm from 'stores/forms/campaign-question-form';
import FollowUpQuestion from 'stores/models/follow-up-question';
import CampaignTextOption from 'stores/models/campaign-text-type-options';

class CampaignPreviewDialog {
  @observable opened = false;
  @observable followUps = [];
  @observable isMobileView = false;
  @observable textTypeOptions = [];
  @observable campaign;

  questionForm = campaignQuestionForm;

  @action open = campaign => {
    const {
      applyCampaignDialog: {toggleDialogVisibility}
    } = this.store;

    toggleDialogVisibility();

    this.opened = true;
    this.setCampaign(campaign);
  };

  @action close = () => {
    const {
      applyCampaignDialog: {toggleDialogVisibility}
    } = this.store;

    toggleDialogVisibility();

    this.reset();
  };

  @action setViewMode = viewMode => {
    this.isMobileView = viewMode;
  };

  @action updateFollowUpValues = (followUps, defaultLocale) => {
    this.followUps = compact(followUps).map(
      ({questionTranslations, ...rest}) =>
        new FollowUpQuestion({...rest, question: questionTranslations[defaultLocale]})
    );
  };

  @action updateTextTypeOptions = (textTypeOptions, defaultLocale) => {
    const {
      platform: {currentLanguage}
    } = this.store;

    this.textTypeOptions = textTypeOptions.map(option => {
      const optionText =
        typeof option === 'string' ? option : option[currentLanguage] ? option[currentLanguage] : option[defaultLocale];

      const singOffOption = new CampaignTextOption();
      singOffOption.form.$('option').sync(optionText);

      return singOffOption;
    });
  };

  @action updateForm = campaign => {
    const {title, defaultLocale, question} = campaign;
    const {questionTranslations, kind, numOfRating} = question;

    this.questionForm.update({
      question: questionTranslations[defaultLocale],
      kind,
      numOfRating,
      title,
      defaultLocale
    });
  };

  @action setCampaign = campaign => {
    this.campaign = campaign;

    const {question, defaultLocale} = campaign;
    const {followUps = [], textTypeOptions = []} = question;

    this.updateForm(campaign);
    this.updateFollowUpValues(followUps, defaultLocale);
    this.updateTextTypeOptions(textTypeOptions, defaultLocale);
  };

  @action reset = () => {
    this.opened = false;
    this.questionForm.reset();
    this.followUps = [];
    this.textTypeOptions = [];
    this.isMobileView = false;
    this.campaign = null;
    this.selectedCampaign = null;
  };

  setStore = store => {
    this.store = store;
  };

  constructor() {
    makeObservable(this);
  }
}

export default CampaignPreviewDialog;
