import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkUniqueSlugDebounced} from 'utils/validation-utils';

const asyncRules = {
  checkUniqueSlug: (value, attr, key, passes) => {
    return checkUniqueSlugDebounced(value, passes);
  }
};

const plugins = dvrWithAsync(asyncRules);

const fields = [
  {
    name: 'translation',
    rules: 'required|string'
  },
  {
    name: 'slug',
    rules: 'required|string|is-slug|checkUniqueSlug'
  },
  {
    name: 'mediaId',
    rules: 'string'
  }
];

export default () => new MobxReactForm({fields}, {plugins});
