import gql from 'graphql-tag';

export const UploadIcon = gql`
  mutation createIcon($icon: IconCreate!) {
    createIcon(icon: $icon) {
      id
    }
  }
`;

export const EditIcon = gql`
  mutation editIcon($icon: IconUpdate!, $iconId: String!) {
    editIcon(icon: $icon, iconId: $iconId) {
      id
    }
  }
`;

export const ToggleIconVisiblity = gql`
  mutation toggleIconVisibility($iconId: String!) {
    toggleIconVisibility(iconId: $iconId) {
      id
    }
  }
`;

export const ToggleStepNoteNameVisiblity = gql`
  mutation toggleStepNoteLabelVisibility {
    toggleStepNoteLabelVisibility {
      result
    }
  }
`;

export const UpdateTagCreationRole = gql`
  mutation updateTagCreationRole($role: CMSRole!) {
    updateTagCreationRole(role: $role) {
      result
    }
  }
`;

export const SetNativeAppAutoRemovalInterval = gql`
  mutation setNativeAppAutoRemovalInterval($value: Int!) {
    setNativeAppAutoRemovalInterval(value: $value) {
      result
    }
  }
`;

export const UpdateAdminLoginOptions = gql`
  mutation updateAdminLoginOptions($loginOptions: [CmsLoginOption!]!) {
    updateAdminLoginOptions(loginOptions: $loginOptions) {
      result
    }
  }
`;

export const UpdateWebhookUrl = gql`
  mutation updateWebhookUrl($url: String!) {
    updateWebhookUrl(url: $url) {
      result
    }
  }
`;

export const GENERATE_TOKEN = gql`
  mutation createAccessToken {
    createAccessToken {
      jti
      token
      exp
    }
  }
`;

export const DELETE_TOKEN = gql`
  mutation revokeAccessToken($jti: String!) {
    revokeAccessToken(jti: $jti) {
      result
    }
  }
`;
