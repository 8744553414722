import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  min-height: 80px;
  margin-top: ${marginTop => (marginTop ? '150px' : '')};
`;
export const CenteredWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  text-align: center;
  .icon {
    color: #cc0000;
  }
`;
export const Message = styled.div`
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
  opacity: 0.7;
`;
