import {defineMessages} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'RolePicker.placeholder',
    defaultMessage: 'Select a role'
  },
  role: {
    id: 'EditUser.role',
    defaultMessage: 'Role'
  }
});

export default messages;
