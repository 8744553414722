import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'UploadTranslations.title',
    defaultMessage: 'Import'
  },
  upload: {
    id: 'common.actions.upload',
    defaultMessage: 'Upload'
  },
  uploadCaption: {
    id: 'UploadTranslations.uploadCaption',
    defaultMessage: 'Upload sheet (.xml)'
  },
  notSelected: {
    id: 'UploadTranslations.notSelected',
    defaultMessage: 'No file selected'
  }
});

export default messages;
