import styled from 'styled-components';

export const TranslationInProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
`;

export const SpinnerWrapper = styled.div`
  margin: 8px 0;
`;
