import gql from 'graphql-tag';

export const XmRealityTeams = gql`
  query xMRealityTeams {
    xMRealityTeams {
      id
      name
    }
  }
`;
