import styled from 'styled-components';
import Icon from 'react-fontawesome';

import {commonPalette} from 'shared/styles/palette';
import {transparentize} from 'shared/utils/color-utils';

export const GuideTitleSection = styled.div`
  color: ${transparentize(commonPalette.txBlack, 0.8)};
  background: ${commonPalette.borderGrey};
  cursor: pointer;
  padding: 12px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
`;

export const GuideInfoWrapper = styled.div`
  display: flex;
  min-width: 130px;
`;

export const InstructionsLengthWrapper = styled.div`
  margin-right: 10px;
  color: ${transparentize(commonPalette.txBlack, 0.6)};
`;

export const StyledExpandIcon = styled(Icon).attrs({
  name: 'sort-up'
})`
  margin-top: 6px;
`;

export const StyledCollapseIcon = styled(Icon).attrs({
  name: 'sort-down'
})``;

export const InstructionWrapper = styled.div`
  background: ${transparentize(commonPalette.borderGrey, 0.4)};
  margin-top: 1px;
  padding: 10px 12px;
`;

export const StyledLink = styled.a`
  color: ${transparentize(commonPalette.txBlack, 0.8)};
  padding: 2px 0;
  border-bottom: 1px solid ${transparentize(commonPalette.txBlack, 0.4)};

  &:hover {
    color: ${commonPalette.sgGreen};
    border-bottom: 1px solid ${transparentize(commonPalette.sgGreen, 0.6)};
  }
`;
