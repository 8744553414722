import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import messages from './messages';
import {mapValues, pick} from 'lodash';

//components
import Container from 'ui-components/Layout/Container';
import SmartSkillsPageHeader from 'components/SmartSkillsPageHeader';
import SkillsTab from './skillsTab';

@inject('store')
@observer
class SkillsList extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {skillsPage}
    } = this.props;

    const translations = mapValues(
      pick(messages, ['skills', 'skillProfiles', 'deletedSkill', 'errorDeletingSkill']),
      message => formatMessage(message)
    );
    skillsPage.setTranslations(translations);
  }

  render() {
    const {
      intl: {formatMessage}
    } = this.props;

    return (
      <Container style={{paddingBottom: '50px', height: 'auto'}}>
        <SmartSkillsPageHeader title={formatMessage(messages.skills)} />
        <SkillsTab />
      </Container>
    );
  }
}

export default injectIntl(SkillsList);
