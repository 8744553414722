import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ForgotPassword.title',
    defaultMessage: 'Forgot Password'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  submit: {
    id: 'common.actions.submit',
    defaultMessage: 'Submit'
  },
  requestSuccess: {
    id: 'ForgotPassword.requestSuccess',
    defaultMessage: 'You have been sent an email for password reset!'
  },
  requestFailure: {
    id: 'common.notifications.requestFailure',
    defaultMessage: 'Sorry, we could not process your request. Please try again after some time!'
  }
});

export default messages;
