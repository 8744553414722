import styled from 'styled-components';

//components
import $CloudinaryPicture from 'shared/components/CloudinaryPicture';
import FontAwesomeIcon from 'ui-components/Layout/FontAwesomeIcon';
import Icon from 'ui-components/Icon';

export const MediaPicker = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  z-index: 2;

  & > svg {
    font-size: 22px;
    z-index: 3;
    align-self: center;
    margin: 0 auto;
  }

  ${p =>
    (p.isInverted || p.focus) &&
    `
    & > svg {
      color: #FFFFFF;
      opacity: ${p.focus ? '1' : '0'};
    }
  `}

  &:hover, .sync-card-hover-with-child-component:hover & {
    ${p =>
      (p.showDarkerOverlay || p.focus) &&
      `
      & > svg {
        color: #FFFFFF;
        opacity: 1;
      }
    `}

    ${() => DarkerOverlay} {
      background: #383838;
      opacity: 0.5;
    }
  }
`;

export const BackgroundImage = styled($CloudinaryPicture)`
  position: absolute;
  z-index: -1;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)``;

export const DarkerOverlay = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  ${p =>
    p.focus &&
    `
    background: #383838;
    opacity: 0.5;
  `}
`;

export const StyledIcon = styled(Icon)`
  color: #d9d9d9;
  margin-bottom: 5px;
`;

export const StyledText = styled.p`
  color: #d9d9d9;
  font-size: 12px;
`;

export const ImageDisabledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageDisabled = styled.div`
  text-align: center;
  margin: auto;
`;
