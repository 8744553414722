import filter from 'lodash/filter';
import find from 'lodash/find';
import intersection from 'lodash/intersection';
import without from 'lodash/without';
import {action, observable, makeObservable, computed} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import enhanceWithClickOutside from 'react-click-outside';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Button from 'ui-components/Button';

//hocs
import withControlledValue from 'shared/hocs/withControlledValue';
import withViewValue from 'shared/hocs/withViewValue';

//styles
import {ButtonLabel, Combobox, StyledTwinPanelPicker} from '../../ui-components/Combobox/styles';

//messages
import messages from '../../ui-components/Combobox/messages';
import {injectIntl} from 'react-intl';
import {StyledIcon} from './styles';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';
import {uniqBy} from 'lodash';

@inject('store')
@withControlledValue
@withViewValue
@enhanceWithClickOutside
@observer
class TagPickerComponent extends Component {
  static defaultProps = {
    modelValue: Object.freeze([]),
    viewValue: Object.freeze([])
  };

  static propTypes = {
    modelValue: PropTypes.array,
    viewValue: PropTypes.array
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable shouldShowDropdown = false;
  @observable buttonXAxisCoordinate;
  @observable localSelectedTags = [];

  componentDidMount() {
    const {
      setViewValue,
      store: {
        allGuidesPage: {customFilter}
      }
    } = this.props;
    this.setLocalSelectedTags(this.options.filter(tag => customFilter.TAG.includes(tag.id)));
    setViewValue(customFilter.TAG);
  }

  @action
  setLocalSelectedTags = val => (this.localSelectedTags = val);

  @action
  hideDropdown = ({cancelChanges = false} = {}) => {
    const {
      setViewValue,
      store: {
        allGuidesPage: {customFilter},
        tagManagementPage: {onSearch, setSearchTerm}
      }
    } = this.props;

    this.shouldShowDropdown = false;
    setSearchTerm('');
    onSearch();

    if (cancelChanges) {
      this.setLocalSelectedTags(this.options.filter(tag => customFilter.TAG.includes(tag.id)));
      setViewValue(customFilter.TAG);
    }
  };

  @action
  showDropdown = e => {
    this.shouldShowDropdown = true;
    const parentButton = e.currentTarget.getBoundingClientRect();
    const buttonParentXAxis = parentButton.left;

    if (buttonParentXAxis) {
      this.buttonXAxisCoordinate = buttonParentXAxis;
    }
  };

  buttonFormatter = props => {
    const {
      store: {
        tagManagementPage: {totalCount},
        allGuidesPage: {customFilter}
      }
    } = props;

    const values = intersection(
      customFilter.TAG,
      this.options?.map(({id}) => id)
    );

    if (!values || values.length === 0) {
      return <FormattedMessage {...messages.filterAny} values={{total: totalCount}} />;
    }

    const labels = values.map(value => {
      const option = find(this.options, option => option.id === value);
      return option.name;
    });

    return <FormattedMessage {...messages.filterSelectedOptions} values={{total: labels.length}} />;
  };

  handleButtonClick = e => {
    if (this.shouldShowDropdown) {
      this.hideDropdown({cancelChanges: true});
    } else {
      this.showDropdown(e);
    }
  };

  handleClickOutside = () => {
    this.hideDropdown({cancelChanges: true});
  };

  handleCommit = () => {
    const {commitViewValue, viewValue, selectOption} = this.props;
    commitViewValue();

    selectOption('TAG', viewValue);
    this.setLocalSelectedTags(this.options.filter(tag => viewValue.includes(tag.id)));
    trackEvent(EVENT_TYPES[`CHANGE_GUIDE_TAG`], {
      ['TAG'.toLowerCase()]: viewValue.length ? viewValue.map(opt => opt).join(', ') : 'All'
    });
    this.hideDropdown();
  };

  handleDeselect = values => {
    const {setViewValue, viewValue} = this.props;
    setViewValue(without(viewValue, ...values));
    this.setLocalSelectedTags(this.localSelectedTags.filter(tag => !values.includes(tag.id)));
  };

  handleSelect = values => {
    const {setViewValue, viewValue} = this.props;
    setViewValue([...viewValue, ...values]);
    this.setLocalSelectedTags([...this.localSelectedTags, ...this.options.filter(tag => values.includes(tag.id))]);
  };

  tagLabelFormatter = (highlightedLabel, option) => {
    if (option.badge) {
      return (
        <span>
          <StyledIcon name="trophy" />
          {highlightedLabel}
        </span>
      );
    }

    return highlightedLabel;
  };

  handleServerSearch = searchTerm => {
    const {
      store: {
        tagManagementPage: {onSearch, setSearchTerm}
      }
    } = this.props;
    setSearchTerm(searchTerm);
    onSearch();
  };

  onScrollToBottom = async () => {
    const {
      store: {
        tagManagementPage: {fetchMoreTags, setTags, tags: loadedTags}
      }
    } = this.props;

    const {tags} = await fetchMoreTags();
    if (tags) {
      setTags(uniqBy([...loadedTags, ...tags], 'id'));
    }
  };

  @computed
  get options() {
    const {
      store: {
        tagManagementPage: {tags}
      }
    } = this.props;
    if (!tags.length) {
      return [];
    }
    return uniqBy(tags.map(({id, title, badge}) => ({id, name: title, badge})).concat(this.localSelectedTags), 'id');
  }

  render() {
    const {
      intl: {formatMessage},
      className,
      dataTestId,
      config,
      viewValue,
      store: {
        tagManagementPage: {searchTerm}
      }
    } = this.props;

    const selectedOptions = filter(this.options, option => viewValue.includes(option.id));

    return (
      <Combobox className={className}>
        <Button
          label={
            <ButtonLabel data-testid={dataTestId} isOpen={this.shouldShowDropdown}>
              {this.buttonLabel}
            </ButtonLabel>
          }
          onClick={this.handleButtonClick}
          underlined
        />
        {this.shouldShowDropdown && (
          <StyledTwinPanelPicker
            options={this.options}
            labelAccessor={({name}) => name}
            labelFormatter={this.tagLabelFormatter}
            onCommit={this.handleCommit}
            onDeselect={this.handleDeselect}
            onSelect={this.handleSelect}
            selectedOptions={selectedOptions}
            title={formatMessage(config.titleMessage)}
            valueAccessor={({id}) => id}
            alignPopover={'left'}
            buttonXAxisCoordinate={this.buttonXAxisCoordinate}
            onScrollToBottom={this.onScrollToBottom}
            lazyLoad
            onSearch={this.handleServerSearch}
            searchTerm={searchTerm}
          />
        )}
      </Combobox>
    );
  }

  get buttonLabel() {
    return this.buttonFormatter(this.props);
  }
}

export default injectIntl(TagPickerComponent);
