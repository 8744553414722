import {defineMessages} from 'react-intl';

const messages = defineMessages({
  themeName: {
    id: 'EditTheme.themeName',
    defaultMessage: 'Theme Name'
  },
  logo: {
    id: 'EditTheme.logo',
    defaultMessage: 'Platform Logo'
  },
  logoExample: {
    id: 'EditTheme.logoExample',
    defaultMessage: 'Example of a platform logo'
  },
  changeLogo: {
    id: 'EditTheme.changeLogo',
    defaultMessage: 'Change platform logo'
  },
  favicon: {
    id: 'EditTheme.favicon',
    defaultMessage: 'App icon'
  },
  faviconExample: {
    id: 'EditTheme.faviconExample',
    defaultMessage: 'Example of app icon'
  },
  changeFavicon: {
    id: 'EditTheme.changeFavicon',
    defaultMessage: 'Change app icon'
  },
  showExample: {
    id: 'EditTheme.showExample',
    defaultMessage: 'Show example'
  },
  selectTeam: {
    id: 'AddWorkspaceTeams.selectTeam',
    defaultMessage: 'Select a Team'
  },
  selectThemeOwner: {
    id: 'EditTheme.selectThemeOwner',
    defaultMessage: 'Select Theme Owner'
  }
});

export default messages;
