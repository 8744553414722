import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Container from 'ui-components/Layout/Container';
import StepsTabs from 'components/StepsTabs';
import {injectIntl} from 'react-intl';
import messages from './messages';
import {mapValues, pick} from 'lodash';
import SmartSkillsPageHeader from 'components/SmartSkillsPageHeader';

@inject('store')
@observer
class SmartSkillsTeams extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {smartSkillsTeamsPage}
    } = this.props;

    const translations = mapValues(pick(messages, ['gapOverview', 'levelsOverview']), message =>
      formatMessage(message)
    );
    smartSkillsTeamsPage.setTranslations(translations);
  }

  render() {
    const {
      intl: {formatMessage},
      store: {
        smartSkillsTeamsPage,
        smartSkillsTeamsPage: {stepsStore}
      }
    } = this.props;

    return (
      <Container style={{paddingBottom: '50px', height: 'auto'}}>
        <SmartSkillsPageHeader title={formatMessage(messages.smartSkills)} />

        <StepsTabs
          mode={'edit'}
          loading={false}
          error={null}
          stepsStore={stepsStore}
          page={smartSkillsTeamsPage}
          hideButtons
          fullHeight
        />
      </Container>
    );
  }
}

export default injectIntl(SmartSkillsTeams);
