import {generateInstructions} from './instructions';

import {colors, pageBreak} from '../constants';

export const generateTopic = async (topic, pdfMessages, guide, guideBaseUrl, intl) => {
  const {title = ' ', instructions} = topic;
  const isTopicHidden = title.trim().length === 0 || !topic.visible;

  const instructionsContent = await generateInstructions(
    instructions,
    topic,
    pdfMessages,
    {guide, topic},
    guideBaseUrl,
    intl
  );

  let topicContent;

  if (instructionsContent) {
    topicContent = [
      {
        text: title,
        color: colors.WHITE,
        tocItem: !isTopicHidden,
        tocStyle: {color: colors.GRAY, fontSize: 11},
        tocMargin: [10, 13, 0, 0],
        fontSize: 12,
        pageBreak: pageBreak.BEFORE
      },
      ...instructionsContent
    ];
  }

  return topicContent;
};
