import React from 'react';
import {DragDropContextProvider} from 'react-dnd';
import ReactDOM from 'react-dom';
import {SENTRY_RELEASE, SENTRY_DSN_CMS, GIT_COMMIT_SHA} from 'shared/constants';
import {browserDetect} from 'shared/utils/generic-utils';

// The mouse back-end is used instead of the standard HTML5 one
// because of the Firefox stochastically failing to capture drag
// events. This bug was seemingly fixed before, though people are still
// experiencing it (including us) on Linux:
//
// https://bugzilla.mozilla.org/show_bug.cgi?id=1452131
//
// When Firefox fixes the bug, we might want to revert back to
// using the HTML5 backend.
import MouseBackend from 'react-dnd-mouse-backend';

//raven
import Raven from 'raven-js';

Raven.config(SENTRY_DSN_CMS || '', {
  release: SENTRY_RELEASE,
  tags: {git_commit: GIT_COMMIT_SHA}
}).install();

//validations
import 'config/validator-config';

//style
import 'normalize.css';
import 'styles/css/index.css';
import 'styles/css/overrides.css';
import 'font-awesome/css/font-awesome.css';
import 'shared/styles/css/lato.css';

//mobx
import store from 'stores/store';
import {Provider} from 'mobx-react';

//router
import {startRouter} from 'mobx-router';
import views from 'config/views';
import {normalizeCurrentUrl} from 'shared/utils/url-utils';

//apollo
import {ApolloProvider} from '@apollo/client';
import {client} from 'utils/apollo-client';

//components
import App from 'components/App';
import {GlobalStyles} from './ui-components';

normalizeCurrentUrl();
startRouter(views, store, {
  // https://github.com/flatiron/director#configuration
  strict: false
});

if (browserDetect.isIE11) {
  document.documentElement.className += ' is-ie-11';
}

const subdomain = window.location.hostname.split('.')[0];
if (subdomain.includes('-')) {
  const platform = subdomain.split('-')[1];
  const platformUrl = window.location.href.replace(subdomain, platform);
  window.location.replace(platformUrl);
}
// This will clear all existing awsalb cookies out there #2431
// TODO: remove this let's say in 2 months
document.cookie = 'AWSALB=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <DragDropContextProvider backend={MouseBackend}>
        <>
          <App />
          <GlobalStyles />
        </>
      </DragDropContextProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);
