import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import invoke from 'lodash/invoke';
import {injectIntl} from 'react-intl';
import {Tabs} from 'antd';

const {TabPane} = Tabs;

//messages
import messages from './messages';

// styles
import {StepsTabsWrapper, StyledTabs} from './styles';
import ButtonGroup from 'ui-components/ButtonGroup';

const getButtonGroupConfig = ({
  isCreateMode,
  isEditMode,
  isViewMode,
  store: {
    newUserPage: {form}
  },
  canSubmit,
  omitPrimaryButton,
  showSaveButton,
  showCancelButton,
  disableNextButton,
  currentStep,
  formSubmit,
  nextTab,
  previousTab,
  cancel,
  customSecondaryButtonText,
  customPrimaryButtonText,
  formatMessage,
  currentStepSpecificConfig
}) => {
  const {password, confirmPassword} = form.values();

  const noCurrentStep = !currentStep;
  const currentStepFormIsNotValid = currentStep.form ? !currentStep.form?.isValid : false;
  const passwordsDontMatchInCreateMode = isCreateMode && password !== confirmPassword;

  const isPrimaryButtonDisabled = [
    noCurrentStep,
    currentStepFormIsNotValid,
    passwordsDontMatchInCreateMode,
    disableNextButton
  ].some(Boolean);

  const possiblyOverridenIsPrimaryButtonDisabled = showSaveButton ? !canSubmit : isPrimaryButtonDisabled;

  const {primaryButtonOverrideConfig} = currentStep;

  const shouldApplyPrimaryButtonOverride = Boolean(
    primaryButtonOverrideConfig && !currentStepSpecificConfig.shouldHideOverridenPrimaryButton
  );

  const shouldDisablePrimaryButtonOverride = Boolean(
    primaryButtonOverrideConfig && currentStepSpecificConfig.shouldDisableOverridenPrimaryButton
  );

  const primaryButtonOverride = shouldApplyPrimaryButtonOverride && {
    primary: {
      label: formatMessage(messages[primaryButtonOverrideConfig.labelL10nKey]),
      onClick: primaryButtonOverrideConfig.onClick,
      disabled: shouldDisablePrimaryButtonOverride
    }
  };

  if (isViewMode || omitPrimaryButton) {
    return {
      secondary: {
        label: formatMessage(customSecondaryButtonText ? customSecondaryButtonText : messages.cancel),
        onClick: cancel,
        disabled: disableNextButton
      },
      ...primaryButtonOverride
    };
  }

  return {
    primary: {
      label: customPrimaryButtonText
        ? customPrimaryButtonText
        : showSaveButton
        ? formatMessage(messages.save)
        : formatMessage(messages.next),
      onClick: showSaveButton || isEditMode ? formSubmit : nextTab,
      disabled: possiblyOverridenIsPrimaryButtonDisabled
    },
    secondary: {
      label: showCancelButton ? formatMessage(messages.cancel) : formatMessage(messages.back),
      onClick: showCancelButton || isEditMode ? cancel : previousTab,
      disabled: disableNextButton
    },
    ...primaryButtonOverride
  };
};

@inject('store')
@observer
class StepsTabs extends Component {
  static defaultProps = {
    canSubmit: true
  };

  render() {
    const {
      mode,
      canSubmit,
      omitPrimaryButton,
      hideButtons,
      page: {showSaveButton, showCancelButton, translations, disableNextButton},
      stepsStore: {steps, currentStep, currentStepIndex, nextTab, goToTab, previousTab},
      store,
      cancel,
      formSubmit,
      customSecondaryButtonText,
      customPrimaryButtonText,
      intl: {formatMessage},
      fullHeight
    } = this.props;
    const isCreateMode = mode === 'create';
    const isEditMode = mode === 'edit';
    const isViewMode = mode === 'view';

    const currentStepSpecificConfig = invoke(this.props, 'getCurrentStepSpecificConfig', currentStep) || {};

    const buttonGroupConfig = getButtonGroupConfig({
      isCreateMode,
      isEditMode,
      isViewMode,
      store,
      canSubmit,
      omitPrimaryButton,
      showSaveButton,
      showCancelButton,
      disableNextButton,
      currentStep,
      formSubmit,
      nextTab,
      previousTab,
      cancel,
      customSecondaryButtonText,
      customPrimaryButtonText,
      formatMessage,
      currentStepSpecificConfig
    });

    return (
      <StepsTabsWrapper fullHeight={fullHeight}>
        <StyledTabs
          activeKey={currentStepIndex.toString()}
          defaultActiveKey="0"
          onChange={activeKey => goToTab(parseInt(activeKey))}
        >
          {steps.map((step, index) => {
            const stepNumber = index + 1;

            let tabTitle =
              isCreateMode && step.checked && (step.form ? step.form.isValid : true)
                ? `✓ ${translations[step.key]}`
                : `${stepNumber}. ${translations[step.key]}`;

            if (isEditMode) {
              tabTitle = translations[step.key];
            }

            return (
              <TabPane
                disabled={isCreateMode ? (index < parseInt(currentStepIndex) ? false : !steps[index].checked) : false}
                tab={tabTitle}
                key={index}
              >
                {step.component}
              </TabPane>
            );
          })}
        </StyledTabs>
        {!hideButtons && <ButtonGroup bottomFixed {...buttonGroupConfig} />}
      </StepsTabsWrapper>
    );
  }
}

export default injectIntl(StepsTabs);
