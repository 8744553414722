import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';
import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';

//styled-components
import Select from 'ui-components/Select';

//messages
import messages from './messages';

@inject('store')
@observer
class CampaignQuestionKindPickerComponent extends Component {
  questionKindChange = value => {
    const {form, field, store} = this.props;
    const {newCampaignPage, editCampaignPage} = store;
    const defaultRatings = {
      [CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO]: 1,
      [CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL]: 1,
      [CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT]: 2,
      [CAMPAIGN_QUESTION_TYPE_ENUMS.STAR]: 3,
      [CAMPAIGN_QUESTION_TYPE_ENUMS.NUMBER]: 5
    };

    if (value) {
      form.$(field).sync(value);
    }

    const rating = defaultRatings[value] || null;

    newCampaignPage.questionForm.$('numOfRating').sync(rating);
    editCampaignPage.questionForm.$('numOfRating').sync(rating);

    newCampaignPage.setFollowUps([]);
    editCampaignPage.setFollowUps([]);
  };

  render() {
    const {
      intl: {formatMessage},
      form,
      field,
      label,
      disabled,
      className,
      allowClear
    } = this.props;

    const questionKinds = [
      {id: CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO, name: formatMessage(messages.yesno)},
      {id: CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL, name: formatMessage(messages.passFail)},
      {id: CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT, name: formatMessage(messages.text)},
      {id: CAMPAIGN_QUESTION_TYPE_ENUMS.NUMBER, name: formatMessage(messages.number)},
      {id: CAMPAIGN_QUESTION_TYPE_ENUMS.STAR, name: formatMessage(messages.star)}
    ];

    const selectedValue = form.$(field).value;

    return (
      <Select
        label={label || formatMessage(messages.answerType)}
        className={className}
        placeholder={formatMessage(messages.placeholder)}
        allowClear={allowClear}
        disabled={disabled}
        options={questionKinds}
        selectedValue={selectedValue}
        onChange={this.questionKindChange}
        dataCy="campaign-question-kind-picker"
      />
    );
  }
}

export default injectIntl(CampaignQuestionKindPickerComponent);
