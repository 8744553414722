import {defineMessages} from 'react-intl';

const messages = defineMessages({
  chooseLanguage: {
    id: 'EditCampaign.languageSelect',
    defaultMessage: 'Choose language'
  },
  translationPercentage: {
    id: 'translationPercentage',
    defaultMessage: '{percentage}% translated'
  },
  addNewLanguage: {
    id: 'EditTranslation.addNewLanguage',
    defaultMessage: 'Add new language'
  },
  translateGuideInto: {
    id: 'translateGuideInto',
    defaultMessage: 'Choose the language you want to translate your Guide into.'
  }
});

export default messages;
