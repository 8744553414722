import {defineMessages} from 'react-intl';

const messages = defineMessages({
  alreadyLoggedIn: {
    id: 'Login.alreadyLoggedIn',
    defaultMessage: "You're already logged in."
  },
  byUsingOurService: {
    id: 'Login.byUsingOurService',
    defaultMessage: 'By using our service, you agree with our {termsLink}'
  },
  termsAndConditionsAndPrivacyPolicy: {
    id: 'Login.termsAndConditionsAndPrivacyPolicy',
    defaultMessage: 'Terms & Conditions and Privacy policy'
  },
  troubleLoggingIn: {
    id: 'Login.troubleLoggingIn',
    defaultMessage: 'Trouble logging in? Check {troubleshootingLink}'
  },
  troubleLoggingInHere: {
    id: 'Login.troubleLoggingInHere',
    defaultMessage: 'here'
  }
});

export default messages;
