import messages from './messages';
import {
  checkEventTypes,
  checklistEventTypes,
  guideEventTypes,
  instructionEventTypes,
  stepEventTypes,
  topicEventTypes
} from './types';

const getEventMessage = (eventType: string, v2Field: boolean) => {
  if (v2Field && eventConfigV2[eventType]) {
    return eventConfigV2[eventType];
  }
  if (eventConfigV1[eventType]) {
    return eventConfigV1[eventType];
  }
  return messages.unknownEvent;
};

export const getEventConfig = (eventType: string, v2Field: boolean, component?: (props: any) => React.JSX.Element) => {
  return {
    ...getEventMessage(eventType, v2Field),
    LinkComponent: component || undefined
  };
};
const eventConfigV1 = {
  step_content_updated: {
    message: messages.updatedStep
  },
  step_note_content_updated: {
    message: messages.updatedStepNote
  },
  step_archived: {message: messages.removedStep},
  step_duplicated: {
    message: messages.duplicatedStep
  },
  step_visual_updated: {
    message: messages.updatedMedia
  },
  step_note_visual_updated: {
    message: messages.updatedNoteMedia
  },
  step_created: {
    message: messages.createdStep
  },
  step_removed: {
    message: messages.removedStep
  },
  instruction_steps_repositioned: {
    message: messages.repositionedStep
  },
  check_content_updated: {
    message: messages.updatedCheck
  },
  check_duplicated: {
    message: messages.duplicatedCheck
  },
  check_visual_updated: {
    message: messages.updatedMediaCheck
  },
  check_archived: {message: messages.removedCheck},
  check_created: {
    message: messages.createdCheck
  },
  checklist_checks_repositioned: {
    message: messages.repositionedCheck
  },
  check_removed: {
    message: messages.removedCheck
  },
  instruction_content_updated: {message: messages.updatedInstructionTitle},
  instruction_set_to_review: {message: messages.setInstructionToReview},
  instruction_set_to_draft: {
    message: messages.setInstructionToDraft
  },
  instruction_archived: {message: messages.archivedInstruction},
  instruction_published: {
    message: messages.publishedInstruction
  },
  instruction_cloned: {
    message: messages.clonedInstruction
  },
  instruction_moved: {message: messages.movedInstruction},
  instruction_created: {
    message: messages.createdInstruction
  },
  instruction_sign_off_enabled: {
    message: messages.signOffEnabled
  },
  instruction_sign_off_disabled: {
    message: messages.signOffDisabled
  },
  instruction_moved_to_topic: {
    message: messages.movedInstructionOutsideTopic
  },
  instruction_moved_to: {
    message: messages.instructionMovedToGuide
  },
  instruction_moved_from: {
    message: messages.instructionMovedFromGuide
  },
  instruction_visual_updated: {
    message: messages.updatedMediaInstruction
  },
  instruction_tags_added: {
    message: messages.instructionTagsAddedGuide
  },
  instruction_tags_removed: {
    message: messages.instructionTagsRemovedGuide
  },
  checklist_content_updated: {message: messages.updatedChecklistTitle},
  checklist_set_to_review: {message: messages.setChecklistToReview},
  checklist_set_to_draft: {
    message: messages.setChecklistToDraft
  },
  checklist_archived: {
    message: messages.archivedChecklist
  },
  checklist_published: {
    message: messages.publishedChecklist
  },
  checklist_cloned: {
    message: messages.clonedChecklist
  },
  checklist_moved: {message: messages.movedChecklist},
  checklist_created: {
    message: messages.createdChecklist
  },
  checklist_moved_to_topic: {
    message: messages.movedChecklistOutsideTopic
  },
  checklist_visual_updated: {
    message: messages.checklistVisualUpdatedGuide
  },
  checklist_moved_to: {
    message: messages.checklistMovedToGuide
  },
  checklist_moved_from: {
    message: messages.checklistMovedFromGuide
  },
  checklist_tags_added: {
    message: messages.checklistTagsAddedGuide
  },
  checklist_tags_removed: {
    message: messages.checklistTagsRemovedGuide
  },
  checklist_require_signature: {message: messages.checklistRequireSignature},
  checklist_remove_signature: {message: messages.checklistRemoveSignature},
  topic_content_updated: {message: messages.updatedTopic},
  topic_removed: {message: messages.removedTopic},
  topic_moved: {message: messages.topicMovedToGuide},
  topic_moved_to: {message: messages.topicMovedToGuide},
  topic_moved_from: {
    message: messages.topicMovedFromGuide
  },
  topic_created: {message: messages.createdTopic},
  guide_topics_repositioned: {message: messages.repositionedTopic},
  topic_settings_updated: {
    message: messages.updatedTopicSettings
  },
  guide_set_to_draft: {message: messages.setGuideToDraft},
  guide_set_to_review: {message: messages.setGuideToReview},
  guide_documents_repositioned: {
    message: messages.repositionedGuide
  },
  guide_archived: {message: messages.archivedGuide},
  guide_cloned: {message: messages.clonedGuide},
  guide_approval_requested: {message: messages.approvalRequested},
  guide_approval_withdrawn: {message: messages.approvalWithdrawnV2},
  guide_approval_approved: {message: messages.approvalReviewApproved},
  guide_approval_rejected: {message: messages.approvalReviewRejectedV2},
  guide_live_version_rejected: {message: messages.liveReviewRejectedV2},
  guide_published: {message: messages.publishedGuideV2},
  guide_content_updated: {message: messages.updatedGuideTitle},
  guide_commented: {message: messages.commented},
  guide_reassigned: {message: messages.assignedToV2},
  guide_created: {message: messages.createdDocument},
  guide_live_version_approved: {message: messages.liveReviewApprovedV2},
  guide_approval_cancelled: {message: messages.approvalReviewCancelled},
  guide_checklists_repositioned: {
    message: messages.repositionedChecklist
  },
  guide_version_restored_as_draft: {message: messages.restoredVersionAsDraft},
  guide_republished: {message: messages.republishedGuide},
  guide_newer_version_published: {message: messages.newerVersionPublished},
  guide_visual_updated: {message: messages.updatedMediaGuide},
  guide_translations_updated: {message: messages.updatedTranslations},
  guide_translations_added: {message: messages.addedTranslations},
  guide_draft_auto_created: {message: messages.draftAutoCreatedV2},
  guide_default_language_updated: {message: messages.updatedDefaultLanguage},
  guide_tags_added: {message: messages.guideTagsAdded},
  guide_tags_removed: {message: messages.guideTagsRemoved},
  guide_workspaces_updated: {message: messages.updatedGuideWorkspaces},
  guide_approval_partially_approved: {message: messages.approvalPartiallyApproved},
  guide_approval_approved_and_published: {message: messages.approvalApprovedAndPublishedBy},
  checklist_alert_recipients_added: {message: messages.checklistAlertRecipientsAdded},
  checklist_alert_recipients_removed: {message: messages.checklistAlertRecipientsRemoved}
};

const eventConfigV2 = {
  step_content_updated: {
    message: messages.updatedStepInInstruction
  },
  step_note_content_updated: {
    message: messages.updatedStepNoteV2
  },
  step_archived: {message: messages.deletedStepInInstruction},
  step_duplicated: {
    message: messages.duplicatedStepInInstruction
  },
  step_visual_updated: {
    message: messages.updatedVisualForStepInInstruction
  },
  step_note_visual_updated: {
    message: messages.updatedNoteMediaV2
  },
  step_created: {
    message: messages.createdStepInInstruction
  },
  instruction_steps_repositioned: {
    message: messages.stepOrderNumberUpdate
  },
  check_content_updated: {
    message: messages.updatedCheckInChecklist
  },
  check_duplicated: {
    message: messages.duplicatedCheckInChecklist
  },
  check_visual_updated: {
    message: messages.updatedVisualForCheckInChecklist
  },

  check_archived: {message: messages.deletedCheckInChecklist},

  check_created: {
    message: messages.createdCheckInChecklist
  },
  checklist_checks_repositioned: {
    message: messages.checkOrderNumberUpdate
  },
  instruction_set_to_draft: {
    message: messages.hiddenInstructionV2
  },
  instruction_archived: {message: messages.deletedInstructionV2},
  instruction_published: {
    message: messages.shownInstructionV2
  },
  instruction_cloned: {
    message: messages.clonedInstructionV2
  },
  instruction_created: {
    message: messages.newInstructionInTopic
  },
  instruction_sign_off_enabled: {
    message: messages.enabledSignOffV2
  },
  instruction_sign_off_disabled: {
    message: messages.disabledSignOffV2
  },
  instruction_moved_to_topic: {
    message: messages.movedInstructionToTopicV2
  },
  instruction_moved_to: {
    message: messages.instructionMovedToGuideV2
  },
  instruction_moved_from: {
    message: messages.instructionMovedFromGuideV2
  },
  instruction_visual_updated: {
    message: messages.updatedMediaInInstructionV2
  },
  instruction_tags_added: {
    message: messages.addedTags
  },
  instruction_tags_removed: {
    message: messages.removedTags
  },
  checklist_set_to_draft: {
    message: messages.hiddenChecklistV2
  },
  checklist_archived: {
    message: messages.deletedChecklistV2
  },
  checklist_published: {
    message: messages.shownChecklistV2
  },
  checklist_cloned: {
    message: messages.clonedChecklistV2
  },
  checklist_created: {
    message: messages.newChecklistInTopic
  },
  checklist_moved_to_topic: {
    message: messages.movedChecklistToTopicV2
  },
  checklist_visual_updated: {
    message: messages.updatedMediaInChecklistV2
  },
  checklist_moved_to: {
    message: messages.checklistMovedToGuideV2
  },
  checklist_moved_from: {
    message: messages.checklistMovedFromGuideV2
  },
  checklist_tags_added: {
    message: messages.addedTagsChecklist
  },
  checklist_tags_removed: {
    message: messages.removedTagsChecklist
  },
  topic_removed: {message: messages.removedTopicV2},
  topic_moved: {message: messages.topicMovedToGuideV2},
  topic_moved_to: {message: messages.topicMovedToGuideV2},
  topic_moved_from: {
    message: messages.topicMovedFromGuideV2
  },
  guide_topics_repositioned: {message: messages.repositionedTopicV2},
  topic_settings_updated: {
    message: messages.updatedTopicSettingsV2
  },
  guide_documents_repositioned: {
    message: messages.repositionedInstructionV2
  },
  guide_cloned: {message: messages.clonedGuideFromGuide},
  guide_approval_requested: {message: messages.approvalRequestedV2},
  guide_checklists_repositioned: {
    message: messages.repositionedChecklistV2
  },
  guide_draft_auto_created: {message: messages.draftAutoCreated}
};

export const isInstructionEvent = (eventType: string) =>
  instructionEventTypes.includes(eventType as typeof instructionEventTypes[number]);

export const isChecklistEvent = (eventType: string) =>
  checklistEventTypes.includes(eventType as typeof checklistEventTypes[number]);

export const isTopicEvent = (eventType: string) =>
  topicEventTypes.includes(eventType as typeof topicEventTypes[number]);

export const isStepEvent = (eventType: string) => stepEventTypes.includes(eventType as typeof stepEventTypes[number]);

export const isCheckEvent = (eventType: string) =>
  checkEventTypes.includes(eventType as typeof checkEventTypes[number]);

export const isGuideEvent = (eventType: string) =>
  guideEventTypes.includes(eventType as typeof guideEventTypes[number]);

export const isCreationEvent = (eventType: string) => {
  return (
    eventType === 'step_created' ||
    eventType === 'check_created' ||
    eventType === 'instruction_created' ||
    eventType === 'checklist_created' ||
    eventType === 'step_note_content_updated' ||
    eventType === 'topic_created'
  );
};

export const isUpdateEvent = (eventType: string) => {
  return (
    eventType === 'step_content_updated' ||
    eventType === 'check_content_updated' ||
    eventType === 'instruction_content_updated' ||
    eventType === 'checklist_content_updated' ||
    eventType === 'step_note_content_updated' ||
    eventType === 'topic_content_updated' ||
    eventType === 'guide_content_updated'
  );
};

export const isMediaUpdateEvent = (eventType: string) => {
  return (
    eventType === 'step_visual_updated' ||
    eventType === 'check_visual_updated' ||
    eventType === 'instruction_visual_updated' ||
    eventType === 'checklist_visual_updated' ||
    eventType === 'step_note_visual_updated' ||
    eventType === 'guide_visual_updated'
  );
};

export const shouldGetInstructionLink = (eventType: string) => {
  return (
    eventType === 'instruction_content_updated' ||
    eventType === 'instruction_set_to_review' ||
    eventType === 'instruction_set_to_draft' ||
    eventType === 'instruction_published' ||
    eventType === 'instruction_cloned' ||
    eventType === 'instruction_moved' ||
    eventType === 'instruction_created' ||
    eventType === 'instruction_sign_off_enabled' ||
    eventType === 'instruction_sign_off_disabled' ||
    eventType === 'instruction_moved_to_topic' ||
    eventType === 'instruction_moved_from' ||
    eventType === 'instruction_visual_updated' ||
    eventType === 'instruction_tags_added' ||
    eventType === 'instruction_tags_removed' ||
    eventType === 'checklist_content_updated' ||
    eventType === 'checklist_set_to_review' ||
    eventType === 'checklist_set_to_draft' ||
    eventType === 'checklist_published' ||
    eventType === 'checklist_cloned' ||
    eventType === 'checklist_moved' ||
    eventType === 'checklist_created' ||
    eventType === 'checklist_moved_to_topic' ||
    eventType === 'checklist_visual_updated' ||
    eventType === 'checklist_moved_from' ||
    eventType === 'checklist_tags_added' ||
    eventType === 'checklist_tags_removed'
  );
};

export const shouldGetTopicLink = (eventType: string) => {
  return (
    eventType === 'topic_content_updated' ||
    eventType === 'topic_moved' ||
    eventType === 'topic_moved_from' ||
    eventType === 'topic_created' ||
    eventType === 'topic_settings_updated'
  );
};

export const shouldGetStepLink = (eventType: string) => {
  return (
    eventType === 'step_content_updated' ||
    eventType === 'step_visual_updated' ||
    eventType === 'step_created' ||
    eventType === 'step_duplicated' ||
    eventType === 'step_note_content_updated' ||
    eventType === 'step_note_visual_updated' ||
    eventType === 'instruction_steps_repositioned' ||
    eventType === 'check_content_updated' ||
    eventType === 'check_visual_updated' ||
    eventType === 'check_created' ||
    eventType === 'check_duplicated' ||
    eventType === 'checklist_checks_repositioned'
  );
};

export const shouldShowViewVersionLink = (eventType: string) => {
  return (
    eventType === 'guide_published' ||
    eventType === 'guide_republished' ||
    eventType === 'guide_approval_approved_and_published'
  );
};

export const getExtraFieldForGuideEventType = (eventType: string) => {
  switch (eventType) {
    case 'guide_topics_repositioned':
    case 'topic_settings_updated': {
      return 'topicTitle';
    }
    case 'guide_documents_repositioned':
    case 'guide_checklists_repositioned': {
      return 'instructionTitle';
    }
    case 'guide_cloned': {
      return 'parentGuide';
    }
    case 'guide_approval_requested': {
      return 'reviewers';
    }
    case 'guide_draft_auto_created': {
      return 'beforeVersion';
    }
    default: {
      return null;
    }
  }
};

export const incrementNumber = (number: number | null | undefined) => {
  if (number === null || number === undefined) {
    return null;
  }
  return number + 1;
};
