import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {pick, mapValues} from 'lodash';

import Dialog from 'ui-components/Dialog';

import {RemoveCampaign} from 'api/campaign/mutations';
import {removeCampaignOptions} from 'api/campaign/mutation-options';

import {commonPalette} from 'shared/styles/palette';

import messages from './messages';

@inject('store')
@graphql(RemoveCampaign, removeCampaignOptions)
@observer
class RemoveCampaignDialog extends Component {
  constructor(props) {
    super(props);
    this.onRemove = this.onRemove.bind(this);
  }

  onRemove() {
    const {
      store: {
        removeCampaignDialog: {submit},
        applyCampaignDialog: {reset: resetApplyCampaignDialog}
      },
      removeCampaignFromInstructionsMutation
    } = this.props;

    submit(removeCampaignFromInstructionsMutation);
    resetApplyCampaignDialog();
  }

  componentDidMount = () => {
    const {
      store: {removeCampaignDialog},
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(
      pick(messages, [
        'campaignRemoveSuccess',
        'campaignRemoveError',
        'campaignsRemoveSuccess',
        'campaignsRemoveError'
      ]),
      message => formatMessage(message)
    );

    removeCampaignDialog.setTranslations(translations);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        removeCampaignDialog: {opened, close},
        applyCampaignDialog: {appliedFromInstruction}
      }
    } = this.props;

    const title = appliedFromInstruction
      ? formatMessage(messages.removeCampaignFromAInstruction)
      : formatMessage(messages.removeCampaignFromAllInstructions);
    const subtitle = appliedFromInstruction
      ? formatMessage(messages.subtitleRemoveCampaignAInstruction)
      : formatMessage(messages.subtitleRemoveCampaignAllInstructions);

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.remove),
            onClick: this.onRemove,
            disabled: false,
            style: {backgroundColor: commonPalette.red}
          }
        ]}
        isOpen={Boolean(opened)}
        title={title}
        onCancel={close}
        dialogStyle={{width: 900}}
      >
        <div>{subtitle}</div>
      </Dialog>
    );
  }
}

export default injectIntl(RemoveCampaignDialog);
