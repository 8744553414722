import {action, observable, computed, makeObservable} from 'mobx';
import {SHAPE_ENUMS} from 'shared/enums';
import Konva from 'konva';
import uuidv4 from 'uuid/v4';
import store from 'stores/store';

//lodash
import find from 'lodash/find';
import toInteger from 'lodash/toInteger';

//helpers
import {getShapeDataBasedOnContainer, extraHitSelection} from 'shared/utils/overlay-shapes-utils';

class MediaOverlayDialog {
  @observable selectedShape = null;

  //toolbar observables
  @observable toolbarColor = '#ff0000';
  @observable toolbarShape = null;
  @observable toolbarThickness = 2;
  @observable showToolbarColorPicker = false;

  //table observables
  @observable shouldCollapse = false;
  @observable showTableColorPicker = false;

  //canvas observables
  @observable iFrameElement = null;
  @observable konvaStage = null;
  @observable konvaLayer = null;
  @observable shapeInstance = null;
  @observable isDragging = false;
  @observable startX = 0;
  @observable startY = 0;

  constructor() {
    makeObservable(this);
  }

  //computed values
  @computed get overlayShapes() {
    return store.mediaPickerDialog.overlayShapes;
  }

  @computed get selectedShapePosition() {
    return this.overlayShapes.indexOf(this.selectedShape);
  }

  @computed get selectedColor() {
    return this.selectedShape ? this.selectedShape.color : this.toolbarColor;
  }

  @computed get selectedThickness() {
    return this.selectedShape ? toInteger(this.selectedShape.thickness) : toInteger(this.toolbarThickness);
  }

  @computed get selectedShapeId() {
    return this.selectedShape ? this.selectedShape.id : null;
  }

  @computed get editMode() {
    return this.selectedShape ? true : false;
  }

  @computed get selectedShapeEnum() {
    return this.selectedShape ? null : this.toolbarShape;
  }

  @computed get draggable() {
    return !this.toolbarShape;
  }

  @computed get width() {
    return this.iFrameElement.offsetWidth;
  }

  @computed get height() {
    return this.iFrameElement.offsetHeight;
  }

  @computed get shapeInstanceType() {
    if (this.shapeInstance instanceof Konva.Arrow) return SHAPE_ENUMS.ARROW;
    if (this.shapeInstance instanceof Konva.Rect) return SHAPE_ENUMS.RECT;
    if (this.shapeInstance instanceof Konva.Line) return SHAPE_ENUMS.LINE;
    if (this.shapeInstance instanceof Konva.Ellipse) return SHAPE_ENUMS.ELLIPSE;
    return null;
  }

  @computed get shapeOnCanvas() {
    return this.selectedShape ? this.konvaLayer.findOne(node => node.attrs.id === this.selectedShape.id) : null;
  }

  @computed get shapeOnCanvasType() {
    if (this.shapeOnCanvas instanceof Konva.Arrow) return SHAPE_ENUMS.ARROW;
    if (this.shapeOnCanvas instanceof Konva.Rect) return SHAPE_ENUMS.RECT;
    if (this.shapeOnCanvas instanceof Konva.Line) return SHAPE_ENUMS.LINE;
    if (this.shapeOnCanvas instanceof Konva.Ellipse) return SHAPE_ENUMS.ELLIPSE;
    return null;
  }

  @computed get shapeDirection() {
    if (this.shapeOnCanvasType === SHAPE_ENUMS.ARROW || this.shapeOnCanvasType === SHAPE_ENUMS.LINE) {
      const {points} = this.shapeOnCanvas.attrs;
      if (points[0] <= points[2]) {
        return points[1] <= points[3] ? 'top-left' : 'bottom-left';
      } else {
        return points[1] <= points[3] ? 'top-right' : 'bottom-right';
      }
    }
    return null;
  }

  @computed get transformer() {
    return new Konva.Transformer({
      node: this.shapeOnCanvas,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      keepRatio: false,
      anchorSize: 8,
      anchorStroke: 'grey',
      anchorCornerRadius: 10,
      borderDash: [2, 2],
      borderStroke: 'white',
      padding: 4,
      rotateEnabled: false,
      ignoreStroke: true,
      boundBoxFunc: (oldBox, newBox) => {
        if (this.shapeOnCanvasType === SHAPE_ENUMS.RECT || this.shapeOnCanvasType === SHAPE_ENUMS.ELLIPSE) {
          if (newBox.width < 20) newBox.width = 20;
          if (newBox.height < 20) newBox.height = 20;
        } else {
          if (newBox.width === 0 || !newBox.width) newBox.width = 1;
          if (newBox.height === 0 || !newBox.height) newBox.height = 1;
        }
        return newBox;
      }
    });
  }

  //table actions
  @action setSelectedShape = shape => {
    this.konvaStage.find('Transformer').destroy();

    if (!shape) {
      this.selectedShape = null;
      this.konvaLayer.draw();
      return;
    }

    this.selectedShape = shape;
    this.konvaLayer.add(this.transformer);
    this.konvaLayer.draw();
  };

  @action setShouldCollapse = shouldCollapse => {
    this.shouldCollapse = shouldCollapse;
    this.showTableColorPicker = false;
    this.showToolbarColorPicker = false;
  };

  @action toggleShowTableColorPicker = () => {
    this.showTableColorPicker = !this.showTableColorPicker;
  };

  //toolbar actions
  @action toggleShowToolbarColorPicker = () => {
    this.showToolbarColorPicker = !this.showToolbarColorPicker;
  };

  @action setToolbarColor = color => {
    if (color.rgb.a !== 1) this.toolbarColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    else this.toolbarColor = color.hex.toUpperCase();
  };

  @action setToolbarThickness = thickness => {
    this.toolbarThickness = thickness;
  };

  @action setToolbarShape = shape => {
    if (this.toolbarShape === shape || !shape) {
      this.konvaStage.container().style.cursor = 'default';
      this.toolbarShape = null;
      this.updateKonva();
    } else {
      this.toolbarShape = shape;
      this.selectedShape = null;
      this.updateKonva();
    }
  };

  //canvas actions
  @action setIFrameElement = iFrameElement => {
    this.iFrameElement = iFrameElement;
  };

  @action setupKonva = selectedMedia => {
    if (this.iFrameElement.offsetHeight === 0 || !selectedMedia) return;

    this.konvaStage = new Konva.Stage({
      container: 'overlay-canvas',
      width: this.iFrameElement.offsetWidth,
      height: this.iFrameElement.offsetHeight
    });
    this.konvaLayer = new Konva.Layer();
    this.konvaStage.add(this.konvaLayer);

    if (this.overlayShapes) {
      this.drawOverlayShapes();
    }

    this.konvaStage.on('mousedown', e => {
      this.startX = e.evt.layerX;
      this.startY = e.evt.layerY;
      this.drawNewShape(e);
    });

    this.konvaStage.on('mousemove', e => {
      if (this.shapeInstance) {
        this.updateSelectedShapeOnMouseMove(e);
      }
    });

    this.konvaStage.on('mouseup', e => {
      if (this.isDragging) return;

      if (this.shapeOnCanvas && e.target !== this.konvaStage) {
        this.shapeInstance = this.shapeOnCanvas;
        const konvaShape = this.compileShapeData(e.target);
        store.mediaPickerDialog.updateShapeData(this.selectedShapePosition, konvaShape.data);
        this.shapeInstance = null;
      } else if (this.shapeInstance) {
        const newShape = this.compileShapeData();
        store.mediaPickerDialog.addShape(newShape);
        this.shapeInstance = null;
        this.setToolbarShape(null);
        this.setSelectedShape(find(this.overlayShapes, ['id', newShape.id]));
      }
    });

    this.konvaStage.on('dragstart', e => {
      this.isDragging = true;

      this.setSelectedShape(find(this.overlayShapes, ['id', e.target.attrs.id]));
      this.konvaStage.container().style.cursor = 'move';
    });

    this.konvaStage.on('dragend', e => {
      this.isDragging = false;
      this.shapeInstance = e.target;

      const konvaShape = this.compileShapeData();
      store.mediaPickerDialog.updateShapeData(this.selectedShapePosition, konvaShape.data);

      this.shapeInstance = null;
    });

    this.konvaStage.on('click', e => {
      this.setSelectedShape(null);
      e.target === this.konvaStage
        ? this.setSelectedShape(null)
        : this.setSelectedShape(find(this.overlayShapes, {id: e.target.attrs.id}));
    });
  };

  @action drawOverlayShapes = () => {
    this.overlayShapes.reverse().forEach(shape => {
      this.drawShapeFromMetadata(shape);
    });
  };

  drawShapeFromMetadata = shape => {
    if (!shape) return;
    const data = getShapeDataBasedOnContainer(shape, this.width, this.height);
    let konvaShape;

    switch (shape.shape) {
      case SHAPE_ENUMS.ARROW:
        konvaShape = this.drawArrow(shape.id, data, shape.color, shape.thickness);
        break;
      case SHAPE_ENUMS.RECT:
        konvaShape = this.drawRect(shape.id, data, shape.color, shape.thickness);
        break;
      case SHAPE_ENUMS.LINE:
        konvaShape = this.drawLine(shape.id, data, shape.color, shape.thickness);
        break;
      case SHAPE_ENUMS.ELLIPSE:
        konvaShape = this.drawEllipse(shape.id, data, shape.color, shape.thickness);
        break;
    }

    this.konvaLayer.add(konvaShape);
    this.konvaLayer.draw();

    konvaShape.on('click', () => {
      this.setSelectedShape(find(this.overlayShapes, ['id', konvaShape.attrs.id]));
    });

    if (this.draggable) {
      konvaShape.on('mouseenter', () => {
        this.konvaStage.container().style.cursor = 'pointer';
      });

      konvaShape.on('mouseleave', () => {
        this.konvaStage.container().style.cursor = 'default';
      });
    }
  };

  @action drawNewShape = e => {
    if (!this.toolbarShape) return;

    switch (this.toolbarShape) {
      case SHAPE_ENUMS.ARROW:
        this.shapeInstance = this.drawArrow(uuidv4(), {
          startX: e.evt.layerX,
          startY: e.evt.layerY,
          endX: e.evt.layerX + 1,
          endY: e.evt.layerY + 1
        });
        break;
      case SHAPE_ENUMS.RECT:
        this.shapeInstance = this.drawRect(uuidv4(), {
          x: e.evt.layerX,
          y: e.evt.layerY,
          width: 10,
          height: 10
        });
        break;
      case SHAPE_ENUMS.LINE:
        this.shapeInstance = this.drawLine(uuidv4(), {
          startX: e.evt.layerX,
          startY: e.evt.layerY,
          endX: e.evt.layerX + 1,
          endY: e.evt.layerY + 1
        });
        break;
      case SHAPE_ENUMS.ELLIPSE:
        this.shapeInstance = this.drawEllipse(uuidv4(), {
          x: e.evt.layerX,
          y: e.evt.layerY,
          radiusX: 5,
          radiusY: 5
        });
        break;
    }

    this.konvaLayer.add(this.shapeInstance);
    this.konvaLayer.draw();
  };

  //draw shapes
  drawArrow = (id, data, color = this.selectedColor, thickness = this.selectedThickness) => {
    const {startX, startY, endX, endY} = data;

    return new Konva.Arrow({
      id,
      points: [startX, startY, endX, endY],
      stroke: color,
      fill: color,
      strokeWidth: thickness,
      strokeScaleEnabled: false,
      draggable: this.draggable
    });
  };

  drawRect = (id, data, color = this.selectedColor, thickness = this.selectedThickness) => {
    const {x, y, width, height} = data;
    return new Konva.Rect({
      id,
      x,
      y,
      width,
      height,
      stroke: color,
      strokeWidth: thickness,
      hitStrokeWidth: thickness + extraHitSelection,
      strokeScaleEnabled: false,
      draggable: this.draggable
    });
  };

  drawLine = (id, data, color = this.selectedColor, thickness = this.selectedThickness) => {
    const {startX, startY, endX, endY} = data;

    return new Konva.Line({
      id,
      points: [startX, startY, endX, endY],
      stroke: color,
      strokeWidth: thickness,
      hitStrokeWidth: thickness + extraHitSelection,
      lineCap: 'round',
      lineJoin: 'round',
      strokeScaleEnabled: false,
      draggable: this.draggable
    });
  };

  drawEllipse = (id, data, color = this.selectedColor, thickness = this.selectedThickness) => {
    const {x, y, radiusX, radiusY} = data;

    return new Konva.Ellipse({
      id,
      x,
      y,
      radiusX,
      radiusY,
      stroke: color,
      strokeWidth: thickness,
      strokeScaleEnabled: false,
      draggable: this.draggable
    });
  };

  @action updateKonva = modifiedShape => {
    this.konvaLayer.destroy();
    this.konvaLayer = new Konva.Layer();
    this.konvaStage.add(this.konvaLayer);

    this.drawOverlayShapes();
    this.setSelectedShape(modifiedShape);

    if (!this.draggable && !modifiedShape) {
      this.konvaStage.container().style.cursor = 'crosshair';
    }
  };

  getReferencePoints = name => {
    const referencePoints = {horizontal: 'left', vertical: 'top'};

    if (name.includes('right')) referencePoints.horizontal = 'right';
    if (name.includes('bottom')) referencePoints.vertical = 'bottom';
    return referencePoints;
  };

  getResizedPoints = (target, points) => {
    if (!target._lastPos || !target.attrs.name)
      return {
        startX: points[0] / this.width,
        startY: points[1] / this.height,
        endX: points[2] / this.width,
        endY: points[3] / this.height
      };

    const {
      _lastPos: {x, y},
      attrs: {name}
    } = target;
    const anchor = this.getReferencePoints(name);
    const shape = this.getReferencePoints(this.shapeDirection);

    let startX = points[0];
    let startY = points[1];
    let endX = points[2];
    let endY = points[3];

    if (anchor.horizontal === shape.horizontal && anchor.vertical === shape.vertical) {
      startX = x;
      startY = y;
    }

    if (anchor.horizontal === shape.horizontal && anchor.vertical !== shape.vertical) {
      startX = x;
      endY = y;
    }

    if (anchor.horizontal !== shape.horizontal && anchor.vertical === shape.vertical) {
      startY = y;
      endX = x;
    }

    if (anchor.horizontal !== shape.horizontal && anchor.vertical !== shape.vertical) {
      endX = x;
      endY = y;
    }

    return {
      startX: startX / this.width,
      startY: startY / this.height,
      endX: endX / this.width,
      endY: endY / this.height
    };
  };

  @action compileShapeData = (target = null) => {
    if (!this.shapeInstance) return {};

    const {attrs} = this.shapeInstance;
    let data = {};
    if (!attrs.scaleX && !attrs.scaleY) {
      attrs.scaleX = 1;
      attrs.scaleY = 1;
    }

    switch (this.shapeInstanceType) {
      case SHAPE_ENUMS.ARROW:
      case SHAPE_ENUMS.LINE:
        if (target) {
          data = this.getResizedPoints(target, attrs.points);
        } else {
          if (!attrs.x && !attrs.y) {
            attrs.x = 0;
            attrs.y = 0;
          }
          data.startX = (attrs.points[0] + attrs.x) / this.width;
          data.startY = (attrs.points[1] + attrs.y) / this.height;
          data.endX = (attrs.points[2] + attrs.x) / this.width;
          data.endY = (attrs.points[3] + attrs.y) / this.height;
        }
        break;
      case SHAPE_ENUMS.RECT:
        data.x = attrs.x / this.width;
        data.y = attrs.y / this.height;
        data.width = (attrs.width * attrs.scaleX) / this.width;
        data.height = (attrs.height * attrs.scaleY) / this.height;
        break;
      case SHAPE_ENUMS.ELLIPSE:
        data.x = attrs.x / this.width;
        data.y = attrs.y / this.height;
        data.radiusX = (attrs.radiusX * attrs.scaleX) / this.width;
        data.radiusY = (attrs.radiusY * attrs.scaleY) / this.height;
        break;
    }

    return {
      id: attrs.id,
      shape: this.shapeInstanceType,
      color: this.selectedColor,
      thickness: this.selectedThickness,
      data: data
    };
  };

  @action updateSelectedShapeOnMouseMove = e => {
    switch (this.shapeInstanceType) {
      case SHAPE_ENUMS.ARROW:
        this.updateArrowOnMouseMove(this.startX, this.startY, e.evt.layerX, e.evt.layerY);
        break;
      case SHAPE_ENUMS.RECT:
        this.updateRectOnMouseMove(
          this.startX,
          this.startY,
          -(this.startX - e.evt.layerX),
          -(this.startY - e.evt.layerY)
        );
        break;
      case SHAPE_ENUMS.LINE:
        this.updateLineOnMouseMove(this.startX, this.startY, e.evt.layerX, e.evt.layerY);
        break;
      case SHAPE_ENUMS.ELLIPSE:
        this.updateEllipseOnMouseMove(
          this.startX,
          this.startY,
          -(this.startX - e.evt.layerX) < 0 ? 0 : -(this.startX - e.evt.layerX),
          -(this.startY - e.evt.layerY) < 0 ? 0 : -(this.startY - e.evt.layerY)
        );
        break;
    }
  };

  @action updateArrowOnMouseMove(startX, startY, endX, endY) {
    this.shapeInstance.setAttr('points', [startX, startY, endX, endY]);
    this.konvaLayer.draw();
  }

  @action updateRectOnMouseMove(startX, startY, width, height) {
    const limitWidth = width > 20 ? width : 20;
    const limitHeight = height > 20 ? height : 20;
    this.shapeInstance.setAttrs({x: startX, y: startY, width: limitWidth, height: limitHeight});
    this.konvaLayer.draw();
  }

  @action updateLineOnMouseMove(startX, startY, endX, endY) {
    this.shapeInstance.setAttr('points', [startX, startY, endX, endY]);
    this.konvaLayer.draw();
  }

  @action updateEllipseOnMouseMove(startX, startY, radiusX, radiusY) {
    const limitRadiusX = radiusX > 10 ? radiusX : 10;
    const limitRadiusY = radiusY > 10 ? radiusY : 10;
    this.shapeInstance.setAttrs({x: startX, y: startY, radiusX: limitRadiusX, radiusY: limitRadiusY});
    this.konvaLayer.draw();
  }

  @action updateCanvasSize = (width, height) => {
    this.konvaStage.width(width);
    this.konvaStage.height(height);
    this.updateKonva();
  };

  @action propagateResize = () => {
    this.iFrameElement && this.updateCanvasSize(this.iFrameElement.offsetWidth, this.iFrameElement.offsetHeight);
  };

  //reset
  @action reset = () => {
    this.selectedShape = null;
    this.toolbarColor = '#ff0000';
    this.toolbarShape = null;
    this.toolbarThickness = 2;
    this.showToolbarColorPicker = false;
    this.shouldCollapse = false;
    this.showTableColorPicker = false;
    this.iFrameElement = null;
    if (this.konvaLayer) this.konvaLayer.destroy();
    if (this.konvaStage) this.konvaStage.destroy();
    this.shapeInstance = null;
    this.isDragging = false;
    this.startX = 0;
    this.startY = 0;
  };
}

export default MediaOverlayDialog;
