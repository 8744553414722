import {defineMessages} from 'react-intl';

const messages = defineMessages({
  addContent: {
    id: 'addContent',
    defaultMessage: 'Please add content first'
  }
});

export default messages;
