import PropTypes from 'prop-types';
import React, {Component} from 'react';
import enhanceWithClickOutside from 'react-click-outside';

//lodash
import omit from 'lodash/omit';

class ClickOutsideComponent extends Component {
  static defaultProps = {
    as: 'div'
  };

  static propTypes = {
    as: PropTypes.elementType,
    onClickOutside: PropTypes.func.isRequired
  };

  handleClickOutside = (...params) => {
    const {onClickOutside} = this.props;
    onClickOutside(...params);
  };

  render() {
    const {as, children, ...otherProps} = this.props;
    const forwardedProps = omit(otherProps, 'onClickOutside');

    return React.createElement(as, forwardedProps, children);
  }
}

export default enhanceWithClickOutside(ClickOutsideComponent);
