import styled from 'styled-components';
import flex from 'styles/flex';

export const ResetPasswordView = styled.div`
  flex: 1;
  min-height: 500px;
  ${flex.vertical} ${flex.centerVertical};
`;

export const AlreadyLoggedIn = styled.div`
  font-size: 16px;
`;
