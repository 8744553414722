import {defineMessages} from 'react-intl';

const messages = defineMessages({
  addCustomEditable: {
    id: 'EditStep.addCustomEditable',
    defaultMessage: 'Add {noteLabel}'
  },
  addCustom: {
    id: 'EditStep.addCustom',
    defaultMessage: 'Add an alternative'
  },
  addFix: {
    id: 'EditStep.addFix',
    defaultMessage: 'Add a fix'
  },
  addTip: {
    id: 'EditStep.addTip',
    defaultMessage: 'Add a tip'
  },
  addWarning: {
    id: 'EditStep.addWarning',
    defaultMessage: 'Add a warning'
  },
  mandatoryNoteCheckbox: {
    id: 'EditStep.mandatoryNoteCheckbox',
    defaultMessage: 'Automatically display this Warning when User enters this Step'
  },
  hiddenResponse: {
    id: 'hiddenResponse',
    defaultMessage: 'Response will be hidden in Operator view.'
  },
  shownResponse: {
    id: 'shownResponse',
    defaultMessage: 'Response will be shown in Operator view.'
  },
  somethingWentWrong: {
    id: 'pdfErrorHeader',
    defaultMessage: 'Something went wrong'
  },
  hideOneResponse: {
    id: 'hideOneResponse',
    defaultMessage: 'You can only hide one response from Viewers.'
  },
  hideTwoResponses: {
    id: 'hideTwoResponses',
    defaultMessage: 'You can only hide two responses from Viewers.'
  },
  hideThreeResponses: {
    id: 'hideThreeResponses',
    defaultMessage: 'You can only hide three responses from Viewers.'
  }
});

export default messages;
