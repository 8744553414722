import React, {useState} from 'react';
import {InjectedIntlProps, injectIntl} from 'react-intl';
import {Popover} from 'antd';

import CloudinaryPicture from 'shared/components/CloudinaryPicture';
import {isVideoUrl} from 'shared/utils/media-utils.js';

import messages from './messages';
import {ImageContent, StyledLink, ImageWrapper} from './styles';

type MediaFeedbackProps = {
  check: {
    id: string;
    media: {
      url: string;
    };
  };
} & InjectedIntlProps;

export const getPreviewLink = (url: string) => {
  if (isVideoUrl(url)) {
    // make url a gif
    const urlParts = url.split('.');
    const extension = urlParts[urlParts.length - 1];
    return url.replace('upload/', 'upload/c_scale,h_200/fl_animated,vs_0.2s,e_loop/').replace(extension, 'gif');
  }

  return url;
};

const MediaFeedback: React.FC<MediaFeedbackProps> = ({check, intl: {formatMessage}}) => {
  const [hoverId, setHoverId] = useState<null | string>(null);

  const imgLink = check?.media?.url;

  return (
    <Popover
      visible={hoverId === check.id}
      overlayClassName="c-header__popover"
      placement="bottomRight"
      trigger="hover"
      content={
        <ImageWrapper>
          <CloudinaryPicture src={getPreviewLink(imgLink)} />
        </ImageWrapper>
      }
    >
      <StyledLink
        data-cy={`image-url-${check.media.url}`}
        href={check.media.url}
        target="_blank"
        onMouseEnter={() => setHoverId(check.id)}
        onMouseLeave={() => setHoverId(null)}
      >
        <ImageContent>
          {isVideoUrl(imgLink) ? formatMessage(messages.video) : formatMessage(messages.image)}
        </ImageContent>
      </StyledLink>
    </Popover>
  );
};

export default injectIntl(MediaFeedback);
