import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  improveText: {
    id: 'improveText',
    defaultMessage: 'Improve Text'
  }
});

export default messages;
