import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//components
import Select from 'ui-components/Select';

//messages
import messages from './messages';

class ThemePickerComponent extends Component {
  render() {
    const {
      intl: {formatMessage},
      label,
      disabled,
      className = 'ThemePicker',
      allowClear = false,
      form,
      field,
      themes = []
    } = this.props;

    return (
      <Select
        label={label}
        className={className}
        placeholder={formatMessage(messages.placeholder)}
        allowClear={allowClear}
        disabled={disabled}
        options={themes}
        form={form}
        field={field}
      />
    );
  }
}

export default injectIntl(ThemePickerComponent);
