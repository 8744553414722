import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const LanguageList = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  max-height: ${({maxHeightEm}) => (maxHeightEm ? `${maxHeightEm}em` : 'none')};
`;

export const Infobox = styled.div`
  margin-top: 12px;
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  background: ${commonPalette.lightGrey};
  margin-bottom: 32px;

  svg {
    margin-right: 12px;
  }
`;
