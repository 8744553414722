import React, {useEffect, useMemo} from 'react';
import {injectIntl} from 'react-intl';
import {inject} from 'mobx-react';
import views from 'config/views';

//helpers
import {CAMPAIGN_STATUS_ENUMS} from 'shared/enums';

//styled-components
import {
  CampaignTitle,
  Card,
  StatusLabel,
  StyledCardToolbar,
  ActivateButton,
  BottomSection,
  TopSection,
  MiddleSection,
  CampaignQuestion,
  ViewResultsDiv,
  StyledViewResultsText,
  InstructionCountInfo,
  BookIcon
} from './styles';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import CampaignMenu from 'components/CampaignMenu';
import {FormattedMessage} from 'components/FormattedComponents';
import Icon from 'ui-components/Layout/Icon';

import {commonPalette} from 'shared/styles/palette';

//messages
import messages from './messages';

const getCallbacks = ({campaign, store}) => ({
  editCampaign: () => {
    store.router.goTo(views.editCampaign, {id: campaign.id, tab: 'questions'});
  },

  onActivate: event => {
    const {changeCampaignStatusDialog} = store;

    /*
     * The activate button is nested under another element with an `onClick`,
     * and we don't want that to trigger.
     */
    event.stopPropagation();

    changeCampaignStatusDialog.open({
      campaign,
      targetStatus: CAMPAIGN_STATUS_ENUMS.PUBLISHED
    });
  },

  onDeactivate: () => {
    const {changeCampaignStatusDialog} = store;

    changeCampaignStatusDialog.open({
      campaign,
      targetStatus: CAMPAIGN_STATUS_ENUMS.ARCHIVED
    });
  },

  onCampaignCardClick: () => {
    const {
      campaignsPage: {openCampaignReport},
      router: {goTo}
    } = store;

    openCampaignReport(campaign.id);

    goTo(views.campaignsResults, {campaignId: campaign.id});
  },

  onTranslate: () => {
    const {
      router: {goTo}
    } = store;

    goTo(views.translateCampaign, {id: campaign.id});
  },

  onVisibility: () => {
    const {
      router: {goTo}
    } = store;

    goTo(views.visibilityCampaign, {id: campaign.id});
  }
});

const setTranslations = ({intl, store}) => {
  const {formatMessage} = intl;
  const {campaignsPage} = store;

  const translations = mapValues(pick(messages, ['signOffDuplicatedSucces', 'signOffDuplicatedError']), message =>
    formatMessage(message)
  );

  campaignsPage.setTranslations(translations);
};

const CampaignCard = inject('store')(
  React.forwardRef(props => {
    const {
      campaign,
      key,
      store,
      store: {
        campaignsPage,
        frequencySettingsDialog,
        platform: {currentLanguage}
      },
      intl,
      intl: {formatMessage}
    } = props;

    const cardKey = key + campaign.id + 'row';

    const isDraft = campaign.status === CAMPAIGN_STATUS_ENUMS.DRAFT;
    const isActivated = campaign.status === CAMPAIGN_STATUS_ENUMS.PUBLISHED;
    const isDeactivated = campaign.status === CAMPAIGN_STATUS_ENUMS.ARCHIVED;

    const {
      title,
      isEditable,
      defaultLocale,
      question: {questionTranslations},
      numInstructionsAppliedTo
    } = campaign;
    const question = questionTranslations[currentLanguage] || questionTranslations[defaultLocale];

    const {editCampaign, onActivate, onDeactivate, onCampaignCardClick, onTranslate, onVisibility} = useMemo(
      () =>
        getCallbacks({
          campaign,
          store
        }),
      [campaign, store]
    );

    useEffect(() => {
      setTranslations({intl, store});
    }, []);

    return (
      <Card key={cardKey} onClick={onCampaignCardClick} isDeactivated={isDeactivated}>
        <TopSection>
          <StatusLabel isActivated={isActivated}>
            {isDraft && <FormattedMessage {...messages.draft} />}
            {isActivated && <FormattedMessage {...messages.active} />}
            {isDeactivated && <FormattedMessage {...messages.deactivated} />}
          </StatusLabel>
          <StyledCardToolbar
            onClickMenu={() => {
              campaignsPage.setCurrentCampaignIdMenuOpen(campaign.id);
            }}
            dropdownMenu={
              <CampaignMenu
                onEdit={editCampaign}
                onDeactivate={onDeactivate}
                onFrequencySettings={() => {
                  frequencySettingsDialog.open(campaign);
                }}
                onTranslate={onTranslate}
                onVisibility={onVisibility}
                isActivated={isActivated}
                isDeactivated={isDeactivated}
                isEditable={isEditable}
              />
            }
          />
        </TopSection>
        <MiddleSection>
          <CampaignTitle>{title}</CampaignTitle>
          <CampaignQuestion>{question}</CampaignQuestion>
        </MiddleSection>
        <BottomSection isDeactivated={isDeactivated} isDraft={isDraft}>
          {!isDeactivated && (
            <InstructionCountInfo numInstructionsAppliedTo={numInstructionsAppliedTo}>
              <BookIcon />
              <FormattedMessage {...messages.instructionCount} values={{count: numInstructionsAppliedTo}} />
            </InstructionCountInfo>
          )}
          {isDraft ? (
            <ActivateButton onClick={onActivate} label={formatMessage(messages.activate)} />
          ) : (
            <ViewResultsDiv>
              <StyledViewResultsText>{formatMessage(messages.viewResults)}</StyledViewResultsText>
              <Icon name="arrow-right" style={{color: commonPalette.txGrey, height: 22}} />
            </ViewResultsDiv>
          )}
        </BottomSection>
      </Card>
    );
  })
);

export default injectIntl(CampaignCard);
