import styled from 'styled-components';
import {ellipsis} from 'shared/utils/sc-utils';
import {commonPalette} from 'shared/styles/palette';
import $CardToolbar from 'components/CardToolbar';

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
`;

export const TableInfoDiv = styled.div`
  display: flex;
  height: 380px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
  background: white;
  z-index: 2;
  ${({absolutePosition}) => (absolutePosition ? 'position:absolute;' : '')}
  width: 100%;
`;

export const TableOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
`;

export const StyledText = styled.div`
  color: #1f271b;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 2px;
`;

export const StyledSubText = styled.div`
  color: ${commonPalette.txtGrey};
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const TableActionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  margin-top: 10px;
`;

export const NameCell = styled.div`
  ${ellipsis}
`;

export const ChevronCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DropdownList = styled.div`
  max-height: 220px;
`;

export const DropwdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  min-width: 300px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  gap: 10px;
`;

export const StyledMenu = styled($CardToolbar)`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;
