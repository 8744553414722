import {get} from 'lodash';

export const skillRoleOptions = {
  options: props => {
    const id = get(props, 'store.router.params.id');
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'skillRoleQuery',
  skip: props => {
    const id = get(props, 'store.router.params.id');
    return !id;
  }
};
