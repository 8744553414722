import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';
import {observable, makeObservable} from 'mobx';
import {graphql} from '@apollo/client/react/hoc/graphql';
import axios from 'axios';

//helpers
import {getCloudinaryApiUrl} from 'shared/utils/cloudinary-utils';
import {readFileAsDataURL} from 'shared/utils/file-utils';
import {imageTypes, acceptedFileTypes, resourceAllowed, getUploadPresetForFile} from 'shared/utils/media-utils';
import {IMAGE_CONFIG, VIDEO_CONFIG, EAGER_CACHE} from 'shared/media-config';
import notification from 'utils/notification-utils';

//data
import {SignMedia, CacheMedia, SaveMedia, EditMedia} from 'api/media/mutations';
import {signMediaOptions, cacheMediaOptions, saveMediaOptions, editMediaOptions} from 'api/media/mutation-options';
import {Platform} from 'api/platform/queries';
//styled-components
import {
  UploadImageWrapper,
  UploadImage,
  Text,
  UploadImageActiveStyle,
  Title,
  AcceptedFiles,
  MiddleText,
  MenuIcon,
  StyledVeticalDiv,
  UploadingDiv
} from './styles';

import Spinner from 'shared/components/Spinner';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@graphql(SignMedia, signMediaOptions)
@graphql(CacheMedia, cacheMediaOptions)
@graphql(SaveMedia, saveMediaOptions)
@graphql(EditMedia, editMediaOptions)
@inject('store')
@graphql(Platform)
@observer
class MediaUploadComponent extends Component {
  static defaultProps = {
    allowVideos: false
  };

  @observable loading = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    window.addEventListener('paste', this.onPaste);
  }

  componentWillUnmount() {
    window.removeEventListener('paste', this.onPaste);
  }

  modify = (e, modificationFn) => {
    e.stopPropagation();
    modificationFn();
  };

  errorUploadingFile = ({type, data}) => {
    const {formatMessage} = this.props.intl;
    const message = formatMessage(messages[type], data);
    notification.error(message);
  };

  onDrop = files => {
    const {
      store: {mediaPickerDialog: dialog}
    } = this.props;
    const {allowVideos, acceptedTypes, setTotalFilesNumber, setCurrentFileNumber} = dialog;
    const filesToBeUploaded = [];
    for (const file of files) {
      const {error} = resourceAllowed(file, allowVideos, acceptedTypes);
      if (error) {
        this.errorUploadingFile(error);
        continue;
      }

      filesToBeUploaded.push(this.upload(file));
    }
    setTotalFilesNumber(filesToBeUploaded.length);
    setCurrentFileNumber(1);

    return Promise.all(filesToBeUploaded);
  };

  onDropRejected = ([file]) => {
    const {
      store: {mediaPickerDialog: dialog}
    } = this.props;
    const {allowVideos, acceptedTypes} = dialog;

    if (file) {
      const {error} = resourceAllowed(file, allowVideos, acceptedTypes);
      this.errorUploadingFile(error);
    }
  };

  getTimestamp = () => Math.round(Date.now() / 1000);

  uploadRaw = (image64, data, uploadPreset) => {
    const {data: dataFromGraphlql} = this.props;
    return axios.post(getCloudinaryApiUrl(), {
      file: image64,
      timestamp: data.sign.timestamp,
      upload_preset: uploadPreset,
      resource_type: 'auto',
      quality: 'auto',
      folder: dataFromGraphlql.platformForCms.assetsFolder
    });
  };

  finishUploading = (data, params) => {
    const {
      store: {mediaPickerDialog: dialog},
      onUploadDone,
      onUploadChange
    } = this.props;
    const {setCurrentFileNumber, currentFileNumber, totalFilesNumber} = dialog;
    if (currentFileNumber === totalFilesNumber) {
      dialog.setLoading(false);
    } else {
      setCurrentFileNumber(currentFileNumber + 1);
    }
    onUploadDone && onUploadDone();
    onUploadChange && onUploadChange(false);

    if (data && data.secure_url) {
      const {saveMedia} = this.props;
      dialog.saveMediaResource(saveMedia, data.secure_url, this.props.editMedia, params);
    }
  };

  upload = async file => {
    let data;
    const {
      signMedia,
      cacheMedia,
      onUploadStart,
      onUploadChange,
      store: {mediaPickerDialog}
    } = this.props;
    const {setLoading} = mediaPickerDialog;

    setLoading(true);
    onUploadStart && onUploadStart();
    onUploadChange && onUploadChange(true);
    const extension = file.type.split('/').pop();
    const fileWithoutExtension = file.name.replace(`.${extension}`, '');

    const params = {
      timestamp: this.getTimestamp(),
      public_id: fileWithoutExtension
    };

    try {
      const sign = await signMedia(params);
      data = sign.data;
    } catch (error) {
      mediaPickerDialog.setLoading(false);
      return this.errorUploadingFile({type: 'networkError'});
    }

    const image64 = await readFileAsDataURL(file).promise;

    const uploadPreset = getUploadPresetForFile(file);

    this.uploadRaw(image64, data, uploadPreset)
      .then(({data}) => {
        // form.$(field).sync(data.secure_url);
        this.finishUploading(data, params);
        const resourceType = data.resource_type;
        if (EAGER_CACHE[resourceType].length > 0) {
          cacheMedia({
            publicId: data.public_id,
            resourceType,
            eagerOptions: JSON.stringify(EAGER_CACHE[resourceType])
          });
        }
      })
      .catch(() => {
        mediaPickerDialog.setLoading(false);
        return this.errorUploadingFile({type: 'networkError'});
      });
  };

  getAcceptedTypes = () => {
    const {
      store: {mediaPickerDialog}
    } = this.props;
    const {acceptedTypes, allowVideos, loading} = mediaPickerDialog;

    if (acceptedTypes) {
      return acceptedTypes;
    }

    if (loading) {
      return [];
    }

    if (allowVideos) {
      return acceptedFileTypes;
    }

    return imageTypes;
  };

  getAcceptedExtensions = () => {
    const {
      store: {mediaPickerDialog}
    } = this.props;
    const {allowedFormats, allowVideos} = mediaPickerDialog;

    if (allowedFormats) {
      return allowedFormats;
    }

    if (allowVideos) {
      return [...IMAGE_CONFIG.extensions, ...VIDEO_CONFIG.extensions];
    }

    return IMAGE_CONFIG.extensions;
  };

  onPaste = ({clipboardData}) => {
    const {
      store: {mediaPickerDialog}
    } = this.props;
    const {loading} = mediaPickerDialog;
    if (clipboardData.files?.length && !loading) {
      this.onDrop(clipboardData.files);
    }
  };

  render() {
    const {
      styles = {},
      className,
      store: {mediaPickerDialog: dialog},
      data: dataFromGraphlql,
      intl
    } = this.props;
    const {formatMessage} = intl;
    const {loading, totalFilesNumber, currentFileNumber} = dialog;

    const acceptedTypes = this.getAcceptedTypes();
    const acceptedExtensions = this.getAcceptedExtensions();
    const {loading: dataLoading, platformForCms} = dataFromGraphlql;

    if (dataLoading || !platformForCms) {
      return null;
    }

    return (
      <UploadImageWrapper>
        <Title>
          <FormattedMessage {...messages.uploadNew} />
        </Title>
        <UploadImage
          {...loading}
          disableClick={loading}
          styles={styles.UploadImage}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          accept={acceptedTypes.join()}
          multiple={true}
          activeStyle={UploadImageActiveStyle}
          loadingImage={loading}
          className={className}
        >
          {!loading && (
            <>
              <StyledVeticalDiv>
                <MenuIcon name="drag-and-drop" size={48} />
                <Text>
                  <FormattedMessage {...messages.dragAndDrop} />
                </Text>
              </StyledVeticalDiv>
              <MiddleText>or</MiddleText>
              <StyledVeticalDiv>
                <MenuIcon name="upload-local" size={48} />
                <Text>
                  <FormattedMessage {...messages.browseYourComputer} />
                </Text>
              </StyledVeticalDiv>
              <MiddleText>or</MiddleText>
              <StyledVeticalDiv>
                <MenuIcon name="copy-paste" size={48} />
                <Text>
                  <FormattedMessage {...messages.copyAndPaste} />
                </Text>
              </StyledVeticalDiv>
            </>
          )}

          {loading && (
            <StyledVeticalDiv>
              <UploadingDiv>{formatMessage(messages.uploading)}</UploadingDiv>
              <Spinner styles={styles.loading} color={'#298784'} />
              <UploadingDiv>
                <FormattedMessage {...messages.numberOfUploads} values={{currentFileNumber, totalFilesNumber}} />
              </UploadingDiv>
            </StyledVeticalDiv>
          )}
        </UploadImage>
        <AcceptedFiles>
          <br />
          {formatMessage(messages.acceptedFiles) + ' '}
          {acceptedExtensions.map(ext => `.${ext}`).join('/')}
        </AcceptedFiles>
      </UploadImageWrapper>
    );
  }
}

export default injectIntl(MediaUploadComponent);
