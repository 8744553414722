import get from 'lodash/get';
export const getFollowUpQuestionText = ({campaignAnswer, locale}) => {
  const campaignDefaultLocale = get(campaignAnswer, 'campaign.defaultLocale');
  const selectedLocale = locale || get(campaignAnswer, 'campaign.defaultLocale');
  const followUpQuestion = campaignAnswer.followUpQuestion;

  if (followUpQuestion) {
    const {questionTranslations} = followUpQuestion;
    const defaultTranslation = questionTranslations[campaignDefaultLocale];
    return questionTranslations[selectedLocale] || defaultTranslation;
  }
};
