import {defineMessages} from 'react-intl';

const messages = defineMessages({
  deleteUser: {
    id: 'deleteUser',
    defaultMessage: 'Delete user'
  },
  cannotDeleteUser: {
    id: 'cannotDeleteUser',
    defaultMessage: 'Cannot delete user'
  },
  cannotArchiveUser: {
    id: 'cannotDeactivateUser',
    defaultMessage: 'Cannot deactivate user'
  },
  close: {
    id: 'common.actions.close',
    defaultMessage: 'Close'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  goToTeams: {
    id: 'goToTeams',
    defaultMessage: 'Go to teams'
  },
  goToGuideOverview: {
    id: 'goToGuideOverview',
    defaultMessage: 'Go to guide overview'
  },
  onlyPlaformAdminDeleteUser: {
    id: 'onlyPlaformAdminDeleteUser',
    defaultMessage:
      'You are the only platform admin and you cannot delete yourself. Assign at least one other user as a platform admin and try again.'
  },
  onlyPlaformAdminArchiveUser: {
    id: 'onlyPlaformAdminDeactivateUser',
    defaultMessage:
      'You are the only platform admin and you cannot deactivate yourself. Assign at least one other user as a platform admin and try again.'
  },
  onlyApproverTeamsDeleteUser: {
    id: 'onlyApproverTeamsDeleteUser',
    defaultMessage:
      '{name} is the only approver in the teams below. Assign at least one other approver for each team or turn off guide approval and try again.'
  },
  onlyApproverTeamDeleteUser: {
    id: 'onlyApproverTeamDeleteUser',
    defaultMessage:
      '{userName} is the only approver in {teamName}. Assign at least one other approver or turn off guide approval and try again.'
  },
  assignedGuidesTeamsDeleteUser: {
    id: 'assignedGuidesTeamsDeleteUser',
    defaultMessage:
      'The guides below are assigned to {name}. Reassign the guides from the guide overview page and try again.'
  },
  assignedGuideTeamsDeleteUser: {
    id: 'assignedGuideTeamsDeleteUser',
    defaultMessage:
      '{guideName} is assigned to {userName}. Reassign the guide from the guide overview page and try again.'
  },
  areYouSureDeleteUser: {
    id: 'areYouSureDeleteUser',
    defaultMessage:
      'Are you sure you want to delete the user {name}? They will be permanently deleted and removed from the platform. This action cannot be undone.'
  },
  hasPendingApprovals: {
    id: 'hasPendingApprovals',
    defaultMessage:
      '{name} is an approver in the teams below that have guides pending approval. In team settings, unassign them as an approver or turn off guide approval and try again.'
  },
  errorFetchUserDetails: {
    id: 'errorFetchUserDetails',
    defaultMessage:
      'User details couldn’t be loaded due to a system error. Contact support if this error continues to occur.'
  },
  couldntLoadUserDetails: {
    id: 'couldntLoadUserDetails',
    defaultMessage: "Couldn't load user details"
  },
  userDeletedSuccess: {
    id: 'userDeletedSuccess',
    defaultMessage: 'User deleted'
  },
  userDeletedError: {
    id: 'userDeletedError',
    defaultMessage: "Couldn't delete user"
  },
  userArchivedSuccess: {
    id: 'userDeactivatedSuccess',
    defaultMessage: 'User deactivated'
  },
  userArchivedError: {
    id: 'userDeactivatedError',
    defaultMessage: "Couldn't deactivate user"
  }
});

export default messages;
