import htmlToPdfmake from 'html-to-pdfmake';

import {generateImageUrlFromMediaAndOverlays} from 'shared/utils/overlay-utils';
import {INSTRUCTION_TYPE} from 'shared/enums';

import {generateImage} from './image';
import {getLinkList} from './linkList';
import {getMediaNotAvailable} from './mediaNotAvailable';
import {generateStepNotes} from './stepNotes';

import {alignmentType, colors, grayBorderLayout, defaultHtmlLinksStyles, pageBreak} from '../constants';
import {
  correctHtmlContent,
  makeListVisible,
  getLettersFromStepContent,
  extractLinkMapFromStep,
  isAImage,
  isAGif,
  isAVideo,
  getMediaType
} from '../utils';

export const generateStep = async (step, currentStep, totalSteps, pdfMessages, additionalInfo, guideBaseUrl, intl) => {
  if (!step) {
    return;
  }

  const linksMap = extractLinkMapFromStep(step);

  const {media, instructionHtml} = step;

  let mediaContent,
    correctedInstructionHtml,
    linkList = [];

  if (media) {
    const isNotSupportedMedia = isAGif(media.url) || isAVideo(media.url);
    const hasOverlays = media.metadata && media.metadata.overlays;

    if (isNotSupportedMedia) {
      const mediaType = getMediaType(media);

      mediaContent = await getMediaNotAvailable(
        {
          ...additionalInfo,
          currentStep
        },
        pdfMessages,
        null,
        guideBaseUrl,
        intl,
        mediaType
      );
    } else if (isAImage(media.url)) {
      let mediaUrl = await generateImage(media.url, media.metadata);

      if (hasOverlays) {
        mediaUrl = await generateImageUrlFromMediaAndOverlays(mediaUrl, media.metadata.overlays, 250, 250 / (4 / 3));
      }

      mediaContent = {
        image: mediaUrl,
        width: 250,
        alignment: alignmentType.RIGHT
      };
    }
  }

  const stepNotes = await generateStepNotes(
    step.notes ? step.notes.filter(stepNote => !stepNote.mandatory) : [],
    pdfMessages,
    {
      ...additionalInfo,
      currentStep
    },
    guideBaseUrl,
    intl,
    linksMap
  );

  const mandatoryStepNotes = await generateStepNotes(
    step.notes ? step.notes.filter(stepNote => stepNote.mandatory) : [],
    pdfMessages,
    {
      ...additionalInfo,
      currentStep
    },
    guideBaseUrl,
    intl,
    linksMap
  );

  if (instructionHtml) {
    const isStepContent = true;
    // The htmlToPdfmake does not support <ins> which is <u> so we need to replace all the <ins> occurences
    correctedInstructionHtml = correctHtmlContent(instructionHtml, linksMap, isStepContent);
    linkList = getLinkList(linksMap, pdfMessages, additionalInfo.guide);
  }

  correctedInstructionHtml = correctedInstructionHtml || '<p></p>';

  const stepContentText = htmlToPdfmake(correctedInstructionHtml, {
    defaultStyles: defaultHtmlLinksStyles
  });

  const lettersInStepContent = getLettersFromStepContent(stepContentText);

  // Hack for when the step is having too much information and should be breakable
  const unbreakable =
    (lettersInStepContent.length < 300 && stepNotes.length + mandatoryStepNotes.length < 3) ||
    (lettersInStepContent.length < 500 && stepNotes.length + mandatoryStepNotes.length < 2) ||
    (lettersInStepContent.length < 800 && stepNotes.length + mandatoryStepNotes.length === 0);

  const pageBreakOptions =
    stepNotes.length + mandatoryStepNotes.length > 2 && currentStep !== 1 ? {pageBreak: pageBreak.BEFORE} : {};

  // Lists are not displayed so this is a hack to show them
  makeListVisible(stepContentText);

  const isChecklist = additionalInfo.instruction.type === INSTRUCTION_TYPE.CHECKLIST;

  const stepContent = [
    {
      text: [
        `${isChecklist ? pdfMessages.check : pdfMessages.step} ${currentStep} / `,
        {text: totalSteps, color: colors.GRAY}
      ],
      margin: [0, 0, 0, 10],
      fontSize: 10
    },
    mandatoryStepNotes,
    {
      text: stepContentText,
      margin: [0, -10, 35, 0],
      fontSize: 10
    },
    stepNotes
  ];

  return {
    stack: [
      {
        table: {
          headerRows: 0,
          widths: ['*'],
          body: [
            [
              {
                columns: [stepContent, mediaContent]
              }
            ]
          ]
        },
        layout: grayBorderLayout,
        margin: [0, 25, 0, 0]
      },
      ...linkList
    ],
    unbreakable,
    ...pageBreakOptions
  };
};
