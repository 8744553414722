import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';
import Select from 'ui-components/Select';

export const WrapperSpinner = styled.div`
  text-align: center;
`;

export const StyledInfo = styled.div`
  margin-bottom: 10px;
  color: ${commonPalette.txLightGrey};
`;

export const TeamSelect = styled(Select)`
  height: 280px;
  overflow-y: auto;
`;

export const dialogStyle = {background: 'none', border: 'none'};
