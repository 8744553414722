import React, {Component} from 'react';

//styled-components
import {Text} from './styles';

class TextComponent extends Component {
  render() {
    const {children, size = 'normal', secondary = false, tag = 'div', ellipsis, opacity, className} = this.props;

    const Tag = Text.withComponent(tag);
    return (
      <Tag className={className} size={size} secondary={secondary} ellipsis={ellipsis} opacity={opacity}>
        {children}
      </Tag>
    );
  }
}

export default TextComponent;
