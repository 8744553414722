import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import Dialog from 'ui-components/Dialog';
import messages from './messages';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {UpdateSkillTask} from 'api/learning-activities/mutations';
import {updateSkillTaskOptions} from 'api/learning-activities/mutation-options';
import {StyledText} from './styles';

@inject('store')
@graphql(UpdateSkillTask, updateSkillTaskOptions)
@observer
class SaveChangesDialog extends Component {
  onCancel = () => {
    const {
      store: {learningActivityGeneralInfo}
    } = this.props;

    learningActivityGeneralInfo.toggleSaveDialog(false);
  };

  onSave = () => {
    const {
      updateSkillTaskMutation,
      store: {learningActivityGeneralInfo}
    } = this.props;

    learningActivityGeneralInfo.editLearningActivity(updateSkillTaskMutation, true);
    learningActivityGeneralInfo.toggleSaveDialog(false);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {learningActivityGeneralInfo}
    } = this.props;

    return (
      <Dialog
        title={formatMessage(messages.saveChanges)}
        isOpen={learningActivityGeneralInfo.isSaveDialogOpen}
        size={'small'}
        actions={[
          {
            label: 'Save',
            design: 'text',
            onClick: () => {
              this.onSave();
            }
          }
        ]}
        onCancel={this.onCancel}
      >
        <StyledText>
          {formatMessage(messages.saveDialogDesc, {count: learningActivityGeneralInfo.skillCount})}
        </StyledText>
      </Dialog>
    );
  }
}

export default injectIntl(SaveChangesDialog);
