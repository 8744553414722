import {CAMPAIGN_STATUS_ENUMS} from 'shared/enums';

const {PUBLISHED, DRAFT, ARCHIVED} = CAMPAIGN_STATUS_ENUMS;

export const filterCampaigns = (campaigns = [], searchTerm = '') =>
  campaigns.filter(campaign => campaign.title.toLowerCase().includes(searchTerm.toLowerCase()));

const compareCampaigns = (campaignOne, campaignTwo) => {
  const campaignOneTitle = campaignOne.title.toLowerCase();
  const campaignTwoTitle = campaignTwo.title.toLowerCase();

  if (campaignOneTitle < campaignTwoTitle) {
    return -1;
  }

  if (campaignOneTitle > campaignTwoTitle) {
    return 1;
  }

  return 0;
};

export const sortCampaigns = (campaigns = []) => {
  let activatedCampaigns = [];
  let draftCampaigns = [];
  let deactivatedCampaigns = [];

  campaigns.forEach(campaign => {
    switch (campaign.status) {
      case PUBLISHED:
        activatedCampaigns.push(campaign);
        break;
      case DRAFT:
        draftCampaigns.push(campaign);
        break;
      case ARCHIVED:
        deactivatedCampaigns.push(campaign);
        break;
    }
  });

  activatedCampaigns = activatedCampaigns.sort(compareCampaigns);
  draftCampaigns = draftCampaigns.sort(compareCampaigns);
  deactivatedCampaigns = deactivatedCampaigns.sort(compareCampaigns);

  return [...activatedCampaigns, ...draftCampaigns, ...deactivatedCampaigns];
};
