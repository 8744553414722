import {defineMessages} from 'react-intl';

const messages = defineMessages({
  guide: {
    id: 'common.field.guide',
    defaultMessage: 'Guide'
  },
  selectGuide: {
    id: 'MoveToDialog.selectGuide',
    defaultMessage: 'Select guide'
  }
});

export default messages;
