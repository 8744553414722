import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import RadioButton from 'shared/components/RadioButton';
import Dialog from 'ui-components/Dialog';

import messages from './messages';
import {OptionWrapper, OptionsWrapper, CaptionText} from './styles';

@inject('store')
@observer
class OverrideCampaignDialog extends Component {
  render() {
    const {
      intl: {formatMessage},
      store: {
        overrideCampaignDialog: {opened, close, overrideExistingCampaigns, setOverrideExistingCampaigns, submit}
      }
    } = this.props;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.apply),
            onClick: submit,
            disabled: false
          }
        ]}
        isOpen={Boolean(opened)}
        title={formatMessage(messages.title)}
        onCancel={close}
        dialogStyle={{width: 900}}
        titleIconId="warning"
      >
        <OptionsWrapper>
          <OptionWrapper>
            <RadioButton
              caption={<CaptionText>{formatMessage(messages.keepCampaignsOption)}</CaptionText>}
              checked={!overrideExistingCampaigns}
              onChange={() => {
                setOverrideExistingCampaigns(false);
              }}
            />
          </OptionWrapper>
          <OptionWrapper>
            <RadioButton
              caption={<CaptionText>{formatMessage(messages.overrideCampaignsOption)}</CaptionText>}
              checked={overrideExistingCampaigns}
              onChange={() => {
                setOverrideExistingCampaigns(true);
              }}
            />
          </OptionWrapper>
        </OptionsWrapper>
      </Dialog>
    );
  }
}

export default injectIntl(OverrideCampaignDialog);
