import {defineMessages} from 'react-intl';

const messages = defineMessages({
  reassignTeamContent: {
    id: 'TeamsView.reassignTeamContent',
    defaultMessage: 'Reassign team content'
  }
});

export default messages;
