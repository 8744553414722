import {createGlobalStyle} from 'styled-components';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Chip from './Chip';
import Dialog from './Dialog';
import Dropdown from './Dropdown';
import Icon from './Icon';
import Select from './Select';
import Toggle from './Toggle';

import {commonPalette} from 'shared/styles/palette';
import {transparentize} from 'shared/utils/color-utils';

export {Button, ButtonGroup, Chip, Dialog, Dropdown, Icon, Select, Toggle};

export const popoverClassName = 'c-color-picker__popover';

export const GlobalStyles = createGlobalStyle`
.${popoverClassName} {
    width: 300px;
    height: 180px;
    min-height: 180px;
    
    .ant-popover-inner-content {
      padding: 0 !important;
    }
  } 

  .c-header__popover {
    width: 218px;

    .ant-popover-inner-content {
      padding: 0 !important;
    }
  }

  .ant-calendar-picker-container {
    z-index: 9999999 !important;
  }

  .ant-input {
    height: 40px !important;
    width: 230px !important;
    font-size: 14px !important;
    padding: 0 14px !important;
    border: 1px solid ${commonPalette.borderGrey} !important;

    &:hover {
      border-color: ${commonPalette.sgGreen} !important;
    }
  }

  .ant-calendar-input-wrap {
    height: 40px !important;
    font-size: 14px !important;
    padding: 0 14px !important;
  }

  .ant-calendar-date-input-wrap, .ant-calendar-input {
    height: 100% !important;
  }

  .ant-calendar-selected-day .ant-calendar-date[aria-disabled=false], 
  .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
  .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
  .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade
  {
    background: ${commonPalette.sgGreen} !important;
  }

  .ant-calendar-today .ant-calendar-date[aria-selected=false][disabled=false] {
    border-color: ${commonPalette.sgGreen} !important;
    color: ${commonPalette.sgGreen} !important;
  }

  .ant-calendar-date[aria-selected=false][aria-disabled=false]:hover,
  .ant-calendar-year-panel-year:hover,
  .ant-calendar-month-panel-month:hover,
  .ant-calendar-decade-panel-decade:hover
  {
    color: ${commonPalette.sgGreen} !important;
    background: ${transparentize(commonPalette.btnHoverGreen, 0.2)} !important;
  }

  .ant-calendar-header a:hover {
    color: ${commonPalette.sgGreen} !important;
  }

  .ant-tabs {
    overflow: visible !important;
    color: ${commonPalette.txBlack} !important;
  }

  .c-header__popover__inner {
    z-index: 1 !important;
    width: 218px;

    .ant-popover-inner-content {
      padding: 0 !important;
    }
  }

  .swipeguide-notification {
    &.ant-notification-notice {
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      line-height: 17px;
      padding: 16px;
      transform: translate(0, 10px); // not using margins to avoid flacky animation
    }

    .ant-notification-notice-icon {
      height: 100%;
      left: 0;
      padding: 0;
      top: 0;
      width: 100%;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-message {
      color: inherit;
      display: block;
      font-size: 14px;
      line-height: inherit;
      margin: 0;
      z-index: 2;
      position: relative;
      cursor: pointer;
    }

    .ant-notification-notice-close,
    .ant-notification-notice-description,
    .ant-notification-notice-message-single-line-auto-margin {
      display: none;
    }
  }

  .ant-popover {
    z-index: 99999999 !important
  }

  .swipeguide-notification-success {
    &.ant-notification-notice {
      background: #2F2F2F;
      color: #fff;
    }
  }

  .swipeguide-notification-error {
    &.ant-notification-notice {
      background: #d32f2f;
      color: #fff;
    }
  }

  .link-highlight {
   animation: highlight 3s 1;
  }

  @keyframes highlight {
    0% {
      background-color: ${transparentize(commonPalette.highlightYellow, 0.8)};
    }
    100% {
      background-color: transparent;
    }
  }
`;
