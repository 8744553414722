import React from 'react';
import {ActivityLogLink} from './ActivityLogLink';
import views from 'config/views';

type StepLinkProps = {
  guideId: string;
  topicId: string;
  instructionId: string;
  stepId: string;
  v: string;
  versionId: string;
  children: React.ReactNode;
  router: any;
  linkValidityChecks: {
    checkStepLinkIsValid: (params: {
      stepId: string;
      topicId: string;
      guideId: string;
      versionId: string;
      instructionId: string;
    }) => boolean;
  };
};

export const StepLink = (props: StepLinkProps) => {
  const {
    guideId,
    topicId,
    instructionId,
    stepId,
    v,
    versionId,
    children,
    router,
    linkValidityChecks: {checkStepLinkIsValid}
  } = props;

  const handleValidateLink = e => {
    e.preventDefault();
    return checkStepLinkIsValid({
      stepId,
      instructionId,
      topicId,
      guideId,
      versionId
    });
  };
  return (
    <ActivityLogLink
      router={router}
      route={views.editInstruction}
      params={{id: guideId, topicId, instructionId, stepId}}
      queryParams={{v}}
      validateLink={handleValidateLink}
    >
      {children}
    </ActivityLogLink>
  );
};
