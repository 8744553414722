import {defineMessages} from 'react-intl';

const messages = defineMessages({
  viewSkill: {
    id: 'Skills.viewSkill',
    defaultMessage: 'View Skill'
  },
  remove: {
    id: 'common.actions.remove',
    defaultMessage: 'Remove'
  },
  editSkill: {
    id: 'editSkill',
    defaultMessage: 'Edit Skill'
  },
  delete: {
    id: 'common.actions.delete',
    defaultMessage: 'Delete'
  }
});

export default messages;
