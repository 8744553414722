export const sanitizeSimpleObject = (object, properties) => {
  const sanitizedObject = {...object};

  properties.forEach(property => (sanitizedObject[property] = sanitizedObject[property].trim()));

  return sanitizedObject;
};

export const removeFieldsFromObject = (object, fieldsArray) =>
  JSON.parse(JSON.stringify(object), (key, value) => (fieldsArray.includes(key) ? undefined : value));
