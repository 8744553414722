import * as d3 from 'd3';

import {FULL_ANIMATION_STEP, SMALL_ANIMATION_STEP} from '../constants.js';

class D3Pie {
  create = (el, data) => {
    this.rootElement = el;

    // Set the initial values of the data and variables
    const dataset = [
      {
        label: 'NO',
        answer: data[0]
      },
      {
        label: 'YES',
        answer: data[1]
      }
    ];

    const h = 64;
    const w = h * 2;

    const color = ['#BE0000', '#007DBE'];

    const outerRadius = h / 2;
    const innerRadius = outerRadius / 2;

    const arc = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius);

    // Initiate the d3 pie chart class
    const pieChart = d3
      .pie()
      .value((d, i) => {
        return dataset[i].answer;
      })
      .sort(null)
      .padAngle(0.03);

    // Set up the environment where the pie chart will be drawn
    const svg = d3
      .select(el)
      .append('svg')
      .attr('width', w)
      .attr('height', h)
      .attr('class', 'shadow')
      .append('g')
      .attr('transform', `translate(${h / 2},${h / 2})`);

    // Make a path for the transition to be filled in
    const path = svg
      .selectAll('path')
      .data(pieChart(dataset))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => {
        return color[i];
      });

    // Animate the incoming pie chart
    path.transition &&
      path
        .transition()
        .duration(FULL_ANIMATION_STEP)
        .attrTween('d', d => {
          const interpolate = d3.interpolate(
            {
              startAngle: 0,
              endAngle: 0
            },
            d
          );
          return function (t) {
            return arc(interpolate(t));
          };
        });

    const createLegendAndNumbers = () => {
      svg.transition &&
        svg
          .selectAll('text')
          .data(pieChart(dataset))
          .enter()
          .append('text')
          .transition()
          .duration(SMALL_ANIMATION_STEP)
          .attr('transform', d => {
            return `translate(${arc.centroid(d)})`;
          })
          .attr('dy', '.4em')
          .attr('text-anchor', 'middle')
          .text((d, i) => {
            return dataset[i].answer > 0 ? dataset[i].answer : '';
          })
          .style('fill', '#fff')
          .style('font-size', '12px');

      const legendRectSize = 15;
      const legendSpacing = 5;
      const legendHeight = legendRectSize + legendSpacing;

      const legend = svg
        .selectAll('.legend')
        .data(color)
        .enter()
        .append('g')
        .attr('class', 'legend')
        .attr('transform', (d, i) => {
          //Just a calculation for x & y position
          return 'translate(' + (8 + h / 2) + ',' + (i * legendHeight - 17.5) + ')';
        });

      // Initiate the legend objects
      legend
        .append('rect')
        .attr('width', legendRectSize)
        .attr('height', legendRectSize)
        .attr('rx', 20)
        .attr('ry', 10)
        .style('fill', (d, i) => {
          return color[i];
        })
        .style('stroke', (d, i) => {
          return color[i];
        });

      // Put the text next to the legend
      legend
        .append('text')
        .attr('x', 20)
        .attr('y', 12.5)
        .text((d, i) => {
          return dataset[i].label;
        })
        .style({
          fill: '#929DAF',
          'font-size': '14px'
        });
    };

    // Wait for pie chart to finish drawing before initiating legend etc.
    setTimeout(createLegendAndNumbers, FULL_ANIMATION_STEP);
  };

  update = data => {
    this.destroy();
    this.create(this.rootElement, data);
  };

  destroy = () => {
    d3.selectAll(this.rootElement.childNodes).remove();
  };
}

export default D3Pie;
