import {defineMessages} from 'react-intl';

const messages = defineMessages({
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  title: {
    id: 'frequencySettingsTitle',
    defaultMessage: 'Frequency settings'
  },
  alwaysShowOption: {
    id: 'alwaysShowOption',
    defaultMessage: 'Show always on selected instructions'
  },
  customWaitOption: {
    id: 'customWaitOption',
    defaultMessage: 'Wait {inputs} to show again after user has submitted this sign-off'
  },
  editSuccess: {
    id: 'SignOff.editSuccessFrequencySettings',
    defaultMessage: 'The frequency settings have been edited.'
  },
  editFailure: {
    id: 'SignOff.editFailureFrequencySettings',
    defaultMessage: 'Failed to edit the frequency settings.'
  }
});

export default messages;
