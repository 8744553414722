import {Slider} from 'antd';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//styled-components
import {CampaignPreviewList} from './styles';

//lodash
import isEmpty from 'lodash/isEmpty';

//components
import StackSpace from 'ui-components/Layout/StackSpace';
import {FormattedMessage} from 'components/FormattedComponents';
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import ViewModePicker from 'components/ViewModePicker';
import CampaignQuestion from 'shared/components/CampaignQuestion';
import RatioContainer from 'shared/components/RatioContainer';

//messages
import messages from './messages';

const landscapeRatio = [8, 3];

@inject('store')
@observer
class CampaignPreview extends Component {
  render() {
    const {
      store: {campaignPreviewDialog, newCampaignPage},
      intl: {formatMessage},
      isNewSignOff
    } = this.props;

    const signOffStore = isNewSignOff ? newCampaignPage : campaignPreviewDialog;
    const {followUps, setViewMode, isMobileView, textTypeOptions, questionForm} = signOffStore;

    const question = {
      ...questionForm.values(),
      textTypeOptions: textTypeOptions ? textTypeOptions.map(opt => opt.form.values().option) : []
    };

    const questionProps = {
      device: isMobileView ? 'mobile' : 'desktop',
      disableModal: true,
      SliderComponent: Slider
    };

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.preview)}
          right={<ViewModePicker isMobileView={isMobileView} setViewMode={setViewMode} />}
        />
        <CampaignPreviewList>
          <h3>
            <FormattedMessage {...messages.question} />
          </h3>
          <StackSpace />
          <RatioContainer ratio={landscapeRatio}>
            <CampaignQuestion {...questionProps} question={question} step="question" />
          </RatioContainer>
          <StackSpace />
          {!isEmpty(followUps) && (
            <h3>
              <FormattedMessage {...messages.followUpQuestion} />
            </h3>
          )}
          {!isEmpty(followUps) && <StackSpace />}
          {!isEmpty(followUps) &&
            followUps.map((fu, index) => {
              return (
                <RatioContainer key={index} ratio={landscapeRatio}>
                  <CampaignQuestion {...questionProps} followUpQuestion={fu.form.values()} step="follow-up-question" />
                </RatioContainer>
              );
            })}
          <StackSpace />
          <h3>
            <FormattedMessage {...messages.thankYouMessage} />
          </h3>
          <StackSpace />
          <RatioContainer ratio={landscapeRatio}>
            <CampaignQuestion {...questionProps} step="thank-you" />
          </RatioContainer>
          <StackSpace />
        </CampaignPreviewList>
      </Container>
    );
  }
}

export default injectIntl(CampaignPreview);
