import React from 'react';
import {TableInfoDiv, TableOverlay} from './styles';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';

type LoadingProps = {
  loading: boolean;
  message: any;
};

const PaginationLoading = (props: LoadingProps) => {
  const {loading, message} = props;

  if (!loading) return null;

  return (
    <>
      <TableInfoDiv absolutePosition={true}>
        <LoadingMessage>
          <FormattedMessage {...message} />
        </LoadingMessage>
      </TableInfoDiv>
      <TableOverlay />
    </>
  );
};

export default PaginationLoading;
