import React, {Component} from 'react';
import equal from 'fast-deep-equal';

import {CropRect, ResizeRect, ResizeHandleV, ResizeHandleH, FocusPointHandle} from './styles';

const getMaxMin = (value, {max, min}) => {
  return Math.max(Math.min(value, max), min);
};

class Draggers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dir: null
    };
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props, prevProps)) {
      const {width, height, offsetX, offsetY, crop, focus, contain, isSvg} = this.props;
      if (crop) {
        crop.x2 = crop.x + crop.w;
        crop.y2 = crop.y + crop.h;
      }
      this.setState({
        width,
        height,
        offsetX,
        offsetY,
        crop,
        focus,
        contain,
        isSvg
      });
    }
  }
  componentWillUnmount() {
    this.detachGlobalEvents();
  }
  attachGlobalEvents = () => {
    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);

    const {croppingStartHandler} = this.props;
    if (croppingStartHandler && typeof croppingStartHandler === 'function') {
      croppingStartHandler();
    }
  };
  detachGlobalEvents = () => {
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);

    const {croppingStopHandler} = this.props;
    const {crop} = this.state;
    if (croppingStopHandler && typeof croppingStopHandler === 'function') {
      croppingStopHandler(crop);
    }
  };
  handleMouseUp = () => {
    this.detachGlobalEvents();
  };
  handleMouseMove = event => {
    const {clientX, clientY} = event;
    this.setFocusFromMouseEvent(clientX, clientY);
  };
  handleMouseDown = (event, dir) => {
    event.preventDefault();
    const {clientX, clientY} = event;
    this.setState({dir}, () => {
      this.attachGlobalEvents();
      this.setFocusFromMouseEvent(clientX, clientY);
    });
  };
  getRelativeMousePosition = (clientX, clientY) => {
    const bounds = this.wrapper.getBoundingClientRect();
    const boundsX = bounds.x || bounds.left;
    const boundsY = bounds.y || bounds.top;
    return {
      x: clientX - boundsX,
      y: clientY - boundsY
    };
  };
  setFocusFromMouseEvent = (clientX, clientY) => {
    const {x: mouseX, y: mouseY} = this.getRelativeMousePosition(clientX, clientY);
    const {width, height, croppingHandler} = this.props;
    const {dir, crop} = this.state;
    const minSize = 10;
    const updatedCrop = {};
    const updatedFocus = {};

    if (dir === 'e') {
      updatedCrop.x2 = getMaxMin(mouseX, {min: crop.x + minSize, max: width});
    } else if (dir === 's') {
      updatedCrop.y2 = getMaxMin(mouseY, {min: crop.y + minSize, max: height});
    } else if (dir === 'w') {
      updatedCrop.x = getMaxMin(mouseX, {min: 0, max: crop.x2 - minSize});
    } else if (dir === 'n') {
      updatedCrop.y = getMaxMin(mouseY, {min: 0, max: crop.y2 - minSize});
    } else if (dir === 'f') {
      const realX = getMaxMin(mouseX - crop.x, {min: 0, max: crop.w});
      const realY = getMaxMin(mouseY - crop.y, {min: 0, max: crop.h});
      updatedFocus.kx = Math.round((100 / crop.w) * realX) / 100;
      updatedFocus.ky = Math.round((100 / crop.h) * realY) / 100;
    }

    const mergedCrop = {...this.state.crop, ...updatedCrop};
    const mergedFocus = {...this.state.focus, ...updatedFocus};

    mergedCrop.w = mergedCrop.x2 - mergedCrop.x;
    mergedCrop.h = mergedCrop.y2 - mergedCrop.y;

    this.setState({crop: mergedCrop, focus: mergedFocus}, () => {
      if (croppingHandler && typeof croppingHandler === 'function') {
        croppingHandler(mergedCrop, mergedFocus);
      }
    });
  };

  render() {
    const {width, height, offsetX, offsetY, crop, focus, contain = false, isSvg} = this.state;
    if (!crop || !width) {
      return null;
    }
    return (
      <ResizeRect
        ref={ref => (this.wrapper = ref)}
        style={{
          left: offsetX,
          top: offsetY,
          width: width,
          height: height
        }}
      >
        {focus && !contain && (
          <div>
            <CropRect
              style={{
                left: crop.x,
                width: crop.w,
                top: crop.y,
                height: crop.h
              }}
              onMouseDown={e => this.handleMouseDown(e, 'f')}
            >
              <FocusPointHandle
                style={{
                  left: `${focus.kx * 100}%`,
                  top: `${focus.ky * 100}%`
                }}
              />
            </CropRect>
          </div>
        )}
        {!isSvg && (
          <div>
            <ResizeHandleH
              style={{left: crop.x, top: crop.y + crop.h / 2}}
              onMouseDown={e => this.handleMouseDown(e, 'w')}
            />
            <ResizeHandleH
              style={{
                left: crop.x + crop.w,
                top: crop.y + crop.h / 2
              }}
              onMouseDown={e => this.handleMouseDown(e, 'e')}
            />
            <ResizeHandleV
              style={{left: crop.x + crop.w / 2, top: crop.y}}
              onMouseDown={e => this.handleMouseDown(e, 'n')}
            />
            <ResizeHandleV
              style={{left: crop.x + crop.w / 2, top: crop.y + crop.h}}
              onMouseDown={e => this.handleMouseDown(e, 's')}
            />
          </div>
        )}
      </ResizeRect>
    );
  }
}

export default Draggers;
