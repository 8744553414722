import draftToHtml from 'draftjs-to-html';
import {action, observable, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {findDOMNode} from 'react-dom';
import {injectIntl} from 'react-intl';

//lodash
import every from 'lodash/every';
import get from 'lodash/get';
import includes from 'lodash/includes';
import invoke from 'lodash/invoke';

//helpers
import views from 'config/views';
import notification from 'utils/notification-utils';
import {ensureDraftVersion} from 'utils/versioning-utils';

//components
import StepsMenu from 'components/StepList/StepsMenu';
import ChecklistIndicatorIcons from 'shared/components/ChecklistIndicatorIcons';

//helpers
import {MEDIA_PREVIEW_TYPE} from 'config/enums';
import {editorHasChanges, convertHtmlToDraft} from 'utils/data-utils';

//styles
import {
  InnerWrapper,
  StepCard,
  StepNumber,
  StyledCardToolbar,
  StyledCoverRatioContainer,
  StyledDragHandle,
  StyledEditor,
  StyledLink,
  StyledMediaPicker,
  StepContentWrapper,
  StepContentEditorWrapper,
  StyledStepContent,
  StyledButton,
  StyledPencilIcon,
  ButtonWrapper
} from './styles';

//messages
import messages from './messages';

//data
import {updateStepOptions} from 'api/step/mutation-options';
import {UpdateStep} from 'api/step/mutations';
import {SignMedia, SaveMedia} from 'api/media/mutations';
import {signMediaOptions, saveMediaOptions} from 'api/media/mutation-options';

//form
import NewSlideForm from 'stores/forms/new-slide-form';
import {bindField} from 'shared/utils/input-utils';
import {captureException} from 'raven-js';

@inject('store')
@graphql(SignMedia, signMediaOptions)
@graphql(SaveMedia, saveMediaOptions)
@graphql(UpdateStep, updateStepOptions)
@observer
class StepCardComponent extends Component {
  static defaultProps = {
    design: 'default'
  };

  static propTypes = {
    design: PropTypes.oneOf(['default', 'ghostly']),
    onDragHandleMouseDown: PropTypes.func
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable isContentHovered = false;
  @observable isEditing = false;
  editorRef = null;
  form = NewSlideForm();

  UNSAFE_componentWillMount() {
    this.hydrateFormFromProps();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      this.hydrateFormFromProps();
    }
  }

  get currentInstructionHtml() {
    const instruction = this.form.$('instruction').value;
    const contentState = JSON.parse(instruction);
    return draftToHtml(contentState);
  }

  cancelChanges = () => {
    const {store} = this.props;
    const {unsavedChangesDialog: dialog} = store;

    this.hydrateFormFromProps();
    this.stopEditing();
    dialog.reset();
  };

  handleContentClick = event => {
    event.preventDefault();
    event.stopPropagation();

    this.setIsContentHovered(false);
    ensureDraftVersion(this.startEditing, this)();
  };

  @action
  startEditing = () => {
    this.isEditing = true;
  };

  @action
  stopEditing = () => {
    this.isEditing = false;
  };

  handleContentHover = event => {
    this.setIsContentHovered(event.type === 'mouseenter');
  };

  handleEditorClickOutside = () => {
    const {store} = this.props;
    const {unsavedChangesDialog: dialog} = store;

    dialog.check().then(() => {
      this.stopEditing();
    });
  };

  handleEditorChange = event => {
    const {step, store} = this.props;
    const {unsavedChangesDialog: dialog} = store;

    const currentValue = event.target.value;
    const hasChanges = editorHasChanges(step.instruction, currentValue);
    if (!hasChanges) {
      return dialog.reset();
    }

    dialog.saveAction = this.updateStep;
    dialog.onCancel = () => {
      this.editorRef.focus();
    };
    dialog.onDiscard = this.cancelChanges;
  };

  hydrateFormFromProps = () => {
    const {step} = this.props;
    const {instructionHtml, media} = step;

    this.form.update({
      instruction: convertHtmlToDraft(instructionHtml),
      mediaId: get(media, 'id')
    });
  };

  saveChanges = () => {
    return this.updateStep();
  };

  setEditorRef = ref => {
    this.editorRef = ref;
  };

  setHostInnerRef = ref => {
    invoke(this.props, 'hostRef', findDOMNode(ref));
  };

  @action
  setIsContentHovered = isContentHovered => {
    this.isContentHovered = isContentHovered;
  };

  stopPropagation = event => {
    event.stopPropagation();
  };

  updateMedia = media => {
    const {step, updateStepMutation} = this.props;
    const {id} = step;

    const mediaId = get(media, 'id', null);

    return updateStepMutation({id, step: {mediaId}});
  };

  updateStep = () => {
    const {step, updateStepMutation, intl, store} = this.props;
    const {id} = step;
    const {formatMessage} = intl;
    const {
      unsavedChangesDialog: dialog,
      editInstructionDetailsPage: {isChecklist}
    } = store;

    const values = this.form.values();
    const stepToUpdate = {
      contentHtml: draftToHtml(JSON.parse(values.instruction))
    };

    return updateStepMutation({
      id,
      step: stepToUpdate
    })
      .then(() => {
        this.stopEditing();
        dialog.reset();
        notification.success(formatMessage(isChecklist ? messages.updateCheckSuccess : messages.updateStepSuccess));
        return Promise.resolve(stepToUpdate);
      })
      .catch(e => {
        captureException(e);
        notification.error(formatMessage(isChecklist ? messages.updateCheckError : messages.updateStepError));
      });
  };

  /**
   * After clone move to the newly created step
   */
  onClone = createdStep => {
    const {store} = this.props;
    const {router} = store;
    const {id, instructionId, topicId} = router.params;

    router.goTo(views.editInstruction, {
      id,
      instructionId,
      stepId: createdStep.id,
      topicId
    });
  };

  render() {
    const {design, dragHandleRef, index, onDragHandleMouseDown, step, store, intl} = this.props;
    const {
      dragDropSteps: page,
      editInstructionDetailsPage,
      saveAsDraftDialog,
      router,
      platform: {
        developmentFeatureFlags: {checklistScoring}
      }
    } = store;
    const {formatMessage} = intl;
    const {id, instructionId, topicId} = router.params;
    const {guide, isChecklist} = editInstructionDetailsPage;
    const mediaUrl = get(step, 'media.url');
    const metadata = get(step, 'media.metadata');
    const hasMedia = Boolean(mediaUrl);
    const canTranslate = guide && guide.canTranslate;

    const isFullyTranslated = every(get(guide, 'availableTranslations'), translation =>
      includes(get(step, 'providedTranslations'), translation)
    );

    return (
      <StepCard className="sync-card-hover-with-child-component" design={design} ref={this.setHostInnerRef}>
        <InnerWrapper isInvisible={design === 'ghostly'}>
          <StyledDragHandle
            forwardedRef={dragHandleRef}
            isDisabled={page.submitting}
            onMouseDown={onDragHandleMouseDown}
          />
          <StyledCoverRatioContainer>
            <StyledMediaPicker
              canOpen={() => saveAsDraftDialog.check()}
              focus={this.isEditing}
              backgroundUrl={mediaUrl}
              backgroundMetadata={metadata}
              form={this.form}
              field="mediaId"
              iconSize={70}
              previewType={MEDIA_PREVIEW_TYPE.STEP_OVERVIEW}
              shouldShowOverlayShapes={true}
              updateMedia={this.updateMedia}
              allowVideos={true}
              toolbar={
                <StyledCardToolbar
                  dropdownMenu={
                    <StepsMenu
                      canTranslate={canTranslate}
                      isOverview={true}
                      step={step}
                      onClone={this.onClone}
                      shouldShowUntranslatedIcon={!isFullyTranslated}
                    />
                  }
                  isInverted={hasMedia}
                  shouldShowUntranslatedIcon={!isFullyTranslated}
                />
              }
            />
          </StyledCoverRatioContainer>
          <StepNumber>{index + 1}</StepNumber>
          {this.isEditing ? (
            <StepContentEditorWrapper onClickOutside={this.handleEditorClickOutside}>
              <StyledEditor
                autoFocus={true}
                editorRef={this.setEditorRef}
                onCancel={this.cancelChanges}
                onSave={this.saveChanges}
                shouldCollapseMargins={true}
                shouldHideFormattingTools={true}
                shouldSaveOnReturn={true}
                {...bindField(this.form, 'instruction', {
                  onChangeReaction: this.handleEditorChange
                })}
              />
            </StepContentEditorWrapper>
          ) : (
            <StyledLink
              params={{
                id,
                instructionId,
                stepId: step.id,
                topicId
              }}
              queryParams={router.queryParams}
              store={store}
              route={views.editInstruction}
            >
              <StepContentWrapper
                onMouseEnter={this.handleContentHover}
                onMouseLeave={this.handleContentHover}
                onMouseOut={this.stopPropagation}
                onMouseOver={this.stopPropagation}
                onClick={this.handleContentClick}
              >
                <StyledStepContent
                  dangerouslySetInnerHTML={{__html: this.currentInstructionHtml}}
                  isCompact={true}
                  isHovered={this.isContentHovered}
                  isResponsive={false}
                  isScrollable={false}
                />
                <StyledPencilIcon />
              </StepContentWrapper>
              <ButtonWrapper isChecklist={isChecklist} checklistScoring={checklistScoring}>
                {isChecklist && !checklistScoring && <ChecklistIndicatorIcons position="left" size="small" />}
                <StyledButton secondary label={formatMessage(isChecklist ? messages.editCheck : messages.editStep)} />
              </ButtonWrapper>
            </StyledLink>
          )}
        </InnerWrapper>
      </StepCard>
    );
  }
}

export default injectIntl(StepCardComponent);
