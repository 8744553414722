import {TranslationRate} from 'api/translations/queries';

export const refreshTranslationsRateQuery = id => {
  return {
    query: TranslationRate,
    variables: {
      id
    }
  };
};
