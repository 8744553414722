import PropTypes from 'prop-types';
import React, {Component} from 'react';
import uniqid from 'uniqid';

//helpers
import {styleable} from 'shared/decorators';

//styles
import {Caption, Checkbox, ClickableArea, CheckboxButton, Checkmark} from './styles';

@styleable
class CheckboxComponent extends Component {
  static defaultProps = {
    checked: false,
    design: 'default',
    disabled: false,
    dataCy: 'checkbox'
  };

  static propTypes = {
    caption: PropTypes.node,
    checked: PropTypes.bool,
    design: PropTypes.oneOf(['default', 'fill-line']),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    dataCy: PropTypes.string,
    height: PropTypes.number
  };

  render() {
    const {caption, checked, design, disabled, onChange, dataCy, dataTestId, height} = this.props;
    const hasCaption = Boolean(caption);
    const captionId = hasCaption && uniqid();

    return (
      <CheckboxButton design={design} height={height}>
        <ClickableArea
          disabled={disabled}
          data-cy={dataCy}
          data-testid={`${dataTestId}-${checked}`}
          {...(!disabled && {onClick: onChange})}
        >
          <Checkbox role="checkbox" aria-checked={checked} {...(hasCaption && {'aria-labelledby': captionId})}>
            {checked && <Checkmark dataCy={`${dataCy}-checked`} />}
          </Checkbox>
          {hasCaption && <Caption id={captionId}>{caption}</Caption>}
        </ClickableArea>
      </CheckboxButton>
    );
  }
}

export default CheckboxComponent;
