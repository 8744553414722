import {defineMessages} from 'react-intl';

const messages = defineMessages({
  assignedTo: {
    id: 'GuideCard.assignedTo',
    defaultMessage: 'Assigned to'
  },
  team: {
    id: 'GuideCard.team',
    defaultMessage: 'Team'
  },
  unpublished: {
    id: 'GuideCard.unpublished',
    defaultMessage: 'Unpublished'
  },
  approval: {
    id: 'GuideCard.approval',
    defaultMessage: 'Approval required'
  },
  draftCount: {
    id: 'GuideCard.draftCount',
    defaultMessage: '{count, number} {count, plural, one {draft} other {drafts}}'
  },
  publishedCount: {
    id: 'GuideCard.publishedCount',
    defaultMessage: '{count} published'
  },
  instructionCount: {
    id: 'GuideCard.instructionCount',
    defaultMessage: '{count, number} {count, plural, one {instruction} other {instructions}}'
  },
  liveVersionExpired: {
    id: 'GuideCard.liveVersionExpired',
    defaultMessage: 'Live version expired'
  },
  liveVersionRejected: {
    id: 'GuideCard.liveVersionRejected',
    defaultMessage: 'Live version rejected'
  }
});

export default messages;
