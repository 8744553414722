import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {ICON_TYPES} from 'config/enums';
import {commonPalette} from 'shared/styles/palette';
import Dialog from 'ui-components/Dialog';
import LanguagePicker from 'components/LanguagePicker';
import Spinner from 'shared/components/Spinner';

import {generatePdf} from './Pdf/generatePdf';

import messages from './messages';
import {SelectLanguageWrapper, StyledInfoText, LanguagePickerWrapper, SpinnerWrapper} from './styles';

@inject('store')
@observer
class ExportToPdfDialogComponent extends Component {
  componentWillUnmount = () => {
    const {
      store: {
        exportToPdfDialog: {setHideLanguageSelector}
      }
    } = this.props;

    setHideLanguageSelector(false);
  };

  render() {
    const {
      store: {
        exportToPdfDialog: {
          opened,
          reset,
          params,
          error,
          availableLanguages,
          selectedLanguage,
          setSelectedLanguage,
          hideLanguageSelector
        }
      },
      intl: {formatMessage}
    } = this.props;
    const {
      pdfExportTo,
      pdfConsiderEnvironment,
      pdfInfoHeader,
      pdfInfoDescription,
      pdfErrorDescription,
      pdfErrorHeader,
      pdfErrorIsAlreadyGenerating,
      pdfGenerate,
      ok,
      close,
      language,
      publishedInMultipleLanguage
    } = messages;

    const actions =
      params.confirmation || params.loading
        ? []
        : error
        ? []
        : [
            {
              label: formatMessage(pdfGenerate),
              onClick: this.handleSubmit,
              disabled: false
            }
          ];
    const textHeader = formatMessage(params.confirmation ? pdfInfoHeader : pdfExportTo);
    const textDescription = params.loadingAvailableLanguages ? (
      <SpinnerWrapper>
        <Spinner centerAbs centerX centerY size={23} />
      </SpinnerWrapper>
    ) : (
      formatMessage(params.confirmation ? pdfInfoDescription : pdfConsiderEnvironment)
    );
    const errorHeader = formatMessage(pdfErrorHeader);
    const errorDescription = formatMessage(params.loading ? pdfErrorIsAlreadyGenerating : pdfErrorDescription);

    const title = error ? errorHeader : textHeader;
    const titleIconId = error ? ICON_TYPES.WARNING : ICON_TYPES.EXPORT_TO_PDF;
    const iconColor = error ? commonPalette.red : commonPalette.sgGreen;
    const description = error ? (error.errorMessage ? error.errorMessage : errorDescription) : textDescription;
    const customCancelButtonText = error ? close : params.confirmation && ok;
    const shouldShowLanguageSelector =
      !params.loadingAvailableLanguages && !hideLanguageSelector && !error && availableLanguages.length > 1;

    return (
      <Dialog
        hideActions={params.loadingAvailableLanguages}
        actions={actions}
        isOpen={opened}
        onCancel={reset}
        title={title}
        titleIconId={titleIconId}
        iconColor={iconColor}
        customCancelButtonText={customCancelButtonText}
      >
        <div>{description}</div>
        {shouldShowLanguageSelector && (
          <SelectLanguageWrapper languageOptions={availableLanguages.length}>
            <StyledInfoText>{formatMessage(publishedInMultipleLanguage)}</StyledInfoText>
            <LanguagePickerWrapper>
              <LanguagePicker
                label={formatMessage(language)}
                languages={availableLanguages}
                hidePlaceholder={true}
                changeLanguage={language => setSelectedLanguage(language)}
                selectedLanguage={selectedLanguage}
              />
            </LanguagePickerWrapper>
          </SelectLanguageWrapper>
        )}
      </Dialog>
    );
  }

  handleSubmit = async () => {
    const {
      store: {
        editGuidePage: {
          versions,
          guide: {id: guideId}
        },
        exportToPdfDialog: {open, setError, setLoadingPdf, setHideLanguageSelector, selectedLanguage},
        platform: {name: platformName},
        app: {
          theme: {icons}
        }
      },
      intl
    } = this.props;

    const lastVersion = versions[0];

    setHideLanguageSelector(true);
    open({params: {confirmation: true}});

    try {
      setLoadingPdf(true);

      const [pdfMake] = await Promise.all([import(/* webpackChunkName: "pdflib" */ 'pdfmake/build/pdfmake')]);

      await generatePdf(guideId, selectedLanguage, lastVersion, intl, platformName, icons, setError, {
        pdfMake
      });
    } catch (err) {
      setError(err);
    } finally {
      setLoadingPdf(false);
    }
  };
}

export default injectIntl(ExportToPdfDialogComponent);
