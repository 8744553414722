import styled from 'styled-components';

export const ViewModePickerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-items: space-around;
  float: right;
`;

export const ViewModePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-items: space-around;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  border-bottom: 2px solid #d7d7d7;
  height: 100%;
`;

export const Button = styled.button`
  background-color: #fff;
  color: #d7d7d7;
  border-radius: 3px;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  ${p =>
    p.selected &&
    `
    background-color: #E6E6E6;
    color: #505050;
  `}
`;

export const Label = styled.h4`
  margin-right: 20px;
`;
