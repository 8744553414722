import get from 'lodash/get';

export const validateResetPasswordLinkOptions = {
  options: ({store}) => {
    const {token} = store.router.queryParams;
    return {
      variables: {token},
      fetchPolicy: 'network-only'
    };
  }
};

export const validateSetPasswordLinkOptions = {
  options: ({store}) => {
    const {token} = store.router.queryParams;
    return {
      variables: {token},
      fetchPolicy: 'network-only'
    };
  }
};

export const userOptions = {
  options: ({store}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        id: store.router.params.id
      }
    };
  }
};

export const userInfoOptions = {
  options: ({store}) => {
    const idToQuery = store.auth.user.id;
    return {
      fetchPolicy: 'network-only',
      variables: {
        id: idToQuery
      }
    };
  }
};

export const usersOptions = {
  options: () => ({
    fetchPolicy: 'network-only'
  })
};

export const assigneesOptions = {
  options: () => ({
    fetchPolicy: 'network-only'
  }),
  name: 'assigneesQuery'
};

export const userNamesOptions = {
  options: () => ({
    fetchPolicy: 'network-only'
  }),
  name: 'userNamesQuery'
};

export const checkUserDeletionOptions = {
  options: ({store}) => {
    const {userId} = store.deleteUserDialog;

    return {
      fetchPolicy: 'no-cache',
      variables: {
        userId
      }
    };
  },
  name: 'checkUserDeletionQuery'
};

export const checkUserArchivingOptions = {
  options: ({store}) => {
    const {userId} = store.archiveUserDialog;

    return {
      fetchPolicy: 'no-cache',
      variables: {
        userId
      }
    };
  },
  name: 'checkUserArchivingQuery'
};

export const getUserQueryOptions = {
  options: ({store}) => {
    const userId = get(store.deleteUserDialog, 'userId') || get(store.archiveUserDialog, 'userId');

    return {
      fetchPolicy: 'network-only',
      variables: {
        id: userId
      }
    };
  },
  name: 'getUserQuery'
};
