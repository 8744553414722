import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//data
import {CreateGuide} from 'api/guide/mutations';
import {createGuide} from 'api/guide/mutation-options';

//components
import GuideDetails from 'components/GuideDetails';

@inject('store')
@graphql(CreateGuide, createGuide)
class NewGuideViewComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      store: {newGuidePage, platform}
    } = this.props;
    newGuidePage.setDefaultLocale(platform.getLanguage());
  }

  render() {
    const {createGuideMutation} = this.props;
    return <GuideDetails submitMutation={createGuideMutation} guideStore={this.props.store.newGuidePage} />;
  }
}

export default NewGuideViewComponent;
