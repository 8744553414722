import styled from 'styled-components';

const PADDINGS = {
  INSTRUCTION: 156,
  TOPIC: 0
};

export const PositionedBox = styled.div`
  left: 0;
  padding-right: ${p => PADDINGS[p.itemType]}px;
  pointer-events: none;
  position: fixed;
  top: 0;
  z-index: 9999;
`;
