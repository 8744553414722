import {
  refreshGuide,
  refreshGuideAssignee,
  refreshGuides,
  refreshDraftGuide,
  refreshGuideDomains,
  refreshGuideWorkspaces
} from './refresh-queries';
import {refreshTranslationsRateQuery} from 'api/translations/refresh-queries';
import get from 'lodash/get';

export const createGuide = {
  props: ({mutate}) => ({
    createGuideMutation: variables => {
      variables.guide.availableTranslations = [variables.guide.defaultLocale];
      return mutate({
        variables,
        refetchQueries: [refreshGuides()]
      });
    }
  })
};

export const archiveGuide = {
  props: ({mutate}) => ({
    archiveGuideMutation: variables => {
      return mutate({
        variables
      });
    }
  })
};

export const archiveGuideFromList = {
  props: ({mutate, ownProps: {guidesData}}) => ({
    archiveGuideFromListMutation: variables => {
      return mutate({
        variables
      }).then(() => guidesData.refetch());
    }
  })
};

export const publishGuideFromList = {
  props: ({mutate}) => ({
    publishGuideFromListMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuides(), refreshGuide(variables.id), refreshDraftGuide(variables.id)]
      });
    }
  })
};

export const cloneGuide = {
  props: ({mutate}) => ({
    cloneGuideMutation: variables => {
      return mutate({
        variables
      });
    }
  })
};

export const cloneGuideFromList = {
  props: ({mutate}) => ({
    cloneGuideFromListMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuides()]
      });
    }
  })
};

export const copyToGuide = {
  props: ({mutate, ownProps: {store}}) => ({
    copyGuideMutaion: variables => {
      variables.teamId = store.copyToDialog.teamCopyToForm.values().teamId;

      return mutate({
        variables
      });
    }
  })
};

export const setGuideToDraftFromList = {
  props: ({mutate}) => ({
    setGuideToDraftFromListMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuides(), refreshGuide(variables.id), refreshDraftGuide(variables.id)]
      });
    }
  })
};

export const updateGuide = {
  props: ({mutate, ownProps: {store}}) => ({
    updateGuideMutation: variables => {
      variables.guide = Object.assign({}, variables.guide, {locale: variables.guide.defaultLocale});
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id), refreshGuideDomains(id), refreshDraftGuide(id)]
      });
    }
  })
};

export const publishGuide = {
  props: ({mutate, ownProps: {store}}) => ({
    publishGuideMutation: variables => {
      const id = get(store.router, 'params.id', null);
      const versionId = get(store.router, 'queryParams.v', null);
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id, versionId), refreshDraftGuide(id), refreshGuideWorkspaces(id)]
      });
    }
  })
};

export const setGuideToDraft = {
  props: ({mutate, ownProps: {store}}) => ({
    setGuideToDraftMutation: variables => {
      const id = get(store.router, 'params.id', null);
      const versionId = get(store.router, 'queryParams.v', null);
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id, versionId), refreshDraftGuide(id)]
      });
    }
  })
};

export const submitGuideApprovalReviewOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    submitGuideApprovalReviewMutation: variables => {
      const {id} = store.editGuidePage.guide;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id), refreshDraftGuide(id)]
      });
    }
  })
};

export const submitLiveVersionReviewOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    submitLiveVersionReviewMutation: variables => {
      const {id} = store.editGuidePage.guide;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id), refreshDraftGuide(id)]
      });
    }
  })
};

export const reassignGuideOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    reassignGuideMutation: variables => {
      const {guideId} = store.reassignGuideDialog;
      return mutate({
        variables,
        refetchQueries: [refreshGuides(), refreshGuideAssignee(guideId)]
      });
    }
  })
};

export const addTranslationsToGuide = {
  props: ({mutate, ownProps: {store}}) => ({
    addTranslationsToGuideMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [refreshTranslationsRateQuery(id)]
      });
    }
  })
};

export const removeTranslationFromGuide = {
  props: ({mutate, ownProps: {store}}) => ({
    removeTranslationFromGuideMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [refreshTranslationsRateQuery(id)]
      });
    }
  })
};

export const updateGuideMediaOptions = {
  props: ({mutate}) => ({
    updateGuideMediaMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuide(variables.id), refreshDraftGuide(variables.id)]
      });
    }
  })
};

export const restoreVersionOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    restoreVersionMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables: {...variables, guideId: id},
        refetchQueries: [refreshDraftGuide(id)],
        awaitRefetchQueries: true
      });
    }
  })
};

export const requestGuideApprovalOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    requestGuideApprovalMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id)]
      });
    }
  })
};

export const withdrawGuideApprovalOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    withdrawGuideApprovalMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id)]
      });
    }
  })
};

export const shareToBeekeeperOptions = {
  props: ({mutate}) => ({
    shareToBeekeeperMutation: variables => {
      return mutate({
        variables
      });
    }
  })
};
