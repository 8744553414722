import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import views from 'config/views';

import CampaignResults from 'components/CampaignResults';

@inject('store')
@observer
class CampaignResultsView extends Component {
  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      auth: {
        user: {canManageCampaigns}
      },
      router,
      signoffResultsPage
    } = store;
    if (!canManageCampaigns) {
      router.goTo(views.root, {});
    }
    signoffResultsPage.init();
  }
  render() {
    const {
      store: {signoffResultsPage}
    } = this.props;

    return <CampaignResults customFilterPayload={signoffResultsPage.customFilterPayload} />;
  }
}

export default CampaignResultsView;
