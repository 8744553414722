import {defineMessages} from 'react-intl';

const messages = defineMessages({
  newTopic: {
    id: 'NewTopic.title',
    defaultMessage: 'New topic'
  },
  showTitle: {
    id: 'EditTopic.showTitle',
    defaultMessage: 'Show title'
  },
  collapseTitle: {
    id: 'EditTopic.collapseTitle',
    defaultMessage: 'Show topic collapsed'
  },
  hideTitle: {
    id: 'EditTopic.hideTitle',
    defaultMessage: 'Hide title'
  },
  saveTopic: {
    id: 'EditTopic.save',
    defaultMessage: 'Save topic'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  createSuccess: {
    id: 'NewTopic.createSuccess',
    defaultMessage: 'Topic has been created.'
  },
  updateSuccess: {
    id: 'EditTopic.updateSuccess',
    defaultMessage: 'Topic has been updated.'
  },
  updateError: {
    id: 'EditTopic.updateError',
    defaultMessage: 'Error updating topic.'
  }
});

export default messages;
