import styled from 'styled-components';
import $Field from 'ui-components/Field';

export const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CreateUserPage = styled.div`
  padding-top: 24px;
`;

export const FormColumn = styled.div`
  margin-right: 30px;
  max-width: 350px;
  min-width: 300px;
  flex: 1;
`;

export const Field = styled($Field)`
  width: 100%;
`;

export const Caption = styled.div``;

export const UsernameWrapper = styled.div`
  padding-left: ${p => (p.indent ? '16px' : '0')};
`;

export const contactMethodPickerStyles = {
  marginBottom: 33
};
