export const languagesList = [
  {
    short: 'EN',
    locale: 'en_US',
    language: 'English',
    languageInEnglish: 'English (USA)'
  },
  {
    short: 'PT',
    locale: 'pt_PT',
    language: 'Português',
    languageInEnglish: 'Portuguese (Portugal)'
  },
  {
    short: 'SV',
    locale: 'sv_SE',
    language: 'Svenska',
    languageInEnglish: 'Swedish (Sweden)'
  },
  {
    short: 'ZH',
    locale: 'zh_CN',
    language: '中文',
    languageInEnglish: 'Chinese (China)'
  },
  {
    short: 'NL',
    locale: 'nl_NL',
    language: 'Nederlands',
    languageInEnglish: 'Dutch (Netherlands)'
  },
  {
    short: 'FR',
    locale: 'fr_FR',
    language: 'Français',
    languageInEnglish: 'French (France)'
  },
  {
    short: 'TR',
    locale: 'tr_TR',
    language: 'Türkçe',
    languageInEnglish: 'Turkish (Turkey)'
  },
  {
    short: 'IT',
    locale: 'it_IT',
    language: 'Italiano',
    languageInEnglish: 'Italian (Italy)'
  },
  {
    short: 'ES',
    locale: 'es_ES',
    language: 'Español',
    languageInEnglish: 'Spanish (Spain)'
  },
  {
    short: 'NO',
    locale: 'no_NO',
    language: 'Norsk',
    languageInEnglish: 'Norwegian (Norway)'
  },
  {
    short: 'FI',
    locale: 'fi_FI',
    language: 'Suomi',
    languageInEnglish: 'Finnish (Finland)'
  },
  {
    short: 'KO',
    locale: 'ko_KR',
    language: '한국어',
    languageInEnglish: 'Korean (Republic of Korea)'
  },
  {
    short: 'HU',
    locale: 'hu_HU',
    language: 'Magyar',
    languageInEnglish: 'Hungarian (Hungary)'
  },
  {
    short: 'RU',
    locale: 'ru_RU',
    language: 'Русский',
    languageInEnglish: 'Russian (Russia)'
  },
  {
    short: 'PL',
    locale: 'pl_PL',
    language: 'Polski',
    languageInEnglish: 'Polish (Poland)'
  },
  {
    short: 'DA',
    locale: 'da_DK',
    language: 'Dansk',
    languageInEnglish: 'Danish (Denmark)'
  },
  {
    short: 'CS',
    locale: 'cs_CZ',
    language: 'Čeština',
    languageInEnglish: 'Czech (Czech Republic)'
  },
  {
    short: 'MS',
    locale: 'ms_MY',
    language: 'Melayu',
    languageInEnglish: 'Malay (Malaysia)'
  },
  {
    short: 'VI',
    locale: 'vi_VN',
    language: 'Tiếng Việt',
    languageInEnglish: 'Vietnamese (Vietnam)'
  },
  {
    short: 'SK',
    locale: 'sk_SK',
    language: 'Slovenčina',
    languageInEnglish: 'Slovak (Slovakia)'
  },
  {
    short: 'EL',
    locale: 'el_GR',
    language: 'Ελληνικά',
    languageInEnglish: 'Greek'
  },
  {
    short: 'BG',
    locale: 'bg_BG',
    language: 'Български',
    languageInEnglish: 'Bulgarian'
  },
  {
    short: 'LV',
    locale: 'lv_LV',
    language: 'Latviešu',
    languageInEnglish: 'Latvian (Latvia)'
  },
  {
    short: 'SI',
    locale: 'si_LK',
    language: 'සිංහල',
    languageInEnglish: 'Sinhalese (Sri Lanka)'
  },
  {
    short: 'KM',
    locale: 'km_KH',
    language: 'ខ្មែរ',
    languageInEnglish: 'Central Khmer (Cambodia)'
  },
  {
    short: 'NE',
    locale: 'ne_NP',
    language: 'नेपाली',
    languageInEnglish: 'Nepali (Nepal)'
  },
  {
    short: 'TE',
    locale: 'te_IN',
    language: 'తెలుగు',
    languageInEnglish: 'Telugu (India)'
  },
  {
    short: 'BN',
    locale: 'bn_IN',
    language: 'বাংলা',
    languageInEnglish: 'Bengali (India)'
  },
  {
    short: 'TL',
    locale: 'tl_PH',
    language: 'Filipino',
    languageInEnglish: 'Tagalog (Philippines)'
  },
  {
    short: 'UR',
    locale: 'ur_IN',
    language: 'اردو',
    languageInEnglish: 'Urdu (India)'
  },
  {
    short: 'GU',
    locale: 'gu_IN',
    language: 'ગુજરાતી',
    languageInEnglish: 'Gujarati (India)'
  },
  {
    short: 'TA',
    locale: 'ta_LK',
    language: 'தமிழ்',
    languageInEnglish: 'Tamil (Sri Lanka)'
  },
  {
    short: 'AR',
    locale: 'ar_OM',
    language: 'العربية',
    languageInEnglish: 'Arabic (Oman)'
  },
  {
    short: 'RO',
    locale: 'ro_RO',
    language: 'Română',
    languageInEnglish: 'Romanian (Romania)'
  },
  {
    short: 'IS',
    locale: 'is_IS',
    language: 'Íslenska',
    languageInEnglish: 'Icelandic (Iceland)'
  },
  {
    short: 'LO',
    locale: 'lo_LA',
    language: 'ລາວ',
    languageInEnglish: 'Lao'
  },
  {
    short: 'ID',
    locale: 'id_ID',
    language: 'Indonesia',
    languageInEnglish: 'Indonesian (Indonesia)'
  },
  {
    short: 'SR',
    locale: 'sr_RS',
    language: 'Српски',
    languageInEnglish: 'Serbian (Serbia)'
  },
  {
    short: 'TH',
    locale: 'th_TH',
    language: 'ไทย',
    languageInEnglish: 'Thai (Thailand)'
  },
  {
    short: 'MY',
    locale: 'my_MM',
    language: 'မြန်မာ',
    languageInEnglish: 'Burmese (Myanmar)'
  },
  {
    short: 'UK',
    locale: 'uk_UA',
    language: 'Українська',
    languageInEnglish: 'Ukrainian (Ukraine)'
  },
  {
    short: 'LT',
    locale: 'lt_LT',
    language: 'Lietuvių',
    languageInEnglish: 'Lithuanian (Lithuania)'
  },
  {
    short: 'HR',
    locale: 'hr_HR',
    language: 'Hrvatski',
    languageInEnglish: 'Croatian (Croatia)'
  },
  {
    short: 'JA',
    locale: 'ja_JP',
    language: '日本語',
    languageInEnglish: 'Japanese (Japan)'
  },
  {
    short: 'SW',
    locale: 'sw_CG',
    language: 'Kiswahili',
    languageInEnglish: 'Swahili (Congo)'
  },
  {
    short: 'HI',
    locale: 'hi_FJ',
    language: 'हिन्दी',
    languageInEnglish: 'Hindi (Fiji)'
  },
  {
    short: 'DE',
    locale: 'de_DE',
    language: 'Deutsch',
    languageInEnglish: 'German (Germany)'
  },
  {
    short: 'OR',
    locale: 'or',
    language: 'ଓଡ଼ିଆ',
    languageInEnglish: 'Oriya'
  },
  {
    short: 'ML',
    locale: 'ml',
    language: 'മലയാളം',
    languageInEnglish: 'Malayalam'
  },
  {
    short: 'KN',
    locale: 'kn_IN',
    language: 'ಕನ್ನಡ',
    languageInEnglish: 'Kannada'
  },
  {
    short: 'MR',
    locale: 'mr_IN',
    language: 'मराठी',
    languageInEnglish: 'Marathi'
  }
];
