import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import get from 'lodash/get';

//helpers
import views from 'config/views';
import {ensureDraftVersion} from 'utils/versioning-utils';
import {getShowIntercom} from 'utils/intercom-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import UntranslatedIndicator from 'components/UntranslatedIndicator';
import MaxEditorsErrorNotificationContent from 'components/MaxEditorsErrorNotificationContent';

//styles
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuDivider,
  DropdownMenuItem,
  DropdownMenuLink
} from 'styles/styled-components/dropdown-styled-components';

//messages
import messages from './messages';

import {TranslationItemWrapper} from './styles';
import {SHARE_DIALOG_TYPE} from 'config/enums';

@inject('store')
@observer
class GuideMenuComponent extends Component {
  edit = ensureDraftVersion(() => {
    const {store} = this.props;
    const {editGuidePage} = store;

    editGuidePage.setEditMode(true);
  }, this);

  modify = modificationFn => {
    const {
      id,
      ownedBy: {id: teamId}
    } = this.props.guide;
    modificationFn({id, teamId});
  };

  publish = () => {
    const {store, guide, publish, intl} = this.props;
    const {formatMessage} = intl;
    const {
      publishGuideDialog,
      platform,
      app: {isActionMenuOpen}
    } = store;

    const publishGuideFailureMaxPublishedGuides = (
      <MaxEditorsErrorNotificationContent
        showIntercom={getShowIntercom({platform, isActionMenuOpen})}
        formattedMessages={{
          contactSupport: formatMessage(messages.contactSupport),
          publishGuideFailureMaxPublishedGuides: formatMessage(messages.publishGuideFailureMaxPublishedGuides),
          chatMessage: formatMessage(messages.chatMessage)
        }}
      />
    );

    const translations = {
      success: formatMessage(messages.success),
      error: formatMessage(messages.error),
      unknownFailure: formatMessage(messages.unknownFailure),
      guidePublishedMessage: formatMessage(messages.guidePublishedMessage),
      guidePublishedDocumentsMessage: formatMessage(messages.guidePublishedDocumentsMessage),
      publishGuideFailureMaxPublishedGuides,
      publishGuideFailure: formatMessage(messages.publishGuideFailure),
      publishGuideFailureForbidden: formatMessage(messages.publishGuideFailureForbidden)
    };

    const {slug} = guide;

    publishGuideDialog.open({
      publishMutation: publish,
      guide,
      guideSlug: slug,
      translations
    });
  };

  unpublish = () => {
    const {store, guide, unpublish, intl} = this.props;
    const {formatMessage} = intl;
    const {publishGuideDialog} = store;

    const translations = {
      success: formatMessage(messages.success),
      unknownFailure: formatMessage(messages.unknownFailure),
      guideUnpublishedMessage: formatMessage(messages.guideUnpublishedMessage)
    };

    publishGuideDialog.open({
      unpublishMutation: unpublish,
      guide,
      translations
    });
  };

  reassignGuide = ensureDraftVersion(() => {
    const {
      store: {reassignGuideDialog},
      guide
    } = this.props;
    const {id, ownedBy} = guide;
    reassignGuideDialog.open({
      guideId: id,
      guideTitle: guide.title,
      isUnderApproval: guide.isUnderApproval,
      teamId: ownedBy.id
    });
  }, this);

  archiveGuide = archiveMutation => {
    const {guide, intl, onArchive, store} = this.props;
    const {formatMessage} = intl;
    const {id, title} = guide;
    const {archiveDialog: dialog} = store;

    const translations = {
      action: formatMessage(messages.archiveGuide),
      confirmation: formatMessage(messages.archiveGuideConfirm, {title}),
      archiveSuccess: formatMessage(messages.archiveGuideSuccess),
      archiveFailure: formatMessage(messages.archiveGuideFailure)
    };

    dialog.open({
      params: {id},
      mutation: archiveMutation,
      onArchive,
      title,
      translations
    });
  };

  feedback = () => {
    const {
      store: {guideFeedbackDialog},
      guide
    } = this.props;
    const {id, title} = guide;
    guideFeedbackDialog.open({
      guideId: id,
      guideTitle: title
    });
  };

  versionHistory = () => {
    const {
      store: {versionHistoryDrawer, router},
      guide
    } = this.props;
    const {id, title, draftVersionOid, reviewingVersionOid} = guide;
    versionHistoryDrawer.open({
      guideId: id,
      guideTitle: title,
      draftVersionOid,
      reviewingVersionOid
    });
    router.goTo(views.editGuide, {id: guide.id}, {});
  };

  share = () => {
    const {intl, store, guide} = this.props;
    const {formatMessage} = intl;
    const {shareDialog: dialog} = store;
    const {id, slug, title} = guide;

    dialog.open({
      itemTitle: title,
      path: `guide/${id}`,
      guide,
      qrBasename: slug,
      title: formatMessage(messages.shareGuide),
      type: SHARE_DIALOG_TYPE.GUIDE
    });
  };

  openGuideInViewer = (hostname, guideSlug) => {
    const url = `https://${hostname}/guide/${guideSlug}?viewMode=preview`;
    window.open(url, '_blank');
  };

  render() {
    const {
      store,
      guide,
      isGuideDetails,
      archive,
      allowPublish = true,
      shouldShowUntranslatedIcon,
      canViewDetails = false
    } = this.props;
    const {
      id,
      canEdit: canEditGuide,
      publishStatus,
      canReassign,
      canShare,
      canTranslate,
      canPublish,
      canCopy: canCopyGuide,
      totalPublishedInstructionsNumber,
      isUnderApproval,
      domains,
      slug
    } = guide;
    const {
      editGuidePage: {isLastestVersion},
      router,
      platform: {hostname, hasFeedbackEnabled},
      guideDetailsDialog,
      copyToDialog
    } = store;

    const isDraft = publishStatus === 'DRAFT';
    const isPublished = publishStatus === 'PUBLISHED';
    const isDraftVersion = !get(router, 'queryParams.v', false);
    const isOnDefaultDomain = domains && domains.some(domain => domain.isDefault);
    const isPublishedOnDefaultWorkspace = isOnDefaultDomain && isPublished;

    const showUnpublish = canEditGuide && allowPublish && !isDraft;
    const showPublish =
      canEditGuide && allowPublish && canPublish && !isUnderApproval && totalPublishedInstructionsNumber > 0;
    const showShare = canShare;
    const showReassign = canEditGuide && canReassign;
    const showActivity = canEditGuide && isDraftVersion;
    const showFeedbackItem =
      hasFeedbackEnabled && canEditGuide && (isDraftVersion || (isPublished && isLastestVersion));
    const showPreview = isPublishedOnDefaultWorkspace;
    const showEdit = canEditGuide;
    const showCopyTo = canCopyGuide;
    const showTranslate = canEditGuide && canTranslate && isDraftVersion;
    const showArchive = canEditGuide;

    return (
      // The Dropdown component tries to amend its child props.
      // To avoid disrupting this we want to forward the props
      // the actual underlying DropdownMenu component it expects.
      <DropdownMenu {...this.props} data-cy="guide-menu">
        {showUnpublish && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.unpublish}>
              <FormattedMessage {...messages.unpublish} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showPublish && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.publish}>
              <FormattedMessage {...messages.publish} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showShare && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.share()}>
              <FormattedMessage {...messages.share} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showReassign && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.modify(this.reassignGuide)}>
              <FormattedMessage {...messages.reassign} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showActivity && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.modify(this.versionHistory)}>
              <FormattedMessage {...messages.activity} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showFeedbackItem && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.modify(this.feedback)}>
              <FormattedMessage {...messages.feedback} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {canViewDetails && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => guideDetailsDialog.open(id)}>
              <FormattedMessage {...messages.viewDetails} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showPreview && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.openGuideInViewer(hostname, slug)}>
              <FormattedMessage {...messages.viewGuide} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        <DropdownMenuDivider />
        {showEdit && (
          <DropdownMenuItem>
            {isGuideDetails ? (
              <DropdownMenuButton onClick={() => this.edit()}>
                <FormattedMessage {...messages.edit} />
              </DropdownMenuButton>
            ) : (
              <DropdownMenuLink store={store} route={views.editGuide} params={{id: id}}>
                <FormattedMessage {...messages.edit} />
              </DropdownMenuLink>
            )}
          </DropdownMenuItem>
        )}
        {showCopyTo && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => copyToDialog.open(id)}>
              <FormattedMessage {...messages.copyTo} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showTranslate && (
          <DropdownMenuItem>
            <DropdownMenuLink store={store} route={views.translateGuide} params={{id: id}}>
              <TranslationItemWrapper>
                <FormattedMessage {...messages.translate} />
                {shouldShowUntranslatedIcon && <UntranslatedIndicator size={16} content="!" />}
              </TranslationItemWrapper>
            </DropdownMenuLink>
          </DropdownMenuItem>
        )}
        {showArchive && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.archiveGuide(archive)}>
              <FormattedMessage {...messages.archive} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
      </DropdownMenu>
    );
  }
}

export default injectIntl(GuideMenuComponent);
