import styled from 'styled-components';

//helpers
import {boxShadow} from 'styles/shadows';

//components
import $Icon from 'ui-components/Layout/Icon';

export const WritingTips = styled.div`
  background: #fff;
  box-shadow: ${boxShadow.medium};
  color: #000;
  margin: 0 auto;
`;

export const Title = styled.h2`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 22px 28px 17px;
`;

export const StyledInfoIcon = styled($Icon).attrs({
  name: 'info',
  size: 24
})`
  margin-right: 12px;
  opacity: 0.75;
  vertical-align: -4px;
`;

export const Content = styled.ul`
  font-size: 14px;
  margin-top: 17px;
  padding: 0 35px 24px;
`;

export const ContentItem = styled.li`
  &::before {
    content: '\\2013\\a0';
  }
`;
