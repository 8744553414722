import gql from 'graphql-tag';

export const CreateTheme = gql`
  mutation createTheme($newTheme: ThemeInput!) {
    createTheme(newTheme: $newTheme) {
      id
    }
  }
`;

export const EditTheme = gql`
  mutation editTheme($id: String!, $editTheme: ThemeInput!) {
    editTheme(id: $id, editTheme: $editTheme) {
      id
    }
  }
`;
