import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {graphql} from '@apollo/client/react/hoc/graphql';

import mutationNames from 'api/team/constants';
import {EditTeam} from 'api/team/mutations';
import {editTeamOptions} from 'api/team/mutation-options';

import NotificationsSection from './NotificationsSection';
import RemindersSection from './RemindersSection';

import messages from './messages';
import {ActionWrapper, SaveButton} from './styles';

const getIsCurrentTeamAdmin = ({isCreation, user, team}) =>
  isCreation ||
  user.teams.some(teamMembership => teamMembership.role === 'ADMIN' && teamMembership.team.id === get(team, 'id'));

@inject('store')
@graphql(EditTeam, editTeamOptions)
@observer
class SetTeamNotifications extends Component {
  render() {
    const {
      intl: {formatMessage},
      action,
      store
    } = this.props;
    const {
      auth: {user},
      editTeamPage,
      teamPage
    } = store;
    const {notificationsForm: createTeamNotificationsForm} = teamPage;
    const {
      team,
      updateTeam,
      teamNotificationsValuesChanged,
      notificationsForm: editTeamNotificationsForm
    } = editTeamPage;

    const isCreation = action === 'create';

    const isCurrentTeamAdmin = getIsCurrentTeamAdmin({isCreation, user, team});
    const canEditNotifications = [isCurrentTeamAdmin, user.isIPA, user.isPlatformAdmin].some(Boolean);

    const showSaveButton = !isCreation && canEditNotifications;

    const notificationsForm = isCreation ? createTeamNotificationsForm : editTeamNotificationsForm;

    const buttonIsDisabled = !teamNotificationsValuesChanged;

    const guideApprovalIsEnabled = isCreation ? teamPage.isGuideApprovalEnabled : editTeamPage.isGuideApprovalEnabled;

    const onClick = () => {
      if (!isCreation) {
        const editTeamMutation = this.props[mutationNames.EDIT_TEAM];
        updateTeam(editTeamMutation);
      }
    };

    return (
      <ActionWrapper>
        <NotificationsSection canEditNotifications={canEditNotifications} notificationsForm={notificationsForm} />
        <RemindersSection
          canEditNotifications={canEditNotifications}
          guideApprovalIsEnabled={guideApprovalIsEnabled}
          notificationsForm={notificationsForm}
        />
        {showSaveButton && (
          <SaveButton
            primary={{
              label: formatMessage(messages.save),
              disabled: buttonIsDisabled,
              onClick
            }}
          />
        )}
      </ActionWrapper>
    );
  }
}

SetTeamNotifications.propTypes = {
  action: PropTypes.oneOf(['create', 'edit', 'view'])
};

export default injectIntl(SetTeamNotifications);
