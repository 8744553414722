import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditGuide.activity',
    defaultMessage: 'View Activity'
  },
  description: {
    id: 'versionHistoryDrawer.description',
    defaultMessage: 'See the activity log on the Guide {name}'
  },
  gotIt: {
    id: 'gotIt',
    defaultMessage: 'Ok, got it'
  }
});

export default messages;
