import React from 'react';
import messages from './messages';
import TranslationInProgressDialogContent from './TranslationInProgressDialogContent';

export const getTranslationInProgressDialogConfig = (_store, formatMessage) => ({
  title: formatMessage(messages.automaticTranslationInProgress),
  content: <TranslationInProgressDialogContent formatMessage={formatMessage} />,
  actionsProps: {
    actions: false
  }
});
