import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import FiltersContainer from 'components/FiltersContainer';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

import {StyledCombobox} from './styles';
import messages from './messages';

import TagPickerComponent from './tagPicker';

const FILTER_CONFIG = [
  {key: 'TAG', message: messages.tags, titleMessage: messages.filterByTag, specialLabel: true},
  {key: 'TEAM', message: messages.teams, titleMessage: messages.filterByTeam},
  {key: 'ASSIGNEE', message: messages.assignees, titleMessage: messages.filterByAssignee},
  {key: 'WORKSPACE', message: messages.workspaces, titleMessage: messages.filterByWorkspace},
  {key: 'LANGUAGE', message: messages.languages, titleMessage: messages.filterByLanguage}
];

@inject('store')
@observer
class CustomGuideListFiltersComponent extends Component {
  handleComboboxChange = (filterKey, options) => {
    const {selectOption} = this.props;

    return value => {
      selectOption(filterKey, value);

      trackEvent(EVENT_TYPES[`CHANGE_GUIDE_${filterKey}`], {
        [filterKey.toLowerCase()]: value.length ? value.map(opt => opt).join(', ') : 'All'
      });
    };
  };

  renderFilterComponent = (config, customFilterOptions, customFilter, formatMessage) => {
    const options = customFilterOptions[config.key];
    const values = options.length ? toJS(customFilter[config.key]) : [];

    return (
      <StyledCombobox
        value={values}
        valueAccessor={({id}) => id}
        labelAccessor={({name}) => name}
        options={options}
        title={formatMessage(config.titleMessage)}
        onChange={this.handleComboboxChange(config.key, options)}
        alignPopover="left"
      />
    );
  };

  render() {
    const {
      className,
      intl: {formatMessage},
      store,
      onReset,
      selectOption
    } = this.props;
    const {allGuidesPage} = store;
    const {customFilterOptions, customFilter} = allGuidesPage;

    if (!customFilter) {
      return null;
    }

    const filterEntries = FILTER_CONFIG.map(config => ({
      label: formatMessage(config.message),
      component: () =>
        config.key !== 'TAG' ? (
          this.renderFilterComponent(config, customFilterOptions, customFilter, formatMessage)
        ) : (
          <TagPickerComponent config={config} selectOption={selectOption} />
        )
    }));

    return <FiltersContainer className={className} filterEntries={filterEntries} onReset={onReset} />;
  }
}

export default injectIntl(CustomGuideListFiltersComponent);
