import get from 'lodash/get';

export const skillOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  }
};

export const skillsOptions = {
  options: () => {
    return {
      fetchPolicy: 'network-only'
    };
  },
  name: 'skillsQuery'
};

export const skillTasksOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'skillTasksQuery'
};

export const skillProfilesOptions = {
  options: () => {
    return {
      fetchPolicy: 'no-cache'
    };
  },
  name: 'skillProfilesQuery'
};

export const skillProfilesForSkillOption = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        skillId: id
      },
      fetchPolicy: 'network-only',
      name: 'skillProfilesBySkillId'
    };
  },
  name: 'skillProfilesForSkillQuery'
};

export const skillProfileOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  },
  name: 'skillProfileQuery',
  skip: props => {
    const id = get(props, 'store.router.params.id');
    return !id || id === 'new';
  }
};

export const jobTitlesOptions = {
  options: {
    fetchPolicy: 'network-only'
  }
};
