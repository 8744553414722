import React, {Component} from 'react';

//styled-components
import {CenteredContainer} from './styles';

class FormContainer extends Component {
  render() {
    const {children, className} = this.props;

    return <CenteredContainer className={className}>{children}</CenteredContainer>;
  }
}

export default FormContainer;
