import gql from 'graphql-tag';

export const FeedbacksList = gql`
  query feedbacksForGuide($guideId: String!, $locale: Locale!) {
    feedbacksForGuide(guideId: $guideId, locale: $locale) {
      body
      guide {
        title
      }
      instruction {
        title
        type
      }
      step {
        position
        instructionTitle
        instructionType
      }
      dateCreated
    }
  }
`;
