import {fields, plugins} from 'stores/forms/campaign-text-type-options';

//mobx form
import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';

class CampaignTextOption {
  form;

  constructor(props) {
    this.form = new MobxReactForm({fields}, {plugins});
    props && this.form.update({option: props.option});
  }

  isValid = () => this.form.isValid;
}

export default CampaignTextOption;
