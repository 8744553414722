import React, {useCallback, useContext, useEffect, useState} from 'react';
import {MobXProviderContext} from 'mobx-react';
import {injectIntl} from 'react-intl';

import messages from './messages';

//components
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import SearchBar from 'ui-components/Layout/SearchBar';
import {useAbortableQuery} from 'hooks/useAbortableQuery';
import {debounce} from 'lodash';
import views from 'config/views';
import TeamsListContainer from './TeamsListContainer';

import {AllParentTeams} from 'api/team/queries';

export const TeamsView = props => {
  const {
    intl: {formatMessage}
  } = props;

  const {store} = useContext(MobXProviderContext);

  const [parentTeamsQuery, {data, loading, error}] = useAbortableQuery<{
    allParentTeams;
  }>(AllParentTeams, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false
  });

  const [searchTerm, setSearchTerm] = useState<string>('');

  const fetchData = useCallback(
    ({searchTerm}) => {
      parentTeamsQuery({
        variables: {
          searchTerm
        }
      });
    },
    [parentTeamsQuery]
  );

  useEffect(() => {
    fetchData({searchTerm});
  }, [searchTerm]);

  useEffect(() => {
    const actionMenuItems = [
      {
        id: 'create',
        params: {tab: 'name'},
        view: 'newTeam'
      }
    ];

    if (!store.auth.user.canViewTeams) {
      store.router.goTo(views.root, {});
    } else if (store.auth.user?.isPlatformAdmin || store.auth.user?.isIPA) {
      store.app.setActionMenuItems(actionMenuItems);
    }

    return () => {
      // componentWillUnmount
      store.app.setActionMenuItems([]);
    };
  }, [store.auth?.user]);

  const handleUpdateSearchTerm = e => {
    e.preventDefault();
    e.stopPropagation();
    const newValue = e.target?.value.substring(0, 50);
    updateSearchTerm(newValue);
  };

  const updateSearchTerm = debounce((newValue: string) => {
    if (!newValue.length || newValue.length >= 3) {
      setSearchTerm(newValue);
    }
  }, 300);

  const teams = data?.allParentTeams || [];

  return (
    <Container style={{paddingBottom: '50px', height: 'auto'}}>
      <ViewTitle
        title={formatMessage(messages.teams)}
        subtitle={formatMessage(messages.teamDescription)}
        right={
          <SearchBar
            placeholder={formatMessage(messages.searchTeams)}
            onChange={handleUpdateSearchTerm}
            maxLength={50}
          />
        }
      />
      <TeamsListContainer loading={loading} error={error} teams={teams} searchTerm={searchTerm} />
    </Container>
  );
};

export default injectIntl(TeamsView);
