import VersionHistoryDrawer from 'components/VersionHistoryDrawer';
import React, {useEffect} from 'react';

import {inject, observer} from 'mobx-react';
import {Wrapper, Content} from './styles';

const GuideView = ({children, store}) => {
  const {router, versionHistoryDrawer} = store;

  useEffect(() => {
    return () => {
      const routerGuideId = router.params.id;

      if (routerGuideId !== versionHistoryDrawer.guideId && versionHistoryDrawer.opened === true) {
        versionHistoryDrawer.reset();
      }
    };
  }, [router.params.id, versionHistoryDrawer.guideId, versionHistoryDrawer.opened]);

  return (
    <Wrapper>
      <Content>{children}</Content>
      <VersionHistoryDrawer />
    </Wrapper>
  );
};

export default inject('store')(observer(GuideView));
