import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import ChecklistAnswerResults from './ChecklistsAnswersResults';

@inject('store')
@observer
class ChecklistsResults extends Component {
  render() {
    const {
      store: {checklistResultsPage}
    } = this.props;

    return (
      <ChecklistAnswerResults checkListAnswersQueryVariables={checklistResultsPage.checkListAnswersQueryVariables} />
    );
  }
}

export default injectIntl(ChecklistsResults);
