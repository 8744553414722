import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import DropdownComponent from 'ui-components/Dropdown';
import $Button from 'ui-components/Button';
import Icon from 'ui-components/Layout/Icon';

export const StyledDropdown = styled(DropdownComponent)`
  overflow: visible;
  margin: 100px;
`;

export const DropdownToggle = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border: ${({open}) => (open ? '1px solid black' : '1px solid #ededed')};
  border-radius: 2px;
  cursor: pointer;
  display: block;
  height: 44px;
  outline: 0;
  padding: 0;
  position: relative;
  width: ${({totalFiltersApplied}) => (totalFiltersApplied ? '100px' : '89px')};
  z-index: 0;
  color: ${commonPalette.txBlack};

  &::after {
    background: currentColor;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &:hover::after {
    opacity: 0.05;
  }
`;

export const DropdownList = styled.div``;

export const DropwdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  min-width: 300px;
`;

export const StyledText = styled.div`
  color: ${commonPalette.txBlack};
  padding-bottom: 5px;
`;

export const DropwdownActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledApplyButton = styled($Button).attrs({
  underlined: true
})`
  padding-bottom: 1.5rem;
  text-decoration: none;
  height: 1%;
  color: ${commonPalette.btnGreen};
  &:hover,
  :focus {
    text-decoration: none;
  }
`;
export const StyledResetButton = styled($Button).attrs({
  underlined: true
})`
  padding-bottom: 1.5rem;
  text-decoration: none;
  height: 1%;
  color: ${commonPalette.txBlack};
  &:hover,
  :focus {
    text-decoration: none;
  }
`;

export const FilterIcon = styled(Icon).attrs(() => ({
  name: 'filter',
  size: 20
}))`
  margin-right: 3px;
  margin-bottom: 2px;
`;
