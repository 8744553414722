import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'MergeTags.title',
    defaultMessage: 'Merge Tags'
  },
  name: {
    id: 'MergeTags.name',
    defaultMessage: 'Select tags (2)'
  },
  description: {
    id: 'MergeTags.description',
    defaultMessage:
      'Select the most accurate description of the tag per language to merge two tags into a single tag. Please also identify the new default language.'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  mergeSuccess: {
    id: 'MergeTags.mergeSuccess',
    defaultMessage: 'Tags have been merged'
  },
  mergeFailure: {
    id: 'MergeTags.mergeFailure',
    defaultMessage: 'Failed to merge the tag.'
  }
});

export default messages;
