import React, {Component} from 'react';
import {observer} from 'mobx-react';

//styles
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

//helpers
import {icons} from 'styles/icons';

@observer
class FontAwesomeIconComponent extends Component {
  resolveActionToIcon = actionId => {
    const icon = icons.find(i => i.action === actionId);
    return icon && icon.name;
  };

  render() {
    const {actionId} = this.props;
    const iconName = this.resolveActionToIcon(actionId);

    if (!iconName) {
      console.log(
        `No icon found for "${actionId}". \nThe available actions are: ${icons.map(
          i => `\naction: ${i.action} - icon: ${i.name}`
        )}`
      );
      return null;
    }

    return <FontAwesomeIcon icon={iconName} />;
  }
}

export default FontAwesomeIconComponent;
