import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  teamNotFound: {
    id: 'common.notifications.teamNotFound',
    defaultMessage: 'Team not found.'
  },
  workspace: {
    id: 'TeamWorkspaces.workspace',
    defaultMessage: 'Workspace'
  },
  managementTeam: {
    id: 'common.forms.managementTeam',
    defaultMessage: 'Management Team'
  },
  managementTeamDescription: {
    id: 'common.description.managementTeamDescription',
    defaultMessage: 'The team responsible for managing the workspace, such as the name, URL, credentials, and branding.'
  },
  defaultTeam: {
    id: 'common.forms.defaultTeam',
    defaultMessage: 'Default Team'
  },
  defaultTeamDescription: {
    id: 'common.description.defaultTeamDescription',
    defaultMessage:
      ' New users are added to this Team when logging in to a Workspace with their Company Account (SSO) for the first time.'
  },
  edit: {
    id: 'TeamWorkspaces.edit',
    defaultMessage: 'Edit Workspace'
  },
  removeWorkspaceSuccess: {
    id: 'TeamWorkspaces.removeSuccess',
    defaultMessage: 'Workspace has been removed from the team'
  },
  removeWorkspaceFailure: {
    id: 'TeamWorkspaces.removeFailure',
    defaultMessage: 'Cannot remove workspace from the team'
  },
  backToOverview: {
    id: 'common.actions.backToOverview',
    defaultMessage: 'Back to overview'
  },
  teamUpdateSuccess: {
    id: 'teamHasBeenUpdatedSuccess',
    defaultMessage: 'The team has been updated'
  },
  teamUpdateFailure: {
    id: 'TeamWorkspaces.teamUpdateFailure',
    defaultMessage: 'Could not update the team name'
  },
  removeWorkspace: {
    id: 'TeamWorkspaces.removeWorkspace',
    defaultMessage: 'Remove workspace'
  },
  removeWorkspaceConfirm: {
    id: 'TeamWorkspaces.removeWorkspaceConfirm',
    defaultMessage: 'You are about to remove this team from the workspace.'
  },
  removeWorkspaceBody: {
    id: 'TeamWorkspaces.removeWorkspaceBody',
    defaultMessage:
      'It will no longer be able to publish to this workspace and access this workspace if it has protected content.'
  },
  noWorkspaces: {
    id: 'TeamWorkspaces.noWorkspaces',
    defaultMessage: 'This Team has not been connected to any Workspaces.'
  },
  connectNewWorkspace: {
    id: 'connectNewWorkspace',
    defaultMessage: 'Connect New Workspace'
  }
});

export default messages;
