import React from 'react';
import {inject, Observer} from 'mobx-react';
import Dialog from 'ui-components/Dialog';
import {useAutoTranslationDialog} from './utils';
import {injectIntl} from 'react-intl';

const AutoTranslationDialog = ({store, intl}) => {
  const {content, title, actionsProps, overflow, overflowBody, onRequestClose, customHeaderStyle} =
    useAutoTranslationDialog(store, intl.formatMessage);
  return (
    <Observer>
      {() => {
        return (
          <Dialog
            title={title}
            {...actionsProps}
            isOpen={store.autoTranslationDialog.opened}
            overflow={overflow}
            overflowBody={overflowBody}
            onRequestClose={onRequestClose}
            customHeaderStyle={customHeaderStyle}
          >
            {content}
          </Dialog>
        );
      }}
    </Observer>
  );
};

export default injectIntl(inject('store')(AutoTranslationDialog));
