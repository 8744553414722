import {defineMessages} from 'react-intl';

const messages = defineMessages({
  editLearning: {
    id: 'editLearning',
    defaultMessage: 'Edit Learning Activity'
  },
  saveLearning: {
    id: 'saveLearning',
    defaultMessage: 'Save'
  },
  failedEditLearningActivity: {
    id: 'failedEditLearningActivity',
    defaultMessage: 'Failed to update Learning Activity'
  },
  successEditLearningActivity: {
    id: 'successEditLearningActivity',
    defaultMessage: 'Successfully updated Learning Activity'
  },
  saveChanges: {
    id: 'saveChanges',
    defaultMessage: 'Save Changes'
  },
  saveDialogDesc: {
    id: 'saveDialogDesc',
    defaultMessage:
      'This Learning Activity is utilized by {count} {count, plural, one {Skill Profile} other {Skill Profiles}}. Changes will apply to all of these after saving.'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  }
});

export default messages;
