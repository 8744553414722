export default {
  UPDATE_PROFILE: 'updateProfileMutation',
  UPDATE_PASSWORD: 'updatePasswordMutation',
  FORGOT_PASSWORD: 'forgotPasswordMutation',
  RESET_PASSWORD: 'resetPasswordMutation',
  SET_PASSWORD: 'setPasswordMutation',
  CREATE_USER: 'createUserMutation',
  EDIT_USER: 'editUserMutation',
  DISABLE_USER: 'disableUserWithResultMutation',
  ENABLE_USER: 'enableUserMutation',
  DELETE_USER: 'deleteUserMutation',
  INVITE_USER: 'inviteUserMutation'
};
