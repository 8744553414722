import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {Popover} from 'antd';

import Select from 'ui-components/Select';

import messages from './messages';

@inject('store')
class LanguagePickerComponent extends Component {
  render() {
    const {
      allowClear = false,
      changeLanguage,
      dataCy,
      disabled,
      filterOption = [],
      hideLabel = false,
      intl: {formatMessage},
      label,
      languages,
      placeholder,
      hidePlaceholder,
      selectedLanguage,
      store: {
        platform: {availableLanguages = []}
      },
      style,
      tooltipText
    } = this.props;

    const languageOptions = formatLanguages(languages ? languages : availableLanguages);

    const selectComponent = (
      <Select
        {...(!hideLabel && {label: label || formatMessage(messages.defaultLanguage)})}
        className={'language-picker'}
        placeholder={!hidePlaceholder ? placeholder || formatMessage(messages.selectPlaceholder) : ''}
        allowClear={allowClear}
        disabled={disabled}
        options={languageOptions}
        filterOption={filterOption}
        onChange={changeLanguage}
        selectedValue={selectedLanguage}
        dataCy={dataCy}
        {...(style && {style})}
      />
    );

    return tooltipText && disabled ? (
      <Popover popupAlign={{offset: [0, 20]}} trigger={'hover'} content={tooltipText}>
        <div>{selectComponent}</div>
      </Popover>
    ) : (
      selectComponent
    );
  }
}

const formatLanguages = languages =>
  languages.map(lang => {
    return {
      id: lang.id || lang.locale,
      name: lang.name || lang.language
    };
  });

export default injectIntl(LanguagePickerComponent);
