import styled from 'styled-components';

import $Checkbox from 'components/Checkbox';
import {commonPalette} from 'shared/styles/palette';
import {Select as $Select} from 'ui-components';

export const MultiDomainPicker = styled.div``;

export const StyledCheckbox = styled($Checkbox)`
  color: ${commonPalette.txBlack};
  margin: 20px 0;
`;

export const StyledSelect = styled($Select)`
  color: ${commonPalette.txBlack};
  margin: 20px 0;
`;
