import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {inject, observer} from 'mobx-react';
import {Tabs} from 'antd';

import {StyledTabs} from 'components/StepsTabs/styles';

import {CustomLiveboard} from './CustomLiveboard';

import {Title, WrapperContainer, StyledContainer} from './styles';
import {DEFAULT_ANALYTICS_PAGE_SLUG, VIEW_CONFIG_MAP} from './constants';

import messages from './messages';

const Caption = ({captionText}) => (
  <Title>
    <span>{captionText}</span>
  </Title>
);

@inject('store')
@observer
class ThoughtspotView extends Component {
  goTo = link => {
    const {store} = this.props;
    store.router.goTo(store.router.currentRoute, {page: link});
  };

  render() {
    const {
      store: {
        app: {
          selectedNavSubMenuItem: {links}
        },
        router: {
          params: {page: pageSlug},
          currentRoute
        },
        auth: {user}
      },
      intl: {formatMessage},
      hideTitle
    } = this.props;

    const viewConfig = VIEW_CONFIG_MAP[pageSlug || currentRoute?.id];
    if (!viewConfig) {
      this.goTo(DEFAULT_ANALYTICS_PAGE_SLUG);
      return null;
    }
    const {liveboardId, sectionTitleMessageKey} = viewConfig;

    const {TabPane} = Tabs;

    return (
      <WrapperContainer>
        <StyledContainer hideTitle={hideTitle}>
          {!hideTitle && <Caption captionText={formatMessage(messages[sectionTitleMessageKey])} />}

          {Array.isArray(links) && (
            <StyledTabs
              defaultActiveKey={pageSlug}
              onChange={link => {
                this.goTo(link);
              }}
              tabsOnly
            >
              {links.map(link => {
                const tabPageSlug = link.params.page;
                const {tabTitleMessageKey} = VIEW_CONFIG_MAP[tabPageSlug];
                return <TabPane key={link.params.page} tab={formatMessage(messages[tabTitleMessageKey])} />;
              })}
            </StyledTabs>
          )}

          <CustomLiveboard user={user} liveboardId={liveboardId} />
        </StyledContainer>
      </WrapperContainer>
    );
  }
}
export default injectIntl(ThoughtspotView);
