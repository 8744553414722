import React, {Component} from 'react';

import {EmptyCard} from './styles';

class EmptyCardComponent extends Component {
  render() {
    return <EmptyCard width={window.innerWidth} />;
  }
}

export default EmptyCardComponent;
