import invoke from 'lodash/invoke';
import {draftToMarkdown, markdownToDraft} from 'markdown-draft-js';
import {action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Children, cloneElement, Component} from 'react';

//helpers
import {styleable} from 'shared/decorators';

//styles
import {MarkdownEditor} from './styles';

@observer
@styleable
export default class MarkdownEditorComponent extends Component {
  static propTypes = {
    contentState: PropTypes.object,
    editorRef: PropTypes.func,
    editorStyle: PropTypes.object,
    handleReturn: PropTypes.func,
    onContentStateChange: PropTypes.func,
    onEscape: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    spellCheck: PropTypes.bool,
    toolbarCustomButtons: PropTypes.arrayOf(PropTypes.node),
    toolbarOnFocus: PropTypes.bool
  };

  @observable isFocused = false;
  defaultValue = draftToMarkdown(this.props.contentState);
  rootRef = null;
  textareaRef = null;

  handleBlur = event => {
    const isComponentStillFocused = this.rootRef && this.rootRef.contains(event.relatedTarget);

    if (isComponentStillFocused) {
      if (this.textareaRef) {
        this.textareaRef.focus();
      }
    } else {
      this.setIsFocused(false);
    }
  };

  handleChange = event => {
    const markdown = markdownToDraft(event.target.value);
    invoke(this.props, 'onContentStateChange', markdown);
  };

  @action
  handleFocus = event => {
    this.setIsFocused(true);
    invoke(this.props, 'onFocus', event);
  };

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      const status = invoke(this.props, 'handleReturn', event);

      if (status === 'handled') {
        event.preventDefault();
      }
    } else if (event.key === 'Escape') {
      invoke(this.props, 'onEscape', event);
    }
  };

  @action
  setIsFocused = isFocused => {
    this.isFocused = isFocused;
  };

  setRootRef = rootRef => {
    this.rootRef = rootRef;
  };

  setTextareaRef = textareaRef => {
    this.textareaRef = textareaRef;
    invoke(this.props, 'editorRef', textareaRef);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {editorStyle, placeholder, readOnly, spellCheck, toolbarCustomButtons, toolbarOnFocus} = this.props;

    const shouldHideToolbar = toolbarOnFocus && !this.isFocused;

    return (
      <MarkdownEditor className="rdw-editor-wrapper" ref={this.setRootRef} onBlur={this.handleBlur}>
        {toolbarCustomButtons && (
          <div className="rdw-editor-toolbar" style={{visibility: shouldHideToolbar ? 'hidden' : 'visible'}}>
            <div className="rdw-editor-toolbar-inner">
              {Children.map(toolbarCustomButtons, (button, key) => cloneElement(button, {key}))}
            </div>
          </div>
        )}
        <textarea
          className="rdw-editor-main"
          defaultValue={this.defaultValue}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={this.setTextareaRef}
          spellCheck={spellCheck}
          style={editorStyle}
        />
      </MarkdownEditor>
    );
  }
}
