import styled from 'styled-components';
import withScrolling from 'react-dnd-scrollzone';

//helpers
import scrollParent from 'decorators/scrollParent';
import flex from 'styles/flex';
import {headerSize} from 'styles/vars';

export const ScrollingContainer = withScrolling(
  scrollParent({
    overflowX: 'hidden', // sorry, this is just too ugly on Windows
    overflowY: 'scroll' // force vertical scrollbar on Windows
  })(styled.div`
    ${flex.vertical};
    height: 100%;
    position: relative; // position children relatively to this
  `)
);

export const InnerWrapper = styled.div`
  ${flex.horizontal};
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: ${headerSize}px;
  height: 100%;
`;

export const MainColumn = styled.main`
  ${flex.allowOverflowX};
  ${flex.vertical};
  flex-grow: 1;
`;

export const Cover = styled.div`
  position: fixed;
  top: ${headerSize}px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(51, 51, 48, 0.75);
  cursor: default;
  z-index: 3;
`;
