import styled from 'styled-components';
import flex from 'styles/flex';
import {iconSize, cond} from 'shared/utils/sc-utils';
import {swallowingStyled} from 'styles/styled-components/utils';

//external
import $Icon from 'react-fontawesome';

export const ExampleImagesSlider = styled.div`
  ${flex.vertical};
  flex: 1;
  margin: -24px;
`;

export const Images = styled.div`
  ${flex.horizontal}
  ${flex.centerHorizontalV}
  ${p => (p.moreImages ? flex.spaceBetween : flex.centerHorizontalH)}
  padding: 24px;
  flex: 1;
`;

export const Arrow = swallowingStyled($Icon, {
  swallowProps: ['enabled']
})`
  color: #636667;
  ${iconSize(29, 51)}
  ${flex.horizontal}
  ${flex.centerHorizontal}
  ${p => cond(p.enabled, 'cursor: pointer;')}
  ${p => cond(!p.enabled, 'opacity: 0.3;')};
`;

export const LeftArrow = styled(Arrow)`
  margin-right: 17px;
`;

export const RightArrow = styled(Arrow)`
  margin-left: 17px;
`;

export const Counter = styled.div`
  font-size: 14px;
  text-align: center;
  color: #939798;
  font-weight: 300;
  border-top: 1px solid #dedede;

  > span {
    height: 72px;
    ${flex.horizontal}
    ${flex.centerHorizontal}
  }
`;

export const StyledNumber = styled.div`
  font-weight: normal;
  margin: 0 3px;
`;
