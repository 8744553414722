import styled from 'styled-components';
import $DataTable from 'ui-components/Layout/DataTable';
import Button from 'ui-components/Button';
import {commonPalette} from 'shared/styles/palette';

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin-right: 32px;
  }
`;

export const DataTable = styled($DataTable)`
  .rt-table {
    overflow: auto !important;
  }

  .rt-tbody {
    overflow: unset !important;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }

  .rt-tr-group:hover {
    ${() => ArchiveButton} {
      opacity: 1;
    }
  }
`;

export const ArchiveButton = styled(Button).attrs({
  iconId: 'remove',
  solidIcon: true,
  iconStyle: {color: '#666', fontSize: 16}
})`
  cursor: pointer;
`;

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
`;

export const NameWrapper = styled.div``;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Url = styled.div`
  color: ${commonPalette.txGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
`;

export const StyledLink = styled.a`
  color: ${commonPalette.txGrey};

  :hover,
  :focus {
    color: ${commonPalette.txGrey};
  }
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'close'
})`
  opacity: 1;
  cursor: pointer;
`;
