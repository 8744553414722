import {action, computed, observable, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import {injectIntl} from 'react-intl';
import draftToHtml from 'draftjs-to-html';

//lodash
import constant from 'lodash/constant';
import get from 'lodash/get';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import noop from 'lodash/noop';
import pickBy from 'lodash/pickBy';
import startsWith from 'lodash/startsWith';
import pick from 'lodash/pick';

//helpers
import {MEDIA_PREVIEW_TYPE} from 'config/enums';
import {closest, rem} from 'shared/utils/dom-utils';
import {bindField} from 'shared/utils/input-utils';
import NewSlideForm from 'stores/forms/new-slide-form';
import {editorHasChanges, editorHasValue, emptyContent, convertHtmlToDraft} from 'utils/data-utils';
import {LayoutType, StepType} from 'utils/step-utils';
import {stepNoteTypes, getCustomIconLabel} from 'shared/utils/instruction-utils';
import {INSTRUCTION_TYPE} from 'shared/enums';

//components
import StepsMenu from 'components/StepList/StepsMenu';
import {StepCardDesktop as StepCard} from 'shared/components/StepCard';
import {Checkbox} from 'antd';
import BinaryOptions from 'shared/components/StepCheckToolbar/BinaryOptions';
import MultipleOptionsCms from 'shared/components/StepCheckToolbar/MultipleOptions/MultipleOptionsCms';
import ChecklistIndicatorIcons from 'shared/components/ChecklistIndicatorIcons';

//styles
import {
  HINT_BUTTON_COLOR,
  HINT_BUTTON_WITH_CONTENT_COLOR,
  HintContent,
  HintContentFader,
  HintContentInner,
  InnerWrapper,
  StyledCardToolbar,
  StyledDragHandle,
  StyledEditor,
  StyledFadingOverflow,
  StyledHintEditor,
  StyledHintEditorWrapper,
  StyledHintMediaPicker,
  StyledHintCheckboxWrapper,
  StyledMediaPicker,
  StyledPencilIcon,
  StyledResponsiveFontSize,
  SwipeableContainerSlide,
  StyledStepNoteEditorInner
} from './styles';

//messages
import messages from './messages';

//constants
const hintPlaceholderMessages = {
  fix: messages.addFix,
  tip: messages.addTip,
  warning: messages.addWarning,
  custom: messages.addCustom,
  customEditable: messages.addCustomEditable
};

@inject('store')
@enhanceWithClickOutside
@observer
class StepCardComponent extends Component {
  static defaultProps = {
    canTranslate: false,
    dragHandleRef: noop,
    isDragHandleDisabled: false,
    isGhostly: false,
    isReadOnly: false,
    isStatic: false,
    isTransparent: false,
    onArchived: noop,
    onCloned: noop,
    onDragHandleMouseDown: noop,
    onSave: constant(Promise.resolve()),
    onToggleHint: noop,
    rootRef: noop,
    shouldShowUntranslatedIcon: false
  };

  static propTypes = {
    // Whether to include the "Translate" option to the pull-down menu.
    // It is proxied to the StepList/StepMenu component
    canTranslate: PropTypes.bool,

    // Callback-style ref to store a reference
    // to the drag and drop handle element
    dragHandleRef: PropTypes.func,

    // Whether the card drag and drop functionality should be disabled
    // (used on the steps that haven't been created yet)
    isDragHandleDisabled: PropTypes.bool,

    // Whether the card should be blurred and contentless
    // (used as the original position "placeholder" while dragging)
    isGhostly: PropTypes.bool,

    // Whether the content editors should be read-only.
    // Is always true when isStatic is true
    // (used on the steps that aren't under a draft guide)
    isReadOnly: PropTypes.bool,

    // Whether the card should allow interaction: editing,
    // opening pull-down menus, viewing hints
    // (used on the steps that aren't current
    // or when a background mutation is in progress)
    isStatic: PropTypes.bool,

    // Whether the card should be semi-transparent
    // (used on the steps that aren't current)
    isTransparent: PropTypes.bool,

    // Layout object from the StepsSwiperResizeListener component
    layout: LayoutType.isRequired,

    // Number to be shown on the step's badge
    number: PropTypes.node,

    // Event handler that is triggered when the step is archived.
    // It is proxied to the onArchive prop of the StepList/StepMenu component
    onArchived: PropTypes.func,

    // Event handler that is triggered when the step is cloned.
    // It is proxied to the onClone prop of the StepList/StepMenu component
    onCloned: PropTypes.func,

    // Event handler that is triggered when the mouse button is pressed over
    // the drag and drop handle element
    onDragHandleMouseDown: PropTypes.func,

    // Event handler that is triggered when a step save is requested.
    // The event argument will be supplied with the following properties:
    //   `key`    - the name of the updated field;
    //   `values` - all the field values.
    onSave: PropTypes.func,

    // Event handler that is triggered
    // when the currently selected hint type is toggled.
    onToggleHint: PropTypes.func,

    // Callback-style ref to store a reference to the root element
    rootRef: PropTypes.func,

    // Whether the "untranslated" icon should be shown near the pull-down menu.
    // It is proxied to the StepList/StepMenu component
    shouldShowUntranslatedIcon: PropTypes.bool,

    // The step model
    step: StepType
  };

  @observable.shallow editorInnerStyle = null;
  @observable.shallow editorOuterStyle = null;
  @observable isFocused = false;
  @observable selectedHintType = null;
  editorInstance = null;
  form = NewSlideForm();
  rootElement = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    const {
      store: {
        editInstructionDetailsPage: {setTranslations}
      },
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(
      pick(messages, [
        'hiddenResponse',
        'shownResponse',
        'somethingWentWrong',
        'hideOneResponse',
        'hideTwoResponses',
        'hideThreeResponses'
      ]),
      message => formatMessage(message)
    );

    setTranslations(translations);
  }

  UNSAFE_componentWillMount() {
    this.updateFormFromStep(this.props.step);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.step, nextProps.step)) {
      this.updateFormFromStep(nextProps.step);
    }
  }

  getInitialValue = fieldName => {
    const {step} = this.props;

    const [type, formIndex, field] = fieldName.split('.');
    if (type !== 'notes') {
      return get(step, fieldName);
    }

    const iconId = this.form.$(`notes.${formIndex}.iconId`).get('value');

    return get(find(step.notes, {iconId}), field);
  };

  bindField = (fieldName, options = {}, shouldCheckContent) => {
    const {step, store} = this.props;
    const {unsavedChangesDialog} = store;

    const bindedProps = bindField(this.form, fieldName, options);
    const onChange = bindedProps.onChange;

    const fieldProps = {
      ...bindedProps,
      onChange: (event, ...otherParams) => {
        onChange(event, ...otherParams);

        const initialValue = this.getInitialValue(fieldName);
        const currentValue = options.checkbox ? event.target.checked : event.target.value;

        if (editorHasChanges(initialValue, currentValue)) {
          unsavedChangesDialog.saveAction = () => this.propagateSave(fieldName.split('.')[0]);
          unsavedChangesDialog.onDiscard = () => this.updateFormFromStep(step);
        } else {
          unsavedChangesDialog.reset();
        }
      }
    };

    if (shouldCheckContent && fieldProps.value) {
      const value = JSON.parse(fieldProps.value);

      return {shouldDisableImage: value.blocks.filter(block => block.text).length === 0};
    }

    return fieldProps;
  };

  blurEditor = () => {
    if (this.editorInstance) {
      this.editorInstance.blur();
    }
  };

  closeHintEditor = () => {
    this.setSelectedHintType(null);
  };

  focusEditor = () => {
    if (this.editorInstance) {
      this.editorInstance.focus();
    }
  };

  getHintMedia = type => {
    if (!type || !this.props.step) return null;
    const note = find(get(this.props.step, 'notes'), {iconId: type});
    return get(note, 'media');
  };

  getStepCardToolbarButtonStyle = hintType => {
    const {step, store} = this.props;
    const {platform} = store;
    const {canUseMediaInStepHints} = platform;

    const notes = get(step, 'notes');
    const hasContent = editorHasValue(get(find(notes, {iconId: hintType}), 'content'));
    const hasMedia = !!get(this.getHintMedia(hintType), 'url');
    const hasAnything = hasContent || (canUseMediaInStepHints && hasMedia);

    return {
      color: hasAnything ? HINT_BUTTON_WITH_CONTENT_COLOR : HINT_BUTTON_COLOR
    };
  };

  handleClickOutside = event => {
    const {isStatic, store} = this.props;
    const {unsavedChangesDialog} = store;

    const isModal = Boolean(closest(event.target, '[class*="ReactModal"]'));

    if (isStatic || isModal) {
      return;
    }

    if (this.selectedHintType && !unsavedChangesDialog.opened) {
      unsavedChangesDialog.check().then(() => {
        this.closeHintEditor();
      });
    }
  };

  handleEditorBlur = () => {
    this.setIsFocused(false);
  };

  handleEditorCancel = () => {
    const {step} = this.props;
    this.updateFormFromStep(step);
    this.blurEditor();
  };

  handleEditorFocus = () => {
    this.setIsFocused(true);
  };

  handleEditorSave = () => {
    this.blurEditor();
    return this.propagateSave('contentHtml');
  };

  handleHintBackdropClick = () => {
    const {store} = this.props;
    const {unsavedChangesDialog} = store;

    unsavedChangesDialog.check().then(() => {
      this.closeHintEditor();
    });
  };

  handleHintEditorCancel = () => {
    const {step} = this.props;
    this.updateFormFromStep(step);
    this.closeHintEditor();
  };

  handleHintEditorSave = () => {
    return this.propagateSave('notes').then(() => {
      this.closeHintEditor();
    });
  };

  handleHintMediaChange = () => this.propagateSave('notes');

  handleMediaChange = () => this.propagateSave('mediaId');

  handlePencilIconClick = () => {
    this.focusEditor();
  };

  handleResponsiveFontSizeChange = style => {
    const paddings = pickBy(style, (value, key) => startsWith(key, 'padding'));
    const halvedPaddings = mapValues(paddings, padding => padding / 2);
    this.setEditorInnerStyle({...style, ...halvedPaddings});
    this.setEditorOuterStyle(halvedPaddings);
  };

  handleStepCardToggleHint = ({type}) => {
    const {store} = this.props;
    const {unsavedChangesDialog} = store;

    unsavedChangesDialog.check().then(() => {
      this.setSelectedHintType(type);
    });
  };

  propagateSave = key => {
    const {onSave, store} = this.props;
    const {unsavedChangesDialog} = store;

    const values = this.form.values();
    if (key === 'contentHtml') {
      values.contentHtml = draftToHtml(JSON.parse(values.instruction));
    }

    if (key === 'notes') {
      values.notes.forEach(note => {
        note.noteContentHtml = draftToHtml(JSON.parse(note.content));
      });
    }

    return onSave({key, values}).then(() => {
      unsavedChangesDialog.reset();
    });
  };

  @action
  setEditorInnerStyle = editorInnerStyle => {
    this.editorInnerStyle = editorInnerStyle;
  };

  setEditorInstance = editorInstance => {
    this.editorInstance = editorInstance;
  };

  @action
  setEditorOuterStyle = editorOuterStyle => {
    this.editorOuterStyle = editorOuterStyle;
  };

  @action
  setIsFocused = isFocused => {
    this.isFocused = isFocused;
  };

  setRootRef = element => {
    const {rootRef} = this.props;
    this.rootElement = element;
    rootRef(element);
  };

  @action
  setSelectedHintType = selectedHintType => {
    const {onToggleHint} = this.props;
    this.selectedHintType = selectedHintType;
    onToggleHint(selectedHintType);
  };

  updateFormFromStep = step => {
    const {store} = this.props;
    const {
      unsavedChangesDialog,
      app: {
        theme: {icons}
      }
    } = store;

    const updateobj = {
      instruction: convertHtmlToDraft(step?.instructionHtml),
      mediaId: get(step, 'media.id'),
      notes: icons.map(icon => {
        const defaultValues = {content: emptyContent, mediaId: null, mandatory: false};
        const note = find(get(step, 'notes'), {iconId: icon.id}) || defaultValues;
        return {
          iconId: icon.id,
          mandatory: note.mandatory && icon.type === stepNoteTypes.WARNING,
          mediaId: get(note, 'media.id'),
          content: get(note, 'content')
        };
      })
    };

    this.form.update(updateobj);

    unsavedChangesDialog.reset();
  };

  get hasMedia() {
    return Boolean(get(this.props.step, 'media.url'));
  }

  get isCreating() {
    return !get(this.props.step, 'id');
  }

  get isReadOnly() {
    const {isReadOnly, isStatic} = this.props;
    return isReadOnly || isStatic;
  }

  get hintMediaPickerBackgroundSizes() {
    const {layout} = this.props;
    const {imageWidth, orientation} = layout;
    return `${orientation === 'portrait' ? imageWidth : imageWidth - 40}px`;
  }

  get mediaPickerBackgroundSizes() {
    const {layout} = this.props;
    const {imageWidth} = layout;
    return `${imageWidth}px`;
  }

  get stepId() {
    return get(this.props.step, 'id');
  }
  get stepIndex() {
    return get(this.props.step, 'position');
  }

  @computed
  get selectedHintMedia() {
    return this.getHintMedia(this.selectedHintType);
  }

  @computed
  get selectedHintField() {
    let hintField = null;

    // mobx-form map property
    this.form.$('notes').map(field => {
      if (field.$('iconId').get('value') === this.selectedHintType) {
        hintField = field;
      }
    });

    return hintField;
  }

  @computed
  get isWarningNote() {
    const icons = get(this.props, 'store.app.theme.icons');
    return !!find(icons, {id: this.selectedHintType, type: stepNoteTypes.WARNING});
  }

  toggleMandatoryWarning = field => field.set('value', !field.value);

  @computed
  get stepNotePlaceholder() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {
      platform: {currentLanguage}
    } = store;
    const icons = get(store, 'app.theme.icons');

    const selectedNote = find(icons, {id: this.selectedHintType});

    if (
      selectedNote.type !== stepNoteTypes.CUSTOM ||
      !selectedNote.labelTranslations ||
      !selectedNote.labelTranslations.length
    ) {
      return formatMessage(hintPlaceholderMessages[selectedNote.type.toLowerCase()]);
    }

    return formatMessage(hintPlaceholderMessages.customEditable, {
      noteLabel: '"' + getCustomIconLabel(selectedNote.labelTranslations, currentLanguage) + '"'
    });
  }

  render() {
    const {
      canTranslate,
      dragHandleRef,
      intl,
      isDragHandleDisabled,
      isGhostly,
      isStatic,
      isTransparent,
      isCurrent,
      layout,
      number,
      onArchived,
      onCloned,
      onDragHandleMouseDown,
      shouldShowUntranslatedIcon,
      step,
      store,
      handleImproveTextClick,
      hideDisableButton,
      onToggleTemplateOption,
      checklistTemplateOptions
    } = this.props;
    const {formatMessage} = intl;
    const {editInstructionDetailsPage, platform, saveAsDraftDialog, unsavedChangesDialog, suggestionDialog} = store;
    const {currentInstruction} = editInstructionDetailsPage;
    const {
      canUseMediaInStepHints,
      hasChecklistsEnabled,
      developmentFeatureFlags: {checklistScoring}
    } = platform;
    const {setCurrentStepPosition} = suggestionDialog;
    const checklistTemplate = currentInstruction?.checklistTemplate;
    const isCheck = currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;
    setCurrentStepPosition(number);

    const footer =
      isCheck && hasChecklistsEnabled ? (
        !checklistScoring ? (
          <ChecklistIndicatorIcons />
        ) : checklistTemplate?.type === 'BINARY' ? (
          <BinaryOptions
            options={checklistTemplate?.options}
            disabled
            testIdPrefix={`binary-options-${this.stepIndex}`}
          />
        ) : (
          <MultipleOptionsCms
            stepId={this.stepId}
            disabled
            onToggleTemplateOption={onToggleTemplateOption}
            options={checklistTemplateOptions}
            hideDisableButton={hideDisableButton}
            testIdPrefix={`score-options-${this.stepIndex}`}
          />
        )
      ) : null;

    return (
      <SwipeableContainerSlide
        ref={this.setRootRef}
        isGhostly={isGhostly}
        isTransparent={isTransparent}
        layout={layout}
      >
        <InnerWrapper isInvisible={isGhostly} data-cy="step-card">
          <StyledDragHandle
            cardSpacing={layout.cardSpacing}
            forwardedRef={dragHandleRef}
            isDisabled={isDragHandleDisabled}
            onMouseDown={onDragHandleMouseDown}
          />
          <StepCard
            isCMS={true}
            checklistTemplate={currentInstruction?.checklistTemplate}
            content={
              <StyledFadingOverflow isDisabled={this.isFocused}>
                <StyledResponsiveFontSize
                  baseWidth={380}
                  minFontSize={rem(1)}
                  minPadding={rem(2)}
                  onResize={this.handleResponsiveFontSizeChange}
                >
                  {!this.isReadOnly && !this.isFocused && <StyledPencilIcon onClick={this.handlePencilIconClick} />}
                  <StyledEditor
                    editorRef={this.setEditorInstance}
                    editorStyle={this.editorInnerStyle}
                    onBlur={this.handleEditorBlur}
                    onCancel={this.handleEditorCancel}
                    onClick={saveAsDraftDialog.check}
                    onFocus={this.handleEditorFocus}
                    onSave={this.handleEditorSave}
                    orientation={layout.orientation}
                    readOnly={this.isReadOnly}
                    shouldHideToolbarOnBlur
                    style={this.editorOuterStyle}
                    handleImproveTextClick={handleImproveTextClick}
                    {...this.bindField('instruction')}
                  />
                  {footer}
                </StyledResponsiveFontSize>
              </StyledFadingOverflow>
            }
            media={
              <StyledMediaPicker
                allowVideos
                backgroundAspectRatio={4 / 3}
                backgroundMetadata={get(step, 'media.metadata')}
                backgroundSizes={this.mediaPickerBackgroundSizes}
                backgroundUrl={get(step, 'media.url')}
                canOpen={() => unsavedChangesDialog.check().then(() => saveAsDraftDialog.check())}
                field="mediaId"
                form={this.form}
                orientation={layout.orientation}
                previewType={MEDIA_PREVIEW_TYPE.STEP_OVERVIEW}
                shouldShowOverlayShapes
                showUpdateNotifications={!this.isCreating}
                toolbar={
                  !isStatic && !this.isCreating ? (
                    <StyledCardToolbar
                      dropdownMenu={
                        <StepsMenu
                          canTranslate={canTranslate}
                          hideEdit
                          onArchive={onArchived}
                          onClone={onCloned}
                          step={step}
                          shouldShowUntranslatedIcon={shouldShowUntranslatedIcon}
                        />
                      }
                      isInverted={this.hasMedia}
                      shouldShowUntranslatedIcon={shouldShowUntranslatedIcon}
                    />
                  ) : null
                }
                updateMedia={this.handleMediaChange}
              />
            }
            hintContent={
              !isStatic && this.selectedHintType ? (
                <HintContent orientation={layout.orientation} imageHeight={layout.imageHeight} data-cy="step-hint">
                  <HintContentFader className="fader" orientation={layout.orientation} />
                  <HintContentInner className="content" onClick={this.handleHintBackdropClick}>
                    <div onClick={event => event.stopPropagation()}>
                      <StyledHintEditorWrapper orientation={layout.orientation}>
                        <StyledStepNoteEditorInner>
                          <StyledHintEditor
                            autoFocus
                            key={this.selectedHintType}
                            onCancel={this.handleHintEditorCancel}
                            editorStyle={this.editorInnerStyle}
                            onClick={saveAsDraftDialog.check}
                            onEscape={this.handleHintEditorCancel}
                            onSave={this.handleHintEditorSave}
                            orientation={layout.orientation}
                            placeholder={this.stepNotePlaceholder}
                            readOnly={this.isReadOnly}
                            {...this.bindField(this.selectedHintField.$('content').path)}
                          />
                          {this.isWarningNote && (
                            <StyledHintCheckboxWrapper>
                              <Checkbox
                                data-cy="checkbox"
                                {...this.bindField(this.selectedHintField.$('mandatory').path, {checkbox: true})}
                              >
                                {formatMessage(messages.mandatoryNoteCheckbox)}
                              </Checkbox>
                            </StyledHintCheckboxWrapper>
                          )}
                        </StyledStepNoteEditorInner>
                      </StyledHintEditorWrapper>

                      {canUseMediaInStepHints && (
                        <StyledHintMediaPicker
                          backgroundAspectRatio={4 / 3}
                          backgroundMetadata={get(this.selectedHintMedia, 'metadata')}
                          backgroundSizes={this.hintMediaPickerBackgroundSizes}
                          backgroundUrl={get(this.selectedHintMedia, 'url')}
                          canOpen={() => unsavedChangesDialog.check().then(() => saveAsDraftDialog.check())}
                          field={this.selectedHintField.$('mediaId').path}
                          form={this.form}
                          orientation={layout.orientation}
                          previewType={MEDIA_PREVIEW_TYPE.STEP_OVERVIEW}
                          updateMedia={this.handleHintMediaChange}
                          shouldShowOverlayShapes
                          {...this.bindField(this.selectedHintField.$('content').path, {}, true)}
                        />
                      )}
                    </div>
                  </HintContentInner>
                </HintContent>
              ) : null
            }
            getToolbarButtonStyle={this.getStepCardToolbarButtonStyle}
            number={number}
            onToggleHint={this.handleStepCardToggleHint}
            orientation={layout.orientation}
            selectedHintType={this.selectedHintType}
            shouldAllToolbarItemsBeEnabled={!this.isCreating}
            step={step}
            isCurrent={isCurrent}
            isCheck={isCheck}
          />
        </InnerWrapper>
      </SwipeableContainerSlide>
    );
  }
}

export default injectIntl(StepCardComponent);
