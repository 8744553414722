import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';
import {transparentize} from 'shared/utils/color-utils';

export const StyledHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${({dark}) => (dark ? commonPalette.txBlack : commonPalette.txLightGrey)};
`;

export const StyledContent = styled.div`
  color: ${commonPalette.txBlack};
  margin-top: 10px;
  max-height: 150px;
  overflow: scroll;
`;

export const StyledInfo = styled.div`
  color: ${commonPalette.txLightGrey};
  margin: 0 10px;
`;

export const Section = styled.div`
  margin: 0 10px 20px 10px;
`;

export const LoadingHeader = styled.div`
  color: ${commonPalette.sgGreen};
  font-size: 20px;
  text-align: center;
`;

export const LoadingSubtitle = styled.div`
  font-size: 20px;
  text-align: center;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const LoadingWrapper = styled.div`
  padding: 20px;
  padding: 0 0 74px 0;
`;

export const dialogStyles = {
  width: 378
};

export const GreenStyledInfo = styled.div`
  margin: 12px 0 0 0;
  padding: 18px;
  background-color: ${transparentize('#2987841A', 1)};
  display: flex;
  justify-content: center;
`;

export const CircleDiv = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: ${commonPalette.sgGreen};
  padding: 3px 0 5px 5px;
  margin-right: 10px;
`;

export const checkIconStyle = {color: 'white', height: 15, width: 15};

export const NoSuggestionWrapper = styled.div`
  margin: 0 10px;
`;
