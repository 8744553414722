import {action, observable, computed, makeObservable} from 'mobx';

//helpers
import store from 'stores/store';
import notification from 'utils/notification-utils';
import views from 'config/views';
import {clamp} from 'lodash';

class ReassignGuideDialog {
  @observable translations = {};
  @observable opened = false;
  @observable confirmDialogOpened = false;
  @observable newAssigneeId = null;
  @observable comment = null;
  @observable originalUsers = null;
  @observable selectedTeamId = null;
  @observable loading = true;
  @observable teams = [];
  @observable teamId = null;
  @observable reassignTeamContent = true;
  @observable guideId = null;
  @observable guideTitle = null;
  /**
   * @type {boolean | null}
   */
  @observable isUnderApproval = false;
  @observable guideAssigneeId;
  @observable reassigning = false;
  openingConfirmDialog = false;
  reassignGuideMutation = null;

  // Pagination
  @observable offset = 0;
  @observable limit = 50;
  @observable searchTerm = '';

  @action
  resetPagination = () => {
    this.offset = 0;
    this.searchTerm = '';
  };

  @action
  setSearchTerm = searchTerm => {
    this.resetPagination();
    this.searchTerm = searchTerm;
  };

  @action
  reset = () => {
    if (this.openingConfirmDialog) {
      this.openingConfirmDialog = false;
      return;
    }
    this.opened = false;
    this.confirmDialogOpened = false;
    this.guideId = null;
    this.guideTitle = null;
    this.isUnderApproval = null;
    this.newAssigneeId = null;
    this.guideAssigneeId = null;
    this.comment = null;
    this.content = null;
    this.selectedTeamId = null;
    this.loading = true;
    this.reassigning = false;
    this.reassignGuideMutation = null;
    this.reassignTeamContent = false;
  };

  @action setLoading = loading => (this.loading = loading);

  @action setGuideAssignee = userId => {
    this.guideAssigneeId = userId;
  };

  @action selectPerson = userId => {
    this.newAssigneeId = userId;
  };

  @action
  addComment = commentEvent => {
    this.setComment(commentEvent.target.value);
  };

  @action
  setComment = comment => {
    this.comment = comment;
  };

  @action
  setReassigning = value => {
    this.reassigning = value;
  };

  @action
  open = ({guideId, teamId, guideTitle, isUnderApproval}) => {
    this.opened = true;
    this.loading = true;
    this.guideId = guideId;
    this.guideTitle = guideTitle;
    this.isUnderApproval = isUnderApproval;
    this.teamId = teamId;
    teamId && this.setSelectedTeamId(teamId);
  };

  @action
  openConfirmDialog = () => {
    this.opened = false;
    this.confirmDialogOpened = true;
    this.openingConfirmDialog = true;
  };

  @action
  addToOffset = () => {
    this.offset += this.limit;
  };

  @action
  setTeams = (teams, excludeTeamIds = [], hasMore) => {
    /**
     * Filter teams:
     * - without users
     * - from the ids to exclude
     * - remove users with VIEWER role
     */
    this.teams = teams.filter(t => {
      const team = {...t};
      team.teamMembers = team.teamMembers.filter(teamMember => teamMember.role !== 'VIEWER');
      return !excludeTeamIds.includes(team.id) && team.teamMembers.length > 0;
    });
    if (this.teams.length < clamp(this.limit, 10, 50) && hasMore) {
      this.addToOffset();
    }

    if (!this.selectedTeamId && this.teamId) {
      this.setSelectedTeamId(this.teamId);
    }
  };

  @action
  onScrollToBottom = teamsPaginatedQuery => {
    const {teamsPaginated, loading} = teamsPaginatedQuery;
    const finishedFetching = teamsPaginated && teamsPaginated.totalCount < teamsPaginated.offset;
    if (!finishedFetching && !loading) {
      this.addToOffset();
    }
  };

  @action
  pushTeams = (teams, excludeTeamIds = [], hasMore) => {
    this.teams.push(
      ...teams.filter(t => {
        const team = {...t};
        team.teamMembers = team.teamMembers.filter(teamMember => teamMember.role !== 'VIEWER');
        return !excludeTeamIds.includes(team.id) && team.teamMembers.length > 0;
      })
    );
    if (this.teams.length < clamp(this.limit, 10, 50) && hasMore) {
      this.addToOffset();
    }
  };

  @action
  setSelectedTeamId = teamId => {
    const team = this.teams.find(({id}) => id === teamId);
    if (!team) return;

    this.selectedTeamId = teamId;
  };

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  toggleReassignTeamContent = (value = !this.reassignTeamContent) => {
    this.reassignTeamContent = value;
    this.loading = false;
  };

  @action
  reassignGuideSubmit = reassignGuideMutation => {
    if (this.isUnderApproval && this.teamId !== this.selectedTeamId) {
      this.reassignGuideMutation = reassignGuideMutation;
      this.openConfirmDialog();
    } else {
      this.reassignGuide(reassignGuideMutation);
    }
  };

  @action
  confirmReassignGuideSubmit = () => {
    this.reassignGuide(this.reassignGuideMutation);
  };

  @action
  reassignGuide = reassignGuideMutation => {
    this.setReassigning(true);
    store.router.goTo(views.guides, {});

    reassignGuideMutation({
      id: this.guideId,
      newAssigneeId: this.newAssigneeId,
      newTeamId: this.selectedTeamId,
      comment: this.comment
    })
      .then(() => {
        this.reset();
        notification.success(this.translations.reassignSuccess);
      })
      .catch(() => {
        this.opened = false;
        this.confirmDialogOpened = false;
        this.setReassigning(false);
        store.router.goTo(views.editGuide, {id: this.guideId});
        notification.error(this.translations.reassignError);
      });
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get enableArchiveSubmit() {
    if (!this.reassignTeamContent) {
      return true;
    }
    return !!(this.selectedTeamId && this.newAssigneeId && this.comment);
  }

  @computed
  get filteredUsers() {
    if (!this.selectedTeamId) {
      return [];
    }

    const team = this.teams.find(({id}) => id === this.selectedTeamId);
    if (!team) {
      return [];
    }

    let users = team.teamMembers.map(({user}) => user);

    if (this.teamId === this.selectedTeamId && this.guideAssigneeId) {
      users = users.filter(user => user.id !== this.guideAssigneeId);
    }

    return users;
  }
}

export default ReassignGuideDialog;
