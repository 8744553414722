import {defineMessages} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'ContactMethodPicker.placeholder',
    defaultMessage: 'Select a contact method'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  phoneNumber: {
    id: 'common.forms.phoneNumber',
    defaultMessage: 'Phone Number'
  }
});

export default messages;
