import {action, makeObservable, observable} from 'mobx';
import Raven from 'raven-js';
import views from 'config/views';

import notification from 'utils/notification-utils';

class SuggestionDialog {
  @observable opened = false;
  @observable originalText;
  @observable suggestedText;
  @observable stepId;
  @observable loading;
  @observable error;
  @observable originalStepContent;
  @observable currentStepPosition;

  constructor() {
    makeObservable(this);
  }

  @action
  reset = () => {
    this.opened = false;
    this.originalText = null;
    this.suggestedText = null;
    this.stepId = null;
    this.error = null;
    this.loading = null;
    this.originalStepContent = null;
    this.currentStepPosition = null;
  };

  @action open = (originalText, suggestedText, stepId) => {
    this.opened = true;
    this.loading = null;
    this.error = null;
    this.originalText = originalText ? originalText.trim() : '';
    this.suggestedText = suggestedText ? suggestedText.trim() : '';
    this.stepId = stepId;
  };

  @action setError = error => (this.error = error);

  @action setLoading = loading => {
    this.loading = loading;

    if (loading) {
      this.opened = true;
    }
  };

  @action setCurrentStepPosition = index => {
    this.currentStepPosition = index;
  };

  setStore = store => (this.store = store);

  @action setOriginalStepContent = originalStepContent => (this.originalStepContent = originalStepContent);

  @action
  updateStep = async (useSuggestion, currentStep, updateStepMutation, translations) => {
    this.setLoading(true);

    const {
      editInstructionDetailsPage: {isChecklist}
    } = this.store;

    let instruction;

    if (useSuggestion) {
      const parsedStep = JSON.parse(currentStep.instruction);
      parsedStep.blocks = [parsedStep.blocks[0]];
      parsedStep.blocks[0].text = this.suggestedText.trim().replaceAll('\n', '');
      instruction = JSON.stringify(parsedStep);
    } else {
      instruction = this.originalStepContent;
    }

    const updateStep = {id: this.stepId, step: {instruction}};

    try {
      await updateStepMutation(updateStep);
      notification.success(isChecklist ? translations.saveCheckSucess : translations.saveStepSucess);
    } catch (error) {
      Raven.captureException(error);
      notification.error(isChecklist ? translations.updateCheckError : translations.updateStepError);
    } finally {
      this.reset();
    }
  };

  @action
  createStep = async (useSuggestion, createStepMutation, translations) => {
    this.setLoading(true);

    const {
      editInstructionDetailsPage: {isChecklist, currentInstruction, guide},
      router
    } = this.store;

    let instruction;

    if (useSuggestion) {
      const suggestedImprovement = this.suggestedText.trim().replaceAll('\n', '');
      instruction = `{"blocks":[{"key":"dqmjq","text":"${suggestedImprovement}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;
    } else {
      instruction = this.originalStepContent;
    }

    const newStep = {
      instruction,
      position: this.currentStepPosition - 1
    };

    try {
      const {
        data: {createStep}
      } = await createStepMutation({step: newStep});
      const params = {
        id: guide.id,
        topicId: currentInstruction.topicId,
        instructionId: currentInstruction.id,
        stepId: createStep.id
      };
      router.goTo(views.editInstruction, params);

      notification.success(isChecklist ? translations.createCheckSuccess : translations.createStepSuccess);
    } catch (error) {
      Raven.captureException(error);
      notification.error(isChecklist ? translations.createCheckError : translations.createStepError);
    } finally {
      this.reset();
    }
  };
}

export default SuggestionDialog;
