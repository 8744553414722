import React, {Component} from 'react';
import {inject} from 'mobx-react';

//styles
import {DisabledMenuItem, MenuItem, MenuItemExternalLink, Icon, SecondaryIcon} from './styles';

@inject('store')
class MenuItemComponent extends Component {
  render() {
    const {item, selected, store} = this.props;

    const icon = item.icon && <Icon name={item.icon} size={18} />;
    const caption = <span dangerouslySetInnerHTML={{__html: item.caption}} />;
    const sections = item.sections ? item.sections.filter(s => !s.disabled) : [];
    const secondaryIcon = item.secondaryIcon && <SecondaryIcon name={item.secondaryIcon} size={13} />;
    let view = null;

    if (item.view) {
      view = item.view;
    } else {
      view = sections.length ? sections[0].view : null;
    }

    if (item.href) {
      return (
        <MenuItemExternalLink href={item.href} target="_blank">
          {icon}
          {caption}
          {secondaryIcon}
        </MenuItemExternalLink>
      );
    }

    if (item.disabled || !view) {
      return (
        <DisabledMenuItem>
          {icon}
          {caption}
        </DisabledMenuItem>
      );
    }

    return (
      <MenuItem selected={selected} store={store} route={view} params={item.params} data-cy={item.id}>
        {icon}
        {caption}
      </MenuItem>
    );
  }
}

export default MenuItemComponent;
