import {defineMessages} from 'react-intl';

const messages = defineMessages({
  noJobTitles: {
    id: 'noJobTitles',
    defaultMessage: "You don't have any Job Titles yet."
  },
  createJobTitle: {
    id: 'createJobTitle',
    defaultMessage: 'Create Job Title'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  title: {
    id: 'jobTitles',
    defaultMessage: 'Job Titles'
  },
  searchJobTitlesPlaceholder: {
    id: 'searchJobTitlesPlaceholder',
    defaultMessage: 'Search for Job Titles'
  },
  jobTitleName: {
    id: 'jobTitleName',
    defaultMessage: 'Job Title Name'
  },
  jobTitleUsersCount: {
    id: 'Users.title',
    defaultMessage: 'Users'
  },
  jobTitleTeamsCount: {
    id: 'Menu.teams',
    defaultMessage: 'Teams'
  }
});

export default messages;
