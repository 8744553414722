import styled from 'styled-components';
import $Button from 'ui-components/Button';

export const ActionMenu = styled.div``;

export const MainActionsWrapper = styled.div`
  position: fixed;
  top: 70px;
  right: 20px;
  display: flex;
  flex-direction: row-reverse;
`;

export const MainActionButton = styled($Button).attrs({
  size: 60,
  round: true
})``;

export const SecondaryActionButton = styled($Button).attrs({
  size: 60,
  round: true,
  secondary: true
})`
  margin-right: 10px;
`;
