import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {pick, mapValues} from 'lodash';

import {Campaigns} from 'api/campaign/queries';
import {campaignsOptions} from 'api/campaign/query-options';
import {ApplyCampaign} from 'api/campaign/mutations';
import {applyCampaignOptions} from 'api/campaign/mutation-options';

import Dialog from 'ui-components/Dialog';
import RadioButton from 'shared/components/RadioButton';

import {STATUS_OPTIONS} from 'shared/enums';
import notification from 'utils/notification-utils';

import messages from './messages';
import {
  StyledSpinner,
  Subtitle,
  DialogContentWrapper,
  CampaignsWrapper,
  CampaignTitle,
  PreviewWrapper,
  RemoveCampaignButton,
  OptionWrapper,
  ErrorText,
  dialogStyle
} from './styles';

@inject('store')
@graphql(Campaigns, campaignsOptions)
@graphql(ApplyCampaign, applyCampaignOptions)
@observer
class ApplyCampaignDialog extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount = () => {
    const {
      store: {applyCampaignDialog},
      intl: {formatMessage}
    } = this.props;

    const translations = mapValues(
      pick(messages, [
        'campaignsApplySuccess',
        'campaignsApplyError',
        'campaignApplySuccess',
        'campaignApplyTo0Instructions'
      ]),
      message => formatMessage(message)
    );

    applyCampaignDialog.setTranslations(translations);
  };

  onSubmit() {
    const {
      store: {
        applyCampaignDialog: {submit, appliedFromInstruction, instructions},
        overrideCampaignDialog: {open: openOverrideCampaignDialog}
      },
      applyCampaignToInstructionsMutation
    } = this.props;

    const hasCampaignsApplied = instructions.some(instruction => instruction.campaign);

    hasCampaignsApplied && !appliedFromInstruction
      ? openOverrideCampaignDialog(applyCampaignToInstructionsMutation)
      : submit(applyCampaignToInstructionsMutation);
  }

  render() {
    const {
      intl: {formatMessage},
      store: {
        applyCampaignDialog: {
          opened,
          reset: resetApplyCampaignDialog,
          selectedCampaign,
          setSelectedCampaign,
          appliedFromInstruction,
          showRemoveButton
        },
        campaignPreviewDialog: {open: openCampaignPreviewDialog},
        removeCampaignDialog: {open: openRemoveCampaignDialog}
      },
      data: {campaigns, loading, error}
    } = this.props;

    const publishedCampaigns = Array.isArray(campaigns)
      ? campaigns
          .filter(campaign => campaign.status === STATUS_OPTIONS.PUBLISHED)
          .sort((a, b) => {
            if (a.title.toLowerCase() < b.title.toLowerCase()) {
              return -1;
            }
            if (a.title.toLowerCase() > b.title.toLowerCase()) {
              return 1;
            }
            return 0;
          })
      : [];

    const campaignsList = publishedCampaigns.map(campaign => (
      <CampaignsWrapper key={campaign.id}>
        <OptionWrapper onClick={() => setSelectedCampaign(campaign)}>
          <RadioButton
            caption={<CampaignTitle>{campaign.title}</CampaignTitle>}
            checked={selectedCampaign && selectedCampaign.id === campaign.id}
          />
        </OptionWrapper>
        <PreviewWrapper onClick={() => openCampaignPreviewDialog(campaign)}>
          {formatMessage(messages.preview)}
        </PreviewWrapper>
      </CampaignsWrapper>
    ));

    let actions = [
      {
        label: formatMessage(messages.apply),
        onClick: this.onSubmit,
        disabled: !selectedCampaign
      }
    ];

    const dialogTitle = appliedFromInstruction
      ? formatMessage(messages.instructionTitle)
      : formatMessage(messages.guideTitle);
    const removeButtonText = appliedFromInstruction
      ? formatMessage(messages.removeCampaignInstruction)
      : formatMessage(messages.removeCampaignGuide);
    const customCancelButtonText = error || campaignsList.length === 0 ? messages.close : messages.cancel;

    let content;

    if (loading) {
      content = <StyledSpinner />;
    } else if (error) {
      notification.error(formatMessage(messages.fetchingCampaignError));
      actions = [];
      content = <ErrorText>{formatMessage(messages.fetchingCampaignError)}</ErrorText>;
    } else if (campaignsList.length === 0) {
      actions = [];
      content = <Subtitle>{formatMessage(messages.noActivatedCampaigns)}</Subtitle>;
    } else {
      content = (
        <div>
          <Subtitle>{formatMessage(messages.subtitle)}</Subtitle>
          {campaignsList}
        </div>
      );
    }

    const leftButtonComponent = (
      <RemoveCampaignButton onClick={openRemoveCampaignDialog}>{removeButtonText}</RemoveCampaignButton>
    );

    return (
      <Dialog
        actions={actions}
        isOpen={Boolean(opened)}
        onCancel={resetApplyCampaignDialog}
        title={dialogTitle}
        dialogStyle={dialogStyle}
        customCancelButtonText={customCancelButtonText}
        leftBottomComponent={showRemoveButton && leftButtonComponent}
      >
        <DialogContentWrapper>{content}</DialogContentWrapper>
      </Dialog>
    );
  }
}

export default injectIntl(ApplyCampaignDialog);
