import {defineMessages} from 'react-intl';

const messages = defineMessages({
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  delete: {
    id: 'common.actions.delete',
    defaultMessage: 'Delete'
  }
});

export default messages;
