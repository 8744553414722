import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import moment from 'moment';

import {DATE_TIME_FORMAT} from 'config/constants';

import messages from './messages';
import {WrapperDiv, TableWrapper, TablesWrapper, LeftColumn, RightColumn, Cell} from './styles';

import {SectionTitle} from '../styles';
import {displayChecklistScore} from 'utils/string-utils';

interface Props {
  intl: any;
  store: any;
}

@inject('store')
@observer
class SessionDetailsSection extends Component<Props> {
  render() {
    const {
      intl: {formatMessage},
      store: {
        checklistsPage: {
          currentChecklistSessionDetails: {
            instruction: {title: checklistTitle, checklistTemplate},
            guide: {title: guideTitle},
            submittedAt,
            score,
            user,
            signatureUrl
          }
        },
        platform: {
          hasChecklistsSignatureEnabled,
          developmentFeatureFlags: {checklistScoring}
        }
      }
    } = this.props;

    return (
      <WrapperDiv>
        <SectionTitle>{formatMessage(messages.sessionDetails)}</SectionTitle>
        <TablesWrapper>
          <TableWrapper>
            <LeftColumn>
              <Cell>{formatMessage(messages.checklist)}</Cell>
              <Cell>{formatMessage(messages.dateAndTime)}</Cell>
              <Cell>{formatMessage(messages.score)}</Cell>
              {checklistScoring && <Cell>{formatMessage(messages.checklistTemplateType)}</Cell>}
            </LeftColumn>
            <RightColumn>
              <Cell data-cy="checklist-title">{checklistTitle}</Cell>
              <Cell>{moment(submittedAt).format(DATE_TIME_FORMAT)}</Cell>
              <Cell data-cy="checklist-score">{displayChecklistScore(checklistTemplate.type, score)}</Cell>
              {checklistScoring && <Cell data-cy="checklist-template-type">{checklistTemplate.name}</Cell>}
            </RightColumn>
          </TableWrapper>
          <TableWrapper>
            <LeftColumn>
              <Cell>{formatMessage(messages.user)}</Cell>
              <Cell>{formatMessage(messages.guide)}</Cell>
              <Cell>{signatureUrl && hasChecklistsSignatureEnabled && formatMessage(messages.signature)}</Cell>
            </LeftColumn>
            <RightColumn>
              <Cell data-cy="checklist-user">{user}</Cell>
              <Cell data-cy="checklist-guide">{guideTitle}</Cell>
              <Cell>
                {signatureUrl && hasChecklistsSignatureEnabled && (
                  <img src={signatureUrl} height={80} style={{aspectRatio: 2 / 1}} />
                )}
              </Cell>
            </RightColumn>
          </TableWrapper>
        </TablesWrapper>
      </WrapperDiv>
    );
  }
}

export default injectIntl(SessionDetailsSection);
