import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

import Dialog from 'ui-components/Dialog';
import {FormattedMessage} from 'components/FormattedComponents';

import messages from './messages';
import {dialogStyle, StyledText} from './styles';

@inject('store')
@observer
class ConfirmGuideReassignDialog extends Component {
  render() {
    const {
      store: {reassignGuideDialog: dialog},
      intl: {formatMessage}
    } = this.props;
    const {confirmDialogOpened: isOpen, reassigning, guideTitle, confirmReassignGuideSubmit} = dialog;

    const title = formatMessage(messages.confirmReassignGuide);
    const content = (
      <StyledText>
        <FormattedMessage
          {...messages.submittedForApproval}
          values={{
            title: <b>{guideTitle}</b>
          }}
        />
      </StyledText>
    );

    const actions = [
      {
        label: formatMessage(messages.confirm),
        loading: reassigning,
        onClick: confirmReassignGuideSubmit,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel
        onCancel={dialog.reset}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {content}
      </Dialog>
    );
  }
}

export default injectIntl(ConfirmGuideReassignDialog);
