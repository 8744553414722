import React from 'react';
import {Container, Circle, Label} from './styles';

export type SmartSkillsLegendItemProps = {
  color: string;
  icon?: JSX.Element;
  label: string;
  outline?: boolean;
  iconStyles?: React.CSSProperties;
  preserveBackground?: boolean;
};

export default function SmartSkillsLegendItem({
  label,
  color,
  icon,
  outline,
  iconStyles,
  preserveBackground
}: Readonly<SmartSkillsLegendItemProps>) {
  return (
    <Container>
      <Circle color={color} outline={outline} preserveBackground={preserveBackground}>
        <div style={iconStyles}>{icon}</div>
      </Circle>
      <Label>{label}</Label>
    </Container>
  );
}
