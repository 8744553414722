import React from 'react';
import {inject, Observer} from 'mobx-react';
import Select from 'ui-components/Select';
import {onChangeLanguageToAdd, getAddLanguagesOptions} from './utils';
import messages from './messages';

const AddNewLanguageDialogContent = ({store, formatMessage}) => {
  const addLanguagesOptions = getAddLanguagesOptions(store);
  const onChange = value => onChangeLanguageToAdd(value, store);

  return (
    <Observer>
      {() => (
        <Select
          placeholder={formatMessage(messages.selectLanguage)}
          selectedValue={store.autoTranslationDialog.selectedLanguageToAdd}
          onChange={onChange}
          options={addLanguagesOptions}
          defaultValue={formatMessage(messages.selectLanguage)}
        />
      )}
    </Observer>
  );
};

export default inject('store')(AddNewLanguageDialogContent);
