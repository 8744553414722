import React from 'react';
import messages from './messages';

const TranslationSuccessDialogContent = ({formatMessage}) => {
  return (
    <div>
      <p>{formatMessage(messages.inaccurateTranslations)}</p>
    </div>
  );
};

export default TranslationSuccessDialogContent;
