import React from 'react';
import {FormattedMessage} from 'components/FormattedComponents';
import {PersonWrapper} from './styles';
import get from 'lodash/get';
import messages from './messages';
import {Person as PersonType} from './types';

type PersonProps = {
  person: PersonType;
};

export const Person = (props: PersonProps) => {
  const {person} = props;
  return <PersonWrapper>{get(person, 'fullName', <FormattedMessage {...messages.removedUser} />)}</PersonWrapper>;
};
