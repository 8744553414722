import {action, observable, makeObservable} from 'mobx';

/**
 * used to show the user that the limit of
 * enable icos was reached
 */
class IconsLimitDialog {
  @observable opened = false;

  @action
  reset = () => {
    this.opened = false;
  };

  @action
  open = () => {
    this.opened = true;
  };

  constructor() {
    makeObservable(this);
  }
}

export default IconsLimitDialog;
