import {colors} from '../constants';

export const correctHtmlContent = (htmlContent, linksMap, isStepContent = false) => {
  let correctedHtmlContent = htmlContent || '';

  correctedHtmlContent = correctedHtmlContent.split('<li></li>').join('');
  correctedHtmlContent = correctedHtmlContent.split('<ul></ul>').join('');
  correctedHtmlContent = correctedHtmlContent.split('<ol></ol>').join('');

  // The pdfmake library does not support rgba so we have to pass rgb
  const splittedByRgba = correctedHtmlContent.split('rgba(');

  if (splittedByRgba.length > 1) {
    for (let i = 1; i < splittedByRgba.length; i++) {
      const splittedByEnding = splittedByRgba[i].split(');');

      if (splittedByEnding[0].match(/^\d/)) {
        const splitByComma = splittedByEnding[0].split(',');

        splitByComma.pop();

        splittedByEnding[0] = splitByComma.join(',');
      }

      splittedByRgba[i] = splittedByEnding.join(');');
    }
  }

  correctedHtmlContent = splittedByRgba.join('rgb(');

  if (correctedHtmlContent.includes('<br>')) {
    correctedHtmlContent = correctedHtmlContent.split('<br>').join(' ');
  }

  if (correctedHtmlContent.includes('<ins>')) {
    correctedHtmlContent = correctedHtmlContent.split('<ins>').join('<u>');
    correctedHtmlContent = correctedHtmlContent.split('</ins>').join('</u>');
  }

  if (correctedHtmlContent.includes('</p>') && correctedHtmlContent.length > 8) {
    correctedHtmlContent = correctedHtmlContent.split('</p>').join('</p><br />');
    correctedHtmlContent = correctedHtmlContent.split('<p>').join('<p><br />');
  }

  if (correctedHtmlContent.includes('<p>&nbsp;</p>')) {
    correctedHtmlContent = correctedHtmlContent.replace('<p>&nbsp;</p>', '');
  }

  if (correctedHtmlContent.includes('<p><br />&nbsp;</p><br />')) {
    correctedHtmlContent = correctedHtmlContent.replace('<p><br />&nbsp;</p><br />', '');
  }

  if (correctedHtmlContent.includes('<p><br /></p><br />')) {
    correctedHtmlContent = correctedHtmlContent.replace('<p><br /></p><br />', '<br />');
  }

  if (correctedHtmlContent.includes('href="')) {
    const links = [];

    const splitedContent = correctedHtmlContent.split('href="');

    splitedContent.forEach(item => {
      if (item.startsWith('http')) {
        links.push(item.split('"')[0]);
      }
    });

    const linksWithPosition = [];

    links.forEach(link => {
      const index = linksMap.findIndex(
        linkObj => linkObj.link === link && !linkObj.annotated && linkObj.isStepNoteLink === !isStepContent
      );

      if (index >= 0) {
        linksMap[index].annotated = true;

        linksWithPosition.push({link, position: index + 1});
      }
    });

    let annotatedHtml = correctedHtmlContent;

    const domParser = new DOMParser();
    const doc = domParser.parseFromString(annotatedHtml, 'text/html');

    const sameLinksMap = {};

    const getCorrectLinkElement = (link, nth) => doc.querySelectorAll(`a[href="${link}"]`)[nth];

    linksWithPosition.forEach(linkObj => {
      const linkElement = getCorrectLinkElement(linkObj.link, sameLinksMap[linkObj.link] || 0);

      sameLinksMap[linkObj.link] = (sameLinksMap[linkObj.link] || 0) + 1;

      const numberElement = document.createElement('span');
      numberElement.style.cssText = `color: ${colors.WHITE}; background-color: ${colors.BLACK}; font-size: 9px;`;

      numberElement.innerText = ` ${linkObj.position} `;

      linkElement.insertAdjacentElement('afterend', numberElement);
      numberElement.insertAdjacentHTML('beforebegin', '&nbsp;');
      numberElement.insertAdjacentHTML('afterend', '&nbsp;');
    });

    annotatedHtml = doc.querySelector('body').innerHTML;
    return annotatedHtml;
  }

  return correctedHtmlContent;
};

export const makeListVisible = content => {
  content.forEach((stepContent, i) => {
    if (stepContent.ul === '') {
      delete stepContent.ul;
      delete stepContent.style;
    }

    if (stepContent.ol === '') {
      delete stepContent.ol;
      delete stepContent.style;
    }

    if (stepContent.ul) {
      const ul = {text: [{text: '\n'}]};

      stepContent.ul.forEach(li => {
        if (Array.isArray(li.text)) {
          const liText = [{text: '•  '}];

          li.text.forEach(text => liText.push({...text}));

          ul.text.push({text: liText});
        } else {
          li.text = `•  ${li.text}\n`;

          ul.text.push(li);
        }
      });

      content[i] = ul;
    }

    if (stepContent.ol) {
      const ol = {text: [{text: '\n'}]};

      stepContent.ol.forEach((li, k) => {
        if (Array.isArray(li.text)) {
          const liText = [{text: `${k + 1}.  `}];

          li.text.forEach(text => liText.push({...text}));

          liText.push({text: '\n'});

          ol.text.push({text: liText});
        } else {
          li.text = `${k + 1}.  ${li.text}\n`;

          ol.text.push(li);
        }
      });

      content[i] = ol;
    }
  });
};

export const getLettersFromStepContent = stepContent => {
  let lettersInStepContent = '';

  stepContent.forEach(textPart => {
    if (Array.isArray(textPart.text)) {
      textPart.text.forEach(str => {
        if (typeof str === 'string') {
          lettersInStepContent += str;
        } else if (typeof str.text === 'string') {
          lettersInStepContent += str.text;
        }
      });
    }

    if (Array.isArray(textPart.ul)) {
      textPart.ul.forEach(str => {
        if (typeof str === 'string') {
          lettersInStepContent += str;
        } else if (typeof str.text === 'string') {
          lettersInStepContent += str.text;
        }
      });
    }

    if (Array.isArray(textPart.ol)) {
      textPart.ol.forEach(str => {
        if (typeof str === 'string') {
          lettersInStepContent += str;
        } else if (typeof str.text === 'string') {
          lettersInStepContent += str.text;
        }
      });
    }
  });

  return lettersInStepContent;
};

export const colorSvg = (svg, isWarning) => {
  if (svg) {
    const splittedSvg = svg.split('fill="');

    if (splittedSvg.length === 1) {
      const noColorSvg = splittedSvg[0].split('<svg');

      noColorSvg[1] = ` fill="${isWarning ? colors.BLACK : colors.GRAY}" ${noColorSvg[1]}`;

      return noColorSvg.join('<svg');
    }

    const modifiedSvg = splittedSvg
      .map(piece => {
        if (piece.startsWith('#')) {
          return `${isWarning ? colors.BLACK : colors.GRAY}${piece.slice(7)}`;
        }

        return piece;
      })
      .join('fill="');

    return modifiedSvg;
  }
};

export const formatPdfMessages = (intl, messages) => ({
  version: intl.formatMessage(messages.pdfVersion),
  created: intl.formatMessage(messages.pdfCreated),
  toc: intl.formatMessage(messages.pdfToc),
  topic: intl.formatMessage(messages.topic),
  instruction: intl.formatMessage(messages.pdfInstruction),
  step: intl.formatMessage(messages.pdfStep),
  warning: intl.formatMessage(messages.warning),
  tip: intl.formatMessage(messages.pdfTip),
  fix: intl.formatMessage(messages.pdfFix),
  alternative: intl.formatMessage(messages.pdfAlternative),
  upToDate: intl.formatMessage(messages.pdfUpToDate),
  pdfReferences: intl.formatMessage(messages.pdfReferences),
  pdfMediaItemHeader: intl.formatMessage(messages.pdfMediaItemHeader),
  goTo: intl.formatMessage(messages.pdfGoTo),
  languageNotSupported: intl.formatMessage(messages.pdfErrorLanguageNoteSupported),
  check: intl.formatMessage(messages.pdfCheck)
});

export const hasMeaningfulHtml = html => {
  // Create a new div element
  const temporalDivElement = document.createElement('div');

  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;

  // Retrieve the text property of the element (cross-browser support)
  return (
    (temporalDivElement.textContent && temporalDivElement.textContent !== '\n') ||
    (temporalDivElement.innerText && temporalDivElement.innerText !== '\n') ||
    ''
  );
};
