import styled from 'styled-components';
import $Icon from 'ui-components/Layout/Icon';
import Loader from 'shared/components/Spinner';
import {commonPalette} from 'shared/styles/palette';

export const colors = {
  iconGrey: commonPalette.txGrey, // #757575
  textGrey: commonPalette.txBlack, // #333330
  backgroundGrey: '#EDEDED'
};

export const DropdownIcon = styled($Icon).attrs(() => ({
  name: 'chevron-down',
  size: 16
}))`
  color: ${colors.iconGrey};
  margin-bottom: 3px;
  margin-right: 5px;
  transform: rotate(${props => (props.open ? '0deg' : '-180deg')});
  transition: transform 0.3s ease-out;
  ${props => (props.disabled ? `color: ${colors.backgroundGrey};` : '')}
  cursor: ${props => (props.disabled ? 'not-allowed' : 'disabled')};
`;

export const Table = styled.table`
  border-collapse: collapse;
  text-align: left;
`;

export const StickyHeader = styled.thead`
  position: sticky;
  top: 100px;
  background: white;
  z-index: 100;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
`;

const getTextAlign = props => {
  if (props.left) {
    return 'left';
  }
  return props.right ? 'right' : 'center';
};

export const Th = styled.th`
  padding: 8px;
  text-align: ${props => getTextAlign(props)};
  font-weight: normal;
  font-size: 14px;
  color: ${colors.iconGrey};
  cursor: ${props => (props.hover || props.sortable ? 'pointer' : '')};
  max-width: ${props => props.width};
  height: 50px;

  &:hover > div {
    text-decoration: ${props => (props.hover ? 'underline' : '')};
  }
`;

export const Td = styled.td`
  height: 50px;
  text-align: ${props => getTextAlign(props)};
`;

export const Tr = styled.tr`
  text-align: left;

  ${props =>
    props.border
      ? 'border-bottom: 1px solid #ededed;'
      : '&:not(:last-child) > td { border-bottom: 1px solid #ededed;}'};

  > th:first-child {
    padding-left: 12px;
  }

  > th:not(:first-child) {
    padding-left: 0px;
  }

  > td:first-child {
    ${props => (props.depth ? `padding-left: ${props.depth * 12}px` : 'padding-left: 12px')};
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 50px;
  }

  > td:nth-child(2) {
    ${props => (props.depth ? `padding-left: ${(props.depth - 1) * 12}px` : 'padding-left: 0px')};
  }
`;

export const TrCollapsable = styled(Tr)`
  ${props => (props.color ? `color: ${props.color}` : '')};
  ${props => (props.header ? `background: #f9f9f9;` : '')};
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;

  > td:first-child {
    cursor: ${props => (props.header ? '' : ' pointer')};
    font-weight: bold;
    border-left: 1px solid #ededed;
    text-align: left;
    max-width: 50px;
  }

  > td:last-child {
    border-right: 1px solid #ededed;
    float: right;
    padding: 8px;
    padding-right: 16px;
  }

  &:hover {
    background-color: ${props => (props.header ? '' : '#f5f5f5')};
    cursor: ${props => (props.header ? '' : ' pointer')};
  }
`;

export const Spinner = styled(Loader).attrs(() => ({
  size: '1rem'
}))`
  margin: 0px 10px;
`;

export const SortingComponent = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-left: 8px;
  ${props => (props.sorting ? 'content: url(/static/media/sort-default.0fa9aa13.svg);' : '')}
  ${props => (props.sorting === 'asc' ? 'content: url(/static/media/sort-asc.d2699359.svg);' : '')}
  ${props => (props.sorting === 'desc' ? 'content: url(/static/media/sort-desc.afa62ab1.svg);' : '')}
`;

export const StyledIcon = styled($Icon)`
  color: ${colors.iconGrey};
`;
