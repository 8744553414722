import React, {Component} from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import {injectIntl} from 'react-intl';

//components
import Button from 'ui-components/Button';

//styles
import {DropdownWrapper, DropdownItem, Label} from './styles';
import {MainActionButton, MainActionsWrapper} from '../styles';

//messages
import messages from './messages';

@enhanceWithClickOutside
class DropdownActionMenu extends Component {
  handleClickOutside = () => {
    const {handleClickOutside} = this.props;
    handleClickOutside();
  };

  getIconId = actionId => (actionId === 'versionHistory' ? 'activity' : actionId);

  render() {
    const {
      mainAction,
      toggle,
      actions,
      onClick,
      expand,
      intl: {formatMessage}
    } = this.props;

    return (
      <DropdownWrapper>
        <MainActionsWrapper>
          <MainActionButton iconId={mainAction.id} onClick={() => toggle()} />
        </MainActionsWrapper>
        {actions.map((action, index) => (
          <DropdownItem key={index} index={index} expand={expand}>
            <Label>{formatMessage(messages[action.id])}</Label>
            <Button
              round
              secondary
              size={50}
              iconId={this.getIconId(action.id)}
              onClick={expand ? () => onClick(action) : null}
            />
          </DropdownItem>
        ))}
      </DropdownWrapper>
    );
  }
}

export default injectIntl(DropdownActionMenu);
