//refresh queries
import {refreshCampaign, refreshCampaigns} from 'api/campaign/refresh-queries';
import {refreshInstruction} from 'api/instruction/refresh-queries';
import {client} from 'utils/apollo-client';

export const createCampaignOptions = {
  options: {
    client
  },
  props: ({mutate}) => ({
    createCampaignMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshCampaigns()]
      });
    }
  })
};

export const editCampaignOptions = {
  props: ({mutate}) => ({
    editCampaignMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshCampaigns(), refreshCampaign(variables.id)]
      })
  })
};

export const applyCampaignOptions = {
  props: ({mutate}) => ({
    applyCampaignToInstructionsMutation: variables =>
      mutate({
        variables,
        refetchQueries: variables.instructionIds.map(instructionId => refreshInstruction(instructionId))
      })
  })
};

export const removeCampaignOptions = {
  props: ({mutate}) => ({
    removeCampaignFromInstructionsMutation: variables =>
      mutate({
        variables,
        refetchQueries: variables.instructionIds.map(instructionId => refreshInstruction(instructionId))
      })
  })
};

export const setCampaignStatusOptions = {
  props: ({mutate}) => ({
    setCampaignStatusMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshCampaigns()]
      })
  })
};
