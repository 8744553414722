import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {mapValues, pick} from 'lodash';
import {
  Header,
  BackButton,
  BackIcon,
  Wrapper,
  SubWrapper,
  StyledText,
  StyledSubText,
  StyledButton,
  StyledDots,
  StyledActivitiesDiv,
  Activity,
  ActivitiesBackground,
  SecondWrapper,
  DeleteAll,
  FieldWrapper
} from './styles';
import messages from './messages';
import views from 'config/views';
import {CreateSkill} from 'api/skills/mutations';
import {createSkillOptions} from 'api/skills/mutation-options';
import InfoBox from 'ui-components/InfoBox';

import {getReskillingFrequencyOptions} from '../../config/constants';

//components
import Container from 'ui-components/Layout/Container';
import Field from 'ui-components/Field';
import ButtonGroup from 'ui-components/ButtonGroup';
import Button from 'ui-components/Button';
import ActivitiesDrawer from 'components/ActivitiesDrawer';
import Select from '../../ui-components/Select';
import TagPicker from 'components/TagPicker';

@inject('store')
@graphql(CreateSkill, createSkillOptions)
@observer
class CreateSkills extends Component {
  componentWillUnmount() {
    const {
      store: {skillsPage, learningActivityGeneralInfo}
    } = this.props;
    if (learningActivityGeneralInfo.previousPage !== views.createSkills) {
      skillsPage.setCreateSkillName('');
      skillsPage.updateBackFromLearnings(false);
      skillsPage.deleteAllActivities();
      skillsPage.setSelectedFrequencyOption(null);
      skillsPage.setSelectedTags([]);
      skillsPage.setLocalSelectedTags([]);
    }
  }

  componentDidMount() {
    const {
      store,
      intl: {formatMessage},
      createSkillMutation
    } = this.props;
    const {skillsPage} = store;
    const translations = mapValues(
      pick(messages, ['createdSkill', 'skillAlreadyExists', 'failedCreateSkill']),
      message => formatMessage(message)
    );

    skillsPage.setTranslations(translations);

    if (createSkillMutation) {
      skillsPage.setCreateSkillMutation(createSkillMutation);
    }
  }

  render() {
    const {
      intl: {formatMessage},
      store: {router, skillsPage}
    } = this.props;
    const {
      deleteAllActivities,
      setIsActivitiesDrawerOpened,
      removeSelectedLearningActivity,
      hasSelectedLearningActivities,
      setSelectedFrequencyOption,
      selectedFrequencyOption,
      setCreateSkillName,
      skillName,
      createSkill,
      selectedLearningActivities,
      selectedTagsIds,
      setSelectedTags,
      localSelectedTags,
      setLocalSelectedTags
    } = skillsPage;

    return (
      <Container style={{paddingBottom: '50px', height: 'auto'}}>
        <Header>
          <BackButton
            onClick={() => {
              router.goTo(views.skills, {tab: 'general'});
            }}
          >
            <BackIcon />
          </BackButton>
          {formatMessage(messages.createSkill)}
        </Header>
        <Wrapper>
          <SubWrapper>
            <StyledText>{formatMessage(messages.generalInformation)}</StyledText>
          </SubWrapper>

          <SubWrapper>
            <Field
              styles={{Title: {marginTop: '20px'}, Input: {marginBottom: '5px'}}}
              autofocus={true}
              size="small"
              title={formatMessage(messages.skillName)}
              placeholder={formatMessage(messages.title)}
              hideError={true}
              field="title"
              value={skillName}
              onChange={e => {
                setCreateSkillName(e.target.value);
              }}
            />
            <Select
              label={formatMessage(messages.reskillingFrequency)}
              options={getReskillingFrequencyOptions(formatMessage, messages)}
              defaultValue={0}
              selectedValue={selectedFrequencyOption}
              placeholder={formatMessage(messages.noReskilling)}
              onChange={selectedValue => {
                setSelectedFrequencyOption(selectedValue);
              }}
            />
          </SubWrapper>
        </Wrapper>
        <Wrapper>
          <SubWrapper>
            <StyledText>{formatMessage(messages.labels)}</StyledText>
          </SubWrapper>
          <FieldWrapper hasMoreSelectedTags={selectedTagsIds.length > 6}>
            {selectedTagsIds.length === 10 && (
              <InfoBox content={formatMessage(messages.max10Tags)} type="informational" />
            )}
            <Field
              size="small"
              title={formatMessage(messages.tags)}
              className="tag-selection"
              hideError={true}
              inputComponent={
                <TagPicker
                  className="tag-picker"
                  selectedTagsIds={selectedTagsIds}
                  setSelectedTags={setSelectedTags}
                  disableCreate={true}
                  isSkillTagsPicker={true}
                  localSelectedTags={localSelectedTags}
                  setLocalSelectedTags={setLocalSelectedTags}
                />
              }
            />
          </FieldWrapper>
        </Wrapper>
        <SecondWrapper>
          <SubWrapper>
            <StyledText>{formatMessage(messages.learningActivities)}</StyledText>
            <StyledSubText>{formatMessage(messages.learningMaterials)}</StyledSubText>
          </SubWrapper>
          <SubWrapper>
            {!hasSelectedLearningActivities && <StyledDots>...</StyledDots>}
            {!!hasSelectedLearningActivities && (
              <>
                <DeleteAll onClick={() => deleteAllActivities()}>{formatMessage(messages.removeAll)}</DeleteAll>
                <ActivitiesBackground>
                  {selectedLearningActivities?.map(
                    learningActivity =>
                      learningActivity && (
                        <Activity key={learningActivity.id}>
                          <StyledActivitiesDiv>{learningActivity.title}</StyledActivitiesDiv>
                          <Button iconId={'remove'} onClick={() => removeSelectedLearningActivity(learningActivity)} />
                        </Activity>
                      )
                  )}
                </ActivitiesBackground>
              </>
            )}
            <StyledButton
              onClick={() => {
                setIsActivitiesDrawerOpened(true);
              }}
              iconId="add"
              label={formatMessage(messages.addLearningActivities)}
            />
          </SubWrapper>
        </SecondWrapper>
        <SubWrapper>
          <ButtonGroup
            style={{marginTop: 0, borderTop: 0}}
            line
            primary={{
              label: formatMessage(messages.create),
              onClick: () => {
                createSkill();
              },
              disabled: !skillName
            }}
            secondary={{
              label: formatMessage(messages.cancel),
              onClick: () => {
                router.goTo(views.skills, {});
              }
            }}
          />
        </SubWrapper>
        <ActivitiesDrawer view={views.createSkills} />
      </Container>
    );
  }
}

export default injectIntl(CreateSkills);
