import React from 'react';
import {action, autorun, observable, computed, makeObservable, runInAction} from 'mobx';

//helpers
import notification from 'utils/notification-utils';
import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';
import {posthogCapture} from 'shared/utils/posthog-utils';
import {templateTypes, templates} from 'config/campaign-question-templates';
import views from 'config/views';
import store from 'stores/store';

//forms
import campaignQuestionForm from 'stores/forms/campaign-question-form';

//models
import FollowUpQuestion from 'stores/models/follow-up-question';
import Steps from 'stores/models/steps';

//components
import CampaignTemplate from 'components/CampaignForm/CampaignTemplate';
import CampaignQuestions from 'components/CampaignForm/CampaignQuestions';
import CampaignPreview from 'components/CampaignPreview';
import CampaignTextOption from 'stores/models/campaign-text-type-options';

//lodash
import map from 'lodash/map';
import every from 'lodash/every';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import toInteger from 'lodash/toInteger';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';

class NewCampaignPage {
  @observable loading = false;
  @observable followUps = [];
  @observable autofocusFollowUp = false;
  @observable isMobileView = false;
  @observable defaultLocale;
  translations = {};
  @observable textTypeOptions = [];
  @observable questionTemplateType;
  @observable questionTemplate;
  @observable templateForm = {isValid: false};

  questionForm = campaignQuestionForm;

  @observable stepsStore = null;

  setTranslations = translations => {
    this.translations = translations;
  };

  setDomainName = name => {
    this.domainName = name;
  };

  @action setLoading = val => (this.loading = val);

  @action setFollowUps = followUps => {
    this.followUps = followUps;
  };

  //campaign preview
  @action setViewMode = val => (this.isMobileView = val);

  constructor() {
    makeObservable(this);

    this.stepsStore = new Steps({
      steps: [
        {
          key: 'template',
          path: 'template',
          disabled: false,
          form: this.templateForm,
          component: <CampaignTemplate isNewCampaign={true} />,
          checked: false
        },
        {
          key: 'questions',
          path: 'questions',
          component: <CampaignQuestions isNewCampaign={true} />,
          form: this.questionForm,
          checked: false
        }
      ],
      preview: {
        key: 'preview',
        path: 'preview',
        component: <CampaignPreview isNewSignOff={true} />
      }
    });
  }

  //campaign templates
  get questionTemplateTypeOptions() {
    return templateTypes;
  }

  @action chooseTemplateType = value => {
    if (this.questionTemplateType === value) {
      this.questionTemplateType = null;
      this.questionTemplate = null;
    } else this.questionTemplateType = value;
  };

  @computed get questionTemplateOptions() {
    return !this.questionTemplateType ? flatMap(templates) : templates[this.questionTemplateType];
  }

  @action chooseTemplate = template => {
    this.templateForm.isValid = true;
    this.questionTemplate = template.value;

    const {kind, question, numOfRating, followUps, textTypeOptions} = template.question;
    this.questionForm.update({kind, question: this.insertDomainName(question, this.domainName), numOfRating});

    this.textTypeOptions = textTypeOptions.map(option => {
      return new CampaignTextOption(option);
    });
    this.followUps = followUps.map(followUp => {
      return new FollowUpQuestion(followUp);
    });

    if (get(store, 'analytics.posthogIsLoaded', false)) {
      posthogCapture('Signoff Template Chosen', {template: template.name});
    }
  };

  @computed get selectedQuestionTemplate() {
    return this.questionValuesHaveChanged ? 'custom' : this.questionTemplate;
  }

  @action insertDomainName = (question, name) => {
    const regexp = '[company]';
    return question.includes(regexp) ? question.replace(regexp, name) : question;
  };

  @action startWithoutTemplate = resetTemplate => {
    this.stepsStore.steps[0].disabled = true;
    if (resetTemplate) {
      this.questionForm.reset();
      this.followUps = [];
      this.textTypeOptions = [];
    }
    this.templateForm.isValid = true;
    store.router.goTo(views.newCampaign, {tab: 'questions'});
  };

  //campaign questions -- default locale
  @action setDefaultLocale = locale => {
    this.questionForm.$('defaultLocale').sync(locale);
    this.defaultLocale = locale;
  };

  // -- question type
  @computed get questionType() {
    return this.questionForm.$('kind').value;
  }

  @computed get isBinaryQuestionType() {
    return (
      this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO ||
      this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL
    );
  }

  @computed get isStarQuestionType() {
    return this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.STAR;
  }

  @computed get isNumberQuestionType() {
    return this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.NUMBER;
  }

  @computed get isTextQuestionType() {
    return this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT;
  }

  @computed get filteredTextTypeOptions() {
    return this.textTypeOptions.filter((item, index) => index < this.maxRating);
  }

  // -- question rating range
  @computed get minFollowUpRating() {
    return this.isBinaryQuestionType ? 0 : 1;
  }

  @computed get maxRating() {
    return Number(this.questionForm.$('numOfRating').get('value'));
  }

  /*
   * Currently, this watcher is never actually disposed as the store is instantiated
   * only once and just gets recycled each time. However, if we ever reinstantiate
   * the store this should be called when the old instance is no longer needed to
   * prevent memory leaks.
   */
  @action
  disposeTextTypeOptionsSizeWatcher = autorun(() => {
    if (!this.isTextQuestionType) {
      return;
    }

    const length = this.textTypeOptions.length;

    if (length >= this.maxRating) {
      return;
    }

    const countToAdd = this.maxRating - length;

    runInAction(() => {
      for (let i = 0; i < countToAdd; i++) {
        this.textTypeOptions.push(new CampaignTextOption());
      }
    });
  });

  // -- question follow ups
  @computed
  get canAddFollowUp() {
    const {kind} = this.questionForm.values();
    const isBinary = kind === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO || kind === CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL;
    const followUpsEmpty = isEmpty(this.followUps);
    const optionsAvailable = !isEmpty(this.binaryOptions[1]);
    const overMaxLength = this.followUps.length === 2;

    return followUpsEmpty || !isBinary || (optionsAvailable && !overMaxLength);
  }

  @action addFollowUp = () => {
    this.followUps.push(new FollowUpQuestion());
    this.autofocusFollowUp = true;
  };

  @action removeFollowUp = followUp => {
    const index = this.followUps.indexOf(followUp);
    this.followUps.splice(index, 1);
  };

  // -- question follow ups - binary
  @computed get binaryOptions() {
    const options =
      this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO
        ? [
            {id: '0', name: 'NO'},
            {id: '1', name: 'YES'}
          ]
        : [
            {id: '0', name: 'FAIL'},
            {id: '1', name: 'PASS'}
          ];

    const firstFollowUp = !isEmpty(this.binarySelectValues) && this.binarySelectValues[0];
    const areOptionsStillAvailable = firstFollowUp.length < 2;

    if (!firstFollowUp || !areOptionsStillAvailable) return [options];
    else {
      const optionsSecondFollowUp = options.filter(option => option.id !== firstFollowUp[0]);
      return [options, optionsSecondFollowUp];
    }
  }

  @computed get binarySelectValues() {
    return this.followUps.map(fu => {
      const {rangeFrom, rangeTo} = fu.form.values();
      return rangeFrom ? (rangeFrom === rangeTo ? [rangeFrom] : [rangeFrom, rangeTo]) : [];
    });
  }

  @action saveFollowUpBinaryValues = (values, index) => {
    const {form} = this.followUps[index];
    const nextFollowUp = this.followUps.length > 1 ? (index === 0 ? this.followUps[1] : this.followUps[0]) : null;

    if (isEmpty(values)) {
      form.$('rangeFrom').reset();
      form.$('rangeTo').reset();
    } else if (values.length > 1) {
      form.$('rangeFrom').sync('0');
      form.$('rangeTo').sync('1');
      nextFollowUp && this.removeFollowUp(nextFollowUp);
    } else {
      form.$('rangeFrom').sync(values[0]);
      form.$('rangeTo').sync(values[0]);
    }
  };

  // campaign validation
  @computed get isValid() {
    return this.isFormValid && this.areFollowUpsValid && this.areTextTypeOptionsValid;
  }

  @computed get isFormValid() {
    return this.questionForm.isValid;
  }

  @computed get showSaveButton() {
    return this.stepsStore.currentStepIndex === this.stepsStore.steps.length - 1;
  }

  @computed get showCancelButton() {
    return this.stepsStore.currentStepIndex === 0;
  }

  // -- textTypeOptions validation
  @computed get areTextTypeOptionsValid() {
    if (this.questionType !== CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT) {
      return true;
    }

    return every(
      map(this.filteredTextTypeOptions, option => option.isValid()),
      item => item === true
    );
  }

  // -- question follow ups validation
  isFollowUpRangeValid = followUp => {
    const {form} = followUp;
    const values = form.values();
    let {rangeFrom, rangeTo} = values;
    const filteredFollowUps = filter(this.followUps, fu => fu !== followUp);

    rangeFrom = toInteger(rangeFrom);
    rangeTo = toInteger(rangeTo);

    const followUpRanges = map(filteredFollowUps, fu => {
      const values = fu.form.values();
      return {rangeFrom: values.rangeFrom, rangeTo: values.rangeTo};
    });

    return (
      rangeTo <= this.maxRating &&
      (filteredFollowUps.length === 0 ||
        every(
          map(followUpRanges, range => {
            if (isEmpty(range.rangeFrom) || isEmpty(range.rangeTo)) {
              return true;
            }

            range.rangeFrom = toInteger(range.rangeFrom);
            range.rangeTo = toInteger(range.rangeTo);

            const rangeUnderBorder = range.rangeFrom < rangeFrom && range.rangeTo < rangeFrom;
            const rangeOverBorder = range.rangeFrom > rangeTo && range.rangeTo > rangeTo;
            return rangeUnderBorder || rangeOverBorder;
          }),
          item => item === true
        ))
    );
  };

  @computed get areFollowUpsRangesValid() {
    return every(
      map(this.followUps, followUp => this.isFollowUpRangeValid(followUp)),
      item => item === true
    );
  }

  @computed get areFollowUpsValid() {
    return (
      every(
        map(this.followUps, followUp => followUp.isValid(this.minFollowUpRating, this.maxRating)),
        item => item === true
      ) && this.areFollowUpsRangesValid
    );
  }

  // save campaign
  @action submit = async createCampaignMutation => {
    const {title, defaultLocale, question, kind} = this.questionForm.values();
    let textTypeOptionsValues = [];

    const followUpValues = this.followUps.map(followUp => {
      const {question, kind, rangeFrom, rangeTo} = followUp.form.values();
      return {
        kind,
        rangeFrom: toInteger(rangeFrom),
        rangeTo: toInteger(rangeTo),
        questionTranslations: {[defaultLocale]: question}
      };
    });

    const questionTranslationValues = {[defaultLocale]: question};

    if (this.isTextQuestionType) {
      textTypeOptionsValues = this.filteredTextTypeOptions.map(item => ({[defaultLocale]: item.form.values().option}));
    }

    const campaign = {
      title,
      defaultLocale,
      question: {
        kind,
        numOfRating: this.maxRating,
        questionTranslations: questionTranslationValues,
        followUps: followUpValues,
        textTypeOptions: textTypeOptionsValues
      }
    };

    if (get(store, 'analytics.posthogIsLoaded', false)) {
      posthogCapture('Create Signoff', {signoff: campaign});
    }

    this.setLoading(true);

    const createCampaignSuccess = () => {
      notification.success(this.translations.createSuccess);
      this.reset();
    };

    const createCampaignError = () => {
      notification.error(this.translations.createFailure);
      this.reset();
    };

    return createCampaignMutation({campaign}).then(createCampaignSuccess, createCampaignError);
  };

  // reset all values
  @action reset = () => {
    this.questionForm.reset();
    this.followUps = [];
    this.loading = false;
    this.autofocusFollowUp = false;
    this.stepsStore.reset();
    this.stepsStore.steps[0].disabled = false;
    this.textTypeOptions = [];
    this.isMobileView = false;
    this.defaultLocale = undefined;
    this.questionTemplateType = undefined;
    this.questionTemplate = undefined;
  };
}

export default NewCampaignPage;
