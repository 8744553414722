import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'SignoffDateFilters.title',
    defaultMessage: 'Filter by date'
  },
  dateRange: {
    id: 'SignoffDateFilters.dateRange',
    defaultMessage: 'Date range'
  },
  starting: {
    id: 'SignoffDateFilters.starting',
    defaultMessage: 'Starting'
  },
  ending: {
    id: 'SignoffDateFilters.ending',
    defaultMessage: 'Ending'
  },
  apply: {
    id: 'common.actions.apply',
    defaultMessage: 'Apply'
  }
});

export default messages;
