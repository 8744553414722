import {defineMessages} from 'react-intl';

const messages = defineMessages({
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  saveTranslation: {
    id: 'EditTranslation.save',
    defaultMessage: 'Save translation'
  },
  saveSuccess: {
    id: 'EditTranslation.saveSuccess',
    defaultMessage: 'Translations have been saved.'
  },
  addSuccess: {
    id: 'EditTranslation.addSuccess',
    defaultMessage: 'Translation have been added.'
  },
  removeSuccess: {
    id: 'EditTranslation.removeSuccess',
    defaultMessage: 'Translation have been removed.'
  },
  backToGuide: {
    id: 'EditTranslation.backToGuide',
    defaultMessage: 'Back to guide'
  }
});

export default messages;
