import React, {Component} from 'react';
import D3Pie from './D3Pie.js';

class PieChartComp extends Component {
  componentDidMount() {
    this.d3pie = new D3Pie();
    this.d3pie.create(this._rootNode, this.props.data);
  }

  componentDidUpdate() {
    this.d3pie.update(this.props.data);
  }

  componentWillUnmount() {
    this.d3pie.destroy(this._rootNode);
  }

  _setRef(componentNode) {
    this._rootNode = componentNode;
  }

  render() {
    return <div id="pie" ref={this._setRef.bind(this)} />;
  }
}

export default PieChartComp;
