import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import {FormattedMessage} from 'components/FormattedComponents';
import RadioButton from 'shared/components/RadioButton';
import Dialog from 'ui-components/Dialog';
import Field from 'ui-components/Field';

import {EditCampaign} from 'api/campaign/mutations';
import {editCampaignOptions} from 'api/campaign/mutation-options';

import {timeOptions, OPTION_TYPE} from './constants';
import messages from './messages';
import {
  OptionWrapper,
  OptionsWrapper,
  StyledSelect,
  TimeOptionWrapper,
  StyledDiv,
  dialogBodyStyle,
  dialogStyle,
  selectStyle,
  customErrorStyle
} from './styles';

@inject('store')
@graphql(EditCampaign, editCampaignOptions)
@observer
class FrequencySettingsDialog extends Component {
  componentDidMount = () => {
    const {
      intl: {formatMessage},
      store: {frequencySettingsDialog}
    } = this.props;

    const translations = mapValues(pick(messages, ['editSuccess', 'editFailure']), message => formatMessage(message));

    frequencySettingsDialog.setTranslations(translations);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        frequencySettingsDialog: {
          opened,
          reset,
          campaign,
          optionType,
          showSave,
          submit,
          selectAlwaysOption,
          selectCustomOption,
          frequencySettingsForm
        }
      },
      editCampaignMutation
    } = this.props;

    const inputs = (
      <TimeOptionWrapper>
        <StyledDiv>
          <Field
            field={'entityNumber'}
            form={frequencySettingsForm}
            size={'xxsmall'}
            disabled={optionType === OPTION_TYPE.ALWAYS}
            type={'number'}
            width={80}
            min={1}
            display={'inline'}
            customErrorStyle={customErrorStyle}
            hideError={!showSave}
            onlyIntegers={true}
          />
          <StyledSelect
            allowClear={false}
            field={'entityType'}
            form={frequencySettingsForm}
            options={timeOptions}
            style={selectStyle}
            disabled={optionType === OPTION_TYPE.ALWAYS}
            width={120}
            display={'inline'}
          />
        </StyledDiv>
      </TimeOptionWrapper>
    );

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.save),
            onClick: () => submit(editCampaignMutation),
            disabled: !showSave
          }
        ]}
        isOpen={Boolean(opened)}
        onCancel={reset}
        title={formatMessage(messages.title, {title: campaign.title})}
        bodyStyle={dialogBodyStyle}
        dialogStyle={dialogStyle}
      >
        <OptionsWrapper>
          <OptionWrapper
            data-testid={`always-option-${optionType === OPTION_TYPE.ALWAYS ? 'selected' : 'not-selected'}`}
          >
            <RadioButton
              caption={<div>{formatMessage(messages.alwaysShowOption)}</div>}
              checked={optionType === OPTION_TYPE.ALWAYS}
              onChange={selectAlwaysOption}
            />
          </OptionWrapper>
          <OptionWrapper
            data-testid={`custom-option-${optionType === OPTION_TYPE.CUSTOM ? 'selected' : 'not-selected'}`}
          >
            <RadioButton
              caption={<FormattedMessage {...messages.customWaitOption} values={{inputs}} />}
              checked={optionType === OPTION_TYPE.CUSTOM}
              onChange={selectCustomOption}
            />
          </OptionWrapper>
        </OptionsWrapper>
      </Dialog>
    );
  }
}

export default injectIntl(FrequencySettingsDialog);
