import React, {Component, Children} from 'react';
import {defaultBreakpoints} from '../utils';

export default class BreakpointProvider extends Component {
  getChildContext() {
    const {breakpoints: propsBreakpoints = {}} = this.props;
    const {breakpoints: contextBreakpoints = {}} = this.context;

    return {
      breakpoints: {
        ...defaultBreakpoints,
        ...contextBreakpoints,
        ...propsBreakpoints
      }
    };
  }

  render() {
    return Children.only(this.props.children);
  }
}

export const withBreakpoints = WrappedComponent =>
  // eslint-disable-next-line react/no-multi-comp
  class Breakpoints extends Component {
    // eslint-disable-line  react/prefer-stateless-function

    render() {
      const {breakpoints} = this.context;

      return <WrappedComponent {...this.props} breakpoints={breakpoints} />;
    }
  };
