import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

//helpers
import views from 'config/views';
import {isDraftVersionId} from 'utils/versioning-utils';

//components
import CustomDragLayer from './CustomDragLayer';
import DraggableStepCard from './DraggableStepCard';
import NewStepLink from './NewStepLink';
import StepCard from './StepCard';

//styles
import {InnerWrapper, StepOverview, StepOverviewItem, StyledStepControls} from './styles';

@inject('store')
@observer
class StepOverviewComponent extends Component {
  componentDidMount() {
    this.setSteps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setSteps(nextProps);
  }

  insertNewStepBefore = index => {
    const {store} = this.props;
    const {router} = store;
    const {params, queryParams} = router;
    const {id, instructionId, topicId} = params;

    const stepId = `new-${index + 1}`;
    router.goTo(views.editInstruction, {id, instructionId, topicId, stepId}, queryParams);
  };

  handleStepDragHandleMouseDown = event => {
    const {store} = this.props;
    const {saveAsDraftDialog} = store;

    event.preventDefault(); // so the focus stays inside the dialog
    saveAsDraftDialog.check();
  };

  setSteps = props => {
    const {store, steps} = props;
    const {dragDropSteps: page} = store;
    page.setSteps(steps);
  };

  render() {
    const {store} = this.props;
    const {dragDropSteps, router} = store;
    const {steps} = dragDropSteps;
    const {id, instructionId, topicId} = router.params;

    return (
      <StepOverview>
        <InnerWrapper>
          {steps.map((step, index) => (
            <StepOverviewItem key={step.id}>
              <StyledStepControls onInsertNewStepClick={() => this.insertNewStepBefore(index)} />
              {this.renderStepCard(step, index)}
            </StepOverviewItem>
          ))}
          <StepOverviewItem>
            <NewStepLink
              params={{id, instructionId, stepId: 'new', topicId}}
              queryParams={router.queryParams}
              store={store}
              view={views.editInstruction}
            />
          </StepOverviewItem>
          <CustomDragLayer />
        </InnerWrapper>
      </StepOverview>
    );
  }

  renderStepCard(step, index) {
    const {store} = this.props;
    const {editInstructionDetailsPage} = store;
    const {currentVersionId} = editInstructionDetailsPage;

    const sharedProps = {step, index};

    if (!isDraftVersionId(currentVersionId)) {
      return <StepCard {...sharedProps} onDragHandleMouseDown={this.handleStepDragHandleMouseDown} />;
    }

    return <DraggableStepCard {...sharedProps} />;
  }
}

export default StepOverviewComponent;
