import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'NewTeam.title',
    defaultMessage: 'Create New Team'
  },
  teamName: {
    id: 'common.forms.teamName',
    defaultMessage: 'Team Name'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  createSuccess: {
    id: 'NewTeam.createSuccess',
    defaultMessage: 'Team has been created.'
  },
  createFailure: {
    id: 'NewTeam.createFailure',
    defaultMessage: 'Failed to create the team.'
  },
  createFailureMaxTeams: {
    id: 'NewTeam.createFailureMaxTeams',
    defaultMessage: 'Teams. Oh no! Unfortunately, you can’t create another team with your current subscription.'
  },
  contactSupport: {
    id: 'NewTeam.contactSupport',
    defaultMessage: 'Please contact support to raise your limit.'
  },
  createNewTeam: {
    id: 'NewTeam.createNewTeam',
    defaultMessage: 'Create New Team'
  },
  addUsersToTeam: {
    id: 'NewTeam.addUsersToTeam',
    defaultMessage: 'Add Users to Team'
  },
  setGuideApproval: {
    id: 'NewTeam.setGuideApproval',
    defaultMessage: 'Set guide approval'
  },
  connectTeamToWorkspace: {
    id: 'NewTeam.connectTeamToWorkspace',
    defaultMessage: 'Connect Team to Workspace'
  },
  setNotificationsForTeam: {
    id: 'NewTeam.setNotificationsForTeam',
    defaultMessage: 'Set Notifications for Team'
  }
});

export default messages;
