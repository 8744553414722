import {observable, action, computed, makeObservable} from 'mobx';
import {client} from 'utils/apollo-client';
import {refreshInstruction} from 'api/instruction/refresh-queries';
import Raven from 'raven-js';

//lodash
import get from 'lodash/get';
import omit from 'lodash/omit';
import truncate from 'lodash/truncate';

//helpers
import store from 'stores/store';
import notification from 'utils/notification-utils';

//models
import EditInstructionForm from 'stores/forms/edit-instruction-form';

//mutations
import {ToggleChecklistOption} from 'api/checklist-templates/mutations';

import {INSTRUCTION_TYPE} from 'shared/enums';

const MIN_ENABLED_OPTIONS = 2;

class EditInstructionDetailsPage {
  @observable guide;
  @observable currentInstruction;
  @observable editMode = false;
  @observable instructionCoverUrl = undefined;
  @observable guideLoading = true;
  @observable metadata = undefined;
  @observable selectedTagsIds = [];
  @observable newTags = [];
  @observable newStepDisabledOptionIds = {};
  @observable translations = {};
  @observable stepNumber = null;
  refetchInstructionQuery = null;
  refetchGuideQuery = undefined;
  updateMediaMutation = undefined;
  form = EditInstructionForm;
  toggleCheckOptionMutation = undefined;
  @observable localSelectedTags = [];

  @action setLocalSelectedTags = tags => {
    this.localSelectedTags = tags;
  };

  @computed
  get checklistTemplateOptionsCount() {
    return this.currentInstruction?.checklistTemplate?.options.length || 0;
  }

  @action
  clearNewStepDisabledOptions = () => {
    this.newStepDisabledOptionIds = {};
  };

  toggleNewStepOption = optionId => {
    const currentlyEnabled = !this.newStepDisabledOptionIds[optionId];
    if (currentlyEnabled) {
      this.newStepDisabledOptionIds[optionId] = true;
      this.checkStepDisabledOptionsLimitReached(Object.keys(this.newStepDisabledOptionIds).length);
    } else {
      delete this.newStepDisabledOptionIds[optionId];
    }
  };

  toggleExistingStepOption = async ({optionId, stepId}) => {
    const step = this.currentInstruction.steps.find(step => step.id === stepId);
    const shouldEnable = !!step.disabledOptions.find(option => optionId === option.id);
    try {
      const result = await client.mutate({
        mutation: ToggleChecklistOption,
        variables: {optionId, stepId, enabled: shouldEnable},
        refetchQueries: [refreshInstruction(this.currentInstruction.id)]
      });
      notification.success(shouldEnable ? this.translations.shownResponse : this.translations.hiddenResponse);
      if (!shouldEnable) {
        // The +1 accounts for the option that is being toggled, since it's not optimistically updated.
        this.checkStepDisabledOptionsLimitReached(step.disabledOptions.length + 1);
      }
      return result;
    } catch (error) {
      Raven.captureException(error);
      console.error(error);
      notification.error(this.translations.somethingWentWrong);
    }
  };

  @computed
  get newStepOptions() {
    return this.currentInstruction.checklistTemplate?.options.map(option => ({
      ...option,
      disabled: !!this.newStepDisabledOptionIds[option.id]
    }));
  }

  existingStepOptions = stepId => {
    const step = this.currentInstruction.steps.find(step => step.id === stepId);
    return this.currentInstruction.checklistTemplate?.options.map(option => ({
      ...option,
      id: option.id,
      disabled: !!step.disabledOptions.find(({id}) => id === option.id)
    }));
  };

  checkStepDisabledOptionsLimitReached = disabledOptionsCount => {
    if (!this.canDisableMoreOptions(disabledOptionsCount)) {
      notification.success(this.getNoMoreOptionsToDisableMessage());
    }
  };

  canDisableMoreOptions = disabledOptionsCount => {
    return this.checklistTemplateOptionsCount - disabledOptionsCount > MIN_ENABLED_OPTIONS;
  };

  getNoMoreOptionsToDisableMessage = () => {
    const maximumDisabledOptions = this.checklistTemplateOptionsCount - MIN_ENABLED_OPTIONS;
    switch (maximumDisabledOptions) {
      case 3:
        return this.translations.hideThreeResponses;
      case 2:
        return this.translations.hideTwoResponses;
      case 1:
        return this.translations.hideOneResponse;
      default:
        return 'Cannot disable more options';
    }
  };

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  setGuide = guide => {
    this.guide = guide;
  };

  @action
  setGuideLoading = guideLoading => {
    this.guideLoading = guideLoading;
  };

  @action
  reset = () => {
    this.guide = undefined;
    this.currentInstruction = undefined;
    this.editMode = false;
    this.instructionCoverUrl = undefined;
    this.guideLoading = true;
    this.metadata = undefined;
    this.updateMediaMutation = undefined;
    this.form.reset();
    this.newTags = [];
    this.clearNewStepDisabledOptions();
  };

  exitView = () => {
    this.refetchInstructionQuery = null;
    this.refetchGuideQuery = null;
  };

  @action
  setInstruction = instruction => {
    this.currentInstruction = instruction;
    let mediaId = undefined;
    if (instruction.media) {
      this.instructionCoverUrl = instruction.media.url;
      mediaId = instruction.media.id;
    } else {
      this.instructionCoverUrl = undefined;
      this.metadata = undefined;
    }
    if (instruction.media && instruction.media.metadata) {
      this.metadata = instruction.media.metadata;
    } else {
      this.metadata = undefined;
    }
    if (instruction.tags) {
      this.selectedTagsIds = instruction.tags.map(t => t.id);
    }
    const {title, slug} = instruction;
    this.form.update({title, slug, mediaId});
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get title() {
    if (this.currentInstruction) {
      const title = this.currentInstruction.title;
      if (title.length > 120) {
        return title.slice(0, 120) + '...';
      } else {
        return title;
      }
    }

    return null;
  }

  @computed
  get isChecklist() {
    return this.currentInstruction && this.currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;
  }

  @action
  submit = (updateInstructionMutation, translations) => {
    const instruction = this.form.values();
    instruction.mediaId = instruction.mediaId || null;
    instruction.tags = this.selectedTagsIds.filter(t => !t.includes('new'));
    instruction.newTags = this.newTagsForSubmit;
    updateInstructionMutation({
      id: this.currentInstruction.id,
      instruction
    })
      .then(() => {
        this.setEditMode(false);
        notification.success(translations.successMessage);
      })
      .catch(() => {
        notification.success(translations.errorMessage);
      });
  };

  @action
  updateMedia = media => {
    const {id, locale} = this.currentInstruction;
    const mediaId = media ? media.id : null;
    return this.updateMediaMutation({
      id,
      locale,
      mediaId
    });
  };

  @action
  cancel = () => {
    this.setEditMode(false);
    this.selectedTagsIds = this.currentInstruction.tags ? this.currentInstruction.tags.map(t => t.id) : [];
  };

  @action
  setEditMode = val => (this.editMode = val);

  @action
  refetchInstruction = () => {
    const {router} = store;
    const {instructionId} = router.params;
    this.refetchInstructionQuery({id: instructionId});
  };

  setRefetchInstruction = query => (this.refetchInstructionQuery = query);

  setRefetchGuide = query => (this.refetchGuideQuery = query);

  @action
  setSelectedTags = (tags, newTag) => {
    this.selectedTagsIds = tags;
    if (newTag) {
      newTag.instructions = [this.currentInstruction.id];
      this.newTags.push(newTag);
    }
  };

  @computed
  get saveButtonEnabled() {
    return !this.loading && this.form.isValid;
  }

  @computed
  get loading() {
    return !this.currentInstruction;
  }

  @computed
  get versions() {
    return get(this.guide, 'versions', null);
  }

  @computed
  get currentVersionId() {
    return get(this.guide, 'versionId', null);
  }

  @computed
  get tagsNames() {
    if (!this.currentInstruction || !this.currentInstruction.tags.length) return null;
    const tagsOnly = this.currentInstruction.tags.filter(tag => !tag.badge);
    const tagsNames = tagsOnly.map(tag => tag.title).join(', ');
    return truncate(tagsNames, {length: 30, separator: ' '});
  }

  @computed
  get badgesNames() {
    if (!this.currentInstruction || !this.currentInstruction.tags.length) return null;
    const badgesOnly = this.currentInstruction.tags.filter(tag => tag.badge);
    const badgesNames = badgesOnly.map(tag => tag.title).join(', ');
    return truncate(badgesNames, {length: 30, separator: ' '});
  }

  @computed
  get newTagsForSubmit() {
    return this.newTags.filter(tag => this.selectedTagsIds.includes(tag.id)).map(tag => omit(tag, 'id'));
  }

  setUpdateMediaMutation = mutation => (this.updateMediaMutation = mutation);
}

export default EditInstructionDetailsPage;
