import React, {Component} from 'react';
import PropTypes from 'prop-types';

//lodash
import invoke from 'lodash/invoke';
import omit from 'lodash/omit';

//styled-components
import {DragHandle, StyledDragHandleIcon} from './styles';

class DragHandleComponent extends Component {
  static defaultProps = {
    isDisabled: false
  };

  static propTypes = {
    forwardedRef: PropTypes.func,
    isDisabled: PropTypes.bool
  };

  handleMouseDown = event => {
    event.preventDefault();
    invoke(this.props, 'onMouseDown', event);
  };

  render() {
    const {forwardedRef} = this.props;

    const forwardedProps = omit(this.props, 'children', 'dangerouslySetInnerHTML', 'forwardedRef');

    return (
      <DragHandle {...forwardedProps} ref={forwardedRef} onMouseDown={this.handleMouseDown}>
        <StyledDragHandleIcon />
      </DragHandle>
    );
  }
}

export default DragHandleComponent;
