import {trackMutation} from './mutation-handler';
import store from 'stores/store';
import {UAParser} from 'ua-parser-js';
const parser = new UAParser();

/**
 * Track an event with additional metadata.
 *
 * @param {string} eventType - The type of the event from EVENT_TYPES.
 * @param {Object} [data={}] - Additional event metadata.
 */
function trackEvent(eventType, data = {}) {
  const {
    platform: {subdomain},
    analytics: {sessionId},
    auth: {user}
  } = store;

  const parserResults = parser.getResult();

  // Construct the event data
  const eventData = {
    platform: subdomain,
    sessionId: sessionId,
    ua: parserResults.ua,
    uaParsed: parserResults,
    userId: user?.id,
    ...data
  };

  // Convert the event data to a JSON string
  const metadata = JSON.stringify(eventData);

  // Send the mutation
  trackMutation(eventType, metadata);
}

export {trackEvent};
