import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  editNSteps: {
    id: 'EditInstruction.editNSteps',
    defaultMessage: 'Edit {count, number} {count, plural, one {step} other {steps}}'
  },
  editNChecks: {
    id: 'EditInstruction.editNChecks',
    defaultMessage: 'Edit {count, number} {count, plural, one {check} other {checks}}'
  },
  yourInstruction: {
    id: 'EditInstruction.yourInstruction',
    defaultMessage: 'Your instruction'
  },
  yourChecklist: {
    id: 'yourChecklist',
    defaultMessage: 'Your Checklist'
  },
  editSteps: {
    id: 'EditInstruction.editSteps',
    defaultMessage: 'Edit steps'
  },
  editChecks: {
    id: 'editChecks',
    defaultMessage: 'Edit checks'
  },
  delete: {
    id: 'common.actions.delete',
    defaultMessage: 'Delete'
  },
  duplicate: {
    id: 'common.actions.duplicate',
    defaultMessage: 'Duplicate'
  },
  hide: {
    id: 'common.actions.hide',
    defaultMessage: 'Hide'
  },
  setAlertRecipients: {
    id: 'setAlertRecipients',
    defaultMessage: 'Set Alert recipients'
  },
  show: {
    id: 'common.actions.show',
    defaultMessage: 'Show'
  },
  share: {
    id: 'common.actions.share',
    defaultMessage: 'Share'
  },
  shareInstruction: {
    id: 'EditInstruction.shareInstruction',
    defaultMessage: 'Share instruction'
  },
  shareChecklist: {
    id: 'shareChecklist',
    defaultMessage: 'Share checklist'
  },
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  removeInstruction: {
    id: 'EditInstruction.removeInstruction',
    defaultMessage: 'Remove instruction'
  },
  removeChecklist: {
    id: 'removeChecklist',
    defaultMessage: 'Remove checklist'
  },
  confirmationInstruction: {
    id: 'EditInstruction.confirmation',
    defaultMessage: 'Are you sure you want to remove your instruction ‘{title}’?'
  },
  confirmationChecklist: {
    id: 'confirmationChecklist',
    defaultMessage: 'Are you sure you want to remove your checklist ‘{title}’?'
  },
  archiveInstructionSuccess: {
    id: 'EditInstruction.archiveSuccess',
    defaultMessage: 'Instruction has been removed.'
  },
  archiveChecklistSuccess: {
    id: 'archiveChecklistSuccess',
    defaultMessage: 'Checklist has been removed.'
  },
  archiveInstructionFailure: {
    id: 'EditInstruction.archiveFailure',
    defaultMessage: 'Error removing instruction.'
  },
  archiveChecklistFailure: {
    id: 'archiveChecklistFailure',
    defaultMessage: 'Error removing checklist.'
  },
  showInstructionSuccess: {
    id: 'EditInstruction.showSuccess',
    defaultMessage: 'Instruction will be shown.'
  },
  showChecklistSuccess: {
    id: 'showChecklistSuccess',
    defaultMessage: 'Checklist will be shown.'
  },
  showInstructionFailure: {
    id: 'EditInstruction.showFailure',
    defaultMessage: 'Error setting your instruction to be shown.'
  },
  showChecklistFailure: {
    id: 'showChecklistFailure',
    defaultMessage: 'Error setting your checklist to be shown.'
  },
  hideInstructionSuccess: {
    id: 'EditInstruction.hideSuccess',
    defaultMessage: 'Instruction will be hidden.'
  },
  hideChecklistSuccess: {
    id: 'hideChecklistSuccess',
    defaultMessage: 'Checklist will be hidden.'
  },
  hideInstructionFailure: {
    id: 'EditInstruction.hideFailure',
    defaultMessage: 'Error setting your instruction to be hidden.'
  },
  hideChecklistFailure: {
    id: 'hideChecklistFailure',
    defaultMessage: 'Error setting your checklist to be hidden.'
  },
  cloneInstructionSuccess: {
    id: 'EditInstruction.cloneSuccess',
    defaultMessage: 'Instruction has been duplicated.'
  },
  cloneChecklistSuccess: {
    id: 'cloneChecklistSuccess',
    defaultMessage: 'Checklist has been duplicated.'
  },
  cloneInstructionError: {
    id: 'EditInstruction.cloneError',
    defaultMessage: 'Error duplicating instruction.'
  },
  cloneChecklistError: {
    id: 'cloneChecklistError',
    defaultMessage: 'Error duplicating checklist.'
  },
  moveTo: {
    id: 'common.actions.moveTo',
    defaultMessage: 'Move to...'
  },
  moveInstructionTitle: {
    id: 'EditInstruction.moveInstructionTitle',
    defaultMessage: 'Move this instruction to...'
  },
  moveChecklistTitle: {
    id: 'moveChecklistTitle',
    defaultMessage: 'Move this checklist to...'
  },
  moveInstructionButton: {
    id: 'EditInstruction.moveInstructionButton',
    defaultMessage: 'Yes, move this instruction'
  },
  moveChecklistButton: {
    id: 'moveChecklistButton',
    defaultMessage: 'Yes, move this checklist'
  },
  moveInstructionSuccess: {
    id: 'EditInstruction.moveInstructionSuccess',
    defaultMessage: 'Instruction moved successfully to '
  },
  moveChecklistSuccess: {
    id: 'moveChecklistSuccesss',
    defaultMessage: 'Checklist moved successfully to '
  },
  moveInstructionFailure: {
    id: 'EditInstruction.moveInstructionFailure',
    defaultMessage: 'Failed to move instruction'
  },
  moveChecklistFailure: {
    id: 'moveChecklistFailure',
    defaultMessage: 'Failed to move checklist'
  },
  applySignOff: {
    id: 'common.actions.applySignOff',
    defaultMessage: 'Apply sign-off'
  },
  defaultLanguagesMustMatch: {
    id: 'defaultLanguagesMustMatch',
    defaultMessage: 'Unable to move content. Make sure default languages are the same everywhere.'
  },
  requireSignature: {
    id: 'requireSignature',
    defaultMessage: 'Require Signature'
  },
  removeSignature: {
    id: 'removeSignature',
    defaultMessage: 'Remove Signature'
  },
  somethingWentWrong: {
    id: 'pdfErrorHeader',
    defaultMessage: 'Something went wrong'
  },
  signatureRequired: {
    id: 'signatureRequired',
    defaultMessage: 'Signature required'
  },
  signatureRemoved: {
    id: 'signatureRemoved',
    defaultMessage: 'Signature removed'
  },
  selectChecklistTemplate: {
    id: 'selectChecklistTemplate',
    defaultMessage: 'Select a template'
  },
  scoreBased: {
    id: 'scoreBased',
    defaultMessage: '(Score based)'
  },
  percentageBased: {
    id: 'percentageBased',
    defaultMessage: '(Percentage based)'
  },
  default: {
    id: 'default',
    defaultMessage: 'Default'
  },
  custom: {
    id: 'custom',
    defaultMessage: 'Custom'
  }
});

export default messages;
