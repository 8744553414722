import styled from 'styled-components';

//helpers
import {commonPalette} from 'shared/styles/palette';
import {boxShadow} from 'styles/shadows';
import typefaces from 'shared/styles/typefaces';
import {truncate} from 'shared/styles/partials';

//components
import $DragHandle from 'components/DragHandle';

//constants
const dragHandleWidth = 45;

export const TopicItem = styled.div`
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 1px;

  ${p =>
    p.design === 'ghostly' &&
    `
    background: ${commonPalette.ghostlyGreen};
    filter: blur(4px);
  `}

  ${p =>
    p.design === 'opaque' &&
    `
    background: ${commonPalette.darkestGray};
    border-color: transparent;
    box-shadow: ${boxShadow.draggable};
  `}

  & + & {
    margin-top: -1px;
  }
`;

export const InnerWrapper = styled.div`
  position: relative;

  ${p =>
    p.isInvisible &&
    `
    // NB. In Microsoft Edge, opacity won't be applied
    // to its absolute-positioned children unless the
    // element is positioned itself

    opacity: 0;
  `}
`;

export const Header = styled.div`
  border-bottom: 1px solid #e6e6e6;
  height: 50px;
  padding: 17px 20px 17px ${dragHandleWidth}px;
  position: relative;
`;

export const StyledDragHandle = styled($DragHandle)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${dragHandleWidth}px;
`;

export const Title = styled.div`
  ${truncate};
  ${typefaces.sidebar.subtitle};
  text-transform: uppercase;
`;

export const Summary = styled.div`
  ${typefaces.sidebar.subtitle};
  background: ${commonPalette.darkestGray};
  height: 51px;
  margin-top: -1px;
  padding: 18px 20px 17px ${dragHandleWidth}px;
`;
