import styled from 'styled-components';

export const Label = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #939798;
  margin-right: 10px;
  cursor: default;

  ${p =>
    p.colorLabel
      ? `
  @media (max-width: 1200px) {
    display: none;
  }`
      : `
  @media (max-width: 1420px) {
    display: none;
  }`}
`;

export const Toolbar = styled.div`
  background: #fafafa;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
`;

export const ToolWrapper = styled.div`
  padding: 10px 16px;
  align-items: center;
  display: flex;

  @media (max-width: 1200px) {
    padding: 0px;
  }

  .ant-select-arrow::before {
    content: '\f0d7' !important;
    color: #333330 !important;
  }
`;

export const ToolBarSeparator = styled.div`
  height: 100%;
  border-width: 0.5px;
  border-style: solid;
  border-color: #e6e6e6;
  padding: 30px 0;

  @media (max-width: 1200px) {
    padding: 20px 0;
  }
`;

export const Color = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${p => p.color};
  border: 1px solid #000;
  margin: 0 10px;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 30;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const ToolbarButton = styled.div`
  background: none;
  border: none;
  padding: 10px;
  border-radius: 3px;

  ${p =>
    p.selected
      ? 'background: #c9cbcb;'
      : `
    &:hover {
      background: #ededed;
    }
  `}
`;
