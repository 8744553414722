import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {map, mapValues, without, xor, filter, pick} from 'lodash';

import {UpdateAdminLoginOptions} from 'api/platform/mutations';
import {PlatformFlags} from 'api/platform/queries';
import {updateAdminLoginSettingsOptions} from 'api/platform/mutation-options';
import {CMS_LOGIN_OPTIONS} from 'config/enums';

import Checkbox from 'components/Checkbox';

import messages from './messages';
import {SectionWrapper, Header, LoginOptionWrapper} from './styles';

const LoginOptionIdTranslationPrefixMap = {
  [CMS_LOGIN_OPTIONS.EMAIL]: 'email'
};

@inject('store')
@graphql(UpdateAdminLoginOptions, updateAdminLoginSettingsOptions)
@graphql(PlatformFlags)
@observer
class AdminLoginOptionsComponent extends Component {
  getTranslatedNotificationsPerLoginOption = loginOptionId => {
    const {
      intl: {formatMessage}
    } = this.props;

    const prefix = LoginOptionIdTranslationPrefixMap[loginOptionId];

    return mapValues(
      pick(
        messages,
        map(
          ['OptionTurnedOnSuccess', 'OptionTurnedOffSuccess', 'OptionTurnedOnError', 'OptionTurnedOffError'],
          key => `${prefix}${key}`
        )
      ),
      message => formatMessage(message)
    );
  };

  onUpdateLoginOption = (optionsConfig, loginOptionId, isChecked) => {
    const {
      updateAdminLoginOptions: updateAdminLoginOptionsMutation,
      store: {
        platformSettingsPage: {onUpdateAdminLoginOptions}
      }
    } = this.props;

    const currentCheckedLoginOptionIds = map(filter(optionsConfig, 'isChecked'), 'id');

    /* 
      We intentionally exclude SSO option, cause by design, this is just a placeholder
      option and the CMS does not provide a way to toggle it.
    */

    const updatedCheckedLoginOptionIds = without(
      xor(currentCheckedLoginOptionIds, [loginOptionId]),
      CMS_LOGIN_OPTIONS.SSO
    );

    const translatedNotifications = this.getTranslatedNotificationsPerLoginOption(loginOptionId);

    onUpdateAdminLoginOptions(
      updateAdminLoginOptionsMutation,
      updatedCheckedLoginOptionIds,
      isChecked,
      translatedNotifications,
      LoginOptionIdTranslationPrefixMap[loginOptionId]
    );
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        platformSettingsPage: {isAdminLoginOptionUpdateLoading}
      },
      data: {platformForCms}
    } = this.props;

    const {signInOptions, emailAndPassword, companyAccount} = messages;

    if (!platformForCms) {
      return null;
    }

    const {enforceAdminSSO} = platformForCms;

    const LoginOptionsConfig = [
      {
        id: CMS_LOGIN_OPTIONS.SSO,
        caption: formatMessage(companyAccount),
        isChecked: true,
        isDisabled: true,
        dataTestId: 'password-login-option-sso'
      },
      {
        id: CMS_LOGIN_OPTIONS.EMAIL,
        caption: formatMessage(emailAndPassword),
        isChecked: !enforceAdminSSO,
        isDisabled: isAdminLoginOptionUpdateLoading,
        dataTestId: 'platform-login-option-email'
      }
    ];

    return (
      <SectionWrapper>
        <Header>{formatMessage(signInOptions)}</Header>
        {LoginOptionsConfig.map(loginOption => (
          <LoginOptionWrapper key={loginOption.id}>
            <Checkbox
              dataTestId={loginOption.dataTestId}
              caption={loginOption.caption}
              checked={loginOption.isChecked}
              onChange={() => this.onUpdateLoginOption(LoginOptionsConfig, loginOption.id, !loginOption.isChecked)}
              disabled={loginOption.isDisabled}
            />
          </LoginOptionWrapper>
        ))}
      </SectionWrapper>
    );
  }
}

export default injectIntl(AdminLoginOptionsComponent);
