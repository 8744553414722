import {defineMessages} from 'react-intl';

const messages = defineMessages({
  reminderCycle: {
    id: 'reminderCycle',
    defaultMessage: 'Set reminder cycle for guide review'
  },
  guideApprovalDisabledInfo: {
    id: 'guideApprovalDisabledInfo',
    defaultMessage: 'Guide approval needs to be enabled for this team if you want to set reminders.'
  },
  remindersInfo: {
    id: 'remindersInfo',
    defaultMessage:
      'Set an expiration date for your team to review the guide and confirm the content is still up to date.'
  },
  reminders: {
    id: 'reminders',
    defaultMessage: 'Reminders'
  }
});

export default messages;
