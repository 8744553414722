import {computed, makeObservable} from 'mobx';
import store from 'stores/store';

class PermissionsStore {
  constructor() {
    makeObservable(this);
  }

  @computed get isAuthenticated() {
    const {
      auth: {user}
    } = store;
    return user !== undefined && user !== null;
  }

  @computed get isUserPlatformAdmin() {
    const {
      auth: {user}
    } = store;
    return user && user.isPlatformAdmin;
  }

  @computed get isUserAdmin() {
    const {
      auth: {user}
    } = store;
    return user && user.isAdmin;
  }

  @computed get isUserEditor() {
    const {
      auth: {user}
    } = store;
    return user && user.isEditor;
  }

  @computed get showReassignGuideDialog() {
    return this.isAuthenticated;
  }

  @computed get showNewTheme() {
    return this.isUserPlatformAdmin || this.isUserAdmin;
  }
}

export default PermissionsStore;
