import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {getPasswordComplexityChecker} from 'utils/validation-utils';

const getPlugins = translations => {
  const checkPasswordComplexity = getPasswordComplexityChecker(translations);
  return dvrWithAsync({
    checkPasswordComplexity: (value, _attr, _key, passes) => checkPasswordComplexity(value, passes)
  });
};

const fields = [
  {
    name: 'oldPassword',
    rules: 'required|string',
    label: 'old password'
  },
  {
    name: 'newPassword',
    rules: 'required|string|checkPasswordComplexity',
    label: 'new password'
  },
  {
    name: 'newPasswordConfirm',
    rules: 'required|string',
    label: 'new password confirmation'
  }
];

export const createLocalisedForm = translations => new MobxReactForm({fields}, {plugins: getPlugins(translations)});

export default new MobxReactForm({fields}, {plugins: getPlugins()});
