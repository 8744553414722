import React from 'react';
import {observable, action, computed, makeObservable} from 'mobx';
import store from 'stores/store';
import views from 'config/views';
import {omit, get, pick} from 'lodash';

//helpers
import notification from 'utils/notification-utils';
import {sanitizeSimpleObject} from 'utils/object-utils';

//components
import ThemeNameAndLogo from 'components/ThemeNameAndLogo';
import ThemeColors from 'components/ThemeColors';
import ThemeInstructionalIcons from 'components/ThemeInstructionalIcons';
import ThemeFontSettings from 'components/ThemeFontSettings';

//models
import ThemeNameAndLogoForm from 'stores/forms/theme-name-and-logo-form';
import ThemeColorsForm from 'stores/forms/theme-colors-form';
import ThemeFontSettingsForm from 'stores/forms/theme-font-settings-form';
import ThemeInstructionalIconsForm from 'stores/forms/theme-instructional-icons-form';
import Steps from 'stores/models/steps';

class ThemePage {
  translations = {};
  @observable theme;
  @observable stepsStore;
  @observable logoMediaUrl;
  @observable faviconMediaUrl;
  @observable icons = [];
  @observable platformIcons = [];
  @observable teams = [];

  fonts = [
    'Lato',
    'Arial',
    'Courier',
    'Georgia',
    'Helvetica',
    'Lucida',
    'Open Sans',
    'Roboto',
    'Tahoma',
    'Times New Roman',
    'Trebuchet',
    'Verdana'
  ];

  //forms
  @observable nameAndLogoForm = ThemeNameAndLogoForm();
  colorsForm = ThemeColorsForm;
  instructionalIconsForm = ThemeInstructionalIconsForm;
  fontSettingsForm = ThemeFontSettingsForm;

  @action
  setNameAndLogoForm = isPlatformAdmin => {
    this.nameAndLogoForm = ThemeNameAndLogoForm(isPlatformAdmin);
  };

  @action
  setSteps = editMode => {
    this.stepsStore = new Steps({
      steps: [
        {
          key: 'nameAndLogo',
          path: 'name-and-logo',
          component: <ThemeNameAndLogo />,
          form: this.nameAndLogoForm,
          checked: false
        },
        {
          key: 'colors',
          path: 'colors',
          component: <ThemeColors />,
          form: this.colorsForm,
          checked: false
        },
        {
          key: 'stepNoteIcons',
          path: 'step-note-icons',
          component: <ThemeInstructionalIcons />,
          form: this.instructionalIconsForm,
          checked: false
        },
        {
          key: 'fontSettings',
          path: 'font-settings',
          component: <ThemeFontSettings />,
          form: this.fontSettingsForm,
          checked: false
        }
      ],
      editMode
    });
  };

  @action setLogoMediaUrl = url => (this.logoMediaUrl = url);
  @action setFaviconMediaUrl = url => (this.faviconMediaUrl = url);

  @action
  reset = () => {
    this.nameAndLogoForm.reset();
    this.colorsForm.reset();
    this.fontSettingsForm.reset();
    this.instructionalIconsForm.reset();
    this.stepsStore.reset();
    this.logoMediaUrl = undefined;
    this.faviconMediaUrl = undefined;
    this.theme = undefined;
  };

  @action
  setTeams = teams => {
    this.teams = teams;
    if (this.teams.length === 1) {
      const teamId = this.teams[0].id;
      this.nameAndLogoForm.update({teamId});
    }
  };

  @action
  setPlatformIcons = (icons = []) => {
    if (!icons) {
      return;
    }
    this.platformIcons = icons;
  };

  @action
  setSelectedTheme = theme => {
    if (this.theme) {
      return;
    }

    const {
      name,
      teamId,
      logoMedia,
      faviconMedia,
      mainColor,
      buttonColor,
      fontFamily,
      customFontRegularUrl,
      customFontBoldUrl,
      customFontItalicUrl,
      customFontBoldItalicUrl,
      customFontFallback
    } = theme;
    const icons = theme.icons || [];
    const logoMediaId = get(logoMedia, 'id', null);
    const faviconMediaId = get(faviconMedia, 'id', null);

    this.nameAndLogoForm.update({name, teamId, logoMediaId, faviconMediaId});
    this.colorsForm.update({mainColor, buttonColor});
    this.instructionalIconsForm.update({icons});

    this.fontSettingsForm.update({
      fontFamily,
      customFontRegularUrl,
      customFontBoldUrl,
      customFontItalicUrl,
      customFontBoldItalicUrl,
      customFontFallback
    });

    this.logoMediaUrl = get(logoMedia, 'url', null);
    this.faviconMediaUrl = get(faviconMedia, 'url', null);

    this.theme = theme;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get fontsForSelect() {
    const presets = this.fonts.map(font => ({
      name: font === 'Lato' ? `Lato ${this.translations.default}` : font,
      id: font
    }));

    if (store.platform.canUseCustomFonts) {
      presets.push({
        name: 'Custom',
        id: 'Custom'
      });
    }

    return presets;
  }

  @computed
  get showSaveButton() {
    return (
      this.stepsStore.currentStepIndex === this.stepsStore.steps.length - 1 ||
      store.router.currentRoute.path === views.editTheme.path
    );
  }

  @computed get showCancelButton() {
    return this.stepsStore.currentStepIndex === 0 || store.router.currentRoute.path === views.editTheme.path;
  }

  @computed
  get isValid() {
    return (
      this.nameAndLogoForm.isValid &&
      this.colorsForm.isValid &&
      this.instructionalIconsForm.isValid &&
      this.fontSettingsForm.isValid
    );
  }

  setTranslations(translations) {
    this.translations = translations;
  }

  createTheme = createThemeMutation => {
    let formValues = {
      ...this.nameAndLogoForm.values(),
      ...this.colorsForm.values(),
      ...this.fontSettingsForm.values(),
      ...this.instructionalIconsForm.values()
    };

    if (formValues.logoMediaId === '') {
      formValues = omit(formValues, 'logoMediaId');
    }
    if (formValues.faviconMediaId === '') {
      formValues = omit(formValues, 'faviconMediaId');
    }

    ['logoMediaId', 'faviconMediaId', 'teamId'].forEach(uuidField => {
      if (!formValues[uuidField]) {
        delete formValues[uuidField];
      }
    });

    formValues.icons = formValues.icons
      ? formValues.icons.filter(icon => icon.id && icon.svg).map(icon => pick(icon, ['id', 'svg']))
      : [];

    const newTheme = sanitizeSimpleObject(formValues, ['name']);

    createThemeMutation({newTheme})
      .then(() => {
        notification.success(this.translations.actionSuccess);
      })
      .catch(() => {
        notification.error(this.translations.actionFailure);
      });
  };

  editTheme = (editThemeMutation, id) => {
    const formValues = {
      ...this.nameAndLogoForm.values(),
      ...this.colorsForm.values(),
      ...this.fontSettingsForm.values(),
      ...this.instructionalIconsForm.values()
    };

    formValues.logoMediaId = formValues.logoMediaId || null;
    formValues.faviconMediaId = formValues.faviconMediaId || null;
    formValues.teamId = formValues.teamId || null;
    formValues.icons = formValues.icons
      ? formValues.icons.filter(icon => icon.id && icon.svg).map(icon => pick(icon, ['id', 'svg']))
      : [];

    const editTheme = sanitizeSimpleObject(formValues, ['name']);

    editThemeMutation({
      id: id,
      editTheme
    })
      .then(() => {
        notification.success(this.translations.actionSuccess);
      })
      .catch(() => {
        notification.success(this.translations.actionFailure);
      });
  };
}

export default ThemePage;
