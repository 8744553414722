import React, {useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer, Observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import flowRight from 'lodash/flowRight';

//data
import {UpdateWebhookUrl} from 'api/platform/mutations';
import {updateWebhookUrlOptions} from 'api/platform/mutation-options';

//messages
import messages from './messages';

//components
import WebhookUrlSelector from './WebhookUrlSelector';

const WebhookUrlSetting = ({intl, store, ...props}) => {
  const {updateWebhookUrlMutation} = props;
  const {formatMessage} = intl;
  const {platformSettingsPage, platform} = store;

  useEffect(() => {
    platformSettingsPage.setTranslations({
      webhookUrlUpdateSuccess: formatMessage(messages.webhookUrlUpdateSuccess),
      webhookUrlUpdateFailure: formatMessage(messages.webhookUrlUpdateFailure)
    });
  });
  const translatedMessages = {
    descriptions: {
      introText: formatMessage(messages.introText)
    },
    webhookUrlFieldLabel: formatMessage(messages.webhookUrlFieldLabel),
    submitButtonLabel: formatMessage(messages.submitButtonLabel)
  };

  const onWebhookUrlSubmit = ({url}) => {
    platformSettingsPage.updateWebhookUrl(updateWebhookUrlMutation, url);
  };

  return (
    <Observer>
      {() => (
        <WebhookUrlSelector
          currentWebhookUrl={platform.webhookUrl}
          messages={translatedMessages}
          onSubmit={url => {
            onWebhookUrlSubmit({url});
          }}
        />
      )}
    </Observer>
  );
};

const decorate = flowRight([inject('store'), graphql(UpdateWebhookUrl, updateWebhookUrlOptions), observer, injectIntl]);

export default decorate(WebhookUrlSetting);
