import styled from 'styled-components';

import Icon from 'ui-components/Layout/Icon';

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const CloseButton = styled(Icon).attrs({
  name: 'close'
})`
  height: 16px;
  margin-top: 5px;
  height: 12px;
`;
