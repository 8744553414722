import {defineMessages} from 'react-intl';

const messages = defineMessages({
  users: {
    id: 'Menu.users',
    defaultMessage: 'Users'
  },
  workspaces: {
    id: 'EditCampaign.workspaces',
    defaultMessage: 'Workspaces'
  },
  backToTeams: {
    id: 'backToTeams',
    defaultMessage: 'Back to Teams'
  },
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications'
  },
  guideApproval: {
    id: 'guideApproval',
    defaultMessage: 'Guide approval'
  }
});

export default messages;
