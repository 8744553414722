import {action, observable, makeObservable} from 'mobx';
import Raven from 'raven-js';

export default class ExportToPdfDialog {
  @observable opened = false;
  @observable params = null;
  @observable error = null;
  @observable loadingPdf = false;
  @observable availableLanguages = null;
  @observable selectedLanguage = null;
  @observable hideLanguageSelector = false;
  @observable loadingAvailableLanguages = false;

  @action
  open = ({params}) => {
    this.params = params;
    this.opened = true;
  };

  @action
  reset = () => {
    this.opened = false;
    this.params = null;
    this.error = null;
    this.loadingAvailableLanguages = false;
  };

  @action
  setError = error => {
    if (typeof error !== 'boolean') {
      Raven.captureException(error);
    }

    this.error = error;
  };

  @action
  setLoadingAvailableLanguages = loadingAvailableLanguages =>
    (this.loadingAvailableLanguages = loadingAvailableLanguages);

  @action
  setLoadingPdf = loadingPdf => (this.loadingPdf = loadingPdf);

  @action
  setAvailableLanguages = availableLanguages => (this.availableLanguages = availableLanguages);

  @action
  setSelectedLanguage = selectedLanguage => (this.selectedLanguage = selectedLanguage);

  @action
  setHideLanguageSelector = hideLanguageSelector => (this.hideLanguageSelector = hideLanguageSelector);

  setStore = store => (this.store = store);

  constructor() {
    makeObservable(this);
  }
}
