import Raven from 'raven-js';

export async function handleAsyncError(asyncFunc) {
  try {
    await asyncFunc();
  } catch (error) {
    // Captures the exception and sends it to Sentry
    Raven.captureException(error);
  }
}
