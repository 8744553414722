import React, {useCallback} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import views from 'config/views';
import DataTable from 'ui-components/Layout/DataTable';
import {CellWrapper, HeaderWrapper} from 'ui-components/Layout/DataTable/styles';
import SmartSkillsSausageBar from 'components/SmartSkillsSausageBar';
import SmartSkillsLegendItem from 'components/SmartSkillsLegendItem';
import {PaginatedSkillMatrix} from 'api/skill-matrix/queries';
import {sumBy} from 'lodash';
import {SausagesContainer, LoaderContainer} from 'views/SmartSkillsTeams/styles';
import messages from './messages';
import {useLazyQuery} from '@apollo/client';
import Pagination from 'ui-components/Pagination';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import {CountCellComponentProps, OverviewCellComponentProps, MatrixDataTeam} from './types';
import {usePaginationParameters} from './utils-hook';
import {maxNumberOfRowsPerPage} from 'ui-components/Layout/DataTable/constants';
const pageSize = maxNumberOfRowsPerPage;
const OverviewCellComponent = (props: OverviewCellComponentProps) => {
  const {value} = props;

  const totalSum = Object.values(value).reduce((sum, {total}) => sum + total, 0);

  return (
    <CellWrapper paddingLeft data-cy={value}>
      <SausagesContainer>
        {Object.entries(value).map(([key, {total, color}]) => {
          const percentage = ((total / totalSum) * 100).toFixed();
          return <SmartSkillsSausageBar key={key} color={color} value={percentage} percentage={percentage} />;
        })}
      </SausagesContainer>
    </CellWrapper>
  );
};

const CountCellComponent = (props: CountCellComponentProps) => {
  const {value} = props;
  return (
    <CellWrapper paddingLeft data-cy={value}>
      <span title={value?.toString() || ''}>{value}</span>
    </CellWrapper>
  );
};

const GapOverviewTable = props => {
  const {
    data = [],
    sorted,
    setSorted,
    store,
    intl: {formatMessage}
  } = props;

  const onClickTeam = id => {
    store.router.goTo(views.smartSkillsTeam, {teamId: id});
  };

  const columns = [
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.teamName)}</HeaderWrapper>,
      accessor: 'name',
      Cell: ({value}) => (
        <CellWrapper paddingLeft data-cy={value.split(' ').join('-')}>
          <span title={value}>{value}</span>
        </CellWrapper>
      ),
      width: 250
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.members)}</HeaderWrapper>,
      accessor: 'members',
      Cell: ({value}) => <CountCellComponent value={value} />,
      width: 110,
      sortable: false
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.skills)}</HeaderWrapper>,
      accessor: 'skills',
      Cell: ({value}) => <CountCellComponent value={value} />,
      width: 110,
      sortable: false
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.overview)} (%)</HeaderWrapper>,
      accessor: 'overview',
      Cell: ({value}) => <OverviewCellComponent value={value} />,
      sortable: false
    }
  ];

  return (
    <DataTable
      columns={columns}
      hoverEffect
      paddingRows={5}
      getTrGroupProps={(_, rowInfo) => ({
        onClick: () => onClickTeam(rowInfo.original.id)
      })}
      rowBottomBorder={false}
      data={data}
      defaultSorted={sorted}
      onSortedChange={setSorted}
    />
  );
};

const LoadingState = () => (
  <LoaderContainer>
    <LoadingMessage></LoadingMessage>
  </LoaderContainer>
);

const ErrorState = () => {
  return <FormattedMessage {...messages.error} />;
};

export const GapOverviewTableInject = injectIntl(inject('store')(GapOverviewTable));

const GapOverviewComponent = props => {
  const {
    intl: {formatMessage}
  } = props;

  const [getSkillMatrixQuery, {loading, error, data}] = useLazyQuery(PaginatedSkillMatrix, {
    fetchPolicy: 'network-only'
  });

  const fetchData: Parameters<typeof usePaginationParameters>[0] = useCallback(
    ({pageIndex, sorted}) => {
      getSkillMatrixQuery({
        variables: {
          limit: pageSize,
          offset: pageIndex * pageSize,
          sortBy: sorted.map(sortByItem => {
            return {field: sortByItem.id, order: sortByItem.desc ? 'desc' : 'asc'};
          })
        }
      });
    },
    [getSkillMatrixQuery]
  );

  const {pageIndex, setPageIndex, sorted, setSorted} = usePaginationParameters(fetchData);

  if (loading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState />;
  }

  const matrixData: MatrixDataTeam[] = data?.paginatedSkillMatrix.results?.map(matrix => {
    const skillsCount = matrix.skills?.skillsCount || 0;
    const totalSkills = matrix.skills?.totalSkillsAssigned || 0;
    const totalApprovedSkills = sumBy(matrix.skills?.levels, 'approvedCount');
    const totalGap = totalSkills - totalApprovedSkills;

    return {
      id: matrix.id,
      name: matrix.name,
      members: matrix.members,
      skills: skillsCount,
      overview: matrix.skills
        ? {
            approved: {
              total: totalApprovedSkills,
              color: '#009D9A'
            },
            gap: {
              total: totalGap,
              color: '#CE131C'
            }
          }
        : {}
    };
  });

  if (!matrixData?.length) {
    return <div>{formatMessage(messages.noData)}</div>;
  }

  const totalPages = Math.ceil(data?.paginatedSkillMatrix.totalCount / pageSize);

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: 20,
          height: 44,
          margin: '32px 0 34px 0'
        }}
      >
        <SmartSkillsLegendItem color="#009D9A" label={formatMessage(messages.statsApproved)} />
        <SmartSkillsLegendItem color="#CE131C" label={formatMessage(messages.gap)} />
      </div>

      <GapOverviewTableInject data={matrixData} sorted={sorted} setSorted={setSorted} />

      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={pageIndex}
          setPageIndex={setPageIndex}
          prevPageTitle={formatMessage(messages.prevPage)}
          nextPageTitle={formatMessage(messages.nextPage)}
        />
      )}
    </>
  );
};

export default injectIntl(inject('store')(GapOverviewComponent));
