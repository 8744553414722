import React, {useEffect, useMemo} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import views from 'config/views';
import {useMutation, useLazyQuery} from '@apollo/client';

//helpers
import notification from 'utils/notification-utils';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';

//styles
import {
  StyledDataTable,
  StyledRemoveIcon,
  Header,
  LanguageCell,
  Translations,
  StyledButton,
  HeaderButtonWrapper,
  CompletionProgressWrapper,
  CompletionProgressBarContainer,
  CompletionProgressBar,
  CompletionAmount,
  DefaultLanguageCompletion,
  StyledRemoveIconWrapper,
  StyledSpinner
} from './styles';

//queries
import {TranslationRate} from 'api/translations/queries';

//mutations
import {RemoveTranslationFromGuide} from 'api/guide/mutations';

//messages
import messages from './messages';

const getOrderedTranslationRateLanguages = (languages, defaultLocale) => {
  if (!languages || !defaultLocale) return [];
  const defaultLanguage = languages.find(language => language.locale === defaultLocale);
  return defaultLanguage ? [defaultLanguage, ...languages.filter(language => language.locale !== defaultLocale)] : [];
};

export const TranslationsComponent = props => {
  const {
    intl: {formatMessage},
    store,
    store: {
      router,
      translationsPage,
      addNewLanguageDialog,
      platform: {availableLanguages}
    }
  } = props;

  const guideId = router.params.id;
  const {defaultLocale, getLanguageLabel, availableTranslations, removeTranslationFromGuide, setAvailableTranslations} =
    translationsPage;
  const canAddMoreLanguages = availableLanguages.length !== availableTranslations.length;

  const [getTranslationsRate, {loading, error, data: translationRateData}] = useLazyQuery(TranslationRate, {
    variables: {
      id: guideId
    },
    fetchPolicy: 'cache-first'
  });

  const languages = useMemo(
    () => getOrderedTranslationRateLanguages(translationRateData?.guideDetails?.translationRate, defaultLocale),
    [translationRateData, defaultLocale]
  );

  const [removeTranslationFromGuideMutation] = useMutation(RemoveTranslationFromGuide, {
    // invalidate translationRate cache to refetch it when open that page again
    update(cache) {
      cache.evict({id: `Guide:${guideId}`, fieldName: 'translationRate'});
      cache.gc();
    }
  });

  const translatedRate = locale => {
    const matchedLanguage = languages.find(language => language.locale === locale);
    const completion = matchedLanguage ? matchedLanguage.completion : 0;
    const roundedCompletion = completion > 100 ? 100 : completion;
    if (locale === defaultLocale) {
      return <DefaultLanguageCompletion>{formatMessage(messages.guideLanguage)}</DefaultLanguageCompletion>;
    }

    return (
      <CompletionProgressWrapper>
        <CompletionAmount>{roundedCompletion}%</CompletionAmount>
        <CompletionProgressBarContainer>
          <CompletionProgressBar completion={completion} />
        </CompletionProgressBarContainer>
        {locale !== defaultLocale && (
          <StyledRemoveIconWrapper onClick={event => onRemoveTranslation(event, locale)}>
            <StyledRemoveIcon />
          </StyledRemoveIconWrapper>
        )}
      </CompletionProgressWrapper>
    );
  };

  const tableData = useMemo(
    () =>
      languages.map(({locale}) => ({
        locale,
        language: getLanguageLabel(locale, translationRateData, defaultLocale),
        completion: translatedRate(locale)
      })),
    [defaultLocale, translationRateData]
  );

  const getRowClassName = item => {
    if (item.locale === defaultLocale) {
      return '-highlight noHighlightBackground';
    }

    return '';
  };

  const onRemoveTranslation = async (event, locale) => {
    event.stopPropagation();
    await removeTranslationFromGuide(locale, removeTranslationFromGuideMutation);
  };

  const importTranslations = () => {
    const {uploadTranslationsDialog: dialog} = store;

    dialog.open(
      () => {
        notification.success(formatMessage(messages.importSuccess));
      },
      guideId,
      error => {
        // For scenarios where users are using old exported traanlation files
        // TODO: This can be removed when no one is using old exported files
        if (error) {
          return notification.error(formatMessage(messages.importDeprecated));
        }

        notification.error(formatMessage(messages.importFailure));
      }
    );
  };

  const exportTranslations = () => {
    const origin = window.location.origin.replace('3033', '3000');
    const exportURL = `${origin}/translation/${guideId}.xlsx`;
    window.open(exportURL);
  };

  useEffect(() => {
    getTranslationsRate();
  }, []);

  useEffect(() => {
    setAvailableTranslations(languages.map(({locale}) => locale));
  }, [languages]);

  if (loading || !defaultLocale) {
    return <StyledSpinner />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Translations>
      <Header>
        <ViewTitle title={formatMessage(messages.manageTranslations)} />
        <HeaderButtonWrapper>
          <StyledButton secondary onClick={exportTranslations} label={formatMessage(messages.exportAll)} />
          <StyledButton secondary label={formatMessage(messages.importAll)} onClick={importTranslations} />
          <StyledButton
            onClick={addNewLanguageDialog.open}
            label={formatMessage(messages.addNewLanguage)}
            disabled={!canAddMoreLanguages}
          />
        </HeaderButtonWrapper>
      </Header>
      <StyledDataTable
        data={tableData}
        sortable={false}
        columns={[
          {
            Header: '',
            accessor: 'language',
            Cell: p => {
              if (typeof p.value !== 'string') {
                return p.value;
              }
              return <LanguageCell>{p.value}</LanguageCell>;
            }
          },
          {
            Header: '',
            accessor: 'completion',
            Cell: p => p.value,
            width: 200
          }
        ]}
        primaryAction={translation => {
          if (!translation.locale || translation.locale === defaultLocale) {
            return;
          } else {
            router.goTo(views.translateLocale, {id: guideId, locale: translation.locale});
          }
        }}
        getTrProps={(_, rowInfo) => ({
          className: getRowClassName(rowInfo.original)
        })}
      />
    </Translations>
  );
};

export default injectIntl(inject('store')(observer(TranslationsComponent)));
