import styled from 'styled-components';

export const ThemeColorPreview = styled.div`
  height: 1rem;
  width: 1rem;
  display: inline-block;
  margin-right: 8px;
  background: ${p => p.color};
  border: 1px solid #ffffff;
`;

export const StyledDiv = styled.div`
  margin: 40px;
`;
