import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkUppercaseOnEmail, checkUniqueEmail, checkUniquePhoneNumber} from 'utils/validation-utils';

const asyncRules = {
  checkUniqueEmail: (value, attr, key, passes) => {
    return checkUniqueEmail(value, passes);
  },
  checkUppercaseOnEmail: (value, attr, key, passes) => {
    return checkUppercaseOnEmail(value, passes);
  },
  checkUniquePhoneNumber: (value, attr, key, passes) => {
    return checkUniquePhoneNumber(value, passes);
  }
};

const plugins = dvrWithAsync(asyncRules);

const fields = [
  {
    name: 'email',
    rules: 'email|string|checkUniqueEmail|checkUppercaseOnEmail'
  },
  {
    name: 'phoneNumber',
    rules: 'string|checkUniquePhoneNumber'
  },
  {
    name: 'fullName',
    label: 'full name',
    rules: 'required|string'
  }
];

export default new MobxReactForm({fields}, {plugins});
