import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'feedback',
    defaultMessage: 'Feedback'
  }
});

export default messages;
