import {defineMessages} from 'react-intl';

const messages = defineMessages({
  warningTitle: {
    id: 'Analytics.warningTitle',
    defaultMessage: 'Invalid daterange'
  },
  warningText: {
    id: 'Analytics.warningText',
    defaultMessage:
      'Data before August 1st 2018 is not available. You will be presented with data starting from August 1st 2018'
  },
  warningButton: {
    id: 'common.actions.okay',
    defaultMessage: 'Okay'
  },
  allWorkspaces: {
    id: 'Analytics.allWorkspaces',
    defaultMessage: 'All Workspaces'
  },
  placeholder: {
    id: 'Analytics.placeholder',
    defaultMessage: 'Select a workspace'
  },
  filterBy: {
    id: 'common.actions.filterBy',
    defaultMessage: 'Filter by'
  },
  workspace: {
    id: 'Menu.workspace',
    defaultMessage: 'Workspace'
  },
  empty: {
    id: 'DataTable.empty',
    defaultMessage: 'No data'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  }
});

export default messages;
