import React, {Component} from 'react';
import moment from 'moment';

import {FormattedMessage} from 'components/FormattedComponents';
import Icon from 'ui-components/Icon';
import {Divider} from 'ui-components/Dialog';

import {DATE_FORMAT, TIME_FORMAT} from 'config/constants';
import {INSTRUCTION_TYPE} from 'shared/enums';

import {Feedback, Info, TimeLabel, Message, Label, Title} from './styles';

import messages from './messages';

const {CHECKLIST} = INSTRUCTION_TYPE;

const FeedbackParentLabel = props => {
  const {feedback} = props;
  if (feedback.guide) {
    return (
      <Label>
        <FormattedMessage {...messages.feedbackOnGuide} />
      </Label>
    );
  } else if (feedback.instruction) {
    const instructionMessage =
      feedback.instruction.type === CHECKLIST ? 'feedbackOnChecklist' : 'feedbackOnInstruction';

    return (
      <Label>
        <FormattedMessage {...messages[instructionMessage]} />
        <Title>{': ' + feedback.instruction.title}</Title>
      </Label>
    );
  } else if (feedback.step) {
    const stepMessage = feedback.step.instructionType === CHECKLIST ? 'feedbackOnChecklistStep' : 'feedbackOnStep';

    return (
      <Label>
        <FormattedMessage {...messages[stepMessage]} />
        <Title>
          {': '}
          <FormattedMessage
            {...messages.stepReference}
            values={{
              instructionTitle: feedback.step.instructionTitle,
              stepNumber: feedback.step.position + 1
            }}
          />
        </Title>
      </Label>
    );
  }
};

class FeedbackRowComponent extends Component {
  render() {
    const {feedback, showDivider} = this.props;
    const {body, dateCreated} = feedback;
    return (
      <Feedback>
        {showDivider && <Divider style={{margin: '20px 0'}} />}
        <TimeLabel>
          <Icon iconId="date" solid={false} />
          <Label>{moment(dateCreated).format(DATE_FORMAT)}</Label>
          <Icon iconId="time" solid={false} />
          <Label>{moment(dateCreated).format(TIME_FORMAT)}</Label>
        </TimeLabel>
        <Info>
          <FeedbackParentLabel feedback={feedback} />
          <Message>{body}</Message>
        </Info>
      </Feedback>
    );
  }
}

export default FeedbackRowComponent;
