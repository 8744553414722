import {action, observable, computed, makeObservable} from 'mobx';
import {client as apolloClient} from 'utils/apollo-client';
import {LinkToTopicIsValid, LinkToInstructionIsValid, LinkToStepIsValid} from 'api/check-links/queries';
import store from 'stores/store';

const defaultFilters = [
  {key: 'contentChanges', selected: true},
  {key: 'publishedVersions', selected: true},
  {key: 'approvalUpdates', selected: true}
];

class VersionHistoryDrawer {
  @observable opened = false;
  @observable guideId;
  @observable guideTitle;
  @observable options = defaultFilters;
  @observable translations;
  @observable draftVersionOid = null;
  @observable reviewingVersionOid = null;

  @action
  reset = () => {
    this.guideId = null;
    this.opened = false;
    this.draftVersionOid = null;
    this.reviewingVersionOid = null;
    this.resetFilters();
    store.router.goTo(store.router.currentRoute, store.router.params);
  };

  @action open = ({guideId, guideTitle, draftVersionOid, reviewingVersionOid}) => {
    this.opened = true;
    this.guideId = guideId;
    this.guideTitle = guideTitle;
    this.draftVersionOid = draftVersionOid;
    this.reviewingVersionOid = reviewingVersionOid;
  };

  @action onOptionChange = value => {
    const options = this.options.map(option => {
      if (option.key === value) {
        return {
          key: value,
          selected: !option.selected
        };
      }

      return option;
    });

    this.setOptions(options);
  };

  @action setOptions = options => (this.options = options);

  @action setTranslations = translations => (this.translations = translations);

  @action resetFilters = () => (this.options = defaultFilters);

  constructor() {
    makeObservable(this);
  }

  getVersionQueryParam = versionOid => {
    const isDraft = versionOid === this.draftVersionOid;
    if (isDraft) {
      return undefined;
    }
    const isVersionUnderReview = versionOid === this.reviewingVersionOid;
    if (isVersionUnderReview) {
      return 'review';
    }
    return versionOid;
  };

  checkTopicLinkIsValid = async ({guideId, topicId, versionId}) => {
    const result = await apolloClient.query({
      query: LinkToTopicIsValid,
      variables: {
        guideId,
        topicId,
        versionId
      },
      fetchPolicy: 'network-only'
    });

    return result.data.linkToTopicIsValid.result;
  };

  checkInstructionLinkIsValid = async ({guideId, topicId, instructionId, versionId}) => {
    const result = await apolloClient.query({
      query: LinkToInstructionIsValid,
      variables: {
        instructionId,
        guideId,
        topicId,
        versionId
      },
      fetchPolicy: 'network-only'
    });

    return result.data.linkToInstructionIsValid.result;
  };

  checkStepLinkIsValid = async ({guideId, topicId, instructionId, stepId, versionId}) => {
    const result = await apolloClient.query({
      query: LinkToStepIsValid,
      variables: {
        stepId,
        instructionId,
        guideId,
        topicId,
        versionId
      },
      fetchPolicy: 'network-only'
    });

    return result.data.linkToStepIsValid.result;
  };

  @computed get activeFilters() {
    return this.options.filter(option => option.selected).length;
  }

  @computed get activeFilterKeys() {
    return this.options.filter(option => option.selected).map(option => option.key);
  }
}

export default VersionHistoryDrawer;
