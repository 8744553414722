import styled, {css} from 'styled-components';
import Icon from 'ui-components/Icon';

//helpers
import {commonPalette} from 'shared/styles/palette';
import {noSelect} from 'shared/utils/sc-utils';

export const CheckboxButton = styled.div`
  height: ${p => p.height || 21}px;
  ${p =>
    p.design === 'default' &&
    css`
      ${ClickableArea} {
        display: inline-flex; // only the circle and the caption are clickable
      }

      ${Caption} {
        margin-left: 10px;
      }
    `}

  ${p =>
    p.design === 'fill-line' &&
    css`
      ${ClickableArea} {
        display: flex; // the whole line is clickable
        padding: 0 24px;

        &:hover {
          color: ${commonPalette.btnGreen};
        }
      }

      ${Checkbox} {
        color: inherit !important;
      }

      ${Caption} {
        margin-left: 10px;
      }
    `}
`;

export const ClickableArea = styled.div`
  align-items: center;
  cursor: pointer;

  ${p =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    `}
`;

export const Checkbox = styled.div`
  width: 21px;
  height: 21px;
  border: 1px solid ${commonPalette.borderGrey};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${commonPalette.sgGreen};
  background-color: #fff;
`;

export const Caption = styled.div`
  ${noSelect}
  font-size: 14px;
  line-height: 20px;
`;

export const Checkmark = styled(Icon).attrs({
  iconId: 'checkmark'
})``;
