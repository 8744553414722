import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ChangePassword.title',
    defaultMessage: 'Change your password'
  },
  oldPassword: {
    id: 'ChangePassword.oldPassword',
    defaultMessage: 'Current password'
  },
  newPassword: {
    id: 'ResetPassword.newPassword',
    defaultMessage: 'New password'
  },
  newPasswordConfirm: {
    id: 'ResetPassword.newPasswordConfirm',
    defaultMessage: 'Retype new password'
  },
  passwordMismatch: {
    id: 'common.forms.passwordMismatch',
    defaultMessage: 'The passwords must match'
  },
  passwordComplexityRulesTip: {
    id: 'common.forms.passwordComplexityRulesTip',
    defaultMessage: 'Please make sure that your password follows these rules:'
  },
  passwordMustHave8Characters: {
    id: 'common.forms.passwordMustHave8Characters',
    defaultMessage: 'Password must have at least 8 characters'
  },
  passwordMustHaveAtLeast1Number: {
    id: 'common.forms.passwordMustHaveAtLeast1Number',
    defaultMessage: 'Password must have at least 1 number'
  },
  passwordMustHaveAtLeast1UppercaseLetter: {
    id: 'common.forms.passwordMustHaveAtLeast1UppercaseLetter',
    defaultMessage: 'Password must have at least 1 uppercase letter'
  },
  passwordMustHaveAtLeast1LowercaseLetter: {
    id: 'common.forms.passwordMustHaveAtLeast1LowercaseLetter',
    defaultMessage: 'Password must have at least 1 lowercase letter'
  },
  passwordMustHaveAtLeast1NonAlphanumericCharacter: {
    id: 'common.forms.passwordMustHaveAtLeast1NonAlphanumericCharacter',
    defaultMessage: 'Password must have at least 1 non-alphanumeric character (!@$&, etc.)'
  },
  change: {
    id: 'common.actions.change',
    defaultMessage: 'Change'
  },
  updateSuccess: {
    id: 'ChangePassword.updateSuccess',
    defaultMessage: 'Password has been changed.'
  },
  updateFailure: {
    id: 'ChangePassword.updateFailure',
    defaultMessage: 'Invalid password, please try again.'
  },
  passwordsDoNotMatch: {
    id: 'passwordsDoNotMatch',
    defaultMessage: 'Passwords do not match'
  }
});

export default messages;
