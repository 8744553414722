import {
  GuidesList,
  GetGuide,
  GetGuideAssignee,
  GetDomainsForGuide,
  GetWorkspacesForGuide,
  GetDraftGuide
} from 'api/guide/queries';

export const refreshGuide = (id, versionId) => {
  return (
    id && {
      query: GetGuide,
      variables: {
        id,
        versionId
      }
    }
  );
};

export const refreshGuideAssignee = id => {
  return (
    id && {
      query: GetGuideAssignee,
      variables: {
        id
      }
    }
  );
};

export const refreshGuideDomains = id => {
  return (
    id && {
      query: GetDomainsForGuide,
      variables: {
        id
      }
    }
  );
};

export const refreshGuideWorkspaces = id => {
  return (
    id && {
      query: GetWorkspacesForGuide,
      variables: {
        id
      }
    }
  );
};

export const refreshDraftGuide = id => {
  return (
    id && {
      query: GetDraftGuide,
      variables: {
        id
      }
    }
  );
};

export const refreshGuides = () => {
  return {
    query: GuidesList
  };
};
