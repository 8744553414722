import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import Icon from 'shared/components/Icon';

export const dialogStyle = {
  maxWidth: 513,
  maxHeight: 525,
  borderRadius: 8
};

export const Title = styled.div`
  color: #000;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
`;

export const CloseButton = styled(Icon).attrs({
  name: 'close'
})`
  height: 14px;
  color: ${commonPalette.txBlack};
  margin-top: 10px;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 20px 0;
`;

export const Description = styled.div`
  color: ${commonPalette.black};

  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 32px;
`;

export const SkillProfilesWrapper = styled.div`
  max-height: 261px;
  overflow: hidden auto;
  margin-bottom: 32px;
`;

export const LoaderContainer = styled.div`
  margin-top: 200px;
`;
