import PropTypes from 'prop-types';
import React, {Component} from 'react';

//helpers
import {TOPIC_TITLE_TYPE} from 'config/enums';
import {styleable} from 'shared/decorators';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {Caret, Instructions, Title, TitleInner, TitleTypePreview} from './styles';

//messages
import messages from './messages';

@styleable
export default class TitleTypePreviewComponent extends Component {
  static defaultProps = {
    type: TOPIC_TITLE_TYPE.VISIBLE
  };

  static propTypes = {
    type: PropTypes.oneOf([TOPIC_TITLE_TYPE.COLLAPSIBLE, TOPIC_TITLE_TYPE.HIDDEN, TOPIC_TITLE_TYPE.VISIBLE])
  };

  render() {
    const {type} = this.props;

    return (
      <TitleTypePreview>
        <Title>
          <TitleInner isInvisible={type === TOPIC_TITLE_TYPE.HIDDEN}>
            <FormattedMessage {...messages.topic} />
          </TitleInner>
          <Caret isInvisible={type !== TOPIC_TITLE_TYPE.COLLAPSIBLE} />
        </Title>
        <Instructions isInvisible={type === TOPIC_TITLE_TYPE.COLLAPSIBLE} />
      </TitleTypePreview>
    );
  }
}
