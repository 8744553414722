import {defineMessages} from 'react-intl';

const messages = defineMessages({
  dragAndDrop: {
    id: 'dragAndDrop',
    defaultMessage: 'Drag and drop files'
  },
  videosForbidden: {
    id: 'MediaUpload.videosForbidden',
    defaultMessage: 'Cannot upload a video. Please try again with an image.'
  },
  typeForbidden: {
    id: 'MediaUpload.typeForbidden',
    defaultMessage: 'Cannot upload a file of that type.'
  },
  extensionForbidden: {
    id: 'MediaUpload.extensionForbidden',
    defaultMessage: 'The file is not using the correct format.'
  },
  tooLarge: {
    id: 'MediaUpload.tooLarge',
    defaultMessage: 'The file is larger than {allowedSize} MB.'
  },
  uploadNew: {
    id: 'MediaUpload.uploadNew',
    defaultMessage: 'Upload new'
  },
  networkError: {
    id: 'MediaUpload.networkError',
    defaultMessage: 'Network Error. Please try again later.'
  },
  acceptedFiles: {
    id: 'acceptedFiles',
    defaultMessage: 'Accepted files:'
  },
  browseYourComputer: {
    id: 'browseYourComputer',
    defaultMessage: 'Browse your computer'
  },
  copyAndPaste: {
    id: 'copyAndPaste',
    defaultMessage: 'Copy and Paste'
  },
  uploading: {
    id: 'uploading',
    defaultMessage: 'Uploading...'
  },
  numberOfUploads: {
    id: 'numberOfUploads',
    defaultMessage: '{currentFileNumber} of {totalFilesNumber}'
  }
});
export default messages;
