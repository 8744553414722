import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

//components
import {Dialog} from 'ui-components';
import Field from 'shared/components/Field';

//messages
import messages from './messages';
import {bindField} from 'shared/utils/input-utils';

@inject('store')
@observer
class RejectVersionDialog extends Component {
  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {rejectVersionDialog} = store;
    const {opened, reset, rejectVersion, form} = rejectVersionDialog;
    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.rejectAndSendFeedback),
            onClick: rejectVersion,
            disabled: !form.isValid
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={formatMessage(messages.rejectVersion)}
        bodyStyle={{marginBottom: '-40px'}}
      >
        <Field
          styles={{Input: {height: '120px', padding: '10px 16px'}}}
          inputType="textarea"
          placeholder={formatMessage(messages.placeholder)}
          title={formatMessage(messages.bodyMessage)}
          {...bindField(form, 'comment')}
        />
      </Dialog>
    );
  }
}

export default injectIntl(RejectVersionDialog);
