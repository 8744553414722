import styled from 'styled-components';
import $Field from 'ui-components/Field';

export const Form = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 855px;
  height: calc(100% - 200px);
  overflow: scroll;
`;

export const FormColumn = styled.div`
  margin-right: 30px;
  max-width: 350px;
  min-width: 300px;
  flex: 1;
`;

export const Field = styled($Field)`
  width: 100%;
`;

export const CheckgroupTitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
`;

export const CheckboxWithIconWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const SignInOptionsWrapper = styled.div`
  margin-bottom: 20px;
`;
