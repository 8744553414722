import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {toJS} from 'mobx';
import get from 'lodash/get';
import views from 'config/views';
import notification from 'utils/notification-utils';

//components
import VersionButton from 'components/VersionButton';
import FontAwesomeIcon from 'ui-components/Layout/FontAwesomeIcon';

//helpers
import {ensureDraftVersion} from 'utils/versioning-utils';
import {INSTRUCTION_TYPE} from 'shared/enums';

//styles
import {
  ChecklistIcon,
  HeaderCard,
  InfoGroup,
  Title,
  InfoWrapper,
  Label,
  ButtonGroup,
  HeaderWrapper,
  StyledUnderlinedButton,
  ExpiryDate,
  StyledHistoryIcon
} from './styles';

import messages from './messages';
import PillButton from 'components/PillButton';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@observer
class DetailsViewComponent extends Component {
  handleEditClick = ensureDraftVersion(() => {
    const {selectedStore} = this.props;
    selectedStore.setEditMode(true);

    trackEvent(EVENT_TYPES.EDIT_GUIDE_DETAILS);
  }, this);

  handleVersionButtonClick = () => {
    const {store} = this.props;
    const {unsavedChangesDialog} = store;

    unsavedChangesDialog.check().then(() => this.openGuideVersions());

    trackEvent(EVENT_TYPES.OPEN_GUIDE_VERSIONS_DIALOG);
  };

  handleResize = ({height}) => {
    const {store} = this.props;
    const {detailsCard} = store;

    if (detailsCard.cardHeight !== height) {
      detailsCard.setCardHeight(height);
    }
  };

  openGuideVersions = () => {
    const {store, selectedStore} = this.props;
    const {guideVersionsDialog: dialog, editGuidePage} = store;

    dialog.open({
      isUnderApproval: editGuidePage.isUnderApproval || selectedStore.guide.isUnderApproval,
      isLiveVersionExpired: editGuidePage.isLiveVersionExpired,
      versions: toJS(selectedStore.guide.versions),
      isPublished: selectedStore.guide.publishStatus === 'PUBLISHED'
    });
  };

  rejectVersion = () => {
    const {store, submitGuideApprovalReviewMutation, submitLiveVersionReviewMutation, translations} = this.props;
    const {rejectVersionDialog: dialog, router, editGuidePage} = store;
    const {isViewingUnderApprovalVersion} = editGuidePage;
    const {id} = router.params;
    const submitReviewMutation = isViewingUnderApprovalVersion
      ? submitGuideApprovalReviewMutation
      : submitLiveVersionReviewMutation;

    dialog.open({
      id,
      translations,
      submitReviewMutation
    });
  };

  getDefaultLanguage = locale => {
    const {
      store: {platform}
    } = this.props;
    const defaultLanguage = platform.availableLanguages.find(l => l.locale === locale);
    return defaultLanguage ? defaultLanguage.language : locale;
  };

  getPrimaryButton = () => {
    const {
      selectedStore,
      store,
      publish,
      requestGuideApproval,
      submitGuideApprovalReviewMutation,
      submitLiveVersionReviewMutation,
      publishButtonLabel,
      translations,
      unpublish,
      intl: {formatMessage}
    } = this.props;
    const {publishGuideDialog, editGuidePage, requestApprovalDialog} = store;
    const {guide} = selectedStore;
    const {
      showPublishButton,
      showPublishButtonDisabled,
      showUnpublishButton,
      showApproveButton,
      showApproveAndPublishButton,
      submitGuideApproval,
      showRequestApprovalForOldVersion,
      showRequestApprovalButton,
      showRequestApprovalButtonDisabled,
      isGuideApprovalEnabled,
      isViewingUnderApprovalVersion,
      canApprove
    } = editGuidePage;

    const versionId = get(store.router, 'queryParams.v', null);
    const submitReviewMutation = isViewingUnderApprovalVersion
      ? submitGuideApprovalReviewMutation
      : submitLiveVersionReviewMutation;

    if (showUnpublishButton) {
      return {
        label: formatMessage(messages.unpublish),
        dataCy: 'unpublish',
        onClick: unpublish
      };
    }

    if (showPublishButton) {
      return {
        label: formatMessage(publishButtonLabel),
        dataCy: 'publish-guide',
        disabled: showPublishButtonDisabled,
        onClick: () =>
          publishGuideDialog.open({
            publishMutation: !isGuideApprovalEnabled && publish,
            approveAndPublishMutation: isGuideApprovalEnabled && submitReviewMutation,
            translations,
            guideSlug: guide.slug,
            guide
          })
      };
    }

    if (showRequestApprovalButton) {
      return {
        label: formatMessage(messages.requestApproval),
        dataCy: 'request-guide-approval',
        disabled: showRequestApprovalButtonDisabled,
        onClick: () =>
          requestApprovalDialog.open({
            requestApprovalMutation: requestGuideApproval,
            id: guide.id,
            translations: {
              requestApprovalSuccess: formatMessage(messages.requestApprovalSuccess),
              requestApprovalFailure: formatMessage(messages.requestApprovalFailure)
            }
          })
      };
    }

    if (showApproveButton) {
      return {
        label: formatMessage(messages.approve),
        dataCy: 'approve-button',
        onClick: () => submitGuideApproval(submitReviewMutation, true, translations),
        buttonStyle: {pointerEvents: 'auto'},
        iconId: !canApprove ? 'info' : '',
        iconStyle: !canApprove ? {width: '16px', height: '16px'} : null,
        disabled: !canApprove,
        popoverButtonContent: !canApprove ? formatMessage(messages.pendingApproval) : ''
      };
    }

    if (showApproveAndPublishButton) {
      return {
        label: formatMessage(messages.approveAndPublish),
        dataCy: 'approve-and-publish-button',
        onClick: () =>
          publishGuideDialog.open({
            approveAndPublishMutation: submitReviewMutation,
            translations,
            guideSlug: guide.slug,
            guide
          })
      };
    }

    if (showRequestApprovalForOldVersion) {
      return {
        label: formatMessage(publishButtonLabel),
        dataCy: 'request-guide-approval-old-version',
        onClick: () =>
          requestApprovalDialog.open({
            requestApprovalMutation: requestGuideApproval,
            id: guide.id,
            versionId,
            translations: {
              requestApprovalSuccess: formatMessage(messages.requestApprovalSuccess),
              requestApprovalFailure: formatMessage(messages.requestApprovalFailure)
            }
          })
      };
    }
    return null;
  };

  getSecondaryButton = () => {
    const {
      selectedStore,
      store,
      withdrawGuideApproval,
      intl: {formatMessage}
    } = this.props;
    const {saveAsDraftDialog, editGuidePage, guideVersionsDialog} = store;
    const {guide} = selectedStore;
    const {showSetAsDraftButton, showCancelRequestButton, showRejectButton, canApprove, isGuideApprovalTypeSequential} =
      editGuidePage;
    const {settingVersion} = guideVersionsDialog;
    const id = store.router.params;

    if (showSetAsDraftButton && !settingVersion) {
      return {
        label: formatMessage(messages.setAsDraft),
        dataCy: 'set-as-draft',
        onClick: saveAsDraftDialog.check
      };
    }

    if (showCancelRequestButton) {
      return {
        label: formatMessage(messages.cancelApproval),
        dataCy: 'cancel-guide-approval',
        onClick: async () => {
          try {
            await withdrawGuideApproval(id);
            store.router.goTo(views.editGuide, {id: guide.id}, {});
            notification.success(formatMessage(messages.successCancelApproval));
          } catch (e) {
            notification.error(formatMessage(messages.errorCancelApproval));
          }
        }
      };
    }

    if (showRejectButton) {
      return {
        label: formatMessage(messages.reject),
        dataCy: 'reject-button',
        onClick: this.rejectVersion,
        disabled: isGuideApprovalTypeSequential && !canApprove
      };
    }

    return null;
  };

  render() {
    const {
      contentType,
      selectedStore,
      store,
      intl: {formatMessage}
    } = this.props;
    const {editGuidePage, selectDefaultGuideLanguageDialog} = store;
    const {
      tagsNames,
      badgesNames,
      guide,
      defaultLocale,
      title,
      versions,
      currentInstruction,
      currentVersionId,
      isLastestVersion
    } = selectedStore;
    const {isViewingUnderApprovalVersion, isViewingDraft} = editGuidePage;
    const expiryDate = guide.expiryDate ? new Date(guide.expiryDate) : null;

    const isChecklist = currentInstruction && currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;

    return (
      <HeaderWrapper>
        <HeaderCard contentType={contentType} onResize={this.handleResize}>
          <StyledUnderlinedButton
            underlined
            dataCy="edit-details"
            className="edit-details-link"
            onClick={this.handleEditClick}
            iconId="edit"
            label={formatMessage(messages.editDetails)}
          />
          <Title data-cy="title">
            {isChecklist && <ChecklistIcon />}
            {title}
          </Title>
          {isLastestVersion && expiryDate && (
            <ExpiryDate>
              <StyledHistoryIcon name="history" />
              {formatMessage(messages.reviewReminder)}: {expiryDate.toLocaleDateString('en-GB')}
            </ExpiryDate>
          )}
          {(tagsNames || badgesNames) && (
            <InfoGroup>
              {tagsNames && (
                <InfoWrapper>
                  <FontAwesomeIcon actionId="tag" />
                  <Label data-cy="tags">{tagsNames}</Label>
                </InfoWrapper>
              )}
              {badgesNames && (
                <InfoWrapper>
                  <FontAwesomeIcon actionId="badge" />
                  <Label>{badgesNames}</Label>
                </InfoWrapper>
              )}
            </InfoGroup>
          )}

          <InfoGroup>
            {contentType === 'guide' && isViewingDraft && (
              <PillButton
                icon={'globe-americas'}
                title={this.getDefaultLanguage(defaultLocale)}
                onClick={() => selectDefaultGuideLanguageDialog.open(guide.id, formatMessage)}
              />
            )}
            <VersionButton
              versions={toJS(versions)}
              currentVersionId={isViewingUnderApprovalVersion ? 'review' : currentVersionId}
              isPublished={guide.publishStatus === 'PUBLISHED'}
              onClick={this.handleVersionButtonClick}
            />
          </InfoGroup>
        </HeaderCard>
        <ButtonGroup primary={this.getPrimaryButton()} secondary={this.getSecondaryButton()} />
      </HeaderWrapper>
    );
  }
}

export default injectIntl(DetailsViewComponent);
