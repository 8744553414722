import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'UnsavedChanges.title',
    defaultMessage: 'You have unsaved changes'
  },
  description: {
    id: 'UnsavedChanges.description',
    defaultMessage: 'What would you like to do?'
  },
  save: {
    id: 'UnsavedChanges.save',
    defaultMessage: 'Save and continue'
  },
  discard: {
    id: 'UnsavedChanges.discard',
    defaultMessage: 'Discard and continue'
  }
});

export default messages;
