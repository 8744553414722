import {defineMessages} from 'react-intl';

const messages = defineMessages({
  followUpTypePlaceholder: {
    id: 'FollowUpKindPicker.typePlaceholder',
    defaultMessage: 'Select answer type...'
  },
  answerType: {
    id: 'EditCampaign.answerType',
    defaultMessage: 'Answer type'
  },
  text: {
    id: 'FollowUpKindPicker.text',
    defaultMessage: 'Text'
  },
  time: {
    id: 'FollowUpKindPicker.time',
    defaultMessage: 'Time'
  }
});

export default messages;
