import styled from 'styled-components';
import $ElementResizeListener from 'shared/components/ElementResizeListener';
import Button from 'ui-components/Button';
import $ButtonGroup from 'ui-components/ButtonGroup';
import Icon from 'ui-components/Layout/Icon';

//helpers
import typefaces from 'shared/styles/typefaces';
import {ellipsisWrap, noSelect} from 'shared/utils/sc-utils';
import {commonPalette} from 'shared/styles/palette';

export const HeaderCard = styled($ElementResizeListener)`
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-width: 445px;
  margin-right: 20px;
  ${p => p.contentType === 'guide' && 'width: 55%'};
  ${p => p.contentType === 'instruction' && 'width: 70%'};
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 5px;
  z-index: 1;

  &:hover {
    ${() => Label} {
      opacity: 1;
    }

    ${() => StyledUnderlinedButton} {
      #button-label {
        opacity: 1;
      }
    }
  }

  @media (max-width: 720px) {
    width: 100%;
    min-width: 0;
    margin: 0;
  }
`;

export const Title = styled.h2`
  ${ellipsisWrap}
  ${typefaces.guideDetails.title};
  ${noSelect}
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const InfoGroup = styled.div`
  display: flex;
  margin: 10px 0;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const InfoWrapper = styled.div`
  margin-right: 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const Label = styled.div`
  margin-left: 5px;
  white-space: nowrap;
  &.edit-label {
    opacity: 0;
  }
`;

export const ButtonGroup = styled($ButtonGroup)`
  margin-top: 20px;
  margin-left: auto;
  z-index: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledUnderlinedButton = styled(Button).attrs({
  underlined: true,
  iconOnRight: true
})`
  width: fit-content;
  margin: 0 0 0 auto;

  > svg {
    font-size: 18px;
  }

  #button-label {
    opacity: 0;
  }
`;

export const ExpiryDate = styled.div`
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 18px;
  text-decoration: underline;
  color: ${commonPalette.txGrey};
  margin: 10px 0;
  cursor: default;
`;

export const StyledHistoryIcon = styled(Icon)`
  color: ${commonPalette.txLightGrey};
  margin-bottom: 5px;
  font-size: 16px;
`;

export const ChecklistIcon = styled(Icon).attrs({
  name: 'checklist',
  size: 38
})`
  margin: -6px 10px -6px -10px;
`;
