import {observable, action, computed, makeObservable} from 'mobx';
import moment from 'moment';
import views from 'config/views';

//lodash
import {get} from 'lodash';

//helpers
import store from 'stores/store';
import notification from 'utils/notification-utils';
import {checkUniqueSlug} from 'utils/validation-utils';
import {dateHasPassed} from 'utils/data-utils';

class PublishGuideDialog {
  @observable opened = false;
  @observable publishMutation = null;
  @observable unpublishMutation = null;
  @observable approveAndPublishMutation = null;
  @observable domains = [];
  @observable visibleToValues = [];
  @observable expiryDate;
  @observable publishing = false;
  translations = null;
  guide = null;
  guideId = null;
  guideSlug = null;

  @action
  reset = () => {
    this.opened = false;
    this.publishMutation = null;
    this.unpublishMutation = null;
    this.approveAndPublishMutation = null;
    this.translations = null;
    this.guideId = null;
    this.guideSlug = null;
    this.guide = null;
    this.domains = [];
    this.visibleToValues = [];
  };

  @action
  setDomains = domains => (this.domains = domains);

  @action
  setVisibleToValues = (domains = []) => {
    if (!this.canChangeVisibleTo && !this.domains.length) {
      this.domains = domains;
    }
    this.visibleToValues = domains.map(domain => domain.id);
  };

  @action
  setExpiryDate = value => {
    this.expiryDate = value;
  };

  @action
  setPublishing = value => {
    this.publishing = value;
  };

  @action
  open = ({publishMutation, approveAndPublishMutation, unpublishMutation, translations = {}, guide, guideSlug}) => {
    this.opened = true;
    this.publishMutation = publishMutation;
    this.unpublishMutation = unpublishMutation;
    this.approveAndPublishMutation = approveAndPublishMutation;
    this.translations = {...this.translations, ...translations};
    this.guide = guide;
    this.guideId = guide && guide.id;
    this.guideSlug = guideSlug;
    this.expiryDate = this.getDefaultDateValue(guide);
  };

  @action
  updateVisibleTo = values => {
    if (!values.length) {
      return;
    }
    this.visibleToValues = values;
  };

  publishGuideSuccess = () => {
    this.setPublishing(false);
    notification.success(
      store.platform?.hasDispatchDocumentsSyncEnabled
        ? this.translations.guidePublishedDocumentsMessage
        : this.translations.guidePublishedMessage
    );
    this.opened = false;
  };

  approveAndPublishGuideSuccess = isViewingLiveVersionUnderApproval => {
    this.setPublishing(false);
    const successNotificationMessage = store.platform?.hasDispatchDocumentsSyncEnabled
      ? this.translations.guidePublishedDocumentsMessage
      : this.translations.guidePublishedMessage;
    notification.success(
      isViewingLiveVersionUnderApproval ? this.translations.guideUpdatedMessage : successNotificationMessage
    );
    store.router.goTo(views.guides, {});
    this.opened = false;
  };

  publishGuideFailure = e => {
    const {graphQLErrors} = e;
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.extensions.code === 'MAX_PUBLISHED_GUIDES') {
          return notification.error(this.translations.publishGuideFailureMaxPublishedGuides);
        }
        if (err.extensions.code === 'FORBIDDEN') {
          return notification.error(this.translations.publishGuideFailureForbidden);
        }
      }
    }
    notification.error(this.translations.unknownFailure);
    this.setPublishing(false);
    this.opened = false;
  };

  @action
  submitGuideApprovalAndPublish = () => {
    this.setPublishing(true);
    const {canPublish, canSubmitFinalLiveVersionApproval} = this.guide;
    const {
      editGuidePage: {isViewingLiveVersionUnderApproval}
    } = store;

    if ((canPublish || canSubmitFinalLiveVersionApproval) && this.approveAndPublishMutation) {
      const review = {approved: true};

      checkUniqueSlug(
        this.guideSlug,
        (isValidSlug, error) => {
          if (isValidSlug) {
            this.approveAndPublishMutation({
              id: this.guideId,
              review,
              domains: this.visibleToValues,
              expiryDate: this.expiryDate ? this.expiryDate.toDate() : null
            }).then(
              () => this.approveAndPublishGuideSuccess(isViewingLiveVersionUnderApproval),
              this.publishGuideFailure
            );
          } else {
            notification.error(error);
            this.reset();
          }
        },
        'guide',
        this.guideId
      );
    }
  };

  @action
  publishGuide = () => {
    this.setPublishing(true);
    const id = get(store.router, 'params.id', null);
    const versionId = get(store.router, 'queryParams.v', null);
    const guideId = this.guideId || id;

    checkUniqueSlug(
      this.guideSlug,
      (isValidSlug, error) => {
        if (isValidSlug) {
          this.publishMutation({
            id: guideId,
            versionId,
            domains: this.visibleToValues
          }).then(this.publishGuideSuccess, this.publishGuideFailure);
        } else {
          notification.error(error);
          this.reset();
        }
      },
      'guide',
      guideId
    );
  };

  @action
  unpublishGuide = () => {
    const unpublishGuideSuccess = () => {
      notification.success(this.translations.guideUnpublishedMessage);
      this.opened = false;
    };
    const unpublishGuideFailure = () => {
      notification.error(this.translations.unknownFailure);
      this.opened = false;
    };
    const id = get(store.router, 'params.id', null);
    const guideId = this.guideId || id;
    this.unpublishMutation({id: guideId}).then(unpublishGuideSuccess, unpublishGuideFailure);
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get canChangeVisibleTo() {
    return this.guide && this.guide.canChangeVisibleTo;
  }

  @computed
  get visibleToNames() {
    if (!this.visibleToValues.length || !this.domains.length) return [];
    return this.visibleToValues.map(domainId => {
      const domain = this.domains.find(domain => domain.id === domainId);
      return domain.name;
    });
  }

  @computed
  get mode() {
    if (this.publishMutation) {
      return 'PUBLISH';
    } else if (this.unpublishMutation) {
      return 'UNPUBLISH';
    } else if (this.approveAndPublishMutation) {
      return 'APPROVE_AND_PUBLISH';
    }
    return null;
  }

  @computed
  get isDraftVersion() {
    return !get(store.router, 'queryParams.v', false);
  }

  @computed
  get canPublish() {
    const {hasMultiDomainEnabled} = store.platform;
    return !hasMultiDomainEnabled || (hasMultiDomainEnabled && !!this.visibleToValues.length);
  }

  @computed
  get canSetReminder() {
    return (
      this.canPublish &&
      this.approveAndPublishMutation &&
      this.guide.ownedBy.guideApproval &&
      !!this.guide.ownedBy.reminderCycle
    );
  }

  @action
  getDefaultDateValue = (guide = this.guide) => {
    if (!this.canSetReminder) {
      return null;
    }
    if (guide && guide.expiryDate && !dateHasPassed(guide.expiryDate)) {
      return moment(guide.expiryDate);
    }
    return moment().add(guide.ownedBy.reminderCycle, 'y');
  };
}

export default PublishGuideDialog;
