import {action, observable, makeObservable} from 'mobx';
import {SkillProfileType} from 'components/SkillProfilesDialog/types';

class SkillProfilesDialog {
  @observable opened = false;
  @observable skillName = '';
  @observable skillProfiles: SkillProfileType[] = [];

  constructor() {
    makeObservable(this);
  }

  @action open = (skillName, skillProfiles) => {
    this.opened = true;
    this.skillName = skillName;
    this.skillProfiles = skillProfiles;
  };

  @action close = () => {
    this.opened = false;
    this.skillName = '';
    this.skillProfiles = [];
  };
}

export default SkillProfilesDialog;
