import {action, computed, observable, makeObservable} from 'mobx';
import notification from 'utils/notification-utils';

export default class ShareDialog {
  @observable opened = false;
  @observable path = null;
  @observable qrBasename = null;
  @observable title = null;
  @observable selectedDomain = null;
  @observable origin = window.location.origin.replace('3033', '3000');
  @observable domains = [];

  @action
  setDomains = domains => {
    this.domains = domains;
    if (this.domains && this.domains.length) {
      this.setSelectedDomain(domains[0].id);
      this.setOrigin(domains[0]);
    }
  };

  @action
  open = ({path, qrBasename, title, itemTitle, type, guide}) => {
    this.guide = guide;
    this.path = path;
    this.qrBasename = qrBasename;
    this.title = title;
    this.itemTitle = itemTitle;
    this.opened = true;
    this.type = type;
  };

  @action
  reset = () => {
    this.opened = false;
    this.path = null;
    this.qrBasename = null;
    this.title = null;
    this.guide = null;
  };

  @action
  setSelectedDomain = domainId => {
    this.selectedDomain = this.domains.find(domain => domain.id === domainId);
    this.setOrigin(this.selectedDomain);
  };

  @action
  setOrigin = domain => {
    if (domain.isDefault) {
      return (this.origin = window.location.origin.replace('3033', '3000').replace('http://', 'https://'));
    }

    const hostParts = window.location.host.split('.');
    hostParts[0] = `https://${domain.slug}-${hostParts[0]}`;
    this.origin = hostParts.join('.').replace('3033', '3000');
  };

  @action
  shareToBeekeeper = ({mutation, translations}) => {
    const vars = {guideId: this.guide.id, domainSlug: this.selectedDomain.slug};
    if (this.instruction) {
      vars.instructionId = this.instruction.id;
    }
    mutation(vars)
      .then(
        ({
          data: {
            shareToBeekeeper: {result}
          }
        }) => {
          if (result === true) {
            notification.success(translations.success);
          } else {
            notification.error(translations.error);
          }
        }
      )
      .catch(() => {
        notification.error(translations.error);
      });
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get embedCode() {
    const onLoad =
      "!this.src && (this.src=this.getAttribute('data-swipeguide-src') + '&embed_referer='+encodeURIComponent(document.location));";
    return `<iframe width="736" height="420" onload="${onLoad}" data-swipeguide-src="${this.embedUrl}"></iframe>`;
  }

  @computed
  get embedUrl() {
    return `${this.origin}/embed/${this.path}?embed=true`;
  }

  @computed
  get qrCodeFilename() {
    return `${this.qrBasename}.png`;
  }

  @computed
  get qrCodeUrl() {
    return `${this.origin}/qr/${this.path}`;
  }

  @computed
  get shareUrl() {
    return `${this.origin}/share/${this.path}`;
  }
}
