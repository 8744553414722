import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {styleable} from 'shared/decorators';

//styles
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Popover} from 'antd';
import PropTypes from 'prop-types';

//helpers
import {icons} from 'styles/icons';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';

@observer
@styleable
class FontAwesomeIconComponent extends Component {
  static defaultProps = {
    solid: true,
    shouldSpin: false
  };

  static propTypes = {
    dataCy: PropTypes.string,
    iconId: PropTypes.string,
    popoverContent: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    popoverTitle: PropTypes.string,
    popoverPlacement: PropTypes.string,
    solid: PropTypes.bool,
    style: PropTypes.object,
    shouldSpin: PropTypes.bool,
    showDash: PropTypes.bool
  };

  resolveActionToIcon = iconId => {
    const icon = icons.find(i => i.action === iconId);
    return icon && icon.name;
  };

  render() {
    const {
      dataCy,
      iconId,
      popoverTitle,
      popoverContent,
      popoverPlacement,
      solid,
      style,
      shouldSpin,
      showDash = true,
      popoverComponent
    } = this.props;
    const type = solid ? 'fas' : 'far';
    let content;
    const iconName =
      !iconId && (popoverTitle || popoverContent || popoverComponent)
        ? this.resolveActionToIcon('info')
        : this.resolveActionToIcon(iconId);

    if (!iconName) {
      console.log(
        `No icon found for "${iconId}". \nThe available iconIds are: ${icons.map(
          i => `\nid: ${i.action} - fontAwesome icon: ${i.name}`
        )}`
      );
      return null;
    }

    if (popoverTitle || popoverContent || popoverComponent) {
      if (popoverContent && isString(popoverContent)) content = popoverContent;
      else if (popoverContent && isArray(popoverContent)) {
        content = popoverContent.map((item, key) => {
          return (
            <p key={item.id}>
              {showDash ? '-' : ''} {item}
            </p>
          );
        });
      }

      if (popoverComponent) {
        content = popoverComponent;
      }

      return (
        <Popover title={popoverTitle} content={content} arrowPointAtCenter placement={popoverPlacement}>
          <FontAwesomeIcon data-cy={dataCy} icon={[type, iconName]} style={style} />
        </Popover>
      );
    }

    return <FontAwesomeIcon data-cy={dataCy} icon={[type, iconName]} pulse={shouldSpin} />;
  }
}

export default FontAwesomeIconComponent;
