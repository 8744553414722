import React, {useState} from 'react';

import {
  DropdownToggle,
  DropwdownItem,
  DropdownList,
  DropwdownActions,
  StyledApplyButton,
  StyledResetButton,
  FilterIcon
} from './styles';
import Dropdown from 'ui-components/Dropdown';
import messages from './messages';

const DropdownMenu = props => {
  const {formatMessage, onApply, onReset} = props;
  return (
    <DropdownList>
      {props.children}
      <DropwdownItem>
        <DropwdownActions>
          <StyledApplyButton label={formatMessage(messages.apply)} onClick={onApply} />
          <StyledResetButton label={formatMessage(messages.resetFilters)} onClick={onReset} />
        </DropwdownActions>
      </DropwdownItem>
    </DropdownList>
  );
};

export const Filters = props => {
  const [open, setOpen] = useState(false);
  const {filtersTitle, totalFiltersApplied} = props;

  return (
    <Dropdown
      width={open ? 300 : 90}
      visible={open}
      open={open}
      trigger="click"
      overlayClassName="c-header__popover__inner"
      toggle={() => setOpen(!open)}
      content={<DropdownMenu {...props} />}
      dropdownStyle={open ? {overflow: 'visible', maxHeight: 'max-content', zIndex: '11'} : {overflow: 'auto'}}
    >
      <DropdownToggle totalFiltersApplied={totalFiltersApplied} open={open}>
        <FilterIcon />
        {filtersTitle}
        {totalFiltersApplied ? ` (${totalFiltersApplied})` : ''}
      </DropdownToggle>
    </Dropdown>
  );
};
