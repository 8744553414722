import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {DropdownMenu, DropdownMenuButton, DropdownMenuItem} from 'styles/styled-components/dropdown-styled-components';

//messages
import messages from './messages';
import views from 'config/views';

@inject('store')
@observer
class SkillsProfileMenuComponent extends Component {
  edit = () => {
    const {store, skillProfileId} = this.props;
    store.router.goTo(views.skillProfilesManage, {id: skillProfileId});
  };

  render() {
    return (
      <DropdownMenu {...this.props}>
        <DropdownMenuItem>
          <DropdownMenuButton onClick={this.edit}>
            <FormattedMessage {...messages.edit} />
          </DropdownMenuButton>
        </DropdownMenuItem>
      </DropdownMenu>
    );
  }
}

export default injectIntl(SkillsProfileMenuComponent);
