import {defineMessages} from 'react-intl';

const messages = defineMessages({
  editDetails: {
    id: 'DetailsView.editDetails',
    defaultMessage: 'Edit details'
  },
  setAsDraft: {
    id: 'EditGuide.setAsDraft',
    defaultMessage: 'Set as draft'
  },
  unpublish: {
    id: 'EditGuide.unpublish',
    defaultMessage: 'Unpublish'
  },
  requestApproval: {
    id: 'EditGuide.requestApproval',
    defaultMessage: 'Request Approval'
  },
  cancelApproval: {
    id: 'EditGuide.cancelApproval',
    defaultMessage: 'Cancel Approval Request'
  },
  errorCancelApproval: {
    id: 'EditGuide.errorCancelApproval',
    defaultMessage: 'Error cancelling approval request'
  },
  successCancelApproval: {
    id: 'EditGuide.successCancelApproval',
    defaultMessage: 'Approval Request cancelled successfully'
  },
  approve: {
    id: 'EditGuide.approve',
    defaultMessage: 'Approve'
  },
  reject: {
    id: 'EditGuide.reject',
    defaultMessage: 'Reject this Version'
  },
  approveAndPublish: {
    id: 'EditGuide.approveAndPublish',
    defaultMessage: 'Approve & Publish'
  },
  requestApprovalSuccess: {
    id: 'EditGuide.requestApprovalSuccess',
    defaultMessage: 'Request approval submitted successfully'
  },
  requestApprovalFailure: {
    id: 'EditGuide.requestApprovalFailure',
    defaultMessage: 'Failed to submit request approval'
  },
  reviewReminder: {
    id: 'EditGuide.reviewReminder',
    defaultMessage: 'Review reminder'
  },
  pendingApproval: {
    id: 'pendingApproval',
    defaultMessage: 'Pending approval. You will receive an email when it is your turn to approve this version.'
  }
});

export default messages;
