import {defineMessages} from 'react-intl';

const messages = defineMessages({
  instructions: {
    id: 'EditGuide.defaultTopic',
    defaultMessage: 'Instructions'
  },
  instructionCount: {
    id: 'EditGuide.instructionCount',
    defaultMessage: '{count, number} {count, plural, one {instruction} other {instructions}}'
  },
  visibleCount: {
    id: 'GuideCard.visibleCount',
    defaultMessage: '{count} visible'
  },
  defaultLanguageMessage: {
    id: 'TranslateTagsView.defaultLanguage',
    defaultMessage: 'Default language'
  },
  translatedIn: {
    id: 'translatedIn',
    defaultMessage: 'Translated in'
  },
  team: {
    id: 'GuideCard.team',
    defaultMessage: 'Team'
  },
  assignedTo: {
    id: 'GuideCard.assignedTo',
    defaultMessage: 'Assigned to'
  },
  createdAt: {
    id: 'createdAt',
    defaultMessage: 'Created at'
  },
  lastEditAt: {
    id: 'lastEditAt',
    defaultMessage: 'Last edit at'
  },
  lastPublishAt: {
    id: 'lastPublishAt',
    defaultMessage: 'Last published at'
  },
  createdByMessage: {
    id: 'createdBy',
    defaultMessage: 'Created by'
  },
  visibleToMessage: {
    id: 'EditGuide.visibleTo',
    defaultMessage: 'Visible to'
  },
  errorDetailsGuide: {
    id: 'errorDetailsGuide',
    defaultMessage: "Unfortunately, we are unable to fetch the guide's details."
  },
  originalParent: {
    id: 'originalParent',
    defaultMessage: 'Original (parent)'
  },
  version: {
    id: 'Pdf.Version',
    defaultMessage: 'Version'
  },
  expirationDate: {
    id: 'expirationDate',
    defaultMessage: 'Expiration Date'
  }
});

export default messages;
