import React from 'react';
import {truncate} from 'lodash';
import {InjectedIntl, injectIntl} from 'react-intl';

import type Store from 'stores/store';
import views from 'config/views';
import {FormattedMessage} from 'components/FormattedComponents';

import {getEventConfig, getExtraFieldForGuideEventType, incrementNumber, shouldShowViewVersionLink} from './../utils';
import messages from './../messages';
import {FlexRow, EventHeader, LinkSection, EventWrapper, AsideButton} from './../styles';
import {Person} from './../Person';
import {ActivityEvent, Person as PersonType} from '../types';

export type GuideEventProps = {
  activity: ActivityEvent;
  intl: InjectedIntl;
  store: typeof Store;
  Chevron: () => React.JSX.Element;
  TimeAndDate: () => React.JSX.Element;
  Info: (props: any) => React.JSX.Element;
};

const GuideEvent = (props: GuideEventProps) => {
  const {
    activity,
    intl: {formatMessage},
    store,
    Chevron,
    TimeAndDate,
    Info
  } = props;

  const {
    eventType,
    versionId,
    guideId,
    topicTitle,
    instructionTitle,
    parentGuide,
    reviewers,
    approvers,
    beforeVersion,
    tags,
    guide,
    publishedVersion,
    translations,
    before,
    after,
    afterPosition
  } = activity;

  const extraField = getExtraFieldForGuideEventType(eventType);
  const hasExtraField = extraField ? !!activity[extraField] : false;
  const eventConfig = getEventConfig(eventType, hasExtraField);

  const showViewVersionButton = guideId && versionId && shouldShowViewVersionLink(eventType);
  const instructionTitleDisplayText = truncate(instructionTitle, {length: 300});
  const versionTitle = publishedVersion?.title;

  const viewInGuide = versionId => {
    store.router.goTo(views.editGuide, {id: activity.guideId}, {v: versionId});
  };

  return (
    <FlexRow>
      <Chevron />
      <EventWrapper>
        <EventHeader>
          <TimeAndDate />
          <LinkSection>
            {showViewVersionButton && (
              <AsideButton
                onClick={() => viewInGuide(versionId)}
                params={{id: guideId}}
                queryParams={{v: versionId}}
                route={views.editGuide}
                store={store}
                label={formatMessage(versionTitle ? messages.viewVersionV2 : messages.viewVersion, {versionTitle})}
                underlined
              />
            )}
          </LinkSection>
        </EventHeader>
        <Info>
          <FormattedMessage
            {...eventConfig.message}
            values={{
              person: <Person person={activity.producedBy} />,
              parentGuideTitle: parentGuide && truncate(parentGuide.title, {length: 300}),
              beforeVersionTitle: beforeVersion && truncate(beforeVersion.title, {length: 300}),
              assignee: <Person person={activity.newAssignee as PersonType} />,
              instructionTitle: instructionTitleDisplayText,
              topicTitle: topicTitle ? truncate(topicTitle, {length: 300}) : formatMessage(messages.defaultTopic),
              guide,
              alertRecipientsCount: activity.count,
              beforeVersion: beforeVersion?.title ?? '',
              reviewers: reviewers?.length,
              approvers: approvers?.length,
              tags: tags?.length,
              language: translations?.[0], // there can only be one for now
              before,
              after,
              afterPosition: incrementNumber(afterPosition)
            }}
          />
        </Info>
      </EventWrapper>
    </FlexRow>
  );
};

export default injectIntl(GuideEvent);
