import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {ListContainer, ListGroup, ListGroupTitleItem} from 'components/SkillProfileRolesDrawer/styles';
import messages from './messages';
import {SkillListTopBar, StyledButton, StyledButtonHover, RequiredLevelLabelWrapper} from './styles';
import RequiredLevelLabel from 'ui-components/RequiredLevelLabel';
import Icon from 'ui-components/Icon';

@inject('store')
@observer
class SkillProfilesSelectedSkills extends Component {
  getRequiredLevel = id => {
    const {
      store: {
        skillProfilePage: {availableSkillLevels}
      }
    } = this.props;

    return availableSkillLevels?.find(level => level.id === id);
  };

  /**
   * Clear all selected skills from the store.
   */
  clearSelectedSkills = () => {
    const {
      store: {
        skillProfilePage: {setSelectedSkills}
      }
    } = this.props;

    setSelectedSkills([]);
  };

  /**
   * Delete role from the team.
   *
   * @param {string} skillId
   */
  handleRemoveClick = skillId => {
    const {
      store: {
        skillProfilePage: {setSelectedSkills, selectedSkills}
      }
    } = this.props;

    setSelectedSkills(selectedSkills.filter(item => item.id !== skillId));
  };

  skillItem = ({id, name, requiredLevelId}) => {
    const {
      store: {
        skillProfilePage: {availableSkillLevels, shouldSkillsHaveRequiredLevel}
      }
    } = this.props;

    return (
      <ListGroup key={id}>
        <ListGroupTitleItem>
          <p style={{fontSize: '9pt', width: '60%'}}>{name}</p>
          {shouldSkillsHaveRequiredLevel && (
            <RequiredLevelLabelWrapper>
              <RequiredLevelLabel
                mode={requiredLevelId ? 'edit' : 'create'}
                requiredLevel={this.getRequiredLevel(requiredLevelId)}
                levels={availableSkillLevels}
                skillId={id}
                skillName={name}
              />
            </RequiredLevelLabelWrapper>
          )}
          <StyledButtonHover onClick={() => this.handleRemoveClick(id)} iconId="remove" />
        </ListGroupTitleItem>
      </ListGroup>
    );
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        skillProfilePage: {shouldSkillsHaveRequiredLevel, selectedSkills, availableSkillLevels}
      }
    } = this.props;

    const skillsNum = selectedSkills?.length;

    if (!skillsNum) {
      return '...';
    }

    const formattedSkillLevels = availableSkillLevels?.map(
      level => `${level.position} - ${level.name}${level.description ? ' - ' + level.description : ''}`
    );

    const popoverContent = formattedSkillLevels || formatMessage(messages.unableToLoadSkillLevels);

    return (
      <>
        <SkillListTopBar>
          <div style={{width: '60%'}}>
            {skillsNum} {formatMessage(skillsNum > 1 ? messages.skills : messages.skill)}
          </div>
          {shouldSkillsHaveRequiredLevel && (
            <div>
              {formatMessage(messages.requiredLevel)}
              <Icon
                iconId="info"
                popoverContent={popoverContent}
                popoverTitle="Level details:"
                showDash={false}
                style={{marginLeft: '4px'}}
              />
            </div>
          )}
          <StyledButton onClick={() => this.clearSelectedSkills()} label={formatMessage(messages.removeAll)} />
        </SkillListTopBar>
        <ListContainer>{selectedSkills.map(item => this.skillItem(item, shouldSkillsHaveRequiredLevel))}</ListContainer>
      </>
    );
  }
}

export default injectIntl(SkillProfilesSelectedSkills);
