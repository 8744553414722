import React, {useCallback} from 'react';
import {StyledLink} from './../styles';
import {injectIntl} from 'react-intl';
import notification from 'utils/notification-utils';
import messages from './../messages';
import views from 'config/views';

export const ActivityLogLink = injectIntl(
  ({router, route, params, queryParams, validateLink, children, intl: {formatMessage}}: any) => {
    const [isValidating, setIsValidating] = React.useState(false);
    const handleClick = useCallback(
      async e => {
        setIsValidating(true);
        e.preventDefault();
        const isValid = validateLink ? await validateLink(e) : true;
        setIsValidating(false);
        if (!isValid) {
          notification.error(formatMessage(messages.itemNoLongerAvailable));
          return;
        }

        router.goTo(route, params, queryParams);
      },
      [validateLink, router, route, params, queryParams]
    );

    return (
      <StyledLink
        href={views.editInstruction.replaceUrlParams(params, queryParams)}
        onClick={handleClick}
        disabled={isValidating}
      >
        {children}
      </StyledLink>
    );
  }
);
