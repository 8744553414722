import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';

// components
import ReassignGuide from 'components/ReassignGuide';
import Checkbox from 'components/Checkbox';
import Spinner from 'shared/components/Spinner';

//data
import {TeamWithGuideCount, TeamsPaginatedWithMembers} from 'api/team/queries';
import {teamsPaginatedOptions, teamWithGuideCountOptions} from 'api/team/query-options';

//styled-components
import {ReassignWrapper} from './styles';

//messages
import messages from './messages';

@inject('store')
@observer
@graphql(TeamsPaginatedWithMembers, teamsPaginatedOptions)
@graphql(TeamWithGuideCount, teamWithGuideCountOptions)
class ArchiveTeamReassign extends Component {
  state = {
    reassignTeamContent: false
  };

  componentDidUpdate(prevProps) {
    const {
      store,
      teamsPaginatedQuery: {teamsPaginated, loading},
      teamWithGuideCountQuery
    } = this.props;
    const {team} = teamWithGuideCountQuery;
    const {reassignGuideDialog} = store;

    if (prevProps.teamsPaginatedQuery.loading && teamsPaginated && !loading) {
      if (teamsPaginated.offset === 0) {
        reassignGuideDialog.setTeams(teamsPaginated.results, [], teamsPaginated.nextOffset !== null);
      } else {
        reassignGuideDialog.pushTeams(teamsPaginated.results, [], teamsPaginated.nextOffset !== null);
      }
      reassignGuideDialog.setLoading(false);
      this.setState({loading: false});
    }

    const hasTeamsToReassign = reassignGuideDialog.teams.length > 0;
    const hasContentToReassign = team?.guideCount > 0;

    if ((!hasTeamsToReassign || !hasContentToReassign) && reassignGuideDialog.reassignTeamContent) {
      this.onToggleReassignTeamContent(false);
    }
  }

  onToggleReassignTeamContent = setTo => {
    const nextValue = setTo !== undefined ? setTo : !this.state.reassignTeamContent;
    const {
      reassignGuideDialog: {toggleReassignTeamContent}
    } = this.props.store;
    this.setState({reassignTeamContent: nextValue});
    toggleReassignTeamContent(nextValue);
  };

  onScrollToBottom = () => {
    const {teamsPaginatedQuery, store} = this.props;
    store.reassignGuideDialog.onScrollToBottom(teamsPaginatedQuery);
  };

  onSearch = value => {
    const {reassignGuideDialog} = this.props.store;
    reassignGuideDialog.setSearchTerm(value);
  };

  componentWillUnmount() {
    const {reassignGuideDialog} = this.props.store;
    reassignGuideDialog.resetPagination();
  }

  render() {
    const {intl, store, teamWithGuideCountQuery} = this.props;

    const {team, loading: teamWithGuideCountLoading} = teamWithGuideCountQuery;
    if (teamWithGuideCountLoading) {
      return <Spinner />;
    }

    const {formatMessage} = intl;
    const {
      reassignGuideDialog: {teams, reassignTeamContent}
    } = store;

    const hasTeamsToReassign = teams.length > 0;
    const hasContentToReassign = team.guideCount > 0;
    const isManagementTeamOfADomain = team.isManagementTeam;
    return (
      <ReassignWrapper data-cy="reassign-team-content">
        <Checkbox
          disabled={!hasTeamsToReassign || !hasContentToReassign || isManagementTeamOfADomain}
          type="checkbox"
          caption={formatMessage(messages.reassignTeamContent)}
          checked={reassignTeamContent}
          onChange={() => this.onToggleReassignTeamContent()}
        />
        {reassignTeamContent && (
          <ReassignGuide
            comment={`Team '${team.name}' archived`}
            style={{paddingTop: 30}}
            onScrollToBottom={this.onScrollToBottom}
            onSearch={this.onSearch}
          />
        )}
      </ReassignWrapper>
    );
  }
}

export default injectIntl(ArchiveTeamReassign);
