import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  saveChanges: {
    id: 'common.actions.saveChanges',
    defaultMessage: 'Save Changes'
  },
  skillLevelName: {
    id: 'skills.skillLevelName',
    defaultMessage: 'Level Name'
  },
  skillLevelDescription: {
    id: 'skills.skillLevelDescription',
    defaultMessage: 'Level Meaning'
  },
  skillLevelDescriptionExplanation: {
    id: 'skills.skillLevelDescriptionExplanation',
    defaultMessage: 'Short description of the skill level. This information is visible to all users.'
  },
  errorNameAlreadyUsed: {
    id: 'skills.errorNameAlreadyUsed',
    defaultMessage: "Pick a name that isn't already used"
  },
  errorNameAlreadyUsedNotification: {
    id: 'skills.errorNameAlreadyUsedNotification',
    defaultMessage: 'A Skill Level with this name already exists'
  },
  errorUpdatingSkill: {
    id: 'skills.errorUpdatingSkill',
    defaultMessage: 'Error updating the Skill Level'
  },
  skillUpdatedSuccessfully: {
    id: 'skills.skillUpdatedSuccessfullyUpdated',
    defaultMessage: 'Successfully updated Skill Level changes'
  },
  characterLimit: {
    id: 'characterLimit',
    defaultMessage: 'Please stay within the character limit'
  }
});

export default messages;
