import {Team, Teams, AllParentTeams} from 'api/team/queries';

export const refreshTeam = id => {
  return (
    id && {
      query: Team,
      variables: {
        id
      }
    }
  );
};

export const refreshTeams = () => ({
  query: Teams
});

export const refreshAllParentTeams = () => ({
  query: AllParentTeams
});

// by refreshing with the hardcoded string, it refreshes all active queries without requiring extra input
export const refreshAllTeamChildren = () => 'allChildrenTeamByParentId';
