import styled from 'styled-components';

//helpers
import typefaces from 'shared/styles/typefaces';

//components
import $UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';
import $MediaPicker from 'components/MediaPicker';
import $Slug from 'components/Slug';
import $CloudinaryPicture from 'shared/components/CloudinaryPicture';

export const GuideDetails = styled.div`
  position: relative;
  z-index: 1;
  min-height: 200px;

  width: 100%;
  display: table; /* fix for the parent to get the height of child */

  .media-picker-label {
    opacity: 0;
  }

  &:hover {
    ${() => StyledMediaPicker} {
      box-sizing: content-box;

      & > svg {
        border-radius: 2px 0px 0px 2px;
      }

      .media-picker-label {
        box-sizing: content-box;
        background-color: white;
        opacity: 0.75;
        padding: 3px 5px;
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
`;

export const BackgroundImage = styled($CloudinaryPicture)`
  position: absolute;
  height: ${p => (p.cardHeight ? p.cardHeight / 2 + 118 : 200)}px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 50px;
  @media (max-width: 720px) {
    padding: 0 20px;
    width: 100%;
  }
`;

export const StyledMediaPicker = styled($MediaPicker)`
  flex-shrink: 0;
  margin-top: 20px;
  margin-bottom: 70px;
  justify-content: flex-start;
  opacity: 1;

  & > svg {
    background-color: white;
    padding: 3px;
    border-radius: 2px;
    opacity: 0.75;
    box-sizing: content-box;
    align-self: flex-start;
    margin: 0;
  }
`;

export const StyledTitleUnderlinedTextbox = styled($UnderlinedTextbox)`
  ${typefaces.guideDetails.title};
`;

export const StyledSlug = styled($Slug)`
  ${typefaces.guideDetails.slug};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  right: 18%;
  bottom: -60px;
  margin-left: 100px;
`;
