import {observable, action, computed, makeObservable} from 'mobx';
import store from 'stores/store';
import views from 'config/views';

//lodash
import omit from 'lodash/omit';

//form
import NewGuideForm from 'stores/forms/new-guide-form';

import {sanitizeSimpleObject} from 'utils/object-utils';

class NewGuidePage {
  form = new NewGuideForm();
  editMode = true;
  createGuide = true;
  @observable guideCoverUrl;
  @observable metadata;
  @observable loading = false;
  @observable selectedTagsIds = [];
  @observable newTags = [];
  @observable teams = [];
  @action setGuideCoverUrl = url => (this.guideCoverUrl = url);
  @action setGuideCoverMetadata = metadata => (this.metadata = metadata);
  @action setLoading = val => (this.loading = val);

  @action
  setDefaultLocale = locale => {
    this.form.update({
      defaultLocale: locale
    });
  };

  @action
  setTeams = teams => (this.teams = teams);

  @action
  submit = createGuideMutation => {
    this.setLoading(true);
    let guide = this.form.values();
    guide.tags = this.selectedTagsIds.filter(t => !t.includes('new'));
    guide.newTags = this.newTagsForSubmit;
    if (guide.mediaId === '') {
      guide = omit(guide, ['mediaId']);
    }
    guide = sanitizeSimpleObject(guide, ['title']);

    createGuideMutation({guide}).then(({data}) => {
      this.reset();
      store.router.goTo(views.editGuide, {id: data.createGuide.id});
    });
  };

  @action
  reset = () => {
    this.form.reset();
    this.guideCoverUrl = null;
    this.metadata = null;
    this.loading = false;
    this.newTags = [];
    this.selectedTagsIds = [];
  };

  @action
  cancel = () => {
    store.router.goTo(views.guides, {});
  };

  @action
  setSelectedTags = (tags, newTag) => {
    this.selectedTagsIds = tags;
    if (newTag) {
      this.newTags.push(newTag);
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get saveButtonEnabled() {
    return this.loading === false && this.form.isValid;
  }

  @computed
  get defaultLocale() {
    return this.form.$('defaultLocale').value;
  }

  @computed
  get newTagsForSubmit() {
    return this.newTags.filter(tag => this.selectedTagsIds.includes(tag.id)).map(tag => omit(tag, 'id'));
  }
}

export default NewGuidePage;
