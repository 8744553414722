import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {topicCardMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';

//components
import $Button from 'ui-components/Button';

//constants
const ghostlyGutter = 10;
const ghostlyHeight = 100;
const ghostlyMarginX = topicCardMetrics.dragHandleWidth + topicCardMetrics.innerPaddingX;
const paddingBottom = 60;

export const TopicList = styled.div`
  ${flex.vertical};
  flex-grow: 1;
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;
  padding: 0px 50px 50px 50px;
  margin-top: 40px;

  ${p =>
    p.isEmpty &&
    `
    padding-left: ${ghostlyMarginX}px;
    padding-right: ${ghostlyMarginX}px;
  `}

  @media (max-width: 720px) {
    padding: 0px 20px 50px 20px;
  }
`;

export const HugeCaption = styled.p`
  font-size: 46px;
  font-weight: 300;
  margin: 20px 0;
`;

export const StyledButton = styled($Button)`
  align-self: flex-start;
  margin-right: 20px;
  @media (max-width: 530px) {
    font-size: 10px;
  }
  @media (max-width: 435px) {
    font-size: 9px;
  }
`;

/**
 * Draws gray rectangles using linear-gradient() and blurs them.
 * The rectangles are infinitively repeated and are only limited by
 * the height of the element.
 */
export const GhostlyInstructionList = styled.div`
  align-self: stretch; // IE11 need this for the element to have width
  background-image: linear-gradient(${commonPalette.darkestGray} ${ghostlyHeight}px, transparent ${ghostlyHeight}px);
  background-size: 100% ${ghostlyHeight + ghostlyGutter}px;
  filter: blur(2px);
  flex-grow: 1;
  margin: 100px 0 ${-paddingBottom}px;
  padding: 0 50px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
`;
