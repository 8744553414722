import gql from 'graphql-tag';

export const AssessUserSkill = gql`
  mutation assessUserSkill(
    $userId: String!
    $skillId: String!
    $approved: Boolean!
    $feedback: String
    $updatedLevelId: String!
  ) {
    assessUserSkill(
      userId: $userId
      skillId: $skillId
      approved: $approved
      feedback: $feedback
      updatedLevelId: $updatedLevelId
    ) {
      result
      error
    }
  }
`;

export const SetSkillAssessmentLevel = gql`
  mutation setSkillAssessmentLevel($userId: String!, $teamId: String!, $skillId: String!, $updatedLevelId: String!) {
    setSkillAssessmentLevel(userId: $userId, teamId: $teamId, skillId: $skillId, updatedLevelId: $updatedLevelId) {
      result
      error
    }
  }
`;
