import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//components
import Dialog from 'ui-components/Dialog';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

//constants
import {ROOT_PATH} from 'config/constants';

@inject('store')
@observer
class SessionExpiredDialogComponent extends Component {
  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {sessionExpiredDialog: dialog} = store;

    return (
      <Dialog
        actions={() => [
          {
            label: formatMessage(messages.login),
            onClick: () => {
              window.location.href = `${window.location.origin}${ROOT_PATH}`;
            }
          }
        ]}
        isOpen={dialog.opened}
        title={formatMessage(messages.title)}
      >
        <FormattedMessage {...messages.info} />
      </Dialog>
    );
  }
}

export default injectIntl(SessionExpiredDialogComponent);
