import React from 'react';
import styled from 'styled-components';
import {notification} from 'antd';
import {uniqueId} from 'lodash';

import Icon from 'shared/components/Icon';

// Ant Design v2.x does not support the onClick handler we need to allow
// closing the notification. To overcome that, a hacky "icon" property is
// provided, which renders a <div /> that covers the whole notification
// and handles the click itself.

const ClickOverlay = styled.div`
  cursor: pointer;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: -2px;
`;

const openNotification = (type, message) => {
  const key = uniqueId(`notification-${type}-`);
  const clickableMessage = (
    <div onClick={() => notification.close(key)}>
      <div>{message}</div>
      <IconWrapper>
        <Icon name={'close'} size={12} />
      </IconWrapper>
    </div>
  );

  return notification[type]({
    className: `swipeguide-notification swipeguide-notification-${type}`,
    duration: 14,
    icon: <ClickOverlay onClick={() => notification.close(key)} />,
    key,
    message: clickableMessage,
    placement: 'bottomLeft'
  });
};

export default {
  error: message => openNotification('error', message),
  success: message => openNotification('success', message),
  destroy: notification.destroy
};
