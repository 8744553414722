import styled from 'styled-components';
import flex from 'styles/flex';

export const LoginView = styled.div`
  ${flex.centerVertical};
  ${flex.vertical};
  flex: 1;
  min-height: 500px;
`;

export const AlreadyLoggedIn = styled.div`
  font-size: 16px;
`;

// Add media query support for mobile and desktop
export const LoginWrapper = styled.div`
  ${flex.vertical}
  width: 400px;
  @media (max-width: 720px) {
    width: 90%;
  }
`;

export const InfoWrapper = styled.div`
  margin-top: 16px;
`;

export const StyledLabel = styled.div`
  color: #4b4b4b;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  margin-bottom: 5px;
  padding-left: 16px;
`;

export const StyledLink = styled.a`
  color: inherit;
  font-weigth: 600; // semibold
  margin-left: 0.1rem;
  text-decoration: underline;
  transition: none;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }
`;
