import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const Feedback = styled.div`
  display: flex;
  flex-direction: column;
  color: ${commonPalette.txGrey};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeLabel = styled.div`
  display: flex;
  align-items: center;

  ${() => Label} {
    color: ${commonPalette.txBlack};
    margin: 0 20px 0 0;
  }

  > svg {
    color: ${commonPalette.sgGreen};
    margin-right: 10px;
  }
`;

export const Message = styled.div`
  word-wrap: break-word;
  height: fit-content;
  color: ${commonPalette.txBlack};
`;

export const Label = styled.div`
  margin-top: 10px;
`;

export const Title = styled.span`
  color: ${commonPalette.txBlack};
`;
