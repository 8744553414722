import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import get from 'lodash/get';

//styled components
import {Body, Dialog, Header, StyledButtonGroup, StyledModal, ButtonsWrapper} from './styles';
import Icon from '../Icon';

//messages
import messages from './messages';

class DialogComponent extends Component {
  static defaultProps = {
    isOpen: false,
    size: 'small'
  };

  static propTypes = {
    actions: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.node,
          disabled: PropTypes.bool,
          loading: PropTypes.bool
        })
      ),
      PropTypes.func,
      PropTypes.oneOf([false])
    ]).isRequired,
    bodyStyle: PropTypes.object,
    children: PropTypes.any.isRequired,
    dialogStyle: PropTypes.object,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onRequestClose: PropTypes.func,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    title: PropTypes.string,
    titleIconId: PropTypes.string,
    width: PropTypes.number,
    boxShadow: PropTypes.bool,
    overflow: PropTypes.string,
    overflowBody: PropTypes.string
  };

  addCancelAction = (actions, onCancel, customCancelButtonText) => {
    const {
      intl: {formatMessage}
    } = this.props;

    const cancelAction = {
      label: customCancelButtonText ? formatMessage(customCancelButtonText) : formatMessage(messages.cancel),
      design: 'text',
      onClick: onCancel
    };

    if (typeof actions === 'function') {
      return actions(cancelAction);
    }

    return [...actions, cancelAction];
  };

  render() {
    const {
      actions,
      bodyStyle,
      children,
      dialogStyle,
      isOpen,
      onCancel,
      onRequestClose,
      size,
      title,
      titleIconId,
      iconColor,
      customCancelButtonText,
      showCancel = true,
      customHeaderStyle,
      buttonGroupStyle,
      isFixed,
      additionalHeaderComponent,
      leftBottomComponent,
      hideActions,
      width,
      boxShadow,
      overflow,
      overflowBody
    } = this.props;

    const buttonGroupActions = actions !== false && this.addCancelAction(actions, onCancel, customCancelButtonText);
    if (buttonGroupActions.length > 2) {
      console.error('Button group does not take more than 2 actions');
    }

    const style = iconColor ? {color: iconColor} : {};
    return (
      <StyledModal
        contentLabel={title}
        isOpen={isOpen}
        onRequestClose={onRequestClose ? onRequestClose : onCancel}
        ariaHideApp={false}
      >
        <Dialog
          size={size}
          style={dialogStyle}
          isFixed={isFixed}
          width={width}
          boxShadow={boxShadow}
          overflow={overflow}
        >
          {title && (
            <div>
              <Header customHeaderStyle={customHeaderStyle}>
                {titleIconId && <Icon iconId={titleIconId} style={style} />}
                {title}
              </Header>
              {additionalHeaderComponent && additionalHeaderComponent()}
            </div>
          )}
          {children && (
            <Body overflow={overflowBody} style={bodyStyle}>
              {children}
            </Body>
          )}
          {!hideActions && (
            <ButtonsWrapper leftBottomComponent={leftBottomComponent}>
              {leftBottomComponent}
              {actions !== false && !!buttonGroupActions.length && (
                <StyledButtonGroup
                  primary={buttonGroupActions[0] && {...buttonGroupActions[0]}}
                  secondary={showCancel && buttonGroupActions[1] ? {...buttonGroupActions[1]} : undefined}
                  buttonGroupStyle={buttonGroupStyle}
                />
              )}
            </ButtonsWrapper>
          )}
        </Dialog>
      </StyledModal>
    );
  }
}

export default injectIntl(DialogComponent);
export {Divider} from './styles';
