import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import Container from 'ui-components/Layout/Container';
import {Header, BackButton, BackIcon} from './styles';
import views from 'config/views';
import messages from './messages';
import StepsTabs from 'components/StepsTabs';
import {UpdateSkillTask} from 'api/learning-activities/mutations';
import {updateSkillTaskOptions} from 'api/learning-activities/mutation-options';
import {SkillTaskById} from 'api/skills/queries';
import {skillTasksOptions} from 'api/skills/query-options';
import {mapValues, pick} from 'lodash';
import SaveChangesDialog from './saveChangesDialog';
import LoadingMessage from 'components/LoadingMessage';

@inject('store')
@graphql(UpdateSkillTask, updateSkillTaskOptions)
@graphql(SkillTaskById, skillTasksOptions)
@observer
class EditLearningActivity extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {learningActivityGeneralInfo, router}
    } = this.props;
    learningActivityGeneralInfo.setLearningId(router.params.id);

    const translations = mapValues(
      pick(messages, ['failedEditLearningActivity', 'successEditLearningActivity']),
      message => formatMessage(message)
    );
    learningActivityGeneralInfo.setTranslations(translations);
  }

  componentWillReceiveProps(nextProps) {
    const {
      store: {learningActivityGeneralInfo},
      skillTasksQuery
    } = nextProps;

    const {skillTask, loading} = skillTasksQuery;
    if (!loading && learningActivityGeneralInfo.isNewLearningActivityFormEmpty) {
      learningActivityGeneralInfo.setSkillCount(skillTask.skillCount);
      learningActivityGeneralInfo.updateLearningActivityForm(skillTask);
      learningActivityGeneralInfo.setInitialForm(skillTask);
      learningActivityGeneralInfo.setLocalSelectedTags(skillTask.tags);
      learningActivityGeneralInfo.setSelectedTags(skillTask.tags.map(tag => tag.id));
    }
  }

  formSubmit = () => {
    const {
      updateSkillTaskMutation,
      store: {
        learningActivityGeneralInfo: {skillCount, onlyTagsChanged, toggleSaveDialog, editLearningActivity}
      }
    } = this.props;
    if (skillCount && !onlyTagsChanged) {
      toggleSaveDialog(true);
    } else {
      editLearningActivity(updateSkillTaskMutation, true);
    }
  };

  render() {
    const {
      skillTasksQuery,
      intl: {formatMessage},
      store: {
        learningActivityGeneralInfo,
        learningActivityGeneralInfo: {stepsStore},
        router
      }
    } = this.props;

    const {loading, error} = skillTasksQuery;

    if (loading || error) return <LoadingMessage hasFailed={error}>{formatMessage(messages.loading)}</LoadingMessage>;

    return (
      <Container style={{paddingBottom: '50px', height: 'auto'}}>
        <Header>
          <BackButton
            onClick={() => {
              router.goTo(views.learningActivities, {});
            }}
          >
            <BackIcon />
          </BackButton>
          {formatMessage(messages.editLearning)}
        </Header>
        <StepsTabs
          mode={'edit'}
          loading={false}
          error={null}
          stepsStore={stepsStore}
          page={learningActivityGeneralInfo}
          fullHeight
          customPrimaryButtonText={formatMessage(messages.saveLearning)}
          formSubmit={this.formSubmit}
          cancel={() => router.goTo(views.learningActivities, {})}
          canSubmit={learningActivityGeneralInfo.canSubmit}
        />
        <SaveChangesDialog />
      </Container>
    );
  }
}

export default injectIntl(EditLearningActivity);
