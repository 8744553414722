import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';

import Field from 'shared/components/Field';
import {bindField} from 'shared/utils/input-utils';

import Dialog from 'ui-components/Dialog';

import {UpdatePassword} from 'api/user/mutations';
import {updatePasswordOptions} from 'api/user/mutation-options';
import {styles, ErrorNotification, StyledErrorIcon, StyledErrorWrapper} from './styles';

import messages from './messages';

@inject('store')
@graphql(UpdatePassword, updatePasswordOptions)
@observer
class ChangePasswordDialogComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {changePasswordDialog}
    } = this.props;

    const translations = mapValues(
      pick(messages, [
        'updateSuccess',
        'updateFailure',
        'passwordMustHave8Characters',
        'passwordMustHaveAtLeast1Number',
        'passwordMustHaveAtLeast1UppercaseLetter',
        'passwordMustHaveAtLeast1LowercaseLetter',
        'passwordMustHaveAtLeast1NonAlphanumericCharacter'
      ]),
      message => formatMessage(message)
    );

    changePasswordDialog.setTranslations(translations);
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      updatePasswordMutation
    } = this.props;
    const {changePasswordDialog: dialog} = store;
    const {form} = dialog;
    const {newPassword, newPasswordConfirm} = form.values();
    const passwordsMatch = newPassword === newPasswordConfirm;
    const enableSubmit = !dialog.loading && form.isValid && passwordsMatch;
    const inputStyles = {Input: styles};

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.change),
            disabled: !enableSubmit,
            loading: dialog.loading,
            onClick: () => dialog.submit(updatePasswordMutation)
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        title={formatMessage(messages.title)}
      >
        <Field
          autofocus={true}
          placeholder={formatMessage(messages.oldPassword)}
          id="oldPassword"
          type="password"
          form={form}
          field={'oldPassword'}
          hideError
          withPasswordToggle
          styles={inputStyles}
          {...bindField(form, 'oldPassword')}
        />
        <Field
          placeholder={formatMessage(messages.newPassword)}
          id="newPassword"
          type="password"
          form={form}
          field={'newPassword'}
          hideError
          {...bindField(form, 'newPassword')}
          styles={inputStyles}
          withPasswordToggle
          withPasswordValidation
        />

        <Field
          placeholder={formatMessage(messages.newPasswordConfirm)}
          id="newPasswordConfirm"
          type="password"
          form={form}
          field={'newPasswordConfirm'}
          hideError
          styles={inputStyles}
          {...bindField(form, 'newPasswordConfirm')}
          withPasswordToggle
        />
        {!passwordsMatch && (
          <StyledErrorWrapper>
            <StyledErrorIcon name={'warning'} />
            <ErrorNotification>{formatMessage(messages.passwordsDoNotMatch)}</ErrorNotification>
          </StyledErrorWrapper>
        )}
      </Dialog>
    );
  }
}

export default injectIntl(ChangePasswordDialogComponent);
