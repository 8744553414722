import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

//components
import Dialog from 'ui-components/Dialog';

import {Text, AffectedGuidesList, AffectedGuidesListItem} from './styles';

import messages from './messages';

@inject('store')
@observer
class NoteIconsAffectedGuidesDialog extends Component {
  render() {
    const {store, intl} = this.props;
    const {formatMessage} = intl;
    const {noteIconsAffectedGuidesDialog: dialog} = store;
    const {affectedGuides} = dialog;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.ok),
            design: 'text',
            onClick: dialog.submit
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        size="small"
        title={formatMessage(messages.title)}
        bodyStyle={{paddingBottom: 0}}
        titleIconId="warning"
      >
        <Text>
          {formatMessage(messages.caption)} <br />
          {formatMessage(messages.caption2, {n: affectedGuides.length})}
        </Text>
        <AffectedGuidesList>
          {affectedGuides.map((guide, index) => (
            <AffectedGuidesListItem
              key={index}
              href={`${window.location.origin}/admin/guides/${guide.id}`}
              target="_blank"
            >
              {guide.title}
            </AffectedGuidesListItem>
          ))}
        </AffectedGuidesList>
      </Dialog>
    );
  }
}

export default injectIntl(NoteIconsAffectedGuidesDialog);
