import {observable, action, computed, toJS, autorun, makeObservable} from 'mobx';
import moment from 'moment';
import pick from 'lodash/pick';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import identity from 'lodash/identity';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import without from 'lodash/without';
import keys from 'lodash/keys';
import values from 'lodash/values';
import {posthogCapture} from 'shared/utils/posthog-utils';
import {DEFAULT_LOCALE} from 'shared/constants';
import {CUSTOM_SIGNOFF_FILTER_OPTIONS} from 'shared/enums';
import signoffColumnsConfig from 'config/campaign-export-columns';

//utils
import notification from 'utils/notification-utils';
import {sortByPropName} from 'utils/array-utils';
import {downloadCsv} from 'utils/media';

import {DATE_FORMAT, EXPORT_DATE_FORMAT} from 'config/constants';

import store from '../store';

const {SIGNOFF, START_DATE, END_DATE, GUIDE, WORKSPACE, TAG} = CUSTOM_SIGNOFF_FILTER_OPTIONS;

const RESULTS_LOADER_KEY = 'SIGNOFF_RESULTS';
const EXPORT_LOADER_KEY = 'EXPORT_RESULTS';

export const getDefaultCustomFilters = () => ({
  [SIGNOFF]: [],
  [GUIDE]: [],
  [WORKSPACE]: [],
  [TAG]: [],
  [START_DATE]: moment().format(DATE_FORMAT),
  [END_DATE]: moment().format(DATE_FORMAT)
});

const defaultCustomFilterEntities = {
  [SIGNOFF]: []
};

const defaultLoaders = {
  [SIGNOFF]: false,
  [GUIDE]: false,
  [WORKSPACE]: true,
  [TAG]: false,
  [RESULTS_LOADER_KEY]: false,
  [EXPORT_LOADER_KEY]: false
};

class SignoffResultsPage {
  @observable customFilter;
  @observable customFilterEntities;
  @observable signoffRawResults;
  @observable loaders;
  @observable translations;

  menuItems = {
    DOWNLOAD: {
      id: 'download',
      disabled: false,
      onClick: () => this.exportResults()
    },
    SPINNER: {
      id: 'spinner',
      isSpinning: true,
      disabled: false
    }
  };

  constructor() {
    makeObservable(this);
  }

  initListeners() {
    this.startListeningToExportStateChanges();
  }

  stopListeners() {
    this.stopListeningToExportStateChanges();
  }

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  setLoader = (key, value) => {
    this.loaders = {
      ...this.loaders,
      [key]: value
    };
  };

  @action
  selectCustomFilterOptions = entries => {
    this.customFilter = {
      ...this.customFilter,
      ...entries
    };
  };

  @action
  setSignoffResults(results = []) {
    this.signoffRawResults = results;
  }

  @action
  setCustomFilterEntity = (key, value = []) => {
    this.customFilterEntities = {
      ...this.customFilterEntities,
      [key]: value
    };
  };

  @computed get signoffTableResults() {
    const includedColumns = [
      'SIGN_OFF_RESULT_ID',
      'SUBMITTED_NAME',
      'DATE',
      'TIME',
      'INSTRUCTION',
      'GUIDE',
      'SIGN_OFF_ID',
      'SIGN_OFF',
      'SIGN_OFF_QUESTION',
      'SIGN_OFF_ANSWER_RAW',
      'SIGN_OFF_ANSWER'
    ];

    return this.transformRawResultsToColumns(includedColumns, 'id', 'html');
  }

  startListeningToExportStateChanges() {
    this.disposeExportStateChangeAutorun = autorun(() => {
      const {shouldShowExportAction, isExportLoading, menuItems} = this;
      const {app} = store;

      if (isExportLoading) {
        app.setActionMenuItems([menuItems.SPINNER]);
        return;
      }

      if (shouldShowExportAction) {
        app.setActionMenuItems([menuItems.DOWNLOAD]);
      } else {
        app.setActionMenuItems([]);
      }
    });
  }

  stopListeningToExportStateChanges() {
    const {app} = store;
    app.setActionMenuItems([]);
    this.disposeExportStateChangeAutorun();
  }

  transformRawResultsToColumns(includedColumns = keys(signoffColumnsConfig), keyAccessor = 'name', renderer = 'text') {
    const {signoffRawResults} = this;

    const columnConfigEntries = values(pick(signoffColumnsConfig, includedColumns));

    return map(signoffRawResults, entry =>
      reduce(
        columnConfigEntries,
        (columns, columnConfig) => ({
          ...columns,
          [columnConfig[keyAccessor]]: columnConfig.valueAccessor(
            entry,
            get(entry, 'campaign.defaultLocale', DEFAULT_LOCALE),
            this.translations,
            {renderer}
          )
        }),
        {}
      )
    );
  }

  // We dont want to compute this
  // Ideally we want to make this async
  // so only when user clicks on EXPORT
  // this queries full signoff report
  // but for now we will transform it from the data we already have.

  async exportResults() {
    this.setLoader(EXPORT_LOADER_KEY, true);

    try {
      const {customFilter} = this;

      const startDate = moment(customFilter[START_DATE]).format(EXPORT_DATE_FORMAT);
      const endDate = moment(customFilter[END_DATE]).format(EXPORT_DATE_FORMAT);

      const columnsToExport = without(
        keys(signoffColumnsConfig),
        'SIGN_OFF_RESULT_ID',
        'SIGN_OFF_ID',
        'GUIDE_VERSION',
        'GUIDE_VERSION_ID'
      );

      const exportedData = this.transformRawResultsToColumns(columnsToExport, 'name', 'text');

      const fileName = `Sign-off results ${startDate} - ${endDate}.csv`;

      const downloadStatus = await downloadCsv(exportedData, fileName, 'Signoff results');

      if (get(store, 'analytics.posthogIsLoaded', false)) {
        posthogCapture('Export Signoff Results');
      }

      if (!downloadStatus.ok) {
        throw new Error(downloadStatus.error);
      }
    } catch (err) {
      console.log(err);
      notification.error("Couldn't export results");
    }

    this.setLoader(EXPORT_LOADER_KEY, false);
  }

  @computed get customFilterPayload() {
    const {customFilter} = this;

    const [startDate, endDate] = [toJS(get(customFilter, START_DATE)), toJS(get(customFilter, END_DATE))];
    const campaignIds = toJS(get(customFilter, SIGNOFF, []));
    const domainIds = toJS(get(customFilter, WORKSPACE, []));
    const tagIds = toJS(get(customFilter, TAG, []));
    const guideIds = toJS(get(customFilter, GUIDE, []));

    const payload = {
      campaignIds: isEmpty(campaignIds) ? null : campaignIds,
      domainIds: isEmpty(domainIds) ? null : domainIds,
      tagIds: isEmpty(tagIds) ? null : tagIds,
      guideIds: isEmpty(guideIds) ? null : guideIds,
      startDate: startDate ? moment.utc(startDate).toDate() : null,
      endDate: endDate ? moment.utc(endDate).toDate() : null
    };

    return payload;
  }

  @computed get customFilterOptions() {
    const {customFilterEntities} = this;

    const signOffs = map(customFilterEntities[SIGNOFF], ({id, title}) => ({
      id,
      name: title
    })).sort(sortByPropName('name'));
    const guides = map(customFilterEntities[GUIDE], ({id, title}) => ({
      id,
      name: title
    })).sort(sortByPropName('name'));
    const workspaces = map(customFilterEntities[WORKSPACE], ({id, name}) => ({
      id,
      name
    })).sort(sortByPropName('name'));
    const tags = map(customFilterEntities[TAG], ({id, title}) => ({
      id,
      name: title
    })).sort(sortByPropName('name'));

    return {
      [SIGNOFF]: toJS(signOffs),
      [GUIDE]: toJS(guides),
      [WORKSPACE]: toJS(workspaces),
      [TAG]: toJS(tags)
    };
  }

  @computed get areResultsLoading() {
    const {loaders} = this;
    const dataLoaders = pick(loaders, [SIGNOFF, GUIDE, WORKSPACE, TAG, RESULTS_LOADER_KEY]);

    return some(dataLoaders, identity);
  }

  @computed get isExportLoading() {
    return this.loaders[EXPORT_LOADER_KEY];
  }

  @computed get shouldShowExportAction() {
    const {signoffRawResults, areResultsLoading} = this;
    return signoffRawResults.length > 0 && !areResultsLoading;
  }

  // Init & Dispose
  @action
  initCustomFilter = () => {
    this.customFilter = getDefaultCustomFilters();
  };

  @action
  initCustomFilterEntities = () => {
    this.customFilterEntities = defaultCustomFilterEntities;
  };

  @action
  initLoaders = () => {
    this.loaders = {...defaultLoaders};
  };

  @action
  reset = () => {
    this.initCustomFilter();
    this.initCustomFilterEntities();
    this.initLoaders();
    this.setSignoffResults([], true);
  };

  @action
  init = () => {
    this.reset();
    this.initListeners();
  };

  @action
  dispose() {
    this.reset();
    this.stopListeners();
  }
}

export default SignoffResultsPage;
