import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import TeamWorkspacesView from 'views/TeamWorkspacesView';

@inject('store')
@observer
class EditTeamWorkspaces extends Component {
  render() {
    const {viewMode} = this.props;
    return <TeamWorkspacesView editTeam={true} viewMode={viewMode} />;
  }
}

export default injectIntl(EditTeamWorkspaces);
