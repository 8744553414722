import includes from 'lodash/includes';
import {getUserHighestRole} from 'utils/data-utils';
import {INTERCOM_PROD_ID, INTERCOM_TEST_ID} from 'config/constants';
import {getCurrentHostname, isPrcHostname} from 'shared/utils/host-utils';

const PRODUCTION_INTERCOM_HOSTNAMES = ['swipeguide.com'];

const getShowIntercom = ({platform, isActionMenuOpen}) => {
  const canUseLiveChat = Boolean((platform || {}).canUseLiveChat);
  const currentHostname = getCurrentHostname();
  return canUseLiveChat && !isActionMenuOpen && !isPrcHostname(currentHostname);
};

const enableProductionIntercom = () => {
  const isImpersonationSession =
    document.cookie.split(';').filter(item => item.trim().startsWith('impersonate=')).length > 0;
  const currentHostname = getCurrentHostname();
  const isUnderProductionHost = PRODUCTION_INTERCOM_HOSTNAMES.some(productionIntercomHostname =>
    includes(currentHostname, productionIntercomHostname)
  );
  return !isImpersonationSession && isUnderProductionHost;
};

const getIntercomProps = ({platform, user} = {}) => {
  const prodIntercomID = INTERCOM_PROD_ID;
  const testIntercomID = INTERCOM_TEST_ID;
  const aUser = user || {};
  const aTeam = aUser.teams && aUser.teams.map(membership => membership.team.name).join();
  const aPlatform = platform || {};
  const platform_role = getUserHighestRole(aUser);
  const intercomAttributes = {
    user_id: aUser.id,
    email: aUser.email,
    name: aUser.fullName,
    platform_role,
    team: aTeam,
    platform: aPlatform.assetsFolder,
    api_base: 'https://api-iam.eu.intercom.io'
  };

  const intercomID = enableProductionIntercom() ? prodIntercomID : testIntercomID;

  return {
    intercomID,
    ...intercomAttributes
  };
};

export {getShowIntercom, getIntercomProps};
