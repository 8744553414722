import {defineMessages} from 'react-intl';

const messages = defineMessages({
  duplicate: {
    id: 'common.actions.duplicate',
    defaultMessage: 'Duplicate'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  deactivate: {
    defaultMessage: 'Deactivate',
    id: 'common.actions.deactivate'
  },
  setAsDraft: {
    id: 'EditSignOff.setAsDraft',
    defaultMessage: 'Set as draft'
  },
  frequencySettings: {
    id: 'Menu.settings',
    defaultMessage: 'Settings'
  },
  translate: {
    id: 'EditSignOff.translate',
    defaultMessage: 'Translate'
  },
  visibility: {
    id: 'EditSignOff.visibility',
    defaultMessage: 'Visibility'
  }
});

export default messages;
