import gql from 'graphql-tag';

export const ChecklistsLite = gql`
  query checklistsLite {
    checklistsLite {
      id
      title
    }
  }
`;

export const ChecklistAnswers = gql`
  query checklistAnswers(
    $startDate: Date
    $endDate: Date
    $checklistIds: [String]
    $guideIds: [String]
    $domainIds: [String]
    $teamIds: [String]
  ) {
    checklistAnswers(
      startDate: $startDate
      endDate: $endDate
      checklistIds: $checklistIds
      guideIds: $guideIds
      domainIds: $domainIds
      teamIds: $teamIds
    ) {
      id
      instruction {
        id
        title
        checklistTemplate {
          id
          type
        }
      }
      guide {
        id
        title
      }
      domain {
        id
        name
      }
      createdAt
      submittedAt
      user
      score
    }
  }
`;

export const ChecklistAnswer = gql`
  query checklistAnswer($id: String!) {
    checklistAnswer(id: $id) {
      id
      instruction {
        id
        title
        checklistTemplate {
          id
          type
          name
        }
      }
      guide {
        id
        title
      }
      createdAt
      submittedAt
      user
      score
      items {
        id
        value
        feedback
        media {
          url
        }
        step {
          instructionHtml
          position
        }
        templateOption {
          option
          id
          value
        }
      }
      signatureUrl
    }
  }
`;

export const ChecklistAnswerOldest = gql`
  query oldestChecklistAnswer {
    oldestChecklistAnswer {
      createdAt
      submittedAt
    }
  }
`;

export const TriggerChecklistExport = gql`
  query checklistAnswersExportTrigger(
    $startDate: Date!
    $endDate: Date!
    $searchTerm: String
    $checklistIds: [String]
    $guideIds: [String]
    $domainIds: [String]
    $teamIds: [String]
  ) {
    checklistAnswersExportTrigger(
      startDate: $startDate
      endDate: $endDate
      searchTerm: $searchTerm
      checklistIds: $checklistIds
      guideIds: $guideIds
      domainIds: $domainIds
      teamIds: $teamIds
    ) {
      result
      error
    }
  }
`;
