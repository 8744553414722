import React, {useState} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import messages from './messages';
import {
  DropdownIcon,
  Td,
  Th,
  Tr,
  TrCustom,
  TrCollapsable,
  DropdownToggle,
  StyledFolderIcon,
  colors,
  NextIcon
} from './styles';
import views from 'config/views';
import {SkillsSkillProfilesPaginated} from 'api/skills/queries';
import {getReskillingFrequencyMessage} from '../../config/constants';

//components
import LoadingMessage from 'components/LoadingMessage';
import SkillsProfileListMenu from 'components/SkillsProfileListMenu';
import RequiredLevelLabel from 'ui-components/RequiredLevelLabel';
import {Dropdown} from 'styles/styled-components/dropdown-styled-components';
import {useQuery} from '@apollo/client';
import Icon from 'ui-components/Icon';

const LoadingState = () => (
  <Tr
    border
    style={{
      display: 'table-row',
      position: 'relative'
    }}
  >
    <Th
      left
      style={{
        paddingLeft: '36px'
      }}
    >
      <LoadingMessage />
    </Th>
  </Tr>
);

const ErrorState = () => (
  <Tr
    border
    style={{
      display: 'table-row'
    }}
  >
    <Th
      left
      style={{
        paddingLeft: '36px'
      }}
    >
      <FormattedMessage {...messages.error} />
    </Th>
  </Tr>
);

export const SkillsDropdown = injectIntl<{skillProfileId: string; skillLevels}>(props => {
  const {
    skillProfileId,
    skillLevels,
    intl: {formatMessage}
  } = props;

  const {loading, error, data} = useQuery(SkillsSkillProfilesPaginated, {
    variables: {
      limit: 100,
      offset: 0,
      filters: {
        skillProfileId: {
          eq: skillProfileId
        }
      }
    },
    fetchPolicy: 'network-only'
  });

  const skillsSkillProfiles = data?.skillsSkillProfiles.results || [];
  if (loading) return <LoadingState />;
  if (error) return <ErrorState />;

  const formattedSkillLevels = skillLevels?.map(
    level => `${level.position} - ${level.name}${level.description ? ' - ' + level.description : ''}`
  );

  const popoverContent = formattedSkillLevels || formatMessage(messages.unableToLoadSkillLevels);

  return (
    <>
      <Tr
        border
        style={{
          display: 'table-row'
        }}
      >
        <Th
          left
          style={{
            paddingLeft: '36px'
          }}
        >
          {formatMessage(messages.skill)}
        </Th>
        <Th></Th>
        <Th left>{formatMessage(messages.repeatEvery)}</Th>
        <Th left>
          {formatMessage(messages.requiredLevel)}
          <Icon
            className="info"
            iconId="info"
            style={{marginLeft: 4}}
            popoverTitle="Level Details"
            popoverContent={popoverContent}
            showDash={false}
          />
        </Th>
        <Th></Th>
      </Tr>
      {skillsSkillProfiles.map(skillsSkillProfile => (
        <TrCustom
          key={`${skillProfileId}-${skillsSkillProfile.id}`}
          style={{
            display: 'table-row',
            fontSize: '13px'
          }}
        >
          <Td align="left">{skillsSkillProfile.skill.name}</Td>
          <Td></Td>
          <Td align="left">
            {skillsSkillProfile?.skill.expirationMonths
              ? getReskillingFrequencyMessage(formatMessage, messages, skillsSkillProfile.skill.expirationMonths)
              : formatMessage(messages.none)}
          </Td>
          <Td>
            <RequiredLevelLabel mode={'view'} requiredLevel={skillsSkillProfile.requiredLevel} />
          </Td>
          <Td></Td>
        </TrCustom>
      ))}
    </>
  );
});

const SkillProfileRow = props => {
  const {
    store: {router},
    id,
    name,
    skills = [],
    teams,
    rolesCount,
    skillLevels
  } = props;

  const [toggled, setToggled] = useState(false);

  const handleToggled = () => {
    if (skills.totalCount === 0) return;
    setToggled(!toggled);
  };

  return (
    <tbody>
      <TrCollapsable color={colors.textGrey}>
        <Td onClick={handleToggled} width="250px" style={{minWidth: '250px'}}>
          <DropdownIcon open={!toggled} empty={skills.totalCount === 0} />
          <StyledFolderIcon />
          {name}
        </Td>
        <Td right data-cy={`${name}-${skills.totalCount}`}>
          {skills.totalCount}
        </Td>
        <Td right data-cy={`${name}-teams-${teams.totalCount}`}>
          {teams.totalCount}
        </Td>
        <Td right data-cy={`${name}-roles-${rolesCount}`}>
          {rolesCount}
        </Td>
        <Td>
          <Dropdown overlay={<SkillsProfileListMenu skillProfileId={id}></SkillsProfileListMenu>}>
            <DropdownToggle data-cy={name} aria-label="skill profile edit button">
              <NextIcon
                onClick={() => {
                  router.goTo(views.skillProfilesManage, {id});
                }}
              />
            </DropdownToggle>
          </Dropdown>
        </Td>
      </TrCollapsable>
      {toggled && <SkillsDropdown skillProfileId={id} skillLevels={skillLevels} />}
    </tbody>
  );
};

export default inject('store')(SkillProfileRow);
