import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {cardToolbarMetrics} from 'styles/metrics';

//components
import $EnhancedIcon from 'ui-components/Layout/EnhancedIcon';
import $Icon from 'ui-components/Layout/Icon';
import $SharedIcon from 'shared/components/Icon';

export const CardToolbar = styled.div`
  ${flex.horizontal};
  cursor: default;
`;

export const StyledEnhancedIcon = styled($EnhancedIcon)`
  display: block;
  height: auto;
  min-width: ${cardToolbarMetrics.size + cardToolbarMetrics.gutter}px;
  padding: 0 ${cardToolbarMetrics.gutter / 2}px;

  ${p =>
    p.onClick &&
    `
    cursor: pointer;

    &:hover > .icon {
      background: rgba(0, 0, 0, 0.05);
    }
  `}

  ${p =>
    p.showOnlyOnHover &&
    `
    opacity: 0;

    .sync-card-hover-with-child-component:hover &,
    &:hover {
      opacity: 1;
    }
  `}
`;

export const DropdownToggle = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border: 0;
  color: currentColor;
  cursor: pointer;
  display: block;
  height: ${cardToolbarMetrics.size}px;
  outline: 0;
  padding: 0;
  position: relative;
  width: ${cardToolbarMetrics.size}px;
  z-index: 0;

  &::after {
    background: currentColor;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &:hover::after {
    opacity: 0.05;
  }
`;

export const StyledKebabIcon = styled($Icon).attrs({
  name: 'kebab-menu',
  size: 32
})`
  left: 50%;
  margin: ${p => p.size / -2}px 0 0 ${p => p.size / -2}px;
  position: absolute;
  top: 50%;
`;

export const StyledSignOffIcon = styled($Icon).attrs({
  name: 'signoff',
  size: 20
})`
  margin: 4px 10px;
`;

export const StyledSignatureIcon = styled($Icon).attrs({
  name: 'signature',
  size: 20
})`
  margin: 4px 10px;
`;

export const StyledAlertRecipientsIcon = styled($SharedIcon).attrs({
  name: 'report-issue',
  size: 20
})`
  margin: 4px 10px;
`;
