import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import moment from 'moment';

//components
import Dialog from 'ui-components/Dialog';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import RadioButton from 'shared/components/RadioButton';
import Icon from 'ui-components/Icon';

import {DATE_FORMAT} from 'config/constants';

//styles
import {InnerWrapper, RevisionCaption} from './styles';

//messages
import messages from './messages';

@inject('store')
@observer
class GuideVersionsDialogComponent extends Component {
  handleViewVersionClick = async () => {
    const {store} = this.props;
    const {guideVersionsDialog: dialog} = store;

    dialog.reset();
    await dialog.setGuideVersion();
  };

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {guideVersionsDialog: dialog} = store;
    const {
      versions,
      isPublished,
      opened,
      reset,
      selectVersion,
      selectedVersion,
      isUnderApproval,
      isLiveVersionExpired
    } = dialog;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.viewVersion),
            design: 'text',
            dataCy: 'view-version',
            onClick: this.handleViewVersionClick
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={formatMessage(messages.title)}
      >
        <InnerWrapper>
          <RadioButton
            caption={<FormattedMessage {...messages.draft} />}
            checked={!selectedVersion}
            design="fill-line"
            onChange={() => selectVersion(null)}
          />
          {isUnderApproval && (
            <RadioButton
              caption={
                <RevisionCaption>
                  <FormattedMessage {...messages.approval} />
                  <Icon iconId="lock" style={{marginLeft: 8}} />
                </RevisionCaption>
              }
              checked={selectedVersion && selectedVersion._id === 'review'}
              design="fill-line"
              onChange={() => selectVersion({_id: 'review'})}
            />
          )}
          {isLiveVersionExpired && (
            <RadioButton
              caption={<FormattedMessage {...messages.liveReview} />}
              checked={selectedVersion && selectedVersion._id === 'live-review'}
              design="fill-line"
              onChange={() => selectVersion({_id: 'live-review'})}
            />
          )}
          {versions?.map((version, index) => (
            <RadioButton
              caption={this.getVersionCaption(version, isPublished && index === 0)}
              checked={selectedVersion && selectedVersion._id === version._id}
              design="fill-line"
              key={index}
              onChange={() => selectVersion(version)}
            />
          ))}
        </InnerWrapper>
      </Dialog>
    );
  }

  getVersionCaption = (version, isLiveVersion) => {
    const date = moment(version.createdAt).format(DATE_FORMAT);

    if (isLiveVersion) {
      return <FormattedMessage {...messages.liveVersion} values={{date}} />;
    }

    return <FormattedMessage {...messages.version} values={{date, version: version.version}} />;
  };
}

export default injectIntl(GuideVersionsDialogComponent);
