import {DND_TYPE as STEP_DND_TYPE} from 'components/StepList/StepOverview/DraggableStepCard/dnd';
export const DND_TYPE = 'INSTRUCTION';

export const source = {
  beginDrag: props => {
    const {instruction, position} = props;

    return {
      preview: instruction,
      sourceId: instruction.id,
      sourcePosition: position,
      sourceTopicId: instruction.topicId
    };
  },
  endDrag: props => {
    const {repositionTopicsAndInstructionsMutation, store} = props;
    const {dragDropItems: page} = store;

    page.submit(repositionTopicsAndInstructionsMutation);
  },
  canDrag: props => !props.store.dragDropItems.submitting,
  isDragging: (props, monitor) => {
    // As the original component may be unmounted during dragging (if moved
    // from one topic to another) a custom isDragging method has to be defined.
    //
    // https://react-dnd.github.io/react-dnd/docs-drag-source.html#specification-methods

    // We're checking for the instruction to be provided so it won't break
    // because of the newly created instructions

    return props.instruction && props.instruction.id === monitor.getItem().sourceId;
  }
};

export const target = {
  hover: (props, monitor) => {
    const type = monitor.getItemType();

    if (type === DND_TYPE) {
      return onHoverFromInstruction(props, monitor);
    }

    return;
  },
  drop: ({instruction}) => ({instruction}),
  canDrop(props, monitor) {
    const item = monitor.getItem();
    const type = monitor.getItemType();
    const {instruction} = props;
    if (type === STEP_DND_TYPE) {
      return instruction.id !== item.step.instructionId;
    }

    return true;
  }
};

const onHoverFromInstruction = (props, monitor) => {
  const item = monitor.getItem();
  const {instruction, position, store} = props;
  const {id, topicId} = instruction;
  const {dragDropItems: page} = store;

  const {sourceId, sourcePosition, sourceTopicId} = item;

  if (sourceId !== id) {
    page.moveInstruction(sourceTopicId, sourcePosition, topicId, position);

    // Note: we're mutating the monitor item here! Generally it's better
    // to avoid mutations, but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.sourcePosition = position;
    item.sourceTopicId = topicId;
  }
};
