import styled from 'styled-components';

const horizontalBarHeight = 30;
const rowHeight = 30;
const gap = 10;

export const Wrapper = styled.div`
  background: #ffffff;
  padding: 14px 0px 4px 0px;
  margin: 0 -4px;
  overflow: auto;
  display: flex;
`;

export const LabelsWrapper = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 125px;
  min-width: 25px;
`;

export const BarsWrapper = styled.div`
  flex: 1;
  margin-left: ${gap}px;
`;

export const Bar = styled.div`
  position: relative;
  height: ${rowHeight}px;
  min-width: 1px;
  width: calc(${p => (p.width ? p.width : 1)}% - ${horizontalBarHeight}px);
  border-radius: 2px;
  background: ${p => (p.bgColor ? p.bgColor : '#ededed')};
`;

export const Rating = styled.div`
  flex: 1;
`;

export const RatingLabel = styled.div`
  text-align: center;
  font-weight: 600;
  position: absolute;
  right: -20px;
  top: 0;
  line-height: ${horizontalBarHeight}px;
`;

export const BarLabel = styled.div`
  font-size: 12px;
  opacity: 0.8;
  text-align: right;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: right;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
`;

export const Cell = styled.div`
  height: ${rowHeight + gap}px;
  display: flex;
  align-items: center;
`;
