import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  noFeedbacks: {
    id: 'Feedback.noFeedbackOnGuide',
    defaultMessage: 'There are no feedbacks for this guide.'
  }
});

export default messages;
