import styled from 'styled-components';

//components
import $Icon from 'ui-components/Layout/Icon';

//constants
const iconSize = 32;

export const DragHandle = styled.div`
  color: #000;
  cursor: move;
  height: ${iconSize}px;
  overflow: hidden; // icon could be larger than the wrapper
  position: relative;
  width: ${iconSize}px;

  ${p =>
    p.isDisabled &&
    `
    cursor: auto;
    opacity: 0.4;
  `}
`;

export const StyledDragHandleIcon = styled($Icon).attrs({
  name: 'drag-handle',
  size: iconSize
})`
  left: 50%;
  margin: ${p => p.size / -2}px 0 0 ${p => p.size / -2}px;
  opacity: 0.1;
  position: absolute;
  top: 50%;
`;
