import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//styled-components
import {ViewModePickerContainer, ViewModePickerWrapper, Button, Label} from './styles';
import Icon from 'ui-components/Layout/Icon';

//messages
import messages from './messages';

class ViewModePickerComponent extends Component {
  render() {
    const {
      intl: {formatMessage},
      isMobileView,
      setViewMode
    } = this.props;

    return (
      <ViewModePickerContainer>
        <Label>{formatMessage(messages.label)}</Label>

        <ViewModePickerWrapper>
          <Button selected={isMobileView} onClick={() => setViewMode(true)}>
            <Icon name={'preview-mobile'}></Icon>
          </Button>

          <Button selected={!isMobileView} onClick={() => setViewMode(false)}>
            <Icon name={'preview-desktop'}></Icon>
          </Button>
        </ViewModePickerWrapper>
      </ViewModePickerContainer>
    );
  }
}

export default injectIntl(ViewModePickerComponent);
