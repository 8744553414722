//constants
import C from './constants';

//refresh queries
import {refreshProfile, refreshUsers, refreshUser} from 'api/user/refresh-queries';
import {refreshTeam} from 'api/team/refresh-queries';

import {client} from 'utils/apollo-client';

export const createUserOptions = {
  props: ({mutate}) => ({
    [C.CREATE_USER]: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshUsers()]
      });
    }
  })
};

export const updateProfileOptions = {
  props: ({mutate}) => ({
    [C.UPDATE_PROFILE]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshProfile()]
      })
  })
};

export const updatePasswordOptions = {
  props: ({mutate}) => ({
    [C.UPDATE_PASSWORD]: variables =>
      mutate({
        variables
      })
  })
};

export const inviteUserOptions = {
  options: {
    client
  },
  props: ({mutate}) => ({
    [C.INVITE_USER]: variables =>
      mutate({
        variables
      })
  })
};

export const forgotPasswordOptions = {
  props: ({mutate}) => ({
    [C.FORGOT_PASSWORD]: variables =>
      mutate({
        variables
      })
  })
};

export const resetPasswordOptions = {
  props: ({mutate}) => ({
    [C.RESET_PASSWORD]: variables =>
      mutate({
        variables
      })
  })
};

export const setPasswordOptions = {
  props: ({mutate}) => ({
    [C.SET_PASSWORD]: variables =>
      mutate({
        variables
      })
  })
};

export const editUserOptions = {
  props: ({mutate}) => ({
    [C.EDIT_USER]: variables =>
      mutate({
        variables
      })
  })
};

export const disableUserOptions = {
  props: ({
    mutate,
    ownProps: {
      store: {editUserPage}
    }
  }) => ({
    [C.DISABLE_USER]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshUser(variables.id), refreshUsers(), ...editUserPage.teamsIds.map(id => refreshTeam(id))]
      })
  })
};

export const enableUserOptions = {
  options: {
    client
  },
  props: ({
    mutate,
    ownProps: {
      store: {editUserPage}
    }
  }) => ({
    [C.ENABLE_USER]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshUser(variables.id), refreshUsers(), ...editUserPage.teamsIds.map(id => refreshTeam(id))]
      })
  })
};

export const deleteUserOptions = {
  props: ({
    mutate,
    ownProps: {
      store: {deleteUserDialog}
    }
  }) => ({
    [C.DELETE_USER]: variables =>
      mutate({
        variables,
        refetchQueries: [
          refreshUser(variables.id),
          refreshUsers(),
          ...deleteUserDialog.teamIds.map(id => refreshTeam(id))
        ]
      })
  })
};

export const archiveUserOptions = {
  props: ({
    mutate,
    ownProps: {
      store: {archiveUserDialog}
    }
  }) => ({
    [C.DISABLE_USER]: variables =>
      mutate({
        variables,
        refetchQueries: [
          refreshUser(variables.id),
          refreshUsers(),
          ...archiveUserDialog.teamIds.map(id => refreshTeam(id))
        ]
      })
  })
};
