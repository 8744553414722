import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

import {ListContainer} from 'components/SkillProfileRolesDrawer/styles';
import messages from './messages';
import {ListTopBar, StyledButton, StyledEnhancedIcon} from './styles';
import SelectedJobTitlesItem from './SelectedJobTitlesItem';

@inject('store')
@observer
class SkillProfilesSelectedJobTitlesComponent extends Component {
  /**
   * Clear all selected team roles from the store.
   * BUT omit the roles that are not available for user to edit.
   */
  deleteAll = () => {
    const {
      store: {
        skillProfilePage: {setSelectedJobTitles}
      }
    } = this.props;

    setSelectedJobTitles([]);
  };

  /**
   * Delete role from the team or complete team based on a mode.
   *
   * @param {string} teamId
   * @param {string} roleId
   * @param {string} mode - 'team' or 'role'
   */
  handleRemoveClick = (teamId, roleId, mode) => {
    const {
      store: {
        skillProfilePage: {selectedJobTitles, setSelectedJobTitles, groupJobTitlesByAvailableNotAvailable}
      }
    } = this.props;

    const {available} = groupJobTitlesByAvailableNotAvailable(selectedJobTitles);
    const teamIndex = available.findIndex(item => item.team.id === teamId);

    if (mode === 'team') {
      setSelectedJobTitles(available.filter(item => item.team.id !== teamId));
    } else if (mode === 'role') {
      const teamItem = available[teamIndex];
      teamItem.roles = teamItem.roles.filter(item => item.id !== roleId);

      if (teamItem.roles.length === 0) {
        setSelectedJobTitles(available.filter(item => item.team.id !== teamId));
      } else {
        setSelectedJobTitles([...available]);
      }
    }
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        skillProfilePage: {selectedJobTitles, availableSkillRoles, groupJobTitlesByAvailableNotAvailable}
      }
    } = this.props;

    const {availableNumber, notAvailableNumber, available} = groupJobTitlesByAvailableNotAvailable(selectedJobTitles);

    if (!availableNumber && !notAvailableNumber) {
      return '...';
    }

    return (
      <>
        <ListTopBar>
          <div>
            {formatMessage(messages.x_teams, {teamsCount: availableNumber})}
            {notAvailableNumber ? (
              <StyledEnhancedIcon
                name="info"
                size={24}
                tooltip={formatMessage(messages.unavailableTeamsWarning, {teamsCount: notAvailableNumber})}
                tooltipPlacement="right"
              />
            ) : (
              <></>
            )}
          </div>
          {availableNumber ? (
            <StyledButton onClick={() => this.deleteAll()} label={formatMessage(messages.removeAll)} />
          ) : (
            <></>
          )}
        </ListTopBar>
        <ListContainer>
          {available.map(item => (
            <SelectedJobTitlesItem
              key={item.team.id}
              team={item.team}
              roles={item.roles}
              availableSkillRoles={availableSkillRoles}
              formatMessage={formatMessage}
              onRemove={(teamId, roleId, mode) => this.handleRemoveClick(teamId, roleId, mode)}
            />
          ))}
        </ListContainer>
      </>
    );
  }
}

export default injectIntl(SkillProfilesSelectedJobTitlesComponent);
