import React, {Component} from 'react';

import {Rating, Row, Left, Right, Title, Text, YesNoAnswer} from './styles';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

class CustomerFeedbackListComponent extends Component {
  render() {
    const {list, colorRange, labelsX, shiftXAxis, isBinary} = this.props;
    return (
      <div>
        {list.length < 1 && (
          <div>
            <FormattedMessage {...messages.noFeedback} />
          </div>
        )}
        {list.map((item, index) => {
          const date = new Date(item.date);
          const prettyDate = date.toLocaleTimeString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          });
          return (
            <Row key={index}>
              <Left>
                {labelsX && labelsX[item.score] ? (
                  <YesNoAnswer>{labelsX[isBinary ? item.score : item.score - 1]}</YesNoAnswer>
                ) : (
                  <Rating color={colorRange[shiftXAxis ? item.score - 1 : item.score]}>{item.score}</Rating>
                )}
              </Left>
              <Right>
                <Title>{prettyDate}</Title>
                <Text>
                  <span>
                    <FormattedMessage {...messages.followUpQuestion} /> :
                  </span>{' '}
                  {item.question}
                </Text>
                <Text>
                  <span>
                    <FormattedMessage {...messages.answer} /> :
                  </span>{' '}
                  {item.answer}
                </Text>
                <Text>
                  <span>
                    <FormattedMessage {...messages.user} /> :
                  </span>{' '}
                  {item.user}
                </Text>
              </Right>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default CustomerFeedbackListComponent;
