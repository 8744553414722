import gql from 'graphql-tag';

export const CreateTag = gql`
  mutation createTag($tag: TagCreate!) {
    createTag(tag: $tag) {
      id
    }
  }
`;

export const UpdateTag = gql`
  mutation updateTag($id: String!, $tag: TagUpdate!) {
    updateTag(id: $id, tag: $tag) {
      id
    }
  }
`;

export const ArchiveTag = gql`
  mutation archiveTag($id: String!) {
    archiveTag(id: $id) {
      archived
    }
  }
`;

export const MergeTags = gql`
  mutation mergeTags($tag: TagMerge!) {
    mergeTags(tag: $tag) {
      result
    }
  }
`;
