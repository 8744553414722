import {defineMessages} from 'react-intl';

const messages = defineMessages({
  guideOverview: {
    id: 'common.title.guideOverview',
    defaultMessage: 'Overview'
  },
  guideOverviewNew: {
    id: 'common.title.guideOverviewNew',
    defaultMessage: 'Guide Overview'
  },
  searchPlaceholder: {
    id: 'common.forms.searchPlaceholder',
    defaultMessage: 'Search...'
  },
  sortBy: {
    id: 'common.actions.sortBy',
    defaultMessage: 'Sort by'
  },
  select: {
    id: 'select',
    defaultMessage: 'Select'
  },
  status: {
    id: 'common.forms.status',
    defaultMessage: 'Status'
  },
  allGuides: {
    id: 'common.title.allGuides',
    defaultMessage: 'All Guides'
  },
  myGuides: {
    id: 'common.title.myGuides',
    defaultMessage: 'My Guides'
  },
  myTeamsGuides: {
    id: 'myTeamsGuides',
    defaultMessage: "My Team's Guides"
  },
  guideTitle: {
    id: 'guideTitle',
    defaultMessage: 'Guide title'
  },
  lastUpdated: {
    id: 'lastUpdated',
    defaultMessage: 'Last updated'
  },
  lastPublished: {
    id: 'lastPublished',
    defaultMessage: 'Last published'
  },
  dateCreated: {
    id: 'dateCreated',
    defaultMessage: 'Date created'
  },
  all: {
    id: 'all',
    defaultMessage: 'All'
  },
  published: {
    id: 'published',
    defaultMessage: 'Published'
  },
  draft: {
    id: 'draft',
    defaultMessage: 'Draft'
  },
  errorLoadingTags: {
    id: 'errorLoadingTags',
    defaultMessage: 'Error loading Tags'
  }
});

export default messages;
