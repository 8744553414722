import styled from 'styled-components';
import $ButtonGroup from 'ui-components/ButtonGroup';
import {commonPalette} from 'shared/styles/palette';

export const InnerWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 60%;
`;

export const RightSide = styled.div`
  background: #fafafa;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 40%;
`;

export const Label = styled.div`
  font-size: 24px;
  font-weight: normal;
  padding: 0 0 1.5rem;
`;

export const Content = styled.div`
  overflow-y: scroll;
  flex-grow: 1;
  padding: 1.5rem;
  display: block;
  flex: 1;
`;

export const UploadContentWrap = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ButtonGroup = styled($ButtonGroup)`
  padding: 2rem;
  @media (max-width: 450px) {
    display: block;
  }
`;

export const Note = styled.div`
  color: ${commonPalette.txGrey};
  letter-spacing: 0.33px;
  text-transform: uppercase;
  margin-left: 10px;
  display: inline;
  font-size: 10px;
`;
