import React, {Component} from 'react';
import {hexWithoutHash} from 'shared/utils/string-utils';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {bindField} from 'shared/utils/input-utils';
import {getIdealColors} from 'shared/utils/color-utils';
import {injectIntl} from 'react-intl';

//models
import GetColorPickerForm from 'stores/forms/color-picker-form';

//styled-components
import {
  ColorPicker,
  Box,
  StyledNumber,
  Label,
  Picker,
  HexTitle,
  InputWrap,
  Hash,
  Input,
  Color,
  PopoverTrigger,
  popoverClassName
} from './styles';

//components
import ButtonGroup from 'ui-components/ButtonGroup';
import Icon from 'ui-components/Layout/Icon';
import {FormattedMessage} from 'components/FormattedComponents';
import {Popover} from 'antd';

//messages
import messages from './messages';

@observer
class ColorPickerComponent extends Component {
  form = GetColorPickerForm();

  @observable popoverVisible;

  @action
  setPopoverVisible = popoverVisible => (this.popoverVisible = popoverVisible);

  @action
  closePopover = () => {
    this.setPopoverVisible(false);
    setTimeout(() => this.setPopoverVisible(true), 300);
  };

  cancel = () => {
    this.closePopover();
  };

  save = () => {
    const {form, field} = this.props;
    const newColorValue = `#${hexWithoutHash(this.form.$('color').value)}`;
    form.$(field).sync(newColorValue);
    this.closePopover();
  };

  onPopoverVisibleChange = visible => {
    const {form, field} = this.props;

    if (visible === true) {
      const colorValue = form.$(field).value;
      this.form.$('color').sync(hexWithoutHash(colorValue));
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {
      number,
      label,
      form,
      field,
      intl: {formatMessage}
    } = this.props;

    const color = form.$(field).value;
    const colorValue = this.form.$('color').value;

    return (
      <ColorPicker>
        <Box {...getIdealColors(color)}>
          <StyledNumber> {number} </StyledNumber>
          <Label> {label} </Label>

          <Popover
            {...(this.popoverVisible === false ? {visible: this.popoverVisible} : {})}
            onVisibleChange={this.onPopoverVisibleChange}
            overlayClassName={popoverClassName}
            placement="right"
            trigger="click"
            content={
              <Picker>
                <HexTitle>
                  <FormattedMessage {...messages.title} />
                </HexTitle>
                <InputWrap>
                  <Hash> # </Hash>
                  <Input {...bindField(this.form, 'color')} type="text" />
                  <Color color={this.form.isValid && hexWithoutHash(colorValue)} />
                </InputWrap>

                <ButtonGroup
                  forColorPicker
                  primary={{
                    label: formatMessage(messages.save),
                    disabled: !this.form.isValid,
                    onClick: this.save
                  }}
                  secondary={{
                    label: formatMessage(messages.cancel),
                    onClick: this.cancel
                  }}
                ></ButtonGroup>
              </Picker>
            }
          >
            <PopoverTrigger>
              <Icon name="pencil" />
            </PopoverTrigger>
          </Popover>
        </Box>
      </ColorPicker>
    );
  }
}

export default injectIntl(ColorPickerComponent);
