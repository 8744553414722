import styled from 'styled-components';
import flex from 'styles/flex';

//styled
import {Title as $Title} from 'shared/styles/styled-components/field-styled-components';

//components
import $MediaPickerWithButton from 'components/MediaPickerWithButton';
import Button from 'ui-components/Button';

export const Form = styled.div`
  min-width: 350px;
  padding: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleAndLink = styled.div`
  ${flex.horizontal};
  ${flex.centerHorizontalV};
  margin-bottom: 12px;
`;

export const Title = styled($Title)`
  margin-bottom: 0;
`;

export const Link = styled(Button).attrs({
  underlined: true
})`
  border-left: 1px solid gray;
  padding-left: 6px;
  margin-left: 6px;
`;

export const MediaPicker = styled($MediaPickerWithButton)`
  margin-bottom: 25px;
`;
