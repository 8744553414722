import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {findDOMNode} from 'react-dom';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

//lodash
import invoke from 'lodash/invoke';

//helpers
import {INSTRUCTION_TYPE} from 'shared/enums';

//styles
import {InnerWrapperLink, InstructionItem, StyledDragHandle, Title, ChecklistIcon} from './styles';

//mutations
import {RepositionTopicsAndInstructions} from 'api/topic/mutations';

//mutation-options
import {repositionTopicsAndInstructions} from 'api/topic/mutation-options';

@inject('store')
@graphql(RepositionTopicsAndInstructions, repositionTopicsAndInstructions)
@observer
class InstructionItemComponent extends Component {
  static defaultProps = {
    design: 'default'
  };

  static propTypes = {
    design: PropTypes.oneOf(['default', 'ghostly', 'highlight', 'opaque']),
    dragHandleRef: PropTypes.func,
    instruction: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      topicId: PropTypes.string.isRequired
    }).isRequired,
    onClick: PropTypes.func,
    onDragHandleMouseDown: PropTypes.func
  };

  render() {
    const {design, dragHandleRef, instruction, onClick, onDragHandleMouseDown, store} = this.props;
    const {id, title, topicId, type} = instruction;
    const {router} = store;
    const {id: guideId, instructionId} = router.params;

    const isCurrent = id === instructionId;
    const isGhostly = design === 'ghostly';
    const isChecklist = type === INSTRUCTION_TYPE.CHECKLIST;

    // NB: Draggable elements inside anchors and buttons
    // do not work if Firefox. That's why the drag handle
    // has to be outside the link component.
    //
    // https://bugzilla.mozilla.org/show_bug.cgi?id=568313

    return (
      <InstructionItem design={design} ref={this.handleHostInnerRef} isCurrent={isCurrent}>
        <InnerWrapperLink
          isInvisible={isGhostly}
          params={{id: guideId, topicId, instructionId: id}}
          queryParams={router.queryParams}
          store={store}
          route={views.editInstruction}
        >
          {isChecklist && <ChecklistIcon />}
          <Title>{title}</Title>
        </InnerWrapperLink>
        <StyledDragHandle isInvisible={isGhostly} forwardedRef={dragHandleRef} onMouseDown={onDragHandleMouseDown} />
      </InstructionItem>
    );
  }

  handleHostInnerRef = ref => {
    const node = findDOMNode(ref);
    invoke(this.props, 'hostRef', node);
  };
}

export default InstructionItemComponent;
