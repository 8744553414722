import {defineMessages} from 'react-intl';

const messages = defineMessages({
  checklistAlerts: {
    id: 'checklistAlerts',
    defaultMessage: 'Checklist Alerts'
  },
  checklistAlertsInfo: {
    id: 'checklistAlertsInfo',
    defaultMessage: 'Checklist Alerts keep you informed about deviations.'
  },
  recipients: {
    id: 'recipients',
    defaultMessage: 'Recipients'
  },
  platformAlerts: {
    id: 'platformAlerts',
    defaultMessage: 'Platform Alerts'
  }
});

export default messages;
