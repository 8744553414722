import React, {Component} from 'react';
import equal from 'fast-deep-equal';

import MeasureImage from './MeasureImage';
import Draggers from './Draggers';
import Canvas from './Canvas';

import {calculateAspectRatioFit, calculateRotatedMeta, scaleObjectValues, calculateRotatedXyScale} from './utils';

class ImageFocusCrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      metadataIn: {},
      metadataOut: {},
      isCropping: false
    };
  }
  componentDidUpdate(prevProps) {
    if (!equal(this.props, prevProps)) {
      if (
        prevProps.metadata &&
        this.props.metadata &&
        (this.props.metadata.rotation !== prevProps.metadata.rotation ||
          this.props.metadata.contain !== prevProps.metadata.contain)
      ) {
        this.initState();
      }
    }
  }
  saveWrapperDimensions = node => {
    if (node) {
      const boundingBox = node.getBoundingClientRect();
      this.setState({
        wrapperWidth: boundingBox.width,
        wrapperHeight: boundingBox.height
      });
    }
  };
  saveImageDimensions = imageWidthHeight => {
    const {height: imageHeight, width: imageWidth} = imageWidthHeight;
    const {setOriginalSize} = this.props;
    this.setState({imageHeight, imageWidth});
    setOriginalSize({height: imageHeight, width: imageWidth});
  };

  canvasLoaded = () => {
    this.initState();
  };

  initState = () => {
    const {metadata, imageSrc} = this.props;
    const {imageHeight, imageWidth} = this.state;

    const defaultMetadata = {
      crop: {x: 0, y: 0, w: imageWidth, h: imageHeight},
      rotation: 0,
      focus: {kx: 0.5, ky: 0.5}
    };

    const mergedMetadataIn = {...defaultMetadata, ...metadata};
    this.setState(
      {
        imageSrc,
        metadataIn: mergedMetadataIn
      },
      () => {
        this.calculateFromIn(mergedMetadataIn);
      }
    );
  };
  calculateFromIn = metadataIn => {
    const {
      // metadataIn,
      imageWidth,
      imageHeight,
      wrapperWidth,
      wrapperHeight,
      isCropping
    } = this.state;
    const calculateWidthRotation = isCropping ? 0 : metadataIn.rotation;
    const rotationHelpers = calculateAspectRatioFit(
      imageWidth,
      imageHeight,
      wrapperWidth,
      wrapperHeight,
      metadataIn.rotation
    );

    const metadataRotated = calculateRotatedMeta(metadataIn, calculateWidthRotation, imageWidth, imageHeight);

    const {x: kx, y: ky} = calculateRotatedXyScale(metadataIn.focus.kx, metadataIn.focus.ky, calculateWidthRotation);

    const metadataOut = {
      crop: {
        w: metadataRotated.w,
        h: metadataRotated.h,
        x: metadataRotated.x,
        y: metadataRotated.y
      },
      focus: {
        kx,
        ky
      },
      rotation: metadataIn.rotation,
      contain: metadataIn.contain
    };
    this.setState({metadataOut, rotationHelpers}, () => {
      this.props.onChangeHandler({
        crop: metadataOut.crop,
        focus: metadataOut.focus
      });
    });
  };

  setMetadataIn = newMetadata => {
    const mergedMetadataIn = {
      ...this.state.metadataIn,
      ...newMetadata
    };
    this.setState(
      {
        metadataIn: mergedMetadataIn
      },
      () => {
        this.calculateFromIn(mergedMetadataIn);
      }
    );
  };

  croppingHandler = (crop, focus) => {
    this.setMetadataIn({crop, focus});
  };
  croppingStopHandler = () => {
    this.setState({isCropping: false});
  };
  croppingStartHandler = () => {
    this.setState({isCropping: true});
  };

  render() {
    const {imageSrc, height: givenHeight = 300, width: givenWidth = 300, isSvg} = this.props;
    const {wrapperHeight, wrapperWidth, imageHeight, imageWidth, rotationHelpers = {}, metadataOut} = this.state;

    if (!metadataOut) {
      return null;
    }
    return (
      <div
        style={{
          height: givenHeight, // can be whatever
          width: givenWidth, // even percentages
          background: '#ededed',
          position: 'relative'
        }}
        ref={this.saveWrapperDimensions}
      >
        <MeasureImage onMeasure={this.saveImageDimensions} src={imageSrc} />
        {wrapperHeight > 0 && imageHeight > 0 && (
          <div>
            <Canvas
              imageSrc={imageSrc}
              wrapperWidth={wrapperWidth}
              wrapperHeight={wrapperHeight}
              crop={metadataOut.crop}
              focus={metadataOut.focus}
              contain={metadataOut.contain}
              rotation={metadataOut.rotation}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              scale={rotationHelpers.scale}
              rotatedWidth={rotationHelpers.rotationWidth}
              rotatedHeight={rotationHelpers.rotationHeight}
              rotatedX={rotationHelpers.translatedX}
              rotatedY={rotationHelpers.translatedY}
              canvasLoadedHandler={this.canvasLoaded}
            />
            <Draggers
              width={rotationHelpers.rotationWidth}
              height={rotationHelpers.rotationHeight}
              offsetX={rotationHelpers.translatedX}
              offsetY={rotationHelpers.translatedY}
              focus={metadataOut.focus}
              contain={metadataOut.contain}
              isSvg={isSvg}
              crop={scaleObjectValues(metadataOut.crop, 1 / rotationHelpers.scale, true)}
              croppingStartHandler={this.croppingStartHandler}
              croppingStopHandler={crop =>
                this.croppingStopHandler(scaleObjectValues(crop, rotationHelpers.scale, true))
              }
              croppingHandler={(crop, focus) =>
                this.croppingHandler(scaleObjectValues(crop, rotationHelpers.scale, true), focus)
              }
            />
          </div>
        )}
      </div>
    );
  }
}
export default ImageFocusCrop;
