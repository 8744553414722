import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

export const Item = styled.div`
   {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: ${commonPalette.txBlack};
    width: 242px;

    &:hover {
      background-color: #eee;
      color: ${commonPalette.txBlack};
    }
  }
`;
