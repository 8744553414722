import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  flex-grow: ${props => props.value};
  width: ${({percentage}) => (percentage > 0 ? percentage + '%' : '')};
  min-width: ${({percentage}) => (percentage > 0 ? '35px' : '')};
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
`;

export const Sausage = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  padding-inline: ${({percentage}) => (percentage > 0 ? '8' : '16')}px;
  border-radius: 99px;
  background-color: ${props => (!props.outline || props.preserveBackground ? props.color : 'white')};
  color: ${props => (!props.outline ? 'white' : 'black')};
  ${props => props.outline && 'border: 1px solid ' + props.color};
`;
