import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Field from 'ui-components/Field';
import Select from 'ui-components/Select';
import RangePicker from 'components/RangePicker';

//styles
import {FollowUpQuestion, Icon, FollowUpKindPicker, Title} from './styles';

//messages
import messages from './messages';

@observer
class FollowUpQuestionComponent extends Component {
  saveBinaryValue = values => {
    const {index, saveBinaryValue} = this.props;
    saveBinaryValue(values, index);
  };

  render() {
    const {
      intl: {formatMessage},
      followUp,
      binarySelectValues,
      binaryOptions,
      removeFollowUpFunc,
      minRating,
      maxRating,
      autofocus = false,
      isBinaryQuestionType,
      areFollowUpsRangesValid,
      index = 0
    } = this.props;
    const {form, isRangeValid} = followUp;

    return (
      <FollowUpQuestion>
        <Title>
          <FormattedMessage {...messages.followUpQuestion} values={{n: index + 1}} />
        </Title>
        <Icon name="close" onClick={() => removeFollowUpFunc(followUp)} />
        {isBinaryQuestionType ? (
          <Field
            inputComponent={
              <Select
                dataCy="follow-up-for-answer"
                label={formatMessage(messages.forAnswer)}
                placeholder={formatMessage(messages.binaryPlaceholder)}
                options={binaryOptions}
                selectedValues={binarySelectValues}
                onChange={this.saveBinaryValue}
              />
            }
          />
        ) : (
          <RangePicker
            autofocus={autofocus}
            form={form}
            minRating={minRating}
            maxRating={maxRating}
            isRangeValid={isRangeValid(minRating, maxRating)}
            showRangeError={!areFollowUpsRangesValid || !isRangeValid(minRating, maxRating)}
          />
        )}

        <Field
          title={formatMessage(messages.question)}
          placeholder={formatMessage(messages.followUpPlaceholder)}
          field="question"
          form={form}
        />

        <Field inputComponent={<FollowUpKindPicker form={form} field="kind" />} />
      </FollowUpQuestion>
    );
  }
}

export default injectIntl(FollowUpQuestionComponent);
