import gql from 'graphql-tag';

export const CreateTopic = gql`
  mutation createTopic($topic: TopicCreate!) {
    createTopic(topic: $topic) {
      id
    }
  }
`;

export const DeleteTopic = gql`
  mutation deleteTopic($id: String!) {
    deleteTopic(id: $id) {
      result
    }
  }
`;

export const UpdateTopic = gql`
  mutation updateTopic($id: String!, $topic: TopicUpdate!) {
    updateTopic(id: $id, topic: $topic) {
      id
    }
  }
`;

export const RepositionTopicsAndInstructions = gql`
  mutation repositionTopicsAndInstructions($topicsInstructionsAndPositions: [TopicsInstructionsAndPositions]!) {
    repositionTopicsAndInstructions(topicsInstructionsAndPositions: $topicsInstructionsAndPositions) {
      result
    }
  }
`;

export const MoveTopic = gql`
  mutation moveTopic($id: String!, $guideId: String!) {
    moveTopic(id: $id, guideId: $guideId) {
      result
    }
  }
`;
