import styled from 'styled-components';

//helpers
import {commonPalette} from 'shared/styles/palette';

//components
import $Icon from 'ui-components/Layout/Icon';

//contants
const badgeBackground = commonPalette.red;
const badgeFontSize = 10;
const badgeForeground = '#fff';
const badgeSize = 15;
const badgeX = -7;
const badgeY = -7;

export const EnhancedIcon = styled.div`
  display: inline-block;
  height: ${p => p.size}px;
  position: relative;
  vertical-align: ${p => p.valign};
  width: ${p => p.size}px;
`;

export const StyledIcon = styled($Icon)`
  left: 50%;
  margin-left: ${p => `${-p.size / 2}px`};
  margin-top: ${p => `${-p.size / 2}px`};
  position: absolute;
  top: 50%;
`;

const AbstractBadge = styled.div`
  bottom: 50%;
  left: 50%;
  margin-bottom: ${p => p.baseSize / 2 + badgeX}px;
  margin-left: ${p => p.baseSize / 2 + badgeY}px;
  position: absolute;
  z-index: 0;
`;

export const StyledBadgeIcon = styled(AbstractBadge.withComponent($Icon)).attrs({
  name: 'warning-round',
  size: badgeSize
})`
  color: ${badgeBackground};
  z-index: 0;

  &::after {
    background: ${badgeForeground};
    border-radius: 50%;
    bottom: 1px;
    content: '';
    left: 1px;
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: -1;
  }
`;

export const Badge = styled(AbstractBadge)`
  background: ${badgeBackground};
  border-radius: ${badgeSize / 2}px;
  color: ${badgeForeground};
  font-size: ${badgeFontSize}px;
  height: ${badgeSize}px;
  line-height: ${badgeSize}px;
  min-width: ${badgeSize}px;
  padding: 0 4px;
  text-align: center;
`;
