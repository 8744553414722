import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, action, computed, makeObservable} from 'mobx';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {styleable} from 'shared/decorators';

//styled-components
import {
  SelectWrapper,
  Select,
  OptionsWrapper,
  Option,
  Button,
  Icon,
  CardLevel,
  CardLevelNum,
  StatusDiv,
  InputField,
  InputText
} from './styles';
import Dropdown from '../../ui-components/Dropdown';

//messages
import messages from './messages';

@styleable
@observer
class AssessSkillDropdownComponent extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static defaultProps = {
    idProp: 'id',
    disabled: false,
    filterOption: [],
    optionFormatter: 'name',
    options: [],
    style: {}
  };

  static propTypes = {
    className: PropTypes.string,
    dataCy: PropTypes.string,
    idProp: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    field: PropTypes.string,
    filterOption: PropTypes.arrayOf(PropTypes.string),
    form: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    notFoundContent: PropTypes.string,
    onChange: PropTypes.func,
    optionFormatter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    selectedValue: PropTypes.string,
    selectedValues: PropTypes.arrayOf(PropTypes.string),
    style: PropTypes.object
  };

  @observable selectorOpened = false;
  @observable userInput = '';

  @action toggleSelector = () => {
    this.selectorOpened = !this.selectorOpened;
  };

  @action onChange = value => {
    const {disabled, form, field, onChange} = this.props;

    if (disabled) return;

    if (this.showMultiSelector) {
      const selectedIds = this.selectedOptionsIds.includes(value)
        ? this.selectedOptionsIds.filter(id => id !== value || this.isDisabled(id))
        : [...this.selectedOptionsIds, value];

      if (form && field) form.$(field).sync(selectedIds);
      if (onChange) onChange(selectedIds);
    } else {
      if (form && field) form.$(field).sync(value);
      if (onChange) onChange(value);
    }

    this.userInput = '';

    if (!this.showMultiSelector || !this.selectorOpened) this.toggleSelector();
  };

  @action isSelected = option => {
    return this.showMultiSelector ? this.selectedOptionsIds.includes(option.id) : this.selectedOptionId === option.id;
  };

  @action isDisabled = id => {
    const {options} = this.props;
    const option = options.find(opt => opt.id === id);
    return !!option.disabled;
  };

  @computed get options() {
    const {options, filterOption, idProp} = this.props;

    const filteredOptions = options.filter(
      option =>
        !filterOption.includes(option[idProp]) ||
        option[idProp] === this.selectedOptionId ||
        this.selectedOptionsIds?.includes(option[idProp])
    );

    if (!this.userInput) return filteredOptions;
    return filteredOptions.filter(option =>
      this.formatOption(option).toLowerCase().trim().includes(this.userInput.toLowerCase().trim())
    );
  }

  @computed get selectedOptionId() {
    const {defaultValue, field, form, options, selectedValue} = this.props;

    if (this.showMultiSelector) return null;

    if (form && field) return form.values()[field];

    if (selectedValue) return selectedValue;

    if (defaultValue) return defaultValue;

    if (options.length) return options[0].id;

    return null;
  }

  @computed get selectedOption() {
    const {options, idProp} = this.props;

    if (!this.selectedOptionId) return null;

    return options.find(option => option[idProp] === this.selectedOptionId) || null;
  }

  @computed get selectedOptionName() {
    return this.selectedOption ? this.formatOption(this.selectedOption) : '';
  }

  @computed get selectedOptionsIds() {
    const {defaultValue, field, form, selectedValues} = this.props;

    if (!this.showMultiSelector) return null;

    if (form && field) return form.values()[field];

    if (selectedValues) return selectedValues;

    if (defaultValue) return [defaultValue];

    return [];
  }

  formatOption = option => {
    const {optionFormatter} = this.props;

    if (typeof optionFormatter === 'function') {
      return optionFormatter(option);
    }

    return option[optionFormatter];
  };

  renderOptions = () => {
    const {
      dataCy,
      intl: {formatMessage},
      idProp,
      notFoundContent,
      style,
      testId
    } = this.props;

    if (!this.options.length)
      return (
        <OptionsWrapper {...(dataCy && {'data-cy': `${dataCy}-options`})} style={style.OptionsWrapper}>
          <Option disabled>{notFoundContent || formatMessage(messages.nothingToSelect)}</Option>
        </OptionsWrapper>
      );

    return (
      <OptionsWrapper {...(dataCy && {'data-cy': `${dataCy}-options`})} style={style.OptionsWrapper}>
        {this.options?.map((option, index) => (
          <CardLevel key={option[idProp]}>
            <Option
              data-testid={`option-${testId}-${index}`}
              {...(dataCy && {'data-cy': `${dataCy}-option`})}
              onClick={() => !option.disabled && this.onChange(option[idProp])}
              selected={this.isSelected(option)}
              role="option"
              aria-selected={this.isSelected(option)}
              disabled={option.disabled}
              style={option.style}
              hidden={option.hidden}
            >
              <CardLevelNum>{option.position}</CardLevelNum>
              {option.iconId && <Icon iconId={option.iconId} />}
              {this.formatOption(option)}
              {option.required && <StatusDiv>REQUIRED</StatusDiv>}
              {option.currentLevel && <StatusDiv>CURRENT</StatusDiv>}
            </Option>
          </CardLevel>
        ))}
      </OptionsWrapper>
    );
  };

  renderSelector = () => {
    const {dataCy, disabled, placeholder, style, width, testId} = this.props;
    const Selector = Select;

    return (
      <Selector
        data-cy={dataCy}
        data-testid={testId}
        disabled={disabled}
        iconId="sort"
        iconOnRight
        label={this.selectedOptionName || placeholder}
        open={!disabled && this.selectorOpened}
        style={style.Selector}
        width={width}
      >
        <InputField>
          {this.selectedOption && <CardLevelNum>{this.selectedOption.position}</CardLevelNum>}
          <InputText hasSelectedOption={this.selectedOption}>{this.selectedOptionName || placeholder}</InputText>
          {this.selectedOption?.required && <StatusDiv>REQUIRED</StatusDiv>}
          {this.selectedOption?.currentLevel && <StatusDiv>CURRENT</StatusDiv>}
        </InputField>

        {this.selectedOption && !this.selectorOpened ? (
          <Button iconId="close" onClick={() => this.onChange(null)} />
        ) : (
          <Button iconId="sort" />
        )}
      </Selector>
    );
  };

  render() {
    const {dataCy, disabled, style, display, width} = this.props;

    const SelectDropdown = Dropdown;

    return (
      <SelectWrapper id={`select-wrapper-${dataCy}`} style={style} display={display} role="listbox">
        <SelectDropdown
          disabled={disabled}
          open={!disabled && this.selectorOpened}
          visible={!disabled && this.selectorOpened}
          toggle={this.toggleSelector}
          onVisibleChange={this.toggleSelector}
          overlayClassName="c-header__popover__inner"
          placement="bottomRight"
          trigger="click"
          content={this.renderOptions()}
          getPopupContainer={() => document.getElementById(`select-wrapper-${dataCy}`)}
          width={width}
        >
          {this.renderSelector()}
        </SelectDropdown>
      </SelectWrapper>
    );
  }
}

export default injectIntl(AssessSkillDropdownComponent);
