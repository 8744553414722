export const sortByPropName = propName => (a, b) => {
  if (!a[propName]) {
    return -1;
  }

  if (!b[propName]) {
    return 1;
  }

  const propA = a[propName].toUpperCase();
  const propB = b[propName].toUpperCase();

  if (propA < propB) {
    return -1;
  }

  if (propA > propB) {
    return 1;
  }

  return 0;
};
