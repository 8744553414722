import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    defaultMessage: 'Set Alert Recipients',
    id: 'checklistAlertRecipientsDialog.title'
  },
  subtitle: {
    defaultMessage: 'Set Recipients',
    id: 'checklistAlertRecipientsDialog.selectAlertRecipients'
  },
  alertRecipientsPlaceholder: {
    defaultMessage: 'Select up to 4 recipients',
    id: 'checklistAlertRecipientsDialog.selectAlertRecipientsPlaceholder'
  },
  save: {
    defaultMessage: 'Set recipients',
    id: 'checklistAlertRecipientsDialog.setRecipients'
  },
  success: {
    defaultMessage: 'Successfully set alert recipients for {checklistName} checklist.',
    id: 'checklistAlertRecipientsDialog.success'
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'common.actions.cancel'
  },
  error: {
    defaultMessage: 'Failed to set alert recipients {checklistName} checklist. Please try again.',
    id: 'checklistAlertRecipientsDialog.error'
  },
  noRecipients: {
    defaultMessage:
      'You don’t have any recipients identified yet. Go to Checklist Alerts in Settings to add a new recipient.',
    id: 'checklistAlertRecipientsDialog.noRecipients'
  }
});

export default messages;
