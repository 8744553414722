import styled from 'styled-components';

export const Rating = styled.div`
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  color: ${p => (p.color ? p.color : '#000000')};
`;
export const YesNoAnswer = styled.div`
  font-weight: 600;
`;

export const Text = styled.div`
  span {
    opacity: 0.7;
  }
`;
export const Title = styled.div`
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
export const Left = styled.div`
  width: 80px;
  text-align: right;
  margin-right: 16px;
`;
export const Right = styled.div`
  flex: 1;
`;
