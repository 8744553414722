import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  selectUser: {
    id: 'UserRole.selectUser',
    defaultMessage: 'User'
  },
  noUsers: {
    id: 'UserRole.noUsers',
    defaultMessage: 'There are no users'
  },
  selectRole: {
    id: 'UserRole.selectRole',
    defaultMessage: 'Role'
  },
  noJobTitle: {
    id: 'common.forms.noJobTitle',
    defaultMessage: 'No Job Title'
  },
  selectJobTitle: {
    id: 'UserRole.selectJobTitle',
    defaultMessage: 'Job Title'
  },
  addAnotherUser: {
    id: 'UserRole.addAnotherUser',
    defaultMessage: 'Add another user'
  },
  turnOnGuideApproval: {
    id: 'UserRole.turnOnGuideApproval',
    defaultMessage: 'Turn on Guide Approval for this Team'
  },
  approver: {
    id: 'common.field.approver',
    defaultMessage: 'Approver'
  },
  guideApprovalDescription: {
    id: 'common.description.guideApprovalDescription',
    defaultMessage: 'Guide Approval requires all content to be approved by an Approver before publishing'
  }
});

export default messages;
