import {observable, action, computed, makeObservable} from 'mobx';
import get from 'lodash/get';
class ChecklistsPage {
  @observable checklistAnswers = [];
  @observable searchTerm = '';
  @observable currentChecklistSessionDetails;
  @observable checklistType = null;

  @action
  setChecklistAnswers = checklistAnswers => {
    this.checklistAnswers = checklistAnswers;
  };

  @action
  setChecklistType = checklistType => {
    this.checklistType = checklistType;
  };

  @action
  setSearchTerm = searchTerm => {
    this.searchTerm = searchTerm;
  };

  @action
  setCurrentChecklistSessionDetails = currentChecklistSessionDetails => {
    this.currentChecklistSessionDetails = currentChecklistSessionDetails;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentChecklistSessionItems() {
    const items = get(this.currentChecklistSessionDetails, 'items', []);
    return items.slice().sort((a, b) => (a.step.position > b.step.position ? 1 : -1));
  }

  @computed
  get filteredChecklistAnswers() {
    return this.checklistAnswers.filter(
      ({instruction}) =>
        typeof instruction.title === 'string' && instruction.title.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
}

export default ChecklistsPage;
