import {observable, action, makeObservable} from 'mobx';

class AdminPage {
  @observable selectedTag;
  @action selectTag = ([tag]) => (this.selectedTag = tag);
  @action clearSelectedTag = () => (this.selectedTag = null);

  constructor() {
    makeObservable(this);
  }
}

export default AdminPage;
