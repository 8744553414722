import {defineMessages} from 'react-intl';

const messages = defineMessages({
  defaultTopic: {
    id: 'EditGuide.defaultTopicV2',
    defaultMessage: 'Default topic'
  },
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  },
  updateSuccess: {
    id: 'EditTopic.updateSuccess',
    defaultMessage: 'Topic has been updated.'
  },
  updateError: {
    id: 'EditTopic.updateError',
    defaultMessage: 'Error updating topic.'
  },
  instructionCount: {
    id: 'EditGuide.instructionCount',
    defaultMessage: '{count, number} {count, plural, one {instruction} other {instructions}}'
  }
});

export default messages;
