import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

//styled-components
import {TeamPicker} from './styles';

//messages
import messages from './messages';

@observer
class TeamPickerComponent extends Component {
  render() {
    const {
      allowClear = false,
      className,
      disabled,
      field,
      filterOption,
      form,
      intl: {formatMessage},
      label,
      onChange,
      teams = [],
      selectedValue,
      dataCy,
      onScrollToBottom,
      onSearch
    } = this.props;

    return (
      <TeamPicker
        allowClear={allowClear}
        className={className}
        disabled={disabled}
        dataCy={dataCy}
        field={field}
        filterOption={filterOption}
        form={form}
        label={label}
        placeholder={formatMessage(messages.placeholder)}
        onChange={onChange}
        options={teams}
        showSearch={true}
        selectedValue={selectedValue}
        onScrollToBottom={onScrollToBottom}
        onSearch={onSearch}
      />
    );
  }
}

export default injectIntl(TeamPickerComponent);
