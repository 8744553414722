export const tagsListOptions = {
  options: () => {
    return {
      fetchPolicy: 'network-only'
    };
  }
};

export const tagsLiteOptions = {
  options: () => {
    return {
      fetchPolicy: 'network-only'
    };
  },
  name: 'tagsQuery'
};

export const tagOptions = {
  options: ({store: {router}, id}) => {
    const idToQuery = id ? id : router.params.id;
    return {
      fetchPolicy: 'network-only',
      variables: {
        id: idToQuery
      }
    };
  }
};
