export const cardToolbarMetrics = {
  gutter: 10,
  size: 24
};

export const headerMetrics = {
  height: 48
};

export const guideCardMetrics = {
  width: 210,
  height: 250,
  gutterX: 25,
  gutterY: 25,
  borderRadius: 4,
  smallBorderRadius: 2
};

export const stepCardMetrics = {
  width: 240,
  height: 338,
  gutterX: 50,
  gutterY: 40
};

export const tableMetrics = {
  baseNestingIndentation: 10,
  cellMarginLeft: 0,
  cellMarginRight: 32,
  minHeight: 230,
  nestingIndentation: 4
};

export const topicCardMetrics = {
  detailsPaddingX: 32,
  dragHandleWidth: 53,
  innerPaddingBottomWhenOpaque: 24,
  innerPaddingTopWhenOpaque: 22,
  innerPaddingX: 20
};

// To align its text with the sibling instruction's texts
topicCardMetrics.detailsPulloutX = topicCardMetrics.innerPaddingX - topicCardMetrics.detailsPaddingX;

export const topicListMetrics = {
  instructionGutter: 10,
  topicGutter: 50
};

export const instructionCardMetrics = {
  maxWidth: 760,
  minWidth: 544
};
