import PropTypes from 'prop-types';
import React from 'react';

//contants
const directions = ['right', 'bottom-right', 'bottom', 'bottom-left', 'left', 'top-left', 'top', 'top-right'];
const rotations = directions.reduce((rotations, direction, index) => {
  rotations[direction] = (360 / directions.length) * index;
  return rotations;
}, {});

const Arrow = ({direction, size, ...forwardedProps}) => (
  <svg height={size} viewBox="0 0 24 24" width={size} {...forwardedProps}>
    <path
      d="M21.77 10.67l-6.36-6.1a1.58 1.58 0 0 0-2.14 0c-.6.56-.6 1.5 0 2.06l3.75 3.62H3.12c-.83 0-1.5.65-1.5 1.46 0 .81.67 1.46 1.52 1.46h13.9l-3.76 3.6c-.6.56-.6 1.5 0 2.06a1.56 1.56 0 0 0 2.17 0l6.33-6.08c.29-.27.46-.65.46-1.04 0-.4-.15-.77-.46-1.04z"
      fill="currentColor"
      transform={`translate(12 12) rotate(${rotations[direction]}) translate(-12 -12)`}
    />
  </svg>
);

Arrow.defaultProps = {
  size: 24
};

Arrow.propTypes = {
  direction: PropTypes.oneOf(directions).isRequired,
  size: PropTypes.number
};

export default Arrow;
