import {defineMessages} from 'react-intl';

const messages = defineMessages({
  newAlertRecipient: {
    id: 'newAlertRecipient',
    defaultMessage: 'New Alert Recipient'
  },
  alertRecipientName: {
    id: 'alertRecipientName',
    defaultMessage: 'Alert Recipient Name'
  },
  deliveryMethods: {
    id: 'deliveryMethods',
    defaultMessage: 'Delivery Methods'
  },
  methodType: {
    id: 'methodType',
    defaultMessage: 'Method Type'
  },
  selectAlertMethod: {
    id: 'selectAlertMethod',
    defaultMessage: 'Select alert method type'
  },
  webhookUrlFieldLabel: {
    id: 'WebhookUrlSetting.webhookUrlFieldLabel',
    defaultMessage: 'Webhook URL'
  },
  copyPasteUrl: {
    id: 'copyPasteUrl',
    defaultMessage: 'Copy and paste your webhook URL here'
  },
  customWebhook: {
    id: 'customWebhook',
    defaultMessage: 'Custom Webhook'
  },
  create: {
    id: 'create',
    defaultMessage: 'Create'
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel'
  },
  createdAlertRecipient: {
    id: 'createdAlertRecipient',
    defaultMessage: 'Successfully created a new alert recipient.'
  },
  createAlertRecipientFailed: {
    id: 'createAlertRecipientFailed',
    defaultMessage: 'Failed to create a new alert recipient. Please try again.'
  },
  editAlertRecipient: {
    id: 'editAlertRecipient',
    defaultMessage: 'Edit Alert Recipient'
  },
  save: {
    id: 'save',
    defaultMessage: 'Save'
  },
  editedAlertRecipient: {
    id: 'editedAlertRecipient',
    defaultMessage: 'Successfully edited an alert recipient.'
  },
  editAlertRecipientFailed: {
    id: 'editAlertRecipientFailed',
    defaultMessage: 'Failed to edit an alert recipient. Please try again.'
  },
  teamsWebhookUrlFieldLabel: {
    id: 'WebhookUrlSetting.teamsWebhookUrlFieldLabel',
    defaultMessage: 'Teams Webhook URL'
  }
});

export default messages;
