import {isEmpty} from 'lodash';
import {autorun} from 'mobx';
import {MobXProviderContext} from 'mobx-react';
import {Route} from 'mobx-router';
import {useContext, useEffect} from 'react';

import {ROOT_PATH} from '../../config/constants';

export const mapObjectsInfo = (route: Route<any, any, any>, params: Record<string, string>) => {
  switch (true) {
    case route.path?.startsWith(`${ROOT_PATH}/guides`): {
      const {id, instructionId, topicId, stepId} = params;

      return {
        guideId: id,
        instructionId,
        topicId,
        stepId
      };
    }
    default: {
      return {};
    }
  }
};

export const useRouteTracking = () => {
  const {store} = useContext(MobXProviderContext);

  useEffect(
    () =>
      autorun(() => {
        const {analytics, router} = store;
        const {currentRoute, params, queryParams} = router;
        const {trackPageViewEvent} = analytics;
        const {href} = window.location;
        const actionContent = JSON.stringify({
          href,
          path: currentRoute.path,
          ...(!isEmpty(params) && {params}),
          ...(!isEmpty(queryParams) && {queryParams})
        });

        trackPageViewEvent({
          action: 'cmspageview',
          actionContent,
          objectsInfo: mapObjectsInfo(currentRoute, params)
        });
      }),
    []
  );
};

export const RouteTracker = () => {
  useRouteTracking();
  return null;
};
