import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//lodash
import uniqueId from 'lodash/uniqueId';
import invoke from 'lodash/invoke';

//styled-components
import {TranslateTags, Header, HeaderLead, HeaderButton, StyledArrow, StyledDataTable, TableInputField} from './styles';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import LanguagePicker from 'components/LanguagePicker';

//helpers
import notification from 'utils/notification-utils';

//messages
import messages from './messages';

@inject('store')
@observer
class TranslateTagsView extends Component {
  id = uniqueId('TranslateTags-');

  importTranslations = () => {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {uploadTranslationsDialog: dialog, tagTranslationsPage} = store;

    dialog.open(
      infoCode => {
        const successMessage = this.getNotificationMessage(
          infoCode,
          formatMessage(messages.importSuccess),
          formatMessage
        );
        invoke(tagTranslationsPage, 'refetchTagsQuery');
        notification.success(successMessage);
      },
      'tags',
      infoCode => {
        const errorMessage = this.getNotificationMessage(
          infoCode,
          formatMessage(messages.importFailure),
          formatMessage
        );
        notification.error(errorMessage);
      }
    );
  };

  getResponseAdditionalInfo = (infoCode, formatMessage) =>
    !messages[infoCode] ? '' : formatMessage(messages[infoCode]);
  getNotificationMessage = (infoCode, generalMessage, formatMessage) => {
    const allMessages = [generalMessage];

    const additionalInfo = this.getResponseAdditionalInfo(infoCode, formatMessage);

    if (additionalInfo) {
      allMessages.push(additionalInfo);
    }

    const message = allMessages.join('-');
    return message;
  };

  exportTranslations = () => {
    const origin = window.location.origin.replace('3033', '3000');
    const exportTagsURL = `${origin}/translation/tags`;
    window.open(exportTagsURL);
  };

  getRowClassName = item => {
    const {
      store: {tagTranslationsPage}
    } = this.props;
    const {selectedTagId, selectedTargetLocale} = tagTranslationsPage;

    if (item.disabled || !selectedTargetLocale) {
      return '-disabled';
    }

    if (selectedTagId === item.id && selectedTargetLocale) {
      return '-active';
    }

    return '';
  };

  getHeaderId = () => `${this.id}-header`;

  getRowId = item => `${this.id}-${item.type}-${item.id}`;

  selectTag = tag => {
    const {store} = this.props;
    const {tagTranslationsPage} = store;
    const {selectedTag, selectedTargetLocale, setSelectedTag} = tagTranslationsPage;

    if (tag !== selectedTag && selectedTargetLocale) {
      setSelectedTag(tag);
    }
  };

  render() {
    const {
      store: {tagTranslationsPage},
      intl: {formatMessage}
    } = this.props;
    const {
      sourceLanguages,
      targetLanguages,
      selectedSourceLocale,
      selectedTargetLocale,
      setSelectedSourceLocale,
      setSelectedTargetLocale,
      tagsTranslationData,
      selectedSourceLanguage,
      selectedTargetLanguage,
      changeTranslation
    } = tagTranslationsPage;

    return (
      <TranslateTags>
        <ViewTitle title={formatMessage(messages.title)} />
        <Header>
          <HeaderLead>
            <FormattedMessage {...messages.translating} />
          </HeaderLead>
          <LanguagePicker
            hideLabel
            dataCy="tag-source-language"
            languages={sourceLanguages}
            selectedLanguage={selectedSourceLocale}
            changeLanguage={setSelectedSourceLocale}
            style={{width: 180}}
          />
          <StyledArrow />
          <LanguagePicker
            allowClear
            hideLabel
            dataCy="tag-target-language"
            languages={targetLanguages}
            placeholder={formatMessage(messages.targetLanguage)}
            selectedLanguage={selectedTargetLocale}
            changeLanguage={setSelectedTargetLocale}
            style={{width: 180}}
          />
          <HeaderButton onClick={this.importTranslations}>
            <FormattedMessage {...messages.import} />
          </HeaderButton>
          <HeaderButton onClick={this.exportTranslations}>
            <FormattedMessage {...messages.export} />
          </HeaderButton>
        </Header>
        <StyledDataTable
          sortable={false}
          data={tagsTranslationData}
          columns={[
            {
              Header: formatMessage(messages.defaultLanguage),
              accessor: 'defaultLanguage'
            },
            {
              Header: formatMessage(messages.sourceText),
              accessor: 'title'
            },
            {
              Header: `${selectedSourceLanguage}`,
              accessor: 'translateFrom',
              show: selectedSourceLocale !== 'default'
            },
            {
              Header: `${selectedTargetLanguage}`,
              Cell: row => {
                return (
                  <TableInputField
                    type="text"
                    id={row.original.id}
                    value={row.original.translateTo}
                    disabled={row.original.disabled || !selectedTargetLocale}
                    onChange={e => changeTranslation(e.target.value)}
                    onFocus={() => this.selectTag(row.original)}
                  />
                );
              }
            }
          ]}
          primaryAction={tag => this.selectTag(tag)}
          getTheadProps={() => ({
            id: this.getHeaderId()
          })}
          getTrProps={(state, rowInfo) => ({
            className: this.getRowClassName(rowInfo.original),
            id: this.getRowId(rowInfo.original)
          })}
        />
      </TranslateTags>
    );
  }
}

export default injectIntl(TranslateTagsView);
