import {defineMessages} from 'react-intl';

const messages = defineMessages({
  yourInstruction: {
    id: 'EditInstruction.yourInstruction',
    defaultMessage: 'Your instruction'
  },
  tags: {
    id: 'EditGuide.tags',
    defaultMessage: 'Tags'
  },
  saveInstruction: {
    id: 'EditInstruction.save',
    defaultMessage: 'Save instruction'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  },
  instructionUpdated: {
    id: 'EditInstruction.updateSuccess',
    defaultMessage: 'Instruction has been updated'
  },
  errorUpdadingInstruction: {
    id: 'EditInstruction.updateFailure',
    defaultMessage: 'Error updating instruction'
  },
  editImage: {
    id: 'EditInstruction.editImage',
    defaultMessage: 'Edit Image'
  }
});

export default messages;
