import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';
import {get} from 'lodash';
import Raven from 'raven-js';

//styled-components
import {Form, FormColumn, Field, CheckgroupTitle, CheckboxWithIconWrapper, SignInOptionsWrapper} from './styles';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import ButtonGroup from 'ui-components/ButtonGroup';
import {FormattedMessage} from 'components/FormattedComponents';
import Container from 'ui-components/Layout/Container';
import LoadingMessage from 'components/LoadingMessage';
import Checkbox from 'components/Checkbox';
import TeamPicker from 'components/TeamPicker';
import ThemePicker from 'components/ThemePicker';
import Icon from 'ui-components/Icon';
import Select from 'ui-components/Select';

// utils
import {bindField, slugifyDomain} from 'shared/utils/input-utils';
import editDomainForm from 'stores/forms/edit-domain-form';
import notification from 'utils/notification-utils';

//messages
import messages from './messages';

//data
import {EditDomain} from 'api/domain/mutations';
import {editDomainOptions} from 'api/domain/mutation-options';
import {Domain} from 'api/domain/queries';
import {domainOptions} from 'api/domain/query-options';
import {AllTeams} from 'api/team/queries';
import {teamsOptions} from 'api/team/query-options';
import {UserInfo} from 'api/user/queries';
import {XmRealityTeams} from 'api/xmreality/queries';
import {xMRealityTeamsOptions} from 'api/xmreality/query-options';
import {DispatchSites} from 'api/dispatch/queries';
import {dispatchSitesOptions} from 'api/dispatch/query-options';

@inject('store')
@graphql(Domain, domainOptions)
@graphql(AllTeams, teamsOptions)
@graphql(XmRealityTeams, xMRealityTeamsOptions)
@graphql(UserInfo)
@graphql(EditDomain, editDomainOptions)
@graphql(DispatchSites, dispatchSitesOptions)
@observer
class EditWorkspaceView extends Component {
  form = editDomainForm();

  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      auth: {
        user: {canManageDomains}
      }
    } = store;

    if (!canManageDomains) {
      store.router.goTo(views.root, {});
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      domainQuery: {domain}
    } = nextProps;

    if (!domain) {
      return;
    }

    const xMRealityEnabled = Boolean(domain && domain.xMRealityTeam);

    this.form.$('name').sync(domain.name);
    this.form.$('slug').sync(domain.slug);
    this.form.$('themeId').sync(domain.theme.id);
    this.form.$('managementTeamId').sync(domain.managementTeam.id);
    this.form.$('defaultTeamId').sync(domain.defaultTeam.id);
    this.form.$('dispatchSiteId').sync(domain.dispatchSiteId);
    this.form.$('enableXMReality').sync(xMRealityEnabled);
    this.form.$('allowedIpRanges').sync(domain.allowedIpRanges?.join(', '));
    if (domain.loginOptions) {
      this.form.$('emailPassword').sync(domain.loginOptions.includes('EMAIL'));
      this.form.$('workspacePassword').sync(domain.loginOptions.includes('DOMAIN_PASSWORD'));
    }
    if (xMRealityEnabled && domain.xMRealityTeam) this.form.$('xMRealityTeamId').sync(domain.xMRealityTeam.id);
  }

  nameFieldOptions = {
    onChangeReaction: e => {
      const {value} = e.target;
      const field = this.form.$('slug');
      const newValue = slugifyDomain(value);

      if (newValue) {
        field.sync(newValue);
      } else {
        field.clear();
      }
    }
  };

  editDomain = async () => {
    const {
      intl: {formatMessage},
      store,
      editDomainMutation
    } = this.props;
    const {
      platform: {sso}
    } = store;
    const {isIPA} = store.auth.user;
    const {id} = store.router.params;

    const values = this.form.values();

    if (values.password === '') {
      delete values.password;
    }

    // if xmreality is not enabled, we do not send any data for the xmreality team
    if (!values.enableXMReality) {
      values.xMRealityTeamId = null;
    } else {
      values.xMRealityTeamId = Number(values.xMRealityTeamId);
    }

    //loginOptions
    values.loginOptions = null;
    if (values.emailPassword) {
      values.loginOptions = ['EMAIL'];
    }
    if (values.workspacePassword) {
      values.loginOptions = [...(values.loginOptions || []), 'DOMAIN_PASSWORD'];
    }
    delete values.emailPassword;
    delete values.workspacePassword;

    // For platforms without SSO we keep default team in sync with management team
    if (!sso) {
      values.defaultTeamId = values.managementTeamId;
    }
    delete values.confirmPassword;
    delete values.enableXMReality;

    values.allowedIpRanges = values.allowedIpRanges ? values.allowedIpRanges.split(',').map(ip => ip.trim()) : [];
    if (!isIPA) {
      delete values.allowedIpRanges;
    }

    try {
      await editDomainMutation({id, editDomain: values});

      notification.success(formatMessage(messages.editSuccess));
      this.form.reset();

      this.redirectToPreviousView();
    } catch (e) {
      if (e.message === 'ip_ranges_invalid') {
        notification.error(formatMessage(messages.ipRangesInvalid));
      } else {
        notification.error(formatMessage(messages.editFailure));
      }
      Raven.captureException(e);
    }
  };

  toggleXMReality = () => {
    const {enableXMReality} = this.form.values();
    const {
      platform: {canUseXmReality}
    } = this.props.store;
    if (canUseXmReality) this.form.$('enableXMReality').sync(!enableXMReality);
  };

  toggleSignInOptions = field => {
    const currentValue = get(this.form.values(), field);
    this.form.$(field).sync(!currentValue);
  };

  redirectToPreviousView = () => {
    const {
      store: {
        editDomainPage: {
          previousView: {view, id}
        },
        router: {goTo}
      }
    } = this.props;

    if (view === 'teamWorkspaces') {
      goTo(views.teamWorkspaces, {id});
    } else {
      goTo(views.workspaces, {});
    }
  };

  render() {
    const {form, editDomain} = this;
    const {
      domainQuery,
      data: {me},
      teamsQuery: {allTeams},
      xMRealityTeamsQuery: {xMRealityTeams = []},
      dispatchSitesQuery: {dispatchSites = []},
      store,
      intl: {formatMessage}
    } = this.props;
    const {domain} = domainQuery;

    const {
      auth: {user},
      platform: {
        sso,
        isContentProtected,
        canUseXmReality,
        developmentFeatureFlags: {contactMethodSelection, dispatch},
        hasDispatchEnabled
      }
    } = store;
    const {isIPA} = user;
    const loading =
      domainQuery.loading ||
      this.props.data.loading ||
      this.props.teamsQuery.loading ||
      this.props.xMRealityTeamsQuery.loading ||
      this.props.dispatchSitesQuery.loading;

    const {
      password,
      confirmPassword,
      enableXMReality,
      xMRealityTeamId,
      emailPassword,
      workspacePassword,
      allowedIpRanges
    } = form.values();
    const passwordsMatch = password === confirmPassword;
    const enableSubmitXmReality = Boolean(!enableXMReality || (enableXMReality && xMRealityTeamId));
    const enableSubmit = form.isValid && passwordsMatch && enableSubmitXmReality;
    const myThemes = (me && [...me.themes]) || [];
    const passwordIndicator = get(domain, 'hasPassword') ? '********' : '';
    const IpRangesPlacehoder = '0.0.0.0/24, 0.0.0.0/32';

    const dispatchSitesResults =
      (dispatchSites?.results instanceof Array &&
        dispatchSites.results.map(site => {
          return {
            id: site.site,
            name: site.description
          };
        })) ||
      [];

    if (loading) {
      return (
        <LoadingMessage>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }

    // If the domain have a theme set that have no team id let's add it to the picker list anyway
    // User might not want to update theme and if it's not on the list the user is forced to
    const isThemeUsedOnDomainInUserThemes = !!myThemes.find(theme => theme.id === domain.theme.id);
    if (!isThemeUsedOnDomainInUserThemes) {
      myThemes.push(domain.theme);
    }

    const isEmailCheckboxDisabled = !sso && !workspacePassword && emailPassword;
    const isWorkspacePasswordCheckboxDisabled = !sso && !emailPassword && workspacePassword;

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <Form>
          <FormColumn>
            <Field
              autofocus={true}
              size="small"
              title={formatMessage(messages.name)}
              field="name"
              form={form}
              {...bindField(form, 'name', this.nameFieldOptions)}
            />

            <Field
              size="small"
              title={formatMessage(messages.workspaceUrl)}
              titleDescription={formatMessage(messages.workspaceUrlDescription)}
              field="slug"
              form={form}
              shadowText={{
                beforeInput: 'https://',
                afterInput: `-${window.location.hostname}`
              }}
            />
            {isContentProtected && (
              <SignInOptionsWrapper>
                <CheckgroupTitle>{formatMessage(messages.signInOptions)}</CheckgroupTitle>
                {sso && (
                  <Field
                    size="small"
                    hideError={true}
                    inputComponent={
                      <CheckboxWithIconWrapper>
                        <Checkbox
                          type="checkbox"
                          caption={formatMessage(messages.companyAccount)}
                          checked={!!sso}
                          disabled={!!sso}
                          height={30}
                        />
                        <Icon
                          name={'info'}
                          size={24}
                          popoverContent={formatMessage(messages.platformSetting)}
                          style={{marginLeft: 10}}
                        />
                      </CheckboxWithIconWrapper>
                    }
                  />
                )}
                <Field
                  size="small"
                  hideError={true}
                  inputComponent={
                    <Checkbox
                      type="checkbox"
                      caption={formatMessage(
                        contactMethodSelection ? messages.emailPhoneAndPassword : messages.emailAndPassword
                      )}
                      checked={emailPassword}
                      disabled={isEmailCheckboxDisabled}
                      onChange={() => this.toggleSignInOptions('emailPassword')}
                      height={30}
                    />
                  }
                />
                <Field
                  size="small"
                  hideError={true}
                  inputComponent={
                    <Checkbox
                      type="checkbox"
                      caption={formatMessage(messages.workspacePassword)}
                      checked={workspacePassword}
                      disabled={isWorkspacePasswordCheckboxDisabled}
                      onChange={() => this.toggleSignInOptions('workspacePassword')}
                      height={30}
                    />
                  }
                />
              </SignInOptionsWrapper>
            )}

            {isContentProtected && (
              <Field
                size="small"
                title={formatMessage(messages.password)}
                type="password"
                field="password"
                form={form}
                placeholder={passwordIndicator}
              />
            )}

            {isContentProtected && (
              <Field
                size="small"
                title={formatMessage(messages.confirmPassword)}
                field="confirmPassword"
                type="password"
                form={form}
                placeholder={passwordIndicator}
                {...(!passwordsMatch && {forcedError: formatMessage(messages.passwordMismatch)})}
              />
            )}
            {isIPA && (
              <Field
                size="small"
                title="Allowed IP Ranges"
                field="allowedIpRanges"
                form={form}
                value={allowedIpRanges}
                placeholder={IpRangesPlacehoder}
              />
            )}
          </FormColumn>
          <FormColumn>
            <Field
              size="small"
              title={formatMessage(messages.theme)}
              titleDescription={formatMessage(messages.themeDescription)}
              inputComponent={<ThemePicker allowClear={false} field="themeId" form={form} themes={myThemes} />}
            />

            <Field
              size="small"
              title={formatMessage(messages.managementTeam)}
              titleDescription={formatMessage(messages.managementTeamDescription)}
              inputComponent={<TeamPicker allowClear={false} field="managementTeamId" form={form} teams={allTeams} />}
            />

            {sso && (
              <Field
                size="small"
                title={formatMessage(messages.defaultTeamForNewUsers)}
                titleDescription={formatMessage(messages.defaultTeamDescription)}
                inputComponent={<TeamPicker allowClear={false} field="defaultTeamId" form={form} teams={allTeams} />}
              />
            )}

            {canUseXmReality && (
              <Field
                size="small"
                inputComponent={
                  <Checkbox
                    type="checkbox"
                    caption={formatMessage(messages.enableXMReality)}
                    checked={enableXMReality}
                    onChange={this.toggleXMReality}
                  />
                }
              />
            )}

            {enableXMReality && (
              <Field
                size="small"
                inputComponent={
                  <TeamPicker allowClear={false} field="xMRealityTeamId" form={form} teams={xMRealityTeams} />
                }
                title={formatMessage(messages.remoteSupportTeam)}
              />
            )}

            {dispatch && hasDispatchEnabled && (
              <Field
                size="small"
                title={formatMessage(messages.associatedSite)}
                inputComponent={
                  <Select
                    className={'AssociatedSite'}
                    placeholder={formatMessage(messages.selectSite)}
                    allowClear={false}
                    options={dispatchSitesResults}
                    form={form}
                    field={'dispatchSiteId'}
                    dataCy={'dispatch-site-select'}
                  />
                }
              />
            )}
          </FormColumn>
        </Form>
        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          primary={{
            label: <FormattedMessage {...messages.save} />,
            loading: loading,
            onClick: editDomain,
            disabled: !enableSubmit
          }}
          secondary={{
            onClick: this.redirectToPreviousView,
            label: <FormattedMessage {...messages.cancel} />
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(EditWorkspaceView);
