import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

// queries
import {Team} from 'api/team/queries';

//mutations
import {EditTeam, UpdateTeamUserRole, SaveGuideApprovalSettings} from 'api/team/mutations';

//options
import {teamOptions} from 'api/team/query-options';
import {editTeamOptions, updateTeamUserRoleOptions, saveGuideApprovalSettingsOptions} from 'api/team/mutation-options';

//components
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import GuideApprovalSelector from 'components/GuideApprovalSelector';

//messages
import messages from './messages';

@inject('store')
@graphql(Team, teamOptions)
@graphql(EditTeam, editTeamOptions)
@graphql(UpdateTeamUserRole, updateTeamUserRoleOptions)
@graphql(SaveGuideApprovalSettings, saveGuideApprovalSettingsOptions)
@observer
class EditTeamApproversView extends Component {
  setActionMenu = () => {
    const {store} = this.props;
    const {app} = store;
    const {id} = store.router.params;

    const actionMenuItems = [
      {
        id: 'create',
        params: {id},
        view: 'newTeamUsers'
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  UNSAFE_componentWillMount() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {editTeamPage} = store;

    const translations = mapValues(
      pick(messages, [
        'loading',
        'activateGuideApproval',
        'guideApprovalRequirementExplanationV2',
        'setApproverOrder',
        'guidesApprovedInOrderV2',
        'addApprover',
        'approver',
        'select',
        'updateGuideApprovalSettingsSuccess',
        'updateGuideApprovalSettingsFailure',
        'confirmChange',
        'guidesPendingApprovalWarning',
        'confirmAction'
      ]),
      message => formatMessage(message)
    );

    editTeamPage.setTranslations(translations);
    editTeamPage.setShowSaveButton(true);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: {team, loading, error},
      store
    } = nextProps;
    const {editTeamPage, app} = store;
    const canManageTeam = team?.canManageTeam;

    if (!loading) {
      const approverIds = editTeamPage.sortedTeamApproverIds;
      editTeamPage.setTeam(team);
      editTeamPage.setGuideApproval(team.guideApproval);
      editTeamPage.setApproverIds(approverIds);

      if (canManageTeam) {
        this.setActionMenu();
      } else {
        app.resetActionMenuItems();
      }

      if (error || (!loading && !team)) {
        store.router.goTo(views.teams, {});
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      data: {team, loading},
      store
    } = this.props;
    const {editTeamPage, app} = store;
    const canManageTeam = team?.canManageTeam;

    if (!loading) {
      const {
        data: {loading: prevPropsDataLoading}
      } = prevProps;

      if (prevPropsDataLoading) {
        editTeamPage.setTeam(team);
        editTeamPage.setGuideApproval(team.guideApproval);
        const approverIds = editTeamPage.sortedTeamApproverIds;
        editTeamPage.setApproverIds(approverIds);
      }

      if (canManageTeam) {
        this.setActionMenu();
      } else {
        app.resetActionMenuItems();
      }
    }
  }

  componentWillUnmount() {
    const {
      store: {app, editTeamPage}
    } = this.props;
    app.resetActionMenuItems();
    editTeamPage.reset();
  }
  render() {
    const {store, data} = this.props;
    const {loading: dataIsLoading, error} = data;
    const {editTeamPage} = store;
    const {translations, usersForApproval, canUserEditGuideApproval} = editTeamPage;

    const isLoading = dataIsLoading;

    const users = usersForApproval;

    const approvalSelectorMessages = {
      requireGuideApproval: translations.activateGuideApproval,
      guideApprovalRequirementExplanation: translations.guideApprovalRequirementExplanationV2,
      approver: translations.approver,
      addApprover: translations.addApprover,
      select: translations.select,
      setApproverOrder: translations.setApproverOrder,
      guidesApprovedInOrder: translations.guidesApprovedInOrderV2
    };

    const onRequireGuideApprovalChange = value => {
      const guideApproval = value ? 'PARALLEL' : null;
      editTeamPage.setGuideApproval(guideApproval);
    };

    const onSelectApproverOrderChange = value => {
      if (value) {
        editTeamPage.setGuideApproval('SEQUENTIAL');
      } else {
        if (editTeamPage.guideApproval !== null) {
          editTeamPage.setGuideApproval('PARALLEL');
        }
      }
    };

    const onApproverIdsChange = approverIds => {
      editTeamPage.setApproverIds(approverIds);
    };

    return isLoading ? (
      <LoadingMessage hasFailed={error}>
        <FormattedMessage {...messages.loading} />
      </LoadingMessage>
    ) : (
      <GuideApprovalSelector
        disabled={!canUserEditGuideApproval}
        requireGuideApproval={editTeamPage.isGuideApprovalEnabled}
        selectApproverOrder={editTeamPage.isGuideApprovalSequential}
        users={users}
        messages={approvalSelectorMessages}
        onRequireGuideApprovalChange={onRequireGuideApprovalChange}
        onSelectApproverOrderChange={onSelectApproverOrderChange}
        onApproverIdsChange={onApproverIdsChange}
      />
    );
  }
}

export default injectIntl(EditTeamApproversView);
