import moment from 'moment';

export const sortByDateTime = (date1, date2) => moment(date1).diff(moment(date2));

export const sortAlphabetically = (string1, string2) => {
  if (string1.trim().toLocaleLowerCase() < string2.trim().toLocaleLowerCase()) {
    return -1;
  }
  if (string1.trim().toLocaleLowerCase() > string2.trim().toLocaleLowerCase()) {
    return 1;
  }
  return 0;
};

export const sortAlphabeticallyByTitle = (obj1, obj2) => {
  if (obj1.title.trim().toLocaleLowerCase() < obj2.title.trim().toLocaleLowerCase()) {
    return -1;
  }
  if (obj1.title.trim().toLocaleLowerCase() > obj2.title.trim().toLocaleLowerCase()) {
    return 1;
  }
  return 0;
};
