import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import views from 'config/views';
import {TimeStamp} from './styles';
import {languagesList} from '../../config/languagesList';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';
import ButtonGroup from 'ui-components/ButtonGroup';
import Field from 'ui-components/Field';
import Checkbox from 'components/Checkbox';

const CheckboxItem = ({title, value, toggle, disabled}) => {
  return (
    <Field
      size="small"
      inputComponent={
        <Checkbox
          disabled={disabled}
          caption={<div style={{display: 'flex', alignItems: 'left'}}>{title}</div>}
          checked={value}
          onChange={toggle}
        />
      }
    />
  );
};
@inject('store')
@observer
class IPAPanel extends Component {
  componentWillMount() {
    const {store} = this.props;
    const {
      router,
      platform: {subdomain},
      auth: {user},
      ipaStore: {getPlatform}
    } = store;
    const {isIPA} = user;
    if (!isIPA) {
      router.goTo(views.guides, {});
      return;
    }
    getPlatform(subdomain);
  }

  render() {
    const {
      store: {ipaStore}
    } = this.props;

    const {
      form,
      loading,
      error,
      updatePlatform,
      checklists,
      colorContent,
      customFonts,
      feedback,
      hasRever,
      guideApproval,
      liveChat,
      versioning,
      multiDomain,
      stepHintMedia,
      canExportToPdf,
      autoTranslations,
      protectedContent,
      maxEditors,
      maxDomains,
      maxPublishedGuides,
      sentimentMeasuring,
      checklistEscalation,
      aiStepContentSuggestions,
      apiAccess,
      skills,
      configurableAlertsRecipients,
      toggleChecklists,
      toggleColorContent,
      toggleCustomFonts,
      toggleFeedback,
      toggleHasRever,
      toggleGuideApproval,
      toggleLiveChat,
      toggleVersioning,
      toggleMultiDomain,
      toggleStepHintMedia,
      toggleCanExportToPdf,
      toggleAutoTranslations,
      toggleProtectedContent,
      toggleSentimentMeasuring,
      isFormValid,
      platformBody,
      toggleChecklistEscalation,
      toggleAiStepContentSuggestions,
      toggleApiAccess,
      toggleSkills,
      toggleLanguage,
      enabledLanguages,
      checklistsSignature,
      toggleChecklistsSignature,
      nativeLatestVersion,
      nativeIosInReview,
      nativeAndroidInReview,
      toggleNativeIosInReview,
      toggleNativeAndroidInReview,
      toggleConfigurableAlertsRecipients
    } = ipaStore;

    if (error) {
      console.error(error);
      return (
        <div>
          Error! <pre>{error}</pre>
        </div>
      );
    }
    if (loading) {
      return <span>Loading</span>;
    }

    return (
      <Container>
        <ViewTitle
          title={'Internal Platform Admin (IPA) Panel'}
          subtitle={
            'Note: Configuration of the following features are not supported through the IPA Panel -  Native App, SSO, Embedded Analytics, Beekeeper, and XM Reality. Please reach out to the Engineering Team to configure these features.'
          }
        />
        <Field
          autofocus={true}
          size="small"
          title={'Maximum Published Guides (leave empty if unlimited)'}
          field="maxPublishedGuides"
          form={form}
          value={maxPublishedGuides}
        />
        <Field
          autofocus={true}
          size="small"
          title={'Maximum Editors (leave empty if unlimited)'}
          field="maxEditors"
          form={form}
          value={maxEditors}
        />
        <Field
          autofocus={true}
          size="small"
          title={'Maximum Workspaces (leave empty if unlimited)'}
          field="maxDomains"
          form={form}
          value={maxDomains}
        />

        <CheckboxItem title="Guide Versioning" value={versioning} toggle={toggleVersioning} />
        <CheckboxItem
          title="Checklists (Requires Versioning)"
          caption={'Requires Versioning'}
          disabled={!versioning}
          value={checklists}
          toggle={toggleChecklists}
        />
        <CheckboxItem title="Color content" value={colorContent} toggle={toggleColorContent} />
        <CheckboxItem title="Custom fonts" value={customFonts} toggle={toggleCustomFonts} />
        <CheckboxItem title="Feedback" value={feedback} toggle={toggleFeedback} />
        <CheckboxItem title="Rever integration" value={hasRever} toggle={toggleHasRever} />
        <CheckboxItem title="Guide approval workflow" value={guideApproval} toggle={toggleGuideApproval} />
        <CheckboxItem title="Live Chat" value={liveChat} toggle={toggleLiveChat} />
        <CheckboxItem title="Multi Workspace" value={multiDomain} toggle={toggleMultiDomain} />
        <CheckboxItem title="Step Hint Media" value={stepHintMedia} toggle={toggleStepHintMedia} />
        <CheckboxItem title="PDF Export" value={canExportToPdf} toggle={toggleCanExportToPdf} />
        <CheckboxItem title="Auto-Translate Guides" value={autoTranslations} toggle={toggleAutoTranslations} />
        <CheckboxItem title="Protected Content" value={protectedContent} toggle={toggleProtectedContent} />
        <CheckboxItem title="Sign Off" value={sentimentMeasuring} toggle={toggleSentimentMeasuring} />
        <CheckboxItem title="Checklist Alerts" value={checklistEscalation} toggle={toggleChecklistEscalation} />
        <CheckboxItem
          title="Ai Step Content Suggestions"
          value={aiStepContentSuggestions}
          toggle={toggleAiStepContentSuggestions}
        />
        <CheckboxItem title="API Access" value={apiAccess} toggle={toggleApiAccess} />
        <CheckboxItem title="Smart Skills" value={skills} toggle={toggleSkills} />
        <CheckboxItem
          title="Configurable Alert Recipients"
          value={configurableAlertsRecipients}
          toggle={toggleConfigurableAlertsRecipients}
        />
        <CheckboxItem title="Checklist Signature" value={checklistsSignature} toggle={toggleChecklistsSignature} />
        <div>
          <h3>Languages</h3>
          <br></br>
          {languagesList.map(language => (
            <CheckboxItem
              key={language.short}
              title={`${language.languageInEnglish}`}
              value={enabledLanguages.some(lang => lang.short === language.short)}
              toggle={() => toggleLanguage(language)}
            />
          ))}
        </div>

        <div>
          <h3>Native app (Do not change unless verified)</h3>
          <br></br>
          <Field
            autofocus={true}
            size="small"
            title={'Native latest version'}
            field="nativeLatestVersion"
            form={form}
            value={nativeLatestVersion}
          />

          <CheckboxItem title="Native iOS in Review" value={nativeIosInReview} toggle={toggleNativeIosInReview} />
          <CheckboxItem
            title="Native Android in Review"
            value={nativeAndroidInReview}
            toggle={toggleNativeAndroidInReview}
          />
        </div>

        <div>
          <ButtonGroup
            line
            primary={{
              label: 'Save',
              onClick: () => updatePlatform(),
              disabled: !isFormValid
            }}
          />
          <TimeStamp>
            Last updated by: {platformBody.lastUpdatedBy} at {platformBody.updated_at}
          </TimeStamp>
        </div>
      </Container>
    );
  }
}

export default injectIntl(IPAPanel);
