import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import typefaces from 'shared/styles/typefaces';

//components
import $Arrow from 'ui-components/Layout/Arrow';

export const TranslateGuideView = styled.div`
  ${flex.vertical};
  flex-grow: 1;
  height: 100%;
`;

export const Content = styled.main`
  ${flex.vertical};
  flex-grow: 1;
  padding: 0;
  height: 100%;
  overflow: auto;
`;

export const Header = styled.header`
  flex-shrink: 0;
  padding: 0;
  position: relative;
  z-index: 0;
`;

export const Title = styled.div`
  ${typefaces.translations.title};
`;

export const StyledBackArrow = styled($Arrow).attrs({
  direction: 'left',
  size: 18
})`
  margin-right: 10px;
`;
