import {defineMessages} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'TagPicker.placeholder',
    defaultMessage: 'Select a tag'
  },
  createTagFor: {
    id: 'TagPicker.createTagFor',
    defaultMessage: "Create tag for '{input}'"
  }
});

export default messages;
