import {defineMessages} from 'react-intl';

const messages = defineMessages({
  changeIcon: {
    id: 'EditThemeStepIconDialog.changeIcon',
    defaultMessage: 'Edit Step Note Icon for {label}'
  },
  uploadIcon: {
    id: 'EditThemeStepIconDialog.uploadIcon',
    defaultMessage: 'Upload Step Note Icon'
  },
  dropboxCaptionOnlySvg: {
    id: 'EditThemeStepIconDialog.dropboxCaptionOnlySvg',
    defaultMessage: 'Only files in SVG format are supported'
  },
  dropboxCaptionThemeColors: {
    id: 'EditThemeStepIconDialog.dropboxCaptionThemeColors',
    defaultMessage: 'This Icon will always be displayed in your Theme Colors'
  },
  tooLarge: {
    id: 'EditThemeStepIconDialog.tooLarge',
    defaultMessage: 'The SVG Icon exceeds 100kb'
  },
  extensionForbidden: {
    id: 'EditThemeStepIconDialog.extensionForbidden',
    defaultMessage: 'Only SVG is supported'
  }
});

export default messages;
