import React, {useMemo} from 'react';

import Icon from 'ui-components/Layout/Icon';
import {EMPTY_ANSWER_SYMBOL} from 'config/constants';

interface StarAnswerProps {
  answer: string;
}

const StarAnswer: React.FC<StarAnswerProps> = ({answer}) => {
  const numValue = parseInt(answer, 10);
  const isValidValue = !Number.isNaN(numValue) && numValue > 0;

  const starElements = useMemo(() => {
    if (isValidValue) {
      return [...Array(numValue).keys()].map(key => <Icon name="star" key={key} size={20} />);
    } else {
      return EMPTY_ANSWER_SYMBOL;
    }
  }, [numValue]);

  return <span>{starElements}</span>;
};

export default StarAnswer;
