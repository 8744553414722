import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';
import {get} from 'lodash';

//styled-components
import {Form, FormColumn, Field, CheckgroupTitle, CheckboxWithIconWrapper, SignInOptionsWrapper} from './styles';

//helpers
import {getShowIntercom} from 'utils/intercom-utils';
import {slugifyDomain} from 'shared/utils/input-utils';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import ButtonGroup from 'ui-components/ButtonGroup';
import {FormattedMessage} from 'components/FormattedComponents';
import Container from 'ui-components/Layout/Container';
import LoadingMessage from 'components/LoadingMessage';
import Checkbox from 'components/Checkbox';
import TeamPicker from 'components/TeamPicker';
import ThemePicker from 'components/ThemePicker';
import MaxEditorsErrorNotificationContent from 'components/MaxEditorsErrorNotificationContent';
import Icon from 'ui-components/Icon';
import Select from 'ui-components/Select';

// utils
import newDomainForm from 'stores/forms/new-domain-form';
import notification from 'utils/notification-utils';

//messages
import messages from './messages';

//data
import {CreateDomain} from 'api/domain/mutations';
import {createDomainOptions} from 'api/domain/mutation-options';
import {AllTeams} from 'api/team/queries';
import {teamsOptions} from 'api/team/query-options';
import {DispatchSites} from 'api/dispatch/queries';
import {dispatchSitesOptions} from 'api/dispatch/query-options';
import {XmRealityTeams} from 'api/xmreality/queries';
import {xMRealityTeamsOptions} from 'api/xmreality/query-options';

import {UserInfo} from 'api/user/queries';

@inject('store')
@graphql(AllTeams, teamsOptions)
@graphql(XmRealityTeams, xMRealityTeamsOptions)
@graphql(UserInfo)
@graphql(CreateDomain, createDomainOptions)
@graphql(DispatchSites, dispatchSitesOptions)
@observer
class NewWorkspaceView extends Component {
  form = newDomainForm();

  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      platform,
      auth: {user}
    } = store;
    const {hasMultiDomainEnabled} = platform;
    const canManageWorkspaces = user.isPlatformAdmin;

    if (!hasMultiDomainEnabled || !canManageWorkspaces) {
      store.router.goTo(views.root, {});
    }
  }

  nameFieldOptions = {
    onChangeReaction: e => {
      const {value} = e.target;
      const field = this.form.$('slug');
      const newValue = slugifyDomain(value);

      if (newValue) {
        field.sync(newValue);
      } else {
        field.clear();
      }
    }
  };

  createDomain = () => {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      platform,
      app: {isActionMenuOpen}
    } = store;

    const values = this.form.values();
    values.defaultTeamId = values.managementTeamId; // TODO: Yet to decide what we are doing here

    // if xmreality is not enabled, we do not send any data for the xmreality team
    if (!values.enableXMReality) {
      values.xMRealityTeamId = null;
    } else {
      values.xMRealityTeamId = Number(values.xMRealityTeamId);
    }

    delete values.confirmPassword;
    delete values.enableXMReality;

    //loginOptions
    values.loginOptions = null;
    if (values.emailPassword) {
      values.loginOptions = ['EMAIL'];
    }
    if (values.workspacePassword) {
      values.loginOptions = [...(values.loginOptions || []), 'DOMAIN_PASSWORD'];
    }

    delete values.emailPassword;
    delete values.workspacePassword;
    this.props
      .createDomainMutation({newDomain: values})
      .then(() => {
        notification.success(formatMessage(messages.createSuccess));
        this.form.reset();
        store.router.goTo(views.workspaces, {});
      })
      .catch(e => {
        const {graphQLErrors} = e;
        if (graphQLErrors) {
          const createFailureMaxDomainsMessage = (
            <MaxEditorsErrorNotificationContent
              showIntercom={getShowIntercom({platform, isActionMenuOpen})}
              formattedMessages={{
                contactSupport: formatMessage(messages.contactSupport),
                publishGuideFailureMaxPublishedGuides: formatMessage(messages.createFailureMaxDomains),
                chatMessage: formatMessage(messages.chatMessage)
              }}
            />
          );

          for (const err of graphQLErrors) {
            if (err.extensions.code === 'MAX_DOMAINS' || err.extensions.code === 'NON_MULTI_DOMAIN') {
              return notification.error(createFailureMaxDomainsMessage);
            }
          }
        }
        notification.error(formatMessage(messages.createFailure));
      });
  };

  toggleXMReality = () => {
    const {enableXMReality} = this.form.values();
    const {
      platform: {canUseXmReality}
    } = this.props.store;
    if (canUseXmReality) this.form.$('enableXMReality').sync(!enableXMReality);
  };

  toggleSignInOptions = field => {
    const currentValue = get(this.form.values(), field);
    this.form.$(field).sync(!currentValue);
  };

  render() {
    const {form, createDomain} = this;
    const {
      data: {me},
      teamsQuery: {allTeams = []},
      xMRealityTeamsQuery: {xMRealityTeams = []},
      dispatchSitesQuery: {dispatchSites = []},
      store,
      intl: {formatMessage}
    } = this.props;
    const {
      platform: {
        sso,
        isContentProtected,
        canUseXmReality,
        developmentFeatureFlags: {contactMethodSelection, dispatch} = {},
        hasDispatchEnabled
      }
    } = store;
    const loading = this.props.data.loading || this.props.teamsQuery.loading;
    const {password, confirmPassword, enableXMReality, xMRealityTeamId, emailPassword, workspacePassword} =
      form.values();
    const passwordsMatch = password === confirmPassword;
    const enableSubmitXmReality = Boolean(!enableXMReality || (enableXMReality && xMRealityTeamId));
    const enableSubmit = form.isValid && passwordsMatch && enableSubmitXmReality;
    const myThemes = me && me.themes;
    let dispatchSitesResults = dispatchSites?.results;
    if (dispatchSitesResults) {
      dispatchSitesResults = dispatchSitesResults.map(site => {
        return {
          id: site.site,
          name: site.description
        };
      });
    }
    if (loading) {
      return (
        <LoadingMessage>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }

    const isEmailCheckboxDisabled = !sso && !workspacePassword && emailPassword;
    const isWorkspacePasswordCheckboxDisabled = !sso && !emailPassword && workspacePassword;

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <Form>
          <FormColumn>
            <Field
              autofocus={true}
              size="small"
              title={formatMessage(messages.name)}
              field="name"
              form={form}
              fieldOptions={this.nameFieldOptions}
            />

            <Field
              size="small"
              title={formatMessage(messages.workspaceUrl)}
              titleDescription={formatMessage(messages.workspaceUrlDescription)}
              field="slug"
              form={form}
              shadowText={{
                beforeInput: 'https://',
                afterInput: `-${window.location.hostname}`
              }}
            />

            {isContentProtected && (
              <SignInOptionsWrapper>
                <CheckgroupTitle>{formatMessage(messages.signInOptions)}</CheckgroupTitle>
                {sso && (
                  <Field
                    size="small"
                    hideError={true}
                    inputComponent={
                      <CheckboxWithIconWrapper>
                        <Checkbox
                          type="checkbox"
                          caption={formatMessage(messages.companyAccount)}
                          checked={!!sso}
                          disabled={!!sso}
                          height={30}
                        />
                        <Icon
                          name={'info'}
                          size={24}
                          popoverContent={formatMessage(messages.platformSetting)}
                          style={{marginLeft: 10}}
                        />
                      </CheckboxWithIconWrapper>
                    }
                  />
                )}
                <Field
                  size="small"
                  hideError={true}
                  inputComponent={
                    <Checkbox
                      type="checkbox"
                      caption={formatMessage(
                        contactMethodSelection ? messages.emailPhoneAndPassword : messages.emailAndPassword
                      )}
                      checked={emailPassword}
                      disabled={isEmailCheckboxDisabled}
                      onChange={() => this.toggleSignInOptions('emailPassword')}
                      height={30}
                    />
                  }
                />
                <Field
                  size="small"
                  hideError={true}
                  inputComponent={
                    <Checkbox
                      type="checkbox"
                      caption={formatMessage(messages.workspacePassword)}
                      checked={workspacePassword}
                      disabled={isWorkspacePasswordCheckboxDisabled}
                      onChange={() => this.toggleSignInOptions('workspacePassword')}
                      height={30}
                    />
                  }
                />
              </SignInOptionsWrapper>
            )}

            {isContentProtected && (
              <Field
                size="small"
                title={formatMessage(messages.password)}
                type="password"
                field="password"
                form={form}
              />
            )}

            {isContentProtected && (
              <Field
                size="small"
                title={formatMessage(messages.confirmPassword)}
                field="confirmPassword"
                type="password"
                form={form}
                {...(!passwordsMatch && {forcedError: formatMessage(messages.passwordMismatch)})}
              />
            )}
          </FormColumn>
          <FormColumn>
            <Field
              size="small"
              title={formatMessage(messages.theme)}
              titleDescription={formatMessage(messages.themeDescription)}
              inputComponent={<ThemePicker allowClear={false} field="themeId" form={form} themes={myThemes} />}
            />

            <Field
              size="small"
              titleDescription={formatMessage(messages.managementTeamDescription)}
              title={formatMessage(messages.managementTeam)}
              inputComponent={<TeamPicker allowClear={false} field="managementTeamId" form={form} teams={allTeams} />}
            />
            {sso && (
              <Field
                size="small"
                title={formatMessage(messages.defaultTeamForNewUsers)}
                titleDescription={formatMessage(messages.defaultTeamDescription)}
                inputComponent={<TeamPicker allowClear={false} field="defaultTeamId" form={form} teams={allTeams} />}
              />
            )}

            {canUseXmReality && (
              <Field
                size="small"
                inputComponent={
                  <Checkbox
                    type="checkbox"
                    caption={formatMessage(messages.enableXMReality)}
                    checked={enableXMReality}
                    onChange={this.toggleXMReality}
                  />
                }
              />
            )}

            {enableXMReality && (
              <Field
                size="small"
                inputComponent={
                  <TeamPicker allowClear={false} field="xMRealityTeamId" form={form} teams={xMRealityTeams} />
                }
                title={formatMessage(messages.remoteSupportTeam)}
              />
            )}
            {dispatch && hasDispatchEnabled && (
              <Field
                size="small"
                title={formatMessage(messages.associatedSite)}
                inputComponent={
                  <Select
                    className={'AssociatedSite'}
                    placeholder={formatMessage(messages.selectSite)}
                    allowClear={false}
                    options={dispatchSitesResults}
                    form={form}
                    field={'dispatchSiteId'}
                  />
                }
              />
            )}
          </FormColumn>
        </Form>

        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          primary={{
            label: <FormattedMessage {...messages.save} />,
            loading: loading,
            onClick: createDomain,
            disabled: !enableSubmit
          }}
          secondary={{
            onClick: () => store.router.goTo(views.workspaces, {}),
            label: <FormattedMessage {...messages.cancel} />
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(NewWorkspaceView);
