import {defineMessages} from 'react-intl';

const messages = defineMessages({
  backToCampaignResults: {
    id: 'SignoffResultDetails.backToSignoffResults',
    defaultMessage: 'Back to Sign-off results'
  },
  title: {
    id: 'SignoffResultDetails.title',
    defaultMessage: 'Sign-off session details'
  },
  submittedName: {
    id: 'SignOffResults.submittedName',
    defaultMessage: 'Submitted name'
  },
  loggedInUser: {
    id: 'SignoffResultDetails.loggedInUser',
    defaultMessage: 'Logged in user'
  },
  ssoId: {
    id: 'SignoffResultDetails.ssoId',
    defaultMessage: 'SSO id'
  },
  team: {
    id: 'common.forms.team',
    defaultMessage: 'Team'
  },
  dateAndTime: {
    id: 'dateAndTime',
    defaultMessage: 'Date and time'
  },
  workspace: {
    id: 'Share.workspace',
    defaultMessage: 'Workspace'
  },
  guide: {
    id: 'guide',
    defaultMessage: 'Guide'
  },
  instruction: {
    id: 'pdfInstruction',
    defaultMessage: 'Instruction'
  },
  signoff: {
    id: 'campaign_sign_off',
    defaultMessage: 'Sign-off'
  },
  question: {
    id: 'EditCampaign.question',
    defaultMessage: 'Question'
  },
  answer: {
    id: 'EditCampaign.answer',
    defaultMessage: 'Answer'
  },
  followUpQuestion: {
    id: 'SignoffResultDetails.followUpQuestion',
    defaultMessage: 'Follow-up question'
  },
  error: {
    id: 'SignoffResultDetails.error',
    defaultMessage: 'An error has occured while fetching the sign-off results details.'
  },
  campaign_action_yes: {
    id: 'campaign_action_yes',
    defaultMessage: 'Yes'
  },
  campaign_action_no: {
    id: 'campaign_action_no',
    defaultMessage: 'No'
  },
  campaign_action_pass: {
    id: 'campaign_action_pass',
    defaultMessage: 'Pass'
  },
  campaign_action_fail: {
    id: 'campaign_action_fail',
    defaultMessage: 'Fail'
  }
});

export default messages;
