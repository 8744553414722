import {defineMessages} from 'react-intl';

const messages = defineMessages({
  copyEmbedCode: {
    id: 'Share.copyEmbedCode',
    defaultMessage: 'Copy embed code'
  },
  copyEmbedCodeSuccess: {
    id: 'Share.copyEmbedCodeSuccess',
    defaultMessage: 'The embed code has been copied to the clipboard.'
  },
  copyUrl: {
    id: 'Share.copyUrl',
    defaultMessage: 'Copy URL'
  },
  copyUrlSuccess: {
    id: 'Share.copyUrlSuccess',
    defaultMessage: 'The URL has been copied to the clipboard.'
  },
  downloadQrCode: {
    id: 'Share.downloadQrCode',
    defaultMessage: 'Download QR (.png)'
  },
  embedCode: {
    id: 'Share.embedCode',
    defaultMessage: 'Embed'
  },
  qrCode: {
    id: 'Share.qrCode',
    defaultMessage: 'QR Code'
  },
  url: {
    id: 'Share.shareUrl',
    defaultMessage: 'URL'
  },
  selectDomain: {
    id: 'Share.selectDomain',
    defaultMessage: 'Select Workspace'
  },
  workspace: {
    id: 'Share.workspace',
    defaultMessage: 'Workspace'
  },
  beekeeper: {
    id: 'Share.beekeeper',
    defaultMessage: 'Beekeeper'
  },
  shareToBeekeeper: {
    id: 'Share.shareToBeekeeper',
    defaultMessage: 'Share to Beekeeper'
  },
  shareToBeekeeperSuccess: {
    id: 'Share.shareToBeekeeperSuccess',
    defaultMessage: 'Guide shared to Beekeeper successfuly'
  },
  shareToBeekeeperError: {
    id: 'Share.shareToBeekeeperError',
    defaultMessage: 'Error sharing guide to Beekeeper. Please contact support!'
  }
});

export default messages;
