import {MAX_IMAGES_LIMIT_NR} from 'components/MediaPickerDialog/MediaGallery/constants';
import {toJS} from 'mobx';

export const getMediaGalleryOptions = {
  name: 'mediaGalleryQuery',
  options: ({store, galleryConfig}) => {
    const defaultVars = {
      guideId: undefined,
      instructionId: undefined,
      themeMediaType: undefined,
      savedMediaIds: toJS(store.mediaPickerDialog.savedMediaIds)
    };
    const variables = galleryConfig
      ? {...defaultVars, ...galleryConfig}
      : {...defaultVars, guideId: store.router.params.id};
    return {
      fetchPolicy: 'network-only',
      variables
    };
  }
};

export const getMediaGalleryPaginatedOptions = {
  name: 'mediaGalleryPaginatedQuery',
  options: ({store}) => {
    const {
      mediaPickerDialog: {galleryConfig, savedMediaIds, cursor}
    } = store;

    const defaultVars = {
      guideId: undefined,
      instructionId: undefined,
      themeMediaType: undefined,
      savedMediaIds: toJS(savedMediaIds),
      cursor,
      limit: MAX_IMAGES_LIMIT_NR
    };
    const variables = galleryConfig
      ? {...defaultVars, ...galleryConfig}
      : {...defaultVars, guideId: store.router.params.id};

    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables
    };
  }
};

export const getSingleMediaOptions = {
  name: 'mediaGalleryQuery',
  options: ({store}) => {
    const {
      mediaPickerDialog: {mediaId, form, field}
    } = store;

    const savedMediaId = mediaId || form.$(field).value;

    const variables = {
      guideId: undefined,
      instructionId: undefined,
      themeMediaType: undefined,
      savedMediaIds: savedMediaId ? [savedMediaId] : undefined
    };

    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables
    };
  }
};
