import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {toJS} from 'mobx';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {MEDIA_PREVIEW_TYPE} from 'config/enums';
import {syncTitleWithSlug} from 'shared/utils/input-utils';
import {ensureDraftVersion} from 'utils/versioning-utils';

//components
import InstructionDetailsEditMode from 'components/InstructionDetailsEditMode';
import DetailsViewComponent from 'components/DetailsViewMode';

//styles
import {
  InnerWrapper,
  InstructionDetails,
  BackgroundImage,
  StyledMediaPicker,
  StyledMediaPickerWithLabel
} from './styles';

//mutations
import {UpdateInstruction, UpdateInstructionMedia} from 'api/instruction/mutations';

//options
import {updateInstructionInGuideOptions, updateInstructionMediaOptions} from 'api/instruction/mutation-options';

//messages
import messages from './messages';
import views from 'config/views';

@inject('store')
@graphql(UpdateInstruction, updateInstructionInGuideOptions)
@graphql(UpdateInstructionMedia, updateInstructionMediaOptions)
@observer
class InstructionDetailsComponent extends Component {
  componentDidMount() {
    const {updateInstructionMediaMutation, store} = this.props;
    const {editInstructionDetailsPage} = store;
    const {setUpdateMediaMutation} = editInstructionDetailsPage;
    setUpdateMediaMutation(updateInstructionMediaMutation);
  }

  componentWillUnmount() {
    const {
      store: {editInstructionDetailsPage}
    } = this.props;
    editInstructionDetailsPage.reset();
  }

  titleFieldOptions = {
    onChangeReaction: e => syncTitleWithSlug(e, this.props.store.editInstructionDetailsPage.form)
  };

  // After clone move to the newly created instruction
  onClone = clonedInstruction => {
    const {store} = this.props;
    const {router, editInstructionDetailsPage} = store;
    const {topicId, id} = router.params;

    router.goTo(views.editInstruction, {id, topicId, instructionId: clonedInstruction.id});
    editInstructionDetailsPage.refetchInstructionQuery({id: clonedInstruction.id});
  };

  /**
   * After archive get back to guide overview
   */
  onArchive = () => {
    const {store} = this.props;
    const {router} = store;
    const {id} = router.params;

    router.goTo(views.editGuide, {id});
  };

  handleTitleClick = ensureDraftVersion(() => {
    const {store} = this.props;
    const {editInstructionDetailsPage: page} = store;

    page.setEditMode(true);
  }, this);

  openGuideVersions = () => {
    const {store} = this.props;
    const {editInstructionDetailsPage: page, guideVersionsDialog: dialog} = store;

    dialog.open({
      versions: toJS(page.guide.versions),
      isPublished: page.guide.publishStatus === 'PUBLISHED',
      refetchQueries: [page.refetchGuideQuery, page.refetchInstructionQuery]
    });
  };

  render() {
    const {intl, store, updateInstructionMutation} = this.props;
    const {formatMessage} = intl;
    const {
      detailsCard: {cardHeight},
      editInstructionDetailsPage: page,
      saveAsDraftDialog
    } = store;
    const {editMode, form, instructionCoverUrl, updateMedia, metadata} = page;

    const translations = {
      success: formatMessage(messages.success),
      error: formatMessage(messages.error),
      successMessage: formatMessage(messages.instructionUpdated),
      errorMessage: formatMessage(messages.errorUpdadingInstruction),
      editImage: formatMessage(messages.editImage)
    };

    return (
      <InstructionDetails className="sync-card-hover-with-child-component">
        <BackgroundImage
          src={instructionCoverUrl}
          metadata={metadata}
          emptyIcon={null}
          cardHeight={cardHeight}
          shouldShowOverlayShapes
        />
        <InnerWrapper>
          <StyledMediaPickerWithLabel>
            <StyledMediaPicker
              canOpen={() => saveAsDraftDialog.check()}
              field="mediaId"
              form={form}
              previewType={MEDIA_PREVIEW_TYPE.INSTRUCTION_COVER}
              updateMedia={updateMedia}
              showDarkerOverlay={false}
              label={translations.editImage}
            />
          </StyledMediaPickerWithLabel>
          {editMode ? (
            <InstructionDetailsEditMode
              translations={translations}
              updateInstructionMutation={updateInstructionMutation}
            />
          ) : (
            <DetailsViewComponent contentType="instruction" selectedStore={page} />
          )}
        </InnerWrapper>
      </InstructionDetails>
    );
  }
}

export default injectIntl(InstructionDetailsComponent);
