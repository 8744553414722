import {defineMessages} from 'react-intl';

const messages = defineMessages({
  all: {
    id: 'all',
    defaultMessage: 'All'
  },
  selected: {
    id: 'selected',
    defaultMessage: 'Selected'
  }
});

export default messages;
