import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import {noSelect} from 'shared/utils/sc-utils';
import Button from '../Button';

export const RemoveButton = styled(Button).attrs({
  iconId: 'close'
})`
  margin-left: 8px;
`;

export const Chip = styled.div`
  ${noSelect}
  border-radius: 2px;
  display: table;
  ${({lightGreen}) => (lightGreen ? `background: ${commonPalette.lightGreen};` : '')}
  ${({borderGrey}) => (borderGrey ? `background: ${commonPalette.borderGrey};` : '')}
`;

export const Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: ${p => (p.maxLabelLength ? `${p.maxLabelLength}px` : '150px')};

  > svg {
    margin: 0 6px 0 0;
  }

  ${p =>
    p.iconOnRight &&
    `
  flex-direction: row-reverse;
  
  > svg {
    margin: 0 0 0 6px;
  }
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  height: 24px;
`;
