import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createLearningActivity: {
    id: 'createLearningActivity',
    defaultMessage: 'Create Learning Activity'
  },
  generalInformation: {
    id: 'generalInformation',
    defaultMessage: 'General Information'
  },
  learningActivities: {
    id: 'learningActivities',
    defaultMessage: 'Learning Activities'
  },
  name: {
    id: 'name',
    defaultMessage: 'Name'
  },
  learningName: {
    id: 'learningName',
    defaultMessage: 'Learning Activity Name'
  },
  addLearningActivities: {
    id: 'addLearningActivities',
    defaultMessage: 'Add Learning Activities'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  create: {
    id: 'create',
    defaultMessage: 'Create'
  },
  description: {
    id: 'description',
    defaultMessage: 'Description'
  },
  link: {
    id: 'link',
    defaultMessage: 'Link'
  },
  descPlaceholder: {
    id: 'descPlaceholder',
    defaultMessage: 'Give learners more detail about the learning activity so they know what to do to succeed'
  },
  linkPlaceholder: {
    id: 'linkPlaceholder',
    defaultMessage: 'Insert link'
  },
  assignToSkill: {
    id: 'assignToSkill',
    defaultMessage: 'Assign To Skill'
  },
  failedCreateLearningActivity: {
    id: 'failedCreateLearningActivity',
    defaultMessage: 'Failed to create Learning Activity'
  },
  successCreateLearningActivity: {
    id: 'successCreateLearningActivity',
    defaultMessage: 'Successfully created new Learning Activity'
  },
  uniqueLearningActivityName: {
    id: 'uniqueLearningActivityName',
    defaultMessage: 'Provide a unique Learning Activity name'
  },
  workspacesTitle: {
    id: 'workspacesTitle',
    defaultMessage: 'Associated Workspaces'
  },
  workspacesDesc: {
    id: 'workspacesDesc',
    defaultMessage: 'Displaying workspaces which are associated with any of the linked learning materials'
  },
  workspacesWarning: {
    id: 'workspacesWarning',
    defaultMessage:
      'This activity is only available for members of the following workspaces. Make sure your team has access to these workspaces.'
  },
  incorrectGuide: {
    id: 'incorrectGuide',
    defaultMessage:
      'Incorrect link. Make sure to copy the URL for the correct workspace in your SwipeGuide Instruction Builder.'
  },
  labels: {
    id: 'labels',
    defaultMessage: 'Labels'
  },
  tags: {
    id: 'GuideListFilters.tags',
    defaultMessage: 'Tags'
  },
  max10TagsOnLearning: {
    id: 'max10TagsOnLearning',
    defaultMessage: 'You can add max. 10 tags per learning activity.'
  }
});

export default messages;
