//lodash
import get from 'lodash/get';
import maxBy from 'lodash/maxBy';

export const isDraftVersionId = id => {
  return !id;
};

export const isLatestVersionId = (id, allVersions) => {
  if (!id) {
    return false;
  }

  const liveVersion = findLiveVersion(allVersions);
  const liveVersionId = get(liveVersion, '_id');

  return liveVersionId === id;
};

export const findLiveVersion = allVersions =>
  allVersions &&
  maxBy(
    allVersions.map(v => ({...v, version: parseInt(v.version)})),
    'version',
    Number
  );

export const getVersionById = (versions, versionId) => {
  const versionsById = {};
  for (const {_id, version} of versions ?? []) {
    versionsById[_id] = version;
  }

  return versionsById[versionId];
};

export const ensureDraftVersion = (originalFn, self) =>
  function () {
    const {store} = self.props;
    const {saveAsDraftDialog: dialog} = store;

    return dialog
      .check()
      .then(() => originalFn.apply(self, arguments))
      .catch(() => undefined);
  };
