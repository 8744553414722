import React, {useEffect, useState} from 'react';
import {TypedDocumentNode, useLazyQuery} from '@apollo/client';
import {DropdownIcon, Td, TrCollapsable, colors, Spinner} from './styles';
import {getOperationName} from '@apollo/client/utilities';
import {startCase} from 'lodash';
import {DataItem, Sort} from './types';
import {calculateOrderIndex, getSortedData} from './utils';

type ExpandableRowProps = {
  item: DataItem;
  action?: (item: DataItem) => void;
  depth: number;
  isParent?: boolean;
  onClick: (itemId: string) => void;
  showAction?: (item: DataItem) => boolean;
  childrenQuery: TypedDocumentNode;
  childrenQueryOptions: {
    options: (options: any) => any;
  };
  queryOptions: (item: DataItem) => any;
  extraColumnsFields?: string[];
  enableExpand: boolean;
  sorting: Sort;
  indexed?: boolean;
  index: number;
  parentIndex?: string;
};

const ExpandableRow = (props: ExpandableRowProps) => {
  const {
    item,
    action,
    depth,
    isParent,
    onClick,
    showAction,
    childrenQuery,
    childrenQueryOptions,
    queryOptions,
    extraColumnsFields,
    enableExpand = true,
    sorting = 'asc',
    indexed = false,
    index,
    parentIndex = ''
  } = props;

  const [toggled, setToggled] = useState(false);

  const [queryChildren, {data, loading}] = useLazyQuery(
    childrenQuery,
    childrenQueryOptions.options(queryOptions(item))
  );

  useEffect(() => {
    if (toggled) queryChildren();
  }, [toggled]);

  const handleToggled = () => {
    if (isParent) setToggled(t => !t);
  };

  const transformData = data => {
    if (data === 0 || parseInt(data) === 0) return '-';
    return data;
  };

  const opData = data?.[getOperationName(childrenQuery) as string];
  const loadedData = opData ? [...opData] : [];

  return (
    <>
      <TrCollapsable color={colors.textGrey} depth={depth} data-cy={`expandable-row-${item.name.split(' ').join('-')}`}>
        <Td
          onClick={enableExpand ? handleToggled : () => onClick(item.id)}
          width="50px"
          data-cy={`expandable-row-toggle-${item.name.split(' ').join('-')}`}
        >
          {enableExpand && <DropdownIcon open={!toggled} disabled={!isParent} />}
        </Td>
        <Td left onClick={() => onClick(item.id)} data-cy={`${item.name.split(' ').join('-')}`}>
          {indexed && calculateOrderIndex(depth, index, parentIndex) + '.'} {startCase(item.name)}{' '}
          {loading && <Spinner />}
        </Td>
        {extraColumnsFields?.length &&
          extraColumnsFields.map((field: string) => <Td key={`${field}${item.name}`}>{transformData(item[field])}</Td>)}
        <Td>{showAction?.(item) && action?.(item)}</Td>
      </TrCollapsable>
      {toggled && (
        <>
          {getSortedData(loadedData, sorting)?.map((childItem: DataItem, i: number) => (
            <ExpandableRow
              {...props}
              key={childItem.id}
              item={childItem}
              isParent={childItem.children}
              depth={depth + 1}
              extraColumnsFields={extraColumnsFields}
              index={i}
              parentIndex={calculateOrderIndex(depth, index, parentIndex)}
              indexed={indexed}
            />
          ))}
        </>
      )}
    </>
  );
};

export default ExpandableRow;
