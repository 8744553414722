import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';
import {commonPalette} from 'shared/styles/palette';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 32px;
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  margin-top: 12px;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 28
}))`
  color: ${commonPalette.txtGrey};
`;

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
`;

export const HeaderSubContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const HeaderSubContentItem = styled.div`
  font-size: 14px;
  display: flex;
  gap: 3px;
  margin-top: 3px;
`;

export const HeaderSubContentItemLabel = styled.span`
  font-size: 14px;
`;

export const HeaderSubContentItemValue = styled.span`
  font-weight: 600;
  margin-left: 3px;
`;

export const RightContent = styled.div`
  margin-top: 10px;
  margin-left: auto;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const RoleDiv = styled.div`
  color: #a3a3a3;
  margin-right: 5px;
`;
