import {TagsList, TagsLite} from 'api/tag/queries';

export const refreshTagsList = locale => ({
  query: TagsList,
  variables: {locale}
});

export const refreshTagsLiteList = locale => ({
  query: TagsLite,
  variables: {locale}
});
