import PropTypes from 'prop-types';

export const getMediaKey = contentKey =>
  contentKey ? (contentKey === 'instruction' ? 'media' : `${contentKey}Media`) : null;

export const getMediaIdKey = contentKey => (contentKey ? `${getMediaKey(contentKey)}Id` : null);

export const contentKeys = ['instruction'];
export const mediaKeys = contentKeys.map(getMediaKey);
export const mediaIdKeys = contentKeys.map(getMediaIdKey);

export const LayoutType = PropTypes.shape({
  cardSpacing: PropTypes.number,
  imageWidth: PropTypes.number,
  orientation: PropTypes.oneOf(['landscape', 'portrait']),
  slideHeight: PropTypes.number,
  slideWidth: PropTypes.number
});

export const MediaType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  url: PropTypes.string.isRequired
});

export const StepNoteType = PropTypes.shape({
  iconId: PropTypes.string,
  svg: PropTypes.string,
  media: MediaType,
  label: PropTypes.string,
  content: PropTypes.string,
  type: PropTypes.string
});

export const StepType = PropTypes.shape({
  instruction: PropTypes.string,
  media: MediaType,
  id: PropTypes.string.isRequired,
  providedTranslations: PropTypes.arrayOf(PropTypes.string),
  notes: PropTypes.arrayOf(StepNoteType)
});
