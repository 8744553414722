import {action, computed, observable, makeObservable} from 'mobx';

//helpers
import {CAMPAIGN_STATUS_ENUMS} from 'shared/enums';

/**
 * The state of the <ChangeCampaignStatusDialog /> component.
 */
class ChangeCampaignStatusDialog {
  /**
   * Whether the dialog is currently submitting data to the API.
   */
  @observable
  isSubmitting = false;

  /**
   * Whether the dialog should be visible to the user.
   *
   * Note: this is not using the "is" prefix ("isOpen") to be
   * consistent with naming in other dialog stores.
   */
  @observable
  opened = false;

  /**
   * The campaign entity to update the status of.
   *
   * Is `null` unless the dialog is opened.
   */
  @observable.ref
  campaign = null;

  /**
   * The status the campaign is prompted to update to.
   */
  @observable
  targetStatus = CAMPAIGN_STATUS_ENUMS.PUBLISHED;

  constructor() {
    makeObservable(this);
  }

  /**
   * Whether the target status implies the "activation" of a campaign.
   */
  @computed
  get isActivate() {
    return this.targetStatus === CAMPAIGN_STATUS_ENUMS.PUBLISHED;
  }

  /**
   * Opens the dialog.
   *
   * @param {Object} options
   * @param {Object} campaign - The campaign entity to update the status of.
   * @param {String} targetStatus - The status to update to.
   */
  @action
  open = ({campaign, targetStatus}) => {
    this.isSubmitting = false;
    this.opened = true;
    this.campaign = campaign;
    this.targetStatus = targetStatus;
  };

  /**
   * Resets the dialog to the initial state and closes it.
   */
  @action
  reset = () => {
    this.isSubmitting = false;
    this.opened = false;
    this.campaign = null;
    this.targetStatus = CAMPAIGN_STATUS_ENUMS.PUBLISHED;
  };

  /**
   * Sets whether the dialog is currently submitting data to the API.
   */
  @action
  setIsSubmitting = isSubmitting => {
    this.isSubmitting = isSubmitting;
  };

  @action
  toggleVisibility = () => {
    this.opened = !this.opened;
  };
}

export default ChangeCampaignStatusDialog;
