import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {stepCardMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';
import {boxShadow, boxShadowTransition} from 'styles/shadows';

//components
import $CardToolbar from 'components/CardToolbar';
import $ClickOutside from 'components/ClickOutside';
import $DragHandle from 'components/DragHandle';
import $Editor from 'components/Editor';
import $MediaPicker from 'components/MediaPicker';
import $Link from 'shared/components/Link';
import $RatioContainer from 'shared/components/RatioContainer';
import {StepContentDesktop as $StepContent} from 'shared/components/StepContent';
import $Icon from 'ui-components/Layout/Icon';
import Button from 'ui-components/Button';

//constants
const numberWidth = 28;

export const StepCard = styled.div`
  height: ${stepCardMetrics.height}px;
  position: relative;
  width: ${stepCardMetrics.width}px;

  ${p =>
    p.design === 'default' &&
    `
    background: #fff;
    box-shadow: ${boxShadow.medium};
    transition: ${boxShadowTransition};
    &:hover {
      box-shadow: ${boxShadow.large};
    }
    `}

  &:hover {
    ${() => StyledPencilIcon} {
      opacity: 1;
    }

    ${() => StyledButton} {
      display: inline;
    }

    ${() => StepContentWrapper} {
      height: 60%;
    }

    ${() => ButtonWrapper} {
      opacity: 1;
    }
  }

  ${p =>
    p.design === 'ghostly' &&
    `
    background: ${commonPalette.ghostlyGreen};
    filter: blur(4px);
  `}
`;

export const InnerWrapper = styled.div`
  ${flex.vertical};
  height: 100%;
  position: relative;

  ${p =>
    p.isInvisible &&
    `
    // NB. In Microsoft Edge, opacity won't be applied
    // to its absolute-positioned children unless the
    // element is positioned itself

    opacity: 0;
  `}
`;

export const StyledDragHandle = styled($DragHandle)`
  height: 60px;
  position: absolute;
  right: 100%;
  top: 0;
  width: ${stepCardMetrics.gutterX}px;
`;

export const StepNumber = styled.div`
  background: #124456;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  height: ${numberWidth}px;
  left: ${numberWidth / -2}px;
  line-height: ${numberWidth}px;
  position: absolute;
  text-align: center;
  top: 16px;
  width: ${numberWidth}px;
`;

export const StyledCoverRatioContainer = styled($RatioContainer).attrs({
  ratio: [4, 3]
})`
  border-bottom: 1px solid ${commonPalette.darkestGray};
  flex-shrink: 0; // IE11
  position: relative;
  z-index: 0;

  ${p =>
    p.backgroundUrl &&
    `
    background-image: url('${p.backgroundUrl}');
    background-position: center;
    background-size: cover;
    color: #fff; // to paint the media picker icon

    &::after {
      background: #383838;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }
  `}
`;

export const StyledMediaPicker = styled($MediaPicker).attrs({
  backgroundAspectRatio: 4 / 3,
  backgroundSizes: `${stepCardMetrics.width}px`
})`
  height: 100%;
`;

export const StyledLink = styled($Link)`
  ${flex.vertical};
  flex-grow: 1;
  padding: 16px 16px 16px 16px;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const StyledEditor = styled($Editor)`
  height: 100%;
  overflow: hidden;

  .rdw-editor-wrapper {
    ${flex.vertical};
    height: 100%;
  }

  .rdw-editor-toolbar {
    box-shadow: none !important;
    flex-shrink: 0;
    z-index: 9999 !important;
    margin: 16px !important;
    order: 1;
  }

  .rdw-editor-main {
    padding: 16px 35px 0 16px;
  }

  .rdw-option-wrapper {
    border-radius: 4px !important;
    box-shadow: ${boxShadow.floatingToolbar};

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`;

export const StepContentWrapper = styled.div`
  height: 60%;
`;

export const StepContentEditorWrapper = styled($ClickOutside)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const StyledStepContent = styled($StepContent).attrs(p => ({
  fadeColor: p.isHovered ? '#ebebeb' : undefined
}))`
  border-radius: 2px;
  cursor: text;
  margin: -1px -8px;
  height: 100%;
  padding: 1px 24px 1px 8px;
  word-wrap: break-word;
  background-color: ${p => (p.isHovered ? '#ebebeb' : 'none')};
  color: ${commonPalette.txBlack};
`;

export const StyledCardToolbar = styled($CardToolbar)`
  color: ${p => (p.isInverted ? '#fff' : '#000')};
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const StyledButton = styled(Button)`
  min-height: 36px;
  width: fit-content;
`;

export const StyledPencilIcon = styled($Icon).attrs({
  name: 'pencil',
  size: 16
})`
  position: absolute;
  right: 0.75rem;
  top: 20px;
  opacity: 0;
  color: ${commonPalette.txBlack};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({isChecklist, checklistScoring}) =>
    isChecklist && !checklistScoring ? 'space-between' : 'flex-end'};
  align-items: center;
  min-height: 40px;
  margin-top: 10px;
`;
