import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';

import StepsTabs from 'components/StepsTabs';
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';

import {Team} from 'api/team/queries';
import {teamOptions} from 'api/team/query-options';

import messages from './messages';

@inject('store')
@graphql(Team, teamOptions)
@observer
class TeamDetailsView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      editTeamPage,
      platform: {guideApproval}
    } = store;

    const translations = mapValues(pick(messages, ['users', 'guideApproval', 'notifications', 'workspaces']), message =>
      formatMessage(message)
    );

    editTeamPage.setTranslations(translations);
    if (editTeamPage.guideApprovalEnabledForPlatform === null) {
      editTeamPage.setGuideApprovalFlag(guideApproval);
    }
  }

  render() {
    const {
      store: {editTeamPage},
      data: {team}
    } = this.props;
    const {loading, stepsStoreForView, goToTeamsPage} = editTeamPage;

    return (
      <Container>
        {team && <ViewTitle title={team.name} />}
        <StepsTabs
          mode={'view'}
          loading={loading}
          stepsStore={stepsStoreForView}
          page={editTeamPage}
          cancel={goToTeamsPage}
          customSecondaryButtonText={messages.backToTeams}
        />
      </Container>
    );
  }
}

export default injectIntl(TeamDetailsView);
