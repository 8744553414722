import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import get from 'lodash/get';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import notification from 'utils/notification-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Checkbox from 'components/Checkbox';

import {SaveMedia, EditMedia} from 'api/media/mutations';
import {saveMediaOptions, editMediaOptions} from 'api/media/mutation-options';

//styles
import {MediaActions, Action, Title, Note} from './styles';

//messages
import messages from './messages';

import strategies from './eventStrategies';
import {EVENT_TYPES} from 'api/tracking/constants';

@graphql(SaveMedia, saveMediaOptions)
@graphql(EditMedia, editMediaOptions)
@inject('store')
@observer
class MediaActionsComponent extends Component {
  enableAudioSuccess = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    notification.success(formatMessage(messages.successMessage));
  };

  enableAudioError = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    notification.error(formatMessage(messages.errorMessage));
  };

  onEnableAudioChange = () => {
    const {
      store: {mediaPickerDialog: dialog},
      editMediaMutation
    } = this.props;
    const {selectedMedia, saveMedia} = dialog;
    selectedMedia.metadata = {
      ...selectedMedia.metadata,
      enableAudio: !get(selectedMedia.metadata, 'enableAudio', false)
    };
    saveMedia(editMediaMutation, this.enableAudioSuccess, this.enableAudioError);
  };

  executeStrategy = (eventType, ...args) => {
    const strategy = strategies[eventType];
    if (!strategy) {
      throw new Error(`No strategy found for event type "${eventType}"`);
    }

    return strategy(this.props.store, ...args);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {mediaPickerDialog: dialog, router},
      saveMedia,
      editMedia
    } = this.props;
    const {isSelectedMediaVideo, selectedMedia} = dialog;
    const {currentRoute} = router;

    const isInThemeView = currentRoute.path.includes('newTheme') || currentRoute.path.includes('editTheme');
    const shouldAnyActionsBeShown = Boolean(selectedMedia);
    const shouldImageActionsBeShown = shouldAnyActionsBeShown && !isSelectedMediaVideo && !isInThemeView;
    const enabledAudio = get(selectedMedia, 'metadata.enableAudio', false);

    return (
      <MediaActions>
        <Title>
          <FormattedMessage {...messages.actions} />
        </Title>
        {!shouldAnyActionsBeShown && <FormattedMessage {...messages.notAvailable} />}
        {shouldAnyActionsBeShown && (
          <Action onClick={this.executeStrategy(EVENT_TYPES.REMOVE_IMAGE)}>
            <FormattedMessage {...messages.removeImage} />
          </Action>
        )}
        {shouldImageActionsBeShown && (
          <Action onClick={this.executeStrategy(EVENT_TYPES.EDIT_IMAGE)}>
            <FormattedMessage {...messages.imageEdit} />
          </Action>
        )}
        {shouldImageActionsBeShown && (
          <Action onClick={this.executeStrategy(EVENT_TYPES.ADD_SHAPES)}>
            <FormattedMessage {...messages.addShapes} />
            <Note>
              <FormattedMessage {...messages.visibleOnStepsAndInstructions} />
            </Note>
          </Action>
        )}
        {shouldImageActionsBeShown && (
          <Action
            onClick={this.executeStrategy(
              EVENT_TYPES.DUPLICATE_IMAGE_WITHOUT_SHAPES,
              saveMedia,
              selectedMedia.url,
              editMedia
            )}
          >
            <FormattedMessage {...messages.duplicateImage} />
            <Note>
              <FormattedMessage {...messages.withoutShapes} />
            </Note>
          </Action>
        )}
        {shouldAnyActionsBeShown && isSelectedMediaVideo && (
          <Checkbox
            checked={enabledAudio}
            onChange={this.onEnableAudioChange}
            caption={formatMessage(messages.enableAudio)}
          />
        )}
      </MediaActions>
    );
  }
}

export default injectIntl(MediaActionsComponent);
