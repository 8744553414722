import PropTypes from 'prop-types';
import React, {Component} from 'react';

//lodash
import find from 'lodash/find';
import get from 'lodash/get';

//helpers
import {isDraftVersionId, isLatestVersionId} from 'utils/versioning-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';
import PillButton from 'components/PillButton';

class VersionButtonComponent extends Component {
  static propTypes = {
    currentVersionId: PropTypes.string,
    versions: PropTypes.array
  };

  render() {
    const {currentVersionId, versions, isPublished, onClick} = this.props;

    return (
      <PillButton
        dataCy="versions-link"
        title={this.renderMessage(versions, currentVersionId, isPublished)}
        icon="layer-group"
        onClick={onClick}
      />
    );
  }

  renderMessage(versions, currentVersionId, isPublished) {
    if (isDraftVersionId(currentVersionId)) {
      return <FormattedMessage {...messages.draftVersion} />;
    }

    if (isPublished && isLatestVersionId(currentVersionId, versions)) {
      return <FormattedMessage {...messages.liveVersion} />;
    }

    if (currentVersionId === 'review') {
      return <FormattedMessage {...messages.approvalVersion} />;
    }

    if (currentVersionId === 'live-review') {
      return <FormattedMessage {...messages.liveReview} />;
    }

    const currentVersion = get(find(versions, {_id: currentVersionId}), 'version');

    return (
      <FormattedMessage
        {...messages.version}
        values={{
          version: currentVersion
        }}
      />
    );
  }
}

export default VersionButtonComponent;
