import React, {ReactElement} from 'react';
import {InfoBoxWrapper, StyledIcon, DraftButton} from './styles';

type InfoBoxProps = {
  content: string;
  type: 'informational' | 'warning' | 'error';
  size?: 'small';
  backToDraftContent?: string;
  backToDraftFunc?: () => void;
};

const iconVariants = {
  informational: 'info',
  warning: 'warning',
  error: 'danger'
};

const InfoBox = ({type, backToDraftContent, backToDraftFunc, size, content}: InfoBoxProps): ReactElement => {
  return (
    <InfoBoxWrapper type={type} size={size} draft={backToDraftContent} data-testid="infobox">
      <StyledIcon iconId={iconVariants[type]} type={type} />
      {content}
      {backToDraftContent && (
        <DraftButton type={type} onClick={() => backToDraftFunc?.()}>
          {backToDraftContent}
        </DraftButton>
      )}
    </InfoBoxWrapper>
  );
};
export default InfoBox;
