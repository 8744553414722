import styled from 'styled-components';

//helpers
import {commonPalette} from 'shared/styles/palette';
import {smallBorderRadius} from 'styles/sizes';

//components
import $Button from 'ui-components/Button';

const borderColor = '#c6c6c6';

export const FiltersContainer = styled.div`
  background: ${commonPalette.darkestGray};
  border-radius: ${smallBorderRadius}px;
  border: 1px solid ${borderColor};
  line-height: 24px;
`;

export const TopSection = styled.div`
  display: flex;
  padding: 11px 25px 13px;
`;

export const BottomSection = styled.div`
  border-top: 1px solid ${borderColor};
  display: flex;
  flex-wrap: wrap;
  padding: 11px 0 13px 25px;
`;

export const Title = styled.div`
  font-size: 16px;
`;

export const CloseButton = styled($Button).attrs({
  type: 'button',
  underlined: true
})`
  color: inherit;
  margin-left: auto;
`;

export const Field = styled.div`
  display: flex;
  margin-right: 24px;
`;

export const Label = styled.div`
  margin-right: 12px;
  opacity: 0.5;
`;
