import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import messages from './messages';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

import ViewTitle from 'ui-components/Layout/ViewTitle';
import LoadingMessage from 'components/LoadingMessage';
import SearchBar from 'ui-components/Layout/SearchBar';
import {HeaderWrapper, CellWrapper} from 'ui-components/Layout/DataTable/styles';
import Icon from 'ui-components/Layout/Icon';
import {FormattedMessage} from 'components/FormattedComponents';
import Container from 'ui-components/Layout/Container';
import Button from 'ui-components/Button';

import {StyledText, DataTable, NoSkillTextContainer} from './styles';

import {JobTitles} from 'api/skills/queries';
import {jobTitlesOptions} from 'api/skills/query-options';

export const NoSkillRolesRaw = props => {
  const {
    intl: {formatMessage},
    store: {router}
  } = props;

  return (
    <>
      <NoSkillTextContainer>
        <Icon name="folder" size={25} />
        <StyledText>{formatMessage(messages.noJobTitles)}</StyledText>
        <Button
          secondary
          size={50}
          label={formatMessage(messages.createJobTitle)}
          onClick={() => {
            router.goTo(views.createJobTitle, {});
          }}
        />
      </NoSkillTextContainer>
    </>
  );
};

export const NoSkillRolesWithHoc = inject('store')(injectIntl(NoSkillRolesRaw));

export const Loading = () => {
  return (
    <LoadingMessage>
      <FormattedMessage {...messages.loading} />
    </LoadingMessage>
  );
};

export const JobTitlesTable = props => {
  const {
    jobTitles,
    intl: {formatMessage},
    store: {router}
  } = props;

  const columns = [
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.jobTitleName)}</HeaderWrapper>,
      accessor: 'name',
      resizable: true,
      Cell: ({value}) => (
        <CellWrapper paddingLeft>
          <span title={value}>{value}</span>
        </CellWrapper>
      )
    }
  ];

  return (
    <DataTable
      data={jobTitles}
      columns={columns}
      hoverEffect
      defaultSorted={[
        {
          id: 'name',
          desc: false
        }
      ]}
      getTrGroupProps={(_, {original}) => ({
        onClick: () => router.goTo(views.editJobTitle, {id: original.id})
      })}
    />
  );
};

export const JobTitlesTableWithHoc = inject('store')(injectIntl(JobTitlesTable));

@inject('store')
@graphql(JobTitles, jobTitlesOptions)
@observer
class JobTitlesView extends Component {
  state = {
    searchTerm: ''
  };

  componentDidMount() {
    const {store} = this.props;
    const {app} = store;

    const actionMenuItems = [
      {
        id: 'create',
        view: 'createJobTitle'
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  }

  updateSearchTerm = e => {
    this.setState({searchTerm: e.target.value});
  };

  render() {
    const {
      data: {skillRoles: jobTitles, loading},
      intl: {formatMessage}
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    const filteredJobTitles = jobTitles.filter(jobTitle =>
      jobTitle.name?.toLowerCase().includes(this.state.searchTerm?.toLowerCase())
    );

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.title)}
          right={
            jobTitles.length && (
              <SearchBar
                placeholder={formatMessage(messages.searchJobTitlesPlaceholder)}
                onChange={this.updateSearchTerm}
                width={250}
              />
            )
          }
        />
        {jobTitles.length ? <JobTitlesTableWithHoc jobTitles={filteredJobTitles} /> : <NoSkillRolesWithHoc />}
      </Container>
    );
  }
}

export default injectIntl(JobTitlesView);
