import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';
import mutationNames from 'api/team/constants';
import {mapValues, pick} from 'lodash';

//styled-components
import Container from 'ui-components/Layout/Container';
import {DataTable, TeamNameField, RemoveButton, NameWrapper, Name, Url, StyledLink, ContentWrapper} from './styles';

// queries
import {Team} from 'api/team/queries';
import {RemoveTeamFromDomain, AddDomainsToTeam, EditTeam} from 'api/team/mutations';

//options
import {teamOptions} from 'api/team/query-options';
import {removeTeamFromDomainOptions, addDomainsOptions, editTeamOptions} from 'api/team/mutation-options';

//components
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import ButtonGroup from 'ui-components/ButtonGroup';
import Icon from 'ui-components/Icon';
import Button from 'ui-components/Button';

//messages
import messages from './messages';
import {HeaderWrapper, CellWrapper} from 'ui-components/Layout/DataTable/styles';

@inject('store')
@graphql(Team, teamOptions)
@graphql(AddDomainsToTeam, addDomainsOptions)
@graphql(RemoveTeamFromDomain, removeTeamFromDomainOptions)
@graphql(EditTeam, editTeamOptions)
@observer
class TeamWorkspacesView extends Component {
  UNSAFE_componentWillMount() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {editTeamPage, editDomainPage, router} = store;
    const translations = mapValues(
      pick(messages, [
        'removeWorkspaceSuccess',
        'removeWorkspaceFailure',
        'teamUpdateSuccess',
        'teamUpdateFailure',
        'connectNewWorkspace'
      ]),
      message => formatMessage(message)
    );

    editTeamPage.setTranslations(translations);
    editDomainPage.setPreviousView(router.currentRoute.path, router.params.path);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: {team, loading, error},
      store
    } = nextProps;
    const {editTeamPage} = store;

    if (!loading && team) {
      editTeamPage.setForm(team);
    }

    if (error || (!loading && !team)) {
      store.router.goTo(views.teams, {});
    }
  }

  removeWorkspace = workspace => {
    const {
      data: {team},
      intl: {formatMessage},
      store
    } = this.props;
    const removeWorkspaceMutation = this.props[mutationNames.REMOVE_TEAM_FROM_DOMAIN];
    const {archiveDialog} = store;

    const translations = {
      action: formatMessage(messages.removeWorkspace),
      confirmation: formatMessage(messages.removeWorkspaceConfirm),
      body: formatMessage(messages.removeWorkspaceBody),
      archiveSuccess: formatMessage(messages.removeWorkspaceSuccess),
      archiveFailure: formatMessage(messages.removeWorkspaceFailure)
    };

    archiveDialog.open({
      params: {teamId: team.id, domainId: workspace.id},
      mutation: removeWorkspaceMutation,
      translations,
      itemPrimary: workspace.name
    });
  };

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  render() {
    const {
      intl: {formatMessage},
      data: {loading, error, team},
      store,
      editTeam,
      viewMode
    } = this.props;
    const {
      editTeamPage,
      platform: {sso: ssoEnabled},
      auth: {user},
      connectNewWorkspaceDialog,
      auth: {
        user: {isIPA, isPlatformAdmin}
      }
    } = store;
    const {form, updateTeamName, resetForm} = editTeamPage;
    const showLoading = loading || error;
    const canManageTeam = team?.canManageTeam;
    const teamDomains = team && team.domains;
    const editTeamMutation = this.props[mutationNames.EDIT_TEAM];

    const columns = [
      {
        Header: <HeaderWrapper>{formatMessage(messages.workspace)}</HeaderWrapper>,
        accessor: 'name',
        resizable: true,
        Cell: ({original: {name, slug, isDefault}}) => {
          const url = isDefault ? `https://${window.location.hostname}` : `https://${slug}-${window.location.hostname}`;
          return (
            <NameWrapper>
              <Name title={name}>{name}</Name>
              <Url>
                <StyledLink title={url} href={url} target="_blank">
                  {url}
                </StyledLink>
              </Url>
            </NameWrapper>
          );
        }
      },
      {
        resizable: true,
        Header: (
          <HeaderWrapper>
            {formatMessage(messages.managementTeam)}
            <Icon style={{marginLeft: 8}} popoverContent={formatMessage(messages.managementTeamDescription)} />
          </HeaderWrapper>
        ),
        accessor: 'managementTeam.name',
        Cell: ({value}) => (
          <CellWrapper>
            <span title={value}>{value}</span>
          </CellWrapper>
        )
      },
      {
        resizable: true,
        Header: () => (
          <HeaderWrapper>
            {formatMessage(messages.defaultTeam)}
            <Icon style={{marginLeft: 8}} popoverContent={formatMessage(messages.defaultTeamDescription)} />
          </HeaderWrapper>
        ),
        accessor: 'defaultTeam.name',
        Cell: ({value}) => (
          <CellWrapper>
            <span title={value}>{value}</span>
          </CellWrapper>
        ),
        show: ssoEnabled,
        width: 140
      },
      {
        accessor: 'id',
        Cell: workspace => {
          const isManagementTeam = workspace.original.managementTeam.id === team.id;

          return (
            (user.isPlatformAdmin || user.isIPA) &&
            !isManagementTeam && (
              <CellWrapper>
                <RemoveButton onClick={() => this.removeWorkspace(workspace.original)} />
              </CellWrapper>
            )
          );
        },
        sortable: false,
        width: 24
      }
    ];

    if (showLoading)
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );

    return (
      <Container noPaddingLeft={editTeam}>
        <ContentWrapper>
          {!editTeam && (
            <TeamNameField
              form={form}
              field="name"
              readOnly={!canManageTeam}
              onSubmit={() => updateTeamName(editTeamMutation)}
              onCancel={() => resetForm()}
            />
          )}
          <DataTable
            data={teamDomains}
            columns={columns}
            noDataMessage={formatMessage(messages.noWorkspaces)}
            hoverEffect
            defaultSorted={[
              {
                id: 'name',
                desc: false
              }
            ]}
          />
          {!viewMode && (isIPA || isPlatformAdmin) && (
            <div>
              <Button
                underlined
                disabled={false}
                iconId="add"
                onClick={connectNewWorkspaceDialog.open}
                label={formatMessage(messages.connectNewWorkspace)}
              />
            </div>
          )}
        </ContentWrapper>

        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          secondary={{
            label: formatMessage(messages.backToOverview),
            onClick: () => store.router.goTo(views.teams, {})
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(TeamWorkspacesView);
