import gql from 'graphql-tag';

export const CreateUser = gql`
  mutation createUser($newUser: UserCreate!, $newUserTeamRoles: [TeamMemberAddNewUser]) {
    createUser(newUser: $newUser, newUserTeamRoles: $newUserTeamRoles) {
      id
    }
  }
`;

export const EditUser = gql`
  mutation editUser($id: String!, $userEdit: UserEdit!) {
    editUser(id: $id, userEdit: $userEdit) {
      id
    }
  }
`;

export const UpdateProfile = gql`
  mutation updateProfile($userUpdate: UserUpdate!) {
    updateProfile(userUpdate: $userUpdate) {
      id
      fullName
    }
  }
`;

export const UpdatePassword = gql`
  mutation updatePassword($updatePassword: UserSetPassword!) {
    updatePassword(updatePassword: $updatePassword) {
      id
    }
  }
`;

export const ForgotPassword = gql`
  mutation forgotPassword($email: String) {
    forgotPassword(email: $email) {
      result
    }
  }
`;

export const ResetPassword = gql`
  mutation resetPassword($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`;

export const SetPassword = gql`
  mutation setPassword($token: String!, $password: String!) {
    setPassword(token: $token, password: $password)
  }
`;

export const DisableUser = gql`
  mutation disableUser($id: String!) {
    disableUser(id: $id) {
      id
    }
  }
`;

export const DisableUserWithResult = gql`
  mutation disableUserWithResult($id: String!) {
    disableUserWithResult(id: $id) {
      result
      error
    }
  }
`;

export const EnableUser = gql`
  mutation enableUser($id: String!) {
    enableUser(id: $id) {
      id
    }
  }
`;

export const InviteUser = gql`
  mutation inviteUser($email: String, $phoneNumber: String) {
    inviteUser(email: $email, phoneNumber: $phoneNumber) {
      result
    }
  }
`;

export const DeleteUser = gql`
  mutation deleteUser($id: String!) {
    deleteUser(id: $id) {
      result
    }
  }
`;
