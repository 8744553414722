import React from 'react';
import {observer} from 'mobx-react';

//helpers
import {styleable} from 'shared/decorators';
import {bindField} from 'shared/utils/input-utils';

//components
import UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';

//styled-components
import {Prefix, Slug, StyledField} from './styles';

function SlugComponent({field, form, placeholder}) {
  return (
    <Slug>
      <Prefix>
        {window.location.host}
        {'\xA0/\xA0\u2026\xA0/\xA0'}
      </Prefix>
      <StyledField
        inputComponent={<UnderlinedTextbox {...(form && field && bindField(form, field))} placeholder={placeholder} />}
        field={field}
        form={form}
      />
    </Slug>
  );
}

export default observer(styleable(SlugComponent));
