import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';

//helpers
import {ensureDraftVersion} from 'utils/versioning-utils';
import {MEDIA_PREVIEW_TYPE} from 'config/enums';

//styles
import {GuideDetails, BackgroundImage, InnerWrapper, StyledMediaPicker} from './styles';

//components
import DetailsViewComponent from 'components/DetailsViewMode';
import GuideDetailsEditMode from 'components/GuideDetailsEditMode';

//mutations
import {UpdateGuideMedia} from 'api/guide/mutations';

//mutation-options
import {updateGuideMediaOptions} from 'api/guide/mutation-options';

//messages
import messages from './messages';

@inject('store')
@graphql(UpdateGuideMedia, updateGuideMediaOptions)
@observer
class GuideDetailsComponent extends Component {
  componentDidMount() {
    const {updateGuideMediaMutation, guideStore} = this.props;
    const {setUpdateMediaMutation} = guideStore;
    setUpdateMediaMutation && setUpdateMediaMutation(updateGuideMediaMutation);
  }

  componentWillUnmount() {
    const {guideStore} = this.props;
    guideStore.reset();
  }

  openGuideVersions = () => {
    const {store} = this.props;

    const {editGuidePage: page, guideVersionsDialog: dialog} = store;

    dialog.open({
      isUnderApproval: page.guide.isUnderApproval,
      versions: page.guide.versions,
      isPublished: page.guide.publishStatus === 'PUBLISHED',
      refetchQueries: [page.refetchGuideQuery]
    });
  };

  handleTitleClick = ensureDraftVersion(() => {
    const {guideStore} = this.props;
    guideStore.setEditMode(true);
  }, this);

  render() {
    const {
      guideStore,
      store,
      publish,
      requestGuideApproval,
      withdrawGuideApproval,
      submitGuideApprovalReviewMutation,
      submitLiveVersionReviewMutation,
      translations,
      submitMutation,
      intl: {formatMessage},
      unpublish,
      banner
    } = this.props;
    const {
      editMode,
      form,
      guideCoverUrl,
      setGuideCoverUrl,
      setGuideCoverMetadata,
      updateMedia,
      metadata,
      showPublishUpdatesLabel,
      showPublishOldVersionLabel
    } = guideStore;
    const {
      saveAsDraftDialog,
      detailsCard: {cardHeight},
      editGuidePage
    } = store;

    let publishButtonLabel = messages.publish;
    if (showPublishUpdatesLabel) {
      publishButtonLabel = messages.publishUpdates;
    } else if (showPublishOldVersionLabel) {
      publishButtonLabel = messages.publishOldVersion;
    }

    return (
      <GuideDetails data-cy="guide-details">
        <BackgroundImage src={guideCoverUrl} metadata={metadata} emptyIcon={null} cardHeight={cardHeight} />
        <InnerWrapper>
          {banner}
          <StyledMediaPicker
            canOpen={() => saveAsDraftDialog.check()}
            field="mediaId"
            form={form}
            previewType={MEDIA_PREVIEW_TYPE.GUIDE_OVERVIEW}
            setCoverUrl={setGuideCoverUrl}
            setMetadata={setGuideCoverMetadata}
            updateMedia={updateMedia}
            showDarkerOverlay={false}
            label={formatMessage(messages.editImage)}
          />
          {editMode ? (
            <GuideDetailsEditMode
              submitMutation={submitMutation}
              translations={translations}
              guideStore={guideStore}
              editGuidePage={editGuidePage}
            />
          ) : (
            <DetailsViewComponent
              contentType="guide"
              selectedStore={guideStore}
              submitGuideApprovalReviewMutation={submitGuideApprovalReviewMutation}
              submitLiveVersionReviewMutation={submitLiveVersionReviewMutation}
              requestGuideApproval={requestGuideApproval}
              withdrawGuideApproval={withdrawGuideApproval}
              publish={publish}
              unpublish={unpublish}
              translations={translations}
              publishButtonLabel={publishButtonLabel}
            />
          )}
        </InnerWrapper>
      </GuideDetails>
    );
  }
}

export default injectIntl(GuideDetailsComponent);
