import React from 'react';
import {IntercomAPI} from 'react-intercom';
import PropTypes from 'prop-types';
import {StyledLink} from './styles';

const MaxEditorsNotificationContent = ({showIntercom, formattedMessages}) => {
  const {contactSupport, publishGuideFailureMaxPublishedGuides, chatMessage} = formattedMessages;

  return (
    <div>
      <div>{publishGuideFailureMaxPublishedGuides}</div>
      <div>
        {showIntercom ? (
          <StyledLink
            onClick={() => {
              IntercomAPI('showNewMessage', chatMessage);
            }}
          >
            {contactSupport}
          </StyledLink>
        ) : (
          contactSupport
        )}
      </div>
    </div>
  );
};

MaxEditorsNotificationContent.propTypes = {
  showIntercom: PropTypes.bool,
  formattedMessages: PropTypes.shape({
    contactSupport: PropTypes.string,
    publishGuideFailureMaxPublishedGuides: PropTypes.string,
    chatMessage: PropTypes.string
  })
};

export {MaxEditorsNotificationContent};

export default MaxEditorsNotificationContent;
