import styled from 'styled-components';
import flex from 'styles/flex';

export const MergeTag = styled.div`
  height: 100%;
  padding: 24px 30px 40px;
`;

export const Top = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  ${flex.vertical}
  ${flex.verticalStart}
  max-width: 690px;
  margin-top: 20px;
`;

export const RadioButton = styled.input``;

export const RadioLabel = styled.label``;

export const RadioOption = styled.div`
  ${RadioButton}
  ${RadioLabel}
`;
