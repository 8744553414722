import {defineMessages} from 'react-intl';

const messages = defineMessages({
  guideLanguage: {
    id: 'guideLanguage',
    defaultMessage: 'Guide Language'
  },
  manageTranslations: {
    id: 'manageTranslations',
    defaultMessage: 'Manage translations'
  },
  defaultLocale: {
    id: 'EditTranslation.defaultLocaleDescription',
    defaultMessage: 'This is your default locale. You can change it in Edit Guide.'
  },
  completion: {
    id: 'EditTranslation.completion',
    defaultMessage: 'Completion'
  },
  importAll: {
    id: 'importAll',
    defaultMessage: 'Import all'
  },
  importSuccess: {
    id: 'EditTranslations.importSuccess',
    defaultMessage: 'Translations have been imported.'
  },
  importFailure: {
    id: 'EditTranslations.importFailure',
    defaultMessage: 'Failed to upload new translations.'
  },
  importDeprecated: {
    id: 'EditTranslations.importDeprecated',
    defaultMessage:
      'Import failed. You are using a deprecated translations file. Please use translations editor or export them again.'
  },
  exportAll: {
    id: 'exportAll',
    defaultMessage: 'Export all'
  },
  addNewLanguage: {
    id: 'EditTranslation.addNewLanguage',
    defaultMessage: 'Add new language'
  }
});

export default messages;
