import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const fields = [
  {
    name: 'email',
    rules: 'required|email|string|between:5,254'
  },
  {
    name: 'password',
    rules: 'required|string'
  },
  {
    name: 'keepSignedIn',
    rules: 'boolean',
    value: true
  }
];

export default () => new MobxReactForm({fields}, {plugins});
