import styled from 'styled-components';
import InfiniteScroll from 'shared/components/InfiniteScroll';
import {showOnHover} from 'shared/utils/sc-utils';
import {commonPalette} from 'shared/styles/palette';

export const pickerOverlayClassName = 'c-pick-cover-image__overlay';

export const CoverImage = styled.div`
  transition: all 150ms linear;
  width: 100%;
  background-image: url('${p => p.src}');
  position: relative;
  overflow: hidden;
`;

export const ChooseFromExisting = styled.div`
  margin-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ChooseFromExistingGrid = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, minmax(150px, 1fr));

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }

  @media (max-width: 720px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
`;

export const Toolbar = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 1rem;
  justify-content: space-between;
`;

export const LargeLabel = styled.div`
  font-weight: bold;
  width: 40%;
`;

export const SmallLabel = styled.div`
  font-size: 14px;
  margin: 1rem;
  opacity: 0.5;
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  margin-top: 30px;
`;

export const LoaderContainer = styled.div`
  position: relative;
`;

export const MediaNameContainer = styled.div`
  padding: 4px;
  border: 1px solid #ececec;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const MediaName = styled.span`
  min-width: 0px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: ${commonPalette.txtGrey};
  text-overflow: ellipsis;
  text-align: start;
`;

export const MediaContainer = styled.button`
  cursor: pointer;
  border: none;

  ${showOnHover(pickerOverlayClassName)};
`;
