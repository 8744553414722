import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'SessionExpired.title',
    defaultMessage: 'Session expired'
  },
  info: {
    id: 'SessionExpired.info',
    defaultMessage: 'Your session have expired and you need to login again.'
  },
  login: {
    id: 'SessionExpired.login',
    defaultMessage: 'Get back to login'
  }
});

export default messages;
