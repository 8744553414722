import React, {useEffect, useMemo, useState} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {toJS} from 'mobx';
import views from 'config/views';

//lodash
import get from 'lodash/get';
import find from 'lodash/find';

//helpers
import {TRANSLATION_ITEM_TYPE} from 'config/enums';
import {bindField, slugify, syncTitleWithSlug} from 'shared/utils/input-utils';
import {tableMetrics} from 'styles/metrics';
import typefaces from 'shared/styles/typefaces';
import TextWidthMeasurer from 'shared/utils/text-width-measurer';
import {findScrollParent, scrollTo} from 'shared/utils/dom-utils';
import notification from 'utils/notification-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Spinner from 'shared/components/Spinner';

//queries
import {GetTranslationsForGuidePaginated} from 'api/translations/queries';

//styles
import {
  CellInner,
  CoverImage,
  Header,
  HeaderButton,
  HeaderLead,
  SourceLanguage,
  StyledArrow,
  StyledDataTable,
  StyledEditor,
  StyledField,
  StyledGearIcon,
  StyledManageLink,
  StyledMediaPicker,
  StyledNoMediaIcon,
  StyledSlug,
  StyledTranslationPicker,
  TranslateLocale,
  TranslationCard,
  TranslationCardContent,
  TranslationCardSection,
  TranslationCardTitle,
  TypeCell,
  LoadingState,
  StyledSpinner
} from './styles';

//constants
import {maxNumberOfRowsPerPage} from '../../ui-components/Layout/DataTable/constants';

//messages
import messages from './messages';
import {useLazyQuery} from '@apollo/client';
import {findIndex} from 'lodash';

export const TranslateLocaleComponent = props => {
  const {
    store,
    intl: {formatMessage},
    saveTranslationsForGuideMutation
  } = props;
  const {translationsPage, router} = store;
  const {currentRoute, params} = router;
  const guideId = router.params.id;

  const {
    form,
    sourceLocaleLabel,
    translationLocaleLabel,
    originalSourceObject,
    formattedTranslatedObject,
    currentLocale,
    mediaUrl,
    mediaMetadata,
    setMediaCoverUrl,
    setMetadata,
    availableTranslations,
    showMedia,
    showSlugField,
    selectTranslationItem,
    setInitialPreviousLocale,
    defaultLocale,
    translations,
    isFormattableItemType,
    saveBeforeSwitchingLocale
  } = translationsPage;

  const [getTranslations, {loading, error, data}] = useLazyQuery(GetTranslationsForGuidePaginated, {
    variables: {
      filters: {guideId: {eq: guideId}, locale: {in: [defaultLocale, currentLocale]}}
    },
    fetchPolicy: 'network-only'
  });

  const tableData = useMemo(() => translationsPage.translationsTable(translations), [translations]);

  const sourceMediaUrl = formattedTranslatedObject?.originalMedia?.url;
  const sourceMediaMetadata = formattedTranslatedObject?.originalMedia?.metadata;
  const allowVideos = formattedTranslatedObject && formattedTranslatedObject.type === TRANSLATION_ITEM_TYPE.STEP;

  const yHeaderCellMeasurer = new TextWidthMeasurer(typefaces.table.yHeaderCell);
  let typingTimer;
  const [firstRenderedPage, setFirstRenderedPage] = useState(0);

  // These are needed to shrink the first column to just fit its content:
  const yHeaderCellStyle = {
    boxSizing: 'content-box',
    paddingLeft: tableMetrics.baseNestingIndentation
  };
  const yHeaderCellWidth = Math.max(
    ...tableData.map(datum => {
      const indentation = datum.level * tableMetrics.nestingIndentation;
      const textWidth = yHeaderCellMeasurer.measure(datum.type);
      return indentation + textWidth;
    })
  );

  const checkSourceAndTargetLocale = () => {
    if (defaultLocale === currentLocale) {
      router.goTo(views.translateGuide, router.params);
    }
  };

  const getRowClassName = item => {
    if (originalSourceObject && originalSourceObject.id === item.id && originalSourceObject.type === item.type) {
      if (item.type === TRANSLATION_ITEM_TYPE.NOTE && originalSourceObject.stepId !== item.stepId) {
        return '';
      }
      return '-active';
    }
    return '';
  };

  const getHeaderId = () => `TranslateLocale-header`;

  const getRowId = item => `TranslateLocale-${item.type}-${item.id}${item.noteLabel && `-${item.stepId}`}`;

  const importTranslations = () => {
    const {uploadTranslationsDialog: dialog} = store;

    dialog.open(
      () => {
        notification.success(formatMessage(messages.importSuccess));
      },
      guideId,
      () => {
        notification.error(formatMessage(messages.importFailure));
      }
    );
  };

  const exportTranslations = () => {
    const origin = window.location.origin.replace('3033', '3000');
    const exportURL = `${origin}/translation/${guideId}`;
    window.open(exportURL);
  };

  const formatFirstCellLabel = tableEntry => {
    const {original, value} = tableEntry;
    const {noteLabel} = original;

    if (value === TRANSLATION_ITEM_TYPE.NOTE) {
      return noteLabel;
    }

    return value;
  };

  const scrollToItem = item => {
    const headerId = getHeaderId();
    const rowId = getRowId(item);

    const headerElement = document.getElementById(headerId) as HTMLElement;
    const rowElement = document.getElementById(rowId);
    const scrollParent = findScrollParent(rowElement);

    if (!rowElement) {
      return;
    }

    scrollTo(scrollParent, {
      left: 0,
      top: rowElement.offsetTop - headerElement.offsetHeight - rowElement.offsetHeight / 2
    });
  };

  const formIsValidating = () => !!translationsPage.form.$('slug').errorAsync;

  const resolveInitialTranslationItem = (scrollIntoView = true) => {
    let id, noteStepId;

    if (currentRoute.path === views.translateLocale.path) {
      id = params.id;
    } else if (currentRoute.path === views.translateTopicLocale.path) {
      id = params.topicId;
    } else if (currentRoute.path === views.translateInstructionLocale.path) {
      id = params.instructionId;
    } else if (currentRoute.path === views.translateStepLocale.path) {
      id = params.stepId;
    } else if (currentRoute.path === views.translateHintLocale.path) {
      id = params.noteId;
      noteStepId = params.stepId;
    } else {
      selectTranslationItem(null);
      return;
    }
    const itemIndex = findIndex(tableData, {id, ...(noteStepId && {stepId: noteStepId})});
    const item = tableData[itemIndex];

    if (!item) {
      return;
    }

    const page = Math.floor(itemIndex / maxNumberOfRowsPerPage);

    setFirstRenderedPage(page);
    selectTranslationItem(item);

    window.requestAnimationFrame(() => {
      if (scrollIntoView) {
        scrollToItem(item);
      }
    });

    setInitialPreviousLocale(get(router, 'params.locale', null));
  };

  const titleFieldOptions = {
    onChangeReaction: e => {
      e.persist();
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => syncTitleWithSlug({target: e.target}, props.store.translationsPage.form), 500);
    }
  };

  useEffect(() => {
    checkSourceAndTargetLocale();

    return () => {
      yHeaderCellMeasurer.destroy();
    };
  }, []);

  useEffect(() => {
    resolveInitialTranslationItem();
  }, [currentRoute, tableData]);

  useEffect(() => {
    // don't reset translations when in localeEditor view
    const setTranslations = !loading || !router.currentRoute.localeEditor;

    if (setTranslations) {
      translationsPage.setTranslations(data?.translationsForGuidePaginated?.results);
    }
  }, [data]);

  useEffect(() => {
    if (!defaultLocale || !currentLocale) return;

    getTranslations({
      variables: {
        filters: {guideId: {eq: guideId}, locale: {in: [defaultLocale, currentLocale]}}
      }
    });
  }, [defaultLocale, currentLocale]);

  if (loading) {
    return <StyledSpinner />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <TranslateLocale>
      <Header>
        <HeaderLead>
          <FormattedMessage {...messages.translating} />
        </HeaderLead>
        <SourceLanguage>{sourceLocaleLabel}</SourceLanguage>
        <StyledArrow />
        <StyledTranslationPicker
          availableTranslations={toJS(availableTranslations.filter(locale => locale !== defaultLocale))}
          changeLanguage={async locale => {
            await saveBeforeSwitchingLocale(saveTranslationsForGuideMutation);

            const {id, topicId, instructionId, stepId, noteId} = router.params;
            router.goTo(router.currentRoute, {
              id,
              topicId,
              instructionId,
              stepId,
              noteId,
              locale
            });
          }}
          selectedLanguage={currentLocale}
        />
        <HeaderButton onClick={importTranslations}>
          <FormattedMessage {...messages.import} />
        </HeaderButton>
        <HeaderButton onClick={exportTranslations}>
          <FormattedMessage {...messages.export} />
        </HeaderButton>
        <StyledManageLink params={router.params} store={store} route={views.translateGuide}>
          <FormattedMessage {...messages.manageTranslations} />
          <StyledGearIcon />
        </StyledManageLink>
      </Header>
      <StyledDataTable
        sortable={false}
        data={tableData}
        currentPage={firstRenderedPage}
        columns={[
          {
            Header: '',
            accessor: 'type',
            Cell: p => {
              const label = formatFirstCellLabel(p);

              return (
                <TypeCell style={{paddingLeft: p.original.level * tableMetrics.nestingIndentation}}>{label}</TypeCell>
              );
            },
            headerStyle: yHeaderCellStyle,
            style: yHeaderCellStyle,
            width: yHeaderCellWidth
          },
          {
            Header: formatMessage(messages.sourceText),
            Cell: p =>
              isFormattableItemType(p.original.type) ? (
                p.original.text ? (
                  <CellInner
                    dangerouslySetInnerHTML={{__html: p.original.text}}
                    isHighlighted={!p.original.translation}
                  />
                ) : (
                  <CellInner />
                )
              ) : (
                <CellInner isHighlighted={!p.original.translation}>{p.original.text}</CellInner>
              )
          },
          {
            Header: formatMessage(messages.translation),
            Cell: p =>
              isFormattableItemType(p.original.type) ? (
                p.original.translation ? (
                  <CellInner dangerouslySetInnerHTML={{__html: p.original.translation}} />
                ) : (
                  <CellInner />
                )
              ) : (
                <CellInner>{p.original.translation}</CellInner>
              )
          }
        ]}
        primaryAction={translation => {
          const {id, topicId, instructionId, stepId, noteId} = store.router.params;
          switch (translation.type) {
            case TRANSLATION_ITEM_TYPE.GUIDE:
              router.goTo(views.translateLocale, {id, locale: currentLocale});
              break;
            case TRANSLATION_ITEM_TYPE.TOPIC:
              router.goTo(views.translateTopicLocale, {id, topicId: translation.id, locale: currentLocale});
              if (topicId && !instructionId) selectTranslationItem(translation);
              break;
            case TRANSLATION_ITEM_TYPE.INSTRUCTION:
            case TRANSLATION_ITEM_TYPE.CHECKLIST:
              router.goTo(views.translateInstructionLocale, {
                id,
                topicId: translation.topicId,
                instructionId: translation.id,
                locale: currentLocale
              });
              if (instructionId && !stepId) selectTranslationItem(translation);
              break;
            case TRANSLATION_ITEM_TYPE.STEP:
            case TRANSLATION_ITEM_TYPE.CHECK:
              router.goTo(views.translateStepLocale, {
                id,
                topicId: find(tableData, {
                  id: translation.instructionId
                })?.topicId,
                instructionId: translation.instructionId,
                stepId: translation.id,
                locale: currentLocale
              });
              if (stepId && !noteId) selectTranslationItem(translation);
              break;

            case TRANSLATION_ITEM_TYPE.NOTE:
              router.goTo(views.translateHintLocale, {
                id,
                topicId: find(tableData, {
                  id: translation.instructionId
                })?.topicId,
                instructionId: translation.instructionId,
                stepId: translation.stepId,
                noteId: translation.id,
                locale: currentLocale
              });
              if (noteId) selectTranslationItem(translation);
              break;
          }
        }}
        getTheadProps={() => ({
          id: getHeaderId()
        })}
        getTrProps={(state, rowInfo) => ({
          className: getRowClassName(rowInfo.original),
          id: getRowId(rowInfo.original)
        })}
      />
      <TranslationCard>
        <TranslationCardTitle>{sourceLocaleLabel}</TranslationCardTitle>
        <TranslationCardSection>
          {formattedTranslatedObject &&
            (isFormattableItemType(formattedTranslatedObject.type) ? (
              <TranslationCardContent dangerouslySetInnerHTML={{__html: formattedTranslatedObject.text}} />
            ) : (
              <TranslationCardContent>{formattedTranslatedObject.text}</TranslationCardContent>
            ))}
          {showMedia && (
            <CoverImage src={sourceMediaUrl} metadata={sourceMediaMetadata} emptyIcon={<StyledNoMediaIcon />} />
          )}
        </TranslationCardSection>
        <TranslationCardTitle>{translationLocaleLabel}</TranslationCardTitle>
        <TranslationCardSection data-cy="translate-card">
          {formIsValidating() && (
            <LoadingState data-cy="loading-state">
              <Spinner />
            </LoadingState>
          )}
          {formattedTranslatedObject &&
            (isFormattableItemType(formattedTranslatedObject.type) ? (
              <StyledEditor
                key={`editor-${currentLocale}-${formattedTranslatedObject.type}-${formattedTranslatedObject.id}${
                  formattedTranslatedObject.type === TRANSLATION_ITEM_TYPE.NOTE &&
                  `-${formattedTranslatedObject.stepId}`
                }`} // force render because value doesn't always update
                shouldCollapseMargins={true}
                shouldHideToolbarOnBlur={true}
                {...bindField(form, 'translation')}
              />
            ) : (
              <StyledField
                form={form}
                field="translation"
                fieldOptions={titleFieldOptions}
                placeholder={formatMessage(messages.noTranslation)}
              />
            ))}
          {showSlugField && (
            <StyledSlug form={form} field="slug" placeholder={slugify(formatMessage(messages.noTranslation))} />
          )}
          {showMedia && (
            <StyledMediaPicker
              form={form}
              field="mediaId"
              setCoverUrl={setMediaCoverUrl}
              setMetadata={setMetadata}
              backgroundUrl={mediaUrl}
              backgroundMetadata={mediaMetadata}
              allowVideos={allowVideos}
            />
          )}
        </TranslationCardSection>
      </TranslationCard>
    </TranslateLocale>
  );
};

export default injectIntl(inject('store')(observer(TranslateLocaleComponent)));
