export const guideRemovalIntervals = [
  {
    name: '7 days',
    id: '7_DAYS'
  },
  {
    name: '15 days',
    id: '15_DAYS'
  },
  {
    name: '30 days',
    id: '30_DAYS'
  },
  {
    name: '45 days',
    id: '45_DAYS'
  },
  {
    name: '60 days',
    id: '60_DAYS'
  },
  {
    name: '90 days',
    id: '90_DAYS'
  },
  {
    name: '180 days',
    id: '180_DAYS'
  }
];
