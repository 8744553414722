import {defineMessages} from 'react-intl';

const messages = defineMessages({
  wholeGuideTranslated: {
    id: 'wholeGuideTranslatedV2',
    defaultMessage: 'Your Guide has been auto-translated!'
  },
  updateLanguage: {
    id: 'updateLanguage',
    defaultMessage: 'Review updated guide'
  },
  manageTranslations: {
    id: 'manageTranslations',
    defaultMessage: 'Manage translations'
  },
  inaccurateTranslations: {
    id: 'inaccurateTranslationsV2',
    defaultMessage: 'Note: Automatic translations may not be accurate. Check the Guide before publishing.'
  }
});

export default messages;
