export const checklistsLiteOptions = {
  options: () => {
    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };
  },
  name: 'checklistsLiteQuery'
};

export const checklistAnswersOptions = {
  options: ({checkListAnswersQueryVariables}) => {
    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: checkListAnswersQueryVariables
    };
  },
  name: 'checklistAnswersQuery'
};

export const oldestChecklistAnswerOptions = {
  options: () => {
    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };
  },
  name: 'oldestChecklistAnswerQuery'
};

export const checklistAnswerOptions = {
  options: ({store}) => {
    const {id} = store.router.params || '';

    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    };
  }
};
