import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {checkUniqueRecipientName} from 'utils/validation-utils';
import {debounce} from 'lodash';
import {dvrWithAsync} from 'config/validator-config';

const asyncRules = {
  checkUniqueRecipientName: debounce((value, attr, key, passes) => {
    return checkUniqueRecipientName(value, passes);
  }, 500)
};

export const fields = [
  {
    name: 'name',
    rules: 'required|string|checkUniqueRecipientName'
  },
  {
    name: 'webhookUrl',
    rules: 'string'
  },
  {
    name: 'webhookTeamsUrl',
    rules: 'string'
  }
];

const plugins = dvrWithAsync(asyncRules);

export default new MobxReactForm({fields}, {plugins});
