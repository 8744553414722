import styled from 'styled-components';

//components
import $Combobox from 'ui-components/Combobox';
import $Icon from 'react-fontawesome';

export const StyledCombobox = styled($Combobox).attrs({
  buttonProps: {
    style: {
      textAlign: 'left'
    }
  }
})``;

export const StyledIcon = styled($Icon)`
  margin-right: 5px;
`;
