export const iconsListOptions = {
  name: 'iconsListQuery',
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        onlyEnabled: false
      }
    };
  }
};

export const iconsListEnabledOptions = {
  name: 'iconsListEnabledQuery',
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        onlyEnabled: true
      }
    };
  }
};

export const availableLanguagesOptions = {
  name: 'availableLanguagesQuery',
  options: () => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        onlyEnabled: true
      }
    };
  }
};
