import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createSkill: {
    id: 'createSkill',
    defaultMessage: 'Create Skill'
  },
  generalInformation: {
    id: 'generalInformation',
    defaultMessage: 'General Information'
  },
  learningActivities: {
    id: 'learningActivities',
    defaultMessage: 'Learning Activities'
  },
  learningMaterials: {
    id: 'learningMaterials',
    defaultMessage: 'Add learning materials that help employees achieve the desired skill level.'
  },
  title: {
    id: 'title',
    defaultMessage: 'Title'
  },
  skillName: {
    id: 'skillName',
    defaultMessage: 'Skill Name'
  },
  addLearningActivities: {
    id: 'addLearningActivities',
    defaultMessage: 'Add Learning Activities'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  create: {
    id: 'create',
    defaultMessage: 'Create'
  },
  createdSkill: {
    id: 'createdSkill',
    defaultMessage: 'Skill has been created.'
  },
  failedCreateSkill: {
    id: 'failedCreateSkill',
    defaultMessage: 'Failed to create skill'
  },
  skillAlreadyExists: {
    id: 'skillAlreadyExists',
    defaultMessage: 'A skill with this name already exists'
  },
  uniqueSkillName: {
    id: 'uniqueSkillName',
    defaultMessage: 'Provide a unique skill name'
  },
  noLearningActivities: {
    id: 'noLearningActivities',
    defaultMessage: 'No learning activities here... yet!'
  },
  createFirstLearningActivity: {
    id: 'createFirstLearningActivity',
    defaultMessage: 'Get started by creating your first learning activity'
  },
  createLearningActivity: {
    id: 'createLearningActivity',
    defaultMessage: 'Create Learning Activity'
  },
  removeAll: {
    id: 'removeAll',
    defaultMessage: 'Remove All'
  },
  learningActivityDetails: {
    id: 'learningActivityDetails',
    defaultMessage: 'Learning Activity Details'
  },
  returnToActivityList: {
    id: 'returnToActivityList',
    defaultMessage: 'Return to Activity List'
  },
  exitPreview: {
    id: 'exitPreview',
    defaultMessage: 'Exit preview'
  },
  link: {
    id: 'link',
    defaultMessage: 'Link'
  },
  description: {
    id: 'description',
    defaultMessage: 'Description'
  },
  editSkill: {
    id: 'editSkill',
    defaultMessage: 'Edit Skill'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  editedSkill: {
    id: 'editedSkill',
    defaultMessage: 'Skill has been updated.'
  },
  failedEditSkill: {
    id: 'failedEditSkill',
    defaultMessage: 'Failed to edit skill'
  },
  saveChanges: {
    id: 'common.actions.saveChanges',
    defaultMessage: 'Save Changes'
  },
  usedSkill: {
    id: 'usedSkill',
    defaultMessage: 'This skill is used by Skill Profiles. The changes will be applied everywhere after saving.'
  },
  reskillingFrequency: {
    id: 'reskillingFrequency',
    defaultMessage: 'Assessment Frequency'
  },
  noReskilling: {
    id: 'noReskilling',
    defaultMessage: 'None'
  },
  sixMonths: {
    id: 'sixMonths',
    defaultMessage: '6 Months'
  },
  oneYear: {
    id: 'oneYear',
    defaultMessage: '1 Year'
  },
  twoYears: {
    id: 'twoYears',
    defaultMessage: '2 Years'
  },
  labels: {
    id: 'labels',
    defaultMessage: 'Labels'
  },
  tags: {
    id: 'GuideListFilters.tags',
    defaultMessage: 'Tags'
  },
  max10Tags: {
    id: 'max10Tags',
    defaultMessage: 'You can add max. 10 tags per skill.'
  }
});

export default messages;
