//helpers
import {transparentize} from 'shared/utils/color-utils';

export const boxShadow = {
  //sizes
  large: `0 2px 10px ${transparentize('#000', 0.16)}`,
  medium: `0 2px 4px ${transparentize('#000', 0.1)}`,

  //special cases
  draggable: `0 2px 10px ${transparentize('#000', 0.2)}`,
  table: `0 2px 4px ${transparentize('#000', 0.06)}`,
  floatingToolbar: `
    0 0 1px rgba(0, 0, 0, 0.12),
    0 1px 1px rgba(0, 0, 0, 0.24);
  `,

  none: 'none'
};

export const boxShadowTransition = '0.2s box-shadow ease-out';

export const getInteractiveShadowStyle = (from, to) => `
  box-shadow: ${boxShadow[from]};
  transition: ${boxShadowTransition};

  &:hover {
    box-shadow: ${boxShadow[to]};
  }
`;

export const interactiveShadowFromNoneStyle = getInteractiveShadowStyle('none', 'medium');
export const interactiveShadowFromMediumStyle = getInteractiveShadowStyle('medium', 'large');
