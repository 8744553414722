import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Menu.signoffResults',
    defaultMessage: 'Sign-off results'
  },
  subtitle: {
    id: 'SignOffResults.subtitle',
    defaultMessage: 'View, filter and export results for sign-offs.'
  },
  submittedName: {
    id: 'SignOffResults.submittedName',
    defaultMessage: 'Submitted name'
  },
  dateAndTime: {
    id: 'dateAndTime',
    defaultMessage: 'Date and time'
  },
  instruction: {
    id: 'pdfInstruction',
    defaultMessage: 'Instruction'
  },
  signOff: {
    id: 'campaign_sign_off',
    defaultMessage: 'Sign-off'
  },
  answer: {
    id: 'EditCampaign.answer',
    defaultMessage: 'Answer'
  },
  noResultsSubmitted: {
    id: 'SignOffResults.noResultsSubmitted',
    defaultMessage: 'No sign-off results have been submitted.'
  },
  today: {
    id: 'SignoffDateFilters.today',
    defaultMessage: 'Today'
  },
  yesterday: {
    id: 'SignoffDateFilters.yesterday',
    defaultMessage: 'Yesterday'
  },
  last7days: {
    id: 'SignoffDateFilters.last7days',
    defaultMessage: 'Last 7 days'
  },
  last30days: {
    id: 'SignoffDateFilters.last30days',
    defaultMessage: 'Last 30 days'
  },
  lastMonth: {
    id: 'SignoffDateFilters.lastMonth',
    defaultMessage: 'Last month'
  },
  lastYear: {
    id: 'SignoffDateFilters.lastYear',
    defaultMessage: 'Last year'
  },
  weekToDate: {
    id: 'SignoffDateFilters.weekToDate',
    defaultMessage: 'Week to date'
  },
  monthToDate: {
    id: 'SignoffDateFilters.monthToDate',
    defaultMessage: 'Month to date'
  },
  yearToDate: {
    id: 'SignoffDateFilters.yearToDate',
    defaultMessage: 'Year to date'
  },
  allTime: {
    id: 'SignoffDateFilters.allTime',
    defaultMessage: 'All time'
  },
  custom: {
    id: 'SignoffDateFilters.custom',
    defaultMessage: 'Custom'
  },
  campaign_action_yes: {
    id: 'campaign_action_yes',
    defaultMessage: 'Yes'
  },
  campaign_action_no: {
    id: 'campaign_action_no',
    defaultMessage: 'No'
  },
  campaign_action_pass: {
    id: 'campaign_action_pass',
    defaultMessage: 'Pass'
  },
  campaign_action_fail: {
    id: 'campaign_action_fail',
    defaultMessage: 'Fail'
  },
  date: {
    id: 'date',
    defaultMessage: 'Date'
  },
  signoffs: {
    id: 'Menu.signoffs',
    defaultMessage: 'Sign-offs'
  },
  filterBySignoff: {
    id: 'filterBySignoff',
    defaultMessage: 'Filter by sign-off'
  },
  selectedSignoffs: {
    id: 'selectedSignoffs',
    defaultMessage: 'Selected sign-offs'
  },
  selectAll: {
    id: 'selectAll',
    defaultMessage: 'Select all'
  },
  deselectAll: {
    id: 'deselectAll',
    defaultMessage: 'Deselect all'
  },
  guide: {
    id: 'guide',
    defaultMessage: 'Guide'
  },
  filterByGuide: {
    id: 'filterByGuide',
    defaultMessage: 'Filter by Guide'
  },
  selectedGuide: {
    id: 'selectedGuides',
    defaultMessage: 'Selected Guides'
  },
  workspace: {
    id: 'Share.workspace',
    defaultMessage: 'Workspace'
  },
  filterByWorkspace: {
    id: 'filterByWorkspace',
    defaultMessage: 'Filter by Workspace'
  },
  selectedWorkspace: {
    id: 'selectedWorkspace',
    defaultMessage: 'Selected Workspaces'
  },
  filterByTag: {
    id: 'filterByTag',
    defaultMessage: 'Filter by Tag'
  },
  selectedTag: {
    id: 'selectedTag',
    defaultMessage: 'Selected Tags'
  },
  tag: {
    id: 'tag',
    defaultMessage: 'Tag'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  }
});

export default messages;
