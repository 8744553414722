//refresh queries
import {refreshUserSkillMatrix, refreshTeamSkillMatrix} from 'api/skill-matrix/refresh-queries';

//constants
import C from './constants';

export const assessUserSkillOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.ASSESS_USER_SKILL]: variables => {
      const {teamId, userId} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [
          // todo refresh skill matrix when it's available
          refreshTeamSkillMatrix(teamId),
          refreshUserSkillMatrix(teamId, userId)
        ]
      });
    }
  })
};

export const setSkillAssessmentLevelOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.SET_SKILL_ASSESSMENT_LEVEL]: variables => {
      const {teamId, userId} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [
          // todo refresh skill matrix when it's available
          refreshTeamSkillMatrix(teamId),
          refreshUserSkillMatrix(teamId, userId)
        ]
      });
    }
  })
};
