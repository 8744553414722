import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    defaultMessage: 'Confirm sign-off deactivation',
    id: 'signOff.confirmDeactivation.title'
  },
  subtitle: {
    defaultMessage: 'This action cannot be undone.',
    id: 'signOff.confirmDeactivation.subtitle'
  },
  deactivate: {
    defaultMessage: 'Deactivate',
    id: 'common.actions.deactivate'
  }
});

export default messages;
