import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectPlaceholder: {
    id: 'TranslationPicker.selectPlaceholder',
    defaultMessage: 'Select language'
  }
});

export default messages;
