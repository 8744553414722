import get from 'lodash/get';
import draftToHtml from 'draftjs-to-html';

const getMediaId = (stepMediaId, {media, mediaId}) => (media || {}).id || mediaId || stepMediaId;

export const getClonedStep = step => {
  const tipMediaId = get(step, 'tipMedia.id');
  const warningMediaId = get(step, 'warningMedia.id');
  const alternativeMediaId = get(step, 'alternativeMedia.id');
  const fixMediaId = get(step, 'fixMedia.id');
  const mediaId = get(step, 'media.id');
  const notes = get(step, 'notes', []).map(note => ({
    iconId: note.iconId,
    content: note.content,
    mandatory: note.mandatory,
    mediaId: getMediaId(mediaId, note),
    noteContentHtml: draftToHtml(JSON.parse(note.content))
  }));

  const {instructionId, tip, warning, alternative, fix, instructionHtml, locale} = step;

  return {
    instructionId,
    tip,
    tipMediaId,
    warning,
    warningMediaId,
    alternative,
    alternativeMediaId,
    fix,
    fixMediaId,
    contentHtml: instructionHtml,
    mediaId,
    locale,
    notes
  };
};
