import {defineMessages} from 'react-intl';

const messages = defineMessages({
  preview: {
    id: 'applySignOff.preview',
    defaultMessage: 'Preview'
  },
  guideTitle: {
    id: 'applySignOff.guideTitle',
    defaultMessage: 'Apply sign-off to all instructions'
  },
  instructionTitle: {
    id: 'applySignOff.instructionTitle',
    defaultMessage: 'Apply sign-off'
  },
  removeCampaignInstruction: {
    id: 'applySignOff.removeSignOffInstruction',
    defaultMessage: 'Remove sign-off'
  },
  removeCampaignGuide: {
    id: 'applySignOff.removeSignOffGuide',
    defaultMessage: 'Remove sign-off from all instructions'
  },
  apply: {
    id: 'common.actions.apply',
    defaultMessage: 'Apply'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  close: {
    id: 'common.actions.close',
    defaultMessage: 'Close'
  },
  subtitle: {
    id: 'applySignOff.subtitle',
    defaultMessage: 'Select sign-off to apply'
  },
  noActivatedCampaigns: {
    id: 'applySignOff.noActivatedSignOffs',
    defaultMessage: 'There are no active sign-offs.'
  },
  campaignsApplySuccess: {
    id: 'applySignOff.signOffsApplySuccess',
    defaultMessage: 'Sign-off applied to all instructions'
  },
  campaignsApplyError: {
    id: 'applySignOff.signOffsApplyError',
    defaultMessage: "Couldn't apply sign-off"
  },
  campaignApplySuccess: {
    id: 'applySignOff.signOffApplySuccess',
    defaultMessage: 'Sign-off applied'
  },
  campaignApplyTo0Instructions: {
    id: 'applySignOff.signOffApplyTo0Instructions',
    defaultMessage: 'All instructions have already a sign-off applied'
  },
  fetchingCampaignError: {
    id: 'applySignOff.fetchingSignOffError',
    defaultMessage: "Couldn't load sign-offs"
  }
});

export default messages;
