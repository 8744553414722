import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';
import {ChromePicker} from 'react-color';

//styled-components
import {
  CropImageWrapper,
  Color,
  ButtonWrapper,
  ColorPickerWrapper,
  ColorPicker,
  ColorLabel,
  Popover,
  Cover,
  Button,
  TipWrapper,
  ImageFitWrapper,
  WarningWrapper,
  Link
} from './styles';
import ImageFocusCrop from './ImageFocusCrop';
import Checkbox from 'components/Checkbox';
import {FormattedMessage} from 'components/FormattedComponents';
import {MEDIA_PREVIEW_TYPE} from 'config/enums';

import strategies from './eventStrategies';
import {EVENT_TYPES} from 'api/tracking/constants';

//messages
import messages from './messages';

@inject('store')
@observer
class MediaEditComponent extends Component {
  executeEventStrategy = (eventType, ...args) => {
    const strategy = strategies[eventType];
    if (!strategy) {
      throw new Error(`No strategy found for event type "${eventType}"`);
    }

    return strategy(this.props.store, ...args);
  };

  render() {
    const {mediaPickerDialog: dialog} = this.props.store;
    const {
      setBackgroundColor,
      backgroundColor,
      colorPickerOpen,
      showColorPicker,
      contain,
      selectedMedia,
      extendSelectedMediaMetadata,
      setOriginalSize
    } = dialog;

    if (!selectedMedia) {
      return null;
    }

    const regex = /\.svg/;
    const isSvg = regex.test(selectedMedia.url);
    const imageSrc = isSvg ? selectedMedia.url.replace(regex, '.jpeg') : selectedMedia.url;
    const noOverlay = !selectedMedia.metadata || !selectedMedia.metadata.overlays;
    const showLinkToStepPreview =
      !noOverlay &&
      dialog.previewType !== MEDIA_PREVIEW_TYPE.STEP_OVERVIEW &&
      dialog.previewType !== MEDIA_PREVIEW_TYPE.STEP;

    return (
      <div>
        <CropImageWrapper>
          <ImageFocusCrop
            height={'100%'}
            width={'100%'}
            isSvg={isSvg}
            imageSrc={imageSrc}
            metadata={selectedMedia.metadata}
            onChangeHandler={metadata => {
              extendSelectedMediaMetadata(metadata);
            }}
            setOriginalSize={setOriginalSize}
          />
        </CropImageWrapper>

        {noOverlay ? (
          <TipWrapper>
            <FormattedMessage {...messages.cropTip} />
          </TipWrapper>
        ) : (
          <WarningWrapper>
            <FormattedMessage {...messages.overlayWarning} />
            {showLinkToStepPreview && (
              <Link onClick={() => dialog.setPreviewType(MEDIA_PREVIEW_TYPE.STEP_OVERVIEW)}>
                <FormattedMessage {...messages.previewOverlay} />
              </Link>
            )}
          </WarningWrapper>
        )}

        <ButtonWrapper>
          {!isSvg && (
            <Button onClick={this.executeEventStrategy(EVENT_TYPES.ROTATE_IMAGE)}>
              <FormattedMessage {...messages.rotate} />
            </Button>
          )}
          <ImageFitWrapper>
            <Checkbox
              checked={contain}
              onChange={this.executeEventStrategy(EVENT_TYPES.SCALE_SELECTION)}
              caption={<FormattedMessage {...messages.scaleSelection} />}
            />
          </ImageFitWrapper>

          {contain && (
            <ColorPickerWrapper>
              <h4>
                <FormattedMessage {...messages.backgroundColor} />
              </h4>
              <ColorPicker>
                <Color color={backgroundColor} onClick={() => showColorPicker()} />
                <ColorLabel>{backgroundColor}</ColorLabel>
              </ColorPicker>
              {colorPickerOpen && (
                <Popover>
                  <Cover onClick={() => showColorPicker()} />
                  <ChromePicker
                    style={{width: 200}}
                    color={backgroundColor}
                    onChange={() => {
                      setBackgroundColor();
                      this.executeEventStrategy(EVENT_TYPES.IMAGE_BACKGROUND_COLOR);
                    }}
                  />
                </Popover>
              )}
            </ColorPickerWrapper>
          )}
        </ButtonWrapper>
      </div>
    );
  }
}

export default injectIntl(MediaEditComponent);
