import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {DragSource, DropTarget} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import {inject} from 'mobx-react';

//components
import InstructionItem from '../InstructionItem';

//mutations
import {RepositionTopicsAndInstructions} from 'api/topic/mutations';

//options
import {repositionTopicsAndInstructions} from 'api/topic/mutation-options';

//drag-and-drop
import {DND_TYPE, source, target} from './dnd';
import {DND_TYPE as STEP_DND_TYPE} from 'components/StepList/StepOverview/DraggableStepCard/dnd';

@inject('store')
@graphql(RepositionTopicsAndInstructions, repositionTopicsAndInstructions)
@DragSource(DND_TYPE, source, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
@DropTarget([DND_TYPE, STEP_DND_TYPE], target, (connect, monitor) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  itemType: monitor.getItemType()
}))
export default class DraggableInstructionItemComponent extends Component {
  componentDidMount() {
    const {connectDragPreview} = this.props;

    connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
  }

  render() {
    const {connectDragSource, connectDropTarget} = this.props;

    return (
      <InstructionItem
        {...this.props}
        design={this.design}
        dragHandleRef={connectDragSource}
        hostRef={connectDropTarget}
      />
    );
  }

  get design() {
    const {canDrop, isDragging, isOver, itemType} = this.props;

    if (isDragging) {
      return 'ghostly';
    }

    if (canDrop && isOver && itemType === STEP_DND_TYPE) {
      return 'highlight';
    }

    return 'default';
  }
}
