import styled from 'styled-components';

export const CenteredContainer = styled.div`
  height: 100%;
  padding: 30px 50px 0 ${({noPaddingLeft}) => (noPaddingLeft ? '0' : '')};
  display: flex;
  flex-direction: column;
  max-width: 1020px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media (max-width: 720px) {
    padding: 30px 20px 0 ${({noPaddingLeft}) => (noPaddingLeft ? '0' : '')};
  }
`;
