import styled from 'styled-components';

//helpers
import {topicListMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';
import {interactiveShadowFromNoneStyle} from 'styles/shadows';

//components
import $Icon from 'ui-components/Layout/Icon';

//constants
const height = 100;
const cardBase = `
  ${interactiveShadowFromNoneStyle};
  background: ${commonPalette.darkestGray};
  border: 0;
  color: #383838;
  cursor: pointer;
  display: block;
  font-size: 18px;
  height: ${height}px;
  line-height: ${height}px;
  margin-top: ${topicListMetrics.instructionGutter}px;
  outline: 0;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  flex: 1;
`;

export const NewInstructionCard = styled.button.attrs({
  type: 'button'
})`
  ${cardBase};
  margin-right: 5px;
`;

export const NewChecklistCard = styled.button.attrs({
  type: 'button'
})`
  ${cardBase};
  margin-left: 5px;
`;

export const PlusIcon = styled($Icon).attrs({
  name: 'plus',
  size: 18 // matches the font size
})`
  margin-left: ${p => -p.size / 2}px; // hanging punctuation
  margin-right: 0.26em; // single space width
  vertical-align: -0.15em;
`;

export const CardsWrapper = styled.div`
  display: flex;
`;
