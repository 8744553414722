import React from 'react';
import {Editor as $Editor} from 'react-draft-wysiwyg';
import styled from 'styled-components';

//helpers
import patchEditor from 'decorators/patchEditor';
import {blockMargin, getFormattedTextStyle} from 'shared/styles/formatted-text';
import {darken} from 'shared/utils/color-utils';
import flex from 'styles/flex';
import {commonPalette} from 'shared/styles/palette';
import {boxShadow} from 'styles/shadows';

//components
import $BlurListener from 'components/BlurListener';
import $ButtonGroup from 'ui-components/ButtonGroup';

//react-draft-wysiwyg styles with draftjs ones removed
//todo: inject these locally
import './react-draft-wysiwyg.css';

// The native onBlur listener causes issues when paired
// with the toolbarOnFocus flag, so lets have a replacement:
export const EditorWrapper = styled($BlurListener)`
  ${flex.vertical};
`;

const PatchedEditor = patchEditor($Editor);

export const StyledEditor = styled(({className, ...forwardedProps}) => (
  <PatchedEditor wrapperClassName={className} {...forwardedProps} />
))`
  display: flex;
  flex-direction: column;

  .rdw-editor-wrapper {
    ${flex.vertical};
    cursor: text;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .rdw-editor-toolbar {
    background: none;
    border-radius: 4px;
    border: 0;
    box-shadow: ${boxShadow.floatingToolbar};
    font-size: 14px;
    margin: 0;
    padding: 0;
    position: relative;
    white-space: nowrap;
    z-index: 0;
    align-self: flex-start;
  }

  .rdw-editor-toolbar-inner {
    border-radius: 4px;
    overflow-x: auto;
  }

  .rdw-options-wrapper {
    display: inline-block; // IE11 won't handle flex items here
    margin: 0;
    position: static;
    vertical-align: middle;

    &:first-child > .rdw-option-wrapper:first-child {
      border-bottom-left-radius: 4px;
      border-left: 0;
      border-top-left-radius: 4px;
      margin: 0;
    }

    &:last-child > .rdw-option-wrapper:last-child {
      border-bottom-right-radius: 4px;
      border-right: 0;
      border-top-right-radius: 4px;
    }
  }

  .rdw-option-wrapper {
    background: ${commonPalette.darkestGray};
    border-color: transparent #efefef;
    border-width: 0 1px;
    border-radius: 0;
    display: inline-flex;
    height: 36px;
    margin: 0 0 0 -1px;
    min-width: 36px;
    padding: 0;
    position: relative;
    vertical-align: middle;

    &:hover,
    &:active {
      box-shadow: none;
    }

    &:hover {
      background: ${darken(commonPalette.darkestGray, 0.05)};
      border-color: transparent;
      z-index: 1;
    }
  }

  .rdw-option-active {
    background: ${darken(commonPalette.darkestGray, 0.05)};
    border: 0;
    box-shadow: inset 0 1px 3px -1px rgba(0, 0, 0, 0.5);
    z-index: 2;

    &:hover {
      background: ${darken(commonPalette.darkestGray, 0.1)};
    }
  }

  .rdw-option-disabled {
    opacity: 1;
    pointer-events: none;

    > img {
      opacity: 0.2;
    }
  }

  .rdw-colorpicker-modal,
  .rdw-link-modal {
    border: 0;
    border-radius: 4px;
    box-shadow: ${boxShadow.floatingToolbar};
    height: auto;
    left: 4px;
    top: 32px;
    width: 235px;
  }

  .rdw-colorpicker-modal {
    padding: 0;
  }

  .rdw-link-modal-target-option > span {
    // A hacky solution to the link model unexpectedly closing:
    // https://github.com/jpuri/react-draft-wysiwyg/pull/842
    pointer-events: none;
  }

  .rdw-editor-main {
    ${getFormattedTextStyle(true)};
    cursor: text;

    ${p =>
      p.shouldCollapseMargins ||
      `
      .public-DraftStyleDefault-block {
        margin-bottom: ${blockMargin}em;
      }
    `}
  }

  .public-DraftEditorPlaceholder-root {
    color: ${commonPalette.txboxPlaceholder};
    position: absolute;
    z-index: 0;
  }

  .DraftEditorPlaceholder-hidden {
    display: none;
  }
`;

export const ButtonGroup = styled($ButtonGroup)`
  > button {
    height: 36px;
  }

  ${p =>
    p.editorStyle &&
    `
    vertical-align: middle;
    display: inline-flex;

    > button {
      border-radius: 0;
      margin: 0 !important;
    }
  `}
`;
