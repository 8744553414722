import styled from 'styled-components';

//components
import $CardToolbar from 'components/CardToolbar';
import $Link from 'shared/components/Link';
import $CloudinaryPicture from 'shared/components/CloudinaryPicture';
import Icon from 'ui-components/Icon';

//helpers
import {commonPalette} from 'shared/styles/palette';
import {transparentize} from 'shared/utils/color-utils';
import {guideCardMetrics} from 'styles/metrics';
import {interactiveShadowFromMediumStyle} from 'styles/shadows';
import {smallBorderRadius} from 'styles/sizes';
import typefaces from 'shared/styles/typefaces';

const guideCardBase = `
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  flex: 1;
  z-index: 0;
  border-radius: ${guideCardMetrics.smallBorderRadius}px;
`;

export const GuideCardStatic = styled.div`
  background-color: #fff;
  ${guideCardBase}
  display: flex;
  height: 100%;
`;

export const GuideCardLink = styled(GuideCardStatic.withComponent($Link))`
  ${interactiveShadowFromMediumStyle};
  cursor: pointer;
  display: flex;
  height: 100%;
`;

export const BackgroundImage = styled($CloudinaryPicture).attrs({
  aspectRatio: 4 / 3
})`
  z-index: -1;
`;

export const InnerWrapper = styled.div`
  background: linear-gradient(180deg, rgba(89, 83, 83, 0.4) 0%, rgba(236, 236, 241, 0) 57%);
`;

export const Title = styled.div`
  ${typefaces.guideCard.smallTitle};
  margin: 0 0 10px;
  word-wrap: break-word;
  color: ${commonPalette.txBlack};
`;

export const Subtitle = styled.div`
  ${typefaces.guideCard.subtitle};
  color: ${commonPalette.txLightGrey};
`;

export const Paragraph = styled.div`
  margin-top: 5px;
  display: flex;
  flex: 1;
`;

export const GuideCardLabel = styled.div`
  background: ${p => (p.isUnderApproval ? '#EF850B' : transparentize('#fff', 0.7))};
  color: ${p => (p.isUnderApproval ? '#FFFFFF' : '#333330')};
  border-radius: ${smallBorderRadius}px;
  font-size: 12px;
  left: 12px;
  line-height: 24px;
  padding: 0 8px;
  position: absolute;
  top: 12px;
`;

export const StyledCardToolbar = styled($CardToolbar)`
  color: #fff;
  position: absolute;
  right: 8px;
  top: 12px;
`;

export const StyledContent = styled.div`
  padding: 12px 16px;
  border-top: 1px solid ${transparentize(commonPalette.txLightGrey, 0.2)};
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const StyledIcon = styled(Icon)`
  margin: 0 ${({marginRight}) => (marginRight ? marginRight : 7)}px 0 0;
  color: ${commonPalette.txLightGrey};
  font-size: 11px;
`;

export const StyledText = styled.div`
  word-wrap: break-word;
  flex-wrap: wrap;
  flex: 1;
`;
