import {defineMessages} from 'react-intl';

const messages = defineMessages({
  automaticTranslationInProgress: {
    id: 'automaticTranslationInProgress',
    defaultMessage: 'Automatic translation in progress'
  },
  untranslatedContentAutoTranslated: {
    id: 'untranslatedContentAutoTranslated',
    defaultMessage: 'Your untranslated content is being automatically translated.'
  },
  translating: {
    id: 'translating',
    defaultMessage: 'Translating...'
  }
});

export default messages;
