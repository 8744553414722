import gql from 'graphql-tag';

export const AlertRecipientsQuery = gql`
  query alertRecipients {
    alertRecipients {
      results {
        name
        id
        configuration {
          webhooks {
            url
          }
          webhooksMsTeams {
            url
          }
        }
      }
    }
  }
`;

export const AlertRecipientQuery = gql`
  query alertRecipient($id: String!) {
    alertRecipient(id: $id) {
      id
      name
      configuration {
        webhooks {
          url
        }
        webhooksMsTeams {
          url
        }
      }
    }
  }
`;

export const AlertRecipientsForSelectQuery = gql`
  query alertRecipientsForSelectQuery($filters: AlertRecipientFilters) {
    alertRecipients(filters: $filters) {
      results {
        name
        id
      }
    }
  }
`;

export const ChecklistAlertRecipientsForSelectQuery = gql`
  query checklistAlertRecipientsForSelect($checklistId: String!) {
    checklistAlertRecipients(filters: {checklistId: {eq: $checklistId}}) {
      results {
        name
        id
      }
    }
  }
`;
