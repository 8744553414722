import styled from 'styled-components';
import flex from 'styles/flex';
import {Title as $Title} from 'shared/styles/styled-components/field-styled-components';

export const CampaignTab = styled.div`
  ${flex.vertical};
  height: 100%;
  position: relative;
  flex: 1;
  padding: 0 16px;
`;

export const Top = styled.div`
  flex: 1 1 auto;
`;

export const Content = styled.div`
  ${flex.vertical} margin-top: 20px;
`;

export const Title = styled($Title).attrs({
  bold: true
})``;
