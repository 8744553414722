import React, {Component} from 'react';
import {DrawerSubtitle, Card, CardTitle, TextSecondary, TaskList, Task, TaskCheckmark} from './styles';
import Icon from 'ui-components/Layout/Icon';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {SkillForUser} from 'api/skill-matrix/queries';
import {skillForUserOptions} from 'api/skill-matrix/query-options';
import {injectIntl} from 'react-intl';
import messages from './messages';

@inject('store')
@graphql(SkillForUser, skillForUserOptions)
@observer
class SkillHistory extends Component {
  isCompleted(task) {
    return task.status === 'COMPLETED';
  }

  tasksCompletion(skill) {
    if (!skill || !skill.skillTasks || skill.skillTasks.length === 0) return '0';

    const completedTasks = skill.skillTasks.reduce((acc, task) => {
      if (this.isCompleted(task)) acc++;
      return acc;
    }, 0);

    const totalTasks = skill.skillTasks.length;

    return `${completedTasks}/${totalTasks}`;
  }

  render() {
    const {
      intl: {formatMessage},
      skillForUserQuery
    } = this.props;
    const {skillForUser: skill, loading} = skillForUserQuery;

    return (
      <>
        <DrawerSubtitle>{formatMessage(messages.historyOfLearning)}</DrawerSubtitle>
        <Card>
          <CardTitle>
            {formatMessage(messages.completed)}
            <TextSecondary>{loading ? '...' : ' ' + this.tasksCompletion(skill)}</TextSecondary>
          </CardTitle>
          <TaskList>
            {skill?.skillTasks?.map(task => (
              <Task key={task.id}>
                <TaskCheckmark checked={this.isCompleted(task)}>
                  <Icon name={'checkmark'} size={20} />
                </TaskCheckmark>
                {task.title}
              </Task>
            ))}
          </TaskList>
        </Card>
      </>
    );
  }
}

export default injectIntl(SkillHistory);
