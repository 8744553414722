import React, {Component} from 'react';

//styles
import {FeedbacksPaginatorWrapper, FeedbacksPaginator, Button, Page, Icon} from './styles';

class FeedbacksPaginatorComponent extends Component {
  render() {
    const {prev, next, setCurrentPage, currentPage, totalPages} = this.props;
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
    const prevButtonEnabled = currentPage > 1;
    const nextButtonEnabled = currentPage !== totalPages;
    return (
      <FeedbacksPaginatorWrapper>
        <FeedbacksPaginator>
          <Button onClick={() => prev()} disabled={!prevButtonEnabled}>
            <Icon name={'angle-left'} />
          </Button>
          {pages.map(page => (
            <Page key={page} onClick={() => setCurrentPage(page)} disabled={page === currentPage}>
              {page}
            </Page>
          ))}
          <Button onClick={() => next()} disabled={!nextButtonEnabled}>
            <Icon name={'angle-right'} />
          </Button>
        </FeedbacksPaginator>
      </FeedbacksPaginatorWrapper>
    );
  }
}

export default FeedbacksPaginatorComponent;
