import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

import {FormattedMessage} from 'components/FormattedComponents';
import {
  StyledDialog,
  UploadIconDropzone,
  UploadIconActiveStyle,
  UploadSvgIconPreview,
  Text,
  Caption
} from './styles.js';

//helpers
import {readFileAsText} from 'shared/utils/file-utils';
import {stepNoteResourceAllowed, svgIconFileLimit} from 'shared/utils/media-utils';
import notification from 'utils/notification-utils';
import {stepNoteTypes} from 'shared/utils/instruction-utils';
import get from 'lodash/get';

//messages
import messages from './messages';

@inject('store')
@observer
class EditThemeIconDialog extends Component {
  errorUploadingFile = ({type, data}) => {
    const {formatMessage} = this.props.intl;
    const message = formatMessage(messages[type], data);
    notification.error(message);
  };

  onDrop = async ([file]) => {
    const {
      store: {editThemeIconDialog: dialog}
    } = this.props;
    const {acceptedTypes, setIcon} = dialog;

    if (file) {
      const {error} = stepNoteResourceAllowed(file, acceptedTypes);

      if (error) {
        return this.errorUploadingFile(error);
      }

      try {
        const svgContent = await readFileAsText(file).promise;
        setIcon(svgContent);
      } catch (e) {
        return this.errorUploadingFile({
          type: 'corruptContent'
        });
      }
    }
  };

  onDropRejected = ([file]) => {
    const {
      store: {editThemeIconDialog: dialog}
    } = this.props;
    const {acceptedTypes} = dialog;

    if (file) {
      const {error} = stepNoteResourceAllowed(file, acceptedTypes);
      if (error) {
        this.errorUploadingFile(error);
      }
    }
  };

  //we need to add quotes for custom types
  getIconDisplayName = (label, isCustom) => (isCustom ? `"${label}"` : label);

  render() {
    const {
      styles = {},
      className,
      store,
      intl: {formatMessage}
    } = this.props;
    const {
      editThemeIconDialog,
      themePage: {colorsForm}
    } = store;
    const {opened, reset, loading, acceptedTypes, icon, saveIcon} = editThemeIconDialog;

    const modalSubtitle = formatMessage(messages.uploadIcon);
    const isWarning = icon.type === stepNoteTypes.WARNING;
    const isCustom = icon.type === stepNoteTypes.CUSTOM;
    const modalTitle = formatMessage(messages.changeIcon, {label: this.getIconDisplayName(icon.shownLabel, isCustom)});
    const themeColor = get(colorsForm.values(), 'buttonColor');

    return (
      <StyledDialog
        actions={[
          {
            label: 'Upload',
            design: 'text',
            dataCy: 'save-icon',
            onClick: saveIcon
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={modalTitle}
      >
        <Text size={'small'} secondary>
          {modalSubtitle}
        </Text>
        <UploadIconDropzone
          {...loading}
          disableClick={loading}
          styles={styles.UploadIconDropzone}
          onDrop={this.onDrop}
          onDropRejected={this.onDropRejected}
          accept={acceptedTypes}
          multiple={false}
          activeStyle={UploadIconActiveStyle}
          loadingImage={loading}
          className={className}
          maxSize={svgIconFileLimit}
        >
          <UploadSvgIconPreview
            dangerouslySetInnerHTML={{__html: icon.svg}}
            isWarning={isWarning}
            themeColor={themeColor}
          />
        </UploadIconDropzone>
        <Caption>
          <FormattedMessage {...messages.dropboxCaptionOnlySvg} />
          <br />
          <FormattedMessage {...messages.dropboxCaptionThemeColors} />
        </Caption>
      </StyledDialog>
    );
  }
}

export default injectIntl(EditThemeIconDialog);
