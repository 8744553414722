import {defineMessages} from 'react-intl';

const messages = defineMessages({
  topic: {
    id: 'EditTopic.topic',
    defaultMessage: 'Topic'
  }
});

export default messages;
