import React from 'react';
import {inject, observer} from 'mobx-react';
import {
  FormattedMessage as $FormattedMessage,
  FormattedNumber as $FormattedNumber,
  FormattedDate as $FormattedDate
} from 'react-intl';

const FormattedBase = inject('store')(
  observer(props => {
    const {FormattedComponent, store, ...newProps} = props;

    if (store.platform.currentLanguage) {
      return <FormattedComponent {...newProps} />;
    }

    return <span>{props.defaultMessage}</span>;
  })
);

export const FormattedMessage = props => <FormattedBase FormattedComponent={$FormattedMessage} {...props} />;

export const FormattedNumber = props => <FormattedBase FormattedComponent={$FormattedNumber} {...props} />;

export const FormattedDate = props => <FormattedBase FormattedComponent={$FormattedDate} {...props} />;
