import {InstructionsList, GetInstructionForEdit} from 'api/instruction/queries';

export const refreshInstruction = id => {
  return {
    query: GetInstructionForEdit,
    variables: {
      id
    }
  };
};

export const refreshInstructions = (topicId, locale) => {
  return {
    query: InstructionsList,
    variables: {
      topicId,
      locale
    }
  };
};
