import $QRCode from 'qrcode.react';
import $ClipboardButton from 'react-clipboard.js';
import styled from 'styled-components';
import Button from 'ui-components/Button';

//helpers
import {styleable} from 'shared/decorators';
import {commonPalette} from 'shared/styles/palette';

export const ClipboardButton = styled($ClipboardButton)`
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
`;

export const SectionTitle = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${commonPalette.txBlack};
`;

export const QrRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextBox = styled.div`
  margin-bottom: 10px;
`;

export const StyledQRCode = styled(styleable($QRCode)).attrs({
  renderAs: 'canvas',
  size: 200
})`
  display: block;
`;

export const StyledButton = styled(Button)`
  @media (max-width: 500px) {
    margin-left: 10px;
    font-size: 10px;
  }
`;
