import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

import {CreateLabelWrapper, LabelWrapper, PositionContainer, NameContainer} from './styles';
import messages from './messages';

import Select from 'ui-components/Select';

@inject('store')
@observer
class RequiredLevelLabel extends Component {
  changeRequiredLevel = requiredLevel => {
    const {
      store: {
        skillProfilePage: {setSelectedSkills, selectedSkills}
      },
      skillId
    } = this.props;

    const newSkills = selectedSkills.map(skill =>
      skill.id === skillId ? {...skill, requiredLevelId: requiredLevel} : skill
    );

    setSelectedSkills(newSkills);
  };

  // mode: 'view' | 'edit' | 'create'
  render() {
    const {
      intl: {formatMessage},
      requiredLevel,
      mode,
      levels,
      skillName,
      store: {
        skillProfilePage: {showSkillsValidationErrors}
      }
    } = this.props;

    if (mode === 'view') {
      return (
        <LabelWrapper>
          <PositionContainer>{requiredLevel?.position}</PositionContainer>
          <NameContainer>{requiredLevel?.name?.toLowerCase()}</NameContainer>
        </LabelWrapper>
      );
    }

    if (mode === 'edit') {
      return (
        <LabelWrapper>
          <PositionContainer>{requiredLevel?.position}</PositionContainer>

          <Select
            underlined
            allowClear={false}
            options={levels}
            selectedValue={requiredLevel?.id || ''}
            onChange={this.changeRequiredLevel}
          />
        </LabelWrapper>
      );
    }

    if (mode === 'create') {
      return (
        <CreateLabelWrapper invalid={showSkillsValidationErrors}>
          <Select
            underlined
            allowClear={false}
            label={formatMessage(messages.setRequiredLevel)}
            selectedValue={''}
            options={levels}
            onChange={this.changeRequiredLevel}
            dataCy={`${skillName}-select`}
          />
        </CreateLabelWrapper>
      );
    }

    return <></>;
  }
}

export default injectIntl(RequiredLevelLabel);
