import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {cardToolbarMetrics, tableMetrics} from 'styles/metrics';

//components
import $DataTable from 'ui-components/Layout/DataTable';
import $EnhancedIcon from 'ui-components/Layout/EnhancedIcon';
import $Icon from 'ui-components/Layout/Icon';
import Button from 'ui-components/Button';
import $Spinner from 'shared/components/Spinner';

export const Translations = styled.div`
  ${flex.vertical};
  flex-grow: 1;
`;

export const Header = styled.div`
  ${flex.horizontal};
  justify-content: space-between;
  flex-shrink: 0;
  @media (max-width: 550px) {
    ${flex.vertical};
    font-size: 12px;
  }
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
`;

export const StyledButton = styled(Button)`
  margin-left: 15px;
`;

export const StyledDataTable = styled($DataTable).attrs({
  className: '-cms-design-v2'
})`
  ${flex.vertical};
  flex-basis: ${tableMetrics.minHeight}px;
  flex-grow: 1;
  max-width: none;

  .rt-table,
  .rt-tbody,
  .rt-td {
    overflow: visible !important;
    border: none !important;
  }

  .noHighlightBackground {
    cursor: default !important;
    &:hover {
      background: #f2f2f2 !important;
    }
  }
`;

export const LanguageCell = styled.div`
  margin-right: ${7 - tableMetrics.cellPaddingRight}px;
  min-height: ${cardToolbarMetrics.size}px;
  padding-right: ${cardToolbarMetrics.size + cardToolbarMetrics.gutter}px;
  position: relative;
  font-size: 14px;
  line-height: 25px;
`;

export const StyledInformationEnhancedIcon = styled($EnhancedIcon).attrs({
  name: 'information'
})`
  margin-left: 4px;
  opacity: 0.27;
  vertical-align: -7px;
`;

export const StyledRemoveIcon = styled($Icon).attrs({
  name: 'trashbin-2'
})`
  color: rgba(0, 0, 0, 0.2);
  z-index: 0;
  opacity: 1;
  transition: opacity 0.1s ease-in-out;

  &:hover {
    opacity: 1;
  }

  &::after {
    background: #000;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    border-radius: 4px;
  }

  &:hover::after {
    opacity: 0.05;
  }
`;

export const CompletionProgressWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 10px;
`;

export const CompletionProgressBarContainer = styled.div`
  display: flex;
  flex: column;
  flex: 3;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 0;
  border: 1px solid #4fb6b2;
`;

export const CompletionProgressBar = styled.div`
  display: flex;
  flex: column;
  background-color: #4fb6b2;
  height: 100%;
  width: ${p => p.completion}%;
  border-radius: 5px;
`;

export const CompletionAmount = styled.div`
  display: flex;
  flex: column;
  flex: 1;
  text-align: right;
`;

export const DefaultLanguageCompletion = styled.div`
  display: flex;
  flex: column;
  flex: 1;
  line-height: 25px;
  font-size: 14px;
`;

export const StyledRemoveIconWrapper = styled.div`
  position: relative;
`;

export const StyledSpinner = styled($Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;
