import {defineMessages} from 'react-intl';

const messages = defineMessages({
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications'
  },
  sendEmailNotifications: {
    id: 'sendGuideRelEmailNotifications',
    defaultMessage: 'Select who should receive guide related email notifications'
  },
  everyoneOnTheTeam: {
    id: 'everyoneFromTheTeam',
    defaultMessage: 'Everyone on the team'
  },
  teamMembersWorking: {
    id: 'teamMembersWorking',
    defaultMessage: 'Team members working on the guide'
  },
  notificationsWillBeShared: {
    id: 'notificationsWillBeShared',
    defaultMessage: 'Notifications will be shared with:'
  },
  feedback: {
    id: 'feedback',
    defaultMessage: 'Feedback'
  },
  guideAssignee: {
    id: 'guideAssignee',
    defaultMessage: 'Guide assignee'
  },
  approvalWorkflow: {
    id: 'approvalWorkflow',
    defaultMessage: 'Approval workflow'
  },
  approvers: {
    id: 'approvers',
    defaultMessage: 'Approver(s)'
  },
  approvalRequester: {
    id: 'approvalRequester',
    defaultMessage: 'Approval requester'
  }
});

export default messages;
