import {action, observable, makeObservable} from 'mobx';

class UploadTranslationsDialog {
  @observable loading = false;
  @observable opened = false;
  @observable submittable = false;

  @action
  reset = () => {
    this.loading = false;
    this.opened = false;
    this.onImportSuccess = null;
    this.translationEndpoint = null;
    this.submittable = false;

    if (this.cancelSource) {
      this.cancelSource.cancel(this.cancelMessage);
    }
    this.cancelSource = null;
  };

  @action setLoading = loading => (this.loading = loading);
  @action setSubmittable = submittable => (this.submittable = submittable);

  @action setCancelSource = source => (this.cancelSource = source);
  @action getCancelToken = () => this.cancelSource && this.cancelSource.token;

  @action
  open = (onImportSuccess, translationEndpoint, onImportFailure) => {
    this.opened = true;
    this.onImportSuccess = onImportSuccess;
    this.onImportFailure = onImportFailure;
    this.translationEndpoint = translationEndpoint;
    this.cancelMessage = 'Operation canceled by the user.';
  };

  constructor() {
    makeObservable(this);
  }
}

export default UploadTranslationsDialog;
