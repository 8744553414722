import {defineMessages} from 'react-intl';

const messages = defineMessages({
  assignedToTeam: {
    id: 'assignedToTeam',
    defaultMessage: 'Assigned to entire Team'
  },
  assginedToRoles: {
    id: 'assignedToRoles',
    defaultMessage: 'Assigned to {roles}'
  }
});

export default messages;
