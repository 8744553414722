import {defineMessages} from 'react-intl';

const messages = defineMessages({
  teamName: {
    id: 'common.forms.teamName',
    defaultMessage: 'Team Name'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  }
});

export default messages;
