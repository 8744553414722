import React, {Component} from 'react';

import {Wrapper, Bar, RatingLabel, BarLabel, Rating, LabelsWrapper, BarsWrapper, Cell} from './styles';

class BarRatingsHorizontalComponent extends Component {
  render() {
    const {ratingsPerScore, colorRange, labelsX} = this.props;
    const highestRating = Math.max(...ratingsPerScore);

    const values = [];
    const labels = [];
    for (let i = 0; i < labelsX.length; i++) {
      labels.push(
        <Cell key={`label${i}`}>
          <BarLabel>{labelsX && labelsX[i] ? labelsX[i] : i + 1}</BarLabel>
        </Cell>
      );
      values.push(
        <Cell key={`bar${i}`}>
          <Rating>
            <Bar width={(ratingsPerScore[i] / highestRating) * 100 || 1} bgColor={colorRange[i]}>
              <RatingLabel>{ratingsPerScore[i]}</RatingLabel>
            </Bar>
          </Rating>
        </Cell>
      );
    }

    return (
      <Wrapper>
        <LabelsWrapper>{labels}</LabelsWrapper>
        <BarsWrapper>{values}</BarsWrapper>
      </Wrapper>
    );
  }
}

export default BarRatingsHorizontalComponent;
