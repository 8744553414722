import styled from 'styled-components';

export const WrapperDiv = styled.div`
  margin-bottom: 40px;
`;

export const TablesWrapper = styled.div`
  display: flex;
`;

export const TableWrapper = styled.div`
  flex: 1;
  display: flex;
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  font-weight: bold;
`;

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 300;
`;

export const Cell = styled.div`
  margin: 5px 0;
`;
