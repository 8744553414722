import styled from 'styled-components';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Button from 'ui-components/Button';
import $DataTable from 'ui-components/Layout/DataTable';
import {commonPalette} from 'shared/styles/palette';

export const StyledViewTitle = styled(ViewTitle)`
  padding-bottom: 10px !important;
`;

export const StyledSlug = styled.h4`
  padding-bottom: 30px;
  color: ${commonPalette.txGrey};
`;

export const DataTable = styled($DataTable)`
  .rt-tr-group:hover {
    ${() => RemoveButton} {
      opacity: 1;
    }
  }
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'close'
})`
  cursor: pointer;
`;
