import styled from 'styled-components';

import $Icon from 'ui-components/Icon';
import $DataTable from 'ui-components/Layout/DataTable';
import $Container from 'ui-components/Layout/Container';
import $EnhancedIcon from 'ui-components/Layout/EnhancedIcon';

import {commonPalette} from 'shared/styles/palette';
import {svgElementsFillCurrentColor} from 'styles/vars';

export const StyledContainer = styled($Container)`
  padding-top: 0;
`;

export const StyledDataTable = styled($DataTable)`
  &:not(.-cms-design-v2) .ReactTable {
    margin-bottom: 3rem;
    .rt-td {
      align-self: center;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

export const StyledCustomTag = styled.div`
  background-color: ${commonPalette.borderGrey};
  padding: 5px 10px;
  color: ${commonPalette.txBlack};
  font-size: 0.75rem;
  line-height: 1rem;
  float: left;
  clear: left;
  > * {
    margin-right: 10px;
  }
`;

export const CellInner = styled.div`
  text-align: center;
  padding: 0.5rem 0;

  & a {
    color: ${commonPalette.txBlack};
  }

  .-row-disabled & {
    color: ${commonPalette.txLightGrey};
  }

  .-row-disabled & a:not(.toggle-visibility-button) {
    color: ${commonPalette.txLightGrey};
    pointer-events: none;
    cursor: default;
  }
`;

export const SvgIconWrapper = styled.div`
  ${p => !p.isWarning && p.themeColor && `color: ${p.themeColor};`}
  ${p => p.isWarning && `color: ${commonPalette.orange};`}
  > svg {
    width: 2rem;
    height: auto;

    ${svgElementsFillCurrentColor}
  }
  .-row-disabled & {
    opacity: 0.6;
  }
`;

export const IconLabel = styled.div`
  font-size: 0.875rem;

  .-row-disabled & {
    color: ${commonPalette.txLightGrey};
  }
`;

export const StyledIcon = styled($Icon)``;

export const Link = styled.a``;

export const StyledEnhancedIcon = styled($EnhancedIcon)`
  > .warning-round {
    color: ${commonPalette.orange};
    width: 0.75rem;
    height: 0.75rem;
  }
`;
