import styled from 'styled-components';

//components
import $MediaPicker from 'components/MediaPicker';
import $CloudinaryPicture from 'shared/components/CloudinaryPicture';

export const InstructionDetails = styled.div`
  position: relative;
  z-index: 1;
  min-height: 160px;

  .media-picker-label {
    opacity: 0;
  }

  &:hover {
    ${() => StyledMediaPicker} {
      box-sizing: content-box;

      & > svg {
        border-radius: 2px 0px 0px 2px;
      }

      .media-picker-label {
        box-sizing: content-box;
        border: 0.5px solid white;
        background-color: white;
        opacity: 0.75;
        padding: 3px 5px;
        border-radius: 0px 2px 2px 0px;
      }
    }
  }
`;

export const BackgroundImage = styled($CloudinaryPicture)`
  position: absolute;
  z-index: -1;
  height: ${p => (p.cardHeight ? p.cardHeight / 2 + 88 : 160)}px;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative; // to stay on top of the overlay
  margin: 0 auto;
  padding: 0 50px;
`;

export const StyledMediaPicker = styled($MediaPicker).attrs({
  iconSize: 70
})`
  margin: 20px 0 40px 0;
  justify-content: flex-start;
  opacity: 1;

  & > svg {
    background-color: white;
    padding: 3px;
    border-radius: 2px;
    opacity: 0.75;
    box-sizing: content-box;
  }
`;

export const StyledMediaPickerWithLabel = styled.div`
  display: flex;
  align-items: flex-end;
  flex-shrink: 0;
`;
