import gql from 'graphql-tag';

export const SkillRole = gql`
  query skillRole($id: String!) {
    skillRole(id: $id) {
      id
      name
    }
  }
`;
