import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';

export const StyledChips = styled.div`
  border-radius: 4px;
  background-color: #fbfafa;
  border-color: #999999;
  border-width: 1px;
  border-style: solid;
  margin-right: 5px;
  text-overflow: elipsis;
  white-space: nowrap;
  align-self: center;
  padding: 2px 8px;
`;

export const CloseIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 3px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 23px;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
`;
