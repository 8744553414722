import {injectIntl, InjectedIntlProps} from 'react-intl';
import React, {ReactElement} from 'react';
import messages from './messages';
import {CardLabel, CardLevel, CardLevelNum, CardRow} from './styles';
import CurrentSkillLabel from './CurrenstSkillLabel';
import Position from 'components/SmartSkillsPosition';
import {SkillLevel} from 'views/SmartSkillsTeam/types';
interface CurrentLevelCardProps extends InjectedIntlProps {
  position?: number;
  skillLevels: SkillLevel[];
  label: string;
}

function CurrentLevelCard({
  position,
  skillLevels,
  label,
  intl: {formatMessage}
}: Readonly<CurrentLevelCardProps>): ReactElement {
  return (
    <CardRow>
      <CardLabel>{formatMessage(messages.currentLevel)}</CardLabel>
      <CardLevel>
        <CardLevelNum>
          <Position position={position} skillLevels={skillLevels} />
        </CardLevelNum>
        <CurrentSkillLabel label={label} skillLevels={skillLevels} />
      </CardLevel>
    </CardRow>
  );
}

export default injectIntl(CurrentLevelCard);
