import React, {useState} from 'react';

import {
  ChevronIcon,
  GrayText,
  ListGroup,
  ListGroupItem,
  ListGroupItemsContainer,
  ListGroupTitleItem
} from 'components/SkillProfileRolesDrawer/styles';
import messages from './messages';
import {StyledButtonHover} from './styles';
import sortBy from 'lodash/sortBy';

const numberOfSelectedRoles = (team, roles, formatMessage) => {
  if (!roles?.length) {
    return null;
  }

  return (
    <GrayText>
      {'('}
      {formatMessage(messages.selected, {num: roles.length})}
      {')'}
    </GrayText>
  );
};

const SelectedJobTitlesItem = ({availableSkillRoles, formatMessage, roles, team, onRemove}) => {
  const [opened, setOpened] = useState(false);

  const hasRoles = roles?.length > 0;
  // If team has no roles - render list of all roles, but don't show delete button
  const rolesList = hasRoles ? sortBy(roles, role => role.name.toLowerCase()) : availableSkillRoles;

  return (
    <ListGroup>
      <ListGroupTitleItem onClick={() => setOpened(!opened)}>
        <ChevronIcon open={opened} />
        <p>
          {team.name} {numberOfSelectedRoles(team, roles, formatMessage)}
        </p>
        <StyledButtonHover onClick={() => onRemove(team.id, null, 'team')} iconId="remove" />
      </ListGroupTitleItem>
      <ListGroupItemsContainer opened={opened} itemCount={rolesList.length}>
        {rolesList.map(role => (
          <ListGroupItem key={role.id}>
            <p>{role.name}</p>
            {hasRoles && <StyledButtonHover onClick={() => onRemove(team.id, role.id, 'role')} iconId="remove" />}
          </ListGroupItem>
        ))}
      </ListGroupItemsContainer>
    </ListGroup>
  );
};

export default SelectedJobTitlesItem;
