import axios from 'axios';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//lodash
import invoke from 'lodash/invoke';

//styles
import {Filename, HiddenFileInput} from './styles';

//components
import Dialog from 'ui-components/Dialog';
import Button from 'ui-components/Button';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@observer
class UploadTranslationsDialog extends Component {
  inputElement = null;

  handleInputChange = () => {
    const {store} = this.props;
    const {uploadTranslationsDialog: dialog} = store;

    const submittable = Boolean(this.selectedFile);
    dialog.setSubmittable(submittable);
  };

  handleSubmit = () => {
    const {store} = this.props;
    const {uploadTranslationsDialog: dialog} = store;

    if (!this.inputElement) {
      return;
    }

    dialog.setLoading(true);
    dialog.setCancelSource(axios.CancelToken.source());

    const formData = new FormData();
    formData.append('excel', this.selectedFile);

    const config = {
      withCredentials: true,
      headers: {
        'content-type': 'multipart/form-data'
      },
      cancelToken: dialog.getCancelToken()
    };

    const importURL = `${window.location.origin.replace('3033', '3000')}/translation/${dialog.translationEndpoint}`;

    axios.post(importURL, formData, config).then(this.handleUploadSuccess, this.handleUploadFailure);
  };

  handleUploadClick = () => {
    if (!this.inputElement) {
      return;
    }

    this.inputElement.click();
  };

  handleUploadFailure = error => {
    const {store} = this.props;
    const {uploadTranslationsDialog: dialog} = store;

    if (error.message === dialog.cancelMessage) {
      return;
    }

    if (error.response) {
      const errorData = error.response.data;
      error = errorData.message || '';
    } else {
      error = '';
    }

    dialog.setLoading(false);
    invoke(dialog, 'onImportFailure', error);
    dialog.reset();
  };

  handleUploadSuccess = response => {
    const {store} = this.props;
    const {uploadTranslationsDialog: dialog} = store;

    dialog.setLoading(false);
    invoke(dialog, 'onImportSuccess', response.data);
    dialog.reset();
  };

  setInputRef = ref => {
    this.inputElement = ref;
  };

  get selectedFile() {
    if (!this.inputElement || !this.inputElement.files[0]) {
      return null;
    }

    return this.inputElement.files[0];
  }

  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {uploadTranslationsDialog: dialog} = store;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.upload),
            disabled: !dialog.submittable,
            loading: dialog.loading,
            onClick: this.handleSubmit,
            type: 'submit'
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        title={formatMessage(messages.title)}
        bodyStyle={{display: 'flex', alignItems: 'center'}}
      >
        <Button label={formatMessage(messages.uploadCaption)} onClick={this.handleUploadClick} />
        <Filename>
          {this.selectedFile ? this.selectedFile.name : <FormattedMessage {...messages.notSelected} />}
        </Filename>
        <HiddenFileInput accept="*" ref={this.setInputRef} name="excel-file" onChange={this.handleInputChange} />
      </Dialog>
    );
  }
}

export default injectIntl(UploadTranslationsDialog);
