import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'NewTheme.title',
    defaultMessage: 'Create New Theme'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  next: {
    id: 'common.actions.next',
    defaultMessage: 'Next'
  },
  actionSuccess: {
    id: 'NewTheme.actionFailed',
    defaultMessage: 'Theme has been created!'
  },
  actionFailure: {
    id: 'NewTheme.actionFailure',
    defaultMessage: 'Failed to create the theme.'
  },
  default: {
    id: 'EditTheme.default',
    defaultMessage: '(default)'
  },
  nameAndLogo: {
    id: 'EditTheme.nameAndLogo',
    defaultMessage: 'Name & Logo'
  },
  colors: {
    id: 'Themes.colors',
    defaultMessage: 'Colors'
  },
  stepNoteIcons: {
    id: 'EditTheme.stepNoteIcons',
    defaultMessage: 'Step Note Icons'
  },
  fontSettings: {
    id: 'EditTheme.fontSettings',
    defaultMessage: 'Font'
  }
});

export default messages;
