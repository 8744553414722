import views from 'config/views';

//mobx
import store from 'stores/store';
import {action, observable, makeObservable} from 'mobx';
import get from 'lodash/get';

//helpers
import notification from 'utils/notification-utils';

//models
import ForgotPasswordForm from '../forms/forgot-password-form.js';

class ForgotPasswordPage {
  @observable form = ForgotPasswordForm();

  @observable loading = false;
  @observable errorResponse = null;

  translations = {};

  @action
  setLoading = val => (this.loading = val);

  @action
  setErrorResponse = val => (this.errorResponse = val);

  @action
  submitForgotPasswordForm = forgotPasswordMutation => {
    const {email} = this.form.values();

    this.setErrorResponse(null);
    this.setLoading(true);

    forgotPasswordMutation({email})
      .then(response => {
        if (!get(response, 'data.forgotPassword.result')) {
          return Promise.reject();
        }

        notification.success(this.translations.requestSuccess);
        store.router.goTo(views.login, {});
      })
      .catch(this.handleForgotPasswordError);
  };

  @action
  handleForgotPasswordError = (response = {}) => {
    this.setErrorResponse(response);
    this.setLoading(false);
  };

  @action
  reset = () => {
    this.form.clear();
    this.errorResponse = null;
    this.loading = false;
  };

  constructor() {
    makeObservable(this);
  }

  setTranslations(translations) {
    this.translations = translations;
  }
}

export default ForgotPasswordPage;
