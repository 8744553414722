import views from 'config/views';

//mobx
import store from 'stores/store';
import {action, observable, makeObservable} from 'mobx';

//helpers
import notification from 'utils/notification-utils';

//models
import ResetPasswordForm from '../forms/reset-password-form.js';

class ResetPasswordPage {
  @observable form = ResetPasswordForm();

  translations = {};

  @action
  submitResetPasswordForm = resetPasswordMutation => {
    const {newPassword} = this.form.values();
    const {token} = store.router.queryParams;
    resetPasswordMutation({token, password: newPassword})
      .then(() => {
        notification.success(this.translations.requestSuccess);
        store.router.goTo(views.login, {});
      })
      .catch(this.handleResetPasswordError);
  };

  @action
  resetPasswordLinkNotValid = () => {
    notification.error(this.translations.invalidLink);

    store.router.goTo(views.forgotPassword, {});
  };

  @action
  handleResetPasswordError = () => {
    notification.error(this.translations.requestFailure);
  };

  constructor() {
    makeObservable(this);
  }

  setTranslations(translations) {
    this.translations = translations;
  }
}

export default ResetPasswordPage;
