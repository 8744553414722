import {defineMessages} from 'react-intl';

const messages = defineMessages({
  guides: {
    id: 'EditCampaign.guides',
    defaultMessage: 'Guides'
  },
  userManagement: {
    id: 'Menu.userManagement',
    defaultMessage: 'User Management'
  },
  analytics: {
    id: 'Menu.analytics',
    defaultMessage: 'Analytics'
  },
  settings: {
    id: 'Menu.settings',
    defaultMessage: 'Settings'
  },
  guideOverview: {
    id: 'common.title.guideOverview',
    defaultMessage: 'Overview'
  },
  allGuides: {
    id: 'common.title.allGuides',
    defaultMessage: 'All Guides'
  },
  tags: {
    id: 'Menu.tags',
    defaultMessage: 'Tags'
  },
  checklistResults: {
    id: 'Menu.checklistResults',
    defaultMessage: 'Checklist results'
  },
  signoffs: {
    id: 'Menu.signoffs',
    defaultMessage: 'Sign-offs'
  },
  signoffResults: {
    id: 'Menu.signoffResults',
    defaultMessage: 'Sign-off results'
  },
  dashboard: {
    id: 'Menu.dashboard',
    defaultMessage: 'Dashboard'
  },
  traffic: {
    id: 'Menu.traffic',
    defaultMessage: 'Traffic'
  },
  usage: {
    id: 'Menu.usage',
    defaultMessage: 'Usage'
  },
  top10: {
    id: 'Menu.top10',
    defaultMessage: 'Top 10'
  },
  allInstructions: {
    id: 'Menu.allInstructions',
    defaultMessage: 'All Instructions'
  },
  teams: {
    id: 'Menu.teams',
    defaultMessage: 'Teams'
  },
  users: {
    id: 'Menu.users',
    defaultMessage: 'Users'
  },
  themes: {
    id: 'Menu.themes',
    defaultMessage: 'Themes'
  },
  workspaces: {
    id: 'Menu.workspaces',
    defaultMessage: 'Workspaces'
  },
  platform: {
    id: 'Menu.platform',
    defaultMessage: 'Platform'
  },
  adoption: {
    id: 'Menu.adoption',
    defaultMessage: 'Adoption'
  },
  helpCenter: {
    id: 'Menu.helpCenter',
    defaultMessage: 'Help Center'
  },
  version: {
    id: 'Pdf.Version',
    defaultMessage: 'Version'
  },
  thoughtspot: {
    id: 'Menu.thoughtspotAdoption',
    defaultMessage: 'Adoption'
  },
  platformAdoption: {
    id: 'platformAdpotion',
    defaultMessage: 'Platform Adoption'
  },
  workspaceAdoption: {
    id: 'workspaceAdoption',
    defaultMessage: 'Workspace Adoption'
  },
  platformLanguage: {
    id: 'platformLanguage',
    defaultMessage: 'Platform language'
  },
  smartSkills: {
    id: 'smartSkills',
    defaultMessage: 'Smart Skills'
  },
  skillsMatrix: {
    id: 'skillsMatrix',
    defaultMessage: 'Skills Matrix'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  skillProfiles: {
    id: 'skillProfiles',
    defaultMessage: 'Skill Profiles'
  },
  learningActivities: {
    id: 'learningActivities',
    defaultMessage: 'Learning Activities'
  },
  jobTitles: {
    id: 'jobTitles',
    defaultMessage: 'Job Titles'
  },
  skillsDevelopment: {
    id: 'skillsDevelopment',
    defaultMessage: 'Skills development for frontline teams to bridge knowledge gaps.'
  },
  introducing: {
    id: 'introducing',
    defaultMessage: 'Introducing: '
  },
  learningHub: {
    id: 'learningHub',
    defaultMessage: 'Learning Hub'
  },
  checklistAlerts: {
    id: 'checklistAlerts',
    defaultMessage: 'Checklist Alerts'
  }
});

export default messages;
