import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import ButtonGroup from 'ui-components/ButtonGroup';

//styled-components
import {Top, Content, StyledEnhancedIcon} from './styles';
import {Panel} from 'styles/styled-components/shared-components';

// queries
import {Tag} from 'api/tag/queries';

//mutations
import {UpdateTag} from 'api/tag/mutations';

//options
import {updateTagOptions} from 'api/tag/mutation-options';
import {tagOptions} from 'api/tag/query-options';

//components
import Field from 'ui-components/Field';
import Checkbox from 'components/Checkbox';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import LanguagePicker from 'components/LanguagePicker';
import FormContainer from 'ui-components/Layout/FormContainer';

//messages
import messages from './messages';

@inject('store')
@graphql(UpdateTag, updateTagOptions)
@graphql(Tag, tagOptions)
@observer
class EditTagView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {tagManagementPage}
    } = this.props;

    const translations = mapValues(pick(messages, ['updateSuccess', 'updateFailure', 'duplicateTitle']), message =>
      formatMessage(message)
    );

    tagManagementPage.setUiTranslations(translations);
  }

  componentDidUpdate() {
    const {tagManagementPage} = this.props.store;
    const {tag, loading} = this.props.data;
    if (!loading && tag && tagManagementPage.editedTagId !== tag.id) {
      tagManagementPage.startEdit(tag);
    }
  }

  componentWillUnmount() {
    const {tagManagementPage} = this.props.store;
    tagManagementPage.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      updateTagMutation,
      data: {loading, error, tag: editableTag}
    } = this.props;
    const {
      tagManagementPage,
      platform: {multipleLocales}
    } = store;
    const {form, setDefaultLocale, defaultLocale, goToTagsList, isBadge, toggleBadge} = tagManagementPage;
    const showLoading = loading || error;
    const tagNotFound = !showLoading && !editableTag;
    const showEditTagArea = !showLoading && editableTag;

    if (showLoading)
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );

    return (
      <FormContainer>
        <ViewTitle title={formatMessage(messages.title)} />
        {tagNotFound && (
          <p>
            <FormattedMessage {...messages.notFound} />
          </p>
        )}
        {showEditTagArea && (
          <Panel>
            <Top>
              <Content>
                <Field autofocus={true} size="small" title={formatMessage(messages.name)} field="title" form={form} />
                {multipleLocales && (
                  <Field
                    size="small"
                    inputComponent={
                      <LanguagePicker changeLanguage={setDefaultLocale} selectedLanguage={defaultLocale} />
                    }
                  />
                )}
                <Field
                  size="small"
                  inputComponent={
                    <Checkbox
                      caption={
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <FormattedMessage {...messages.badge} />
                          <StyledEnhancedIcon
                            name="info"
                            tooltipPlacement="right"
                            tooltip={<FormattedMessage {...messages.badgeDescription} />}
                          />
                        </div>
                      }
                      checked={isBadge}
                      onChange={toggleBadge}
                    />
                  }
                />
              </Content>
            </Top>

            <ButtonGroup
              line
              primary={{
                label: formatMessage(messages.save),
                onClick: () => tagManagementPage.updateTag({updateTagMutation, goToTagsList}),
                disabled: tagManagementPage.loading || !form.isValid,
                loading: tagManagementPage.loading
              }}
              secondary={{
                label: formatMessage(messages.cancel),
                onClick: goToTagsList
              }}
            />
          </Panel>
        )}
      </FormContainer>
    );
  }
}

export default injectIntl(EditTagView);
