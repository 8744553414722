/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import Row from './Row';
import {withBreakpoints} from './BreakpointProvider';
import {divvy, breakpoint, passOn} from '../utils';

function ColumnContainer(props) {
  const {children, tagName, debug, ...rest} = props;
  const newChildren = passOn(children, [Row], child => {
    return {
      debug: typeof child.props.debug === 'undefined' ? debug : child.props.debug
    };
  });
  const newProps = (!tagName && {className: rest.className}) || rest;
  return React.createElement(tagName || 'div', newProps, newChildren);
}

ColumnContainer.defaultProps = {
  divisions: 12
};

const compute = name =>
  breakpoint(name, (props, name) =>
    ((divisions, size, shift) => `
      ${size ? `width: ${divvy(divisions, size)}%;` : ''}
      ${shift ? `margin-left: ${divvy(divisions, shift)}%;` : ''}
    `)(props.divisions, props[name], props[`${name}Shift`])
  );

const Column = styled(ColumnContainer)`
  display: block;
  ${props =>
    props.debug
      ? `background-color: rgba(50, 50, 255, .1);
       outline: 1px solid #fff;`
      : ''} box-sizing: border-box;
  padding: ${props => (props.gutter ? `${props.gutter}px` : '20px')};
  width: 100%;
  ${compute('xs')} ${compute('sm')} ${compute('md')} ${compute('lg')};
`;

export default withBreakpoints(Column);
