import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import Icon from 'shared/components/Icon';
import {zIndex} from 'styles/vars';

export const Drawer = styled.div`
  background: ${commonPalette.white};
  max-width: 90vw;
  width: 550px;
  border: 1px solid rgb(204, 204, 204);
  position: fixed;
  height: 100vh;
  z-index: ${zIndex.versionsDrawer};
  overflow: hidden;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const DrawerSpacer = styled.div`
@media (min-width: 1020px) {
  min-width: 520px;
  box-sizing: border-box;
`;

export const DrawerHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px; 32px;
`;

export const DrawerTitle = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DrawerDescription = styled.p`
  color: var(--neutrals-grey, ${commonPalette.txGrey});
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 32px;
`;

export const CloseButton = styled(Icon).attrs({
  name: 'close'
})`
  height: 14px;
  color: ${commonPalette.txBlack};
`;

export const ListContainer = styled.div`
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 600px;
  margin-bottom: 18px;
  height: 100%;
  overflow-y: scroll;
`;
