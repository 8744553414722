import {defineMessages} from 'react-intl';

const messages = defineMessages({
  shapes: {
    id: 'OverlayToolbar.shapes',
    defaultMessage: 'Shapes'
  },
  color: {
    id: 'OverlayToolbar.color',
    defaultMessage: 'Color'
  },
  thickness: {
    id: 'OverlayToolbar.thickness',
    defaultMessage: 'Thickness'
  }
});

export default messages;
