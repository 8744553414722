import {observable, action, computed, makeObservable} from 'mobx';
import {find, get} from 'lodash';

//helpers
import store from 'stores/store';
import GetTranslationForm from 'stores/forms/translation-form';
import notification from 'utils/notification-utils';
import {normalizeLocale} from 'utils/languages-utils';
import {TRANSLATION_ITEM_TYPE} from 'config/enums';
import {stepNoteTypes} from 'shared/utils/instruction-utils';
import Raven from 'raven-js';
import {areEqual} from 'utils/data-utils';

import {convertFromHTML, ContentState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const stateFromHtml = html => {
  if (!html) return JSON.stringify(convertToRaw(ContentState.createFromText('')));
  const blocksFromHtml = convertFromHTML(html);
  const {contentBlocks, entityMap} = blocksFromHtml;
  const contentState = contentBlocks
    ? ContentState.createFromBlockArray(contentBlocks, entityMap)
    : ContentState.createFromText('');
  return JSON.stringify(convertToRaw(contentState));
};

class TranslationsPage {
  @observable uiTranslations = {};
  @observable translations = [];
  @observable currentTranslation = [];
  @observable selectedTranslationItem = null;
  @observable mediaUrl = null;
  @observable mediaMetadata = null;
  @observable guideId = null;
  @observable guideTitle = null;
  @observable guideImageUrl = null;
  @observable guideMetadata = null;
  @observable defaultLocale = null;
  @observable availableTranslations = [];
  @observable savingTranslations = false;
  form = GetTranslationForm();
  modified = {};
  previousLocale = null;

  setForm = () => {
    if (this.selectedTranslationItem) {
      this.form.reset(); // reset before updating to keep track of changes

      let translation;
      if (this.isFormattableItemType(this.formattedTranslatedObject.type)) {
        translation = stateFromHtml(this.formattedTranslatedObject.translation);
      } else {
        translation = this.formattedTranslatedObject.translation;
      }

      this.form.update({
        text: this.formattedTranslatedObject.text,
        translation,
        slug: this.formattedTranslatedObject.slug,
        mediaId: get(this.formattedTranslatedObject, 'media.id', '')
      });
      this.mediaUrl = get(this.formattedTranslatedObject, 'media.url');
      this.mediaMetadata = get(this.formattedTranslatedObject, 'media.metadata');
    } else {
      this.form.reset();
      this.mediaUrl = null;
      this.mediaMetadata = null;
    }
  };

  @action
  setAvailableTranslations = translations => {
    this.availableTranslations =
      translations.length && this.defaultLocale
        ? [this.defaultLocale, ...translations.filter(translation => translation !== this.defaultLocale)]
        : [];
  };

  @action
  setGuideData = guide => {
    const {title, media, defaultLocale, availableTranslations, id} = guide;
    this.guideTitle = title;
    this.guideImageUrl = get(media, 'url', '');
    this.guideMetadata = get(media, 'metadata', '');
    this.defaultLocale = defaultLocale;
    this.availableTranslations = [
      this.defaultLocale,
      ...availableTranslations.filter(translation => translation !== defaultLocale)
    ];
    this.guideId = id;
  };

  @action
  setUiTranslations = uiTranslations => {
    this.uiTranslations = uiTranslations;
  };

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  selectTranslationItem = item => {
    // if there is a selected item, add it to the modified translations
    if (this.selectedTranslationItem) {
      this.addModifiedTranslation();
    }
    this.selectedTranslationItem = item;
    this.setForm();
  };

  @action
  onLocaleChange = () => {
    if (this.selectedTranslationItem) {
      this.addModifiedTranslation();
    }
    this.setForm();
  };

  @action
  reset = () => {
    this.uiTranslations = {};
    this.translations = [];
    this.currentTranslation = [];
    this.selectedTranslationItem = null;
    this.mediaUrl = null;
    this.mediaMetadata = null;
    this.guideId = null;
    this.guideTitle = null;
    this.guideImageUrl = null;
    this.guideMetadata = null;
    this.defaultLocale = null;
    this.savingTranslations = false;
    this.modified = {};
    this.previousLocale = null;
    this.form.reset();
  };

  unchangedParentItem = (previousItem, newFormValues, formMediaValue) =>
    areEqual(previousItem.slug, newFormValues.slug) &&
    areEqual(previousItem.translation, newFormValues.translation) &&
    areEqual(previousItem.media?.id, formMediaValue);

  unchangedFormattableItem = (previousItem, formMediaValue) => {
    return this.form.select('translation').changed < 3 && previousItem.media?.id === formMediaValue;
  };

  @action
  addModifiedTranslation = () => {
    if (!this.currentLocale) {
      return;
    }
    const values = this.form.values();
    const formMediaValue = values.mediaId === '' ? undefined : values.mediaId;
    const currentTranslatingObject = this.selectedTranslationItem;

    if (this.isParentItemType(currentTranslatingObject.type)) {
      if (this.unchangedParentItem(currentTranslatingObject, values, formMediaValue)) {
        return;
      }

      currentTranslatingObject.slug = values.slug;
      currentTranslatingObject.translation = values.translation;
    } else {
      if (this.unchangedFormattableItem(currentTranslatingObject, formMediaValue)) {
        return;
      }
      currentTranslatingObject.translation = draftToHtml(JSON.parse(values.translation));
    }

    if (currentTranslatingObject.type !== TRANSLATION_ITEM_TYPE.TOPIC) {
      currentTranslatingObject.mediaId = formMediaValue;
      currentTranslatingObject.media = {id: formMediaValue, url: this.mediaUrl, metadata: this.mediaMetadata};
    }

    if (currentTranslatingObject.type === TRANSLATION_ITEM_TYPE.NOTE) {
      currentTranslatingObject.stepId = this.selectedTranslationItem.stepId;
    }

    currentTranslatingObject.modified = true;
    const uniqueId =
      currentTranslatingObject.type === TRANSLATION_ITEM_TYPE.NOTE
        ? `${currentTranslatingObject.id}-${currentTranslatingObject.stepId}`
        : currentTranslatingObject.id;
    this.modified[uniqueId] = currentTranslatingObject;
  };

  @action setMediaCoverUrl = url => {
    this.mediaUrl = url;
  };

  @action setMetadata = metadata => {
    this.mediaMetadata = metadata;
  };

  @action saveTranslations = async mutation => {
    this.setSaving(true);
    try {
      await mutation({
        variables: {
          id: this.guideId,
          translations: this.getTranslationsForServer()
        }
      });
      this.modified = {};
      notification.success(this.uiTranslations.saveSuccess);
      this.setSaving(false);
    } catch (e) {
      notification.error(e.message);
    }
  };

  @action
  addTranslationsToGuide = (locale, mutation) => {
    const {router} = store;
    const {id} = router.params;
    mutation({id, translations: [locale]}).then(() => notification.success(this.uiTranslations.addSuccess));
  };

  @action
  removeTranslationFromGuide = (locale, mutation) => {
    const {router} = store;
    const {id} = router.params;
    return mutation({variables: {id, translation: locale}}).then(() =>
      notification.success(this.uiTranslations.removeSuccess)
    );
  };

  @action
  setSaving = value => {
    this.savingTranslations = value;
  };

  @action
  saveBeforeSwitchingLocale = async saveTranslationsForGuideMutation => {
    if (this.selectedTranslationItem) {
      this.addModifiedTranslation();
    }
    if (Object.keys(this.modified).length) {
      await this.saveTranslations(saveTranslationsForGuideMutation);
      this.form.reset();
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get currentLocale() {
    return store.router.params.locale;
  }

  findInTranslations(translations, itemToFind) {
    if (!itemToFind) {
      return null;
    }
    if (itemToFind.type !== TRANSLATION_ITEM_TYPE.NOTE) {
      return translations?.find(obj => obj.id === itemToFind.id);
    }
    return translations?.find(obj => {
      return obj.type === itemToFind.type && obj.id === itemToFind.id && obj.stepId === itemToFind.stepId;
    });
  }

  @computed
  get originalSourceObject() {
    return this.findInTranslations(this.translations, this.selectedTranslationItem);
  }

  get formattedTranslatedObject() {
    return this.findInTranslations(this.currentTranslation, this.selectedTranslationItem);
  }

  @computed
  get guideInTranslation() {
    return this.originalSourceObject && this.originalSourceObject.type === TRANSLATION_ITEM_TYPE.GUIDE;
  }

  @computed
  get topicInTranslation() {
    return this.originalSourceObject && this.originalSourceObject.type === TRANSLATION_ITEM_TYPE.TOPIC;
  }

  @computed
  get instructionInTranslation() {
    return (
      this.originalSourceObject &&
      (this.originalSourceObject.type === TRANSLATION_ITEM_TYPE.INSTRUCTION ||
        this.originalSourceObject.type === TRANSLATION_ITEM_TYPE.CHECKLIST)
    );
  }

  @computed
  get translationObjectId() {
    return get(this.selectedTranslationItem, 'id');
  }

  @computed
  get sourceLocaleLabel() {
    return this.getLanguageLabel(this.defaultLocale);
  }

  @computed
  get translationLocaleLabel() {
    return this.getLanguageLabel(this.currentLocale);
  }

  @computed
  get showMedia() {
    const {platform} = store;
    if (get(this.selectedTranslationItem, 'type') === TRANSLATION_ITEM_TYPE.TOPIC) {
      return false;
    }

    if (get(this.selectedTranslationItem, 'type') === TRANSLATION_ITEM_TYPE.NOTE) {
      return platform.canUseMediaInStepHints;
    }
    return true;
  }

  @computed
  get showSlugField() {
    return this.isParentItemType(this.selectedTranslationItem?.type);
  }

  isFormattableItemType = type =>
    [TRANSLATION_ITEM_TYPE.NOTE, TRANSLATION_ITEM_TYPE.STEP, TRANSLATION_ITEM_TYPE.CHECK].includes(type);

  getNoteLabel(note) {
    const {
      app: {
        theme: {icons}
      }
    } = store;

    const icon = find(icons, {id: note.id});

    if (icon && icon.type === stepNoteTypes.CUSTOM) {
      return get(icon, 'labelTranslations[0].translation', 'ALTERNATIVE');
    }

    return icon ? icon.type : '';
  }

  translationsTable = () => {
    if (!this.currentLocale || !this.defaultLocale || !this.translations?.length) return [];
    this.currentTranslation = this.translations.map(({translations, ...item}) => {
      const originalLanguageItem = translations.find(translation => translation.locale === this.defaultLocale);
      const translatedLanguageItem = translations.find(translation => translation.locale === this.currentLocale);
      return {
        ...item, // item without translations
        id: item.id,
        level: item.level,
        media: translatedLanguageItem?.media,
        originalMedia: originalLanguageItem?.media,
        slug: translatedLanguageItem?.slug,
        text: originalLanguageItem?.text,
        translation: translatedLanguageItem?.text,
        type: item.type,
        ...(item.type === TRANSLATION_ITEM_TYPE.NOTE && {noteLabel: this.getNoteLabel(item)})
      };
    });
    return this.currentTranslation;
  };

  getLanguageLabel = locale => {
    const {platform} = store;
    const {availableLanguages} = platform;
    const language = find(availableLanguages, {locale});
    return language ? `${language.language} (${language.locale})` : '';
  };

  // server is returning check and checklist instead of step and instruction with isCheck/isChecklist flag - need conversion before saving
  getItemTypeForServer = type => {
    if (type === TRANSLATION_ITEM_TYPE.CHECK) {
      return TRANSLATION_ITEM_TYPE.STEP;
    }
    if (type === TRANSLATION_ITEM_TYPE.CHECKLIST) {
      return TRANSLATION_ITEM_TYPE.INSTRUCTION;
    }
    return type;
  };

  getTranslationsForServer = () => {
    if (this.selectedTranslationItem) {
      this.addModifiedTranslation();
    }
    const modifiedTranslations = [];

    for (const [, value] of Object.entries(this.modified)) {
      modifiedTranslations.push({
        id: value.id,
        type: this.getItemTypeForServer(value.type),
        contents: [
          {
            locale: this.currentLocale,
            ...this.translationsItemTypeProps(value)
          }
        ],
        ...(value.type === TRANSLATION_ITEM_TYPE.NOTE && {stepId: value.stepId})
      });
    }
    return modifiedTranslations;
  };

  translationsItemTypeProps = item => {
    if (this.isParentItemType(item.type)) {
      return {
        title: item.translation,
        slug: item.slug,
        mediaId: get(item, 'media.id', null)
      };
    } else if (item.type === TRANSLATION_ITEM_TYPE.NOTE) {
      return {
        note: stateFromHtml(item.translation),
        mediaId: get(item, 'media.id', null),
        noteContentHtml: item.translation
      };
    } else if (this.isFormattableItemType(item.type)) {
      return {
        instruction: stateFromHtml(item.translation),
        mediaId: item?.media?.id || null,
        contentHtml: item.translation
      };
    } else {
      return {};
    }
  };

  setInitialPreviousLocale = locale => {
    if (!this.previousLocale) {
      try {
        this.previousLocale = normalizeLocale(locale);
      } catch (error) {
        Raven.captureException(error);
        this.previousLocale = locale;
      }
    }
  };

  isParentItemType = type =>
    [
      TRANSLATION_ITEM_TYPE.GUIDE,
      TRANSLATION_ITEM_TYPE.TOPIC,
      TRANSLATION_ITEM_TYPE.INSTRUCTION,
      TRANSLATION_ITEM_TYPE.CHECKLIST
    ].includes(type);
}

export default TranslationsPage;
