import styled from 'styled-components';

//helpers
import typefaces from 'shared/styles/typefaces';
import {boxShadow} from 'styles/shadows';
import {truncate} from 'shared/styles/partials';
import {commonPalette} from 'shared/styles/palette';
import {topicCardMetrics, topicListMetrics} from 'styles/metrics';

//components
import $CardToolbar from 'components/CardToolbar';
import $DragHandle from 'components/DragHandle';
import {EditableText as $EditableText} from 'shared/styles/styled-components/field-styled-components';

export const TopicCard = styled.div`
  // Top padding is set in Header as it contains the DragHandle,
  // which has to start at the top of the draggable element
  // and be aligned to the title.
  //
  // Can't use margin-top for gutters as it also affects the dragged
  // element when autoscrolled and prevents the dragging
  border-radius: 2px;
  margin-bottom: ${topicListMetrics.topicGutter}px;

  ${p =>
    p.design === 'ghostly' &&
    `
    background: ${commonPalette.ghostlyGreen};
    filter: blur(4px);
    padding-bottom: ${topicCardMetrics.innerPaddingBottomWhenOpaque}px;
  `}

  ${p =>
    p.design === 'opaque' &&
    `
    background: ${commonPalette.darkestGray};
    box-shadow: ${boxShadow.draggable};
    padding-bottom: ${topicCardMetrics.innerPaddingBottomWhenOpaque}px;
  `}
`;

export const InnerWrapper = styled.div`
  position: relative;

  ${p =>
    p.isInvisible &&
    `
    // NB. In Microsoft Edge, opacity won't be applied
    // to its absolute-positioned children unless the
    // element is positioned itself

    opacity: 0;
  `}
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 ${-topicCardMetrics.innerPaddingTopWhenOpaque}px;
  padding: ${topicCardMetrics.innerPaddingTopWhenOpaque}px 0;
  position: relative;
`;

export const StyledDragHandle = styled($DragHandle)`
  height: 100%;
  position: absolute;
  right: 100%;
  top: 0;
  width: ${topicCardMetrics.dragHandleWidth}px;
`;

export const DefaultTitle = styled.h2`
  ${truncate};
  ${typefaces.topicCard.title};
  font-weight: 300;
  opacity: 0.4;
  z-index: -1;
`;

export const EditableTitle = styled($EditableText.withComponent('h2')).attrs({
  'data-cy': 'topic-title'
})`
  ${typefaces.topicCard.title};
`;

export const StyledCardToolbar = styled($CardToolbar)`
  flex-shrink: 0;
  margin-left: 5px;
`;

export const Summary = styled.div`
  display: flex;
  margin-top: 17px;
`;

export const SummaryInner = styled.div`
  background: #fff;
  border-radius: 2px;
  box-shadow: ${boxShadow.medium};
  padding: 7px 12px;
`;

export const Instructions = styled.div`
  margin-top: 23px;
  padding: 0;
`;

export const Message = styled.div`
  ${typefaces.topicCard.message};
  color: #383838;
  margin-bottom: 24px;
  opacity: 0.6;
`;
