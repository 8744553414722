import {action, observable, makeObservable} from 'mobx';
import {client} from 'utils/apollo-client';
import Raven from 'raven-js';
import {TranslationRate} from 'api/translations/queries';
import {UpdateGuide} from 'api/guide/mutations';
import notification from 'utils/notification-utils';
import {get} from 'lodash';
import messages from 'components/SelectDefaultLanguageDialog/messages';

class SelectDefaultGuideLanguageDialog {
  @observable opened = false;
  @observable isLoading = false;
  @observable defaultLocale = undefined;
  @observable selectedLocale = undefined;
  @observable translationRate = [];
  @observable guideId = undefined;

  @action open = (guideId, formatMessage) => {
    this.opened = true;
    this.setGuideId(guideId);
    this.fetchTranslationRate(formatMessage);
  };

  @action reset = () => {
    this.opened = false;
    this.isLoading = false;
    this.defaultLocale = undefined;
    this.selectedLocale = undefined;
    this.translationRate = [];
    this.guideId = undefined;
  };

  @action setLoading = loading => {
    this.isLoading = loading;
  };

  @action setDefaultLocale = locale => {
    this.defaultLocale = locale;
  };

  @action setTranslationRate = rate => {
    this.translationRate = rate;
  };

  @action setSelectedLocale = locale => {
    this.selectedLocale = locale;
  };

  @action setGuideId = guideId => {
    this.guideId = guideId;
  };

  @action fetchTranslationRate = async formatMessage => {
    this.setLoading(true);
    let result;
    try {
      result = await client.query({
        query: TranslationRate,
        variables: {id: this.guideId},
        fetchPolicy: 'network-only'
      });
    } catch (error) {
      Raven.captureException(error);
    }
    const guideDetails = get(result, 'data.guideDetails');
    if (guideDetails?.translationRate) {
      this.setTranslationRate([...guideDetails.translationRate].sort((a, b) => (a.completion > b.completion ? -1 : 1)));
      this.setDefaultLocale(guideDetails.defaultLocale);
      this.setSelectedLocale(guideDetails.defaultLocale);
    } else {
      this.reset();
      notification.error(formatMessage(messages.errorFetchingLanguages));
    }
    this.setLoading(false);
  };

  @action updateDefaultLanguage = async formatMessage => {
    this.setLoading(true);
    let result;
    try {
      result = await client.mutate({
        mutation: UpdateGuide,
        variables: {
          id: this.guideId,
          guide: {
            defaultLocale: this.selectedLocale,
            locale: this.defaultLocale
          }
        },
        refetchQueries: ['draftGuideForCms', 'getDomainsForGuide', 'guideForCms']
      });
    } catch (error) {
      Raven.captureException(error);
    }
    if (!get(result, 'data.updateGuide.id')) {
      notification.error(formatMessage(messages.errorChangingDefaultLanguage));
    }
    this.reset();
    this.setLoading(false);
  };

  @action onSave = async formatMessage => {
    if (this.selectedLocale !== this.defaultLocale) {
      await this.updateDefaultLanguage(formatMessage);
      return;
    }
    this.reset();
  };

  constructor() {
    makeObservable(this);
  }
}

export default SelectDefaultGuideLanguageDialog;
