import CloudinaryPicture from 'shared/components/CloudinaryPicture';
import React from 'react';
import {IconFa, PickerOverlay, IconGradient} from './styles';
import messagesProperties from '../MediaProperties/messages';
import {
  CoverImage,
  MediaNameContainer,
  MediaContainer,
  MediaName,
  pickerOverlayClassName
} from '../MediaGallery/styles';

const MediaImageItem = ({
  media,
  selectedMedia,
  setSelectedMedia,
  metadata,
  formatMessage,
  isMediaVideo,
  fileName,
  isUnassignedMedia,
  setIsUnassignedMedia
}) => {
  return (
    <MediaContainer
      onClick={() => {
        setSelectedMedia(media);
        setIsUnassignedMedia(Boolean(isUnassignedMedia));
      }}
    >
      <CoverImage>
        <CloudinaryPicture aspectRatio={4 / 3} src={media.url} metadata={metadata} />
        <PickerOverlay selected={selectedMedia && selectedMedia.id === media.id} className={pickerOverlayClassName} />
        <IconGradient />
        <IconFa name={isMediaVideo(media) ? 'video-camera' : 'image'} className="icon" />
      </CoverImage>
      <MediaNameContainer>
        <MediaName>{fileName || formatMessage(messagesProperties.untitled)}</MediaName>
      </MediaNameContainer>
    </MediaContainer>
  );
};

export default MediaImageItem;
