import styled from 'styled-components';

import {commonPalette, checklistColors} from 'shared/styles/palette';

export const StyledHighlightedInfo = styled.div`
  color: #d96b4a;
  background-color: #fbf1ee;
  padding: 16px;
`;

export const ListWrapper = styled.div`
  color: black;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ListLink = styled.a`
  color: ${commonPalette.txBlack};
  text-decoration: underline;
  text-underline-offset: 2px;
  transition: none;
  margin-bottom: 5px;

  &:hover {
    color: ${commonPalette.txBlack};
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;

export const StyledText = styled.div`
  color: ${commonPalette.txBlack};
`;

export const SpinnerWrapper = styled.div`
  padding: 15px;
`;

export const dialogStyle = {width: 560, padding: 26};

export const deleteUserButtonStyle = {color: commonPalette.white, backgroundColor: checklistColors.red};
