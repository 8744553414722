import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import get from 'lodash/get';

//helpers
import {ensureDraftVersion} from 'utils/versioning-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import UntranslatedIndicator from 'components/UntranslatedIndicator';

//utils
import {getClonedStep} from 'api/step/utils';
import views from 'config/views';
import notification from 'utils/notification-utils';

import {
  DropdownMenu,
  DropdownMenuDivider,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuLink
} from 'styles/styled-components/dropdown-styled-components';

//data
import {deleteStepOptions, createStepOptions, repositionStepsOptions} from 'api/step/mutation-options';
import {DeleteStep, CreateStep, RepositionSteps} from 'api/step/mutations';
import {SetInstructionCover} from 'api/instruction/mutations';
import {setInstructionCoverOptions} from 'api/instruction/mutation-options';

//messages
import messages from './messages';

import {TranslationItemWrapper} from './styles';
import {getTranslateRouteAndParams} from 'utils/translate-route';

@inject('store')
@graphql(CreateStep, createStepOptions)
@graphql(RepositionSteps, repositionStepsOptions)
@graphql(SetInstructionCover, setInstructionCoverOptions)
@graphql(DeleteStep, deleteStepOptions)
@observer
class StepsMenuComponent extends Component {
  render() {
    const {canTranslate, step, store, isOverview, shouldShowUntranslatedIcon} = this.props;
    const {router, editInstructionDetailsPage: page} = store;
    const {id: guideId, instructionId, topicId} = router.params;

    const guideDefaultLocale = get(page, 'guide.defaultLocale', null);
    const isDraftVersion = !get(router, 'queryParams.v', false);

    const {params: translateParams, route: translateRoute} = getTranslateRouteAndParams(
      guideDefaultLocale,
      page.guide.availableTranslations,
      {guideId, topicId, instructionId, stepId: step.id}
    );

    return (
      // The Dropdown component tries to amend its child props.
      // To avoid disrupting this we want to forward the props
      // the actual underlying DropdownMenu component it expects.
      <DropdownMenu {...this.props}>
        {canTranslate && isDraftVersion && (
          <DropdownMenuItem>
            <DropdownMenuLink params={translateParams} store={store} route={translateRoute}>
              <TranslationItemWrapper>
                <FormattedMessage {...messages.translate} />
                {shouldShowUntranslatedIcon && <UntranslatedIndicator size={16} content="!" />}
              </TranslationItemWrapper>
            </DropdownMenuLink>
          </DropdownMenuItem>
        )}
        {step.media && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.setStepAsCoverPhoto()}>
              <FormattedMessage {...messages.coverPhoto} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        <DropdownMenuDivider />
        {isOverview && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.edit()}>
              <FormattedMessage {...messages.edit} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {isDraftVersion && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => this.clone()}>
              <FormattedMessage {...messages.duplicate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        <DropdownMenuItem>
          <DropdownMenuButton onClick={() => this.archive()}>
            <FormattedMessage {...messages.delete} />
          </DropdownMenuButton>
        </DropdownMenuItem>
      </DropdownMenu>
    );
  }

  archive = ensureDraftVersion(() => {
    const {deleteStepMutation: mutation, step, store, intl, onArchive} = this.props;
    const {formatMessage} = intl;
    const {id} = step;
    const {
      archiveDialog,
      editInstructionDetailsPage: {isChecklist}
    } = store;

    const translations = {
      action: formatMessage(isChecklist ? messages.actionCheck : messages.actionStep),
      confirmation: formatMessage(isChecklist ? messages.confirmationCheck : messages.confirmationStep),
      archiveFailure: formatMessage(isChecklist ? messages.archiveCheckFailure : messages.archiveStepFailure),
      archiveSuccess: formatMessage(isChecklist ? messages.archiveCheckSuccess : messages.archiveStepSuccess)
    };

    archiveDialog.open({mutation, translations, params: {id}, onArchive});
  }, this);

  edit = ensureDraftVersion(() => {
    const {store, step} = this.props;
    const {instructionId, id, topicId} = store.router.params;
    store.router.goTo(views.editInstruction, {
      id,
      instructionId,
      stepId: step.id,
      topicId
    });
  }, this);

  clone = ensureDraftVersion(() => {
    const {createStepMutation, step, intl, onClone, store} = this.props;
    const {
      unsavedChangesDialog,
      editInstructionDetailsPage: {isChecklist, currentInstruction}
    } = store;
    const disabledOptions = currentInstruction?.steps[step.position]?.disabledOptions;
    const disabledOptionIds = disabledOptions.map(obj => {
      return obj.id;
    });
    unsavedChangesDialog.check().then(answer => {
      const {formatMessage} = intl;
      const newStep = getClonedStep(step);
      newStep.disabledOptionIds = disabledOptionIds;
      createStepMutation({step: answer && answer.result ? {...newStep, ...answer.result} : newStep, isDuplicate: true})
        .then(({data}) => {
          notification.success(formatMessage(isChecklist ? messages.cloneCheckSuccess : messages.cloneStepSuccess));
          if (typeof onClone === 'function') {
            onClone(data.createStep);
          }
        })
        .catch(() => {
          notification.error(formatMessage(isChecklist ? messages.cloneCheckError : messages.cloneStepError));
        });
    });
  }, this);

  setStepAsCoverPhoto = ensureDraftVersion(() => {
    const {setInstructionCoverMutation, step, intl} = this.props;
    const {formatMessage} = intl;
    const {instructionId, locale} = step;
    const instructionUpdate = {
      id: instructionId,
      mediaId: get(step, 'media.id'),
      locale
    };

    setInstructionCoverMutation(instructionUpdate)
      .then(() => {
        notification.success(formatMessage(messages.setCoverSuccess));
      })
      .catch(() => {
        notification.error(formatMessage(messages.setCoverError));
      });
  }, this);
}

export default injectIntl(StepsMenuComponent);
