import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {observable, toJS, makeObservable} from 'mobx';
import {get, isEmpty, mapValues, pick} from 'lodash';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';

import {SORT_BY_OPTIONS, GUIDE_FILTER_OPTIONS, STATUS_OPTIONS, CUSTOM_FILTER_OPTIONS} from 'shared/enums';

//styled-components
import {
  FilterWrapper,
  FilterWrapperSection,
  InstructionsList,
  StyledCustomGuideListFilters,
  StyledInfiniteScroll,
  StyledViewTitle
} from './styles';

//queries
import {GuidesList} from 'api/guide/queries';
import {AllTeams} from 'api/team/queries';
import {Assignees} from 'api/user/queries';
import {DomainsLite} from 'api/domain/queries';
import {AvailableLanguages} from 'api/platform/queries';

//options
import {myGuidesOptions} from 'api/guide/query-options';
import {teamsLiteOptions} from 'api/team/query-options';
import {assigneesOptions} from 'api/user/query-options';
import {domainsOptions} from 'api/domain/query-options';
import {availableLanguagesOptions} from 'api/platform/query-options';

//components
import Guides from 'components/GuidesList';
import SearchBar from 'ui-components/Layout/SearchBar';
import Select from 'ui-components/Select';

import {setFiltersToLocalStorage, filtersKeys} from 'utils/filters';

//messages
import messages from './messages';

import {queries, SEARCH_TIMEOUT} from './constants';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

const retry = async (ms, maxAttempts, condition) =>
  new Promise(resolve => {
    let attempt = 1;
    const intervalId = setInterval(() => {
      if (condition() || attempt > maxAttempts) {
        clearInterval(intervalId);
        resolve(condition());
      } else {
        attempt += 1;
      }
    }, ms);
  });
@inject('store')
@graphql(GuidesList, {...myGuidesOptions, name: 'guidesData'})
@graphql(AllTeams, {...teamsLiteOptions, name: 'teamsQuery'})
@graphql(Assignees, assigneesOptions)
@graphql(DomainsLite, domainsOptions)
@graphql(AvailableLanguages, availableLanguagesOptions)
@observer
class InstructionsListComponent extends Component {
  timeout = null;
  @observable searchTerm = '';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  async componentDidMount() {
    const {
      intl: {formatMessage},
      store: {
        allGuidesPage: {setCustomFilterEntity, customFilter},
        tagManagementPage: {fetchData: fetchTags, setTags, fetchTagsByIds, setUiTranslations}
      }
    } = this.props;
    const translations = mapValues(pick(messages, ['errorLoadingTags']), message => formatMessage(message));
    setUiTranslations(translations);

    let {tags} = await fetchTags();

    const notLoadedTags = customFilter.TAG.filter(tagId => !tags.some(tag => tag.id === tagId));

    if (notLoadedTags.length) {
      const {tagsByIds} = await fetchTagsByIds(notLoadedTags);
      tags = [...tags, ...tagsByIds];
    }

    setTags(tags);
    const shouldRefetchGuides = setCustomFilterEntity('TAG', tags);
    if (shouldRefetchGuides) {
      this.refetchGuides();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      store: {allGuidesPage}
    } = this.props;

    const isCustomFilterUpdated = (name, current) =>
      current !== undefined && get(allGuidesPage, `customFilterEntities.${name}`, []).length !== (current || []).length;

    queries.forEach(({name, entity, storeKey}) => {
      const prev = get(prevProps, `${name}.${entity}`);
      const current = get(this.props, `${name}.${entity}`);
      const shouldUpdateCustomFilter = prev !== current || isCustomFilterUpdated(name, current);
      if (shouldUpdateCustomFilter) {
        const shouldRefetchGuides = allGuidesPage.setCustomFilterEntity(storeKey, current);
        if (shouldRefetchGuides) {
          this.refetchGuides();
        }
      }
    });
  }

  refetchGuides = () => {
    const {
      store: {allGuidesPage}
    } = this.props;

    this.refetch({
      guideFilter: allGuidesPage.selectedFilter,
      customFilter: allGuidesPage.customFilterPayload,
      publishStatus: allGuidesPage.selectedStatusPayload
    });
  };

  fetchMore = () => {
    const {guidesData} = this.props;
    const {fetchMore, loading, pagedGuidesForCms} = guidesData;
    const {nextOffset} = pagedGuidesForCms;

    if (loading || !nextOffset) {
      return;
    }

    fetchMore({
      variables: {offset: nextOffset},
      fetchPolicy: 'network-only',
      updateQuery: (previousResult, {fetchMoreResult}) => {
        const {guides, nextOffset} = fetchMoreResult.pagedGuidesForCms;

        return guides.length
          ? {
              pagedGuidesForCms: {
                __typename: previousResult.pagedGuidesForCms.__typename,
                guides: [...previousResult.pagedGuidesForCms.guides, ...guides],
                nextOffset
              }
            }
          : previousResult;
      }
    });
  };

  refetch = variables => {
    retry(100, 10, () => [7, 8].includes(this.props.guidesData.networkStatus)).then(canRefetch => {
      if (canRefetch) {
        this.props.guidesData.refetch(variables);
      }
    });
  };

  onChangeReaction = event => {
    const {
      store: {
        allGuidesPage: {form}
      }
    } = this.props;

    const searchTerm = event.target.value;
    this.searchTerm = searchTerm;
    form.$('searchTerm').sync(searchTerm);

    setFiltersToLocalStorage(filtersKeys.SEARCH_TERM, searchTerm);

    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.refetch({searchTerm});
    }, SEARCH_TIMEOUT);
  };

  changeSortBy = id => {
    const {
      store: {allGuidesPage}
    } = this.props;

    setFiltersToLocalStorage(filtersKeys.SORT_BY, id);

    allGuidesPage.changeSortBy(id);
    this.refetch({sortBy: id});
    allGuidesPage.toggleSortBy(false);

    trackEvent(EVENT_TYPES.GUIDE_OVERVIEW_SORT, {id});
  };

  selectFilterOption = id => {
    const {
      store: {allGuidesPage}
    } = this.props;
    const {selectedFilter: currentSelectedFilter} = allGuidesPage;

    if (currentSelectedFilter === id) {
      return;
    }

    setFiltersToLocalStorage(filtersKeys.FILTER_OPTION, id);

    if (currentSelectedFilter === GUIDE_FILTER_OPTIONS.ALL_GUIDES) {
      allGuidesPage.disposeCustomFilter();
    }

    if (id === GUIDE_FILTER_OPTIONS.ALL_GUIDES) {
      allGuidesPage.initCustomFilter();
    }

    allGuidesPage.selectFilterOption(id);

    this.refetch({
      guideFilter: allGuidesPage.selectedFilter,
      customFilter: allGuidesPage.customFilterPayload,
      publishStatus: allGuidesPage.selectedStatusPayload
    });

    trackEvent(EVENT_TYPES.GUIDE_OVERVIEW_SELECT_FILTER, {id});
  };

  selectStatusOption = id => {
    const {store} = this.props;
    const {allGuidesPage} = store;
    const {selectedOption} = allGuidesPage;

    if (selectedOption === id) {
      return;
    }

    setFiltersToLocalStorage(filtersKeys.SELECT_STATUS, id);

    allGuidesPage.selectStatusOption(id);
    this.refetch({publishStatus: allGuidesPage.selectedStatusPayload});

    trackEvent(EVENT_TYPES.GUIDE_OVERVIEW_STATUS_FILTER, {id});
  };

  selectCustomFilterOption = (key, value) => {
    const {
      store: {allGuidesPage}
    } = this.props;

    setFiltersToLocalStorage(key, value);

    allGuidesPage.selectCustomFilterOption(key, value);
    const {customFilterPayload} = allGuidesPage;

    this.refetch({customFilter: customFilterPayload});
  };

  resetCustomFilter = () => {
    const {
      store: {allGuidesPage}
    } = this.props;

    setFiltersToLocalStorage(CUSTOM_FILTER_OPTIONS.TAG, []);
    setFiltersToLocalStorage(CUSTOM_FILTER_OPTIONS.TEAM, []);
    setFiltersToLocalStorage(CUSTOM_FILTER_OPTIONS.ASSIGNEE, []);
    setFiltersToLocalStorage(CUSTOM_FILTER_OPTIONS.WORKSPACE, []);
    setFiltersToLocalStorage(CUSTOM_FILTER_OPTIONS.LANGUAGE, []);

    allGuidesPage.selectFilterOption(GUIDE_FILTER_OPTIONS.ALL_GUIDES);
    allGuidesPage.initCustomFilter();
    const {selectedFilter: guideFilter} = allGuidesPage;

    this.refetch({guideFilter, customFilter: {}});
  };

  UNSAFE_componentWillMount = () => {
    const {
      intl: {formatMessage},
      store: {
        allGuidesPage: {setSortByOptions, setFilterOptions, setStatusOptions}
      }
    } = this.props;

    const {
      guideTitle,
      lastUpdated,
      lastPublished,
      dateCreated,
      allGuides,
      myGuides,
      myTeamsGuides,
      all,
      published,
      draft
    } = messages;

    const sortByOptions = [
      {
        id: SORT_BY_OPTIONS.PUBLISHED_TITLE,
        name: formatMessage(guideTitle)
      },
      {
        id: SORT_BY_OPTIONS.LAST_UPDATED,
        name: formatMessage(lastUpdated)
      },
      {
        id: SORT_BY_OPTIONS.LAST_PUBLISHED,
        name: formatMessage(lastPublished)
      },
      {
        id: SORT_BY_OPTIONS.CREATION_DATE,
        name: formatMessage(dateCreated)
      }
    ];

    const filterOptions = [
      {
        id: GUIDE_FILTER_OPTIONS.ALL_GUIDES,
        name: formatMessage(allGuides)
      },
      {
        id: GUIDE_FILTER_OPTIONS.MY_GUIDES,
        name: formatMessage(myGuides)
      },
      {
        id: GUIDE_FILTER_OPTIONS.TEAM_GUIDES,
        name: formatMessage(myTeamsGuides)
      }
    ];

    const statusOptions = [
      {
        id: STATUS_OPTIONS.ALL,
        name: formatMessage(all)
      },
      {
        id: STATUS_OPTIONS.PUBLISHED,
        name: formatMessage(published)
      },
      {
        id: STATUS_OPTIONS.DRAFT,
        name: formatMessage(draft)
      }
    ];

    setSortByOptions(sortByOptions);
    setFilterOptions(filterOptions);
    setStatusOptions(statusOptions);
  };

  componentWillUnmount() {
    const {
      store: {
        tagManagementPage: {setSearchTerm, setPageIndex}
      }
    } = this.props;
    setSearchTerm('');
    setPageIndex(0);
  }

  render() {
    const {
      intl: {formatMessage},
      store: {allGuidesPage},
      guidesData = {},
      form,
      editable = true
    } = this.props;
    const {
      pagedGuidesForCms = {},
      loading,
      variables: {customFilter}
    } = guidesData;
    const {guides, nextOffset} = pagedGuidesForCms;
    const {
      sortByOptions,
      selectedOption,
      filterOptions,
      selectedFilter,
      shouldShowCustomFilter,
      statusOptions,
      selectedStatus
    } = allGuidesPage;

    return (
      <InstructionsList>
        <StyledViewTitle title={formatMessage(messages.guideOverviewNew)} />
        <FilterWrapper>
          <FilterWrapperSection>
            <Select
              underlined
              allowClear={false}
              label={formatMessage(messages.select)}
              options={toJS(filterOptions)}
              selectedValue={selectedFilter}
              onChange={this.selectFilterOption}
              dataCy={'guides-filter'}
            />
            <Select
              underlined
              allowClear={false}
              label={formatMessage(messages.status)}
              options={statusOptions}
              selectedValue={selectedStatus}
              onChange={this.selectStatusOption}
              style={{marginLeft: 24}}
              dataCy={'status-filter'}
            />
          </FilterWrapperSection>
          <FilterWrapperSection>
            <SearchBar
              placeholder={formatMessage(messages.searchPlaceholder)}
              form={form}
              field={'searchTerm'}
              onChange={this.onChangeReaction}
              defaultValue={form.values().searchTerm}
            />
            <Select
              underlined
              allowClear={false}
              label={formatMessage(messages.sortBy)}
              options={toJS(sortByOptions)}
              selectedValue={selectedOption.id}
              onChange={this.changeSortBy}
            />
          </FilterWrapperSection>
        </FilterWrapper>
        {shouldShowCustomFilter && (
          <StyledCustomGuideListFilters selectOption={this.selectCustomFilterOption} onReset={this.resetCustomFilter} />
        )}
        <div data-cy-loading={loading}>
          <Guides
            guides={guides}
            showCreateAction={editable}
            guidesData={guidesData}
            searchTerm={this.searchTerm}
            loading={loading}
            hasCustomFilters={!isEmpty(customFilter)}
            nextOffset={nextOffset}
          />
          {nextOffset && (
            <StyledInfiniteScroll isLoading={loading} onBottom={this.fetchMore} spinnerSize={false} threshold={200} />
          )}
        </div>
      </InstructionsList>
    );
  }
}

export default injectIntl(InstructionsListComponent);
