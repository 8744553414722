import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import {dateRangeOptionsValues} from 'shared/enums';

import Filters from './Filters';

import {getOptionsMap} from './constants';
import {StyledIcon, StyledLabel, DateFilterWrapper} from './styles';

@observer
class DateFilter extends Component {
  @observable isHovering = false;
  @observable isVisible = false;

  @action setIsHovering = isHovering => (this.isHovering = isHovering);
  @action setIsVisible = isVisible => (this.isVisible = isVisible);
  @action setOption = option => (this.option = option);

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  getOptionByDateRange = (startDate, endDate) => {
    let label;

    const {isToday, isYesterday, isLast7Days, isLast30Days, isLastMonth, isWeekToDate, isMonthToDate} = getOptionsMap(
      startDate,
      endDate
    );

    if (isToday) {
      label = dateRangeOptionsValues.today;
    } else if (isYesterday) {
      label = dateRangeOptionsValues.yesterday;
    } else if (isLast7Days) {
      label = dateRangeOptionsValues.last7days;
    } else if (isLast30Days) {
      label = dateRangeOptionsValues.last30days;
    } else if (isLastMonth) {
      label = dateRangeOptionsValues.lastMonth;
    } else if (isWeekToDate) {
      label = dateRangeOptionsValues.weekToDate;
    } else if (isMonthToDate) {
      label = dateRangeOptionsValues.monthToDate;
    } else {
      label = dateRangeOptionsValues.custom;
    }

    return label;
  };

  @observable option = this.getOptionByDateRange(this.props.startDate, this.props.endDate);

  render() {
    const {
      isHovering,
      isVisible,
      setIsHovering,
      setIsVisible,
      option,
      props: {onApply, startDate, endDate, translations}
    } = this;
    const iconId = isVisible ? 'caretUp' : 'sort';
    const optionId = this.getOptionByDateRange(startDate, endDate).id;

    return (
      <DateFilterWrapper data-testid={'date-filter'}>
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => setIsVisible(!isVisible)}
        >
          <StyledLabel isHovering={isHovering}>{translations[optionId]}</StyledLabel>
          <StyledIcon iconId={iconId} isHovering={isHovering} />
        </div>
        {isVisible && (
          <Filters
            hideMenu={() => setIsVisible(false)}
            startDate={startDate}
            endDate={endDate}
            onApply={onApply}
            translations={translations}
            option={option}
          />
        )}
      </DateFilterWrapper>
    );
  }
}

export default injectIntl(DateFilter);
