import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

export const DND_TYPE = 'OVERLAY_SHAPE';

export const source = {
  beginDrag: (props, monitor, component) => {
    const {shape, position, onBeginDrag} = props;

    if (typeof onBeginDrag === 'function') {
      const instance = component.getDecoratedComponentInstance();
      onBeginDrag(instance.dragHandleElement);
    }

    return {
      preview: shape,
      sourceIndex: position
    };
  },
  endDrag: (props, monitor, component) => {
    const {onEndDrag} = props;

    if (typeof onEndDrag === 'function') {
      const instance = component.getDecoratedComponentInstance();
      onEndDrag(instance.dragHandleElement);

      trackEvent(EVENT_TYPES.SHAPE_REORDER);
    }
  }
};

export const target = {
  hover: (props, monitor) => {
    const item = monitor.getItem();
    const {position, store} = props;
    const {sourceIndex} = item;
    const {mediaPickerDialog: page} = store;

    if (position !== sourceIndex) {
      page.moveShape(sourceIndex, position);

      // Note: we're mutating the monitor item here! Generally it's better
      // to avoid mutations, but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.sourceIndex = position;
    }
  }
};
