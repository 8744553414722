import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {commonPalette} from 'shared/styles/palette';

//styled-components
import {Form, Field, FormColumn, CreateUserPage, Caption, UsernameWrapper, contactMethodPickerStyles} from './styles';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Checkbox from 'components/Checkbox';
import Icon from 'ui-components/Icon';
import PhoneInput from 'shared/components/PhoneInput';

//helpers
import get from 'lodash/get';

//messages
import messages from './messages';
import ContactMethodPicker from '../ContactMethodPicker';

@inject('store')
@observer
class NewUserCreate extends Component {
  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {newUserPage: page, auth, platform} = store;
    const {developmentFeatureFlags} = platform;
    const {form, isPlatformAdmin, togglePlatformAdmin} = page;
    const {user: managingUser} = auth;
    const formValues = form.values();
    const contactMethodSelection = get(developmentFeatureFlags, 'contactMethodSelection', false);
    return (
      <CreateUserPage>
        <Form>
          <FormColumn>
            <Field autofocus={true} title={formatMessage(messages.fullName)} field="fullName" form={form} />
            {contactMethodSelection && (
              <ContactMethodPicker
                form={form}
                field="contactMethod"
                label={formatMessage(messages.contactMethod)}
                dataCy="contact-method-picker"
                style={contactMethodPickerStyles}
              />
            )}

            <UsernameWrapper indent={contactMethodSelection}>
              {formValues.contactMethod === 'EMAIL' ? (
                <Field title={formatMessage(messages.email)} field="email" form={form} />
              ) : (
                <PhoneInput title={formatMessage(messages.phoneNumber)} field="phoneNumber" form={form} />
              )}
            </UsernameWrapper>
          </FormColumn>
        </Form>
        {managingUser.isPlatformAdmin && formValues.contactMethod === 'EMAIL' && (
          <Field
            field="isPlatformAdmin"
            inputComponent={
              <Checkbox
                type="checkbox"
                caption={
                  <Caption>
                    <FormattedMessage {...messages.setPlatformAdmin} />
                    <Icon
                      popoverContent={formatMessage(messages.setPlatformAdminDescription)}
                      style={{marginLeft: 8, color: commonPalette.txGrey}}
                    />
                  </Caption>
                }
                checked={isPlatformAdmin}
                onChange={togglePlatformAdmin}
              />
            }
          ></Field>
        )}
      </CreateUserPage>
    );
  }
}

export default injectIntl(NewUserCreate);
