import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  title: {
    id: 'PlatformLanguageSelectionDialog.title',
    defaultMessage: 'Select Platform Language'
  },
  subtitle: {
    id: 'PlatformLanguageSelectionDialog.subtitle',
    defaultMessage: 'This language may be auto-translated'
  },
  saveButtonLabel: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  }
});

export default messages;
