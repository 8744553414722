import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const ThemeStepNotesIconsWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Text = styled.div`
  color: ${commonPalette.txGrey};
  font-style: italic;
  padding-bottom: 1rem;
`;

export const ThemeStepNotesIconsCaptionWrapper = styled.div`
  flex: 0;
`;

export const ThemeStepNotesIconsListWrapper = styled.div`
  flex: 1;
  overflow-y: auto !important;
  overflow: -moz-scrollbars-vertical;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    border-radius: 3px;
    width: 4px;
    background-color: ${commonPalette.borderGrey};
  }
  ::-webkit-scrollbar-thumb {
    -webkit-appearance: none;
    border-radius: 3px;
    background-color: ${commonPalette.txLightGrey};
  }
`;
