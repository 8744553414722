import MobxReactForm from 'mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkTextLimit} from 'utils/validation-utils';

const asyncRules = {
  checkTextLimit: (value, attr, key, passes) => {
    return checkTextLimit(value, attr, passes);
  }
};

const plugins = dvrWithAsync(asyncRules);

export const teamLevelsEditForm = (initialValues = {}, hooks = {}) => {
  const fields = [
    {
      name: 'id',
      rules: 'required|string',
      value: initialValues.id
    },
    {
      name: 'name',
      rules: 'required|string|checkTextLimit:2,20',
      value: initialValues.name
    },
    {
      name: 'description',
      rules: 'string|checkTextLimit:2,60',
      value: initialValues.description
    }
  ];

  const options = {
    validateOnChange: true
  };

  const form = new MobxReactForm({fields}, {plugins, hooks, options});
  return form;
};
