import {defineMessages} from 'react-intl';

const messages = defineMessages({
  confirmReassignGuide: {
    id: 'ConfirmGuideReassignDialog.confirmReassignGuide',
    defaultMessage: 'Confirm reassign guide'
  },
  submittedForApproval: {
    id: 'ConfirmGuideReassignDialog.submittedForApproval',
    defaultMessage:
      'The guide {title} is currently submitted for approval. When you reassign the guide, the approval request will be cancelled.'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  confirm: {
    id: 'common.actions.confirm',
    defaultMessage: 'Confirm'
  }
});

export default messages;
