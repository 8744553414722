import some from 'lodash/some';

// key represents the flag, value represents the blocked paths
const flags = {canManageSkills: ['skills', 'jobtitles']};

export const userHasCorrectFlags = (user, currentRoute) => {
  if (!user || !currentRoute.restricted) return true;

  for (const flag in flags) {
    const paths = flags[flag];
    const pathsRestricted = some(paths, path => currentRoute?.path.includes(path));
    const userHasFlag = !!user[flag];
    if (pathsRestricted && !userHasFlag) {
      return false;
    }
  }

  return true;
};

export const shouldRestrictAccessToRoute = ({user, currentRoute}) => {
  if (currentRoute?.ipa && (!user || !user.isIPA)) return true;

  const restrictedView = currentRoute && currentRoute.editor;

  const isEditor = user && user.isEditor;

  return restrictedView ? !isEditor : false;
};
