import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'NewUser.title',
    defaultMessage: 'Create New User'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  phoneNumber: {
    id: 'common.forms.phoneNumber',
    defaultMessage: 'Phone Number'
  },
  fullName: {
    id: 'EditUser.fullName',
    defaultMessage: 'Full name'
  },
  password: {
    id: 'common.forms.password',
    defaultMessage: 'Password'
  },
  confirmPassword: {
    id: 'common.forms.confirmPassword',
    defaultMessage: 'Confirm password'
  },
  passwordMismatch: {
    id: 'common.forms.passwordMismatch',
    defaultMessage: 'The passwords must match'
  },
  passwordComplexityRulesTip: {
    id: 'common.forms.passwordComplexityRulesTip',
    defaultMessage: 'Please make sure that your password follows these rules:'
  },
  passwordMustHave8Characters: {
    id: 'common.forms.passwordMustHave8Characters',
    defaultMessage: 'Password must have at least 8 characters'
  },
  passwordMustHaveAtLeast1Number: {
    id: 'common.forms.passwordMustHaveAtLeast1Number',
    defaultMessage: 'Password must have at least 1 number'
  },
  passwordMustHaveAtLeast1UppercaseLetter: {
    id: 'common.forms.passwordMustHaveAtLeast1UppercaseLetter',
    defaultMessage: 'Password must have at least 1 uppercase letter'
  },
  passwordMustHaveAtLeast1LowercaseLetter: {
    id: 'common.forms.passwordMustHaveAtLeast1LowercaseLetter',
    defaultMessage: 'Password must have at least 1 lowercase letter'
  },
  passwordMustHaveAtLeast1NonAlphanumericCharacter: {
    id: 'common.forms.passwordMustHaveAtLeast1NonAlphanumericCharacter',
    defaultMessage: 'Password must have at least 1 non-alphanumeric character (!@$&, etc.)'
  },
  role: {
    id: 'EditUser.role',
    defaultMessage: 'Role'
  },
  setPlatformAdmin: {
    id: 'EditUser.setPlatformAdmin',
    defaultMessage: 'Set as Platform Admin'
  },
  setPlatformAdminDescription: {
    id: 'common.description.setPlatformAdmin',
    defaultMessage: 'This will give the user the authority to manage the entire Platform.'
  },
  contactMethod: {
    id: 'common.forms.contactMethod',
    defaultMessage: 'Contact Method'
  }
});

export default messages;
