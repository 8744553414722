import gql from 'graphql-tag';

export const GetTranslationsForGuide = gql`
  query translationsForGuide($guideId: String!) {
    translationsForGuide(guideId: $guideId)
  }
`;

export const GetAutoTranslations = gql`
  query autoTranslations($content: [String]!, $sourceLang: String, $targetLang: String!) {
    autoTranslations(content: $content, sourceLang: $sourceLang, targetLang: $targetLang) {
      content
      translatedContent
      targetLang
      sourceLang
    }
  }
`;

export const TranslationRate = gql`
  query translationRate($id: String!) {
    guideDetails(id: $id) {
      id
      defaultLocale
      translationRate {
        locale
        language
        completion
      }
    }
  }
`;

export const GetTranslationsForGuidePaginated = gql`
  query translationsForGuidePaginated($offset: Int, $limit: Int, $filters: TranslationFilters!) {
    translationsForGuidePaginated(offset: $offset, limit: $limit, filters: $filters) {
      results {
        id
        level
        type

        ... on StepTranslationItem {
          instructionId
        }

        ... on StepNoteTranslationItem {
          instructionId
          stepId
        }

        ... on InstructionTranslationItem {
          topicId
        }

        translations {
          locale
          text

          ... on TranslationWithSlug {
            slug
          }

          ... on TranslationWithMedia {
            media {
              id
              url
            }
          }

          ... on TranslationWithSlugAndMedia {
            slug
            media {
              id
              url
            }
          }
        }
      }
    }
  }
`;
