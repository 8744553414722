export const themesOptions = {
  options: {
    fetchPolicy: 'network-only'
  }
};

export const getThemeOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      name: 'themeQuery',
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    };
  }
};
