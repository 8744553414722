import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {
  DrawerTitle,
  DrawerWrapper,
  FlexRow,
  BackButton,
  StyledSubText,
  ActivityListBackIcon,
  FlexColumn,
  StyledContent,
  StyledTitle,
  StyledLink,
  FixedDrawerTitle,
  FixedButtonWrapper
} from './styles';
import Button from 'ui-components/Button';
import messages from './messages';

@inject('store')
@observer
class DetailedActivity extends Component {
  render() {
    const {
      store: {skillsPage},
      intl: {formatMessage}
    } = this.props;
    const {setIsDetailedViewSelected, selectedLearningActivity, setSelectedLearningActivity} = skillsPage;

    return (
      <DrawerWrapper>
        <FixedDrawerTitle>
          <DrawerTitle>{formatMessage(messages.learningActivityDetails)}</DrawerTitle>

          <FlexRow>
            <BackButton
              onClick={() => {
                setIsDetailedViewSelected(false);
                setSelectedLearningActivity(null);
              }}
            >
              <ActivityListBackIcon />
            </BackButton>
            <StyledSubText isEmptyState={true}>{formatMessage(messages.returnToActivityList)}</StyledSubText>
          </FlexRow>
        </FixedDrawerTitle>

        <FlexColumn>
          <StyledTitle>{formatMessage(messages.title)}</StyledTitle>
          <StyledContent>{selectedLearningActivity?.title ? selectedLearningActivity.title : '-'}</StyledContent>
          <StyledTitle>{formatMessage(messages.link)}</StyledTitle>
          {selectedLearningActivity?.resourceUrl ? (
            <StyledLink target="blank" href={selectedLearningActivity?.resourceUrl}>
              {selectedLearningActivity?.resourceUrl ? selectedLearningActivity.resourceUrl : '-'}
            </StyledLink>
          ) : (
            <StyledContent>{'-'}</StyledContent>
          )}
          <StyledTitle>{formatMessage(messages.description)}</StyledTitle>
          <StyledContent style={{marginBottom: 40}}>
            {selectedLearningActivity?.content ? selectedLearningActivity.content : '-'}
          </StyledContent>
        </FlexColumn>
        <FixedButtonWrapper>
          <Button
            onClick={() => {
              setIsDetailedViewSelected(false);
              setSelectedLearningActivity(null);
            }}
            label={formatMessage(messages.exitPreview)}
          ></Button>
        </FixedButtonWrapper>
      </DrawerWrapper>
    );
  }
}
export default injectIntl(DetailedActivity);
