import {defineMessages} from 'react-intl';

const messages = defineMessages({
  ok: {
    id: 'NoteIconsAffectedGuidesDialog.confirmation',
    defaultMessage: 'Disable Step Note'
  },
  title: {
    id: 'NoteIconsAffectedGuidesDialog.title',
    defaultMessage: 'Disable Step Note'
  },
  caption: {
    id: 'NoteIconsAffectedGuidesDialog.caption',
    defaultMessage: 'You are about to disable this Step Note'
  },
  caption2: {
    id: 'NoteIconsAffectedGuidesDialog.caption2',
    defaultMessage: 'This will hide the Step Note in the following {n} Guides'
  }
});

export default messages;
