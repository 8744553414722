import styled from 'styled-components';

import Icon from 'ui-components/Icon';

import {commonPalette} from 'shared/styles/palette';

export const StyledIcon = styled(Icon).attrs({
  style: {width: 8}
})`
  ${({isHovering}) => (isHovering ? `color: ${commonPalette.sgGreen}; cursor: pointer;` : '')}
  margin-left: 5px;
`;

export const StyledLabel = styled.span`
  ${({isHovering}) => (isHovering ? `color: ${commonPalette.sgGreen}; cursor: pointer;` : '')}
  border-bottom: 1px solid ${({isHovering}) => (isHovering ? commonPalette.sgGreen : commonPalette.txBlack)};
`;

export const DateFilterWrapper = styled.div`
  position: relative;
`;
