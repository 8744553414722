import React from 'react';
import {observer} from 'mobx-react';
import Container from 'ui-components/Layout/Container';
import {injectIntl} from 'react-intl';

import SmartSkillsPageHeader from 'components/SmartSkillsPageHeader';
import Drawer from 'components/Drawer';
import AssessSkillDrawer from 'views/SmartSkillsAssessSkill';
import SmartSkillsUserSkillMatrix from 'components/SmartSkillsSkillMatrix/UserSkillMatrix';
import Spinner from 'shared/components/Spinner';
import SmartSkillsSausageBar from 'components/SmartSkillsSausageBar';
import {
  LevelsOverview,
  LevelsOverviewHeader,
  LevelsOverviewHeading,
  LevelsOverviewLegendsContainer,
  LevelsOverviewSausagesContainer,
  SausagesContainer
} from 'views/SmartSkillsUser/styles';

import {Table, Td, Th, Tr} from 'components/SmartSkillsSkillMatrix/styles';
import SmartSkillsLegendItem from 'components/SmartSkillsLegendItem';
import {capitalize} from 'lodash';
import {LEGEND_LEVEL_COLOR_MAP} from 'views/SmartSkillsTeams/constants';
import messages from './messages';
import {
  NO_LEVEL,
  useSmartSkillsUserOverview,
  useSmartSkillsUserQueries,
  useSmartSkillsUserRouter
} from './useSmartSkillsUser';

export const SmartSkillsUser = ({intl: {formatMessage}}) => {
  const {teamUserRolesQuery, userSkillMatrixQuery, skillLevelsQuery} = useSmartSkillsUserQueries();

  const {userId, skillId, handleCloseDrawer, handleSelectSkill} = useSmartSkillsUserRouter();

  const {data: {userSkillMatrix = []} = {}, loading: userSkillMatrixLoading} = userSkillMatrixQuery;
  const {data: {team} = {}, loading: teamUserRolesLoading} = teamUserRolesQuery;
  const {data: {skillLevels} = {}, loading: skillLevelsLoading} = skillLevelsQuery;

  const {totalApprovedSkills, totalGaps, levelsOverview, selectedSkill, teamUser} = useSmartSkillsUserOverview({
    userSkillMatrix,
    skillId,
    team,
    userId
  });

  if (userSkillMatrixLoading || teamUserRolesLoading || skillLevelsLoading) {
    return <Spinner centerAbs centerX centerY size={23} />;
  }

  return (
    <Container style={{paddingBottom: '50px', height: 'auto'}}>
      <SmartSkillsPageHeader title={teamUser?.user?.fullName} subtitle={teamUser?.skillRole?.name} showBackButton />

      <SausagesContainer>
        {totalApprovedSkills > 0 && (
          <SmartSkillsSausageBar
            color="#009D9A"
            label={formatMessage(messages.statsApproved)}
            value={totalApprovedSkills}
          />
        )}
        {totalGaps > 0 && (
          <SmartSkillsSausageBar color="#CE131C" label={formatMessage(messages.gap)} value={totalGaps} />
        )}
      </SausagesContainer>

      <SmartSkillsUserSkillMatrix
        userSkillMatrix={userSkillMatrix}
        skillLevels={skillLevels}
        onDrawerOpen={handleSelectSkill}
      />

      <LevelsOverview>
        <LevelsOverviewHeader>
          <LevelsOverviewHeading>{formatMessage(messages.levelsOverview)}</LevelsOverviewHeading>
          <LevelsOverviewLegendsContainer>
            {levelsOverview.map(({level}, index) => (
              <SmartSkillsLegendItem
                key={level}
                color={LEGEND_LEVEL_COLOR_MAP[index]}
                outline={level === NO_LEVEL}
                label={capitalize(level)}
              />
            ))}
          </LevelsOverviewLegendsContainer>
        </LevelsOverviewHeader>

        <Table>
          <thead>
            <Tr>
              <Th left width="30%">
                {formatMessage(messages.skills)}
              </Th>
              <Th left>{formatMessage(messages.levelDistribution)}</Th>
            </Tr>
          </thead>
          <tbody>
            <Tr>
              <Td align="left">{userSkillMatrix.length}</Td>
              <Td style={{paddingLeft: '8px'}}>
                <LevelsOverviewSausagesContainer>
                  {levelsOverview.map(({level, count}, index) => (
                    <SmartSkillsSausageBar
                      key={level}
                      color={LEGEND_LEVEL_COLOR_MAP[index]}
                      outline={level === NO_LEVEL}
                      value={count}
                    />
                  ))}
                </LevelsOverviewSausagesContainer>
              </Td>
            </Tr>
          </tbody>
        </Table>
      </LevelsOverview>

      <Drawer isOpen={!!selectedSkill} close={handleCloseDrawer} label={formatMessage(messages.assessSkill)}>
        <AssessSkillDrawer
          skill={selectedSkill}
          userId={userId}
          userName={teamUser?.user?.fullName}
          close={handleCloseDrawer}
        />
      </Drawer>
    </Container>
  );
};

export default injectIntl(observer(SmartSkillsUser));
