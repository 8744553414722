import PropTypes from 'prop-types';

const FilterEntry = PropTypes.exact({
  label: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired
});

const propTypes = {
  className: PropTypes.string,
  filterEntries: PropTypes.arrayOf(FilterEntry).isRequired,
  onReset: PropTypes.func.isRequired,
  intl: PropTypes.object
};

export default propTypes;
