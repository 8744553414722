import {capitalize} from 'lodash';
import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {LevelDropdownIcon, StatusIndicator} from './styles';
import {Menu, MenuItem, Popover} from 'components/Header/styles';
import {CardLevelNum, CardLevelUnstyled} from 'views/SmartSkillsAssessSkill/styles';
import mutationNames from 'api/skill-matrix/constants';
import notification from 'utils/notification-utils';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {SetSkillAssessmentLevel} from 'api/skill-matrix/mutations';
import {setSkillAssessmentLevelOptions} from 'api/skill-matrix/mutation-options';
import messages from './messages';
import Position from 'components/SmartSkillsPosition';

@inject('store')
@graphql(SetSkillAssessmentLevel, setSkillAssessmentLevelOptions)
class LevelsDropdown extends Component {
  runMutation = async updatedLevelId => {
    const {
      intl: {formatMessage},
      skill,
      store
    } = this.props;
    const {teamId, userId} = store.router.params;
    const setSkillAssessmentLevelMutation = this.props[mutationNames.SET_SKILL_ASSESSMENT_LEVEL];

    const response = await setSkillAssessmentLevelMutation({
      userId,
      teamId,
      skillId: skill.id,
      updatedLevelId
    });

    if (!response?.data?.setSkillAssessmentLevel) {
      // Unknown error
      notification.error(formatMessage(messages.levelSetError));
      return;
    }

    const {setSkillAssessmentLevel} = response.data;

    if (setSkillAssessmentLevel.result === true) {
      notification.success(formatMessage(messages.levelSetSuccess));
    } else {
      notification.error(formatMessage(messages.levelSetError));
    }
  };

  render() {
    const {
      intl: {formatMessage},
      skillLevels,
      skill,
      store
    } = this.props;
    const {isIPA} = store.auth.user;
    const position = <Position position={skill.currentLevel?.position} skillLevels={skillLevels} />;

    return (
      <>
        {!isIPA && (skill.status === 'IN_PROGRESS' || skill.status === null) ? (
          <Popover
            trigger="click"
            content={
              <Menu data-testid="levels-dropdown-menu">
                <div style={{padding: '10px 16px'}}>{formatMessage(messages.levelSetTitle)}</div>
                {skillLevels.map(level => (
                  <MenuItem
                    key={level.id}
                    onClick={() => this.runMutation(level.id)}
                    selected={level.id === skill.currentLevel?.id}
                    data-testid={`level-${level.id}`}
                  >
                    <CardLevelUnstyled>
                      <CardLevelNum>
                        <Position position={level.position} skillLevels={skillLevels} />
                      </CardLevelNum>
                      {capitalize(level.name)}
                    </CardLevelUnstyled>
                  </MenuItem>
                ))}
              </Menu>
            }
          >
            <div style={{display: 'inline-block', position: 'relative', cursor: 'pointer'}}>
              <StatusIndicator variant={'red'} data-testid="levels-dropdown-indicator">
                {position}
              </StatusIndicator>
              <LevelDropdownIcon data-testid="levels-dropdown-icon" />
            </div>
          </Popover>
        ) : (
          <StatusIndicator variant={skill.status === 'APPROVED' ? 'green' : 'red'}>{position}</StatusIndicator>
        )}
      </>
    );
  }
}

export default injectIntl(LevelsDropdown);
