import styled from 'styled-components';
import flex from 'styles/flex';
import $EnhancedIconComponent from '../../ui-components/Layout/EnhancedIcon';

export const Top = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  ${flex.vertical} margin-top: 20px;
`;

export const StyledEnhancedIcon = styled($EnhancedIconComponent)`
  top: -3px;
`;
