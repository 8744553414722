import {defineMessages} from 'react-intl';

const messages = defineMessages({
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  }
});

export default messages;
