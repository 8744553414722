import React, {Component} from 'react';

//helpers
import {getDisplayName} from 'shared/utils/decorator-utils';

/**
 * Wraps the contents of ".rdw-editor-toolbar" element
 * into a ".rdw-editor-toolbar-inner" custom one.
 */
export default WrappedComponent => {
  const isClassComponent = WrappedComponent.prototype && WrappedComponent.prototype.isReactComponent;

  const ParentClass = isClassComponent ? WrappedComponent : Component;

  return class extends ParentClass {
    static displayName = getDisplayName(WrappedComponent, 'PatchEditor');

    render() {
      const originalElement = isClassComponent ? super.render() : WrappedComponent(this.props);

      if (!originalElement) {
        return originalElement;
      }

      const children = React.Children.map(originalElement.props.children, child => {
        if (child.props.className === 'rdw-editor-toolbar') {
          return React.cloneElement(
            child,
            null,
            <div className="rdw-editor-toolbar-inner">
              {React.Children.map(child.props.children, child => (child ? React.cloneElement(child) : null))}
            </div>
          );
        }

        return child;
      });

      return React.cloneElement(originalElement, {...originalElement.props, children});
    }
  };
};
