import React, {Component} from 'react';
import {observer} from 'mobx-react';

//styles
import {SearchBar, Input, SearchIcon} from './styles';

@observer
class SearchBarComponent extends Component {
  state = {
    isFocused: false,
    isActive: false
  };

  UNSAFE_componentWillMount() {
    document.addEventListener('mousedown', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick = e => {
    const isFocused = e.target.name === 'searchTerm';

    this.setState({isFocused, isActive: isFocused});
  };

  handleOnMouseLeave = () => !this.state.isActive && this.state.isFocused && this.setState({isFocused: false});

  handleOnMouseEnter = () => !this.state.isFocused && this.setState({isFocused: true});

  render() {
    const {onClick, placeholder, width, ...forwardedProps} = this.props;
    const {isFocused} = this.state;

    return (
      <SearchBar
        data-cy="search"
        onClick={this.handleClick}
        onMouseEnter={this.handleOnMouseEnter}
        onMouseLeave={this.handleOnMouseLeave}
        isFocused={isFocused}
        width={width}
        {...forwardedProps}
      >
        <Input type="text" placeholder={placeholder} {...forwardedProps} />
        <SearchIcon onClick={onClick} />
      </SearchBar>
    );
  }
}

export default SearchBarComponent;
