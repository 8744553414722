import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {inject} from 'mobx-react';

//helpers
import {ensureDraftVersion} from 'utils/versioning-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {NewInstructionCard, PlusIcon, CardsWrapper, NewChecklistCard} from './styles';

//messages
import messages from './messages';

@inject('store')
export default class NewInstructionCardComponent extends Component {
  static propTypes = {
    hostRef: PropTypes.func
  };

  render() {
    const {
      hostRef,
      store: {
        platform: {hasChecklistsEnabled}
      }
    } = this.props;

    return (
      <CardsWrapper ref={hostRef}>
        <NewInstructionCard
          onClick={() => this.createEntity({isChecklist: false})}
          data-cy="new-instruction-placeholder"
        >
          <PlusIcon />
          <FormattedMessage {...messages.createInstruction} />
        </NewInstructionCard>
        {hasChecklistsEnabled && (
          <NewChecklistCard onClick={() => this.createEntity({isChecklist: true})} data-cy="new-checklist-placeholder">
            <PlusIcon />
            <FormattedMessage {...messages.createChecklist} />
          </NewChecklistCard>
        )}
      </CardsWrapper>
    );
  }

  createEntity = ensureDraftVersion(({isChecklist}) => {
    const {store, topic} = this.props;
    const {editInstructionPage: page} = store;

    page.startEditingNewInstruction({topicId: topic.id, isChecklist});
  }, this);
}
