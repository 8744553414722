import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import {CAMPAIGN_QUESTION_TYPE_ENUMS, CAMPAIGN_FOLLOW_UP_TYPE_ENUMS} from 'shared/enums';

const {STAR, TEXT, YES_NO, PASS_FAIL, NUMBER} = CAMPAIGN_QUESTION_TYPE_ENUMS;
const {TEXT: TEXT_FOLLOW_UP_ANSWER, TIME: TIME_FOLLOW_UP_ANSWER} = CAMPAIGN_FOLLOW_UP_TYPE_ENUMS;
export const EMPTY_ANSWER_SYMBOL = '-';
const STAR_CHAR_SYMBOL = '☆';

// General

const renderWithEmptyFallBack = value => {
  const isNotNonZeroFalsy = value === 0 || Boolean(value);
  const isNotEmptyObject = !(isObject(value) && isEmpty(value));
  const isNotEmptyArray = !(Array.isArray(value) && value.length === 0);
  const isNotBlankString = !(typeof value === 'string' && /^[\s\t\r\n]+$/.test(value));

  const answerIsPresent = [isNotNonZeroFalsy, isNotEmptyObject, isNotEmptyArray, isNotBlankString].every(Boolean);
  return answerIsPresent ? value : EMPTY_ANSWER_SYMBOL;
};

const addEmptyValueSymbol = value => {
  if (Array.isArray(value)) {
    return value.map(addEmptyValueSymbol);
  } else if (isObject(value)) {
    return mapValues(value, addEmptyValueSymbol);
  } else {
    return renderWithEmptyFallBack(value);
  }
};

// CampaignAnswer

const getBooleanRenderer =
  kind =>
  ({answer, translations}) => {
    const isValidAnswer = ['0', '1'].includes(answer);

    if (isValidAnswer) {
      const {campaign_action_no, campaign_action_yes, campaign_action_fail, campaign_action_pass} = translations;
      const {zeroLabel, oneLabel} = {
        [YES_NO]: {
          zeroLabel: campaign_action_no,
          oneLabel: campaign_action_yes
        },
        [PASS_FAIL]: {
          zeroLabel: campaign_action_fail,
          oneLabel: campaign_action_pass
        }
      }[kind];

      const answerToLabel = {
        0: zeroLabel,
        1: oneLabel
      };
      return answerToLabel[answer];
    }
  };

const renderYesNoAnswer = getBooleanRenderer(YES_NO);
const renderPassFailAnswer = getBooleanRenderer(PASS_FAIL);

const renderNumberAnswer = ({answer}) => {
  const numValue = typeof answer === 'number' ? answer : parseFloat(answer);
  const isValidNumber = !Number.isNaN(numValue);
  if (isValidNumber) {
    return `${numValue}%`;
  }
};

const renderTextAnswer = ({answer, campaign, locale}) => {
  const {question: {textTypeOptions = []} = {}, defaultLocale} = campaign;
  const numValue = parseInt(answer, 10);
  const isInRange = numValue => 1 <= numValue && numValue <= textTypeOptions.length;
  const isValidValue = !Number.isNaN(numValue) && isInRange(numValue);

  if (isValidValue) {
    const optionIndex = numValue - 1;
    const textTypeOption = textTypeOptions[optionIndex];
    return textTypeOption[locale] || textTypeOption[defaultLocale];
  }
};

const renderStarAnswer = ({answer}) => {
  const numValue = parseInt(answer, 10);
  const isValidValue = !Number.isNaN(numValue) && numValue > 0;

  if (isValidValue) {
    const range = [...Array(numValue).keys()];
    return range.map(() => STAR_CHAR_SYMBOL).join('');
  } else {
    return EMPTY_ANSWER_SYMBOL;
  }
};

const renderCampaignAnswerText = ({answer, campaign, locale, translations}) => {
  const {
    question: {kind}
  } = campaign;

  const getAnswerContent = () => {
    switch (kind) {
      case YES_NO:
        return renderYesNoAnswer({answer, translations});
      case PASS_FAIL:
        return renderPassFailAnswer({answer, translations});
      case NUMBER:
        return renderNumberAnswer({answer});
      case TEXT:
        return renderTextAnswer({answer, campaign, locale});
      case STAR:
        return renderStarAnswer({answer});
      default:
        return EMPTY_ANSWER_SYMBOL;
    }
  };

  return getAnswerContent() || EMPTY_ANSWER_SYMBOL;
};

// FollowUpAnswer

const renderTextFollowUpAnswer = ({followUpAnswer}) =>
  typeof followUpAnswer === 'string' && Boolean(followUpAnswer) ? followUpAnswer : EMPTY_ANSWER_SYMBOL;

const renderTimeFollowUpAnswer = ({followUpAnswer}) =>
  /[0-9]{2}:[0-9]{2}:[0-9]{2}/.test(followUpAnswer) ? followUpAnswer : EMPTY_ANSWER_SYMBOL;

const renderCampaignFolloUpAnswerText = ({followUpAnswer, followUpQuestion}) => {
  const kind = get(followUpQuestion, 'kind');

  const getAnswerContent = () => {
    switch (kind) {
      case TEXT_FOLLOW_UP_ANSWER:
        return renderTextFollowUpAnswer({followUpAnswer});
      case TIME_FOLLOW_UP_ANSWER:
        return renderTimeFollowUpAnswer({followUpAnswer});
      default:
        return EMPTY_ANSWER_SYMBOL;
    }
  };

  return getAnswerContent() || EMPTY_ANSWER_SYMBOL;
};

export {renderWithEmptyFallBack, addEmptyValueSymbol, renderCampaignAnswerText, renderCampaignFolloUpAnswerText};
