import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {toJS} from 'mobx';
import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';

// custom components
import Spinner from 'shared/components/Spinner';
import Select from 'ui-components/Select';
import FeedbacksPaginator from 'components/FeedbacksPaginator';
import Icon from 'ui-components/Layout/Icon';
import {FormattedMessage} from 'components/FormattedComponents';
import Container from 'ui-components/Layout/Container';
import {Icon as InfoIcon} from 'ui-components';

import {
  GridRow,
  Card,
  CardTitle,
  CardValue,
  StackSpace,
  CampaignTitle,
  CampaignQuestion,
  CampaignQuestionLabel
} from './styles';
import BarRatings from './BarRatings';
import BarRatingsHorizontal from './BarRatingsHorizontal';
import PieChartComp from './PieChartComp';
import CustomerFeedbackList from './CustomerFeedbackList';

//messages
import messages from './messages';

//data
import {CampaignReport, CampaignFeedback, Campaign} from 'api/campaign/queries';
import {campaignReportOptions, campaignFeedbackOptions, campaignOptions} from 'api/campaign/query-options';

import {getColorRange} from 'shared/utils/color-utils';

@inject('store')
@graphql(CampaignReport, campaignReportOptions)
@graphql(Campaign, {...campaignOptions, name: 'campaignQuery'})
@graphql(CampaignFeedback, campaignFeedbackOptions)
@observer
class CampaignReportComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store: {
        campaignsPage: {setCurrentCampaignReport, setCurrentCampaignFeedback, setSignOff}
      }
    } = this.props;
    const {campaignReportQuery, campaignFeedbackQuery, campaignQuery} = nextProps;

    if (campaignReportQuery && campaignReportQuery.campaignReport) {
      setCurrentCampaignReport(campaignReportQuery);
    }

    if (campaignFeedbackQuery) {
      setCurrentCampaignFeedback(nextProps.campaignFeedbackQuery);
    }

    if (campaignQuery.campaign) {
      setSignOff(campaignQuery.campaign);
    }
  }

  componentDidMount() {
    this.setActionMenu();
  }

  setActionMenu = () => {
    const {
      store: {app}
    } = this.props;

    const actionMenuItems = [
      {
        id: 'back',
        view: 'campaigns',
        secondary: true
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  shiftXAxis = currentCampaignReport => {
    return (
      (currentCampaignReport && currentCampaignReport.questionKind === CAMPAIGN_QUESTION_TYPE_ENUMS.STAR) ||
      currentCampaignReport.questionKind === CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT ||
      currentCampaignReport.questionKind === CAMPAIGN_QUESTION_TYPE_ENUMS.NUMBER
    );
  };

  getShiftedRatingsPerScore = currentCampaignReport => {
    if (!currentCampaignReport) {
      return [];
    }
    const ratingsPerScore = toJS(currentCampaignReport).ratingsPerScore;
    if (this.shiftXAxis(currentCampaignReport)) {
      ratingsPerScore.shift();
    }
    return ratingsPerScore;
  };

  getLabelsX = (currentCampaignReport, signOff, locale) => {
    const LabelIconSize = 14;

    if (!currentCampaignReport) {
      return null;
    }

    if (currentCampaignReport.questionKind == CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO) {
      return ['no', 'yes'];
    }

    if (currentCampaignReport.questionKind == CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL) {
      return ['fail', 'pass'];
    }

    if (currentCampaignReport.questionKind == CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT) {
      return signOff.question.textTypeOptions.map(question =>
        question[locale] ? question[locale] : question[signOff.defaultLocale]
      );
    }

    if (currentCampaignReport.questionKind == CAMPAIGN_QUESTION_TYPE_ENUMS.STAR) {
      return [...Array(currentCampaignReport.ratingsPerScore.length)].map((_, i) =>
        [...Array(i + 1)].map((_, i) => <Icon name="star" key={i} size={LabelIconSize} />)
      );
    }

    return null;
  };

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {
      campaignsPage,
      platform: {currentLanguage}
    } = store;
    const {
      currentCampaignReport,
      currentCampaignFeedback,
      previousFeedbackPage,
      nextFeedbackPage,
      showFeedbacksPagination,
      currentFeedbackPage,
      totalFeedbackPages,
      setFeedbackPage,
      signOff
    } = campaignsPage;

    if (!currentCampaignReport || !signOff) {
      return <Spinner center centerAbs />;
    }
    const ratingsPerScoreShifted = this.getShiftedRatingsPerScore(currentCampaignReport);
    const questionKind = currentCampaignReport.questionKind;

    const kindIsBinary =
      questionKind === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO || questionKind === CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL;

    const kindIsText = questionKind === CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT;

    const labelsX = this.getLabelsX(currentCampaignReport, signOff, currentLanguage);

    const colorRange = getColorRange(ratingsPerScoreShifted.length);
    const colorRangePercentage = getColorRange(10);

    const avgRatingColor = kindIsBinary
      ? colorRangePercentage[(currentCampaignReport.avgRating * 10).toFixed() - 1]
      : colorRange[currentCampaignReport.avgRating.toFixed() - 1];

    const signOffQuestion = signOff.question.questionTranslations[currentLanguage];

    return (
      <Container>
        <CampaignTitle>{currentCampaignReport.campaignTitle}</CampaignTitle>
        <CampaignQuestionLabel>
          <FormattedMessage {...messages.signOffQuestion} />
        </CampaignQuestionLabel>
        <CampaignQuestion>{signOffQuestion}</CampaignQuestion>
        <StackSpace />
        <Select
          allowClear
          optionFormatter="title"
          onChange={campaignsPage.setCurrentInstructionId}
          options={campaignsPage.instructionsList.slice()}
          placeholder={formatMessage(messages.allInstructions)}
          selectedValue={campaignsPage.currentInstructionId}
          style={{width: '100%', maxWidth: 420}}
        />
        <StackSpace />
        <GridRow>
          {!kindIsText && !kindIsBinary && (
            <Card>
              <CardTitle>
                <FormattedMessage {...messages.averageRating} />
              </CardTitle>
              <CardValue color={avgRatingColor}>{currentCampaignReport.avgRating.toFixed(2)}</CardValue>
            </Card>
          )}
          <Card>
            <CardTitle>
              <FormattedMessage {...messages.totalRatings} />
            </CardTitle>
            <CardValue>{currentCampaignReport.numOfRating}</CardValue>
          </Card>
          {kindIsBinary ? (
            <Card>
              <CardTitle>
                <FormattedMessage {...messages.scoresPerSentiment} />
              </CardTitle>
              <StackSpace />
              <PieChartComp data={toJS(currentCampaignReport.ratingsPerScore)} />
            </Card>
          ) : (
            <Card>
              <CardTitle>
                <FormattedMessage {...messages.ratingsPerScore} />
              </CardTitle>
              {kindIsText ? (
                <BarRatingsHorizontal
                  ratingsPerScore={ratingsPerScoreShifted}
                  colorRange={colorRange}
                  shiftXAxis={this.shiftXAxis(currentCampaignReport)}
                  labelsX={labelsX}
                />
              ) : (
                <BarRatings
                  ratingsPerScore={ratingsPerScoreShifted}
                  colorRange={colorRange}
                  shiftXAxis={this.shiftXAxis(currentCampaignReport)}
                  labelsX={labelsX}
                />
              )}
            </Card>
          )}
        </GridRow>
        <StackSpace />
        <Card>
          <CardTitle>
            <FormattedMessage {...messages.feedback} />
            <InfoIcon popoverContent={formatMessage(messages.infoForFeedback)} style={{marginLeft: 8}} />
            <StackSpace />
          </CardTitle>
          {currentCampaignFeedback && (
            <CustomerFeedbackList
              list={currentCampaignFeedback}
              shiftXAxis={this.shiftXAxis(currentCampaignReport)}
              colorRange={colorRange}
              labelsX={labelsX}
              isBinary={kindIsBinary}
            />
          )}
          {showFeedbacksPagination && (
            <FeedbacksPaginator
              prev={previousFeedbackPage}
              next={nextFeedbackPage}
              setCurrentPage={setFeedbackPage}
              currentPage={currentFeedbackPage}
              totalPages={totalFeedbackPages}
            />
          )}
        </Card>
        <StackSpace />
      </Container>
    );
  }
}

export default injectIntl(CampaignReportComponent);
