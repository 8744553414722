export const target = {
  drop: (props, monitor) => {
    const item = monitor.getItem();
    return {...item, createTopic: true};
  },
  hover: (props, monitor) => {
    const {store} = props;
    const {dragDropItems: page} = store;

    const item = monitor.getItem();
    const {sourcePosition, sourceTopicId, initialSourcePosition, initialSourceTopicId} = item;

    // Note: we're mutating the monitor item here! Generally it's better
    // to avoid mutations, but it's good here for the sake of performance
    // to avoid expensive index searches.
    item.sourcePosition = initialSourcePosition;
    item.sourceTopicId = initialSourceTopicId;

    // If we are hovering the new card placeholder let's
    // put back the instruction on the origin
    page.moveInstruction(sourceTopicId, sourcePosition, initialSourceTopicId, initialSourcePosition);

    // TODO: we might even want that the instruction doens't
    // show at all anywhere. Figure this out
  }
};
