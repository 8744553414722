import styled from 'styled-components';
import {colors} from 'views/SkillProfiles/styles';
import $InfiniteScroll from 'shared/components/InfiniteScroll';
import {commonPalette} from 'shared/styles/palette';

export const SearchBarContainer = styled.div`
  position: relative;
  margin-bottom: 10px;

  > input {
    border: 1px solid ${colors.backgroundGrey};
    border-radius: 2px;
    display: block;
    padding: 10px 16px 10px 44px;
    width: 100%;

    :focus {
      outline: none;
      border: 1px solid #298784;
    }
  }

  > svg {
    color: ${colors.iconGrey};
    height: 18px;
    position: absolute;
    left: 16px;
    top: 12px;
  }
`;

export const StyledInfiniteScroll = styled($InfiniteScroll)`
  margin-top: 30px;
`;

export const FixedContainer = styled.div`
  position: sticky;
  top: -20px;
  padding-top: 5px;
  background: var(--surface-default, ${commonPalette.white});
  width: 500px;
`;

export const SpinnerWrapper = styled.div`
  text-align: center;
  margin-bottom: ${props => (props.hasSkills ? '20px' : '')};
  margin-top: ${props => (!props.hasSkills ? '160px' : '')};
`;

export const ErrorWrapper = styled.div`
  text-align: center;
`;

export const ListContainer = styled.div`
  background: ${commonPalette.lightGrey};
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  max-width: 600px;
  margin-bottom: 18px;
`;
