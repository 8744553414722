import {TeamSkillMatrix, UserSkillMatrix} from 'api/skill-matrix/queries';

export const refreshUserSkillMatrix = (teamId, userId) => {
  return {
    query: UserSkillMatrix,
    variables: {
      teamId,
      userId
    }
  };
};

export const refreshTeamSkillMatrix = teamId => {
  return {
    query: TeamSkillMatrix,
    variables: {
      teamId
    }
  };
};
