import gql from 'graphql-tag';

export const CreateSkillTask = gql`
  mutation createSkillTask($newSkillTask: SkillTaskCreate) {
    createSkillTask(newSkillTask: $newSkillTask) {
      title
    }
  }
`;

export const UpdateSkillTask = gql`
  mutation updateSkillTask($skillTask: SkillTaskUpdate) {
    updateSkillTask(skillTask: $skillTask) {
      title
    }
  }
`;
