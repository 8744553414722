import styled from 'styled-components';
import {Radio as $Radio, Select as $Select, TreeSelect as $TreeSelect} from 'antd';

export const Select = styled($Select)`
  &.ant-select {
    color: #000;
    display: block;
    font-size: 14px;
  }

  .ant-select-selection {
    border-color: #e2e2e2;

    ${p =>
      p.borderRadius !== undefined &&
      `
      border-radius: ${p.borderRadius}px !important;
    `}

    ${p =>
      p.height !== undefined &&
      `
      min-height: ${p.height}px !important;
    `}
  }

  .ant-select-selection__rendered {
    font-size: inherit;
    margin-left: 18px;
    margin-right: 18px;

    ${p =>
      p.height !== undefined &&
      `
      height: ${p.height - 2}px;
      line-height: ${p.height - 2}px !important;
    `}
  }

  .ant-select-selection-selected-value,
  .ant-select-selection__placeholder,
  .ant-select-tree-title {
    font-size: inherit;
  }

  .ant-select-arrow {
    color: #7f7f7f;
    font-size: 14px !important;
    margin-top: -7px;
    right: 11px;
    transform: none;

    &::before {
      content: '\f078';
      font-family: FontAwesome !important;
    }
  }
`;

export const TreeSelect = Select.withComponent($TreeSelect);

export const SelectOption = $Select.Option;

export const Toggle = styled($Radio.Group)`
  &.ant-radio-group {
    display: block;
    margin-left: 14px;
  }
`;

export const ToggleOption = styled($Radio.Button)`
  &.ant-radio-button-wrapper {
    background: #fff !important;
    border-color: #e2e2e2 !important;
    color: #b2b2b2 !important;
    height: ${p => p.height}px;
    line-height: ${p => p.height - 2}px;
    padding: 0;
    text-align: center;
    width: ${p => p.height}px;

    &:first-child {
      border-radius: ${p => p.borderRadius}px 0 0 ${p => p.borderRadius}px;
    }

    &:last-child {
      border-radius: 0 ${p => p.borderRadius}px ${p => p.borderRadius}px 0;
    }
  }

  &.ant-radio-button-wrapper-checked {
    background: #f5f5f5 !important;
    box-shadow: none !important;
    color: #494949 !important;

    &:not(:first-child)::before {
      display: none;
    }
  }
`;

Select.defaultProps =
  TreeSelect.defaultProps =
  ToggleOption.defaultProps =
    {
      borderRadius: 3,
      height: 35
    };

export const Radio = styled($Radio)`
  &.ant-radio-wrapper {
    font-size: 14px;
    line-height: 1;
    margin-right: 18px;
  }

  .ant-radio {
    vertical-align: -1px;
  }

  .ant-radio-inner {
    background: none;
    border-color: #4d4d4d !important;
    border-radius: 50%;
    height: 12px;
    width: 12px;

    &::after {
      background: #4d4d4d;
      left: 2px;
      top: 2px;
    }
  }
`;
