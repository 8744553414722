import gql from 'graphql-tag';

export const DispatchSites = gql`
  query dispatchSites {
    dispatchSites {
      results {
        id
        site
        description
      }
    }
  }
`;
