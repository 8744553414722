import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import moment from 'moment';

// lodash helpers
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import orderBy from 'lodash/orderBy';

import {ChecklistsLite, ChecklistAnswers, ChecklistAnswerOldest} from 'api/checklists/queries';
import {AllGuidesLite} from 'api/guide/queries';
import {TeamsLite} from 'api/team/queries';
import {DomainsIncludingArchived} from 'api/domain/queries';
import {
  checklistsLiteOptions,
  checklistAnswersOptions,
  oldestChecklistAnswerOptions
} from 'api/checklists/query-options';
import {guidesLiteForChecklistsOptions} from 'api/guide/query-options';
import {domainsIncludingArchivedOptions} from 'api/domain/query-options';
import {teamsLiteOptions} from 'api/team/query-options';

import LoadingMessage from 'components/LoadingMessage';
import Select from 'ui-components/Select';
import Container from 'ui-components/Layout/Container';

import {DATE_TIME_FORMAT} from 'config/constants';
import views from 'config/views';

import messages from './messages';
import {HeaderWrapper} from 'ui-components/Layout/DataTable/styles';
import {sortAlphabetically, sortAlphabeticallyByTitle} from 'ui-components/Layout/DataTable/sorters';
import {
  StyledTable,
  FiltersWrapper,
  StyledCombobox,
  LoadingWrapper,
  ChecklistAnswersWrapper,
  CellWrapper
} from './styles';

import {displayChecklistScore} from 'utils/string-utils';
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

const defaultDateFilterKeys = ['today', 'yesterday', 'last7days', 'last30days'];

const getFilterOptions = (queryResult = []) =>
  orderBy(
    queryResult
      .filter(({title, name}) => typeof (title || name) === 'string')
      .map(({id, title, name}) => ({id, name: title || name})),
    ({name}) => name.toLowerCase(),
    ['asc']
  );

@inject('store')
@graphql(ChecklistsLite, checklistsLiteOptions)
@graphql(ChecklistAnswers, checklistAnswersOptions)
@graphql(ChecklistAnswerOldest, oldestChecklistAnswerOptions)
@graphql(AllGuidesLite, guidesLiteForChecklistsOptions)
@graphql(DomainsIncludingArchived, domainsIncludingArchivedOptions)
@graphql(TeamsLite, teamsLiteOptions)
@observer
class ChecklistsAnswersResults extends Component {
  state = {
    defaultSorted: [
      {
        id: 'date',
        asc: true
      }
    ]
  };

  componentDidMount = async () => {
    const {
      intl: {formatMessage},
      store: {
        checklistResultsPage: {setTranslations, setDefaultFilters}
      }
    } = this.props;

    const translations = mapValues(
      pick(messages, ['checklistExportTriggerSuccess', 'checklistExportTriggerFailure']),
      message => formatMessage(message)
    );

    const defaultFilters = mapValues(pick(messages, defaultDateFilterKeys), message => formatMessage(message));

    setTranslations(translations);
    setDefaultFilters(defaultFilters);
  };

  componentWillUnmount() {
    const {dispose} = this.props.store.checklistResultsPage;
    dispose();
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    const {
      checklistAnswersQuery,
      oldestChecklistAnswerQuery,
      store: {
        checklistsPage: {setChecklistAnswers},
        checklistResultsPage: {setOldestDateEntry}
      }
    } = nextProps;

    if (oldestChecklistAnswerQuery.oldestChecklistAnswer) {
      setOldestDateEntry(oldestChecklistAnswerQuery.oldestChecklistAnswer);
    }

    if (checklistAnswersQuery.checklistAnswers) {
      setChecklistAnswers(checklistAnswersQuery.checklistAnswers);
    }
  };

  render() {
    const {
      intl,
      store,
      checklistsLiteQuery,
      checklistAnswersQuery,
      oldestChecklistAnswerQuery,
      guidesListLiteQuery,
      domainsIncludingArchivedQuery,
      teamsQuery
    } = this.props;
    const {loading: loadingChecklistsLite, error: errorChecklistsLite, checklistsLite} = checklistsLiteQuery;
    const {loading: loadingChecklistAnswers, error: errorChecklistAnswers} = checklistAnswersQuery;
    const {loading: loadingChecklistAnswerOldest, error: errorChecklistAnswerOldest} = oldestChecklistAnswerQuery;
    const {loading: loadingGuidesLite, error: errorGuidesLite, guidesForCms} = guidesListLiteQuery;
    const {
      loading: loadingDomainsLite,
      error: errorDomainsLite,
      domainsIncludingArchived
    } = domainsIncludingArchivedQuery;
    const {loading: loadingTeamsLite, error: errorTeamsLite, teams} = teamsQuery;
    const {formatMessage} = intl;
    const {checklistsPage, router, checklistResultsPage} = store;

    const {checklistAnswers, filteredChecklistAnswers, searchTerm} = checklistsPage;
    const {
      setDateFilterId,
      setChecklistFilterIds,
      setGuideFilterIds,
      setWorkspaceFilterIds,
      setTeamFilterIds,
      generatedDateFilters,
      dateFilterId,
      checklistFilterIds,
      guideFilterIds,
      workspaceFilterIds,
      teamFilterIds
    } = checklistResultsPage;

    if (loadingChecklistAnswerOldest) {
      return (
        <LoadingWrapper>
          <LoadingMessage>{formatMessage(messages.loading)}</LoadingMessage>
        </LoadingWrapper>
      );
    }

    if (
      errorChecklistAnswers ||
      errorChecklistAnswerOldest ||
      errorGuidesLite ||
      errorChecklistsLite ||
      errorDomainsLite ||
      errorTeamsLite
    ) {
      return <Container>{intl.formatMessage(messages.errorChecklists)}</Container>;
    }

    const noDataMessage = formatMessage(
      checklistAnswers.length === 0 ? messages.noChecklistsSubmitted : messages.noFilteredChecklists,
      {searchTerm}
    );

    const columns = [
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.submittedName)}</HeaderWrapper>,
        accessor: 'user',
        resizable: true,
        width: 220,
        sortMethod: sortAlphabetically,
        Cell: ({original}) => (
          <CellWrapper paddingLeft title={original.user}>
            {original.user}
          </CellWrapper>
        )
      },
      {
        Header: formatMessage(messages.dateAndTime),
        accessor: 'date',
        resizable: true,
        width: 180,
        Cell: ({original}) => <CellWrapper>{moment(original.submittedAt).format(DATE_TIME_FORMAT)}</CellWrapper>
      },
      {
        Header: formatMessage(messages.checklist),
        accessor: 'instruction',
        resizable: true,
        width: 220,
        sortMethod: sortAlphabeticallyByTitle,
        Cell: ({original}) => <CellWrapper title={original.instruction.title}>{original.instruction.title}</CellWrapper>
      },
      {
        Header: formatMessage(messages.guide),
        accessor: 'guide',
        resizable: true,
        width: 220,
        sortMethod: sortAlphabeticallyByTitle,
        Cell: ({original}) => <CellWrapper title={original.guide.title}>{original.guide.title}</CellWrapper>
      },
      {
        Header: formatMessage(messages.score),
        accessor: 'score',
        width: 100,
        Cell: ({original}) => (
          <CellWrapper>
            {displayChecklistScore(original.instruction?.checklistTemplate?.type, original.score)}
          </CellWrapper>
        )
      }
    ];

    const renderChecklistFilter = !loadingChecklistsLite;

    const checklistFilter = renderChecklistFilter && {
      label: `${formatMessage(messages.checklist)}:`,
      component: () => (
        <StyledCombobox
          dataTestId={'checklist-picker'}
          value={toJS(checklistFilterIds) || []}
          valueAccessor={checklist => checklist.id}
          labelAccessor={checklist => checklist.name}
          options={getFilterOptions(checklistsLite || [])}
          title={formatMessage(messages.filterByChecklist)}
          onChange={selectedChecklistFilterIds => {
            setChecklistFilterIds(selectedChecklistFilterIds);

            trackEvent(EVENT_TYPES.CHECKLIST_CHECKLIST_FILTER);
          }}
        />
      )
    };

    const renderGuideFilter = !loadingGuidesLite;
    const guideFilter = renderGuideFilter && {
      label: `${formatMessage(messages.guide)}:`,
      component: () => (
        <StyledCombobox
          dataTestId={'guide-picker'}
          value={toJS(guideFilterIds) || []}
          valueAccessor={guide => guide.id}
          labelAccessor={guide => guide.name}
          options={getFilterOptions(guidesForCms || [])}
          title={formatMessage(messages.filterByGuide)}
          onChange={selectedGuideFilterIds => {
            setGuideFilterIds(selectedGuideFilterIds);

            trackEvent(EVENT_TYPES.CHECKLIST_GUIDE_FILTER);
          }}
        />
      )
    };

    const renderWorkspaceFilter = !loadingDomainsLite;
    const workspaceFilter = renderWorkspaceFilter && {
      label: `${formatMessage(messages.workspace)}:`,
      component: () => (
        <StyledCombobox
          dataTestId={'workspace-picker'}
          value={toJS(workspaceFilterIds) || []}
          valueAccessor={domain => domain.id}
          labelAccessor={domain => domain.name}
          options={getFilterOptions(domainsIncludingArchived || [])}
          title={formatMessage(messages.filterByWorkspace)}
          onChange={selectedWorkspaceFilterIds => {
            setWorkspaceFilterIds(selectedWorkspaceFilterIds);

            trackEvent(EVENT_TYPES.CHECKLIST_WORKSPACE_FILTER);
          }}
        />
      )
    };

    const renderTeamFilter = !loadingTeamsLite;
    const teamFilter = renderTeamFilter && {
      label: `${formatMessage(messages.team)}:`,
      component: () => (
        <StyledCombobox
          dataTestId={'team-picker'}
          value={toJS(teamFilterIds) || []}
          valueAccessor={team => team.id}
          labelAccessor={team => team.name}
          options={getFilterOptions(teams || [])}
          title={formatMessage(messages.filterByTeam)}
          onChange={selectedTeamFilterIds => {
            setTeamFilterIds(selectedTeamFilterIds);

            trackEvent(EVENT_TYPES.CHECKLIST_TEAM_FILTER);
          }}
        />
      )
    };

    const checklistFilterSegment = renderChecklistFilter ? [checklistFilter] : [];
    const guideFilterSegment = renderGuideFilter ? [guideFilter] : [];
    const workspaceFilterSegment = renderWorkspaceFilter ? [workspaceFilter] : [];
    const teamFilterSegment = renderTeamFilter ? [teamFilter] : [];

    const filterEntries = [
      {
        label: `${formatMessage(messages.date)}: `,
        component: () => (
          <Select
            testId={'month-date-picker'}
            options={generatedDateFilters}
            selectedValue={dateFilterId}
            onChange={selectedValueId => {
              setDateFilterId(selectedValueId);

              trackEvent(EVENT_TYPES.CHECKLIST_DATE_FILTER);
            }}
            underlined={true}
          />
        )
      },
      ...checklistFilterSegment,
      ...guideFilterSegment,
      ...workspaceFilterSegment,
      ...teamFilterSegment
    ];

    return (
      <ChecklistAnswersWrapper>
        {
          <FiltersWrapper
            filterEntries={filterEntries}
            onReset={() => {
              checklistResultsPage.resetFilters();
            }}
          />
        }
        {loadingChecklistAnswers ? (
          <LoadingWrapper>
            <LoadingMessage>{formatMessage(messages.loading)}</LoadingMessage>
          </LoadingWrapper>
        ) : (
          <StyledTable
            data={filteredChecklistAnswers}
            paddingRows={5}
            columns={columns}
            noDataMessage={noDataMessage}
            defaultSorted={this.state.defaultSorted}
            onSortedChange={sorts => this.setState({defaultSorted: sorts})}
            hoverEffect
            getTrProps={(_, {original: {id}}) => ({
              onClick: () => router.goTo(views.viewChecklist, {id})
            })}
          />
        )}
      </ChecklistAnswersWrapper>
    );
  }
}

export default injectIntl(ChecklistsAnswersResults);
