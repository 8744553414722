import React from 'react';
import {inject, observer} from 'mobx-react';
import {InjectedIntl, injectIntl} from 'react-intl';
import type {Store} from 'stores/store';
import messages from './messages';
import InfoBox from 'ui-components/InfoBox';

type EditGuideBannerProps = {intl: InjectedIntl} & Partial<Store>;

const EditGuideBanner = ({store, intl: {formatMessage}}: EditGuideBannerProps) => {
  if (!store) {
    return null;
  }

  const {
    editGuidePage: {
      isLastestVersion,
      isViewingDraft,
      isViewingLiveVersionUnderApproval,
      isViewingExpiredLiveVersion,
      isViewingRejectedLiveVersion,
      isViewingUnderApprovalVersion,
      backToDraftVersion,
      currentVersion: version
    }
  } = store;
  if (isViewingDraft) {
    return null;
  }

  const baseProps = {
    type: 'informational' as const,
    backToDraftContent: formatMessage(messages.backToDraft),
    backToDraftFunc: backToDraftVersion
  };

  if (isViewingUnderApprovalVersion || isViewingLiveVersionUnderApproval) {
    return <InfoBox {...baseProps} content={formatMessage(messages.viewVersionUnderApproval, {version})} />;
  }

  if (isViewingRejectedLiveVersion) {
    return (
      <InfoBox {...baseProps} type={'error'} content={formatMessage(messages.viewRejectedLiveVersion, {version})} />
    );
  }

  if (isViewingExpiredLiveVersion) {
    return (
      <InfoBox {...baseProps} type={'warning'} content={formatMessage(messages.viewExpiredLiveVersion, {version})} />
    );
  }

  if (isLastestVersion) {
    return <InfoBox {...baseProps} content={formatMessage(messages.viewLiveVersion, {version})} />;
  }

  return <InfoBox {...baseProps} content={formatMessage(messages.viewVersion, {version})} />;
};

export default injectIntl(inject('store')(observer(EditGuideBanner)));
