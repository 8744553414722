import {defineMessages} from 'react-intl';

const messages = defineMessages({
  signOffDuplicatedSucces: {
    id: 'SignOff.duplicatedSucces',
    defaultMessage: 'Sign-off has been duplicated.'
  },
  signOffDuplicatedError: {
    id: 'SignOff.duplicatedError',
    defaultMessage: 'There was an error while trying to duplicate the Sign-off.'
  },
  activate: {
    defaultMessage: 'Activate',
    id: 'common.actions.activate'
  },
  viewResults: {
    id: 'viewResults',
    defaultMessage: 'View results'
  },
  active: {
    id: 'signOff.active',
    defaultMessage: 'Active'
  },
  deactivated: {
    id: 'signOff.deactivated',
    defaultMessage: 'Deactivated'
  },
  draft: {
    id: 'draft',
    defaultMessage: 'Draft'
  },
  instructionCount: {
    id: 'EditGuide.instructionCount',
    defaultMessage: '{count, number} {count, plural, one {instruction} other {instructions}}'
  }
});

export default messages;
