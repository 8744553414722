import React, {Component} from 'react';
import {graphql} from 'utils/observer-graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import get from 'lodash/get';

//queries
import {GetGuide} from 'api/guide/queries';

//mutations
import {
  UpdateGuide,
  ArchiveGuide,
  PublishGuide,
  SetGuideToDraft,
  RequestGuideApproval,
  WithdrawGuideApproval,
  SubmitGuideApprovalReview,
  SubmitLiveVersionReview
} from 'api/guide/mutations';

//options
import {getGuideOptions} from 'api/guide/query-options';
import {
  updateGuide,
  archiveGuide,
  publishGuide,
  setGuideToDraft,
  requestGuideApprovalOptions,
  withdrawGuideApprovalOptions,
  submitGuideApprovalReviewOptions,
  submitLiveVersionReviewOptions
} from 'api/guide/mutation-options';

//helpers
import views from 'config/views';
import {INSTRUCTION_TYPE} from 'shared/enums';
import {SHARE_DIALOG_TYPE} from 'config/enums';
import {ensureDraftVersion} from 'utils/versioning-utils';
import {getShowIntercom} from 'utils/intercom-utils';
import {setLanguagesForPdfExport} from 'components/ExportToPdfDialog/Pdf/utils';

//components
import GuideDetails from 'components/GuideDetails';
import TopicList from 'components/TopicList';
import LoadingMessage from 'components/LoadingMessage';
import NotFoundMessage from 'components/NotFoundMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import MaxEditorsErrorNotificationContent from 'components/MaxEditorsErrorNotificationContent';

//styles
import {EditGuide} from './styles';

//messages
import messages from './messages';

// event tracking
import {EVENT_TYPES} from 'api/tracking/constants';
import {trackEvent} from 'utils/tracking/event-tracking';
import EditGuideBanner from './EditGuideBanner';
import ScrollToRouteAnchor from 'components/ScrollToAnchor';

@inject('store')
@graphql(GetGuide, getGuideOptions)
@graphql(UpdateGuide, updateGuide)
@graphql(ArchiveGuide, archiveGuide)
@graphql(PublishGuide, publishGuide)
@graphql(SubmitGuideApprovalReview, submitGuideApprovalReviewOptions)
@graphql(SubmitLiveVersionReview, submitLiveVersionReviewOptions)
@graphql(SetGuideToDraft, setGuideToDraft)
@graphql(RequestGuideApproval, requestGuideApprovalOptions)
@graphql(WithdrawGuideApproval, withdrawGuideApprovalOptions)
@observer
class EditGuideViewComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {data, store} = nextProps;
    const {guideForCms: guide, loading, refetch} = data;
    const {editGuidePage, saveAsDraftDialog, platform} = store;
    const {isViewingUnderApprovalVersion, isViewingLiveVersionUnderApproval} = editGuidePage;
    const isGuideReady = Boolean(!loading && guide);
    const isActivityLogOpened = store.router.queryParams?.activityLog;
    if (isActivityLogOpened && !loading) {
      store.versionHistoryDrawer.open({
        guideId: guide.id,
        guideTitle: guide.title,
        draftVersionOid: guide.draftVersionOid,
        reviewingVersionOid: guide.reviewingVersionOid
      });
    }
    if (isGuideReady) {
      const canViewUnderApprovalVersion =
        platform.guideApproval && guide && guide.ownedBy.guideApproval && guide.isUnderApproval;
      const canViewLiveVersionUnderApproval = guide?.isLiveVersionExpired;
      const versionId = store.router.queryParams?.v;
      if (
        (isViewingUnderApprovalVersion && !canViewUnderApprovalVersion) ||
        (isViewingLiveVersionUnderApproval && !canViewLiveVersionUnderApproval)
      ) {
        store.router.goTo(views.editGuide, {id: guide.id});
      }
      editGuidePage.setForm(guide);
      editGuidePage.setRefetchQuery(async params => {
        const response = await refetch(params);
        // TODO: This looks like a hack which reduces
        // the maintainability of our code.
        // Can't we do this in some other way?
        const newGuide = response.data.guideForCms;
        this.setActionMenu(newGuide);
      });
      saveAsDraftDialog.setCurrentVersionId(versionId);
      this.setActionMenu(guide);
    } else {
      editGuidePage.reset();
    }

    if (!loading) {
      editGuidePage.setLoading(false);
    }
  }

  unpublish = () => {
    const {
      store,
      data: {guideForCms: guide},
      intl,
      setGuideToDraftMutation
    } = this.props;
    const {formatMessage} = intl;
    const {publishGuideDialog} = store;

    const translations = {
      success: formatMessage(messages.success),
      unknownFailure: formatMessage(messages.unknownFailure),
      guideUnpublishedMessage: formatMessage(messages.guideUnpublishedMessage)
    };

    publishGuideDialog.open({
      unpublishMutation: setGuideToDraftMutation,
      guide,
      translations
    });

    trackEvent(EVENT_TYPES.UNPUBLISH_GUIDE, {guide: guide.id});
  };

  /**
   * After archive move to the overview
   */
  onArchive = () => {
    const {store} = this.props;
    const {router} = store;

    router.goTo(views.guides, null);
  };

  archiveGuide = () => {
    const {
      data: {guideForCms: guide},
      intl,
      store,
      archiveGuideMutation
    } = this.props;
    const {formatMessage} = intl;
    const {id, title} = guide;
    const {archiveDialog: dialog} = store;

    const translations = {
      action: formatMessage(messages.archiveGuide),
      confirmation: formatMessage(messages.archiveGuideConfirm, {title}),
      archiveSuccess: formatMessage(messages.archiveGuideSuccess),
      archiveFailure: formatMessage(messages.archiveGuideFailure)
    };

    dialog.open({
      params: {id},
      mutation: archiveGuideMutation,
      onArchive: this.onArchive,
      title,
      translations
    });
  };

  exportToPdf = async () => {
    const {
      data: {
        guideForCms: {id}
      },
      store
    } = this.props;
    const {
      exportToPdfDialog: {open, loadingPdf, setError, loadingAvailableLanguages, setLoadingAvailableLanguages}
    } = store;
    if (loadingAvailableLanguages || loadingPdf) {
      setError(true);

      open({
        params: {loading: true}
      });

      return;
    }

    try {
      setLoadingAvailableLanguages(true);
      open({
        params: {loadingAvailableLanguages: true, loading: true}
      });
      await setLanguagesForPdfExport(store);
    } catch (error) {
      setError(error);
    } finally {
      setLoadingAvailableLanguages(false);
    }

    open({
      params: {id}
    });

    trackEvent(EVENT_TYPES.EXPORT_GUIDE_PDF, {guide: id});
  };

  share = () => {
    const {
      intl,
      store,
      data: {guideForCms: guide}
    } = this.props;
    const {formatMessage} = intl;
    const {shareDialog: dialog} = store;
    const {id, slug, title} = guide;

    dialog.open({
      itemTitle: title,
      path: `guide/${id}`,
      qrBasename: slug,
      title: formatMessage(messages.shareGuide),
      type: SHARE_DIALOG_TYPE.GUIDE
    });

    trackEvent(EVENT_TYPES.SHARE_GUIDE, {guide: id, guideTitle: title});
  };

  modify = modificationFn => {
    const {
      guideForCms: guide,
      guideForCms: {
        id,
        ownedBy: {id: teamId}
      }
    } = this.props.data;

    modificationFn({id, teamId, guide});
  };

  feedback = () => {
    const {
      store: {guideFeedbackDialog},
      data: {guideForCms: guide}
    } = this.props;
    const {id, title} = guide;

    guideFeedbackDialog.open({
      guideId: id,
      guideTitle: title
    });

    trackEvent(EVENT_TYPES.VIEW_GUIDE_FEEDBACK, {guide: id, guideTitle: title});
  };

  versionHistory = () => {
    const {
      store: {versionHistoryDrawer},
      data: {guideForCms: guide}
    } = this.props;
    const {id, title, draftVersionOid, reviewingVersionOid} = guide;
    versionHistoryDrawer.open({
      guideId: id,
      guideTitle: title,
      draftVersionOid,
      reviewingVersionOid
    });

    trackEvent(EVENT_TYPES.VIEW_ACTIVITY_LOG, {
      guide: id,
      guideTitle: title
    });
  };

  reassignGuide = ensureDraftVersion(() => {
    const {
      store: {reassignGuideDialog},
      data: {guideForCms: guide}
    } = this.props;

    const {id, ownedBy} = guide;

    reassignGuideDialog.open({
      guideId: id,
      teamId: ownedBy.id
    });

    trackEvent(EVENT_TYPES.REASSIGN_GUIDE, {
      guide: id,
      team: ownedBy.id,
      ownedBy
    });
  }, this);

  applySignOff = ({guide}) => {
    const {
      store: {applyCampaignDialog}
    } = this.props;

    const instructions = [];

    guide.topics.forEach(topic => {
      topic.instructions.forEach(instruction => {
        if (instruction.type !== INSTRUCTION_TYPE.CHECKLIST) {
          instructions.push(instruction);
        }
      });
    });

    applyCampaignDialog.open(instructions);

    trackEvent(EVENT_TYPES.APPLY_SIGNOFF_GUIDE, {guide: guide.id});
  };

  translate = () => {
    const {
      store: {autoTranslationDialog, platform}
    } = this.props;

    if (platform.canUseAutoTranslations) {
      autoTranslationDialog.open();
    }

    trackEvent(EVENT_TYPES.GUIDE_TRANSLATION_INITIATED, {canUseAutoTranslation: platform.canUseAutoTranslations});
  };

  setActionMenu = guide => {
    const {
      store: {
        app,
        router,
        auth: {
          user: {canManageCampaigns}
        },
        editGuidePage: {isLastestVersion},
        platform: {canExportToPdf, canUseAutoTranslations},
        autoTranslationDialog
      }
    } = this.props;

    const isGuidePublished = get(guide, 'publishStatus') === 'PUBLISHED';
    const isDraftVersion = !get(router, 'queryParams.v', false);
    const canTranslate = get(guide, 'canTranslate') && isDraftVersion;
    const canReassign = get(guide, 'canReassign');
    const canShare = get(guide, 'canShare');
    const isOnDraftOrLatestVersion = isDraftVersion || (isGuidePublished && isLastestVersion);

    let canApplySignOff = false;
    guide.topics.forEach(topic => {
      topic.instructions.forEach(instruction => {
        if (instruction.type !== INSTRUCTION_TYPE.CHECKLIST) {
          canApplySignOff = canManageCampaigns;
        }
      });
    });

    const actionMenuItems = [
      {
        id: 'share',
        onClick: this.share,
        disabled: !isOnDraftOrLatestVersion || !canShare
      },
      {
        id: 'reassign',
        disabled: !canReassign || !isDraftVersion,
        onClick: () => this.modify(this.reassignGuide, guide)
      },
      {
        id: 'activity',
        onClick: () => this.modify(this.versionHistory, guide)
      },
      {
        id: 'applySignOff',
        disabled: !canApplySignOff,
        onClick: () => this.modify(this.applySignOff, guide)
      },
      {
        id: 'feedback',
        disabled: !isOnDraftOrLatestVersion,
        onClick: () => this.modify(this.feedback, guide)
      },
      {
        id: 'translate',
        view: canUseAutoTranslations ? '' : 'translateGuide',
        disabled: !canTranslate || !isDraftVersion,
        params: canUseAutoTranslations ? null : {id: guide.id},
        onClick: () => {
          autoTranslationDialog.setDefaultLocale(guide.defaultLocale);
          autoTranslationDialog.setGuideId(guide.id);
          this.translate();
        }
      },
      {
        id: 'exportToPdf',
        disabled: !canExportToPdf || !isGuidePublished || !isOnDraftOrLatestVersion,
        onClick: this.exportToPdf
      }
    ].filter(obj => obj.id);

    app.setActionMenuItems(actionMenuItems);
  };

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  render() {
    const {
      intl: {formatMessage},
      store: {
        editGuidePage,
        platform,
        app: {isActionMenuOpen}
      },
      data,
      updateGuideMutation,
      publishGuideMutation,
      requestGuideApprovalMutation,
      withdrawGuideApprovalMutation,
      submitGuideApprovalReviewMutation,
      submitLiveVersionReviewMutation
    } = this.props;
    const {error, guideForCms} = data;
    const {loading} = editGuidePage;

    const publishGuideFailureMaxPublishedGuides = (
      <MaxEditorsErrorNotificationContent
        showIntercom={getShowIntercom({platform, isActionMenuOpen})}
        formattedMessages={{
          contactSupport: formatMessage(messages.contactSupport),
          publishGuideFailureMaxPublishedGuides: formatMessage(messages.publishGuideFailureMaxPublishedGuides),
          chatMessage: formatMessage(messages.chatMessage)
        }}
      />
    );

    const translations = {
      success: formatMessage(messages.success),
      successMessage: formatMessage(messages.successMessage),
      error: formatMessage(messages.error),
      unknownFailure: formatMessage(messages.unknownFailure),
      guidePublishedMessage: formatMessage(messages.guidePublishedMessage),
      guidePublishedDocumentsMessage: formatMessage(messages.guidePublishedDocumentsMessage),
      guideUnpublishedMessage: formatMessage(messages.guideUnpublishedMessage),
      publishGuideFailureMaxPublishedGuides,
      publishGuideFailure: formatMessage(messages.publishGuideFailure),
      publishGuideFailureForbidden: formatMessage(messages.publishGuideFailureForbidden),
      reviewSubmitSuccess: formatMessage(messages.reviewSubmitSuccess),
      reviewSubmitFailure: formatMessage(messages.reviewSubmitFailure),
      guideUpdatedMessage: formatMessage(messages.guideUpdatedMessage)
    };

    if (loading || error) {
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loadingEditor} />
        </LoadingMessage>
      );
    }

    if (!guideForCms) {
      return <NotFoundMessage />;
    }

    return (
      <EditGuide>
        <GuideDetails
          submitMutation={updateGuideMutation}
          guideStore={editGuidePage}
          translations={translations}
          guide={guideForCms}
          publish={publishGuideMutation}
          requestGuideApproval={requestGuideApprovalMutation}
          withdrawGuideApproval={withdrawGuideApprovalMutation}
          submitGuideApprovalReviewMutation={submitGuideApprovalReviewMutation}
          submitLiveVersionReviewMutation={submitLiveVersionReviewMutation}
          unpublish={this.unpublish}
          banner={<EditGuideBanner />}
        />
        <TopicList key={editGuidePage.currentVersionId} topics={guideForCms.topics} translations={translations} />
        <ScrollToRouteAnchor />
      </EditGuide>
    );
  }
}

export default injectIntl(EditGuideViewComponent);
