import {defineMessages} from 'react-intl';

const messages = defineMessages({
  notAvailable: {
    id: 'GuideActivity.notAvailable',
    defaultMessage: 'Activity log not available.'
  },
  noFilteredActivities: {
    id: 'noFilteredActivities',
    defaultMessage: 'There are no activities available for these filters.'
  }
});

export default messages;
