import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {FormattedMessage} from 'components/FormattedComponents';
import Icon from 'ui-components/Icon';

import {removalRightsOptions} from './constants';
import messages from './messages';
import {StyledSelect, WrapperInfo} from './styles';

import {Section, SectionRow, iconStyles} from '../styles';

@inject('store')
@observer
class RemindersSection extends Component {
  render() {
    const {
      intl: {formatMessage},
      notificationsForm,
      canEditNotifications,
      guideApprovalIsEnabled
    } = this.props;

    return (
      <Section>
        <SectionRow header>{formatMessage(messages.reminders)}</SectionRow>
        <SectionRow>
          <WrapperInfo>
            <span>{formatMessage(messages.reminderCycle)} </span>
            <Icon name="info" popoverContent={formatMessage(messages.remindersInfo)} style={iconStyles} />
          </WrapperInfo>
        </SectionRow>
        {!guideApprovalIsEnabled && (
          <SectionRow>
            <FormattedMessage {...messages.guideApprovalDisabledInfo} />
          </SectionRow>
        )}
        <SectionRow>
          <StyledSelect
            allowClear={false}
            options={removalRightsOptions}
            field={'reminderCycle'}
            form={notificationsForm}
            disabled={!guideApprovalIsEnabled || !canEditNotifications}
          />
        </SectionRow>
      </Section>
    );
  }
}

export default injectIntl(RemindersSection);
