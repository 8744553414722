import styled from 'styled-components';
import flex from 'styles/flex';

export const AllGuides = styled.div`
  ${flex.vertical};
  padding-bottom: 30px;
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;

  @media (min-width: 1650px) {
    max-width: 1260px;
  }
`;
