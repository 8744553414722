import React, {Component} from 'react';

//components
import {FormattedMessage} from 'components/FormattedComponents';

import {INSTRUCTION_TYPE} from 'shared/enums';

//styles
import {InnerWrapper, PlusIcon, StyledLink, Title} from './styles';

//messages
import messages from './messages';

class NewStepLinkComponent extends Component {
  render() {
    const {params, store, view} = this.props;
    const {
      editInstructionDetailsPage: {currentInstruction}
    } = store;

    const isChecklist = currentInstruction && currentInstruction.type === INSTRUCTION_TYPE.CHECKLIST;

    const linkTitle = isChecklist ? messages.titleCheck : messages.titleStep;
    const newEntityPlaceholder = isChecklist ? 'new-check-placeholder' : 'new-step-placeholder';

    return (
      <StyledLink
        params={params}
        queryParams={store.router.queryParams}
        store={store}
        route={view}
        data-cy={newEntityPlaceholder}
      >
        <InnerWrapper>
          <Title>
            <PlusIcon />
            <FormattedMessage {...linkTitle} />
          </Title>
        </InnerWrapper>
      </StyledLink>
    );
  }
}

export default NewStepLinkComponent;
