import styled from 'styled-components';
import $Field from 'ui-components/Field';
import Button from 'ui-components/Button';
import $Checkbox from 'components/Checkbox';

export const PickerRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 920px;
  align-items: center;
`;

export const Scroller = styled.div.attrs({
  id: 'scroller'
})`
  height: 200px;
  overflow-y: auto;
  padding: 0 20px 0 0;
  width: 100%;
`;

export const PaddingContainer = styled.div`
  position: absolute;
  bottom: -30px;
`;

export const Field = styled($Field)`
  width: 350px;
  margin-right: 30px;
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'remove',
  solidIcon: false
})`
  cursor: pointer;
  margin-bottom: ${p => (p.removeMargin ? 0 : 36)}px;
`;

export const ContentWrapper = styled.div`
  ${({editTeam}) => (editTeam ? 'min-height: 200px;' : '')}
`;

export const CheckboxField = styled($Field)`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Checkbox = styled($Checkbox)`
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;
`;

export const WrapperDiv = styled.div`
  position: relative;
`;
