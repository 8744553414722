import styled, {css} from 'styled-components';

//helpers
import flex from 'styles/flex';
import {tableMetrics} from 'styles/metrics';
import {boxShadow} from 'styles/shadows';
import typefaces from 'shared/styles/typefaces';

//components
import $Editor from 'components/Editor';
import $Field from 'ui-components/Field';
import $Arrow from 'ui-components/Layout/Arrow';
import $DataTable from 'ui-components/Layout/DataTable';
import $EnhancedIcon from 'ui-components/Layout/EnhancedIcon';
import $Icon from 'ui-components/Layout/Icon';
import $MediaPicker from 'components/MediaPicker';
import $Slug from 'components/Slug';
import {Error as $Error} from 'shared/styles/styled-components/field-styled-components';
import $TranslationPicker from './TranslationPicker';
import $CloudinaryPicture from 'shared/components/CloudinaryPicture';
import {Link} from 'utils/mobx';
import $Spinner from 'shared/components/Spinner';

//constants
const contentPadding = '10px 16px';
const contentPullout = '-10px -16px';
const headerGutter = 24;
const mediaHeight = 106;
const mediaWidth = 142;

export const TranslateLocale = styled.div`
  ${flex.vertical};
  flex-grow: 1;
  height: 100%;
`;

export const Header = styled.div`
  ${flex.horizontal};
  align-items: center;
  margin: 0 0 20px;
  @media (max-width: 600px) {
    font-size: 10px;
  }
`;

export const HeaderLead = styled.div`
  font-weight: bold;
  margin-right: ${headerGutter}px;
`;

export const SourceLanguage = styled.div`
  margin-right: ${headerGutter}px;
  @media (max-width: 600px) {
    margin-right: 5px;
  }
`;

export const StyledInformationEnhancedIcon = styled($EnhancedIcon).attrs({
  name: 'information'
})`
  margin-left: 4px;
  margin-right: -4px;
  opacity: 0.27;
`;

export const StyledArrow = styled($Arrow).attrs({
  direction: 'right'
})`
  display: block;
  margin-right: ${headerGutter}px;
  opacity: 0.6;
  @media (max-width: 600px) {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

export const StyledTranslationPicker = styled($TranslationPicker)``;

export const HeaderButton = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-left: ${headerGutter}px;
  outline: 0;
  padding: 0;
  transition: 0.2s color ease-out;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    transition: none;
  }
  @media (max-width: 600px) {
    margin-top: 5px;
    font-size: 10px;
    margin-left: 10px;
  }
`;

export const StyledManageLink = styled(Link)`
  ${flex.horizontal};
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  margin-left: auto;
  transition: 0.2s color ease-out;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    transition: none;
  }
  @media (max-width: 600px) {
    margin-top: 5px;
    font-size: 10px;
  }
`;

export const StyledGearIcon = styled($Icon).attrs({
  name: 'gear'
})`
  display: block;
  margin-left: 10px;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const TranslationCard = styled.div`
  box-shadow: ${boxShadow.table};
  margin: 20px 0 0;
  position: relative;
`;

export const TranslationCardTitle = styled.div`
  background: #fff;
  font-weight: bold;
  line-height: 40px;
  padding: 0 16px;
`;

export const TranslationCardSection = styled.div`
  ${flex.vertical};
  height: ${mediaHeight}px;
  padding-right: ${mediaWidth}px;
  position: relative;
`;

export const TranslationCardContent = styled.div`
  padding: ${contentPadding};
  overflow-y: auto;
`;

export const StyledField = styled($Field).attrs({
  styles: {
    Input: css`
      ${typefaces.basic};
      background: none;
      border: 0 !important;
      box-shadow: none !important;
      outline: 0;
      padding: ${contentPadding};
      margin: ${contentPullout};
    `
  }
})`
  flex-grow: 1;
  padding: ${contentPadding};
`;

export const StyledEditor = styled($Editor)`
  flex-grow: 1;

  .rdw-editor-wrapper {
    height: 100%;
  }

  .rdw-editor-toolbar {
    bottom: 0;
    left: 50%;
    position: absolute !important;
    transform: translate(-50%, 50%);
    z-index: 9999 !important;
  }

  .rdw-editor-main {
    padding: ${contentPadding};
  }
  overflow-y: auto;
`;

export const StyledSlug = styled($Slug)`
  ${typefaces.small};
  color: #949494;
  flex-shrink: 0;
  padding: 0 16px 9px;

  .Slug_Field {
    ${flex.horizontal};
    align-items: flex-start;
    color: #000;
  }

  ${() => $Error} {
    margin-left: 15px;
  }
`;

export const StyledMediaPicker = styled($MediaPicker).attrs({
  backgroundAspectRatio: mediaWidth / mediaHeight,
  backgroundSizes: `${mediaWidth}px`,
  iconSize: 65
})`
  height: ${mediaHeight}px;
  position: absolute;
  right: 0;
  top: 0;
  width: ${mediaWidth}px;
  z-index: 1;
`;

export const StyledDataTable = styled($DataTable).attrs({
  className: '-cms-design-v2'
})`
  ${flex.vertical};
  max-width: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-height: ${tableMetrics.minHeight}px;

  > .ReactTable {
    max-height: 100%;
    overflow: hidden;
  }
`;

export const TypeCell = styled.div`
  font-size: 8px;
  text-transform: uppercase;
`;

export const CellInner = styled.div`
  ${p =>
    p.isHighlighted &&
    `
    font-weight: bold;
  `}
`;

export const CoverImage = styled($CloudinaryPicture).attrs({
  aspectRatio: mediaWidth / mediaHeight,
  sizes: `${mediaWidth}px`
})`
  height: ${mediaHeight}px;
  position: absolute;
  right: 0;
  top: 0;
  width: ${mediaWidth}px;
`;

export const StyledNoMediaIcon = styled($Icon).attrs({
  name: 'no-media',
  size: 65
})`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingState = styled.div`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  opacity: 0.8;

  & > div {
    padding-left: 20px;
    padding-top: 10px;
  }
`;

export const StyledSpinner = styled($Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
`;
