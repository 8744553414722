import styled from 'styled-components';

export const ShapeTable = styled.div`
  width: 100%;
  display: block;
`;

export const TipWrapper = styled.div`
  background-color: #cce4fe;
  color: #2c71c0;
  border-radius: 3px;
  width: 100%;
  line-height: 40px;
  padding: 5px 18px 5px 18px;
`;
