import styled from 'styled-components';
import flex from 'styles/flex';

//components
import $Field from 'ui-components/Field';

export const RangePicker = styled.div`
  ${flex.horizontal};
  ${flex.spaceBetween};
  position: relative;
  margin: 0 -8px;
`;

export const Field = styled($Field)`
  padding: 0 8px;
`;

export const StyledRangeError = styled.div`
  position: absolute;
  bottom: 10px;
  color: red;
  padding: 0 8px;
  font-size: 12px;
`;
