import styled from 'styled-components';
import {textFontSizes} from 'shared/styles/vars';

const getFontSize = p => {
  const size = textFontSizes[p.size] ? p.size : 'normal';
  const textSize = textFontSizes[size];
  return `
    font-size: ${textSize.fontSize}rem;
    line-height: ${textSize.fontSize * 1.5}rem;
  `;
};

export const Text = styled.div`
  &.tag-description {
    padding: 20px 0;
  }

  ${p => getFontSize(p)};
  ${p =>
    p.secondary &&
    `
    opacity: 0.5;
  `};
  ${p =>
    p.opacity &&
    `
    opacity: ${p.opacity};
  `};

  ${p =>
    p.ellipsis &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `};
`;
