import {defineMessages} from 'react-intl';

const messages = defineMessages({
  colors: {
    id: 'EditTheme.colors',
    defaultMessage: 'Theme Colors'
  },
  colorsExample: {
    id: 'EditTheme.colorsExample',
    defaultMessage: 'Example of theme colors'
  },
  mainColor: {
    id: 'EditTheme.mainColor',
    defaultMessage: 'Main color'
  },
  buttonColor: {
    id: 'EditTheme.buttonColor',
    defaultMessage: 'Button color'
  },
  showExample: {
    id: 'EditTheme.showExample',
    defaultMessage: 'Show example'
  },
  stepNoteColors: {
    id: 'EditTheme.stepNoteColors',
    defaultMessage: 'These colors also apply to your Step Note Icons'
  }
});

export default messages;
