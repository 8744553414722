import styled from 'styled-components';

export const LoginOptionWrapper = styled.div`
  margin-bottom: 8px;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const Header = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;
