export const LEGEND_LEVEL_COLOR_MAP = [
  '#11335E',
  '#02408B',
  '#0058BA',
  '#0471F2',
  '#2184F2',
  '#2184F2',
  '#ccc', // TODO #ccc is a buffer zone when there are more levels than defined colors
  '#ccc', // Or ask BE to return color for each level
  '#ccc'
];
