import {defineMessages} from 'react-intl';

const messages = defineMessages({
  feedbackOnGuide: {
    id: 'guide',
    defaultMessage: 'Guide'
  },
  feedbackOnInstruction: {
    id: 'pdfInstruction',
    defaultMessage: 'Instruction'
  },
  feedbackOnChecklist: {
    id: 'checklist',
    defaultMessage: 'Checklist'
  },
  feedbackOnStep: {
    id: 'pdfStep',
    defaultMessage: 'Step'
  },
  feedbackOnChecklistStep: {
    id: 'feedbackOnChecklistStep',
    defaultMessage: 'Checklist step'
  },
  stepReference: {
    id: 'Feedback.stepReference',
    defaultMessage: '{instructionTitle}, step {stepNumber}'
  }
});

export default messages;
