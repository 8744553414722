import {defineMessages} from 'react-intl';

const messages = defineMessages({
  startWithoutTemplate: {
    id: 'EditCampaign.startWithoutTemplate',
    defaultMessage: 'Start without a template'
  },
  categories: {
    id: 'EditCampaign.categories',
    defaultMessage: 'Categories'
  },
  training: {
    id: 'EditCampaign.training',
    defaultMessage: 'Training'
  },
  processConfirmation: {
    id: 'EditCampaign.processConfirmation',
    defaultMessage: 'Process confirmation'
  },
  feedback: {
    id: 'EditCampaign.feedback',
    defaultMessage: 'Feedback'
  },
  starRating: {
    id: 'EditCampaign.starRating',
    defaultMessage: 'Star rating'
  },
  yesNo: {
    id: 'EditCampaign.yesno',
    defaultMessage: 'Yes / No'
  },
  passFail: {
    id: 'EditCampaign.passFail',
    defaultMessage: 'Pass / Fail'
  },
  number: {
    id: 'EditCampaign.number',
    defaultMessage: 'Number'
  },
  multipleChoice: {
    id: 'EditCampaign.text',
    defaultMessage: 'Multiple choice'
  }
});

export default messages;
