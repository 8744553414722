import styled from 'styled-components';

import $Button from 'ui-components/Button';
import $CardToolbar from 'components/CardToolbar';
import $Icon from 'ui-components/Layout/Icon';

import {commonPalette} from 'shared/styles/palette';
import {transparentize} from 'shared/utils/color-utils';

export const Card = styled.div`
  background: ${({isDeactivated}) => (isDeactivated ? '#fafafa' : '#ffffff')};
  border-radius: 2px;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.26);
  position: relative;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  height: 100%;

  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
`;

export const CampaignTitle = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const StatusLabel = styled.div`
  text-transform: uppercase;
  margin: 16px;
  color: ${({isActivated}) => (isActivated ? commonPalette.sgGreen : transparentize(commonPalette.txLightGrey, 0.6))};
`;

export const StyledCardToolbar = styled($CardToolbar)`
  color: ${commonPalette.txGrey};
  position: absolute;
  right: 8px;
  top: 14px;
`;

export const BottomSection = styled.div`
  display: flex;
  justify-content: ${({isDeactivated}) => (isDeactivated ? 'flex-end' : 'space-between')};
  align-items: center;
  margin-bottom: 16px;
`;

export const ActivateButton = styled($Button)`
  width: 30px;
  margin: 0 14px 0 0;
`;

export const TopSection = styled.div`
  display: flex;
`;

export const MiddleSection = styled.div`
  margin: 0 16px 16px;
  flex: 1;
`;

export const CampaignQuestion = styled.div`
  color: ${commonPalette.txGrey};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

export const ViewResultsDiv = styled.div`
  display: flex;
  margin: 0 16px 0 0;
`;

export const StyledViewResultsText = styled.div`
  font-size: 14px;
  margin-right: 5px;
  font-weight: 400;
  color: ${commonPalette.txGrey};
  line-height: 20px;
`;

export const InstructionCountInfo = styled.div`
  margin-left: 15px;
  font-style: italic;
  ${({numInstructionsAppliedTo}) =>
    !numInstructionsAppliedTo && `color: ${transparentize(commonPalette.txLightGrey, 0.6)};`}
`;

export const BookIcon = styled($Icon).attrs({
  name: 'guides'
})`
  margin-right: 8px;
`;
