import {defineMessages} from 'react-intl';

const messages = defineMessages({
  icons: {
    id: 'EditTheme.icons',
    defaultMessage: 'Instructional Icons'
  },
  showInstructionalIconLabel: {
    id: 'EditTheme.showInstructionalIconLabel',
    defaultMessage: 'Show Instructional Icon Label'
  },
  changeThemeStepNote: {
    id: 'EditTheme.changeThemeStepNote',
    defaultMessage: 'Change Step Note Icons for this Theme'
  }
});

export default messages;
