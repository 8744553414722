import PropTypes from 'prop-types';
import React, {Component} from 'react';

//lodash
import omit from 'lodash/omit';

// The purpose of this component is to trigger
// its onBlur handler when the focus leaves it.

class BlurListenerComponent extends Component {
  static defaultProps = {
    as: 'div'
  };

  static propTypes = {
    as: PropTypes.elementType,
    elementRef: PropTypes.any,
    onBlur: PropTypes.func,
    onMouseDown: PropTypes.func
  };

  elementRef = null;
  whenMouseButtonReleased = Promise.resolve();

  handleBlur = (...params) => {
    const {onBlur} = this.props;

    // The final focus target might be known only
    // after the mouse button is released:
    this.whenMouseButtonReleased.then(() => {
      window.requestAnimationFrame(() => {
        if (typeof onBlur === 'function' && this.elementRef && !this.elementRef.contains(document.activeElement)) {
          onBlur(...params);
        }
      });
    });
  };

  handleMouseDown = (...params) => {
    const {onMouseDown} = this.props;

    this.whenMouseButtonReleased = new Promise(resolve => {
      document.addEventListener('mouseup', function handler() {
        document.removeEventListener('mouseup', handler);
        resolve();
      });
    });

    if (typeof onMouseDown === 'function') {
      onMouseDown(...params);
    }
  };

  setElementRef = ref => {
    const {elementRef} = this.props;

    this.elementRef = ref;

    if (typeof elementRef === 'function') {
      elementRef(ref);
    }
  };

  render() {
    const {as, children, ...otherProps} = this.props;
    const forwardedProps = omit(otherProps, 'elementRef', 'onMouseDown');

    return React.createElement(
      as,
      {
        ...forwardedProps,
        onBlur: this.handleBlur,
        onMouseDown: this.handleMouseDown,
        ref: this.setElementRef
      },
      children
    );
  }
}

export default BlurListenerComponent;
