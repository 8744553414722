import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {mapValues, pick} from 'lodash';
import {bindField} from 'shared/utils/input-utils';
import {inject, observer} from 'mobx-react';

import {
  SetPasswordForm,
  Title,
  Wrap,
  ErrorWrapper,
  styles,
  ErrorNotification,
  StyledErrorIcon,
  StyledErrorWrapper
} from './styles';
import mutationNames from 'api/user/constants';

//options
import {validateSetPasswordLinkOptions} from 'api/user/query-options';
import {ValidateSetPasswordLink} from 'api/user/queries';

//data
import {graphql} from '@apollo/client/react/hoc/graphql';

//mutations
import {SetPassword} from 'api/user/mutations';

//options
import {setPasswordOptions} from 'api/user/mutation-options';

//components
import Button from 'ui-components/Button';
import {FormattedMessage} from 'components/FormattedComponents';
import Field from 'shared/components/Field';

//messages
import messages from './messages';

@inject('store')
@graphql(ValidateSetPasswordLink, validateSetPasswordLinkOptions)
@graphql(SetPassword, setPasswordOptions)
@observer
class SetPasswordFormComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {setPasswordPage}
    } = this.props;

    const translations = mapValues(pick(messages, ['requestSuccess', 'requestFailure', 'invalidLink']), message =>
      formatMessage(message)
    );

    setPasswordPage.setTranslations(translations);
  }

  componentWillUnmount() {
    const {store} = this.props;
    store.setPasswordPage.form.clear();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.isPasswordSetLinkValid === false) {
      const {store} = this.props;
      const {setPasswordPage} = store;
      setPasswordPage.setPasswordLinkNotValid();
    }
  }

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const setPasswordMutation = this.props[mutationNames.SET_PASSWORD];
    const {setPasswordPage} = store;
    const {form, isInvalidLink} = setPasswordPage;
    const {newPassword, newPasswordConfirm} = form.values();
    const passwordsMatch = newPassword === newPasswordConfirm;
    const inputStyles = {Input: styles};

    return (
      <SetPasswordForm>
        <Title>
          <FormattedMessage {...messages.title} />
        </Title>

        {isInvalidLink && (
          <ErrorWrapper>
            <FormattedMessage {...messages.setLinkExpired} />
          </ErrorWrapper>
        )}

        {!isInvalidLink && (
          <Wrap>
            <Field
              autofocus={true}
              placeholder={formatMessage(messages.newPassword)}
              id="newPassword"
              type="password"
              form={form}
              field={'newPassword'}
              hideError
              withPasswordToggle
              withPasswordValidation
              styles={inputStyles}
              {...bindField(form, 'newPassword')}
            />
            <Field
              placeholder={formatMessage(messages.newPasswordConfirm)}
              id="newPasswordConfirm"
              type="password"
              form={form}
              field={'newPasswordConfirm'}
              hideError
              styles={inputStyles}
              {...bindField(form, 'newPasswordConfirm')}
              withPasswordToggle
            />
            {!passwordsMatch && (
              <StyledErrorWrapper>
                <StyledErrorIcon name={'warning'} />
                <ErrorNotification>{formatMessage(messages.passwordsDoNotMatch)}</ErrorNotification>
              </StyledErrorWrapper>
            )}
            <Button
              disabled={!form.isValid || setPasswordPage.loading === true || !passwordsMatch}
              label={formatMessage(messages.submit)}
              loading={setPasswordPage.loading}
              onClick={() => setPasswordPage.submitSetPasswordForm(setPasswordMutation)}
            />
          </Wrap>
        )}
      </SetPasswordForm>
    );
  }
}

export default injectIntl(SetPasswordFormComponent);
