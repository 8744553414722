import {defineMessages} from 'react-intl';

const messages = defineMessages({
  approved: {
    id: 'SmartSkills.skillStatus.APPROVED',
    defaultMessage: 'Approved'
  },
  waitingApproval: {
    id: 'SmartSkills.skillStatus.COMPLETED',
    defaultMessage: 'Waiting approval'
  },
  inProgress: {
    id: 'SmartSkills.skillStatus.IN_PROGRESS',
    defaultMessage: 'In progress'
  },
  notStarted: {
    id: 'SmartSkills.skillStatus.NOT_STARTED',
    defaultMessage: 'Not started'
  },
  expired: {
    id: 'SmartSkills.skillStatus.EXPIRED',
    defaultMessage: 'Expired'
  }
});

export default messages;
