import {defineMessages} from 'react-intl';

const messages = defineMessages({
  instructionTitle: {
    id: 'WritingTips.instruction.title',
    defaultMessage: 'Writing tips – for instructions'
  },
  tipTitle: {
    id: 'WritingTips.tip.title',
    defaultMessage: 'Writing tips – for tips'
  },
  warningTitle: {
    id: 'WritingTips.warning.title',
    defaultMessage: 'Writing tips – for warnings'
  },
  alternativeTitle: {
    id: 'WritingTips.alternative.title',
    defaultMessage: 'Writing tips – for alternative'
  },
  fixTitle: {
    id: 'WritingTips.fix.title',
    defaultMessage: 'Writing tips – for fixes'
  },
  instructionContent: {
    id: 'WritingTips.instruction.content',
    defaultMessage: `
Simplest form of instruction is a verb + noun: “Press button”.
Additional information on where it is located: “Insert the plug into the bottom of the device”.
Use short sentences (15-20 words preferred and 25 words max.).
Use the active voice where possible.
Be consistent. Always use the same word to describe the same thing.
Address the reader as ‘you’ when needed.
    `
  },
  tipContent: {
    id: 'WritingTips.tip.content',
    defaultMessage: `
Tips give advice on the best way for users to perform a task.
Tips provide users with extra information about the step.
If the extra info is to warn a user rather use the ‘warning’ field in the editor.
Write in an active voice and be brief.
    `
  },
  warningContent: {
    id: 'WritingTips.warning.content',
    defaultMessage: `
Insert a warning at steps where users frequently make mistakes and/or mistakes have a high impact.
The warning indicates that the users needs to look at additional information first before proceeding with the step.
Warnings inform the user of various issues of hazard control.
Warnings can also offer a reminder / cue to prevent users from forgetting to act safely at a critical moment.
Warnings must tell users what to do or what to avoid.
    `
  },
  alternativeContent: {
    id: 'WritingTips.alternative.content',
    defaultMessage: `
In case of steps where there are several ways for users to complete a step one preferred way is described in the instruction field.
The other possible way is inserted as an alternative.
The ‘alternative’ way describes other possible actions users can undertake to complete the step.
    `
  },
  fixContent: {
    id: 'WritingTips.fix.content',
    defaultMessage: `
In case of steps where users can be confronted with issues we insert a fix.
The fix describes how users can resolve issues within a certain step.
Make sure a fix is given in time for users to give them time to respond.
Fixes / error recovery should be given when actions may be needed.
    `
  },
  customTitle: {
    id: 'WritingTips.custom.title',
    defaultMessage: 'How to write Custom Step Notes:'
  },
  customContent: {
    id: 'WritingTips.custom.content',
    defaultMessage: `
Use short sentences (15-20 words preferred and 25 words max.).
Use the active voice where possible.
Be consistent. Always use the same word to describe the same thing.
Address the reader as ‘you’ when needed.
    `
  },
  customTitleSecondary: {
    id: 'WritingTips.customTitleSecondary.title',
    defaultMessage: 'For Example:'
  },
  customContentSecondary: {
    id: 'WritingTips.customContentSecondary.content',
    defaultMessage: `
For Step Note Equipment, write which equipment is required to execute each of their procedural tasks in the respective Step Note.
For Step Note Location, write where the specific machinery or component referred to in the Step Content is located on the line, on the shop floor, or at which machine.
For Step Note Tools, write which tools are needed to execute each of their procedural tasks in the respective Step Note.
    `
  }
});

export default messages;
