import {action, observable, makeObservable} from 'mobx';

class ConfirmDeactivateDialog {
  @observable opened = false;
  @observable handleSubmit;
  @observable handleCancel;

  @action
  reset = () => {
    this.opened = false;
    this.handleSubmit = null;
    this.handleCancel = null;
  };

  @action open = (handleSubmit, handleCancel) => {
    this.handleSubmit = handleSubmit;
    this.handleCancel = handleCancel;
    this.opened = true;
  };

  submit = () => {
    this.handleSubmit();
    this.reset();
  };

  cancel = () => {
    this.handleCancel();
    this.reset();
  };

  constructor() {
    makeObservable(this);
  }
}

export default ConfirmDeactivateDialog;
