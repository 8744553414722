import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import map from 'lodash/map';
import injectScrollParent from 'decorators/injectScrollParent';

import FollowUpQuestion from 'components/FollowUpQuestion';
import LanguagePicker from 'components/LanguagePicker';
import {FormattedMessage} from 'components/FormattedComponents';
import Field from 'ui-components/Field';
import Select from 'ui-components/Select';

import {scrollToTop} from 'shared/utils/dom-utils';
import {formatRange} from 'utils/string-utils';

import {multipleChoiceOptions} from './constants';
import messages from './messages';
import {CampaignQuestionKindPicker, AddFollowUpButton, TextQuestionsWrapper} from './styles';

import {CampaignTab, Top, Content, Title} from '../styles';

@inject('store')
@injectScrollParent
@observer
class CampaignQuestionsComponent extends Component {
  UNSAFE_componentWillMount() {
    const {store, isNewCampaign} = this.props;
    const defaultLocale = store.platform.browserLocale;

    if (isNewCampaign) {
      store.newCampaignPage.setDefaultLocale(defaultLocale);
      store.newCampaignPage.setDomainName(store.platform.organizationName);
    }
  }

  componentDidMount = () => {
    const {getScrollParent} = this.props;
    const scrollParent = getScrollParent();
    scrollToTop(scrollParent);
  };

  render() {
    const {
      intl: {formatMessage},
      store,
      isNewCampaign
    } = this.props;
    let campaignPage = store.newCampaignPage;
    if (!isNewCampaign) {
      campaignPage = store.editCampaignPage;
    }

    const {
      setDefaultLocale,
      questionForm,
      followUps,
      isValid,
      areFollowUpsRangesValid,
      maxRating,
      minFollowUpRating,
      isBinaryQuestionType,
      isStarQuestionType,
      isNumberQuestionType,
      isTextQuestionType,
      autofocusFollowUp,
      defaultLocale,
      textTypeOptions,
      filteredTextTypeOptions,
      binarySelectValues,
      binaryOptions,
      saveFollowUpBinaryValues,
      canAddFollowUp
    } = campaignPage;

    const {
      platform: {multipleLocales}
    } = store;

    return (
      <CampaignTab>
        <Top>
          <Content>
            <Field
              autofocus={true}
              size="small"
              title={formatMessage(messages.signOffTitle)}
              placeholder={formatMessage(messages.titlePlaceholder)}
              field="title"
              form={questionForm}
            />
            {multipleLocales && (
              <Field
                size="small"
                inputComponent={
                  <LanguagePicker
                    tooltipText={formatMessage(messages.defaultLanguageTooltip)}
                    disabled={!isNewCampaign}
                    changeLanguage={setDefaultLocale}
                    selectedLanguage={defaultLocale}
                    dataCy={'edit-signoff'}
                  />
                }
              />
            )}

            <Field
              size="small"
              title={formatMessage(messages.question)}
              placeholder={formatMessage(messages.questionPlaceholder)}
              field="question"
              form={questionForm}
            />

            <Field size="small" inputComponent={<CampaignQuestionKindPicker form={questionForm} field="kind" />} />

            {isStarQuestionType && (
              <Field
                type="number"
                min={3}
                max={7}
                size="small"
                title={formatMessage(messages.numberOfStars)}
                field="numOfRating"
                form={questionForm}
              />
            )}
            {isNumberQuestionType && (
              <Field
                type="number"
                min={1}
                max={100}
                size="small"
                title={formatMessage(messages.range)}
                field="numOfRating"
                form={questionForm}
              />
            )}
            {isTextQuestionType && (
              <TextQuestionsWrapper>
                <Field
                  size="small"
                  title={formatMessage(messages.numberOfChoices)}
                  inputComponent={
                    <Select
                      placeholder={`${questionForm.$('numOfRating').value}`}
                      options={multipleChoiceOptions}
                      field="numOfRating"
                      form={questionForm}
                      id="number-of-choices"
                      testId="multiple-choice-options"
                    />
                  }
                  inputId="number-of-choices"
                />
                {!!textTypeOptions.length &&
                  maxRating &&
                  map(filteredTextTypeOptions, (option, index) => (
                    <Field
                      key={index}
                      autofocus={false}
                      size="small"
                      title={formatMessage(messages.textTypeOptionTitle, {n: index + 1})}
                      placeholder={formatMessage(messages.textTypeOptionPlaceholder)}
                      field="option"
                      form={textTypeOptions[index].form}
                    />
                  ))}
              </TextQuestionsWrapper>
            )}

            <Title>
              {isStarQuestionType ? (
                <FormattedMessage
                  {...messages.followUpQuestionsRanging}
                  values={{
                    range: formatRange(minFollowUpRating, maxRating)
                  }}
                />
              ) : (
                <FormattedMessage {...messages.followUpQuestions} />
              )}
            </Title>

            {map(followUps, (followUp, index) => (
              <FollowUpQuestion
                key={index}
                index={index}
                followUp={followUp}
                removeFollowUpFunc={campaignPage.removeFollowUp}
                minRating={minFollowUpRating}
                maxRating={maxRating}
                autofocus={autofocusFollowUp}
                isBinaryQuestionType={isBinaryQuestionType}
                binarySelectValues={binarySelectValues[index]}
                binaryOptions={binaryOptions[index]}
                saveBinaryValue={saveFollowUpBinaryValues}
                areFollowUpsRangesValid={areFollowUpsRangesValid}
              />
            ))}

            <AddFollowUpButton
              onClick={() => campaignPage.addFollowUp()}
              label={formatMessage(messages.addFollowUp)}
              disabled={!isValid || !canAddFollowUp}
              secondary
            />
          </Content>
        </Top>
      </CampaignTab>
    );
  }
}

export default injectIntl(CampaignQuestionsComponent);
