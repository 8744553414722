import styled from 'styled-components';
import {Button as $Button} from 'styles/styled-components/shared-components';
import Icon from 'react-fontawesome';
import $CardToolbar from 'components/CardToolbar';

export const Button = styled($Button)`
  &:first-child {
    margin-right: 10px;
  }
`;

export const StyledIcon = styled(Icon)`
  padding-right: 5px;
`;

export const StyledTagTitle = styled.div`
  margin-top: 20px;
  font-size: 16px;
`;

export const StyledDiv = styled.div`
  margin: 40px;
`;

export const StyledMenu = styled($CardToolbar)`
  position: relative;
  top: 12px;
  left: 40px;
`;
