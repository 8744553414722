import {action, observable, makeObservable} from 'mobx';

//lodash
import find from 'lodash/find';
import memoize from 'lodash/memoize';

class DragDropItems {
  constructor() {
    makeObservable(this);
  }

  @observable areTopicsCollapsed = false;
  @observable dirty = false;
  @observable submitting = false;
  @observable.shallow topics = [];

  @action
  setAreTopicsCollapsed = value => {
    this.areTopicsCollapsed = value;
  };

  @action
  setTopics = topics => {
    this.dirty = false;
    this.topics.replace(
      topics.map(topic => {
        const {instructions, ...rest} = topic;
        return {
          instructions: observable(instructions),
          ...rest
        };
      })
    );
    this.getTopicById.cache.clear();
  };

  @action
  moveInstruction = (sourceTopicId, sourcePosition, targetTopicId, targetPosition) => {
    const sourceTopic = this.getTopicById(sourceTopicId);
    const targetTopic = this.getTopicById(targetTopicId);
    const sourceInstruction = sourceTopic.instructions[sourcePosition];

    sourceTopic.instructions.splice(sourcePosition, 1);
    targetTopic.instructions.splice(targetPosition, 0, sourceInstruction);

    this.dirty = true;
  };

  @action
  moveTopic = (sourceId, targetId) => {
    const sourceTopic = this.getTopicById(sourceId);
    const targetTopic = this.getTopicById(targetId);
    const sourcePosition = this.topics.indexOf(sourceTopic);
    const targetPosition = this.topics.indexOf(targetTopic);

    this.topics.splice(sourcePosition, 1);
    this.topics.splice(targetPosition, 0, sourceTopic);

    this.dirty = true;
  };

  getTopicById = memoize(topicId => {
    return find(this.topics, {id: topicId});
  });

  submit = mutation => {
    if (!this.dirty) {
      return;
    }

    this.setSubmitting(true);

    const topicsInstructionsAndPositions = this.topics.map((topic, topicIndex) => ({
      id: topic.id,
      position: topicIndex,
      instructions: topic.instructions.map((instruction, instructionIndex) => ({
        id: instruction.id,
        position: instructionIndex,
        topicId: topic.id
      }))
    }));

    mutation({topicsInstructionsAndPositions})
      .then(() => {
        this.setSubmitting(false);
      })
      .catch(() => {
        this.setSubmitting(false);
      });
  };

  @action
  setSubmitting = value => {
    this.submitting = value;
  };
}

export default DragDropItems;
