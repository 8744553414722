import React, {Component} from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {observable, action, makeObservable} from 'mobx';
import {DatePicker} from 'antd';
import moment from 'moment';

import Select from 'ui-components/Select';

import {DATE_FORMAT} from 'config/constants';
import {dateRangeOptions, dateRangeOptionsValues} from 'shared/enums';

import {
  StyledTitleText,
  FiltersArea,
  ApplyButton,
  BottomArea,
  StyledPopover,
  SectionDelimiter,
  FilterWrapper,
  LabelWrapper,
  StyledDiv
} from './styles';
import messages from './messages';

// import {allTimeYears} from '../constants';

@enhanceWithClickOutside
@observer
class Filters extends Component {
  @observable startingDate;
  @observable endingDate;
  @observable dateOption;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action setStartingDate = (startingDate, setFromDropown) => {
    if (!setFromDropown) {
      this.setDateOption(dateRangeOptionsValues.custom);
    }

    this.startingDate = startingDate;
  };

  @action setEndingDate = (endingDate, setFromDropown) => {
    if (!setFromDropown) {
      this.setDateOption(dateRangeOptionsValues.custom);
    }

    this.endingDate = endingDate;
  };

  @action setDateOption = dateOption => (this.dateOption = dateOption);

  @action setDates = dateOption => {
    const {setStartingDate, setEndingDate, setDateOption} = this;

    setDateOption(dateRangeOptionsValues[dateOption]);

    let startingDate, endingDate;

    switch (dateOption) {
      case dateRangeOptionsValues.yesterday.id:
        startingDate = moment().subtract(1, 'days');
        endingDate = moment().subtract(1, 'days');
        break;
      case dateRangeOptionsValues.last7days.id:
        startingDate = moment().subtract(7, 'days');
        endingDate = moment();
        break;
      case dateRangeOptionsValues.last30days.id:
        startingDate = moment().subtract(30, 'days');
        endingDate = moment();
        break;
      case dateRangeOptionsValues.lastMonth.id:
        startingDate = moment().subtract(1, 'months').startOf('month');
        endingDate = moment().subtract(1, 'months').endOf('month');
        break;
      case dateRangeOptionsValues.weekToDate.id:
        startingDate = moment().startOf('week');
        endingDate = moment();
        break;
      case dateRangeOptionsValues.monthToDate.id:
        startingDate = moment().startOf('month');
        endingDate = moment();
        break;
      default:
        startingDate = moment();
        endingDate = moment();
        break;
    }

    setStartingDate(startingDate, true);
    setEndingDate(endingDate, true);
  };

  handleClickOutside = ({target: {className}}) => {
    const wasSvgIconClicked = typeof className === 'object';
    const wasLabelTextClicked = typeof className === 'string' && className.includes('styles__StyledLabel');
    const wasDatePickerClicked = typeof className === 'string' && className.includes('ant-calendar');

    if (wasSvgIconClicked || wasLabelTextClicked || wasDatePickerClicked) {
      return;
    }

    this.props.hideMenu();
  };

  onApply = () => {
    const {
      startingDate,
      endingDate,
      props: {hideMenu, onApply, startDate, endDate}
    } = this;

    const startDateFormatted = startingDate ? moment(startingDate).format(DATE_FORMAT) : startDate;
    const endDateFormatted = endingDate ? moment(endingDate).format(DATE_FORMAT) : endDate;
    onApply(startDateFormatted, endDateFormatted);

    hideMenu();
  };

  render() {
    const {
      startingDate,
      endingDate,
      setStartingDate,
      setEndingDate,
      setDates,
      onApply,
      dateOption,
      props: {
        intl: {formatMessage},
        startDate,
        endDate,
        translations,
        option
      }
    } = this;

    const startDateMoment = startingDate || moment(new Date(startDate));
    const endDateMoment = endingDate || moment(new Date(endDate));
    const options = dateRangeOptions.map(dateRangeOption => ({
      ...dateRangeOption,
      name: translations[dateRangeOption.id]
    }));
    const selectedValue = dateOption ? dateOption.id : option.id;

    return (
      <StyledDiv>
        <StyledPopover>
          <StyledTitleText>{formatMessage(messages.title)}</StyledTitleText>

          <SectionDelimiter />

          <FiltersArea>
            <FilterWrapper>
              <LabelWrapper>{formatMessage(messages.dateRange)}</LabelWrapper>
              <Select
                testId={'range-picker'}
                options={options}
                selectedValue={selectedValue}
                onChange={setDates}
                showSearch={true}
              />
            </FilterWrapper>

            <FilterWrapper>
              <LabelWrapper>{formatMessage(messages.starting)}</LabelWrapper>
              <div data-testid="start-date-picker">
                <DatePicker
                  format={DATE_FORMAT}
                  allowClear={false}
                  value={startDateMoment}
                  disabledDate={current => moment(current).isAfter(endDateMoment)}
                  onChange={newStartDate => {
                    setStartingDate(newStartDate);
                    setEndingDate(moment.min(endDateMoment, moment(newStartDate).add(30, 'days')));
                  }}
                  showToday={false}
                />
              </div>
            </FilterWrapper>

            <FilterWrapper>
              <LabelWrapper>{formatMessage(messages.ending)}</LabelWrapper>
              <div data-testid="end-date-picker">
                <DatePicker
                  format={DATE_FORMAT}
                  allowClear={false}
                  value={endDateMoment}
                  onChange={date => setEndingDate(date)}
                  disabledDate={current =>
                    moment(current).isAfter(moment()) ||
                    moment(current).isBefore(startDateMoment) ||
                    moment(current).isAfter(moment(startDateMoment).add(30, 'days'))
                  }
                  showToday={false}
                />
              </div>
            </FilterWrapper>
          </FiltersArea>

          <SectionDelimiter />

          <BottomArea>
            <ApplyButton data-testid={'apply-date-filter'} onClick={onApply}>
              {formatMessage(messages.apply)}
            </ApplyButton>
          </BottomArea>
        </StyledPopover>
      </StyledDiv>
    );
  }
}

export default injectIntl(Filters);
