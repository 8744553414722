import React, {useState, useEffect, useCallback} from 'react';
import {observer} from 'mobx-react';
import {InjectedIntl, injectIntl} from 'react-intl';
import debounce from 'lodash/debounce';

//components
import {Dialog, Select} from 'ui-components';

//data
import {GuidesListLite} from 'api/guide/queries';

//messages
import messages from './messages';
import {useStore} from 'hooks/useStore';
import {useAbortableQuery} from 'hooks/useAbortableQuery';

const SEARCH_DEBOUNCE_DELAY = 300;
const ITEMS_PER_PAGE = 100;

type MoveToDialogProps = {
  intl: InjectedIntl;
};

const MoveToDialog = ({intl}: MoveToDialogProps) => {
  const [searchTerm, setSearchTerm] = useState(null);
  const handleSearch = useCallback(debounce(setSearchTerm, SEARCH_DEBOUNCE_DELAY), [setSearchTerm]);

  const [guideListsQuery, {data}] = useAbortableQuery(GuidesListLite, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    guideListsQuery({
      variables: {
        searchTerm,
        limit: ITEMS_PER_PAGE,
        offset: 0
      }
    });
  }, [searchTerm]);

  const queryResultGuides: {
    guides: {
      id: string;
      title: string;
    }[];
  } = data?.pagedGuidesForCms.guides;

  const store = useStore();

  const {moveToDialog, router} = store;

  useEffect(() => {
    if (queryResultGuides) {
      moveToDialog.setGuides(queryResultGuides);
    }
  }, [queryResultGuides]);

  const {formatMessage} = intl;

  const {selectedGuide, setSelectedGuide, guides, opened, reset, moveTo, translations} = moveToDialog;

  const currentGuideId: string = router.params.id;

  return (
    <Dialog
      actions={[
        {
          label: translations.buttonLabel,
          disabled: !selectedGuide,
          onClick: moveTo
        }
      ]}
      isOpen={opened}
      onCancel={reset}
      title={translations.title}
      dialogStyle={{overflow: 'visible'}}
      bodyStyle={{overflow: 'visible'}}
    >
      <Select
        allowClear
        showSearch
        dataCy="guide-picker"
        filterOption={[currentGuideId]}
        label={formatMessage(messages.guide)}
        optionFormatter="title"
        onChange={setSelectedGuide}
        options={guides?.slice()}
        placeholder={formatMessage(messages.selectGuide)}
        selectedValue={selectedGuide}
        onSearch={handleSearch}
      />
    </Dialog>
  );
};

export default injectIntl(observer(MoveToDialog));
