import {action, observable, makeObservable} from 'mobx';

class ExamplesDialog {
  @observable opened = false;
  @observable images;
  @observable title;

  constructor() {
    makeObservable(this);
  }

  @action
  reset = () => {
    this.opened = false;
    this.title = null;
    this.images = null;
  };

  @action
  open = (images, title) => {
    this.opened = true;
    this.images = images;
    this.title = title;
  };
}

export default ExamplesDialog;
