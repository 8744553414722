import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {Header, BackButton, BackIcon} from './styles';
import messages from './messages';
import {CreateSkillTask} from 'api/learning-activities/mutations';
import {createSkillTaskOptions} from 'api/learning-activities/mutation-options';
import StepsTabs from 'components/StepsTabs';
import views from 'config/views';

//components
import Container from 'ui-components/Layout/Container';

import {mapValues, pick} from 'lodash';

@inject('store')
@graphql(CreateSkillTask, createSkillTaskOptions)
@observer
class CreateLearningActivity extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {learningActivityGeneralInfo}
    } = this.props;

    const translations = mapValues(
      pick(messages, [
        'generalInformation',
        'assignToSkill',
        'failedCreateLearningActivity',
        'successCreateLearningActivity'
      ]),
      message => formatMessage(message)
    );
    learningActivityGeneralInfo.setTranslations(translations);
  }

  componentWillUnmount() {
    const {
      store: {learningActivityGeneralInfo, skillsPage}
    } = this.props;

    if (learningActivityGeneralInfo.previousPage === views.createSkills && !skillsPage.backFromLearnings) {
      skillsPage.setCreateSkillName('');
      skillsPage.setSkillNameExists(false);
      skillsPage.setIsActivitiesDrawerOpened(false);
      skillsPage.deleteAllActivities();
      skillsPage.setSelectedFrequencyOption(null);
    } else if (learningActivityGeneralInfo.previousPage === views.editSkills && !skillsPage.backFromLearnings) {
      skillsPage.setIsActivitiesDrawerOpened(false);
    }
    learningActivityGeneralInfo.updatePreviousPage(null);
  }

  formSubmit = () => {
    const {
      createSkillTaskMutation,
      store: {learningActivityGeneralInfo}
    } = this.props;
    learningActivityGeneralInfo.createLearningActivity(createSkillTaskMutation, false);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        learningActivityGeneralInfo,
        learningActivityGeneralInfo: {stepsStore}
      }
    } = this.props;

    const navigateBack = () => {
      const {
        store: {router, learningActivityGeneralInfo, skillsPage}
      } = this.props;
      if (learningActivityGeneralInfo.previousPage === views.createSkills) {
        skillsPage.updateBackFromLearnings(true);
        return router.goTo(views.createSkills, {});
      }
      if (learningActivityGeneralInfo.previousPage === views.editSkills) {
        skillsPage.updateBackFromLearnings(true);
        return router.goTo(views.editSkills, {id: skillsPage.skillForEditing.id});
      }

      return router.goTo(views.learningActivities, {});
    };
    return (
      <Container style={{paddingBottom: '50px', height: 'auto'}}>
        <Header>
          <BackButton onClick={navigateBack}>
            <BackIcon />
          </BackButton>
          {formatMessage(messages.createLearningActivity)}
        </Header>
        <StepsTabs
          mode={'edit'}
          loading={false}
          error={null}
          stepsStore={stepsStore}
          page={learningActivityGeneralInfo}
          fullHeight
          customPrimaryButtonText={formatMessage(messages.create)}
          formSubmit={this.formSubmit}
          cancel={navigateBack}
          canSubmit={learningActivityGeneralInfo.canSubmit}
        />
      </Container>
    );
  }
}

export default injectIntl(CreateLearningActivity);
