import {action, autorun, computed, observable, makeObservable, toJS} from 'mobx';
import {isEmpty, isMatch, noop, findIndex, find} from 'lodash';

import views from 'config/views';
import store from 'stores/store';
import notification from 'utils/notification-utils';

class AppStore {
  constructor() {
    makeObservable(this);
  }

  @observable title = 'swipeguide';
  @observable translations = {};
  disposeAutorun = noop;

  //navMenu
  @observable isNavMenuOpened = false;
  @observable.ref selectedNavMenuItem = null;
  @observable.ref selectedNavSubMenuItem = null;

  //actionMenu
  @observable actionMenuWrapperRef;
  @observable isActionMenuOpen = false;
  @observable actionMenuItems = [];

  //platform language
  @observable isPlatformLanguageDialogOpen = false;

  @action
  openPlatformLanguageDialog = () => {
    this.isPlatformLanguageDialogOpen = true;
  };

  @action
  toggleNavMenu = (value = !this.isNavMenuOpened) => {
    this.isNavMenuOpened = value;
  };

  @action
  toggleActionMenu = (value = !this.isActionMenuOpen) => {
    this.isActionMenuOpen = value;
  };

  @action
  setActionMenuItems = items => {
    this.actionMenuItems = items;
  };

  @action
  resetActionMenuItems = () => {
    this.actionMenuItems = [];
  };

  @action
  setActionMenuWrapperRef = wrapper => {
    this.actionMenuWrapperRef = wrapper;
  };

  @action
  setTheme = theme => {
    const whiteColorHex = '#ffffff';
    const defaultColorHex = '#5C9CAA';

    const fullTheme = {
      ...theme,
      buttonColor:
        !isEmpty(theme.buttonColor) && theme.buttonColor !== whiteColorHex ? theme.buttonColor : defaultColorHex,
      mainColor: !isEmpty(theme.mainColor) && theme.mainColor !== whiteColorHex ? theme.mainColor : defaultColorHex
    };

    this.theme = fullTheme;
  };

  @computed
  get hasSubmenu() {
    return Boolean(this.selectedNavMenuItem && this.selectedNavMenuItem.sections);
  }

  @computed
  get selectedSubmenuItemIndex() {
    return (
      this.selectedNavMenuItem &&
      this.selectedNavSubMenuItem &&
      findIndex(this.selectedNavMenuItem.sections, {id: this.selectedNavSubMenuItem.id})
    );
  }

  @computed
  get menuItems() {
    const {
      auth: {user},
      platform: {hasChecklistsEnabled, hasConfigurableAlertsRecipients}
    } = store;
    if (!user) {
      return [];
    }
    const {
      isPlatformAdmin,
      canManageThemes,
      canViewAnalytics,
      canManageCampaigns,
      canViewUsers,
      canViewTeams,
      canManageUsers,
      canManageDomains,
      canManageSkills,
      canCreateTags,
      isIPA
    } = user;

    const thoughtspotAdoptionItem = {
      id: 'adoptionThoughtspot',
      view: views.thoughtspot,
      caption: this.translations.thoughtspot,
      disabled: !canViewAnalytics,
      links: [
        {
          id: 'platformAdoption',
          caption: this.translations.platformAdoption,
          view: views.thoughtspot,
          params: {page: 'platform'},
          disabled: !canViewAnalytics
        },
        {
          id: 'workspaceAdoption',
          caption: this.translations.workspaceAdoption,
          views: views.thoughtspot,
          params: {page: 'workspace'},
          disabled: !canViewAnalytics
        }
      ]
    };

    const smartSkillsMenuItems = canManageSkills
      ? [
          {
            id: 'smartSkills',
            caption: this.translations.smartSkills,
            icon: 'smart-skills',
            disabled: !canManageSkills,
            sections: [
              {
                id: 'skillsMatrix',
                caption: this.translations.skillsMatrix,
                view: views.smartSkills,
                disabled: !canManageSkills,
                relatedViews: [views.smartSkills, views.smartSkillsTeam, views.smartSkillsUser]
              },
              {
                id: 'skills',
                caption: this.translations.skills,
                view: views.skills,
                params: {tab: 'general'},
                disabled: !canManageSkills
              },
              {
                id: 'skillProfiles',
                caption: this.translations.skillProfiles,
                view: views.skillProfiles,
                disabled: !canManageSkills
              },
              {
                id: 'learningActivities',
                caption: this.translations.learningActivities,
                view: views.learningActivities,
                disabled: !canManageSkills
              }
            ]
          }
        ]
      : [];
    const configurableAlertsRecipientsMenuItem =
      hasConfigurableAlertsRecipients && isPlatformAdmin
        ? [
            {
              id: 'checklistAlerts',
              caption: this.translations.checklistAlerts,
              view: views.checklistAlerts,
              relatedViews: [views.checklistAlerts]
            }
          ]
        : [];

    const jobTitlesItem = canManageSkills
      ? [
          {
            id: 'jobTitles',
            caption: this.translations.jobTitles,
            view: views.jobTitles,
            disabled: !canManageSkills,
            relatedViews: [views.createJobTitle, views.editJobTitle]
          }
        ]
      : [];

    const menuItems = [
      {
        id: 'guides',
        caption: this.translations.guides,
        icon: 'guides',
        sections: [
          {
            id: 'myGuides',
            caption: this.translations.guideOverview,
            view: views.guides,
            relatedViews: [
              views.newGuide,
              views.editGuide,
              views.editInstruction,
              views.translateGuide,
              views.translateLocale,
              views.translateTopicLocale,
              views.translateInstructionLocale,
              views.translateStepLocale,
              views.translateHintLocale
            ]
          },
          {
            id: 'campaigns',
            caption: this.translations.signoffs,
            disabled: !canManageCampaigns,
            view: views.campaigns,
            relatedViews: [views.newCampaign, views.editCampaign]
          }
        ]
      },
      ...smartSkillsMenuItems,
      {
        id: 'analytics',
        caption: this.translations.analytics,
        icon: 'analytics',
        sections: [
          {
            id: 'checklists',
            caption: this.translations.checklistResults,
            disabled: !hasChecklistsEnabled,
            view: views.checklists
          },
          {
            id: 'campaignsResults',
            caption: this.translations.signoffResults,
            disabled: !canManageCampaigns,
            view: views.campaignsResults
          },
          thoughtspotAdoptionItem
        ]
      },
      {
        id: 'userManagement',
        caption: this.translations.userManagement,
        icon: 'user-management',
        disabled: !canViewTeams && !canManageUsers,
        sections: [
          {
            id: 'teams',
            caption: this.translations.teams,
            view: views.teams,
            disabled: !canViewTeams,
            relatedViews: [views.newTeam, views.editTeam, views.teamUsers, views.newTeamUsers, views.teamWorkspaces]
          },
          {
            id: 'users',
            caption: this.translations.users,
            view: views.users,
            disabled: !canViewUsers && !canManageUsers,
            relatedViews: [views.newUser, views.editUser]
          },
          ...jobTitlesItem
        ]
      },
      {
        id: 'settings',
        caption: this.translations.settings,
        icon: 'settings',
        disabled: !canManageDomains && !canManageThemes && !canCreateTags,
        sections: [
          {
            id: 'platformSettings',
            caption: this.translations.platform,
            view: views.platformSettings,
            disabled: !isPlatformAdmin,
            relatedViews: [views.platformSettings]
          },
          {
            id: 'workspaces',
            caption: this.translations.workspaces,
            view: views.workspaces,
            disabled: !canManageDomains,
            relatedViews: [views.newWorkspace, views.editWorkspace, views.workspaceTeams, views.workspaceNewTeams]
          },
          {
            id: 'tags',
            caption: this.translations.tags,
            view: views.tags,
            disabled: !canCreateTags,
            relatedViews: [views.editTag]
          },
          ...configurableAlertsRecipientsMenuItem,
          {
            id: 'themes',
            caption: this.translations.themes,
            view: views.themes,
            disabled: !canManageThemes,
            relatedViews: [views.newTheme, views.editTheme]
          }
        ]
      },
      {
        id: 'learningHub',
        caption: this.translations.learningHub,
        icon: 'learning-hub',
        href: 'https://l2l.thinkific.com',
        secondaryIcon: 'open-in-new'
      }
    ];

    if (isIPA) {
      const settings = find(menuItems, {id: 'settings'});
      settings.sections.push({
        id: 'ipaPanel',
        caption: 'Admin panel',
        view: views.ipapanel
      });
    }

    return menuItems;
  }

  @action
  setTranslations = (translations = {}) => {
    this.translations = {...this.translations, ...translations};
  };

  handleGoesOffline = () => notification.error(this.translations.goesOffline);

  handleGoesBackOnline = () => notification.success(this.translations.goesBackOnline);

  isCurrentView = (view, params) => {
    const {router} = store;

    return view && router.currentRoute.path === view.path && isMatch(toJS(store.router.params), toJS(params));
  };

  @action
  setSelectedNavMenuItem = menuItem => (this.selectedNavMenuItem = menuItem);

  @action
  setSelectedNavSubMenuItem = section => (this.selectedNavSubMenuItem = section);

  startListeningToRouteChange = () => {
    this.disposeAutorun = autorun(() => {
      this.setSelectedNavMenuItem(null);
      this.setSelectedNavSubMenuItem(null);

      for (const menuItem of this.menuItems) {
        if (!menuItem.sections || menuItem.disabled) {
          continue;
        }

        for (const section of menuItem.sections) {
          if (this.isCurrentView(section.view, section.params)) {
            this.setSelectedNavMenuItem(menuItem);
            this.setSelectedNavSubMenuItem(section);
            return;
          }

          if (section.relatedViews) {
            for (const relatedView of section.relatedViews) {
              if (this.isCurrentView(relatedView)) {
                this.setSelectedNavMenuItem(menuItem);
                this.setSelectedNavSubMenuItem(section);
                return;
              }
            }
          }

          if (section.links) {
            for (const link of section.links) {
              if (this.isCurrentView(link.view, link.params)) {
                this.setSelectedNavMenuItem(menuItem);
                this.setSelectedNavSubMenuItem(section);
                return;
              }
            }
          }
        }
      }
    });
  };

  stopListeningToRouteChange = () => {
    this.disposeAutorun();
  };
}

export default AppStore;
