import {JobTitles} from 'api/skills/queries';
import {jobTitlesOptions} from 'api/skills/query-options';

export const createSkillRoleOptions = {
  props: ({mutate}) => ({
    createSkillRoleMutation: variables =>
      mutate({
        variables,
        refetchQueries: [
          {
            query: JobTitles,
            ...jobTitlesOptions
          }
        ]
      })
  })
};

export const editSkillRoleOptions = {
  props: ({mutate}) => ({
    editSkillRoleMutation: variables => {
      return mutate({
        variables,
        refetchQueries: []
      });
    }
  })
};
