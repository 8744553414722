import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectWorkspaces: {
    id: 'EditGuide.selectWorkspaces',
    defaultMessage: 'Select workspaces'
  },
  workspaces: {
    id: 'EditCampaign.workspaces',
    defaultMessage: 'Workspaces'
  },
  main: {
    id: 'PublishGuide.main',
    defaultMessage: '(main)'
  },
  visibleOnGuideLevel: {
    id: 'visibleOnGuideLevel',
    defaultMessage: 'Make this Guide visible to other Teams on the main Workspace'
  },
  visibleOnTeamsLevel: {
    id: 'visibleOnTeamsLevel',
    defaultMessage: 'Make this Team’s Guides visible to other Teams on the main Workspace'
  },
  makeAvailableInOtherWorkspaces: {
    id: 'PublishGuide.makeAvailableInOtherWorkspaces',
    defaultMessage: 'Make available for copy in other workspaces'
  }
});

export default messages;
