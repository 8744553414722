import styled from 'styled-components';
import $Icon from 'shared/components/Icon';

export const styles = {
  height: '56px',
  padding: '0px 40px 0px 16px',
  marginBottom: '5px'
};

export const ErrorNotification = styled.div`
  color: #f31414;
  font-size: 14px;
  font-family: lato;
  padding: 1px 2px 15px 10px;
  margin-left: 10px;
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
`;

export const StyledErrorIcon = styled($Icon).attrs({})`
  width: 16px;
  color: #f31414;
  display: inline-block;
  position: absolute;
`;
