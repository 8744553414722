import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {clone, mapValues, pick} from 'lodash';
import views from 'config/views';
import mutationNames from 'api/team/constants';
import {TEAM_MEMBER_ROLE} from 'shared/enums';
import {isUserUsingPhone} from 'utils/data-utils';

//styled-components
import Container from 'ui-components/Layout/Container';
import UserPicker from 'components/UserPicker';
import RolePicker from 'components/RolePicker';

// queries
import {EnabledUsersLite} from 'api/user/queries';
import {SkillRoles} from 'api/skill-matrix/queries';
import {SkillRolesOptions} from 'api/skill-matrix/query-options';

//options
import {userNamesOptions} from 'api/user/query-options';

//components
import LoadingMessage from 'components/LoadingMessage';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import {FormattedMessage} from 'components/FormattedComponents';
import Button from 'ui-components/Button';
import ButtonGroup from 'ui-components/ButtonGroup';

//messages
import messages from './messages';

//styles
import {ContentWrapper, Field, PaddingContainer, PickerRow, RemoveButton, Scroller, WrapperDiv} from './styles';

@inject('store')
@graphql(SkillRoles, SkillRolesOptions)
@graphql(EnabledUsersLite, userNamesOptions)
@observer
class NewTeamUsersView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      teamPage,
      router
    } = store;
    const {canManageTeams} = user;
    if (!canManageTeams) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, ['createSuccess', 'createFailure', 'createMultipleSuccess', 'createMultipleFailure']),
      message => formatMessage(message)
    );

    teamPage.setTranslations(translations);
    teamPage.setNewForm();
  }

  scrollToLastPicker() {
    window.requestAnimationFrame(() => {
      const scroller = document.querySelector('#scroller');
      scroller.scrollTop = scroller.scrollHeight;
    });
  }

  componentDidUpdate() {
    const {
      setDisabled,
      store: {
        teamPage: {areSelectionsValid}
      }
    } = this.props;

    setDisabled(!areSelectionsValid);
  }

  render() {
    const {
      intl: {formatMessage},
      userNamesQuery,
      skillRolesQuery: {loading: loadingSkillRoles, skillRoles},
      store,
      editTeam
    } = this.props;
    const {loading, error} = userNamesQuery;
    const {id} = store.router.params;
    const showLoading = loading || loadingSkillRoles || error;
    const {
      teamPage,
      addUserToTeamDialog: {usersRowCount, incrementUsersRowCount},
      platform: {hasSkillsEnabled}
    } = store;
    const {
      userTeamRoles,
      reset,
      allUsers,
      isSelectionValid,
      isAddUserRoleEnabled,
      removeUserRolePicker,
      addUserRolePicker,
      selectedIds,
      userRoleSelection
    } = teamPage;
    const createTeamMemberMutation = this.props[mutationNames.CREATE_TEAM_USERS];
    const maxCountLimit = allUsers.length - 1;
    const disableAddAnotherUser =
      (!editTeam && !isAddUserRoleEnabled) ||
      allUsers.length === userRoleSelection.length ||
      usersRowCount === maxCountLimit;
    const sortedSkillRoles = (clone(skillRoles) || []).sort((a, b) => a.name.localeCompare(b.name));
    const skillRoleList = [{id: null, name: formatMessage(messages.noJobTitle)}, ...sortedSkillRoles];

    const goToTeamUsersList = () => {
      store.router.goTo(views.teamUsers, {id});
    };

    const cancel = () => {
      reset();
      goToTeamUsersList();
    };

    if (showLoading)
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );

    if (!showLoading && allUsers.length === 0) {
      return (
        <Container noPaddingLeft={editTeam}>
          {!editTeam && <ViewTitle title={formatMessage(messages.title)} />}
          <FormattedMessage {...messages.noUsersAvailable} />
        </Container>
      );
    }

    return (
      <Container noPaddingLeft={editTeam}>
        {!editTeam && <ViewTitle title={formatMessage(messages.title)} />}
        <ContentWrapper editTeam={editTeam}>
          <WrapperDiv>
            <Scroller>
              {userTeamRoles?.map((userRole, index) => {
                return editTeam && usersRowCount < index ? null : (
                  <PickerRow key={`${userRole.userID}-${index}`}>
                    <Field
                      size="small"
                      inputComponent={
                        <UserPicker
                          dataCy={`user-picker-${index}`}
                          field="userId"
                          filterOption={selectedIds}
                          form={userRole.form}
                          users={allUsers}
                          {...(!index && {label: formatMessage(messages.selectUser)})}
                        />
                      }
                    />
                    <Field
                      size="small"
                      inputComponent={
                        <RolePicker
                          dataCy={`role-picker-${index}`}
                          allowClear={false}
                          form={userRole.form}
                          field="role"
                          {...(!index && {label: formatMessage(messages.selectRole)})}
                          {...(isUserUsingPhone(allUsers, userRole?.form?.$('userId').value) && {
                            roles: [{name: TEAM_MEMBER_ROLE.VIEWER, id: TEAM_MEMBER_ROLE.VIEWER}]
                          })}
                        />
                      }
                    />
                    {hasSkillsEnabled && (
                      <Field
                        size="small"
                        inputComponent={
                          <RolePicker
                            roles={skillRoleList}
                            placeholder={formatMessage(messages.noJobTitle)}
                            dataCy={`skill-role-picker-${index}`}
                            allowClear={false}
                            form={userRole.form}
                            field="skillRoleId"
                            {...(!index && {label: formatMessage(messages.selectJobTitle)})}
                          />
                        }
                      />
                    )}
                    <RemoveButton
                      removeMargin={!index}
                      onClick={() => {
                        removeUserRolePicker(index, editTeam);
                      }}
                    />
                  </PickerRow>
                );
              })}
            </Scroller>
            <PaddingContainer>
              <Button
                underlined
                style={{height: 'auto'}}
                disabled={disableAddAnotherUser}
                iconId="add"
                onClick={() => {
                  incrementUsersRowCount(maxCountLimit);
                  addUserRolePicker();
                  this.scrollToLastPicker();
                }}
                label={formatMessage(messages.addAnotherUser)}
                dataCy="add-another-user-picker"
              />
            </PaddingContainer>
          </WrapperDiv>
        </ContentWrapper>
        {!editTeam && (
          <ButtonGroup
            bottomFixed
            style={{paddingRight: 50}}
            primary={{
              disabled: !isSelectionValid,
              loading: loading,
              label: formatMessage(messages.save),
              onClick: () => teamPage.submitNewTeamMembers({createTeamMemberMutation, goToTeamUsersList, teamId: id})
            }}
            secondary={{
              onClick: () => cancel(),
              label: formatMessage(messages.cancel)
            }}
          />
        )}
      </Container>
    );
  }
}

export default injectIntl(NewTeamUsersView);
