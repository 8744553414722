import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ColorPicker.title',
    defaultMessage: 'HEX Color'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  }
});

export default messages;
