import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//components
import {Dialog} from 'ui-components';
import Field from 'shared/components/Field';
import {bindField} from 'shared/utils/input-utils';

//messages

import messages from './messages';

@inject('store')
@observer
class RequestApprovalDialog extends Component {
  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {requestApprovalDialog} = store;
    const {opened, reset, requestApproval, versionId, form} = requestApprovalDialog;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.requestApproval),
            dataCy: 'request-approval',
            onClick: requestApproval,
            disabled: !form.isValid
          }
        ]}
        isOpen={opened}
        onCancel={reset}
        title={
          versionId ? formatMessage(messages.publishingRequiresApproval) : formatMessage(messages.requestingApproval)
        }
      >
        <Field
          styles={{Input: {height: '120px', padding: '10px 16px', marginTop: '20px'}}}
          inputType="textarea"
          title={
            versionId
              ? formatMessage(messages.requestApprovalBodyOldVersion)
              : formatMessage(messages.requestApprovalBody)
          }
          {...bindField(form, 'comment')}
        />
      </Dialog>
    );
  }
}

export default injectIntl(RequestApprovalDialog);
