import styled from 'styled-components';
import {Button as $Button} from 'styles/styled-components/shared-components';
import {idealTextColorAgainstWhite} from 'shared/utils/color-utils';

export const CropImageWrapper = styled.div`
  width: 100%;
  height: 420px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
`;

export const Container = styled.div`
  position: relative;
  transform: rotate(${p => p.rotation}deg);
`;

export const Image = styled.div`
  top: 0;
  left: 0;
`;

export const Canvas = styled.div`
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  filter: brightness(40%);
`;

export const ColorPicker = styled.div`
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  height: 35px;
  display: flex;
  padding: 5px 18px 5px 18px;
  margin-left: 5px;
`;

export const Color = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background-color: ${p => p.color};
  border: 1px solid ${p => idealTextColorAgainstWhite(p.color)};
  margin: auto;
`;

export const ColorLabel = styled.div`
  margin-left: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: space-around;
  margin-top: 16px;
  flex-wrap: wrap;
`;

export const ColorPickerWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 300px;
  justify-content: space-evenly;
  margin-bottom: 16px;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;

export const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const Button = styled($Button)`
  margin-right: 32px;
  margin-bottom: 16px;
  background-color: #fff;
  color: #000;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
  height: 35px;
  font-family: Lato;
  font-weight: bold;
`;

export const TipWrapper = styled.div`
  background-color: #cce4fe;
  color: #2c71c0;
  border-radius: 3px;
  width: 100%;
  margin: 25px 0;
  line-height: 40px;
  padding: 5px 18px 5px 18px;
`;

export const WarningWrapper = styled.div`
  background-color: #fdf1ed;
  color: #e9633f;
  border-radius: 3px;
  width: 100%;
  margin: 25px 0;
  padding: 16px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Link = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.33px;
  margin-left: 25px;
  align-content: right;
`;

export const ImageFitWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: space-around;
  margin: -15px 25px 0 25px;
`;
