import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import Chip from 'ui-components/Chip';

export const StyledChip = styled(Chip)`
  background-color: ${commonPalette.bannerInformational};
  color: ${commonPalette.iconInformational};
  margin-right: 5px;
  display: inline-block;
`;

export const StyledChipContainer = styled.div`
  :hover {
    cursor: default;
  }
`;
