import React from 'react';

import Button from 'ui-components/Button';

import {Item} from './styles';

const TokenItem = ({token, onDelete, label}) => (
  <Item key={token.jti}>
    {token.hint}
    <Button label={label} size={32} secondary={true} iconId="remove" onClick={() => onDelete(token.jti)} />
  </Item>
);

export default TokenItem;
