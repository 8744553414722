import styled from 'styled-components';

import Select from 'ui-components/Select';

export const StyledSelect = styled(Select)`
  flex: 2;
  margin-top: 15px;
  width: 275px;
`;

export const WrapperInfo = styled.div`
  flex: 4;
  margin-top: 5px;
`;
