import gql from 'graphql-tag';

export const ChecklistTemplates = gql`
  query checklistTemplates($locale: String!) {
    checklistTemplates(locale: $locale) {
      results {
        id
        name
      }
    }
  }
`;
