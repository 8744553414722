import gql from 'graphql-tag';

export const SignMedia = gql`
  mutation sign($params: MediaUploadParams) {
    sign(params: $params) {
      timestamp
      public_id
      signature
    }
  }
`;

export const CacheMedia = gql`
  mutation cache($publicId: String!, $resourceType: String!, $eagerOptions: String!) {
    cache(publicId: $publicId, resourceType: $resourceType, eagerOptions: $eagerOptions)
  }
`;

export const EditMedia = gql`
  mutation EditMedia($id: String!, $mediaEdit: MediaEdit!) {
    editMedia(id: $id, media: $mediaEdit) {
      id
      metadata
      fileName
    }
  }
`;

export const SaveMedia = gql`
  mutation saveMedia($url: String!) {
    saveMedia(url: $url) {
      id
    }
  }
`;
