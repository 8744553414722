import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createInstruction: {
    id: 'common.actions.newInstruction',
    defaultMessage: 'New Instruction'
  },
  createChecklist: {
    id: 'createChecklist',
    defaultMessage: 'New Checklist'
  }
});

export default messages;
