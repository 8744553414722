import React from 'react';
import {truncate} from 'lodash';

import {FormattedMessage} from 'components/FormattedComponents';

import {getEventConfig, shouldGetStepLink, incrementNumber} from './../utils';
import messages from './../messages';
import {FlexRow, EventHeader, LinkSection, EventWrapper} from './../styles';
import {Person} from './../Person';
import {StepLink} from './../Links/StepLink';
import {ActivityEvent, Person as PersonType} from '../types';

export type StepCheckEventProps = {
  activity: ActivityEvent;
  canLink: boolean;
  router: any;
  checkStepLinkIsValid: any;
  Chevron: () => React.JSX.Element;
  TimeAndDate: () => React.JSX.Element;
  Info: (props: any) => React.JSX.Element;
};

const StepCheckEvent = (props: StepCheckEventProps) => {
  const {activity, canLink, router, checkStepLinkIsValid, Chevron, TimeAndDate, Info} = props;

  const {
    eventType,
    versionId,
    position,
    instructionTitle,
    beforePosition,
    afterPosition,
    guide,
    beforeNote,
    afterNote,
    beforeNoteMedia,
    afterNoteMedia
  } = activity;
  const stepLink = shouldGetStepLink(eventType) ? StepLink : undefined;

  const instructionTitleDisplayText = truncate(instructionTitle, {length: 300});
  const hasPosition = position !== null;
  const isRevisionHistoryStepEvent = hasPosition && instructionTitle;

  let iconType;
  if (eventType === 'step_note_content_updated') {
    iconType = beforeNote?.icon?.type ?? afterNote?.icon?.type;
  } else if (eventType === 'step_note_visual_updated') {
    iconType = beforeNoteMedia?.icon?.type ?? afterNoteMedia?.icon?.type;
  }

  const eventConfig = getEventConfig(eventType, !!isRevisionHistoryStepEvent, stepLink);

  return (
    <FlexRow>
      <Chevron />
      <EventWrapper>
        <EventHeader>
          <TimeAndDate />
          <LinkSection>
            {canLink && eventConfig.LinkComponent && versionId && (
              <eventConfig.LinkComponent
                {...activity}
                router={router}
                linkValidityChecks={{
                  checkStepLinkIsValid
                }}
              >
                <FormattedMessage {...messages.view} />
              </eventConfig.LinkComponent>
            )}
          </LinkSection>
        </EventHeader>
        <Info>
          <FormattedMessage
            {...eventConfig.message}
            values={{
              person: <Person person={activity.producedBy} />,
              iconType,
              assignee: <Person person={activity.newAssignee as PersonType} />,
              instructionTitle: instructionTitleDisplayText,
              alertRecipientsCount: activity.count,
              beforePosition: incrementNumber(beforePosition),
              afterPosition: incrementNumber(afterPosition),
              position: incrementNumber(position),
              guide
            }}
          />
        </Info>
      </EventWrapper>
    </FlexRow>
  );
};

export default StepCheckEvent;
