import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';

// components
import {FormattedMessage} from 'components/FormattedComponents';
import LoadingMessage from 'components/LoadingMessage';
import Container from 'ui-components/Layout/Container';
import ButtonGroup from 'ui-components/ButtonGroup';
import Chip from 'ui-components/Chip';

//styled-components
import {StyledViewTitle, StyledSlug, RemoveButton, DataTable} from './styles';
import {HeaderWrapper, CellWrapper} from 'ui-components/Layout/DataTable/styles';

//data
import {Domain} from 'api/domain/queries';
import {domainOptions} from 'api/domain/query-options';
import {RemoveTeamFromDomain} from 'api/domain/mutations';
import {removeTeamFromDomainOptions} from 'api/domain/mutation-options';

//messages
import messages from './messages';

//views
import views from 'config/views';

@inject('store')
@graphql(Domain, domainOptions)
@graphql(RemoveTeamFromDomain, removeTeamFromDomainOptions)
@observer
class WorkspaceTeamsViewComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {
      auth: {user},
      router
    } = store;
    const {workspaceTeamsPage} = store;
    const {canManageDomains} = user;

    if (!canManageDomains) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(pick(messages, ['removeTeamSuccess', 'removeTeamFailure']), message =>
      formatMessage(message)
    );

    workspaceTeamsPage.setTranslations(translations);
  }

  componentDidMount() {
    const {store} = this.props;
    const {router, app} = store;
    const id = router.params.id;

    const actionMenuItems = [
      {
        id: 'create',
        view: 'workspaceNewTeams',
        params: {id}
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  remove = team => {
    const {
      domainQuery: {domain},
      removeTeamFromDomainMutation,
      intl: {formatMessage},
      store
    } = this.props;
    const {archiveDialog} = store;

    const translations = {
      action: formatMessage(messages.removeTeam),
      confirmation: formatMessage(messages.removeTeamConfirm),
      body: formatMessage(messages.removeTeamBody),
      archiveSuccess: formatMessage(messages.removeTeamSuccess),
      archiveFailure: formatMessage(messages.removeTeamFailure)
    };

    archiveDialog.open({
      params: {teamId: team.id, domainId: domain.id},
      mutation: removeTeamFromDomainMutation,
      translations,
      itemPrimary: team.name
    });
  };

  render() {
    const {
      domainQuery: {domain, loading, error},
      intl: {formatMessage},
      store
    } = this.props;
    const {
      platform: {sso}
    } = store;
    const showLoading = loading || error;
    const workspaceTeams = domain && domain.teams;
    const managementTeamId = domain && domain.managementTeam.id;
    const defaultTeamId = domain && domain.defaultTeam.id;
    const workspaceName = domain && domain.name;
    const workspaceSlug = domain && domain.slug;

    if (showLoading)
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );

    return (
      <Container>
        <StyledViewTitle title={workspaceName} />
        <StyledSlug>
          {domain.isDefault
            ? `https://${window.location.hostname}`
            : `https://${workspaceSlug}-${window.location.hostname}`}
        </StyledSlug>
        <DataTable
          defaultSorted={[
            {
              id: 'name',
              desc: false
            }
          ]}
          hoverEffect
          data={workspaceTeams}
          noDataMessage={formatMessage(messages.noTeams)}
          columns={[
            {
              Header: <HeaderWrapper>{formatMessage(messages.teamName)}</HeaderWrapper>,
              accessor: 'name',
              resizable: true,
              Cell: ({value}) => (
                <CellWrapper>
                  <span title={value}>{value}</span>
                </CellWrapper>
              )
            },
            {
              accessor: 'id',
              Cell: ({value: team}) => {
                if (team === managementTeamId) {
                  return (
                    <CellWrapper>
                      <Chip
                        borderGrey
                        iconId="team"
                        label={formatMessage(messages.managementTeam)}
                        popoverContent={formatMessage(messages.managementTeamDescription)}
                      />
                    </CellWrapper>
                  );
                }
              },
              sortable: false
            },
            {
              accessor: 'id',
              Cell: ({value: team}) => {
                if (sso && team === defaultTeamId) {
                  return (
                    <CellWrapper>
                      <Chip
                        borderGrey
                        iconId="team"
                        label={formatMessage(messages.defaultTeamForNewUsers)}
                        popoverContent={formatMessage(messages.defaultTeamDescription)}
                      />
                    </CellWrapper>
                  );
                }
              },
              sortable: false
            },
            {
              accessor: 'id',
              Cell: ({value: id, original: team}) => {
                if (id !== managementTeamId) {
                  return (
                    <CellWrapper>
                      <RemoveButton onClick={() => this.remove(team)} />
                    </CellWrapper>
                  );
                }
              },
              sortable: false,
              width: 30
            }
          ]}
        />
        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          secondary={{
            label: formatMessage(messages.backToOverview),
            onClick: () => store.router.goTo(views.workspaces, {})
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(WorkspaceTeamsViewComponent);
