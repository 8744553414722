import {guessCountryByPartialPhoneNumber} from 'react-international-phone';

export function maskPhoneNumber(phoneNumber) {
  const guessedCountry = guessCountryByPartialPhoneNumber({phone: phoneNumber});
  let mask = guessedCountry.country?.format;

  if (!mask) return phoneNumber;
  if (typeof mask !== 'string') {
    if (mask.default) mask = mask.default;
    else return phoneNumber;
  }
  const dialCode = guessedCountry.country?.dialCode;
  const prefix = '+';

  let numberIndex = (dialCode?.length || 0) + 1;

  const maskedNumber =
    prefix +
    dialCode +
    ' ' +
    (mask as string)
      ?.split('')
      .map(char => {
        if (char === '.') return phoneNumber[numberIndex++];
        return char;
      })
      .join('');

  return maskedNumber;
}
