import React, {ReactElement, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {DataTable, StyledMenu, LoaderContainer} from './styles';
import messages from './messages';
import {HeaderWrapper, CellWrapper, StyledText} from 'ui-components/Layout/DataTable/styles';
import {AlertRecipientsQuery} from 'api/alerts-recipients/queries';
import LoadingMessage from 'components/LoadingMessage';
import {useQuery} from '@apollo/client';
import views from 'config/views';
import InfoBox from 'ui-components/InfoBox';
//components
import {FormattedMessage} from 'components/FormattedComponents';
//styles
import {DropdownMenu, DropdownMenuButton, DropdownMenuItem} from 'styles/styled-components/dropdown-styled-components';
import type {InjectedIntl, FormattedMessage as FormattedMessageType} from 'react-intl';

type RecipientProps = {
  store: any;
  intl: InjectedIntl;
};

const LoadingState = () => (
  <LoaderContainer>
    <LoadingMessage></LoadingMessage>
  </LoaderContainer>
);

const ErrorState = ({
  messages
}: {
  messages: {
    errorLoadingRecipients: FormattedMessageType.MessageDescriptor;
  };
}) => {
  return <FormattedMessage {...messages.errorLoadingRecipients} />;
};

const Recipients = (props: RecipientProps): ReactElement => {
  interface AlertRecipients {
    name?: string;
    configuration?: {
      webhooks: [{url: string}];
      microsoftTeams: [{url: string}];
    };
  }

  const {
    intl: {formatMessage},
    store
  } = props;

  useEffect(() => {
    const {
      store: {app}
    } = props;
    const actionMenuItems = [
      {
        id: 'create',
        view: 'newAlertRecipient'
      }
    ];
    app.setActionMenuItems(actionMenuItems);
  }, []);

  const noRecipientsMessage: string = formatMessage(messages.noRecipients);

  const alertRecipientsQuery = useQuery(AlertRecipientsQuery);
  const alertRecipients: [AlertRecipients] = alertRecipientsQuery?.data?.alertRecipients?.results || [];

  if (alertRecipientsQuery.loading) {
    return <LoadingState />;
  }

  if (alertRecipientsQuery.error) {
    return <ErrorState messages={messages} />;
  }
  const columns = [
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.recipientName)}</HeaderWrapper>,
      accessor: 'name',
      resizable: true,
      width: 250,
      Cell: ({value, original: {isDisabled}}) => (
        <CellWrapper paddingLeft disabled={isDisabled}>
          <span title={value}>{value}</span>
        </CellWrapper>
      )
    },
    {
      Header: <StyledText marginLeft={20}>{formatMessage(messages.deliveryMethods)}</StyledText>,
      accessor: 'configuration',
      resizable: true,
      Cell: ({original: {configuration}}) => (
        <CellWrapper>
          <StyledText marginLeft={20}>
            {[
              configuration?.webhooks?.[0]?.url ? 'Webhook' : null,
              configuration?.webhooksMsTeams?.[0]?.url ? 'Microsoft Teams' : null
            ]
              .filter(Boolean)
              .join(' / ')}
          </StyledText>
        </CellWrapper>
      )
    },

    {
      Header: <StyledText>{formatMessage(messages.actions)}</StyledText>,
      Cell: ({original: {id}}) => (
        <StyledMenu
          dropdownMenu={
            <DropdownMenu>
              <DropdownMenuItem>
                <DropdownMenuButton onClick={() => store.router.goTo(views.editAlertRecipient, {id})}>
                  <FormattedMessage {...messages.edit} />
                </DropdownMenuButton>
              </DropdownMenuItem>
              <DropdownMenuItem>
                {/* <DropdownMenuButton onClick={() => console.log('CLICKED ON DELETE')}>
                  <FormattedMessage {...messages.delete} />
                </DropdownMenuButton> */}
              </DropdownMenuItem>
            </DropdownMenu>
          }
        />
      ),
      sortable: false,
      width: 60
    }
  ];
  return (
    <>
      <InfoBox content={formatMessage(messages.checklistRecipients)} type="informational" />
      <DataTable
        data={alertRecipients}
        columns={columns}
        noDataMessage={noRecipientsMessage}
        hoverEffect
        defaultSorted={[
          {
            id: 'name',
            desc: false
          }
        ]}
        getTrGroupProps={(_, rowInfo) => ({
          onClick: () => store.router.goTo(views.editAlertRecipient, {id: rowInfo.original.id})
        })}
      />
    </>
  );
};
export default injectIntl(inject('store')(observer(Recipients)));
