import styled from 'styled-components';

//helpers
import typefaces from 'shared/styles/typefaces';

//components
import $UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';
import $Slug from 'components/Slug';
import $ElementResizeListener from 'shared/components/ElementResizeListener';

export const HeaderCard = styled($ElementResizeListener)`
  padding: 25px;
  min-width: 445px;
  width: 55%;
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 5px;
  z-index: 1;

  @media (max-width: 720px) {
    width: 100%;
    min-width: 0;
  }
`;

export const StyledTitleUnderlinedTextbox = styled($UnderlinedTextbox)`
  ${typefaces.guideDetails.title};
`;

export const StyledSlug = styled($Slug)`
  ${typefaces.guideDetails.slug};
`;
