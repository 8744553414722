import React, {Component} from 'react';

import Dialog from 'ui-components/Dialog';

import Spinner from 'shared/components/Spinner';

import {dialogStyle, SpinnerWrapper} from '../styles';

class LoadingUserDialog extends Component {
  render() {
    const {isOpen} = this.props;

    return (
      <Dialog actions={[]} isOpen={isOpen} dialogStyle={dialogStyle} hideActions>
        <SpinnerWrapper>
          <Spinner centerAbs centerX centerY size={23} />
        </SpinnerWrapper>
      </Dialog>
    );
  }
}

export default LoadingUserDialog;
