import styled from 'styled-components';
import BaseField from 'ui-components/Field';

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
`;

export const Field = styled(BaseField)`
  padding-top: 48px;
`;
