import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';
import mutationNames from 'api/user/constants';

//styled-components
import {ProfileSettings} from './styles';
import {TransparentButton} from 'styles/styled-components/shared-components';

//components
import Field from 'ui-components/Field';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Icon from 'ui-components/Layout/Icon';
import SSOButton from 'shared/components/SSOButton';
import ButtonGroup from 'ui-components/ButtonGroup';
import {FormattedMessage} from 'components/FormattedComponents';
import notification from 'utils/notification-utils';

//data
import {graphql} from '@apollo/client/react/hoc/graphql';
import {UserInfo} from 'api/user/queries';
import {UpdateProfile} from 'api/user/mutations';
import {updateProfileOptions} from 'api/user/mutation-options';

//messages
import messages from './messages';

@inject('store')
@graphql(UserInfo, {options: {fetchPolicy: 'network-only'}})
@graphql(UpdateProfile, updateProfileOptions)
@observer
class ProfileSettingsComponent extends Component {
  static defaultProps = {
    store: null,
    data: null
  };

  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {editProfilePage}
    } = this.props;

    const translations = mapValues(pick(messages, ['updateSuccess']), message => formatMessage(message));

    editProfilePage.setTranslations(translations);
  }

  componentDidMount() {
    const {
      intl: {formatMessage},
      store: {router}
    } = this.props;
    const {merged = ''} = router.queryParams || {};
    if (merged) {
      notification.success(formatMessage(messages.mergeSuccess));
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {store, data} = nextProps;
    const {me} = data;
    const {editProfilePage} = store;

    editProfilePage.setInitialForm(me);
    editProfilePage.form.update(me);
  }

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const updateProfileMutation = this.props[mutationNames.UPDATE_PROFILE];

    const {
      editProfilePage,
      changePasswordDialog,
      platform: {sso}
    } = store;

    const {form, cancelForm, updateProfile} = editProfilePage;
    const mergeWithCompanyAccLink = `${window.location.origin.replace('3033', '3000')}/sso/authorize?origin=cms&href=${
      window.location.href
    }`;

    return (
      <div>
        <ViewTitle title={formatMessage(messages.title)} />
        <ProfileSettings>
          <Field size="small" title={formatMessage(messages.fullName)} field="fullName" form={form} />

          <Field
            title={formatMessage(messages.password)}
            inputComponent={
              <div>
                <TransparentButton onClick={changePasswordDialog.open}>
                  <FormattedMessage {...messages.changePassword} />
                </TransparentButton>
              </div>
            }
          />

          {sso && (
            <SSOButton name={sso} iconComponent={Icon} href={mergeWithCompanyAccLink}>
              <FormattedMessage {...messages.mergeWithCompanyAccount} />
            </SSOButton>
          )}

          <ButtonGroup
            line
            primary={{
              label: formatMessage(messages.save),
              onClick: () => updateProfile(updateProfileMutation),
              disabled: !form.isValid
            }}
            secondary={{
              label: formatMessage(messages.cancel),
              onClick: () => {
                cancelForm();
                window.history.back();
              }
            }}
          />
        </ProfileSettings>
      </div>
    );
  }
}

export default injectIntl(ProfileSettingsComponent);
