import styled from 'styled-components';
import $Icon from 'react-fontawesome';
import {commonPalette} from 'shared/styles/palette';
import flex from 'styles/flex';
import {absoulteFull} from 'shared/utils/sc-utils';

export const PickerOverlay = styled.div`
  ${absoulteFull};
  ${flex.vertical};
  ${flex.centerVertical};
  transition: all 80ms linear;
  cursor: ${p => (p.selected ? 'default' : 'pointer')};
  opacity: ${p => (p.selected ? 1 : 0)};
  border: 4px solid ${commonPalette.sgGreen};
`;

// Radial gradient bottom right behind the icon

export const IconGradient = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: radial-gradient(circle at center, #00000040 0, transparent 60%);
  transform: translate(50%, 50%);
`;
export const IconFa = styled($Icon)`
  color: white;
  font-size: 18px !important;
  position: absolute;
  right: 6px;
  bottom: 6px;
`;
