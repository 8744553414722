import gql from 'graphql-tag';

export const SaveTranslationsForGuide = gql`
  mutation saveTranslationsForGuide($id: String!, $translations: ContentTranslation!) {
    saveTranslationsForGuide(id: $id, translations: $translations) {
      result
    }
  }
`;

export const SaveTranslationsForTags = gql`
  mutation saveTranslationsForTags($translations: ContentTranslation!) {
    saveTranslationsForTags(translations: $translations) {
      result
    }
  }
`;

export const AutoTranslateGuide = gql`
  mutation AutoTranslateGuide($id: String!, $targetLocales: [String]!) {
    autoTranslateGuide(id: $id, targetLocales: $targetLocales) {
      result
      error
    }
  }
`;
