import {Campaigns, Campaign} from 'api/campaign/queries';

export const refreshCampaigns = () => ({
  query: Campaigns
});

export const refreshCampaign = campaignId => {
  return (
    campaignId && {
      query: Campaign,
      variables: {campaignId}
    }
  );
};
