import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'skillProfileRolesDrawer.title',
    defaultMessage: 'Assign Skill Profile to Team or Job Title'
  },
  description: {
    id: 'skillProfileRolesDrawer.description',
    defaultMessage: 'The Skill Profile will be assigned to all members in the team or with the job title in the team.'
  },
  searchByTeamOrRole: {
    id: 'skillProfileRolesDrawer.searchByTeamOrRole',
    defaultMessage: 'Search by team'
  },
  noTeamsFound: {
    id: 'skillProfileRolesDrawer.noTeamsFound',
    defaultMessage: 'No teams here... yet!'
  },
  selected: {
    id: 'skillProfileRolesDrawer.selected',
    defaultMessage: '{num} selected'
  },
  createFirstTeam: {
    id: 'skillProfileRolesDrawer.createFirstTeam',
    defaultMessage: 'Get started by creating your first team'
  },
  adjustSearch: {
    id: 'skillProfileRolesDrawer.adjustSearch',
    defaultMessage: 'Adjust your search'
  },
  searchEmpty: {
    id: 'skillProfileRolesDrawer.searchEmpty',
    defaultMessage: 'No results found'
  }
});

export default messages;
