import gql from 'graphql-tag';

export const LinkToTopicIsValid = gql`
  query linkToTopicIsValid($topicId: String!, $versionId: String!, $guideId: String!) {
    linkToTopicIsValid(topicId: $topicId, versionId: $versionId, guideId: $guideId) {
      result
    }
  }
`;

export const LinkToInstructionIsValid = gql`
  query linkToInstructionIsValid($instructionId: String!, $topicId: String!, $versionId: String!, $guideId: String!) {
    linkToInstructionIsValid(
      instructionId: $instructionId
      topicId: $topicId
      versionId: $versionId
      guideId: $guideId
    ) {
      result
    }
  }
`;

export const LinkToStepIsValid = gql`
  query linkToStepIsValid(
    $stepId: String!
    $instructionId: String!
    $topicId: String!
    $versionId: String!
    $guideId: String!
  ) {
    linkToStepIsValid(
      stepId: $stepId
      instructionId: $instructionId
      topicId: $topicId
      versionId: $versionId
      guideId: $guideId
    ) {
      result
    }
  }
`;
