import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {commonPalette} from 'shared/styles/palette';

import {FormattedMessage} from 'components/FormattedComponents';
import LoadingMessage from 'components/LoadingMessage';
import CloudinaryPicture from 'shared/components/CloudinaryPicture';
import Select from 'ui-components/Select';
import Icon from 'ui-components/Icon';

import {MEDIA_GALLERY_FILTER_LEVEL} from 'config/enums';

import {
  ChooseFromExisting,
  ChooseFromExistingGrid,
  CoverImage,
  Toolbar,
  LargeLabel,
  SmallLabel,
  StyledInfiniteScroll,
  LoaderContainer,
  MediaNameContainer,
  MediaContainer,
  MediaName
} from './styles';
import messages from './messages';
import MediaImageItem from '../MediaImageItem';

const skeleton = ['skeleton-0', 'skeleton-1', 'skeleton-2', 'skeleton-3'];

@inject('store')
@observer
class MediaGalleryComponent extends Component {
  state = {
    unassignedMedia: [],
    isMyMediaLoading: undefined
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      if (this.state.isMyMediaLoading === undefined) {
        this.setState({isMyMediaLoading: true});
      }
      const unassignedMedia = window.localStorage.getItem('unassignedMedia');
      this.setState({unassignedMedia: JSON.parse(unassignedMedia), isMyMediaLoading: false});
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setScrollingContainerRef = ref => {
    const {
      store: {mediaPickerDialog}
    } = this.props;

    mediaPickerDialog.setRef('existingMediaScrollingContainer', ref);
  };

  render() {
    const {
      store: {mediaPickerDialog: dialog},
      intl: {formatMessage},
      mediaGalleryPaginatedQuery: {loading},
      fetchMore,
      changeLevel
    } = this.props;
    const {
      galleryLevel,
      instructionId,
      mediaAllowedFormat,
      selectedMedia,
      setSelectedMedia,
      fetchingNewImages,
      cursor,
      type,
      setIsUnassignedMedia,
      isMediaVideo
    } = dialog;
    const isMediaLoading = loading || fetchingNewImages;
    const shouldShowGalleryLevelPicker = instructionId !== undefined;
    const shouldShowMediaGrid = mediaAllowedFormat.length > 0 || isMediaLoading;
    const shouldShowSkeletonItems = cursor !== null && isMediaLoading;
    const shouldRenderInfiniteScroll = cursor !== null;
    const typeOfMessage = type ? messages.platformMedia : messages.guideMedia;
    const typeOfDescription = type ? messages.platformMediaDescription : messages.guideMediaDescription;

    return (
      <ChooseFromExisting>
        {(!!this.state.unassignedMedia?.length || this.state.isMyMediaLoading !== false) && (
          <>
            <Toolbar>
              <LargeLabel>
                <FormattedMessage {...messages.myMedia} />
                <Icon
                  popoverContent={formatMessage(messages.myMediaDescription)}
                  style={{marginLeft: 8, color: commonPalette.txGrey}}
                />
              </LargeLabel>
            </Toolbar>
            {this.state.isMyMediaLoading !== false && (
              <LoaderContainer>
                <LoadingMessage>
                  <FormattedMessage {...messages.loadingMedia} />
                </LoadingMessage>
              </LoaderContainer>
            )}
            <ChooseFromExistingGrid>
              {this.state.unassignedMedia.map(media => {
                const metadata = media && media.metadata ? media.metadata : {};
                return (
                  <MediaImageItem
                    key={media.id}
                    fileName={media.fileName}
                    isUnassignedMedia
                    {...{
                      media,
                      selectedMedia,
                      setSelectedMedia,
                      metadata,
                      setIsUnassignedMedia,
                      formatMessage,
                      isMediaVideo
                    }}
                  />
                );
              })}
            </ChooseFromExistingGrid>
          </>
        )}
        <Toolbar>
          <LargeLabel style={{paddingTop: '16px'}}>
            <FormattedMessage {...typeOfMessage} />
            <Icon
              popoverContent={formatMessage(typeOfDescription)}
              style={{marginLeft: 8, color: commonPalette.txGrey}}
            />
          </LargeLabel>
          {shouldShowGalleryLevelPicker && (
            <SmallLabel>
              <FormattedMessage {...messages.filter} />
            </SmallLabel>
          )}
          {shouldShowGalleryLevelPicker && (
            <Select
              onChange={changeLevel}
              selectedValue={galleryLevel}
              style={{maxWidth: 300}}
              options={[
                {
                  id: MEDIA_GALLERY_FILTER_LEVEL.GUIDE,
                  name: formatMessage(messages.onlyThisGuide)
                },
                {
                  id: MEDIA_GALLERY_FILTER_LEVEL.INSTRUCTION,
                  name: formatMessage(messages.onlyThisInstruction)
                }
              ]}
            />
          )}
        </Toolbar>
        {shouldShowMediaGrid && (
          <div className="existing-media-grid-container" ref={this.setScrollingContainerRef}>
            {loading && (
              <LoaderContainer>
                <LoadingMessage>
                  <FormattedMessage {...messages.loadingMedia} />
                </LoadingMessage>
              </LoaderContainer>
            )}
            <ChooseFromExistingGrid>
              {mediaAllowedFormat.map((media, key) => {
                const metadata = media && media.metadata ? media.metadata : {};

                return (
                  <MediaImageItem
                    key={media.id}
                    fileName={media.fileName}
                    {...{
                      media,
                      selectedMedia,
                      setSelectedMedia,
                      metadata,
                      formatMessage,
                      isMediaVideo,
                      setIsUnassignedMedia
                    }}
                  />
                );
              })}
              {shouldShowSkeletonItems &&
                skeleton.map(item => (
                  <MediaContainer key={item}>
                    <CoverImage>
                      <CloudinaryPicture aspectRatio={4 / 3} loadingImageCard />
                    </CoverImage>
                    <MediaNameContainer>
                      <MediaName>...</MediaName>
                    </MediaNameContainer>
                  </MediaContainer>
                ))}
            </ChooseFromExistingGrid>

            {shouldRenderInfiniteScroll && (
              <StyledInfiniteScroll
                isLoading={isMediaLoading}
                onBottom={fetchMore}
                spinnerSize={false}
                threshold={30}
              />
            )}
          </div>
        )}
        {!shouldShowMediaGrid && <FormattedMessage {...messages.noMediaFound} />}
      </ChooseFromExisting>
    );
  }
}

export default injectIntl(MediaGalleryComponent);
