import includes from 'lodash/includes';

export const domainsOptions = {
  options: ({store}) => {
    const adminOfManagementTeamsOnly = includes(
      ['/admin/workspaces', 'editTeam'],
      store.router.currentRoute && store.router.currentRoute.path
    );
    return {
      fetchPolicy: 'network-only',
      variables: {
        adminOfManagementTeamsOnly
      }
    };
  },
  name: 'domainsQuery'
};

export const domainsIncludingArchivedOptions = {
  options: () => {
    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only'
    };
  },
  name: 'domainsIncludingArchivedQuery'
};

export const domainsForAnalyticsOptions = {
  options: {
    fetchPolicy: 'network-only'
  },
  name: 'domainsForAnalyticsQuery'
};

export const domainOptions = {
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    };
  },
  name: 'domainQuery'
};
