import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'TagManagement.title',
    defaultMessage: 'Tags'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  name: {
    id: 'TagManagement.nameNew',
    defaultMessage: 'Tag Name'
  },
  defaultLanguage: {
    id: 'common.forms.defaultLanguage',
    defaultMessage: 'Default Language'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  searchTags: {
    id: 'searchTagsByDefaultLanguage',
    defaultMessage: 'Search by name in default language'
  },
  noTags: {
    id: 'noTags',
    defaultMessage: 'There are no Tags created yet.'
  },
  noFilteredTags: {
    id: 'noFilteredTags',
    defaultMessage: 'No Tags found for {searchTerm}'
  },
  removeTag: {
    id: 'EditTag.removeTag',
    defaultMessage: 'Remove tag'
  },
  confirmation: {
    id: 'EditTag.confirmation',
    defaultMessage: 'Are you sure you want to remove this tag?'
  },
  archiveSuccess: {
    id: 'EditTag.archiveSuccess',
    defaultMessage: 'The tag has been removed.'
  },
  archiveFailure: {
    id: 'EditTag.archiveFailure',
    defaultMessage: 'Error removing tag.'
  },
  subtitle: {
    id: 'TagManagement.subtitle',
    defaultMessage: 'Tags help you organize your guide and skill content'
  },
  delete: {
    id: 'common.actions.delete',
    defaultMessage: 'Delete'
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions'
  }
});

export default messages;
