import React, {Component} from 'react';
import {DropTarget} from 'react-dnd';
import {inject} from 'mobx-react';

//components
import NewInstructionCard from '../NewInstructionCard';

//drag-and-drop
import {DND_TYPE, target} from './dnd';

@inject('store')
@DropTarget(DND_TYPE, target, connect => ({
  connectDropTarget: connect.dropTarget()
}))
export default class DroppableNewInstructionCardComponent extends Component {
  render() {
    const {connectDropTarget, ...forwardedProps} = this.props;

    return <NewInstructionCard {...forwardedProps} hostRef={connectDropTarget} />;
  }
}
