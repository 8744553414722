export const queries = [
  {
    name: 'teamsQuery',
    entity: 'allTeams',
    storeKey: 'TEAM'
  },
  {
    name: 'tagsQuery',
    entity: 'tags',
    storeKey: 'TAG'
  },
  {
    name: 'assigneesQuery',
    entity: 'assignees',
    storeKey: 'ASSIGNEE'
  },
  {
    name: 'domainsQuery',
    entity: 'domains',
    storeKey: 'WORKSPACE'
  },
  {
    name: 'availableLanguagesQuery',
    entity: 'availableLanguages',
    storeKey: 'LANGUAGE'
  }
];

export const SEARCH_TIMEOUT = 100;
