import React, {Component} from 'react';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';

//styles
import {CenteredWrapper, Message, NotFoundMessage, WarningIcon} from './styles';

//messages
import messages from './messages';

class NotFoundMessageComponent extends Component {
  render() {
    return (
      <NotFoundMessage>
        <CenteredWrapper>
          <WarningIcon />
          <Message>
            <FormattedMessage {...messages.pageNotFound} />
          </Message>
        </CenteredWrapper>
      </NotFoundMessage>
    );
  }
}

export default NotFoundMessageComponent;
