import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {mapValues} from 'lodash';
import views from 'config/views';
import {Tabs} from 'antd';

//data
import {DomainsForAnalytics} from 'api/domain/queries';
import {domainsForAnalyticsOptions} from 'api/domain/query-options';

//components
import Spinner from 'shared/components/Spinner';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import {SpinnerContainer, AnalyticsContent, AnalyticsContainer} from './styles';
import {StyledTabs} from 'components/StepsTabs/styles';
import Container from 'ui-components/Layout/Container';
import Select from 'ui-components/Select';
import {FormattedMessage} from 'components/FormattedComponents';
import LoadingMessage from 'components/LoadingMessage';

//messages
import messages from './messages';

@inject('store')
@graphql(DomainsForAnalytics, domainsForAnalyticsOptions)
@observer
class AnalyticsView extends Component {
  componentDidMount() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {analyticsPage} = store;
    analyticsPage.checkPermissions();
    analyticsPage.handlePostMessages();
    analyticsPage.setTranslations(mapValues(messages, message => formatMessage(message)));
    analyticsPage.trackPageViewEvent();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store: {
        analyticsPage,
        auth: {user}
      },
      intl: {formatMessage},
      domainsForAnalyticsQuery: {domainsForAnalytics = []},
      loading
    } = nextProps;
    if (loading || !domainsForAnalytics) {
      return;
    }

    let domainsList = domainsForAnalytics;
    if (user.isPlatformAdmin) {
      const allWorkspaces = {
        id: 'all',
        slug: 'all',
        name: formatMessage(messages.allWorkspaces)
      };
      if (domainsForAnalytics.length > 1) {
        domainsList = [allWorkspaces, ...domainsForAnalytics];
        analyticsPage.setDomains(domainsList);
        analyticsPage.setSelectedDomainSlug('all');
      }
    }
    analyticsPage.setDomains(domainsList);
  }

  componentDidUpdate() {
    const {
      store: {analyticsPage}
    } = this.props;
    analyticsPage.trackPageViewEvent();
  }

  goTo = link => {
    const {store} = this.props;
    store.router.goTo(views.analytics, {page: link});
    store.analyticsPage.setDrilldownPayload(false);
  };

  render() {
    const {
      domainsForAnalyticsQuery: {loading, error},
      store: {analyticsPage, platform, app},
      intl: {formatMessage}
    } = this.props;
    const {selectedNavSubMenuItem} = app;
    const {hasMultiDomainEnabled} = platform;
    const {selectedSlug, setSelectedDomainSlug, domains, getAnalyticsUrl, showFilterByWorkspaceSelect} = analyticsPage;
    const {caption, links} = selectedNavSubMenuItem;

    if (loading || error) {
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }

    if (!loading && !domains.length) {
      return <FormattedMessage {...messages.empty} />;
    }

    const {TabPane} = Tabs;
    const analyticsUrl = getAnalyticsUrl();

    const elementCaption = <span dangerouslySetInnerHTML={{__html: caption}} />;

    return (
      <Container>
        <ViewTitle
          title={elementCaption}
          right={
            showFilterByWorkspaceSelect &&
            hasMultiDomainEnabled &&
            domains &&
            domains.length > 1 && (
              <Select
                showSearch
                label={formatMessage(messages.filterBy)}
                options={domains.slice()}
                selectedValue={selectedSlug}
                idProp={'slug'}
                onChange={slug => setSelectedDomainSlug(slug)}
                placeholder={formatMessage(messages.placeholder)}
              />
            )
          }
        />
        {links && (
          <StyledTabs defaultActiveKey={links[0].id} onChange={link => this.goTo(link)} tabsOnly>
            {links.map(link => (
              <TabPane tab={link.caption} key={link.params.page} />
            ))}
          </StyledTabs>
        )}
        <AnalyticsContainer>
          <AnalyticsContent
            id="periscope"
            src={analyticsUrl}
            frameBorder="0"
            onLoad={() => {
              analyticsPage.setPeriscopeKPI();
            }}
            isLoading={analyticsPage.loading}
          ></AnalyticsContent>
          {analyticsPage.loading && (
            <SpinnerContainer>
              <Spinner size={'5rem'} color={'black'}></Spinner>
            </SpinnerContainer>
          )}
        </AnalyticsContainer>
      </Container>
    );
  }
}

export default injectIntl(AnalyticsView);
