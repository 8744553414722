import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loadingMedia: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  existingMedia: {
    id: 'MediaGallery.existingMedia',
    defaultMessage: 'Existing Media'
  },
  onlyThisGuide: {
    id: 'MediaGallery.onlyThisGuide',
    defaultMessage: 'Only this Guide'
  },
  onlyThisInstruction: {
    id: 'MediaGallery.onlyThisInstruction',
    defaultMessage: 'Only this Instruction'
  },
  noMediaFound: {
    id: 'MediaGallery.notFound',
    defaultMessage: 'No media found'
  },
  filter: {
    id: 'MediaGallery.filter',
    defaultMessage: 'Filter'
  },
  guideMedia: {
    id: 'guideMedia',
    defaultMessage: "Guide's Media"
  },
  myMedia: {
    id: 'myMedia',
    defaultMessage: 'My Media'
  },
  myMediaDescription: {
    id: 'myMediaDescription',
    defaultMessage: 'Only you can view the images and videos'
  },
  guideMediaDescription: {
    id: 'guideMediaDescription',
    defaultMessage: 'Images and videos that are used in this guide'
  },
  platformMedia: {
    id: 'platformMedia',
    defaultMessage: 'Platform media'
  },
  platformMediaDescription: {
    id: 'platformMediaDescription',
    defaultMessage: 'Images and icons that are used on the platform'
  }
});

export default messages;
