import React, {Component} from 'react';
import equal from 'fast-deep-equal';
import * as drawCtx from './draw';

class Canvas extends Component {
  componentDidUpdate(prevProps) {
    if (!equal(this.props, prevProps)) {
      this.drawIt();
    }
  }
  canvasLoaded = node => {
    if (node) {
      this.canvas = node;
      const ctx = this.canvas.getContext('2d');
      this.ctx = ctx;
      this.props.canvasLoadedHandler();
    }
  };
  drawIt() {
    const {
      imageSrc,
      crop,
      focus,
      scale,
      imageWidth,
      imageHeight,
      wrapperWidth,
      wrapperHeight,
      rotatedWidth,
      rotatedHeight,
      rotatedX,
      rotatedY,
      rotation,
      contain = false
    } = this.props;

    if (this.ctx && crop) {
      const ctx = this.ctx;
      const image = new Image();
      image.src = imageSrc;

      const drawImageRotatedProps = {
        image,
        scale,
        rotationCenterX: wrapperWidth / 2,
        rotationCenterY: wrapperHeight / 2,
        imageWidth,
        imageHeight,
        rotationDegree: rotation
      };
      const lineWidthScale = 1 / scale;

      ctx.clearRect(0, 0, wrapperWidth, wrapperHeight);

      drawCtx.drawImageRotated(ctx, drawImageRotatedProps);

      // fader
      drawCtx.drawFilledRect(ctx, {
        x: rotatedX,
        x2: rotatedX + rotatedWidth,
        y: rotatedY,
        y2: rotatedY + rotatedHeight,
        color: 'rgba(0,0,0,.7)'
      });

      ctx.save();
      ctx.setTransform(scale, 0, 0, scale, rotatedX, rotatedY);

      // window
      drawCtx.drawFilledRect(ctx, {
        x: crop.x,
        y: crop.y,
        x2: crop.x + crop.w,
        y2: crop.y + crop.h
      });
      ctx.clip();
      ctx.setTransform(1, 0, 0, 1, 0, 0);

      drawCtx.drawImageRotated(ctx, drawImageRotatedProps);

      ctx.restore();

      ctx.setTransform(scale, 0, 0, scale, rotatedX, rotatedY);

      // crop dotted line
      drawCtx.drawRect(ctx, {
        x: crop.x,
        y: crop.y,
        x2: crop.x + crop.w,
        y2: crop.y + crop.h,
        color: '#ffffff',
        lineWidth: 1 * lineWidthScale,
        lineDash: [4 * lineWidthScale, 2 * lineWidthScale]
      });

      // focus dot
      !contain &&
        drawCtx.drawCircle(ctx, {
          x: crop.x + crop.w * focus.kx,
          y: crop.y + crop.h * focus.ky,
          size: 2 * lineWidthScale,
          lineWidth: 2 * lineWidthScale,
          color: '#000000'
        });

      ctx.setTransform(1, 0, 0, 1, 0, 0);
    }
  }
  render() {
    const {wrapperHeight, wrapperWidth, rotatedX, rotatedWidth, rotatedHeight, rotatedY} = this.props;

    return (
      <div style={{position: 'relative'}}>
        <div
          style={{
            position: 'absolute',
            left: rotatedX,
            width: rotatedWidth,
            height: rotatedHeight,
            top: rotatedY
          }}
        />
        <canvas width={wrapperWidth} height={wrapperHeight} ref={this.canvasLoaded} title={'canvas'} alt={'canvas'} />
      </div>
    );
  }
}
export default Canvas;
