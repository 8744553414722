import {defineMessages} from 'react-intl';

const messages = defineMessages({
  actionStep: {
    id: 'ArchiveStep.action',
    defaultMessage: 'Remove step'
  },
  actionCheck: {
    id: 'actionCheck',
    defaultMessage: 'Remove check'
  },
  confirmationStep: {
    id: 'ArchiveStep.confirmation',
    defaultMessage: 'Are you sure you want to remove your step?'
  },
  confirmationCheck: {
    id: 'confirmationCheck',
    defaultMessage: 'Are you sure you want to remove your check?'
  },
  archiveStepSuccess: {
    id: 'ArchiveStep.archiveSuccess',
    defaultMessage: 'Step has been removed.'
  },
  archiveCheckSuccess: {
    id: 'archiveCheckSuccess',
    defaultMessage: 'Check has been removed.'
  },
  archiveStepFailure: {
    id: 'ArchiveStep.archiveFailure',
    defaultMessage: 'Error removing step.'
  },
  archiveCheckFailure: {
    id: 'archiveCheckFailure',
    defaultMessage: 'Error removing check.'
  },
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  coverPhoto: {
    id: 'EditStep.coverPhoto',
    defaultMessage: 'Set as cover photo'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  duplicate: {
    id: 'common.actions.duplicate',
    defaultMessage: 'Duplicate'
  },
  delete: {
    id: 'common.actions.remove',
    defaultMessage: 'Remove'
  },
  cloneStepSuccess: {
    id: 'EditStep.cloneSuccess',
    defaultMessage: 'Step has been duplicated.'
  },
  cloneCheckSuccess: {
    id: 'cloneCheckSuccess',
    defaultMessage: 'Check has been duplicated.'
  },
  cloneStepError: {
    id: 'EditStep.cloneError',
    defaultMessage: 'Error duplicating step.'
  },
  cloneCheckError: {
    id: 'cloneCheckError',
    defaultMessage: 'Error duplicating check.'
  },
  setCoverSuccess: {
    id: 'EditStep.setCoverSuccess',
    defaultMessage: 'The cover image of the instruction has been updated.'
  },
  setCoverError: {
    id: 'EditStep.setCoverError',
    defaultMessage: 'Error setting the cover image of the instruction.'
  }
});

export default messages;
