import React from 'react';
import {truncate} from 'lodash';
import {InjectedIntl, injectIntl} from 'react-intl';

import {getEventConfig, shouldGetInstructionLink, incrementNumber} from './../utils';
import messages from './../messages';

import {FormattedMessage} from 'components/FormattedComponents';

import {FlexRow, EventHeader, LinkSection, EventWrapper} from './../styles';
import {Person} from './../Person';
import {InstructionLink} from './../Links/InstructionLink';
import {ActivityEvent, Person as PersonType} from '../types';

export type ChecklistInstructionEventProps = {
  activity: ActivityEvent;
  canLink: boolean;
  intl: InjectedIntl;
  router: any;
  checkInstructionLinkIsValid: any;
  Chevron: () => React.JSX.Element;
  TimeAndDate: () => React.JSX.Element;
  Info: (props: any) => React.JSX.Element;
};

const ChecklistInstructionEvent = (props: ChecklistInstructionEventProps) => {
  const {
    activity,
    canLink,
    intl: {formatMessage},
    router,
    checkInstructionLinkIsValid,
    Chevron,
    TimeAndDate,
    Info
  } = props;

  const {eventType, versionId, instructionTitle, topicTitle, afterPosition, beforePosition, position, tags, guide} =
    activity;
  const instructionLink = shouldGetInstructionLink(eventType) ? InstructionLink : undefined;
  const eventConfig = getEventConfig(eventType, !!instructionTitle, instructionLink);
  const instructionTitleDisplayText = truncate(instructionTitle, {length: 300});

  return (
    <FlexRow>
      <Chevron />
      <EventWrapper>
        <EventHeader>
          <TimeAndDate />
          <LinkSection>
            {canLink && eventConfig.LinkComponent && versionId && (
              <eventConfig.LinkComponent
                {...activity}
                router={router}
                linkValidityChecks={{
                  checkInstructionLinkIsValid
                }}
              >
                <FormattedMessage {...messages.view} />
              </eventConfig.LinkComponent>
            )}
          </LinkSection>
        </EventHeader>
        <Info>
          <FormattedMessage
            {...eventConfig.message}
            values={{
              person: <Person person={activity.producedBy} />,
              assignee: <Person person={activity.newAssignee as PersonType} />,
              instructionTitle: instructionTitleDisplayText,
              topicTitle: topicTitle ? truncate(topicTitle, {length: 300}) : formatMessage(messages.defaultTopic),
              alertRecipientsCount: activity.count,
              beforePosition: incrementNumber(beforePosition),
              afterPosition: incrementNumber(afterPosition),
              position: incrementNumber(position),
              tags: tags?.length,
              guide
            }}
          />
        </Info>
      </EventWrapper>
    </FlexRow>
  );
};

export default injectIntl(ChecklistInstructionEvent);
