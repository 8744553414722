import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {DEFAULT_TOPIC_SLUG} from 'config/constants';
import {isDraftVersionId} from 'utils/versioning-utils';

//components
import DraggableInstructionItem from '../DraggableInstructionItem';
import DroppableInstructionItemPlaceholder from '../DroppableInstructionItemPlaceholder';
import InstructionItem from '../InstructionItem';
import InstructionItemPlaceholder from '../InstructionItemPlaceholder';
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {Header, InnerWrapper, StyledDragHandle, Summary, Title, TopicItem} from './styles';

//mutations
import {RepositionTopicsAndInstructions} from 'api/topic/mutations';

//mutation-options
import {repositionTopicsAndInstructions} from 'api/topic/mutation-options';

//messages
import messages from './messages';

@inject('store')
@graphql(RepositionTopicsAndInstructions, repositionTopicsAndInstructions)
@observer
class TopicItemComponent extends Component {
  static propTypes = {
    isChildrenDragAndDropDisabled: PropTypes.bool,
    onDragHandleMouseDown: PropTypes.func
  };

  static defaultProps = {
    isChildrenDragAndDropDisabled: false
  };

  render() {
    const {store, topic, hostRef, design, isChildrenDragAndDropDisabled} = this.props;
    const {dragDropItems} = store;

    const InstructionItemPlaceholderComponent = isChildrenDragAndDropDisabled
      ? InstructionItemPlaceholder
      : DroppableInstructionItemPlaceholder;

    return (
      <TopicItem design={design} ref={hostRef}>
        <InnerWrapper isInvisible={design === 'ghostly'}>
          {this.renderHeader()}
          {dragDropItems.areTopicsCollapsed && (
            <Summary>
              <FormattedMessage {...messages.instructionCount} values={{count: topic.instructions.length}} />
            </Summary>
          )}
          {!dragDropItems.areTopicsCollapsed &&
            topic.instructions.map((instruction, position) => this.renderInstruction(instruction, position))}
          {!dragDropItems.areTopicsCollapsed && <InstructionItemPlaceholderComponent topic={topic} />}
        </InnerWrapper>
      </TopicItem>
    );
  }

  renderHeader() {
    const {dragHandleRef, onDragHandleMouseDown, topic} = this.props;

    if (topic.slug === DEFAULT_TOPIC_SLUG) {
      return (
        <Header>
          <Title>
            <FormattedMessage {...messages.defaultTopic} />
          </Title>
        </Header>
      );
    }

    return (
      <Header>
        <StyledDragHandle
          forwardedRef={dragHandleRef}
          isDisabled={false}
          onClick={this.avoidNavigation}
          onMouseDown={onDragHandleMouseDown}
        />
        <Title>{topic.title}</Title>
      </Header>
    );
  }

  renderInstruction(instruction, position) {
    const {store, isChildrenDragAndDropDisabled} = this.props;
    const {editInstructionDetailsPage} = store;
    const {currentVersionId} = editInstructionDetailsPage;

    const sharedProps = {
      instruction: instruction,
      key: instruction.id,
      position: position
    };

    if (isChildrenDragAndDropDisabled) {
      return <InstructionItem {...sharedProps} />;
    }

    if (!isDraftVersionId(currentVersionId)) {
      return <InstructionItem {...sharedProps} onDragHandleMouseDown={this.handleInstructionDragHandleMouseDown} />;
    }

    return <DraggableInstructionItem {...sharedProps} />;
  }

  avoidNavigation = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleInstructionDragHandleMouseDown = event => {
    const {store} = this.props;
    const {saveAsDraftDialog} = store;

    event.preventDefault(); // so the focus stays inside the dialog
    saveAsDraftDialog.check();
  };
}

export default TopicItemComponent;
