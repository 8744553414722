import {defineMessages} from 'react-intl';

const messages = defineMessages({
  noGuidesFound: {
    id: 'Guides.notFound',
    defaultMessage: 'No guides found'
  }
});

export default messages;
