import styled from 'styled-components';
import $CampaignQuestionKindPicker from 'components/CampaignQuestionKindPicker';
import $Button from 'ui-components/Button';

export const CampaignQuestionKindPicker = styled($CampaignQuestionKindPicker)`
  color: rgba(0, 0, 0, 0.85);
`;

export const AddFollowUpButton = styled($Button)`
  width: 250px;
  margin-top: 16px;
`;

export const TextQuestionsWrapper = styled.div``;
