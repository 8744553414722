import React from 'react';
import {ActivityLogLink} from './ActivityLogLink';
import views from 'config/views';

type InstructionLinkProps = {
  guideId: string;
  topicId: string;
  instructionId: string;
  v: string;
  versionId: string;
  children: React.ReactNode;
  router: any;
  linkValidityChecks: {
    checkInstructionLinkIsValid: (params: {
      topicId: string;
      guideId: string;
      versionId: string;
      instructionId: string;
    }) => boolean;
  };
};

export const InstructionLink = (props: InstructionLinkProps) => {
  const {
    guideId,
    topicId,
    instructionId,
    v,
    versionId,
    children,
    router,
    linkValidityChecks: {checkInstructionLinkIsValid}
  } = props;

  const handleValidateLink = e => {
    e.preventDefault();
    return checkInstructionLinkIsValid({
      instructionId,
      topicId,
      guideId,
      versionId
    });
  };
  return (
    <ActivityLogLink
      router={router}
      route={views.editInstruction}
      params={{id: guideId, topicId, instructionId}}
      queryParams={{v}}
      validateLink={handleValidateLink}
    >
      {children}
    </ActivityLogLink>
  );
};
