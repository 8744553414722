import {client} from 'utils/apollo-client';

import {refreshGuide} from 'api/guide/refresh-queries';

export const createTopic = {
  props: ({mutate, ownProps: {store}}) => ({
    createTopicMutation: variables => {
      const refetchQueries = [];
      const {newTopicPage, editGuidePage} = store;
      const {defaultLocale} = editGuidePage.guide;
      if (!newTopicPage.droppedItem) {
        refetchQueries.push(refreshGuide(store.router.params.id));
      }
      variables.topic.locale = defaultLocale;
      return mutate({
        variables,
        refetchQueries
      });
    }
  })
};

export const deleteTopic = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    deleteTopicMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const updateTopic = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    updateTopicMutation: variables => {
      const {defaultLocale} = store.editGuidePage.guide;
      variables.topic.locale = defaultLocale;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const repositionTopicsAndInstructions = {
  props: ({mutate, ownProps: {store}}) => ({
    repositionTopicsAndInstructionsMutation: variables => {
      return mutate({
        variables: variables,
        refetchQueries: [refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const moveTopicOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    moveTopicMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id)],
        awaitRefetchQueries: true
      });
    }
  })
};
