import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const SectionHeader = styled.h3`
  margin-bottom: 16px;
`;

export const CampaignTemplateView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CampaignTemplateTypeContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 32px;
  padding: 24px 0 32px;
  white-space: nowrap;
`;

export const CampaignTemplateType = styled.div`
  background: #fafafa;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 8px;
  padding: 0 6px;

  ${p =>
    p.isSelected &&
    `
    background: ${commonPalette.btnGreen};
    color: #fff;
  `}
`;

export const CampaignTemplateQuestionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const StartWithoutTemplateLink = styled.a`
  align-self: flex-start;
  display: flex;
  flex-wrap: no-wrap;
  margin-top: 16px;
  color: ${commonPalette.btnGreen};
  &:hover {
    text-decoration: none;
  }
`;
