import Raven from 'raven-js';

import {FILTERS_KEY} from 'utils/filters';
import {SORT_BY_OPTIONS, GUIDE_FILTER_OPTIONS, STATUS_OPTIONS} from 'shared/enums';

export const openTeamsWithDialogClosed = () => window.open('/admin/teams?hideDialog=true');

export const openTeamWithDialogClosed = teamId => window.open(`/admin/teams/${teamId}/users?hideDialog=true`);

export const openGuidesWithDialogClosed = userId => {
  try {
    const customFilters = JSON.parse(localStorage.getItem(FILTERS_KEY));

    // Reset all guides to the default except for the ASSIGNEE
    customFilters.ASSIGNEE = [userId];
    customFilters.TAG = [];
    customFilters.TEAM = [];
    customFilters.WORKSPACE = [];
    customFilters.filterOption = GUIDE_FILTER_OPTIONS.ALL_GUIDES;
    customFilters.searchTerm = '';
    customFilters.selectStatus = STATUS_OPTIONS.ALL;
    customFilters.sortBy = SORT_BY_OPTIONS.PUBLISHED_TITLE;

    localStorage.setItem(FILTERS_KEY, JSON.stringify(customFilters));
  } catch (error) {
    Raven.captureException(error);
  } finally {
    window.open('/admin/guides?hideDialog=true');
  }
};

export const openGuideWithDialogClosed = guideId => window.open(`/admin/guides/${guideId}?hideDialog=true`);
