import {defineMessages} from 'react-intl';

const messages = defineMessages({
  failure: {
    id: 'LoadingMessage.failure',
    defaultMessage: "That didn't go as expected, please try again."
  },
  timeout: {
    id: 'LoadingMessage.timeout',
    defaultMessage: 'This is taking a bit too long, please try to refresh the page.'
  }
});

export default messages;
