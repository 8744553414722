import {action, observable, makeObservable} from 'mobx';

class OverrideCampaignDialog {
  @observable opened = false;
  @observable overrideExistingCampaigns = false;

  applyCampaignToInstructionsMutation;

  @action open = applyCampaignToInstructionsMutation => {
    const {
      applyCampaignDialog: {toggleDialogVisibility}
    } = this.store;

    toggleDialogVisibility();
    this.applyCampaignToInstructionsMutation = applyCampaignToInstructionsMutation;
    this.opened = true;
  };

  @action close = () => {
    const {
      applyCampaignDialog: {reset: resetApplyCampaignDialog}
    } = this.store;

    this.reset();
    resetApplyCampaignDialog();
  };

  @action setOverrideExistingCampaigns = overrideExistingCampaigns => {
    this.overrideExistingCampaigns = overrideExistingCampaigns;
  };

  @action submit = () => {
    const {
      applyCampaignDialog: {submit: submitApplyCampaignDialog}
    } = this.store;

    submitApplyCampaignDialog(this.applyCampaignToInstructionsMutation, this.overrideExistingCampaigns);
    this.reset();
  };

  @action
  reset = () => {
    this.opened = false;
    this.overrideExistingCampaigns = false;
    this.applyCampaignToInstructionsMutation = null;
  };

  setStore = store => {
    this.store = store;
  };

  constructor() {
    makeObservable(this);
  }
}

export default OverrideCampaignDialog;
