import React from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import messages from './messages';

const ActiveFilters = props => {
  const {
    store: {
      versionHistoryDrawer: {activeFilters}
    },
    intl: {formatMessage}
  } = props;

  return (
    <span>
      {activeFilters === 3 ? formatMessage(messages.all) : `${activeFilters} ${formatMessage(messages.selected)}`}
    </span>
  );
};

export default injectIntl(inject('store')(observer(ActiveFilters)));
