import styled from 'styled-components';

//helpers
import {transparentize} from 'shared/utils/color-utils';
import {tableMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';
import {boxShadow} from 'styles/shadows';
import typefaces from 'shared/styles/typefaces';

import Button from 'ui-components/Button';

import iconSortAsc from './icons/sort-asc.svg';
import iconSortDesc from './icons/sort-desc.svg';
import iconSortDefault from './icons/sort-default.svg';

const getTdStyleWithHoverEffect = hoverEffect =>
  hoverEffect
    ? `margin: 0; 
   border-bottom: 1px solid ${commonPalette.hoverGrey};
`
    : `margin: 8px ${tableMetrics.cellMarginRight}px 8px ${tableMetrics.cellMarginLeft}px;`;

export const Wrapper = styled.div`
  max-width: 1260px;

  ${({rowBottomBorder}) =>
    !rowBottomBorder &&
    `
    .ReactTable .rt-tbody .rt-tr-group {
      border-bottom: none;
    }
  `}

  .ReactTable .-pagination {
    box-shadow: none;
    border: none;
    display: flex;
    width: 270px;
    margin: 10px auto 0;
  }

  ${({hoverEffect}) =>
    hoverEffect &&
    `
    .ReactTable .rt-tr:hover .rt-td {
      background: ${commonPalette.hoverGrey};
      cursor: pointer;
    }
  `}

  .rt-table {
    overflow: auto !important;
  }

  &.-cms-design-v2 > .ReactTable {
    border: 0;
    box-shadow: ${boxShadow.table};

    .rt-thead {
      &.-header {
        background: #fff;
        box-shadow: none;
      }

      .rt-tr {
        text-align: left;
      }

      .rt-th,
      .rt-td {
        border-right: 0;
      }
    }

    .rt-tbody {
      .rt-tr-group {
        border-bottom: 0;
      }

      .rt-td {
        ${typefaces.table.bodyCell};
        border-right-color: #fff;
        width: fit-content;
      }
    }

    .rt-tr {
      &.hasOnClick {
        cursor: pointer;

        &:hover {
          background: ${transparentize(commonPalette.btnGreen, 0.2)};
        }
      }

      &.-odd {
        background: #f2f2f2;
      }

      &.-active {
        background: ${commonPalette.btnGreen} !important;
        color: #fff;
      }

      &.-disabled {
        cursor: default;
      }

      &.-highlight {
        font-weight: bold;
      }
    }
  }

  &:not(.-cms-design-v2) > .ReactTable {
    border: none;
    border-bottom: 1px solid #ececec;
    margin-bottom: 8rem;
    min-width: 100px;
    .rt-thead .rt-th,
    .rt-tbody .rt-td {
      border-right: none;
      ${({paddingRows}) => (paddingRows ? `padding: ${paddingRows}px 0` : '')};
    }
    .rt-thead.-header {
      box-shadow: none;
      border-bottom: none;
      ${typefaces.table.xHeaderCell};
      color: ${commonPalette.txGrey};
    }
    .rt-th,
    .rt-td {
      padding: 0;
      ${({hoverEffect}) => getTdStyleWithHoverEffect(hoverEffect)}

      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .rt-thead .rt-th,
    .rt-thead .rt-td {
      outline: none;
    }
    .rt-thead .rt-th,
    .rt-thead .rt-td {
      text-align: left;
      padding-bottom: 8px;
    }

    .rt-tr.hasOnClick {
      cursor: pointer;
      &:hover {
        background: rgba(255, 255, 255, 0.5);
        button {
          opacity: 1;
        }
      }
    }
    .rt-thead .rt-td,
    .rt-thead .rt-th {
      border-bottom: none;
      &.-sort-asc {
        display: flex;
        align-items: center;
        box-shadow: none;
        &:after {
          content: url(${iconSortAsc});
          margin-left: 10px;
          margin-top: -15px;
          width: 5px;
          height: 5px;
        }
      }
      &.-sort-desc {
        display: flex;
        align-items: center;
        box-shadow: none;
        &:after {
          content: url(${iconSortDesc});
          margin-left: 10px;
          margin-top: -15px;
          width: 5px;
          height: 5px;
        }
      }
    }
    .-cursor-pointer {
      display: flex;
      &:after {
        content: url(${iconSortDefault});
        margin-left: 10px;
      }
    }
    .rt-noData {
      margin-top: 15%;
    }
  }
`;

export const NoDataWrapper = styled.div`
  margin: 0 0 30px 0;
`;

const ButtonBase = `
  padding: 10px 20px;
  border: 1px solid ${transparentize(commonPalette.txLightGrey, 0.3)};
  border-radius: 3px;
  font-size: 18px;
`;

export const LeftButton = styled(Button)`
  ${ButtonBase}
  background-color: ${({disabled}) => (disabled ? transparentize(commonPalette.txLightGrey, 0.1) : '#FFF')};
  color: ${({disabled}) => (disabled ? transparentize(commonPalette.txLightGrey, 0.7) : commonPalette.sgGreen)};
`;

export const RightButton = styled(Button)`
  ${ButtonBase}
  background-color: ${({disabled}) => (disabled ? transparentize(commonPalette.txLightGrey, 0.1) : '#FFF')};
  color: ${({disabled}) => (disabled ? transparentize(commonPalette.txLightGrey, 0.7) : commonPalette.sgGreen)};
  margin-left: 20px;
`;

export const HeaderWrapper = styled.div`
  padding: 0 25px 0 ${({paddingLeft}) => (paddingLeft ? '25px' : '0')};
`;

export const CellWrapper = styled.div`
  margin: auto 0;
  padding: 14px 25px 14px ${({paddingLeft}) => (paddingLeft ? '25px' : '0')};
  display: flex;
  flex: 1;
  align-items: center;
  ${({disabled}) => (disabled ? `color: ${commonPalette.txLightGrey};` : '')}
`;

export const StyledText = styled.div`
  ${({marginLeft}) => (marginLeft ? `margin-left: ${marginLeft}px;` : '')}
  ${({marginRight}) => (marginRight ? `margin-right: ${marginRight}px;` : '')}
  ${({centered}) => (centered ? 'text-align: center;' : '')}
`;
