import {action, observable, makeObservable} from 'mobx';

class WarningDialog {
  @observable opened = false;
  @observable content = {};

  @action
  reset = () => {
    this.opened = false;
    this.content = {};
  };

  @action open = ({content = {}}) => {
    this.content = content;
    this.opened = true;
  };

  cancel = () => {
    this.reset();
  };

  constructor() {
    makeObservable(this);
  }
}

export default WarningDialog;
