import {locales, fonts} from '../constants';

export const getFontByLocale = locale => {
  const {
    en_US,
    pt_PT,
    sv_SE,
    nl_NL,
    fr_FR,
    de_DE,
    it_IT,
    es_ES,
    no_NO,
    fi_FI,
    hu_HU,
    pl_PL,
    da_DK,
    tr_TR,
    ru_RU,
    cs_CZ,
    zh_CN,
    ko_KR,
    ms_MY,
    vi_VN,
    sk_SK
  } = locales;

  const {LATO, OPEN_SANS, NOTO_SANS_KR, NOTO_SANS_SC, NOTO_SANS_THAI, PADAUK, NOTO_SANS_JP} = fonts;

  switch (locale) {
    case en_US:
    case pt_PT:
    case sv_SE:
    case nl_NL:
    case fr_FR:
    case de_DE:
    case it_IT:
    case es_ES:
    case no_NO:
    case fi_FI:
    case hu_HU:
    case pl_PL:
    case da_DK:
    case ms_MY:
      return LATO;
    case tr_TR:
    case ru_RU:
    case cs_CZ:
    case vi_VN:
    case sk_SK:
      return OPEN_SANS;
    case zh_CN:
      return NOTO_SANS_SC;
    case ko_KR:
      return NOTO_SANS_KR;
    case 'th_TH':
      return NOTO_SANS_THAI;
    case 'ja_JP':
      return NOTO_SANS_JP;
    case 'my_MM':
      return PADAUK;
    default:
      return LATO;
  }
};
