import styled from 'styled-components';
import {cardToolbarMetrics} from 'styles/metrics';
import $Icon from 'ui-components/Layout/Icon';
import {commonPalette} from 'shared/styles/palette';

export const colors = {
  iconGrey: commonPalette.txGrey,
  textGrey: '#333330',
  backgroundGrey: '#EDEDED'
};

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
`;

export const LoaderContainer = styled.div`
  margin-top: 200px;
`;

export const PageOptions = styled.div`
  display: block;
  margin-top: 32px;
`;

export const SearchBarContainer = styled.div`
  display: inline-block;
  padding: 8px 0px;
  // border: 1px solid ${colors.backgroundGrey};
  color: ${colors.textGrey};

  > div {
    margin: 0;
  }
`;

export const SortContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid ${colors.backgroundGrey};
  display: block;
  float: right;
  margin: 0 16px;
  text-align: center;

  > button {
    width: 65px;

    > div {
      display: inline-block;
      margin: 3px 0;
      float: right;
    }
  }

  .c-header__popover__inner {
    z-index: 1000 !important;
  }
`;

export const CreateButtonContainer = styled.div`
  display: inline-block;
  float: right;
`;

export const SkillsDiv = styled.div`
  display: flex;
  height: 680px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
`;

export const StyledText = styled.div`
  color: #1f271b;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledSubText = styled.div`
  color: ${colors.grey};
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const DropdownToggle = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border: 0;
  color: currentColor;
  cursor: pointer;
  display: block;
  height: ${cardToolbarMetrics.size}px;
  outline: 0;
  padding: 0;
  position: relative;
  width: ${cardToolbarMetrics.size}px;
  z-index: 0;

  &::after {
    background: currentColor;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &:hover::after {
    opacity: 0.05;
  }
`;

export const NextIcon = styled($Icon).attrs({
  name: 'chevron-right',
  size: 20
})`
  font-size: 18px;
  color: rgba(163, 163, 163, 0.7);
`;

export const StyledFolderIcon = styled($Icon).attrs({
  name: 'folder',
  size: 32
})`
  color: ${colors.iconGrey};
  padding: 0 4px 1px;
  vertical-align: middle;
  border-radius: 50%;
  background-color: ${colors.backgroundGrey};
  margin-right: 8px;
`;

export const DropdownIcon = styled($Icon).attrs(() => ({
  name: 'chevron-down',
  size: 16
}))`
  color: ${colors.grey};
  margin-bottom: 3px;
  margin-right: 5px;
  transform: rotate(${props => (props.open ? '0deg' : '180deg')});
  transition: transform 0.3s ease-out;
  opacity: ${({empty}) => (empty ? 0.5 : 1)};
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

function getTextAlign(props) {
  if (props.left) {
    return 'left';
  }
  return props.right ? 'right' : 'center';
}

export const Th = styled.th`
  padding: 8px;
  text-align: ${props => getTextAlign(props)};
  font-weight: normal;
  font-size: 12px;
  color: ${colors.grey};
  cursor: ${props => (props.hover ? 'pointer' : '')};
  max-width: ${props => props.width};

  &:hover > div {
    text-decoration: ${props => (props.hover ? 'underline' : '')};
  }
`;

export const Td = styled.td`
  height: 40px;
  text-align: ${props => props.align || 'center'};
`;

export const Tr = styled.tr`
  text-align: center;

  ${props =>
    props.border
      ? 'border-bottom: 1px solid #ededed;'
      : '&:not(:last-child) > td { border-bottom: 1px solid #ededed;}'};

  > th:first-child {
    padding-left: 12px;
  }

  > td:first-child {
    padding-left: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 240px;
  }
`;

export const TrCollapsable = styled(Tr)`
  background: #f9f9f9;
  ${props => (props.color ? `color: ${props.color}` : '')};

  td {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    text-align: right;
  }

  > td:first-child {
    cursor: pointer;
    font-weight: bold;
    border-left: 1px solid #ededed;
    text-align: left;
  }

  > td:last-child {
    border-right: 1px solid #ededed;
    float: right;
    padding: 8px;
  }
`;

export const TrCustom = styled(Tr)`
  > td {
    padding: 0 8px;
    text-alight: left;
  }

  > td:first-child {
    padding-left: 36px;
  }

  > td:last-child {
    float: right;
    padding: 8px;
  }
`;

export const NoData = styled.div`
  margin-top: 30px;
`;
