import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditUser.myAccount',
    defaultMessage: 'My Account'
  },
  fullName: {
    id: 'EditUser.fullName',
    defaultMessage: 'Full name'
  },
  password: {
    id: 'common.forms.password',
    defaultMessage: 'Password'
  },
  changePassword: {
    id: 'ProfileSettings.changePassword',
    defaultMessage: 'Change password'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  updateSuccess: {
    id: 'ProfileSettings.updateSuccess',
    defaultMessage: 'Your profile has been updated!'
  },
  mergeWithCompanyAccount: {
    id: 'ProfileSettings.mergeWithCompanyAccount',
    defaultMessage: 'Merge user with company account'
  },
  mergeSuccess: {
    id: 'ProfileSettings.mergeSuccess',
    defaultMessage: 'Accounts successfully merged'
  }
});

export default messages;
