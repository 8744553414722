export const tagCreationRoleOptions = [
  {
    name: 'Platform Admins only',
    id: 'PLATFORM_ADMIN'
  },
  {
    name: 'Platform Admins and Team Admins',
    id: 'ADMIN'
  },
  {
    name: 'Platform Admins, Team Admins, and Editors',
    id: 'EDITOR'
  }
];
