export const USER_ROLE_ENUMS = {
  EDITOR: 'EDITOR',
  ADMIN: 'ADMIN',
  PLATFORM_ADMIN: 'PLATFORM_ADMIN',
  VIEWER: 'VIEWER'
};

export const IPA_USER_ROLE_ENUMS = {
  IPA: 'IPA'
};

export const NOTIFICATION_RECIPIENTS = {
  ALL_IN_TEAM: 'ALL_IN_TEAM',
  ALL_WORKING_ON_GUIDE: 'ALL_WORKING_ON_GUIDE'
};

export const MEDIA_PREVIEW_DEVICE = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE'
};

export const MEDIA_PREVIEW_TYPE = {
  FULL_IMAGE: 'FULL_IMAGE',
  GUIDE_OVERVIEW: 'GUIDE_OVERVIEW',
  INSTRUCTION_COVER: 'INSTRUCTION_COVER',
  INSTRUCTION_OVERVIEW: 'INSTRUCTION_OVERVIEW',
  STEP: 'STEP',
  STEP_OVERVIEW: 'STEP_OVERVIEW'
};

export const MEDIA_GALLERY_FILTER_LEVEL = {
  GUIDE: 'GUIDE',
  INSTRUCTION: 'INSTRUCTION'
};

export const MEDIA_GALLERY_STEPS = {
  UPLOAD: 'UPLOAD',
  EDIT: 'EDIT',
  OVERLAY: 'OVERLAY'
};

export const INSTRUCTION_EDIT_MODE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE'
};

export const TOPIC_TITLE_TYPE = {
  COLLAPSIBLE: 'COLLAPSIBLE',
  HIDDEN: 'HIDDEN',
  VISIBLE: 'VISIBLE'
};

export const TRANSLATION_ITEM_TYPE = {
  GUIDE: 'GUIDE',
  TOPIC: 'TOPIC',
  INSTRUCTION: 'INSTRUCTION',
  STEP: 'STEP',
  TIP: 'TIP',
  FIX: 'FIX',
  WARNING: 'WARNING',
  ALTERNATIVE: 'ALTERNATIVE',
  NOTE: 'NOTE',
  CHECKLIST: 'CHECKLIST',
  CHECK: 'CHECK'
};

export const ANCHOR_TYPE = {
  TOP_LEFT: 'TOP-LEFT',
  TOP_RIGHT: 'TOP-RIGHT',
  BOTTOM_LEFT: 'BOTTOM-LEFT',
  BOTTOM_RIGHT: 'BOTTOM-RIGHT'
};

export const ICON_TYPES = {
  WARNING: 'warning',
  EXPORT_TO_PDF: 'exportToPdf',
  EXPORT_TO_CSV: 'file-csv'
};

export const CMS_LOGIN_OPTIONS = {
  EMAIL: 'EMAIL',
  SSO: 'SSO'
};

export const GUIDE_APPROVAL_TYPES = {
  PARALLEL: 'PARALLEL',
  SEQUENTIAL: 'SEQUENTIAL'
};

export const SHARE_DIALOG_TYPE = {
  INSTRUCTION: 'Instruction',
  CHECKLIST: 'Checklist',
  GUIDE: 'Guide',
  TOPIC: 'Topic'
};
