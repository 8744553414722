import axios from 'axios';

import {getLastPublishedGuideByLocaleQuery} from 'api/guide/queries';

import {pageSize, alignmentType, colors, emptyTextForForrmating, fontSettings} from './constants';
import {generateContent} from './generate/content';
import messages from './messages';
import {mapIconsToGuide, formatPdfMessages, getFontByLocale} from './utils';

export const generatePdf = async (
  guideId,
  selectedLocale,
  lastVersion,
  intl,
  platformName,
  icons,
  setError,
  {pdfMake}
) => {
  const {version: versionNumber, _id: versionId} = lastVersion;

  const guide = await axios
    .post('/graphql', {query: getLastPublishedGuideByLocaleQuery(guideId, versionId, selectedLocale)})
    .then(response => response.data.data.guideForCms);

  guide.lastVersion = lastVersion;
  guide.icons = icons;

  const pdfMessages = formatPdfMessages(intl, messages);

  // Workaround until the issue with the custom fonts is done
  if (guide.locale === 'zh_CN' || guide.locale === 'ko_KR') {
    setError({error: true, errorMessage: pdfMessages.languageNotSupported});

    return;
  }

  let guideBaseUrl = window.location.href.split('/admin/guides')[0].concat(`/guide/${guide.slug}`);
  const publishedOnDefaultDomain = guide.domains.find(domain => domain.isDefault);

  //if guide is not published on default domain, get first domain guide is published to
  //TO DO - select a domain the user can access for sure
  if (!publishedOnDefaultDomain && guide.domains.length) {
    const regexp = /(^https?:\/\/)(.+\.[^.]+)/;

    const matchResult = regexp.exec(guideBaseUrl);

    if (matchResult) {
      const [, http, url] = matchResult;
      const slug = guide.domains[0].slug;

      guideBaseUrl = `${http}${slug}-${url}`;
    }
  }

  // Only for dev purposes
  if (guideBaseUrl.includes('localhost:3033') || guideBaseUrl.includes('local:3033')) {
    guideBaseUrl = guideBaseUrl.replace('3033', '3030');
  }

  mapIconsToGuide(guide, icons);

  const font = getFontByLocale(guide.locale);
  const fonts = {
    [font]: fontSettings[font]
  };

  const documentDefinition = {
    pageSize,
    footer: currentPage => [
      {
        text: currentPage === 1 ? emptyTextForForrmating : `${pdfMessages.version} ${versionNumber}`,
        alignment: alignmentType.LEFT,
        margin: [35, 20, 0, 25],
        color: colors.GRAY,
        fontSize: 11
      },
      {
        text: currentPage === 1 ? emptyTextForForrmating : currentPage,
        alignment: alignmentType.CENTER,
        margin: [0, -41, 0, 20],
        fontSize: 11
      }
    ],
    content: await generateContent(guide, pdfMessages, guideBaseUrl, platformName, intl),
    pageMargins: [20, 20, 20, 50],
    defaultStyle: {
      font,
      lineHeight: 1.2
    }
  };

  pdfMake.createPdf(documentDefinition, null, fonts).download(`${guide.title}.pdf`);
};
