import styled from 'styled-components';
import Field from 'shared/components/Field';

export const WebhookUrlContainer = styled.div`
  height: 100%;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > * + * {
    margin-top: 15px;
  }
`;

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > * + * {
    margin-top: 15px;
  }
`;

export const UrlFieldContainer = styled.div`
  display: flex;
  align-items: end;

  & > * + * {
    margin-left: 10px;
  }
`;

export const UrlField = styled(Field)`
  & > [class*='Error'] {
    display: none;
  }
`;
