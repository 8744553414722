import {useMemo} from 'react';
import {AUTO_TRANSLATE_DIALOGS_MAP} from 'stores/dialogs/auto-translation-dialog';
import {getAddNewLanguageDialogConfig} from './add-new-language';
import {getSelectLanguageDialogConfig} from './select-language';
import {getTranslateGuideDialogConfig} from './translate-guide';
import {getTranslationErrorDialogConfig} from './translation-error';
import {getTranslationInProgressDialogConfig} from './translation-in-progress';
import {getTranslationSuccessDialogConfig} from './translation-success';

export const useAutoTranslationDialog = (store, formatMessage) => {
  return useMemo(() => {
    const dialogMap = {
      [AUTO_TRANSLATE_DIALOGS_MAP.translateGuide]: getTranslateGuideDialogConfig,
      [AUTO_TRANSLATE_DIALOGS_MAP.selectLanguage]: getSelectLanguageDialogConfig,
      [AUTO_TRANSLATE_DIALOGS_MAP.translationInProgress]: getTranslationInProgressDialogConfig,
      [AUTO_TRANSLATE_DIALOGS_MAP.addNewLanguage]: getAddNewLanguageDialogConfig,
      [AUTO_TRANSLATE_DIALOGS_MAP.translationSuccess]: getTranslationSuccessDialogConfig,
      [AUTO_TRANSLATE_DIALOGS_MAP.translationError]: getTranslationErrorDialogConfig
    };
    return dialogMap[store.autoTranslationDialog.currentDialog](store, formatMessage);
  }, [
    store.autoTranslationDialog.currentDialog,
    store.autoTranslationDialog.selectedLanguageToTranslate,
    store.autoTranslationDialog.selectedLanguageToAdd,
    store.autoTranslationDialog.loading
  ]);
};
