import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${({width}) => (width ? `${width}px;` : '100%')};
`;

export const DropdownTrigger = styled.div``;

export const Dropdown = styled.div`
  position: absolute;
  background: white;
  max-height: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  width: 100%;
  top: 100%;
  border: 1px solid ${commonPalette.borderGrey};
  border-radius: 0 0 4px 4px;
  transition: all 0.1s ease-in-out;

  ${p =>
    p.open &&
    `
    opacity: 1;
    max-height: 250px;
    height: auto;
    z-index: 3;
    transition: all 0.1s ease-in-out;
  `}
`;
