/**
 * This is just a temporary component that helps
 * transitioning to clients-shared lib "Field" component.
 * SHOULD NOT BE USED ON NEWLY INTREGATIONS.
 * Refer to 'clients-shared' Readme for more info on how to use.
 */

import React, {Component} from 'react';
import {observer} from 'mobx-react';

import Field from 'shared/components/Field';

import {bindField} from 'shared/utils/input-utils';

import Icon from '../Icon';
import {commonPalette} from 'shared/styles/palette';

function higherOrderComponent(WrappedComponent) {
  return class extends Component {
    render() {
      const {form, field, titleDescription, inline, fieldOptions} = this.props;

      return (
        <WrappedComponent
          {...this.props}
          {...(form && bindField(form, field, fieldOptions))}
          {...(titleDescription && {
            // used for labels icons like popover
            icon: <Icon style={{marginLeft: 8, color: commonPalette.txtGrey}} popoverContent={titleDescription} />
          })}
          {...(inline && {
            // used for inputs that have read-only mode -> example EditUser
            icon: <Icon iconId="edit" />
          })}
        />
      );
    }
  };
}

export default observer(higherOrderComponent(Field));
