import {defineMessages} from 'react-intl';

const messages = defineMessages({
  alreadyLoggedIn: {
    id: 'Login.alreadyLoggedIn',
    defaultMessage: "You're already logged in."
  }
});

export default messages;
