import styled from 'styled-components';

import Select from 'ui-components/Select';

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const StyledTagsCreationSelect = styled(Select)`
  width: 350px;
`;

export const WrapperInfo = styled.div`
  flex: 4;
  margin-bottom: 8px;
`;

export const Header = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;
