import {client} from '../apollo-client';
import {handleAsyncError} from '../error-handler';

import {Track} from 'api/tracking/mutations';

/** Sends a mutation to the server to track an event.
 *
 * @param {string} eventType - Type of the event being tracked.
 * @param {string} metadata - JSON serialized string containing event data.
 */
function trackMutation(eventType, metadata) {
  handleAsyncError(async () => {
    await client.mutate({
      mutation: Track,
      variables: {eventType, metadata}
    });
  });
}

export {trackMutation};
