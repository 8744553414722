import {action, computed, observable, makeObservable} from 'mobx';

class ShareGuidePage {
  @observable guideId;

  @action
  reset = () => {
    this.guideId = null;
  };

  @action
  init = guideId => {
    this.guideId = guideId;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get embedCode() {
    const url = this.getUrl('embed');
    const onLoad =
      "!this.src && (this.src=this.getAttribute('data-swipeguide-src') + '&embed_referer='+encodeURIComponent(document.location));";
    return `<iframe width="736" height="420" onLoad="${onLoad}" data-swipeguide-src="${url}"></iframe>`;
  }

  @computed
  get shareUrl() {
    return this.getUrl('share');
  }

  @computed
  get qrUrl() {
    return this.getUrl('qr');
  }

  //helpers

  getUrl = pathName => {
    const entityUrl = 'guide';
    const beginning = `${window.location.origin.replace('3033', '3015')}/${pathName}/`;
    const url = `${entityUrl}/${this.guideId}`;
    return `${beginning}${url}${pathName === 'embed' ? '?embed=true' : ''}`;
  };
}

export default ShareGuidePage;
