import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import isEmpty from 'lodash/isEmpty';

// styles
import {Feedbacks} from './styles';

//components
import FeedbackRow from 'components/FeedbackRow';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

//queries
import {FeedbacksList} from 'api/feedback/queries';

//options
import {feedbacksListOptions} from 'api/feedback/query-options';

@inject('store')
@graphql(FeedbacksList, feedbacksListOptions)
class FeedbackComponent extends Component {
  render() {
    const {data} = this.props;
    const {feedbacksForGuide: feedbacks, loading, error} = data;

    if (loading || error) {
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }

    const feedbackRows =
      feedbacks && feedbacks.map((f, index) => <FeedbackRow key={index} feedback={f} showDivider={!!index} />);
    return (
      <Feedbacks>
        {isEmpty(feedbacks) && <FormattedMessage {...messages.noFeedbacks} />}
        {feedbacks && feedbackRows}
      </Feedbacks>
    );
  }
}

export default FeedbackComponent;
