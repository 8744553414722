import {action, observable, makeObservable} from 'mobx';

//helpers
import notification from 'utils/notification-utils';
import store from 'stores/store';
import views from 'config/views';
import {commentForm, getComment} from 'stores/forms/comment-form';

class RequestApprovalDialog {
  @observable opened = false;
  @observable id;
  @observable versionId;
  @observable comment;
  translations;
  requestApprovalMutation;
  form = commentForm();

  @action
  reset = () => {
    this.opened = false;
    this.id = null;
    this.versionId = null;
    this.form.reset();
    this.translations = null;
    this.requestApprovalMutation = null;
  };

  @action open = ({id, translations, requestApprovalMutation, versionId}) => {
    this.opened = true;
    this.id = id;
    this.versionId = versionId;
    this.translations = translations;
    this.requestApprovalMutation = requestApprovalMutation;
  };

  @action requestApproval = () => {
    const requestApprovalSuccess = () => {
      notification.success(this.translations.requestApprovalSuccess);
      store.router.goTo(views.editGuide, {id: this.id}, {v: 'review'});
      this.reset();
    };

    const requestApprovalFailure = () => {
      notification.error(this.translations.requestApprovalFailure);
      this.reset();
    };
    const comment = getComment(this.form);
    this.requestApprovalMutation({id: this.id, versionId: this.versionId, comment}).then(
      requestApprovalSuccess,
      requestApprovalFailure
    );
  };

  constructor() {
    makeObservable(this);
  }
}

export default RequestApprovalDialog;
