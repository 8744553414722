import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import injectScrollParent from 'decorators/injectScrollParent';
import isEmpty from 'lodash/isEmpty';

//styled-components
import {ShapeTable, TipWrapper} from './styles';
import DraggableShapeRow from './DraggableShapeRow';
import CustomDragLayer from './CustomDragLayer';
import {FormattedMessage} from 'shared/components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@injectScrollParent
@observer
class MediaShapesComponent extends Component {
  render() {
    const {
      store: {mediaPickerDialog, mediaOverlayDialog}
    } = this.props;
    const {overlayShapes} = mediaPickerDialog;
    const {setShouldCollapse} = mediaOverlayDialog;

    if (!overlayShapes || isEmpty(overlayShapes))
      return (
        <TipWrapper>
          <FormattedMessage {...messages.selectAShape} />
        </TipWrapper>
      );

    return (
      <ShapeTable>
        {overlayShapes &&
          overlayShapes.map((shape, position) => {
            return (
              <DraggableShapeRow
                key={shape.id}
                position={position}
                shape={shape}
                onBeginDrag={() => setShouldCollapse(true)}
                onEndDrag={() => setShouldCollapse(false)}
              />
            );
          })}
        <CustomDragLayer />
      </ShapeTable>
    );
  }
}

export default injectIntl(MediaShapesComponent);
