import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const HelpLink = styled.a`
  transition: none;
  font-size: 14px;
  line-height: 24px;
  color: ${commonPalette.btnGreen};

  &:hover {
    color: ${commonPalette.btnGreen};
    text-decoration: underline;
  }
`;
