import styled from 'styled-components';

import Field from 'ui-components/Field';
import ButtonGroup from 'ui-components/ButtonGroup';
import Spinner from 'shared/components/Spinner';

import {Title as $Title} from 'shared/styles/styled-components/field-styled-components';

import {commonPalette} from 'shared/styles/palette';

export const TranslationContentWrapper = styled.div`
  margin: 8px;
  display: flex;
`;

export const LanguagePickerWrapper = styled.div`
  margin: 8px 8px 16px 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${commonPalette.borderGrey};
  padding-bottom: 16px;
`;

export const DefaultLanguageQuestionWrapper = styled.div`
  flex: 1;
`;

export const TranslationField = styled(Field)`
  flex: 1;
`;

export const Title = styled($Title).attrs({
  bold: true
})``;

export const StyledSpinner = styled(Spinner)`
  margin: auto;
`;

export const SelectWrapper = styled.div`
  flex: 1;
`;

export const StyledError = styled.div`
  margin: 20px;
`;

export const StyledButtonGroup = styled(ButtonGroup)`
  padding-bottom: 20px;
`;
