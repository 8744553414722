import {defineMessages} from 'react-intl';

const messages = defineMessages({
  nameYourGuide: {
    id: 'EditGuide.nameYourGuide',
    defaultMessage: 'Name your guide'
  },
  defaultLanguage: {
    id: 'common.forms.defaultLanguage',
    defaultMessage: 'Default Language'
  },
  defaultLanguageDescription: {
    id: 'common.description.defaultLanguageDescription',
    defaultMessage: 'The language a guide is created in.'
  },
  team: {
    id: 'common.forms.team',
    defaultMessage: 'Team'
  },
  tags: {
    id: 'EditGuide.tags',
    defaultMessage: 'Tags'
  },
  visibleTo: {
    id: 'EditGuide.visibleTo',
    defaultMessage: 'Visible to'
  },
  selectWorkspaces: {
    id: 'EditGuide.selectWorkspaces',
    defaultMessage: 'Select workspaces'
  },
  saveDraft: {
    id: 'EditGuide.saveDraft',
    defaultMessage: 'Save draft'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  publish: {
    id: 'EditGuide.publish',
    defaultMessage: 'Publish'
  },
  publishUpdates: {
    id: 'EditGuide.publishUpdates',
    defaultMessage: 'Publish updates'
  },
  publishOldVersion: {
    id: 'EditGuide.publishOldVersion',
    defaultMessage: 'Publish old version'
  },
  unpublishGuide: {
    id: 'EditGuide.unpublishGuide',
    defaultMessage: 'Unpublish guide'
  },
  setAsDraft: {
    id: 'EditGuide.setAsDraft',
    defaultMessage: 'Set as draft'
  }
});

export default messages;
