import {CAMPAIGN_QUESTION_TYPE_ENUMS, CAMPAIGN_FOLLOW_UP_TYPE_ENUMS} from 'shared/enums';

const {YES_NO, PASS_FAIL, STAR, NUMBER, TEXT: TEXT_OPTIONS} = CAMPAIGN_QUESTION_TYPE_ENUMS;
const {TEXT} = CAMPAIGN_FOLLOW_UP_TYPE_ENUMS;

export const templates = {
  training: [
    {
      value: 'read_and_understood',
      name: 'Read & Understood',
      imageUrl: require('img/signoff-templates/read_and_understood.png'),
      question: {
        kind: YES_NO,
        numOfRating: 1,
        question: 'Did you read and understand this instruction?',
        followUps: [],
        textTypeOptions: []
      }
    },
    {
      value: 'skill_level_range',
      name: 'Skill Level (Range)',
      imageUrl: require('img/signoff-templates/skill_level_range.png'),
      question: {
        kind: NUMBER,
        numOfRating: 100,
        question: 'How confident (in %) are you performing this task?',
        followUps: [],
        textTypeOptions: []
      }
    },
    {
      value: 'pass_fail',
      name: 'Pass / Fail',
      imageUrl: require('img/signoff-templates/pass_fail.png'),
      question: {
        kind: PASS_FAIL,
        numOfRating: 1,
        question: 'Are you able to complete the task at the required level, according to your trainer?',
        followUps: [
          {
            kind: TEXT,
            rangeFrom: 0,
            rangeTo: 1,
            question: 'Please add below the name of your trainer.'
          }
        ],
        textTypeOptions: []
      }
    },
    {
      value: 'skill_level_multiple_choice',
      name: 'Skill Level (Multiple Choice)',
      imageUrl: require('img/signoff-templates/skill_level_multiple_choice.png'),
      question: {
        kind: TEXT_OPTIONS,
        numOfRating: 5,
        question: 'At which level can you currently complete the task, according to your trainer?',
        followUps: [],
        textTypeOptions: [
          {option: 'Cannot do'},
          {option: 'Can do with help'},
          {option: 'Can do alone'},
          {option: 'Can train others'},
          {option: 'Can conduct evaluations'}
        ]
      }
    }
  ],
  process: [
    {
      value: 'task_confirmation',
      name: 'Task Confirmation',
      imageUrl: require('img/signoff-templates/task_confirmation.png'),
      question: {
        kind: YES_NO,
        numOfRating: 1,
        question: 'Where you able complete this task according to standard?',
        followUps: [
          {
            kind: TEXT,
            rangeFrom: 0,
            rangeTo: 0,
            question: 'Why were you not able to complete the task?'
          }
        ],
        textTypeOptions: []
      }
    }
  ],
  feedback: [
    {
      value: 'instruction_scoring',
      name: 'Instruction Scoring',
      imageUrl: require('img/signoff-templates/instruction_scoring.png'),
      question: {
        kind: STAR,
        numOfRating: 5,
        question: 'How useful was this instruction?',
        followUps: [],
        textTypeOptions: []
      }
    },
    {
      value: 'first_time_right',
      name: 'First Time Right',
      imageUrl: require('img/signoff-templates/first_time_right.png'),
      question: {
        kind: TEXT_OPTIONS,
        numOfRating: 7,
        question: 'This instruction made it easier for me to execute this task the first time right.',
        followUps: [
          {
            kind: TEXT,
            rangeFrom: 1,
            rangeTo: 3,
            question: 'What can we improve?'
          }
        ],
        textTypeOptions: [
          {option: 'Strongly Disagree'},
          {option: 'Disagree'},
          {option: 'Somewhat Disagree'},
          {option: 'Neutral'},
          {option: 'Somewhat Agree'},
          {option: 'Agree'},
          {option: 'Strongly Agree'}
        ]
      }
    }
  ]
};

export const templateTypes = Object.keys(templates);
