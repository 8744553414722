import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import $Icon from '../Icon';

export const ToggleWrapper = styled.div`
  height: auto;
  width: 49px;
  border: 1px solid ${p => (p.on ? 'rgba(0,0,0,0.15)' : 'rgba(0,0,0,0.1)')};
  border-radius: 16px;
  background-color: ${p => (p.on ? '#217F87' : 'rgba(208,208,208,0.56)')};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ToggleSwitch = styled.div`
  height: 24px;
  width: 26px;
  border-radius: 100%;
  background-color: #ffffff;
  margin: 2px;
  margin-left: ${p => (p.on ? 'calc(100% - 28px)' : '2px')};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
`;

export const Icon = styled($Icon)`
  color: ${commonPalette.btnGreen};
  font-size: 14px;
`;
