import styled, {css} from 'styled-components';
import flex from 'styles/flex';
import Dropzone from 'react-dropzone';
import {cond} from 'shared/utils/sc-utils';
import colors from 'styles/colors';
import {swallowingStyled} from 'styles/styled-components/utils';
import {Title as $Title} from 'shared/styles/styled-components/field-styled-components';

export const overlayClassName = 'c-upload-image__overlay';
import Icon from 'ui-components/Layout/Icon';

export const UploadImageWrapper = styled.div``;

export const UploadImage = swallowingStyled(Dropzone, {
  swallowProps: ['imageThumbnail', 'styles', 'loadingImage']
})`
  ${flex.horizontal}
  ${flex.centerHorizontal}
  transition: all 150ms linear;
  width: 100%;
  height: 170px;
  background-color: #EAEAEA;
  position: relative;
  color: #ffffff;
  border-radius: 8px;
  border: 1px dashed rgba(51, 51, 51, 0.3);

  @media (max-width: 600px) {
    display: block;
  }

  ${p => cond(p.imageThumbnail, `background: url('${p.imageThumbnail}') center / cover no-repeat;`)}

  ${p =>
    cond(
      p.loadingImage === true,
      css`
        .${overlayClassName} {
          opacity: 0.8;
        }
      `
    )}

  ${p =>
    cond(
      p.loadingImage === false,
      css`
        &:hover {
          .${overlayClassName} {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      `
    )}

  ${p =>
    cond(
      !p.imageThumbnail && !p.loadingImage,
      css`
        &:hover {
          cursor: pointer;
          background-color: #dcd9d9;
        }
      `
    )}

  ${p => p.styles}
`;

export const UploadImageActiveStyle = {
  border: `3px dashed ${colors.primary}`
};

export const Text = styled.div`
  width: 200px;
  height: 32px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #555555;
  font-family: Lato;
  @media (max-width: 1300px) {
    font-size: 13px;
    width: 170px;
  }
  @media (max-width: 1150px) {
    font-size: 10px;
    width: 150px;
  }
  @media (max-width: 1000px) {
    width: 110px;
  }
  @media (max-width: 720px) {
    width: 90px;
  }

  @media (max-width: 680px) {
    width: 80px;
  }
  @media (max-width: 600px) {
    font-size: 8px;
  }
`;

export const Title = styled($Title)`
  margin-bottom: 1rem;
`;

export const AcceptedFiles = styled.div`
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #333330;
  @media (max-width: 1100px) {
    font-size: 10px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
`;

export const MiddleText = styled.div`
  letter-spacing: 0.3px;
  color: #121212;
  font-family: Lato;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 8%;
  @media (max-width: 1300px) {
    font-size: 13px;
  }
  @media (max-width: 1150px) {
    font-size: 10px;
  }
  @media (max-width: 720px) {
    display: none;
  }
`;

export const MenuIcon = styled(Icon)`
  color: #555555;
  width: 32px;
  height: 32px;
  @media (max-width: 1300px) {
    width: 28px;
    height: 28px;
  }
  @media (max-width: 1150px) {
    width: 25px;
    height: 25px;
  }
  @media (max-width: 600px) {
    width: 23px;
    height: 23px;
    margin-top: 3px;
  }
`;

export const StyledVeticalDiv = styled.div`
  ${flex.vertical}
  ${flex.centerVertical}
`;

export const UploadingDiv = styled.div`
  color: #298784;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 5px;
`;
