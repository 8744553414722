import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react';
import {inject, observer} from 'mobx-react';
import {FormattedMessage, injectIntl} from 'react-intl';
import debounce from 'lodash/debounce';

//components
import Container from 'ui-components/Layout/Container';
import DataTable from 'ui-components/Layout/DataTable';
import {HeaderWrapper, CellWrapper} from 'ui-components/Layout/DataTable/styles';
import {maxNumberOfRowsPerPage as pageSize} from 'ui-components/Layout/DataTable/constants';
import SearchBar from 'ui-components/Layout/SearchBar';
import {Button} from 'ui-components';
import EmptyLearningActivities from 'views/CreateSkills/EmptyLearningActivities';
import LoadingMessage from 'components/LoadingMessage';
import {useAbortableQuery} from 'hooks/useAbortableQuery';
import TagsChips from 'components/TagsChips';
import {Filters} from 'components/Filters/Filters';
import TagPicker from '../../components/TagPicker';
import InfoBox from 'ui-components/InfoBox';
import DeleteLearningActivityDialog from './DeleteLearningActivityDialog';

import {TableInfoDiv, TableOverlay} from 'views/Skills/styles';
import {
  Header,
  Row,
  DropwdownItem,
  StyledText,
  TableActionsHeader,
  TableActions,
  FiltersWrapper,
  StyledMenu
} from './styles';

import {AllSkillTasks} from 'api/skills/queries';
import views from 'config/views';

import messages from './messages';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';
import FiltersChips from 'components/FiltersChips';

const MAX_SELECTED = 10;

import LearningActivityMenu from './LearningActivityMenu';

type LoadingComponentProps = {
  loading: boolean;
};

type CellComponentProps = {
  value: string;
};

type TagsCellComponentProps = {
  value: Tag[];
};

interface Tag {
  title: string;
  id: string;
}

const HeaderCellComponent = ({value}: CellComponentProps): ReactElement | false => {
  return (
    <CellWrapper data-cy={value.split(' ').join('-')}>
      <span title={value}>{value}</span>
    </CellWrapper>
  );
};

const AppliedToSkillsCellComponent = ({value}: CellComponentProps): ReactElement | false => {
  return (
    <CellWrapper>
      <span title={value}>{value}</span>
    </CellWrapper>
  );
};

const TagsCellComponent = ({value}: TagsCellComponentProps): ReactElement | false => {
  return (
    <CellWrapper paddingLeft>
      <TagsChips tags={value} />
    </CellWrapper>
  );
};

const LoadingComponent = ({loading}: LoadingComponentProps): ReactElement | false => {
  return (
    loading && (
      <>
        <TableInfoDiv>
          <LoadingMessage>
            <FormattedMessage {...messages.loading} />
          </LoadingMessage>
        </TableInfoDiv>
        <TableOverlay />
      </>
    )
  );
};

const ActionsCellComponent = (learningActivity): ReactElement | false => (
  <CellWrapper style={{alignItems: 'center'}}>
    <StyledMenu
      dataCy={`menu-skill-task-${learningActivity.title.split(' ').join('-')}`}
      dropdownMenu={<LearningActivityMenu learningActivity={learningActivity} />}
    />
  </CellWrapper>
);

const NoDataFoundComponent = (): ReactElement => {
  return (
    <>
      <TableInfoDiv>
        <FormattedMessage {...messages.noLearningActivitiesFound} />
      </TableInfoDiv>
      <TableOverlay />
    </>
  );
};

type LearningActivitiesProps = {
  intl: any;
  store: any;
};

type LearningActivity = {
  title: string;
  skillCount: number;
  tags: Tag[];
  id: string;
};

type LearningActivitiesQueryData = {
  results: LearningActivity[];
  totalCount: number;
};

const usePaginationParameters = (
  refetch: (parameters: {pageIndex: number; sorted: {id: string; desc: boolean}[]; filtered: string}) => unknown
) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [sorted, setSorted] = useState<{id: string; desc: boolean}[]>([{id: 'title', desc: false}]);
  const [filtered, setFiltered] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredByTag, setFilteredByTag] = useState<Tag[]>([]);
  useEffect(() => {
    refetch({
      pageIndex,
      sorted,
      filtered
    });
  }, [pageIndex, sorted, filtered, filteredByTag]);

  // Reset pagination when filters or sorting change
  useEffect(() => {
    setPageIndex(0);
  }, [filtered, sorted, filteredByTag]);

  return {
    pageIndex,
    setPageIndex,
    sorted,
    setSorted,
    filtered,
    setFiltered,
    searchTerm,
    setSearchTerm,
    filteredByTag,
    setFilteredByTag
  };
};

const LearningActivities = (props: LearningActivitiesProps): ReactElement => {
  const {
    intl: {formatMessage},
    store: {
      router,
      learningActivityGeneralInfo,
      platform: {
        developmentFeatureFlags: {learningActivityTags}
      }
    }
  } = props;

  const [learningActivitiesQuery, {data, loading, error}] = useAbortableQuery<{
    allSkillTasksPaginated: LearningActivitiesQueryData;
  }>(AllSkillTasks, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false
  });

  const fetchData: Parameters<typeof usePaginationParameters>[0] = useCallback(
    ({filtered, pageIndex, sorted}) => {
      learningActivitiesQuery({
        variables: {
          limit: pageSize,
          offset: pageIndex * pageSize,
          sortBy: sorted.map(sortByItem => {
            return {field: sortByItem.id, order: sortByItem.desc ? 'desc' : 'asc'};
          }),
          filters: {
            title: {contains: filtered},
            tags: {in: learningActivityGeneralInfo.filterSelectedTagsIds}
          }
        }
      });
    },
    [learningActivitiesQuery]
  );

  const {
    pageIndex,
    setPageIndex,
    sorted,
    setSorted,
    filtered,
    setFiltered,
    searchTerm,
    setSearchTerm,
    setFilteredByTag,
    filteredByTag
  } = usePaginationParameters(fetchData);

  const pages = useMemo(() => {
    if (!data) return null;
    return Math.ceil(data.allSkillTasksPaginated.totalCount / pageSize);
  }, [data]);

  const columns = [
    {
      Header: <HeaderWrapper>{formatMessage(messages.learningActivityName)}</HeaderWrapper>,
      accessor: 'title',
      Cell: HeaderCellComponent
    },
    ...(learningActivityTags
      ? [
          {
            Header: <HeaderWrapper paddingLeft>{formatMessage(messages.filterByTag)}</HeaderWrapper>,
            accessor: 'tags',
            Cell: TagsCellComponent,
            resizable: false,
            sortable: false,
            width: 300
          }
        ]
      : []),
    {
      Header: <HeaderWrapper>{formatMessage(messages.appliedToSkills)}</HeaderWrapper>,
      headerStyle: {
        justifyContent: 'flex-end'
      },
      accessor: 'skillCount',
      maxWidth: 150,
      Cell: AppliedToSkillsCellComponent,
      sortable: false
    },
    {
      sortable: false,
      maxWidth: 50,
      style: {
        justifyContent: 'center'
      },
      Cell: ({original}) => ActionsCellComponent(original)
    }
  ];
  const handleUpdateSearchTerm = e => {
    e.preventDefault();
    e.stopPropagation();
    const newValue = e.target?.value.substring(0, 50);
    setSearchTerm(newValue);

    trackEvent(EVENT_TYPES.SEARCH_LEARNING_ACTIVITY, {searchTerm: newValue});
    return updateSearchTerm(newValue);
  };

  const updateSearchTerm = debounce((newValue: string) => {
    if (!newValue.length || (newValue.length >= 3 && newValue.length <= 50)) {
      setFiltered(newValue);
    }
  }, 300);

  const filterByTag = () => {
    const {
      store: {
        learningActivityGeneralInfo: {filterLocalSelectedTags, setFilterSelectedTags}
      }
    } = props;
    const ids = filterLocalSelectedTags.map(tag => tag.id);
    setFilterSelectedTags(ids);
    setFilteredByTag(filterLocalSelectedTags);
  };

  const removeSelectedTag = tag => {
    const {
      store: {
        learningActivityGeneralInfo: {setFilterLocalSelectedTags, filterLocalSelectedTags}
      }
    } = props;
    const tags = filterLocalSelectedTags.filter(i => i.id !== tag.id);
    setFilterLocalSelectedTags(tags);

    filterByTag();
  };

  const resetFilters = () => {
    const {
      store: {
        learningActivityGeneralInfo: {setFilterLocalSelectedTags, setFilterSelectedTags}
      }
    } = props;

    setFilterLocalSelectedTags([]);
    setFilterSelectedTags([]);
    setFilteredByTag([]);
  };

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  return (
    <Container style={{paddingBottom: '50px', height: '100%'}}>
      <Header>{formatMessage(messages.learningActivities)}</Header>

      {error ? (
        <FormattedMessage {...messages.error} />
      ) : (
        <>
          <TableActionsHeader>
            <FiltersWrapper>
              <SearchBar
                value={searchTerm}
                placeholder={formatMessage(messages.searchByName)}
                onChange={handleUpdateSearchTerm}
              />
              {learningActivityTags && (
                <Filters
                  filtersTitle={formatMessage(messages.filtersTitle)}
                  formatMessage={formatMessage}
                  onApply={filterByTag}
                  onReset={resetFilters}
                  totalFiltersApplied={learningActivityGeneralInfo.filterSelectedTagsIds.length}
                >
                  {learningActivityGeneralInfo.filterLocalSelectedTags.length >= MAX_SELECTED && (
                    <InfoBox content={formatMessage(messages.tenTags)} type="informational" size="small" />
                  )}

                  <DropwdownItem>
                    <StyledText>{formatMessage(messages.filterByTag)}</StyledText>
                    <TagPicker
                      selectedTagsIds={learningActivityGeneralInfo.filterLocalSelectedTags.map(tag => tag.id)}
                      setLocalSelectedTags={learningActivityGeneralInfo.setFilterLocalSelectedTags}
                      localSelectedTags={learningActivityGeneralInfo.filterLocalSelectedTags}
                      showCheckBoxSelector
                      disableCreate
                      isSkillTagsPicker={true}
                    />
                  </DropwdownItem>
                </Filters>
              )}
            </FiltersWrapper>
            <TableActions>
              {/* HIDE UNTIL WE HAVE MORE SORTING FUNCTIONALITY <Select
                placeholder={formatMessage(messages.sortBy)}
                selectedValue={null}
                onChange={() => {}}
                options={[{id: 'name', name: formatMessage(messages.name)}]}
                defaultValue={formatMessage(messages.name)}
                style={{width: '120px'}}
              /> */}
              <Button
                label={formatMessage(messages.createLearningActivity)}
                secondary
                iconId={'create'}
                onClick={() => {
                  learningActivityGeneralInfo.updatePreviousPage(views.learningActivities);
                  router.goTo(views.createLearningActivity, {});
                }}
              />
            </TableActions>
          </TableActionsHeader>
          {learningActivityTags && <FiltersChips items={filteredByTag} removeItem={removeSelectedTag}></FiltersChips>}
          <Row></Row>

          {pages === 0 && !filtered && !loading && !filteredByTag.length ? (
            <EmptyLearningActivities fullScreen view={views.learningActivities} />
          ) : (
            <DataTable
              defaultSorted={[
                {
                  id: 'title',
                  desc: false
                }
              ]}
              hoverEffect
              data={data?.allSkillTasksPaginated?.results ?? []}
              columns={columns}
              getTrGroupProps={(_, rowInfo) => ({
                onClick: () => {
                  router.goTo(views.editLearningActivity, {id: rowInfo.original.id});
                }
              })}
              manual
              noDataMessage={''}
              noDataText={'no items found'}
              pages={pages}
              loading={loading}
              LoadingComponent={LoadingComponent}
              NoDataComponent={NoDataFoundComponent}
              page={pageIndex}
              sorted={sorted}
              onSortedChange={setSorted}
              onPageChange={setPageIndex}
            />
          )}
        </>
      )}
      <DeleteLearningActivityDialog store={learningActivityGeneralInfo} />
    </Container>
  );
};

export default injectIntl(inject('store')(observer(LearningActivities)));
