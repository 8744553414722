import {action, computed, observable, makeObservable} from 'mobx';

import campaignFrequencySettingsForm from 'stores/forms/frequency-settings-form';

import notification from 'utils/notification-utils';
import {removeFieldsFromObject} from 'utils/object-utils';

import {TIME_TYPE, SECONDS_NUMBER, OPTION_TYPE} from 'components/FrequencySettingsDialog/constants';

const defaultCustomOptionNumber = '3';
const defaultCustomOptionEntity = TIME_TYPE.DAYS;

class FrequencySettingsDialog {
  @observable opened = false;
  @observable campaign = null;
  @observable optionType = null;
  @observable translations = {};

  frequencySettingsForm = campaignFrequencySettingsForm;
  initialValues = {};
  initialOptionType;

  @action
  open = campaign => {
    this.opened = true;
    this.campaign = campaign;

    const {entityNumber, entityType} = this.getFrequencySettings(campaign.showPeriodSeconds);

    this.setInitialValues({entityNumber, entityType});

    const isAlwaysShow = entityType === TIME_TYPE.SECONDS;
    const optionType = isAlwaysShow ? OPTION_TYPE.ALWAYS : OPTION_TYPE.CUSTOM;
    const entityNumberValue = isAlwaysShow ? defaultCustomOptionNumber : entityNumber;
    const entityTypeValue = isAlwaysShow ? defaultCustomOptionEntity : entityType;

    this.frequencySettingsForm.$('entityNumber').sync(entityNumberValue);
    this.frequencySettingsForm.$('entityType').sync(entityTypeValue);

    this.setOptionType(optionType);
    this.setInitialOptionType(optionType);
  };

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  setInitialValues = initialValues => {
    this.initialValues = initialValues;
  };

  @action
  setInitialOptionType = initialOptionType => {
    this.initialOptionType = initialOptionType;
  };

  @action
  selectAlwaysOption = () => {
    this.setOptionType(OPTION_TYPE.ALWAYS);
  };

  @action
  selectCustomOption = () => {
    if (!this.optionType === OPTION_TYPE.CUSTOM) {
      this.frequencySettingsForm.reset();
    }

    this.setOptionType(OPTION_TYPE.CUSTOM);
  };

  @action
  reset = () => {
    this.opened = false;
    this.campaign = null;
    this.optionType = null;
    this.frequencySettingsForm.reset();
  };

  getTimePerSeconds = (entityNumber, entityType) => {
    const numberValue = parseInt(entityNumber);

    switch (entityType) {
      case TIME_TYPE.HOURS:
        return numberValue * SECONDS_NUMBER.IN_HOUR;
      case TIME_TYPE.DAYS:
        return numberValue * SECONDS_NUMBER.IN_DAY;
      case TIME_TYPE.WEEKS:
        return numberValue * SECONDS_NUMBER.IN_WEEK;
      case TIME_TYPE.MONTHS:
        return numberValue * SECONDS_NUMBER.IN_MONTH;
      default:
        return 0;
    }
  };

  getFrequencySettings = seconds => {
    let entityNumber, entityType;

    const isFullMonth = seconds / SECONDS_NUMBER.IN_MONTH >= 1 && Number.isInteger(seconds / SECONDS_NUMBER.IN_MONTH);
    const isFullWeek = seconds / SECONDS_NUMBER.IN_WEEK >= 1 && Number.isInteger(seconds / SECONDS_NUMBER.IN_WEEK);
    const isFullDay = seconds / SECONDS_NUMBER.IN_DAY >= 1 && Number.isInteger(seconds / SECONDS_NUMBER.IN_DAY);

    if (isFullMonth) {
      entityNumber = parseInt(seconds / SECONDS_NUMBER.IN_MONTH);
      entityType = TIME_TYPE.MONTHS;
    } else if (isFullWeek) {
      entityNumber = parseInt(seconds / SECONDS_NUMBER.IN_WEEK);
      entityType = TIME_TYPE.WEEKS;
    } else if (isFullDay) {
      entityNumber = parseInt(seconds / SECONDS_NUMBER.IN_DAY);
      entityType = TIME_TYPE.DAYS;
    } else if (seconds / SECONDS_NUMBER.IN_HOUR >= 1) {
      entityNumber = parseInt(seconds / SECONDS_NUMBER.IN_HOUR);
      entityType = TIME_TYPE.HOURS;
    } else {
      entityNumber = 0;
      entityType = TIME_TYPE.SECONDS;
    }

    return {
      entityNumber,
      entityType
    };
  };

  @action
  submit = editCampaignMutation => {
    const campaign = removeFieldsFromObject(this.campaign, [
      'id',
      '__typename',
      'isEditable',
      'status',
      'numInstructionsAppliedTo'
    ]);
    const {entityNumber, entityType} = this.frequencySettingsForm.values();

    campaign.showPeriodSeconds =
      this.optionType === OPTION_TYPE.ALWAYS ? 0 : this.getTimePerSeconds(entityNumber, entityType);

    editCampaignMutation({
      id: this.campaign.id,
      campaign
    })
      .then(() => {
        notification.success(this.translations.editSuccess);
      })
      .catch(() => {
        notification.error(this.translations.editFailure);
      })
      .finally(this.reset);
  };

  @action
  setOptionType = optionType => {
    this.optionType = optionType;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get showSave() {
    const {entityType, entityNumber} = this.frequencySettingsForm.values();

    const isFormValid = this.frequencySettingsForm.isValid;
    const isDifferentOption = this.initialOptionType !== this.optionType;
    const areDifferentCustomValues =
      entityType !== this.initialValues.entityType || parseInt(entityNumber) !== this.initialValues.entityNumber;

    return isFormValid && (isDifferentOption || areDifferentCustomValues);
  }
}

export default FrequencySettingsDialog;
