import React, {Component} from 'react';

import {FormattedMessage} from 'components/FormattedComponents';

import Dialog from 'ui-components/Dialog';

import messages from '../messages';
import {dialogStyle, StyledText, ListWrapper, ListLink} from '../styles';
import {openTeamsWithDialogClosed, openTeamWithDialogClosed} from '../utils';

class OnlyApproverDialog extends Component {
  render() {
    const {
      teams,
      isOpen,
      onCancel,
      isArchiving,
      user: {fullName}
    } = this.props;

    const title = isArchiving ? (
      <FormattedMessage {...messages.cannotArchiveUser} />
    ) : (
      <FormattedMessage {...messages.cannotDeleteUser} />
    );
    const subtitle =
      teams.length === 1 ? (
        <StyledText>
          <FormattedMessage
            {...messages.onlyApproverTeamDeleteUser}
            values={{
              userName: <b>{fullName}</b>,
              teamName: (
                <ListLink key={teams[0].id} onClick={() => openTeamWithDialogClosed(teams[0].id)} target="_blank">
                  {teams[0].name}
                </ListLink>
              )
            }}
          />
        </StyledText>
      ) : (
        <StyledText>
          <FormattedMessage
            {...messages.onlyApproverTeamsDeleteUser}
            values={{
              name: <b>{fullName}</b>
            }}
          />
        </StyledText>
      );
    const content = teams.length > 1 && (
      <ListWrapper>
        {teams.map(team => (
          <ListLink key={team.id} onClick={() => openTeamWithDialogClosed(team.id)} target="_blank">
            {team.name}
          </ListLink>
        ))}
      </ListWrapper>
    );

    const actions = [
      {
        label: <FormattedMessage {...messages.goToTeams} />,
        onClick: openTeamsWithDialogClosed,
        disabled: false
      },
      {
        label: <FormattedMessage {...messages.close} />,
        onClick: onCancel,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel
        onCancel={onCancel}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {subtitle}
        {content}
      </Dialog>
    );
  }
}

export default OnlyApproverDialog;
