import React, {Component} from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {ChromePicker} from 'react-color';
import color from 'ui-components/Layout/Icon/source/color.svg';

import {EditorColorPickerWrapper} from './styles';

@observer
class EditorColorPicker extends Component {
  @observable expanded = false;
  @observable colorPickerRef = null;

  @action
  setColorPickerRef = node => {
    this.colorPickerRef = node;
  };

  handleClickOutsideColorPicker = event => {
    if (this.colorPickerRef && !this.colorPickerRef.contains(event.target) && this.expanded) {
      this.togglePicker();
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutsideColorPicker);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutsideColorPicker);
  }

  stopPropagation = event => {
    event.stopPropagation();
  };

  onChange = color => {
    const {onChange} = this.props;
    onChange('color', color.hex);
  };

  togglePicker = () => (this.expanded = !this.expanded);

  renderModal = () => {
    const {color} = this.props.currentState;
    return (
      <div onClick={this.stopPropagation} className="rdw-colorpicker-modal">
        <ChromePicker
          color={color}
          onChangeComplete={this.onChange}
          disableAlpha={true}
          innerRef={this.setColorPickerRef}
        />
      </div>
    );
  };

  render() {
    return (
      <EditorColorPickerWrapper
        aria-haspopup="true"
        aria-expanded={this.expanded}
        aria-label="rdw-color-picker"
        className="rdw-colorpicker-wrapper rdw-options-wrapper"
        ref={this.setColorPickerRef}
      >
        <div onClick={this.togglePicker} className="rdw-option-wrapper">
          <img src={color} />
        </div>
        {this.expanded ? this.renderModal() : undefined}
      </EditorColorPickerWrapper>
    );
  }
}

export default EditorColorPicker;
