import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'NewTopic.title',
    defaultMessage: 'New topic'
  }
});

export default messages;
