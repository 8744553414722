import {client} from 'utils/apollo-client';
import {getLanguageInfo} from 'api/guide/queries';
import {formatLanguages} from 'utils/languages-utils';
import {maxBy} from 'lodash';

export const setLanguagesForPdfExport = async store => {
  const {
    editGuidePage: {versions, guide},
    exportToPdfDialog: {setAvailableLanguages, setSelectedLanguage},
    platform: {availableLanguagesDetails}
  } = store;

  if (guide && versions) {
    const lastVersion = maxBy(versions, version => version.createdAt);

    if (lastVersion) {
      const guideLanguages = await client.query({
        // Cache is ignored to keep consistent behavior with previous implementation. If considered safe, it can be removed.
        fetchPolicy: 'network-only',
        query: getLanguageInfo,
        variables: {
          guideId: guide.id,
          versionId: lastVersion._id
        }
      });
      const {
        guideForCms: {availableTranslations, defaultLocale}
      } = guideLanguages.data;
      const formattedLanguages = formatLanguages(availableTranslations, availableLanguagesDetails);
      setAvailableLanguages(formattedLanguages);
      setSelectedLanguage(defaultLocale);
    }
  }
};
