import views from 'config/views';
import {get} from 'lodash';
import {client} from 'utils/apollo-client';

//constants
import C from './constants';

//refresh queries
import {refreshInstructions, refreshInstruction} from 'api/instruction/refresh-queries';
import {refreshGuide} from 'api/guide/refresh-queries';

export const createInstructionOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.CREATE_INSTRUCTION]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      variables.instruction.locale = locale;
      return mutate({
        variables,
        refetchQueries: [
          refreshInstructions(variables.instruction.topicId, locale),
          // if creating an instruction inside of a topic, also refresh the guide and the instruction header
          // TODO: It shouldn't be possible to create an instruction without topic.
          ...(variables.instruction.topicId ? [refreshGuide(store.router.params.id)] : [])
        ]
      });
    }
  })
};

export const archiveInstructionOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.ARCHIVE_INSTRUCTION]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      return mutate({
        variables,
        refetchQueries: [
          refreshInstructions(null, locale),
          // if there are params.id it means that user is inside of guide
          ...(store.router.params.id ? [refreshGuide(store.router.params.id)] : [])
        ]
      });
    }
  })
};

export const archiveInstructionInGuideOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {id, store}}) => ({
    archiveInstructionInGuideMutation: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      variables = Object.assign({}, variables, {locale});
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(id, locale), refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const setInstructionToDraftOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.SET_INSTRUCTION_TO_DRAFT]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(variables.id), refreshInstructions(null, locale)]
      });
    }
  })
};

export const setInstructionToDraftInGuideOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    [C.SET_INSTRUCTION_TO_DRAFT]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      variables = Object.assign({}, variables, {locale});
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(variables.id, locale), refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const setInstructionCoverOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    [C.SET_INSTRUCTION_COVER]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(variables.id, locale)]
      });
    }
  })
};

export const publishInstructionInListOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    publishInstructionMutation: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      return mutate({
        variables,
        refetchQueries: [refreshInstructions(null, locale)]
      });
    }
  })
};

export const publishInstructionInGuideOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    [C.PUBLISH_INSTRUCTION]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      variables = Object.assign({}, variables, {locale});
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(variables.id, locale), refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const cloneInstructionInListOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    cloneInstructionMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshGuide(store.router.params.id)]
      })
  })
};

export const updateInstructionInGuideOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.UPDATE_INSTRUCTION]: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      variables.instruction = Object.assign({}, variables.instruction, {locale});
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(variables.id, locale)]
      });
    }
  })
};

export const updateInstructionMediaOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    updateInstructionMediaMutation: variables => {
      const {router} = store;
      let locale;
      if (router.currentRoute.path === views.editGuide.path) {
        locale = get(store.editGuidePage, 'guide.defaultLocale', null);
      } else if (router.currentRoute.path === views.editInstruction.path) {
        locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      }
      variables = Object.assign({}, variables, {locale});
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(variables.id, locale)]
      });
    }
  })
};

export const moveInstructionOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps: {store}}) => ({
    moveInstructionMutation: variables => {
      const {id} = store.router.params;
      return mutate({
        variables,
        refetchQueries: [refreshGuide(id)],
        awaitRefetchQueries: true
      });
    }
  })
};

export const requireSignatureOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    requireSignatureMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuide(store.router.params.id)]
      });
    }
  })
};

export const removeSignatureOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    removeSignatureMutation: variables => {
      return mutate({
        variables,
        refetchQueries: [refreshGuide(store.router.params.id)]
      });
    }
  })
};
