import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectGuideLanguage: {
    id: 'selectGuideLanguage',
    defaultMessage: 'Select Guide language'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  translationPercentage: {
    id: 'translationPercentage',
    defaultMessage: '{percentage}% translated'
  },
  errorFetchingLanguages: {
    id: 'errorFetchingLanguages',
    defaultMessage: 'Could not load the guide languages'
  },
  errorChangingDefaultLanguage: {
    id: 'errorChangingDefaultLanguage',
    defaultMessage: 'Could not change the guide language'
  }
});

export default messages;
