import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectImage: {
    id: 'MediaPicker.selectImage',
    defaultMessage: 'Select Image'
  },
  add: {
    id: 'common.actions.add',
    defaultMessage: 'Add'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  details: {
    id: 'MediaPicker.details',
    defaultMessage: 'Details'
  },
  shapes: {
    id: 'MediaPicker.shapes',
    defaultMessage: 'Shapes'
  },
  imageEdit: {
    id: 'MediaPicker.imageEdit',
    defaultMessage: 'Edit Image'
  },
  imageOverlay: {
    id: 'MediaPicker.imageOverlay',
    defaultMessage: 'Image Overlay'
  },
  visibleOnStepsAndInstructions: {
    id: 'MediaActions.visibleOnStepsAndInstructions',
    defaultMessage: 'Visible on steps and instructions'
  },
  imagePreview: {
    id: 'MediaPicker.imagePreview',
    defaultMessage: 'Preview'
  },
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  mediaSaveSuccess: {
    id: 'MediaPicker.mediaSaveSuccess',
    defaultMessage: 'Media saved!'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  },
  mediaSaveError: {
    id: 'MediaPicker.mediaSaveError',
    defaultMessage: 'Unknown error occured!'
  }
});

export default messages;
