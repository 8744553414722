import React, {useEffect, useState} from 'react';

import axios from 'axios';
import get from 'lodash/get';
import Raven from 'raven-js';

import {init, AuthType, AuthStatus, EmbedEvent, LiveboardEmbed} from '@thoughtspot/visual-embed-sdk';
import {GetTSAuthenticationToken} from 'api/user/queries';

import {THOUGHTSPOT_HOST, THOUGHTSPOT_TEST_USER_ID} from 'shared/constants';
import {THOUGHTSPOT_LIVEBOARD_HIDDEN_ACTIONS} from './constants';

import Spinner from 'shared/components/Spinner';

import {SpinnerContainer, LiveBoardContainer} from './styles';

const waitForSDKSuccess = emitter =>
  new Promise((resolve, reject) => {
    emitter.once(AuthStatus.SDK_SUCCESS, () => {
      resolve();
    });

    emitter.once(AuthStatus.FAILURE, () => {
      reject(new Error('Could not log in'));
    });
  });

const getAuthToken = async () => {
  const response = await axios.post('/graphql', {query: GetTSAuthenticationToken});

  return get(response, 'data.data.getAuthenticationToken.token');
};

const initLiveBoard = async ({user, onLoad}) => {
  const tsEventEmitter = init({
    thoughtSpotHost: THOUGHTSPOT_HOST,
    authType: AuthType.TrustedAuthTokenCookieless,
    getAuthToken: getAuthToken,
    username: user.isIPA ? THOUGHTSPOT_TEST_USER_ID : user.id,
    autoLogin: true,
    disableLoginRedirect: true
  });

  try {
    await waitForSDKSuccess(tsEventEmitter);
    onLoad();
  } catch (error) {
    Raven.captureException(error);
  }
};

const renderEmbeddedLiveboard = async ({liveboardId, onLoad}) => {
  const liveboard = new LiveboardEmbed('#tse', {
    liveboardId,
    fullHeight: true,
    hiddenActions: THOUGHTSPOT_LIVEBOARD_HIDDEN_ACTIONS,
    preventLiveboardFilterRemoval: true
  });

  liveboard.on(EmbedEvent.Load, () => {
    onLoad();
  });

  liveboard.render();
};

export const CustomLiveboard = ({user, liveboardId}) => {
  const [initialised, setInitialised] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    initLiveBoard({
      user,
      onLoad: () => {
        setInitialised(true);
      }
    });

    return () => {
      setInitialised(false);
    };
  }, []);

  useEffect(() => {
    setLoaded(false);
    renderEmbeddedLiveboard({
      liveboardId,
      onLoad: () => {
        setLoaded(true);
      }
    });
  }, [user.id, liveboardId]);

  const shouldRenderLiveBoard = initialised && loaded;

  return (
    <>
      <LiveBoardContainer id="tse" data-testid="thoughtspot-liveboard-root" isVisible={shouldRenderLiveBoard} />
      {!shouldRenderLiveBoard && (
        <SpinnerContainer>
          <Spinner size={'5rem'} color={'black'}></Spinner>
        </SpinnerContainer>
      )}
    </>
  );
};
