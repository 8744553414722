import {ContentState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';
import {TEAM_MEMBER_ROLE} from 'shared/enums';

const INITIAL_VALUE = '<p></p>';

export const sortTags = tagsList => {
  const sorted = tagsList.map(({child, ...rest}) =>
    child && child.length > 0
      ? {...rest, child: sortTags(sortBy(child, c => new Date(c.created_at)))}
      : {...rest, child: sortBy(child, c => new Date(c.created_at))}
  );

  return sortBy(sorted, c => new Date(c.created_at));
};

export const getEmptyContentState = () => {
  return convertToRaw(ContentState.createFromText(''));
};

export const getUniqueTags = (array = []) => uniq(array.map(i => i.tag.id));

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const normalizeStringifiedValue = stringifiedValue => {
  if (!stringifiedValue) {
    return INITIAL_VALUE;
  }

  const struct = JSON.parse(stringifiedValue);

  return draftToHtml(struct).trim();
};

export const editorHasValue = stringifiedValue => {
  const html = normalizeStringifiedValue(stringifiedValue);
  return html !== INITIAL_VALUE;
};

export const editorHasChanges = (initialStateStringified, currentStateStringified) => {
  const initialHtml = normalizeStringifiedValue(initialStateStringified);
  const currentHtml = normalizeStringifiedValue(currentStateStringified);

  return initialHtml !== currentHtml;
};

export const emptyContent = JSON.stringify(getEmptyContentState());

export const getNestedFormField = (form, path, value) => {
  let field = null;
  form.map(item => {
    if (item.$(path).value === value) {
      field = item;
    }
  });
  return field;
};

const roles = {
  [TEAM_MEMBER_ROLE.ADMIN]: 3,
  [TEAM_MEMBER_ROLE.EDITOR]: 2,
  [TEAM_MEMBER_ROLE.VIEWER]: 1
};

export const getUserHighestRole = user => {
  if (!user) {
    return null;
  }

  if (user.isPlatformAdmin) {
    return 'PLATFORM_ADMIN';
  }

  if (!user.teams || !user.teams.length) {
    return null;
  }

  return user.teams.reduce((acc, curr) => {
    if (!acc) {
      return curr.role;
    }

    if (roles[curr.role] > roles[acc]) {
      return curr.role;
    }

    return acc;
  }, null);
};

export const dateHasPassed = date => {
  if (!date) return false;
  const targetDate = new Date(date);
  const currentDate = new Date();
  return (
    currentDate.getFullYear() >= targetDate.getFullYear() &&
    currentDate.getMonth() >= targetDate.getMonth() &&
    currentDate.getDate() >= targetDate.getDate()
  );
};

export const areEqual = (a, b) => a === b || (!a && !b);

export const isUserUsingPhone = (usersList, userId) => {
  const user = usersList.find(user => user.id === userId);
  return user?.phoneNumber;
};

export const convertHtmlToDraft = html => {
  if (!html) {
    return emptyContent;
  }

  const {contentBlocks, entityMap} = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  return JSON.stringify(convertToRaw(contentState));
};
