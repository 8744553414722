import {action, observable, makeObservable} from 'mobx';

class DetailsCard {
  @observable cardHeight = null;
  @observable wrapperHeight = null;

  @action
  setCardHeight = cardHeight => {
    this.cardHeight = cardHeight;
  };

  @action
  setWrapperHeight = wrapperHeight => {
    this.wrapperHeight = wrapperHeight;
  };

  constructor() {
    makeObservable(this);
  }
}

export default DetailsCard;
