import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {ChromePicker} from 'react-color';
import {SHAPE_ENUMS} from 'shared/enums';

//styled-components
import {Label, Toolbar, ToolWrapper, Color, Popover, Cover, ToolbarButton, ToolBarSeparator} from './styles';

//components
import {FormattedMessage} from 'shared/components/FormattedComponents';
import Icon from 'ui-components/Layout/Icon';
import {Select, SelectOption} from 'styles/styled-components/form-styled-components';

//messages
import messages from './messages';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

class OverlayToolbar extends Component {
  setColor = color => {
    const {setShapeColor, position, setToolbarColor, editMode} = this.props;
    if (editMode) setShapeColor(position, color);
    else setToolbarColor(color);

    trackEvent(EVENT_TYPES.SHAPE_COLOR_SELECTED, {color});
  };

  setThickness = thickness => {
    const {setShapeThickness, position, setToolbarThickness, editMode} = this.props;
    if (editMode) setShapeThickness(position, thickness);
    else setToolbarThickness(thickness);

    trackEvent(EVENT_TYPES.SHAPE_THICKNESS_SELECTED, {thickness});
  };

  render() {
    const {selectedColor, selectedShape, selectedThickness, colorPickerOpen, setShape, toggleColorPicker} = this.props;
    const thicknessOptions = [2, 4, 6];

    return (
      <Toolbar>
        <ToolWrapper>
          <Label>
            <FormattedMessage {...messages.shapes} />
          </Label>
          {Object.values(SHAPE_ENUMS).map((shape, index) => {
            return (
              <ToolbarButton key={index} selected={selectedShape === shape} onClick={() => setShape(shape)}>
                <Icon name={shape} />
              </ToolbarButton>
            );
          })}
        </ToolWrapper>
        <ToolBarSeparator />
        <ToolWrapper>
          <Label colorLabel>
            <FormattedMessage {...messages.color} />
          </Label>
          <Color color={selectedColor} onClick={() => toggleColorPicker()} />
          {colorPickerOpen && (
            <Popover>
              <Cover onClick={() => toggleColorPicker()} />
              <ChromePicker style={{width: 200}} color={selectedColor} onChange={this.setColor} />
            </Popover>
          )}
        </ToolWrapper>
        <ToolBarSeparator />
        <ToolWrapper>
          <Label>
            <FormattedMessage {...messages.thickness} />
          </Label>
          <Select value={`${selectedThickness}`} onChange={this.setThickness} styles={{margin: 10}}>
            {thicknessOptions.map(option => (
              <SelectOption key={option} value={`${option}`}>
                <Icon type="thickness" name={`thickness${option}`} style={{marginRight: 8}} />
                {option}px
              </SelectOption>
            ))}
          </Select>
        </ToolWrapper>
      </Toolbar>
    );
  }
}

export default injectIntl(OverlayToolbar);
