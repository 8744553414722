import {defineMessages} from 'react-intl';

const messages = defineMessages({
  signOffTitle: {
    id: 'EditSignOff.name',
    defaultMessage: 'Sign-off Title'
  },
  titlePlaceholder: {
    id: 'EditCampaign.titlePlaceholder',
    defaultMessage: 'Insert title here...'
  },
  question: {
    id: 'EditCampaign.question',
    defaultMessage: 'Question'
  },
  questionPlaceholder: {
    id: 'EditCampaign.questionPlaceholder',
    defaultMessage: 'Insert question text here...'
  },
  range: {
    id: 'EditCampaign.range',
    defaultMessage: 'Range'
  },
  numberOfStars: {
    id: 'EditCampaign.numOfStars',
    defaultMessage: 'Number of stars'
  },
  numberOfChoices: {
    id: 'EditCampaign.numberOfChoices',
    defaultMessage: 'Number of choices'
  },
  followUpQuestions: {
    id: 'EditCampaign.followUpQuestions',
    defaultMessage: 'Follow-up Question(s)'
  },
  followUpQuestionsRanging: {
    id: 'EditCampaign.followUpQuestionsRanging',
    defaultMessage: 'Follow-up Question(s) Ranging from {range}'
  },
  addFollowUp: {
    id: 'EditCampaign.addFollowUp',
    defaultMessage: 'Add Follow-up Question'
  },
  textTypeOptionTitle: {
    id: 'EditCampaign.answerOption',
    defaultMessage: 'Answer option {n}'
  },
  textTypeOptionPlaceholder: {
    id: 'EditCampaign.typeAnswerHere',
    defaultMessage: 'Type answer here...'
  },
  defaultLanguageTooltip: {
    id: 'EditCampaign.defaultLanguageCannotBeChanged',
    defaultMessage: 'The default language cannot be changed'
  }
});

export default messages;
