import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import TeamUsersView from 'views/TeamUsersView';

import {Team} from 'api/team/queries';
import {teamOptions} from 'api/team/query-options';

@inject('store')
@graphql(Team, teamOptions)
@observer
class EditTeamUsers extends Component {
  render() {
    const {viewMode} = this.props;

    return <TeamUsersView editTeam={true} viewMode={viewMode} />;
  }
}

export default injectIntl(EditTeamUsers);
