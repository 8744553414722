import {Domain, Domains} from 'api/domain/queries';

export const refreshDomains = (adminOfManagementTeamsOnly = true) => ({
  query: Domains,
  variables: {
    adminOfManagementTeamsOnly
  }
});

export const refreshDomain = id => {
  return (
    id && {
      query: Domain,
      variables: {
        id
      }
    }
  );
};
