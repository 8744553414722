export const drawCircle = (ctx, {x = 0, y = 0, size = 8, color = '#cc0000', lineWidth = 1, lineDash = []} = {}) => {
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.setLineDash(lineDash);
  ctx.beginPath();
  ctx.arc(x, y, size, 0, Math.PI * 2);
  ctx.stroke();
};

export const drawFilledRect = (ctx, {x = 0, y = 0, x2 = 100, y2 = 100, color = '#cc0000'} = {}) => {
  ctx.fillStyle = color;
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x, y2);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x2, y);
  ctx.lineTo(x, y);
  ctx.fill();
  ctx.closePath();
};

export const drawRect = (
  ctx,
  {x = 0, y = 0, x2 = 100, y2 = 100, color = '#cc0000', lineWidth = 6, lineDash = []} = {}
) => {
  ctx.lineWidth = lineWidth;
  ctx.setLineDash(lineDash);
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.moveTo(x, y);
  ctx.lineTo(x, y2);
  ctx.lineTo(x2, y2);
  ctx.lineTo(x2, y);
  ctx.lineTo(x, y);
  ctx.stroke();

  // drawRectRaster(ctx, x, y, x2 - x, y2 - y);
};
export const drawRectRaster = (ctx, x, y, w, h) => {
  ctx.setLineDash([]);
  ctx.beginPath();
  ctx.strokeStyle = 'rgba(0,0,0,.5)';
  ctx.moveTo(x, y);
  ctx.lineTo(w + x, h + y);
  ctx.moveTo(w + x, y);
  ctx.lineTo(x, h + y);
  ctx.stroke();
};
export const drawText = (ctx, {text = '?'} = {}) => {
  ctx.font = '48px Arial';
  ctx.fillStyle = '#000000';
  ctx.fillText(text, 10, 50);
};

export const drawImageRotated = (
  ctx,
  {image, scale, rotationCenterX, rotationCenterY, imageWidth, imageHeight, rotationDegree}
) => {
  const radians = (rotationDegree * Math.PI) / 180;
  ctx.setTransform(scale, 0, 0, scale, rotationCenterX, rotationCenterY);
  ctx.rotate(radians);
  ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2);
  ctx.setTransform(1, 0, 0, 1, 0, 0);
};
