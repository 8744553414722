export const getAddLanguagesOptions = store => {
  const availableLanguages = store.platform.availableLanguages;
  const existingLanguagesInGuide = store.autoTranslationDialog.translationsForGuide.map(
    translation => translation.locale
  );
  const activeLanguages = availableLanguages.map(language => ({
    id: language.locale,
    name: language.language,
    disabled: existingLanguagesInGuide.includes(language.locale)
  }));

  return activeLanguages;
};

export const onChangeLanguageToAdd = (value, store) => {
  store.autoTranslationDialog.setSelectedLanguageToAdd(value);
};
