import gql from 'graphql-tag';

export const CreateDomain = gql`
  mutation createDomain($newDomain: DomainCreate!) {
    createDomain(newDomain: $newDomain) {
      id
    }
  }
`;

export const EditDomain = gql`
  mutation editDomain($id: String!, $editDomain: DomainEdit!) {
    editDomain(id: $id, editDomain: $editDomain) {
      id
    }
  }
`;

export const ArchiveDomain = gql`
  mutation archiveDomain($id: String!) {
    archiveDomain(id: $id) {
      result
    }
  }
`;

export const AddTeamsToDomain = gql`
  mutation addTeamsToDomain($teamIds: [String], $domainId: String!) {
    addTeamsToDomain(teamIds: $teamIds, domainId: $domainId) {
      result
    }
  }
`;

export const RemoveTeamFromDomain = gql`
  mutation removeTeamFromDomain($teamId: String!, $domainId: String!) {
    removeTeamFromDomain(teamId: $teamId, domainId: $domainId) {
      result
    }
  }
`;
