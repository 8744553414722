import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {FormattedMessage} from 'components/FormattedComponents';

import {DropdownMenu, DropdownMenuButton, DropdownMenuItem} from 'styles/styled-components/dropdown-styled-components';
import messages from './messages';

import {inject} from 'mobx-react';
import views from 'config/views';
import {DeleteSkillTask} from 'api/skills/queries';
import {deleteSkillTaskOptions} from 'api/learning-activities/mutation-options';
import {graphql} from '@apollo/client/react/hoc/graphql';
import notificationUtils from 'utils/notification-utils';

@inject('store')
@graphql(DeleteSkillTask, deleteSkillTaskOptions)
class LearningActivityMenu extends Component {
  onEdit = () => {
    const {store, learningActivity} = this.props;
    store.router.goTo(views.editLearningActivity, {id: learningActivity.id});
  };
  // commented out since this is not yet implemented
  // onAssignToSkills = () => {};

  onDelete = async () => {
    const {
      deleteSkillTaskMutation,
      store: {learningActivityGeneralInfo},
      learningActivity,
      intl: {formatMessage}
    } = this.props;
    learningActivityGeneralInfo.setLearningActivityToBeDeleted(learningActivity);
    learningActivityGeneralInfo.setSkillProfilesCount(learningActivity.skillProfilesCount);
    if (learningActivity.skillProfilesCount > 0) {
      learningActivityGeneralInfo.setIsDeleteDialogOpen(true);
    } else {
      const {
        data: {
          deleteSkillTask: {result}
        }
      } = await deleteSkillTaskMutation({
        id: learningActivityGeneralInfo.learningActivityToBeDeleted.id
      });
      if (!result) {
        return notificationUtils.error(formatMessage(messages.errorDeletingLearningActivity));
      }
      notificationUtils.success(formatMessage(messages.successDeletingLearningActivity));
    }
  };

  render() {
    const actions = [
      {
        key: 'edit',
        label: messages.edit,
        onClick: this.onEdit
      },
      // commented out since this is not yet implemented
      // {
      //   key: 'assign-to-skills',
      //   label: messages.assignToSkills,
      //   onClick: this.onAssignToSkills
      // },
      {
        key: 'delete',
        label: messages.delete,
        onClick: this.onDelete
      }
    ];

    return (
      <DropdownMenu {...this.props}>
        {actions.map(({key, label, onClick}) => (
          <DropdownMenuItem key={key}>
            <DropdownMenuButton onClick={onClick}>
              <FormattedMessage {...label} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        ))}
      </DropdownMenu>
    );
  }
}

export default injectIntl(LearningActivityMenu);
