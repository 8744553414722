import {action, observable, makeObservable} from 'mobx';

class GuideFeedbackDialog {
  @observable opened = false;
  @observable guideId;
  @observable guideTitle;

  @action
  reset = () => {
    this.opened = false;
  };

  @action open = ({guideId, guideTitle}) => {
    this.opened = true;
    this.guideId = guideId;
    this.guideTitle = guideTitle;
  };

  constructor() {
    makeObservable(this);
  }
}

export default GuideFeedbackDialog;
