import React from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import reverse from 'lodash/reverse';
import uuidv4 from 'uuid/v4';

// components
import Icon from 'ui-components/Icon';
import SkillLevelRow from './SkillLevelRow';
import {Subtitle, Infobox} from './styles';

// data
import {SkillLevels} from 'api/skills/queries';

import messages from './messages';

const SkillLevelsWrapper = ({levels = []}) => {
  const showNumbers = levels.length > 2;

  // reverse because in case where we have 2 skills, we want to show higher position first
  const skillLevels = showNumbers ? levels : reverse([...levels]);

  const levelItems = skillLevels.map(level => {
    return <SkillLevelRow level={level} showNumbers={showNumbers} key={uuidv4()} />;
  });

  return levelItems;
};

function SkillLevelsSettings(props) {
  const {
    data: {skillLevels, loading},
    intl: {formatMessage}
  } = props;

  const content = skillLevels?.length ? (
    <SkillLevelsWrapper levels={skillLevels} />
  ) : (
    <Subtitle>{formatMessage(messages.askAdminSkillLevels)}</Subtitle>
  );

  return (
    <>
      <Subtitle>{formatMessage(messages.settingsSubtitle)}</Subtitle>
      <Infobox>
        <Icon iconId="info" />
        {formatMessage(messages.infoboxMessage)}
      </Infobox>
      <>{loading ? 'Loading..' : content}</>
    </>
  );
}

export default injectIntl(graphql(SkillLevels)(observer(SkillLevelsSettings)));
