import React from 'react';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {Popover} from 'antd';

import MultiSelect from 'ui-components/MultiSelect';

import {FilterWrapper, StyledText, ClickableText} from './styles';
import messages from './messages';

import ActiveFilters from '../ActiveFilters';

const FilterPicker = props => {
  const {
    intl: {formatMessage}
  } = props;

  return (
    <FilterWrapper>
      <StyledText>{`${formatMessage(messages.show)}`}:</StyledText>
      <Popover placement="bottom" trigger="click" content={<MultiSelect storeName={'versionHistoryDrawer'} />}>
        <ClickableText>
          {`${formatMessage(messages.eventTypes)} `}
          (<ActiveFilters />)
        </ClickableText>
      </Popover>
    </FilterWrapper>
  );
};

export default injectIntl(observer(FilterPicker));
