import {refreshInstruction} from 'api/instruction/refresh-queries';
import {get} from 'lodash';
import {client} from 'utils/apollo-client';

export const createStepOptions = {
  options: {
    client
  },
  props: ({mutate, ownProps}) => ({
    createStepMutation: variables => {
      const {store} = ownProps;
      const {editInstructionDetailsPage, router} = store;
      const {instructionId} = router.params;

      const locale = get(editInstructionDetailsPage, 'guide.defaultLocale', null);
      variables.step = Object.assign({}, variables.step, {instructionId, locale});

      return mutate({
        variables,
        awaitRefetchQueries: true, // so when the list is visited the new step is already there
        refetchQueries: [refreshInstruction(instructionId, locale)]
      });
    }
  })
};

export const deleteStepOptions = {
  options: {
    client
  },
  props: ({
    mutate,
    ownProps: {
      store,
      step: {instructionId}
    }
  }) => ({
    deleteStepMutation: variables => {
      const locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(instructionId, locale)]
      });
    }
  })
};

export const updateStepOptions = {
  props: ({mutate, ownProps}) => ({
    updateStepMutation: variables => {
      const {store} = ownProps;
      const {editInstructionDetailsPage, router} = store;
      const {instructionId} = router.params;

      const locale = get(editInstructionDetailsPage, 'guide.defaultLocale', null);

      // Ignore router's instructionId if the step already contains it:
      variables.step = Object.assign({instructionId}, variables.step, {locale});

      return mutate({
        variables,
        refetchQueries: [refreshInstruction(instructionId, locale)]
      });
    }
  })
};

export const repositionStepsOptions = {
  options: {
    client
  },
  props: ({
    mutate,
    ownProps: {
      store,
      step: {instructionId}
    }
  }) => ({
    repositionStepsMutation: variables => {
      const locale = get(store.editInstructionDetailsPage, 'guide.defaultLocale', null);
      return mutate({
        variables,
        refetchQueries: [refreshInstruction(instructionId, locale)]
      });
    }
  })
};
