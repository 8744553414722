export const EVENT_TYPES = {
  RECOMMENDED_CONTENT: 'recommended_content',
  APPLY_SIGNOFF_INSTRUCTION: 'apply_signoff_instruction',
  APPLY_SIGNOFF_GUIDE: 'apply_signoff_guide',
  EDIT_GUIDE_DETAILS: 'edit_guide_details',
  CHANGE_GUIDE_TAG: 'change_guide_tag',
  CHANGE_GUIDE_TEAM: 'change_guide_team',
  CHANGE_GUIDE_ASSIGNEE: 'change_guide_assignee',
  CHANGE_GUIDE_WORKSPACE: 'change_guide_workspace',
  CHANGE_GUIDE_LANGUAGE: 'change_guide_language',
  GUIDE_TRANSLATION_INITIATED: 'guide_translation_initiated',
  EXPORT_GUIDE_PDF: 'export_guide_pdf',
  PUBLISH_GUIDE: 'publish_guide',
  UNPUBLISH_GUIDE: 'unpublish_guide',
  HIDE_INSTRUCTION: 'hide_instruction',
  SHOW_INSTRUCTION: 'show_instruction',
  SHARE_GUIDE: 'share_guide',
  SHARE_INSTRUCTION: 'share_instruction',
  DOWNLOAD_QR_CODE: 'download_qr_code',
  VIEW_GUIDE_FEEDBACK: 'view_guide_feedback',
  VIEW_ACTIVITY_LOG: 'view_activity_log',
  COPY_GUIDE: 'copy_guide',
  REASSIGN_GUIDE: 'reassign_guide',
  DUPLICATE_INSTRUCTION: 'duplicate_instruction',
  MOVE_INSTRUCTION: 'move_instruction',
  OPEN_GUIDE_VERSIONS_DIALOG: 'open_guide_versions_dialog',
  SELECT_PLATFORM_LANGUAGE: 'select_platform_language',
  CHECKLISTS_EXPORT: 'checklists_export',
  RESENT_INVITE: 'resent_invite',
  OPEN_MEDIA_EDITOR: 'open_media_editor',
  RENAME_FILE: 'rename_file',
  REMOVE_IMAGE: 'remove_image',
  EDIT_IMAGE: 'edit_image',
  ADD_SHAPES: 'add_shapes',
  DUPLICATE_IMAGE_WITHOUT_SHAPES: 'duplicate_image_without_shapes',
  SCALE_SELECTION: 'scale_selection',
  ROTATE_IMAGE: 'rotate_image',
  IMAGE_BACKGROUND_COLOR: 'image_background_color',
  MEDIA_EDITOR_VIEWER_PREVIEW: 'media_editor_viewer_preview',
  MEDIA_EDITOR_DEVICE_PREVIEW: 'media_editor_device_preview',
  SHAPE_ADDED: 'shape_added',
  SHAPE_COLOR_SELECTED: 'shape_color_selected',
  SHAPE_THICKNESS_SELECTED: 'shape_thickness_selected',
  SHAPE_REORDER: 'shape_reorder',
  EDIT_SHAPE: 'edit_shape',
  GUIDE_OVERVIEW_SELECT_FILTER: 'guide_overview_select_filter',
  GUIDE_OVERVIEW_STATUS_FILTER: 'guide_overview_status_filter',
  GUIDE_OVERVIEW_SORT: 'guide_overview_sort',
  SEARCH_SKILL: 'search_skill',
  SEARCH_LEARNING_ACTIVITY: 'search_learning_activity',
  SKILL_PROFILES_SORT: 'skill_profiles_sort',
  CHECKLIST_CHECKLIST_FILTER: 'checklist_checklist_filter',
  CHECKLIST_DATE_FILTER: 'checklist_date_filter',
  CHECKLIST_GUIDE_FILTER: 'checklist_guide_filter',
  CHECKLIST_WORKSPACE_FILTER: 'checklist_workspace_filter',
  CHECKLIST_TEAM_FILTER: 'checklist_team_filter',
  CHECKLISTS_SEARCH: 'checklists_search',
  CHECKLIST_SET_ALERT_RECIPIENTS: 'checklist_set_alert_recipients'
};

export const STATUS = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined'
};

export const PARENT_TYPE = {
  STEP: 'step',
  INSTRUCTION: 'instruction',
  GUIDE: 'guide',
  CHECK: 'check'
};
