import notification from '../utils/notification-utils';

//global
import AppStore from 'stores/global/app-store';
import AuthStore from 'stores/global/auth-store';
import IPAStore from 'stores/global/ipa-store';
import AnalyticsStore from 'shared/stores/analytics';
import PlatformStore from 'stores/global/platform-store';
import PermissionsStore from 'stores/global/permissions-store';
import CampaignStore from 'shared/stores/campaign';
import CookiePreferenceStore from 'shared/stores/cookie-preference';
import {RouterStore} from 'mobx-router';

//pages
import AllGuidesPage from 'stores/pages/all-guides-page';
import NewGuidePage from 'stores/pages/new-guide-page';
import NewTopicPage from 'stores/pages/new-topic-page';
import EditTopicPage from 'stores/pages/edit-topic-page';
import EditGuidePage from 'stores/pages/edit-guide-page';
import EditDomainPage from 'stores/pages/edit-domain-page';
import EditInstructionDetailsPage from 'stores/pages/edit-instruction-details-page';
import AdminPage from 'stores/pages/admin-page';
import HomePage from 'stores/pages/home-page';
import EditProfilePage from 'stores/pages/edit-profile-page';
import ForgotPasswordPage from 'stores/pages/forgot-password-page';
import ResetPasswordPage from 'stores/pages/reset-password-page';
import SetPasswordPage from 'stores/pages/set-password-page';
import GuideViewPage from 'stores/pages/guide-view-page';
import InstructionViewPage from 'stores/pages/instruction-view-page';
import CampaignsPage from 'stores/pages/campaigns-page';
import NewUserPage from 'stores/pages/new-user-page';
import EditUserPage from 'stores/pages/edit-user-page';
import EditTeamPage from 'stores/pages/edit-team-page';
import ShareGuidePage from 'stores/pages/share-guide-page';
import ThemePage from 'stores/pages/theme-page';
import NewCampaignPage from 'stores/pages/new-campaign-page';
import EditCampaignPage from 'stores/pages/edit-campaign-page';
import EditInstructionPage from 'stores/pages/edit-instruction-page';
import AnalyticsPage from 'stores/pages/analytics-page';
import DragDropItems from 'stores/pages/drag-drop-items';
import DragDropSteps from 'stores/pages/drag-drop-steps';
import TranslationsPage from 'stores/pages/translations-page';
import TagTranslationsPage from 'stores/pages/tag-translations-page';
import TagManagementPage from 'stores/pages/tag-management-page';
import TeamPage from 'stores/pages/team-page';
import WorkspaceTeamsPage from 'stores/pages/workspace-teams-page';
import PlatformSettingsPage from 'stores/pages/platform-settings-page';
import ChecklistsPage from 'stores/pages/checklists-page';
import TranslateCampaignPage from 'stores/pages/translate-campaign-page';
import SignoffResultsPage from 'stores/pages/signoff-results-page.js';
import CheckListResultsPage from 'stores/pages/checklist-results-page';
import SkillsPage from 'stores/pages/skills-page';
import TokenPage from 'stores/pages/token-page';
import SkillProfilePage from 'stores/pages/skill-profile-page';
import ChecklistAlertsPage from 'stores/pages/checklist-alerts-page';

//dialogs
import ChangePasswordDialog from 'stores/dialogs/change-password-dialog';
import ChangeCampaignStatusDialog from 'stores/dialogs/change-campaign-status-dialog';
import ExamplesDialog from 'stores/dialogs/examples-dialog';
import ShareDialog from 'stores/dialogs/share-dialog';
import UploadTranslationsDialog from 'stores/dialogs/upload-translations-dialog';
import ReassignGuideDialog from 'stores/dialogs/reassign-guide-dialog';
import ArchiveDialog from 'stores/dialogs/archive-dialog';
import AuthErrorDialog from 'stores/dialogs/auth-error-dialog';
import VersionMismatchDialog from 'stores/dialogs/version-mismatch-dialog';
import MediaOverlayDialog from 'stores/dialogs/media-overlay-dialog';
import MediaPickerDialog from 'stores/dialogs/media-picker-dialog';
import GuideFeedbackDialog from 'stores/dialogs/guide-feedback-dialog';
import SessionExpiredDialog from 'stores/dialogs/session-expired-dialog';
import SaveAsDraftDialog from 'stores/dialogs/save-as-draft-dialog';
import UnsavedChangesDialog from 'stores/dialogs/unsaved-changes-dialog';
import GuideVersionsDialog from 'stores/dialogs/guide-versions-dialog';
import PublishGuideDialog from 'stores/dialogs/publish-guide-dialog';
import ExportToPdfDialog from 'stores/dialogs/export-to-pdf-dialog';
import InstructionalIconDialog from 'stores/dialogs/instructional-icon-dialog';
import EditThemeIconDialog from 'stores/dialogs/edit-theme-icon-dialog';
import IconsLimitDialog from 'stores/dialogs/icons-limit-dialog';
import NoteIconAffectedGuidesDialog from 'stores/dialogs/note-icons-affected-guides-dialog';
import MoveToDialog from 'stores/dialogs/move-to-dialog';
import RequestApprovalDialog from 'stores/dialogs/request-approval-dialog';
import RejectVersionDialog from 'stores/dialogs/reject-version-dialog';
import ConnectNewWorkspaceDialog from 'stores/dialogs/connect-new-workspace-dialog';
import AddUserToTeamDialog from 'stores/dialogs/add-user-to-team-dialog';
import GuideDetailsDialog from 'stores/dialogs/guide-details-dialog';
import CopyToDialog from 'stores/dialogs/copy-to-dialog';
import FrequencySettingsDialog from 'stores/dialogs/frequency-settings-dialog';
import ApplyCampaignDialog from 'stores/dialogs/apply-campaign-dialog';
import CampaignPreviewDialog from 'stores/dialogs/campaign-preview-dialog';
import RemoveCampaignDialog from 'stores/dialogs/remove-campaign-dialog';
import ConfirmDeactivateDialog from 'stores/dialogs/confirm-deactivate-campaign-dialog';
import OverrideCampaignDialog from 'stores/dialogs/override-campaign-dialog';
import DeleteUserDialog from 'stores/dialogs/delete-user-dialog';
import ArchiveUserDialog from 'stores/dialogs/archive-user-dialog';
import TeamHasPendingApprovalsDialog from 'stores/dialogs/team-has-pending-approvals-dialog';
import WarningDialog from 'stores/dialogs/warning-dialog';
import AddNewLanguageDialog from './dialogs/add-new-language-dialog';
import SuggestionDialog from 'stores/dialogs/suggestion-dialog';
import ChecklistAlertRecipientsDialog from 'stores/dialogs/checklist-alert-recipients-dialog';
import SkillProfilesDialog from 'stores/dialogs/skill-profiles-dialog';

//cards
import DetailsCard from 'stores/cards/details-card';
import AutoTranslationDialog from './dialogs/auto-translation-dialog';
import SelectDefaultGuideLanguageDialog from './dialogs/select-default-guide-language-dialog';
import SmartSkillsTeamsPage from './pages/smart-skills-teams-page';
import LearningActivityGeneralInfo from './pages/learning-activity-general-info';
import JobTitlePage from './pages/job-title-page';

//drawers
import VersionHistoryDrawer from 'stores/drawers/version-history-drawer';

const analytics = new AnalyticsStore();

export const store = {
  //global
  app: new AppStore(),
  auth: new AuthStore(),
  ipaStore: new IPAStore(),
  analytics,
  router: new (RouterStore as any)(), // React MobX router expects the store to be a class, so that it can pass itself to the router store.
  platform: new PlatformStore(window.location.hostname),
  permissions: new PermissionsStore(),
  campaign: new CampaignStore(),
  cookiePreference: new CookiePreferenceStore({
    appName: 'CMS',
    enablePosthog: analytics.enablePosthog,
    disablePosthog: analytics.disablePosthog
  }),

  //pages
  allGuidesPage: new AllGuidesPage(),
  newGuidePage: new NewGuidePage(),
  newTopicPage: new NewTopicPage(),
  editTopicPage: new EditTopicPage(),
  editDomainPage: new EditDomainPage(),
  editGuidePage: new EditGuidePage(),
  editInstructionDetailsPage: new EditInstructionDetailsPage(),
  adminPage: new AdminPage(),
  homePage: new HomePage(),
  editProfilePage: new EditProfilePage(),
  forgotPasswordPage: new ForgotPasswordPage(),
  resetPasswordPage: new ResetPasswordPage(),
  setPasswordPage: new SetPasswordPage(),
  guideViewPage: new GuideViewPage(),
  instructionViewPage: new InstructionViewPage(),
  campaignsPage: new CampaignsPage(),
  newUserPage: new NewUserPage(),
  editUserPage: new EditUserPage(),
  shareGuidePage: new ShareGuidePage(),
  themePage: new ThemePage(),
  newCampaignPage: new NewCampaignPage(),
  editCampaignPage: new EditCampaignPage(),
  editInstructionPage: new EditInstructionPage(),
  dragDropItems: new DragDropItems(),
  dragDropSteps: new DragDropSteps(),
  analyticsPage: new AnalyticsPage(),
  translationsPage: new TranslationsPage(),
  tagTranslationsPage: new TagTranslationsPage(),
  tagManagementPage: new TagManagementPage(),
  teamPage: new TeamPage(),
  workspaceTeamsPage: new WorkspaceTeamsPage(),
  editTeamPage: new EditTeamPage(),
  platformSettingsPage: new PlatformSettingsPage(),
  checklistsPage: new ChecklistsPage(),
  translateCampaignPage: new TranslateCampaignPage(),
  signoffResultsPage: new SignoffResultsPage(),
  checklistResultsPage: new CheckListResultsPage(),
  smartSkillsTeamsPage: new SmartSkillsTeamsPage(),
  learningActivityGeneralInfo: new LearningActivityGeneralInfo(),
  skillsPage: new SkillsPage(),
  jobTitlePage: new JobTitlePage(),
  tokenPage: new TokenPage(),
  skillProfilePage: new SkillProfilePage(),
  checklistAlerts: new ChecklistAlertsPage(),

  //dialogs
  changePasswordDialog: new ChangePasswordDialog(),
  changeCampaignStatusDialog: new ChangeCampaignStatusDialog(),
  checklistAlertRecipientsDialog: new ChecklistAlertRecipientsDialog(),
  examplesDialog: new ExamplesDialog(),
  shareDialog: new ShareDialog(),
  uploadTranslationsDialog: new UploadTranslationsDialog(),
  reassignGuideDialog: new ReassignGuideDialog(),
  archiveDialog: new ArchiveDialog(),
  authErrorDialog: new AuthErrorDialog(),
  mediaOverlayDialog: new MediaOverlayDialog(),
  mediaPickerDialog: new MediaPickerDialog(),
  guideFeedbackDialog: new GuideFeedbackDialog(),
  sessionExpiredDialog: new SessionExpiredDialog(),
  saveAsDraftDialog: new SaveAsDraftDialog(),
  unsavedChangesDialog: new UnsavedChangesDialog(),
  versionMismatchDialog: new VersionMismatchDialog(),
  guideVersionsDialog: new GuideVersionsDialog(),
  publishGuideDialog: new PublishGuideDialog(),
  exportToPdfDialog: new ExportToPdfDialog(),
  instructionalIconDialog: new InstructionalIconDialog(),
  editThemeIconDialog: new EditThemeIconDialog(),
  iconsLimitDialog: new IconsLimitDialog(),
  noteIconsAffectedGuidesDialog: new NoteIconAffectedGuidesDialog(),
  moveToDialog: new MoveToDialog(),
  requestApprovalDialog: new RequestApprovalDialog(),
  rejectVersionDialog: new RejectVersionDialog(),
  connectNewWorkspaceDialog: new ConnectNewWorkspaceDialog(),
  addUserToTeamDialog: new AddUserToTeamDialog(),
  guideDetailsDialog: new GuideDetailsDialog(),
  copyToDialog: new CopyToDialog(),
  frequencySettingsDialog: new FrequencySettingsDialog(),
  applyCampaignDialog: new ApplyCampaignDialog(),
  campaignPreviewDialog: new CampaignPreviewDialog(),
  removeCampaignDialog: new RemoveCampaignDialog(),
  confirmDeactivateDialog: new ConfirmDeactivateDialog(),
  overrideCampaignDialog: new OverrideCampaignDialog(),
  deleteUserDialog: new DeleteUserDialog(),
  archiveUserDialog: new ArchiveUserDialog(),
  teamHasPendingApprovalsDialog: new TeamHasPendingApprovalsDialog(),
  warningDialog: new WarningDialog(),
  autoTranslationDialog: new AutoTranslationDialog(),
  selectDefaultGuideLanguageDialog: new SelectDefaultGuideLanguageDialog(),
  addNewLanguageDialog: new AddNewLanguageDialog(),
  suggestionDialog: new SuggestionDialog(),
  skillProfilesDialog: new SkillProfilesDialog(),

  //cards
  detailsCard: new DetailsCard(),

  //drawers
  versionHistoryDrawer: new VersionHistoryDrawer()
};

store.analytics.setStore(store);
store.archiveDialog.setStore(store);
store.campaignsPage.setStore(store);
store.applyCampaignDialog.setStore(store);
store.campaignPreviewDialog.setStore(store);
store.removeCampaignDialog.setStore(store);
store.overrideCampaignDialog.setStore(store);
store.deleteUserDialog.setStore(store);
store.archiveUserDialog.setStore(store);
store.platformSettingsPage.setStore(store);
store.cookiePreference.setNotification(notification);
store.router.store = store;
store.suggestionDialog.setStore(store);
store.checklistAlerts.setStore(store);

export type Store = {store: typeof store};

export default store;
