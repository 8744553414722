import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import React from 'react';
import messages from './messages';
import {useNotifyResize} from 'hooks/useNotifyresize';

import {Drawer, DrawerSpacer, DrawerTitle, DrawerDescription, CloseButton, ListContainer, DrawerHeader} from './styles';
import GuideActivityLog from 'components/GuideActivityLog';
import FiltersPicker from 'components/GuideActivityLog/FiltersPicker';
import {truncate} from 'lodash';

type VersionHistoryProps = {
  intl: any;
  store?: any;
};

const VersionHistoryDrawer = (props: VersionHistoryProps) => {
  const {
    intl: {formatMessage},
    store: {versionHistoryDrawer: drawer}
  } = props;

  useNotifyResize(drawer.opened);

  return (
    drawer.opened && (
      <DrawerSpacer>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle>
              {formatMessage(messages.title)}
              <CloseButton onClick={drawer.reset} data-cy="drawer-close" />
            </DrawerTitle>
            <DrawerDescription>
              {formatMessage(messages.description, {
                name: truncate(drawer.guideTitle, {length: 300})
              })}
            </DrawerDescription>

            <FiltersPicker />
          </DrawerHeader>
          <ListContainer>
            <GuideActivityLog />
          </ListContainer>
        </Drawer>
      </DrawerSpacer>
    )
  );
};

export default injectIntl(inject('store')(observer(VersionHistoryDrawer)));
