import styled from 'styled-components';
import {cond, circle} from 'shared/utils/sc-utils';
import flex from 'styles/flex';

export const popoverClassName = 'c-color-picker__popover';

export const ColorPicker = styled.div`
  margin-bottom: 10px;
`;

export const Box = styled.div`
  height: 40px;
  width: 256px;
  ${flex.horizontal} ${flex.centerHorizontalV} border: 1px solid #dedede;
  padding: 9px 11px;

  color: ${p => p.color};
  background-color: ${p => (p.backgroundColor ? p.backgroundColor : '#cccccc')};
  position: relative;
`;

export const PopoverTrigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  text-align: right;
  padding: 4px 8px;
  &:hover {
    opacity: 0.5;
  }
`;

export const StyledNumber = styled.div`
  ${circle(24)} margin-right: 8px;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const Label = styled.div`
  font-size: 16px;
  text-align: left;
  flex: 1;
`;

export const Picker = styled.div`
  padding: 20px 20px 30px 20px;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
  height: 100%;
`;

export const HexTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #4a4c4d;
  margin-bottom: 7px;
`;

export const InputWrap = styled.div`
  ${flex.horizontal} ${flex.centerHorizontalV} height: 40px;
  width: 260px;
  position: relative;
`;

export const Hash = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;

  ${flex.horizontal} ${flex.centerHorizontal} font-size: 16px;
  text-align: center;
  color: #939798;
  border: 1px solid #dedede;
  border-right: none;
`;

export const Input = styled.input`
  padding: 10px 35px 10px 10px;
  border: 1px solid #dedede;
  outline: none;
  height: 100%;
  flex: 1;

  ${p =>
    cond(
      p.error,
      `
    box-shadow: 0 0 3px rgba(255, 0, 0, 0.56);
  `
    )};
`;

export const Color = styled.div`
  height: 30px;
  width: 30px;
  background-color: #${p => p.color};
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  margin: auto;
`;
