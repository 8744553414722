import styled from 'styled-components';

//helpers
import {topicListMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';
import {boxShadow, boxShadowTransition} from 'styles/shadows';

//components
import $CardToolbar from 'components/CardToolbar';
import $DragHandle from 'components/DragHandle';
import $UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';
import $MediaPicker from 'components/MediaPicker';
import $Slug from 'components/Slug';
import {Link as $Link} from 'utils/mobx';
import $Icon from 'ui-components/Layout/Icon';
import Button from 'ui-components/Button';

//constants
const coverHeight = 84;
const coverWidth = 112;
const coverWidthMobile = 98;

export const InstructionCardStatic = styled.div`
  position: relative;

  ${p =>
    p.isGhostly &&
    `
    background: ${commonPalette.ghostlyGreen};
    box-shadow: none !important;
    filter: blur(4px);
  `}

  + ${() => InstructionCardStatic},
  + ${() => InstructionCardForm} {
    margin-top: ${topicListMetrics.instructionGutter}px;
  }

  &:hover {
    ${() => StyledPencilIcon} {
      opacity: 1;
    }
    ${() => StyledButton} {
      display: block !important;
    }
  }
`;

export const InstructionCardForm = InstructionCardStatic.withComponent('form');

export const InnerWrapperStatic = styled.div`
  background: #fff;
  box-shadow: ${boxShadow.medium};
  min-height: ${coverHeight + 2 * 8}px;
  padding: 26px 52px 16px 172px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${p =>
    p.isInvisible &&
    `
    // NB. In Microsoft Edge, opacity won't be applied
    // to its absolute-positioned children unless the
    // element is positioned itself

    opacity: 0;
  `}

  @media (max-width: 720px) {
    padding: 26px 52px 16px 142px;
  }
`;

export const InnerWrapperLink = styled(InnerWrapperStatic.withComponent($Link))`
  display: block;
  transition: ${boxShadowTransition};
  color: ${commonPalette.txBlack}

  &:hover {
    box-shadow: ${boxShadow.large};
  }
`;

export const StyledDragHandle = styled($DragHandle)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;

  ${p =>
    p.isInvisible &&
    `
    opacity: 0;
  `}
`;

export const Cover = styled.div`
  height: ${coverHeight}px;
  left: 30px;
  margin-top: ${-coverHeight / 2}px;
  position: absolute;
  top: 50%;
  width: ${coverWidth}px;
  @media (max-width: 720px) {
    width: ${coverWidthMobile}px;
  }
`;

export const StyledMediaPicker = styled($MediaPicker).attrs({
  backgroundAspectRatio: coverWidth / coverHeight,
  backgroundSizes: `${coverWidth}px`
})`
  height: 100%;
  left: 0;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  color: ${commonPalette.txBlack};
`;

export const StyledUnderlinedTextbox = styled($UnderlinedTextbox)`
  font-size: 18px;
`;

export const StyledSlug = styled($Slug)`
  font-size: 14px;
  margin-bottom: 2px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  font-size: 18px;
  color: ${commonPalette.txBlack};
`;

export const StyledPencilIcon = styled($Icon).attrs({
  name: 'pencil',
  size: 16
})`
  margin-left: 7px;
  min-width: 16px;
  cursor: pointer;
  opacity: 0;
  color: ${commonPalette.txBlack};
`;

export const StyledCardToolbar = styled($CardToolbar)`
  position: absolute;
  right: 10px;
  top: 10px;
  color: ${commonPalette.txBlack};
`;

export const StyledButton = styled(Button)`
  margin-top: 10px;
  height: 36px;
`;

export const FieldWrapper = styled.div`
  display: flex;
`;

export const ChecklistIcon = styled($Icon).attrs({
  name: 'checklist',
  size: 32
})`
  flex-shrink: 0;
  margin: -4px 10px 0 -10px;
`;

export const StyledChecklistType = styled.div`
  margin-bottom: 3px;
  color: #575f5f;
`;
