import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {syncTitleWithSlug} from 'shared/utils/input-utils';

//components
import TopicDetails from 'components/TopicDetails';

//data
import {UpdateTopic} from 'api/topic/mutations';
import {updateTopic} from 'api/topic/mutation-options';

@inject('store')
@graphql(UpdateTopic, updateTopic)
@observer
class EditTopicComponent extends Component {
  titleFieldOptions = {
    onChangeReaction: e => syncTitleWithSlug(e, this.props.topicStore.form)
  };

  render() {
    const {
      store: {editTopicPage},
      updateTopicMutation,
      topic
    } = this.props;

    return <TopicDetails mode="edit" topic={topic} submitMutation={updateTopicMutation} topicStore={editTopicPage} />;
  }
}

export default EditTopicComponent;
