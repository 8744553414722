/**
 * Before rendering to the canvas not all fonts migth be ready.
 * Allow for trying to call the Fonts API to preload them. This
 * might not be supported in all browsers (e.g.: IE11) which will
 * result in the first canvas rendering of the font to fall back
 * to its default.
 */
export const tryPreloadingFonts = async (...fonts) => {
  if (!window.document.fonts?.load) {
    // Custom
    return;
  }
  await Promise.all(fonts.map(font => document.fonts.load(font))).catch(error => {
    console.warn('Failed to preload fonts', error);
  });
};
