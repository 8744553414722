import {Platform, IconsList, PlatformFlags} from 'api/platform/queries';

export const refreshPlatform = () => ({
  query: Platform,
  fetchPolicy: 'network-only'
});

export const refreshPlatformFlags = () => ({
  query: PlatformFlags
});

export const refreshIconsList = (onlyEnabled = false) => {
  return {
    query: IconsList,
    fetchPolicy: 'network-only',
    variables: {
      onlyEnabled
    }
  };
};
