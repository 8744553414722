import {getShouldUpdateUsers, getCanAddMoreEmptyCards, getUserOptionAdder, getCanSelectApprovalOrder} from './utils';

const UPDATE_REQUIRE_GUIDE_APPROVAL = Symbol('UPDATE_REQUIRE_GUIDE_APPROVAL');
const UPDATE_SELECT_APPROVER_ORDER = Symbol('UPDATE_SELECT_APPROVER_ORDER');
const UPDATE_USERS = Symbol('UPDATE_USERS');
const ADD_EMPTY_CARD = Symbol('ADD_EMPTY_CARD');
const UPDATE_CARD_VALUE = Symbol('UPDATE_CARD_VALUE');
const REMOVE_CARD = Symbol('REMOVE_CARD');
const MOVE_CARD_UP = Symbol('MOVE_CARD_UP');
const MOVE_CARD_DOWN = Symbol('MOVE_CARD_DOWN');

const actionTypes = {
  UPDATE_REQUIRE_GUIDE_APPROVAL,
  UPDATE_SELECT_APPROVER_ORDER,
  UPDATE_USERS,
  ADD_EMPTY_CARD,
  UPDATE_CARD_VALUE,
  REMOVE_CARD,
  MOVE_CARD_UP,
  MOVE_CARD_DOWN
};

const resetState = ({requireGuideApproval, selectApproverOrder, users}) => {
  const bareInitialState = {
    requireGuideApproval,
    users,
    teamMembershipCardsData: []
  };

  const initialStateWithPopulatedCarsds = requireGuideApproval
    ? enableGuideApproval(bareInitialState)
    : bareInitialState;

  const newInitialState = {
    ...initialStateWithPopulatedCarsds,
    selectApproverOrder: getCanSelectApprovalOrder(initialStateWithPopulatedCarsds) && selectApproverOrder
  };

  return newInitialState;
};

const getNewTeamMembershipCardsDataWithNoOptions = users => {
  const approversExist = users.some(({isApprover}) => isApprover);
  if (approversExist) {
    return users
      .filter(({isApprover}) => isApprover)
      .map(({id}) => ({
        selectedUserId: id,
        isEmpty: false,
        usersOptions: []
      }));
  } else {
    return [
      {
        isEmpty: true,
        selectedUserId: null,
        usersOptions: []
      }
    ];
  }
};

const enableGuideApproval = state => {
  const {users, selectApproverOrder} = state;
  const newTeamMembershipCardsDataWithNoOptions = getNewTeamMembershipCardsDataWithNoOptions(users);
  const addOptions = getUserOptionAdder({teamMembershipCards: newTeamMembershipCardsDataWithNoOptions, users});
  const teamMembershipCardsData = newTeamMembershipCardsDataWithNoOptions.map(addOptions);

  return {
    requireGuideApproval: true,
    selectApproverOrder,
    users,
    teamMembershipCardsData
  };
};

const disableGuideApproval = state => ({
  ...state,
  requireGuideApproval: false,
  selectApproverOrder: false,
  teamMembershipCardsData: []
});

const updateRequireGuideApproval = (state, payload) => {
  const {requireGuideApproval} = state;
  const {requireGuideApproval: newRequireGuideApproval} = payload;

  if (!requireGuideApproval && newRequireGuideApproval) {
    return enableGuideApproval(state);
  } else if (requireGuideApproval && !newRequireGuideApproval) {
    return disableGuideApproval(state);
  } else {
    return state;
  }
};

const updateUsers = (state, payload) => {
  const {users} = payload;
  const shouldUpdateUsers = getShouldUpdateUsers({state, newUsers: users});

  if (shouldUpdateUsers) {
    const {requireGuideApproval, selectApproverOrder} = state;
    return resetState({requireGuideApproval, selectApproverOrder, users});
  } else {
    return state;
  }
};

const addEmptyCard = state => {
  const {users, teamMembershipCardsData} = state;
  const canAddMoreEmptyCards = getCanAddMoreEmptyCards(state);

  if (canAddMoreEmptyCards) {
    const emptyCard = {
      isEmpty: true,
      selectedUserId: null,
      usersOptions: []
    };
    const newTeamMembershipCardsDataWithOldOptions = [...teamMembershipCardsData, emptyCard];
    const addOptions = getUserOptionAdder({teamMembershipCards: newTeamMembershipCardsDataWithOldOptions, users});
    const newTeamMembershipCardsData = newTeamMembershipCardsDataWithOldOptions.map(addOptions);

    return {
      ...state,
      teamMembershipCardsData: newTeamMembershipCardsData
    };
  } else {
    return state;
  }
};

const updateCardValue = (state, payload) => {
  const {users, teamMembershipCardsData} = state;
  const {cardPosition, selectedUserId} = payload;

  const newTeamMembershipCardsDataWithOldOptions = teamMembershipCardsData.map(
    ({isEmpty, selectedUserId: cardSelectedUserId, usersOptions}, index) => {
      const isValidOption = cardPosition === index && usersOptions.some(({userId}) => userId == selectedUserId);
      const newSelectedUserId = isValidOption ? selectedUserId : cardSelectedUserId;
      const newIsEmpty = isValidOption ? false : isEmpty;

      return {
        isEmpty: newIsEmpty,
        selectedUserId: newSelectedUserId,
        usersOptions
      };
    }
  );

  const addOptions = getUserOptionAdder({teamMembershipCards: newTeamMembershipCardsDataWithOldOptions, users});
  const newTeamMembershipCardsData = newTeamMembershipCardsDataWithOldOptions.map(addOptions);

  return {
    ...state,
    teamMembershipCardsData: newTeamMembershipCardsData
  };
};

const removeCard = (state, payload) => {
  const {selectApproverOrder, users, teamMembershipCardsData} = state;
  const {cardPosition} = payload;

  const newTeamMembershipCardsDataWithOldOptions = teamMembershipCardsData.filter((_, index) => cardPosition !== index);

  const addOptions = getUserOptionAdder({teamMembershipCards: newTeamMembershipCardsDataWithOldOptions, users});
  const newTeamMembershipCardsData = newTeamMembershipCardsDataWithOldOptions.map(addOptions);

  const bareNewState = {
    ...state,
    teamMembershipCardsData: newTeamMembershipCardsData
  };
  const newSelectApproverOrder = getCanSelectApprovalOrder(bareNewState) && selectApproverOrder;

  return {
    ...bareNewState,
    selectApproverOrder: newSelectApproverOrder
  };
};

const moveCardUp = (state, payload) => {
  const {teamMembershipCardsData} = state;
  const {cardPosition} = payload;

  const cardToBeMoved = teamMembershipCardsData[cardPosition];
  const cardToBeReplaced = teamMembershipCardsData[cardPosition - 1];

  teamMembershipCardsData[cardPosition] = cardToBeReplaced;
  teamMembershipCardsData[cardPosition - 1] = cardToBeMoved;

  return {
    ...state,
    teamMembershipCardsData
  };
};

const moveCardDown = (state, payload) => {
  const {teamMembershipCardsData} = state;
  const {cardPosition} = payload;

  const cardToBeMoved = teamMembershipCardsData[cardPosition];
  const cardToBeReplaced = teamMembershipCardsData[cardPosition + 1];

  teamMembershipCardsData[cardPosition] = cardToBeReplaced;
  teamMembershipCardsData[cardPosition + 1] = cardToBeMoved;

  return {
    ...state,
    teamMembershipCardsData
  };
};

const updateSetApprovalOrder = (state, {selectApproverOrder}) => {
  const newSelectApproverOrder = getCanSelectApprovalOrder(state) && selectApproverOrder;

  return {
    ...state,
    selectApproverOrder: newSelectApproverOrder
  };
};

const initState = resetState;

const getNextState = (state, {actionType, payload}) => {
  switch (actionType) {
    case UPDATE_REQUIRE_GUIDE_APPROVAL:
      return updateRequireGuideApproval(state, payload);
    case UPDATE_USERS:
      return updateUsers(state, payload);
    case ADD_EMPTY_CARD:
      return addEmptyCard(state);
    case UPDATE_CARD_VALUE:
      return updateCardValue(state, payload);
    case REMOVE_CARD:
      return removeCard(state, payload);
    case MOVE_CARD_UP:
      return moveCardUp(state, payload);
    case MOVE_CARD_DOWN:
      return moveCardDown(state, payload);
    case UPDATE_SELECT_APPROVER_ORDER:
      return updateSetApprovalOrder(state, payload);
    default:
      return state;
  }
};

export {actionTypes, initState, getNextState};
