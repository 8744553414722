import React, {Component} from 'react';

//styled-components
import {ImageVariants} from './styles';

class ImageVariantsComponent extends Component {
  render() {
    const {images, className} = this.props;

    return <ImageVariants className={className} src={images['1x']} srcSet={`${images['2x']} 2x, ${images['3x']} 3x`} />;
  }
}

export default ImageVariantsComponent;
