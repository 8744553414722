import React from 'react';
import {injectIntl} from 'react-intl';
import {CenterDiv, StyledSubText} from './styles';
import Icon from 'ui-components/Layout/Icon';
import Button from 'ui-components/Button';
import messages from './messages';
import {inject, observer} from 'mobx-react';
import views from 'config/views';

@inject('store')
@observer
class EmptyLearningActivities extends React.Component {
  render() {
    const {
      view,
      intl: {formatMessage},
      store: {router, learningActivityGeneralInfo}
    } = this.props;
    return (
      <CenterDiv>
        <Icon data-cy="book" name="book" size={25} />
        <div>{formatMessage(messages.noLearningActivities)}</div>
        <StyledSubText isEmptyState={true}>{formatMessage(messages.createFirstLearningActivity)}</StyledSubText>
        <Button
          secondary
          label={formatMessage(messages.createLearningActivity)}
          onClick={() => {
            learningActivityGeneralInfo.updatePreviousPage(view);
            router.goTo(views.createLearningActivity, {});
          }}
        />
      </CenterDiv>
    );
  }
}

export default injectIntl(EmptyLearningActivities);
