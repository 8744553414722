import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {ChromePicker} from 'react-color';
import {findDOMNode} from 'react-dom';

//lodash
import capitalize from 'lodash/capitalize';
import invoke from 'lodash/invoke';

//styled-components
import {
  ShapeRow,
  DragHandleWrapper,
  DragHandle,
  ShapeDetails,
  Icon,
  Label,
  ColorPicker,
  ColorLabelWrapper,
  Header,
  DetailRow,
  Popover,
  Color,
  Cover,
  ButtonRow,
  DeleteButton
} from './styles';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import {Select, SelectOption} from 'styles/styled-components/form-styled-components';

//messages
import messages from './messages';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@observer
class ShapeRowComponent extends Component {
  setThickness = thickness => {
    const {
      position,
      store: {mediaPickerDialog}
    } = this.props;
    mediaPickerDialog.setThickness(position, thickness);

    trackEvent(EVENT_TYPES.SHAPE_THICKNESS_SELECTED, {thickness});
  };

  setShapeColor = color => {
    const {
      position,
      store: {mediaPickerDialog}
    } = this.props;
    mediaPickerDialog.setShapeColor(position, color);

    trackEvent(EVENT_TYPES.SHAPE_COLOR_SELECTED, {color});
  };

  handleHostInnerRef = ref => {
    const node = findDOMNode(ref);
    invoke(this.props, 'hostRef', node);
  };

  renderShapeDetails = () => {
    const {
      shape,
      design,
      position,
      store: {mediaPickerDialog, mediaOverlayDialog}
    } = this.props;
    const {selectedShapeId, setSelectedShape, shouldCollapse, toggleShowTableColorPicker, showTableColorPicker} =
      mediaOverlayDialog;
    const {deleteShape} = mediaPickerDialog;

    const isSelected = selectedShapeId === shape.id;
    const isInvisible = design === 'ghostly';
    const thicknessOptions = [2, 4, 6];

    if (!isSelected || shouldCollapse) {
      return (
        <ShapeDetails collapsed onClick={() => setSelectedShape(shape)} isInvisible={isInvisible}>
          <Icon type="shape" name={shape.shape} size={24} />

          <Label collapsed>{capitalize(shape.shape)}</Label>

          <Label collapsed>
            <Icon type="thickness" name={`thickness${shape.thickness}`} />
            {shape.thickness}px
          </Label>

          <ColorLabelWrapper>
            <Color color={shape.color} />
            <Label collapsed colorLabel>
              {shape.color}
            </Label>
          </ColorLabelWrapper>

          <Icon
            type="edit"
            name="pencil"
            size={20}
            onClick={() => trackEvent(EVENT_TYPES.EDIT_SHAPE, {shape: {name: shape.shape}})}
          />
        </ShapeDetails>
      );
    }

    return (
      <ShapeDetails>
        <Header onClick={() => setSelectedShape(null)}>
          <Icon type="shape" name={shape.shape} size={24} />
          <Label>{capitalize(shape.shape)}</Label>
          <Icon type="close" name="close" size={14} />
        </Header>

        <DetailRow>
          <Label>
            <FormattedMessage {...messages.thickness} />
          </Label>
          <Select value={`${shape.thickness}`} onChange={this.setThickness}>
            {thicknessOptions.map(option => (
              <SelectOption key={option} value={`${option}`}>
                <Icon type="thickness" name={`thickness${option}`} />
                {option}px
              </SelectOption>
            ))}
          </Select>
        </DetailRow>

        <DetailRow>
          <Label>
            <FormattedMessage {...messages.color} />
          </Label>
          <ColorPicker>
            <Color color={shape.color} onClick={() => toggleShowTableColorPicker()} />
            <Label colorLabel>{shape.color}</Label>
          </ColorPicker>
          {showTableColorPicker && (
            <Popover>
              <Cover onClick={() => toggleShowTableColorPicker()} />
              <ChromePicker style={{width: 200}} color={shape.color} onChange={this.setShapeColor} />
            </Popover>
          )}
        </DetailRow>

        <ButtonRow>
          <DeleteButton
            onClick={() => {
              deleteShape(position);
              setSelectedShape(null);
            }}
          >
            <FormattedMessage {...messages.deleteShape} />
          </DeleteButton>
        </ButtonRow>
      </ShapeDetails>
    );
  };

  render() {
    const {
      shape,
      dragHandleRef,
      design,
      store: {mediaOverlayDialog}
    } = this.props;
    const {selectedShapeId} = mediaOverlayDialog;
    const isInvisible = design === 'ghostly';
    const isSelected = selectedShapeId === shape.id;

    return (
      <ShapeRow key={shape.id} design={design} ref={this.handleHostInnerRef} isSelected={isSelected}>
        <DragHandleWrapper isInvisible={isInvisible}>
          <DragHandle forwardedRef={dragHandleRef} />
        </DragHandleWrapper>
        {this.renderShapeDetails()}
      </ShapeRow>
    );
  }
}

export default injectIntl(ShapeRowComponent);
