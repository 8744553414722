import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import views from 'config/views';

//lodash
import find from 'lodash/find';
import without from 'lodash/without';

//helperes
import {DEFAULT_TOPIC_SLUG} from 'config/constants';
import injectScrollParent from 'decorators/injectScrollParent';
import {scrollTo} from 'shared/utils/dom-utils';
import {isDraftVersionId} from 'utils/versioning-utils';

//styles
import {EditInstructionMenu, Header, StyledViewTitle} from './styles';

//components
import TopicItem from './TopicItem';
import DraggableTopicItem from './DraggableTopicItem';
import CustomDragLayer from './CustomDragLayer';
import Button from 'ui-components/Button';

//messages
import messages from './messages';

@inject('store')
@injectScrollParent
@observer
class EditInstructionMenuComponent extends Component {
  bottomSpacerElement = null;
  topSpacerElement = null;

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {editInstructionDetailsPage, dragDropItems, router} = store;
    const {guide} = editInstructionDetailsPage;
    const {id} = router.params;
    const {topics} = dragDropItems;

    const defaultTopic = find(topics, {slug: DEFAULT_TOPIC_SLUG});
    const customTopics = without(topics, defaultTopic);

    return (
      <EditInstructionMenu>
        <Header>
          <StyledViewTitle title={guide.title} />
          <Button
            underlined
            label={formatMessage(messages.guideOverview)}
            params={{id}}
            queryParams={router.queryParams}
            store={store}
            route={views.editGuide}
            iconId="back"
          />
        </Header>
        <div
          ref={ref => {
            this.topSpacerElement = ref;
          }}
        />
        {defaultTopic && <TopicItem design="default" topic={defaultTopic} />}
        {customTopics.map(topic => this.renderCustomTopic(topic))}
        <CustomDragLayer />
        <div
          ref={ref => {
            this.bottomSpacerElement = ref;
          }}
        />
      </EditInstructionMenu>
    );
  }

  renderCustomTopic(topic) {
    const {store} = this.props;
    const {editInstructionDetailsPage} = store;
    const {currentVersionId} = editInstructionDetailsPage;

    const sharedProps = {
      key: topic.id,
      topic: topic
    };

    if (!isDraftVersionId(currentVersionId)) {
      return <TopicItem {...sharedProps} onDragHandleMouseDown={this.handleTopicDragHandleMouseDown} />;
    }

    return (
      <DraggableTopicItem
        {...sharedProps}
        onBeginDrag={element => {
          this.toggleTopicsAndScrollElementIntoView(element, true);
        }}
        onEndDrag={element => {
          this.toggleTopicsAndScrollElementIntoView(element, false);
        }}
      />
    );
  }

  handleTopicDragHandleMouseDown = event => {
    const {store} = this.props;
    const {saveAsDraftDialog} = store;

    event.preventDefault(); // so the focus stays inside the dialog
    saveAsDraftDialog.check();
  };

  scrollIntoView = (element, offsetX, offsetY) => {
    const {getScrollParent} = this.props;
    const scrollParent = getScrollParent();
    const {left, top} = element.getBoundingClientRect();

    const maxScrollTop = scrollParent.scrollHeight - scrollParent.offsetHeight;
    const newScrollLeft = scrollParent.scrollLeft + left - offsetX;
    const newScrollTop = scrollParent.scrollTop + top - offsetY;

    this.bottomSpacerElement.style.height = newScrollTop > maxScrollTop ? `${newScrollTop - maxScrollTop}px` : 0;

    this.topSpacerElement.style.height = newScrollTop < 0 ? `${Math.abs(newScrollTop)}px` : 0;

    scrollTo(scrollParent, {
      left: newScrollLeft,
      top: newScrollTop
    });
  };

  toggleTopicsAndScrollElementIntoView = (element, shouldCollapse) => {
    const {store} = this.props;
    const {dragDropItems} = store;

    const {left, top} = element.getBoundingClientRect();

    // This should be done asyncronously as otherwise the immediate
    // layout change would end the drag
    window.requestAnimationFrame(() => {
      dragDropItems.setAreTopicsCollapsed(shouldCollapse);
      this.scrollIntoView(element, left, top);
    });
  };
}

export default injectIntl(EditInstructionMenuComponent);
