import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {ForgotPasswordView, FormWrapper, AlreadyLoggedIn} from './styles';

//components
import ForgotPasswordForm from 'components/ForgotPasswordForm';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@observer
class ForgotPasswordComponent extends Component {
  render() {
    const {store} = this.props;

    return (
      <ForgotPasswordView>
        {store.auth.user ? (
          <AlreadyLoggedIn>
            <FormattedMessage {...messages.alreadyLoggedIn} />
          </AlreadyLoggedIn>
        ) : (
          <FormWrapper>
            <ForgotPasswordForm />
          </FormWrapper>
        )}
      </ForgotPasswordView>
    );
  }
}

export default ForgotPasswordComponent;
