import {defineMessages} from 'react-intl';

const messages = defineMessages({
  preview: {
    id: 'common.actions.preview',
    defaultMessage: 'Preview'
  },
  question: {
    id: 'EditCampaign.question',
    defaultMessage: 'Question'
  },
  followUpQuestion: {
    id: 'EditCampaign.followUpQuestions',
    defaultMessage: 'Follow Up Questions'
  },
  thankYouMessage: {
    id: 'EditCampaign.thankYouMessage',
    defaultMessage: 'Thank You Message'
  }
});

export default messages;
