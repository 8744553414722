import {defineMessages} from 'react-intl';

const messages = defineMessages({
  connectedSkillProfiles: {
    id: 'connectedSkillProfiles',
    defaultMessage:
      'This skill is connected to {number} skill profiles in your team. Go to the profile to make changes.'
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel'
  }
});

export default messages;
