import React from 'react';
import messages from './messages';
import Spinner from 'shared/components/Spinner';
import {SpinnerWrapper, TranslationInProgressContainer} from './styles';

const TranslationInProgressDialogContent = ({formatMessage}) => {
  return (
    <TranslationInProgressContainer>
      <p>{formatMessage(messages.untranslatedContentAutoTranslated)}</p>
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
      <p>{formatMessage(messages.translating)}</p>
    </TranslationInProgressContainer>
  );
};

export default TranslationInProgressDialogContent;
