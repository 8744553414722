import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Container from 'ui-components/Layout/Container';
import SearchBar from 'ui-components/Layout/SearchBar';
import ViewTitle from 'ui-components/Layout/ViewTitle';

import views from 'config/views';

import messages from './messages';
import StepsTabs from 'components/StepsTabs';
import {mapValues, pick} from 'lodash';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@observer
class ChecklistsReport extends Component {
  UNSAFE_componentWillMount = () => {
    const {
      store: {
        platform: {hasChecklistsEnabled},
        router: {goTo},
        checklistResultsPage: {setSteps, setTranslations}
      },
      intl: {formatMessage}
    } = this.props;
    if (!hasChecklistsEnabled) {
      goTo(views.guides);
      return;
    }
    const translations = mapValues(pick(messages, ['overview', 'results']), message => formatMessage(message));
    setTranslations(translations);
    setSteps();
  };

  componentDidMount() {
    const {
      store: {
        checklistResultsPage: {init},
        router: {params}
      }
    } = this.props;

    init(params);
  }

  componentWillUnmount = () => {
    const {
      store: {
        checklistsPage: {setSearchTerm}
      }
    } = this.props;

    setSearchTerm('');
  };

  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {checklistsPage, checklistResultsPage} = store;
    const {stepsStore} = checklistResultsPage;
    const {setSearchTerm} = checklistsPage;

    const handleSearchTermChange = e => {
      const searchTerm = e.target.value;
      setSearchTerm(searchTerm);

      trackEvent(EVENT_TYPES.CHECKLISTS_SEARCH, {searchTerm});
    };

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.checklists)}
          right={
            stepsStore.currentStepIndex === 1 && (
              <SearchBar placeholder={formatMessage(messages.searchChecklists)} onChange={handleSearchTermChange} />
            )
          }
        />
        <StepsTabs fullHeight mode={'edit'} stepsStore={stepsStore} page={checklistResultsPage} hideButtons={true} />
      </Container>
    );
  }
}

export default injectIntl(ChecklistsReport);
