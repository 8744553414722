import styled from 'styled-components';

//helpers
import {stepCardMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';
import {interactiveShadowFromMediumStyle} from 'styles/shadows';
import typefaces from 'shared/styles/typefaces';

//components
import $Icon from 'ui-components/Layout/Icon';
import $Link from 'shared/components/Link';

export const StyledLink = styled($Link)`
  ${interactiveShadowFromMediumStyle};
  background: ${commonPalette.darkestGray};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ${stepCardMetrics.height}px;
  padding: 0 50px;
  text-align: center;
  width: ${stepCardMetrics.width}px;
`;

export const InnerWrapper = styled.div`
  margin: auto;
`;

export const Title = styled.div`
  ${typefaces.newStepLink.title};
  color: ${commonPalette.txBlack};
`;

export const PlusIcon = styled($Icon).attrs({
  name: 'plus',
  size: 24 // matches the font size
})`
  margin-left: ${p => -p.size / 2}px; // hanging punctuation
  margin-right: 0.26em; // single space width
  vertical-align: -0.15em;
`;
