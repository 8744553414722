import React from 'react';
import {action, autorun, observable, computed, makeObservable, runInAction} from 'mobx';
import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';

//helpers
import notification from 'utils/notification-utils';

//forms
import campaignQuestionForm from 'stores/forms/campaign-question-form';

//models
import FollowUpQuestion from 'stores/models/follow-up-question';
import CampaignTextOption from 'stores/models/campaign-text-type-options';
import Steps from 'stores/models/steps';

//components
import CampaignQuestions from 'components/CampaignForm/CampaignQuestions';
import CampaignPreview from 'components/CampaignPreview';

//lodash
import map from 'lodash/map';
import every from 'lodash/every';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import assign from 'lodash/assign';
import toInteger from 'lodash/toInteger';

class EditCampaignPage {
  @observable loading = false;
  @observable followUps = [];
  @observable editedCampaignId;
  @observable availableLanguages = [];
  @observable questionTranslations = {};
  @observable followUpTranslations = [];
  @observable autofocusFollowUp = false;
  @observable stepsStore;
  @observable isMobileView = false;
  @observable initialValues = {};
  @observable textTypeOptions = [];
  @observable textTypeOptionsTranslations = [];
  translations = {};
  multipleLocales = false;
  @observable showSaveButton = true;
  @observable showCancelButton = true;

  questionForm = campaignQuestionForm;

  setTranslations = translations => {
    this.translations = translations;
  };

  @action setLoading = val => {
    this.loading = val;
  };

  @action setSteps = () => {
    const steps = [
      {
        key: 'questions',
        path: 'questions',
        component: <CampaignQuestions isNewCampaign={false} />,
        form: this.questionForm,
        checked: false
      }
    ];
    const preview = {
      key: 'preview',
      path: 'preview',
      component: <CampaignPreview />
    };
    this.stepsStore = new Steps({
      steps,
      preview,
      editMode: true
    });
  };

  @action setFollowUps = followUps => {
    this.followUps = followUps;
  };

  @action startEdit = campaign => {
    const {question, defaultLocale} = campaign;
    const {followUps, textTypeOptions} = question;

    //set initial values
    this.editedCampaignId = campaign.id;
    this.updateForms(campaign);
    this.updateFollowUpValues(followUps, defaultLocale);
    this.updateTextTypeOptions(textTypeOptions, defaultLocale);

    this.saveInitialValues();
  };

  @action updateForms = campaign => {
    const {title, defaultLocale, question: questionObj} = campaign;
    const {questionTranslations, kind: questionKind, numOfRating} = questionObj;

    this.questionForm.update({
      question: questionTranslations[defaultLocale],
      kind: questionKind,
      numOfRating,
      title,
      defaultLocale
    });

    this.questionTranslations = omitBy(omit(questionTranslations, [defaultLocale], ['__typename']), question =>
      isNull(question)
    );
  };

  @action updateFollowUpValues = (followUps, defaultLocale) => {
    this.followUps = followUps.map((followUp, index) => {
      const {questionTranslations, ...rest} = followUp;
      this.followUpTranslations[index] = omitBy(omit(questionTranslations, [defaultLocale], ['__typename']), question =>
        isNull(question)
      );
      return new FollowUpQuestion({...rest, question: questionTranslations[defaultLocale]});
    });
  };

  @action updateTextTypeOptions = (textTypeOptions, defaultLocale) => {
    this.textTypeOptions = textTypeOptions.map((option, index) => {
      const formattedOption = option[defaultLocale];

      this.textTypeOptionsTranslations[index] = omit(option, [defaultLocale, '__typename']);
      return new CampaignTextOption({option: formattedOption});
    });
  };

  @action saveInitialValues = () => {
    const followUpsValues = this.followUps.map(fu => fu.form.values());
    const textTypeOptionsValues = this.textTypeOptions.map(to => to.form.values());

    this.initialValues = {
      questions: this.questionForm.values(),
      followUps: followUpsValues,
      textTypeOptions: textTypeOptionsValues
    };
  };

  //campaign preview
  @action setViewMode = val => (this.isMobileView = val);

  //campaign questions -- default locale
  @action setDefaultLocale = locale => {
    this.questionForm.$('defaultLocale').sync(locale);
  };

  constructor() {
    makeObservable(this);
  }

  @computed get defaultLocale() {
    return this.questionForm.values().defaultLocale;
  }

  // -- question type
  @computed get questionType() {
    return this.questionForm.$('kind').value;
  }

  @computed get isBinaryQuestionType() {
    return (
      this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO ||
      this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL
    );
  }

  @computed get isStarQuestionType() {
    return this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.STAR;
  }

  @computed get isNumberQuestionType() {
    return this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.NUMBER;
  }

  @computed get isTextQuestionType() {
    return this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT;
  }

  @computed get filteredTextTypeOptions() {
    return this.textTypeOptions.filter((item, index) => index < this.maxRating);
  }

  // -- question rating range
  @computed get minFollowUpRating() {
    return this.isBinaryQuestionType ? 0 : 1;
  }

  @computed get maxRating() {
    return Number(this.questionForm.$('numOfRating').get('value'));
  }

  /*
   * Currently, this watcher is never actually disposed as the store is instantiated
   * only once and just gets recycled each time. However, if we ever reinstantiate
   * the store this should be called when the old instance is no longer needed to
   * prevent memory leaks.
   */
  @action
  disposeTextTypeOptionsSizeWatcher = autorun(() => {
    if (!this.isTextQuestionType) {
      return;
    }

    const length = this.textTypeOptions.length;

    if (length >= this.maxRating) {
      return;
    }

    const countToAdd = this.maxRating - length;

    runInAction(() => {
      for (let i = 0; i < countToAdd; i++) {
        this.textTypeOptions.push(new CampaignTextOption());
        this.textTypeOptionsTranslations.push({});
      }
    });
  });

  // -- question follow ups
  @computed get canAddFollowUp() {
    const {kind} = this.questionForm.values();
    const isBinary = kind === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO || kind === CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL;
    const followUpsEmpty = isEmpty(this.followUps);
    const optionsAvailable = !isEmpty(this.binaryOptions[1]);
    const overMaxLength = this.followUps.length === 2;

    return followUpsEmpty || !isBinary || (optionsAvailable && !overMaxLength);
  }

  @action addFollowUp = () => {
    this.autofocusFollowUp = true;
    this.followUps.push(new FollowUpQuestion());
    this.followUpTranslations.push({});
  };

  @action removeFollowUp = followUp => {
    const index = this.followUps.indexOf(followUp);
    this.followUps.splice(index, 1);
    this.followUpTranslations.splice(index, 1);
  };

  // -- question follow ups - binary
  @computed get binaryOptions() {
    const options =
      this.questionType === CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO
        ? [
            {id: '0', name: 'NO'},
            {id: '1', name: 'YES'}
          ]
        : [
            {id: '0', name: 'FAIL'},
            {id: '1', name: 'PASS'}
          ];

    const firstFollowUp = !isEmpty(this.binarySelectValues) && this.binarySelectValues[0];
    const areOptionsStillAvailable = firstFollowUp.length < 2;

    if (!firstFollowUp || !areOptionsStillAvailable) return [options];
    else {
      const optionsSecondFollowUp = options.filter(option => option.id !== firstFollowUp[0]);
      return [options, optionsSecondFollowUp];
    }
  }

  @computed get binarySelectValues() {
    return this.followUps.map(fu => {
      const {rangeFrom, rangeTo} = fu.form.values();
      return rangeFrom ? (rangeFrom === rangeTo ? [rangeFrom] : [rangeFrom, rangeTo]) : [];
    });
  }

  @action saveFollowUpBinaryValues = (values, index) => {
    const {form} = this.followUps[index];
    const nextFollowUp = this.followUps.length > 1 ? (index === 0 ? this.followUps[1] : this.followUps[0]) : null;

    if (isEmpty(values)) {
      form.$('rangeFrom').reset();
      form.$('rangeTo').reset();
    } else if (values.length > 1) {
      form.$('rangeFrom').sync('0');
      form.$('rangeTo').sync('1');
      nextFollowUp && this.removeFollowUp(nextFollowUp);
    } else {
      form.$('rangeFrom').sync(values[0]);
      form.$('rangeTo').sync(values[0]);
    }
  };

  // campaign validation
  @computed get isValid() {
    return this.isFormValid && this.areFollowUpsValid && this.areTextTypeOptionsValid;
  }

  @computed get isFormValid() {
    return this.questionForm.isValid;
  }

  // -- textTypeOptions validation
  @computed get areTextTypeOptionsValid() {
    if (this.questionType !== CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT) {
      return true;
    }
    return every(
      map(this.filteredTextTypeOptions, option => option.isValid()),
      item => item === true
    );
  }

  // -- question follow ups validation
  isFollowUpRangeValid = followUp => {
    const {form} = followUp;
    const values = form.values();
    let {rangeFrom, rangeTo} = values;
    const filteredFollowUps = filter(this.followUps, fu => fu !== followUp);

    rangeFrom = toInteger(rangeFrom);
    rangeTo = toInteger(rangeTo);

    const followUpRanges = map(filteredFollowUps, fu => {
      const values = fu.form.values();
      return {rangeFrom: values.rangeFrom, rangeTo: values.rangeTo};
    });

    return (
      rangeTo <= this.maxRating &&
      (filteredFollowUps.length === 0 ||
        every(
          map(followUpRanges, range => {
            if (isEmpty(range.rangeFrom) || isEmpty(range.rangeTo)) {
              return true;
            }

            range.rangeFrom = toInteger(range.rangeFrom);
            range.rangeTo = toInteger(range.rangeTo);

            const rangeUnderBorder = range.rangeFrom < rangeFrom && range.rangeTo < rangeFrom;
            const rangeOverBorder = range.rangeFrom > rangeTo && range.rangeTo > rangeTo;
            return rangeUnderBorder || rangeOverBorder;
          }),
          item => item === true
        ))
    );
  };

  @computed get areFollowUpsRangesValid() {
    return every(
      map(this.followUps, followUp => this.isFollowUpRangeValid(followUp)),
      item => item === true
    );
  }

  @computed get areFollowUpsValid() {
    return (
      every(
        map(this.followUps, followUp => followUp.isValid(this.minFollowUpRating, this.maxRating)),
        item => item === true
      ) && this.areFollowUpsRangesValid
    );
  }

  // save campaign
  @action submit = async editCampaignMutation => {
    const {title, defaultLocale, question, kind} = this.questionForm.values();
    let textTypeOptionsValues = [];

    // if defaultLocale is changed after providing translations,
    // remove any existing translations with that locale
    this.questionTranslations = omit(this.questionTranslations, defaultLocale);

    this.followUpTransations = this.followUpTranslations.map(followUpTranslation =>
      omit(followUpTranslation, defaultLocale)
    );

    this.filteredTextTypeOptions.forEach((_, index) => {
      if (this.textTypeOptionsTranslations.length <= index + 1) {
        this.textTypeOptionsTranslations[index] = omit(this.textTypeOptionsTranslations[index], defaultLocale);
      }
    });

    const questionTranslationValues = assign({[defaultLocale]: question}, this.questionTranslations);
    const followUpValues = this.followUps.map((followUp, index) => {
      const {question, kind, rangeFrom, rangeTo} = followUp.form.values();
      const questionTranslations = assign({[defaultLocale]: question}, this.followUpTranslations[index]);
      return {
        kind,
        rangeFrom: toInteger(rangeFrom),
        rangeTo: toInteger(rangeTo),
        questionTranslations: questionTranslations
      };
    });

    if (this.isTextQuestionType) {
      textTypeOptionsValues = this.filteredTextTypeOptions.map((item, index) => {
        const {option} = item.form.values();
        return assign({[defaultLocale]: option}, this.textTypeOptionsTranslations[index]);
      });
    }

    const campaign = {
      title,
      defaultLocale,
      question: {
        kind,
        numOfRating: this.maxRating,
        questionTranslations: questionTranslationValues,
        followUps: followUpValues,
        textTypeOptions: textTypeOptionsValues
      }
    };

    this.setLoading(true);

    const editCampaignSuccess = () => {
      const message = this.translations.editSuccess;
      notification.success(message);
    };

    const editCampaignError = () => {
      const message = this.translations.editFailure;
      notification.error(message);
    };

    editCampaignMutation({
      id: this.editedCampaignId,
      campaign
    }).then(editCampaignSuccess, editCampaignError);

    this.stepsStore.goToPreview();
    this.setLoading(false);
  };

  // reset all values
  @action reset = () => {
    this.questionForm.reset();
    this.followUps = [];
    this.followUpTranslations = [];
    this.textTypeOptions = [];
    this.textTypeOptionsTranslations = [];
    this.loading = false;
    this.editedCampaignId = '';
    this.manageTranslationOpen = false;
    this.autofocusFollowUp = false;
    this.stepsStore.reset();
    this.isMobileView = false;
    this.initialValues = {};
  };
}

export default EditCampaignPage;
