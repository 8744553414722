import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {topicCardMetrics} from 'styles/metrics';
import typefaces from 'shared/styles/typefaces';
import {Radio as $Radio} from 'styles/styled-components/form-styled-components';

//components
import $UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';
import $Slug from 'components/Slug';
import $TitleTypePreview from 'components/TopicDetails/TitleTypePreview';

export const BaseTopicDetails = styled.div`
  background: #f5f5f5;
  border-radius: 6px;
  margin: 0 ${topicCardMetrics.detailsPulloutX}px;
  padding: 24px ${topicCardMetrics.detailsPaddingX}px 27px;
  @media (max-width: 720px) {
    margin: 0;
  }
`;

export const TopicDetails = BaseTopicDetails.withComponent('form');

export const StyledUnderlinedTextbox = styled($UnderlinedTextbox)`
  ${typefaces.topicCard.title};
`;

export const StyledSlug = styled($Slug)`
  ${typefaces.topicCard.slug};
`;

export const TitleTypePicker = styled.div`
  ${flex.horizontal};
  padding: 15px 0 31px;
`;

export const StyledTitleTypePreview = styled($TitleTypePreview)`
  flex-shrink: 0;
  width: 188px;
  @media (max-width: 430px) {
    width: 115px;
  }
`;

export const TitleTypePickerOptions = styled.div`
  ${flex.vertical};
  align-items: flex-start;
  margin-left: 20px;
  padding: 8px 0;
`;

export const StyledRadio = styled($Radio)`
  display: block;
  padding: 4px 0;
`;
