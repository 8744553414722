import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

//helpers
import mutationNames from 'api/user/constants';
import {bindField} from 'shared/utils/input-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import AuthForm from 'shared/components/AuthForm';
import ButtonGroup from 'ui-components/ButtonGroup';
import Textbox from 'shared/components/Textbox';

//mutations
import {ForgotPassword} from 'api/user/mutations';

//options
import {forgotPasswordOptions} from 'api/user/mutation-options';

//data
import {graphql} from '@apollo/client/react/hoc/graphql';

//messages
import messages from './messages';

@inject('store')
@graphql(ForgotPassword, forgotPasswordOptions)
@observer
class ForgotPasswordFormComponent extends Component {
  UNSAFE_componentWillMount() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {forgotPasswordPage: page} = store;

    const translations = mapValues(pick(messages, ['requestSuccess']), message => formatMessage(message));

    page.setTranslations(translations);
  }

  componentWillUnmount() {
    const {store} = this.props;
    const {forgotPasswordPage: page} = store;

    page.reset();
  }

  handleEmailChange = event => {
    const {store} = this.props;
    const {forgotPasswordPage: page} = store;
    const {form} = page;

    form.$('email').sync(event.target.value.toLowerCase());
  };

  handleSubmit = event => {
    const {store} = this.props;
    const {forgotPasswordPage: page} = store;

    event.preventDefault();
    page.submitForgotPasswordForm(this.props[mutationNames.FORGOT_PASSWORD]);
  };

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {forgotPasswordPage: page} = store;
    const {form} = page;

    return (
      <AuthForm
        errorNotification={this.renderError()}
        onSubmit={this.handleSubmit}
        title={<FormattedMessage {...messages.title} />}
      >
        <Textbox
          autoFocus
          caption={formatMessage(messages.email)}
          type="email"
          {...bindField(form, 'email', {
            onChangeFn: this.handleEmailChange
          })}
        />
        <ButtonGroup
          style={{marginTop: 24}}
          primary={{
            label: formatMessage(messages.submit),
            disabled: !form.isValid,
            loading: page.loading,
            type: 'submit'
          }}
        />
      </AuthForm>
    );
  }

  renderError() {
    const {store} = this.props;
    const {forgotPasswordPage: page} = store;
    const {errorResponse} = page;

    if (!errorResponse) {
      return null;
    }
    return <FormattedMessage {...messages.requestFailure} />;
  }
}

export default injectIntl(ForgotPasswordFormComponent);
