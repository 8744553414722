import {defineMessages} from 'react-intl';

const messages = defineMessages({
  filterAny: {
    id: 'Combobox.filterAny',
    defaultMessage: 'Any [{total, number}]'
  },
  filterSelectedOptions: {
    id: 'Combobox.filterSelectedOptions',
    defaultMessage: '{total, number}'
  }
});

export default messages;
