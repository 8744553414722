import {defineMessages} from 'react-intl';

const messages = defineMessages({
  activateDescriptionTitle: {
    defaultMessage:
      'After you activate this sign-off, you can apply it to instructions in the guide menu. You will not be able to edit the sign-off content anymore.',
    id: 'signOff.activateDescriptionTitle'
  },
  activateDescriptionSubtitle: {
    defaultMessage: 'Confirm your sign-off is ready to be activated.',
    id: 'signOff.activateDescriptionSubtitle'
  },
  activateFailure: {
    defaultMessage: 'Failed to activate the sign-off',
    id: 'signOff.activateFailure'
  },
  activateSubmit: {
    defaultMessage: 'Activate',
    id: 'common.actions.activate'
  },
  activateSuccess: {
    defaultMessage: 'Sign-off activated',
    id: 'signOff.activateSuccess'
  },
  activateTitle: {
    defaultMessage: 'Activate sign-off',
    id: 'signOff.activateTitle'
  },
  deactivateDescription: {
    defaultMessage:
      'When this sign-off is deactivated, it will be removed from {instructionCount, number} {instructionCount, plural, one {instruction} other {instructions}} across all workspaces in your organization. You will still be able to view the results.',
    id: 'signOff.deactivateDescription'
  },
  deactivateFailure: {
    defaultMessage: 'Failed to deactivate the sign-off',
    id: 'signOff.deactivateFailure'
  },
  continueDeactivateSubmit: {
    defaultMessage: 'Continue',
    id: 'common.actions.continue'
  },
  deactivateSuccess: {
    defaultMessage: 'Sign-off deactivated',
    id: 'signOff.deactivateSuccess'
  },
  deactivateTitle: {
    defaultMessage: 'Deactivate sign-off',
    id: 'signOff.deactivateTitle'
  },
  deactivateDescription0Instructions: {
    defaultMessage: 'When this sign-off is deactivated, you will still be able to view the results.',
    id: 'signOff.deactivateDescription0Instructions'
  }
});

export default messages;
