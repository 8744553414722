import styled from 'styled-components';

import ButtonGroup from 'ui-components/ButtonGroup';

import {commonPalette} from 'shared/styles/palette';

export const ActionWrapper = styled.div`
  display: flex;
  padding: 20px 0;
  flex-direction: column;
`;

export const SectionRow = styled.div`
  ${({header}) => (header ? 'font-weight: 600;' : 'margin: 8px 0;')}
`;

export const Section = styled.div`
  margin-bottom: 10px;
`;

export const SaveButton = styled(ButtonGroup)`
  display: inline-block;
  margin-top: 15px;
`;

export const iconStyles = {
  color: commonPalette.txLightGrey,
  marginLeft: 5,
  fontSize: 16
};
