import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

export const Content = styled.main`
  width: 100%;
  scrollbar-width: none;
`;
