import {stepNoteTypes} from '../constants';

export const isDefaultStepNoteType = type => type && !!stepNoteTypes[type.toUpperCase()];

export const mapIconsToGuide = (guide, icons) => {
  guide.topics.forEach(
    topic =>
      topic.instructions &&
      topic.instructions.forEach(
        instruction =>
          instruction.steps &&
          instruction.steps.forEach(
            step =>
              step.notes &&
              step.notes.forEach(note => {
                icons &&
                  icons.forEach(icon => {
                    if (icon.id === note.iconId) {
                      if (icon.type.toLowerCase() === stepNoteTypes.CUSTOM) {
                        const label = icon.labelTranslations.find(label => label.locale === guide.locale);

                        if (label) {
                          note.type = label.translation;
                        }
                      } else {
                        note.type = icon.type;
                      }

                      note.iconSvg = icon.svg;
                    }
                  });
              })
          )
      )
  );
};
