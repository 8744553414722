import React from 'react';
import {AUTO_TRANSLATE_DIALOGS_MAP, ERRORS_TYPE_MAP} from 'stores/dialogs/auto-translation-dialog';
import TranslationErrorDialogContent from './TranslationErrorDialogContent';
import messages from './messages';

export const getTranslationErrorDialogConfig = ({autoTranslationDialog}, formatMessage) => ({
  title: formatMessage(messages.somethingWentWrong),
  content: <TranslationErrorDialogContent formatMessage={formatMessage} />,
  actionsProps: {
    actions: [
      {
        label: formatMessage(messages.tryAgain),
        onClick: () => {
          const errorType = autoTranslationDialog.getErrorType();

          switch (errorType) {
            case ERRORS_TYPE_MAP.errorTranslatingGuide:
              autoTranslationDialog.startAutoTranslation();
              break;
            case ERRORS_TYPE_MAP.errorAddingNewLanguage:
              autoTranslationDialog.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.addNewLanguage);
              break;

            case ERRORS_TYPE_MAP.errorFetchingLanguages:
              autoTranslationDialog.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.selectLanguage);
              break;

            default:
              autoTranslationDialog.reset();
          }
        }
      }
    ],
    customCancelButtonText: messages.close,
    onCancel: () => {
      autoTranslationDialog.reset();
    }
  }
});
