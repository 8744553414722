import {observable, action, computed, makeObservable} from 'mobx';
import store from 'stores/store';
import {find} from 'lodash';

//helpers
import notification from 'utils/notification-utils';

//store
import PlatformSettingsNoteIconsForm from 'stores/forms/platform-settings-note-icons-form.js';

class InstructionalIconDialog {
  @observable opened = false;
  @observable icon = {};
  @observable loading = false;
  @observable form = PlatformSettingsNoteIconsForm;

  allowVideos = false;
  allowedFormats = null;
  acceptedTypes = ['image/svg+xml'];
  isThemeStylingView = false;
  translations = {};

  @action
  open = ({icon = {}, isThemeStylingView = false}) => {
    this.icon = icon;
    this.updateForm(icon);
    this.opened = true;
    this.isThemeStylingView = isThemeStylingView;
  };

  @action
  reset = () => {
    this.opened = false;
    this.loading = false;
    this.icon = {};
    this.resetForm();
    this.isThemeStylingView = null;
  };

  getLanguageLabel = locale => {
    const {platform} = store;
    const {availableLanguages} = platform;
    const language = find(availableLanguages, {locale});
    return language ? `${language.language}` : '';
  };

  updateForm = (icon = {}) => {
    const {svg = null, labelTranslations: translations} = icon;
    this.form.$('icon').set('value', svg);
    this.form.update({
      translations: store.platform.availableLanguages.map(({locale}) => {
        return find(translations, {locale}) || {locale, translation: ''};
      })
    });
  };

  constructor() {
    makeObservable(this);
  }

  setTranslations(translations) {
    this.translations = translations;
  }

  @action
  resetForm = () => {
    this.form.reset();
  };

  @action
  setIcon = (iconSvg = null) => {
    this.icon = {...this.icon, svg: iconSvg};
    this.form.$('icon').set('value', iconSvg);
  };

  @action
  setLoading = loading => (this.loading = loading);

  @action
  submit = async (mutation, isEditMutation = false, params) => {
    this.setLoading(true);

    const mutationSuccess = () => {
      notification.success(isEditMutation ? this.translations.updateSuccess : this.translations.uploadSuccess);
      this.reset();
    };

    const mutationError = () => {
      notification.error(isEditMutation ? this.translations.updateFailure : this.translations.uploadFailure);
      this.reset();
    };

    mutation(params).then(mutationSuccess).catch(mutationError);
  };

  @computed
  get isValid() {
    const isIconValid = this.form.$('icon').isValid;

    return this.isThemeStylingView
      ? isIconValid
      : isIconValid && this.form.$('translations.0.translation').value.length > 2;
  }
}

export default InstructionalIconDialog;
