import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import views from 'config/views';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import mutationNames from 'api/user/constants';
import {mapValues, pick} from 'lodash';
import {commonPalette} from 'shared/styles/palette';

//styled-components
import {
  UserNameField,
  EmailField,
  Checkbox,
  Caption,
  DivWrapper,
  SSOIdWrapper,
  MainInfoWrapper,
  ssoIconStyle
} from './styles';

//mutations
import {EditUser} from 'api/user/mutations';
import {User} from 'api/user/queries';

//options
import {editUserOptions} from 'api/user/mutation-options';
import {userOptions} from 'api/user/query-options';

//components
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import Container from 'ui-components/Layout/Container';
import DataTable from 'ui-components/Layout/DataTable';
import ButtonGroup from 'ui-components/ButtonGroup';
import Icon from 'ui-components/Icon';

//messages
import messages from './messages';
import {HeaderWrapper, CellWrapper} from 'ui-components/Layout/DataTable/styles';

@inject('store')
@graphql(User, userOptions)
@graphql(EditUser, editUserOptions)
@observer
class EditUserView extends Component {
  componentDidUpdate() {
    const {editUserPage: page} = this.props.store;
    const {user, loading} = this.props.data;

    if (!loading && user && page.editedUserId !== user.id) {
      user && page.startEdit(user);
    }
  }

  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      editUserPage,
      router
    } = store;
    const {canManageUsers, canViewUsers} = user;

    if (!canManageUsers && !canViewUsers) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, [
        'updateSuccess',
        'updateFailure',
        'disableSuccess',
        'disableFailure',
        'enableSuccess',
        'enableFailure'
      ]),
      message => formatMessage(message)
    );

    editUserPage.setTranslations(translations);
  }

  render() {
    const {
      store,
      intl: {formatMessage},
      data: {loading, error, user: editableUser}
    } = this.props;
    const showLoading = loading || error;
    const editUserMutation = this.props[mutationNames.EDIT_USER];
    const {
      editUserPage: page,
      auth,
      platform: {hasSkillsEnabled}
    } = store;
    const {form} = page;
    const userNotFound = !showLoading && !editableUser;
    const canSetPlatformAdmin = auth.user.isPlatformAdmin && !editableUser?.phoneNumber;

    const formValues = form.values();
    if (showLoading)
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );

    if (userNotFound) return <FormattedMessage {...messages.notFound} />;

    return (
      <Container>
        <DivWrapper>
          <MainInfoWrapper>
            <UserNameField
              inline
              form={form}
              field="fullName"
              readOnly={!editableUser.canManageUser}
              onSubmit={() => page.editUser({editUserMutation})}
              onCancel={() => page.resetForm(editableUser)}
            />
            {editableUser.ssoId && (
              <SSOIdWrapper>
                <Icon popoverContent={formatMessage(messages.ssoIdInfo)} style={ssoIconStyle} />
                {`SSO ID: ${editableUser.ssoId}`}
              </SSOIdWrapper>
            )}
          </MainInfoWrapper>
        </DivWrapper>
        <DivWrapper>
          {formValues.email && (
            <EmailField
              inline
              form={form}
              field="email"
              readOnly={!editableUser.canManageUser}
              onSubmit={() => page.editUser({editUserMutation})}
              onCancel={() => page.resetForm(editableUser)}
            />
          )}
          {formValues.phoneNumber && (
            <EmailField
              inline
              form={form}
              field="phoneNumber"
              readOnly={!editableUser.canManageUser}
              onSubmit={() => page.editUser({editUserMutation})}
              onCancel={() => page.resetForm(editableUser)}
            />
          )}
        </DivWrapper>
        {canSetPlatformAdmin && (
          <DivWrapper>
            <Checkbox
              caption={
                <Caption>
                  <FormattedMessage {...messages.platformAdmin} />
                  <Icon
                    popoverContent={formatMessage(messages.setPlatformAdminDescription)}
                    style={{marginLeft: 8, color: commonPalette.txGrey}}
                  />
                </Caption>
              }
              checked={page.isPlatformAdmin}
              onChange={() => page.setPlatformAdmin({editUserMutation})}
              dataCy="checkbox-platform-admin"
            />
          </DivWrapper>
        )}
        <DivWrapper>
          <DataTable
            data={editableUser.teams}
            noDataMessage={formatMessage(messages.noTeams)}
            defaultSorted={[
              {
                id: 'team.name',
                desc: false
              }
            ]}
            columns={[
              {
                Header: <HeaderWrapper>{formatMessage(messages.team)}</HeaderWrapper>,
                accessor: 'team.name',
                resizable: true,
                Cell: ({value}) => (
                  <CellWrapper>
                    <span title={value}>{value}</span>
                  </CellWrapper>
                )
              },
              {
                Header: <HeaderWrapper>{formatMessage(messages.role)}</HeaderWrapper>,
                accessor: 'role',
                Cell: ({value}) => (
                  <CellWrapper>
                    <span title={value}>{value}</span>
                  </CellWrapper>
                )
              },
              hasSkillsEnabled && {
                Header: <HeaderWrapper>{formatMessage(messages.jobTitle)}</HeaderWrapper>,
                accessor: 'skillRole.name',
                Cell: ({value}) => (
                  <CellWrapper>
                    <span title={value}>{value}</span>
                  </CellWrapper>
                )
              }
            ].filter(Boolean)}
            style={{marginTop: 40}}
            hoverEffect
          />
        </DivWrapper>
        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          secondary={{
            label: formatMessage(messages.backToOverview),
            onClick: () => store.router.goTo(views.users, {})
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(EditUserView);
