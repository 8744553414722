import htmlToPdfmake from 'html-to-pdfmake';

import {generateImage} from './image';
import {getMediaNotAvailable} from './mediaNotAvailable';

import {
  alignmentType,
  boldBorderLayout,
  noBorderLayout,
  oneGrayBorderLayout,
  stepNoteTypes,
  defaultHtmlLinksStyles
} from '../constants';
import {
  makeListVisible,
  colorSvg,
  isAImage,
  isAGif,
  isAVideo,
  getMediaType,
  isDefaultStepNoteType,
  hasMeaningfulHtml
} from '../utils';

export const generateStepNote = async (note, pdfMessages, additionalLinkInfo, guideBaseUrl, intl) => {
  const {contentHtml, media: mediaType, type = '', mandatory, iconSvg} = note;

  if (!hasMeaningfulHtml(contentHtml) || !iconSvg) {
    return;
  }

  const isDefaultType = isDefaultStepNoteType(type);
  const isWarning = type.toLowerCase() === stepNoteTypes.WARNING;

  const infoContent = [
    {
      text: isDefaultType ? pdfMessages[type.toLowerCase()] : type,
      bold: true,
      margin: [isWarning ? -35 : 0, isWarning ? -25 : 2, 0, 0],
      fontSize: 10
    }
  ];

  if (mediaType) {
    let media;

    const isNotSupportedMedia = isAGif(mediaType.url) || isAVideo(mediaType.url);
    const hasOverlays = mediaType.metadata && mediaType.metadata.overlays;

    if (isNotSupportedMedia || hasOverlays) {
      const typeOfMedia = getMediaType(mediaType);

      media = await getMediaNotAvailable(additionalLinkInfo, pdfMessages, {isWarning}, guideBaseUrl, intl, typeOfMedia);
    } else if (isAImage(mediaType.url)) {
      media = {
        image: await generateImage(mediaType.url, mediaType.metadata),
        width: isWarning ? 170 : 150,
        alignment: alignmentType.LEFT,
        margin: [isWarning ? -35 : 0, 5, 0, 5]
      };
    }

    infoContent.push(media);
  }

  const infoContentText = htmlToPdfmake(contentHtml, {defaultStyles: defaultHtmlLinksStyles});

  // Lists are not displayed so this is a hack to show them
  makeListVisible(infoContentText);

  infoContent.push({
    text: infoContentText,
    margin: isWarning ? [-35, -15, 0, 20] : [0, -15, 0, 0],
    fontSize: 10
  });

  const table = {
    headerRows: 0,
    body: [[{columns: [infoContent]}]],
    widths: isWarning ? [140] : [190]
  };

  return {
    table: {
      headerRows: 0,
      body: [
        [
          {
            columns: [
              {
                svg: colorSvg(iconSvg, isWarning),
                width: isWarning ? 23 : 17,
                margin: [isWarning ? -10 : -13, isWarning ? -10 : 0, 0, 0]
              },
              {table, layout: isWarning ? noBorderLayout : oneGrayBorderLayout, margin: [isWarning ? 20 : -5, 0, 0, 0]}
            ]
          }
        ]
      ]
    },
    unbreakable: true,
    layout: isWarning ? boldBorderLayout : noBorderLayout,
    margin: [0, 10, 0, mandatory ? 20 : 0]
  };
};
