import {defineMessages} from 'react-intl';

const messages = defineMessages({
  signoffs: {
    id: 'Menu.signoffs',
    defaultMessage: 'Sign-offs'
  },
  signoffsNew: {
    id: 'Menu.guideSignoffs',
    defaultMessage: 'Guide Sign-offs'
  },
  campaignSubtitle: {
    id: 'SignOff.campaignSubtitle',
    defaultMessage:
      'Ask your users to "sign-off" or provide feedback after viewing an instruction. You can apply a sign-off to any guide or instruction.'
  },
  extraCampaignSubtitle: {
    id: 'SignOff.extraCampaignSubtitle',
    defaultMessage: 'Go to the guide menu to apply active sign-offs.'
  },
  noSignOffs: {
    id: 'SignOff.notFound',
    defaultMessage: 'No Sign-offs found in the system.'
  },
  searchByTitle: {
    id: 'SignOff.searchByTitle',
    defaultMessage: 'Search by title'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  }
});

export default messages;
