import styled from 'styled-components';

import Button from 'ui-components/Button';
import Icon from 'ui-components/Icon';

import {commonPalette} from 'shared/styles/palette';

const GuideApprovalSelectorContainer = styled.div`
  > * + * {
    margin-top: 20px;
  }
`;

const ApprovalRequirementCheckboxCaptionContainer = styled.div`
  color: ${commonPalette.txBlack};

  .explanation {
    color: ${commonPalette.txGrey};
  }
`;

const ApprovalRequirementCheckboxContainer = styled.div`
  display: block;

  > .approval-requirement--checkbox {
    height: auto;

    > [class*='ClickableArea'] {
      align-items: start;
    }
  }
`;

const ApproverSelectionContainer = styled.div`
  padding-left: 31px;

  > * + * {
    margin-top: 20px;
  }
`;

const ApproverSelectorsContainer = styled.div`
  > * + * {
    margin-top: 20px;
  }
`;

const AddAproverButton = styled(Button)`
  background-color: ${commonPalette.white};
  color: ${commonPalette.sgGreen};
  text-transform: none;

  &:disabled {
    background-color: ${commonPalette.white};
    color: ${commonPalette.txLightGrey};
  }

  &:not(:disabled):hover,
  &:focus {
    background-color: ${commonPalette.white};
  }
`;

const SelectApprovalOrderCheckboxContainer = styled.div`
  display: flex;
  margin: 20px 0 20px 30px;
  gap: 12px;
`;

const StyledIcon = styled(Icon).attrs({
  style: {
    color: commonPalette.txGrey,
    marginTop: 3
  }
})``;

export {
  GuideApprovalSelectorContainer,
  ApprovalRequirementCheckboxCaptionContainer,
  ApprovalRequirementCheckboxContainer,
  ApproverSelectionContainer,
  ApproverSelectorsContainer,
  AddAproverButton,
  SelectApprovalOrderCheckboxContainer,
  StyledIcon
};
