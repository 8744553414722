import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

import Select from 'ui-components/Select';
import Field from 'ui-components/Field';

//components
import TeamPicker from 'components/TeamPicker';
import {FormattedMessage} from 'components/FormattedComponents';
import Spinner from 'shared/components/Spinner';

//helpers
import {styleable} from 'shared/decorators';

//messages
import messages from './messages';
import {SpinnerWrapper} from './styles';

@inject('store')
@observer
@styleable
class ReassignGuide extends Component {
  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {reassignGuideDialog} = store;
    if (reassignGuideDialog.selectedTeamId) {
      reassignGuideDialog.setSelectedTeamId(reassignGuideDialog.selectedTeamId);
    }
  }

  componentWillUnmount() {
    const {
      store: {reassignGuideDialog}
    } = this.props;
    reassignGuideDialog.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      comment,
      onScrollToBottom,
      onSearch
    } = this.props;
    const {reassignGuideDialog: dialog, auth} = store;
    const {teams, filteredUsers, selectedTeamId, setSelectedTeamId, newAssigneeId, loading, guideAssigneeId} = dialog;
    const {user} = auth;

    const doesGuideBelongToEditor = guideAssigneeId === user.id;

    // only admins or editors that own the guide can reassign the guide
    const showTeamPicker = doesGuideBelongToEditor || (user?.isTeamAdmin && teams?.length > 0);
    const userOptions = filteredUsers?.map(user => ({id: user.id, name: user.fullName}));

    if (loading) {
      return (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      );
    }

    if (!loading && !showTeamPicker) {
      return (
        <div>
          <FormattedMessage {...messages.noTeamsAvailable} />
        </div>
      );
    }

    if (comment) {
      dialog.setComment(comment);
    }

    return (
      <div>
        <Field
          inputComponent={
            <TeamPicker
              allowClear
              label={formatMessage(messages.team)}
              onChange={setSelectedTeamId}
              onSearch={onSearch}
              selectedValue={selectedTeamId}
              teams={teams.toJSON()}
              dataCy="team-picker"
              onScrollToBottom={onScrollToBottom}
            />
          }
        />
        {userOptions?.length > 0 && (
          <Field
            inputComponent={
              <Select
                showSearch
                allowClear
                label={formatMessage(messages.assignee)}
                onChange={dialog.selectPerson}
                options={userOptions}
                placeholder={formatMessage(messages.selectPersonPlaceholder)}
                selectedValue={newAssigneeId}
              />
            }
          />
        )}

        <Field
          title={formatMessage(messages.comments)}
          styles={{Input: {height: '120px', padding: '10px 16px'}}}
          inputType="textarea"
          defaultValue={comment}
          onChange={dialog.addComment}
        />
      </div>
    );
  }
}

export default injectIntl(ReassignGuide);
