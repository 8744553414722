import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Dialog from 'ui-components/Dialog';

import messages from './messages';

@inject('store')
@injectIntl
@observer
class ConfirmDeactivateDialog extends Component {
  render() {
    const {
      intl: {formatMessage},
      store: {
        confirmDeactivateDialog: {opened, submit, cancel}
      }
    } = this.props;

    const actions = [{label: formatMessage(messages.deactivate), onClick: submit}];

    return (
      <Dialog
        actions={actions}
        isOpen={opened}
        onCancel={cancel}
        title={formatMessage(messages.title)}
        titleIconId="warning"
      >
        {formatMessage(messages.subtitle)}
      </Dialog>
    );
  }
}

export default ConfirmDeactivateDialog;
