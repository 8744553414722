import gql from 'graphql-tag';

export const MediaGallery = gql`
  query media($guideId: String, $instructionId: String, $themeMediaType: String, $savedMediaIds: [String]) {
    media(
      guideId: $guideId
      instructionId: $instructionId
      themeMediaType: $themeMediaType
      savedMediaIds: $savedMediaIds
    ) {
      id
      mediaResource {
        id
        url
      }
      metadata
      fileName
      dateCreated
      occurence
      url
    }
  }
`;

export const MediaGalleryPaginated = gql`
  query mediaPaginated(
    $guideId: String
    $instructionId: String
    $themeMediaType: String
    $savedMediaIds: [String]
    $cursor: String
    $limit: Int
  ) {
    mediaPaginated(
      guideId: $guideId
      instructionId: $instructionId
      themeMediaType: $themeMediaType
      savedMediaIds: $savedMediaIds
      cursor: $cursor
      limit: $limit
    ) {
      cursor
      results {
        id
        mediaResource {
          id
          url
        }
        metadata
        fileName
        dateCreated
        occurence
        url
      }
    }
  }
`;
