import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import {Wrapper, StyledStickySidebar, MainColumn, TitleWrapper} from './styles';
import ViewTitle from 'ui-components/Layout/ViewTitle';

@inject('store')
@observer
class LeftMenuWrapper extends Component {
  render() {
    const {
      store,
      children,
      nav,
      title,
      navTitle,
      collapsed,
      navBarHidden = false,
      navBarPadding = true,
      padding = true,
      onClick,
      canCollapse
    } = this.props;
    const {app} = store;
    const {hasSubmenu} = app;

    return (
      <Wrapper canCollapse={canCollapse} collapsed={collapsed}>
        {!navBarHidden && (
          <StyledStickySidebar
            level={hasSubmenu ? 'third' : 'second'}
            isOpen={!collapsed}
            onClick={onClick}
            padding={navBarPadding}
            className={'LeftMenuWrapper__NavColumn'}
          >
            {collapsed && navTitle && (
              <TitleWrapper>
                <h3>{navTitle}</h3>
              </TitleWrapper>
            )}
            {!collapsed && <div>{nav}</div>}
          </StyledStickySidebar>
        )}
        <MainColumn onClick={!collapsed && onClick} padding={padding} className={'LeftMenuWrapper__MainColumn'}>
          {title && <ViewTitle title={title} />}

          {children}
        </MainColumn>
      </Wrapper>
    );
  }
}

export default LeftMenuWrapper;
