import {defineMessages} from 'react-intl';

const messages = defineMessages({
  teamDetails: {
    id: 'teamDetails',
    defaultMessage: 'Team Details'
  },
  users: {
    id: 'setRolesForTeam',
    defaultMessage: 'Users'
  },
  guideApproval: {
    id: 'guideApproval',
    defaultMessage: 'Guide Approval'
  },
  notifications: {
    id: 'notifications',
    defaultMessage: 'Notifications'
  },
  workspaces: {
    id: 'connectedWorkspaces',
    defaultMessage: 'Workspaces'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  backToTeams: {
    id: 'backToTeams',
    defaultMessage: 'Back to Teams'
  },
  teamUpdateSuccess: {
    id: 'teamHasBeenUpdatedSuccess',
    defaultMessage: 'The team has been updated'
  },
  teamUpdateFailure: {
    id: 'teamUpdateFailure',
    defaultMessage: 'Error while updating the team'
  }
});

export default messages;
