import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  empty: {
    id: 'DataTable.empty',
    defaultMessage: 'No data'
  }
});

export default messages;
