import {defineMessages} from 'react-intl';

const messages = defineMessages({
  guideOverview: {
    id: 'MediaPreview.guideOverview',
    defaultMessage: 'Guide overview'
  },
  instructionOverview: {
    id: 'MediaPreview.instructionOverview',
    defaultMessage: 'Instruction overview'
  },
  step: {
    id: 'MediaPreview.step',
    defaultMessage: 'Step (scaled)'
  },
  stepOverview: {
    id: 'MediaPreview.stepOverview',
    defaultMessage: 'Step overview'
  },
  instructionCover: {
    id: 'MediaPreview.instructionCover',
    defaultMessage: 'Instruction cover (scaled)'
  },
  fullImage: {
    id: 'MediaPreview.fullImage',
    defaultMessage: 'Full image'
  },
  guideTitle: {
    id: 'MediaPreview.guideTitle',
    defaultMessage: 'Guide title'
  },
  instructionTitle: {
    id: 'MediaPreview.instructionTitle',
    defaultMessage: 'Instruction title'
  },
  stepContent: {
    id: 'MediaPreview.stepContent',
    defaultMessage: 'Step content'
  },
  notSelected: {
    id: 'MediaPreview.notSelected',
    defaultMessage: 'No image selected'
  },
  viewer: {
    id: 'MediaPreview.viewer',
    defaultMessage: 'Viewer'
  },
  type: {
    id: 'MediaPreview.type',
    defaultMessage: 'Type'
  }
});

export default messages;
