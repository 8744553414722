import {defineMessages} from 'react-intl';

const messages = defineMessages({
  account: {
    id: 'Header.account',
    defaultMessage: 'Account'
  },
  logout: {
    id: 'Header.logout',
    defaultMessage: 'Log out'
  },
  stopImpersonating: {
    id: 'Header.stopImpersonating',
    defaultMessage: 'Stop Impersonating'
  },
  goesOffline: {
    id: 'goesOffline',
    defaultMessage: "You're offline. Check your internet connection."
  },
  goesBackOnline: {
    id: 'goesBackOnline',
    defaultMessage: "You're back online!"
  },
  privacy: {
    id: 'privacy',
    defaultMessage: 'Privacy'
  }
});

export default messages;
