import React, {Component} from 'react';
import {StatusCircle, SkillCardTitle, SkillCardStatus, SkillCard} from './styles';
import SkillStatusLabel, {UserSkillStatus} from 'components/SmartsSkillsStatusLabel';

const hasStarted = status =>
  [UserSkillStatus.APPROVED, UserSkillStatus.COMPLETED, UserSkillStatus.IN_PROGRESS].includes(status);

class SkillCardComponent extends Component {
  render() {
    const {skill} = this.props;
    return (
      <SkillCard>
        <div>
          <StatusCircle color={'#009d9a'} fill={hasStarted(skill?.status)} />
        </div>
        <div style={{overflow: 'hidden'}}>
          <SkillCardTitle>{skill?.name}</SkillCardTitle>
          <SkillCardStatus>
            <SkillStatusLabel status={skill.status} />
          </SkillCardStatus>
        </div>
      </SkillCard>
    );
  }
}

export default SkillCardComponent;
