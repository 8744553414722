import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import moment from 'moment';

import Container from 'ui-components/Layout/Container';
import CampaignAnswer from 'components/CampaignAnswer';
import CampaignFollowUpAnswer from 'components/CampaignFollowUpAnswer';
import {getFollowUpQuestionText} from './utils';

import Spinner from 'shared/components/Spinner';

import {CampaignAnswer as CampaignAnswerQuery} from 'api/campaign/queries';
import {campaignAnswerOptions} from 'api/campaign/query-options';

import {DATE_TIME_FORMAT} from 'config/constants';

import {renderWithEmptyFallBack, addEmptyValueSymbol} from 'utils/campaign-answer-utils';

import views from 'config/views';

import messages from './messages';
import {
  BackToCampaignResultsWrapper,
  BackIcon,
  WrapperText,
  TitleWrapper,
  SectionWrapper,
  Row,
  StyledErrorText,
  CellLeft,
  CellRight,
  RowSection
} from './styles';

@inject('store')
@graphql(CampaignAnswerQuery, campaignAnswerOptions)
@observer
class CampaignResultDetailsView extends Component {
  UNSAFE_componentWillMount = () => {
    const {
      intl: {formatMessage},
      store: {signoffResultsPage}
    } = this.props;

    const translations = mapValues(
      pick(messages, ['campaign_action_yes', 'campaign_action_no', 'campaign_action_pass', 'campaign_action_fail']),
      message => formatMessage(message)
    );

    signoffResultsPage.setTranslations(translations);
  };

  onBackToCampaignResultsClick = () => {
    const {store} = this.props;
    const {
      router: {goTo}
    } = store;

    goTo(views.campaignsResults, {});
  };

  render() {
    const {
      intl: {formatMessage},
      campaignAnswerQuery: {campaignAnswer, error, loading},
      store: {
        signoffResultsPage: {translations},
        platform: {getLanguage}
      }
    } = this.props;

    const backToCampaignResults = (
      <BackToCampaignResultsWrapper onClick={this.onBackToCampaignResultsClick}>
        <BackIcon />
        <WrapperText>{formatMessage(messages.backToCampaignResults)}</WrapperText>
      </BackToCampaignResultsWrapper>
    );

    if (loading) {
      return (
        <Container>
          {backToCampaignResults}
          <Spinner centerAbs centerX centerY />
        </Container>
      );
    }

    if (error) {
      return (
        <Container>
          {backToCampaignResults}
          <StyledErrorText>{formatMessage(messages.error)}</StyledErrorText>
        </Container>
      );
    }

    const {
      user: submittedName,
      loggedInUser,
      submittedAt: dateAndTime,
      guide: {
        title: guideTitle,
        ownedBy: {name: team}
      },
      instruction: {title: instructionTitle},
      campaign,
      answer,
      followUpAnswer,
      followUpQuestion,
      domain
    } = addEmptyValueSymbol(campaignAnswer);
    const {title: campaignTitle, defaultLocale, question} = campaign;

    const followUpQuestionText = getFollowUpQuestionText({campaignAnswer, locale: getLanguage()});

    return (
      <Container>
        {backToCampaignResults}
        <TitleWrapper data-testid={'signoff-answer-details-page-title'}>{formatMessage(messages.title)}</TitleWrapper>
        <SectionWrapper>
          <RowSection>
            <Row>
              <CellLeft>{formatMessage(messages.submittedName)}</CellLeft>
              <CellRight data-testid={'signoff-answer-submitted-name'}>{submittedName}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.loggedInUser)}</CellLeft>
              <CellRight>{renderWithEmptyFallBack(get(loggedInUser, 'fullName'))}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.ssoId)}</CellLeft>
              <CellRight>{renderWithEmptyFallBack(get(loggedInUser, 'ssoId'))}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.team)}</CellLeft>
              <CellRight data-testid={'signoff-answer-team'}>{team}</CellRight>
            </Row>
          </RowSection>

          <RowSection>
            <Row>
              <CellLeft>{formatMessage(messages.dateAndTime)}</CellLeft>
              <CellRight>{moment(dateAndTime).format(DATE_TIME_FORMAT)}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.workspace)}</CellLeft>
              <CellRight>{renderWithEmptyFallBack(get(domain, 'name'))}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.guide)}</CellLeft>
              <CellRight>{guideTitle}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.instruction)}</CellLeft>
              <CellRight data-testid={'signoff-answer-instruction-title'}>{instructionTitle}</CellRight>
            </Row>
          </RowSection>

          <RowSection>
            <Row>
              <CellLeft>{formatMessage(messages.signoff)}</CellLeft>
              <CellRight>{campaignTitle}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.question)}</CellLeft>
              <CellRight data-testid={'signoff-answer-question'}>
                {question.questionTranslations[defaultLocale]}
              </CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.answer)}</CellLeft>
              <CellRight>
                <CampaignAnswer answer={answer} campaign={campaign} translations={translations} />
              </CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.followUpQuestion)}</CellLeft>
              <CellRight data-testid={'signoff-answer-follow-up-question'}>{followUpQuestionText}</CellRight>
            </Row>
            <Row>
              <CellLeft>{formatMessage(messages.answer)}</CellLeft>
              <CellRight>
                <CampaignFollowUpAnswer followUpAnswer={followUpAnswer} followUpQuestion={followUpQuestion} />
              </CellRight>
            </Row>
          </RowSection>
        </SectionWrapper>
      </Container>
    );
  }
}

export default injectIntl(CampaignResultDetailsView);
