import React from 'react';
import styled from 'styled-components';

export const swallowingStyled = (WrappedComponent, {swallowProps = []} = {}) => {
  const Wrapper = ({children, _ref, ...props}) => {
    swallowProps.forEach(propName => {
      delete props[propName];
    });
    return (
      <WrappedComponent ref={_ref} {...props}>
        {children}
      </WrappedComponent>
    );
  };
  return styled(Wrapper);
};
