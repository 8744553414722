import {useEffect} from 'react';

const resetHighlight = (element: HTMLElement) => {
  element.classList.add('link-highlight');
  element.style.animation = 'none';
  element.offsetHeight; // Trigger reflow so that the animation can restart.
  element.style.animation = '';
};

export const useScrollToAnchor = (anchor: string) => {
  useEffect(() => {
    if (!anchor) {
      return;
    }
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth'
      });
      resetHighlight(element);
    }
  }, [anchor]);
};
