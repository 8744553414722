import React, {Component} from 'react';
import {groupBySkillSet} from 'views/SmartSkillsUser/utils';
import views from 'config/views';
import {inject} from 'mobx-react';
import {
  NextIcon,
  Pagination,
  PrevIcon,
  RotatedHeader,
  StatusIndicator,
  Name,
  Role,
  SkillsetDropdownIcon,
  Table,
  Td,
  Th,
  StickyTh,
  Tr,
  TrCollapsable,
  StickyHeader,
  NotAssignedIcon
} from './styles';

import Icon from 'ui-components/Layout/Icon';
import messages from './messages';
import {injectIntl} from 'react-intl';

@inject('store')
class SmartSkillsTeamSkillMatrix extends Component {
  allUsers = [];
  state = {
    pagination: {
      currentPage: 0,
      pageSize: 8
    },
    isLoading: false,
    skillSetCollapsed: {}, // { skillSetId: true|false }
    groupedBySkillSet: {}
  };

  onClickUser = userId => {
    const router = this.props.store.router;

    router.goTo(views.smartSkillsUser, {teamId: router.params.teamId, userId});
  };

  toggleSkillSet = skillSetId => {
    this.setState(previousState => ({
      skillSetCollapsed: {
        ...previousState.skillSetCollapsed,
        [skillSetId]: !previousState.skillSetCollapsed[skillSetId]
      }
    }));
  };

  getRoleByUser(id) {
    const {userSkillRoles} = this.props;
    const roleName = userSkillRoles.find(role => role.user.id === id)?.skillRole?.name;

    return roleName ? <Role title={roleName}>{roleName}</Role> : null;
  }

  // TODO add expiring icon
  getStatusBySkillUser(skill, user) {
    const skillUser = skill.users.find(u => u.user.id === user.id);

    if (!skillUser) {
      return (
        <StatusIndicator variant="grey">
          <NotAssignedIcon />
        </StatusIndicator>
      );
    }

    if (skillUser.status === 'APPROVED') {
      return (
        <StatusIndicator variant="green">
          <Icon name={'checkmark'} size={14} />
        </StatusIndicator>
      );
    }

    return (
      <StatusIndicator variant="red" style={{fontWeight: 'bold'}}>
        !
      </StatusIndicator>
    );
  }

  /**
   * Sum up all approved skills for each user in the skill
   * @param skill
   * @returns {number}
   */
  calculateApprovedCountBySkill(skill) {
    return skill.users.filter(u => u.status === 'APPROVED').length;
  }

  /**
   * Sum up all NON-approved skills for each user in the skill
   * @param skill
   * @returns {number}
   */
  calculateGapCountBySkill(skill) {
    return skill.users.length - this.calculateApprovedCountBySkill(skill);
  }

  /**
   * Sum up all approved skills for each user in the skill set
   * @param skills
   * @returns {number}
   */
  calculateApprovedCountBySkillSet(skills) {
    return skills.reduce((acc, skill) => acc + this.calculateApprovedCountBySkill(skill), 0);
  }

  /**
   * Sum up all NON-approved skills for each user in the skill set
   * @param skills
   * @returns {number}
   */
  calculateGapCountBySkillSet(skills) {
    return skills.reduce((acc, skill) => acc + this.calculateGapCountBySkill(skill), 0);
  }

  nextPage() {
    this.setState(previousState => ({
      pagination: {
        ...previousState.pagination,
        currentPage: previousState.pagination.currentPage + 1
      }
    }));
  }

  prevPage() {
    this.setState(previousState => ({
      pagination: {
        ...previousState.pagination,
        currentPage: previousState.pagination.currentPage - 1
      }
    }));
  }

  renderPagination() {
    const {
      intl: {formatMessage}
    } = this.props;
    const isPrevDisabled = this.state.pagination.currentPage === 0;
    const isNextDisabled =
      this.state.pagination.currentPage === Math.ceil(this.allUsers.length / this.state.pagination.pageSize) - 1;

    return (
      <Pagination>
        <span>
          {this.state.pagination.currentPage + 1}/{Math.ceil(this.allUsers.length / this.state.pagination.pageSize)}
        </span>
        <button title={formatMessage(messages.prevPage)} disabled={isPrevDisabled} onClick={() => this.prevPage()}>
          <PrevIcon></PrevIcon>
        </button>
        <button title={formatMessage(messages.nextPage)} disabled={isNextDisabled} onClick={() => this.nextPage()}>
          <NextIcon></NextIcon>
        </button>
      </Pagination>
    );
  }

  getSkillProfiles(teamSkillMatrix, skillId) {
    const matrix = teamSkillMatrix.find(skill => skill.id === skillId);
    return matrix.skillProfiles;
  }

  render() {
    const {
      intl: {formatMessage},
      teamSkillMatrix,
      store
    } = this.props;
    const {skillProfilesDialog} = store;
    const {open} = skillProfilesDialog;
    const groupedBySkillSet = groupBySkillSet(teamSkillMatrix);

    // Get all unique sorted users existing in a team skill matrix response
    this.allUsers = Object.values(groupedBySkillSet)
      .map(({skills}) => skills.map(({users}) => users))
      .flat()
      .flat()
      .filter((user, index, self) => self.findIndex(u => u.user.id === user.user.id) === index)
      .map(({user}) => user)
      .sort((a, b) => a.fullName.localeCompare(b.fullName));

    let showPagination = false;
    let paginatedUsers = this.allUsers;

    if (this.allUsers.length > this.state.pagination.pageSize) {
      showPagination = true;
      paginatedUsers = this.allUsers.slice(
        this.state.pagination.pageSize * this.state.pagination.currentPage,
        this.state.pagination.pageSize * (this.state.pagination.currentPage + 1)
      );
    }

    if (paginatedUsers.length < this.state.pagination.pageSize) {
      const diff = this.state.pagination.pageSize - paginatedUsers.length;

      const usersToUnshift = this.allUsers.slice(
        -this.state.pagination.pageSize,
        -this.state.pagination.pageSize + diff
      );
      paginatedUsers.unshift(...usersToUnshift);
    }

    return (
      <Table>
        <StickyHeader>
          <Tr>
            <Th left width="240px" style={{minWidth: '240px'}}>
              {showPagination && this.renderPagination()}
            </Th>
            {paginatedUsers.map(user => (
              <StickyTh hover left width="30px" onClick={() => this.onClickUser(user.id)} key={user.id}>
                <RotatedHeader>
                  <Name title={user.fullName}>{user.fullName}</Name>
                  {this.getRoleByUser(user.id)}
                </RotatedHeader>
              </StickyTh>
            ))}
            <StickyTh width="60px">{formatMessage(messages.statsApproved)}</StickyTh>
            <StickyTh width="30px">{formatMessage(messages.gap)}</StickyTh>
          </Tr>
        </StickyHeader>
        {Object.values(groupedBySkillSet).map(({skillSet, skills}) => (
          <tbody key={skillSet.id}>
            <TrCollapsable>
              <Td colSpan={paginatedUsers.length + 1} onClick={() => this.toggleSkillSet(skillSet.id)}>
                <SkillsetDropdownIcon open={this.state.skillSetCollapsed[skillSet.id]} />
                {skillSet.name}
              </Td>
              <Td>{this.calculateApprovedCountBySkillSet(skills)}</Td>
              <Td>{this.calculateGapCountBySkillSet(skills)}</Td>
            </TrCollapsable>
            {skills.map(skill => (
              <Tr key={skill.id} style={{display: this.state.skillSetCollapsed[skillSet.id] ? 'none' : 'table-row'}}>
                <Td
                  cursor={'pointer'}
                  onClick={() => open(skill.name, this.getSkillProfiles(teamSkillMatrix, skill.id))}
                  align="left"
                >
                  {skill.name}
                </Td>
                {paginatedUsers.map(user => (
                  <Td key={user.id}>{this.getStatusBySkillUser(skill, user)}</Td>
                ))}
                <Td>{this.calculateApprovedCountBySkill(skill)}</Td>
                <Td>{this.calculateGapCountBySkill(skill)}</Td>
              </Tr>
            ))}
          </tbody>
        ))}
      </Table>
    );
  }
}

export default injectIntl(SmartSkillsTeamSkillMatrix);
