import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';

//components
import Dialog from 'ui-components/Dialog';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@observer
class UnsavedChangesDialog extends Component {
  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {unsavedChangesDialog: dialog} = store;
    const {isSaving} = dialog;

    return (
      <Dialog
        actions={() => [
          {
            label: isSaving ? '' : formatMessage(messages.save),
            disabled: isSaving,
            loading: isSaving,
            onClick: dialog.save
          },
          {
            label: formatMessage(messages.discard),
            design: 'text',
            onClick: dialog.discard
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.cancel}
        title={formatMessage(messages.title)}
        titleIconId="warning"
      >
        <FormattedMessage {...messages.description} />
      </Dialog>
    );
  }
}

export default injectIntl(UnsavedChangesDialog);
