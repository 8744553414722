import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createFirstInstruction: {
    id: 'Topics.createFirst',
    defaultMessage: 'Create the first instruction'
  },
  createFirstTopic: {
    id: 'createFirstTopic',
    defaultMessage: 'Create the first topic'
  },
  createFirstChecklist: {
    id: 'createFirstChecklist',
    defaultMessage: 'Create the first checklist'
  },
  empty: {
    id: 'Topics.empty',
    defaultMessage: 'Nothing here yet!'
  },
  olderVerionHint: {
    id: 'EditGuide.olderVerionHint',
    defaultMessage:
      'You are currently viewing an older version of your guide. You can use it as a draft or publish it from this point of time'
  },
  viewDraftHint: {
    id: 'EditGuide.viewDraftHint',
    defaultMessage:
      'You can view the draft and previous version(s) of a guide by clicking on the "Live version" label above'
  },
  viewLiveHint: {
    id: 'EditGuide.viewLiveHint',
    defaultMessage:
      'You can view the live and previous version(s) of a guide by clicking on the "Draft version" label above'
  },
  reviewVersionHint: {
    id: 'EditGuide.reviewVersionHint',
    defaultMessage:
      'Your request to publish this Guide is pending approval. You can keep working on your draft while the submitted version is under review.'
  }
});

export default messages;
