import styled from 'styled-components';

//helpers
import {hintToolbarHeight} from 'shared/styles/vars';
import {scrollbarWidth} from 'shared/utils/dom-utils';
import flex from 'styles/flex';
import {commonPalette} from 'shared/styles/palette';

//components
import $CardToolbar from 'components/CardToolbar';
import $DragHandle from 'components/DragHandle';
import $Editor from 'components/Editor';
import $Icon from 'ui-components/Layout/Icon';
import $MediaPicker from 'components/MediaPicker';
import $FadingOverflow from 'shared/components/FadingOverflow';
import $ResponsiveFontSize from 'shared/components/ResponsiveFontSize';

const hintContentMarginRem = 2.5;
const editorToolbarLeft = 24;

//constants
export const HINT_BUTTON_COLOR = 'rgba(0, 0, 0, 0.2)';
export const HINT_BUTTON_WITH_CONTENT_COLOR = commonPalette.darkGreen;

export const SwipeableContainerSlide = styled.div.attrs(p => ({
  style: {
    height: p.layout.slideHeight,
    padding: `0 ${p.layout.cardSpacing / 2}px`,
    width: p.layout.slideWidth
  }
}))`
  transition: 0.5s opacity ease-out;

  ${p =>
    p.isGhostly &&
    `
    background: ${commonPalette.ghostlyGreen};
    filter: blur(4px);
  `}

  ${p =>
    p.isTransparent &&
    `
    opacity: 0.3;
  `}
`;

export const InnerWrapper = styled.div`
  height: 100%;
  position: relative;

  ${p =>
    p.isInvisible &&
    `
    // NB. In Microsoft Edge, opacity won't be applied
    // to its absolute-positioned children unless the
    // element is positioned itself

    opacity: 0;
  `}

  .stepNumber {
    z-index: 2;
  }
`;

export const StyledDragHandle = styled($DragHandle)`
  height: 88px;
  position: absolute;
  right: 100%;
  top: 0;
  width: ${p => p.cardSpacing / 2}px;
`;

export const StyledFadingOverflow = styled($FadingOverflow)`
  ${flex.allowOverflowY};
  flex-grow: 1;
  overflow: visible;

  .FadingOverlay__InnerWrapper {
    ${flex.vertical};
    height: 100%;
    overflow-y: scroll; // to keep scrollbar width contant for the pencil icon
  }
`;

export const StyledResponsiveFontSize = styled($ResponsiveFontSize)`
  ${flex.vertical};
  flex-grow: 1;
  flex-shrink: 0; // IE11
`;

const BaseEditor = styled($Editor)`
  .rdw-editor-toolbar {
    left: ${editorToolbarLeft}px;
    max-width: calc(${p => `${p.orientation === 'portrait' ? 100 : 200}% - ${2 * editorToolbarLeft}px`});
    position: absolute !important;
    top: -30px;
    z-index: 9999 !important;
  }
`;

export const StyledEditor = styled(BaseEditor)`
  ${flex.vertical};
  flex-grow: 1;

  .rdw-editor-wrapper {
    flex-grow: 1;
  }

  .rdw-editor-main {
    border-radius: 4px;
    overflow: visible;
  }

  &:not(.has-focus) .rdw-editor-main:hover,
  ${() => StyledPencilIcon}:hover + & .rdw-editor-main {
    background: #ebebeb;
  }
`;

export const StyledPencilIcon = styled($Icon).attrs({
  name: 'pencil',
  size: 16
})`
  cursor: pointer;
  position: absolute;
  right: calc(1rem + ${4 + scrollbarWidth}px);
  top: calc(1rem + 4px);
  z-index: 1;
`;

export const StyledMediaPicker = styled($MediaPicker)`
  ${p =>
    p.orientation === 'landscape'
      ? `
    border-left: 1px solid ${commonPalette.darkestGray};
  `
      : `
    border-bottom: 1px solid ${commonPalette.darkestGray};
  `}
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
`;

export const HintContent = styled.div`
  left: 0;
  bottom: ${hintToolbarHeight};
  position: absolute;
  z-index: 3;

  ${p =>
    p.orientation === 'landscape'
      ? `
    top: 0;
    width: 200%;
  `
      : `
    top: ${-p.imageHeight}px;
    width: 100%;
  `}
`;

export const HintContentFader = styled.div`
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${p =>
    p.orientation === 'landscape' &&
    `
    &::after {
      background: inherit;
      content: '';
      height: ${hintToolbarHeight};
      left: 50%;
      position: absolute;
      top: 100%;
      width: 50%;
    }
  `}
`;

export const HintContentInner = styled.div`
  // Top position is not set for the animations to work
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
`;

export const StyledHintEditorWrapper = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;

  ${p =>
    p.orientation === 'landscape'
      ? `
    top: 2.5rem;
    width: 50%;
  `
      : `
    top: calc(50% + ${hintContentMarginRem}rem);
    width: 100%;
  `}
`;

export const StyledHintEditor = styled(BaseEditor)`
  height: 100%;
  flex: 1;
  overflow: scroll;
  .rdw-editor-wrapper {
    background: #fff;
    height: 100%;
  }

  .rdw-editor-main {
    padding: 1.5rem 2rem;
  }
`;

export const StyledHintCheckboxWrapper = styled.div`
  background: #fff;
  padding: 1rem;
`;

export const StyledHintMediaPicker = styled(StyledMediaPicker)`
  background-color: #fff;
  bottom: -${hintToolbarHeight};
  height: auto;
  width: auto;

  ${p =>
    p.orientation === 'landscape'
      ? `
    left: 50%;
    right: ${hintContentMarginRem * p.backgroundAspectRatio}rem;
    top: ${hintContentMarginRem}rem;
  `
      : `
    bottom: calc(50% - ${hintContentMarginRem}rem);
    left: 0;
    right: 0;
    top: ${hintContentMarginRem}rem  ;
  `}
`;

export const StyledCardToolbar = styled($CardToolbar)`
  color: ${p => (p.isInverted ? '#fff' : '#000')};
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const StyledStepNoteEditorInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
