import styled from 'styled-components';
import CardToolbar from 'components/CardToolbar';
import {commonPalette} from 'shared/styles/palette';

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 32px;
`;

export const StyledText = styled.div`
  color: #1f271b;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledSubText = styled.div`
  color: ${commonPalette.txtGrey};
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const StyledMenu = styled(CardToolbar)``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${props => (props.paddingVertical ? '32px 0' : '0')};
  gap: 8px;
`;

export const ChevronCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DropwdownItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  min-width: 300px;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: end;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TableActionsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

export const TableActions = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
  margin-top: 10px;
`;
