import React, {useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import views from 'config/views';
import {useMutation, useQuery} from '@apollo/client';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

//components
import ButtonGroup from 'ui-components/ButtonGroup';
import Translations from 'components/Translations';
import TranslateLocale from 'components/TranslateLocale';
import Container from 'ui-components/Layout/Container';

//queries
import {GetGuideForTranslationView} from 'api/guide/queries';

//mutations
import {SaveTranslationsForGuide} from 'api/translations/mutations';

//styles
import {Content} from './styles';

//messages
import messages from './messages';

export const TranslateGuideViewComponent = props => {
  const {
    intl: {formatMessage},
    store,
    store: {translationsPage, router}
  } = props;
  const {savingTranslations} = translationsPage;
  const showTranslations = views.translateGuide.path === router.currentRoute.path;
  const showTranslateLocale = !!router.currentRoute.localeEditor;
  const guideId = router.params.id;

  useEffect(() => {
    const {translationsPage: page} = store;
    const uiTranslations = mapValues(pick(messages, ['addSuccess', 'removeSuccess', 'saveSuccess']), message =>
      formatMessage(message)
    );
    page.setUiTranslations(uiTranslations);

    return () => {
      translationsPage.reset();
    };
  }, []);

  const {loading: guidesLoading, data: guidesData} = useQuery(GetGuideForTranslationView, {
    variables: {
      id: guideId
    },
    fetchPolicy: 'network-only'
  });

  const [saveTranslationsForGuideMutation] = useMutation(SaveTranslationsForGuide, {
    // invalidate translationRate cache to refetch it when open that page again
    update(cache) {
      cache.evict({id: `Guide:${guideId}`, fieldName: 'translationRate'});
      cache.gc();
    }
  });

  useEffect(() => {
    if (!guidesLoading && guidesData) {
      translationsPage.setGuideData(guidesData.guideForCms);
      if (!guidesData.guideForCms.canTranslate) {
        router.goTo(views.editGuide, {id: guideId});
      }
    }
  }, [guidesData]);

  return (
    <Container>
      <Content>
        {showTranslations && <Translations />}
        {showTranslateLocale && <TranslateLocale saveTranslationsForGuideMutation={saveTranslationsForGuideMutation} />}
      </Content>
      <ButtonGroup
        sticky
        primary={
          showTranslateLocale
            ? {
                label: savingTranslations ? '' : formatMessage(messages.saveTranslation),
                disabled: savingTranslations,
                loading: savingTranslations,
                onClick: () => translationsPage.saveTranslations(saveTranslationsForGuideMutation)
              }
            : undefined
        }
        secondary={{
          label: formatMessage(messages.backToGuide),
          onClick: () => router.goTo(views.editGuide, {id: guideId})
        }}
      />
    </Container>
  );
};

export default injectIntl(inject('store')(observer(TranslateGuideViewComponent)));
