import React from 'react';
import {ActivityLogLink} from './ActivityLogLink';
import views from 'config/views';

type TopicLinkProps = {
  guideId: string;
  topicId: string;
  v: string;
  versionId: string;
  children: React.ReactNode;
  router: any;
  linkValidityChecks: {
    checkTopicLinkIsValid: (params: {topicId: string; guideId: string; versionId: string}) => boolean;
  };
};

export const TopicLink = (props: TopicLinkProps) => {
  const {
    guideId,
    topicId,
    v,
    versionId,
    children,
    router,
    linkValidityChecks: {checkTopicLinkIsValid}
  } = props;

  const handleValidateLink = e => {
    e.preventDefault();
    return checkTopicLinkIsValid({
      topicId,
      guideId,
      versionId
    });
  };

  return (
    <ActivityLogLink
      router={router}
      route={views.editGuide}
      params={{id: guideId}}
      queryParams={{l: topicId, v}}
      validateLink={handleValidateLink}
    >
      {children}
    </ActivityLogLink>
  );
};
