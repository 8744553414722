import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cropTip: {
    id: 'MediaEdit.cropTip',
    defaultMessage: "Only crop off what you don't want to see"
  },
  rotate: {
    id: 'MediaEdit.rotate',
    defaultMessage: 'Rotate'
  },
  scaleSelection: {
    id: 'MediaEdit.scaleSelection',
    defaultMessage: 'Scale selection'
  },
  backgroundColor: {
    id: 'MediaEdit.backgroundColor',
    defaultMessage: 'Background Color'
  },
  overlayWarning: {
    id: 'MediaEdit.overlayWarning',
    defaultMessage: 'After editing this image you should reposition the overlay shapes.'
  },
  previewOverlay: {
    id: 'MediaEdit.previewOverlay',
    defaultMessage: 'Preview'
  }
});

export default messages;
