import {action, computed, makeObservable, observable} from 'mobx';
import Steps from 'stores/models/steps';
import React from 'react';
import store from 'stores/store';
import views from 'config/views';
import SkillProfileCreateGeneral from 'components/SkillProfiles/SkillProfileCreateGeneralTab';
import SkillProfileCreateAssign from 'components/SkillProfiles/SkillProfileCreateAssignTab';
import AssignStepForm from 'stores/forms/new-skill-profile-assign-step-form';
import GeneralStepForm from 'stores/forms/new-skill-profile-general-step-form';
import notification from 'utils/notification-utils';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import some from 'lodash/some';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import {smartTrim} from 'utils/validation-utils';
import {SkillPaginated} from 'api/skills/queries';
import {client} from 'utils/apollo-client';

class SkillProfilePage {
  @observable isRolesDrawerOpened = false;
  @observable isSkillsDrawerOpened = false;
  @observable translations = {};
  @observable stepsStore;
  @observable shouldSkillsHaveRequiredLevel = false;
  @observable showSkillsValidationErrors = false;
  @observable mode = undefined;

  @observable availableSkillRoles = [];
  @observable availableSkills = [];
  @observable availableTeams = [];
  @observable availableSkillLevels = [];
  @observable localSelectedTags = [];
  @observable selectedTags = [];
  @observable teamId = null;

  @observable skillsFilters = {
    name: {
      contains: ''
    },
    tags: {
      in: []
    }
  };
  @observable skillsSortBy = [{field: 'name', order: 'asc'}];
  @observable skillsOffset = 0;
  @observable skillsLimit = 50;
  @observable skillsLoading = false;
  @observable totalSkillsCount = 0;
  @observable skillsLoadingError = false;

  competentSkillLevel = null;
  generalStepForm = GeneralStepForm;
  assignStepForm = AssignStepForm;
  skillProfile = null;

  // Form fields
  @observable selectedSkills = [];
  // Data type: [{ roles: [{id, name]], team: {id, name} }]
  @observable selectedJobTitles = {};

  constructor() {
    makeObservable(this);
    this.setSteps();
  }

  setTranslations = translations => {
    this.translations = translations;
  };

  setMode = mode => {
    this.mode = mode;
    this.setSteps();
  };

  @action openRolesDrawer = () => {
    this.isRolesDrawerOpened = true;
  };

  @action closeRolesDrawer = () => {
    this.setSearchTerm('');
    this.isRolesDrawerOpened = false;
  };

  @action openSkillsDrawer = () => {
    this.isSkillsDrawerOpened = true;
  };

  @action closeSkillsDrawer = () => {
    this.setSearchTerm('');
    this.isSkillsDrawerOpened = false;
  };

  @action setSearchTerm = value => {
    this.skillsFilters.name.contains = value;
  };

  @action reset = () => {
    this.isRolesDrawerOpened = false;
    this.isSkillsDrawerOpened = false;
    this.showSkillsValidationErrors = false;
    this.skillProfile = null;
    this.selectedSkills = [];
    this.selectedJobTitles = [];
    this.stepsStore.reset();
    this.generalStepForm.reset();
    this.generalStepForm.update({skills: []});
    this.assignStepForm.reset();
    this.assignStepForm.update({teamSkillRoles: []});
  };

  @action setAvailableSkillRoles = skillRoles => {
    this.availableSkillRoles = sortBy(skillRoles || [], role => role.name.toLowerCase());
  };

  @action
  setLocalSelectedTags = tags => {
    this.localSelectedTags = tags;
  };

  @action
  setSelectedTags = tags => {
    this.selectedTags = tags;
  };

  @action
  filterByTag = async () => {
    this.selectedTags = this.localSelectedTags;
    this.skillsFilters.tags.in = this.localSelectedTags.map(tag => tag.id);
    const skills = await this.fetchSkills();
    this.updateAvailableSkills(skills, true);
  };

  @action
  resetFilters = async () => {
    this.skillsFilters.tags.in = [];
    this.setLocalSelectedTags([]);
    this.setSelectedTags([]);
    const skills = await this.fetchSkills();
    this.updateAvailableSkills(skills);
  };

  @action
  removeSelectedTag = tag => {
    const tags = this.localSelectedTags.filter(i => i.id !== tag.id);
    this.setLocalSelectedTags(tags);
    this.filterByTag();
  };

  // Accepts array of only available team skill roles (the ones that user have access to).
  // Omit passing the unavailable ones, they are added inside of this function.
  @action setSelectedJobTitles = jobTitles => {
    const titles = this.groupJobTitlesByAvailableNotAvailable(this.selectedJobTitles);

    // update internal state
    this.selectedJobTitles = [...sortBy(jobTitles, item => item.team?.name?.toLowerCase()), ...titles.notAvailable];

    // Fix for form.update method, which updates arrays/objects with a custom logic
    this.assignStepForm.update({teamSkillRoles: []});
    this.assignStepForm.update({
      teamSkillRoles: this.selectedJobTitles
    });
  };

  @action setAvailableSkills = skills => {
    this.availableSkills = skills;
  };

  @action setAvailableSkillLevels = skillLevels => {
    this.availableSkillLevels = skillLevels || [];

    // In case there are only 2 skill levels set on the platform (pass/fail) it equals false and otherwise to true
    this.shouldSkillsHaveRequiredLevel = this.availableSkillLevels.length !== 2;

    // set this.competentSkillLevelId to skill with the highest position
    if (!this.shouldSkillsHaveRequiredLevel) {
      this.competentSkillLevel = sortBy(skillLevels, level => -level.position)[0];
    }
  };

  @action setSelectedSkills = skills => {
    // update internal state
    this.selectedSkills = sortBy(skills || [], skill => skill.name.toLowerCase());

    // Fix for form.update method, which updates arrays/objects with a custom logic
    this.generalStepForm.update({skills: []});
    this.generalStepForm.update({skills});

    // hide validation errors if there are no skills
    if (skills?.length === 0) {
      this.showSkillsValidationErrors = false;
    }
  };

  @action setAvailableTeams = teams => {
    this.availableTeams = sortBy(teams || [], team => team.name.toLowerCase());
  };

  @action setTeamId = teamId => {
    this.teamId = teamId;
  };

  @action setSkillProfile = skillProfile => {
    this.skillProfile = skillProfile;

    // Map teams db data to the format that is used in the form
    const teamsById = this.availableTeams.reduce((acc, team) => {
      acc[team.id] = team;
      return acc;
    }, {});

    const rolesById = this.availableSkillRoles.reduce((acc, role) => {
      acc[role.id] = role;
      return acc;
    }, {});

    const selectedJobTitles = skillProfile.teams.reduce((acc, team) => {
      acc.push({
        team: teamsById[team.teamId] || {...team, id: team.teamId},
        roles: team.skillRoleIds ? team.skillRoleIds.map(roleId => rolesById[roleId]) : []
      });

      return acc;
    }, []);

    this.setSelectedJobTitles(selectedJobTitles);

    // Map skills db data to the format that is used in the form
    const skills = skillProfile.skills.map(skill => {
      const skillWithRequiredLevel = {...skill, requiredLevelId: skill.requiredLevel?.id || ''};
      return skillWithRequiredLevel;
    });

    this.setSelectedSkills(skills);

    // Update general step form with name
    this.generalStepForm.update({
      name: skillProfile.name
    });
  };

  areSkillsValid = () => {
    const {skills} = this.generalStepForm.values();

    let isValid = true;

    if (this.shouldSkillsHaveRequiredLevel && skills.length > 0) {
      const hasSkillsWithoutRequiredLevelSet = some(skills, skill => !skill.requiredLevelId);
      isValid = !hasSkillsWithoutRequiredLevelSet;
    }

    return isValid;
  };

  /**
   * Validates general tab and if validation is successful calls a callback if present.
   *
   * @param callback a callback to be called if validation is successful
   */
  validateGeneralTab = callback => {
    const isValid = this.areSkillsValid();

    if (isValid) {
      this.showSkillsValidationErrors = false;

      if (callback) {
        callback(isValid);
      }
    } else {
      notification.error(this.translations.skillLevelMustBeSet);
      this.showSkillsValidationErrors = true;
    }
  };

  @action
  setSteps = () => {
    let generalTabPrimaryButtonOverrideConfig;
    if (this.mode === 'create') {
      generalTabPrimaryButtonOverrideConfig = {
        labelL10nKey: 'next',
        onClick: () => this.validateGeneralTab(() => this.stepsStore.goToTab(1))
      };
    }

    this.stepsStore = new Steps({
      steps: [
        {
          key: 'general',
          path: 'general',
          checked: false,
          form: this.generalStepForm,
          primaryButtonOverrideConfig: generalTabPrimaryButtonOverrideConfig,
          component: <SkillProfileCreateGeneral />
        },
        {
          key: 'assignTo',
          path: 'assign',
          checked: false,
          form: this.assignStepForm,
          component: <SkillProfileCreateAssign />
        }
      ],
      editMode: true
    });
  };

  @action updateSkillsOffset = offset => (this.skillsOffset = offset);

  @action setTotalSkillsCount = count => (this.totalSkillsCount = count);

  @action updateAvailableSkills = (skills, tagsFiltering) => {
    this.availableSkills = tagsFiltering ? [...skills] : [...this.availableSkills, ...skills];
  };

  @action updateSkillsLoadingState = val => (this.skillsLoading = val);

  @action updateSkillsLoadingError = val => (this.skillsLoadingError = val);

  @action
  fetchSkills = async () => {
    this.updateSkillsLoadingState(true);

    try {
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: SkillPaginated,
        variables: {
          limit: this.skillsLimit,
          offset: this.skillsOffset,
          sortBy: this.skillsSortBy,
          filters: this.skillsFilters
        }
      });
      const {
        skillsPaginated: {totalCount, results}
      } = response.data;

      this.setTotalSkillsCount(totalCount);
      if (this.skillsOffset === 0) {
        this.setAvailableSkills(results);
      }

      this.updateSkillsLoadingError(false);
      return results;
    } catch (error) {
      this.updateSkillsLoadingError(true);
    } finally {
      this.updateSkillsLoadingState(false);
    }
  };

  @action
  fetchMore = async () => {
    if (this.skillsOffset + this.skillsLimit <= this.totalSkillsCount) {
      this.updateSkillsOffset(this.skillsOffset + this.skillsLimit);
      const skills = await this.fetchSkills();
      this.updateAvailableSkills(skills);
    }
  };

  searchSkills = debounce(async term => {
    this.updateSkillsOffset(0);
    if ((term.length <= 50 && term.length >= 3) || term.length === 0) {
      await this.fetchSkills();
    }
  }, 300);

  /**
   * Manages stepsStore custom submit button state.
   * Used only at general tab for name/skills validation rules.
   *
   * @param stepKey
   * @returns {undefined|{shouldHideOverridenPrimaryButton: boolean, shouldDisableOverridenPrimaryButton: boolean}}
   */
  getCurrentStepSpecificConfig = ({key: stepKey}) => {
    if (stepKey !== 'general') {
      return undefined;
    }
    const isValid = this.generalStepForm.isValid;

    return {
      shouldHideOverridenPrimaryButton: false,
      shouldDisableOverridenPrimaryButton: !isValid
    };
  };

  @computed
  get isEditMode() {
    return this.mode === 'edit';
  }

  @computed
  get showSaveButton() {
    if (this.isEditMode) {
      return true;
    }

    return this.stepsStore?.currentStepIndex === this.stepsStore?.steps.length - 1;
  }

  @computed
  get showCancelButton() {
    if (this.isEditMode) {
      return true;
    }

    return this.stepsStore.currentStepIndex === 0;
  }
  @computed
  get showInfiniteLoader() {
    return this.availableSkills?.length !== this.totalSkillsCount;
  }

  goToListPage = () => {
    if (this.teamId) {
      store.router.goTo(views.smartSkillsTeam, {teamId: this.teamId});
    } else store.router.goTo(views.skillProfiles, {});
    this.reset();
  };

  createSkillProfile = async (saveSkillProfileMutation, {successMessage, errorMessage, nameExistsErrorMessage}) => {
    const {name, skills} = this.generalStepForm.values();
    const {teamSkillRoles} = this.assignStepForm.values();

    const data = {
      name: smartTrim(name),
      teams: this.formatTeamSkillRolesData(teamSkillRoles),
      skills: this.formatSkillsData(skills)
    };

    try {
      await saveSkillProfileMutation(data);

      notification.success(successMessage);
      this.goToListPage();
      return {success: true};
    } catch (error) {
      notification.error(error.message === 'skill_profile_name_already_exists' ? nameExistsErrorMessage : errorMessage);
      return {error, success: false};
    }
  };

  updateNameAndSkills = async updateSkillProfileMutation => {
    const id = this.skillProfile.id;
    const {name, skills} = this.generalStepForm.values();
    const data = {skillProfile: {id, name: smartTrim(name)}, skills: this.formatSkillsData(skills)};

    try {
      await updateSkillProfileMutation(data);
      return {success: true};
    } catch (error) {
      return {error, success: false};
    }
  };

  updateTeams = async updateSkillProfileTeamsMutation => {
    const id = this.skillProfile.id;
    const {teamSkillRoles} = this.assignStepForm.values();

    const teams = this.formatTeamSkillRolesData(teamSkillRoles);

    try {
      await updateSkillProfileTeamsMutation({id, teams});
      return {success: true};
    } catch (error) {
      return {error, success: false};
    }
  };

  formatSkillsData = skills => {
    return (skills || []).map(skill => ({skillId: skill.id, requiredLevelId: skill.requiredLevelId}));
  };

  formatTeamSkillRolesData = teamSkillRoles => {
    return (teamSkillRoles || []).map(item => ({
      teamId: item.team.id,
      skillRoleIds: item.roles?.length > 0 ? map(item.roles, 'id') : null
    }));
  };

  groupJobTitlesByAvailableNotAvailable(jobTitles) {
    const grouped = groupBy(jobTitles, title => (title.team?.name ? 'available' : 'notAvailable'));

    return {
      available: grouped.available || [],
      notAvailable: grouped.notAvailable || [],
      availableNumber: grouped.available?.length || 0,
      notAvailableNumber: grouped.notAvailable?.length || 0
    };
  }
}

export default SkillProfilePage;
