import {defineMessages} from 'react-intl';

const messages = defineMessages({
  notTransalted: {
    id: 'StepNotesIconsList.labelNotTranslated',
    defaultMessage: 'Not translated'
  }
});

export default messages;
