import {ORIGIN} from 'shared/constants';

export const pageSize = 'A4';

export const alignmentType = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right'
};

export const colors = {
  GRAY: '#A8A8A8',
  LIGHT_GRAY: '#D1D1D1',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLUE: '#4B91E2'
};

export const pageBreak = {
  AFTER: 'after',
  BEFORE: 'before'
};

export const emptyTextForForrmating = '';

export const iconTypes = {
  WARNING: 'warning',
  LIGHTBULB: 'light-bulb',
  SPLIT_ARROW: 'split-arrow',
  PATCH: 'patch'
};

export const stepNoteTypes = {
  WARNING: 'warning',
  ALTERNATIVE: 'alternative',
  TIP: 'tip',
  FIX: 'fix',
  CUSTOM: 'custom'
};

export const defaultHtmlLinksStyles = {
  a: {
    color: colors.BLUE,
    decoration: ''
  }
};

export const grayBorderLayout = {
  hLineColor: () => colors.LIGHT_GRAY,
  vLineColor: () => colors.LIGHT_GRAY,
  hLineWidth: () => 0.5,
  vLineWidth: () => 0.5,
  paddingLeft: () => 15,
  paddingRight: () => 15,
  paddingTop: () => 15,
  paddingBottom: () => 15
};

export const oneGrayBorderLayout = {
  vLineColor: () => colors.LIGHT_GRAY,
  hLineWidth: () => 0,
  vLineWidth: i => (i === 0 ? 1.5 : 0),
  paddingLeft: () => 10,
  paddingRight: () => 0,
  paddingTop: () => 0,
  paddingBottom: () => -5
};

export const noBorderLayout = {
  hLineWidth: () => 0,
  vLineWidth: () => 0,
  paddingLeft: () => 15,
  paddingRight: () => 15,
  paddingTop: () => 15,
  paddingBottom: () => 0
};

export const boldBorderLayout = {
  hLineColor: () => colors.BLACK,
  vLineColor: () => colors.BLACK,
  hLineWidth: () => 2,
  vLineWidth: () => 2,
  paddingLeft: () => 15,
  paddingRight: () => 5,
  paddingTop: () => 15,
  paddingBottom: () => -15
};

export const blackBorderLayout = {
  hLineColor: () => colors.BLACK,
  vLineColor: () => colors.BLACK,
  hLineWidth: () => 1,
  vLineWidth: () => 1,
  paddingLeft: () => 10,
  paddingRight: () => 10,
  paddingTop: () => 10,
  paddingBottom: () => 5
};

export const fonts = {
  LATO: 'Lato',
  OPEN_SANS: 'OpenSans',
  NOTO_SANS_SC: 'NotoSansSC',
  NOTO_SANS_KR: 'NotoSansKR',
  NOTO_SANS_THAI: 'NotoSansThai',
  PADAUK: 'Padauk',
  NOTO_SANS_JP: 'NotoSansJP'
};

const withAbsoluteUrl = relativeUrl => ORIGIN.concat(relativeUrl);

export const fontSettings = {
  Lato: {
    normal: withAbsoluteUrl(require('shared/fonts/lato-regular.woff')),
    bold: withAbsoluteUrl(require('shared/fonts/lato-bold.woff')),
    italics: withAbsoluteUrl(require('shared/fonts/lato-italic.woff')),
    bolditalics: withAbsoluteUrl(require('shared/fonts/lato-bold-italic.woff'))
  },
  OpenSans: {
    normal: withAbsoluteUrl(require('shared/fonts/opensans-regular.ttf')),
    bold: withAbsoluteUrl(require('shared/fonts/opensans-bold.ttf')),
    italics: withAbsoluteUrl(require('shared/fonts/opensans-italic.ttf')),
    bolditalics: withAbsoluteUrl(require('shared/fonts/opensans-bolditalic.ttf'))
  },
  // Chinese language files not available
  // NotoSansSC: {
  //   normal: withAbsoluteUrl(require('shared/fonts/NotoSansSC-Regular.otf')),
  //   bold: withAbsoluteUrl(require('shared/fonts/NotoSansSC-Bold.otf')),
  //   italics: withAbsoluteUrl(require('shared/fonts/NotoSansSC-Regular.otf')),
  //   bolditalics: withAbsoluteUrl(require('shared/fonts/NotoSansSC-Bold.otf')),
  // },
  // Korean language files not available
  // NotoSansKR: {
  //   normal: withAbsoluteUrl(require('shared/fonts/NotoSansKR-Regular.otf')),
  //   bold: withAbsoluteUrl(require('shared/fonts/NotoSansKR-Bold.otf')),
  //   italics: withAbsoluteUrl(require('shared/fonts/NotoSansKR-Regular.otf')),
  //   bolditalics: withAbsoluteUrl(require('shared/fonts/NotoSansKR-Bold.otf')),
  // },
  NotoSansThai: {
    normal: withAbsoluteUrl(require('shared/fonts/notosansthai-regular.ttf')),
    bold: withAbsoluteUrl(require('shared/fonts/notosansthai-bold.ttf')),
    italics: withAbsoluteUrl(require('shared/fonts/notosansthai-regular.ttf')),
    bolditalics: withAbsoluteUrl(require('shared/fonts/notosansthai-bold.ttf'))
  },
  Padauk: {
    normal: withAbsoluteUrl(require('shared/fonts/padauk-regular.ttf')),
    bold: withAbsoluteUrl(require('shared/fonts/padauk-bold.ttf')),
    italics: withAbsoluteUrl(require('shared/fonts/padauk-regular.ttf')),
    bolditalics: withAbsoluteUrl(require('shared/fonts/padauk-bold.ttf'))
  },
  NotoSansJP: {
    normal: withAbsoluteUrl(require('shared/fonts/notosansjp-regular.ttf')),
    bold: withAbsoluteUrl(require('shared/fonts/notosansjp-bold.ttf')),
    italics: withAbsoluteUrl(require('shared/fonts/notosansjp-regular.ttf')),
    bolditalics: withAbsoluteUrl(require('shared/fonts/notosansjp-bold.ttf'))
  }
};

export const publishStatus = {
  PUBLISHED: 'PUBLISHED'
};

export const mediaTypes = {
  GIF: 'gif',
  VIDEO: 'video',
  IMAGE_WITH_OVERLAYS: 'image with overlays'
};

// TODO: This is a quick hack to get the code to builld. Check what is going on here.
export const locales = {};
