import React, {useReducer, useEffect} from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'components/Checkbox';

import TeamMembershipCard from './TeamMembershipCard';

import {
  GuideApprovalSelectorContainer,
  ApproverSelectionContainer,
  ApprovalRequirementCheckboxCaptionContainer,
  ApprovalRequirementCheckboxContainer,
  SelectApprovalOrderCheckboxContainer,
  ApproverSelectorsContainer,
  AddAproverButton,
  StyledIcon
} from './styles';

import {actionTypes, initState, getNextState} from './state-manager';

import {
  getApproverIdsFromCards,
  getSerializedInternalApproverIds,
  getSerializedUserIds,
  getSerializedApproverIds,
  getShouldCallOnRequireGuideApprovalChange,
  getCanAddMoreEmptyCards,
  getIsDeletable,
  getCanSelectApprovalOrder
} from './utils';

const {
  UPDATE_REQUIRE_GUIDE_APPROVAL,
  UPDATE_USERS,
  ADD_EMPTY_CARD,
  UPDATE_CARD_VALUE,
  REMOVE_CARD,
  MOVE_CARD_UP,
  MOVE_CARD_DOWN,
  UPDATE_SELECT_APPROVER_ORDER
} = actionTypes;

const messagesPropType = PropTypes.shape({
  requireGuideApproval: PropTypes.string.isRequired,
  setApproverOrder: PropTypes.string.isRequired,
  guideApprovalRequirementExplanation: PropTypes.string.isRequired,
  select: PropTypes.string.isRequired,
  approver: PropTypes.string.isRequired,
  addApprover: PropTypes.string.isRequired
}).isRequired;

const ApprovalRequirementCheckboxCaption = ({messages}) => (
  <ApprovalRequirementCheckboxCaptionContainer>
    <p>{messages.requireGuideApproval}</p>
    <p className="explanation">{messages.guideApprovalRequirementExplanation}</p>
  </ApprovalRequirementCheckboxCaptionContainer>
);

ApprovalRequirementCheckboxCaption.propTypes = {
  messages: messagesPropType
};

const GuideApprovalSelector = ({
  requireGuideApproval,
  selectApproverOrder,
  users,
  disabled,
  messages,
  onRequireGuideApprovalChange,
  onSelectApproverOrderChange,
  onApproverIdsChange
}) => {
  const [state, dispatchAction] = useReducer(
    getNextState,
    {requireGuideApproval, users, selectApproverOrder},
    initState
  );

  const {
    requireGuideApproval: internalRequireGuideApproval,
    selectApproverOrder: internalSelectApproverOrder,
    teamMembershipCardsData
  } = state;

  const serializedUserIds = getSerializedUserIds(users);
  const serializedApproverIds = getSerializedApproverIds(users);
  const serializedInternalApproverIds = getSerializedInternalApproverIds(state);

  // Observe prop changes
  useEffect(() => {
    dispatchAction({
      actionType: UPDATE_REQUIRE_GUIDE_APPROVAL,
      payload: {
        requireGuideApproval
      }
    });
  }, [requireGuideApproval]);

  useEffect(() => {
    dispatchAction({
      actionType: UPDATE_SELECT_APPROVER_ORDER,
      payload: {
        selectApproverOrder
      }
    });
  }, [selectApproverOrder]);

  useEffect(() => {
    dispatchAction({
      actionType: UPDATE_USERS,
      payload: {
        users
      }
    });
  }, [serializedUserIds, serializedApproverIds]);

  // Observe internal state changes
  useEffect(() => {
    const shouldCallOnRequireGuideApprovalChange = getShouldCallOnRequireGuideApprovalChange({
      state,
      requireGuideApproval
    });

    if (shouldCallOnRequireGuideApprovalChange) {
      onRequireGuideApprovalChange(internalRequireGuideApproval);
      if (!internalRequireGuideApproval) {
        onApproverIdsChange([]);
      }
    }
  }, [internalRequireGuideApproval]);

  useEffect(() => {
    onSelectApproverOrderChange(internalSelectApproverOrder);
  }, [internalSelectApproverOrder]);

  useEffect(() => {
    const newApproverIds = getApproverIdsFromCards(state);
    onApproverIdsChange(newApproverIds);
  }, [serializedInternalApproverIds]);

  const shouldShowCardAdditionButton = getCanAddMoreEmptyCards(state);
  const disabledSelectApprovalOrderCheckbox = disabled || !getCanSelectApprovalOrder(state);

  return (
    <GuideApprovalSelectorContainer>
      <ApprovalRequirementCheckboxContainer>
        <Checkbox
          className="approval-requirement--checkbox"
          checked={internalRequireGuideApproval}
          caption={<ApprovalRequirementCheckboxCaption messages={messages} />}
          disabled={disabled}
          onChange={() => {
            dispatchAction({
              actionType: UPDATE_REQUIRE_GUIDE_APPROVAL,
              payload: {
                requireGuideApproval: !internalRequireGuideApproval
              }
            });
          }}
        />
      </ApprovalRequirementCheckboxContainer>
      {internalRequireGuideApproval && (
        <SelectApprovalOrderCheckboxContainer>
          <Checkbox
            checked={internalSelectApproverOrder}
            caption={messages.setApproverOrder}
            disabled={disabledSelectApprovalOrderCheckbox}
            onChange={() => {
              dispatchAction({
                actionType: UPDATE_SELECT_APPROVER_ORDER,
                payload: {
                  selectApproverOrder: !internalSelectApproverOrder
                }
              });
            }}
          />
          <StyledIcon popoverContent={messages.guidesApprovedInOrder} />
        </SelectApprovalOrderCheckboxContainer>
      )}
      <ApproverSelectionContainer>
        <ApproverSelectorsContainer>
          {internalRequireGuideApproval &&
            teamMembershipCardsData.map(({isEmpty, selectedUserId, usersOptions}, index) => (
              <TeamMembershipCard
                key={index}
                label={messages.approver}
                allowClear={isEmpty}
                selectedUserId={selectedUserId}
                usersOptions={usersOptions}
                emptyOptionPlaceHolder={messages.select}
                showCloseButton={getIsDeletable({state, cardPosition: index})}
                disabled={disabled}
                disabledSelector={!isEmpty}
                cardPosition={index}
                cardsLength={teamMembershipCardsData.length}
                selectApproverOrder={internalSelectApproverOrder}
                onChange={newSelectedUserId => {
                  const action = {
                    actionType: UPDATE_CARD_VALUE,
                    payload: {
                      cardPosition: index,
                      selectedUserId: newSelectedUserId
                    }
                  };
                  dispatchAction(action);
                }}
                onClose={() => {
                  const action = {
                    actionType: REMOVE_CARD,
                    payload: {
                      cardPosition: index
                    }
                  };
                  dispatchAction(action);
                }}
                onMoveUp={() => {
                  dispatchAction({
                    actionType: MOVE_CARD_UP,
                    payload: {
                      cardPosition: index
                    }
                  });
                }}
                onMoveDown={() => {
                  dispatchAction({
                    actionType: MOVE_CARD_DOWN,
                    payload: {
                      cardPosition: index
                    }
                  });
                }}
              />
            ))}
        </ApproverSelectorsContainer>

        {shouldShowCardAdditionButton && (
          <div>
            <AddAproverButton
              iconId="add"
              disabled={disabled}
              onClick={() => {
                dispatchAction({
                  actionType: ADD_EMPTY_CARD
                });
              }}
              label={messages.addApprover}
            />
          </div>
        )}
      </ApproverSelectionContainer>
    </GuideApprovalSelectorContainer>
  );
};

GuideApprovalSelector.propTypes = {
  requireGuideApproval: PropTypes.bool,
  selectApproverOrder: PropTypes.bool,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      isApprover: PropTypes.bool,
      role: PropTypes.oneOf(['ADMIN', 'EDITOR'])
    })
  ),
  disabled: PropTypes.bool,
  messages: messagesPropType,
  onRequireGuideApprovalChange: PropTypes.func,
  onSelectApproverOrderChange: PropTypes.func,
  onApproverIdsChange: PropTypes.func
};

GuideApprovalSelector.defaultProps = {
  requireGuideApproval: false,
  users: [],
  disabled: false
};

export default GuideApprovalSelector;
