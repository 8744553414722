import styled, {css} from 'styled-components';

//helpers
import {baseFontSize} from 'styles/menu';
import {noSelect} from 'shared/utils/sc-utils';

//components
import $Icon from 'ui-components/Layout/Icon';
import $Link from 'shared/components/Link';

const colors = {
  highlight: '#298784',
  text: 'rgba(0, 0, 0, 0.7)'
};

const sizes = {
  iconSize: 24,
  itemHeight: 48
};

const baseMenuItemCss = css`
  ${noSelect};
  color: ${colors.text};
  display: flex;
  align-items: center;
  font-size: ${baseFontSize}px;
  height: 50px;
  line-height: ${sizes.itemHeight}px;
  position: relative;
  transition: 0.05s background linear;
  white-space: nowrap;
`;

export const DisabledMenuItem = styled.div`
  ${baseMenuItemCss};
  opacity: 0.2;
`;

export const MenuItem = styled($Link)`
  ${baseMenuItemCss};

  &:hover {
    color: ${colors.highlight};
  }

  ${p =>
    p.selected &&
    css`
      color: ${colors.highlight};
    `}
`;

export const MenuItemExternalLink = styled.a`
  ${baseMenuItemCss};
  text-decoration: none;

  &:hover {
    color: ${colors.highlight};
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }

  ${p =>
    p.selected &&
    css`
      text-decoration: none;
      color: ${colors.highlight};
    `}
`;

export const Icon = styled($Icon)`
  margin-right: 8px;
`;

export const SecondaryIcon = styled($Icon)`
  margin-left: 50px;
`;
