import {defineMessages} from 'react-intl';

const messages = defineMessages({
  settingsSubtitle: {
    id: 'skills.settingsSubtitle',
    defaultMessage: 'Define the skill levels for your skills framework on the platform.'
  },
  infoboxMessage: {
    id: 'skills.infoboxMessage',
    defaultMessage: 'Level structure is fixed but labels for each level can be modified.'
  },
  askAdminSkillLevels: {
    id: 'skills.askAdminSkillLevels',
    defaultMessage: 'Ask your Customer Success Manager to set up skill levels'
  }
});

export default messages;
