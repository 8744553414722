import React, {Component} from 'react';
import {DragSource, DropTarget} from 'react-dnd';
import {getEmptyImage} from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import {inject} from 'mobx-react';

//components
import ShapeRow from '../ShapeRow';

//drag-and-drop
import {DND_TYPE, source, target} from './dnd';

@inject('store')
@DragSource(DND_TYPE, source, (connect, monitor) => ({
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
@DropTarget([DND_TYPE], target, (connect, monitor) => ({
  canDrop: monitor.canDrop(),
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  itemType: monitor.getItemType()
}))
export default class DraggableShapeRowComponent extends Component {
  static propTypes = {
    position: PropTypes.number.isRequired,
    shape: PropTypes.object.isRequired,
    onBeginDrag: PropTypes.func,
    onEndDrag: PropTypes.func
  };

  dragHandleElement = null;

  componentDidMount() {
    const {connectDragPreview} = this.props;

    connectDragPreview(getEmptyImage(), {
      // IE fallback: specify that we'd rather screenshot the node
      // when it already knows it's being dragged so we can hide it with CSS.
      captureDraggingState: true
    });
  }

  render() {
    const {connectDragSource, connectDropTarget, isDragging} = this.props;

    return (
      <ShapeRow
        {...this.props}
        design={isDragging ? 'ghostly' : 'default'}
        dragHandleRef={ref => {
          connectDragSource(ref);
          this.dragHandleElement = ref;
        }}
        hostRef={connectDropTarget}
      />
    );
  }
}
