import {defineMessages} from 'react-intl';

const messages = defineMessages({
  showStepNoteName: {
    id: 'PlatformSettingsStepNotes.showStepNoteName',
    defaultMessage: 'Show Step Note Name in your Guides'
  },
  addNewStepNote: {
    id: 'PlatformSettingsStepNotes.addNewStepNote',
    defaultMessage: 'Add new Step Note'
  },
  captionText: {
    id: 'PlatformSettingsStepNotes.captionText',
    defaultMessage: 'Step Notes provide additional information within a Step.'
  },
  captionTextDefaultIcons: {
    id: 'PlatformSettingsStepNotes.captionTextDefaultIcons',
    defaultMessage: 'Choose the default Step Notes visible on all your Workspaces.'
  }
});

export default messages;
