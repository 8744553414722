import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Menu.platformSettings',
    defaultMessage: 'Platform settings'
  },
  stepNotes: {
    id: 'PlatformSettings.stepNotes',
    defaultMessage: 'Step Notes'
  },
  skillLevels: {
    id: 'PlatformSettings.skillLevels',
    defaultMessage: 'Skill Levels'
  },
  general: {
    id: 'PlatformSettings.general',
    defaultMessage: 'General'
  },
  apiAccess: {
    id: 'PlatformSettings.apiAccess',
    defaultMessage: 'API access tokens'
  },
  alerts: {
    id: 'PlatformSettings.alerts',
    defaultMessage: 'Alerts'
  },
  toggleStepNoteNameSuccess: {
    id: 'PlatformSettings.toggleStepNoteNameSuccess',
    defaultMessage: 'Step Note Name visibility changed successfully'
  },
  toggleStepNoteNameFailure: {
    id: 'PlatformSettings.toggleStepNoteNameFailure',
    defaultMessage: 'Failed to change the visibility of the Step Note Name'
  },
  tagCreationRoleUpdateSuccess: {
    id: 'tagCreationRoleUpdateSuccess',
    defaultMessage: 'Tag creation role updated'
  },
  tagCreationRoleUpdateFailure: {
    id: 'tagCreationRoleUpdateFailure',
    defaultMessage: 'Failed to update tag creation role rights'
  },
  webhookUrlUpdateSuccess: {
    id: 'webhookUrlUpdateSuccess',
    defaultMessage: 'Webhook URL updated'
  },
  webhookUrlUpdateFailure: {
    id: 'webhookUrlUpdateFailure',
    defaultMessage: 'Failed to update webhook URL'
  }
});

export default messages;
