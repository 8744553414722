import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {mapValues, pick} from 'lodash';
import {bindField} from 'shared/utils/input-utils';
import {inject, observer} from 'mobx-react';

import {ResetPasswordForm, Title, Wrap, styles, ErrorNotification, StyledErrorIcon, StyledErrorWrapper} from './styles';
import mutationNames from 'api/user/constants';

//options
import {validateResetPasswordLinkOptions} from 'api/user/query-options';
import {ValidateResetPasswordLink} from 'api/user/queries';

//data
import {graphql} from '@apollo/client/react/hoc/graphql';

//mutations
import {ResetPassword} from 'api/user/mutations';

//options
import {resetPasswordOptions} from 'api/user/mutation-options';

//components
import Button from 'ui-components/Button';
import {FormattedMessage} from 'components/FormattedComponents';
import Field from 'shared/components/Field';

//messages
import messages from './messages';

@inject('store')
@graphql(ValidateResetPasswordLink, validateResetPasswordLinkOptions)
@graphql(ResetPassword, resetPasswordOptions)
@observer
class ResetPasswordFormComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {resetPasswordPage}
    } = this.props;

    const translations = mapValues(pick(messages, ['requestSuccess', 'requestFailure', 'invalidLink']), message =>
      formatMessage(message)
    );

    resetPasswordPage.setTranslations(translations);
  }

  componentWillUnmount() {
    const {store} = this.props;
    store.resetPasswordPage.form.clear();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.data.isPasswordResetLinkValid === false) {
      const {store} = this.props;
      const {resetPasswordPage} = store;
      resetPasswordPage.resetPasswordLinkNotValid();
    }
  }

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const resetPasswordMutation = this.props[mutationNames.RESET_PASSWORD];
    const {resetPasswordPage} = store;
    const {form} = resetPasswordPage;
    const {newPassword, newPasswordConfirm} = form.values();
    const passwordsMatch = newPassword === newPasswordConfirm;
    const inputStyles = {Input: styles};

    return (
      <ResetPasswordForm>
        <Title>
          <FormattedMessage {...messages.title} />
        </Title>

        <Wrap>
          <Field
            autofocus={true}
            placeholder={formatMessage(messages.newPassword)}
            id="newPassword"
            type="password"
            form={form}
            field={'newPassword'}
            hideError
            withPasswordToggle
            withPasswordValidation
            styles={inputStyles}
            {...bindField(form, 'newPassword')}
          />
          <Field
            placeholder={formatMessage(messages.newPasswordConfirm)}
            id="newPasswordConfirm"
            type="password"
            form={form}
            field={'newPasswordConfirm'}
            hideError
            styles={inputStyles}
            {...bindField(form, 'newPasswordConfirm')}
            withPasswordToggle
          />
          {!passwordsMatch && (
            <StyledErrorWrapper>
              <StyledErrorIcon name={'warning'} />
              <ErrorNotification>{formatMessage(messages.passwordsDoNotMatch)}</ErrorNotification>
            </StyledErrorWrapper>
          )}

          <Button
            disabled={!form.isValid || resetPasswordPage.loading === true || !passwordsMatch}
            label={formatMessage(messages.submit)}
            loading={resetPasswordPage.loading}
            onClick={() => resetPasswordPage.submitResetPasswordForm(resetPasswordMutation)}
          />
        </Wrap>
      </ResetPasswordForm>
    );
  }
}

export default injectIntl(ResetPasswordFormComponent);
