import React from 'react';
import {inject, observer} from 'mobx-react';
import views from 'config/views';
//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {DropdownMenu, DropdownMenuButton, DropdownMenuItem} from 'styles/styled-components/dropdown-styled-components';

//messages
import messages from './messages';

type Skill = {
  id: string;
  name: string;
  skillProfilesCount: number;
};

type SkilksListMenuProps = {
  store?: any;
  skill: Skill;
};

const SkillsListMenuComponent = (props: SkilksListMenuProps) => {
  const {
    store: {
      router,
      skillsPage: {setSkillToDelete, deleteSkill: deleteSkillAction, setDeleteSkillDialogOpened}
    },
    skill
  } = props;
  const deleteSkill = async () => {
    setSkillToDelete(skill);

    if (skill.skillProfilesCount > 0) {
      setDeleteSkillDialogOpened(true);
    } else {
      await deleteSkillAction();
    }
  };

  const isSkillsPage = router.currentRoute.id === views.skills.id;

  return (
    <>
      {isSkillsPage && (
        <DropdownMenu {...props}>
          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => router.goTo(views.editSkills, {id: skill.id})}>
              <FormattedMessage {...messages.editSkill} />
            </DropdownMenuButton>
          </DropdownMenuItem>

          <DropdownMenuItem>
            <DropdownMenuButton onClick={() => deleteSkill()}>
              <FormattedMessage {...messages.delete} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        </DropdownMenu>
      )}
    </>
  );
};

export default inject('store')(observer(SkillsListMenuComponent));
