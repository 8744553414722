import React, {ReactElement} from 'react';
import {capitalize} from 'lodash';
import {injectIntl, InjectedIntlProps} from 'react-intl';
import messages from './messages';

interface CurrentSkillLabelProps extends InjectedIntlProps {
  label?: string | null;
  skillLevels: any[];
}

function CurrentSkillLabel({
  label = null,
  skillLevels,
  intl: {formatMessage}
}: Readonly<CurrentSkillLabelProps>): ReactElement {
  if (label) {
    return <>{capitalize(label)}</>;
  }
  const isBinary = skillLevels.length === 2;
  if (isBinary) {
    return <>{capitalize(skillLevels[0].name)}</>;
  }
  return <>{formatMessage(messages.noLevel)}</>;
}

export default injectIntl(CurrentSkillLabel);
