import {defineMessages} from 'react-intl';

const messages = defineMessages({
  guideOverview: {
    id: 'EditGuide.backToOverview',
    defaultMessage: 'Guide overview'
  }
});

export default messages;
