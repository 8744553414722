import React from 'react';
import TranslationSuccessDialogContent from './TranslationSuccessDialogContent';
import messages from './messages';
import views from 'config/views';

export const getTranslationSuccessDialogConfig = (store, formatMessage) => ({
  title: formatMessage(messages.wholeGuideTranslated),
  content: <TranslationSuccessDialogContent formatMessage={formatMessage} />,
  actionsProps: {
    actions: [
      {
        label: formatMessage(messages.updateLanguage),
        onClick: () => {
          store.autoTranslationDialog.updateDefaultLanguage(
            store.autoTranslationDialog.defaultLocale,
            store.autoTranslationDialog.selectedLanguageToTranslate,
            formatMessage
          );
        }
      }
    ],
    customCancelButtonText: messages.manageTranslations,
    onCancel: () => {
      const guideId = store.autoTranslationDialog.guideId;
      store.autoTranslationDialog.reset();
      store.router.goTo(views.translateGuide, {id: guideId});
    }
  },
  onRequestClose: () => {
    store.autoTranslationDialog.reset();
  },
  customHeaderStyle: {paddingBottom: 25}
});
