import {action, computed, observable, makeObservable} from 'mobx';

//lodash
import find from 'lodash/find';
import memoize from 'lodash/memoize';

class DragDropSteps {
  @observable dirty = false;
  @observable isDragged = false;
  @observable submitting = false;
  @observable.shallow steps = [];

  @action
  setIsDragged = isDragged => {
    this.isDragged = isDragged;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get shouldBeInteractive() {
    return !this.isDragged;
  }

  @action
  setSteps = steps => {
    this.dirty = false;
    this.isDragged = false;
    this.submitting = false;
    this.steps.replace(steps);
    this.getStepById.cache.clear();
  };

  @action
  moveStep = (sourceId, targetId) => {
    const sourceStep = this.getStepById(sourceId);
    const targetStep = this.getStepById(targetId);
    const sourcePosition = this.steps.indexOf(sourceStep);
    const targetPosition = this.steps.indexOf(targetStep);

    this.steps.splice(sourcePosition, 1);
    this.steps.splice(targetPosition, 0, sourceStep);

    this.dirty = true;
  };

  getStepById = memoize(stepId => {
    return find(this.steps, {id: stepId});
  });

  submit = mutation => {
    if (!this.dirty) {
      return;
    }

    this.setSubmitting(true);

    const stepsAndPositions = this.steps.map((step, stepIndex) => ({
      id: step.id,
      position: stepIndex
    }));

    const guideId = this.steps[0].guideId;

    mutation({guideId, stepsAndPositions})
      .then(() => {
        this.setSubmitting(false);
      })
      .catch(() => {
        this.setSubmitting(false);
      });
  };

  @action updateInstruction = ({updateStepMutation, instructionId, step}) => {
    return updateStepMutation({
      step: {instructionId},
      id: step.id
    }).then(() => {
      // Remove from steps
      this.setSteps(
        this.steps.filter(s => {
          return s.id !== step.id;
        })
      );
    });
  };

  @action
  setSubmitting = value => {
    this.submitting = value;
  };

  getStepIndex = id => {
    return this.steps.findIndex(step => step.id === id);
  };
}

export default DragDropSteps;
