import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'VersionMismatch.title',
    defaultMessage: 'We have updated our app'
  },
  info: {
    id: 'VersionMismatch.info',
    defaultMessage: 'We have updated SwipeGuide. Please refresh your page.'
  },
  refresh: {
    id: 'common.actions.refresh',
    defaultMessage: 'Refresh'
  },
  continue: {
    id: 'VersionMismatch.continue',
    defaultMessage: 'Continue (Not recommended)'
  }
});

export default messages;
