import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';
import {CAMPAIGN_FOLLOW_UP_TYPE_ENUMS} from 'shared/enums';

//components
import Select from 'ui-components/Select';

//messages
import messages from './messages';

@observer
class FollowUpKindPickerComponent extends Component {
  render() {
    const {
      intl: {formatMessage},
      form,
      field,
      label,
      disabled,
      className,
      allowClear
    } = this.props;

    const followUpKinds = [
      {id: CAMPAIGN_FOLLOW_UP_TYPE_ENUMS.TEXT, name: formatMessage(messages.text)},
      {id: CAMPAIGN_FOLLOW_UP_TYPE_ENUMS.TIME, name: formatMessage(messages.time)}
    ];

    return (
      <Select
        label={label || formatMessage(messages.answerType)}
        className={className}
        placeholder={formatMessage(messages.followUpTypePlaceholder)}
        allowClear={allowClear}
        disabled={disabled}
        options={followUpKinds}
        form={form}
        field={field}
      />
    );
  }
}

export default injectIntl(FollowUpKindPickerComponent);
