import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {
  checkUppercaseOnEmail,
  checkUniqueEmail,
  checkValidPhoneNumber,
  checkUniquePhoneNumber,
  getPasswordComplexityChecker
} from 'utils/validation-utils';

const getPlugins = translations => {
  const checkPasswordComplexity = getPasswordComplexityChecker(translations);

  return dvrWithAsync({
    checkValidPhoneNumber: (value, _attr, _key, passes) => checkValidPhoneNumber(value, passes),
    checkUniqueEmail: (value, _attr, _key, passes) => checkUniqueEmail(value, passes),
    checkUniquePhoneNumber: (value, _attr, _key, passes) => checkUniquePhoneNumber(value, passes),
    checkUppercaseOnEmail: (value, _attr, _key, passes) => checkUppercaseOnEmail(value, passes),
    checkPasswordComplexity: (value, _attr, _key, passes) => checkPasswordComplexity(value, passes)
  });
};

const fields = [
  {
    name: 'fullName',
    rules: 'required|string'
  },
  {
    name: 'contactMethod',
    rules: 'string|required',
    value: 'EMAIL'
  },
  {
    name: 'email',
    rules: 'email|string|checkUniqueEmail|checkUppercaseOnEmail'
  },
  {
    name: 'phoneNumber',
    rules: 'string|checkUniquePhoneNumber|checkValidPhoneNumber'
  },
  {
    name: 'password',
    rules: 'string|checkPasswordComplexity'
  },
  {
    name: 'confirmPassword',
    rules: 'string'
  }
];

export const createLocalisedForm = translations => new MobxReactForm({fields}, {plugins: getPlugins(translations)});

export default new MobxReactForm({fields}, {plugins: getPlugins()});
