import {action, observable, makeObservable} from 'mobx';

class NoteIconAffectedGuides {
  @observable opened = false;
  @observable confirmAction = null;
  @observable affectedGuides = [];

  @action
  reset = () => {
    this.opened = false;
    this.confirmAction = null;
    this.affectedGuides = [];
  };

  @action
  open = ({toggleIconVisibilityConfirm, affectedGuides}) => {
    this.confirmAction = toggleIconVisibilityConfirm;
    this.affectedGuides = affectedGuides;
    this.opened = true;
  };

  @action
  submit = async () => {
    await this.confirmAction();
    this.reset();
  };

  constructor() {
    makeObservable(this);
  }
}

export default NoteIconAffectedGuides;
