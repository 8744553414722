import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  addToTeam: {
    id: 'addToTeam',
    defaultMessage: 'Add To Team'
  },
  addUserToTeam: {
    id: 'addUserToTeam',
    defaultMessage: 'Add User to this Team'
  },
  selectAnUser: {
    id: 'selectAnUser',
    defaultMessage: 'Select platform users to collaborate with your team.'
  }
});

export default messages;
