import styled from 'styled-components';

export const Grid = styled.div`
  gap: 10px;
  border-bottom: 1px solid #eee;
`;

export const StyledDiv = styled.div`
  font-size: 18px;
  font-family: Lato;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const LoaderContainer = styled.div`
  margin-top: 200px;
`;
