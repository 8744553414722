import {action, observable, makeObservable} from 'mobx';

class TeamHasPendingApprovalsDialog {
  @observable opened = false;
  @observable handleSubmit;
  @observable content = {};

  @action
  reset = () => {
    this.opened = false;
    this.handleSubmit = null;
    this.content = {};
  };

  @action open = ({callback: handleSubmit, content = {}}) => {
    this.handleSubmit = handleSubmit;
    this.content = content;
    this.opened = true;
  };

  submit = () => {
    const confirmCancellingPendingApprovals = true;
    this.handleSubmit(confirmCancellingPendingApprovals);
    this.reset();
  };

  cancel = () => {
    this.reset();
  };

  constructor() {
    makeObservable(this);
  }
}

export default TeamHasPendingApprovalsDialog;
