import React, {useState} from 'react';
import {injectIntl} from 'react-intl';
import {observer, inject} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import flowRight from 'lodash/flowRight';

import {teamLevelsEditForm} from 'stores/forms/team-levels-edit-form';
import notificationUtils from 'utils/notification-utils';

import {
  SkillLevelContainer,
  SkillLevelLabel,
  SkillLevelName,
  SkillLevelDescription,
  SkillLevelEditIcon,
  StyledButton,
  SkillLevelEditWrapper,
  SkillLevelReadWrapper,
  SkillLevelDetailsWrapper,
  StyledIcon,
  StyledLevelIcon,
  ButtonWrapper,
  Label,
  FlexRow
} from './styles';
import Field from 'ui-components/Field';

//data
import {EditSkillLevel} from 'api/skills/mutations';
import {editSkillLevelOptions} from 'api/skills/mutation-options';

import messages from './messages';

const SkillLevelRead = props => {
  const {level, setEditMode} = props;
  return (
    <SkillLevelReadWrapper>
      <SkillLevelDetailsWrapper>
        <SkillLevelName>{level.name}</SkillLevelName>
        <SkillLevelDescription>{level.description}</SkillLevelDescription>
      </SkillLevelDetailsWrapper>
      <SkillLevelEditIcon onClick={() => setEditMode(true)} data-testid="edit-level">
        <StyledIcon iconId="edit-alt" />
      </SkillLevelEditIcon>
    </SkillLevelReadWrapper>
  );
};

const decorateSkillLevelEdit = flowRight([
  injectIntl,
  inject('store'),
  graphql(EditSkillLevel, editSkillLevelOptions),
  observer
]);
const SkillLevelEdit = decorateSkillLevelEdit(props => {
  const {
    level,
    setEditMode,
    editSkillLevelMutation,
    intl: {formatMessage},
    store: {
      platform: {
        developmentFeatureFlags: {skillLevelDescription}
      }
    }
  } = props;

  const onFormSuccess = async form => {
    const values = form.values();
    const {
      data: {
        editSkillLevel: {result, error}
      }
    } = await editSkillLevelMutation({id: level.id, name: values.name, description: values.description});

    if (!result) {
      if (error === 'name_already_exists') {
        form.$('name').invalidate(formatMessage(messages.errorNameAlreadyUsed));
        return notificationUtils.error(formatMessage(messages.errorNameAlreadyUsedNotification));
      }

      return notificationUtils.error(formatMessage(messages.errorUpdatingSkill));
    }

    notificationUtils.success(formatMessage(messages.skillUpdatedSuccessfully));
    return setEditMode(false);
  };
  const [form] = useState(() =>
    teamLevelsEditForm({name: level.name, id: level.id, description: level.description}, {onSubmit: onFormSuccess})
  );
  const values = form.values();
  return (
    <SkillLevelEditWrapper>
      <Field
        autofocus={true}
        size="small"
        title={`${formatMessage(messages.skillLevelName)}`}
        placeholder={formatMessage(messages.skillLevelName)}
        field="name"
        inputId="name"
        form={form}
        type="text"
        value={values.name}
        maxFeedbackLength={20}
      />
      {skillLevelDescription && (
        <>
          <FlexRow>
            <Label>{formatMessage(messages.skillLevelDescription)}</Label>
            <StyledIcon popoverContent={formatMessage(messages.skillLevelDescriptionExplanation)} />
          </FlexRow>
          <Field
            inputType="textarea"
            maxFeedbackLength={60}
            field="description"
            placeholder={formatMessage(messages.skillLevelDescription)}
            value={values.description}
            form={form}
            styles={{Input: {height: '100px', paddingTop: '10px'}}}
          />
        </>
      )}

      <ButtonWrapper>
        <StyledButton
          type="button"
          secondary={true}
          onClick={() => setEditMode(false)}
          label={formatMessage(messages.cancel)}
        />
        <StyledButton
          type="submit"
          onClick={e => form.onSubmit(e)}
          disabled={!form.isValid}
          label={formatMessage(messages.saveChanges)}
        />
      </ButtonWrapper>
    </SkillLevelEditWrapper>
  );
});

const SkillLevelRow = ({level, showNumbers}) => {
  const [editMode, setEditMode] = useState(false);

  return (
    <SkillLevelContainer>
      <SkillLevelLabel position={level.position} showNumbers={showNumbers}>
        {showNumbers ? level.position : <StyledLevelIcon iconId={level.position ? 'checkmark' : 'close'} />}
      </SkillLevelLabel>
      {editMode ? (
        <SkillLevelEdit setEditMode={setEditMode} level={level} />
      ) : (
        <SkillLevelRead setEditMode={setEditMode} level={level} />
      )}
    </SkillLevelContainer>
  );
};

export default SkillLevelRow;
