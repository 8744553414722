import {action, observable, computed, makeObservable} from 'mobx';
import axios from 'axios';
import Raven from 'raven-js';

import {getTeamsIdsForUserQuery} from 'api/user/queries';

//helpers
import editUserFormForAdmin from 'stores/forms/edit-user-form-for-admin';
import notification from 'utils/notification-utils';
import {sanitizeSimpleObject} from 'utils/object-utils';
import isEqual from 'lodash/isEqual';

class EditUserPage {
  @observable loading = false;
  @observable formLoaded = false;
  @observable editedUserId;
  @observable form = editUserFormForAdmin;
  @observable originalValues = {};
  @observable isPlatformAdmin = false;
  @observable teamsIds = [];
  translations = {};

  @action
  reset = () => {
    this.loading = false;
    this.formLoaded = false;
    this.editedUserId = '';
  };

  @action
  setTeamsIds = teamsIds => (this.teamsIds = teamsIds);

  @action
  setLoading = loading => {
    this.loading = loading;
  };

  @action
  setFormLoaded = loaded => {
    this.formLoaded = loaded;
  };

  @action
  resetForm = () => {
    const {email, fullName, phoneNumber} = this.originalValues;
    this.form.update({
      email,
      fullName,
      phoneNumber
    });
  };

  constructor() {
    makeObservable(this);
  }

  @computed get formChanged() {
    const values = this.form.values();
    values.isPlatformAdmin = this.isPlatformAdmin;

    return !isEqual(this.originalValues, values);
  }

  @action
  startEdit = user => {
    const {email, fullName, phoneNumber, isPlatformAdmin} = user;

    this.form.update({
      email,
      fullName,
      phoneNumber
    });

    this.originalValues = {
      email,
      fullName,
      phoneNumber,
      isPlatformAdmin
    };
    this.isPlatformAdmin = isPlatformAdmin;
    this.editedUserId = user.id;
    this.setFormLoaded(true);
  };

  @action
  togglePlatformAdmin = () => {
    this.isPlatformAdmin = !this.isPlatformAdmin;
  };

  @action
  editUser = async ({editUserMutation, goToUsersList}) => {
    const values = this.form.values();
    if (!this.formChanged) return;

    const {isValid} = await this.form.validate({showErrors: true});
    if (!isValid) {
      notification.error(Object.values(this.form.errors()).join(''));
      this.resetForm();
      return;
    }

    let userEdit = values;
    userEdit = {...userEdit, isPlatformAdmin: this.isPlatformAdmin};
    userEdit = sanitizeSimpleObject(userEdit, ['fullName']);

    this.setLoading(true);

    try {
      await editUserMutation({
        id: this.editedUserId,
        userEdit: userEdit
      });
      notification.success(this.translations.updateSuccess);
      this.originalValues = {...values, isPlatformAdmin: this.isPlatformAdmin};
      goToUsersList && goToUsersList();
    } catch (e) {
      notification.error(this.translations.updateFailure);
      this.resetForm();
    }

    this.setLoading(false);
  };

  @action disableUser = async (disableUserMutation, id) => {
    await this.getUserTeamIds(id);

    disableUserMutation({id}).then(
      () => notification.success(this.translations.disableSuccess),
      () => notification.error(this.translations.disableFailure)
    );
  };

  @action enableUser = async (enableUserMutation, id) => {
    await this.getUserTeamIds(id);

    enableUserMutation({id}).then(
      () => notification.success(this.translations.enableSuccess),
      () => notification.error(this.translations.enableFailure)
    );
  };

  @action setPlatformAdmin = ({editUserMutation}) => {
    this.togglePlatformAdmin();
    this.editUser({editUserMutation});
  };

  @action getUserTeamIds = async id => {
    try {
      const response = await axios.post('/graphql', {query: getTeamsIdsForUserQuery(id)});
      const teamsIds = response.data.data.user.teams.map(team => team.team.id);
      this.setTeamsIds(teamsIds);
    } catch (error) {
      this.setTeamsIds([]);
      Raven.captureException(error);
    }
  };

  setTranslations(translations) {
    this.translations = translations;
  }
}

export default EditUserPage;
