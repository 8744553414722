import React from 'react';
import Raven from 'raven-js';
import {action, observable, computed, toJS, makeObservable} from 'mobx';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import xor from 'lodash/xor';
import map from 'lodash/map';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';

//components
import EditTeamDetails from 'components/EditTeamDetails';
import EditTeamUsers from 'components/EditTeamUsers';
import EditTeamWorkspaces from 'components/EditTeamWorkspaces';
import SetTeamNotifications from 'components/SetTeamNotifications';
import EditTeamApproversView from 'views/EditTeamApproversView';

//helpers
import notification from 'utils/notification-utils';
import store from 'stores/store';
import {TEAM_MEMBER_ROLE} from 'shared/enums';
import {NOTIFICATION_RECIPIENTS, GUIDE_APPROVAL_TYPES} from 'config/enums';
import views from 'config/views';
import {sanitizeSimpleObject} from 'utils/object-utils';
import {client} from 'utils/apollo-client';

//models
import TeamForm from 'stores/forms/team-form';
import notificationsForm from 'stores/forms/team-notifications-form';
import Steps from 'stores/models/steps';

//queries
import {TeamHasPendingApprovals as TeamHasPendingApprovalsQuery} from 'api/team/queries';

const {ALL_IN_TEAM, ALL_WORKING_ON_GUIDE} = NOTIFICATION_RECIPIENTS;

class EditTeamPage {
  @observable loading = false;
  @observable editMode = false;
  @observable canManageTeam = false;
  @observable originalValues = {};
  @observable team;
  @observable guideApproval = null;
  @observable approverIds = [];
  @observable notificationsOption;
  @observable translations = {};
  form = TeamForm;
  notificationsForm = notificationsForm;
  @observable showSaveButton = false;
  showCancelButton = false;
  @observable saveGuideApprovalSettingsMutation = () => {};
  @observable guideApprovalEnabledForPlatform = null;

  @observable offset = 0;
  @observable searchTerm = '';
  limit = 20;

  onSaveGuideApprovalSettings = async () => {
    const {translations} = this;

    return this.checkTeamHasGuidesPendingApproval({
      callback: this.saveGuideApprovalSettings,
      content: {
        title: translations.confirmChange,
        message: translations.guidesPendingApprovalWarning,
        buttonText: translations.confirmAction
      }
    });
  };

  @action setSteps = () => {
    this.stepsStore = new Steps({
      steps: [
        {
          key: 'teamDetails',
          path: 'name',
          component: <EditTeamDetails shouldRenderSaveButton />
        },
        {
          key: 'users',
          path: 'users',
          component: <EditTeamUsers />
        },
        ...(this.guideApprovalEnabledForPlatform
          ? [
              {
                key: 'guideApproval',
                path: 'guide-approval',
                primaryButtonOverrideConfig: {
                  labelL10nKey: 'save',
                  onClick: this.onSaveGuideApprovalSettings
                },
                component: <EditTeamApproversView />
              }
            ]
          : []),
        {
          key: 'notifications',
          path: 'notifications',
          form: this.notificationsForm,
          component: <SetTeamNotifications action="edit" />
        },
        {
          key: 'workspaces',
          path: 'workspaces',
          component: <EditTeamWorkspaces />
        }
      ],
      editMode: true
    });
  };

  @action
  setGuideApprovalFlag = guideApproval => {
    this.guideApprovalEnabledForPlatform = guideApproval;
  };

  @action
  setAllInTeamOption = () => {
    this.notificationsOption = ALL_IN_TEAM;
  };

  @action
  setAllWorkingOnGuideOption = () => {
    this.notificationsOption = ALL_WORKING_ON_GUIDE;
  };

  @action
  setOffset = offset => {
    this.offset = offset;
  };

  @action
  setSearchTerm = searchTerm => {
    this.searchTerm = searchTerm;
  };

  @action
  reset = () => {
    this.loading = false;
    this.editMode = false;
    this.canManageTeam = false;
    this.originalValues = {};
    this.team = null;
    this.translations = {};
    this.showSaveButton = false;
    this.form.reset();
    this.notificationsForm.reset();
    this.offset = 0;
    this.searchTerm = '';
  };

  @action
  setForm = team => {
    const {name, guideApproval, parent} = team;
    const reminderCycle = team.reminderCycle || '0';
    const notificationRecipients = team.notificationRecipients || ALL_IN_TEAM;

    this.form.update({name, guideApproval, parentId: parent?.id});
    this.notificationsForm.update({
      notificationRecipients,
      reminderCycle: reminderCycle.toString()
    });

    this.originalValues = {
      name,
      guideApproval,
      notificationRecipients,
      reminderCycle: reminderCycle.toString(),
      parentId: parent?.id
    };
  };

  @action
  setTeam = (team = {}) => {
    this.team = team;

    // make things consistent, server returns null instead of false on first call
    this.setForm(team);
  };

  @action
  resetForm = () => {
    this.form.update({
      name: this.originalValues.name,
      parentId: this.originalValues.parentId
    });
  };

  @action
  setShowSaveButton = value => {
    this.showSaveButton = value;
  };

  @action
  cancel = team => {
    this.setForm(team);
    this.editMode = false;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get stepsStoreForView() {
    return new Steps({
      steps: [
        {
          key: 'users',
          path: 'users',
          component: <EditTeamUsers viewMode={true} />,
          checked: false
        },
        ...(this.guideApprovalEnabledForPlatform
          ? [
              {
                key: 'guideApproval',
                path: 'guide-approval',
                component: <EditTeamApproversView />,
                checked: false
              }
            ]
          : []),
        {
          key: 'notifications',
          path: 'notifications',
          form: this.notificationsForm,
          component: <SetTeamNotifications action="view" />,
          checked: false
        },
        {
          key: 'workspaces',
          path: 'workspaces',
          component: <EditTeamWorkspaces viewMode={true} />,
          checked: false
        }
      ]
    });
  }

  @computed
  get isGuideApprovalEnabled() {
    return store.platform.guideApproval && Object.values(GUIDE_APPROVAL_TYPES).includes(this.guideApproval);
  }

  @computed
  get isGuideApprovalSequential() {
    return store.platform.guideApproval && this.guideApproval === GUIDE_APPROVAL_TYPES.SEQUENTIAL;
  }

  @computed
  get canUserEditGuideApproval() {
    const {team, usersForApproval} = this;

    if (!team || usersForApproval.length === 0) {
      return false;
    }

    const {canManageTeam} = team;

    return canManageTeam;
  }

  @computed
  get isAtLeastOneApproverSet() {
    return !isEmpty(toJS(this.approverIds));
  }

  @computed
  get isGuideApprovalSelectionValid() {
    return !this.isGuideApprovalEnabled || (this.isGuideApprovalEnabled && this.isAtLeastOneApproverSet);
  }

  @computed
  get shouldDisableGuideApprovalTab() {
    const {isGuideApprovalSelectionValid} = this;

    // any change reflected on this tab should be checked for pending approvals
    const checkForPendingGuideApprovals = this.shouldCheckForPendingGuideApprovals();

    const checks = [!isGuideApprovalSelectionValid, !checkForPendingGuideApprovals];

    return some(checks);
  }

  @computed
  get name() {
    const name = this.form.$('name').value;
    if (name.length > 120) {
      return name.slice(0, 120) + '...';
    } else {
      return name;
    }
  }

  @computed
  get usersForApproval() {
    return (this.team?.teamMembers || [])
      .filter(teamMember => ['ADMIN', 'EDITOR'].includes(teamMember.role))
      .map(({user: {id, fullName: name}, role, isApprover, approverOrder}) => ({
        id,
        name,
        role,
        isApprover,
        approverOrder
      }))
      .sort((a, b) => a.approverOrder - b.approverOrder);
  }

  @computed
  get sortedTeamApproverIds() {
    const teamMembers = this.team?.teamMembers || [];
    const approvers = filter(teamMembers, 'isApprover');
    const sortedApprovers =
      get(this.team, 'guideApproval') === GUIDE_APPROVAL_TYPES.SEQUENTIAL
        ? sortBy(approvers, approver => approver.approverOrder)
        : approvers;
    return map(sortedApprovers, teamMember => teamMember.user.id);
  }

  @computed
  get saveButtonEnabled() {
    return this.loading === false && this.form.isValid;
  }

  @computed
  get formChanged() {
    const {name, guideApproval, parentId} = this.form.values();
    const {reminderCycle, notificationRecipients} = this.notificationsForm.values();
    return !isEqual(this.originalValues, {name, guideApproval, reminderCycle, notificationRecipients, parentId});
  }

  @computed
  get teamNotificationsValuesChanged() {
    const {reminderCycle: originalReminderCycle, notificationRecipients: originalNotificationRecipients} =
      this.originalValues;
    const {reminderCycle, notificationRecipients} = this.notificationsForm.values();

    return originalReminderCycle !== reminderCycle || originalNotificationRecipients !== notificationRecipients;
  }

  @computed
  get guideApprovalHasChanged() {
    if (!this.formChanged || !store.platform.guideApproval) {
      return false;
    } else {
      const {guideApproval} = this.form.values();
      return !isEqual(this.originalValues.guideApproval, guideApproval);
    }
  }

  @computed
  get successNotification() {
    if (this.guideApprovalHasChanged) {
      return this.isGuideApprovalEnabled
        ? this.translations.turnOnGuideApprovalSuccess
        : this.translations.turnOffGuideApprovalSuccess;
    }
    return this.translations.teamUpdateSuccess;
  }

  @computed
  get failureNotification() {
    if (this.guideApprovalHasChanged) {
      return this.isGuideApprovalEnabled
        ? this.translations.turnOnGuideApprovalFailure
        : this.translations.turnOffGuideApprovalFailure;
    } else {
      return this.translations.teamUpdateFailure;
    }
  }

  @computed
  get allTeamMembersAreViewers() {
    return this.team?.teamMember?.every(tm => tm.role === TEAM_MEMBER_ROLE.VIEWER);
  }

  @computed
  get canSetGuideApproval() {
    return (
      store.platform.guideApproval &&
      !this.allTeamMembersAreViewers &&
      this.team &&
      this.team.teamMembers &&
      this.team.teamMembers.length
    );
  }

  @computed get paginatedTeamsVariables() {
    return {
      offset: this.offset,
      limit: this.limit,
      searchTerm: this.searchTerm
    };
  }

  @action toggleIsApprover = (teamMember, updateRoleMutation) => {
    const {role, isApprover, user} = teamMember;
    this.updateTeamMemberRole(updateRoleMutation, user, role, !isApprover);
  };

  @action updateTeamMemberRole = async (
    updateRoleMutation,
    user,
    role,
    skillRoleId,
    isApprover,
    formattedTranslations = {},
    confirmCancellingPendingApprovals
  ) => {
    const {id: userId} = user;
    const {id: teamId} = store.router.params;
    const {warningDialog} = store;
    const updateTeamMemberRole = {role, skillRoleId, userId, teamId, isApprover};
    this.loading = true;
    try {
      const {data} = await updateRoleMutation({updateTeamMemberRole, confirmCancellingPendingApprovals});

      const result = get(data, 'updateTeamMemberRoleWithResult.result');
      const error = get(data, 'updateTeamMemberRoleWithResult.error');

      if (!result) {
        if (error === 'user_is_only_approver_in_team') {
          warningDialog.open({
            content: {
              title: this.translations.cannotChangeRole,
              message: formattedTranslations.onlyApproverInSingleTeamWarning
            }
          });
          return;
        }

        throw error;
      }

      notification.success(this.translations.updateTeamMemberSuccess);
    } catch (e) {
      Raven.captureException(e);
      notification.error(this.translations.updateTeamMemberFailure);
    } finally {
      this.loading = false;
    }
  };

  removeTeamMemberDialogCallback = (response, translations = {}, context = {}) => {
    const {
      auth: {user: me}
    } = store;

    const result = get(response, 'removeTeamMember.result');
    const error = get(response, 'removeTeamMember.error');

    if (!result) {
      if (error === 'user_is_only_approver_in_team') {
        store.warningDialog.open({
          content: {
            title: translations.cannotRemoveUser,
            message: translations.onlyApproverInSingleTeam
          }
        });
        return;
      }

      return notification.error(translations.removeTeamMemberFailure);
    }

    notification.success(translations.removeTeamMemberSuccess);
    if (me.id === context.user.id) {
      store.router.goTo(views.teams, {});
    }
  };

  @action setEditTeamMutation = editTeamMutation => {
    this.editTeamMutation = editTeamMutation;
  };

  @action setSaveGuideApprovalSettingsMutation = saveGuideApprovalSettingsMutation => {
    this.saveGuideApprovalSettingsMutation = saveGuideApprovalSettingsMutation;
  };

  @action setGuideApproval = guideApproval => {
    this.guideApproval = guideApproval;
  };

  @action setApproverIds = approverIds => {
    this.approverIds = approverIds;
  };

  @action onSelectParentTeam = parentId => {
    this.form.update({parentId});
  };

  @action saveGuideApprovalSettings = async (confirmCancellingPendingApprovals = false) => {
    const {id: teamId} = store.router.params;

    const guideApproval = this.guideApproval;
    const approverIds = this.approverIds;

    this.loading = true;
    try {
      await this.saveGuideApprovalSettingsMutation({
        teamId,
        guideApproval: guideApproval || null,
        approverIds,
        confirmCancellingPendingApprovals
      });
      notification.success(this.translations.updateGuideApprovalSettingsSuccess);
      this.loading = false;
    } catch (e) {
      notification.error(this.translations.updateGuideApprovalSettingsFailure);
      console.error(e);
    }
  };

  @action updateTeam = async (editTeamMutation, getEditTeamOptionsForHook, isCreation) => {
    if (!this.formChanged || (isCreation && !this.form.isValid) || !this.notificationsForm.isValid) {
      return;
    }

    const {id} = store.router.params;
    const {name, parentId} = this.form.values();
    const {reminderCycle, notificationRecipients} = this.notificationsForm.values();

    const editTeamData = {
      id,
      name,
      notificationRecipients,
      reminderCycle: Number.parseInt(reminderCycle, 10) || 0,
      parentId: parentId || null
    };

    const sanitizedEditTeam = sanitizeSimpleObject(editTeamData, ['name']);

    this.loading = true;
    try {
      // useMutation hook is used to call function
      if (getEditTeamOptionsForHook) {
        await editTeamMutation(getEditTeamOptionsForHook({editTeam: sanitizedEditTeam}));
      } else {
        // graphql mutation is used to call function
        await editTeamMutation({editTeam: sanitizedEditTeam});
      }

      notification.success(this.translations.teamUpdateSuccess);
      this.originalValues = {
        name,
        notificationRecipients,
        reminderCycle,
        parentId
      };
      this.editMode = false;
      this.loading = false;
    } catch (e) {
      notification.error(this.translations.teamUpdateFailure);
      this.editMode = false;
    }
  };

  @action setEditMode = value => {
    this.editMode = value;
    this.loading = false;
  };

  @action
  checkTeamHasGuidesPendingApproval = async ({callback, content: {title, message, buttonText}}) => {
    try {
      const teamId = get(store, 'router.params.id');

      if (!teamId) {
        throw new Error('team id is not defined');
      }

      const requestResponse = await client.query({
        query: TeamHasPendingApprovalsQuery,
        fetchPolicy: 'network-only',
        variables: {teamId}
      });

      const {result: teamHasPendingApprovalsCheck, error} = get(
        requestResponse,
        'data.teamHasGuideApprovalInProgress',
        {}
      );

      if (error) {
        throw new Error(error);
      }

      if (!teamHasPendingApprovalsCheck) {
        callback();
        return;
      }

      store.teamHasPendingApprovalsDialog.open({callback, content: {title, message, buttonText}});
    } catch (e) {
      notification.error(this.translations.updateTeamMemberFailure);
      Raven.captureException(e);
    }
  };

  getCurrentStepSpecificConfig = ({key: stepKey}) => {
    const {canUserEditGuideApproval, shouldDisableGuideApprovalTab} = this;

    switch (stepKey) {
      case 'guideApproval':
        return {
          shouldHideOverridenPrimaryButton: !canUserEditGuideApproval,
          shouldDisableOverridenPrimaryButton: shouldDisableGuideApprovalTab
        };
      default:
        return undefined;
    }
  };

  goToTeamsPage = () => {
    this.reset();
    store.router.goTo(views.teams, {});
  };

  setTranslations(translations) {
    this.translations = {...this.translations, ...translations};
  }

  shouldCheckForPendingGuideApprovals = () => {
    const {team, guideApproval, approverIds: currentApproverIds, loading} = this;

    if (!team || loading) {
      return false;
    }

    const {guideApproval: originalGuideApprovalType} = team;

    const originalApproverIds = this.sortedTeamApproverIds;

    const hasGuideApprovalBeenTurnedOff = originalGuideApprovalType !== null && guideApproval === null;

    const isApprovalTypeChanged = originalGuideApprovalType !== guideApproval;

    const isApproverSetChanged = !isEmpty(xor(originalApproverIds, currentApproverIds));

    const isApprovalOrderChanged = !isEqual(originalApproverIds, toJS(currentApproverIds));

    const isSequentialAndApproverOrderChanged =
      guideApproval === GUIDE_APPROVAL_TYPES.SEQUENTIAL && isApprovalOrderChanged;

    return some([
      hasGuideApprovalBeenTurnedOff,
      isApprovalTypeChanged,
      isApproverSetChanged,
      isSequentialAndApproverOrderChanged
    ]);
  };
}

export default EditTeamPage;
