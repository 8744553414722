import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Feedback from 'components/Feedback';
import Dialog from 'ui-components/Dialog';

import messages from './messages';
import {TitleWrapper, CloseButton} from './styles';

@inject('store')
@observer
class FeedbackDialogComponent extends Component {
  render() {
    const {
      intl: {formatMessage},
      store: {guideFeedbackDialog: dialog}
    } = this.props;

    return (
      <Dialog
        actions={() => []}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        title={
          <TitleWrapper>
            <div>{formatMessage(messages.title, {title: dialog.guideTitle})}</div>
            <CloseButton onClick={dialog.reset} />
          </TitleWrapper>
        }
      >
        <Feedback />
      </Dialog>
    );
  }
}

export default injectIntl(FeedbackDialogComponent);
