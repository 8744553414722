export const normalizeLocale = localeCode => {
  const hasRightPattern = /[a-z]{2}_[a-zA-Z]{2}/.test(localeCode);
  if (!hasRightPattern) {
    throw new Error(`Argument "${localeCode}" does not follow the correct locale code pattern`);
  }
  const [languageCode, countryCode] = localeCode.split('_');
  return `${languageCode}_${countryCode.toUpperCase()}`;
};

export const formatLanguages = (availableLocales, availableLanguagesDetails) =>
  availableLocales.map(availableLocale => {
    const formattedLanguage = {};

    availableLanguagesDetails.forEach(availableLanguage => {
      if (availableLanguage.locale === availableLocale) {
        formattedLanguage.locale = availableLanguage.locale;
        formattedLanguage.name = availableLanguage.language;
      }
    });

    return formattedLanguage;
  });

export const getLanguageByLocale = (locale, availableLanguagesDetails) =>
  availableLanguagesDetails.find(availableLanguage => availableLanguage.locale === locale).language;
