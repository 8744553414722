import {defineMessages} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'EditCampaign.placeholder',
    defaultMessage: 'Select a question type'
  },
  answerType: {
    id: 'EditCampaign.answerType',
    defaultMessage: 'Answer type'
  },
  star: {
    id: 'EditCampaign.starRating',
    defaultMessage: 'Star rating'
  },
  yesno: {
    id: 'EditCampaign.yesno',
    defaultMessage: 'Yes / No'
  },
  passFail: {
    id: 'EditCampaign.passFail',
    defaultMessage: 'Pass / Fail'
  },
  number: {
    id: 'EditCampaign.number',
    defaultMessage: 'Number'
  },
  text: {
    id: 'EditCampaign.text',
    defaultMessage: 'Multiple choice'
  }
});

export default messages;
