import {action, observable, makeObservable} from 'mobx';

//helpers
import notification from 'utils/notification-utils';
import views from 'config/views';
import store from 'stores/store';
import {commentForm, getComment} from 'stores/forms/comment-form';

class RejectVersionDialog {
  @observable opened = false;
  @observable id;
  @observable comment;
  translations;
  submitReviewMutation;
  form = commentForm();

  @action
  reset = () => {
    this.opened = false;
    this.id = null;
    this.form.reset();
    this.translations = null;
    this.submitReviewMutation = null;
  };

  @action open = ({id, translations, submitReviewMutation}) => {
    this.opened = true;
    this.id = id;
    this.translations = translations;
    this.submitReviewMutation = submitReviewMutation;
  };

  @action rejectVersion = () => {
    const comment = getComment(this.form);
    const review = {
      approved: false,
      ...(comment && {comment})
    };

    const rejectVersionSuccess = () => {
      store.router.goTo(views.editGuide, {id: this.id});
      notification.success(this.translations.reviewSubmitSuccess);
      this.reset();
    };

    const rejectVersionFailure = () => {
      notification.error(this.translations.reviewSubmitFailure);
      this.reset();
    };

    this.submitReviewMutation({
      id: this.id,
      review
    }).then(rejectVersionSuccess, rejectVersionFailure);
  };

  constructor() {
    makeObservable(this);
  }
}

export default RejectVersionDialog;
