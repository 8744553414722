import React from 'react';
import {action, observable, computed, toJS, autorun, makeObservable} from 'mobx';

import {findIndex, find, isEmpty, some, invoke} from 'lodash';

//helpers
import notification from 'utils/notification-utils';
import {sanitizeSimpleObject} from 'utils/object-utils';
import {NOTIFICATION_RECIPIENTS} from 'config/enums';

// components
import Steps from 'stores/models/steps';
import NewTeamCreate from 'components/NewTeamCreate';
import NewTeamUsers from 'components/NewTeamUsers';
import NewConnectTeamToWorkspace from 'components/NewConnectTeamToWorkspace';
import SetTeamNotifications from 'components/SetTeamNotifications';
import NewTeamApproversView from 'views/NewTeamApproversView';

// store
import store from 'stores/store';
import views from 'config/views';

//models
import UserTeamRolesModel from 'stores/models/user-team-roles';

//forms
import teamForm from 'stores/forms/team-form';
import notificationsForm from 'stores/forms/team-notifications-form';
import newTeamWorkspaceForm from 'stores/forms/new-team-workspace-form';

const {ALL_IN_TEAM, ALL_WORKING_ON_GUIDE} = NOTIFICATION_RECIPIENTS;
class teamPage {
  @observable loading = false;
  @observable userTeamRoles = [];
  @observable allUsers = [];
  @observable notificationsOption;
  @observable guideApprovalEnabledForPlatform = null;
  @observable guideApproval = null;
  @observable stepsStore = null;

  @observable offset = 0;
  @observable searchTerm = '';
  limit = 20;

  disposeStepsStoreWatcher = autorun(() => {
    if (this.stepsStore) {
      this.stepsStore.startListeningToRouteChange();
    }
  });

  constructor() {
    makeObservable(this);
  }

  @action
  setSteps() {
    this.stepsStore = new Steps({
      steps: [
        {
          key: 'createNewTeam',
          path: 'name',
          component: <NewTeamCreate />,
          form: this.form,
          checked: false
        },
        {
          key: 'addUsersToTeam',
          path: 'users',
          component: <NewTeamUsers />,
          checked: false
        },
        ...(this.guideApprovalEnabledForPlatform
          ? [
              {
                key: 'setGuideApproval',
                path: 'guide-approval',
                component: <NewTeamApproversView />,
                checked: false
              }
            ]
          : []),
        {
          key: 'setNotificationsForTeam',
          path: 'notifications',
          component: <SetTeamNotifications action="create" />,
          form: this.notificationsForm,
          checked: false
        },
        {
          key: 'connectTeamToWorkspace',
          path: 'workspace',
          component: <NewConnectTeamToWorkspace />,
          checked: false
        }
      ]
    });
  }

  form = teamForm;
  notificationsForm = notificationsForm;
  teamWorkspaceForm = new newTeamWorkspaceForm();
  translations = {};
  minimumFields = 4;
  @observable approverIds = [];

  @action
  setAllInTeamOption = () => {
    this.notificationsOption = ALL_IN_TEAM;
  };

  @action
  setAllWorkingOnGuideOption = () => {
    this.notificationsOption = ALL_WORKING_ON_GUIDE;
  };

  @action
  setUsers = availableUsers => {
    this.allUsers = availableUsers;
  };

  @action onSelectParentTeam = parentId => {
    this.form.update({parentId});
  };

  @action
  setTeamForm = team => {
    const {name, guideApproval, parentId} = team;
    this.form.update({
      name,
      guideApproval,
      parentId
    });
  };

  @action
  setNewForm = () => {
    for (let i = 0; i < this.minimumFields; i++) {
      this.userTeamRoles = [...this.userTeamRoles, new UserTeamRolesModel()];
    }
  };

  @action
  setGuideApprovalFlag = guideApproval => {
    this.guideApprovalEnabledForPlatform = guideApproval;
  };

  @action
  goToTeamsList = () => {
    store.router.goTo(views.teams, {});
    this.reset();
  };

  @action setLoading = val => {
    this.loading = val;
  };

  @computed
  get disableNextButton() {
    const {form, stepsStore, isSelectionValid, isGuideApprovalSelectionValid} = this;
    const {currentStep} = stepsStore;

    const isCurrentStepGuideApproval = currentStep.key === 'setGuideApproval';
    const checks = [form.isValid && !isSelectionValid];

    if (isCurrentStepGuideApproval) {
      checks.push(form.isValid && !isGuideApprovalSelectionValid);
    }

    return some(checks);
  }

  @computed
  get isAddUserRoleEnabled() {
    if (!this.allUsers.length || this.userTeamRoles.length >= this.allUsers.length) return false;
    return this.userTeamRoles.every(userTeamRole => {
      return userTeamRole.form.isValid;
    });
  }

  @computed
  get approverCandidates() {
    const userTeamRolesIds = this.userTeamRoles
      .filter(utr => ['ADMIN', 'EDITOR'].includes(utr.form.$('role').value))
      .map(utr => utr.form.$('userId').value);
    return this.allUsers
      .filter(user => {
        return userTeamRolesIds.includes(user.id);
      })
      .map(user => {
        return {
          id: user.id,
          name: user.fullName
        };
      });
  }

  @computed get paginatedTeamsVariables() {
    return {
      offset: this.offset,
      limit: this.limit,
      searchTerm: this.searchTerm
    };
  }

  @action
  addUserRolePicker = () => {
    this.userTeamRoles.push(new UserTeamRolesModel());
  };

  @action
  removeUserRolePicker = (index, editTeam) => {
    const shouldNotRemoveRolePicker = editTeam && index < 1 && this.userTeamRoles.length === this.minimumFields;

    if (shouldNotRemoveRolePicker) {
      return;
    }

    const {
      addUserToTeamDialog: {decrementUsersRowCount}
    } = store;

    decrementUsersRowCount();

    this.userTeamRoles.splice(index, 1);

    if (!this.userTeamRoles.length) {
      this.addUserRolePicker();
    }
  };

  @action setGuideApproval = value => {
    this.form.$('guideApproval').sync(value);
    this.guideApproval = value;
  };

  @action
  toggleIsApprover = (index, value) => {
    const {form} = this.userTeamRoles[index];
    const {isApprover} = form.values();
    const newValue = value || !isApprover;
    if (form.isValid) {
      form.$('isApprover').sync(newValue);
    }
    if (!this.isAtLeastOneApproverSet && this.isOnNewTeamPage) {
      this.setApproverIds(null);
    }
  };

  @action
  setApproverIds = approverIds => {
    this.approverIds = approverIds;
  };

  @computed
  get areSelectionsValid() {
    const totalValidUsersRoles = this.userTeamRoles.filter(userTeamRole => userTeamRole.form.isValid).length;
    const totalUserTeamRoles = this.userTeamRoles.length + 1 - this.minimumFields;

    return totalValidUsersRoles === totalUserTeamRoles;
  }

  @computed
  get userRoleSelection() {
    return this.userTeamRoles
      .filter(userTeamRole => userTeamRole.form.isValid)
      .map(userTeamRole => userTeamRole.form.values());
  }

  @computed
  get isOnNewTeamPage() {
    return store.router.currentRoute.path === 'newTeam';
  }

  @computed
  get selectedIds() {
    return this.userTeamRoles.map(userTeamRole => {
      return userTeamRole.form.values().userId;
    });
  }

  @action
  filterUsers = userId => {
    const filteredIds = userId ? this.selectedIds.filter(id => id !== userId) : this.selectedIds;
    return this.allUsers.filter(user => !filteredIds.includes(user.id));
  };

  @computed
  get isGuideApprovalSelectionValid() {
    return !this.isGuideApprovalEnabled || (this.isGuideApprovalEnabled && this.isAtLeastOneApproverSet);
  }

  @computed
  get isSelectionValid() {
    return this.userTeamRoles.every(({form}) => {
      const {userId, role} = form.values();
      return form.isValid || (!userId && !role);
    });
  }

  @computed
  get isGuideApprovalEnabled() {
    return ['PARALLEL', 'SEQUENTIAL'].includes(this.form.values().guideApproval);
  }

  @computed
  get isAtLeastOneApproverSet() {
    return !isEmpty(toJS(this.approverIds));
  }

  @action
  setOffset = offset => {
    this.offset = offset;
  };

  @action
  setSearchTerm = searchTerm => {
    this.searchTerm = searchTerm;
  };

  @action
  reset = () => {
    this.loading = false;
    this.userTeamRoles = [];
    this.allUsers = [];
    this.approverIds = [];
    this.guideApproval = null;
    this.offset = 0;
    this.searchTerm = '';

    this.form.reset();

    this.notificationsForm.reset();
    this.teamWorkspaceForm.reset();
    // this.stepsStore may be null
    invoke(this.stepsStore, 'reset');
    store.addUserToTeamDialog.resetUserRowCount();
  };

  @computed
  get showSaveButton() {
    return this.stepsStore.currentStepIndex === this.stepsStore.steps.length - 1;
  }

  @computed get showCancelButton() {
    return !this.stepsStore.currentStepIndex;
  }

  @action
  submit = async ({createTeamMutation}) => {
    const {name, guideApproval, parentId} = this.form.values();
    const {reminderCycle, notificationRecipients} = this.notificationsForm.values();

    const newTeamData = {
      name,
      guideApproval: guideApproval || null,
      notificationRecipients,
      reminderCycle: Number.parseInt(reminderCycle, 10) || 0,
      parentId: parentId || null
    };

    const sanitizedNewTeam = sanitizeSimpleObject(newTeamData, ['name']);

    this.setLoading(true);
    try {
      const {domainIds} = this.teamWorkspaceForm.values();

      const teamMembersList = this.userRoleSelection
        .slice()
        .map(value => ({...value, skillRoleId: value.skillRoleId || null}));
      const orderedApprovers = [];

      this.approverIds.forEach(approverId => {
        const foundTeamMember = find(teamMembersList, member => member.userId === approverId);
        const foundTeamMemberIndex = findIndex(teamMembersList, member => member.userId === approverId);

        foundTeamMember.isApprover = true;
        orderedApprovers.push(foundTeamMember);
        teamMembersList.splice(foundTeamMemberIndex, 1);
      });

      const teamMembers = [...orderedApprovers, ...teamMembersList];

      await createTeamMutation({newTeam: sanitizedNewTeam, teamMembers, domainIds});
      notification.success(this.translations.createSuccess);
      this.goToTeamsList();
      this.reset();
    } catch (e) {
      this.setLoading(false);
      const {graphQLErrors} = e;
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          if (err.extensions.code === 'MAX_TEAMS') {
            return notification.error(this.translations.createFailureMaxTeams);
          }
        }
      }

      notification.error(this.translations.createFailure);
      console.error(e);
    }
  };

  @action
  submitNewTeamMembers = async ({createTeamMemberMutation, goToTeamUsersList, teamId}) => {
    const values = this.userRoleSelection.slice();
    const createTeamMembers = values.map(value => ({...value, teamId, skillRoleId: value.skillRoleId || null}));
    this.setLoading(true);

    // If there is no users selected, don't do anything
    if (createTeamMembers.length === 0) {
      goToTeamUsersList();
      this.reset();
      return;
    }

    try {
      await createTeamMemberMutation({createTeamMembers});
      if (createTeamMembers.length > 1) {
        notification.success(this.translations.createMultipleSuccess);
      } else {
        notification.success(this.translations.createSuccess);
      }
      goToTeamUsersList();
      this.reset();
    } catch (e) {
      this.setLoading(false);
      if (createTeamMembers.length > 1) {
        notification.error(this.translations.createMultipleFailure);
      } else {
        notification.error(this.translations.createFailure);
      }
      console.error(e);
    }
  };

  @action
  submitNewWorkspace = async (addWorkspacesMutation, teamId, goToTeamWorkspacesList) => {
    const {domainIds} = this.teamWorkspaceForm.values();
    this.setLoading(true);

    // If there is no domainIds, don't do anything
    if (isEmpty(domainIds)) {
      goToTeamWorkspacesList();
      this.reset();

      return;
    }

    try {
      await addWorkspacesMutation({teamId, domainIds});
      notification.success(this.translations.addWorkspaceSuccess);
      goToTeamWorkspacesList();
      this.reset();
    } catch (e) {
      this.setLoading(false);
      notification.error(this.translations.addWorkspaceFailure);
      console.error(e);
    }
  };

  setTranslations = translations => {
    this.translations = translations;
  };

  pickDefaultDomain = domains => {
    const defaultDomain = find(domains, {isDefault: true});

    if (defaultDomain) {
      this.teamWorkspaceForm.$('domainIds').sync([defaultDomain.id]);
    }
  };
}

export default teamPage;
