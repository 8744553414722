import {defineMessages} from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'ThemePicker.placeholder',
    defaultMessage: 'Select a theme'
  }
});

export default messages;
