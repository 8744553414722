import {defineMessages} from 'react-intl';

const messages = defineMessages({
  assignees: {
    id: 'GuideListFilters.assignees',
    defaultMessage: 'Assignees'
  },
  filterByAssignee: {
    id: 'GuideListFilters.filterByAssignee',
    defaultMessage: 'Filter by Assignee'
  },
  filterByTag: {
    id: 'GuideListFilters.filterByTag',
    defaultMessage: 'Filter by Tag'
  },
  filterByTeam: {
    id: 'GuideListFilters.filterByTeam',
    defaultMessage: 'Filter by Team'
  },
  tags: {
    id: 'GuideListFilters.tags',
    defaultMessage: 'Tags'
  },
  teams: {
    id: 'GuideListFilters.teams',
    defaultMessage: 'Teams'
  },
  workspaces: {
    id: 'EditCampaign.workspaces',
    defaultMessage: 'Workspaces'
  },
  filterByWorkspace: {
    id: 'GuideListFilters.filterByWorkspace',
    defaultMessage: 'Filter by workspace'
  },
  languages: {
    id: 'StepNoteIconDialog.headerLanguages',
    defaultMessage: 'Languages'
  },
  filterByLanguage: {
    id: 'GuideListFilters.filterByLanguage',
    defaultMessage: 'Filter by language'
  }
});

export default messages;
