import styled from 'styled-components';

//components
import RatioContainer from 'shared/components/RatioContainer';
import {Toggle as $Toggle} from 'styles/styled-components/form-styled-components';
import $Select from 'ui-components/Select';

export const MediaPreview = styled.div`
  margin: 0 0 1rem;
`;

export const Toolbar = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 1rem;
`;

export const LargeLabel = styled.div`
  opacity: 0.5;
  width: 40%;
`;

export const SmallLabel = styled.div`
  font-size: 14px;
  margin: 1rem;
  opacity: 0.5;
`;

export const Select = styled($Select)`
  flex-grow: 1;
`;

export const Toggle = styled($Toggle)`
  &.ant-radio-group {
    flex-shrink: 0;
  }
`;

export const NotSelected = styled.div`
  align-items: center;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  display: flex;
  height: 195px;
  justify-content: center;
  padding: 1rem;
  width: 260px;
  @media (max-width: 700px) {
    padding: 0;
    width: 200px;
    height: 150px;
  }
  @media (max-width: 550px) {
    width: 100px;
    height: 100px;
    font-size: 10px;
  }
`;

export const SinglePreview = styled.div`
  max-width: 260px;
`;

export const DualPreview = styled.div`
  align-items: start;
  display: flex;
`;

export const DualPreviewPortrait = styled(RatioContainer)`
  flex-grow: 1;
`;

export const DualPreviewLandscape = styled(RatioContainer)`
  flex-grow: 2;
  margin-left: 10px;
`;
