import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {getCurrentHostname} from 'shared/utils/host-utils';

const asyncRules = {
  checkShareLink: (value, attr, key, passes) => {
    if (value.includes(getCurrentHostname())) {
      const isPassing = value.includes('/share') && !value.includes('/admin');
      passes(
        isPassing,
        isPassing
          ? undefined
          : 'Incorrect guide link. Make sure to copy the URL for the correct workspace in your SwipeGuide Instruction Builder.'
      );
    }
  }
};

const fields = [
  {
    name: 'title',
    rules: 'required|string'
  },
  {
    name: 'content',
    rules: 'string'
  },
  {
    name: 'resourceUrl',
    rules: 'string|checkShareLink'
  },
  {
    name: 'resourceType',
    rules: 'string'
  },
  {
    name: 'tagsIds',
    rules: 'array',
    value: []
  }
];

const plugins = dvrWithAsync(asyncRules);
export const form = new MobxReactForm({fields}, {plugins});
