import styled from 'styled-components';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OptionWrapper = styled.div``;

export const CaptionText = styled.div`
  margin-left: 10px;
`;
