import React, {Component} from 'react';
import {findDOMNode} from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

//helpers
import {getDisplayName} from 'shared/utils/decorator-utils';

/**
 * Provides the elements with the scrollbars
 */
export default ({overflowX = 'auto', overflowY = 'auto'} = {}) =>
  WrappedComponent => {
    const StyledComponent = styled(WrappedComponent)`
      -webkit-overflow-scrolling: touch;
      overflow-x: ${overflowX};
      overflow-y: ${overflowY};
    `;

    return class extends Component {
      static childContextTypes = {
        getScrollParent: PropTypes.func
      };

      static displayName = getDisplayName(WrappedComponent, 'ScrollParent');

      getChildContext = () => ({
        getScrollParent: () => findDOMNode(this.scrollParentRef)
      });

      scrollParentRef = null;

      setScrollParentRef = ref => {
        this.scrollParentRef = ref;
      };

      render() {
        return <StyledComponent {...this.props} ref={this.setScrollParentRef} />;
      }
    };
  };
