import React from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//styled-components
import {Field} from './styles';

//messages
import messages from './messages';

import ParentTeamSelector from 'components/ParentTeamSelector';

const NewTeamCreate = props => {
  const {
    store: {
      teamPage,
      platform: {
        developmentFeatureFlags: {teamLevels}
      },
      auth: {
        user: {canManageSkills}
      }
    },
    intl: {formatMessage}
  } = props;
  const {form, paginatedTeamsVariables} = teamPage;
  const canSelectTeam = canManageSkills && teamLevels;

  return (
    <div style={{maxWidth: '500px'}}>
      <Field size="small" autofocus={true} title={formatMessage(messages.teamName)} field="name" form={form} />
      {canSelectTeam && <ParentTeamSelector teamPage={teamPage} paginatedTeamsVariables={paginatedTeamsVariables} />}
    </div>
  );
};

export default injectIntl(inject('store')(observer(NewTeamCreate)));
