import messages from './messages';

export const groupBySkillSet = skillList => {
  if (!skillList) return {};

  const skillSets: {
    other?: {
      skills: Record<string, any>[];
      skillSet: {
        id: string;
        name: string;
      };
    };
  } = {};

  skillList.forEach(skill => {
    if (!skill.skillSets || skill.skillSets.length === 0) {
      // If skill has no SkillSet – push it to 'Other' SkillSet
      if (skillSets.other) {
        skillSets.other.skills.push(skill);
      } else {
        skillSets.other = {
          skills: [skill],
          skillSet: {
            id: messages.skills.id,
            name: messages.skills.defaultMessage
          }
        };
      }
    } else {
      (skill.skillSets || []).forEach(skillSet => {
        if (!skillSet) return;

        const skillSetEntry = skillSets[skillSet.id];
        if (skillSetEntry) {
          skillSetEntry.skills.push(skill);
        } else {
          skillSets[skillSet.id] = {
            skills: [skill],
            skillSet
          };
        }
      });
    }
  });

  return skillSets;
};
