import {makeAutoObservable, action, observable} from 'mobx';
import {client} from 'utils/apollo-client';

import {GET_TOKENS} from 'api/platform/queries';

import Raven from 'raven-js';
import notification from 'utils/notification-utils';

class TokenPage {
  tokens = [];
  currentToken = {
    jti: null,
    token: null
  };
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {
      tokens: observable,
      currentToken: observable,
      loading: observable,
      fetchTokens: action.bound,
      addToken: action.bound,
      removeToken: action.bound,
      setTokens: action.bound,
      setCurrentToken: action.bound,
      resetCurrentToken: action.bound,
      setLoading: action
    });
  }

  async fetchTokens() {
    this.setLoading(true);

    try {
      const result = await client.query({
        query: GET_TOKENS,
        fetchPolicy: 'network-only'
      });

      if (result.data?.tokens) {
        this.setTokens(result.data.tokens);
      }
    } catch (error) {
      notification.error(`Failed to fetch tokens: ${error.message}`);
      Raven.captureException(error);
    }

    this.setLoading(false);
  }

  setTokens(tokens) {
    this.tokens = [...tokens];
  }

  setCurrentToken(token) {
    this.currentToken = token;
  }

  resetCurrentToken() {
    this.currentToken = {
      jti: null,
      token: null
    };
  }

  setLoading = loading => {
    this.isLoading = loading;
  };

  addToken(token) {
    this.tokens = [...this.tokens, token];
    this.setCurrentToken({jti: token.jti, token: token.token});

    return this.fetchTokens();
  }

  removeToken(removedJti) {
    const tokenToRemove = this.tokens.find(token => token.jti === removedJti);
    if (tokenToRemove && tokenToRemove.jti === this.currentToken.jti) {
      this.resetCurrentToken();
    }
    return this.fetchTokens();
  }
}

export default TokenPage;
