import styled from 'styled-components';

//components
import $Icon from 'ui-components/Layout/Icon';

export const NotFoundMessage = styled.div`
  flex: 1;
  position: relative;
`;

export const CenteredWrapper = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
`;

export const WarningIcon = styled($Icon).attrs({
  name: 'warning',
  size: 32
})`
  opacity: 0.2;
`;

export const Message = styled.div`
  font-size: 14px;
  margin-top: 16px;
  opacity: 0.7;
`;
