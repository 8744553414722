import {defineMessages} from 'react-intl';

const messages = defineMessages({
  ok: {
    id: 'IconsLimitDialog.confirmation',
    defaultMessage: 'OK'
  },
  title: {
    id: 'IconsLimitDialog.title',
    defaultMessage: 'Disable a Step Note'
  },
  limitExceeded: {
    id: 'IconsLimitDialog.limitExceeded',
    defaultMessage: 'You can’t have more than 5 Step Notes visible'
  },
  disableIcon: {
    id: 'IconsLimitDialog.disableIcon',
    defaultMessage: 'Disable one or more Step Notes to make this one visible'
  }
});

export default messages;
