import {defineMessages} from 'react-intl';

const messages = defineMessages({
  newCampaignTitle: {
    id: 'NewSignOff.newCampaignTitle',
    defaultMessage: 'Create Sign-off'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  next: {
    id: 'common.actions.next',
    defaultMessage: 'Next'
  },
  continueEditing: {
    id: 'EditCampaign.continueEditing',
    defaultMessage: 'Continue Editing'
  },
  createSuccess: {
    id: 'NewSignOff.createSuccess',
    defaultMessage: 'Sign-off has been created.'
  },
  createFailure: {
    id: 'NewSignOff.createFailure',
    defaultMessage: 'Failed to create a sign-off.'
  },
  preview: {
    id: 'common.actions.preview',
    defaultMessage: 'Preview'
  },
  template: {
    id: 'NewCampaign.template',
    defaultMessage: 'Choose template'
  },
  questions: {
    id: 'EditCampaign.questions',
    defaultMessage: 'Questions'
  },
  seePreview: {
    id: 'EditCampaign.seePreview',
    defaultMessage: 'See Preview'
  },
  goToTemplates: {
    id: 'EditCampaign.goToTemplates',
    defaultMessage: 'Back to templates'
  }
});

export default messages;
