import {maxNumberOfRowsPerPage} from 'ui-components/Layout/DataTable/constants';
import {AllSkillTasks} from '../skills/queries';

export const refreshSkillsTasks = () => {
  return {
    query: AllSkillTasks,
    variables: {
      offset: 0,
      limit: maxNumberOfRowsPerPage
    }
  };
};
