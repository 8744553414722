import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';
import $Button from 'ui-components/Button';
import {commonPalette} from 'shared/styles/palette';
import FontAwesomeIcon from '../../ui-components/Layout/FontAwesomeIcon';

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 32px;
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 32
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const ActivityListBackIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 22
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  border-top: 1px solid #eee;
  gap: 250px;
  @media (max-width: 820px) {
    gap: 170px;
  }
  @media (max-width: 740px) {
    gap: 110px;
  }
`;

export const SecondWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
`;
export const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-bottom: 20px;
`;

export const StyledText = styled.div`
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
`;

export const StyledSubText = styled.div`
  color: var(--neutrals-grey, ${commonPalette.txGrey});
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  ${({isEmptyState}) => (isEmptyState ? '' : 'width: 60%')}
`;

export const StyledButton = styled($Button).attrs({
  underlined: true
})`
  padding-bottom: 1.5rem;
  text-decoration: none;
  height: 1%;
  color: ${commonPalette.btnGreen};
  &:hover,
  :focus {
    text-decoration: none;
  }
`;

export const StyledDots = styled.div`
  color: var(--neutrals-grey, ${commonPalette.txGrey});
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DrawerWrapper = styled.div`
  background: ${commonPalette.white};
  position: relative;
  max-width: 90vw;
  width: 500px;
`;

export const DrawerTitle = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  padding-right: 40px;
  padding-bottom: 10px;
`;

export const DrawerTitleButton = styled.button`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 40px;
  margin-bottom: 10px;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;

export const InputDiv = styled.div`
  display: flex;
  height: 44px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid #d9d9d9;
  background: var(--surface-default, ${commonPalette.white});
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const SearchIcon = styled(FontAwesomeIcon).attrs({
  actionId: 'search',
  backgroundColor: `${commonPalette.txtGrey}`
})`
  font-size: 10px;
  background-color: ${commonPalette.txGrey};
`;

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  ${({hasGap}) => (hasGap ? 'justify-content: space-between;' : '')}
  @media (max-width: 450px) {
    ${({hasGap}) => (hasGap ? 'justify-content: space-between;' : '')}
  }
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 85px;
  gap: 10px;
`;

export const StyledActivitiesDiv = styled.div`
  color: ${commonPalette.txBlack};
  width: 100%;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({isSelectable}) => (isSelectable ? ' cursor: pointer;' : '')}
`;

export const Activity = styled.div`
  display: flex;
  flex-direction: row;
  height: 44px;
  padding: 16px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border: 1px solid #d9d9d9;
  background: var(--surface-default, ${commonPalette.white});
`;

export const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  height: 100%;
`;

export const ActivitiesBackground = styled.div`
  display: flex;
  width: 500px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: ${commonPalette.lightGrey};
  border-top: 1px solid #eee;
  margin-bottom: 10px;
  overflow: scroll;
`;

export const DeleteAll = styled.div`
  color: ${commonPalette.btnGreen};
  cursor: pointer;
  margin-left: 430px;
  margin-top: 15px;
`;

export const StyledTitle = styled.div`
  color: var(--text-primary, #333330);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledLink = styled.a`
  display: flex;
  height: 44px;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
export const StyledContent = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: ${commonPalette.txGrey};
  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const FixedDrawerTitle = styled.div`
  background: var(--surface-default, ${commonPalette.white});
  width: 500px;
  position: fixed;
  top: 0;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const FixedButtonWrapper = styled.div`
  background: var(--surface-default, ${commonPalette.white});
  width: 500px;
  position: fixed;
  bottom: 0;
  padding-bottom: 10px;
  padding-top: 10px;
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-left: 100px;
  ${({hasMoreSelectedTags}) => (hasMoreSelectedTags ? 'margin-bottom: 100px' : 'margin-bottom: 50px')};
`;
