import styled from 'styled-components';

import Field from 'ui-components/Field';
import $Checkbox from 'components/Checkbox';

import {darken} from 'shared/utils/color-utils';
import {commonPalette} from 'shared/styles/palette';

export const UserNameField = styled(Field).attrs({
  styles: {Input: {fontSize: '32px', fontWeight: 300}}
})`
  margin-bottom: 10px;
  width: fit-content;
`;

export const EmailField = styled(Field)`
  width: fit-content;
`;

export const Checkbox = styled($Checkbox)`
  margin-top: 20px;
`;

export const Caption = styled.div``;

export const DivWrapper = styled.div`
  margin: 10px 0;
`;

export const MainInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SSOIdWrapper = styled.div`
  margin: 10px 0;
  padding: 10px;
  background-color: ${darken(commonPalette.gray, 0.1)};
  display: inline-block;
`;

export const ssoIconStyle = {
  marginRight: 10,
  cursor: 'pointer',
  color: commonPalette.txGrey
};
