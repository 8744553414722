import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/Spinner';

import {StyledAnchor, StyledButton, StyledLink, Label} from './styles';
import Icon from '../Icon';
import {Popover} from 'antd';

export default class Button extends Component {
  static defaultProps = {
    disabled: false,
    iconOnRight: false,
    loading: false,
    params: {},
    solidIcon: true,
    round: false,
    secondary: false,
    size: 60,
    transition: true,
    underlined: false,
    shouldSpinIcon: false
  };

  static propTypes = {
    className: PropTypes.string,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    iconId: PropTypes.string,
    iconOnRight: PropTypes.bool,
    iconStyle: PropTypes.object,
    buttonStyle: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    params: PropTypes.object,
    popoverContent: PropTypes.string,
    popoverButtonContent: PropTypes.string,
    popoverPlacement: PropTypes.string,
    round: PropTypes.bool,
    secondary: PropTypes.bool,
    size: PropTypes.number,
    solidIcon: PropTypes.bool,
    router: PropTypes.object,
    transition: PropTypes.bool,
    type: PropTypes.oneOf(['button', 'reset', 'submit']),
    underlined: PropTypes.bool,
    route: PropTypes.object,
    shouldSpinIcon: PropTypes.bool
  };

  getRootComponent = () => {
    const {href, route} = this.props;

    if (route) {
      return StyledLink;
    }

    if (href) {
      return StyledAnchor;
    }

    return StyledButton;
  };

  render() {
    const {
      underlined,
      loading,
      round,
      label,
      iconId,
      dataCy,
      disabled,
      solidIcon,
      popoverContent,
      popoverPlacement,
      popoverButtonContent,
      iconStyle,
      buttonStyle,
      shouldSpinIcon,
      ...forwardProps
    } = this.props;
    const RootComponent = this.getRootComponent();
    const showRound = !underlined && round;

    if (round && underlined) {
      console.error(
        'Invalid button style: button cannot receive both underlined and round styles. It will default to underlined'
      );
    }

    const rootComponent = (
      <RootComponent
        disabled={disabled || loading || shouldSpinIcon}
        underlined={underlined}
        round={showRound}
        icon={iconId}
        label={label}
        style={buttonStyle}
        data-cy={dataCy || iconId}
        {...forwardProps}
      >
        {iconId && (
          <Icon
            iconId={iconId}
            solid={solidIcon}
            popoverContent={popoverContent}
            popoverPlacement={popoverPlacement}
            style={iconStyle}
            shouldSpin={shouldSpinIcon}
          />
        )}
        {!showRound && <Label id="button-label">{label}</Label>}
        {loading && <Spinner size={25} />}
      </RootComponent>
    );
    const popoverComponent = <Popover content={popoverButtonContent}>{rootComponent}</Popover>;
    return popoverButtonContent && disabled ? popoverComponent : rootComponent;
  }
}
