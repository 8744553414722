import get from 'lodash/get';

export const DND_TYPE = 'STEP';

export const source = {
  beginDrag: ({step}) => ({
    step,
    sourceId: step.id
  }),
  endDrag: (props, monitor) => {
    const {repositionStepsMutation, updateStepMutation, store, step} = props;
    const {dragDropSteps: page} = store;

    // Check if it was a drop on instruction
    const instructionId = get(monitor.getDropResult(), 'instruction.id');
    if (instructionId && instructionId !== step.instructionId) {
      return page.updateInstruction({updateStepMutation, instructionId, step});
    }

    return page.submit(repositionStepsMutation);
  },
  canDrag: props => !props.store.dragDropItems.submitting
};

export const target = {
  hover: (props, monitor) => {
    const {store, step} = props;
    const {dragDropSteps: page} = store;

    const item = monitor.getItem();
    const {sourceId} = item;

    if (sourceId !== step.id) {
      page.moveStep(sourceId, step.id);
    }
  }
};
