import styled from 'styled-components';
import $Button from '../Button';
import $Chip from '../Chip';
import $Icon from '../Icon';
import {Popover as $Popover} from 'antd';
import {commonPalette} from 'shared/styles/palette';
import {noSelect, ellipsis} from 'shared/utils/sc-utils';
import $Checkbox from 'components/Checkbox';

export const SelectWrapper = styled.div`
  display: ${({display}) => (display ? display : 'flex')};
  flex-direction: ${p => (p.underlined ? 'row' : 'column')};
  align-items: ${p => (p.underlined ? 'center' : 'flex-start')};
  position: relative; // to preserve popover position while scrolling
  width: 100%;

  ${() => Label} {
    margin: 0 0 4px;

    ${p =>
      p.underlined &&
      `
      color: ${commonPalette.txGrey};
      margin: 0 10px 0 0;

      :after {
        content: ':';
      }
    `}
  }
`;

export const Button = styled($Button)``;

export const Icon = styled($Icon)``;

export const Chip = styled($Chip)`
  margin: 0 8px 7px 0;
`;

export const Text = styled.span`
  margin-bottom: 8px;
  margin-right: 3px;
`;

export const Label = styled.label``;

export const UnderlinedButton = styled($Button).attrs({
  underlined: true
})`
  > svg {
    margin-left: 10px;
    transform: rotate(0deg);
    transition: transform 0.2s linear;

    ${p =>
      p.open &&
      `
      transform: rotate(180deg);
      transition: transform 0.2s linear;
    `}
  }
`;

export const Select = styled.div`
  width: ${({width}) => (width ? `${width}px` : '100%')};
  height: 40px;
  border: 1px solid ${commonPalette.borderGrey};
  border-radius: 4px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: border-color 0.2s easy-in-out;
  cursor: pointer;
  background-color: #fff;
  overflow: hidden;

  ${p =>
    p.disabled &&
    `
    background-color: ${commonPalette.lightGrey};
    color: ${commonPalette.txLightGrey};
    cursor: default;
  `}

  ${p =>
    p.showSearch &&
    `
    cursor: text;
  `}

  ${() => Button} {
    > svg {
      margin: 0 0 0 10px;
      transform: rotate(0deg);
      transition: transform 0.2s linear;

      ${p =>
        p.open &&
        `
        transform: rotate(180deg);
        transition: transform 0.2s linear;
      `}
    }
  }
`;

export const MultiSelector = styled(Select)`
  justify-content: flex-start;
  padding-top: 7px;
  flex-wrap: wrap;
  height: auto;
  min-height: 40px;

  ${() => UserInput} {
    width: auto;
    margin-bottom: 8px;
  }
`;

export const UserInput = styled.input`
  border: none;
  width: 88%;
  background: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${commonPalette.txLightGrey};
  }
`;

export const Popover = styled($Popover)``;

export const OptionsWrapper = styled.div`
  max-height: 200px;
  overflow: auto;
`;

export const Option = styled.div`
  ${noSelect}
  ${ellipsis}
  height: 40px;
  font-size: 14px;
  padding: 0 16px;
  text-align: left;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 100ms linear;
  color: ${p => (p.selected && !p.popover ? commonPalette.sgGreen : commonPalette.txBlack)};
  font-weight: ${p => (p.selected || !p.popover ? 400 : 300)};

  ${p =>
    p.disabled &&
    `
    color: ${commonPalette.txLightGrey};
    cursor: default;
  `}

  &:hover {
    background-color: ${commonPalette.lightGrey};
  }

  .icon {
    margin: 0 0 0 auto;
    ${p => !p.popover && `color: ${commonPalette.sgGreen};`}
  }

  ${p =>
    p.popover &&
    `

    &:after {
      content:"";
      position: absolute;
      bottom: 0;
      left: 16px;
      right: 16px;
      border-bottom: 1px solid #e0e0e0;
    }

    &:last-child:after {
      border-bottom: none;
    }
  `}

  ${() => Icon} {
    margin-right: 8px;
  }
`;

export const StyledCheckbox = styled($Checkbox)`
  color: ${commonPalette.txBlack};
  margin: 20px 10px;
`;
