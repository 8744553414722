import styled from 'styled-components';
import $Icon from 'react-fontawesome';
import flex from 'styles/flex';
import {commonPalette} from 'shared/styles/palette';
import {borderRadius} from 'styles/sizes';
import {withoutLastOfType, noSelect} from 'shared/utils/sc-utils';

export const Overlay = styled.div`
  ${flex.horizontal} ${flex.justifyEnd} transition: all 200ms linear;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  padding: 15px;
  overflow: visible;
  z-index: 99999;
`;

export const Menu = styled.div`
  width: 160px;
  background-color: white;
  position: absolute;
  top: 51px;
  right: 15px;
  z-index: 9999;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  cursor: default;
  ${noSelect};
`;

export const MenuItem = styled.div.attrs({
  className: 'menu-item'
})`
  ${flex.vertical} ${flex.centerVerticalV} transition: all 100ms linear;
  height: 40px;
  font-size: 14px;
  color: #2f3133;
  padding: 0 16px;
  cursor: pointer;
  text-align: center;

  &:hover {
    background-color: ${commonPalette.lightGrey};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    border-bottom: 1px solid #e0e0e0;
  }

  &:last-child:after {
    border-bottom: none;
  }

  ${noSelect};
`;

export const Button = styled.button`
  transition: all 150ms linear;
  color: white;
  border: 1px solid white;
  border-radius: ${borderRadius}px;
  padding: 0 15px;
  font-weight: 300;
  font-size: 12px;
  height: 32px;
  background: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #323232;
  }

  ${withoutLastOfType('margin-right: 8px;', 'margin-right: 0;')};
`;

export const Icon = styled($Icon)`
  color: white;
`;
