import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectPlaceholder: {
    id: 'LanguagePicker.selectPlaceholder',
    defaultMessage: 'Select default language'
  },
  defaultLanguage: {
    id: 'common.forms.defaultLanguage',
    defaultMessage: 'Default Language'
  }
});

export default messages;
