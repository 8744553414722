import styled from 'styled-components';

import Select from 'ui-components/Select';

import {commonPalette} from 'shared/styles/palette';

export const AutoRemovalSection = styled.div`
  margin-bottom: 8px;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const StyledRemoveGuidesSelect = styled(Select)`
  width: 200px;
  margin-right: 8px;
`;

export const Header = styled.div`
  font-weight: 600;
  margin-bottom: 8px;
`;

export const RemoveGuidesSection = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSpan = styled.span`
  margin-right: 8px;
  color: ${({disabled}) => (disabled ? commonPalette.txLightGrey : commonPalette.txBlack)};
`;
