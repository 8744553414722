import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

//helpers
import flex from 'styles/flex';
import typefaces from 'shared/styles/typefaces';
import {truncate} from 'shared/styles/partials';
import {noSelect} from 'shared/utils/sc-utils';

export const ViewTitle = styled.div`
  ${flex.horizontal};
  ${noSelect}
  padding-bottom: 30px;
  @media (max-width: 820px) {
    ${flex.vertical};
  }
`;

export const Left = styled.div`
  ${flex.allowOverflowX};
  flex-grow: 1;
`;

export const Right = styled.div`
  display: flex;
  align-items: flex-end;
  height: 40px;
`;

export const Heading = styled.h3`
  ${typefaces.sidebar.title};
  ${truncate};
  font-weight: 300;
  @media (max-width: 720px) {
    font-size: 20px;
  }
`;

export const Subtitle = styled.p`
  margin-top: 10px;
  color: ${commonPalette.txGrey};
`;
