import gql from 'graphql-tag';

export const Team = gql`
  query team($id: String!) {
    team(id: $id) {
      id
      name
      canManageTeam
      isManagementTeam
      guideApproval
      notificationRecipients
      reminderCycle
      teamMembers {
        role
        skillRole {
          id
          name
        }
        user {
          id
          fullName
          email
        }
        isApprover
        approverOrder
      }
      domains {
        id
        name
        slug
        managementTeam {
          id
          name
        }
        defaultTeam {
          id
          name
        }
        theme {
          id
          name
        }
      }
      parent {
        id
        name
      }
    }
  }
`;

export const TeamUserRoles = gql`
  query teamUserRoles($teamId: String!, $includeChildrenTeamPermissions: Boolean) {
    team(id: $teamId, includeChildrenTeamPermissions: $includeChildrenTeamPermissions) {
      name
      teamMembers {
        skillRole {
          name
        }
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const TeamWithGuideCount = gql`
  query teamWithGuideCount($id: String!) {
    team(id: $id) {
      id
      name
      canManageTeam
      isManagementTeam
      guideCount
    }
  }
`;

export const Teams = gql`
  query teams {
    teams {
      id
      name
      canManageTeam
      guideCount
    }
  }
`;

export const TeamsLite = gql`
  query teamsLite {
    teams {
      id
      name
      parent {
        id
      }
    }
  }
`;

export const PaginatedTeamsLite = gql`
  query teamsPaginatedLite($limit: Int, $offset: Int, $searchTerm: String) {
    teamsPaginated(limit: $limit, offset: $offset, searchTerm: $searchTerm) {
      offset
      limit
      totalCount
      nextOffset
      results {
        id
        name
        parent {
          id
        }
      }
    }
  }
`;

export const AllTeams = gql`
  query allTeams {
    allTeams {
      id
      name
    }
  }
`;

export const TeamsWithTeamMembers = gql`
  query teamsWithTeamMembers {
    allTeams {
      id
      name
      teamMembers {
        role
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const TeamsPaginatedWithMembers = gql`
  query teamsPaginatedWithMembers($limit: Int, $offset: Int, $searchTerm: String, $sortBy: TeamSortByOption) {
    teamsPaginated(limit: $limit, offset: $offset, searchTerm: $searchTerm, sortBy: $sortBy) {
      offset
      limit
      totalCount
      nextOffset
      results {
        id
        name
        teamMembers {
          role
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

export const TeamHasPendingApprovals = gql`
  query teamHasGuideApprovalInProgress($teamId: String!) {
    teamHasGuideApprovalInProgress(teamId: $teamId) {
      result
      error
    }
  }
`;

export const AllParentTeams = gql`
  query allParentTeams($searchTerm: String) {
    allParentTeams(searchTerm: $searchTerm) {
      id
      name
      parent {
        id
      }
      children
    }
  }
`;

export const AllChildrenTeamByParentId = gql`
  query allChildrenTeamByParentId($teamId: String!) {
    allChildrenTeamByParentId(teamId: $teamId) {
      id
      name
      parent {
        id
      }
      children
    }
  }
`;
