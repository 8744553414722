import {
  faGripHorizontal,
  faTimes,
  faPlus,
  faCog,
  faLanguage,
  faExchangeAlt,
  faShareAlt,
  faUserEdit,
  faBell,
  faComment,
  faEdit,
  faCopy,
  faTrashAlt,
  faEyeSlash,
  faEye,
  faLaptop,
  faThList,
  faCameraRetro,
  faTag,
  faGlobe,
  faTrophy,
  faArrowLeft,
  faSearch,
  faCaretDown,
  faCaretUp,
  faUserAlt,
  faUserAltSlash,
  faUserNinja,
  faPlusCircle,
  faExclamationTriangle,
  faCheck,
  faLock,
  faUserFriends,
  faInfoCircle,
  faFilePdf,
  faPen,
  faCommentSlash,
  faUser,
  faUsers,
  faCity,
  faAngleLeft,
  faAngleRight,
  faFileSignature,
  faDownload,
  faSpinner,
  faFileCsv,
  faArrowDown,
  faArrowUp,
  faLayerGroup,
  faGlobeAmericas,
  faVideo,
  faMagic,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import {
  faCopy as faCopyRegular,
  faClock as faClockRegular,
  faCalendarAlt as faCalendarAltRegular,
  faTrashAlt as faTrashAltRegular,
  faCheckCircle as faCheckCircleRegular,
  faUser as faUserRegular,
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular
} from '@fortawesome/free-regular-svg-icons';

export const icons = [
  {
    name: 'grip-horizontal',
    code: 'f58d',
    action: 'menu',
    icon: faGripHorizontal
  },
  {
    name: 'times',
    code: 'f00d',
    action: 'close',
    icon: faTimes
  },
  {
    name: 'plus',
    code: 'f067',
    action: 'create',
    icon: faPlus
  },
  {
    name: 'cog',
    code: 'f013',
    action: 'settings',
    icon: faCog
  },
  {
    name: 'language',
    code: 'f1ab',
    action: 'translate',
    icon: faLanguage
  },
  {
    name: 'exchange-alt',
    code: 'f362',
    action: 'merge',
    icon: faExchangeAlt
  },
  {
    name: 'share-alt',
    code: 'f1e0',
    action: 'share',
    icon: faShareAlt
  },
  {
    name: 'user-edit',
    code: 'f4ff',
    action: 'reassign',
    icon: faUserEdit
  },
  {
    name: 'user-alt',
    code: 'f406',
    action: 'enable',
    icon: faUserAlt
  },
  {
    name: 'user-alt-slash',
    code: 'f4fa',
    action: 'disable',
    icon: faUserAltSlash
  },
  {
    name: 'bell',
    code: 'f0f3',
    action: 'activity',
    icon: faBell
  },
  {
    name: 'comment',
    code: 'f075',
    action: 'feedback',
    icon: faComment
  },
  {
    name: 'edit',
    code: 'f044',
    action: 'edit',
    icon: faEdit
  },
  {
    name: 'pen',
    code: 'f304',
    action: 'edit-alt',
    icon: faPen
  },
  {
    name: 'copy',
    code: 'f0c5',
    action: 'duplicate',
    icon: [faCopy, faCopyRegular]
  },
  {
    name: 'trash-alt',
    code: 'f2ed',
    action: 'remove',
    icon: [faTrashAlt, faTrashAltRegular]
  },
  {
    name: 'eye-slash',
    code: 'f070',
    action: 'hide',
    icon: [faEyeSlash, faEyeSlashRegular]
  },
  {
    name: 'eye',
    code: 'f06e',
    action: 'show',
    icon: [faEye, faEyeRegular]
  },
  {
    name: 'laptop',
    code: 'f109',
    action: 'preview',
    icon: faLaptop
  },
  {
    name: 'th-list',
    code: 'f00b',
    action: 'newStep',
    icon: faThList
  },
  {
    name: 'camera-retro',
    code: 'f083',
    action: 'editMedia',
    icon: faCameraRetro
  },
  {
    name: 'tag',
    code: 'f02b',
    action: 'tag',
    icon: faTag
  },
  {
    name: 'globe',
    code: 'f0ac',
    action: 'language',
    icon: faGlobe
  },
  {
    name: 'trophy',
    code: 'f091',
    action: 'badge',
    icon: faTrophy
  },
  {
    name: 'arrow-left',
    code: 'f060',
    action: 'back',
    icon: faArrowLeft
  },
  {
    name: 'search',
    code: 'f002',
    action: 'search',
    icon: faSearch
  },
  {
    name: 'caret-down',
    code: 'f0d7',
    action: 'sort',
    icon: faCaretDown
  },
  {
    name: 'caret-up',
    code: 'f0d7',
    action: 'caretUp',
    icon: faCaretUp
  },
  {
    name: 'user-ninja',
    code: 'f504',
    action: 'admin',
    icon: faUserNinja
  },
  {
    name: 'plus-circle',
    code: 'f055',
    action: 'add',
    icon: faPlusCircle
  },
  {
    name: 'clock',
    code: 'f017',
    action: 'time',
    icon: faClockRegular
  },
  {
    name: 'calendar-alt',
    code: 'f073',
    action: 'date',
    icon: faCalendarAltRegular
  },
  {
    name: 'exclamation-circle',
    code: 'f06a',
    action: 'danger',
    icon: faExclamationCircle
  },
  {
    name: 'exclamation-triangle',
    code: 'f071',
    action: 'warning',
    icon: faExclamationTriangle
  },
  {
    name: 'user',
    code: 'f007',
    action: 'user',
    icon: faUserRegular
  },
  {
    name: 'check',
    code: 'f00c',
    action: 'checkmark',
    icon: faCheck
  },
  {
    name: 'lock',
    code: 'f023',
    action: 'lock',
    icon: faLock
  },
  {
    name: 'user-friends',
    code: 'f500',
    action: 'team',
    icon: faUserFriends
  },
  {
    name: 'info-circle',
    code: 'f05a',
    action: 'info',
    icon: faInfoCircle
  },
  {
    name: 'comment-slash',
    code: 'f4b3',
    action: 'unpublish',
    icon: faCommentSlash
  },
  {
    name: 'check-circle',
    code: 'f058',
    action: 'guideApproval',
    icon: faCheckCircleRegular
  },
  {
    name: 'file-pdf',
    code: 'f1c1',
    action: 'exportToPdf',
    icon: faFilePdf
  },
  {
    name: 'user',
    code: 'f007',
    action: 'user',
    icon: faUser
  },
  {
    name: 'users',
    code: 'f0c0',
    action: 'users',
    icon: faUsers
  },
  {
    name: 'city',
    code: 'f64f',
    action: 'city',
    icon: faCity
  },
  {
    name: 'angle-left',
    code: 'f104',
    action: 'angle-left',
    icon: faAngleLeft
  },
  {
    name: 'angle-right',
    code: 'f105',
    action: 'angle-right',
    icon: faAngleRight
  },
  {
    name: 'file-signature',
    code: 'f573',
    action: 'applySignOff',
    icon: faFileSignature
  },
  {
    name: 'download',
    code: 'f109',
    action: 'download',
    icon: faDownload
  },
  {
    name: 'file-csv',
    code: 'f109',
    action: 'file-csv',
    icon: faFileCsv
  },
  {
    name: 'spinner',
    code: 'f105',
    action: 'spinner',
    icon: faSpinner
  },
  {
    name: 'arrow-up',
    code: 'f062',
    action: 'arrow-up',
    icon: faArrowUp
  },
  {
    name: 'arrow-down',
    code: 'f063',
    action: 'arrow-down',
    icon: faArrowDown
  },
  {
    name: 'layer-group',
    code: 'f5fd',
    action: 'layer-group',
    icon: faLayerGroup
  },
  {
    name: 'globe-americas',
    code: 'f57d',
    action: 'globe-americas',
    icon: faGlobeAmericas
  },
  {
    name: 'video',
    code: 'f03d',
    action: 'video',
    icon: faVideo
  },
  {
    name: 'magic',
    code: 'f0d0',
    action: 'magic',
    icon: faMagic
  }
];

export const iconsForLibrary = icons.map(icon => icon.icon);
