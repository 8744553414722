import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const fields = [
  {
    name: 'teamSkillRoles',
    rules: 'array',
    value: []
  }
];

export default new MobxReactForm({fields}, {plugins});
