export const getTimestamp = () => Math.round(new Date().getTime() / 1000);

export const downloadCanvas = (canvas, filename = 'download.png') => {
  if (canvas.msToBlob && window.navigator.msSaveBlob) {
    const blob = canvas.msToBlob();
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const anchor = document.createElement('a');
    anchor.href = canvas.toDataURL('image/png');
    anchor.download = filename;
    anchor.click();
  }
};

export const downloadCsv = async (jsonData, fileName, sheetName = '') => {
  try {
    const XLSX = await import('xlsx/dist/xlsx');

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(jsonData);

    XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
    XLSX.writeFile(workBook, fileName, {
      type: 'base64'
    });

    return {
      ok: true
    };
  } catch (error) {
    console.log(error, 'Error while lazy importing XLSX');
    return {
      ok: false,
      error
    };
  }
};
