import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

import Checkbox from 'components/Checkbox';

import {Label, Option, OptionText} from './styles';

@inject('store')
@observer
class MultiSelect extends Component {
  renderOption = ({key, selected}, translations) => {
    const {onOptionChange} = this.props.store[this.props.storeName];

    const optionText = translations[key];

    return (
      <Option key={key} onClick={() => onOptionChange(key)}>
        <Label>
          <Checkbox checked={selected} />
          <OptionText>{optionText}</OptionText>
        </Label>
      </Option>
    );
  };

  render() {
    const {options, translations} = this.props.store[this.props.storeName];

    return <div>{options.map(option => this.renderOption(option, translations))}</div>;
  }
}

export default MultiSelect;
