import React, {Component} from 'react';
import {inject, PropTypes as MobxPropTypes} from 'mobx-react';
import PropTypes from 'prop-types';

//helpers
import {styleable} from 'shared/decorators';

//styles
import {
  MediaPicker,
  StyledFontAwesomeIcon,
  BackgroundImage,
  DarkerOverlay,
  StyledIcon,
  ImageDisabledWrapper,
  ImageDisabled,
  StyledText
} from './styles';
import Button from 'ui-components/Button';

import {FormattedMessage} from 'components/FormattedComponents';
import messages from './messages';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@styleable
class MediaPickerComponent extends Component {
  static defaultProps = {
    iconSize: 22,
    showDarkerOverlay: true
  };

  static propTypes = {
    backgroundAspectRatio: PropTypes.number,
    backgroundMetadata: PropTypes.object,
    backgroundSizes: PropTypes.string,
    backgroundUrl: PropTypes.string,
    canOpen: PropTypes.func,
    field: PropTypes.string,
    form: MobxPropTypes.observableObject,
    iconSize: PropTypes.number,
    onOpen: PropTypes.func,
    focus: PropTypes.bool,
    shouldShowOverlayShapes: PropTypes.bool,
    mediaId: PropTypes.string,
    toolbar: PropTypes.element,
    showDarkerOverlay: PropTypes.bool,
    label: PropTypes.string,
    shouldDisableImage: PropTypes.bool,
    type: PropTypes.string
  };

  render() {
    const {
      backgroundAspectRatio,
      backgroundMetadata,
      backgroundSizes,
      backgroundUrl,
      focus,
      shouldShowOverlayShapes,
      toolbar,
      showDarkerOverlay,
      label,
      shouldDisableImage
    } = this.props;

    if (shouldDisableImage) {
      return (
        <ImageDisabledWrapper>
          <ImageDisabled>
            <StyledIcon iconId="editMedia" />
            <StyledText>
              <FormattedMessage {...messages.addContent} />
            </StyledText>
          </ImageDisabled>
        </ImageDisabledWrapper>
      );
    }

    return (
      <MediaPicker
        onClick={this.handleClick}
        showDarkerOverlay={showDarkerOverlay}
        focus={focus}
        isInverted={backgroundUrl}
      >
        {backgroundUrl && (
          <BackgroundImage
            aspectRatio={backgroundAspectRatio}
            sizes={backgroundSizes}
            src={backgroundUrl}
            metadata={backgroundMetadata}
            shouldShowOverlayShapes={shouldShowOverlayShapes}
          />
        )}
        <StyledFontAwesomeIcon actionId={'editMedia'} />
        {showDarkerOverlay && <DarkerOverlay focus={focus} />}
        {toolbar}
        {label && <Button underlined className={'media-picker-label'} label={label} />}
      </MediaPicker>
    );
  }

  handleClick = () => {
    const {canOpen, shouldDisableImage} = this.props;

    if (shouldDisableImage) {
      return;
    }

    if (!canOpen) {
      this.openDialog();
      return;
    }

    canOpen().then(
      () => this.openDialog(),
      () => undefined
    );
  };

  openDialog = () => {
    const {
      acceptedTypes,
      allowedFormats,
      allowVideos,
      field,
      form,
      galleryConfig,
      instructionId,
      onOpen,
      previewType,
      setCoverUrl,
      setMetadata,
      store,
      updateMedia,
      showUpdateNotifications = true,
      shouldShowOverlayShapes = false,
      mediaId,
      shouldDisableImage,
      type
    } = this.props;
    const {mediaPickerDialog: dialog} = store;

    if (shouldDisableImage) {
      return;
    }

    dialog.open({
      acceptedTypes,
      allowedFormats,
      allowVideos,
      field,
      form,
      galleryConfig,
      instructionId,
      previewType,
      setCoverUrl,
      setMetadata,
      updateMedia,
      showUpdateNotifications,
      shouldShowOverlayShapes,
      mediaId,
      type
    });

    if (typeof onOpen === 'function') {
      onOpen();
      trackEvent(EVENT_TYPES.OPEN_MEDIA_EDITOR);
    }
  };
}

export default MediaPickerComponent;
