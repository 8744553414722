import React from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import messages from './messages';
import {
  BackIcon,
  StyledSubText,
  StyledButton,
  DrawerTitleButton,
  DrawerWrapper,
  InputDiv,
  SearchIcon,
  StyledInput,
  FlexRow,
  StyledActivitiesDiv,
  ActivitiesList,
  StyledCheckbox,
  Activity,
  StyledLearningList,
  FixedContainer,
  SpinnerWrapper,
  ErrorWrapper,
  StyledInfiniteScroll,
  DropwdownItem,
  StyledText
} from './styles';

//components
import Drawer from 'components/Drawer';
import DetailedActivity from '../../views/CreateSkills/DetailedActivity';
import views from 'config/views';
import {SkillTasksPaginated} from 'api/skills/queries';
import {client} from 'utils/apollo-client';
import {debounce} from 'lodash';
import Spinner from 'shared/components/Spinner';
import {Filters} from 'components/Filters/Filters';
import InfoBox from 'ui-components/InfoBox';
import TagPicker from 'components/TagPicker';
import FiltersChips from 'components/FiltersChips';

const MAX_SELECTED = 10;

@inject('store')
@observer
class ActivitiesDrawer extends React.Component {
  async componentDidMount() {
    const {
      store: {
        skillsPage: {
          updateSkillTasksLoadingState,
          skillTasksOffset,
          setLearningActivities,
          backFromLearnings,
          addSelectedLearningActivity,
          newLearningActivityTitle,
          updateNewLearningActivityTitle
        }
      }
    } = this.props;
    updateSkillTasksLoadingState(true);
    const skillTasks = await this.fetchData(skillTasksOffset);
    let newLearningActivity = null;
    if (backFromLearnings) {
      newLearningActivity = skillTasks.find(learningACtivity => learningACtivity.title === newLearningActivityTitle);
    }
    setLearningActivities(skillTasks);
    updateSkillTasksLoadingState(false);

    if (newLearningActivity) {
      addSelectedLearningActivity(newLearningActivity);
      updateNewLearningActivityTitle(null);
    }
  }

  componentWillUnmount() {
    const {
      store: {
        skillsPage: {updateSkillTasksOffset, setSearchTerm, updateSkillTasksLoadingState, updateSkillTasksLoadingError}
      }
    } = this.props;
    setSearchTerm('');
    updateSkillTasksOffset(0);
    updateSkillTasksLoadingState(false);
    updateSkillTasksLoadingError(false);
    this.resetFilters();
  }

  fetchData = async () => {
    const {
      store: {
        skillsPage: {
          skillTasksOffset,
          skillTasksLimit,
          skillTasksFilters,
          setTotalSkillTasksCount,
          skillTasksSortBy,
          updateSkillTasksLoadingError,
          updateSkillTasksLoadingState
        }
      }
    } = this.props;

    try {
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: SkillTasksPaginated,
        variables: {
          limit: skillTasksLimit,
          offset: skillTasksOffset,
          sortBy: skillTasksSortBy,
          filters: skillTasksFilters
        }
      });
      const {allSkillTasksPaginated} = response.data;
      const {results: skillTasks, totalCount} = allSkillTasksPaginated;
      setTotalSkillTasksCount(totalCount);
      updateSkillTasksLoadingError(false);
      return skillTasks;
    } catch (error) {
      updateSkillTasksLoadingState(false);
      updateSkillTasksLoadingError(true);
    }
  };

  searchSkillTasks = debounce(async term => {
    if ((term.length >= 3 && term.length <= 50) || term.length === 0) {
      const {
        store: {
          skillsPage: {setLearningActivities, updateSkillTasksOffset, updateSkillTasksLoadingState}
        }
      } = this.props;
      updateSkillTasksLoadingState(true);
      updateSkillTasksOffset(0);
      const skillTasks = await this.fetchData();
      setLearningActivities(skillTasks);
      updateSkillTasksLoadingState(false);
    }
  }, 300);

  fetchMore = async () => {
    const {
      store: {
        skillsPage: {
          skillTasksOffset,
          skillTasksLimit,
          updateSkillTasks,
          updateSkillTasksOffset,
          updateSkillTasksLoadingState
        }
      }
    } = this.props;
    updateSkillTasksLoadingState(true);
    updateSkillTasksOffset(skillTasksOffset + skillTasksLimit);
    const skillTasks = await this.fetchData();
    updateSkillTasks(skillTasks);
    updateSkillTasksLoadingState(false);
  };

  goToCreateLearningActivities = () => {
    const {
      view,
      store: {router, learningActivityGeneralInfo}
    } = this.props;
    learningActivityGeneralInfo.updatePreviousPage(view);
    router.goTo(views.createLearningActivity, {});
  };

  toggleLearningActivity = (e, skillTask) => {
    const {removeSelectedLearningActivity, addSelectedLearningActivity} = this.props.store.skillsPage;
    e.target.checked ? addSelectedLearningActivity(skillTask) : removeSelectedLearningActivity(skillTask);
  };

  filterByTag = async () => {
    const {
      store: {
        skillsPage: {filterByTag, updateSkillTasksLoadingState, updateSkillTasksOffset, setLearningActivities}
      }
    } = this.props;
    updateSkillTasksLoadingState(true);
    updateSkillTasksOffset(0);
    filterByTag();
    const skillTasks = await this.fetchData();
    setLearningActivities(skillTasks);
    updateSkillTasksLoadingState(false);
  };

  resetFilters = async () => {
    const {
      store: {
        skillsPage: {resetFilters, updateSkillTasksLoadingState, updateSkillTasksOffset, setLearningActivities}
      }
    } = this.props;
    updateSkillTasksLoadingState(true);
    updateSkillTasksOffset(0);
    resetFilters();
    const skillTasks = await this.fetchData();
    setLearningActivities(skillTasks);
    updateSkillTasksLoadingState(false);
  };

  removeSelectedTag = tag => {
    const {
      store: {
        skillsPage: {drawerLocalSelectedTags, setDrawerLocalSelectedTags}
      }
    } = this.props;
    const tags = drawerLocalSelectedTags.filter(i => i.id !== tag.id);
    setDrawerLocalSelectedTags(tags);
    this.filterByTag();
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        skillsPage,
        platform: {
          developmentFeatureFlags: {learningActivityTags}
        }
      }
    } = this.props;
    const {
      isActivitiesDrawerOpened,
      setSearchTerm,
      learningActivities,
      isDetailedViewSelected,
      setIsDetailedViewSelected,
      setSelectedLearningActivity,
      resetDrawer,
      skillTasksFilters,
      skillTasksLoading,
      skillTasksLoadingError,
      showInfiniteLoader,
      selectedLearningActivities,
      drawerLocalSelectedTags,
      setDrawerLocalSelectedTags,
      drawerSelectedTags
    } = skillsPage;
    return (
      <Drawer
        isOpen={isActivitiesDrawerOpened}
        close={() => {
          resetDrawer();
        }}
      >
        {!isDetailedViewSelected ? (
          <DrawerWrapper>
            <FixedContainer>
              <DrawerTitleButton
                data-cy="drawer-title"
                onClick={() => {
                  resetDrawer();
                }}
              >
                <BackIcon />
                {formatMessage(messages.addLearningActivities)}
              </DrawerTitleButton>
              <StyledSubText isEmptyState={true}>{formatMessage(messages.oneOrMoreActivities)}</StyledSubText>
              <InputDiv>
                <SearchIcon />
                <StyledInput
                  placeholder={formatMessage(messages.searchByName)}
                  onChange={e => {
                    setSearchTerm(e.target?.value.substring(0, 50));
                    this.searchSkillTasks(e.target?.value.substring(0, 50));
                  }}
                  value={skillTasksFilters.title.contains}
                />
              </InputDiv>
              <FlexRow hasGap={true}>
                <StyledLearningList>{formatMessage(messages.learningList)}</StyledLearningList>
                <StyledButton
                  onClick={() => {
                    this.goToCreateLearningActivities();
                  }}
                  iconId="add"
                  label={formatMessage(messages.createNewActivity)}
                />
              </FlexRow>
              {learningActivityTags && (
                <>
                  <Filters
                    filtersTitle={formatMessage(messages.filtersTitle)}
                    formatMessage={formatMessage}
                    onApply={this.filterByTag}
                    onReset={this.resetFilters}
                    totalFiltersApplied={skillTasksFilters?.tags.in.length}
                  >
                    {drawerLocalSelectedTags.length >= MAX_SELECTED && (
                      <InfoBox content={formatMessage(messages.tenTags)} type="informational" size="small" />
                    )}

                    <DropwdownItem>
                      <StyledText>{formatMessage(messages.filterByTag)}</StyledText>
                      <TagPicker
                        selectedTagsIds={drawerSelectedTags.map(tag => tag.id)}
                        setLocalSelectedTags={setDrawerLocalSelectedTags}
                        localSelectedTags={drawerLocalSelectedTags}
                        showCheckBoxSelector
                        disableCreate
                        isSkillTagsPicker={true}
                      />
                    </DropwdownItem>
                  </Filters>
                  <FiltersChips items={drawerSelectedTags} removeItem={this.removeSelectedTag}></FiltersChips>
                </>
              )}
            </FixedContainer>
            {learningActivities?.length > 0 && (
              <ActivitiesList>
                {learningActivities?.map(skillTask => (
                  <Activity key={skillTask.id}>
                    <StyledActivitiesDiv
                      isSelectable={true}
                      onClick={() => {
                        setIsDetailedViewSelected(true);
                        setSelectedLearningActivity(skillTask);
                      }}
                    >
                      {skillTask.title}
                    </StyledActivitiesDiv>
                    <StyledCheckbox
                      data-cy={skillTask.title}
                      type="checkbox"
                      defaultChecked={selectedLearningActivities.find(activity => activity?.id === skillTask?.id)}
                      onChange={e => this.toggleLearningActivity(e, skillTask)}
                    ></StyledCheckbox>
                  </Activity>
                ))}
              </ActivitiesList>
            )}

            {skillTasksLoading && (
              <SpinnerWrapper hasSkillTasks={learningActivities?.length}>
                <Spinner />
              </SpinnerWrapper>
            )}

            {skillTasksLoadingError && <ErrorWrapper>{formatMessage(messages.error)}</ErrorWrapper>}

            {!learningActivities?.length && !skillTasksLoading && (
              <ErrorWrapper>{formatMessage(messages.noLearningActivitiesFound)}</ErrorWrapper>
            )}

            {showInfiniteLoader && !skillTasksLoadingError && (
              <StyledInfiniteScroll
                isLoading={skillTasksLoading}
                onBottom={this.fetchMore}
                spinnerSize={false}
                threshold={200}
              />
            )}
          </DrawerWrapper>
        ) : (
          <DetailedActivity />
        )}
      </Drawer>
    );
  }
}

export default injectIntl(ActivitiesDrawer);
