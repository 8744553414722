import React, {Component} from 'react';
import {observer} from 'mobx-react';
import enhanceWithClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';

//styled-components
import {DropdownWrapper, DropdownTrigger, Dropdown} from './styles';

@enhanceWithClickOutside
@observer
class DropdownComponent extends Component {
  static defaultProps = {
    disabled: false,
    open: false
  };

  static propTypes = {
    children: PropTypes.element,
    content: PropTypes.element,
    disabled: PropTypes.bool,
    open: PropTypes.bool,
    toggle: PropTypes.func
  };

  handleClickOutside = () => {
    const {open, toggle} = this.props;
    if (open) toggle();
  };

  render() {
    const {children, content, disabled, open, toggle, width, dropdownStyle} = this.props;

    return (
      <DropdownWrapper width={width}>
        <DropdownTrigger onClick={toggle}>{children}</DropdownTrigger>
        {!disabled && (
          <Dropdown style={dropdownStyle} open={open}>
            {content}
          </Dropdown>
        )}
      </DropdownWrapper>
    );
  }
}

export default DropdownComponent;
