import styled from 'styled-components';
import $Field from 'ui-components/Field';
import Button from 'ui-components/Button';

export const CreateUserPage = styled.div`
  padding-top: 24px;
`;

export const PickerColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 920px;
  align-items: flex-start;
`;

export const PickerRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Field = styled($Field)`
  width: 350px;
  margin-right: 30px;
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'remove',
  solidIcon: false
})`
  cursor: pointer;
  margin-top: 40px;
`;

export const BreakLine = styled.hr`
  width: 98%;
  display: flex;
  flex-direction: row;
  background-color: #dbdbdb;
  margin-bottom: 10px;
  opacity: 0.4;
`;
