import React from 'react';
import {RouteAdapter as Route} from 'mobx-router-utils/RouteAdapter';

import EditProfileView from 'views/EditProfileView';
import LoginView from 'views/LoginView';
import LogoutView from 'views/LogoutView';
import ForgotPassword from 'views/ForgotPassword';
import ResetPassword from 'views/ResetPassword';
import SetPassword from 'views/SetPassword';
import AllGuides from 'views/AllGuides';
import NewGuide from 'views/NewGuide';
import EditGuide from 'views/EditGuide';
import EditInstruction from 'views/EditInstruction';
import ThemesView from 'views/ThemesView';
import NewThemeView from 'views/NewThemeView';
import EditThemeView from 'views/EditThemeView';
import TeamsView from 'views/TeamsView';
import WorkspacesView from 'views/WorkspacesView';
import WorkspaceTeamsView from 'views/WorkspaceTeamsView';
import WorkspaceNewTeamsView from 'views/WorkspaceNewTeamsView';
import NewTeamView from 'views/NewTeamView';
import TeamUsersView from 'views/TeamUsersView';
import NewTeamUsersView from 'views/NewTeamUsersView';
import TeamWorkspacesView from 'views/TeamWorkspacesView';
import TagManagement from 'views/TagManagement';
import NewTag from 'views/NewTag';
import NewWorkspace from 'views/NewWorkspace';
import EditWorkspace from 'views/EditWorkspace';
import EditTag from 'views/EditTag';
import MergeTagView from 'views/MergeTags';
import UsersList from 'views/UsersList';
import NewUser from 'views/NewUser';
import EditUser from 'views/EditUser';
import Analytics from 'views/Analytics';
import ThoughtspotView from 'views/ThoughtspotView';
import ChecklistsReport from 'views/ChecklistsReport';
import ChecklistSessionDetails from 'views/ChecklistSessionDetails';
import CampaignsList from 'views/CampaignsList';
import CampaignReport from 'views/CampaignReport';
import NewCampaignView from 'views/NewCampaignView';
import EditCampaignView from 'views/EditCampaignView';
import TranslateGuide from 'views/TranslateGuide';
import TranslateTagsView from 'views/TranslateTagsView';
import PlatformSettingsView from 'views/PlatformSettingsView';
import EditTeamView from 'views/EditTeamView';
import TeamDetailsView from 'views/TeamDetailsView';
import CampaignResultsView from 'views/CampaignResultsView';
import CampaignResultDetailsView from 'views/CampaignResultDetailsView';
import CampaignTranslateView from 'views/CampaignTranslateView';
import CampaignVisibilityView from 'views/CampaignVisibilityView';
import IPAPanel from 'views/IPAPanel';
import PrivacyCentreView from 'shared/views/PrivacyCentreView';
import SmartSkillsTeams from 'views/SmartSkillsTeams';
import SmartSkillsTeam from 'views/SmartSkillsTeam';
import SmartSkillsUser from 'views/SmartSkillsUser';
import Skills from 'views/Skills';
import SkillProfiles from 'views/SkillProfiles';
import ManageSkillProfile from 'views/ManageSkillProfile';
import CreateSkills from 'views/CreateSkills';
import EditSkill from 'views/EditSkill';
import LearningActivities from 'views/LearningActivities';
import CreateLearningActivity from 'views/CreateLearningActivity';
import JobTitles from 'views/JobTitlesView';
import EditLearningActivity from 'views/EditLearningActivity';
import ManageJobTitle from 'views/ManageJobTitleView';
import ChecklistAlerts from 'views/ChecklistAlerts';
import CreateAlertRecipient from 'views/AlertRecipient/CreateAlertRecipient';
import EditAlertRecipient from 'views/AlertRecipient/EditAlertRecipient';
import GuideView from 'views/GuideView';

import {ROOT_PATH} from './constants';

const views = {
  // public
  root: new Route({
    id: 'root',
    component: <LoginView />,
    path: ROOT_PATH
  }),
  redirectLegacyRoot: new Route({
    id: 'redirectLegacyRoot',
    path: '/',
    beforeEnter: (_route, _params, store) => {
      store.router.goTo(views.root, {});
      return false;
    }
  }),
  login: new Route({
    id: 'login',
    component: <LoginView />,
    path: `${ROOT_PATH}/login`
  }),
  logout: new Route({
    id: 'logout',
    component: <LogoutView />,
    path: `${ROOT_PATH}/logout`
  }),
  forgotPassword: new Route({
    id: 'forgotPassword',
    component: <ForgotPassword />,
    path: `${ROOT_PATH}/forgot-password`
  }),
  resetPassword: new Route({
    id: 'resetPassword',
    component: <ResetPassword />,
    path: `${ROOT_PATH}/reset-password`
  }),
  setPassword: new Route({
    id: 'setPassword',
    component: <SetPassword />,
    path: `${ROOT_PATH}/set-password`
  }),

  // editor
  editProfile: new Route({
    id: 'editProfile',
    component: <EditProfileView />,
    path: `${ROOT_PATH}/edit-profile`,
    editor: true
  }),
  guides: new Route({
    id: 'guides',
    component: <AllGuides />,
    path: `${ROOT_PATH}/guides`,
    editor: true,
    actionMenu: true
  }),
  newGuide: new Route({
    id: 'newGuide',
    component: <NewGuide />,
    path: `${ROOT_PATH}/guides/new`,
    editor: true
  }),
  editGuide: new Route({
    id: 'editGuide',
    component: (
      <GuideView>
        <EditGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id`,
    editor: true,
    actionMenu: true,
    beforeEnter: (_route, params) => {
      if (params.id.endsWith('.json')) {
        return window.location.replace(
          `${window.location.origin.replace('3033', '3000')}/ipa/export-guide/${params.id.replace('.json', '')}`
        );
      }
      return true;
    }
  }),
  editInstruction: new Route({
    id: 'editInstruction',
    component: (
      <GuideView>
        <EditInstruction />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/:topicId/:instructionId/:stepId?`,
    editor: true,
    actionMenu: true
  }),
  translateGuide: new Route({
    id: 'translateGuide',
    component: (
      <GuideView>
        <TranslateGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/translate`,
    editor: true
  }),
  translateLocale: new Route({
    id: 'translateLocale',
    component: (
      <GuideView>
        <TranslateGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/translate/:locale`,
    editor: true,
    localeEditor: true,
    onParamsChange: (_route, _params, store) => {
      if (store) {
        const {translationsPage} = store;
        translationsPage.onLocaleChange();
      }
    }
  }),
  translateTopicLocale: new Route({
    id: 'translateTopicLocale',
    component: (
      <GuideView>
        <TranslateGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/:topicId/translate/:locale`,
    editor: true,
    localeEditor: true,
    onParamsChange: (_route, _params, store) => {
      if (store) {
        const {translationsPage} = store;
        translationsPage.onLocaleChange();
      }
    }
  }),
  translateInstructionLocale: new Route({
    id: 'translateInstructionLocale',
    component: (
      <GuideView>
        <TranslateGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/:topicId/:instructionId/translate/:locale`,
    editor: true,
    localeEditor: true,
    onParamsChange: (_route, _params, store) => {
      if (store) {
        const {translationsPage} = store;
        translationsPage.onLocaleChange();
      }
    }
  }),
  translateStepLocale: new Route({
    id: 'translateStepLocale',
    component: (
      <GuideView>
        <TranslateGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/:topicId/:instructionId/:stepId/translate/:locale`,
    editor: true,
    localeEditor: true,
    onParamsChange: (_route, _params, store) => {
      if (store) {
        const {translationsPage} = store;
        translationsPage.onLocaleChange();
      }
    }
  }),
  translateHintLocale: new Route({
    id: 'translateHintLocale',
    component: (
      <GuideView>
        <TranslateGuide />
      </GuideView>
    ),
    path: `${ROOT_PATH}/guides/:id/:topicId/:instructionId/:stepId/:noteId/translate/:locale`,
    editor: true,
    localeEditor: true,
    onParamsChange: (_route, _params, store) => {
      if (store) {
        const {translationsPage} = store;
        translationsPage.onLocaleChange();
      }
    }
  }),
  themes: new Route({
    id: 'themes',
    component: <ThemesView />,
    path: `${ROOT_PATH}/themes`,
    editor: true,
    actionMenu: true
  }),
  newTheme: new Route({
    id: 'newTheme',
    component: <NewThemeView />,
    path: `${ROOT_PATH}/themes/new/:tab`,
    editor: true,
    onEnter: (route, params, store) => {
      const validTabs = ['name-and-logo', 'colors', 'instructural-icons', 'font-settings'];
      if (!validTabs.includes(params.tab)) {
        store.router.goTo(views.newTheme, {tab: 'name-and-logo'});
        return false;
      }
    }
  }),
  newThemeRedirect: new Route({
    id: 'newThemeRedirect',
    path: `${ROOT_PATH}/themes/new`,
    onEnter: (route, params, store) => {
      if (!params.tab) {
        store.router.goTo(views.newTheme, {tab: 'name-and-logo'});
        return false;
      }
    }
  }),
  editTheme: new Route({
    id: 'editTheme',
    component: <EditThemeView />,
    path: `${ROOT_PATH}/themes/:id/:tab`,
    editor: true,
    onEnter: (route, params, store) => {
      const validTabs = ['name-and-logo', 'colors', 'instructural-icons', 'font-settings'];
      if (!validTabs.includes(params.tab)) {
        store.router.goTo(views.editTheme, {id: params.id, tab: 'name-and-logo'});
        return false;
      }
    }
  }),
  editThemeRedirect: new Route({
    id: 'editThemeRedirect',
    path: `${ROOT_PATH}/themes/:id`,
    onEnter: (route, params, store) => {
      if (!params.tab) {
        store.router.goTo(views.editTheme, {id: params.id, tab: 'name-and-logo'});
        return false;
      }
    }
  }),
  teams: new Route({
    id: 'teams',
    component: <TeamsView />,
    path: `${ROOT_PATH}/teams`,
    editor: true,
    actionMenu: true
  }),
  newTeam: new Route({
    id: 'newTeam',
    component: <NewTeamView />,
    path: `${ROOT_PATH}/teams/new/:tab`,
    editor: true,
    onEnter: (_route, params, store) => {
      const validTabs = ['name'];

      if (!params.tab || !validTabs.includes(params.tab)) {
        store.router.goTo(views.newTeam, {tab: 'name'});
        return false;
      }
    }
  }),
  editTeam: new Route({
    id: 'editTeam',
    component: <EditTeamView />,
    editor: true,
    path: `${ROOT_PATH}/teams/:id/:tab`,
    onEnter: (_route, params, store) => {
      const validTabs = ['name', 'users', 'guide-approval', 'notifications', 'workspaces'];
      if (!params.tab || !validTabs.includes(params.tab)) {
        store.router.goTo(views.editTeam, {id: params.id, tab: 'name'});
        return false;
      }
    }
  }),
  viewTeamDetails: new Route({
    id: 'viewTeamDetails',
    component: <TeamDetailsView />,
    path: `${ROOT_PATH}/teams/view/:id/:tab`,
    onEnter: (_route, params, store) => {
      const validTabs = ['users', 'guide-approval', 'notifications', 'workspaces'];
      if (!params.tab || !validTabs.includes(params.tab)) {
        store.router.goTo(views.viewTeamDetails, {id: params.id, tab: 'users'});
        return false;
      }
    }
  }),
  newTeamRedirect: new Route({
    id: 'newTeamRedirect',
    path: `${ROOT_PATH}/teams/new`,
    beforeEnter: (_route, _params, store) => {
      store.router.goTo(views.newTeam, {tab: 'name'});
      return false;
    }
  }),
  teamUsers: new Route({
    id: 'teamUsers',
    component: <TeamUsersView />,
    path: `${ROOT_PATH}/teams/:id/users`,
    editor: true,
    actionMenu: true
  }),
  newTeamUsers: new Route({
    id: 'newTeamUsers',
    component: <NewTeamUsersView />,
    path: `${ROOT_PATH}/teams/:id/users/new`,
    editor: true
  }),
  teamWorkspaces: new Route({
    id: 'teamWorkspaces',
    component: <TeamWorkspacesView />,
    path: `${ROOT_PATH}/teams/:id/workspaces`,
    editor: true,
    actionMenu: true
  }),
  jobTitles: new Route({
    id: 'jobTitles',
    component: <JobTitles />,
    path: `${ROOT_PATH}/jobtitles`,
    actionMenu: true,
    restricted: true
  }),
  createJobTitle: new Route({
    id: 'createJobTitle',
    component: <ManageJobTitle />,
    path: `${ROOT_PATH}/jobtitles/new`,
    restricted: true
  }),
  editJobTitle: new Route({
    id: 'editJobTitle',
    component: <ManageJobTitle />,
    path: `${ROOT_PATH}/jobtitles/:id`,
    restricted: true
  }),
  tags: new Route({
    id: 'tags',
    component: <TagManagement />,
    path: `${ROOT_PATH}/tags`,
    editor: true,
    actionMenu: true
  }),
  newTag: new Route({
    id: 'newTag',
    component: <NewTag />,
    path: `${ROOT_PATH}/tags/new`,
    editor: true
  }),
  translateTags: new Route({
    id: 'translateTags',
    component: <TranslateTagsView />,
    path: `${ROOT_PATH}/tags/translate`,
    editor: true
  }),
  mergeTags: new Route({
    id: 'mergeTags',
    component: <MergeTagView />,
    path: `${ROOT_PATH}/tags/merge`,
    editor: true
  }),
  editTag: new Route({
    id: 'editTag',
    component: <EditTag />,
    path: `${ROOT_PATH}/tags/:id`,
    editor: true
  }),
  users: new Route({
    id: 'users',
    component: <UsersList />,
    path: `${ROOT_PATH}/users`,
    editor: true,
    actionMenu: true
  }),
  newUser: new Route({
    id: 'newUser',
    component: <NewUser />,
    path: `${ROOT_PATH}/users/new/:tab`,
    editor: true,
    onEnter: (route, params, store) => {
      const validTabs = ['create-new-user', 'add-new-user-team'];
      if (!params.tab || !validTabs.includes(params.tab)) {
        store.router.goTo(views.newUser, {tab: 'create-new-user'});
        return false;
      }
    }
  }),
  newUserRedirect: new Route({
    id: 'newUserRedirect',
    path: `${ROOT_PATH}/users/new`,
    beforeEnter: (_route, _params, store) => {
      store.router.goTo(views.newUser, {tab: 'create-new-user'});
      return false;
    }
  }),
  editUser: new Route({
    id: 'editUser',
    component: <EditUser />,
    path: `${ROOT_PATH}/users/:id`,
    editor: true
  }),
  workspaces: new Route({
    id: 'workspaces',
    component: <WorkspacesView />,
    path: `${ROOT_PATH}/workspaces`,
    editor: true,
    actionMenu: true
  }),
  workspaceTeams: new Route({
    id: 'workspaceTeams',
    component: <WorkspaceTeamsView />,
    path: `${ROOT_PATH}/workspaces/:id/teams`,
    editor: true,
    actionMenu: true
  }),
  workspaceNewTeams: new Route({
    id: 'workspaceNewTeams',
    component: <WorkspaceNewTeamsView />,
    path: `${ROOT_PATH}/workspaces/:id/teams/new`,
    editor: true
  }),
  newWorkspace: new Route({
    id: 'newWorkspace',
    component: <NewWorkspace />,
    path: `${ROOT_PATH}/workspaces/new`,
    editor: true
  }),
  editWorkspace: new Route({
    id: 'editWorkspace',
    component: <EditWorkspace />,
    path: `${ROOT_PATH}/workspaces/:id/edit`,
    editor: true
  }),
  analytics: new Route({
    id: 'analytics',
    component: <Analytics />,
    path: `${ROOT_PATH}/analytics/:page`,
    editor: true,
    onParamsChange: (_route, params, store) => {
      const {analyticsPage} = store;
      analyticsPage.setDrilldownPayload(false);
      analyticsPage.setLoading(true);
      if (!params.page) {
        store.router.goTo(views.analytics, {page: 'adoption-overview'});
        return false;
      }
    }
  }),
  analyticsRedirect: new Route({
    id: 'analyticsRedirect',
    path: `${ROOT_PATH}/analytics`,
    beforeEnter: (_route, _params, store) => {
      store.router.goTo(views.analytics, {page: 'adoption-overview'});
      return false;
    }
  }),
  thoughtspot: new Route({
    id: 'analyticsThoughtspot',
    component: <ThoughtspotView />,
    path: `${ROOT_PATH}/adoption/:page`,
    editor: true,
    beforeEnter: (_route, params, store) => {
      if (!params.page || !['platform', 'workspace'].includes(params.page)) {
        store.router.goTo(views.thoughtspot, {page: 'platform'});
        return false;
      }
    }
  }),
  //TODO: Once we completely remove the old Adoption tab update this to /analytics
  thoughtspotRedirect: new Route({
    id: 'analyticsThoughtspotRedirect',
    path: `${ROOT_PATH}/analytics-thoughtspot`,
    beforeEnter: (_route, _params, store) => {
      store.router.goTo(views.thoughtspot, {page: 'platform'});
      return false;
    }
  }),
  checklists: new Route({
    id: 'checklists',
    component: <ChecklistsReport />,
    path: `${ROOT_PATH}/checklists`,
    editor: true,
    actionMenu: true,
    beforeEnter: (_route, params, store) => {
      const validTabs = ['overview', 'results'];
      if (!params.tab || !validTabs.includes(params.tab)) {
        store.router.goTo(views.checklists, {tab: 'overview', page: 'checklists'});
        return false;
      }
    }
  }),
  viewChecklist: new Route({
    id: 'checklists',
    component: <ChecklistSessionDetails />,
    path: `${ROOT_PATH}/checklists/:id`,
    editor: true,
    actionMenu: true
  }),
  instructionScoring: new Route({
    id: 'instructionScoring',
    component: <CampaignsList />,
    path: `${ROOT_PATH}/sentiment`,
    editor: true,
    actionMenu: true,
    beforeEnter: (_, __, store) => {
      store.router.goTo(views.campaigns, {});
    }
  }),
  campaigns: new Route({
    id: 'campaigns',
    component: <CampaignsList />,
    path: `${ROOT_PATH}/sign-offs`,
    editor: true,
    actionMenu: true
  }),
  campaignsResults: new Route({
    id: 'campaignsResults',
    component: <CampaignResultsView />,
    path: `${ROOT_PATH}/sign-offs/results/:campaignId?`,
    editor: true,
    actionMenu: true
  }),
  campaignResult: new Route({
    id: 'campaignResult',
    component: <CampaignResultDetailsView />,
    path: `${ROOT_PATH}/sign-offs/results/:campaignId/:resultId`,
    editor: true,
    actionMenu: true
  }),
  translateCampaign: new Route({
    id: 'translateCampaign',
    component: <CampaignTranslateView />,
    path: `${ROOT_PATH}/sign-offs/translate/:id`,
    editor: true
  }),
  visibilityCampaign: new Route({
    id: 'visibilityCampaign',
    actionMenu: true,
    component: <CampaignVisibilityView />,
    path: `${ROOT_PATH}/sign-offs/visibility/:id`,
    editor: true
  }),
  editCampaign: new Route({
    id: 'editCampaign',
    component: <EditCampaignView pagedGuides={false} />,
    path: `${ROOT_PATH}/sign-offs/edit/:id/:tab`,
    editor: true,
    actionMenu: true,
    onEnter: (_route, params, store) => {
      const validTabs = ['preview', 'questions'];
      if (!validTabs.includes(params.tab)) {
        store.router.goTo(views.editCampaign, {id: params.id, tab: 'questions'});
        return false;
      }
    }
  }),
  editCampaignRedirect: new Route({
    id: 'editCampaignRedirect',
    path: `${ROOT_PATH}/sign-offs/edit/:id`,
    onEnter: (_route, params, store) => {
      if (!params.tab) {
        store.router.goTo(views.editCampaign, {id: params.id, tab: 'questions'});
        return false;
      }
    }
  }),
  newCampaign: new Route({
    id: 'newCampaign',
    component: <NewCampaignView pagedGuides={false} />,
    path: `${ROOT_PATH}/sign-offs/new/:tab`,
    editor: true,
    onEnter: (route, params, store) => {
      route.id = 'newCampaign';
      route.editor = true;
      route.actionMenu = true;
      const validTabs = ['preview', 'questions'];
      if (!validTabs.includes(params.tab)) {
        store.router.goTo(views.newCampaign, {tab: 'template'});
        return false;
      }
    }
  }),
  newCampaignRedirect: new Route({
    id: 'newCampaignRedirect',
    path: `${ROOT_PATH}/sign-offs/new`,
    onEnter: (route, params, store) => {
      if (!params.tab) {
        store.router.goTo(views.newCampaign, {tab: 'template'});
        return false;
      }
    }
  }),
  campaignReport: new Route({
    id: 'campaignReport',
    component: <CampaignReport />,
    path: `${ROOT_PATH}/sentiment/:id`,
    editor: true,
    actionMenu: true
  }),
  platformSettings: new Route({
    id: 'platformSettings',
    component: <PlatformSettingsView />,
    path: `${ROOT_PATH}/platform-settings/:tab`,
    editor: true,
    actionMenu: false,
    onEnter: (_route, params, store) => {
      const validTabs = ['step-notes', 'general', 'alerts', 'api-access'];
      if (!validTabs.includes(params.tab)) {
        store.router.goTo(views.platformSettings, {tab: 'general'});
        return false;
      }
    }
  }),
  ipapanel: new Route({
    id: 'ipapanel',
    component: <IPAPanel />,
    path: `${ROOT_PATH}/ipapanel`,
    ipa: false,
    actionMenu: true
  }),
  privacy: new Route({
    id: 'privacy',
    component: <PrivacyCentreView />,
    path: `${ROOT_PATH}/privacy-page`
  }),
  platformSettingsRedirect: new Route({
    id: 'platformSettingsRedirect',
    path: `${ROOT_PATH}/platform-settings`,
    onEnter: (_route, _, store) => {
      store.router.goTo(views.platformSettings, {tab: 'general'});
    }
  }),
  smartSkills: new Route({
    id: 'smartSkills',
    path: `${ROOT_PATH}/smartskills/matrix`,
    component: <SmartSkillsTeams />,
    editor: true,
    restricted: true
  }),
  smartSkillsTeam: new Route({
    id: 'smartSkillsTeam',
    path: `${ROOT_PATH}/smartskills/matrix/:teamId`,
    component: <SmartSkillsTeam />,
    editor: true,
    restricted: true
  }),
  smartSkillsUser: new Route({
    id: 'smartSkillsUser',
    path: `${ROOT_PATH}/smartskills/matrix/:teamId/:userId`,
    component: <SmartSkillsUser />,
    editor: true,
    restricted: true
  }),
  skills: new Route({
    id: 'skills',
    component: <Skills />,
    path: `${ROOT_PATH}/smartskills/skills`,
    editor: true,
    actionMenu: false,
    restricted: true
  }),
  createSkills: new Route({
    id: 'createSkills',
    path: `${ROOT_PATH}/smartskills/skills/new`,
    component: <CreateSkills />,
    editor: true,
    restricted: true
  }),
  skillProfiles: new Route({
    id: 'skillProfiles',
    path: `${ROOT_PATH}/smartskills/skill-profiles`,
    component: <SkillProfiles />,
    editor: true,
    restricted: true
  }),
  skillProfilesManage: new Route({
    id: 'skillProfilesManage',
    component: <ManageSkillProfile />,
    path: `${ROOT_PATH}/smartskills/skill-profiles/:id`,
    editor: true,
    restricted: true
  }),
  editSkills: new Route({
    id: 'editSkills',
    path: `${ROOT_PATH}/smartskills/skills/:id`,
    component: <EditSkill />,
    editor: true,
    restricted: true
  }),
  learningActivities: new Route({
    id: 'learningActivities',
    path: `${ROOT_PATH}/smartskills/learning-activities`,
    component: <LearningActivities />,
    restricted: true
  }),
  createLearningActivity: new Route({
    id: 'createLearningActivity',
    path: `${ROOT_PATH}/smartskills/learning-activities/new`,
    component: <CreateLearningActivity />,
    restricted: true
  }),
  editLearningActivity: new Route({
    id: 'editLearningActivity',
    path: `${ROOT_PATH}/smartskills/learning-activities/:id`,
    component: <EditLearningActivity />,
    restricted: true
  }),
  checklistAlerts: new Route({
    id: 'checklistAlerts',
    path: `${ROOT_PATH}/checklist-alerts/:tab`,
    component: <ChecklistAlerts />,
    restrticted: true,
    actionMenu: true,
    onEnter: (_route, params, store) => {
      const validTabs = ['recipients', 'platform-alerts'];
      if (!validTabs.includes(params.tab)) {
        store.router.goTo(views.checklistAlerts, {tab: 'recipients'});
        return false;
      }
    }
  }),
  checklistAlertsRedirect: new Route({
    id: 'checklistAlertsRedirect',
    path: `${ROOT_PATH}/checklist-alerts`,
    onEnter: (_route, _, store) => {
      store.router.goTo(views.checklistAlerts, {tab: 'recipients'});
    }
  }),
  newAlertRecipient: new Route({
    id: 'newAlertRecipient',
    path: `${ROOT_PATH}/recipient/new`,
    component: <CreateAlertRecipient />,
    restricted: true
  }),
  editAlertRecipient: new Route({
    id: 'editAlertRecipient',
    path: `${ROOT_PATH}/recipient/:id`,
    component: <EditAlertRecipient />,
    restricted: true
  })
};

export default views;
