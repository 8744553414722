import React, {Component} from 'react';

import {FormattedMessage} from 'components/FormattedComponents';

import Dialog from 'ui-components/Dialog';

import messages from '../messages';
import {dialogStyle} from '../styles';

class ErrorFetchingUserDialog extends Component {
  render() {
    const {isOpen, onCancel} = this.props;

    const title = <FormattedMessage {...messages.couldntLoadUserDetails} />;
    const content = <FormattedMessage {...messages.errorFetchUserDetails} />;

    const actions = [
      {
        label: <FormattedMessage {...messages.close} />,
        onClick: onCancel,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel={false}
        onCancel={onCancel}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {content}
      </Dialog>
    );
  }
}

export default ErrorFetchingUserDialog;
