import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import {FormattedMessage} from 'components/FormattedComponents';

//styled-components
import {Top, Content, StyledEnhancedIcon} from './styles';
import {Panel} from 'styles/styled-components/shared-components';

//mutations
import {CreateTag} from 'api/tag/mutations';

//options
import {createTagOptions} from 'api/tag/mutation-options';

//components
import Field from 'ui-components/Field';
import Checkbox from 'components/Checkbox';
import ButtonGroup from 'ui-components/ButtonGroup';
import LanguagePicker from 'components/LanguagePicker';
import FormContainer from 'ui-components/Layout/FormContainer';

//messages
import messages from './messages';

@inject('store')
@graphql(CreateTag, createTagOptions)
@observer
class NewTagView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {tagManagementPage, platform}
    } = this.props;
    const {currentLanguage} = platform;

    const translations = mapValues(pick(messages, ['createSuccess', 'createFailure', 'duplicateTitle']), message =>
      formatMessage(message)
    );

    tagManagementPage.setDefaultLocale(currentLanguage);
    tagManagementPage.setUiTranslations(translations);
  }

  componentWillUnmount() {
    const {tagManagementPage} = this.props.store;
    tagManagementPage.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      createTagMutation
    } = this.props;
    const {
      tagManagementPage,
      platform: {multipleLocales}
    } = store;
    const {form, setDefaultLocale, defaultLocale, goToTagsList, toggleBadge, isBadge} = tagManagementPage;

    return (
      <FormContainer>
        <ViewTitle title={formatMessage(messages.title)} />
        <Panel>
          <Top>
            <Content>
              <Field autofocus={true} size="small" title={formatMessage(messages.name)} field="title" form={form} />
              {multipleLocales && (
                <Field
                  size="small"
                  inputComponent={<LanguagePicker changeLanguage={setDefaultLocale} selectedLanguage={defaultLocale} />}
                />
              )}
              <Field
                size="small"
                className="badge-selection"
                inputComponent={
                  <Checkbox
                    caption={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <FormattedMessage {...messages.badge} />
                        <StyledEnhancedIcon
                          name="info"
                          tooltipPlacement="right"
                          tooltip={<FormattedMessage {...messages.badgeDescription} />}
                        />
                      </div>
                    }
                    checked={isBadge}
                    onChange={toggleBadge}
                  />
                }
              ></Field>
            </Content>
          </Top>

          <ButtonGroup
            line
            primary={{
              label: tagManagementPage.loading ? '' : formatMessage(messages.save),
              onClick: () => tagManagementPage.createTag({createTagMutation}),
              disabled: tagManagementPage.loading || !form.isValid,
              loading: tagManagementPage.loading
            }}
            secondary={{
              label: formatMessage(messages.cancel),
              onClick: goToTagsList
            }}
          />
        </Panel>
      </FormContainer>
    );
  }
}

export default injectIntl(NewTagView);
