import {action, observable, makeObservable} from 'mobx';
import notification from 'utils/notification-utils';
import {smartTrim} from 'utils/validation-utils';
import form from '../forms/job-title-form';
import store from 'stores/store';
import views from 'config/views';

class JobTitlePage {
  @observable jobTitleForm = form;
  @observable isSaving = false;

  constructor() {
    makeObservable(this);
  }

  setIsSaving = isSaving => {
    this.isSaving = isSaving;
  };

  @action
  onCreateJobTitle = async (createSkillRoleMutation, successMessage, errorMessage) => {
    const {router} = store;
    this.setIsSaving(true);
    try {
      const name = smartTrim(this.jobTitleForm.values().name);
      const result = await createSkillRoleMutation({
        name
      });

      if (result?.data?.createSkillRole?.id) {
        this.jobTitleForm.reset();
        router.goTo(views.jobTitles);
        notification.success(successMessage);
      } else {
        notification.error(errorMessage);
      }
    } catch (error) {
      notification.error(errorMessage);
    } finally {
      this.setIsSaving(false);
    }
  };

  @action
  onEditJobTitle = async (editSkillRoleMutation, successMessage, errorMessage) => {
    const {router} = store;
    this.setIsSaving(true);
    try {
      const name = smartTrim(this.jobTitleForm.values().name);
      const id = router.params.id;
      const result = await editSkillRoleMutation({
        skillRole: {
          name,
          id
        }
      });

      if (result?.data?.editSkillRole?.id) {
        this.jobTitleForm.reset();
        router.goTo(views.jobTitles);
        notification.success(successMessage);
      } else {
        notification.error(errorMessage);
      }
    } catch (error) {
      notification.error(errorMessage);
    } finally {
      this.setIsSaving(false);
    }
  };
}

export default JobTitlePage;
