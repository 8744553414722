import {defineMessages} from 'react-intl';

const messages = defineMessages({
  clickToShow: {
    id: 'EditGuide.clickToShow',
    defaultMessage: 'Click to make visible to users'
  },
  clickToHide: {
    id: 'EditGuide.clickToHide',
    defaultMessage: 'Click to hide from users'
  },
  notTranslated: {
    id: 'EditGuide.notTranslated',
    defaultMessage: 'Not translated'
  },
  showInstructionSuccess: {
    id: 'EditInstruction.showSuccess',
    defaultMessage: 'Instruction will be shown.'
  },
  showChecklistSuccess: {
    id: 'showChecklistSuccess',
    defaultMessage: 'Checklist will be shown.'
  },
  showInstructionFailure: {
    id: 'EditGuide.showFailure',
    defaultMessage: 'Error setting your instruction to be shown.'
  },
  showChecklistFailure: {
    id: 'showChecklistFailure',
    defaultMessage: 'Error setting your checklist to be shown.'
  },
  hideInstructionSuccess: {
    id: 'EditInstruction.hideSuccess',
    defaultMessage: 'Instruction will be hidden.'
  },
  hideChecklistSuccess: {
    id: 'hideChecklistSuccess',
    defaultMessage: 'Checklist will be hidden.'
  },
  hideInstructionFailure: {
    id: 'EditInstruction.hideFailure',
    defaultMessage: 'Error setting your instruction to be hidden.'
  },
  hideChecklistFailure: {
    id: 'hideChecklistFailure',
    defaultMessage: 'Error setting your checklist to be hidden.'
  }
});

export default messages;
