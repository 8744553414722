export const flex = {
  horizontal: `
    display: flex;
    flex-direction: row;
  `,
  horizontalInline: `
    display: inline-flex;
    flex-direction: row;
  `,
  vertical: `
    display: flex;
    flex-direction: column;
  `,
  verticalInline: `
    display: inline-flex;
    flex-direction: column;
  `,
  flexWrap: `
    display: flex;
    flex-wrap: wrap;
  `,
  centerHorizontal: `
    justify-content: center;
    align-items: center;
  `,
  wrap: `
    flex-wrap: wrap;
  `,
  centerHorizontalH: `
    justify-content: center;
  `,
  centerHorizontalV: `
    align-items: center;
  `,
  centerVertical: `
    align-items: center;
    justify-content: center;
  `,
  centerVerticalReset: `
    align-item: initial;
    justify-content: initial;
  `,
  centerVerticalH: `
    align-items: center;
  `,
  centerVerticalV: `
    justify-content: center;
  `,
  spaceAround: `
    justify-content: space-around;
  `,
  spaceBetween: `
    justify-content: space-between;
  `,
  justifyEnd: `
    justify-content: flex-end;
  `,
  justifyStart: `
    justify-content: flex-start;
  `,
  // Disable the content-based minimum size suggestion
  // https://css-tricks.com/flexbox-truncated-text/#article-header-id-3
  allowOverflowX: `
    flex-basis: 0; // IE11
    min-width: 0;
  `,
  allowOverflowY: `
    flex-basis: 0; // IE11
    min-height: 0;
  `
};

export default flex;
