import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import RadioButton from 'shared/components/RadioButton';
import {FormattedMessage} from 'components/FormattedComponents';
import Icon from 'ui-components/Icon';
import {NOTIFICATION_RECIPIENTS} from 'config/enums';

import messages from './messages';
import {StyledList, StyledHeader, SectionTitle} from './styles';

import {Section, SectionRow, iconStyles} from '../styles';

const {ALL_IN_TEAM, ALL_WORKING_ON_GUIDE} = NOTIFICATION_RECIPIENTS;

const getNotificationRecipientsSetter =
  ({notificationsForm, value}) =>
  () => {
    notificationsForm.$('notificationRecipients').sync(value);
  };
@inject('store')
@observer
class NotificationsSection extends Component {
  render() {
    const {
      intl: {formatMessage},
      notificationsForm,
      canEditNotifications
    } = this.props;

    const {notificationRecipients} = notificationsForm.values();

    return (
      <Section>
        <SectionRow header>{formatMessage(messages.notifications)}</SectionRow>
        <SectionRow>
          <FormattedMessage {...messages.sendEmailNotifications} />
        </SectionRow>
        <SectionRow>
          <RadioButton
            height={30}
            checked={notificationRecipients === ALL_IN_TEAM}
            onChange={getNotificationRecipientsSetter({notificationsForm, value: ALL_IN_TEAM})}
            caption={formatMessage(messages.everyoneOnTheTeam)}
            disabled={!canEditNotifications}
          />
          <RadioButton
            height={30}
            checked={notificationRecipients === ALL_WORKING_ON_GUIDE}
            onChange={getNotificationRecipientsSetter({notificationsForm, value: ALL_WORKING_ON_GUIDE})}
            disabled={!canEditNotifications}
            caption={
              <div>
                {formatMessage(messages.teamMembersWorking)}
                <Icon
                  name="info"
                  popoverComponent={
                    <div>
                      <StyledHeader>{formatMessage(messages.notificationsWillBeShared)}</StyledHeader>
                      <SectionTitle>{formatMessage(messages.feedback)}</SectionTitle>
                      <StyledList>
                        <li>{formatMessage(messages.guideAssignee)}</li>
                      </StyledList>
                      <SectionTitle>{formatMessage(messages.approvalWorkflow)}</SectionTitle>
                      <StyledList>
                        <li>{formatMessage(messages.guideAssignee)}</li>
                        <li>{formatMessage(messages.approvers)}</li>
                        <li>{formatMessage(messages.approvalRequester)}</li>
                      </StyledList>
                    </div>
                  }
                  style={iconStyles}
                />
              </div>
            }
          />
        </SectionRow>
      </Section>
    );
  }
}

export default injectIntl(NotificationsSection);
