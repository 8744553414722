import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import messages from './messages';
import {Field, Content} from './styles';
import {bindField} from 'shared/utils/input-utils';
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import ButtonGroup from 'ui-components/ButtonGroup';
import {createSkillRoleOptions, editSkillRoleOptions} from 'api/job-titles/mutation-options';
import {CreateSkillRole, EditSkillRole} from 'api/job-titles/mutations';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';
import {SkillRole} from 'api/job-titles/queries';
import {skillRoleOptions} from 'api/job-titles/query-options';
import LoadingMessage from 'components/LoadingMessage';

@inject('store')
@graphql(CreateSkillRole, createSkillRoleOptions)
@graphql(EditSkillRole, editSkillRoleOptions)
@graphql(SkillRole, skillRoleOptions)
@observer
class ManageJobTitleView extends Component {
  onCreate = async () => {
    const {
      intl: {formatMessage},
      store: {
        jobTitlePage: {jobTitleForm, onCreateJobTitle}
      },
      createSkillRoleMutation
    } = this.props;

    const successMessage = formatMessage(messages.successJobTitleCreation, {name: jobTitleForm.values().name});
    const errorMessage = formatMessage(messages.failJobTitleCreation);

    await onCreateJobTitle(createSkillRoleMutation, successMessage, errorMessage);
  };

  onEdit = async () => {
    const {
      intl: {formatMessage},
      store: {
        jobTitlePage: {jobTitleForm, onEditJobTitle}
      },
      editSkillRoleMutation
    } = this.props;

    const successMessage = formatMessage(messages.successJobTitleEdit, {name: jobTitleForm.values().name});
    const errorMessage = formatMessage(messages.failJobTitleEdit);

    await onEditJobTitle(editSkillRoleMutation, successMessage, errorMessage);
  };

  componentDidUpdate(prevProps) {
    const {
      skillRoleQuery,
      store: {jobTitlePage, router}
    } = this.props;
    const {id} = router.params;
    const isCreateMode = !id;

    if (!isCreateMode && prevProps.skillRoleQuery.loading && !skillRoleQuery.loading && !skillRoleQuery.error) {
      jobTitlePage.jobTitleForm.$('name').sync(skillRoleQuery.skillRole.name);
    }
  }

  componentWillUnmount() {
    const {
      store: {jobTitlePage}
    } = this.props;
    jobTitlePage.jobTitleForm.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      store: {
        jobTitlePage: {jobTitleForm, isSaving},
        router
      },
      skillRoleQuery
    } = this.props;

    const {id} = router.params;
    const isCreateMode = !id;

    return (
      <Container>
        <ViewTitle
          title={isCreateMode ? formatMessage(messages.createJobTitle) : formatMessage(messages.editJobTitle)}
        />
        {!isCreateMode && (skillRoleQuery.loading || skillRoleQuery.error) ? (
          <LoadingMessage hasFailed={skillRoleQuery.error} />
        ) : (
          <Content>
            <Field
              autofocus={true}
              id="name"
              size="small"
              title={formatMessage(messages.jobTitleName)}
              field="name"
              form={jobTitleForm}
              dataTestId={'input-name-job-title'}
              {...bindField(jobTitleForm, 'name')}
            />
            <ButtonGroup
              primary={{
                label: isCreateMode ? formatMessage(messages.create) : formatMessage(messages.save),
                disabled: !jobTitleForm.isValid || jobTitleForm.isEmpty,
                loading: isSaving,
                onClick: isCreateMode ? this.onCreate : this.onEdit,
                dataCy: 'save-draft-button',
                type: 'submit'
              }}
              secondary={{
                label: formatMessage(messages.cancel),
                onClick: () => {
                  jobTitleForm.reset();
                  router.goTo(views.jobTitles);
                },
                dataCy: 'cancel-button'
              }}
            />
          </Content>
        )}
      </Container>
    );
  }
}

export default injectIntl(ManageJobTitleView);
