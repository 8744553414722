import {defineMessages} from 'react-intl';

const messages = defineMessages({
  impersonate: {
    id: 'Users.impersonate',
    defaultMessage: 'Impersonate'
  },
  resendInvite: {
    id: 'UsersList.resendInvite',
    defaultMessage: 'Resend Invite'
  },
  activate: {
    id: 'UsersList.activate',
    defaultMessage: 'Reactivate User'
  },
  deactivate: {
    id: 'UsersList.deactivate',
    defaultMessage: 'Deactivate User'
  },
  delete: {
    id: 'UsersList.delete',
    defaultMessage: 'Delete User'
  },
  enableSuccess: {
    id: 'UsersList.enableSuccess',
    defaultMessage: 'User has been enabled.'
  },
  enableFailure: {
    id: 'UsersList.enableFailure',
    defaultMessage: 'Failed to enable user.'
  },
  disableSuccess: {
    id: 'UsersList.disableSuccess',
    defaultMessage: 'User has been disabled.'
  },
  disableFailure: {
    id: 'UsersList.disableFailure',
    defaultMessage: 'Failed to disable user.'
  },
  inviteSentSuccess: {
    id: 'UsersList.inviteSentSuccess',
    defaultMessage: 'Invite sent to {fullName}'
  },
  inviteSentFailure: {
    id: 'UsersList.inviteSentFailure',
    defaultMessage: 'Failed to resend invite. Please try again.'
  }
});

export default messages;
