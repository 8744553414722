import styled from 'styled-components';

//helpers
import {commonPalette} from 'shared/styles/palette';

const BaseHideable = styled.div`
  opacity: ${p => (p.isInvisible ? 0 : 1)};
  transition: 0.2s opacity ease-out;
`;

export const TitleTypePreview = styled.div`
  background: #fff;
  border-radius: 2px;
  padding: 16px 16px 18px;
`;

export const Title = styled(BaseHideable)`
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
`;

export const TitleInner = BaseHideable.withComponent('span');

export const Caret = styled(BaseHideable)`
  border-color: #000 transparent;
  border-style: solid;
  border-width: 0 4px 4px;
  display: inline-block;
  margin-left: 10px;
  vertical-align: 1px;
`;

export const Instructions = styled(BaseHideable)`
  margin-top: 9px;

  &::before,
  &::after {
    background: ${commonPalette.darkestGray};
    content: '';
    display: block;
    height: 15px;
  }

  &::after {
    margin-top: 4px;
  }
`;
