import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {toJS} from 'mobx';
import {injectIntl} from 'react-intl';

//helpers
import {bindField, slugify, syncTitleWithSlug} from 'shared/utils/input-utils';

//styles
import {HeaderCard, StyledTitleUnderlinedTextbox, StyledSlug} from './styles';

//components
import Field from 'ui-components/Field';
import ButtonGroup from 'ui-components/ButtonGroup';
import TagPicker from 'components/TagPicker';
import LanguagePicker from 'components/LanguagePicker';
import TeamPicker from 'components/TeamPicker';
import MultiDomainPicker from 'components/MultiDomainPicker';

//data
import {TeamsLite} from 'api/team/queries';
import {teamsLiteOptions} from 'api/team/query-options';
import {domainsOptions} from 'api/domain/query-options';
import {DomainsLite} from 'api/domain/queries';
import {GetWorkspacesForGuide} from 'api/guide/queries';
import {domainsForGuidesOptions} from 'api/guide/query-options';

//mutations
import {UpdateGuideMedia} from 'api/guide/mutations';

//mutation-options
import {updateGuideMediaOptions} from 'api/guide/mutation-options';

//messages
import messages from './messages.js';

@inject('store')
@graphql(UpdateGuideMedia, updateGuideMediaOptions)
@graphql(TeamsLite, teamsLiteOptions)
@graphql(DomainsLite, domainsOptions)
@graphql(GetWorkspacesForGuide, domainsForGuidesOptions)
@observer
class GuideDetailsEditMode extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      teamsQuery: {teams = [], loading},
      domainsQuery: {domains = [], loading: domainsLoading},
      guideDomainsQuery
    } = nextProps;
    const {guideStore, editGuidePage} = this.props;
    const {domainsForGuide, loading: guideDomainsLoading} = guideDomainsQuery;

    if (!guideDomainsLoading && domainsForGuide && !editGuidePage.domainIds?.length) {
      editGuidePage.setGuideDomains(domainsForGuide);
    }

    if (!loading && teams) {
      guideStore.setTeams(teams);
    }
    if (!domainsLoading && domains) {
      editGuidePage.setAllDomains(domains);
    }
  }

  componentWillUnmount() {
    const {
      store: {editGuidePage}
    } = this.props;
    editGuidePage.setLocalSelectedTags([]);
  }

  changeLanguage = language => {
    if (language) {
      const {form} = this.props.guideStore;
      form.$('defaultLocale').sync(language);
    }
  };

  titleFieldOptions = {
    onChangeReaction: e => syncTitleWithSlug(e, this.props.guideStore.form)
  };

  handleResize = ({height}) => {
    const {store} = this.props;
    const {detailsCard} = store;

    if (detailsCard.cardHeight !== height) {
      detailsCard.setCardHeight(height);
    }
  };

  render() {
    const {guideStore, intl, store, submitMutation, translations} = this.props;
    const {
      cancel,
      canChangeVisibleTo,
      defaultLocale,
      form,
      saveButtonEnabled,
      selectDomain,
      selectedTagsIds,
      setSelectedTags,
      teams,
      createGuide,
      domainIds,
      saving
    } = guideStore;
    const {formatMessage} = intl;
    const {
      platform,
      detailsCard,
      auth: {user},
      editGuidePage
    } = store;
    const {cardHeight, wrapperHeight} = detailsCard;
    const {allDomains, localSelectedTags, setLocalSelectedTags} = editGuidePage;
    const belongsToMultipleTeams = teams.length > 1;
    const canCreateTags = user && user.canCreateTags;

    if (createGuide && teams.length && !belongsToMultipleTeams) {
      form.$('teamId').sync(teams[0].id);
    }

    const visibleToSelectValues = (domainIds && domainIds.length && toJS(domainIds)) || [];

    return (
      <HeaderCard wrapperHeight={wrapperHeight} cardHeight={cardHeight} onResize={this.handleResize}>
        <Field
          field="title"
          form={form}
          inputComponent={
            <StyledTitleUnderlinedTextbox
              data-cy="guide-details-card-title-line"
              autoFocus
              placeholder={formatMessage(messages.nameYourGuide)}
              {...bindField(form, 'title', this.titleFieldOptions)}
            />
          }
        />
        <StyledSlug field="slug" form={form} placeholder={slugify(formatMessage(messages.nameYourGuide))} />
        {createGuide && platform.multipleLocales && (
          <Field
            title={formatMessage(messages.defaultLanguage)}
            titleDescription={formatMessage(messages.defaultLanguageDescription)}
            inputComponent={
              <LanguagePicker
                hideLabel
                dataCy="language-picker"
                changeLanguage={this.changeLanguage}
                selectedLanguage={defaultLocale}
              />
            }
            className="short"
          />
        )}
        {belongsToMultipleTeams && createGuide && (
          <Field
            inputComponent={
              <TeamPicker
                dataCy="team-picker"
                allowClear={false}
                field="teamId"
                form={form}
                label={formatMessage(messages.team)}
                teams={teams.slice()}
              />
            }
            className="short"
          />
        )}
        <Field
          title={formatMessage(messages.tags)}
          inputComponent={
            <TagPicker
              dataCy="tag-picker"
              className="tag-picker"
              selectedTagsIds={selectedTagsIds}
              setSelectedTags={setSelectedTags}
              locale={form.values().defaultLocale}
              disableCreate={!canCreateTags}
              localSelectedTags={localSelectedTags}
              setLocalSelectedTags={setLocalSelectedTags}
            />
          }
          className="short"
        />
        {canChangeVisibleTo && (
          <MultiDomainPicker
            dataCy="visible-to-picker"
            selectedValues={visibleToSelectValues}
            onChange={selectDomain}
            options={allDomains}
            label={formatMessage(messages.visibleTo)}
            minSize={1}
            onGuideLevel={true}
          />
        )}
        <ButtonGroup
          primary={{
            label: saving ? null : formatMessage(messages.saveDraft),
            disabled: !saveButtonEnabled,
            loading: saving,
            onClick: () => guideStore.submit(submitMutation, translations),
            dataCy: 'save-draft-button',
            type: 'submit'
          }}
          secondary={{
            label: formatMessage(messages.cancel),
            onClick: cancel,
            dataCy: 'cancel-button'
          }}
        />
      </HeaderCard>
    );
  }
}

export default injectIntl(GuideDetailsEditMode);
