import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'NewWorkspace.title',
    defaultMessage: 'Create New Workspace'
  },
  name: {
    id: 'common.forms.name',
    defaultMessage: 'Name'
  },
  workspaceUrl: {
    id: 'NewWorkspace.workspaceUrl',
    defaultMessage: 'Workspace URL'
  },
  workspaceUrlDescription: {
    id: 'common.description.workspaceUrlDescription',
    defaultMessage: 'This will be the URL link for your workspace.'
  },
  password: {
    id: 'common.forms.password',
    defaultMessage: 'Password'
  },
  confirmPassword: {
    id: 'common.forms.confirmPassword',
    defaultMessage: 'Confirm password'
  },
  passwordMismatch: {
    id: 'common.forms.passwordMismatch',
    defaultMessage: 'The passwords must match'
  },
  theme: {
    id: 'common.forms.theme',
    defaultMessage: 'Theme'
  },
  themeDescription: {
    id: 'common.description.themeDescription',
    defaultMessage: 'The branding options of a workspace, including color, logo, and font.'
  },
  managementTeam: {
    id: 'NewWorkspace.managementTeam',
    defaultMessage: 'Management Team'
  },
  managementTeamDescription: {
    id: 'common.description.managementTeamDescription',
    defaultMessage: 'The team responsible for managing the workspace, such as the name, URL, credentials, and branding.'
  },
  defaultTeamForNewUsers: {
    id: 'NewWorkspace.defaultTeamForNewUsers',
    defaultMessage: 'Default Team for New Users'
  },
  defaultTeamDescription: {
    id: 'common.description.defaultTeamDescription',
    defaultMessage:
      'New users are added to this Team when logging in to a Workspace with their Company Account (SSO) for the first time.'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  createSuccess: {
    id: 'NewWorkspace.createSuccess',
    defaultMessage: 'Workspace has been created.'
  },
  createFailure: {
    id: 'NewWorkspace.createFailure',
    defaultMessage: 'Failed to create Workspace.'
  },
  createFailureMaxDomains: {
    id: 'NewWorkspace.createFailureMaxDomains',
    defaultMessage:
      'Maximum Number of Workspaces Reached. Oh no! Unfortunately, you can’t add another Workspace with your current subscription.'
  },
  contactSupport: {
    id: 'NewWorkspace.contactSupport',
    defaultMessage: 'Please contact support to raise your limit.'
  },
  enableXMReality: {
    id: 'NewWorkspace.enableXMReality',
    defaultMessage: 'Enable XM Reality remote call support on this workspace'
  },
  remoteSupportTeam: {
    id: 'NewWorkspace.remoteSupportTeam',
    defaultMessage: 'Remote Support Team'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  signInOptions: {
    id: 'NewWorkspace.signInOptions',
    defaultMessage: 'Sign in options'
  },
  companyAccount: {
    id: 'NewWorkspace.companyAccount',
    defaultMessage: 'Company account (Single Sign On)'
  },
  emailAndPassword: {
    id: 'NewWorkspace.emailAndPassword',
    defaultMessage: 'Email and password'
  },
  emailPhoneAndPassword: {
    id: 'NewWorkspace.emailPhoneAndPassword',
    defaultMessage: 'Email/Phone Number and Password'
  },
  workspacePassword: {
    id: 'NewWorkspace.workspacePassword',
    defaultMessage: 'Workspace password'
  },
  platformSetting: {
    id: 'NewWorkspace.platformSetting',
    defaultMessage: 'Platform setting'
  },
  chatMessage: {
    id: 'chatMessageCreatedDomainsLimit',
    defaultMessage:
      'Hi SwipeGuide,\nI’m hitting the limit on our created Workspaces. Could you tell me more on how I can upgrade my platform?'
  },
  associatedSite: {
    id: 'NewWorkspace.associatedSite',
    defaultMessage: 'Associated Site'
  },
  selectSite: {
    id: 'NewWorkspace.selectSite',
    defaultMessage: 'Select site'
  }
});

export default messages;
