import {action, computed, observable, toJS, makeObservable} from 'mobx';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';

//lodash
import every from 'lodash/every';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import get from 'lodash/get';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';

//helpers
import views from 'config/views';
import notification from 'utils/notification-utils';
import {mediaIdKeys} from 'utils/step-utils';
import {isDraftVersionId} from 'utils/versioning-utils';
import {editorHasValue} from 'utils/data-utils';

//api
import {CreateStep, UpdateStep} from 'api/step/mutations';
import {RecommendContent} from 'api/recommendations/mutations';
import {recommendContentMutationOptions} from 'api/recommendations/mutation-options';
import {createStepOptions, updateStepOptions} from 'api/step/mutation-options';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import {StepNumberDesktop as StepNumber} from 'shared/components/StepNumber';
import StepsSwiperResizeListener from 'shared/components/StepsSwiperResizeListener';
import CustomDragLayer from './CustomDragLayer';
import DraggableStepCard from './DraggableStepCard';
import StepCard from './StepCard';
import WritingTips from './WritingTips';

//styles
import {
  Content,
  GoToNextStepOverlay,
  GoToPreviousStepOverlay,
  Header,
  NewStepPlaceholder,
  OverviewIcon,
  OverviewLink,
  PlusIcon,
  StepWrapper,
  StyledStepControls,
  SwipeableContainer,
  SwipeableSteps,
  Top
} from './styles';

//messages
import messages from './messages';
import Raven from 'raven-js';

//constants
const contentMinHeight = 270;
const contentExtendedMinHeight = 400;

@inject('store')
@graphql(CreateStep, createStepOptions)
@graphql(UpdateStep, updateStepOptions)
@graphql(RecommendContent, recommendContentMutationOptions)
@observer
class SwipeableStepsComponent extends Component {
  @observable layout = {};
  @observable writingTipsType = 'instruction';
  topElementRef = null;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  UNSAFE_componentWillMount() {
    const {steps, store} = this.props;
    const {dragDropSteps} = store;

    dragDropSteps.setSteps(steps);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {steps, store} = this.props;
    const {dragDropSteps} = store;

    if (steps !== nextProps.steps) {
      dragDropSteps.setSteps(nextProps.steps);
    }
  }

  createStep = async (position, values) => {
    const {createStepMutation, intl, store} = this.props;
    const {
      router,
      editInstructionDetailsPage: {isChecklist, newStepDisabledOptionIds}
    } = store;
    const {formatMessage} = intl;
    const {id: guideId, topicId, instructionId} = router.params;

    try {
      const result = await createStepMutation({
        step: {...values, position, disabledOptionIds: Object.keys(newStepDisabledOptionIds)}
      });
      const {
        data: {createStep}
      } = result;
      const params = {id: guideId, topicId, instructionId, stepId: createStep.id};
      router.goTo(views.editInstruction, params, router.queryParams);
      notification.success(formatMessage(isChecklist ? messages.createCheckSuccess : messages.createStepSuccess));
    } catch (error) {
      Raven.captureException(error);
      notification.error(formatMessage(isChecklist ? messages.createCheckError : messages.createStepError));
    }
  };

  insertNewStepAt = index => {
    const {store} = this.props;
    const {router} = store;
    const {params, queryParams} = router;
    const {id, instructionId, topicId} = params;
    const stepId = `new-${index + 1}`;
    router.goTo(views.editInstruction, {id, instructionId, topicId, stepId}, queryParams, router.queryParams);
  };

  @action
  goToIndex = index => {
    const {store} = this.props;
    const {dragDropSteps, router} = store;
    const {steps} = dragDropSteps;
    const {id: guideId, topicId, instructionId} = router.params;

    const normalizedIndex = index > this.newStepIndex ? index - 1 : index;
    const stepId = normalizedIndex < steps.length ? steps[normalizedIndex].id : 'new';
    const params = {id: guideId, topicId, instructionId, stepId};

    router.goTo(views.editInstruction, params, router.queryParams);
  };

  @action
  handleLayoutChange = layout => {
    this.layout = layout;
  };

  handleNavigationOverlayClickFactory = delta => () => {
    const {store} = this.props;
    const {
      unsavedChangesDialog,
      editInstructionDetailsPage: {clearNewStepDisabledOptions}
    } = store;

    unsavedChangesDialog.check().then(() => {
      clearNewStepDisabledOptions();
      this.goToIndex(this.currentStepIndex + delta);
    });
  };

  handleStepArchivedFactory = stepId => () => {
    const {store} = this.props;
    const {dragDropSteps, router} = store;
    const {steps} = dragDropSteps;
    const {id: guideId, topicId, instructionId} = router.params;

    const position = findIndex(steps, {id: stepId});
    const params = {id: guideId, topicId, instructionId};

    if (position > 0) {
      params.stepId = steps[position - 1].id;
    } else if (steps.length > 1) {
      params.stepId = steps[1].id;
    } else {
      params.stepId = 'new';
    }

    router.goTo(views.editInstruction, params, router.queryParams);
  };

  handleStepCloned = ({id: stepId}) => {
    const {store} = this.props;
    const {router} = store;
    const {id: guideId, topicId, instructionId} = router.params;

    const params = {id: guideId, topicId, instructionId, stepId};
    router.goTo(views.editInstruction, params, router.queryParams);
  };

  handleStepDragHandleMouseDown = event => {
    const {store} = this.props;
    const {saveAsDraftDialog} = store;

    event.preventDefault(); // so the focus stays inside the dialog
    saveAsDraftDialog.check();
  };

  // removes empty and falsy values
  sanitizeNotesValues = notes =>
    notes
      .map(note => {
        return editorHasValue(note.content) && {...pickBy(note, identity)};
      })
      .filter(note => note);

  handleStepSave = ({key, values}) => {
    const {steps} = this.props;

    if (values.notes) {
      values.notes = this.sanitizeNotesValues(values.notes);
    }

    if (this.currentStepIndex === this.newStepIndex) {
      return this.createStep(this.currentStepIndex, values);
    }

    return this.updateStep(steps[this.currentStepIndex].id, key, values[key]);
  };

  updateStep = (stepId, key, value) => {
    const {
      intl,
      updateStepMutation,
      store: {editInstructionDetailsPage}
    } = this.props;
    const {formatMessage} = intl;
    const {isChecklist} = editInstructionDetailsPage;

    const step = {[key]: value};
    const promise = updateStepMutation({id: stepId, step});

    if (includes(mediaIdKeys, key)) {
      // If the media is updated, return without showing notifications as
      // the media picker has already shown them:
      return promise;
    }

    return promise
      .then(() => {
        notification.success(formatMessage(isChecklist ? messages.updateCheckSuccess : messages.updateStepSuccess));
      })
      .catch(() => {
        notification.error(formatMessage(isChecklist ? messages.updateCheckError : messages.updateStepError));
      });
  };

  @action
  handleToggleHint = id => {
    const {
      store: {
        app: {
          theme: {icons}
        }
      }
    } = this.props;
    const icon = find(icons, {id});
    const iconType = get(icon, 'type');
    this.writingTipsType = icon ? iconType : 'instruction';
  };

  handleImproveText = async value => {
    const {
      recommendContentMutation,
      store: {suggestionDialog, router},
      locale
    } = this.props;

    suggestionDialog.setLoading(true);
    suggestionDialog.setOriginalStepContent(value);

    let content = '';

    const parsedContent = JSON.parse(value);
    parsedContent.blocks.forEach(block => {
      content += `${block.text}. `;
    });

    const response = await recommendContentMutation({content, locale});
    const {recommendation, error} = response.data.recommendContent;

    if (error) {
      suggestionDialog.reset();
      notification.error(error);
      return;
    }

    if (recommendation) {
      suggestionDialog.open(content, recommendation, router.params.stepId);
    }
  };

  renderExistingStep = (key, index, step) => {
    const {store} = this.props;
    const {
      dragDropSteps,
      editInstructionDetailsPage,
      platform: {hasAiStepContentSuggestions}
    } = store;
    const {shouldBeInteractive} = dragDropSteps;
    const {guide, existingStepOptions, canDisableMoreOptions, toggleExistingStepOption} = editInstructionDetailsPage;

    const normalizedIndex = index < this.newStepIndex ? index : index + 1;
    const isCurrent = normalizedIndex === this.currentStepIndex;
    const isFollowedByNewStep = normalizedIndex === this.newStepIndex - 1;

    const sharedProps = {
      isStatic: !isCurrent || !shouldBeInteractive,
      isTransparent: !isCurrent,
      isCurrent,
      layout: this.layout,
      number: normalizedIndex + 1,
      onToggleHint: this.handleToggleHint,
      step: toJS(step)
    };

    if (this.isReadOnly) {
      return (
        <StepWrapper key={key}>
          <StepCard {...sharedProps} isReadOnly onDragHandleMouseDown={this.handleStepDragHandleMouseDown} />
        </StepWrapper>
      );
    }

    const isFullyTranslated = every(get(guide, 'availableTranslations'), translation =>
      includes(get(step, 'providedTranslations'), translation)
    );

    return (
      <StepWrapper key={key}>
        <DraggableStepCard
          {...sharedProps}
          canTranslate={get(guide, 'canTranslate', false)}
          onArchived={this.handleStepArchivedFactory(step.id)}
          onBeginDrag={() => dragDropSteps.setIsDragged(true)}
          onCloned={this.handleStepCloned}
          onEndDrag={() => dragDropSteps.setIsDragged(false)}
          onSave={this.handleStepSave}
          shouldShowUntranslatedIcon={!isFullyTranslated}
          handleImproveTextClick={hasAiStepContentSuggestions && this.handleImproveText}
          checklistTemplateOptions={existingStepOptions(step.id)}
          hideDisableButton={!canDisableMoreOptions(step.disabledOptions?.length)}
          onToggleTemplateOption={optionId => toggleExistingStepOption({stepId: step.id, optionId})}
        />
        {isCurrent && (
          <StyledStepControls
            cardSpacing={this.layout.cardSpacing}
            design="large"
            onInsertNewStepClick={() => this.insertNewStepAt(normalizedIndex)}
          />
        )}
        {isCurrent && !isFollowedByNewStep && (
          <StyledStepControls
            cardSpacing={this.layout.cardSpacing}
            design="large"
            onInsertNewStepClick={() => this.insertNewStepAt(normalizedIndex + 1)}
            isRight
          />
        )}
      </StepWrapper>
    );
  };

  renderNewStep = (key, index) => {
    const {store} = this.props;
    const {
      dragDropSteps,
      platform: {hasAiStepContentSuggestions},
      editInstructionDetailsPage
    } = store;
    const {shouldBeInteractive} = dragDropSteps;
    const {canDisableMoreOptions, toggleNewStepOption, newStepOptions, newStepDisabledOptionIds} =
      editInstructionDetailsPage;

    const newStepPlaceholderStyle = {
      height: this.layout.slideHeight,
      marginLeft: this.layout.overlayWidth - this.layout.cardHint,
      width: this.layout.slideWidth
    };
    const isCurrent = this.currentStepIndex === index;

    return isCurrent ? (
      <StepWrapper key={key}>
        <StepCard
          isDragHandleDisabled
          isReadOnly={this.isReadOnly}
          isStatic={!shouldBeInteractive}
          layout={this.layout}
          number={index + 1}
          onSave={this.handleStepSave}
          handleImproveTextClick={hasAiStepContentSuggestions && this.handleImproveText}
          checklistTemplateOptions={newStepOptions}
          hideDisableButton={!canDisableMoreOptions(Object.keys(newStepDisabledOptionIds).length)}
          onToggleTemplateOption={toggleNewStepOption}
        />
      </StepWrapper>
    ) : (
      <NewStepPlaceholder key={key} style={newStepPlaceholderStyle}>
        <PlusIcon availableWidth={this.layout.cardHint} />
        <StepNumber />
      </NewStepPlaceholder>
    );
  };

  renderSteps = steps => {
    const containerStyle = {
      height: this.layout.containerHeight,
      marginTop: this.layout.extraVerticalSpace,
      WebkitTransform: this.containerTransform,
      transform: this.containerTransform
    };

    const newStep = this.renderNewStep('new', this.newStepIndex);
    const allSteps = steps.map((step, index) => this.renderExistingStep(step.id, index, step));
    allSteps.splice(this.newStepIndex, 0, newStep);

    return <SwipeableContainer style={containerStyle}>{allSteps}</SwipeableContainer>;
  };

  setTopElementRef = topElementRef => {
    this.topElementRef = topElementRef;
  };

  @computed
  get containerTransform() {
    const {slideWidth, overlayWidth} = this.layout;

    if (!slideWidth || !overlayWidth || this.currentStepIndex === null) {
      return null;
    }

    const translateX = overlayWidth - this.currentStepIndex * slideWidth;
    return `translate3d(${translateX}px, 0, 0)`;
  }

  @computed
  get currentStepIndex() {
    const {store} = this.props;
    const {dragDropSteps, router} = store;
    const {steps} = dragDropSteps;
    const {stepId} = router.params;

    const index = findIndex(steps, {id: stepId});

    if (index === -1) {
      return this.newStepIndex;
    }

    return index;
  }

  @computed
  get isReadOnly() {
    const {store} = this.props;
    const {editInstructionDetailsPage} = store;
    const {currentVersionId} = editInstructionDetailsPage;

    return !isDraftVersionId(currentVersionId);
  }

  @computed
  get newStepIndex() {
    const {newStepIndex, store} = this.props;
    const {dragDropSteps} = store;
    const {steps} = dragDropSteps;

    return isNumber(newStepIndex) ? newStepIndex : steps.length;
  }

  render() {
    const {store} = this.props;
    const {
      dragDropSteps,
      router,
      editInstructionDetailsPage: {isChecklist}
    } = store;
    const {shouldBeInteractive, steps} = dragDropSteps;
    const {id: guideId, topicId, instructionId} = router.params;

    const overlayStyle = {
      height: this.layout.overlayHeight,
      width: this.layout.overlayWidth - this.layout.cardSpacing / 2
    };

    const writingTipsStyle = {
      width: this.layout.slideWidth - this.layout.cardSpacing || 0
    };

    const isCurrentStepFirst = this.currentStepIndex === 0;
    const isCurrentStepLast = this.currentStepIndex === steps.length;
    const prevEntityDataCy = isChecklist ? 'prev-check' : 'prev-step';
    const nextEntityDataCy = isChecklist ? 'next-check' : 'next-step';

    return (
      <SwipeableSteps>
        <Top ref={this.setTopElementRef}>
          <Header>
            <OverviewLink
              queryParams={router.queryParams}
              params={{id: guideId, topicId, instructionId}}
              store={store}
              route={views.editInstruction}
            >
              <OverviewIcon />
              <FormattedMessage {...messages.backToOverview} />
            </OverviewLink>
          </Header>
          <Content extendedMinHeight={contentExtendedMinHeight} minHeight={contentMinHeight}>
            <StepsSwiperResizeListener
              defaultResponsiveMargin="large"
              maxCardWidth={1600}
              minHeight={contentMinHeight}
              minPortraitRatio={1.6}
              minWidth={270}
              navigationArrowsHeight={0}
              onChange={this.handleLayoutChange}
              responsiveMargins={{
                small: {
                  breakpoint: 400,
                  cardHint: 50,
                  cardSpacing: 16
                },
                medium: {
                  breakpoint: 800,
                  cardHint: 50,
                  cardSpacing: 32
                },
                large: {
                  breakpoint: 1200,
                  cardHint: 50,
                  cardSpacing: 80
                }
              }}
              shouldHideContentWhileResizing={false}
            >
              {this.renderSteps(steps)}
              {shouldBeInteractive && !isCurrentStepFirst && (
                <GoToPreviousStepOverlay
                  onClick={this.handleNavigationOverlayClickFactory(-1)}
                  style={overlayStyle}
                  data-cy={prevEntityDataCy}
                />
              )}
              {shouldBeInteractive && !isCurrentStepLast && (
                <GoToNextStepOverlay
                  onClick={this.handleNavigationOverlayClickFactory(+1)}
                  style={overlayStyle}
                  data-cy={nextEntityDataCy}
                />
              )}
            </StepsSwiperResizeListener>
          </Content>
        </Top>
        {this.writingTipsType && <WritingTips type={this.writingTipsType} style={writingTipsStyle} />}
        <CustomDragLayer layout={this.layout} />
      </SwipeableSteps>
    );
  }
}

export default injectIntl(SwipeableStepsComponent);
