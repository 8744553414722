import {generateInstruction} from './instruction';

import {emptyTextForForrmating} from '../constants';

export const generateInstructions = async (instructions, topic, pdfMessages, guide, guideBaseUrl, intl) => {
  if (instructions.length > 0) {
    const instructionsContent = [];

    for (let i = 0; i < instructions.length; i++) {
      const isFirstInstruction = i === 0;

      if (instructions[i].steps.length > 0) {
        const instruction = await generateInstruction(
          instructions[i],
          topic,
          pdfMessages,
          isFirstInstruction,
          guide,
          guideBaseUrl,
          intl
        );

        instructionsContent.push(instruction);
      }
    }

    return [...instructionsContent, {text: emptyTextForForrmating}];
  }
};
