import PropTypes from 'prop-types';
import React from 'react';

//styled-components
import {CampaignTemplateCard, Description, Image, Main, Title} from './styles';

const CampaignTemplateCardComponent = ({description, imageUrl, isSelected, onClick, title}) => (
  <CampaignTemplateCard isSelected={isSelected} onClick={onClick}>
    <Image src={imageUrl} />
    <Main>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Main>
  </CampaignTemplateCard>
);

CampaignTemplateCardComponent.propTypes = {
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired
};

export default CampaignTemplateCardComponent;
