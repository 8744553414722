import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

export const plugins = {dvr: dvr(validatorjs)};

export const fields = [
  {
    name: 'entityNumber',
    rules: 'required|integer|min:1'
  },
  {
    name: 'entityType',
    rules: 'required|string'
  }
];

export default new MobxReactForm({fields}, {plugins});
