import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {toJS} from 'mobx';
import {inject, observer} from 'mobx-react';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import filter from 'lodash/filter';

import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import DateFilter from 'components/DateFilter';
import LoadingMessage from 'components/LoadingMessage';

import {CUSTOM_SIGNOFF_FILTER_OPTIONS, CAMPAIGN_STATUS_ENUMS} from 'shared/enums';

import {CampaignsLite, CampaignAnswers} from 'api/campaign/queries';
import {AllGuidesLite} from 'api/guide/queries';
import {DomainsLite} from 'api/domain/queries';
import {TagsLite} from 'api/tag/queries';
import {campaignsLiteOptions, campaignAnswersOptions} from 'api/campaign/query-options';
import {guidesLiteForSignOffsOptions} from 'api/guide/query-options';
import {domainsOptions} from 'api/domain/query-options';
import {tagsLiteOptions} from 'api/tag/query-options';

import views from 'config/views';

import messages from './messages';

import {HeaderWrapper, StyledText} from 'ui-components/Layout/DataTable/styles';
import {StyledTable, InfoWrapper, ExtraInfoWrapper, StyledCombobox, FiltersWrapper, CellWrapper} from './styles';

import {sortByDateTime} from 'ui-components/Layout/DataTable/sorters';

const {SIGNOFF, START_DATE, END_DATE, GUIDE, WORKSPACE, TAG} = CUSTOM_SIGNOFF_FILTER_OPTIONS;
const RESULTS_LOADER_KEY = 'SIGNOFF_RESULTS';

@inject('store')
@graphql(CampaignsLite, campaignsLiteOptions)
@graphql(CampaignAnswers, campaignAnswersOptions)
@graphql(AllGuidesLite, guidesLiteForSignOffsOptions)
@graphql(DomainsLite, domainsOptions)
@graphql(TagsLite, tagsLiteOptions)
@observer
class CampaignResultsComponent extends Component {
  state = {
    defaultSorted: [
      {
        id: 'DATE_AND_TIME',
        desc: true
      }
    ]
  };

  async UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {signoffResultsPage, router}
    } = this.props;

    const {params = {}} = router;

    const translations = mapValues(
      pick(messages, [
        'today',
        'yesterday',
        'last7days',
        'last30days',
        'lastMonth',
        'weekToDate',
        'monthToDate',
        'custom',
        'campaign_action_yes',
        'campaign_action_no',
        'campaign_action_pass',
        'campaign_action_fail'
      ]),
      message => formatMessage(message)
    );

    signoffResultsPage.setTranslations(translations);

    if (params.campaignId) {
      signoffResultsPage.selectCustomFilterOptions({
        [SIGNOFF]: [params.campaignId]
      });
    }
  }

  componentWillUnmount() {
    const {
      store: {signoffResultsPage}
    } = this.props;

    signoffResultsPage.dispose();
  }

  componentDidUpdate(prevProps) {
    const {store} = this.props;
    const {signoffResultsPage} = store;

    const queriesConfig = [
      {
        queryName: 'campaignAnswersQuery',
        dataKey: 'campaignAnswers',
        setData: data => signoffResultsPage.setSignoffResults(data),
        setLoader: isLoading => signoffResultsPage.setLoader(RESULTS_LOADER_KEY, isLoading)
      },
      {
        queryName: 'campaignsLiteQuery',
        dataKey: 'campaigns',
        setData: data =>
          signoffResultsPage.setCustomFilterEntity(
            SIGNOFF,
            filter(data, ({status}) => ![CAMPAIGN_STATUS_ENUMS.DRAFT].includes(status))
          ),
        setLoader: isLoading => signoffResultsPage.setLoader(SIGNOFF, isLoading)
      },
      {
        queryName: 'guidesListLiteQuery',
        dataKey: 'guidesForCms',
        setData: data =>
          signoffResultsPage.setCustomFilterEntity(
            GUIDE,
            data ? data.filter(guide => guide).filter(guide => guide.title) : []
          ),
        setLoader: isLoading => signoffResultsPage.setLoader(GUIDE, isLoading)
      },
      {
        queryName: 'domainsQuery',
        dataKey: 'domains',
        setData: data => signoffResultsPage.setCustomFilterEntity(WORKSPACE, data),
        setLoader: isLoading => signoffResultsPage.setLoader(WORKSPACE, isLoading)
      },
      {
        queryName: 'tagsQuery',
        dataKey: 'tags',
        setData: data => signoffResultsPage.setCustomFilterEntity(TAG, data),
        setLoader: isLoading => signoffResultsPage.setLoader(TAG, isLoading)
      }
    ];

    queriesConfig.forEach(queryConfig => {
      const prevQuery = prevProps[queryConfig.queryName];
      const currentQuery = this.props[queryConfig.queryName];

      queryConfig.setLoader(currentQuery.loading);

      if (prevQuery.loading && !currentQuery.loading) {
        queryConfig.setData(currentQuery[queryConfig.dataKey]);
      }
    });
  }

  render() {
    const {
      intl: {formatMessage},
      store: {
        signoffResultsPage,
        router: {goTo}
      }
    } = this.props;

    const {
      customFilterOptions,
      customFilter,
      areResultsLoading,
      selectCustomFilterOptions,
      signoffTableResults,
      translations
    } = signoffResultsPage;

    const columns = [
      {
        Header: (
          <HeaderWrapper>
            <StyledText marginLeft={25}>{formatMessage(messages.submittedName)}</StyledText>
          </HeaderWrapper>
        ),
        accessor: 'SUBMITTED_NAME',
        resizable: true,
        width: 200,
        Cell: ({original: {SUBMITTED_NAME}}) => (
          <CellWrapper title={SUBMITTED_NAME}>
            <StyledText marginLeft={25}>{SUBMITTED_NAME}</StyledText>
          </CellWrapper>
        )
      },
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.dateAndTime)}</HeaderWrapper>,
        id: 'DATE_AND_TIME',
        accessor: ({DATE, TIME}) => `${DATE} ${TIME}`,
        resizable: true,
        width: 200,
        sortMethod: sortByDateTime,
        Cell: ({original: {DATE, TIME}}) => {
          const dateAndTime = `${DATE} ${TIME}`;
          return (
            <CellWrapper paddingLeft title={dateAndTime}>
              <StyledText>{dateAndTime}</StyledText>
            </CellWrapper>
          );
        }
      },
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.instruction)}</HeaderWrapper>,
        accessor: 'INSTRUCTION',
        resizable: true,
        width: 200,
        Cell: ({original: {INSTRUCTION, GUIDE}}) => (
          <CellWrapper paddingLeft>
            <InfoWrapper title={INSTRUCTION}>{INSTRUCTION}</InfoWrapper>
            <ExtraInfoWrapper title={GUIDE}>{GUIDE}</ExtraInfoWrapper>
          </CellWrapper>
        )
      },
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.signOff)}</HeaderWrapper>,
        accessor: 'SIGN_OFF',
        resizable: true,
        width: 200,
        Cell: ({original: {SIGN_OFF, SIGN_OFF_QUESTION}}) => (
          <CellWrapper paddingLeft>
            <InfoWrapper title={SIGN_OFF}>{SIGN_OFF}</InfoWrapper>
            <ExtraInfoWrapper title={SIGN_OFF_QUESTION}>{SIGN_OFF_QUESTION}</ExtraInfoWrapper>
          </CellWrapper>
        )
      },
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.answer)}</HeaderWrapper>,
        accessor: 'SIGN_OFF_ANSWER',
        resizable: true,
        sortable: false,
        width: 120,
        Cell: ({original: {SIGN_OFF_ANSWER, SIGN_OFF_ANSWER_RAW}}) => (
          <CellWrapper paddingLeft title={SIGN_OFF_ANSWER_RAW}>
            {SIGN_OFF_ANSWER}
          </CellWrapper>
        )
      }
    ];

    const customComboboxMessages = {
      selectAll: messages.selectAll,
      deselectAll: messages.deselectAll
    };

    const filterEntries = [
      {
        label: `${formatMessage(messages.date)}:`,
        component: () => (
          <DateFilter
            translations={translations}
            startDate={customFilter.START_DATE}
            endDate={customFilter.END_DATE}
            onApply={(startDate, endDate) => {
              selectCustomFilterOptions({
                [START_DATE]: startDate,
                [END_DATE]: endDate
              });
            }}
          />
        )
      },
      {
        label: `${formatMessage(messages.signoffs)}:`,
        component: () => (
          <StyledCombobox
            dataTestId={'signoff-picker'}
            value={toJS(customFilter[SIGNOFF])}
            valueAccessor={signoff => signoff.id}
            labelAccessor={signoff => signoff.name}
            options={customFilterOptions[SIGNOFF]}
            title={formatMessage(messages.filterBySignoff)}
            customMessages={{...customComboboxMessages, selected: messages.selectedSignoffs}}
            onChange={value => {
              selectCustomFilterOptions({
                [SIGNOFF]: value
              });
            }}
          />
        )
      },
      {
        label: `${formatMessage(messages.guide)}:`,
        component: () => (
          <StyledCombobox
            dataTestId={'guide-picker'}
            value={toJS(customFilter[GUIDE])}
            valueAccessor={guide => guide.id}
            labelAccessor={guide => guide.name}
            options={customFilterOptions[GUIDE]}
            title={formatMessage(messages.filterByGuide)}
            customMessages={{...customComboboxMessages, selected: messages.selectedGuide}}
            onChange={value => {
              selectCustomFilterOptions({
                [GUIDE]: value
              });
            }}
          />
        )
      },
      {
        label: `${formatMessage(messages.workspace)}:`,
        component: () => (
          <StyledCombobox
            dataTestId={'workspace-picker'}
            value={toJS(customFilter[WORKSPACE])}
            valueAccessor={workspace => workspace.id}
            labelAccessor={workspace => workspace.name}
            options={customFilterOptions[WORKSPACE]}
            title={formatMessage(messages.filterByWorkspace)}
            customMessages={{...customComboboxMessages, selected: messages.selectedWorkspace}}
            onChange={value => {
              selectCustomFilterOptions({
                [WORKSPACE]: value
              });
            }}
          />
        )
      },
      {
        label: `${formatMessage(messages.tag)}:`,
        component: () => (
          <StyledCombobox
            dataTestId={'tags-picker'}
            value={toJS(customFilter[TAG])}
            valueAccessor={tag => tag.id}
            labelAccessor={tag => tag.name}
            options={customFilterOptions[TAG]}
            title={formatMessage(messages.filterByTag)}
            customMessages={{...customComboboxMessages, selected: messages.selectedTag}}
            onChange={value => {
              selectCustomFilterOptions({
                [TAG]: value
              });
            }}
          />
        )
      }
    ];

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} subtitle={formatMessage(messages.subtitle)} />
        <FiltersWrapper filterEntries={filterEntries} onReset={signoffResultsPage.initCustomFilter} />
        {areResultsLoading ? (
          <LoadingMessage>{formatMessage(messages.loading)}</LoadingMessage>
        ) : (
          <StyledTable
            data={signoffTableResults}
            paddingRows={5}
            columns={columns}
            noDataMessage={
              <span data-testid={'no-signoffs-submitted'}>{formatMessage(messages.noResultsSubmitted)}</span>
            }
            hoverEffect
            getTrProps={(_, {original: {SIGN_OFF_ID, SIGN_OFF_RESULT_ID}}) => ({
              onClick: () => goTo(views.campaignResult, {campaignId: SIGN_OFF_ID, resultId: SIGN_OFF_RESULT_ID})
            })}
            defaultSorted={this.state.defaultSorted}
            onSortedChange={sorts => this.setState({defaultSorted: sorts})}
          />
        )}
      </Container>
    );
  }
}

export default injectIntl(CampaignResultsComponent);
