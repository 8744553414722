import React, {ReactElement, useEffect} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';
import StepsTabs from 'components/StepsTabs';
import messages from './messages';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

const ChecklistAlerts = (props): ReactElement => {
  const {
    intl: {formatMessage},
    store
  } = props;

  const {checklistAlerts} = store;
  const {stepsStore} = checklistAlerts;

  useEffect(() => {
    const {
      store,
      intl: {formatMessage}
    } = props;
    const translations = mapValues(pick(messages, ['recipients', 'platformAlerts']), message => formatMessage(message));

    store.checklistAlerts.setTranslations(translations);
  }, []);

  return (
    <Container>
      <ViewTitle
        title={formatMessage(messages.checklistAlerts)}
        subtitle={formatMessage(messages.checklistAlertsInfo)}
      />
      <StepsTabs
        mode={'edit'}
        loading={false}
        error={null}
        stepsStore={stepsStore}
        page={checklistAlerts}
        hideButtons={true}
      />
    </Container>
  );
};

export default injectIntl(inject('store')(observer(ChecklistAlerts)));
