//refresh queries
import {refreshThemesLite, refreshThemes} from 'api/theme/refresh-queries';
import {refreshProfile} from 'api/user/refresh-queries';
import {refreshPlatform} from 'api/platform/refresh-queries';

//constants
import C from './constants';

export const createThemeOptions = {
  props: ({mutate}) => ({
    [C.CREATE_THEME]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshThemesLite(), refreshThemes(), refreshProfile(), refreshPlatform()]
      })
  })
};

export const editThemeOptions = {
  props: ({mutate}) => ({
    [C.EDIT_THEME]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshThemesLite(), refreshProfile(), refreshPlatform()]
      })
  })
};
