import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  fullName: {
    id: 'common.forms.fullName',
    defaultMessage: 'Full name'
  },
  selectUser: {
    id: 'UserRole.selectUser',
    defaultMessage: 'User'
  },
  noUsersAvailable: {
    id: 'noUsersAvailable',
    defaultMessage: 'There are no more users available to add.'
  },
  selectRole: {
    id: 'UserRole.selectRole',
    defaultMessage: 'Role'
  },
  noJobTitle: {
    id: 'common.forms.noJobTitle',
    defaultMessage: 'No Job Title'
  },
  selectJobTitle: {
    id: 'UserRole.selectJobTitle',
    defaultMessage: 'Job Title'
  },
  addAnotherUser: {
    id: 'UserRole.addAnotherUser',
    defaultMessage: 'Add another user'
  },
  title: {
    id: 'UserRole.title',
    defaultMessage: 'Add Users to this Team'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  createSuccess: {
    id: 'UserRole.createSuccess',
    defaultMessage: 'Team member has been created.'
  },
  createFailure: {
    id: 'UserRole.createFailure',
    defaultMessage: 'Failed to create team member.'
  },
  createMultipleSuccess: {
    id: 'UserRole.createMultipleSuccess',
    defaultMessage: 'Teams member have been created.'
  },
  createMultipleFailure: {
    id: 'UserRole.createMultipleFailure',
    defaultMessage: 'Failed to create team members.'
  },
  approver: {
    id: 'common.field.approver',
    defaultMessage: 'Approver'
  }
});

export default messages;
