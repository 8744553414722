import views from 'config/views';

//mobx
import store from 'stores/store';
import {action, makeObservable, observable} from 'mobx';

//helpers
import notification from 'utils/notification-utils';

//models
import SetPasswordForm from '../forms/set-password-form.js';

class SetPasswordPage {
  @observable form = SetPasswordForm();
  @observable isInvalidLink = false;

  translations = {};

  @action
  submitSetPasswordForm = setPasswordMutation => {
    const {newPassword} = this.form.values();
    const {token} = store.router.queryParams;
    setPasswordMutation({token, password: newPassword})
      .then(() => {
        notification.success(this.translations.requestSuccess);
        store.router.goTo(views.login, {});
      })
      .catch(this.handleSetPasswordError);
  };

  @action
  setPasswordLinkNotValid = () => {
    this.isInvalidLink = true;
  };

  @action
  handleSetPasswordError = () => {
    notification.error(this.translations.requestFailure);
  };

  setTranslations(translations) {
    this.translations = translations;
  }

  constructor() {
    makeObservable(this);
  }
}

export default SetPasswordPage;
