import styled from 'styled-components';

export const PillWrapper = styled.div`
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 16px;
  gap: 10px;
  border: 1px solid #ececec;
  border-radius: 28px;
  align-self: stretch;
  flex-grow: 0;
  color: #298784;
  text-transform: uppercase;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    border-color: #298784;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;
