import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import $Icon from 'ui-components/Layout/Icon';
import {truncate} from 'lodash';

export const ListGroupItemsContainer = styled.div`
  padding-left: 32px;
  transition: height 0.3s ease-out;
`;

export const ListGroupItem = styled.div`
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 0;
  height: auto;
  margin-top: 10px;

  :last-child {
    border-bottom: none;
  }

  > p {
    flex-grow: 1;
  }
`;

export const Checkmark = styled($Icon).attrs(() => ({
  name: 'checkmark',
  size: 16
}))`
  color: ${commonPalette.txGrey};
  min-width: 16px;
`;

export const Close = styled($Icon).attrs(() => ({
  name: 'small-close',
  size: 16
}))`
  color: ${({hidden}) => (hidden ? 'transparent' : commonPalette.txGrey)};
  min-width: 16px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
export const StyledBeforeText = styled.div.attrs(({dangerouslySetInnerHTML}) => {
  if (!dangerouslySetInnerHTML) return null;

  const text = dangerouslySetInnerHTML?.__html;
  return {
    title: text,
    dangerouslySetInnerHTML: {
      __html: truncate(text, {length: 300})
    }
  };
})`
  color: var(--Text-Secondary, #575f5f);
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-decoration: line-through;
`;

export const StyledAfterText = styled.div.attrs(({dangerouslySetInnerHTML}) => {
  if (!dangerouslySetInnerHTML) return null;

  const text = dangerouslySetInnerHTML?.__html;
  if (!text) return null;
  return {
    title: text,
    dangerouslySetInnerHTML: {
      __html: truncate(text, {length: 300})
    }
  };
})`
  color: var(--Text-Secondary, #575f5f);

  /* General/Body */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

export const StyledImageWrapper = styled.div`
  width: 75px;
  aspect-ratio: 4/3;
`;

export const WorkspaceWrapper = styled.span`
  color: ${commonPalette.txBlack};
`;
