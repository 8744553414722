import PropTypes from 'prop-types';
import React, {Component} from 'react';

//styles
import {Circle, InsertNewStepBeforeButton, PlusIcon, StepControls} from './styles';

class StepControlsComponent extends Component {
  static propTypes = {
    design: PropTypes.oneOf(['medium', 'large']),
    onInsertNewStepClick: PropTypes.func
  };

  static defaultProps = {
    design: 'medium'
  };

  render() {
    const {design, onInsertNewStepClick, ...forwardedProps} = this.props;

    return (
      <StepControls {...forwardedProps}>
        <InsertNewStepBeforeButton onClick={onInsertNewStepClick}>
          <Circle design={design}>
            <PlusIcon design={design} />
          </Circle>
        </InsertNewStepBeforeButton>
      </StepControls>
    );
  }
}

export default StepControlsComponent;
