import styled from 'styled-components';
import flex from 'styles/flex';

export const ForgotPasswordView = styled.div`
  ${flex.centerVertical};
  ${flex.vertical};
  flex: 1;
  min-height: 500px;
`;

export const FormWrapper = styled.div`
  ${flex.vertical}
  width: 400px;
`;

export const AlreadyLoggedIn = styled.div`
  font-size: 16px;
`;
