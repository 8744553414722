import {defineMessages} from 'react-intl';

const messages = defineMessages({
  connectNewWorkspace: {
    id: 'connectNewWorkspace',
    defaultMessage: 'Connect a new Workspace'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  connect: {
    id: 'connect',
    defaultMessage: 'Connect'
  },
  teamOpportunity: {
    id: 'teamOpportunity',
    defaultMessage: 'Allow this team to view and publish on another workspace.'
  },
  workspace: {
    id: 'Share.workspace',
    defaultMessage: 'Workspace'
  },
  addWorkspaceSuccess: {
    id: 'AddTeamWorkspace.addWorkspaceSuccess',
    defaultMessage: 'Workspace has been added to the team'
  },
  addWorkspaceFailure: {
    id: 'AddTeamWorkspace.addWorkspaceFailure',
    defaultMessage: 'Failed to add workspace to the team'
  },
  noWorkspacesToAdd: {
    id: 'noWorkspacesToAdd',
    defaultMessage: 'There are no more workspaces available to add.'
  }
});

export default messages;
