import styled from 'styled-components';

export const SelectLanguageWrapper = styled.div`
  margin-bottom: ${({languageOptions}) => (languageOptions ? (languageOptions > 3 ? 135 : languageOptions * 45) : 0)}px;
`;

export const StyledInfoText = styled.p`
  margin: 20px 0;
`;

export const LanguagePickerWrapper = styled.div`
  width: 60%;
`;

export const SpinnerWrapper = styled.div`
  margin: 5px;
  padding: 3px;
`;
