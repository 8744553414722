import gql from 'graphql-tag';

export const Campaigns = gql`
  query campaigns {
    campaigns {
      id
      title
      status
      isEditable
      defaultLocale
      numInstructionsAppliedTo
      question {
        kind
        questionTranslations
        numOfRating
        followUps {
          kind
          rangeFrom
          rangeTo
          questionTranslations
        }
        textTypeOptions
      }
      showPeriodSeconds
    }
  }
`;

export const CampaignsLite = gql`
  query campaignsLite {
    campaigns {
      id
      title
      status
    }
  }
`;

export const Campaign = gql`
  query campaign($campaignId: String!) {
    campaign(id: $campaignId) {
      id
      title
      defaultLocale
      status
      showPeriodSeconds
      question {
        kind
        questionTranslations
        numOfRating
        followUps {
          kind
          rangeFrom
          rangeTo
          questionTranslations
        }
        textTypeOptions
      }
    }
  }
`;

export const CampaignToDuplicate = gql`
  query campaignToDuplicate($campaignId: String!) {
    campaign(id: $campaignId) {
      id
      title
      defaultLocale
      showPeriodSeconds
      question {
        kind
        questionTranslations
        numOfRating
        followUps {
          kind
          rangeFrom
          rangeTo
          questionTranslations
        }
        textTypeOptions
      }
    }
  }
`;

export const CampaignReport = gql`
  query campaignReport($campaignId: String!, $instructionId: String) {
    campaignReport(campaignId: $campaignId, instructionId: $instructionId) {
      campaignId
      campaignTitle
      question
      questionKind
      instructions {
        id
        title
      }
      avgRating
      numOfRating
      ratingsPerScore
      feedbacksLength
      textTypeOptions
      campaignQuestionLocale
      userPlatformLocale
    }
  }
`;

export const CampaignFeedback = gql`
  query campaignFeedback($campaignId: String!, $instructionId: String, $feedbackOffset: Int, $feedbackLimit: Int) {
    campaignFeedback(
      campaignId: $campaignId
      instructionId: $instructionId
      feedbackOffset: $feedbackOffset
      feedbackLimit: $feedbackLimit
    ) {
      date
      score
      question
      answer
      user
    }
  }
`;

export const CampaignAnswers = gql`
  query campaignAnswers(
    $campaignIds: [String]
    $domainIds: [String]
    $guideIds: [String]
    $tagIds: [String]
    $startDate: Date
    $endDate: Date
  ) {
    campaignAnswers(
      campaignIds: $campaignIds
      domainIds: $domainIds
      guideIds: $guideIds
      tagIds: $tagIds
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      user
      answer
      followUpAnswer
      followUpQuestion {
        kind
        questionTranslations
      }
      createdAt
      submittedAt
      domain {
        name
      }
      instruction {
        id
        title
        topicId
      }
      topic {
        id
        title
      }
      loggedInUser {
        id
        fullName
        ssoId
        teams {
          team {
            name
          }
        }
      }
      guide {
        id
        title
        ownedBy {
          id
          name
        }
      }
      campaign {
        id
        title
        question {
          kind
          textTypeOptions
          questionTranslations
          followUps {
            kind
            questionTranslations
          }
        }
        defaultLocale
      }
    }
  }
`;

export const CampaignAnswer = gql`
  query campaignAnswer($id: String!) {
    campaignAnswer(id: $id) {
      user
      domain {
        name
      }
      loggedInUser {
        fullName
        ssoId
      }
      createdAt
      submittedAt
      guide {
        title
        ownedBy {
          name
        }
      }
      instruction {
        title
      }
      campaign {
        title
        defaultLocale
        question {
          textTypeOptions
          kind
          questionTranslations
          followUps {
            kind
            rangeFrom
            rangeTo
            questionTranslations
          }
        }
      }
      answer
      followUpAnswer
      followUpQuestion {
        kind
        rangeFrom
        rangeTo
        questionTranslations
      }
    }
  }
`;

export const CampaignGuidesQuery = gql`
  query campaignGuides($id: String!) {
    campaignGuides(id: $id) {
      id
      title
      slug
      topics {
        id
        instructions {
          id
          title
          slug
        }
      }
    }
  }
`;
