import {defineMessages} from 'react-intl';

const messages = defineMessages({
  titleStep: {
    id: 'NewStep.title',
    defaultMessage: 'New step'
  },
  titleCheck: {
    id: 'titleCheck',
    defaultMessage: 'New check'
  }
});

export default messages;
