//refresh queries
import {refreshTeam, refreshTeams, refreshAllParentTeams, refreshAllTeamChildren} from 'api/team/refresh-queries';
import {refreshProfile} from 'api/user/refresh-queries';

//constants
import C from './constants';

export const createTeamOptions = {
  props: ({mutate}) => ({
    [C.CREATE_TEAM]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshTeams(), refreshProfile()]
      })
  })
};

// graphql is used to call function
export const editTeamOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.EDIT_TEAM]: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id), refreshTeams()]
      });
    }
  })
};

// useMutation hook is used to call function
export const getEditTeamOptionsForHook = ({editTeam}) => ({
  variables: {
    editTeam
  },
  refetchQueries: [refreshTeams(), refreshProfile()]
});

export const archiveTeamOptions = {
  props: ({mutate}) => ({
    [C.ARCHIVE_TEAM]: variables =>
      mutate({
        variables,
        refetchQueries: [refreshProfile(), refreshAllParentTeams(), refreshAllTeamChildren()]
      })
  })
};

export const createTeamUsersOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.CREATE_TEAM_USERS]: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id)]
      });
    }
  })
};

export const updateTeamUserRoleOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.UPDATE_TEAM_USER]: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id), refreshTeams()]
      });
    }
  })
};

export const saveGuideApprovalSettingsOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.SAVE_APPROVAL_SETTINGS]: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id), refreshTeams()]
      });
    }
  })
};

export const removeTeamMemberOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.REMOVE_TEAM_USER]: variables => {
      const id = store.router.params.id;
      const removeSelf = variables.userId === variables.me.id;
      const isPlatformAdmin = variables.me.isPlatformAdmin;
      if (isPlatformAdmin && removeSelf) {
        return mutate({
          variables,
          refetchQueries: [refreshTeam(id), refreshTeams()]
        });
      }
      if (removeSelf) {
        return mutate({
          variables,
          refetchQueries: [refreshTeams()]
        });
      }
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id)]
      });
    }
  })
};

export const addDomainsOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.ADD_DOMAINS]: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id)]
      });
    }
  })
};

export const removeTeamFromDomainOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    [C.REMOVE_TEAM_FROM_DOMAIN]: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshTeam(id)]
      });
    }
  })
};
