import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {
  dvr: dvr(validatorjs)
};

export default isPlatformAdmin => {
  const teamField = isPlatformAdmin ? {name: 'teamId', rules: 'string'} : {name: 'teamId', rules: 'string|required'};
  const fields = [
    {
      name: 'name',
      rules: 'string|required'
    },
    {
      name: 'logoMediaId',
      rules: 'string'
    },
    {
      name: 'faviconMediaId',
      rules: 'string'
    },
    teamField
  ];

  return new MobxReactForm({fields}, {plugins});
};
