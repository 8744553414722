import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ResetPassword.title',
    defaultMessage: 'Reset Password'
  },
  newPassword: {
    id: 'ResetPassword.newPassword',
    defaultMessage: 'New password'
  },
  newPasswordConfirm: {
    id: 'ResetPassword.newPasswordConfirm',
    defaultMessage: 'Retype new password'
  },
  submit: {
    id: 'common.actions.submit',
    defaultMessage: 'Submit'
  },
  requestSuccess: {
    id: 'ResetPassword.requestSuccess',
    defaultMessage: 'Your password has been reset. Please login with new password!'
  },
  requestFailure: {
    id: 'common.notifications.requestFailure',
    defaultMessage: 'Sorry, we could not process your request. Please try again after some time!'
  },
  invalidLink: {
    id: 'ResetPassword.invalidLink',
    defaultMessage: 'This link is no longer active. Please enter your username to get a new active link!'
  },
  passwordsDoNotMatch: {
    id: 'passwordsDoNotMatch',
    defaultMessage: 'Passwords do not match'
  }
});

export default messages;
