import {defineMessages} from 'react-intl';

const messages = defineMessages({
  addLearningActivities: {
    id: 'addLearningActivities',
    defaultMessage: 'Add Learning Activities'
  },
  oneOrMoreActivities: {
    id: 'oneOrMoreActivities',
    defaultMessage: 'Select one or more of the following activities for the skill.'
  },
  searchByName: {
    id: 'searchByName',
    defaultMessage: 'Search by name'
  },
  learningList: {
    id: 'learningList',
    defaultMessage: 'Learning List'
  },
  createNewActivity: {
    id: 'createLearningActivity',
    defaultMessage: 'Create new activity'
  },
  error: {
    id: 'common.errors.pageload',
    defaultMessage: "Couldn't load the page. Please try again."
  },
  noLearningActivitiesFound: {
    id: 'noLearningActivitiesFound',
    defaultMessage: 'No Learning Activities found'
  },
  filtersTitle: {
    id: 'MediaGallery.filter',
    defaultMessage: 'Filter'
  },
  tenTags: {
    id: 'tenTags',
    defaultMessage: 'Select up to 10 tags.'
  },
  filterByTag: {
    id: 'TagManagement.title',
    defaultMessage: 'Tags'
  }
});

export default messages;
