import {action, observable, makeObservable} from 'mobx';
import Raven from 'raven-js';
import notification from 'utils/notification-utils';
import views from 'config/views';
import store from 'stores/store';

import teamCopyToForm from 'stores/forms/team-copy-to-form';

class CopyToDialog {
  @observable opened = false;
  @observable teams;
  @observable loading = true;
  @observable.ref translations = {};
  @observable copyingGuide = false;

  guideId;
  teamCopyToForm = new teamCopyToForm();

  @action
  reset = () => {
    this.opened = false;
    this.teams = null;
    this.loading = true;
    this.guideId = null;
    this.teamCopyToForm.reset();
    this.copyingGuide = false;
  };

  @action open = guideId => {
    this.guideId = guideId;
    this.opened = true;
  };

  @action setTeams = teams => {
    this.teams = teams;
    this.loading = false;
  };

  @action setLoading = loading => (this.loading = loading);

  @action setCopyingGuide = value => (this.copyingGuide = value);

  @action
  setTranslations = translations => {
    this.translations = translations;
  };

  @action
  copyGuide = copyGuideMutaion => {
    this.setCopyingGuide(true);
    copyGuideMutaion({id: this.guideId})
      .then(({data}) => {
        notification.success(this.translations.copyToGuideSuccess);
        store.router.goTo(views.editGuide, {id: data.cloneGuide.id});
      })
      .catch(err => {
        Raven.captureException(err);
        notification.error(this.translations.copyToGuideFailure);
      })
      .finally(() => {
        this.reset();
      });
  };

  constructor() {
    makeObservable(this);
  }
}

export default CopyToDialog;
