import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'NewTag.title',
    defaultMessage: 'Create New Tag'
  },
  name: {
    id: 'EditTag.name',
    defaultMessage: 'Tag title'
  },
  badge: {
    id: 'NewTag.badge',
    defaultMessage: 'Set as Badge'
  },
  badgeDescription: {
    id: 'NewTag.badgeDescription',
    defaultMessage:
      'Authorized users can tag content as important with certifications or standards. Always protected from unauthorized changes.'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  createSuccess: {
    id: 'NewTag.createSuccess',
    defaultMessage: 'Tag has been created.'
  },
  duplicateTitle: {
    id: 'NewTag.duplicateTitle',
    defaultMessage: 'This tag name already exists'
  },
  createFailure: {
    id: 'NewTag.createFailure',
    defaultMessage: 'Failed to create the tag.'
  }
});

export default messages;
