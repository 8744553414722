import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';

import Dialog from 'ui-components/Dialog';
import RadioButton from 'shared/components/RadioButton';
import {LanguageList, Infobox} from './styles';
import messages from '../messages';
import Icon from 'ui-components/Icon';
import {injectIntl} from 'react-intl';

const ITEM_HEIGHT_EM = 4;

const getDialogSize = languageOptions => {
  const numLanguageOptions = languageOptions.length;
  if (numLanguageOptions <= 24) {
    return 'small';
  } else if (numLanguageOptions <= 32) {
    return 'medium';
  } else {
    return 'large';
  }
};

const getMaxHeightEm = languageOptions => {
  const numLanguageOptions = languageOptions.length;
  if (numLanguageOptions <= 4) {
    return ITEM_HEIGHT_EM * 2;
  } else if (numLanguageOptions <= 12) {
    return ITEM_HEIGHT_EM * 4;
  } else if (numLanguageOptions <= 32) {
    return ITEM_HEIGHT_EM * 6;
  } else {
    return ITEM_HEIGHT_EM * 12;
  }
};

const PlatformLanguageSelector = ({
  title,
  saveButtonLabel,
  isOpen,
  currentLocale,
  languageOptions,
  onChange,
  onCancel,
  onSave,
  intl
}) => {
  const sortedLanguageOptions = sortBy(languageOptions, ['language']);
  const {formatMessage} = intl;

  return (
    <Dialog
      title={title}
      isOpen={isOpen}
      size={getDialogSize(languageOptions)}
      actions={[
        {
          label: saveButtonLabel,
          design: 'text',
          onClick: () => {
            onSave();
          }
        }
      ]}
      onCancel={() => {
        onCancel();
      }}
    >
      <LanguageList maxHeightEm={getMaxHeightEm(languageOptions)}>
        {sortedLanguageOptions.map(({locale, language}) => (
          <RadioButton
            key={locale}
            caption={language}
            checked={currentLocale === locale}
            design="fill-line"
            onChange={() => {
              onChange(locale);
            }}
          />
        ))}
      </LanguageList>
      <Infobox>
        <Icon iconId="info" />
        {formatMessage(messages.subtitle)}
      </Infobox>
    </Dialog>
  );
};

PlatformLanguageSelector.propTypes = {
  title: PropTypes.string,
  saveButtonLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  currentLocale: PropTypes.string,
  languageOptions: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      language: PropTypes.string
    })
  ),
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func
};

export default injectIntl(PlatformLanguageSelector);
