import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import moment from 'moment-timezone';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Icon from 'ui-components/Layout/Icon';

//styles
import {
  MediaProperties,
  Properties,
  Property,
  PropertyKey,
  Title,
  EditNameButton,
  StyledUnderlinedTextbox,
  EditNameWrapper,
  NameContainer,
  Name
} from './styles';

//messages
import messages from './messages';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {EditMedia} from 'api/media/mutations';
import {editMediaOptions} from 'api/media/mutation-options';
import ButtonGroup from 'ui-components/ButtonGroup';

//helpers
import notification from 'utils/notification-utils';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@graphql(EditMedia, editMediaOptions)
@inject('store')
@observer
class MediaPropertiesComponent extends Component {
  state = {
    id: null,
    editingFileName: '',
    isEditing: false,
    isSaving: false
  };

  timeouts = [];

  renameSuccess = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    notification.success(formatMessage(messages.successfulEditName));
  };

  renameError = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    notification.error(formatMessage(messages.failedEditName));
  };

  onSave = async () => {
    const {
      mediaPickerDialog: {saveMedia, setSelectedMediaFileName, isUnassignedMedia, selectedMedia}
    } = this.props.store;

    this.setState({isSaving: true});

    setSelectedMediaFileName(this.state.editingFileName);
    await saveMedia(this.props.editMedia, this.renameSuccess, this.renameError);
    if (isUnassignedMedia) {
      const unassignedMedia = JSON.parse(localStorage.getItem('unassignedMedia')) || [];
      unassignedMedia.find(media => media.id === selectedMedia.id).fileName = this.state.editingFileName;
      window.localStorage.setItem('unassignedMedia', JSON.stringify(unassignedMedia));
    }
    this.setState({isSaving: false, isEditing: false, editingFileName: ''});
  };

  onEdit = () => {
    const {
      mediaPickerDialog: {selectedMedia}
    } = this.props.store;
    this.setState({isEditing: true, editingFileName: selectedMedia?.fileName});
  };

  onBlur = () => {
    this.timeouts.push(
      // When the save button is clicked the on blur is also called.
      // This timeout is to prevent the state from being reset before the save is complete.
      setTimeout(() => {
        const {isSaving} = this.state;
        if (!isSaving) {
          this.setState({
            isEditing: false,
            editingFileName: ''
          });
        }
      }, 200)
    );
  };

  handleKeyDown = e => {
    if (e.key === 'Enter' && this.state.isEditing && !this.state.isSaving) {
      this.onSave();
    }
  };

  componentDidMount() {
    // Lister for enter key to save the name when editing
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.timeouts.forEach(timeout => clearTimeout(timeout));
    // Remove the listener for the enter key
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleFileNameChange(fileName) {
    this.setState({editingFileName: fileName});

    trackEvent(EVENT_TYPES.RENAME_FILE);
  }

  render() {
    const {
      store: {mediaPickerDialog: dialog},
      intl: {formatMessage}
    } = this.props;
    const {selectedMedia} = dialog;
    const shouldAnyPropertiesBeShown = Boolean(selectedMedia);
    const fileName = selectedMedia?.fileName || formatMessage(messages.untitled);
    return (
      <MediaProperties>
        <Title>
          <FormattedMessage {...messages.properties} />
        </Title>
        <Properties>
          <Property>
            <PropertyKey>
              <FormattedMessage {...messages.name} />
            </PropertyKey>
            {this.props.selectedId && (
              <>
                {this.state.isEditing ? (
                  <EditNameWrapper>
                    <StyledUnderlinedTextbox
                      data-cy="edit-media-name"
                      type="text"
                      autoFocus
                      value={this.state.editingFileName}
                      onChange={e => this.handleFileNameChange(e.target.value)}
                      onBlur={this.onBlur}
                      placeholder={formatMessage(messages.edit_media_name)}
                      maxLength={255}
                    />

                    <ButtonGroup
                      primary={{
                        label: formatMessage(messages.save),
                        onClick: this.onSave,
                        disabled: this.state.editingFileName === fileName || this.state.isSaving,
                        loading: this.state.isSaving
                      }}
                      secondary={{
                        label: formatMessage(messages.cancel),
                        onClick: this.onBlur,
                        disabled: this.state.isSaving
                      }}
                    />
                  </EditNameWrapper>
                ) : (
                  <NameContainer>
                    <Name>{fileName}</Name>
                    <EditNameButton data-testid={'edit-media-name-button'} onClick={this.onEdit}>
                      <Icon name="pencil" size={18} />
                    </EditNameButton>
                  </NameContainer>
                )}
              </>
            )}
          </Property>
          <Property>
            <PropertyKey>
              <FormattedMessage {...messages.uploaded} />
            </PropertyKey>
            {shouldAnyPropertiesBeShown && this.uploadDate}
          </Property>
          <Property>
            <PropertyKey>
              <FormattedMessage {...messages.usages} />
            </PropertyKey>
            {shouldAnyPropertiesBeShown && `${selectedMedia.occurence}x`}
          </Property>
          <Property>
            <PropertyKey>
              <FormattedMessage {...messages.focus} />
            </PropertyKey>
            {shouldAnyPropertiesBeShown && this.focus}
          </Property>
        </Properties>
      </MediaProperties>
    );
  }

  get focus() {
    const {store} = this.props;
    const {selectedMedia} = store.mediaPickerDialog;

    if (selectedMedia && selectedMedia.metadata && selectedMedia.metadata.focus) {
      const {kx, ky} = selectedMedia.metadata.focus;
      return `${kx}, ${ky}`;
    }

    return '0.5, 0.5';
  }

  get uploadDate() {
    const {store} = this.props;
    const {selectedMedia} = store.mediaPickerDialog;
    const userTimezone = moment.tz.guess();

    return moment(selectedMedia.dateCreated).tz(userTimezone).format('l LT');
  }
}

export default injectIntl(MediaPropertiesComponent);
