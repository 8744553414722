import {defineMessages} from 'react-intl';

const messages = defineMessages({
  publishGuide: {
    id: 'PublishGuide.publishGuide',
    defaultMessage: 'Publish Guide'
  },
  publishUpdates: {
    id: 'EditGuide.publishUpdates',
    defaultMessage: 'Publish updates'
  },
  publishOldVersion: {
    id: 'EditGuide.publishOldVersion',
    defaultMessage: 'Publish old version'
  },
  unpublishGuide: {
    id: 'PublishGuide.unpublishGuide',
    defaultMessage: 'Unpublish Guide'
  },
  singlePublishGuideMessage: {
    id: 'PublishGuide.singlePublishGuideMessage',
    defaultMessage: 'By publishing this guide you will make your guide publicly available to this workspace'
  },
  singlePublishGuideUpdatesMessage: {
    id: 'PublishGuide.singlePublishGuideUpdatesMessage',
    defaultMessage: 'By publishing those updates you will make them publicly available to this workspace'
  },
  multiPublishGuideMessage: {
    id: 'PublishGuide.multiPublishGuideMessage',
    defaultMessage: 'By publishing this guide you will make your guide publicly available to these workspaces'
  },
  multiPublishGuideUpdatesMessage: {
    id: 'PublishGuide.publishGuideUpdatesMessage',
    defaultMessage: 'By publishing those updates you will make them publicly available to these workspaces'
  },
  publishOlderVersion: {
    id: 'PublishGuide.publishOlderVersion',
    defaultMessage:
      'You\'re about to publish an older version of your guide. Your current live version will be saved as "Version {version}"'
  },
  publishOlderVersionWithoutLive: {
    id: 'PublishGuide.publishOlderVersionWithoutLive',
    defaultMessage: "You're about to publish an older version of your guide."
  },
  unpublishLive: {
    id: 'PublishGuide.unpublishLive',
    defaultMessage:
      'Are you sure you want to unpublish this guide? Your guide will not be publicly accessible anymore, but you will still be able to edit it and publish it again later'
  },
  nextReminder: {
    id: 'PublishGuide.nextReminder',
    defaultMessage: 'Next reminder to review this guide'
  },
  updateReminder: {
    id: 'updateReminder',
    defaultMessage: 'Update Reminder'
  },
  updateGuide: {
    id: 'updateGuide',
    defaultMessage: 'Update Guide'
  }
});

export default messages;
