import {action, computed, observable, makeObservable} from 'mobx';

//lodash
import find from 'lodash/find';
import omit from 'lodash/omit';
import truncate from 'lodash/truncate';

//helpers
import notification from 'utils/notification-utils';
import {sanitizeSimpleObject} from 'utils/object-utils';
import {DEFAULT_TOPIC_SLUG} from 'config/constants';
import store from 'stores/store';
import {getVersionById, isLatestVersionId} from 'utils/versioning-utils';
import views from 'config/views';

//models
import EditGuideForm from 'stores/forms/edit-guide-form';

class EditGuidePage {
  @observable loading = true;
  @observable guideCoverUrl = null;
  @observable metadata = null;
  @observable editMode = false;
  @observable domains = [];
  @observable domainIds = []; // selected domains for visibleTo selector
  @observable guide = null;
  @observable currentVersionId = null;
  @observable versions = null;
  @observable selectedTagsIds = [];
  @observable newTags = [];
  @observable teams = [];
  @observable saving = false;
  @observable allDomains = [];
  showContextMenu = true;
  showCreateContextMenu = true;
  refetchGuideQuery = null;
  updateMediaMutation = null;
  form = EditGuideForm;
  @observable localSelectedTags = [];

  @action setLocalSelectedTags = tags => {
    this.localSelectedTags = tags;
  };

  @action
  setLoading = val => (this.loading = val);

  @action
  setTeams = teams => (this.teams = teams);

  @action
  setGuideDomains = domains => {
    this.domainIds = domains.map(domain => domain.id);
  };
  @action
  setAllDomains = domains => {
    this.allDomains = domains;
  };

  @action
  setEditMode = val => (this.editMode = val);

  @action
  setForm = guide => {
    this.guide = guide;
    let mediaId = '';
    if (guide.media) {
      mediaId = guide.media.id;
      this.guideCoverUrl = guide.media.url;
    } else {
      this.guideCoverUrl = '';
    }
    if (guide.media && guide.media.metadata) {
      this.metadata = guide.media.metadata;
    } else {
      this.metadata = null;
    }
    if (guide.tags) {
      this.selectedTagsIds = guide.tags.map(t => t.id);
    }

    this.versions = guide.versions;
    this.currentVersionId = guide.versionId;
    this.form.update({
      title: guide.title,
      slug: guide.slug,
      mediaId: mediaId,
      defaultLocale: guide.defaultLocale
    });
  };

  @action
  rollbackForm = () => {
    this.setForm(this.guide);
  };

  @action
  reset = () => {
    this.loading = true;
    this.guideCoverUrl = null;
    this.metadata = null;
    this.editMode = false;
    this.domains = [];
    this.guide = null;
    this.refetchGuideQuery = null;
    this.updateMediaMutation = null;
    this.versions = null;
    this.currentVersionId = null;
    this.newTags = [];
    this.domainIds = [];
  };

  @action
  selectDomain = domainIds => {
    if (!domainIds.length) {
      return;
    }
    this.domainIds = domainIds;
  };

  @action
  submit = (updateGuideMutation, translations) => {
    this.setSaving(true);
    const {id} = store.router.params;
    const {title, slug, defaultLocale, mediaId} = this.form.values();

    const tags = this.selectedTagsIds.filter(t => !t.includes('new'));

    let guide = {
      title,
      slug,
      defaultLocale,
      mediaId: mediaId || null,
      tags: tags,
      newTags: this.newTagsForSubmit
    };

    if (this.canChangeVisibleTo) {
      guide.domains = this.domainIds.slice();
    }

    const updateGuideSuccess = () => {
      this.setSaving(false);
      notification.success(translations.successMessage);
      this.setEditMode(false);
    };

    const updateGuideFailure = () => {
      this.setSaving(false);
      notification.error(translations.unknownFailure);
      this.setEditMode(false);
    };

    guide = sanitizeSimpleObject(guide, ['title']);

    updateGuideMutation({id, guide}).then(updateGuideSuccess, updateGuideFailure);
  };

  @action
  submitGuideApproval = (submitGuideApprovalReviewMutation, approved, translations) => {
    const {id} = this.guide;
    const submitGuideApprovalSuccess = () => {
      notification.success(translations.reviewSubmitSuccess);
      return approved ? store.router.goTo(views.guides, {}) : store.router.goTo(views.editGuide, {id});
    };
    const submitGuideApprovalFailure = () => notification.error(translations.reviewSubmitFailure);
    const review = {approved};

    return submitGuideApprovalReviewMutation({id, review}).then(submitGuideApprovalSuccess, submitGuideApprovalFailure);
  };

  @action
  updateMedia = media => {
    const {id, locale} = this.guide;
    const mediaId = media ? media.id : null;
    return this.updateMediaMutation({
      id,
      locale,
      mediaId
    });
  };

  @action
  cancel = () => {
    this.setEditMode(false);
    this.selectedTagsIds = this.guide.tags ? this.guide.tags.map(t => t.id) : [];
    this.rollbackForm();
    this.domainIds = [];
  };

  @action
  setSelectedTags = (tags, newTag) => {
    this.selectedTagsIds = tags;
    if (newTag) {
      newTag.guides = [this.guide.id];
      this.newTags.push(newTag);
    }
  };

  @action
  setSaving = value => {
    this.saving = value;
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get isApprover() {
    if (!this.guide) return null;
    const {user} = store.auth;
    const teamMember = user.teams.find(userTeam => userTeam.team.id === this.guide.ownedBy.id);
    return teamMember && teamMember.isApprover;
  }

  @computed
  get canAssignAlertRecipients() {
    if (!this.guide) {
      return false;
    }
    const {user} = store.auth;
    if (!user) {
      return false;
    }
    if (user.isIPA || user.isPlatformAdmin) {
      return true;
    }
    const teamMember = user.teams.find(userTeam => userTeam.team.id === this.guide.ownedBy.id);
    return teamMember && ['ADMIN', 'EDITOR'].includes(teamMember.role);
  }

  @computed
  get canApprove() {
    return this.guide && (this.guide.canApprove || this.guide.canApproveLiveVersion);
  }

  @computed
  get canApproveLiveVersion() {
    return this.guide && this.guide.canApproveLiveVersion;
  }

  @computed
  get canSubmitFinalLiveVersionApproval() {
    return this.guide && this.guide.canSubmitFinalLiveVersionApproval;
  }

  @computed
  get canPublish() {
    return this.guide && this.guide.canPublish;
  }

  @computed
  get isUnderApproval() {
    return this.guide && this.guide.isUnderApproval;
  }

  @computed
  get isLiveVersionExpired() {
    return this.guide && this.guide.isLiveVersionExpired;
  }

  @computed
  get isViewingExpiredLiveVersion() {
    return this.guide?.isLiveVersionExpired && this.isLastestVersion;
  }

  @computed
  get isViewingRejectedLiveVersion() {
    return this.guide?.isLiveVersionRejected && this.isLastestVersion;
  }

  @computed
  get isGuideApprovalEnabled() {
    return this.guide && this.guide.ownedBy && this.guide.ownedBy.guideApproval;
  }

  @computed
  get isGuideApprovalTypeSequential() {
    return this.guide && this.guide.ownedBy?.guideApproval === 'SEQUENTIAL';
  }

  @computed
  get isGuideApprovedByMe() {
    return this.guide && this.guide.isUnderApproval && this.guide.hasBeenApprovedByMe;
  }

  @computed
  get defaultLocale() {
    return this.form.$('defaultLocale').value;
  }

  @computed
  get title() {
    const title = this.form.$('title').value;
    if (title.length > 120) {
      return title.slice(0, 120) + '...';
    } else {
      return title;
    }
  }

  @computed
  get saveButtonEnabled() {
    return this.loading === false && this.form.isValid;
  }

  @computed
  get isViewingOldVersion() {
    return (
      store.router.queryParams &&
      store.router.queryParams.v &&
      store.router.queryParams.v !== 'review' &&
      store.router.queryParams.v !== 'live-review'
    );
  }

  @computed
  get showApproveButton() {
    return (
      this.isApprover &&
      this.isGuideApprovalEnabled &&
      ((this.canApprove && !this.canPublish && this.isViewingUnderApprovalVersion && this.isUnderApproval) ||
        (this.canApproveLiveVersion &&
          !this.canSubmitFinalLiveVersionApproval &&
          this.isViewingLiveVersionUnderApproval &&
          this.isLiveVersionExpired) ||
        (this.isGuideApprovalTypeSequential && !this.canApprove && !this.isGuideApprovedByMe))
    );
  }

  @computed
  get showApproveAndPublishButton() {
    return (
      this.isApprover &&
      this.isGuideApprovalEnabled &&
      ((this.canApprove && this.canPublish && this.isViewingUnderApprovalVersion && this.isUnderApproval) ||
        (this.canApproveLiveVersion &&
          this.canSubmitFinalLiveVersionApproval &&
          this.isViewingLiveVersionUnderApproval &&
          this.isLiveVersionExpired))
    );
  }

  @computed
  get showPublishButton() {
    return !this.canApprove && this.canPublish && !this.isLastestVersion && !this.isViewingLiveVersionUnderApproval;
  }

  @computed
  get showPublishButtonDisabled() {
    return this.showPublishButton && !this.guide.totalPublishedInstructionsNumber;
  }

  @computed
  get showRequestApprovalButtonDisabled() {
    return this.showRequestApprovalButton && !this.guide.totalPublishedInstructionsNumber;
  }

  @computed
  get showUnpublishButton() {
    return this.guide && this.guide.publishStatus === 'PUBLISHED' && this.isLastestVersion;
  }

  @computed
  get showRequestApprovalButton() {
    return this.isGuideApprovalEnabled && this.isViewingDraft && !this.isUnderApproval;
  }

  @computed
  get showRejectButton() {
    return (
      this.isApprover &&
      ((this.isViewingUnderApprovalVersion && this.isUnderApproval) ||
        (this.isViewingLiveVersionUnderApproval && this.isLiveVersionExpired))
    );
  }

  @computed
  get showRequestApprovalForOldVersion() {
    if (this.isGuideApprovalEnabled && this.isViewingOldVersion && !this.isUnderApproval && !this.isLastestVersion) {
      return this.guide && this.guide.totalPublishedInstructionsNumber > 0;
    }
    return false;
  }

  @computed
  get showSetAsDraftButton() {
    return !this.isViewingDraft && !this.isViewingUnderApprovalVersion && !this.isViewingLiveVersionUnderApproval;
  }

  @computed
  get showPublishUpdatesLabel() {
    return (
      (this.isViewingDraft || this.isViewingUnderApprovalVersion) && this.isGuidePublished && this.versions.length > 0
    );
  }

  @computed
  get showPublishOldVersionLabel() {
    return !this.isViewingDraft && !this.isViewingUnderApprovalVersion && !this.isViewingLiveVersionUnderApproval;
  }

  @computed
  get showCancelRequestButton() {
    return this.isUnderApproval && ((!this.isApprover && this.isViewingUnderApprovalVersion) || this.isViewingDraft);
  }

  @computed
  get isGuidePublished() {
    return this.guide && this.guide.publishStatus === 'PUBLISHED';
  }

  @computed
  get isViewingDraft() {
    return !store.router.queryParams || !store.router.queryParams.v;
  }

  @computed
  get isViewingUnderApprovalVersion() {
    return store.router.queryParams && store.router.queryParams.v === 'review';
  }

  @computed
  get isViewingLiveVersionUnderApproval() {
    return store.router.queryParams && store.router.queryParams.v === 'live-review';
  }

  @computed
  get isLastestVersion() {
    return isLatestVersionId(this.currentVersionId, this.versions);
  }

  @computed
  get currentVersion() {
    return getVersionById(this.versions, this.currentVersionId);
  }

  @action
  backToDraftVersion = () => {
    if (!this.guide?.id) {
      return;
    }
    store?.router.goTo(views.editGuide, {id: this.guide.id});
  };

  @computed
  get defaultTopic() {
    return this.guide && find(this.guide.topics, {slug: DEFAULT_TOPIC_SLUG});
  }

  @computed
  get canChangeVisibleTo() {
    return this.guide && this.guide.canChangeVisibleTo;
  }

  @computed
  get tagsNames() {
    if (!this.guide || !this.guide.tags.length) return null;
    const tagsOnly = this.guide.tags.filter(tag => !tag.badge);
    const tagsNames = tagsOnly.map(tag => tag.title).join(', ');
    return truncate(tagsNames, {length: 30, separator: ' '});
  }

  @computed
  get badgesNames() {
    if (!this.guide || !this.guide.tags.length) return null;
    const badgesOnly = this.guide.tags.filter(tag => tag.badge);
    const badgesNames = badgesOnly.map(tag => tag.title).join(', ');
    return truncate(badgesNames, {length: 30, separator: ' '});
  }

  @computed
  get newTagsForSubmit() {
    return this.newTags.filter(tag => this.selectedTagsIds.includes(tag.id)).map(tag => omit(tag, 'id'));
  }

  setRefetchQuery = query => (this.refetchGuideQuery = query);

  setUpdateMediaMutation = mutation => (this.updateMediaMutation = mutation);
}

export default EditGuidePage;
