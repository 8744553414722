import React, {Component} from 'react';
import {inject} from 'mobx-react';

import InstructionsList from 'components/InstructionsList';

import {getFiltersFromLocalStorage} from 'utils/filters';

import {AllGuides} from './styles';

@inject('store')
class AllGuidesView extends Component {
  UNSAFE_componentWillMount() {
    const {
      store: {
        allGuidesPage: {form, selectStatusOption, selectFilterOption, changeSortBy}
      }
    } = this.props;

    const filters = getFiltersFromLocalStorage();
    const {searchTerm, selectStatus, filterOption, sortBy} = filters;

    form.$('searchTerm').sync(searchTerm);
    selectStatusOption(selectStatus);
    selectFilterOption(filterOption);
    changeSortBy(sortBy);
  }

  componentDidMount() {
    const {
      store: {app}
    } = this.props;
    const actionMenuItems = [
      {
        id: 'create',
        view: 'newGuide'
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  render() {
    const {store} = this.props;
    const {allGuidesPage} = store;
    const {form} = allGuidesPage;

    return (
      <AllGuides>
        <InstructionsList form={form} editable={true} onlyPulibhsed={false} />
      </AllGuides>
    );
  }
}

export default AllGuidesView;
