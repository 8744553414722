import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';

//helpers
import {LOCKED_ACCOUNT_STATUS_CODE, INVALID_CREDENTIALS_ERRORS} from 'shared/constants';
import views from 'config/views';
import {bindField} from 'shared/utils/input-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Icon from 'ui-components/Layout/Icon';
import AuthForm from 'shared/components/AuthForm';
import AuthFormDivider from 'shared/components/AuthFormDivider';
import ButtonGroup from 'ui-components/ButtonGroup';
import SSOButton from 'shared/components/SSOButton';
import Textbox from 'shared/components/Textbox';

//messages
import messages from './messages';
import {HelpLink} from './styles';

@inject('store')
@observer
class LoginFormComponent extends Component {
  componentWillUnmount() {
    const {store} = this.props;
    const {auth} = store;

    auth.loginForm.clear();
    auth.setErrorResponse(null);
    auth.setLoading(false);
  }

  handleEmailChange = event => {
    const {store} = this.props;
    const {auth} = store;
    const {loginForm} = auth;

    loginForm.$('email').sync(event.target.value.toLowerCase());
  };

  handleSubmit = async event => {
    const {store} = this.props;
    const {auth} = store;

    event.preventDefault();
    await auth.submitLoginForm();
  };

  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {auth, platform} = store;
    const {loginForm} = auth;
    const {sso, enforceAdminSSO} = platform;
    const showDivider = sso && !enforceAdminSSO;

    const ssoUrl = `${window.location.origin.replace('3033', '3000')}/sso/login?origin=cms&href=${
      window.location.href
    }`;
    return (
      <AuthForm
        errorNotification={this.renderError()}
        onSubmit={this.handleSubmit}
        title={<FormattedMessage {...messages.title} />}
      >
        {sso && (
          <SSOButton name={sso} iconComponent={Icon} href={ssoUrl}>
            <FormattedMessage {...messages.ssoLogin} />
          </SSOButton>
        )}
        {showDivider && (
          <AuthFormDivider>
            <FormattedMessage {...messages.divider} />
          </AuthFormDivider>
        )}
        {!enforceAdminSSO && (
          <Textbox
            autoFocus
            caption={formatMessage(messages.email)}
            type="email"
            {...bindField(loginForm, 'email', {
              onChangeFn: this.handleEmailChange
            })}
          />
        )}
        {!enforceAdminSSO && (
          <Textbox caption={formatMessage(messages.password)} type="password" {...bindField(loginForm, 'password')} />
        )}
        {!enforceAdminSSO && (
          <ButtonGroup
            style={{marginTop: 24}}
            primary={{
              label: auth.loading ? '' : formatMessage(messages.login),
              type: 'submit',
              loading: auth.loading,
              disabled: !loginForm.isValid
            }}
            secondary={{
              label: formatMessage(messages.forgotPassword),
              onClick: () => store.router.goTo(views.forgotPassword, {})
            }}
          />
        )}
      </AuthForm>
    );
  }

  renderError() {
    const {store} = this.props;
    const {auth} = store;
    const {errorResponse} = auth;

    if (!errorResponse) {
      return null;
    }

    const {data, status} = errorResponse;

    if (status === LOCKED_ACCOUNT_STATUS_CODE) {
      return (
        <FormattedMessage
          {...messages.accountLockedFailedAttempts}
          values={{
            helpLink: (
              <HelpLink
                href={'https://intercom-help.eu/swipeguide-eu/en/articles/66075-your-account-was-locked'}
                target="_blank"
              >
                <FormattedMessage {...messages.helpCenter} />
              </HelpLink>
            )
          }}
        />
      );
    }

    if (data && INVALID_CREDENTIALS_ERRORS.includes(data)) {
      return <FormattedMessage {...messages.invalidCredentials} />;
    }

    return <FormattedMessage {...messages.loginFailure} />;
  }
}

export default injectIntl(LoginFormComponent);
