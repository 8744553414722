import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//styled-components
import {UserPicker} from './styles';

//messages
import messages from './messages';

@inject('store')
@observer
class UserPickerComponent extends Component {
  render() {
    const {
      allowClear = false,
      className,
      disabled,
      field,
      filterOption,
      form,
      intl: {formatMessage},
      label,
      onChange,
      users = [],
      dataCy
    } = this.props;

    const usernames =
      users &&
      users.map(user => {
        return {name: user.fullName, id: user.id};
      });

    return (
      <UserPicker
        allowClear={allowClear}
        className={className}
        dataCy={dataCy}
        disabled={disabled}
        field={field}
        filterOption={filterOption}
        form={form}
        label={label}
        placeholder={formatMessage(messages.placeholder)}
        onChange={onChange}
        options={usernames}
        showSearch={true}
      />
    );
  }
}

export default injectIntl(UserPickerComponent);
