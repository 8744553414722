import {defineMessages} from 'react-intl';

const messages = defineMessages({
  updatedStep: {
    id: 'GuideActivity.updatedStep',
    defaultMessage: '{person} updated step content.'
  },
  updatedStepNote: {
    id: 'GuideActivity.updatedStepNote',
    defaultMessage: '{person} updated {iconType} step note content on a step.'
  },
  updatedCheck: {
    id: 'GuideActivity.updatedCheck',
    defaultMessage: '{person} updated check content.'
  },
  removedStep: {
    id: 'GuideActivity.removedStep',
    defaultMessage: '{person} removed a step.'
  },
  removedCheck: {
    id: 'GuideActivity.removedCheck',
    defaultMessage: '{person} removed a check.'
  },
  createdStep: {
    id: 'GuideActivity.createdStep',
    defaultMessage: '{person} created a step.'
  },
  createdCheck: {
    id: 'GuideActivity.createdCheck',
    defaultMessage: '{person} created a check.'
  },
  repositionedStep: {
    id: 'GuideActivity.repositionedStep',
    defaultMessage: '{person} repositioned instruction steps.'
  },
  repositionedCheck: {
    id: 'GuideActivity.repositionedCheck',
    defaultMessage: '{person} repositioned checklist checks.'
  },
  updatedInstructionTitle: {
    id: 'GuideActivity.updatedInstructionTitle',
    defaultMessage: '{person} updated instruction title.'
  },
  updatedChecklist: {
    id: 'GuideActivity.updatedChecklist',
    defaultMessage: '{person} updated checklist content.'
  },
  setInstructionToReview: {
    id: 'GuideActivity.setInstructionToReview',
    defaultMessage: '{person} set instruction to review.'
  },
  setChecklistToReview: {
    id: 'GuideActivity.setChecklistToReview',
    defaultMessage: '{person} set checklist to review.'
  },
  setInstructionToDraft: {
    id: 'GuideActivity.setInstructionToDraft',
    defaultMessage: '{person} set instruction to draft.'
  },
  setChecklistToDraft: {
    id: 'GuideActivity.setChecklistToDraft',
    defaultMessage: '{person} set checklist to draft.'
  },
  archivedInstruction: {
    id: 'GuideActivity.archivedInstruction',
    defaultMessage: '{person} archived instruction.'
  },
  archivedChecklist: {
    id: 'GuideActivity.archivedChecklist',
    defaultMessage: '{person} archived checklist.'
  },
  publishedInstruction: {
    id: 'GuideActivity.publishedInstruction',
    defaultMessage: '{person} published instruction.'
  },
  publishedChecklist: {
    id: 'GuideActivity.publishedChecklist',
    defaultMessage: '{person} published checklist.'
  },
  clonedInstruction: {
    id: 'GuideActivity.clonedInstruction',
    defaultMessage: '{person} cloned instruction.'
  },
  clonedChecklist: {
    id: 'GuideActivity.clonedChecklist',
    defaultMessage: '{person} cloned checklist.'
  },
  movedInstruction: {
    id: 'GuideActivity.movedInstruction',
    defaultMessage: '{person} moved instruction.'
  },
  movedChecklist: {
    id: 'GuideActivity.movedChecklist',
    defaultMessage: '{person} moved checklist.'
  },
  createdInstruction: {
    id: 'GuideActivity.createdInstruction',
    defaultMessage: '{person} created instruction.'
  },
  createdChecklist: {
    id: 'GuideActivity.createdChecklist',
    defaultMessage: '{person} created checklist.'
  },
  updatedTopic: {
    id: 'GuideActivity.updatedTopicTitle',
    defaultMessage: '{person} updated topic title.'
  },
  removedTopic: {
    id: 'GuideActivity.removedTopic',
    defaultMessage: '{person} removed topic.'
  },
  removedTopicV2: {
    id: 'GuideActivity.removedTopicV2',
    defaultMessage: '{person} deleted topic {topicTitle}'
  },
  movedTopic: {
    id: 'GuideActivity.movedTopic',
    defaultMessage: '{person} moved topic.'
  },
  createdTopic: {
    id: 'GuideActivity.createdTopicV2',
    defaultMessage: '{person} created a new topic.'
  },
  setGuideToDraft: {
    id: 'GuideActivity.setGuideToDraft',
    defaultMessage: '{person} set guide to draft.'
  },
  setGuideToReview: {
    id: 'GuideActivity.setGuideToReview',
    defaultMessage: '{person} set guide to review.'
  },
  repositionedGuide: {
    id: 'GuideActivity.repositionedGuide',
    defaultMessage: '{person} repositioned guide contents.'
  },
  archivedGuide: {
    id: 'GuideActivity.archivedGuide',
    defaultMessage: '{person} archived guide.'
  },
  clonedGuide: {
    id: 'GuideActivity.clonedGuide',
    defaultMessage: '{person} cloned guide.'
  },
  clonedGuideFromGuide: {
    id: 'clonedGuideFromGuide',
    defaultMessage: '{person} cloned guide from {parentGuideTitle}.'
  },
  publishedGuideV2: {
    id: 'GuideActivity.publishedGuideV2',
    defaultMessage: 'Guide published by {person}.'
  },
  updatedGuideTitle: {
    id: 'GuideActivity.updatedGuideTitle',
    defaultMessage: '{person} updated guide title.'
  },
  commented: {
    id: 'GuideActivity.commented',
    defaultMessage: '{person} commented.'
  },
  assignedToV2: {
    id: 'GuideActivity.assignedToV2',
    defaultMessage: '{person} assigned the guide to {assignee}.'
  },
  createdDocument: {
    id: 'GuideActivity.createdDraftGuide',
    defaultMessage: '{person} created new draft guide.'
  },
  removedUser: {
    id: 'GuideActivity.deletedUser',
    defaultMessage: '(Deleted user)'
  },
  approvalRequested: {
    id: 'GuideActivity.approvalRequested',
    defaultMessage: '{person} requested guide approval.'
  },
  approvalRequestedV2: {
    id: 'GuideActivity.approvalRequestedV2',
    defaultMessage: '{person} requested guide approval from:'
  },
  approvalWithdrawnV2: {
    id: 'GuideActivity.approvalWithdrawnV2',
    defaultMessage: '{person} withdrew guide approval request.'
  },
  approvalReviewApproved: {
    id: 'GuideActivity.approvalReviewApproved',
    defaultMessage: '{person} has approved guide approval request.'
  },
  approvalReviewRejectedV2: {
    id: 'GuideActivity.approvalReviewRejectedV2',
    defaultMessage: 'Guide rejected by {person}.'
  },
  approved: {
    id: 'GuideActivity.approved',
    defaultMessage: 'Approved'
  },
  rejected: {
    id: 'GuideActivity.rejected',
    defaultMessage: 'Rejected'
  },
  liveReviewRejectedV2: {
    id: 'GuideActivity.liveReviewRejectedV2',
    defaultMessage: 'Guide rejected by {person}.'
  },
  liveReviewApprovedV2: {
    id: 'GuideActivity.liveReviewApprovedV2',
    defaultMessage: 'Guide re-approved by {person}.'
  },
  viewVersion: {
    id: 'GuideVersions.viewVersion',
    defaultMessage: 'View version'
  },
  viewVersionV2: {
    id: 'GuideVersions.viewVersion2',
    defaultMessage: 'View version {versionTitle}'
  },
  view: {
    id: 'GuideVersions.view',
    defaultMessage: 'View'
  },
  approvalReviewCancelled: {
    id: 'approvalReviewCancelled',
    defaultMessage: '{person} cancelled guide approval request'
  },
  updatedMedia: {
    id: 'GuideActivity.updatedMedia',
    defaultMessage: '{person} step visual.'
  },
  updatedMediaGuide: {
    id: 'GuideActivity.updatedMediaGuide',
    defaultMessage: '{person} updated guide visual'
  },
  updatedNoteMedia: {
    id: 'GuideActivity.updatedNoteMedia',
    defaultMessage: '{person} updated {iconType} step note visual on a step.'
  },
  updatedMediaCheck: {
    id: 'GuideActivity.updatedMediaCheck',
    defaultMessage: '{person} check visual.'
  },
  duplicatedStep: {
    id: 'GuideActivity.duplicatedStep',
    defaultMessage: '{person} duplicated a step.'
  },
  duplicatedCheck: {
    id: 'GuideActivity.duplicatedCheck',
    defaultMessage: '{person} duplicated a check.'
  },
  unknownEvent: {
    id: 'GuideActivity.unknownEvent',
    defaultMessage: 'Event'
  },
  updatedMediaInstruction: {
    id: 'GuideActivity.updatedMediaInstruction',
    defaultMessage: '{person} updated media on an instruction.'
  },
  signOffEnabled: {
    id: 'GuideActivity.signOffEnabled',
    defaultMessage: '{person} enabled sign off for instruction.'
  },
  signOffDisabled: {
    id: 'GuideActivity.signOffDisabled',
    defaultMessage: '{person} disabled sign off for instruction.'
  },
  repositionedChecklist: {
    id: 'GuideActivity.repositionedChecklist',
    defaultMessage: '{person} repositioned checklist contents.'
  },
  movedInstructionToTopic: {
    id: 'GuideActivity.movedInstructionToTopic',
    defaultMessage: '{person} moved instruction to another topic.'
  },
  movedChecklistToTopic: {
    id: 'GuideActivity.movedChecklistToTopic',
    defaultMessage: '{person} moved checklist to another topic.'
  },
  checklistVisualUpdatedGuide: {
    id: 'GuideActivity.checklistVisualUpdatedGuide',
    defaultMessage: '{person} updated the image on the checklist.'
  },
  checklistMovedToGuide: {
    id: 'GuideActivity.checklistMovedToGuide',
    defaultMessage: '{person} moved checklist to another guide.'
  },
  checklistMovedFromGuide: {
    id: 'GuideActivity.checklistMovedFromGuide',
    defaultMessage: '{person} moved checklist from another guide.'
  },
  checklistTagsAddedGuide: {
    id: 'GuideActivity.checklistTagsAddedGuide',
    defaultMessage: '{person} added tags to checklist.'
  },
  checklistTagsRemovedGuide: {
    id: 'GuideActivity.checklistTagsRemovedGuide',
    defaultMessage: '{person} removed tags from checklist.'
  },
  instructionMovedToGuide: {
    id: 'GuideActivity.instructionMovedToGuide',
    defaultMessage: '{person} moved instruction to another guide.'
  },
  instructionMovedFromGuide: {
    id: 'GuideActivity.instructionMovedFromGuide',
    defaultMessage: '{person} moved instruction from another guide.'
  },
  instructionTagsAddedGuide: {
    id: 'GuideActivity.instructionTagsAddedGuide',
    defaultMessage: '{person} added tags to instruction.'
  },
  instructionTagsRemovedGuide: {
    id: 'GuideActivity.instructionTagsRemovedGuide',
    defaultMessage: '{person} removed tags from instruction.'
  },
  createdStepInInstruction: {
    id: 'GuideActivity.createdStepInInstruction',
    defaultMessage: '{person} created a new step in instruction {instructionTitle}.'
  },
  updatedStepInInstruction: {
    id: 'GuideActivity.updatedStepInInstruction',
    defaultMessage: '{person} updated content for step {position} in instruction {instructionTitle}.'
  },
  updatedVisualForStepInInstruction: {
    id: 'GuideActivity.updatedVisualForStepInInstruction',
    defaultMessage: '{person} updated visual for step {position} in instruction {instructionTitle}.'
  },
  duplicatedStepInInstruction: {
    id: 'GuideActivity.duplicatedStepInInstruction',
    defaultMessage: '{person} duplicated step {position} in instruction {instructionTitle}.'
  },
  stepOrderNumberUpdate: {
    id: 'GuideActivity.stepOrderNumberUpdate',
    defaultMessage:
      '{person} moved step {beforePosition} in instruction {instructionTitle} to position {afterPosition}.'
  },
  deletedStepInInstruction: {
    id: 'GuideActivity.deletedStepInInstruction',
    defaultMessage: '{person} deleted step {position} in instruction {instructionTitle}.'
  },
  createdCheckInChecklist: {
    id: 'GuideActivity.createdCheckInChecklist',
    defaultMessage: '{person} created a new check in checklist {instructionTitle}.'
  },
  updatedCheckInChecklist: {
    id: 'GuideActivity.updatedCheckInChecklist',
    defaultMessage: '{person} updated content for check {position} in checklist {instructionTitle}.'
  },
  updatedVisualForCheckInChecklist: {
    id: 'GuideActivity.updatedVisualForCheckInChecklist',
    defaultMessage: '{person} updated visual for check {position} in checklist {instructionTitle}.'
  },
  duplicatedCheckInChecklist: {
    id: 'GuideActivity.duplicatedCheckInChecklist',
    defaultMessage: '{person} duplicated check {position} in instruction {instructionTitle}.'
  },
  checkOrderNumberUpdate: {
    id: 'GuideActivity.checkOrderNumberUpdate',
    defaultMessage: '{person} moved check {beforePosition} in checklist {instructionTitle} to position {afterPosition}.'
  },
  deletedCheckInChecklist: {
    id: 'GuideActivity.deletedCheckInChecklist',
    defaultMessage: '{person} deleted check {position} in checklist {instructionTitle}.'
  },
  repositionedTopic: {
    id: 'GuideActivity.repositionedTopic',
    defaultMessage: '{person} repositioned topics.'
  },
  repositionedTopicV2: {
    id: 'GuideActivity.repositionedTopicV2',
    defaultMessage: '{person} moved topic {topicTitle} to position {afterPosition}.'
  },
  updatedTopicSettings: {
    id: 'GuideActivity.updatedTopicSettings',
    defaultMessage: '{person} set topic display for Users to "{displaySettings}".'
  },
  updatedTopicSettingsV2: {
    id: 'GuideActivity.updatedTopicSettingsV2',
    defaultMessage: '{person} set topic {topicTitle} display for Users to "{displaySettings}".'
  },
  topicMovedToGuide: {
    id: 'GuideActivity.topicMovedToGuide',
    defaultMessage: '{person} moved topic to another guide.'
  },
  topicMovedToGuideV2: {
    id: 'GuideActivity.topicMovedToGuideV2',
    defaultMessage: '{person} moved topic {topicTitle} to guide {guide}'
  },
  topicMovedFromGuide: {
    id: 'GuideActivity.topicMovedFromGuide',
    defaultMessage: '{person} moved topic from another guide.'
  },
  topicMovedFromGuideV2: {
    id: 'GuideActivity.topicMovedFromGuideV2',
    defaultMessage: '{person} moved topic {topicTitle} from guide {guide}'
  },
  show: {
    id: 'GuideActivity.show',
    defaultMessage: 'Show'
  },
  hide: {
    id: 'GuideActivity.hide',
    defaultMessage: 'Hide'
  },
  showAndCollapse: {
    id: 'GuideActivity.showAndCollapse',
    defaultMessage: 'Show & Collapse'
  },
  none: {
    id: 'GuideActivity.none',
    defaultMessage: 'None'
  },
  updatedNoteMediaV2: {
    id: 'GuideActivity.updatedNoteMediaV2',
    defaultMessage:
      '{person} updated {iconType} Step Note visual for step {position} in instruction {instructionTitle}.'
  },
  updatedStepNoteV2: {
    id: 'GuideActivity.updatedStepNoteV2',
    defaultMessage:
      '{person} updated {iconType} Step Note content for step {position} in instruction {instructionTitle}.'
  },
  restoredVersionAsDraft: {
    id: 'GuideActivity.restoredVersionAsDraft',
    defaultMessage: 'New draft guide created from Version {beforeVersionTitle}.'
  },
  republishedGuide: {
    id: 'GuideActivity.republishedGuide',
    defaultMessage: '{person} reverted back to this guide version from Version {beforeVersionTitle}.'
  },
  newerVersionPublished: {
    id: 'GuideActivity.newerVersionPublished',
    defaultMessage: 'Replaced by newer version.'
  },
  addedTranslations: {
    id: 'GuideActivity.addedTranslations',
    defaultMessage: '{person} translated guide content into {language}.'
  },
  updatedTranslations: {
    id: 'GuideActivity.updatedTranslations',
    defaultMessage: '{person} updated guide translations into {language}.'
  },
  updatedDefaultLanguage: {
    id: 'GuideActivity.updatedDefaultLanguage',
    defaultMessage: '{person} updated the default language to {after} from {before}.'
  },
  draftAutoCreated: {
    id: 'GuideActivity.draftAutoCreated',
    defaultMessage: 'New draft guide was created from Version {beforeVersion}.'
  },
  draftAutoCreatedV2: {
    id: 'GuideActivity.draftAutoCreatedV2',
    defaultMessage: 'New draft guide was created from previous version.'
  },
  guideTagsAdded: {
    id: 'GuideActivity.guideTagsAdded',
    defaultMessage: '{person} added {tags, plural, one {tag} other {tags}} to the guide.'
  },
  guideTagsRemoved: {
    id: 'GuideActivity.guideTagsRemoved',
    defaultMessage: '{person} removed {tags, plural, one {tag} other {tags}} from the guide.'
  },
  updatedGuideWorkspaces: {
    id: 'GuideActivity.updatedGuideWorkspaces',
    defaultMessage: '{person} updated the workspaces for the guide.'
  },
  approvalPartiallyApproved: {
    id: 'GuideActivity.approvalPartiallyApproved',
    defaultMessage: 'Guide approved by {approvers}/{reviewers} approvers'
  },
  approvalApprovedAndPublishedBy: {
    id: 'GuideActivity.approvalApprovedAndPublishedBy',
    defaultMessage: 'Guide approved and published by {person}'
  },
  newInstructionOutsideTopic: {
    id: 'GuideActivity.newInstructionOutsideTopic',
    defaultMessage: '{person} created a new instruction outside of a topic.'
  },
  newInstructionInTopic: {
    id: 'GuideActivity.newInstructionInTopic',
    defaultMessage: '{person} created a new instruction within topic {topicTitle}.'
  },
  updatedMediaInInstructionV2: {
    id: 'GuideActivity.updatedMediaInInstructionV2',
    defaultMessage: '{person} updated instruction {instructionTitle} visual.'
  },
  clonedInstructionV2: {
    id: 'GuideActivity.clonedInstructionV2',
    defaultMessage: '{person} duplicated instruction and created {instructionTitle}.'
  },
  hiddenInstructionV2: {
    id: 'GuideActivity.hiddenInstructionV2',
    defaultMessage: '{person} updated instruction {instructionTitle} to be hidden from Users.'
  },
  shownInstructionV2: {
    id: 'GuideActivity.shownInstructionV2',
    defaultMessage: '{person} updated instruction {instructionTitle} to be shown to Users.'
  },
  repositionedInstructionV2: {
    id: 'GuideActivity.repositionedInstructionV2',
    defaultMessage: '{person} moved instruction {instructionTitle} to position {afterPosition}.'
  },
  deletedInstructionV2: {
    id: 'GuideActivity.deletedInstructionV2',
    defaultMessage: '{person} deleted instruction {instructionTitle}.'
  },
  enabledSignOffV2: {
    id: 'GuideActivity.enabledSignOffV2',
    defaultMessage: '{person} enabled sign-off for instruction {instructionTitle}.'
  },
  disabledSignOffV2: {
    id: 'GuideActivity.disabledSignOffV2',
    defaultMessage: '{person} disabled sign-off for instruction {instructionTitle}.'
  },
  addedTags: {
    id: 'GuideActivity.addedTags',
    defaultMessage: '{person} added  {tags, plural, one {tag} other {tags}} to instruction {instructionTitle}.'
  },
  removedTags: {
    id: 'GuideActivity.removedTags',
    defaultMessage: '{person} removed  {tags, plural, one {tag} other {tags}} from instruction {instructionTitle}.'
  },
  instructionMovedToGuideV2: {
    id: 'GuideActivity.instructionMovedToGuideV2',
    defaultMessage: '{person} moved instruction {instructionTitle} to guide {guide}.'
  },
  instructionMovedFromGuideV2: {
    id: 'GuideActivity.instructionMovedFromGuideV2',
    defaultMessage: '{person} moved over instruction {instructionTitle} from guide {guide}.'
  },
  movedInstructionToTopicV2: {
    id: 'GuideActivity.movedInstructionToTopicV2',
    defaultMessage: '{person} moved instruction {instructionTitle} to topic {topicTitle}.'
  },
  movedInstructionOutsideTopic: {
    id: 'GuideActivity.movedInstructionOutsideTopic',
    defaultMessage: '{person} moved instruction {instructionTitle} outside of all topics.'
  },
  checklistAlertRecipientsAdded: {
    id: 'GuideActivity.checklistAlertRecipientsAdded',
    defaultMessage:
      '{person} added alert {alertRecipientsCount, plural, one {recipient} other {recipients}} to checklist {instructionTitle}.'
  },
  checklistAlertRecipientsRemoved: {
    id: 'GuideActivity.checklistAlertRecipientsRemoved',
    defaultMessage:
      '{person} removed alert {alertRecipientsCount, plural, one {recipient} other {recipients}} from checklist {instructionTitle}.'
  },
  checklistRequireSignature: {
    id: 'GuideActivity.checklistRequireSignature',
    defaultMessage: '{person} turned on signatures for checklist {instructionTitle}.'
  },
  checklistRemoveSignature: {
    id: 'GuideActivity.checklistRemoveSignature',
    defaultMessage: '{person} turned off signatures for checklist {instructionTitle}.'
  },
  newChecklistOutsideTopic: {
    id: 'GuideActivity.newChecklistOutsideTopic',
    defaultMessage: '{person} created a new checklist outside of a topic.'
  },
  newChecklistInTopic: {
    id: 'GuideActivity.newChecklistInTopic',
    defaultMessage: '{person} created a new checklist within topic {topicTitle}.'
  },
  updatedChecklistTitle: {
    id: 'GuideActivity.updatedChecklistTitle',
    defaultMessage: '{person} updated checklist title.'
  },
  updatedMediaInChecklistV2: {
    id: 'GuideActivity.updatedMediaInChecklistV2',
    defaultMessage: '{person} updated checklist {instructionTitle} visual.'
  },
  clonedChecklistV2: {
    id: 'GuideActivity.clonedChecklistV2',
    defaultMessage: '{person} duplicated checklist and created {instructionTitle}.'
  },
  hiddenChecklistV2: {
    id: 'GuideActivity.hiddenChecklistV2',
    defaultMessage: '{person} updated checklist {instructionTitle} to be hidden from Users.'
  },
  shownChecklistV2: {
    id: 'GuideActivity.shownChecklistV2',
    defaultMessage: '{person} updated checklist {instructionTitle} to be shown to Users.'
  },
  repositionedChecklistV2: {
    id: 'GuideActivity.repositionedChecklistV2',
    defaultMessage: '{person} moved checklist {instructionTitle} to position {afterPosition}.'
  },
  deletedChecklistV2: {
    id: 'GuideActivity.deletedChecklistV2',
    defaultMessage: '{person} deleted checklist {instructionTitle}.'
  },
  addedTagsChecklist: {
    id: 'GuideActivity.addedTagChecklist',
    defaultMessage: '{person} added {tags, plural, one {tag} other {tags}} to checklist {instructionTitle}.'
  },
  removedTagsChecklist: {
    id: 'GuideActivity.removedTagsChecklist',
    defaultMessage: '{person} removed  {tags, plural, one {tag} other {tags}} from checklist {instructionTitle}.'
  },
  checklistMovedToGuideV2: {
    id: 'GuideActivity.checklistMovedToGuideV2',
    defaultMessage: '{person} moved checklist {instructionTitle} to guide {guide}.'
  },
  checklistMovedFromGuideV2: {
    id: 'GuideActivity.checklistMovedFromGuideV2',
    defaultMessage: '{person} moved over checklist {instructionTitle} from guide {guide}.'
  },
  movedChecklistToTopicV2: {
    id: 'GuideActivity.movedChecklistToTopicV2',
    defaultMessage: '{person} moved checklist {instructionTitle} to topic {topicTitle}.'
  },
  movedChecklistOutsideTopic: {
    id: 'GuideActivity.movedChecklistOutsideTopic',
    defaultMessage: '{person} moved checklist {instructionTitle} outside of all topics.'
  },
  itemNoLongerAvailable: {
    id: 'GuideActivity.itemNoLongerAvailable',
    defaultMessage: 'This item is no longer available.'
  },
  defaultTopic: {
    id: 'EditGuide.defaultTopicV2',
    defaultMessage: 'Default topic'
  }
});

export default messages;
