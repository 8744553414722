import styled from 'styled-components';

//components
import {Title as $Title} from 'shared/styles/styled-components/field-styled-components';

import $UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';

export const MediaProperties = styled.div`
  margin-top: 2rem;
`;

export const Properties = styled.div``;

export const Property = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 660px) {
    display: block;
  }
`;

export const PropertyKey = styled.div`
  opacity: 0.6;
  width: 30%;
`;

export const Title = styled($Title)`
  margin-bottom: 1rem;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  width: 70%;
`;
export const Name = styled.span`
  word-break: break-word;
  @media (max-width: 660px) {
    word-break: unset;
  }
`;

export const EditNameButton = styled.button`
  margin: 0 6px;
  padding: 0 10px;
  background: none;
  border: none;
  cursor: pointer;
  color: #323232;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledUnderlinedTextbox = styled($UnderlinedTextbox)`
  width: 100% !important;
  margin: 0 24px 12px 0;
`;

export const EditNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
