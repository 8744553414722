import gql from 'graphql-tag';

export const RecommendContent = gql`
  mutation recommendContent($content: String!, $locale: String) {
    recommendContent(content: $content, locale: $locale) {
      recommendation
      error
    }
  }
`;
