import {generateSteps} from './steps';

import {colors, emptyTextForForrmating, pageBreak} from '../constants';

export const generateInstruction = async (
  instruction,
  topic,
  pdfMessages,
  isFirstInstruction,
  additionalInfo,
  guideBaseUrl,
  intl
) => {
  const {steps, title: instructionTitle} = instruction;
  const {visible, title: topicTitle} = topic;

  const stepsContent = await generateSteps(steps, pdfMessages, {...additionalInfo, instruction}, guideBaseUrl, intl);

  const pageBreakOption = isFirstInstruction ? {} : {pageBreak: pageBreak.BEFORE};

  const instructionContent = [
    {
      text: visible ? topicTitle : emptyTextForForrmating,
      color: colors.GRAY,
      fontSize: 14,
      ...pageBreakOption,
      margin: [13, 0, 0, 0]
    },
    {
      text: instructionTitle,
      fontSize: 28,
      tocItem: true,
      tocStyle: {fontSize: 11},
      tocMargin: [25, visible ? 0 : 10, 0, 0],
      margin: [13, 0, 0, 0]
    },
    stepsContent,
    {text: emptyTextForForrmating, margin: [0, 0, 0, 40]}
  ];

  return instructionContent;
};
