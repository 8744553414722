import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

import Dialog from 'ui-components/Dialog';
import LanguagePicker from 'components/LanguagePicker';

import messages from './messages';

//mutations
import {AddTranslationsToGuide} from 'api/guide/mutations';
import {TranslationRate} from 'api/translations/queries';

//mutaion-options
import {addTranslationsToGuide} from 'api/guide/mutation-options';
import {getTranslationRateOptionsNoCache} from 'api/translations/query-options';

@inject('store')
@graphql(AddTranslationsToGuide, addTranslationsToGuide)
@graphql(TranslationRate, getTranslationRateOptionsNoCache)
@observer
class AddNewLanguageDialog extends Component {
  onCancel = () => {
    const {
      store: {
        addNewLanguageDialog: {reset}
      }
    } = this.props;

    reset();
  };

  saveNewTranslation = () => {
    const {store, addTranslationsToGuideMutation} = this.props;
    const {translationsPage, addNewLanguageDialog} = store;
    const {selectedLanguage, reset} = addNewLanguageDialog;
    translationsPage.addTranslationsToGuide(selectedLanguage, addTranslationsToGuideMutation);
    reset();
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        addNewLanguageDialog: {opened, selectedLanguage, setSelectedLanguage},
        platform: {availableLanguages}
      },
      translationRateQuery
    } = this.props;

    const availableTranslations = translationRateQuery?.guideDetails?.translationRate?.map(language => language.locale);

    const actions = [
      {
        label: formatMessage(messages.add),
        onClick: () => this.saveNewTranslation(),
        disabled: !selectedLanguage
      }
    ];

    return (
      <Dialog
        isOpen={opened}
        onCancel={this.onCancel}
        actions={actions}
        title={formatMessage(messages.addNewGuideLanguage)}
        overflow={'unset'}
        overflowBody={'unset'}
        dialogStyle={{maxWidth: 428}}
      >
        <LanguagePicker
          label={' '}
          placeholder={formatMessage(messages.selectLanguage)}
          changeLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
          filterOption={availableTranslations}
          languages={availableLanguages}
          allowClear={true}
          dataCy="translations-picker"
        />
      </Dialog>
    );
  }
}

export default injectIntl(AddNewLanguageDialog);
