import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const LabelWrapper = styled.div`
  display: flex;
  float: left;

  #button-label {
    font-size: 9pt;
  }
`;

export const PositionContainer = styled.div`
  padding: 0 8px;
  margin-right: 8px;
  background-color: #ededed;
  border-radius: 20%;
`;

export const NameContainer = styled.span`
  text-transform: capitalize;
`;

export const CreateLabelWrapper = styled(LabelWrapper)`
  > div > label {
    color: ${p => (p.invalid ? `${commonPalette.red} !important` : 'inherit')};
    font-size: 9pt;
    margin: 0 !important;

    &:after {
      content: initial !important;
    }
  }

  > div > button > svg {
    color: ${p => (p.invalid ? `${commonPalette.red} !important` : 'inherit')};
  }

  #button-label {
    display: none;
  }
`;
