import {defineMessages} from 'react-intl';

const messages = defineMessages({
  autoRemoval: {
    id: 'PlatformSettings.autoRemoval',
    defaultMessage: 'Auto-removal of downloaded guides'
  },
  automaticallyRemoveGuides: {
    id: 'PlatformSettings.automaticallyRemoveGuides',
    defaultMessage: 'Automatically remove downloaded guides from the native app'
  },
  removeGuides: {
    id: 'PlatformSettings.removeGuides',
    defaultMessage: 'Remove guides'
  },
  afterDownload: {
    id: 'PlatformSettings.afterDownload',
    defaultMessage: 'after download'
  },
  changedDaysSuccessMessage: {
    id: 'changedDaysSuccessMessage',
    defaultMessage: 'Guide auto-removal days updated'
  },
  changedDaysErrorMessage: {
    id: 'changedDaysErrorMessage',
    defaultMessage: "Couldn't update guide auto-removal days"
  },
  autoRemovalTurnedOnSuccess: {
    id: 'autoRemovalTurnedOnSuccess',
    defaultMessage: 'Guide auto-removal turned on'
  },
  autoRemovalTurnedOffSuccess: {
    id: 'autoRemovalTurnedOffSuccess',
    defaultMessage: 'Guide auto-removal turned off'
  },
  autoRemovalTurnedOnError: {
    id: 'autoRemovalTurnedOnError',
    defaultMessage: "Couldn't turn on guide auto-removal"
  },
  autoRemovalTurnedOffError: {
    id: 'autoRemovalTurnedOffError',
    defaultMessage: "Couldn't turn off guide auto-removal"
  }
});

export default messages;
