/* eslint-disable no-unused-vars */
import React, {ElementType} from 'react';
import styled from 'styled-components';
import Column from './Column';
import Hidden from './Hidden';
import {passOn} from '../utils';
import omit from 'lodash/omit';

type Props = {
  children?: Array<ElementType>;
  className?: string;
  debug?: boolean;
  tagName?: string;
  theme?: Record<string, any>;
  // grid props
  divisions?: number;
  // flex props
  alignContent?: string;
  alignItems?: string;
  alignSelf?: string;
  justifyContent?: string;
  order?: string;
};

function RowContainer(props: Props) {
  const {
    children,
    tagName,
    debug,
    divisions,
    theme,
    alignContent,
    alignItems,
    alignSelf,
    justifyContent,
    order,
    ...rest
  } = props;
  const newChildren = passOn(children, [Column, Hidden], child => {
    return {
      debug: typeof child.props.debug === 'undefined' ? debug : child.props.debug,
      divisions
    };
  });
  return React.createElement(tagName || 'section', omit(rest, ['gutter']), newChildren);
}

RowContainer.defaultProps = {
  divisions: 12
};

const ifDefined =
  (prop, css = prop) =>
  props =>
    props[prop] ? `${css}: ${props[prop]}` : '';

const Row = styled(({hasOnClick, ...props}) => <RowContainer {...props} />)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${ifDefined('alignContent', 'align-content')}
  ${ifDefined('alignItems', 'align-items')}
  ${ifDefined('alignSelf', 'align-self')}
  ${ifDefined('justifyContent', 'justify-content')}
  ${ifDefined('order')};

  ${p =>
    p.hasOnClick &&
    `
    cursor: pointer;
    &:hover {
      background: rgba(255,255,255,.5);
    }
  `}
`;

export default Row;
