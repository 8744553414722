import {useEffect} from 'react';

/**
 * Trigger a window resize event when the passed property changes.
 * This is a way to trigger a size calculation based on layout changes.
 *
 * We have elements that use the window size to determine how they should be shown
 * when it would be more appropriate to use the size of the parent element.
 *
 * We are not yet using the ResizeObserver API until we decide we do not support browsers
 * that do not implement it. See: https://caniuse.com/resizeobserver
 *
 * @param prop The property to watch for changes.
 */
export const useNotifyResize = (prop: unknown) => {
  useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [prop]);
};
