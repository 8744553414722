import styled from 'styled-components';

//components
import $Icon from 'ui-components/Icon';

export const StepControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

// Buttons are made out of two elements to extend their click areas past just
// the visible elements

export const InsertNewStepBeforeButton = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  height: 0;
  outline: 0;
  padding-top: 100%;
  position: relative;
  width: 100%;
`;

export const Circle = styled.span`
  border-radius: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  ${p =>
    p.design === 'medium' &&
    `
    height: 24px;
    width: 24px;
  `}

  ${p =>
    p.design === 'large' &&
    `
    height: 36px;
    width: 36px;
  `}

  ${() => InsertNewStepBeforeButton}:hover > & {
    background: #fff;
    box-shadow: 0 2px 24px rgba(0, 0, 0, 0.14), 0 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.14);
  }
`;

export const PlusIcon = styled($Icon).attrs({
  iconId: 'create'
})`
  left: 50%;
  opacity: 0.4;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  ${p =>
    p.design === 'medium' &&
    `
    color: #333330;
    width: 8px !important; // override font-awesome sizing
  `}

  ${p =>
    p.design === 'large' &&
    `
    color: #298784;
    width: 14px !important; // override font-awesome sizing
  `}
`;
