import {action, observable, makeObservable} from 'mobx';

class EditDomainPage {
  @observable previousView = {};

  constructor() {
    makeObservable(this);
  }

  @action setPreviousView(view, id) {
    this.previousView = {view, id};
  }
}

export default EditDomainPage;
