import messages from './messages';
import {AUTO_TRANSLATE_DIALOGS_MAP} from 'stores/dialogs/auto-translation-dialog';

export const SELECT_LANGUAGE_OPTIONS = {
  addNewLanguage: 'addNewLanguage'
};

export const getLanguagesOptions = ({platform, autoTranslationDialog}, formatMessage) => {
  if (autoTranslationDialog.translationsForGuide.length === 0) return [];
  const options = autoTranslationDialog.translationsForGuide.map(translation =>
    formatLanguageOption(translation, formatMessage)
  );

  if (platform.availableLanguages.length > autoTranslationDialog.translationsForGuide.length) {
    options.unshift({
      id: SELECT_LANGUAGE_OPTIONS.addNewLanguage,
      name: `+ ${formatMessage(messages.addNewLanguage)}`,
      style: {
        color: '#298784',
        textTransform: 'uppercase',
        letterSpacing: '1px',
        fontSize: '14px'
      }
    });
  }

  return options;
};

export const formatLanguageOption = (translation, formatMessage) => {
  return {
    id: translation.locale,
    name: `${translation.language} (${formatMessage(messages.translationPercentage, {
      percentage: translation.completion
    })})`,
    disabled: translation.completion === 100
  };
};

export const onChangeLanguageToTranslate = (value, store) => {
  const {
    autoTranslationDialog: {setSelectedLanguageToTranslate, setCurrentDialog}
  } = store;
  if (value === SELECT_LANGUAGE_OPTIONS.addNewLanguage) {
    setSelectedLanguageToTranslate(undefined);
    setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.addNewLanguage);
    return;
  }
  setSelectedLanguageToTranslate(value);
};
