import {action, makeObservable, observable} from 'mobx';
import notification from 'utils/notification-utils';
import {client} from 'utils/apollo-client';
import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {UpdateChecklistAlertRecipientsMutation} from 'api/alerts-recipients/mutations';
import Raven from 'raven-js';

const apolloClient: ApolloClient<NormalizedCacheObject> = client;
const MAX_ALERT_RECIPIENTS = 4;
class ChecklistAlertRecipientsDialog {
  @observable opened = false;
  @observable selectedRecipients: string[] | null = null;
  @observable checklist: {
    id: string;
    title: string;
  } | null = null;
  @observable saving = false;

  constructor() {
    makeObservable(this);
  }

  @action
  reset = () => {
    this.selectedRecipients = null;
  };

  @action save = async (translations: {success: string; error: string}) => {
    try {
      if (!this.checklist || !this.selectedRecipients) {
        throw new Error('Invalid state. Checklist or selected recipients are not set.');
      }
      this.saving = true;
      const result = await apolloClient.mutate({
        mutation: UpdateChecklistAlertRecipientsMutation,
        variables: {
          checklistId: this.checklist.id,
          alertRecipientIds: this.selectedRecipients
        },
        refetchQueries: ['guideForCms']
      });
      if (!result.data) {
        throw new Error('No data returned from the server');
      }
      notification.success(translations.success);
    } catch (error) {
      Raven.captureException(error as Error);
      notification.error(translations.error);
    } finally {
      this.saving = false;
      this.close();
    }
  };

  @action close = () => {
    this.opened = false;
    this.reset();
  };

  @action setSelectedRecipients = (selectedRecipients: string[]) => {
    if (selectedRecipients.length > MAX_ALERT_RECIPIENTS) {
      return;
    }
    this.selectedRecipients = selectedRecipients;
  };

  @action cancel = () => {
    this.close();
  };

  @action open = (checklist: NonNullable<typeof this.checklist>) => {
    this.checklist = checklist;
    this.opened = true;
  };
}

export default ChecklistAlertRecipientsDialog;
