import {defineMessages} from 'react-intl';

const messages = defineMessages({
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  }
});

export default messages;
