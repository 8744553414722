import styled from 'styled-components';
import Icon from 'react-fontawesome';

import {commonPalette} from 'shared/styles/palette';

const TeamMembershipCardLabelContainer = styled.span`
  color: ${commonPalette.txBlack};
  opacity: ${({disabled}) => (disabled ? 0.5 : 1)};
`;

const TeamMembershipCardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.1);

  > button {
    font-size: 16px;
    margin-right: 12px;
    margin-left: 28px;
  }
`;

const SelectWrapper = styled.div`
  flex: 1;
  max-width: 680px;
`;

const StyledOrderingButtons = styled.div`
  display: flex;
  gap: 20px;
  margin: 0 10px;
  width: 50px;
  text-align: center;
  justify-content: center;
  ${({marginRight}) => (marginRight ? 'margin-right: 85px' : '')}
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

export {
  TeamMembershipCardLabelContainer,
  TeamMembershipCardContainer,
  SelectWrapper,
  StyledOrderingButtons,
  StyledIcon
};
