import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

//lodash
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

//components
import TranslateTags from 'components/TranslateTags';
import ButtonGroup from 'ui-components/ButtonGroup';
import Container from 'ui-components/Layout/Container';

//queries
import {TagsList} from 'api/tag/queries';

//mutations
import {SaveTranslationsForTags} from 'api/translations/mutations';

//options
import {tagsListOptions} from 'api/tag/query-options';
import {getSaveTranslationsForTagsOptions} from 'api/translations/mutation-options';

//messages
import messages from './messages';

import {TranslateTagsWrapper, ButtonGroupWrapper} from './styles';

@inject('store')
@graphql(TagsList, tagsListOptions)
@graphql(SaveTranslationsForTags, getSaveTranslationsForTagsOptions)
@observer
class TranslateTagsViewComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: {tags, refetch},
      store: {tagTranslationsPage}
    } = nextProps;
    tagTranslationsPage.setTagsTranslations(tags);
    tagTranslationsPage.setRefetchQuery(refetch);
  }

  UNSAFE_componentWillMount() {
    const {
      store: {platform, tagTranslationsPage},
      intl: {formatMessage}
    } = this.props;
    const {setSourceLanguages, setUiTranslations} = tagTranslationsPage;

    const uiTranslations = mapValues(
      pick(messages, ['saveFailure', 'saveSuccess', 'defaultLanguage', 'noLanguageSelected']),
      message => formatMessage(message)
    );
    setUiTranslations(uiTranslations);
    setSourceLanguages(platform.availableLanguages);
  }

  componentWillUnmount() {
    const {store} = this.props;
    const {tagTranslationsPage} = store;
    tagTranslationsPage.reset();
  }

  render() {
    const {
      store,
      saveTranslationsForTagsMutation,
      intl: {formatMessage}
    } = this.props;
    const {saveTranslations} = store.tagTranslationsPage;

    return (
      <Container>
        <TranslateTagsWrapper>
          <TranslateTags />
          <ButtonGroupWrapper>
            <ButtonGroup
              primary={{
                label: formatMessage(messages.saveTranslation),
                onClick: () => saveTranslations(saveTranslationsForTagsMutation)
              }}
              secondary={{
                label: formatMessage(messages.backToOverview),
                onClick: () => store.router.goTo(views.tags, {})
              }}
            />
          </ButtonGroupWrapper>
        </TranslateTagsWrapper>
      </Container>
    );
  }
}

export default injectIntl(TranslateTagsViewComponent);
