import styled from 'styled-components';

export const TimeStamp = styled.p`
  position: relative;
  bottom: 30px;
  font-style: italic;
  display: inline;
  color: gray;
  @media (max-width: 720px) {
    bottom: -15px;
    padding-bottom: 30px;
  }
`;
