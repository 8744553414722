import gql from 'graphql-tag';

export const InstructionsList = gql`
  query instructions($publishStatus: PublishStatus, $locale: Locale!, $topicId: String!) {
    instructions(publishStatus: $publishStatus, orderByDate: true, locale: $locale, topicId: $topicId) {
      id
      stepsNumber
      publishStatus
      created_at
      title
      media {
        id
        url
        metadata
      }
      tags {
        id
        title
        badge
      }
      slug
      steps {
        media {
          id
          url
          metadata
        }
      }
    }
  }
`;

export const GetInstructionForEdit = gql`
  query instructionForCms($id: String, $versionId: String) {
    instructionForCms(id: $id, versionId: $versionId) {
      id
      publishStatus
      slug
      type
      providedTranslations
      title
      checklistTemplate {
        id
        name
        type
        options {
          id
          option
          value
        }
      }
      campaign {
        id
      }
      media {
        id
        url
        metadata
      }
      locale
      stepsNumber
      topicId
      tags {
        id
        title
        badge
      }
      steps {
        id
        position
        instructionId
        providedTranslations
        locale
        disabledOptions {
          id
        }
        media {
          id
          url
          metadata
        }
        instructionHtml
        fix
        fixHtml
        fixMedia {
          id
          url
          metadata
        }
        alternative
        alternativeHtml
        alternativeMedia {
          id
          url
          metadata
        }
        warning
        warningHtml
        warningMedia {
          id
          url
          metadata
        }
        tip
        tipHtml
        tipMedia {
          id
          url
          metadata
        }
        notes {
          iconId
          content
          mandatory
          media {
            id
            url
            metadata
          }
        }
      }
    }
  }
`;
