import {defineMessages} from 'react-intl';

const messages = defineMessages({
  backToOverview: {
    id: 'SwipeableSteps.backToOverview',
    defaultMessage: 'Back to overview'
  },
  updateStepSuccess: {
    id: 'EditStep.updateSuccess',
    defaultMessage: 'Step has been updated.'
  },
  updateCheckSuccess: {
    id: 'updateCheckSuccess',
    defaultMessage: 'Check has been updated.'
  },
  updateStepError: {
    id: 'EditStep.updateError',
    defaultMessage: 'Error updating step.'
  },
  updateCheckError: {
    id: 'updateCheckError',
    defaultMessage: 'Error updating check.'
  },
  createStepSuccess: {
    id: 'EditStep.createSuccess',
    defaultMessage: 'Step has been created.'
  },
  createCheckSuccess: {
    id: 'createCheckSuccess',
    defaultMessage: 'Check has been created.'
  },
  createStepError: {
    id: 'EditStep.createError',
    defaultMessage: 'Error creating step.'
  },
  createCheckError: {
    id: 'createCheckError',
    defaultMessage: 'Error creating check.'
  }
});

export default messages;
