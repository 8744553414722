import {defineMessages} from 'react-intl';

const messages = defineMessages({
  campaignsOverview: {
    id: 'CampaignReport.campaignsOverview',
    defaultMessage: 'Campaigns - Overview'
  },
  results: {
    id: 'CampaignReport.results',
    defaultMessage: 'Results'
  },
  signOffQuestion: {
    id: 'SignOffReport.signOffQuestion',
    defaultMessage: 'Sign-off question:'
  },
  averageRating: {
    id: 'CampaignReport.averageRating',
    defaultMessage: 'Average rating'
  },
  totalRatings: {
    id: 'CampaignReport.totalRatings',
    defaultMessage: 'Total ratings'
  },
  scoresPerSentiment: {
    id: 'CampaignReport.scoresPerSentiment',
    defaultMessage: 'Scores per sentiment'
  },
  ratingsPerScore: {
    id: 'CampaignReport.ratingsPerScore',
    defaultMessage: 'Ratings per score'
  },
  feedback: {
    id: 'CampaignReport.feedback',
    defaultMessage: 'Feedback'
  },
  allInstructions: {
    id: 'ReportInstructionPicker.allInstructions',
    defaultMessage: 'All Instructions'
  },
  infoForFeedback: {
    id: 'CampaignReport.infoFeedback',
    defaultMessage: 'Feedback is only for follow up questions'
  }
});

export default messages;
