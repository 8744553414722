import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import views from 'config/views';

//utils
import {localiseHostName, isPrcHostname, getCurrentHostname} from 'shared/utils/host-utils';

//styled-components
import {InfoWrapper, LoginWrapper, LoginView, AlreadyLoggedIn, StyledLabel, StyledLink} from './styles';

//components
import LoginForm from 'components/LoginForm';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

//consts
import {termsAndConditionsUrl, TROUBLESHOOTING_URL} from 'config/constants';

@inject('store')
@observer
class LoginViewComponent extends Component {
  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      router,
      auth: {user}
    } = store;
    if (user) {
      router.goTo(views.guides, {});
    }
  }

  render() {
    const {store} = this.props;

    const {
      alreadyLoggedIn,
      troubleLoggingIn,
      troubleLoggingInHere,
      byUsingOurService,
      termsAndConditionsAndPrivacyPolicy
    } = messages;

    const localisedTermsAndConditionsUrl = localiseHostName(termsAndConditionsUrl);
    const shouldShowTroubleShooting = !isPrcHostname(getCurrentHostname());

    return (
      <LoginView>
        {store.auth.user ? (
          <AlreadyLoggedIn>
            <FormattedMessage {...alreadyLoggedIn} />
          </AlreadyLoggedIn>
        ) : (
          <LoginWrapper>
            <LoginForm />
            <InfoWrapper>
              {shouldShowTroubleShooting && (
                <StyledLabel>
                  <FormattedMessage
                    {...troubleLoggingIn}
                    values={{
                      troubleshootingLink: (
                        <StyledLink href={TROUBLESHOOTING_URL} target="_blank">
                          <FormattedMessage {...troubleLoggingInHere}>
                            {message => <span>{message.toUpperCase()}</span>}
                          </FormattedMessage>
                        </StyledLink>
                      )
                    }}
                  />
                </StyledLabel>
              )}
              <StyledLabel>
                <FormattedMessage
                  {...byUsingOurService}
                  values={{
                    termsLink: (
                      <StyledLink href={localisedTermsAndConditionsUrl} target="_blank">
                        <FormattedMessage {...termsAndConditionsAndPrivacyPolicy} />
                      </StyledLink>
                    )
                  }}
                />
              </StyledLabel>
            </InfoWrapper>
          </LoginWrapper>
        )}
      </LoginView>
    );
  }
}

export default LoginViewComponent;
