import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Container from 'ui-components/Layout/Container';
import DataTable from 'ui-components/Layout/DataTable';
import SearchBar from 'ui-components/Layout/SearchBar';
import ViewTitle from 'ui-components/Layout/ViewTitle';

import {CampaignGuidesQuery, Campaign} from 'api/campaign/queries';
import {campaignGuidesOptions, campaignOptions} from 'api/campaign/query-options';

import GuideExpander from './GuideExpander';

import messages from './messages';
import {StyledSpinner} from './styles';

@inject('store')
@graphql(Campaign, {...campaignOptions, name: 'campaignQuery'})
@graphql(CampaignGuidesQuery, campaignGuidesOptions)
@observer
class CampaignVisibilityView extends Component {
  state = {
    searchTerm: ''
  };

  setSearchTerm = e => {
    const searchTerm = e.target.value;

    this.setState(() => ({searchTerm}));
  };

  componentDidMount = () => {
    const {
      store: {app}
    } = this.props;

    const actionMenuItems = [
      {
        id: 'back',
        view: 'campaigns',
        secondary: true
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;

    app.resetActionMenuItems();
  }

  render() {
    const {
      campaignQuery: {campaign, error: campaignError, loading: campaignLoading},
      campaignGuidesQuery: {campaignGuides, error: campaignGuidesError, loading: campaignGuidesLoading},
      intl: {formatMessage}
    } = this.props;
    const {searchTerm} = this.state;

    if (campaignLoading || campaignGuidesLoading) {
      return (
        <Container>
          <ViewTitle title={formatMessage(messages.title)} />
          <StyledSpinner />
        </Container>
      );
    }

    if (campaignError || campaignGuidesError) {
      return (
        <Container>
          <ViewTitle title={formatMessage(messages.title)} />
          <div>{formatMessage(messages.error)}</div>
        </Container>
      );
    }

    const {title} = campaign;
    const filteredGuides = campaignGuides.filter(campaignGuide =>
      campaignGuide.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subtitle, {title})}
          right={<SearchBar placeholder={formatMessage(messages.searchBy)} onChange={this.setSearchTerm} width={180} />}
        />
        <DataTable
          data={filteredGuides}
          noDataMessage={
            campaignGuides.length > 0
              ? formatMessage(messages.noGuidesFound, {searchTerm})
              : formatMessage(messages.noGuides)
          }
          rowBottomBorders={false}
          columns={[
            {
              Header: formatMessage(messages.tableHeader),
              resizable: true,
              Cell: ({original}) => <GuideExpander guide={original} />,
              accessor: 'title'
            }
          ]}
          defaultSorted={[
            {
              id: 'title',
              desc: false
            }
          ]}
          style={{marginTop: 40}}
        />
      </Container>
    );
  }
}

export default injectIntl(CampaignVisibilityView);
