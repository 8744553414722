import {defineMessages} from 'react-intl';

const messages = defineMessages({
  teamName: {
    id: 'common.forms.teamName',
    defaultMessage: 'Team Name'
  }
});

export default messages;
