import React, {Component} from 'react';
import {action, observable, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

//styled-components
import {ExampleImagesSlider, StyledNumber, LeftArrow, RightArrow, Images, Counter} from './styles';

//components
import ImageVariants from 'components/ImageVariants';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@observer
class ExampleImagesSliderComponent extends Component {
  @observable currentImageIndex = 0;
  @action
  setCurrentImageIndex = currentImageIndex => (this.currentImageIndex = currentImageIndex);

  @action
  setPreviousImage = () => {
    this.setCurrentImageIndex(this.currentImageIndex - 1);
  };

  @action
  setNextImage = () => {
    this.setCurrentImageIndex(this.currentImageIndex + 1);
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {images} = this.props;
    const currentImage = images[this.currentImageIndex];
    const hasNext = this.currentImageIndex < images.length - 1;
    const hasPrevious = this.currentImageIndex - 1 >= 0;

    const moreImages = images.length > 1;

    return (
      <ExampleImagesSlider>
        <Images moreImages={moreImages}>
          {moreImages && (
            <LeftArrow enabled={hasPrevious} onClick={hasPrevious && this.setPreviousImage} name="angle-left" />
          )}
          <ImageVariants images={currentImage} />
          {moreImages && <RightArrow enabled={hasNext} onClick={hasNext && this.setNextImage} name="angle-right" />}
        </Images>

        {moreImages && (
          <Counter>
            <FormattedMessage
              {...messages.counter}
              values={{
                current: <StyledNumber>{this.currentImageIndex + 1}</StyledNumber>,
                total: <StyledNumber>{images.length}</StyledNumber>
              }}
            />
          </Counter>
        )}

        {images.length === 1 && (
          <Counter>
            <FormattedMessage {...messages.singleCounter} />
          </Counter>
        )}
      </ExampleImagesSlider>
    );
  }
}

export default ExampleImagesSliderComponent;
