import {action, observable, computed, toJS, makeObservable} from 'mobx';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import each from 'lodash/each';
import get from 'lodash/get';
import map from 'lodash/map';
import difference from 'lodash/difference';
import store from 'stores/store';
import uniqBy from 'lodash/uniqBy';

//forms
import FilterContentForm from 'stores/forms/filter-content-form';

//helpers
import {GUIDE_FILTER_OPTIONS, CUSTOM_FILTER_OPTIONS, STATUS_OPTIONS} from 'shared/enums';
import {sortByPropName} from 'utils/array-utils';
import {
  getFiltersFromLocalStorage,
  setFiltersToLocalStorage,
  defaultCustomFilters,
  filtersKeys,
  removeEntityId
} from 'utils/filters';

const {CUSTOM_FILTERS, SELECT_STATUS, SORT_BY, FILTER_OPTION} = filtersKeys;

class AllGuidesPage {
  @observable sortBy = getFiltersFromLocalStorage(SORT_BY);
  @observable selectedFilter = getFiltersFromLocalStorage(FILTER_OPTION);
  @observable selectedStatus = getFiltersFromLocalStorage(SELECT_STATUS);
  @observable sortByOpened = false;
  @observable wrapperRef = null;
  @observable customFilter = getFiltersFromLocalStorage(CUSTOM_FILTERS) || {};
  @observable customFilterEntities = defaultCustomFilters;
  @observable translations = {};
  @observable sortByOptions = [];
  @observable filterOptions = [];
  @observable emptyImageDivWidth = 0;

  form = new FilterContentForm();

  @action
  setCustomFilter = customFilter => (this.customFilter = customFilter);

  @action
  setEmptyImageDivWidth = emptyImageDivWidth => (this.emptyImageDivWidth = emptyImageDivWidth);

  @action
  setSortByOptions = sortByOptions => (this.sortByOptions = sortByOptions);

  @action
  setFilterOptions = filterOptions => (this.filterOptions = filterOptions);

  @action
  setStatusOptions = statusOptions => (this.statusOptions = statusOptions);

  constructor() {
    makeObservable(this);
  }

  @computed get selectedOption() {
    return this.sortByOptions.find(opt => opt.id === this.sortBy);
  }

  @computed get customFilterOptions() {
    //TODO: sync the options retrieved from the backend here

    const {customFilterEntities, selectedFilter} = this;

    if (selectedFilter !== GUIDE_FILTER_OPTIONS.ALL_GUIDES) {
      return {
        [CUSTOM_FILTER_OPTIONS.TAG]: [],
        [CUSTOM_FILTER_OPTIONS.TEAM]: [],
        [CUSTOM_FILTER_OPTIONS.ASSIGNEE]: [],
        [CUSTOM_FILTER_OPTIONS.WORKSPACE]: [],
        [CUSTOM_FILTER_OPTIONS.LANGUAGE]: []
      };
    }

    const tags = map(customFilterEntities.TAG, ({id, title, badge}) => ({
      id,
      name: title,
      badge //todo somehow rename this more generally
    }));

    const teams = map(customFilterEntities.TEAM, ({id, name}) => ({
      id,
      name
    })).sort(sortByPropName('name'));

    const assignees = map(customFilterEntities.ASSIGNEE, ({id, fullName: name}) => ({
      id,
      name
    })).sort(sortByPropName('name'));

    const workspaces = map(customFilterEntities.WORKSPACE, ({id, name}) => ({
      id,
      name
    })).sort(sortByPropName('name'));

    const languages = map(customFilterEntities.LANGUAGE, ({locale, language}) => ({
      id: locale,
      name: language
    })).sort(sortByPropName('name'));

    return {
      [CUSTOM_FILTER_OPTIONS.TAG]: toJS(tags),
      [CUSTOM_FILTER_OPTIONS.TEAM]: toJS(teams),
      [CUSTOM_FILTER_OPTIONS.ASSIGNEE]: toJS(assignees),
      [CUSTOM_FILTER_OPTIONS.WORKSPACE]: toJS(workspaces),
      [CUSTOM_FILTER_OPTIONS.LANGUAGE]: toJS(languages)
    };
  }

  @computed get shouldShowCustomFilter() {
    return this.selectedFilter === GUIDE_FILTER_OPTIONS.ALL_GUIDES;
  }

  @computed get customFilterPayload() {
    const {customFilter} = this;

    if (customFilter === null) {
      return null;
    }

    const payload = {};
    each(keys(customFilter), option => {
      const value = toJS(get(customFilter, option, []));

      if (!isEmpty(value)) {
        payload[option] = value;
      }
    });

    return payload;
  }

  @computed get selectedStatusPayload() {
    if (this.selectedStatus === STATUS_OPTIONS.ALL) {
      return null;
    }

    return this.selectedStatus;
  }

  @action
  init = () => {
    this.form.reset();
  };

  @action
  toggleSortBy = () => {
    this.sortByOpened = !this.sortByOpened;
  };

  @action
  changeSortBy = value => {
    this.sortBy = value;
    setFiltersToLocalStorage(SORT_BY, value);
  };

  @action
  setCustomFilterEntity = (key, value = []) => {
    let shouldRefetchGuides = false;

    const idFieldName = key === CUSTOM_FILTER_OPTIONS.LANGUAGE ? 'locale' : 'id';
    if (!isEmpty(value)) {
      const savedFilterIds = get(getFiltersFromLocalStorage(CUSTOM_FILTERS), key);
      const currentEntityIds = map(value, idFieldName);
      const idsToBeRemoved = difference(savedFilterIds, currentEntityIds);
      each(idsToBeRemoved, id => removeEntityId(key, id, store));
      shouldRefetchGuides = !isEmpty(idsToBeRemoved);
    }

    if (key === 'TAG' && store.tagManagementPage.page > 0) {
      this.customFilterEntities = {
        ...this.customFilterEntities,
        [key]: uniqBy([...this.customFilterEntities[key], ...value], idFieldName)
      };
    } else {
      this.customFilterEntities = {
        ...this.customFilterEntities,
        [key]: value
      };
    }

    return shouldRefetchGuides;
  };

  @action
  selectFilterOption = value => {
    this.selectedFilter = value;
    setFiltersToLocalStorage(FILTER_OPTION, value);
  };

  @action
  selectStatusOption = value => {
    this.selectedStatus = value;
    setFiltersToLocalStorage(SELECT_STATUS, value);
  };

  @action
  selectCustomFilterOption = (key, value) => {
    this.customFilter = {
      ...this.customFilter,
      [key]: value
    };
  };

  @action
  initCustomFilter = () => {
    this.customFilter = defaultCustomFilters;
  };

  @action
  disposeCustomFilter = () => {
    this.customFilter = null;
  };

  @action
  setWrapperRef = node => {
    this.wrapperRef = node;
  };
}

export default AllGuidesPage;
