import styled from 'styled-components';
import $DataTable from 'ui-components/Layout/DataTable';
import Button from 'ui-components/Button';
import Field from 'ui-components/Field';

export const TeamNameField = styled(Field).attrs({
  styles: {Input: {fontSize: '32px', fontWeight: 300}},
  inline: true
})`
  margin-bottom: 40px;
`;

export const ContentWrapper = styled.div`
  height: 100%;
`;

export const DataTable = styled($DataTable)`
  .ReactTable {
    margin-bottom: 1rem !important;
  }

  .rt-table {
    overflow: visible !important;
  }

  .rt-tbody {
    overflow: visible !important;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }
  .rt-tr-group:hover {
    ${() => RemoveButton} {
      opacity: 1;
    }
  }
`;
export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const RemoveButton = styled(Button).attrs({
  iconId: 'close'
})`
  cursor: pointer;
`;
