import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';

//queries

//mutations
import {ArchiveTag} from 'api/tag/mutations';
import {archiveTagOptions} from 'api/tag/mutation-options';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import DataTable from 'ui-components/Layout/DataTable';
import Container from 'ui-components/Layout/Container';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import SearchBar from 'ui-components/Layout/SearchBar';
import {DropdownMenu, DropdownMenuButton, DropdownMenuItem} from 'styles/styled-components/dropdown-styled-components';

import views from 'config/views';

import {HeaderWrapper, CellWrapper, StyledText} from 'ui-components/Layout/DataTable/styles';
import {StyledIcon, StyledTagTitle, StyledDiv, StyledMenu} from './styles';

//messages
import messages from './messages';

@inject('store')
@graphql(ArchiveTag, archiveTagOptions)
@observer
class TagManagementView extends Component {
  static defaultProps = {
    data: null
  };

  async componentDidMount() {
    const {store} = this.props;
    const {
      app,
      auth: {user},
      router,
      tagManagementPage: {fetchData, setTags}
    } = store;

    const actionMenuItems = [
      {
        id: 'create',
        view: 'newTag'
      },
      {
        id: 'translate',
        view: 'translateTags'
      },
      {
        id: 'merge',
        view: 'mergeTags'
      }
    ];

    app.setActionMenuItems(actionMenuItems);

    if (!user.canCreateTags) {
      router.goTo(views.root, {});
    }
    const {tags} = await fetchData();
    setTags(tags);
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  archiveTag = row => {
    const {
      store,
      archiveTagMutation,
      intl: {formatMessage}
    } = this.props;
    const {id, title} = row;

    const translations = {
      removeTag: formatMessage(messages.removeTag),
      confirmation: formatMessage(messages.confirmation),
      archiveFailure: formatMessage(messages.archiveFailure),
      archiveSuccess: formatMessage(messages.archiveSuccess),
      action: formatMessage(messages.removeTag)
    };

    store.archiveDialog.open({
      params: {id},
      mutation: archiveTagMutation,
      title: formatMessage(messages.removeTag),
      translations: translations,
      onArchive: () => store.router.goTo(views.tags, {}),
      content: <StyledTagTitle>{title}</StyledTagTitle>,
      type: 'tag'
    });
  };

  setSearchTerm = e => {
    e.preventDefault();
    e.stopPropagation();
    const {
      store: {
        tagManagementPage: {setSearchTerm, onSearch}
      }
    } = this.props;
    setSearchTerm(event.target.value);
    onSearch();
  };

  LoadingComponent = ({loading}) => {
    return (
      loading && (
        <LoadingMessage>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      )
    );
  };

  render() {
    const {
      store: {
        tagManagementPage: {
          tagsWithLanguage,
          editTag,
          searchTerm,
          setPageIndex,
          setSorted,
          tagsLoading,
          page,
          sorted,
          pages,
          tags,
          totalCount
        },
        auth: {
          user: {isIPA, isPlatformAdmin}
        }
      },
      intl: {formatMessage}
    } = this.props;

    if (!tags && !tagsLoading) {
      return <StyledDiv>{formatMessage(messages.noTags)}</StyledDiv>;
    }

    const noTagsMessage =
      !tagsLoading && !tags.length && !searchTerm
        ? formatMessage(messages.noTags)
        : searchTerm
        ? formatMessage(messages.noFilteredTags, {searchTerm})
        : null;

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.subtitle)}
          right={
            <SearchBar
              value={searchTerm}
              placeholder={formatMessage(messages.searchTags)}
              onChange={e => this.setSearchTerm(e)}
              width={250}
            />
          }
        />
        <DataTable
          defaultSorted={[
            {
              id: 'title',
              desc: false
            }
          ]}
          data={tagsWithLanguage}
          noDataMessage={noTagsMessage}
          hoverEffect
          manual
          pages={pages}
          loading={tagsLoading}
          page={page}
          sorted={sorted}
          onSortedChange={setSorted}
          onPageChange={setPageIndex}
          loadingComponent={this.LoadingComponent}
          columns={[
            {
              Header: <HeaderWrapper paddingLeft>{`${formatMessage(messages.name)} (${totalCount})`}</HeaderWrapper>,
              accessor: 'title',
              Cell: tag => (
                <CellWrapper paddingLeft>
                  {tag.original.badge && <StyledIcon name="trophy" />}
                  <span title={tag.original.title}>{tag.original.title}</span>
                </CellWrapper>
              ),
              sortable: true,
              minWidth: 150,
              resizable: true
            },
            {
              Header: <StyledText marginLeft={20}>{formatMessage(messages.defaultLanguage)}</StyledText>,
              accessor: 'default_locale',
              width: 250,
              Cell: tag => (
                <CellWrapper>
                  <StyledText marginLeft={20}>{tag.original.defaultLanguage}</StyledText>
                </CellWrapper>
              )
            },
            {
              Header: <StyledText marginLeft={20}>{formatMessage(messages.actions)}</StyledText>,
              Cell: tag => {
                const canModifyTags = isIPA || isPlatformAdmin;

                if (canModifyTags) {
                  return (
                    <StyledMenu
                      title={`${tag.original.title.split(' ').join('-')}`}
                      dropdownMenu={
                        <DropdownMenu>
                          <DropdownMenuItem>
                            <DropdownMenuButton
                              data-cy={`edit-tag-${tag.original.title.split(' ').join('-')}`}
                              onClick={() => editTag(tag.original.id)}
                            >
                              <FormattedMessage {...messages.edit} />
                            </DropdownMenuButton>
                          </DropdownMenuItem>

                          <DropdownMenuItem>
                            <DropdownMenuButton
                              data-cy={`remove-tag-${tag.original.title.split(' ').join('-')}`}
                              onClick={() => this.archiveTag(tag.original)}
                            >
                              <FormattedMessage {...messages.delete} />
                            </DropdownMenuButton>
                          </DropdownMenuItem>
                        </DropdownMenu>
                      }
                    />
                  );
                }
              },
              sortable: false,
              width: 80
            }
          ]}
        />
      </Container>
    );
  }
}

export default injectIntl(TagManagementView);
