import {defineMessages} from 'react-intl';

const messages = defineMessages({
  removeCampaignFromAllInstructions: {
    id: 'removeSignOffFromAllInstructions',
    defaultMessage: 'Remove sign-off from all instructions'
  },
  removeCampaignFromAInstruction: {
    id: 'removeSignOffFromAInstruction',
    defaultMessage: 'Remove sign-off'
  },
  subtitleRemoveCampaignAllInstructions: {
    id: 'subtitleRemoveSignOffAllInstructions',
    defaultMessage:
      'Any sign-off that was applied before will be removed from all instructions in this guide. You can manually reapply them.'
  },
  subtitleRemoveCampaignAInstruction: {
    id: 'subtitleRemoveSignOffAInstruction',
    defaultMessage: 'The existing sign-off will be removed from this instruction. You can manually reapply it.'
  },
  remove: {
    id: 'common.actions.remove',
    defaultMessage: 'Remove'
  },
  campaignRemoveSuccess: {
    id: 'removeSignOff.signOffRemoveSuccess',
    defaultMessage: 'Sign-off removed from the instruction'
  },
  campaignRemoveError: {
    id: 'removeSignOff.signOffRemoveError',
    defaultMessage: "Couldn't remove sign-off"
  },
  campaignsRemoveSuccess: {
    id: 'removeSignOff.signOffsRemoveSuccess',
    defaultMessage: 'Sign-offs removed from all instructions'
  },
  campaignsRemoveError: {
    id: 'removeSignOff.signOffsRemoveError',
    defaultMessage: "Couldn't remove sign-off from instructions"
  }
});

export default messages;
