import React, {Component} from 'react';

import {FormattedMessage} from 'components/FormattedComponents';

import Dialog from 'ui-components/Dialog';

import messages from '../messages';
import {dialogStyle, StyledText, ListWrapper, ListLink} from '../styles';
import {openGuidesWithDialogClosed, openGuideWithDialogClosed} from '../utils';

class HasAssignedGuidesDialog extends Component {
  render() {
    const {
      guides,
      isOpen,
      onCancel,
      userId,
      isArchiving,
      user: {fullName}
    } = this.props;

    const title = isArchiving ? (
      <FormattedMessage {...messages.cannotArchiveUser} />
    ) : (
      <FormattedMessage {...messages.cannotDeleteUser} />
    );
    const subtitle =
      guides.length === 1 ? (
        <StyledText>
          <FormattedMessage
            {...messages.assignedGuideTeamsDeleteUser}
            values={{
              userName: <b>{fullName}</b>,
              guideName: (
                <ListLink key={guides[0].id} onClick={() => openGuideWithDialogClosed(guides[0].id)} target="_blank">
                  {guides[0].title}
                </ListLink>
              )
            }}
          />
        </StyledText>
      ) : (
        <StyledText>
          <FormattedMessage
            {...messages.assignedGuidesTeamsDeleteUser}
            values={{
              name: <b>{fullName}</b>
            }}
          />
        </StyledText>
      );
    const content = guides.length > 1 && (
      <ListWrapper>
        {guides.map(guide => (
          <ListLink key={guide.id} onClick={() => openGuideWithDialogClosed(guide.id)} target="_blank">
            {guide.title}
          </ListLink>
        ))}
      </ListWrapper>
    );

    const actions = [
      {
        label: <FormattedMessage {...messages.goToGuideOverview} />,
        onClick: () => openGuidesWithDialogClosed(userId),
        disabled: false
      },
      {
        label: <FormattedMessage {...messages.close} />,
        onClick: onCancel,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        onCancel={onCancel}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {subtitle}
        {content}
      </Dialog>
    );
  }
}

export default HasAssignedGuidesDialog;
