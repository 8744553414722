import {observable, action, computed, makeObservable} from 'mobx';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import notification from 'utils/notification-utils';

class TagTranslationsPage {
  @observable uiTranslations = {};
  @observable sourceLanguages = [];
  @observable selectedSourceLocale = 'default';
  @observable selectedTargetLocale;
  @observable tagsTranslations = [];

  refetchTagsQuery = null;

  @action
  setUiTranslations = uiTranslations => {
    this.uiTranslations = uiTranslations;
  };

  @action setSourceLanguages = languages => {
    const defaultLanguage = {
      locale: 'default',
      language: this.uiTranslations.defaultLanguage
    };
    this.sourceLanguages = [defaultLanguage].concat(...languages);
  };

  @action setSelectedSourceLocale = locale => {
    if (locale === this.selectedTargetLocale) {
      this.selectedTargetLocale = undefined;
    }
    this.selectedSourceLocale = locale ? locale : 'default';
  };

  @action setSelectedTargetLocale = locale => {
    this.selectedTargetLocale = locale;
    this.checkSelectedTag();
  };

  @action checkSelectedTag = () => {
    if (!this.selectedTag || this.selectedTag.defaultLocale === this.selectedTargetLocale) {
      const selectableTags = this.tagsTranslationData.filter(t => !t.disabled);
      if (selectableTags.length) this.setSelectedTag(selectableTags[0]);
      else this.selectedTag = null;
    }
  };

  @action setTagsTranslations = tags => {
    this.tagsTranslations = tags;
  };

  @action setSelectedTag = tag => {
    if (!this.selectedTargetLocale || !tag || tag.disabled) return;
    this.selectedTag = tag;
    const element = document.getElementById(tag.id);
    if (!element) return;
    element.focus();
  };

  @action changeTranslation = input => {
    this.tagsTranslations = this.tagsTranslations.map(t => {
      if (t.id !== this.selectedTag.id) return t;
      t.translations[this.selectedTargetLocale] = {title: input};
      return t;
    });
  };

  @action saveTranslations = mutation => {
    mutation({translations: this.tagsTranslations}).then(result =>
      result
        ? notification.success(this.uiTranslations.saveSuccess)
        : notification.failure(this.uiTranslations.saveFailure)
    );
  };

  @action reset = () => {
    this.selectedTag = undefined;
    this.selectedSourceLocale = 'default';
    this.selectedTargetLocale = undefined;
    this.tagsTranslations = [];
    this.sourceLanguages = [];
    this.refetchTagsQuery = null;
  };

  constructor() {
    makeObservable(this);
  }

  @computed get selectedTagId() {
    return this.selectedTag ? this.selectedTag.id : null;
  }

  @computed get targetLanguages() {
    return this.selectedSourceLocale
      ? this.sourceLanguages.filter(l => l.locale !== this.selectedSourceLocale && l.locale !== 'default')
      : this.sourceLanguages;
  }

  @computed get selectedSourceLanguage() {
    return find(this.sourceLanguages, {locale: this.selectedSourceLocale}).language;
  }

  @computed get selectedTargetLanguage() {
    const targetLanguage = find(this.targetLanguages, {locale: this.selectedTargetLocale});
    return targetLanguage ? targetLanguage.language : this.uiTranslations.noLanguageSelected;
  }

  @computed get tagsTranslationData() {
    if (!this.tagsTranslations) return [];
    const data = this.tagsTranslations.map(t => {
      const defaultLanguage = find(this.sourceLanguages, {locale: t.defaultLocale});
      return {
        id: t.id,
        defaultLocale: t.defaultLocale,
        defaultLanguage: defaultLanguage ? defaultLanguage.language : t.defaultLocale,
        title: t.translations[t.defaultLocale].title,
        translateFrom:
          (t.translations[this.selectedSourceLocale] && t.translations[this.selectedSourceLocale].title) || '-',
        translateTo:
          (t.translations[this.selectedTargetLocale] && t.translations[this.selectedTargetLocale].title) || '',
        disabled: t.defaultLocale === this.selectedTargetLocale
      };
    });
    return sortBy(data, ['title']);
  }

  setRefetchQuery = refetch => {
    this.refetchTagsQuery = refetch;
  };
}

export default TagTranslationsPage;
