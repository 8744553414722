import {defineMessages} from 'react-intl';

const messages = defineMessages({
  noSkills: {
    id: 'noSkills',
    defaultMessage: "You don't have any Skills yet."
  },
  clickAddSkillButton: {
    id: 'clickAddSkillButton',
    defaultMessage: 'To add new Skill Set click on the button below.'
  },
  createSkill: {
    id: 'createSkill',
    defaultMessage: 'Create Skill'
  },
  importSkills: {
    id: 'importSkills',
    defaultMessage: 'Import Skills'
  },
  learnings: {
    id: 'learnings',
    defaultMessage: 'Learnings'
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading'
  },
  searchByName: {
    id: 'searchByName',
    defaultMessage: 'Search by name'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  skillProfiles: {
    id: 'skillProfiles',
    defaultMessage: 'Skill Profiles'
  },
  skillName: {
    id: 'skillName',
    defaultMessage: 'Skill Name'
  },
  sortBy: {
    id: 'common.actions.sortBy',
    defaultMessage: 'Sort by'
  },
  repeatEvery: {
    id: 'repeatEvery',
    defaultMessage: 'Assessment Frequency'
  },
  everySixMonths: {
    id: 'everySixMonths',
    defaultMessage: 'Every 6 months'
  },
  everyOneYear: {
    id: 'everyOneYear',
    defaultMessage: 'Every 1 year'
  },
  everyTwoYears: {
    id: 'everyTwoYears',
    defaultMessage: 'Every 2 years'
  },
  none: {
    id: 'common.none',
    defaultMessage: 'None'
  },
  error: {
    id: 'common.errors.pageload',
    defaultMessage: "Couldn't load the page. Please try again."
  },
  noSkillsFound: {
    id: 'noSkillsFound',
    defaultMessage: 'No Skills found'
  },
  filtersTitle: {
    id: 'MediaGallery.filter',
    defaultMessage: 'Filter'
  },
  filterByTag: {
    id: 'TagManagement.title',
    defaultMessage: 'Tags'
  },
  tenTags: {
    id: 'tenTags',
    defaultMessage: 'Select up to 10 tags.'
  },
  deleteSkill: {
    id: 'deleteSkill',
    defaultMessage: 'Delete Skill {name}?'
  },
  deleteEverywhere: {
    id: 'common.actions.deleteEverywhere',
    defaultMessage: 'Delete Everywhere'
  },
  deleteDialog: {
    id: 'deleteDialog',
    defaultMessage:
      'This skill is used by {count} {count, plural, one {Skill Profile} other {Skill Profiles}}. If you delete it, it will be removed everywhere.'
  },
  deletedSkill: {
    id: 'deletedSkill',
    defaultMessage: 'Skill has been deleted.'
  },
  errorDeletingSkill: {
    id: 'errorDeletingSkill',
    defaultMessage: 'Error deleting skill. Please try again.'
  }
});

export default messages;
