import {defineMessages} from 'react-intl';

const messages = defineMessages({
  gap: {
    id: 'SmartSkills.gap',
    defaultMessage: 'Gap'
  },
  gaps: {
    id: 'SmartSkills.gaps',
    defaultMessage: 'Gaps'
  },
  levels: {
    id: 'SmartSkills.levels',
    defaultMessage: 'Levels'
  },
  members: {
    id: 'SmartSkills.members',
    defaultMessage: 'Members'
  },
  noMatrix: {
    id: 'SmartSkills.noMatrix',
    defaultMessage: 'There are no Skills assigned to this Team yet.'
  },
  noMembers: {
    id: 'SmartSkills.noMembers',
    defaultMessage: 'There are no Members in this Team yet.'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  statsApproved: {
    id: 'SmartSkills.stats.approved',
    defaultMessage: 'Approved'
  },
  notAssigned: {
    id: 'SmartSkills.notAssigned',
    defaultMessage: 'Not Assigned'
  }
});

export default messages;
