import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Modal from 'react-modal';

import ChangePasswordDialog from 'components/ChangePasswordDialog';
import ChangeCampaignStatusDialog from 'components/ChangeCampaignStatusDialog';
import ExamplesDialog from 'components/ExamplesDialog';
import ShareDialog from 'components/ShareDialog';
import UploadTranslationsDialog from 'components/UploadTranslationsDialog';
import ReassignGuideDialog from 'components/ReassignGuideDialog';
import ConfirmGuideReassignDialog from 'components/ConfirmGuideReassignDialog';
import ArchiveDialog from 'components/ArchiveDialog';
import AuthErrorDialog from 'components/AuthErrorDialog';
import SSOErrorDialog from 'components/SSOErrorDialog';
import VersionMismatchDialog from 'components/VersionMismatchDialog';
import MediaPickerDialog from 'components/MediaPickerDialog';
import GuideFeedbackDialog from 'components/GuideFeedbackDialog';
import SessionExpiredDialog from 'components/SessionExpiredDialog';
import SaveAsDraftDialog from 'components/SaveAsDraftDialog';
import UnsavedChangesDialog from 'components/UnsavedChangesDialog';
import GuideVersionsDialog from 'components/GuideVersionsDialog';
import PublishGuideDialog from 'components/PublishGuideDialog';
import ExportToPdfDialog from 'components/ExportToPdfDialog';
import InstructionalIconDialog from 'components/InstructionalIconDialog';
import EditThemeIconDialog from 'components/EditThemeIconDialog';
import IconsLimitDialog from 'components/IconsLimitDialog';
import NoteIconsAffectedGuidesDialog from 'components/NoteIconsAffectedGuidesDialog';
import MoveToDialog from 'components/MoveToDialog';
import RequestApprovalDialog from 'components/RequestApprovalDialog';
import RejectVersionDialog from 'components/RejectVersionDialog';
import ConnectNewWorkspaceDialog from 'components/ConnectNewWorkspaceDialog';
import AddUserToTeamDialog from 'components/AddUserToTeamDialog';
import GuideDetailsDialog from 'components/GuideDetailsDialog';
import CopyToDialog from 'components/CopyToDialog';
import FrequencySettingsDialog from 'components/FrequencySettingsDialog';
import ApplyCampaignDialog from 'components/ApplyCampaignDialog';
import CampaignPreviewDialog from 'components/CampaignPreviewDialog';
import RemoveCampaignDialog from 'components/RemoveCampaignDialog';
import ConfirmDeactivateDialog from 'components/ConfirmDeactivateDialog';
import OverrideCampaignDialog from 'components/OverrideCampaignDialog';
import CookieBanner from 'shared/components/CookieBanner';
import DeleteUserDialog from 'components/DeleteUserDialog';
import ArchiveUserDialog from 'components/ArchiveUserDialog';
import TeamHasPendingApprovalsDialog from 'components/TeamHasPendingApprovalsDialog';
import WarningDialog from 'components/WarningDialog';
import AddNewLanguageDialog from 'components/AddNewLanguageDialog';
import PlatformLanguageSelectionDialog from 'components/PlatformLanguageSelectionDialog';
import SuggestionDialog from 'components/SuggestionDialog';
import ChecklistAlertRecipientsDialog from 'components/ChecklistAlertRecipientsDialog';
import SkillsProfilesDialog from 'views/CreateSkills/SkillsProfilesDialog';
import SkillProfilesDialog from 'components/SkillProfilesDialog';

import views from 'config/views';
import AutoTranslationDialog from 'components/AutoTranslationDialog';
import SelectDefaultLanguageDialog from 'components/SelectDefaultLanguageDialog';

import type {store} from 'stores/store';

type DialogsProps = {
  store: typeof store;
};

@inject('store')
@observer
class DialogsComponent extends Component<DialogsProps> {
  render() {
    const {store} = this.props;
    const {
      changePasswordDialog,
      changeCampaignStatusDialog,
      examplesDialog,
      shareDialog,
      uploadTranslationsDialog,
      reassignGuideDialog,
      archiveDialog,
      authErrorDialog,
      mediaPickerDialog,
      guideFeedbackDialog,
      sessionExpiredDialog,
      saveAsDraftDialog,
      skillsPage,
      unsavedChangesDialog,
      guideVersionsDialog,
      publishGuideDialog,
      exportToPdfDialog,
      iconsLimitDialog,
      instructionalIconDialog,
      noteIconsAffectedGuidesDialog,
      editThemeIconDialog,
      moveToDialog,
      requestApprovalDialog,
      rejectVersionDialog,
      connectNewWorkspaceDialog,
      addUserToTeamDialog,
      guideDetailsDialog,
      copyToDialog,
      frequencySettingsDialog,
      applyCampaignDialog,
      campaignPreviewDialog,
      removeCampaignDialog,
      confirmDeactivateDialog,
      overrideCampaignDialog,
      suggestionDialog,
      cookiePreference,
      router,
      deleteUserDialog,
      archiveUserDialog,
      teamHasPendingApprovalsDialog,
      warningDialog,
      autoTranslationDialog,
      platform,
      selectDefaultGuideLanguageDialog,
      addNewLanguageDialog,
      checklistAlertRecipientsDialog,
      skillProfilesDialog,
      app
    } = store;

    const {hideCookiePreferencesDialog} = cookiePreference;

    return (
      <div>
        {changePasswordDialog.opened && <ChangePasswordDialog />}
        {changeCampaignStatusDialog.opened && <ChangeCampaignStatusDialog />}
        {examplesDialog.opened && <ExamplesDialog />}
        {shareDialog.opened && <ShareDialog />}
        {uploadTranslationsDialog.opened && <UploadTranslationsDialog />}
        {reassignGuideDialog.opened && <ReassignGuideDialog />}
        {reassignGuideDialog.confirmDialogOpened && <ConfirmGuideReassignDialog />}
        {archiveDialog.opened && <ArchiveDialog />}
        {authErrorDialog.opened && <AuthErrorDialog />}
        {mediaPickerDialog.opened && <MediaPickerDialog />}
        {guideFeedbackDialog.opened && <GuideFeedbackDialog />}
        {sessionExpiredDialog.opened && <SessionExpiredDialog />}
        {saveAsDraftDialog.opened && <SaveAsDraftDialog />}
        {unsavedChangesDialog.opened && <UnsavedChangesDialog />}
        {<VersionMismatchDialog />}
        {guideVersionsDialog.opened && <GuideVersionsDialog />}
        {publishGuideDialog.opened && <PublishGuideDialog />}
        {exportToPdfDialog.opened && <ExportToPdfDialog />}
        {iconsLimitDialog.opened && <IconsLimitDialog />}
        {instructionalIconDialog.opened && <InstructionalIconDialog />}
        {editThemeIconDialog.opened && <EditThemeIconDialog />}
        {noteIconsAffectedGuidesDialog.opened && <NoteIconsAffectedGuidesDialog />}
        {moveToDialog.opened && <MoveToDialog />}
        {requestApprovalDialog.opened && <RequestApprovalDialog />}
        {rejectVersionDialog.opened && <RejectVersionDialog />}
        {connectNewWorkspaceDialog.opened && <ConnectNewWorkspaceDialog />}
        {addUserToTeamDialog.opened && <AddUserToTeamDialog />}
        {guideDetailsDialog.opened && <GuideDetailsDialog />}
        {copyToDialog.opened && <CopyToDialog />}
        {frequencySettingsDialog.opened && <FrequencySettingsDialog />}
        {applyCampaignDialog.opened && <ApplyCampaignDialog />}
        {campaignPreviewDialog.opened && <CampaignPreviewDialog />}
        {removeCampaignDialog.opened && <RemoveCampaignDialog />}
        {confirmDeactivateDialog.opened && <ConfirmDeactivateDialog />}
        {overrideCampaignDialog.opened && <OverrideCampaignDialog />}
        {deleteUserDialog.opened && <DeleteUserDialog />}
        {archiveUserDialog.opened && <ArchiveUserDialog />}
        {teamHasPendingApprovalsDialog.opened && <TeamHasPendingApprovalsDialog />}
        {warningDialog.opened && <WarningDialog />}
        {addNewLanguageDialog.opened && <AddNewLanguageDialog />}
        {suggestionDialog.opened && <SuggestionDialog />}
        {skillsPage.isSkillProfileDialogOpened && <SkillsProfilesDialog />}
        {skillProfilesDialog.opened && <SkillProfilesDialog />}
        {app.isPlatformLanguageDialogOpen && <PlatformLanguageSelectionDialog />}
        {platform.canUseAutoTranslations && autoTranslationDialog.opened && (
          <AutoTranslationDialog
            currentDialog={autoTranslationDialog.currentDialog}
            selectedLanguageToTranslate={autoTranslationDialog.selectedLanguageToTranslate}
            selectedLanguageToAdd={autoTranslationDialog.selectedLanguageToAdd}
            loading={autoTranslationDialog.loading}
          />
        )}
        {selectDefaultGuideLanguageDialog.opened && (
          <SelectDefaultLanguageDialog
            isOpen={selectDefaultGuideLanguageDialog.opened}
            isLoading={selectDefaultGuideLanguageDialog.isLoading}
            selectedLocale={selectDefaultGuideLanguageDialog.selectedLocale}
          />
        )}
        {checklistAlertRecipientsDialog.opened && <ChecklistAlertRecipientsDialog />}
        <CookieBanner
          ModalComponent={Modal}
          goToPrivacyPage={() => {
            hideCookiePreferencesDialog();
            router.goTo(views.privacy, {});
          }}
        />
        <SSOErrorDialog />
      </div>
    );
  }
}

export default DialogsComponent;
