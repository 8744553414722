import moment from 'moment';

export const getOptionsMap = (startDate, endDate) => {
  const startingDate = moment(new Date(startDate));
  const endingDate = moment(new Date(endDate));
  const itEndsToday = moment().diff(endingDate, 'days') === 0;

  return {
    isToday: moment().diff(startingDate, 'days') === 0 && itEndsToday,
    isYesterday: moment().diff(startingDate, 'days') === 1 && moment().diff(endingDate, 'days') === 1,
    isLast7Days: moment().subtract(7, 'days').diff(startingDate, 'days') === 0 && itEndsToday,
    isLast30Days: moment().subtract(30, 'days').diff(startingDate, 'days') === 0 && itEndsToday,
    isLastMonth:
      moment().subtract(1, 'months').startOf('month').diff(startingDate, 'days') === 0 &&
      moment().subtract(1, 'months').endOf('month').diff(endingDate, 'days') === 0,
    isWeekToDate: moment().startOf('week').diff(startingDate, 'days') === 0 && itEndsToday,
    isMonthToDate: moment().startOf('month').diff(startingDate, 'days') === 0 && itEndsToday
  };
};

export const allTimeYears = 10;
