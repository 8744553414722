import styled from 'styled-components';
import InputWithIcon from 'components/InputWithIcon';
import flex from 'styles/flex';
import colors from 'styles/colors';
import {borderRadius} from 'styles/sizes';
import $Icon from 'shared/components/Icon';

export const SetPasswordForm = styled.div`
  background-color: white;
  width: 540px;
  min-height: 358px;
  ${flex.vertical} ${flex.centerVertical} border: 1px solid #e0e0e0;
  padding: 35px 0;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 33px;
  color: #2f3133;
`;

export const Link = styled.a`
  color: ${colors.linkColor};
  font-size: 14px;
`;

export const Wrap = styled.div`
  width: 404px;
`;

export const Input = styled(InputWithIcon)`
  margin-bottom: 4px;
  border: none;
`;

export const ErrorWrapper = styled.div`
  ${flex.horizontal} ${flex.centerHorizontal} width: 400px;
  height: 50px;
  border-radius: ${borderRadius}px;
  background-color: #f9dddd;
  font-size: 14px;
  font-weight: 300;
  color: #2f3133;
  margin-bottom: 10px;
`;

export const styles = {
  height: '56px',
  padding: '1px 40px 0px 16px',
  marginBottom: '10px'
};

export const ErrorNotification = styled.div`
  color: #f31414;
  font-size: 14px;
  font-family: lato;
  padding: 0px 2px 15px 10px;
  margin-left: 10px;
`;

export const StyledErrorWrapper = styled.div`
  display: flex;
`;

export const StyledErrorIcon = styled($Icon).attrs({})`
  width: 16px;
  color: #f31414;
  display: inline-block;
  position: absolute;
`;
