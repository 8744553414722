import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const fields = ['icons', 'icons[].id', 'icons[].svg'];

const rules = {
  'icons[].id': 'string',
  'icons[].svg': 'string'
};

export default new MobxReactForm({fields, rules}, {plugins});
