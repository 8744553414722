import styled from 'styled-components';

export const Wrapper = styled.div`
  background: #ffffff;
  padding: 28px 0px 0px 0px;
  margin: 0 -4px;
`;

export const BarLabelWrapper = styled.div`
  display: inline-block;
  float: left;
  position: relative;
  height: 100%;
  width: ${p => p.width}%;
`;

export const BarWrapper = styled.div`
  position: relative;
  height: 94px;
  width: ${p => p.width}%;
`;

export const LabelWrapper = styled.div`
  text-align: center;
  margin-top: 4px;
  font-size: 12px;
  opacity: 0.8;
`;

export const Bar = styled.div`
  position: absolute;
  left: 4px;
  right: 4px;
  bottom: 0px;
  height: ${p => p.height}%;
  min-height: 1px;
  border-radius: 2px;
  background: ${p => (p.bgColor ? p.bgColor : '#ededed')};
`;

export const RatingLabel = styled.div`
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
`;
