import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//helpers
import {syncTitleWithSlug} from 'shared/utils/input-utils';

//components
import TopicDetails from 'components/TopicDetails';

//styles
import {NewTopic} from './styles';

//data
import {CreateTopic, RepositionTopicsAndInstructions} from 'api/topic/mutations';
import {createTopic, repositionTopicsAndInstructions} from 'api/topic/mutation-options';

//mutations
import {UpdateInstruction} from 'api/instruction/mutations';

//options
import {updateInstructionInGuideOptions} from 'api/instruction/mutation-options';

@inject('store')
@graphql(CreateTopic, createTopic)
@graphql(UpdateInstruction, updateInstructionInGuideOptions)
@graphql(RepositionTopicsAndInstructions, repositionTopicsAndInstructions)
@observer
class NewTopicComponent extends Component {
  titleFieldOptions = {
    onChangeReaction: e => syncTitleWithSlug(e, this.props.store.newTopicPage.form)
  };

  /**
   * To create a topic, if there was a dropped instruction
   * it should also move the instruction to the newly created topic
   */
  createTopic = ({topic}) => {
    const {store, createTopicMutation, repositionTopicsAndInstructionsMutation} = this.props;
    const {dragDropItems: page, newTopicPage} = store;
    const {droppedItem} = newTopicPage;
    return createTopicMutation({topic}).then(({data}) => {
      if (!newTopicPage.droppedItem) {
        return;
      }

      // Move the instruction to the new topic
      page.topics.push({...topic, id: data.createTopic.id, instructions: []});
      page.moveInstruction(droppedItem.sourceTopicId, droppedItem.sourcePosition, data.createTopic.id, 0);

      page.submit(repositionTopicsAndInstructionsMutation);
    });
  };

  render() {
    const {
      store: {newTopicPage}
    } = this.props;

    return (
      <NewTopic>
        <TopicDetails mode="new" submitMutation={this.createTopic} topicStore={newTopicPage} />
      </NewTopic>
    );
  }
}

export default NewTopicComponent;
