import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

//components
import StepOverview from './StepOverview';
import SwipeableSteps from './SwipeableSteps';

@inject('store')
@observer
export default class StepListComponent extends Component {
  render() {
    const {store, ...forwardedProps} = this.props;
    const {stepId} = store.router.params;

    if (stepId) {
      const matches = stepId.match(/^new-(\d+)$/);
      const newStepIndex = matches && matches[1] - 1;

      return <SwipeableSteps newStepIndex={newStepIndex} {...forwardedProps} />;
    }

    return <StepOverview {...forwardedProps} />;
  }
}
