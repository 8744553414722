import {defineMessages} from 'react-intl';

const messages = defineMessages({
  rangeFrom: {
    id: 'RangePicker.rangeFrom',
    defaultMessage: 'Range from'
  },
  rangeFromPlaceholder: {
    id: 'RangePicker.rangeFromPlaceholder',
    defaultMessage: 'From...'
  },
  rangeTo: {
    id: 'RangePicker.rangeTo',
    defaultMessage: 'Range to'
  },
  rangeToPlaceholder: {
    id: 'RangePicker.rangeToPlaceholder',
    defaultMessage: 'To...'
  },
  rangeNotValid: {
    id: 'RangePicker.rangeNotValid',
    defaultMessage: 'Range not valid!'
  },
  rangeOverlap: {
    id: 'RangePicker.rangeOverlap',
    defaultMessage: 'Ranges of the follow up questions should not overlap!'
  }
});

export default messages;
