import styled from 'styled-components';

//components
import $CustomGuideListFilters from 'components/CustomGuideListFilters';
import $ViewTitle from 'ui-components/Layout/ViewTitle';
import $InfiniteScroll from 'shared/components/InfiniteScroll';

//helpers
import flex from 'styles/flex';

export const InstructionsList = styled.div`
  padding: 42px 50px 50px;
  @media (max-width: 720px) {
    padding: 42px 20px 50px;
  }
`;

export const FilterWrapper = styled.div`
  ${flex.horizontal};
  margin: 0 12px 36px;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const FilterWrapperSection = styled.div`
  ${flex.horizontal};
  padding: 10px 0;
`;

export const StyledCustomGuideListFilters = styled($CustomGuideListFilters)`
  margin: 0 12px 36px;
`;

export const StyledInfiniteScroll = styled($InfiniteScroll)`
  margin-top: 30px;
`;

export const StyledViewTitle = styled($ViewTitle)`
  margin: 0 12px 16px;
  padding: 0;
`;
