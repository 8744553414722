import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

export const StyledTitleText = styled.div`
  padding: 10px 15px;
  font-size: 16px;
`;

export const FiltersArea = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px 15px 0px 15px;
`;

export const BottomArea = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ApplyButton = styled.div`
  cursor: pointer;
  margin: 10px 15px;
  display: inline-flex;
  text-align: flex;
  border-bottom: 1px solid ${commonPalette.txLightGrey};
`;

export const SectionDelimiter = styled.div`
  border-bottom: 1px solid ${commonPalette.borderGrey};
`;

export const LabelWrapper = styled.div`
  margin-bottom: 3px;
`;

export const StyledPopover = styled.div`
  position: absolute;
  top: 30px;
  z-index: 100;
  background-color: white;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 2px 11px rgb(0 0 0 / 10%), 0 1px 2px rgb(0 0 0 / 15%);
`;

export const StyledDiv = styled.div`
  &::before {
    background: #fff;
    border-left: 1px solid #cfcfcf;
    border-top: 1px solid #cfcfcf;
    border-radius: 1px;
    content: '';
    height: 8px;
    left: 14px;
    position: absolute;
    top: 24px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 8px;
    z-index: 200;
  }
`;
