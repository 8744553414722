import {defineMessages} from 'react-intl';

const messages = defineMessages({
  label: {
    id: 'EditCampaign.label',
    defaultMessage: 'View mode'
  }
});

export default messages;
