import React, {Component} from 'react';
import PropTypes from 'prop-types';

import Button from '../Button';
import {StyledButtonGroup} from './styles';

class ButtonGroup extends Component {
  static defaultProps = {
    bottomFixed: false,
    forColorPicker: false,
    line: false,
    sticky: false
  };

  static propTypes = {
    bottomFixed: PropTypes.bool,
    forColorPicker: PropTypes.bool,
    line: PropTypes.bool,
    primary: PropTypes.object,
    secondary: PropTypes.object,
    sticky: PropTypes.bool,
    underlined: PropTypes.object
  };

  render() {
    const {primary, secondary, underlined} = this.props;

    return (
      <StyledButtonGroup {...this.props}>
        {primary && (
          <Button
            data-cy={primary.dataCy || 'primary-button'}
            data-testid={primary.dataTestId || 'primary-button'}
            {...primary}
          />
        )}
        {secondary && (
          <Button
            secondary
            data-cy={secondary.dataCy || 'secondary-button'}
            data-testid={secondary.dataTestId || 'secondary-button'}
            {...secondary}
          />
        )}
        {underlined && <Button underlined {...underlined} />}
      </StyledButtonGroup>
    );
  }
}

export default ButtonGroup;
