import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, action, computed, makeObservable} from 'mobx';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import {styleable} from 'shared/decorators';

//styled-components
import {
  SelectWrapper,
  Label,
  Select,
  MultiSelector,
  Popover,
  OptionsWrapper,
  Option,
  UnderlinedButton,
  UserInput,
  Button,
  Chip,
  Icon,
  Text,
  StyledCheckbox
} from './styles';
import LayoutIcon from 'ui-components/Layout/Icon';
import Dropdown from '../Dropdown';

//messages
import messages from './messages';

@observer
@styleable
class SelectComponent extends Component {
  static defaultProps = {
    allowClear: false,
    idProp: 'id',
    disabled: false,
    filterOption: [],
    optionFormatter: 'name',
    options: [],
    showSearch: false,
    style: {},
    underlined: false,
    lazyLoad: false,
    showCheckBoxSelector: false
  };

  static propTypes = {
    allowClear: PropTypes.bool,
    className: PropTypes.string,
    dataCy: PropTypes.string,
    idProp: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    field: PropTypes.string,
    filterOption: PropTypes.arrayOf(PropTypes.string),
    form: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    notFoundContent: PropTypes.string,
    onChange: PropTypes.func,
    optionFormatter: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    selectedValue: PropTypes.string,
    selectedValues: PropTypes.arrayOf(PropTypes.string),
    showSearch: PropTypes.bool,
    style: PropTypes.object,
    underlined: PropTypes.bool,
    lazyLoad: PropTypes.bool,
    showCheckBoxSelector: PropTypes.bool
  };

  inputId = this.props.id || uniqueId('select-');

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @observable selectorOpened = false;
  @observable userInput = '';

  @action toggleSelector = e => {
    if (e?.preventDefault) e.preventDefault();
    this.selectorOpened = !this.selectorOpened;
  };

  @action onChange = value => {
    const {disabled, form, field, onChange} = this.props;

    if (disabled) return;

    if (this.showMultiSelector) {
      const selectedIds = this.selectedOptionsIds.includes(value)
        ? this.selectedOptionsIds.filter(id => id !== value || this.isDisabled(id))
        : [...this.selectedOptionsIds, value];

      if (form && field) form.$(field).sync(selectedIds);
      if (onChange) onChange(selectedIds);
    } else {
      if (form && field) form.$(field).sync(value);
      if (onChange) onChange(value);
    }

    this.userInput = '';

    if (!this.showMultiSelector || !this.selectorOpened) this.toggleSelector();
  };

  @action updateUserInput = e => {
    const {onSearch} = this.props;
    this.userInput = e.target.value;
    if (onSearch) onSearch(this.userInput);
    if (!this.selectorOpened) this.toggleSelector();
  };

  @action handleInputKeydown = event => {
    if (event.keyCode === 13 && this.options.length) {
      const firstAvailbleOption = this.showMultiSelector
        ? this.options.find(opt => !this.isSelected(opt)).id
        : this.options[0].id;
      this.onChange(firstAvailbleOption);
    }

    if (event.keyCode === 8 && this.showMultiSelector && this.selectedOptionsIds.length && !this.userInput) {
      this.onChange(this.selectedOptionsIds[this.selectedOptionsIds.length - 1]);
    }
  };

  @action isSelected = option => {
    return this.showMultiSelector ? this.selectedOptionsIds.includes(option.id) : this.selectedOptionId === option.id;
  };

  @action isDisabled = id => {
    const {options} = this.props;
    const option = options.find(opt => opt.id === id);
    return !!option.disabled;
  };

  @computed get showMultiSelector() {
    const {selectedValues, form, field} = this.props;
    if (form && field) return Array.isArray(form.values()[field]);
    return !!selectedValues;
  }

  @computed get showUnderlined() {
    const {underlined} = this.props;
    return !this.showMultiSelector && underlined;
  }

  @computed get options() {
    const {options, filterOption, showSearch, idProp, lazyLoad} = this.props;

    if (lazyLoad) return options;

    const filteredOptions = options.filter(
      option =>
        !filterOption.includes(option[idProp]) ||
        option[idProp] === this.selectedOptionId ||
        this.selectedOptionsIds?.includes(option[idProp])
    );

    if (!this.userInput || !showSearch) return filteredOptions;
    return filteredOptions.filter(option =>
      this.formatOption(option).toLowerCase().trim().includes(this.userInput.toLowerCase().trim())
    );
  }

  @computed get selectedOptionId() {
    const {allowClear, defaultValue, field, form, options, selectedValue} = this.props;

    if (this.showMultiSelector) return null;

    if (form && field) return form.values()[field];

    if (selectedValue) return selectedValue;

    if (defaultValue) return defaultValue;

    if (options.length && !allowClear) return options[0].id;

    return null;
  }

  @computed get selectedOption() {
    const {options, idProp} = this.props;

    if (!this.selectedOptionId) return null;

    return options.find(option => option[idProp] === this.selectedOptionId) || null;
  }

  @computed get selectedOptionName() {
    return this.selectedOption ? this.formatOption(this.selectedOption) : '';
  }

  @computed get selectedOptionsIds() {
    const {defaultValue, field, form, selectedValues, lazyLoad, idProp} = this.props;

    if (!this.showMultiSelector) return null;

    if (form && field) return form.values()[field];

    if (selectedValues && lazyLoad) return selectedValues.map(val => val[idProp]);

    if (selectedValues) return selectedValues;

    if (defaultValue) return [defaultValue];

    return [];
  }

  @computed get selectedOptions() {
    const {idProp, options} = this.props;
    if (!this.selectedOptionsIds) return null;
    return options.filter(option => this.selectedOptionsIds.includes(option[idProp]));
  }

  @computed get hideRemoveOption() {
    const {allowClear} = this.props;
    return this.showMultiSelector && !allowClear && this.selectedOptionsIds.length === 1;
  }

  @computed get focusInput() {
    const {showSearch} = this.props;
    return this.selectorOpened && showSearch;
  }

  formatOption = option => {
    const {optionFormatter} = this.props;

    if (typeof optionFormatter === 'function') {
      return optionFormatter(option);
    }

    return option[optionFormatter];
  };

  handleScroll = e => {
    const {onScrollToBottom} = this.props;
    if (!onScrollToBottom) return;
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      onScrollToBottom();
    }
  };

  renderOptions = () => {
    const {
      dataCy,
      intl: {formatMessage},
      idProp,
      notFoundContent,
      style,
      testId,
      showCheckBoxSelector
    } = this.props;

    if (!this.options.length)
      return (
        <OptionsWrapper {...(dataCy && {'data-cy': `${dataCy}-options`})} style={style.OptionsWrapper}>
          <Option disabled popover={this.showUnderlined}>
            {notFoundContent || formatMessage(messages.nothingToSelect)}
          </Option>
        </OptionsWrapper>
      );

    return (
      <OptionsWrapper
        {...(dataCy && {'data-cy': `${dataCy}-options`})}
        style={style.OptionsWrapper}
        onScroll={this.handleScroll}
      >
        {this.options?.map((option, index) =>
          showCheckBoxSelector ? (
            <StyledCheckbox
              data-testid={`option-${testId}-${index}`}
              key={index}
              {...(dataCy && {'data-cy': `${dataCy}-option`})}
              caption={this.formatOption(option)}
              checked={this.isSelected(option)}
              onChange={() => !option.disabled && this.onChange(option[idProp])}
              role="option"
              aria-selected={this.isSelected(option)}
              disabled={option.disabled}
            />
          ) : (
            <Option
              data-testid={`option-${testId}-${index}`}
              key={index}
              {...(dataCy && {'data-cy': `${dataCy}-option`})}
              popover={this.showUnderlined}
              onClick={() => !option.disabled && this.onChange(option[idProp])}
              selected={this.isSelected(option)}
              role="option"
              aria-selected={this.isSelected(option)}
              disabled={option.disabled}
              style={option.style}
              hidden={option.hidden}
            >
              {option.iconId && <Icon iconId={option.iconId} />}
              {this.formatOption(option)}
              {this.isSelected(option) && (
                <LayoutIcon name="checkmark" size={16} data-testid={`${option.name}-selected`} />
              )}
            </Option>
          )
        )}
      </OptionsWrapper>
    );
  };

  renderSelector = () => {
    const {allowClear, dataCy, disabled, placeholder, showSearch, style, width, testId} = this.props;

    const Selector = this.showUnderlined && !this.showMultiSelector ? UnderlinedButton : Select;

    return (
      <Selector
        data-cy={dataCy}
        data-testid={testId}
        disabled={disabled}
        iconId="sort"
        iconOnRight
        label={this.selectedOptionName || placeholder}
        open={!disabled && this.selectorOpened}
        showSearch={showSearch}
        style={style.Selector}
        width={width}
      >
        {this.showUnderlined ? (
          this.selectedOptionName || placeholder
        ) : (
          <UserInput
            autoFocus={this.focusInput}
            id={this.inputId}
            style={style.Input}
            placeholder={this.selectedOptionName || placeholder}
            readOnly={!showSearch}
            value={this.selectorOpened ? this.userInput : this.selectedOptionName}
            disabled={disabled}
            onKeyDown={this.handleInputKeydown}
            {...(showSearch && {onChange: this.updateUserInput})}
          />
        )}
        {allowClear && this.selectedOption && !this.selectorOpened ? (
          <Button iconId="close" onClick={() => this.onChange(null)} />
        ) : (
          <Button iconId="sort" />
        )}
      </Selector>
    );
  };

  renderMultiSelector = () => {
    const {dataCy, idProp, showSearch, placeholder, style, disabled} = this.props;

    return (
      <MultiSelector showSearch={showSearch} data-cy={dataCy}>
        {this.selectedOptions?.map((selectedOption, index) => {
          return (
            <Chip
              key={index}
              iconId={selectedOption.iconId}
              label={this.formatOption(selectedOption)}
              {...(!selectedOption.disabled &&
                !this.hideRemoveOption && {
                  onRemove: () => this.onChange(selectedOption[idProp])
                })}
            />
          );
        })}
        {showSearch && this.selectorOpened && (
          <UserInput
            data-cy={`input-${dataCy}`}
            id={this.inputId}
            style={style.Input}
            placeholder={!this.selectedOptions.length ? placeholder : ''}
            value={this.userInput}
            disabled={disabled}
            onKeyDown={this.handleInputKeydown}
            autoFocus={this.focusInput}
            {...(showSearch && {onChange: this.updateUserInput})}
          />
        )}
      </MultiSelector>
    );
  };

  renderMultiCheckboxSelector = () => {
    const {dataCy, showSearch, placeholder, style, disabled} = this.props;

    return (
      <MultiSelector showSearch={showSearch} data-cy={dataCy}>
        <Text>
          {this.selectedOptions?.map(
            (selectedOption, index) =>
              `${this.formatOption(selectedOption)}${this.selectedOptions.length - 1 !== index ? ', ' : ''}`
          )}
        </Text>
        {showSearch && this.selectorOpened && (
          <UserInput
            data-cy={`input-${dataCy}`}
            id={this.inputId}
            style={style.Input}
            placeholder={!this.selectedOptions.length ? placeholder : ''}
            value={this.userInput}
            disabled={disabled}
            onKeyDown={this.handleInputKeydown}
            autoFocus={this.focusInput}
            {...(showSearch && {onChange: this.updateUserInput})}
          />
        )}
      </MultiSelector>
    );
  };

  render() {
    const {dataCy, disabled, label, style, display, width, showCheckBoxSelector} = this.props;

    const SelectDropdown = this.showUnderlined && !this.showMultiSelector ? Popover : Dropdown;

    return (
      <SelectWrapper
        id={`select-wrapper-${dataCy}`}
        underlined={this.showUnderlined}
        style={style}
        display={display}
        role="listbox"
      >
        {label && <Label htmlFor={this.inputId}>{label}</Label>}
        <SelectDropdown
          disabled={disabled}
          open={!disabled && this.selectorOpened}
          visible={!disabled && this.selectorOpened}
          toggle={this.toggleSelector}
          onVisibleChange={this.toggleSelector}
          overlayClassName="c-header__popover__inner"
          placement="bottomRight"
          trigger="click"
          content={this.renderOptions()}
          getPopupContainer={() => document.getElementById(`select-wrapper-${dataCy}`)}
          width={width}
        >
          {showCheckBoxSelector && this.showMultiSelector
            ? this.renderMultiCheckboxSelector()
            : this.showMultiSelector
            ? this.renderMultiSelector()
            : this.renderSelector()}
        </SelectDropdown>
      </SelectWrapper>
    );
  }
}

export default injectIntl(SelectComponent);
