import {fields, plugins} from 'stores/forms/campaign-question-follow-up-form';

//mobx form
import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';

//lodash
import isEmpty from 'lodash/isEmpty';

class FollowUpQuestion {
  form;

  constructor(props) {
    this.form = new MobxReactForm({fields}, {plugins});
    if (props) {
      const {rangeFrom, rangeTo, question, kind} = props;
      const updateForm = {
        kind,
        rangeFrom: rangeFrom.toString(),
        rangeTo: rangeTo.toString(),
        question
      };
      this.form.update(updateForm);
    }
  }

  isRangeValid = (minRange, maxRange) => {
    const fields = this.form.values();
    const {rangeFrom, rangeTo} = fields;

    const rangeFromValue = parseInt(rangeFrom, 10);
    const rangeToValue = parseInt(rangeTo, 10);

    return (
      (isEmpty(rangeFrom) && isEmpty(rangeTo)) ||
      (rangeFromValue <= rangeToValue && rangeFromValue >= minRange && rangeToValue <= maxRange)
    );
  };

  isValid = (minRange, maxRange) => {
    return this.form.isValid && this.isRangeValid(minRange, maxRange);
  };
}

export default FollowUpQuestion;
