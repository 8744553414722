import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import examples from 'config/example-images';

//messages
import messages from './messages';

//styled-components
import {Form, Row, Title, TitleAndLink, Link, MediaPicker} from './styles';

//components
import Field from 'ui-components/Field';
import TeamPicker from 'components/TeamPicker';
import {FormattedMessage} from 'components/FormattedComponents';

@inject('store')
@observer
class ThemeNameAndLogo extends Component {
  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      themePage,
      examplesDialog,
      auth: {user}
    } = store;
    const {
      nameAndLogoForm: form,
      logoMediaUrl,
      faviconMediaUrl,
      setLogoMediaUrl,
      setFaviconMediaUrl,
      teams
    } = themePage;

    // Themes withot team can only be created/edit by platform admins
    const allowThemeWithoutTeam = user.isPlatformAdmin;
    return (
      <Form>
        <Row>
          <Field size="small" title={formatMessage(messages.themeName)} field="name" form={form} />
          {teams.length > 1 && (
            <Field
              size="small"
              inputComponent={
                <TeamPicker
                  allowClear={allowThemeWithoutTeam}
                  field="teamId"
                  form={form}
                  label={formatMessage(messages.selectThemeOwner)}
                  placeholder={formatMessage(messages.selectTeam)}
                  teams={teams.slice()}
                />
              }
            />
          )}
        </Row>
        <TitleAndLink>
          <Title>
            <FormattedMessage {...messages.logo} />
          </Title>
          <Link
            label={formatMessage(messages.showExample)}
            onClick={() => examplesDialog.open(examples.platformLogos, formatMessage(messages.logoExample))}
          />
        </TitleAndLink>

        <MediaPicker
          slim
          form={form}
          field="logoMediaId"
          backgroundUrl={logoMediaUrl}
          setCoverUrl={setLogoMediaUrl}
          iconSize={logoMediaUrl ? 50 : 70}
          label={formatMessage(messages.changeLogo)}
          galleryConfig={{themeMediaType: 'logo'}}
          type="theme"
        />

        <TitleAndLink>
          <Title>
            <FormattedMessage {...messages.favicon} />
          </Title>
          <Link
            label={formatMessage(messages.showExample)}
            onClick={() => examplesDialog.open(examples.appIcon, formatMessage(messages.faviconExample))}
          />
        </TitleAndLink>
        <MediaPicker
          slim
          form={form}
          allowedFormats={['ico', 'svg']}
          field="faviconMediaId"
          backgroundUrl={faviconMediaUrl}
          setCoverUrl={setFaviconMediaUrl}
          iconSize={faviconMediaUrl ? 50 : 70}
          label={formatMessage(messages.changeFavicon)}
          acceptedTypes={['image/x-icon', 'image/vnd.microsoft.icon', 'image/svg+xml']}
          galleryConfig={{themeMediaType: 'appIcon'}}
          type="theme"
        />
      </Form>
    );
  }
}

export default injectIntl(ThemeNameAndLogo);
