import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import {graphql} from '@apollo/client/react/hoc/graphql';
import notification from 'utils/notification-utils';

// graphql
import {SaveSkillProfile, UpdateSkillProfile, UpdateSkillProfileTeams} from 'api/skills/mutations';
import {
  saveSkillProfileOptions,
  updateSkillProfileOptions,
  updateSkillProfileTeamsOptions
} from 'api/skills/mutation-options';
import {SkillProfile} from 'api/skills/queries';
import {skillProfileOptions} from 'api/skills/query-options';
import {TeamsLite} from 'api/team/queries';
import {teamsLiteOptions} from 'api/team/query-options';
import {SkillRoles} from 'api/skill-matrix/queries';
import {SkillRolesOptions} from 'api/skill-matrix/query-options';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';

//components
import StepsTabs from 'components/StepsTabs';
import Container from 'ui-components/Layout/Container';
import {BackButton, BackIcon} from 'components/SmartSkillsPageHeader/styles';

//messages
import messages from './messages';

// constants
import {CREATE_MODE, EDIT_MODE, NEW_PATH_PARAM} from '../../config/constants';

@inject('store')
@graphql(SkillProfile, skillProfileOptions)
@graphql(SkillRoles, SkillRolesOptions)
@graphql(TeamsLite, teamsLiteOptions)
@graphql(UpdateSkillProfile, updateSkillProfileOptions)
@graphql(UpdateSkillProfileTeams, updateSkillProfileTeamsOptions)
@graphql(SaveSkillProfile, saveSkillProfileOptions)
@observer
class ManageSkillProfileView extends Component {
  componentDidMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {skillProfilePage} = store;

    if (store.router.queryParams?.teamId) {
      skillProfilePage.setTeamId(store.router.queryParams.teamId);
    }

    const mode = this.setModeBasedOnRouterParams();
    this.saveStrategy = this.selectStrategy(mode);

    const translations = mapValues(
      pick(messages, ['createSuccess', 'createFailure', 'title', 'assignTo', 'general', 'skillLevelMustBeSet']),
      message => formatMessage(message)
    );

    skillProfilePage.reset();
    skillProfilePage.setTranslations(translations);
    skillProfilePage.stepsStore.startListeningToRouteChange();
  }

  componentDidUpdate(prevProps) {
    const {
      skillProfileQuery,
      skillRolesQuery,
      teamsQuery,
      store: {
        skillProfilePage: {setAvailableTeams, setAvailableSkillRoles, setSkillProfile}
      }
    } = this.props;

    if (
      !skillProfileQuery?.loading &&
      !teamsQuery?.loading &&
      !skillRolesQuery.loading &&
      (prevProps.teamsQuery !== teamsQuery ||
        prevProps.skillProfileQuery !== skillProfileQuery ||
        prevProps.skillRolesQuery !== skillRolesQuery)
    ) {
      setAvailableTeams(teamsQuery.teams);
      setAvailableSkillRoles(skillRolesQuery.skillRoles);

      // Conditional and depends on teams and skill roles to be set
      if (skillProfileQuery) {
        setSkillProfile(skillProfileQuery.skillProfile);
      }
    }
  }

  componentWillUnmount() {
    const {
      store: {skillProfilePage}
    } = this.props;
    skillProfilePage.stepsStore.stopListeningToRouteChange();
    skillProfilePage.reset();
    skillProfilePage.resetFilters();
    skillProfilePage.setTeamId(null);
  }

  setModeBasedOnRouterParams() {
    const {id} = this.props.store.router.params;
    const mode = id === NEW_PATH_PARAM ? CREATE_MODE : EDIT_MODE;
    this.props.store.skillProfilePage.setMode(mode);
    return mode;
  }

  validateAndUpdate = () => {
    const {
      intl: {formatMessage},
      store: {
        skillProfilePage: {validateGeneralTab, goToListPage}
      }
    } = this.props;

    validateGeneralTab(async () => {
      const resultNameAndSkills = await this.updateNameAndSkills();
      const resultTeams = await this.updateTeams();

      if (resultNameAndSkills.success && resultTeams.success) {
        goToListPage();
        notification.success(formatMessage(messages.updateSuccess));
        return;
      }

      if (resultNameAndSkills.error?.message === 'skill_profile_name_already_exists') {
        notification.error(formatMessage(messages.skillProfileAlreadyExists));
        return;
      }

      if (resultNameAndSkills.error || resultTeams.error) {
        notification.error(formatMessage(messages.updateFailure));
      }
    });
  };

  // Strategy selectors based on the mode
  selectStrategy = mode => {
    const strategies = {
      create: this.createSkillProfile,
      edit: this.validateAndUpdate
    };

    return strategies[mode];
  };

  save() {
    this.saveStrategy();
  }

  updateNameAndSkills = () => {
    const {
      updateSkillProfileMutation,
      store: {
        skillProfilePage: {updateNameAndSkills}
      }
    } = this.props;

    return updateNameAndSkills(updateSkillProfileMutation);
  };

  updateTeams = () => {
    const {
      updateSkillProfileTeamsMutation,
      store: {
        skillProfilePage: {updateTeams}
      }
    } = this.props;

    return updateTeams(updateSkillProfileTeamsMutation);
  };

  createSkillProfile = async () => {
    const {
      saveSkillProfileMutation,
      intl: {formatMessage},
      store: {
        skillProfilePage: {createSkillProfile}
      }
    } = this.props;

    const result = await createSkillProfile(saveSkillProfileMutation, {
      successMessage: formatMessage(messages.createSuccess),
      errorMessage: formatMessage(messages.createFailure),
      nameExistsErrorMessage: formatMessage(messages.skillProfileAlreadyExists)
    });
    if (result.success === false && result.error.message === 'skill_profile_name_already_exists') {
      this.setState({
        skillNameExists: true
      });
    }
  };

  render() {
    const {
      intl: {formatMessage},
      skillProfileQuery,
      store
    } = this.props;
    const {skillProfilePage} = store;
    const {stepsStore} = skillProfilePage;

    if (!stepsStore) {
      return null;
    }

    return (
      <Container>
        <ViewTitle
          title={
            <>
              <BackButton onClick={() => skillProfilePage.goToListPage()}>
                <BackIcon />
              </BackButton>
              {formatMessage(skillProfilePage.mode === 'create' ? messages.createSkillProfile : messages.editTitle)}
            </>
          }
        />
        <StepsTabs
          mode={skillProfilePage.mode}
          loading={skillProfileQuery?.loading}
          stepsStore={stepsStore}
          page={skillProfilePage}
          formSubmit={() => this.save()}
          cancel={skillProfilePage.goToListPage}
          getCurrentStepSpecificConfig={skillProfilePage.getCurrentStepSpecificConfig}
          canSubmit={skillProfilePage.generalStepForm.isValid}
        />
      </Container>
    );
  }
}

export default injectIntl(ManageSkillProfileView);
