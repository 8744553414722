export const getMessages = (messages, formatMessage, isChecklist) => ({
  publish: {
    showSuccess: formatMessage(isChecklist ? messages.showChecklistSuccess : messages.showInstructionSuccess),
    showFailure: formatMessage(isChecklist ? messages.showChecklistFailure : messages.showInstructionFailure)
  },
  unpublish: {
    showSuccess: formatMessage(isChecklist ? messages.hideChecklistSuccess : messages.hideInstructionSuccess),
    showFailure: formatMessage(isChecklist ? messages.hideChecklistFailure : messages.hideInstructionFailure)
  },
  icons: {
    clickToShow: messages.clickToShow,
    clickToHide: messages.clickToHide,
    notTranslated: messages.notTranslated
  }
});
