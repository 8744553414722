import styled from 'styled-components';
import {scrollbarWidth} from 'shared/utils/dom-utils';
import {zIndex, headerSize} from 'styles/vars';
import {Popover as $Popover} from 'antd';
import {MenuItem as $MenuItem} from 'styles/styled-components/overlay-components';
import Icon from 'ui-components/Layout/Icon';
import Button from 'ui-components/Button';
import {Link} from 'utils/mobx';
import React from 'react';

const menuButtonBackground = `
  background-color: #F1F1F1;
  border-radius: 100%;
`;

export const Header = styled.div`
  height: ${headerSize}px;
  background: #ffffff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: ${scrollbarWidth}px;
  z-index: ${zIndex.menuBarSticky};
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const Logo = styled.img`
  height: 25px;
`;

export const MenuIcon = styled(Icon)`
  margin-left: 30px;
  cursor: pointer;
`;

export const StyledLogoLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 8rem;
  user-select: none;
  height: 25px;
  &:hover {
    opacity: 0.7;
  }
  margin-left: 20px;
`;

export const Menu = styled.div`
  max-height: 319px;
  ${({isScrollable}) => (isScrollable ? 'overflow-y: scroll;' : '')}
`;

export const MenuItem = styled($MenuItem)`
  text-align: left;
  position: relative;
  font-weight: ${p => (p.selected ? 'normal' : 300)};
  .icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
`;

export const UserName = styled(MenuItem)`
  text-align: left;
  cursor: default;
  font-weight: normal;

  &:hover {
    background: none;
  }
`;

export const HeaderLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  align-items: center;
`;

export const MenuButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  &:focus {
    outline: none;
  }
  &:hover {
    ${menuButtonBackground}
  }
  ${p => p.selected && menuButtonBackground}

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Popover = styled($Popover).attrs({
  overlayClassName: 'c-header__popover',
  placement: 'bottomRight',
  trigger: 'click'
})``;

export const StopImpersonateButton = styled(Button).attrs({})`
  margin-right: 45px;
`;

export const StyledLink = styled.a`
  color: #333330;
  font-size: 32px;
  display: flex;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;

  &:hover {
    color: #333330;
    ${menuButtonBackground}
  }
  &:focus {
    outline: none;
  }
`;
