import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';

import {ToggleWrapper, ToggleSwitch, Icon} from './styles';
import {styleable} from 'shared/decorators';

@observer
@styleable
export default class ToggleComponent extends Component {
  static defaultProps = {
    on: false,
    dataCy: 'checkbox',
    iconId: 'checkmark'
  };

  static propTypes = {
    dataCy: PropTypes.string,
    iconId: PropTypes.string,
    on: PropTypes.bool,
    onChange: PropTypes.func
  };

  render() {
    const {on, onChange, dataCy, iconId} = this.props;

    return (
      <ToggleWrapper on={on} onClick={onChange} data-cy={dataCy}>
        <ToggleSwitch on={on} data-cy={`${dataCy}-${on ? 'on' : 'off'}`}>
          {on && <Icon iconId={iconId} />}
        </ToggleSwitch>
      </ToggleWrapper>
    );
  }
}
