import {action, observable, makeObservable} from 'mobx';

class AddUserToTeam {
  @observable opened = false;
  @observable usersRowCount = 0;

  @action
  reset = () => (this.opened = false);

  @action
  open = () => (this.opened = true);

  @action
  incrementUsersRowCount = maxCountLimit => this.usersRowCount !== maxCountLimit && this.usersRowCount++;

  @action
  decrementUsersRowCount = () => this.usersRowCount >= 1 && this.usersRowCount--;

  @action
  resetUserRowCount = () => (this.usersRowCount = 0);

  constructor() {
    makeObservable(this);
  }
}

export default AddUserToTeam;
