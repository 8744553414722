import styled from 'styled-components';

import Icon from 'ui-components/Icon';

import {commonPalette} from 'shared/styles/palette';

export const BackToCampaignResultsWrapper = styled.div`
  display: flex;
  color: ${commonPalette.txLightGrey};
  align-items: center;
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const BackIcon = styled(Icon).attrs({
  iconId: 'back'
})`
  margin-right: 10px;
`;

export const WrapperText = styled.div`
  font-weight: 300;
`;

export const TitleWrapper = styled.div`
  font-size: 24px;
  font-weight: 300;
`;

export const SectionWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
`;

export const CellLeft = styled.div`
  margin: 7px 0;
  flex: 2;
  font-weight: bold;
`;

export const CellRight = styled.div`
  margin: 7px 0;
  flex: 9;
  font-weight: 300;
`;

export const Row = styled.div`
  display: flex;
`;

export const RowSection = styled.div`
  margin: 20px 0;
`;

export const StyledErrorText = styled.div`
  padding: 0 0 15px 15px;
`;
