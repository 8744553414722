import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  }
});

export default messages;
