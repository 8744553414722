import React, {Component} from 'react';
import {DragLayer} from 'react-dnd';

//components
import ShapeRow from '../ShapeRow';

//styled-components
import {PositionedBox} from './styles';

@DragLayer(monitor => ({
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType()
}))
export default class CustomDragLayer extends Component {
  render() {
    const {currentOffset, isDragging, item, itemType} = this.props;

    if (!currentOffset || !isDragging) {
      return null;
    }

    const {x, y} = currentOffset;
    const {preview} = item;

    const transform = `translate(${x}px, ${y}px)`;

    return (
      <div style={{width: '100%'}}>
        <PositionedBox
          ref={this.inheritParentWidth}
          itemType={itemType}
          style={{
            WebkitTransform: transform,
            transform
          }}
        >
          <ShapeRow design="opaque" shape={preview} shouldCollapse={true} />
        </PositionedBox>
      </div>
    );
  }

  inheritParentWidth = self => {
    if (self instanceof HTMLElement) {
      self.style.width = `${self.parentNode.offsetWidth}px`;
    }
  };
}
