import React, {Component} from 'react';
import {DragLayer} from 'react-dnd';
import {inject} from 'mobx-react';

//styles
import {PositionedBox, StyledStepCard} from './styles';

@inject('store')
@DragLayer(monitor => ({
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType()
}))
export default class CustomDragLayer extends Component {
  render() {
    const {currentOffset, isDragging, item, itemType, store} = this.props;
    const {dragDropSteps: page} = store;

    if (!currentOffset || !isDragging) {
      return null;
    }

    const {x, y} = currentOffset;
    const {step} = item;

    if (!step) {
      return null;
    }

    const index = page.getStepIndex(step.id);

    const transform = `translate(${x}px, ${y}px)`;

    return (
      <div>
        <PositionedBox
          itemType={itemType}
          style={{
            WebkitTransform: transform,
            transform
          }}
        >
          {itemType === 'STEP' && <StyledStepCard index={index} step={step} />}
        </PositionedBox>
      </div>
    );
  }
}
