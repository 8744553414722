import {useState, useEffect} from 'react';

export const usePaginationParameters = (
  refetch: (parameters: {pageIndex: number; sorted: {id: string; desc: boolean}[]}) => unknown
) => {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [sorted, setSorted] = useState<{id: string; desc: boolean}[]>([{id: 'name', desc: false}]);

  useEffect(() => {
    refetch({
      pageIndex,
      sorted
    });
  }, [pageIndex, sorted]);

  // Reset pagination when  sorting changes
  useEffect(() => {
    setPageIndex(0);
  }, [sorted]);

  return {
    pageIndex,
    setPageIndex,
    sorted,
    setSorted: val => setSorted(val)
  };
};
