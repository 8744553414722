import styled from 'styled-components';

//helpers
import flex from 'styles/flex';
import {tableMetrics} from 'styles/metrics';

//components
import $Arrow from 'ui-components/Layout/Arrow';
import $DataTable from 'ui-components/Layout/DataTable';

//constants
const headerGutter = 24;

export const TranslateTags = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  ${flex.horizontal};
  align-items: center;
  margin: 0 0 20px;
  @media (max-width: 720px) {
    width: 90%;
  }
  @media (max-width: 550px) {
    display: block;
  }
`;

export const HeaderLead = styled.div`
  font-weight: bold;
  margin-right: ${headerGutter}px;
`;

export const HeaderButton = styled.button.attrs({
  type: 'button'
})`
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-left: 24px;
  outline: 0;
  padding: 0;
  transition: 0.2s color ease-out;

  &:hover {
    color: rgba(0, 0, 0, 0.85);
    transition: none;
  }
  @media (max-width: 550px) {
    margin-top: 10px;
  }
`;

export const StyledArrow = styled($Arrow).attrs({
  direction: 'right'
})`
  display: block;
  margin: 0 ${headerGutter}px;
  opacity: 0.6;
  overflow: inherit !important;
  @media (max-width: 550px) {
    margin: 5px 80px;
    transform: rotate(90deg);
  }
`;

export const StyledDataTable = styled($DataTable).attrs({
  className: '-cms-design-v2'
})`
  ${flex.vertical};
  max-width: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-height: ${tableMetrics.minHeight}px;

  > .ReactTable {
    max-height: 100%;
    overflow: hidden;
  }
`;

export const TableInputField = styled.input`
  background: none;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }
`;
