import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loadingEditor: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  successMessage: {
    id: 'EditGuide.successMessage',
    defaultMessage: 'The guide has been updated!'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  },
  unknownFailure: {
    id: 'common.notifications.unknownFailure',
    defaultMessage: 'Unknown error happened'
  },
  guidePublishedMessage: {
    id: 'EditGuide.publishSuccess',
    defaultMessage: 'Your guide is now published'
  },
  guidePublishedDocumentsMessage: {
    id: 'EditGuide.guidePublishedDocumentsMessage',
    defaultMessage: 'Your guide is now published and available in Dispatch Document Center.'
  },
  guideUnpublishedMessage: {
    id: 'EditGuide.unpublishSuccess',
    defaultMessage: 'Your guide is now unpublished'
  },
  publishGuideFailure: {
    id: 'EditGuide.publishGuideFailure',
    defaultMessage: 'Error publishing your guide.'
  },
  publishGuideFailureMaxPublishedGuides: {
    id: 'EditGuide.publishGuideFailureMaxPublishedGuides',
    defaultMessage: 'Guides. Oh no! Unfortunately, you can’t publish another guide with your current subscription.'
  },
  publishGuideFailureForbidden: {
    id: 'EditGuide.publishGuideFailureForbidden',
    defaultMessage: "You don't have permissions to publish this guide."
  },
  contactSupport: {
    id: 'EditGuide.contactSupport',
    defaultMessage: 'Please contact support to raise your limit.'
  },
  cloneGuideSuccess: {
    id: 'EditGuide.cloneGuideSuccess',
    defaultMessage: 'Guide duplicated successfully.'
  },
  cloneGuideFailure: {
    id: 'EditGuide.cloneGuideFailure',
    defaultMessage: 'Error cloning guide.'
  },
  archiveGuide: {
    id: 'EditGuide.archiveGuide',
    defaultMessage: 'Remove guide'
  },
  archiveGuideConfirm: {
    id: 'EditGuide.archiveGuideConfirm',
    defaultMessage: 'Are you sure you want to remove your guide ‘{title}’?'
  },
  archiveGuideSuccess: {
    id: 'EditGuide.archiveGuideSuccess',
    defaultMessage: 'Guide has been removed.'
  },
  archiveGuideFailure: {
    id: 'EditGuide.archiveGuideFailure',
    defaultMessage: 'Error removing guide.'
  },
  shareGuide: {
    id: 'EditGuide.shareGuide',
    defaultMessage: 'Share guide'
  },
  reviewSubmitSuccess: {
    id: 'EditGuide.reviewSubmitSuccess',
    defaultMessage: 'Review submitted successfully'
  },
  reviewSubmitFailure: {
    id: 'EditGuide.reviewSubmitFailure',
    defaultMessage: 'Failed to submit review'
  },
  guideUpdatedMessage: {
    id: 'EditGuide.updateSuccess',
    defaultMessage: 'Your guide is now up to date'
  },
  chatMessage: {
    id: 'chatMessagePublishedGuidesLimit',
    defaultMessage:
      'Hi Swipee,\nI’m hitting the limit on our published guides. Could you tell me more on how I can upgrade my platform?'
  },
  backToDraft: {
    id: 'backToDraft',
    defaultMessage: 'BACK TO DRAFT'
  },
  viewVersion: {
    id: 'viewVersion',
    defaultMessage: 'You are viewing VERSION {version}.'
  },
  viewLiveVersion: {
    id: 'viewLiveVersion',
    defaultMessage: 'You are viewing the live version (VERSION {version}).'
  },
  viewVersionUnderApproval: {
    id: 'viewVersionUnderApproval',
    defaultMessage: 'You are viewing the version under approval.'
  },
  viewExpiredLiveVersion: {
    id: 'viewExpiredLiveVersion',
    defaultMessage: 'This Guide version has expired and needs to be re-approved.'
  },
  viewRejectedLiveVersion: {
    id: 'viewRejectedLiveVersion',
    defaultMessage: 'This Guide version has expired. It will be visible to Viewers until a new one is published.'
  }
});

export default messages;
