import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

const strategies = {
  [EVENT_TYPES.REMOVE_IMAGE]: store => () => {
    store.mediaPickerDialog.removeMedia();
    trackEvent(EVENT_TYPES.REMOVE_IMAGE);
  },
  [EVENT_TYPES.EDIT_IMAGE]: store => () => {
    store.mediaPickerDialog.editImage();
    trackEvent(EVENT_TYPES.EDIT_IMAGE);
  },
  [EVENT_TYPES.ADD_SHAPES]: store => () => {
    store.mediaPickerDialog.overlayImage();
    trackEvent(EVENT_TYPES.ADD_SHAPES);
  },
  [EVENT_TYPES.DUPLICATE_IMAGE_WITHOUT_SHAPES]: (store, saveMedia, selectedMediaUrl, editMedia) => () => {
    store.mediaPickerDialog.saveMediaResource(saveMedia, selectedMediaUrl, editMedia);
    store.mediaPickerDialog.setIsUnassignedMedia(true);
    trackEvent(EVENT_TYPES.DUPLICATE_IMAGE_WITHOUT_SHAPES);
  }
};

export default strategies;
