import React, {Component} from 'react';

class MeasureImage extends Component {
  onImgLoad = ({target: img}) => {
    const {onMeasure} = this.props;
    const orgWidth = img.offsetWidth;
    const orgHeight = img.offsetHeight;
    onMeasure({width: orgWidth, height: orgHeight});
  };
  render() {
    const {src} = this.props;
    return (
      <div
        style={{
          overflow: 'hidden',
          height: 0,
          width: 0,
          position: 'relative'
        }}
      >
        <img src={src} alt={'hidden'} onLoad={this.onImgLoad} />
      </div>
    );
  }
}
export default MeasureImage;
