import React from 'react';
import {inject, Observer} from 'mobx-react';
import Select from 'ui-components/Select';
import {onChangeLanguageToTranslate, getLanguagesOptions} from './utils';
import messages from './messages';

import {Content} from './styles';

const SelectLanguageDialogContent = ({store, formatMessage}) => {
  const languagesOptions = getLanguagesOptions(store, formatMessage);
  const onChange = value => onChangeLanguageToTranslate(value, store);

  return (
    <Observer>
      {() => (
        <>
          <Content>{formatMessage(messages.translateGuideInto)}</Content>
          <Select
            placeholder={formatMessage(messages.chooseLanguage)}
            selectedValue={store.autoTranslationDialog.selectedLanguageToTranslate}
            onChange={onChange}
            options={languagesOptions}
            defaultValue={formatMessage(messages.chooseLanguage)}
          />
        </>
      )}
    </Observer>
  );
};

export default inject('store')(SelectLanguageDialogContent);
