export const DND_TYPE = 'TOPIC';

export const source = {
  beginDrag: (props, monitor, component) => {
    const {onBeginDrag, topic} = props;

    if (typeof onBeginDrag === 'function') {
      const instance = component.getDecoratedComponentInstance();
      onBeginDrag(instance.dragHandleElement);
    }

    return {
      preview: topic,
      sourceId: topic.id
    };
  },
  endDrag: (props, monitor, component) => {
    const {onEndDrag, repositionTopicsAndInstructionsMutation, store} = props;
    const {dragDropItems: page} = store;

    if (typeof onEndDrag === 'function') {
      const instance = component.getDecoratedComponentInstance();
      onEndDrag(instance.dragHandleElement);
    }

    page.submit(repositionTopicsAndInstructionsMutation);
  },
  canDrag: props => !props.store.dragDropItems.submitting
};

export const target = {
  hover: (props, monitor) => {
    const {store, topic} = props;
    const {dragDropItems: page} = store;

    const item = monitor.getItem();
    const {sourceId} = item;

    if (sourceId !== topic.id) {
      page.moveTopic(sourceId, topic.id);
    }
  }
};
