import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import ButtonGroup from 'ui-components/ButtonGroup';
import Text from 'ui-components/Layout/Text';

//styled-components
import {Top, Content, RadioButton, RadioLabel, RadioOption} from './styles';

import {Panel} from 'styles/styled-components/shared-components';

// queries
import {TagsList} from 'api/tag/queries';

//mutations
import {MergeTags} from 'api/tag/mutations';

//options
import {mergeTagsOptions} from 'api/tag/mutation-options';
import {tagsListOptions} from 'api/tag/query-options';

//components
import Field from 'ui-components/Field';
import TagPicker from 'components/TagPicker';
import {FormattedMessage} from 'components/FormattedComponents';
import LanguagePicker from 'components/LanguagePicker';
import FormContainer from 'ui-components/Layout/FormContainer';

//messages
import messages from './messages';
import DataTable from '../../ui-components/Layout/DataTable';

@inject('store')
@graphql(MergeTags, mergeTagsOptions)
@graphql(TagsList, tagsListOptions)
@observer
class MergeTagView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store: {tagManagementPage}
    } = this.props;

    const translations = mapValues(pick(messages, ['mergeSuccess', 'mergeFailure']), message => formatMessage(message));

    tagManagementPage.setUiTranslations(translations);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: {tags},
      store: {tagManagementPage}
    } = nextProps;
    tagManagementPage.setTags(tags);
  }

  componentDidUpdate() {
    const {
      store: {
        tagManagementPage: {availableLocales, setDefaultLocale}
      }
    } = this.props;
    if (availableLocales.length < 2) {
      setDefaultLocale(availableLocales[0]);
    }
  }

  componentWillUnmount() {
    const {tagManagementPage} = this.props.store;
    tagManagementPage.reset();
    tagManagementPage.setLocalSelectedTags([]);
  }

  render() {
    const {
      intl: {formatMessage},
      store: {tagManagementPage},
      mergeTagsMutation
    } = this.props;
    const {
      form,
      setDefaultLocale,
      availableLocales,
      defaultLocale,
      updateMergedTagContent,
      setSelectedTags,
      selectedTagsIds,
      selectedTagsData,
      showDefaultLanguagePicker,
      goToTagsList,
      disableMerge,
      availableLanguages,
      localSelectedTags,
      setLocalSelectedTags
    } = tagManagementPage;

    return (
      <FormContainer>
        <ViewTitle title={formatMessage(messages.title)} />
        <Panel>
          <Top>
            <Content>
              <Field
                size="small"
                title={formatMessage(messages.name)}
                className="tag-selection"
                inputComponent={
                  <TagPicker
                    className="tag-picker"
                    selectedTagsIds={selectedTagsIds}
                    setSelectedTags={setSelectedTags}
                    locale={form.values().defaultLocale}
                    disableCreate={true}
                    canMergeBadges={false}
                    localSelectedTags={localSelectedTags}
                    setLocalSelectedTags={setLocalSelectedTags}
                  />
                }
              />
              {showDefaultLanguagePicker && (
                <Content>
                  <Text className="tag-description" opacity={0.4}>
                    <FormattedMessage {...messages.description} />
                  </Text>
                  <DataTable
                    className="merge-tags-table"
                    data={selectedTagsData}
                    columns={[
                      {
                        Header: 'Language',
                        accessor: 'language',
                        sortable: false
                      },
                      {
                        Header: 'First Tag',
                        accessor: 'firstTag',
                        Cell: row => (
                          <div>
                            {row.value !== '-' ? (
                              <RadioOption>
                                <RadioButton
                                  name={row.index}
                                  value={row.value}
                                  type="radio"
                                  onChange={() => updateMergedTagContent(row)}
                                />
                                <RadioLabel>&nbsp;&nbsp;{row.value}</RadioLabel>
                              </RadioOption>
                            ) : (
                              row.value
                            )}
                          </div>
                        ),
                        sortable: false
                      },
                      {
                        Header: 'Second Tag',
                        accessor: 'secondTag',
                        Cell: row => (
                          <div>
                            {row.value !== '-' ? (
                              <RadioOption>
                                <RadioButton
                                  name={row.index}
                                  value={row.value}
                                  type="radio"
                                  onChange={() => updateMergedTagContent(row)}
                                />
                                <RadioLabel>&nbsp;&nbsp;{row.value}</RadioLabel>
                              </RadioOption>
                            ) : (
                              row.value
                            )}
                          </div>
                        ),
                        sortable: false
                      }
                    ]}
                  />
                  {availableLocales.length > 1 && (
                    <Field
                      size="small"
                      className="tag-default-language"
                      inputComponent={
                        <LanguagePicker
                          allowClear
                          changeLanguage={setDefaultLocale}
                          languages={availableLanguages}
                          selectedLanguage={defaultLocale}
                        />
                      }
                    />
                  )}
                </Content>
              )}
            </Content>
          </Top>

          <ButtonGroup
            line
            primary={{
              label: formatMessage(messages.save),
              onClick: () => tagManagementPage.mergeTags({mergeTagsMutation}),
              disabled: disableMerge
            }}
            secondary={{
              label: formatMessage(messages.cancel),
              onClick: goToTagsList
            }}
          />
        </Panel>
      </FormContainer>
    );
  }
}

export default injectIntl(MergeTagView);
