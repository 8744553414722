import {defineMessages} from 'react-intl';

const messages = defineMessages({
  translating: {
    id: 'EditTranslation.translating',
    defaultMessage: 'Translating'
  },
  noTranslation: {
    id: 'EditTranslation.noTranslation',
    defaultMessage: 'No translation'
  },
  defaultLocale: {
    id: 'EditTranslation.defaultLocaleDescription',
    defaultMessage: 'This is your default locale. You can change it in Edit Guide.'
  },
  sourceText: {
    id: 'EditTranslation.sourceText',
    defaultMessage: 'Source text'
  },
  translation: {
    id: 'EditTranslation.translation',
    defaultMessage: 'Translation'
  },
  import: {
    id: 'common.actions.import',
    defaultMessage: 'Import'
  },
  importSuccess: {
    id: 'EditTranslations.importSuccess',
    defaultMessage: 'Translations have been imported.'
  },
  importFailure: {
    id: 'EditTranslations.importFailure',
    defaultMessage: 'Failed to upload new translations.'
  },
  export: {
    id: 'common.actions.export',
    defaultMessage: 'Export'
  },
  manageTranslations: {
    id: 'manageTranslations',
    defaultMessage: 'Manage translations'
  }
});

export default messages;
