import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';
import views from 'config/views';
import mutationNames from 'api/user/constants';
import {graphql} from '@apollo/client/react/hoc/graphql';

//mutations
import {CreateUser} from 'api/user/mutations';

//options
import {createUserOptions} from 'api/user/mutation-options';

//helpers
import {getShowIntercom} from 'utils/intercom-utils';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import StepsTabs from 'components/StepsTabs';
import Container from 'ui-components/Layout/Container';
import MaxEditorsErrorNotificationContent from 'components/MaxEditorsErrorNotificationContent';

//messages
import messages from './messages';

@inject('store')
@graphql(CreateUser, createUserOptions)
@observer
class NewUserView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      newUserPage,
      router,
      platform,
      app: {isActionMenuOpen}
    } = store;
    const {canManageUsers} = user;

    if (!canManageUsers) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, [
        'createSuccess',
        'createFailure',
        'addNewUserToTeam',
        'createNewUser',
        'passwordMustHave8Characters',
        'passwordMustHaveAtLeast1Number',
        'passwordMustHaveAtLeast1UppercaseLetter',
        'passwordMustHaveAtLeast1LowercaseLetter',
        'passwordMustHaveAtLeast1NonAlphanumericCharacter'
      ]),
      message => formatMessage(message)
    );

    const createFailureMaxEditors = (
      <MaxEditorsErrorNotificationContent
        showIntercom={getShowIntercom({platform, isActionMenuOpen})}
        formattedMessages={{
          contactSupport: formatMessage(messages.contactSupport),
          publishGuideFailureMaxPublishedGuides: formatMessage(messages.createFailureMaxEditors),
          chatMessage: formatMessage(messages.chatMessage)
        }}
      />
    );

    newUserPage.setTranslations({
      ...translations,
      createFailureMaxEditors
    });
    newUserPage.setSteps(false);
    newUserPage.stepsStore.startListeningToRouteChange();
  }

  componentWillUnmount() {
    const {
      store: {newUserPage}
    } = this.props;
    newUserPage.reset();
    newUserPage.stepsStore.stopListeningToRouteChange();
  }

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const createUserMutation = this.props[mutationNames.CREATE_USER];
    const {newUserPage} = store;
    const goToUsersList = () => store.router.goTo(views.users, {});
    const {stepsStore} = newUserPage;
    const formSubmit = () =>
      newUserPage.submit({
        createUserMutation,
        goToUsersList
      });
    const loading = false;
    const error = false;
    return (
      <Container>
        <ViewTitle title={formatMessage(messages.createUser)} />
        <StepsTabs
          mode={'create'}
          loading={loading}
          error={error}
          stepsStore={stepsStore}
          page={newUserPage}
          formSubmit={formSubmit}
          cancel={() => newUserPage.reset(goToUsersList)}
          customPrimaryButtonText={
            newUserPage.stepsStore.currentStepIndex === 1 && formatMessage(messages.createAndInvite)
          }
        />
      </Container>
    );
  }
}

export default injectIntl(NewUserView);
