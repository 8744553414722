import {generateGuideOverview} from './guideOverview';
import {generateTopics} from './topics';

export const generateContent = async (guide, pdfMessages, guideBaseUrl, platformName, intl) => {
  const guideOverview = await generateGuideOverview(guide, pdfMessages, platformName, guideBaseUrl);
  const tableOfContents = {
    toc: {
      title: {text: pdfMessages.toc, fontSize: 30, margin: [7, 25, 0, 20]},
      numberStyle: {fontSize: 11}
    },
    margin: [15, 0, 25, 0]
  };
  const topicsContent = await generateTopics(guide, pdfMessages, guideBaseUrl, intl);

  return [...guideOverview, tableOfContents, ...topicsContent];
};
