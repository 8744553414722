import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import views from 'config/views';

//styled-components
import {ActionMenu, MainActionsWrapper, MainActionButton, SecondaryActionButton} from './styles';
import DropdownActionMenu from './DropdownActionMenu';

import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@observer
class ActionMenuComponent extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get activeActions() {
    const {
      store: {app}
    } = this.props;
    const {actionMenuItems} = app;

    return actionMenuItems.filter(action => !action.disabled);
  }

  @computed get mainAction() {
    const {
      store: {app}
    } = this.props;
    if (this.activeActions.length && this.activeActions.length < 2) return this.activeActions[0];

    return app.isActionMenuOpen ? {id: 'close'} : {id: 'menu'};
  }

  @computed get mainActionIcon() {
    return this.mainAction ? this.mainAction.id : null;
  }

  @computed get secondaryAction() {
    if (this.activeActions.length && this.activeActions.length < 3) {
      return this.activeActions[1];
    }

    return null;
  }

  @computed get secondaryActionIcon() {
    return this.secondaryAction ? this.secondaryAction.id : null;
  }

  @computed get dropdownActions() {
    return this.activeActions.length > 1 ? this.activeActions : [];
  }

  goTo = (view, params = {}) => {
    const {store} = this.props;
    const {router, app} = store;

    router.goTo(views[view], params);
    app.toggleActionMenu(false);
  };

  onClick = action => {
    const {store} = this.props;
    const {
      app: {toggleActionMenu}
    } = store;

    const {view, params = {}, onClick} = action;

    if (!view && !onClick && action.id === 'menu') {
      return toggleActionMenu(false);
    }

    if (view) this.goTo(view, params);

    if (onClick) {
      onClick();
      toggleActionMenu(false);
    }

    trackEvent(EVENT_TYPES.CHECKLISTS_EXPORT);
  };

  handleClickOutside = () => {
    const {
      store: {app}
    } = this.props;
    if (app.isActionMenuOpen) app.toggleActionMenu();
  };

  render() {
    const {
      store: {app}
    } = this.props;
    const {isActionMenuOpen} = app;

    if (!this.mainAction || !this.activeActions.length) return null;

    const shouldSpin = Boolean(this.mainAction.isSpinning);

    if (!this.dropdownActions.length)
      return (
        <ActionMenu>
          <MainActionsWrapper>
            <MainActionButton
              shouldSpinIcon={shouldSpin}
              iconId={this.mainActionIcon}
              onClick={() => this.onClick(this.mainAction)}
              secondary={this.mainAction.secondary}
              aria-label={this.mainAction.view}
            />
            {this.secondaryAction && (
              <SecondaryActionButton
                iconId={this.secondaryActionIcon}
                onClick={() => this.onClick(this.secondaryAction)}
              />
            )}
          </MainActionsWrapper>
        </ActionMenu>
      );

    return (
      <DropdownActionMenu
        mainAction={this.mainAction}
        toggle={app.toggleActionMenu}
        expand={isActionMenuOpen}
        actions={this.dropdownActions}
        onClick={this.onClick}
        handleClickOutside={this.handleClickOutside}
      />
    );
  }
}

export default ActionMenuComponent;
