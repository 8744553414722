import {defineMessages} from 'react-intl';

const messages = defineMessages({
  rejectVersion: {
    id: 'RejectVersion.rejectVersion',
    defaultMessage: 'Reject this version'
  },
  bodyMessage: {
    id: 'RejectVersion.bodyMessage',
    defaultMessage: "If you wish you can add feedback for your team's editors."
  },
  rejectAndSendFeedback: {
    id: 'RejectVersion.rejectAndSendFeedback',
    defaultMessage: 'Reject and send feedback'
  },
  placeholder: {
    id: 'RejectVersion.placeholder',
    defaultMessage: 'This feedback goes to the activity log'
  }
});

export default messages;
