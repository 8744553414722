import gql from 'graphql-tag';

export const CreateSkillRole = gql`
  mutation createSkillRole($name: String!) {
    createSkillRole(name: $name) {
      id
      name
    }
  }
`;

export const EditSkillRole = gql`
  mutation editSkillRole($skillRole: SkillRoleEdit!) {
    editSkillRole(skillRole: $skillRole) {
      id
      name
    }
  }
`;
