import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {CampaignToDuplicate} from 'api/campaign/queries';
import {CreateCampaign} from 'api/campaign/mutations';
import {campaignToDuplicateOptions} from 'api/campaign/query-options';
import {createCampaignOptions} from 'api/campaign/mutation-options';

import {FormattedMessage} from 'components/FormattedComponents';

import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuDivider
} from 'styles/styled-components/dropdown-styled-components';

import messages from './messages';

@inject('store')
@graphql(CampaignToDuplicate, campaignToDuplicateOptions)
@graphql(CreateCampaign, createCampaignOptions)
@observer
class CampaignMenuComponent extends Component {
  constructor(props) {
    super(props);
    this.duplicateCampaign = this.duplicateCampaign.bind(this);
  }
  componentWillReceiveProps = nextProps => {
    const {
      store: {
        campaignsPage: {setCampaignToDuplicate}
      },
      data: {campaign}
    } = nextProps;

    if (campaign) {
      setCampaignToDuplicate(campaign);
    }
  };

  duplicateCampaign() {
    const {
      store: {
        campaignsPage: {duplicateCampaign, campaignToDuplicate}
      },
      createCampaignMutation
    } = this.props;

    duplicateCampaign(createCampaignMutation, campaignToDuplicate);
  }

  render() {
    const {
      isDeactivated,
      isActivated,
      isEditable,
      onEdit,
      onDeactivate,
      onFrequencySettings,
      onTranslate,
      onVisibility,
      data: {loading}
    } = this.props;

    const showVisibility = isActivated;
    const showFirstDelimter = isActivated;
    const showEdit = isEditable;
    const showTranslate = !isDeactivated;
    const showSecondDelimiter = !isDeactivated;
    const showFrequencySettings = !isDeactivated;
    const showDuplicate = !loading;
    const showThirdDelimiter = !isDeactivated;
    const showDeactivate = !isDeactivated;

    return (
      <DropdownMenu {...this.props}>
        {showVisibility && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={onVisibility}>
              <FormattedMessage {...messages.visibility} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showFirstDelimter && <DropdownMenuDivider />}
        {showEdit && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={onEdit}>
              <FormattedMessage {...messages.edit} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showTranslate && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={onTranslate}>
              <FormattedMessage {...messages.translate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showSecondDelimiter && <DropdownMenuDivider />}
        {showFrequencySettings && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={onFrequencySettings}>
              <FormattedMessage {...messages.frequencySettings} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showDuplicate && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={this.duplicateCampaign}>
              <FormattedMessage {...messages.duplicate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
        {showThirdDelimiter && <DropdownMenuDivider />}
        {showDeactivate && (
          <DropdownMenuItem>
            <DropdownMenuButton onClick={onDeactivate}>
              <FormattedMessage {...messages.deactivate} />
            </DropdownMenuButton>
          </DropdownMenuItem>
        )}
      </DropdownMenu>
    );
  }
}

export default injectIntl(CampaignMenuComponent);
