import {TranslationRate} from 'api/translations/queries';
import {AddTranslationsToGuide, UpdateGuide} from 'api/guide/mutations';
import {get} from 'lodash';
import {action, observable, makeObservable} from 'mobx';
import {client} from 'utils/apollo-client';
import Raven from 'raven-js';
import {AutoTranslateGuide} from 'api/translations/mutations';
import notification from 'utils/notification-utils';
import messages from 'components/AutoTranslationDialog/translation-error/messages';
import store from 'stores/store';

export const AUTO_TRANSLATE_DIALOGS_MAP = {
  translateGuide: 'translateGuide',
  selectLanguage: 'selectLanguage',
  addNewLanguage: 'addNewLanguage',
  translationInProgress: 'translationInProgress',
  translationSuccess: 'translationSuccess',
  translationError: 'translationError'
};

export const ERRORS_TYPE_MAP = {
  errorTranslatingGuide: 'errorTranslatingGuide',
  errorFetchingLanguages: 'errorFetchingLanguages',
  errorAddingNewLanguage: 'errorAddingNewLanguage'
};

class AutoTranslationDialog {
  @observable opened = false;
  @observable currentDialog = undefined;
  @observable selectedLanguageToTranslate = undefined;
  @observable selectedLanguageToAdd = undefined;
  @observable translationsForGuide = [];
  @observable guideId = undefined;
  @observable loading = false;
  @observable defaultLocale = undefined;

  @action
  reset = () => {
    this.opened = false;
    this.setCurrentDialog(undefined);
    this.setSelectedLanguageToTranslate(undefined);
    this.setSelectedLanguageToAdd(undefined);
    this.setTranslationsForGuide([]);
    this.setGuideId(undefined);
    this.setLoading(false);
    this.setDefaultLocale(undefined);
  };

  @action open = () => {
    this.opened = true;
    this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translateGuide);
  };

  @action setCurrentDialog = dialog => {
    this.currentDialog = dialog;
  };

  @action setSelectedLanguageToTranslate = language => {
    this.selectedLanguageToTranslate = language;
  };

  @action setSelectedLanguageToAdd = language => {
    this.selectedLanguageToAdd = language;
  };

  @action setTranslationsForGuide = translations => {
    this.translationsForGuide = translations;
  };

  @action setGuideId = guideId => {
    this.guideId = guideId;
  };

  @action setLoading = loading => {
    this.loading = loading;
  };

  @action setDefaultLocale = defaultLocale => {
    this.defaultLocale = defaultLocale;
  };

  @action fetchTranslationRate = async guideId => {
    this.setLoading(true);
    let result;
    try {
      result = await client.query({
        query: TranslationRate,
        variables: {id: guideId},
        fetchPolicy: 'network-only'
      });
    } catch (error) {
      Raven.captureException(error);
    }
    if (get(result, 'data.guideDetails.translationRate')) {
      this.setTranslationsForGuide(result.data.guideDetails.translationRate);
    } else {
      this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translationError);
      this.setTranslationsForGuide([]);
    }
    this.setLoading(false);
  };

  @action onAddTranslationToGuide = async languageToAdd => {
    if (!languageToAdd) return;
    this.setLoading(true);
    let result;
    try {
      result = await client.mutate({
        mutation: AddTranslationsToGuide,
        variables: {
          id: this.guideId,
          translations: [languageToAdd]
        }
      });
    } catch (error) {
      Raven.captureException(error);
    }
    if (get(result, 'data.addTranslationsToGuide.result')) {
      await this.fetchTranslationRate(this.guideId);
      this.setSelectedLanguageToTranslate(languageToAdd);
      this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.selectLanguage);
      this.setSelectedLanguageToAdd(undefined);
    } else {
      this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translationError);
      this.setSelectedLanguageToAdd(undefined);
    }
    this.setLoading(false);
  };

  @action startAutoTranslation = async () => {
    this.setLoading(true);
    this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translationInProgress);
    let result;
    try {
      result = await client.mutate({
        mutation: AutoTranslateGuide,
        variables: {
          id: this.guideId,
          targetLocales: [this.selectedLanguageToTranslate]
        }
      });
    } catch (error) {
      Raven.captureException(error);
    }
    if (get(result, 'data.autoTranslateGuide.result')) {
      this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translationSuccess);
      this.trackAutoTranslationSuccess();
    } else {
      this.setCurrentDialog(AUTO_TRANSLATE_DIALOGS_MAP.translationError);
    }
    this.setLoading(false);
  };

  @action trackAutoTranslationSuccess = async () => {
    const action = 'auto-translation-success';
    const objectsInfo = {guideId: this.guideId};
    await store.analytics.trackActionEvent({
      action,
      objectsInfo
    });
  };

  @action updateDefaultLanguage = async (currentDefaultLocale, localeToSetAsDefault, formatMessage) => {
    this.setLoading(true);
    let result;
    let translatedLanguage;
    try {
      translatedLanguage = this.translationsForGuide.find(
        translation => translation.locale === localeToSetAsDefault
      ).language;
      result = await client.mutate({
        mutation: UpdateGuide,
        variables: {
          id: this.guideId,
          guide: {
            defaultLocale: localeToSetAsDefault,
            locale: currentDefaultLocale
          }
        },
        refetchQueries: ['draftGuideForCms', 'getDomainsForGuide', 'guideForCms']
      });
    } catch (error) {
      Raven.captureException(error);
    }
    if (!get(result, 'data.updateGuide.id')) {
      notification.error(formatMessage(messages.cannotReviewGuideInLanguage, {translatedLanguage}));
    }
    this.reset();
    this.setLoading(false);
  };

  @action getErrorType = () => {
    if (this.selectedLanguageToTranslate) {
      return ERRORS_TYPE_MAP.errorTranslatingGuide;
    }
    if (this.selectedLanguageToAdd) {
      return ERRORS_TYPE_MAP.errorAddingNewLanguage;
    }
    return ERRORS_TYPE_MAP.errorFetchingLanguages;
  };

  constructor() {
    makeObservable(this);
  }
}

export default AutoTranslationDialog;
