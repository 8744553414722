import axios from 'axios';

const messageMap = {
  MIN_LENGTH_OF_8: 'passwordMustHave8Characters',
  ONE_UPPERCASE: 'passwordMustHaveAtLeast1UppercaseLetter',
  ONE_LOWERCASE: 'passwordMustHaveAtLeast1LowercaseLetter',
  ONE_DIGIT: 'passwordMustHaveAtLeast1Number',
  ONE_NON_ALPHANUMERIC: 'passwordMustHaveAtLeast1NonAlphanumericCharacter'
};

const getLocalisedMessage = ({validationError: {code, message}, translations}) => {
  const translationsKey = messageMap[code];
  const translation = translationsKey && translations[translationsKey];
  return translation || message;
};

export const getPasswordComplexityChecker =
  (translations = {}) =>
  async (password, passes) => {
    const query = `#graphql
    query passwordValidation {
      passwordValidation(password: "${password}") {
        isValid
        errors {
          code
          message
        }
      }
    }
  `;
    const result = await axios.post('/graphql', {
      query,
      variables: '{}'
    });

    const {
      data: {
        data: {
          passwordValidation: {isValid, errors}
        }
      }
    } = result;

    const localisedMessage = !isValid ? getLocalisedMessage({validationError: errors[0], translations}) : null;

    passes(isValid, localisedMessage);
  };
