import styled from 'styled-components';
import Button from 'ui-components/Button';
import {Field} from 'shared/components/Field/styles';
import {Error} from 'shared/styles/styled-components/field-styled-components';
import Icon from 'ui-components/Icon';
import {commonPalette} from 'shared/styles/palette';

export const SkillLevelContainer = styled.div`
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px #00000026;
  margin-bottom: 1rem;
  width: 60%;
  display: flex;
`;

export const SkillLevelLabel = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: white;
  border-radius: 3px;
  margin-right: 1rem;
  background: ${props => (!props.showNumbers && props.position === 0 ? commonPalette.newRed : commonPalette.sgGreen)};
`;

export const SkillLevelName = styled.div`
  flex: 1;
`;

export const SkillLevelDescription = styled.div`
  flex: 1;
  color: ${commonPalette.txGrey};
`;

export const SkillLevelEditIcon = styled.div`
  width: 30px;
`;

export const StyledButton = styled(Button)`
  height: 36px;
  float: right;
`;

export const SkillLevelEditWrapper = styled.div`
  display: block;
  width: 100%;

  ${Field} {
    width: 100%;
    margin-bottom: 8px;
  }

  ${Error} {
    height: auto;
  }
`;

export const SkillLevelReadWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const SkillLevelDetailsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledIcon = styled(Icon)`
  color: ${commonPalette.txGrey};
`;

export const StyledLevelIcon = styled(Icon)``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const Label = styled.p`
  color: ${commonPalette.txBlack};
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
`;
