import gql from 'graphql-tag';

export const SkillsPaginated = gql`
  #graphql
  query skillsPaginated($offset: Int!, $limit: Int, $sortBy: [SkillsSortBy!], $filters: SkillFilters) {
    skillsPaginated(offset: $offset, limit: $limit, sortBy: $sortBy, filters: $filters) {
      results {
        id
        name
        expirationMonths
        skillTasks {
          totalCount
        }
        tags {
          title
        }
        skillProfilesCount
      }
      totalCount
    }
  }
`;

export const SkillLevels = gql`
  query skillLevels {
    skillLevels {
      id
      name
      position
      description
    }
  }
`;

export const SkillProfile = gql`
  query skillProfile($id: String!) {
    skillProfile(id: $id) {
      id
      name
      skills {
        id
        name
        requiredLevel {
          id
          name
          position
        }
      }
      teams {
        teamId
        skillRoleIds
      }
    }
  }
`;

export const SkillProfiles = gql`
  query skillProfiles {
    skillProfiles {
      id
      name
      created_at
      teams
      roles
      skills {
        id
        name
        expirationMonths
        requiredLevel {
          position
          name
        }
        expiresAt
      }
    }
  }
`;

export const AllSkillTasks = gql`
  query allSkillTasksPaginated($offset: Int!, $limit: Int, $sortBy: [SkillTaskSortBy!], $filters: SkillTaskFilters) {
    allSkillTasksPaginated(offset: $offset, limit: $limit, sortBy: $sortBy, filters: $filters) {
      results {
        id
        title
        skillProfilesCount
        skillCount
        tags {
          title
        }
      }
      totalCount
    }
  }
`;

export const SkillTasks = gql`
  query skillTasks {
    skillTasks {
      id
      title
      content
      resourceUrl
      resourceType
    }
  }
`;

export const SkillTaskById = gql`
  query skillTask($id: String!) {
    skillTask(id: $id) {
      id
      title
      content
      resourceUrl
      resourceType
      skillCount
      skillProfilesCount
      tags {
        id
        title
      }
    }
  }
`;

export const Skill = gql`
  query skill($id: String!) {
    skill(id: $id) {
      id
      name
      expirationMonths
      skillTasks {
        id
        title
      }
      tags {
        id
      }
      skillProfilesCount
    }
  }
`;

export const SkillProfilesBySkillId = gql`
  query skillProfilesBySkillId($skillId: String!) {
    skillProfilesBySkillId(skillId: $skillId) {
      id
      name
    }
  }
`;

export const JobTitles = gql`
  query skillRoles {
    skillRoles {
      id
      name
    }
  }
`;

export const SkillPaginated = gql`
  query skillsPaginated($offset: Int, $limit: Int, $sortBy: [SkillsSortBy!], $filters: SkillFilters) {
    skillsPaginated(offset: $offset, limit: $limit, sortBy: $sortBy, filters: $filters) {
      totalCount
      limit
      results {
        id
        name
      }
    }
  }
`;

export const SkillTasksPaginated = gql`
  query allSkillTasksPaginated($offset: Int, $limit: Int, $sortBy: [SkillTaskSortBy!], $filters: SkillTaskFilters) {
    allSkillTasksPaginated(offset: $offset, limit: $limit, sortBy: $sortBy, filters: $filters) {
      totalCount
      results {
        id
        title
        content
        resourceUrl
        resourceType
      }
    }
  }
`;

export const SkillProfilesPaginated = gql`
  query skillProfilesPaginated(
    $offset: Int!
    $limit: Int
    $sortBy: [SkillProfilesSortBy!]
    $filters: SkillProfilesFilters
  ) {
    skillProfilesPaginated(offset: $offset, limit: $limit, sortBy: $sortBy, filters: $filters) {
      results {
        id
        name
        skills {
          totalCount
        }
        teams {
          totalCount
        }
        rolesCount
      }
      totalCount
    }
  }
`;

export const SkillsSkillProfilesPaginated = gql`
  query skillsSkillProfiles($offset: Int!, $limit: Int, $filters: SkillsSkillProfilesFilters) {
    skillsSkillProfiles(offset: $offset, limit: $limit, filters: $filters) {
      results {
        id
        skill {
          id
          name
          expirationMonths
        }
        requiredLevel {
          id
          name
          position
        }
      }
      totalCount
    }
  }
`;

export const DeleteSkillTask = gql`
  mutation deleteSkillTask($id: String!) {
    deleteSkillTask(id: $id) {
      result
      error
    }
  }
`;
