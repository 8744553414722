import {client} from 'utils/apollo-client';

export const campaignOptions = {
  options: ({store: {router}}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        campaignId: router.params.id
      }
    };
  }
};

export const campaignsOptions = {
  options: {
    fetchPolicy: 'network-only'
  }
};

export const campaignsLiteOptions = {
  name: 'campaignsLiteQuery',
  options: {
    fetchPolicy: 'network-only'
  }
};

export const campaignReportOptions = {
  name: 'campaignReportQuery',
  options: ({store: {campaignsPage, platform, router}}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        campaignId: router.params.id,
        instructionId: campaignsPage.currentInstructionId,
        locale: platform.getLanguage()
      }
    };
  }
};

export const campaignToDuplicateOptions = {
  options: ({store}) => {
    const {
      campaignsPage: {currentCampaignIdMenuOpen: campaignId},
      platform: {getLanguage}
    } = store;
    const locale = getLanguage();

    return {
      client,
      fetchPolicy: 'network-only',
      variables: {
        campaignId,
        locale
      }
    };
  }
};

export const campaignFeedbackOptions = {
  name: 'campaignFeedbackQuery',
  options: ({store: {campaignsPage, router}}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        campaignId: router.params.id,
        instructionId: campaignsPage.currentInstructionId
      }
    };
  }
};

export const campaignsSettingsOptions = {
  options: {
    fetchPolicy: 'network-only'
  }
};

export const campaignGuidesOptions = {
  name: 'campaignGuidesQuery',
  options: ({store: {router}}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        id: router.params.id
      }
    };
  }
};

export const campaignAnswersOptions = {
  name: 'campaignAnswersQuery',
  options: ({customFilterPayload}) => {
    return {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        ...customFilterPayload
      }
    };
  }
};

export const campaignAnswerOptions = {
  name: 'campaignAnswerQuery',
  options: ({store: {router}}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        id: router.params.resultId
      }
    };
  }
};
