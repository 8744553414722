import {refreshTagsLiteList} from 'api/tag/refresh-queries';

export const archiveTagOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    archiveTagMutation: variables => {
      const locale = store.platform.getLanguage();
      return mutate({
        variables,
        refetchQueries: [refreshTagsLiteList(locale)]
      });
    }
  })
};

export const createTagOptions = {
  props: ({mutate}) => ({
    createTagMutation: variables => {
      variables.tag.locale = variables.tag.defaultLocale;
      return mutate({
        variables
      });
    }
  })
};

export const updateTagOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    updateTagMutation: variables => {
      const locale = store.platform.getLanguage();
      variables.tag.locale = variables.tag.defaultLocale;
      return mutate({
        variables,
        refetchQueries: [refreshTagsLiteList(locale)]
      });
    }
  })
};

export const mergeTagsOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    mergeTagsMutation: variables => {
      const locale = store.platform.getLanguage();
      return mutate({
        variables,
        refetchQueries: [refreshTagsLiteList(locale)]
      });
    }
  })
};
