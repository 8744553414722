import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import {observable, action, makeObservable} from 'mobx';
import {injectIntl} from 'react-intl';

import Dialog from 'ui-components/Dialog';
import NewTeamUsersView from 'views/NewTeamUsersView';

import mutationNames from 'api/team/constants';

import messages from './messages';
import {StyledInfoText, UsersWrapper} from './styles';

// queries
import {EnabledUsersLite} from 'api/user/queries';
import {Team} from 'api/team/queries';

//mutations
import {CreateTeamUsers} from 'api/team/mutations';

//options
import {userNamesOptions} from 'api/user/query-options';
import {teamOptions} from 'api/team/query-options';
import {createTeamUsersOptions} from 'api/team/mutation-options';

@inject('store')
@graphql(CreateTeamUsers, createTeamUsersOptions)
@graphql(EnabledUsersLite, userNamesOptions)
@graphql(Team, teamOptions)
@observer
class AddUserToTeamDialog extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store,
      userNamesQuery,
      data: {team, loading}
    } = nextProps;
    const {enabledUsers: users = []} = userNamesQuery;
    const {teamPage} = store;
    if (!loading && users && team) {
      const {teamMembers} = team;
      const existingIds = teamMembers.map(member => member.user.id);
      const availableUsers = users.filter(user => !existingIds.includes(user.id));
      teamPage.setUsers(availableUsers);
      teamPage.setTeamForm(team);
    }
  }
  @observable disabled = true;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  setDisabled = disabled => (this.disabled = disabled);

  onCancel = () => {
    const {
      store: {
        addUserToTeamDialog: {reset},
        teamPage: {reset: resetTeamPage}
      }
    } = this.props;

    reset();
    resetTeamPage();
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        addUserToTeamDialog: {opened, reset},
        teamPage: {submitNewTeamMembers, allUsers},
        router: {
          params: {id: teamId}
        }
      }
    } = this.props;
    const {cancel, addToTeam, addUserToTeam, selectAnUser} = messages;
    const createTeamMemberMutation = this.props[mutationNames.CREATE_TEAM_USERS];

    const actions =
      allUsers.length > 0
        ? [
            {
              label: formatMessage(addToTeam),
              onClick: () => submitNewTeamMembers({createTeamMemberMutation, goToTeamUsersList: reset, teamId}),
              disabled: this.disabled
            }
          ]
        : [];

    const title = formatMessage(addUserToTeam);
    const infoText = formatMessage(selectAnUser);

    return (
      <Dialog
        actions={actions}
        size="medium"
        isOpen={opened}
        onCancel={this.onCancel}
        title={title}
        customCancelButtonText={cancel}
        bodyStyle={{overflow: 'visible'}}
      >
        <StyledInfoText>{infoText}</StyledInfoText>
        <UsersWrapper>
          <NewTeamUsersView editTeam={true} setDisabled={this.setDisabled} />
        </UsersWrapper>
      </Dialog>
    );
  }
}

export default injectIntl(AddUserToTeamDialog);
