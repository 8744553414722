import moment from 'moment';
import {toDataURL} from 'qrcode';

import {DATE_TIME_FORMAT} from 'config/constants';

import {generateImage} from './image';

import {alignmentType, colors, pageBreak} from '../constants';
import {isAImage} from '../utils';

export const generateGuideOverview = async (guide, pdfMessages, platformName, guideBaseUrl) => {
  const {title, media, lastVersion, locale} = guide;
  const createdDate = moment().format(DATE_TIME_FORMAT);
  let guideCover;

  const qrCodeImage = await toDataURL(guideBaseUrl);

  if (media) {
    if (isAImage(media.url)) {
      guideCover = {
        image: await generateImage(media.url, media.metadata),
        fit: [500, 500],
        margin: [0, 50, 0, 0],
        alignment: alignmentType.CENTER
      };
    }
  }

  return [
    guideCover,
    platformName && {
      text: platformName,
      alignment: alignmentType.CENTER,
      color: colors.GRAY,
      fontSize: 18,
      absolutePosition: {y: 470}
    },
    {text: title, alignment: alignmentType.CENTER, fontSize: 22, absolutePosition: {y: 500}},
    [
      {text: pdfMessages.upToDate, absolutePosition: {x: 47, y: 640}, bold: true},
      {
        image: qrCodeImage,
        fit: [75, 75],
        absolutePosition: {x: 40, y: 660}
      },
      {text: guideBaseUrl, guideBaseUrl, absolutePosition: {x: 120, y: 665}, color: colors.BLUE}
    ],
    [
      {
        text: [{text: pdfMessages.version, bold: true}, {text: ` ${lastVersion.version} | ${locale}`}],
        absolutePosition: {x: 450, y: 750},
        margin: [0, 0, 47, 0]
      },
      {
        text: [{text: pdfMessages.created, bold: true}, {text: ` ${createdDate}`}],
        alignment: alignmentType.LEFT,
        absolutePosition: {x: 47, y: 750},
        pageBreak: pageBreak.AFTER
      }
    ]
  ];
};
