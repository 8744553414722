import {defineMessages} from 'react-intl';

const messages = defineMessages({
  giveTeamAccess: {
    id: 'giveTeamAccess',
    defaultMessage: 'Give this Team access to view and publish to the following Workspace(s).'
  }
});

export default messages;
