import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditTag.title',
    defaultMessage: 'Edit Tag'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  notFound: {
    id: 'EditTag.notFound',
    defaultMessage: 'Tag not found.'
  },
  name: {
    id: 'EditTag.name',
    defaultMessage: 'Tag title'
  },
  badge: {
    id: 'NewTag.badge',
    defaultMessage: 'Set as Badge'
  },
  badgeDescription: {
    id: 'NewTag.badgeDescription',
    defaultMessage:
      'Authorized users can tag content as important with certifications or standards. Always protected from unauthorized changes.'
  },
  confirmation: {
    id: 'EditTag.confirmation',
    defaultMessage: 'Are you sure you want to remove this tag?'
  },
  archiveSuccess: {
    id: 'EditTag.archiveSuccess',
    defaultMessage: 'The tag has been removed.'
  },
  archiveFailure: {
    id: 'EditTag.archiveFailure',
    defaultMessage: 'Error removing tag.'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  updateSuccess: {
    id: 'EditTag.updateSuccess',
    defaultMessage: 'Tag has been edited.'
  },
  updateFailure: {
    id: 'EditTag.updateFailure',
    defaultMessage: 'Failed to edit the tag.'
  },
  duplicateTitle: {
    id: 'NewTag.duplicateTitle',
    defaultMessage: 'This tag name already exists'
  }
});

export default messages;
