const examples = {
  platformLogos: [
    {
      '1x': require('img/examples/platform-logos/1/example-platform-logo-1.jpg'),
      '2x': require('img/examples/platform-logos/1/example-platform-logo-1@2x.jpg'),
      '3x': require('img/examples/platform-logos/1/example-platform-logo-1@3x.jpg')
    },
    {
      '1x': require('img/examples/platform-logos/2/example-platform-logo-2.jpg'),
      '2x': require('img/examples/platform-logos/2/example-platform-logo-2@2x.jpg'),
      '3x': require('img/examples/platform-logos/2/example-platform-logo-2@3x.jpg')
    }
  ],
  themeColors: [
    {
      '1x': require('img/examples/theme-colors/1/example-colors-1.jpg'),
      '2x': require('img/examples/theme-colors/1/example-colors-1@2x.jpg'),
      '3x': require('img/examples/theme-colors/1/example-colors-1@3x.jpg')
    },
    {
      '1x': require('img/examples/theme-colors/2/example-colors-2.jpg'),
      '2x': require('img/examples/theme-colors/2/example-colors-2@2x.jpg'),
      '3x': require('img/examples/theme-colors/2/example-colors-2@3x.jpg')
    },
    {
      '1x': require('img/examples/theme-colors/3/example-colors-3.jpg'),
      '2x': require('img/examples/theme-colors/3/example-colors-3@2x.jpg'),
      '3x': require('img/examples/theme-colors/3/example-colors-3@3x.jpg')
    }
  ],
  appIcon: [
    {
      '1x': require('img/examples/app-icon/1/example-app-icon.jpg'),
      '2x': require('img/examples/app-icon/1/example-app-icon@2x.jpg'),
      '3x': require('img/examples/app-icon/1/example-app-icon@3x.jpg')
    }
  ]
};

export default examples;
