import {defineMessages} from 'react-intl';

const messages = defineMessages({
  unpublish: {
    id: 'EditGuide.unpublish',
    defaultMessage: 'Unpublish (make draft)'
  },
  activity: {
    id: 'EditGuide.activity',
    defaultMessage: 'View activity'
  },
  versionHistory: {
    id: 'EditGuide.versionHistory',
    defaultMessage: 'Version history'
  },
  feedback: {
    id: 'EditGuide.feedback',
    defaultMessage: 'View feedback'
  },
  publish: {
    id: 'common.actions.publish',
    defaultMessage: 'Publish'
  },
  reassign: {
    id: 'common.actions.reassign',
    defaultMessage: 'Reassign'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  duplicate: {
    id: 'common.actions.duplicate',
    defaultMessage: 'Duplicate'
  },
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  archive: {
    id: 'common.actions.remove',
    defaultMessage: 'Remove'
  },
  share: {
    id: 'common.actions.share',
    defaultMessage: 'Share'
  },
  shareGuide: {
    id: 'EditGuide.shareGuide',
    defaultMessage: 'Share guide'
  },
  archiveGuide: {
    id: 'EditGuide.archiveGuide',
    defaultMessage: 'Remove guide'
  },
  archiveGuideConfirm: {
    id: 'EditGuide.archiveGuideConfirm',
    defaultMessage: 'Are you sure you want to remove your guide ‘{title}’?'
  },
  archiveGuideSuccess: {
    id: 'EditGuide.archiveGuideSuccess',
    defaultMessage: 'Guide has been removed.'
  },
  archiveGuideFailure: {
    id: 'EditGuide.archiveGuideFailure',
    defaultMessage: 'Error removing guide.'
  },
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  },
  unknownFailure: {
    id: 'common.notifications.unknownFailure',
    defaultMessage: 'Unknown error happened'
  },
  cloneGuideSuccess: {
    id: 'EditGuide.cloneGuideSuccess',
    defaultMessage: 'Guide duplicated successfully.'
  },
  cloneGuideFailure: {
    id: 'EditGuide.cloneGuideFailure',
    defaultMessage: 'Error cloning guide.'
  },
  guidePublishedMessage: {
    id: 'EditGuide.publishSuccess',
    defaultMessage: 'Your guide is now published'
  },
  guidePublishedDocumentsMessage: {
    id: 'EditGuide.guidePublishedDocumentsMessage',
    defaultMessage: 'Your guide is now published and available in Dispatch Document Center.'
  },
  guideUnpublishedMessage: {
    id: 'EditGuide.unpublishSuccess',
    defaultMessage: 'Your guide is now unpublished'
  },
  publishGuideFailure: {
    id: 'EditGuide.publishGuideFailure',
    defaultMessage: 'Error publishing your guide.'
  },
  publishGuideFailureMaxPublishedGuides: {
    id: 'EditGuide.publishGuideFailureMaxPublishedGuides',
    defaultMessage: 'Guides. Oh no! Unfortunately, you can’t publish another guide with your current subscription.'
  },
  contactSupport: {
    id: 'EditGuide.contactSupport',
    defaultMessage: 'Please contact support to raise your limit.'
  },
  publishGuideFailureForbidden: {
    id: 'EditGuide.publishGuideFailureForbidden',
    defaultMessage: "You don't have permissions to publish this guide."
  },
  viewGuide: {
    id: 'viewGuide',
    defaultMessage: 'View guide'
  },
  viewDetails: {
    id: 'viewDetails',
    defaultMessage: 'View details'
  },
  copyTo: {
    id: 'copyTo',
    defaultMessage: 'Copy to'
  },
  chatMessage: {
    id: 'chatMessagePublishedGuidesLimit',
    defaultMessage:
      'Hi Swipee,\nI’m hitting the limit on our published guides. Could you tell me more on how I can upgrade my platform?'
  }
});

export default messages;
