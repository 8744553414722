import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

const blackText = `color: ${commonPalette.txBlack};`;

export const StyledList = styled.ul`
  ${blackText}
  list-style-type: disc;
  margin-left: 15px;
`;

export const StyledHeader = styled.div`
  ${blackText}
`;

export const SectionTitle = styled.div`
  ${blackText}
  margin-top: 10px;
`;
