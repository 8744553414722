import styled from 'styled-components';

import Icon from 'ui-components/Icon';

import {commonPalette} from 'shared/styles/palette';

export const BackToChecklistsWrapper = styled.div`
  display: flex;
  color: ${commonPalette.txLightGrey};
  align-items: center;
  margin-bottom: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const BackIcon = styled(Icon).attrs({
  iconId: 'back'
})`
  margin-right: 10px;
`;

export const WrapperText = styled.div`
  font-weight: 300;
`;

export const SectionTitle = styled.div`
  font-size: 18px;
  margin-bottom: 15px;
`;
