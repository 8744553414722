import {mediaTypes} from '../constants';

export const isAImage = url =>
  url.endsWith('.jpg') || url.endsWith('.jpeg') || url.endsWith('.png') || url.endsWith('.bmp') || url.endsWith('.svg');

export const isAGif = url => url.endsWith('.gif');

export const isAVideo = url =>
  url.endsWith('.mp4') ||
  url.endsWith('.mov') ||
  url.endsWith('.webm') ||
  url.endsWith('.ogv') ||
  url.endsWith('.3gp') ||
  url.endsWith('.3g2') ||
  url.endsWith('.wmv') ||
  url.endsWith('.mpeg') ||
  url.endsWith('.flv') ||
  url.endsWith('.mkv') ||
  url.endsWith('.avi');

export const getMediaType = media => {
  let mediaType;

  if (isAGif(media.url)) {
    mediaType = mediaTypes.GIF;
  } else if (isAVideo(media.url)) {
    mediaType = mediaTypes.VIDEO;
  } else {
    mediaType = mediaTypes.IMAGE_WITH_OVERLAYS;
  }

  return mediaType;
};
