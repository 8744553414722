import {observable, action, makeObservable} from 'mobx';
import filter from 'lodash/filter';

class GuideViewPage {
  @observable openedTopics = [];
  @observable openedFirstTopic = false;
  currentGuideId;
  lastVisitedTopic;

  @action setOpenedFirstTopic = val => (this.openedFirstTopic = val);

  @action
  toggleOpenedTopic = ({track, topicId, topicTitle, store}) => {
    const hasTopic = this.openedTopics.indexOf(topicId) !== -1;
    this.openedTopics = hasTopic ? filter(this.openedTopics, tId => tId !== topicId) : [...this.openedTopics, topicId];

    // track browse topic
    if (!track) return;

    const action = hasTopic ? 'closeTopic' : 'openTopic';
    const actionContent = topicTitle;
    const objectsInfo = {guideId: this.currentGuideId, topicId};
    store.analytics.trackActionEvent({
      objectsInfo,
      action,
      actionContent
    });
  };

  @action
  clear = () => {
    this.openedTopics = [];
    this.lastVisitedTopic = undefined;
    this.openedFirstTopic = false;
  };

  setCurrentGuideId = guideId => {
    this.currentGuideId = guideId;
  };

  setLastVisitedTopic = topicId => {
    this.lastVisitedTopic = topicId;
  };

  constructor() {
    makeObservable(this);
  }
}

export default GuideViewPage;
