import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {clone} from 'lodash';

//styled-components
import UserPicker from 'components/UserPicker';
import RolePicker from 'components/RolePicker';

// queries
import {SkillRoles} from 'api/skill-matrix/queries';
import {SkillRolesOptions} from 'api/skill-matrix/query-options';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

//styles
import {AddTeamUsers, Button, Field, PickerRow, RemoveButton} from './styles';
import LoadingMessage from 'components/LoadingMessage';

//shared
import {TEAM_MEMBER_ROLE} from 'shared/enums';
import {isUserUsingPhone} from 'utils/data-utils';

@inject('store')
@graphql(SkillRoles, SkillRolesOptions)
@observer
class NewTeamUsers extends Component {
  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {teamPage} = store;
    teamPage.setNewForm();
  }

  render() {
    const {
      intl: {formatMessage},
      skillRolesQuery: {loading: loadingSkillRoles, skillRoles},
      store
    } = this.props;
    const {
      teamPage,
      platform: {hasSkillsEnabled}
    } = store;
    const {userTeamRoles, allUsers, isAddUserRoleEnabled, removeUserRolePicker, addUserRolePicker, selectedIds} =
      teamPage;
    const sortedSkillRoles = (clone(skillRoles) || []).sort((a, b) => a.name.localeCompare(b.name));
    const skillRoleList = [{id: null, name: formatMessage(messages.noJobTitle)}, ...sortedSkillRoles];

    if (loadingSkillRoles) {
      return (
        <LoadingMessage>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }

    if (allUsers.length === 0) {
      return (
        <AddTeamUsers>
          <FormattedMessage {...messages.noUsers} />
        </AddTeamUsers>
      );
    }

    return (
      <AddTeamUsers>
        {userTeamRoles?.map((userRole, index) => (
          <PickerRow key={`${userRole.userID}-${index}`}>
            <Field
              inputComponent={
                <UserPicker
                  dataCy={`user-picker-${index}`}
                  field="userId"
                  filterOption={selectedIds}
                  form={userRole.form}
                  users={allUsers}
                  {...(!index && {label: formatMessage(messages.selectUser)})}
                />
              }
            />
            <Field
              inputComponent={
                <RolePicker
                  allowClear={false}
                  dataCy={`role-picker-${index}`}
                  form={userRole.form}
                  field="role"
                  {...(!index && {label: formatMessage(messages.selectRole)})}
                  {...(isUserUsingPhone(allUsers, userRole?.form?.$('userId').value) && {
                    roles: [{name: TEAM_MEMBER_ROLE.VIEWER, id: TEAM_MEMBER_ROLE.VIEWER}]
                  })}
                />
              }
            />
            {hasSkillsEnabled && (
              <Field
                inputComponent={
                  <RolePicker
                    roles={skillRoleList}
                    placeholder={formatMessage(messages.noJobTitle)}
                    allowClear={false}
                    dataCy={`skill-role-picker-${index}`}
                    form={userRole.form}
                    field="skillRoleId"
                    {...(!index && {label: formatMessage(messages.selectJobTitle)})}
                  />
                }
              />
            )}
            <RemoveButton removeMargin={!index} onClick={() => removeUserRolePicker(index)} />
          </PickerRow>
        ))}
        <Button
          underlined
          style={{height: 'auto'}}
          disabled={!isAddUserRoleEnabled}
          iconId="add"
          onClick={addUserRolePicker}
          label={formatMessage(messages.addAnotherUser)}
        />
      </AddTeamUsers>
    );
  }
}

export default injectIntl(NewTeamUsers);
