import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'SSOError.title',
    defaultMessage: 'SSO Authentication Error'
  },
  viewContent: {
    id: 'SSOError.viewContent',
    defaultMessage: 'View Content'
  },
  SSO001: {
    id: 'SSOError.SSO001',
    defaultMessage: 'Email claim is missing on the SSO user profile.'
  },
  SSO010: {
    id: 'SSOError.SSO010',
    defaultMessage: 'Time to link SSO account expired.'
  },
  SSO011: {
    id: 'SSOError.SSO011',
    defaultMessage: 'There is an existing user with this SSO acount on our system already.'
  },
  SSO020: {
    id: 'SSOError.SSO020',
    defaultMessage:
      'You are trying to login to edit the guides and you do not have permission to do so. You may use the guides for now in the viewer. If you’d like to edit a guide, please get in touch with your admin.'
  },
  SSO100: {
    id: 'SSOError.SSO100',
    defaultMessage: 'Something went wrong. Please try again or contact support.'
  },
  SSO401: {
    id: 'SSOError.SSO401',
    defaultMessage: 'Your account was locked. Please get in touch to unlock it.'
  },
  defaultErrorCode: {
    id: 'SSOError.default',
    defaultMessage: 'Something went wrong. Please contact support (Code: {ssoError})'
  },
  ok: {
    id: 'common.actions.ok',
    defaultMessage: 'OK'
  }
});

export default messages;
