import {observable, action, makeObservable} from 'mobx';
import {get, find} from 'lodash';

//helpers
import views from 'config/views';
import store from 'stores/store';

class GuideVersionsDialog {
  @observable opened = false;
  @observable versions = null;
  @observable selectedVersion = null;
  @observable isPublished = null;
  @observable settingVersion = false;

  @action
  reset = () => {
    this.opened = false;
  };

  @action
  setGuideVersion = async () => {
    this.settingVersion = true;
    this.goToVersion();

    this.settingVersion = false;
  };

  @action
  goToVersion = () => {
    const {router} = store;
    const {currentRoute, params} = router;
    const {id, topicId, instructionId, stepId} = params;
    const queryParams = this.selectedVersion ? {v: this.selectedVersion._id} : {};
    if (currentRoute.path === views.editGuide.path) {
      router.goTo(views.editGuide, {id: params.id}, queryParams);
    } else if (currentRoute.path === views.editInstruction.path) {
      router.goTo(views.editInstruction, {id, topicId, instructionId, stepId}, queryParams);
    }
  };

  @action
  open = ({isUnderApproval, versions, isPublished, isLiveVersionExpired}) => {
    this.opened = true;
    this.setVersions({isUnderApproval, versions, isPublished, isLiveVersionExpired});
  };

  @action
  setVersions = ({isUnderApproval, versions, isPublished, isLiveVersionExpired}) => {
    this.versions = versions;
    this.isPublished = isPublished;
    const versionId = get(store.router, 'queryParams.v', null);
    this.isUnderApproval = isUnderApproval || versionId === 'review';
    this.isLiveVersionExpired = isLiveVersionExpired;
    if (versionId === 'live-review') {
      this.selectedVersion = {_id: 'live-review'};
    } else if (versionId === 'review') {
      this.selectedVersion = {_id: 'review'};
    } else {
      this.selectedVersion = find(this.versions, version => versionId === version._id);
    }
  };

  constructor() {
    makeObservable(this);
  }

  @action
  selectVersion = version => {
    this.selectedVersion = version;
  };
}

export default GuideVersionsDialog;
