import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import Checkbox from 'components/Checkbox';
import {graphql} from '@apollo/client/react/hoc/graphql';

//messages
import messages from './messages';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import InstructionalIconsList from 'components/InstructionalIconsList';

import {
  TextGrey,
  StepNotesCaptionWrapper,
  StyledUnderlinedButton,
  StepNotesNewIconWrapper,
  InstructionalIconsWrapper,
  InstructionalIconListWrapper
} from './styles';

// queries
import {IconsList} from 'api/platform/queries';
import {iconsListOptions} from 'api/platform/query-options';

// mutations
import {ToggleStepNoteNameVisiblity} from 'api/platform/mutations';
import {toggleStepNoteNameOptions} from 'api/platform/mutation-options';

@inject('store')
@graphql(IconsList, {...iconsListOptions, name: 'iconsData'})
@graphql(ToggleStepNoteNameVisiblity, toggleStepNoteNameOptions)
@observer
class InstructionalIcons extends Component {
  editIconOnClick = ({icon}, e) => {
    e.preventDefault();
    const {instructionalIconDialog} = this.props.store;
    instructionalIconDialog.open({icon});
  };

  render() {
    const {
      store,
      iconsData: {icons, loading: dataLoading},
      intl: {formatMessage},
      toggleStepNoteNameMutation
    } = this.props;
    const {
      platformSettingsPage,
      instructionalIconDialog,
      platform: {showStepNoteIconLabel}
    } = store;
    const {toggleStepNoteNames} = platformSettingsPage;

    if (dataLoading) {
      return null;
    }

    return (
      <InstructionalIconsWrapper>
        <StepNotesCaptionWrapper>
          <TextGrey>
            <FormattedMessage {...messages.captionText} />
            <br />
            <FormattedMessage {...messages.captionTextDefaultIcons} />
          </TextGrey>
          <Checkbox
            checked={showStepNoteIconLabel}
            onChange={toggleStepNoteNames.bind(this, toggleStepNoteNameMutation)}
            caption={formatMessage(messages.showStepNoteName)}
          />
        </StepNotesCaptionWrapper>

        <InstructionalIconListWrapper>
          <InstructionalIconsList isThemeStylingView={false} icons={icons} onEditIcon={this.editIconOnClick} />
        </InstructionalIconListWrapper>
        <StepNotesNewIconWrapper>
          <StyledUnderlinedButton
            underlined
            dataCy="add-new-icon"
            className="add-new-icon-link"
            onClick={instructionalIconDialog.open.bind(this)}
            iconId="add"
            label={formatMessage(messages.addNewStepNote)}
          />
        </StepNotesNewIconWrapper>
      </InstructionalIconsWrapper>
    );
  }
}

export default injectIntl(InstructionalIcons);
