import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';
import views from 'config/views';

//styled-components
import {PickerRow, RemoveButton} from './styles';

// queries
import {AllTeams} from 'api/team/queries';
import {Domain} from 'api/domain/queries';

//mutations
import {AddTeamsToDomain} from 'api/domain/mutations';

//options
import {teamsOptions} from 'api/team/query-options';
import {domainOptions} from 'api/domain/query-options';
import {addTeamsToDomainOptions} from 'api/domain/mutation-options';

//components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import {FormattedMessage} from 'components/FormattedComponents';
import ButtonGroup from 'ui-components/ButtonGroup';
import Button from 'ui-components/Button';
import Field from 'ui-components/Field';
import TeamPicker from 'components/TeamPicker';
import Container from 'ui-components/Layout/Container';

//messages
import messages from './messages';

@inject('store')
@graphql(AllTeams, teamsOptions)
@graphql(Domain, domainOptions)
@graphql(AddTeamsToDomain, addTeamsToDomainOptions)
@observer
class WorkspaceNewTeamsView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      workspaceTeamsPage,
      router
    } = store;
    const {canManageDomains} = user;
    if (!canManageDomains) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(pick(messages, ['addTeamSuccess', 'addTeamFailure']), message =>
      formatMessage(message)
    );

    workspaceTeamsPage.setTranslations(translations);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store,
      teamsQuery: {allTeams = [], loading: teamsLoading},
      domainQuery: {domain, loading: domainLoading}
    } = nextProps;
    const {workspaceTeamsPage} = store;

    if (!teamsLoading && allTeams) {
      workspaceTeamsPage.setTeams(allTeams);
    }

    if (!domainLoading && domain) {
      workspaceTeamsPage.setDomain(domain);
    }
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      addTeamsToDomainMutation
    } = this.props;
    const {workspaceTeamsPage} = store;
    const {
      selectedIds,
      selectedTeams,
      addTeamPicker,
      removeTeamPicker,
      isSelectionValid,
      availableTeams,
      canAddAvailableTeams,
      domain
    } = workspaceTeamsPage;

    const goToWorkspaceTeamsList = () => store.router.goTo(views.workspaceTeams, {id: domain.id});

    // todo: improve the UI for empty state
    if (!availableTeams.length) {
      return (
        <Container>
          <ViewTitle title={formatMessage(messages.title)} />
          <FormattedMessage {...messages.noTeamsAvailable} />
          <ButtonGroup
            bottomFixed
            style={{paddingRight: 50}}
            secondary={{
              onClick: goToWorkspaceTeamsList,
              label: formatMessage(messages.cancel)
            }}
          />
        </Container>
      );
    }

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} subtitle={formatMessage(messages.subtitle)} />
        {selectedTeams &&
          selectedTeams.map((picker, index) => {
            return (
              <PickerRow key={index}>
                <Field
                  size="small"
                  inputComponent={
                    <TeamPicker
                      allowClear={false}
                      field="teamId"
                      filterOption={selectedIds}
                      form={picker.form}
                      label={formatMessage(messages.selectTeam)}
                      placeholder={formatMessage(messages.selectTeam)}
                      teams={availableTeams}
                    />
                  }
                />
                {(!!index || selectedTeams.length > 1) && <RemoveButton onClick={e => removeTeamPicker(e, index)} />}
              </PickerRow>
            );
          })}
        <Button
          underlined
          style={{height: 'auto'}}
          iconId="add"
          disabled={!canAddAvailableTeams || !isSelectionValid}
          onClick={addTeamPicker}
          label={formatMessage(messages.addTeamPicker)}
        />
        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          primary={{
            disabled: !isSelectionValid,
            label: formatMessage(messages.save),
            onClick: () => workspaceTeamsPage.submit(addTeamsToDomainMutation, domain.id, goToWorkspaceTeamsList)
          }}
          secondary={{
            onClick: goToWorkspaceTeamsList,
            label: formatMessage(messages.cancel)
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(WorkspaceNewTeamsView);
