import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Circle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (!props.outline || props.preserveBackground ? props.color : 'white')};
  color: ${props => (props.outline ? 'black' : 'white')}};
  ${props => props.outline && `border: 1px solid ${props.color};`}
  font-size: 9px;
`;

export const Label = styled.div`
  font-size: 12px;
  white-space: nowrap;
`;
