import {action, observable, computed, makeObservable} from 'mobx';

import views from 'config/views';

import campaignQuestionForm, {createCampaignQuestionForm} from 'stores/forms/campaign-question-form';
import {createFollowUpQuestionForm} from 'stores/forms/campaign-question-follow-up-form';
import {createCampaignTextTypeOptionForm} from 'stores/forms/campaign-text-type-options';
import store from 'stores/store';

import notification from 'utils/notification-utils';

class TranslateCampaignPage {
  @observable campaign;
  @observable availableLanguages = [];
  @observable translatedLanguage;
  @observable wasTranslated = false;

  questionForm = campaignQuestionForm;
  translations = {};

  @action setCampaign = campaign => {
    this.campaign = campaign;

    this.createQuestionForms(campaign);
    this.createFollowUpQuestionsForms(campaign);
    this.createTextTypeOptionsForms(campaign);
  };

  @action setWasTranslated = wasTranslated => {
    this.wasTranslated = wasTranslated;
  };

  // Creates a form for each campaign question for each available locale
  @action
  createQuestionForms = campaign => {
    const {
      defaultLocale,
      question: {questionTranslations}
    } = campaign;

    this[`question${defaultLocale}`] = createCampaignQuestionForm();
    this[`question${defaultLocale}`].$('translation').sync(questionTranslations[defaultLocale]);

    this.languageOptions.forEach(({id: locale}) => {
      this[`question${locale}`] = createCampaignQuestionForm();

      Object.keys(questionTranslations).forEach(() => {
        const foundTranslation = questionTranslations[locale];

        if (foundTranslation) {
          this[`question${locale}`].$('translation').sync(foundTranslation);
        }
      });
    });
  };

  // Creates a form for each follow up question for each available locale
  @action
  createFollowUpQuestionsForms = campaign => {
    const {
      defaultLocale,
      question: {followUps}
    } = campaign;

    followUps.forEach(({questionTranslations}, index) => {
      this[`followUpQuestion${index}${defaultLocale}`] = createFollowUpQuestionForm();
      this[`followUpQuestion${index}${defaultLocale}`].$('translation').sync(questionTranslations[defaultLocale]);

      this.languageOptions.forEach(({id: locale}) => {
        this[`followUpQuestion${index}${locale}`] = createFollowUpQuestionForm();

        Object.keys(questionTranslations).forEach(() => {
          const foundTranslation = questionTranslations[locale];

          if (foundTranslation) {
            this[`followUpQuestion${index}${locale}`].$('translation').sync(foundTranslation);
          }
        });
      });
    });
  };

  // Creates a form for each text type option for each available locale
  @action
  createTextTypeOptionsForms = campaign => {
    const {
      defaultLocale,
      question: {textTypeOptions}
    } = campaign;

    textTypeOptions.forEach((textTypeOption, index) => {
      this[`textTypeOption${index}${defaultLocale}`] = createCampaignTextTypeOptionForm();
      this[`textTypeOption${index}${defaultLocale}`].$('translation').sync(textTypeOptions[defaultLocale]);

      this.languageOptions.forEach(({id: locale}) => {
        this[`textTypeOption${index}${locale}`] = createCampaignTextTypeOptionForm();

        Object.keys(textTypeOption).forEach(() => {
          const foundTranslation = textTypeOption[locale];

          if (foundTranslation) {
            this[`textTypeOption${index}${locale}`].$('translation').sync(foundTranslation);
          }
        });
      });
    });
  };

  @action resetCampaign = () => {
    store.router.goTo(views.campaigns, {});

    this.campaign.question.followUps.forEach((_, index) => {
      this.languageOptions.forEach(({id: locale}) => {
        this[`followUpQuestion${index}${locale}`].reset();
      });
    });

    this.campaign.question.textTypeOptions.forEach((_, index) => {
      this.languageOptions.forEach(({id: locale}) => {
        this[`textTypeOption${index}${locale}`].reset();
      });
    });

    this.languageOptions.forEach(({id: locale}) => {
      this[`question${locale}`].reset();
    });

    this.questionForm.reset();

    this.setAvailableLanguages([]);
    this.setTranslatedLanguage(null);
    this.setTranslations({});
    this.setWasTranslated(false);
    this.campaign = null;
  };

  @action setTranslatedLanguage = translatedLanguage => {
    this.translatedLanguage = translatedLanguage;
  };

  @action setAvailableLanguages = languages => {
    this.availableLanguages = languages;
  };

  constructor() {
    makeObservable(this);
  }

  @computed get defaultLanguage() {
    return this.availableLanguages
      .filter(l => l.locale === (this.campaign && this.campaign.defaultLocale))
      .map(l => l.language);
  }

  @computed get selectedLocale() {
    return this.questionForm.values().translationLocale;
  }

  @computed get languageOptions() {
    return this.availableLanguages
      .filter(({locale}) => locale !== (this.campaign && this.campaign.defaultLocale))
      .map(({locale, language: name}) => {
        if (this.campaign) {
          const {
            question: {followUps, textTypeOptions, questionTranslations}
          } = this.campaign;

          // 1 comes from the main question of the campaign
          const totalNumberTranslations = 1 + followUps.length + textTypeOptions.length;

          const translatedMainQuestion = questionTranslations[locale] ? 1 : 0;

          let followUpsTranslations = 0;
          followUps.forEach(followUp => {
            followUpsTranslations += followUp.questionTranslations[locale] ? 1 : 0;
          });

          let textTypeOptionsTranslations = 0;
          textTypeOptions.forEach(textTypeOption => {
            textTypeOptionsTranslations += textTypeOption[locale] ? 1 : 0;
          });

          const actualTranslationsCompleted =
            translatedMainQuestion + followUpsTranslations + textTypeOptionsTranslations;
          const resultTranslationsCompleted = (actualTranslationsCompleted * 100) / totalNumberTranslations;

          return {
            id: locale,
            name: `${name} (${resultTranslationsCompleted.toFixed()}% ${this.translations.complete})`
          };
        }

        return {id: locale, name};
      });
  }

  @action setTranslationLanguage = translationLocale => {
    this.questionForm.$('translationLocale').sync(translationLocale);
  };

  @action submit = async editCampaignMutation => {
    const {
      id,
      title,
      defaultLocale,
      question: {followUps, textTypeOptions, kind, numOfRating}
    } = this.campaign;

    // Creating the question translations in the right format
    const questionTranslationsValues = {};

    this.availableLanguages.forEach(({locale}) => {
      questionTranslationsValues[locale] = this[`question${locale}`].values().translation;
    });

    // Creating the followup translations in the right format
    const followUpValues = followUps.map((followUp, index) => {
      const {kind, rangeFrom, rangeTo, questionTranslations} = followUp;
      const followUpTranslationValues = {};

      this.availableLanguages.forEach(({locale}) => {
        followUpTranslationValues[locale] = this[`followUpQuestion${index}${locale}`].values().translation;
      });

      this.availableLanguages.forEach(({locale}) => {
        const foundTranslation = followUpTranslationValues[locale];

        if (foundTranslation) {
          questionTranslations[locale] = foundTranslation;
        }
      });

      return {kind, rangeTo, rangeFrom, questionTranslations};
    });

    // Creating the text type option translations in the right format
    const textTypeOptionsValues = [];

    textTypeOptions.forEach((textTypeOption, index) => {
      const textTypeOptionTranslation = {};

      this.availableLanguages.forEach(({locale}) => {
        textTypeOptionTranslation[locale] = this[`textTypeOption${index}${locale}`].values().translation;
      });

      textTypeOptionTranslation[defaultLocale] = textTypeOption[defaultLocale];
      textTypeOptionsValues.push(textTypeOptionTranslation);
    });

    const translatedCampaign = {
      title,
      defaultLocale,
      question: {
        kind,
        numOfRating,
        questionTranslations: questionTranslationsValues,
        followUps: followUpValues,
        textTypeOptions: textTypeOptionsValues
      }
    };

    editCampaignMutation({
      id,
      campaign: translatedCampaign
    })
      .then(() => {
        notification.success(this.translations.translationsSuccess);
        this.setWasTranslated(true);
        store.router.goTo(views.instructionScoring, {});
      })
      .catch(() => {
        notification.error(this.translations.translationsFailure);
      });
  };

  setTranslations = translations => {
    this.translations = translations;
  };
}

export default TranslateCampaignPage;
