import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  teams: {
    id: 'common.title.teams',
    defaultMessage: 'Teams'
  },
  teamDescription: {
    id: 'teamDescriptionV2',
    defaultMessage: 'Teams can create, share, and publish content across Workspaces.'
  },
  teamName: {
    id: 'common.form.teamName',
    defaultMessage: 'Team Name'
  },
  subTeams: {
    id: 'common.form.subTeams',
    defaultMessage: 'Sub-Teams'
  },
  team: {
    id: 'common.form.team',
    defaultMessage: 'Team'
  },
  viewTeam: {
    id: 'common.actions.viewTeam',
    defaultMessage: 'View Team'
  },
  editTeam: {
    id: 'common.actions.editTeam',
    defaultMessage: 'Edit Team'
  },
  viewWorkspaces: {
    id: 'common.actions.viewWorkspaces',
    defaultMessage: 'View Workspaces'
  },
  archiveTeamSuccess: {
    id: 'TeamsView.archiveTeamSuccess',
    defaultMessage: 'Team has been successfully archived'
  },
  archiveTeamFailure: {
    id: 'TeamsView.archiveTeamFailure',
    defaultMessage: 'Team cannot be archived'
  },
  archiveTeamContentBody: {
    id: 'TeamsView.archiveTeamContentBody',
    defaultMessage:
      'No one will be able to access it or the content owned by it. Consider reassigning if there is any content to another team before archiving.'
  },
  archiveTeamBodyNoReassign: {
    id: 'TeamsView.archiveTeamBodyNoReassign',
    defaultMessage: 'You cannot reassign this content because this platform has no other teams.'
  },
  deleteTeam: {
    id: 'TeamsView.deleteTeam',
    defaultMessage: 'Delete Team'
  },
  deleteTeamConfirm: {
    id: 'TeamsView.deleteTeamConfirm',
    defaultMessage: 'You are about to delete this team.'
  },
  reassignContentError: {
    id: 'TeamsView.reassignContentError',
    defaultMessage: 'To reassign this team content you need to choose a team and an assignee.'
  },
  searchTeams: {
    id: 'TeamsView.searchTeams',
    defaultMessage: 'Search teams'
  },
  noTeams: {
    id: 'TeamsView.noTeams',
    defaultMessage: 'There are no Teams created yet.'
  },
  noFilteredTeams: {
    id: 'TeamsView.noFilteredTeams',
    defaultMessage: 'No Teams found for {searchTerm}'
  },
  teamSettings: {
    id: 'teamSettings',
    defaultMessage: 'Team Settings'
  },
  error: {
    id: 'common.errors.pageload',
    defaultMessage: "Couldn't load the page. Please try again."
  }
});

export default messages;
