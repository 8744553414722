import styled from 'styled-components';

export const StyledButtonGroup = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start !important;

  > button {
    margin-right: 10px;

    :first-child {
      margin-right: 0;
    }
  }

  ${p =>
    p.line &&
    `
    border-top: 1px solid #cccccc;
    margin-top: 2rem;
    padding-top: 2rem;
  `}

  ${p =>
    p.forColorPicker &&
    `margin-top: 2rem;
  `}
  ${p =>
    p.sticky &&
    `
    background: #fff;
    bottom: 0;
    flex-shrink: 0;
    padding: 28px 0;
    padding-right: 0;
    position: sticky;
    z-index: 1;
  `}
  
  ${p =>
    p.bottomFixed &&
    `
    background: #fff;
    position: fixed;
    bottom: 0;
    padding: 40px 50px;
    z-index: 1;
    width: 100%;
    max-width: 1020px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;
