import styled from 'styled-components';

import Select from 'ui-components/Select';

import {commonPalette} from 'shared/styles/palette';

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSelect = styled(Select)`
  margin: 10px 8px 0 0;
`;

export const OptionWrapper = styled.div`
  margin: 1px 0;
`;

export const TimeOptionWrapper = styled.div`
  display: inline-block;
  align-items: center;
`;

export const StyledDiv = styled.div`
  display: flex;
`;

export const dialogBodyStyle = {
  color: commonPalette.txBlack,
  height: 175,
  overflow: 'visible'
};

export const dialogStyle = {
  width: 900
};

export const selectStyle = {
  width: 125
};

export const customErrorStyle = {
  position: 'absolute',
  left: '40px'
};
