import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';

import mutationNames from 'api/team/constants';
import views from 'config/views';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import MaxEditorsErrorNotificationContent from 'components/MaxEditorsErrorNotificationContent';

// queries
import {EnabledUsersLite} from 'api/user/queries';

//mutations
import {CreateTeam} from 'api/team/mutations';

//options
import {createTeamOptions} from 'api/team/mutation-options';
import {userNamesOptions} from 'api/user/query-options';

//helpers
import {getShowIntercom} from 'utils/intercom-utils';

//components
import StepsTabs from 'components/StepsTabs';
import Container from 'ui-components/Layout/Container';

//messages
import messages from './messages';

@inject('store')
@graphql(CreateTeam, createTeamOptions)
@graphql(EnabledUsersLite, userNamesOptions)
@observer
class NewTeamView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      teamPage,
      router,
      platform,
      app: {isActionMenuOpen}
    } = store;
    const {isPlatformAdmin, isIPA} = user;

    if (!isPlatformAdmin && !isIPA) {
      router.goTo(views.root, {});
    }
    if (teamPage.guideApprovalEnabledForPlatform === null) {
      teamPage.setGuideApprovalFlag(platform.guideApproval);
    }

    teamPage.setSteps();
    teamPage.stepsStore.startListeningToRouteChange();

    const translations = mapValues(
      pick(messages, [
        'createSuccess',
        'createFailure',
        'addUsersToTeam',
        'setGuideApproval',
        'createNewTeam',
        'connectTeamToWorkspace',
        'setNotificationsForTeam'
      ]),
      message => formatMessage(message)
    );

    const createFailureMaxTeams = (
      <MaxEditorsErrorNotificationContent
        showIntercom={getShowIntercom({platform, isActionMenuOpen})}
        formattedMessages={{
          contactSupport: formatMessage(messages.contactSupport),
          publishGuideFailureMaxPublishedGuides: formatMessage(messages.createFailureMaxTeams)
        }}
      />
    );

    teamPage.reset();
    teamPage.setTranslations({
      ...translations,
      createFailureMaxTeams
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {store, userNamesQuery} = nextProps;
    const {enabledUsers: users = [], loading} = userNamesQuery;
    const {teamPage} = store;
    if (!loading && users) {
      teamPage.setUsers(users);
    }
  }

  componentWillUnmount() {
    const {
      store: {teamPage}
    } = this.props;
    teamPage.stepsStore.stopListeningToRouteChange();
    teamPage.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const createTeamMutation = this.props[mutationNames.CREATE_TEAM];
    const {teamPage} = store;
    const {stepsStore} = teamPage;
    const {userRoleSelection} = teamPage;
    const teamMembers = userRoleSelection.slice();

    if (!stepsStore) {
      return null;
    }

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <StepsTabs
          mode={'create'}
          loading={teamPage.loading}
          stepsStore={stepsStore}
          page={teamPage}
          formSubmit={() => teamPage.submit({createTeamMutation, teamMembers})}
          cancel={teamPage.goToTeamsList}
        />
      </Container>
    );
  }
}

export default injectIntl(NewTeamView);
