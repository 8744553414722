import React, {Component} from 'react';
import PropTypes from 'prop-types';

//helpers
import {getDisplayName} from 'shared/utils/decorator-utils';

/**
 * Injects the reference to the closest element with the scrollbars
 */
export default WrappedComponent => {
  class InjectScrollParent extends Component {
    render() {
      return <WrappedComponent {...this.props} getScrollParent={this.context.getScrollParent} />;
    }
  }

  InjectScrollParent.contextTypes = {
    getScrollParent: PropTypes.func
  };

  InjectScrollParent.displayName = getDisplayName(WrappedComponent, 'InjectScrollParent');

  return InjectScrollParent;
};
