import React, {Component} from 'react';
import {passInputProps} from 'shared/utils/input-utils';

//styled-components
import {InputWithIcon, Input, Icon, StyledError, InputAndIcon} from './styles';

class InputWithIconComponent extends Component {
  render() {
    const {icon, type, className, styles = {}, error, autofocus} = this.props;

    return (
      <InputWithIcon className={className}>
        <InputAndIcon styles={styles.InputAndIcon}>
          <Input autoFocus={autofocus} type={type} {...passInputProps(this.props)} />
          {icon && <Icon name={icon} />}
        </InputAndIcon>
        <StyledError visible={error}> {error} </StyledError>
      </InputWithIcon>
    );
  }
}

export default InputWithIconComponent;
