import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import Dialog from 'ui-components/Dialog';

@inject('store')
@injectIntl
@observer
class TeamHasPendingApprovalsDialog extends Component {
  render() {
    const {
      store: {
        teamHasPendingApprovalsDialog: {opened, cancel, submit, content}
      }
    } = this.props;

    const actions = [{label: content.buttonText, onClick: submit}];

    return (
      <Dialog actions={actions} isOpen={opened} onCancel={cancel} title={content.title} titleIconId="warning">
        {content.message}
      </Dialog>
    );
  }
}

export default TeamHasPendingApprovalsDialog;
