import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkUniqueSlug} from 'utils/validation-utils';

const asyncRules = {
  checkDomainSlug: (value, attr, key, passes) => {
    return checkUniqueSlug(value, passes);
  }
};

const plugins = dvrWithAsync(asyncRules);

const fields = [
  {
    name: 'name',
    rules: 'required|string'
  },
  {
    name: 'slug',
    rules: 'required|string|is-workspace-slug|checkDomainSlug'
  },
  {
    name: 'password',
    rules: 'string'
  },
  {
    name: 'themeId',
    rules: 'required|string'
  },
  {
    name: 'managementTeamId',
    rules: 'required|string'
  },
  {
    name: 'defaultTeamId',
    rules: 'string'
  },
  {
    name: 'confirmPassword',
    rules: 'string'
  },
  {
    type: 'checkbox',
    name: 'enableXMReality',
    rules: 'boolean'
  },
  {
    type: 'checkbox',
    name: 'emailPassword',
    rules: 'boolean'
  },
  {
    type: 'checkbox',
    name: 'workspacePassword',
    rules: 'boolean'
  },
  {
    name: 'xMRealityTeamId',
    rules: 'numeric'
  },
  {
    name: 'dispatchSiteId',
    rules: 'string'
  },
  {
    name: 'allowedIpRanges',
    rules: 'string'
  }
];

export default () => new MobxReactForm({fields}, {plugins});
