import React, {useEffect} from 'react';
import {injectIntl} from 'react-intl';
import {useMutation, useQuery} from '@apollo/client';
import {inject, observer} from 'mobx-react';

import {UpdateTagCreationRole} from 'api/platform/mutations';
import {updateTagCreationRoleOptionsForHook} from 'api/platform/mutation-options';
import {Platform} from 'api/platform/queries';

import {tagCreationRoleOptions} from './constants';
import messages from './messages';
import {SectionWrapper, StyledTagsCreationSelect, WrapperInfo, Header} from './styles';

const TagCreationRole = props => {
  const {data} = useQuery(Platform, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      const {
        store: {
          platformSettingsPage: {setTagCreationRoleForm}
        }
      } = props;
      const {platformForCms} = data;
      setTagCreationRoleForm(platformForCms);
    }
  }, [data]);

  const {
    intl: {formatMessage},
    store: {
      platformSettingsPage: {updateTagCreationRole}
    },
    tagCreationRoleForm
  } = props;
  const {tagCreation, tagCreationRole} = messages;
  const [updateTagCreationRoleMutation] = useMutation(UpdateTagCreationRole);

  return (
    <SectionWrapper>
      <Header>{formatMessage(tagCreation)}</Header>
      <WrapperInfo>{formatMessage(tagCreationRole)}</WrapperInfo>
      <StyledTagsCreationSelect
        allowClear={false}
        options={tagCreationRoleOptions}
        field={'tagCreationRole'}
        form={tagCreationRoleForm}
        onChange={() =>
          updateTagCreationRole(
            updateTagCreationRoleMutation,
            updateTagCreationRoleOptionsForHook,
            tagCreationRoleForm.values()
          )
        }
        disabled={false}
      />
    </SectionWrapper>
  );
};

export default injectIntl(inject('store')(observer(TagCreationRole)));
