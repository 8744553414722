import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditSignOff.visibility',
    defaultMessage: 'Visibility'
  },
  subtitle: {
    id: 'SignOff.visibility.subtitle',
    defaultMessage: 'The sign-off “{title}” is applied to the following instructions'
  },
  searchBy: {
    id: 'SignOff.visibility.search',
    defaultMessage: 'Search by guide title'
  },
  tableHeader: {
    id: 'SignOff.visibility.tableHeader',
    defaultMessage: 'Guide title'
  },
  noGuides: {
    id: 'SignOff.visibility.noGuides',
    defaultMessage: 'This Signoff has not been applied to any guide'
  },
  noGuidesFound: {
    id: 'SignOff.visibility.noGuidesFound',
    defaultMessage: 'No guides found for "{searchTerm}"'
  },
  error: {
    id: 'SignOff.visibility.error',
    defaultMessage: 'There has been an error while fetching the data'
  }
});

export default messages;
