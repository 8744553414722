import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';

//messages
import messages from './messages';

@inject('store')
@observer
class SSOErrorDialogComponent extends Component {
  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {router} = store;
    const {ssoErrorCode = '', ssoErrorMessage = ''} = router.queryParams || {};
    let message = '';

    let actions = [
      {
        label: formatMessage(messages.ok),
        design: 'text',
        onClick: () => {
          if (ssoErrorCode) {
            router.queryParams.ssoErrorCode = '';
          }
          if (ssoErrorMessage) {
            router.queryParams.ssoErrorMessage = '';
          }
        }
      }
    ];

    if (ssoErrorCode === 'SSO0020') {
      actions = [
        {
          label: formatMessage(messages.viewContent),
          design: 'text',
          href: '/'
        }
      ];
    }

    const isOpen = !!ssoErrorCode || !!ssoErrorMessage;
    if (ssoErrorMessage) {
      message = ssoErrorMessage;
    } else if (ssoErrorCode) {
      message = messages[ssoErrorCode] ? (
        <FormattedMessage {...messages[ssoErrorCode]} />
      ) : (
        <FormattedMessage {...messages.defaultErrorCode} values={{ssoError: ssoErrorCode}} />
      );
    }

    return (
      <Dialog actions={() => actions} isOpen={isOpen} title={formatMessage(messages.title)}>
        {message}
      </Dialog>
    );
  }
}

export default injectIntl(SSOErrorDialogComponent);
