import React, {Component} from 'react';
import {computed, makeObservable} from 'mobx';
import {inject, observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {injectIntl} from 'react-intl';
import PropTypes from 'prop-types';
import {DEFAULT_TOPIC_SLUG} from 'config/constants';

//lodash
import every from 'lodash/every';
import get from 'lodash/get';
import includes from 'lodash/includes';

//helpers
import {INSTRUCTION_EDIT_MODE} from 'config/enums';
import {isDraftVersionId, ensureDraftVersion} from 'utils/versioning-utils';

//components
import EditTopic from 'components/EditTopic';
import TopicMenu from 'components/TopicList/TopicMenu';
import {FormattedMessage} from 'components/FormattedComponents';
import DraggableInstructionCard from '../DraggableInstructionCard';
import DroppableNewInstructionCard from '../DroppableNewInstructionCard';
import InstructionCard from '../InstructionCard';
import NewInstructionCard from '../NewInstructionCard';

//styled-components
import {
  DefaultTitle,
  EditableTitle,
  Header,
  InnerWrapper,
  Instructions,
  StyledCardToolbar,
  StyledDragHandle,
  Summary,
  SummaryInner,
  TopicCard
} from './styles';

//messages
import messages from './messages';

@inject('store')
@observer
class TopicCardComponent extends Component {
  static propTypes = {
    design: PropTypes.oneOf(['default', 'ghostly', 'opaque']),
    dragHandleRef: PropTypes.func,
    hostRef: PropTypes.func,
    isChildrenDragAndDropDisabled: PropTypes.bool,
    onDragHandleMouseDown: PropTypes.func,
    topic: PropTypes.shape({
      instructions: MobxPropTypes.arrayOrObservableArrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired
        })
      ).isRequired,
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  };

  static defaultProps = {
    design: 'default',
    isChildrenDragAndDropDisabled: false
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {design, hostRef, isChildrenDragAndDropDisabled, store, topic} = this.props;
    const {dragDropItems, newTopicPage} = store;
    const {instructions} = topic;

    const creatingTopic = newTopicPage.opened;

    const NewInstructionCardComponent = isChildrenDragAndDropDisabled
      ? NewInstructionCard
      : DroppableNewInstructionCard;

    return (
      <TopicCard design={design} ref={hostRef} data-cy="topic-card">
        <InnerWrapper isInvisible={design === 'ghostly'}>
          {this.renderHeader()}
          {dragDropItems.areTopicsCollapsed && (
            <Summary>
              <SummaryInner>
                <FormattedMessage {...messages.instructionCount} values={{count: this.instructionCount}} />
              </SummaryInner>
            </Summary>
          )}
          {!dragDropItems.areTopicsCollapsed && (
            <Instructions key={topic.id}>
              {instructions.map((instruction, position) => this.renderInstruction(instruction, position))}
              {this.creatingInstruction && <InstructionCard />}
              {!this.creatingInstruction && !creatingTopic && <NewInstructionCardComponent topic={topic} />}
            </Instructions>
          )}
        </InnerWrapper>
      </TopicCard>
    );
  }

  renderHeader() {
    const {dragHandleRef, onDragHandleMouseDown, store, topic} = this.props;
    const {dragDropItems, editGuidePage} = store;
    const {providedTranslations, title} = topic;
    const {guide} = editGuidePage;

    if (this.editingTopic) {
      return <EditTopic topic={topic} />;
    }

    if (topic.slug === DEFAULT_TOPIC_SLUG) {
      return (
        <Header data-cy="default-topic-card">
          <DefaultTitle data-cy="default-topic-title">
            <FormattedMessage {...messages.defaultTopic} />
          </DefaultTitle>
        </Header>
      );
    }

    const canTranslate = guide && guide.canTranslate;

    const isFullyTranslated = every(get(guide, 'availableTranslations'), translation =>
      includes(providedTranslations, translation)
    );

    return (
      <Header data-cy="topic-card" id={topic.id}>
        <StyledDragHandle
          forwardedRef={dragHandleRef}
          isDisabled={dragDropItems.submitting}
          onMouseDown={onDragHandleMouseDown}
        />
        <EditableTitle onClick={this.handleTitleClick} data-cy="topic-title">
          {title}
        </EditableTitle>
        <StyledCardToolbar
          dropdownMenu={
            <TopicMenu canTranslate={canTranslate} topic={topic} shouldShowUntranslatedIcon={!isFullyTranslated} />
          }
          shouldShowUntranslatedIcon={!isFullyTranslated}
        />
      </Header>
    );
  }

  renderInstruction(instruction, position) {
    const {isChildrenDragAndDropDisabled, store} = this.props;
    const {editGuidePage} = store;
    const {currentVersionId} = editGuidePage;

    const sharedProps = {
      instruction: instruction,
      key: instruction.id,
      position: position
    };

    if (isChildrenDragAndDropDisabled) {
      return <InstructionCard {...sharedProps} />;
    }

    if (!isDraftVersionId(currentVersionId)) {
      return <InstructionCard {...sharedProps} onDragHandleMouseDown={this.handleInstructionDragHandleMouseDown} />;
    }

    return <DraggableInstructionCard {...sharedProps} />;
  }

  get creatingInstruction() {
    const {store, topic} = this.props;
    const {editInstructionPage} = store;

    return editInstructionPage.mode === INSTRUCTION_EDIT_MODE.CREATE && editInstructionPage.topicId === topic.id;
  }

  @computed
  get editingTopic() {
    const {store, topic} = this.props;
    const {editTopicPage} = store;
    const {id} = topic;

    return editTopicPage.isEditing && editTopicPage.topicId === id;
  }

  get instructionCount() {
    const {topic} = this.props;
    const {instructions} = topic;

    if (this.creatingInstruction) {
      return instructions.length + 1;
    }

    return instructions.length;
  }

  handleInstructionDragHandleMouseDown = event => {
    const {store} = this.props;
    const {saveAsDraftDialog} = store;

    event.preventDefault(); // so the focus stays inside the dialog
    saveAsDraftDialog.check();
  };

  handleTitleClick = ensureDraftVersion(() => {
    const {intl, store, topic} = this.props;
    const {formatMessage} = intl;
    const {editTopicPage} = store;

    const translations = {
      error: formatMessage(messages.error),
      success: formatMessage(messages.success),
      updateError: formatMessage(messages.updateError),
      updateSuccess: formatMessage(messages.updateSuccess)
    };

    editTopicPage.startEditing(topic, translations);
  }, this);
}

export default injectIntl(TopicCardComponent);
