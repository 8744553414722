import {refreshSkillsTasks} from './refresh-queries';

export const createSkillTaskOptions = {
  props: ({mutate}) => ({
    createSkillTaskMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshSkillsTasks()]
      })
  })
};

export const updateSkillTaskOptions = {
  props: ({mutate}) => ({
    updateSkillTaskMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshSkillsTasks()]
      })
  })
};

export const deleteSkillTaskOptions = {
  props: ({mutate}) => ({
    deleteSkillTaskMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshSkillsTasks()]
      })
  })
};
