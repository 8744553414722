import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Login.title',
    defaultMessage: 'Login'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  password: {
    id: 'common.forms.password',
    defaultMessage: 'Password'
  },
  forgotPassword: {
    id: 'Login.forgotPassword',
    defaultMessage: 'Forgot password?'
  },
  login: {
    id: 'Login.login',
    defaultMessage: 'Login'
  },
  ssoLogin: {
    id: 'Login.ssoLogin',
    defaultMessage: 'Log in with your company account'
  },
  loginFailure: {
    id: 'Login.loginFailure',
    defaultMessage: "Couldn't log in due to server error."
  },
  invalidCredentials: {
    id: 'Login.invalidCredentials',
    defaultMessage: 'Invalid credentials. Please make sure to use the username and password.'
  },
  divider: {
    id: 'Login.divider',
    defaultMessage: 'or'
  },
  accountLockedFailedAttempts: {
    id: 'Login.accountLockedFailedAttempts',
    defaultMessage:
      'Your account has been locked after too many failed attempts to log-in. Click the forgot password button to reset your password. View the {helpLink} for more information.'
  },
  helpCenter: {
    id: 'Menu.helpCenter',
    defaultMessage: 'Help Center'
  }
});

export default messages;
