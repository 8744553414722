import {defineMessages} from 'react-intl';

const messages = defineMessages({
  pdfExportTo: {
    id: 'pdfExportTo',
    defaultMessage: 'Export to PDF'
  },
  pdfGenerate: {
    id: 'pdfGenerate',
    defaultMessage: 'Generate PDF'
  },
  pdfConsiderEnvironment: {
    id: 'pdfConsiderEnvironmentV2',
    defaultMessage: 'Please consider the planet before printing this Guide.'
  },
  pdfInfoHeader: {
    id: 'pdfInfoHeader',
    defaultMessage: 'Your PDF is being generated'
  },
  pdfInfoDescription: {
    id: 'pdfInfoDescription',
    defaultMessage:
      'Your PDF is being generated and will be downloaded automatically. You can keep working, but please don’t close or refresh this browser window. This will cancel the download.'
  },
  pdfErrorHeader: {
    id: 'pdfErrorHeader',
    defaultMessage: 'Something went wrong'
  },
  pdfErrorDescription: {
    id: 'pdfErrorDescription',
    defaultMessage: 'Please try again later. If the problem continues please contact your support desk.'
  },
  pdfErrorIsAlreadyGenerating: {
    id: 'pdfErrorIsAlreadyGenerating',
    defaultMessage: 'Another PDF is currently being generated. Once it is finished you can export again.'
  },
  ok: {
    id: 'common.actions.ok',
    defaultMessage: 'OK'
  },
  close: {
    id: 'common.actions.close',
    defaultMessage: 'Close'
  },
  language: {
    id: 'EditTranslation.language',
    defaultMessage: 'Language'
  },
  publishedInMultipleLanguage: {
    id: 'publishedInMultipleLanguageV2',
    defaultMessage:
      'Choose the language for your export. Note that special characters may not always display correctly.'
  }
});

export default messages;
