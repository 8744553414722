import {defineMessages} from 'react-intl';

const messages = defineMessages({
  checklistResults: {
    id: 'Menu.checklistResults',
    defaultMessage: 'Checklist results'
  },
  checklists: {
    id: 'checklists',
    defaultMessage: 'Checklists'
  },
  searchChecklists: {
    id: 'searchChecklists',
    defaultMessage: 'Search checklists'
  },
  overview: {
    id: 'overview',
    defaultMessage: 'Overview'
  },
  results: {
    id: 'results',
    defaultMessage: 'Results'
  }
});

export default messages;
