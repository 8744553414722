import {defineMessages} from 'react-intl';

const messages = defineMessages({
  addSkills: {
    id: 'addSkills',
    defaultMessage: 'ADD SKILLS'
  },
  skillProfilesDescription: {
    id: 'skillProfilesDescription',
    defaultMessage: 'Group skills and then assign them to Teams and Job Titles.'
  },
  addTeamAndJobTitle: {
    id: 'addTeamAndJobTitle',
    defaultMessage: 'ADD TEAM & JOB TITLE'
  },
  assignSkillProfileToRole: {
    id: 'assignSkillProfileToRole',
    defaultMessage: 'Assign Skill Profile to a Team or Job Title'
  },
  removeAll: {
    id: 'removeAll',
    defaultMessage: 'Remove All'
  },
  enter: {
    id: 'common.forms.enter',
    defaultMessage: 'Enter'
  },
  generalSettings: {
    id: 'generalSettings',
    defaultMessage: 'General Settings'
  },
  skillProfileName: {
    id: 'common.forms.skillProfileName',
    defaultMessage: 'Skill Profile Name'
  },
  x_teams: {
    id: 'x_teams',
    defaultMessage: '{teamsCount, number} {teamsCount, plural, one {Team} other {Teams}}'
  },
  skill: {
    id: 'skill',
    defaultMessage: 'Skill'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  reskillingFrequency: {
    id: 'repeatEvery',
    defaultMessage: 'Assessment Frequency'
  },
  requiredLevel: {
    id: 'SmartSkills.requiredLevel',
    defaultMessage: 'Required Level'
  },
  selected: {
    id: 'skillProfileRolesDrawer.selected',
    defaultMessage: '{num} selected'
  },
  unavailableTeamsWarning: {
    id: 'SmartSkills.unavailableTeamsWarning',
    defaultMessage: '{teamsCount, plural, =1 {1 team is} other {# teams are}} hidden because of access missing.'
  },
  unableToLoadSkillLevels: {
    id: 'SmartSkills.unableToLoadSkillLevels',
    defaultMessage: 'Unable to load required level details'
  }
});

export default messages;
