//lodash
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

//mobx
import store from 'stores/store';
import {observable, action, computed, makeObservable} from 'mobx';

//helpers
import notification from 'utils/notification-utils';

//models
import EditProfileForm from 'stores/forms/edit-profile-form';

class ProfileSettingsTab {
  form = EditProfileForm;
  formFields = ['fullName'];
  translations = {};

  @observable initialForm;

  constructor() {
    makeObservable(this);
  }

  @action
  setInitialForm = form => {
    this.initialForm = form;
  };

  @action
  cancelForm = () => {
    this.form.update(this.initialForm);
  };

  @action
  reset = () => {
    this.initialForm = null;
  };

  @action
  updateProfile = updateProfileMutation => {
    const {fullName} = this.form.values();
    const userUpdate = {fullName};

    updateProfileMutation({userUpdate}).then(({data}) => {
      store.auth.updateUser(data.updateProfile);
      notification.success(this.translations.updateSuccess);
    });
  };

  @computed
  get formHasChanges() {
    const formValues = pick(this.form.values(), this.formFields);
    const initialFormValues = pick(this.initialForm, this.formFields);
    const formHasChanges = !isEqual(formValues, initialFormValues);
    return formHasChanges;
  }

  setTranslations(translations) {
    this.translations = translations;
  }
}

export default ProfileSettingsTab;
