import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import Container from 'ui-components/Layout/Container';
import {injectIntl} from 'react-intl';
import SmartSkillsPageHeader from 'components/SmartSkillsPageHeader';
import {teamSkillMatrixOptions} from 'api/skill-matrix/query-options';
import {TeamSkillMatrix} from 'api/skill-matrix/queries';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {TeamUserRoles} from 'api/team/queries';
import {teamUserRolesOptions} from 'api/team/query-options';
import SmartSkillsTeamSkillMatrix from 'components/SmartSkillsSkillMatrix/TeamSkillMatrix';
import {Card, CardHeading, CardsContainer, LegendsContainer, SausagesContainer} from 'views/SmartSkillsTeam/styles';
import SmartSkillsLegendItem from 'components/SmartSkillsLegendItem';
import SmartSkillsSausageBar from 'components/SmartSkillsSausageBar';
import {capitalize, sum} from 'lodash';
import Icon from 'ui-components/Layout/Icon';
import Spinner from 'shared/components/Spinner';
import {LEGEND_LEVEL_COLOR_MAP} from 'views/SmartSkillsTeams/constants';
import messages from './messages';
import {commonPalette} from 'shared/styles/palette';

@inject('store')
@graphql(TeamUserRoles, teamUserRolesOptions)
@graphql(TeamSkillMatrix, teamSkillMatrixOptions)
@observer
class SmartSkillsTeam extends Component {
  get totalApprovedSkills() {
    const {teamSkillMatrixQuery} = this.props;
    const {teamSkillMatrix, loading} = teamSkillMatrixQuery;

    if (loading) {
      return 0;
    }

    return teamSkillMatrix
      .map(({users}) => users)
      .flat()
      .filter(({status}) => status === 'APPROVED').length;
  }

  get totalUsersPerSkill() {
    const {teamSkillMatrixQuery} = this.props;
    const {teamSkillMatrix, loading} = teamSkillMatrixQuery;

    if (loading) {
      return 0;
    }

    return teamSkillMatrix.map(skill => skill.users).flat().length;
  }

  // Unlike approvedSkills where you simply count by status === APPROVED
  // here in gaps you need to calculate total gaps using this formula: users per skill - approved
  get totalGaps() {
    return this.totalUsersPerSkill - this.totalApprovedSkills;
  }

  get totalNotAssigned() {
    const {teamUserRolesQuery, teamSkillMatrixQuery} = this.props;
    const {team, loading: teamUserRolesLoading} = teamUserRolesQuery;
    const {teamSkillMatrix, loading: teamSkillMatrixLoading} = teamSkillMatrixQuery;

    if (teamUserRolesLoading || teamSkillMatrixLoading) {
      return 0;
    }

    return team.teamMembers.length * teamSkillMatrix.length - this.totalUsersPerSkill;
  }

  get groupedSkillsByLevel() {
    const {teamSkillMatrixQuery} = this.props;
    const {teamSkillMatrix, loading: teamSkillMatrixLoading} = teamSkillMatrixQuery;

    if (teamSkillMatrixLoading) {
      return {};
    }

    let result = {};

    teamSkillMatrix
      .map(({users}) => users)
      .flat()
      .sort((a, b) => (b.currentLevel?.position ?? -1) - (a.currentLevel?.position ?? -1))
      .map(i => i.currentLevel?.name)
      .filter(Boolean)
      .forEach(level => (result[level] = result[level] ? result[level] + 1 : 1));

    // Total skill with level defined (level != No Level)
    const totalDefinedLevels = sum(Object.values(result));

    result = {
      ...result,
      'NO LEVEL': this.totalUsersPerSkill - totalDefinedLevels
    };

    return result;
  }

  render() {
    const {
      intl: {formatMessage},
      teamUserRolesQuery,
      teamSkillMatrixQuery
    } = this.props;
    const {team, loading: teamUserRolesLoading} = teamUserRolesQuery;
    const {teamSkillMatrix, loading: teamSkillMatrixLoading} = teamSkillMatrixQuery;

    if (teamUserRolesLoading || teamSkillMatrixLoading) {
      return <Spinner centerAbs centerX centerY size={23} />;
    }

    if (!team) {
      return null;
    }

    const headerBottomLine = [
      {
        label: formatMessage(messages.members),
        value: team.teamMembers?.length || 0
      },
      {
        label: formatMessage(messages.skills),
        value: teamSkillMatrix?.length || 0
      }
    ];

    return (
      <Container style={{paddingBottom: '50px', height: 'auto'}}>
        <SmartSkillsPageHeader title={team.name} bottomLine={headerBottomLine} showBackButton />

        {!teamSkillMatrix && <span>{formatMessage(messages.noMembers)}</span>}

        {teamSkillMatrix?.length === 0 && <span>{formatMessage(messages.noMatrix)}</span>}

        {teamSkillMatrix?.length > 0 && (
          <>
            <CardsContainer>
              <Card>
                <CardHeading>{formatMessage(messages.gaps)}</CardHeading>
                <LegendsContainer>
                  <SmartSkillsLegendItem
                    color={commonPalette.iconSuccess}
                    label={formatMessage(messages.statsApproved)}
                  />
                  {/*<SmartSkillsLegendItem color="#E87021" label="Expiring" />*/}
                  <SmartSkillsLegendItem color={commonPalette.iconError} label={formatMessage(messages.gap)} />
                  <SmartSkillsLegendItem color={commonPalette.silver} label={formatMessage(messages.notAssigned)} />
                </LegendsContainer>
                <SausagesContainer>
                  <SmartSkillsSausageBar color={commonPalette.iconSuccess} value={this.totalApprovedSkills} />
                  {/*<SmartSkillsSausageBar color="#009D9A" value={23} />*/}
                  <SmartSkillsSausageBar color={commonPalette.iconError} value={this.totalGaps} />
                  <SmartSkillsSausageBar
                    color={commonPalette.silver}
                    value={this.totalNotAssigned}
                    outline={true}
                    preserveBackground={true}
                  />
                </SausagesContainer>
              </Card>

              <Card>
                <CardHeading>{formatMessage(messages.levels)}</CardHeading>
                <LegendsContainer>
                  {Object.keys(this.groupedSkillsByLevel).map((level, index) => (
                    <SmartSkillsLegendItem
                      key={level}
                      color={LEGEND_LEVEL_COLOR_MAP[index]}
                      outline={level === 'NO LEVEL'}
                      label={capitalize(level)}
                    />
                  ))}
                </LegendsContainer>
                <SausagesContainer>
                  {Object.keys(this.groupedSkillsByLevel).map((level, index) => (
                    <SmartSkillsSausageBar
                      key={level}
                      color={LEGEND_LEVEL_COLOR_MAP[index]}
                      outline={level === 'NO LEVEL'}
                      value={this.groupedSkillsByLevel[level]}
                    />
                  ))}
                </SausagesContainer>
              </Card>
            </CardsContainer>

            <LegendsContainer style={{height: '44px', justifyContent: 'flex-end', marginBottom: '24px'}}>
              <SmartSkillsLegendItem
                color={commonPalette.iconSuccess}
                label={formatMessage(messages.statsApproved)}
                icon={<Icon name={'checkmark'} size={8} />}
              />
              <SmartSkillsLegendItem color={commonPalette.iconError} label={formatMessage(messages.gap)} icon={'!'} />
              <SmartSkillsLegendItem
                color={commonPalette.silver}
                label={formatMessage(messages.notAssigned)}
                icon={'-'}
                iconStyles={{color: commonPalette.txtGrey, fontSize: '12px', lineHeight: 5}}
                outline={true}
                preserveBackground={true}
              />
            </LegendsContainer>

            <SmartSkillsTeamSkillMatrix teamSkillMatrix={teamSkillMatrix} userSkillRoles={team.teamMembers} />
          </>
        )}
      </Container>
    );
  }
}

export default injectIntl(SmartSkillsTeam);
