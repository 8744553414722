import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {mapValues, pick} from 'lodash';

import Dialog from 'ui-components/Dialog';

import Spinner from 'shared/components/Spinner';
import Icon from 'ui-components/Icon';

import {commonPalette} from 'shared/styles/palette';
import {graphql} from '@apollo/client/react/hoc/graphql';

//api
import {CreateStep, UpdateStep} from 'api/step/mutations';
import {createStepOptions, updateStepOptions} from 'api/step/mutation-options';

import messages from './messages';
import {
  StyledHeader,
  StyledContent,
  StyledInfo,
  Section,
  SpinnerWrapper,
  LoadingHeader,
  LoadingSubtitle,
  LoadingWrapper,
  dialogStyles,
  GreenStyledInfo,
  CircleDiv,
  checkIconStyle,
  NoSuggestionWrapper
} from './styles';

import {PARENT_TYPE, STATUS, EVENT_TYPES} from 'api/tracking/constants';
import {trackEvent} from 'utils/tracking/event-tracking';

@inject('store')
@graphql(CreateStep, createStepOptions)
@graphql(UpdateStep, updateStepOptions)
@observer
class SuggestionDialog extends Component {
  updateStepWithSuggestion = async useSuggestion => {
    const {
      store,
      updateStepMutation,
      createStepMutation,
      intl: {formatMessage}
    } = this.props;
    const {
      dragDropSteps: {steps},
      suggestionDialog: {stepId, updateStep, createStep}
    } = store;

    const currentStep = steps.find(step => step.id === stepId);

    const translations = mapValues(
      pick(messages, [
        'saveCheckSucess',
        'saveStepSucess',
        'updateCheckError',
        'updateStepError',
        'createCheckSuccess',
        'createStepSuccess',
        'createCheckError',
        'createStepError'
      ]),
      message => formatMessage(message)
    );

    if (currentStep) {
      await updateStep(useSuggestion, currentStep, updateStepMutation, translations);
    } else {
      await createStep(useSuggestion, createStepMutation, translations);
    }

    trackEvent(EVENT_TYPES.RECOMMENDED_CONTENT, {
      parentId: store.router.params.stepId,
      parentType: PARENT_TYPE.STEP,
      status: useSuggestion ? STATUS.ACCEPTED : STATUS.DECLINED,
      inputText: null,
      outputText: null
    });
  };

  render() {
    const {
      intl: {formatMessage},
      store: {suggestionDialog: dialog}
    } = this.props;
    const {originalText, suggestedText, opened, loading, reset} = dialog;

    const isSuggestionRelevant = originalText !== suggestedText;

    const actions = isSuggestionRelevant
      ? [
          {
            label: formatMessage(messages.useSuggestion),
            onClick: () => this.updateStepWithSuggestion(true)
          },
          {
            label: formatMessage(messages.keepOriginal),
            onClick: () => this.updateStepWithSuggestion(false)
          }
        ]
      : [
          null,
          {
            label: formatMessage(messages.ok),
            onClick: () => this.updateStepWithSuggestion(false)
          }
        ];

    return (
      <Dialog
        actions={actions}
        isOpen={opened}
        onCancel={!loading && reset}
        hideActions={loading}
        dialogStyle={dialogStyles}
        title={formatMessage(messages.textImprovementSuggestion)}
      >
        {loading ? (
          <LoadingWrapper>
            <LoadingHeader>{formatMessage(messages.processing)}</LoadingHeader>
            <SpinnerWrapper>
              <Spinner color={commonPalette.sgGreen} />
            </SpinnerWrapper>
            <LoadingSubtitle>{formatMessage(messages.reviewingYourText)}</LoadingSubtitle>
          </LoadingWrapper>
        ) : (
          <div>
            <Section>
              <StyledHeader>{formatMessage(messages.original)}</StyledHeader>
              <StyledContent>{originalText}</StyledContent>
            </Section>
            {isSuggestionRelevant ? (
              <div>
                <Section>
                  <StyledHeader dark>{formatMessage(messages.suggestion)}</StyledHeader>
                  <StyledContent>{suggestedText}</StyledContent>
                </Section>
                <StyledInfo>{formatMessage(messages.userVerbPlusNoun)}</StyledInfo>
              </div>
            ) : (
              <NoSuggestionWrapper>
                <StyledHeader dark>{formatMessage(messages.noSuggestion)}</StyledHeader>
                <GreenStyledInfo>
                  <CircleDiv>
                    <Icon iconId="checkmark" style={checkIconStyle} />
                  </CircleDiv>
                  <p>{formatMessage(messages.looksGreat)}</p>
                </GreenStyledInfo>
              </NoSuggestionWrapper>
            )}
          </div>
        )}
      </Dialog>
    );
  }
}

export default injectIntl(SuggestionDialog);
