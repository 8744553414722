import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {action, makeObservable, observable} from 'mobx';
import groupBy from 'lodash/groupBy';

import messages from './messages';
import {GrayText, SearchBarContainer} from './styles';
import Drawer from 'components/Drawer';
import {
  BackButton,
  BackIcon,
  ChevronIcon,
  DrawerDescription,
  DrawerTitle,
  DrawerWrapper,
  ListContainer,
  ListGroup,
  ListGroupItem,
  ListGroupItemsContainer,
  ListGroupTitleItem,
  StyledCheckbox
} from 'components/SkillProfileRolesDrawer/styles';
import {Input, SearchIcon} from 'ui-components/Layout/SearchBar/styles';
import {TableInfoDiv as NoSkillsDiv, StyledSubText, StyledText} from 'views/Skills/styles';
import Icon from 'ui-components/Layout/Icon';

@inject('store')
@observer
class SkillProfilesRolesDrawerComponent extends Component {
  @observable openedTeams = {};
  // object with `teamId` or `teamId|roleId` as key and boolean as a value
  @observable selectedItems = {};

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      store: {
        skillProfilePage: {selectedJobTitles, availableSkillRoles, groupJobTitlesByAvailableNotAvailable}
      }
    } = props;

    const {available} = groupJobTitlesByAvailableNotAvailable(selectedJobTitles);

    // update selected items state from store
    this.selectedItems = available.reduce((acc, {team, roles}) => {
      const teamId = team.id;
      const teamRolesIds = (roles || []).map(role => this.skillRoleId(teamId, role.id));
      // teams is selected but it has no roles selected
      acc[teamId] = teamRolesIds.length === 0;

      if (teamRolesIds?.length) {
        teamRolesIds.forEach(teamRoleId => {
          acc[teamRoleId] = true;
        });
      } else {
        // Team is check -> mark all skill roles as checked
        availableSkillRoles.forEach(role => {
          acc[this.skillRoleId(teamId, role.id)] = true;
        });
      }

      return acc;
    }, {});
  }

  skillRoleId = (teamId, roleId) => {
    return teamId + '|' + roleId;
  };

  @action
  toggleTeamAccordion(teamId) {
    this.openedTeams[teamId] = !this.openedTeams[teamId];
  }

  @action
  toggleTeam(teamId, skillRoles) {
    const newState = !this.selectedItems[teamId];
    this.selectedItems[teamId] = newState;

    skillRoles.forEach(role => {
      this.selectedItems[this.skillRoleId(teamId, role.id)] = newState;
    });

    this.emitOnChange();
  }

  @action
  toggleRole(teamId, roleId) {
    const key = this.skillRoleId(teamId, roleId);
    this.selectedItems[teamId] = false;
    this.selectedItems[key] = !this.selectedItems[key];
    this.emitOnChange();
  }

  emitOnChange() {
    const {
      onChange,
      store: {
        skillProfilePage: {availableSkillRoles, availableTeams}
      }
    } = this.props;

    // Create hash stores of teams and skill roles to find by id
    const availableTeamsById = availableTeams.reduce((acc, team) => {
      acc[team.id] = team;
      return acc;
    }, {});

    const availableSkillRolesById = availableSkillRoles.reduce((acc, role) => {
      acc[role.id] = role;
      return acc;
    }, {});

    // Build selected values arrays
    const selectedInUiTeamsIds = [];
    const selectedInUiRolesIds = [];

    // Omit falsy properties from selectedItems and form arrays of selected skill roles ids and selected teams ids
    Object.keys(this.selectedItems).forEach(key => {
      if (this.selectedItems[key]) {
        if (key.includes('|')) {
          selectedInUiRolesIds.push(key);
        } else {
          selectedInUiTeamsIds.push(key);
        }
      }
    });

    const rolesByTeams = groupBy(selectedInUiRolesIds, item => item.split('|')[0]);

    // Filter out roles which belong to selected teams, just in case
    selectedInUiTeamsIds.forEach(teamId => {
      delete rolesByTeams[teamId];
    });

    // Build the final object
    const teams = selectedInUiTeamsIds.map(teamId => ({
      team: availableTeamsById[teamId],
      roles: []
    }));

    const roles = Object.keys(rolesByTeams).map(teamId => ({
      team: availableTeamsById[teamId],
      roles: rolesByTeams[teamId].map(teamRoleId => availableSkillRolesById[teamRoleId.split('|')[1]])
    }));

    onChange([...teams, ...roles]);
  }

  numberOfSelectedRoles(team) {
    const {
      intl: {formatMessage}
    } = this.props;

    if (this.selectedItems[team.id]) {
      return <></>;
    }

    const selectedRolesNumber = Object.keys(this.selectedItems).filter(
      key => this.selectedItems[key] && key.includes(team.id + '|')
    ).length;

    if (!selectedRolesNumber) {
      return null;
    }

    return (
      <GrayText>
        {'('}
        {formatMessage(messages.selected, {num: selectedRolesNumber})}
        {')'}
      </GrayText>
    );
  }

  teamItem(team, skillRoles) {
    return (
      <ListGroup key={team.id}>
        <ListGroupTitleItem onClick={() => this.toggleTeamAccordion(team.id)}>
          <ChevronIcon open={this.openedTeams[team.id]} data-cy={`expand-${team.name}`} />
          <p>
            {team.name} {this.numberOfSelectedRoles(team)}
          </p>

          <StyledCheckbox
            type="checkbox"
            onClick={e => e.stopPropagation()}
            onChange={() => this.toggleTeam(team.id, skillRoles)}
            checked={this.selectedItems[team.id] ?? false}
            data-cy={team.name}
          />
        </ListGroupTitleItem>
        <ListGroupItemsContainer opened={this.openedTeams[team.id]} itemCount={skillRoles.length}>
          {skillRoles.map(role => (
            <ListGroupItem key={role.id}>
              <p>{role.name}</p>

              <StyledCheckbox
                type="checkbox"
                onChange={() => this.toggleRole(team.id, role.id)}
                checked={this.selectedItems[this.skillRoleId(team.id, role.id)] ?? false}
                data-cy={`checkbox-${role.name}-${team.name}`}
              />
            </ListGroupItem>
          ))}
        </ListGroupItemsContainer>
      </ListGroup>
    );
  }

  render() {
    const {
      intl: {formatMessage},
      isOpen,
      onClose,
      store: {
        skillProfilePage: {setSearchTerm, availableSkillRoles, availableTeams, skillsFilters}
      }
    } = this.props;

    const searchQuery = skillsFilters?.name?.contains || '';
    const filteredTeams = availableTeams.filter(team =>
      (team.name || '').toLowerCase().includes(searchQuery.toLowerCase())
    );
    const hasTeams = availableTeams.length > 0;
    const hasSearch = Boolean(searchQuery);

    return (
      <Drawer isOpen={isOpen} close={onClose}>
        <DrawerWrapper>
          <DrawerTitle>
            <BackButton onClick={onClose} data-cy="drawer-close">
              <BackIcon />
            </BackButton>
            {formatMessage(messages.title)}
          </DrawerTitle>
          <DrawerDescription>{formatMessage(messages.description)}</DrawerDescription>

          <SearchBarContainer>
            <Input
              type="text"
              placeholder={formatMessage(messages.searchByTeamOrRole)}
              value={searchQuery}
              onChange={event => setSearchTerm(event.target.value)}
            />
            <SearchIcon />
          </SearchBarContainer>

          <ListContainer>
            {filteredTeams?.length > 0 ? (
              filteredTeams.map(team => this.teamItem(team, availableSkillRoles))
            ) : (
              <NoSkillsDiv>
                <Icon data-cy="smart-skills" name="smart-skills" size={25} />
                {hasTeams && hasSearch ? (
                  <>
                    <StyledText>{formatMessage(messages.searchEmpty)}</StyledText>
                    <StyledSubText>{formatMessage(messages.adjustSearch)}</StyledSubText>
                  </>
                ) : (
                  <>
                    <StyledText>{formatMessage(messages.noTeamsFound)}</StyledText>
                    <StyledSubText>{formatMessage(messages.createFirstTeam)}</StyledSubText>
                  </>
                )}
              </NoSkillsDiv>
            )}
          </ListContainer>
        </DrawerWrapper>
      </Drawer>
    );
  }
}

export default injectIntl(SkillProfilesRolesDrawerComponent);
