import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {makeObservable, observable, action} from 'mobx';

import messages from './messages';
import {
  SearchBarContainer,
  StyledInfiniteScroll,
  FixedContainer,
  SpinnerWrapper,
  ErrorWrapper,
  ListContainer
} from './styles';
import Drawer from 'components/Drawer';
import {
  BackButton,
  BackIcon,
  DrawerDescription,
  DrawerTitle,
  DrawerWrapper,
  ListGroup,
  ListGroupTitleItem,
  StyledCheckbox
} from 'components/SkillProfileRolesDrawer/styles';
import {DropwdownItem, StyledText} from '../../views/Skills/styles';
import {Input, SearchIcon} from 'ui-components/Layout/SearchBar/styles';
import Spinner from 'shared/components/Spinner';
import {Filters} from 'components/Filters/Filters';
import TagPicker from '../../components/TagPicker';
import InfoBox from 'ui-components/InfoBox';
import FiltersChips from 'components/FiltersChips';

const MAX_SELECTED = 10;

@inject('store')
@observer
class SkillsDrawer extends Component {
  @observable selectedItems = {};

  UNSAFE_componentWillReceiveProps(props) {
    const {
      store: {
        skillProfilePage: {selectedSkills}
      }
    } = props;

    this.selectedItems = selectedSkills.reduce((acc, skill) => {
      acc[skill.id] = true;
      return acc;
    }, {});
  }

  async componentDidMount() {
    this.props.store.skillProfilePage.fetchSkills();
  }

  componentWillUnmount() {
    this.props.store.skillProfilePage.searchSkills.cancel();
  }

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action toggleSkill(skillId) {
    this.selectedItems[skillId] = !this.selectedItems[skillId];
    this.emitOnChange();
  }

  emitOnChange() {
    const {
      onChange,
      store: {
        skillProfilePage: {availableSkills, selectedSkills, competentSkillLevel, shouldSkillsHaveRequiredLevel}
      }
    } = this.props;

    const availableSkillsById = availableSkills.reduce((acc, skill) => {
      acc[skill.id] = skill;
      return acc;
    }, {});

    const selectedSkillsById = selectedSkills.reduce((acc, skill) => {
      acc[skill.id] = skill;
      return acc;
    }, {});

    const skillsById = {
      ...availableSkillsById,
      // @NB selected should override available to preserve requiredLevel and frequency selection
      ...selectedSkillsById
    };

    const selectedIds = Object.keys(this.selectedItems)
      .filter(key => this.selectedItems[key] === true)
      .map(id => ({
        ...skillsById[id],
        requiredLevelId: skillsById[id].requiredLevel?.id || skillsById[id].requiredLevelId,
        frequency: null
      }));

    if (!shouldSkillsHaveRequiredLevel) {
      selectedIds.forEach(skill => {
        skill.requiredLevel = competentSkillLevel;
        skill.requiredLevelId = competentSkillLevel?.id;
      });
    }

    onChange(selectedIds);
  }

  skillItem(skill) {
    return (
      <ListGroup key={skill.id}>
        <ListGroupTitleItem>
          <p>{skill.name}</p>

          <StyledCheckbox
            type="checkbox"
            onClick={e => e.stopPropagation()}
            onChange={() => this.toggleSkill(skill.id)}
            checked={this.selectedItems[skill.id] ?? false}
            data-cy={skill.name}
          />
        </ListGroupTitleItem>
      </ListGroup>
    );
  }

  closeDrawer = () => {
    const {
      onClose,
      store: {
        skillProfilePage: {searchSkills}
      }
    } = this.props;

    searchSkills('');

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  render() {
    const {
      intl: {formatMessage},
      isOpen,
      store: {
        skillProfilePage: {
          availableSkills,
          skillsLoading,
          showInfiniteLoader,
          skillsFilters,
          setSearchTerm,
          skillsLoadingError,
          searchSkills,
          fetchMore,
          filterByTag,
          resetFilters,
          localSelectedTags,
          setLocalSelectedTags,
          selectedTags,
          removeSelectedTag
        }
      }
    } = this.props;

    return (
      <Drawer isOpen={isOpen} close={this.closeDrawer}>
        <DrawerWrapper>
          <FixedContainer>
            <DrawerTitle>
              <BackButton onClick={this.closeDrawer} data-cy="drawer-close">
                <BackIcon />
              </BackButton>
              {formatMessage(messages.title)}
            </DrawerTitle>
            <DrawerDescription>{formatMessage(messages.description)}</DrawerDescription>
            <SearchBarContainer>
              <Input
                type="text"
                value={skillsFilters.name.contains}
                placeholder={formatMessage(messages.searchBySkillName)}
                onChange={event => {
                  const term = event.target?.value.substring(0, 50);
                  setSearchTerm(term);
                  searchSkills(term);
                }}
              />
              <SearchIcon />
            </SearchBarContainer>
            <Filters
              filtersTitle={formatMessage(messages.filtersTitle)}
              formatMessage={formatMessage}
              onApply={filterByTag}
              onReset={resetFilters}
              totalFiltersApplied={skillsFilters?.tags.in.length}
            >
              {localSelectedTags.length >= MAX_SELECTED && (
                <InfoBox content={formatMessage(messages.tenTags)} type="informational" size="small" />
              )}

              <DropwdownItem>
                <StyledText>{formatMessage(messages.filterByTag)}</StyledText>
                <TagPicker
                  selectedTagsIds={localSelectedTags.map(tag => tag.id)}
                  setLocalSelectedTags={setLocalSelectedTags}
                  localSelectedTags={localSelectedTags}
                  showCheckBoxSelector
                  disableCreate
                  isSkillTagsPicker={true}
                />
              </DropwdownItem>
            </Filters>
            <FiltersChips items={selectedTags} removeItem={removeSelectedTag}></FiltersChips>
          </FixedContainer>

          {availableSkills?.length > 0 && (
            <ListContainer>{availableSkills.map(skill => this.skillItem(skill))}</ListContainer>
          )}

          {skillsLoading && (
            <SpinnerWrapper hasSkills={availableSkills?.length}>
              <Spinner />
            </SpinnerWrapper>
          )}

          {skillsLoadingError && <ErrorWrapper>{formatMessage(messages.error)}</ErrorWrapper>}

          {!availableSkills?.length && !skillsLoading && (
            <ErrorWrapper>{formatMessage(messages.noSkillsFound)}</ErrorWrapper>
          )}

          {showInfiniteLoader && !skillsLoadingError && (
            <StyledInfiniteScroll isLoading={skillsLoading} onBottom={fetchMore} spinnerSize={false} threshold={200} />
          )}
        </DrawerWrapper>
      </Drawer>
    );
  }
}

export default injectIntl(SkillsDrawer);
