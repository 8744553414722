import {defineMessages} from 'react-intl';

const messages = defineMessages({
  properties: {
    id: 'MediaProperties.properties',
    defaultMessage: 'Properties'
  },
  uploaded: {
    id: 'MediaProperties.uploaded',
    defaultMessage: 'Uploaded'
  },
  usages: {
    id: 'MediaProperties.usages',
    defaultMessage: 'Usages'
  },
  focus: {
    id: 'MediaProperties.focus',
    defaultMessage: 'Focus'
  },
  name: {
    id: 'MediaProperties.name',
    defaultMessage: 'Name'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  edit_media_name: {
    id: 'MediaProperties.edit_media_name',
    defaultMessage: 'Edit media name'
  },
  untitled: {
    id: 'MediaProperties.untitled',
    defaultMessage: 'Untitled'
  },
  successfulEditName: {
    id: 'MediaProperties.successfulEditName',
    defaultMessage: 'Media name successfully edited'
  },
  failedEditName: {
    id: 'MediaProperties.failedEditName',
    defaultMessage: 'Failed to edit media name'
  }
});

export default messages;
