import styled from 'styled-components';

//helpers
import {topicCardMetrics} from 'styles/metrics';
import {commonPalette} from 'shared/styles/palette';
import {interactiveShadowFromNoneStyle} from 'styles/shadows';

//components
import $Icon from 'ui-components/Layout/Icon';

export const NewTopicCard = styled.div`
  ${interactiveShadowFromNoneStyle};
  background: ${commonPalette.darkestGray};
  cursor: pointer;
  margin: 0;
  padding: 28px ${topicCardMetrics.detailsPaddingX}px 29px;
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 26px;
  font-weight: normal;
`;

export const Description = styled.div``;

export const PlusIcon = styled($Icon).attrs({
  name: 'plus',
  size: 26 // matches the font size
})`
  margin-left: ${p => -p.size / 2}px; // hanging punctuation
  margin-right: 0.26em; // single space width
  vertical-align: -0.15em;
`;
