import React from 'react';
import {injectIntl} from 'react-intl';

//components
import {FormattedMessage} from 'components/FormattedComponents';

//styles
import {BottomSection, CloseButton, FiltersContainer, Field, Label, Title, TopSection} from './styles';

//messages
import messages from './messages';

//types
import propTypes from './types';

const FiltersContainerComponent = props => {
  const {className, filterEntries, intl, onReset} = props;
  const {formatMessage} = intl;

  return (
    <FiltersContainer className={`FilterContainersWrapper ${className}`}>
      <TopSection>
        <Title>
          <FormattedMessage {...messages.filters} />
        </Title>
        <CloseButton data-testid={'reset-button'} label={formatMessage(messages.resetAll)} onClick={onReset} />
      </TopSection>
      <BottomSection>
        {filterEntries.map(({label, component: FilterEntry}) => (
          <Field key={label}>
            <Label>{label}</Label>
            <FilterEntry />
          </Field>
        ))}
      </BottomSection>
    </FiltersContainer>
  );
};

FiltersContainerComponent.propTypes = propTypes;

export default injectIntl(FiltersContainerComponent);
