import styled from 'styled-components';

import DataTable from 'ui-components/Layout/DataTable';
import FiltersContainer from 'components/FiltersContainer';
import $Combobox from 'ui-components/Combobox';
import {CellWrapper as DefaultCellWrapper} from 'ui-components/Layout/DataTable/styles';

export const StyledTable = styled(DataTable)`
  .rt-table {
    overflow: auto !important;
  }

  .rt-tbody {
    overflow: unset !important;
  }

  .rt-td {
    display: flex;
    align-items: center;
  }
`;

export const StyledCombobox = styled($Combobox).attrs({
  buttonProps: {
    style: {
      textAlign: 'left'
    }
  }
})``;

export const FiltersWrapper = styled(FiltersContainer)`
  margin-bottom: 30px;
`;

export const LoadingWrapper = styled.div`
  position: relative;
  margin-top: 30px;
`;

// min-height is needed in order to avoid filters being cutoff for containers with less than 550px
export const ChecklistAnswersWrapper = styled.div`
  min-height: 550px;
`;

export const CellWrapper = styled(DefaultCellWrapper)`
  display: block;
  flex: unset;
`;
