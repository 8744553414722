import {defineMessages} from 'react-intl';

const messages = defineMessages({
  team: {
    id: 'common.forms.team',
    defaultMessage: 'Team'
  },
  assignee: {
    id: 'ReassignGuide.assignee',
    defaultMessage: 'Assignee'
  },
  comments: {
    id: 'ReassignGuide.comments',
    defaultMessage: 'Comment(s)'
  },
  selectPersonPlaceholder: {
    id: 'ReassignGuide.selectPersonPlaceholder',
    defaultMessage: 'Select a person'
  },
  noTeamsAvailable: {
    id: 'ReassignGuide.noTeamsAvailable',
    defaultMessage: 'There are no teams available to reassign.'
  }
});

export default messages;
