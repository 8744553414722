import {defineMessages} from 'react-intl';

const messages = defineMessages({
  authenticationError: {
    id: 'AuthError.authenticationError',
    defaultMessage: 'Authentication Error'
  },
  cannotAccessCMS: {
    id: 'AuthError.cannotAccessCMS',
    defaultMessage: 'Cannot access the Platform CMS'
  },
  accountOnlyInfo: {
    id: 'AuthError.accountOnlyInfo',
    defaultMessage:
      'You are trying to log into the platform editor. Currently, your account only allows you to log in to the platform viewer. Please contact your administrator for editing rights.'
  },
  adminAccountCMS: {
    id: 'AuthError.adminAccountCMS',
    defaultMessage: 'Sign out and sign in with an admin account to access the CMS.'
  },
  viewContent: {
    id: 'AuthError.viewContent',
    defaultMessage: 'View Content'
  },
  logoutViewer: {
    id: 'AuthError.logoutViewer',
    defaultMessage: 'Logout'
  }
});

export default messages;
