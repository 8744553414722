export const getTranslationsForGuideOptions = {
  name: 'translations',
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        guideId: id
      },
      fetchPolicy: 'network-only'
    };
  }
};

export const getTranslationRateOptionsNoCache = {
  name: 'translationRateQuery',
  options: ({store}) => {
    const {id} = store.router.params;
    return {
      variables: {
        id: id
      },
      fetchPolicy: 'cache-only'
    };
  }
};
