import styled, {css} from 'styled-components';
import flex from 'styles/flex';
import colors from 'styles/colors';
import {cond} from 'shared/utils/sc-utils';
import {swallowingStyled} from 'styles/styled-components/utils';
import {commonPalette} from 'shared/styles/palette';
import {svgElementsFillCurrentColor} from 'styles/vars';

import $DataTable from 'ui-components/Layout/DataTable';
import $Field from 'ui-components/Field';
import $Dialog from 'ui-components/Dialog';
import $Icon from 'ui-components/Layout/Icon';
import {Error} from 'shared/styles/styled-components/field-styled-components';
import {Divider} from 'ui-components/Dialog';

//helpers
import typefaces from 'shared/styles/typefaces';

//external
import Dropzone from 'react-dropzone';

//styled
import {TransparentButton} from 'styles/styled-components/shared-components';

export const overlayClassName = 'c-upload-image__overlay';

//constants
const contentPadding = '10px 16px';
const contentPullout = '-10px -16px';

export const UploadIconDropzone = swallowingStyled(Dropzone, {
  swallowProps: ['imageThumbnail', 'styles', 'loadingImage']
})`
    ${flex.vertical}
    ${flex.centerVertical}
    transition: all 150ms linear;
    width: 100%;
    height: 148px;
    background-color: white;
    position: relative;
    text-align: center;
    color: ${commonPalette.txBlack};
    border: 1px dashed rgb(108,108,108);
    margin-top:15px;
    margin-bottom:15px;
  
    ${p => cond(p.imageThumbnail, `background: url('${p.imageThumbnail}') center / cover no-repeat;`)}
  
    ${p =>
      cond(
        p.loadingImage === true,
        css`
          .${overlayClassName} {
            opacity: 0.8;
          }
        `
      )}
  
    ${p =>
      cond(
        p.loadingImage === false,
        css`
          &:hover {
            .${overlayClassName} {
              cursor: pointer;
              opacity: 0.5;
            }
          }
        `
      )}
  
    ${p =>
      cond(
        !p.imageThumbnail && !p.loadingImage,
        css`
          &:hover {
            cursor: pointer;
          }
        `
      )}
  
    ${p => p.styles}
  `;

export const UploadIconActiveStyle = {
  border: `3px dashed ${colors.primary}`
};

export const UploadSvgIconPreview = styled.div`
  color: ${colors.black};
  > svg {
    width: auto;
    height: 100px;
    fill: currentColor !important;

    ${svgElementsFillCurrentColor}
  }
`;

export const UploadAndTrash = styled.div`
  ${flex.horizontal} ${flex.centerHorizontalV};
`;

export const TrashButton = styled(TransparentButton)`
  margin-left: 15px;
`;

export const ChangeButton = styled(TransparentButton)`
  margin-left: 15px;
`;

export const Text = styled.div`
  font-size: 0.875rem;
  line-height: 1.33;
  color: ${colors.txBlack};
`;

export const TextGrey = styled(Text)`
  color: ${commonPalette.txGrey};
`;

export const DropboxCaption = styled.div`
  font-size: 0.75rem;
  line-height: 1.33;
  color: ${commonPalette.txGrey};
  font-style: italic;
  margin-bottom: 1.2rem;
`;

export const TextUnderlined = styled(Text)`
  text-decoration: underline;
`;

export const StyledDataTable = styled($DataTable).attrs({})`
  ${flex.vertical};
  max-width: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;

  > .ReactTable {
    max-height: 100%;
    overflow: hidden;
  }
`;

export const StyledField = styled($Field).attrs({
  styles: {
    Input: css`
      ${typefaces.basic};
      background: none;
      border: 0 !important;
      box-shadow: none !important;
      outline: 0;
      padding: ${contentPadding};
      margin: ${contentPullout};
    `
  }
})`
  flex-grow: 1;
  padding: ${contentPadding};
`;

export const IconTranslationForm = styled.div`
  max-height: 100px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
    background: rgba(236, 236, 236, 1);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(163, 163, 163, 1);
  }
`;

export const StyledDialog = styled($Dialog)``;

export const IconTranslationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
`;
export const IconTranslationTextWrapper = styled.div`
  flex-basis: 30%;
`;

export const IconTranslationInputWrapper = styled.div`
  flex-basis: 60%;
`;

export const IconTranslationValidationWrapper = styled.div`
  flex-basis: 10%;
`;

export const IconTranslationInput = styled($Field)`
  width: 100%;

  ${Error} {
    display: none;
  }
`;

export const IconTranslationTextAsterisk = styled.span`
  font-size: 1rem;
`;

export const IconTranslationText = styled.div`
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${commonPalette.txBlack};
`;

export const IconTranslationFormWrapper = styled.div`
  margin-top: 15px;
`;

export const StyledDivider = styled(Divider)`
  margin: 8px 0;
`;

export const StyledUploadIcon = styled($Icon)`
  color: ${commonPalette.txLightGrey};
`;
