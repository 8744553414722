import styled from 'styled-components';
import $ClipboardButton from 'react-clipboard.js';

import {commonPalette} from 'shared/styles/palette';

export const Divider = styled.hr`
  border-color: #ebedee;
  border-style: solid;
  border-width: 1px 0 0;
  margin: 20px 0;
`;

export const ClipboardButton = styled($ClipboardButton)`
  background: none;
  border: 0;
  cursor: pointer;
  outline: 0;
`;

export const TextBox = styled.div`
  margin-bottom: 10px;
  margin-top: 4px;
  font-style: italic;
`;

export const Title = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${commonPalette.txBlack};
`;

export const Description = styled.div`
  font-size: 14px;
  margin-bottom: 12px;
  color: ${commonPalette.txBlack};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: 8px 0;
`;
