class InstructionViewPage {
  backByButton = false;
  lastVisitedStep = 0;

  setBackByButton = flag => (this.backByButton = flag);

  resetBackByButton = () => (this.backByButton = false);

  setLastVisitedStep = step => (this.lastVisitedStep = step);

  resetLastVisitedStep = () => (this.lastVisitedStep = 0);
}

export default InstructionViewPage;
