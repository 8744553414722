import gql from 'graphql-tag';

export const TagsList = gql`
  query tags {
    tags {
      id
      title
      defaultLocale
      translations
      badge
    }
  }
`;

export const TagsLite = gql`
  query tagsLite {
    tags {
      id
      title
      badge
    }
  }
`;

export const Tag = gql`
  query tag($id: String) {
    tag(id: $id) {
      id
      title
      defaultLocale
      badge
    }
  }
`;

export const TagsByIds = gql`
  query tagsByIds($tagIds: [String!]) {
    tagsByIds(tagIds: $tagIds) {
      id
      title
      defaultLocale
      badge
    }
  }
`;

export const TagsPaginated = gql`
  query tagsPaginated($locale: Locale, $offset: Int!, $limit: Int, $filters: TagFilters, $sortBy: [TagSortBy]) {
    tagsPaginated(locale: $locale, offset: $offset, limit: $limit, filters: $filters, sortBy: $sortBy) {
      offset
      nextOffset
      limit
      totalCount
      results {
        id
        title
        defaultLocale
        translations
        badge
      }
    }
  }
`;
