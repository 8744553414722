import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

export const plugins = {dvr: dvr(validatorjs)};

export const fields = [
  {
    name: 'kind',
    rules: 'required|string'
  },
  {
    name: 'question',
    rules: 'required|string'
  },
  {
    name: 'rangeFrom',
    rules: 'required|numeric'
  },
  {
    name: 'rangeTo',
    rules: 'required|numeric'
  },
  {
    name: 'translation',
    rules: 'string'
  }
];

export const createFollowUpQuestionForm = () => new MobxReactForm({fields}, {plugins});
