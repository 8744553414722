import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectAShape: {
    id: 'MediaShapes.selectAShape',
    defaultMessage: 'Select a shape to start drawing'
  }
});

export default messages;
