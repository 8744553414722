import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import $Icon from 'ui-components/Layout/Icon';

export const StyledCard = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 3px;
  background: ${commonPalette.bannerDefault};
  margin-bottom: 8px;
  cursor: pointer;
`;

export const PrimaryText = styled.div`
  color: ${commonPalette.iconDefault};
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const SecondaryText = styled.div`
  color: ${commonPalette.iconDefault};
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
`;

export const LevelButton = styled.div`
  color: ${commonPalette.textSecondary};
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 4px;
  background: ${commonPalette.whiteSmoke};
`;

export const ChevronIcon = styled($Icon).attrs(() => ({
  name: 'chevron-right',
  size: 16
}))`
  color: ${commonPalette.darkCharcoal};
  cursor: pointer;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
