import styled from 'styled-components';
import $DataTable from 'ui-components/Layout/DataTable';
import Button from 'ui-components/Button';
import $CardToolbar from 'components/CardToolbar';
import {commonPalette} from 'shared/styles/palette';

export const CheckIcon = styled(Button).attrs({
  iconId: 'checkmark'
})`
  opacity: 1;
  margin: auto;
  color: ${commonPalette.sgGreen};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  > button {
    margin-right: 32px;
  }
`;

export const CenteredWrapper = styled.div`
  margin: auto !important;
`;

export const DataTable = styled($DataTable)`
  .rt-tr-group:hover {
    ${() => DisableButton} {
      opacity: 1;
    }
  }
`;

export const DisableButton = styled(Button).attrs({
  iconId: 'disable'
})`
  opacity: ${p => (p.showIcon ? 1 : 0)};
  cursor: pointer;
  margin: auto;
`;

export const StyledMenu = styled($CardToolbar)`
  position: relative;
  top: 12px;
  left: 16px;
`;
