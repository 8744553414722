import {Modal} from 'antd';
import isAfter from 'date-fns/is_after';
import subDays from 'date-fns/sub_days';
import format from 'date-fns/format';
import {action, observable, computed, makeObservable} from 'mobx';
import get from 'lodash/get';

import {store} from 'stores/store';
import {setCookie, getCookie} from 'shared/utils/data-utils';
import {DATE_CONSTRAINT} from 'views/Analytics/constants';
import views from 'config/views';

class AnalyticsPage {
  drilldownPayload = false;
  translations = {};
  // periscopeIframe = '';
  @observable loading = true;
  @observable domains = [];
  @observable selectedSlug = null;

  @action setLoading = val => (this.loading = val);
  trackPageViewEvent = () => {
    store.analytics.trackPageViewEvent();
  };

  @action setDomains = domains => {
    this.domains = domains;
  };

  @action checkPermissions = () => {
    const {
      auth: {user},
      router
    } = store;
    if (!user.canViewAnalytics) {
      router.goTo(views.root, {});
    }
  };

  @action
  setSelectedDomainSlug = slug => {
    this.selectedSlug = slug;
  };

  isDaterangeValid = daterange => {
    if (daterange === null) {
      return false;
    }

    let date = '';

    if (daterange.days && daterange.days !== null) {
      date = subDays(new Date(), daterange.days);
    }

    if (daterange.start) {
      date = daterange.start;
    }

    return isAfter(date, subDays(DATE_CONSTRAINT, 1));
  };

  handlePostMessages = () => {
    window.addEventListener('message', message => {
      const {data} = message;

      if (data.event_type === 'viewport_changed' && data.event_detail === 'filters_changed') {
        this.isDaterangeValid(data.daterange) ? this.setUserSettingsCookie(data) : this.handleInvalidDaterange(data);
      } else if (data.event_type === 'drilldown') {
        this.handleDrilldownEvent(data);
        this.setLoading(true);
      }
    });
  };

  handleInvalidDaterange = data => {
    this.setUserSettingsCookie({...data, daterange: this.getValidDaterange(data.daterange)});

    Modal.warning({
      title: this.translations.warningTitle,
      content: this.translations.warningText,
      okText: this.translations.warningButton
    });
  };

  handleDrilldownEvent = data => {
    const drilldownPayload = {
      dashboard: data.destination_dashboard_id,
      filters: data.filter_values.map(f => ({
        name: f.filter_name,
        value: f.column_value
      }))
    };

    this.setDrilldownPayload(drilldownPayload);
  };

  getValidDaterange = daterange => {
    const validDaterange = {
      start: DATE_CONSTRAINT,
      end: format(new Date(), 'YYYY-MM-DD')
    };

    if (daterange && daterange.end && isAfter(daterange.end, subDays(DATE_CONSTRAINT, 1))) {
      validDaterange.end = daterange.end;
    }

    return validDaterange;
  };

  getAnalyticsUrl = () => {
    const {router, platform} = store;
    const subdomain = platform.subdomain;

    const url =
      subdomain === 'localhost'
        ? 'http://analytics.swipe.local:3000'
        : window.location.origin.replace(subdomain, 'analytics').replace('3033', '3000');

    let subDomainQueryString = subdomain;
    if (this.domains && this.domains.length === 1) {
      const defaultSlug = this.domains[0].slug;
      subDomainQueryString = defaultSlug + '-' + subdomain;
    }

    if (this.selectedSlug && this.selectedSlug !== 'all') {
      subDomainQueryString = this.selectedSlug + '-' + subdomain;
    }

    const pageQueryString = router.params.page;

    const userSettingsQueryString = getCookie('sg-periscope-user-settings')
      ? `&userSettings=${getCookie('sg-periscope-user-settings')}`
      : '';

    const drilldownQueryString = this.drilldownPayload ? `&drilldown=${JSON.stringify(this.drilldownPayload)}` : '';

    return `${url}?subdomain=${subDomainQueryString}&page=${pageQueryString}${userSettingsQueryString}${drilldownQueryString}`;
  };

  setPeriscopeKPI = () => {
    this.setLoading(false);
    let iframeMouseOver = false;
    window.addEventListener('blur', () => {
      if (iframeMouseOver) {
        store.analytics.trackActionEvent({
          action: 'operate',
          actionContent: 'periscope'
        });
      }
    });

    const periscopeElement = document.getElementById('periscope');
    if (periscopeElement) {
      periscopeElement.addEventListener('mouseover', () => {
        iframeMouseOver = true;
      });

      periscopeElement.addEventListener('mouseout', () => {
        iframeMouseOver = false;
      });
    }
  };

  setUserSettingsCookie = data => {
    const {filters, aggregation, daterange} = data;
    const userSettings = {
      aggregation,
      daterange: daterange && daterange.days !== null ? daterange : false,
      filters
    };

    setCookie('sg-periscope-user-settings', JSON.stringify(userSettings), 0);
  };

  setDrilldownPayload = payload => {
    this.drilldownPayload = payload;
  };

  setTranslations = translations => {
    this.translations = translations;
  };

  constructor() {
    makeObservable(this);
  }

  @computed get showFilterByWorkspaceSelect() {
    const {router} = store;
    return get(router, 'params.page', '') !== 'adoption-overview';
  }
}

export default AnalyticsPage;
