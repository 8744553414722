import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'accessTokenManager.title',
    defaultMessage: 'API Access Tokens'
  },
  description: {
    id: 'accessTokenManager.description',
    defaultMessage:
      'API access tokens allow you to access the API on behalf of your account. You can create multiple tokens and delete them at any time.'
  },
  newToken: {
    id: 'accessTokenManager.new',
    defaultMessage: 'Your new token is:'
  },
  submit: {
    id: 'accessTokenManager.submit',
    defaultMessage: 'Generate new token'
  },
  delete: {
    id: 'common.actions.delete',
    defaultMessage: 'Delete'
  },
  copy: {
    id: 'common.actions.copy',
    defaultMessage: 'Please copy the token and keep it in a safe place. Remember that you are not able to see it again.'
  },
  copyToken: {
    id: 'accessTokenManager.copyToken',
    defaultMessage: 'Copy Token'
  },
  copyTokenSuccess: {
    id: 'accessTokenManager.copyTokenSuccess',
    defaultMessage: 'The token has been copied to the clipboard.'
  },
  createOperationSuccess: {
    id: 'accessTokenManager.createOperationSuccess',
    defaultMessage: 'Successfully created a new token.'
  },
  createOperationFailure: {
    id: 'accessTokenManager.createOperationFailure',
    defaultMessage: 'Failed to generate a new token.'
  },
  deleteOperationSuccess: {
    id: 'accessTokenManager.deleteOperationSuccess',
    defaultMessage: 'Successfully deleted the token.'
  },
  deleteOperationFailure: {
    id: 'accessTokenManager.deleteOperationFailure',
    defaultMessage: 'Failed to delete the token.'
  }
});

export default messages;
