import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'EditSignOff.title',
    defaultMessage: 'Edit sign-off'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  saveAnyway: {
    id: 'common.actions.saveAnyway',
    defaultMessage: 'Save anyway'
  },
  editSuccess: {
    id: 'EditSignOff.editSuccess',
    defaultMessage: 'Sign-off has been edited.'
  },
  editFailure: {
    id: 'EditSignOff.editFailure',
    defaultMessage: 'Failed to edit the sign-off.'
  },
  preview: {
    id: 'common.actions.preview',
    defaultMessage: 'Preview'
  },
  questions: {
    id: 'EditCampaign.questions',
    defaultMessage: 'Questions'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  saveChangesTitle: {
    id: 'common.actions.saveChanges',
    defaultMessage: 'Save changes'
  },
  saveChangesContent: {
    id: 'EditCampaign.saveConfirm',
    defaultMessage:
      'If you leave without saving, your changes to the campaign will be lost. Please save or discard your changes.'
  },
  discardChangesAction: {
    id: 'common.actions.discardChanges',
    defaultMessage: 'Discard changes'
  },
  close: {
    id: 'common.actions.close',
    defaultMessage: 'Close'
  }
});

export default messages;
