import {defineMessages} from 'react-intl';

const messages = defineMessages({
  followUpQuestion: {
    id: 'FollowUpQuestion.title',
    defaultMessage: 'Follow-up Question {n}'
  },
  question: {
    id: 'EditCampaign.question',
    defaultMessage: 'Question'
  },
  followUpPlaceholder: {
    id: 'FollowUpQuestion.followUpPlaceholder',
    defaultMessage: 'Add question...'
  },
  forAnswer: {
    id: 'FollowUpQuestion.forAnswer',
    defaultMessage: 'For answer'
  },
  binaryPlaceholder: {
    id: 'FollowUpQuestion.binaryPlaceholder',
    defaultMessage: 'Select answer...'
  }
});

export default messages;
