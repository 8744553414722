import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';

//lodash
import every from 'lodash/every';
import get from 'lodash/get';
import includes from 'lodash/includes';

//helpers
import views from 'config/views';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import GuideMenu from 'components/GuideMenu';

//styles
import {
  GuideCardLabel,
  InnerWrapper,
  Paragraph,
  StyledCardToolbar,
  GuideCardStatic,
  GuideCardLink,
  BackgroundImage,
  Subtitle,
  Title,
  StyledContent,
  StyledIcon,
  StyledText
} from './styles';

//messages
import messages from './messages';

import EmptyImage from './EmptyImage';
import EmptyCard from './EmptyCard';

@inject('store')
@observer
class GuideCardComponent extends Component {
  render() {
    const {archive, guide, publish, store, unpublish, isEmptyCard} = this.props;

    if (isEmptyCard) {
      return <EmptyCard />;
    }

    const {
      availableTranslations,
      canEdit,
      id,
      media,
      providedTranslations,
      publishStatus,
      isUnderApproval,
      ownedBy: {id: teamId},
      isLiveVersionExpired,
      isLiveVersionRejected
    } = guide;
    const {user} = store.auth;

    const title = guide.title || '';
    const mediaUrl = get(media, 'url');
    const metadata = get(media, 'metadata');
    const isDraft = publishStatus === 'DRAFT';

    const isFullyTranslated = every(availableTranslations, translation => includes(providedTranslations, translation));
    const showApprovalRequiredLabel = isUnderApproval || isLiveVersionExpired;
    const showUnpublishedLabel = !showApprovalRequiredLabel && isDraft;
    const showExpiredLabel = !showApprovalRequiredLabel && !showUnpublishedLabel && isLiveVersionRejected;

    const canViewDetails =
      user.isIPA || user.isPlatformAdmin || user.teams.find(userTeam => userTeam.team.id === teamId);
    const {isApprover} = user.teams.find(userTeam => userTeam.team.id === teamId) || false;
    let queryParams;
    if (isApprover && isUnderApproval) {
      queryParams = {v: 'review'};
    } else if (isApprover && isLiveVersionExpired) {
      queryParams = {v: 'live-review'};
    }

    const GuideCard = canEdit ? GuideCardLink : GuideCardStatic;

    return (
      <GuideCard store={store} route={views.editGuide} params={{id: id}} queryParams={queryParams} data-cy="guide-card">
        {mediaUrl ? (
          <InnerWrapper>
            <BackgroundImage src={mediaUrl} metadata={metadata} />{' '}
          </InnerWrapper>
        ) : (
          <EmptyImage />
        )}

        <StyledContent>
          <Title data-cy={title.split(' ').join('-')}>{title}</Title>
          <Subtitle>
            <Paragraph data-cy="guide-assigned-to">
              <div>
                <StyledIcon iconId="user" marginRight={12} />
              </div>
              <StyledText>{get(guide, 'assignedToUser.fullName')}</StyledText>
            </Paragraph>
            <Paragraph data-cy="guide-assigned-to">
              <div>
                <StyledIcon iconId="users" />
              </div>
              <StyledText>{guide.ownedBy.name}</StyledText>
            </Paragraph>
          </Subtitle>
        </StyledContent>
        {showApprovalRequiredLabel && (
          <GuideCardLabel isUnderApproval={true} data-cy={isUnderApproval ? 'approval-label' : 'live-review-label'}>
            <FormattedMessage {...(isUnderApproval ? messages.approval : messages.liveVersionExpired)} />
          </GuideCardLabel>
        )}
        {showExpiredLabel && (
          <GuideCardLabel data-cy="expired-label">
            <FormattedMessage {...messages.liveVersionRejected} />
          </GuideCardLabel>
        )}
        {showUnpublishedLabel && (
          <GuideCardLabel data-cy="unpublished-label">
            <FormattedMessage {...messages.unpublished} />
          </GuideCardLabel>
        )}
        <StyledCardToolbar
          dataCy={`menu-${title.split(' ').join('-')}`}
          dropdownMenu={
            <GuideMenu
              archive={archive}
              guide={guide}
              publish={publish}
              unpublish={unpublish}
              shouldShowUntranslatedIcon={!isFullyTranslated}
              canViewDetails={canViewDetails}
            />
          }
          shouldShowUntranslatedIcon={!isFullyTranslated}
        />
      </GuideCard>
    );
  }
}

export default GuideCardComponent;
