import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick, map, filter, includes} from 'lodash';
import {injectIntl} from 'react-intl';

import Dialog from 'ui-components/Dialog';
import Field from 'ui-components/Field';
import Select from 'ui-components/Select';

import {Team} from 'api/team/queries';
import {DomainsLite} from 'api/domain/queries';
import {AddDomainsToTeam} from 'api/team/mutations';
import {domainsOptions} from 'api/domain/query-options';
import {teamOptions} from 'api/team/query-options';
import {addDomainsOptions} from 'api/team/mutation-options';
import mutationNames from 'api/team/constants';

import messages from './messages';
import {StyledInfoText, WorkspaceSelect} from './styles';

@inject('store')
@graphql(Team, teamOptions)
@graphql(DomainsLite, domainsOptions)
@graphql(AddDomainsToTeam, addDomainsOptions)
@observer
class ConnectNewWorkspaceDialog extends Component {
  onCancel = () => {
    const {
      store: {
        connectNewWorkspaceDialog: {reset},
        teamPage: {reset: resetTeamPage}
      }
    } = this.props;

    reset();
    resetTeamPage();
  };

  onSubmit = () => {
    const {
      intl: {formatMessage},
      store: {
        connectNewWorkspaceDialog: {reset},
        teamPage: {submitNewWorkspace, setTranslations},
        router: {
          params: {id: teamId}
        }
      }
    } = this.props;
    const addWorkspacesMutation = this.props[mutationNames.ADD_DOMAINS];

    const translations = mapValues(pick(messages, ['addWorkspaceSuccess', 'addWorkspaceFailure']), message =>
      formatMessage(message)
    );
    setTranslations(translations);

    submitNewWorkspace(addWorkspacesMutation, teamId, reset);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        connectNewWorkspaceDialog: {opened},
        teamPage: {teamWorkspaceForm}
      },
      domainsQuery: {domains = []},
      data: {team}
    } = this.props;
    const {cancel, connect, connectNewWorkspace, teamOpportunity, workspace, noWorkspacesToAdd} = messages;

    const existingDomainIds = team ? map(team.domains, 'id') : [];
    const options = filter(domains, domain => !includes(existingDomainIds, domain.id));
    const workspacesLength = domains.length - existingDomainIds.length;
    const actions = [];

    if (options.length > 0) {
      actions.push({
        label: formatMessage(connect),
        onClick: this.onSubmit,
        disabled: false
      });
    }

    return (
      <Dialog
        actions={actions}
        isOpen={opened}
        onCancel={this.onCancel}
        title={formatMessage(connectNewWorkspace)}
        customCancelButtonText={cancel}
        dialogStyle={{overflow: 'scroll'}}
        bodyStyle={{overflow: 'visible'}}
      >
        <StyledInfoText>{formatMessage(teamOpportunity)}</StyledInfoText>
        <WorkspaceSelect workspacesLength={workspacesLength}>
          {options.length === 0 ? (
            <p>{formatMessage(noWorkspacesToAdd)}</p>
          ) : (
            <Field
              size="small"
              inputComponent={
                <Select
                  allowClear={true}
                  options={options}
                  form={teamWorkspaceForm}
                  field="domainIds"
                  label={formatMessage(workspace)}
                  showSearch={true}
                />
              }
            />
          )}
        </WorkspaceSelect>
      </Dialog>
    );
  }
}

export default injectIntl(ConnectNewWorkspaceDialog);
