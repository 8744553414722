import {action, observable, makeObservable} from 'mobx';

//helpers
import {isDraftVersionId} from 'utils/versioning-utils';

export default class SaveAsDraftDialog {
  @observable currentVersionId = null;
  @observable draftGuide = null;
  @observable opened = false;
  @observable isSaving = false;
  onCancel = null;
  onConfirm = null;

  check = () => {
    if (isDraftVersionId(this.currentVersionId)) {
      return Promise.resolve();
    }

    this.open();

    return new Promise((resolve, reject) => {
      this.onCancel = () => {
        this.reset();
        reject();
      };

      this.onConfirm = () => {
        this.reset();
        resolve();
      };
    });
  };

  @action
  open = () => {
    this.opened = true;
  };

  @action
  reset = () => {
    this.opened = false;
    this.isSaving = false;

    // These might have been set by the "check" method:
    this.onCancel = null;
    this.onConfirm = null;
  };

  @action
  setCurrentVersionId = currentVersionId => {
    this.currentVersionId = currentVersionId;
  };

  @action
  setDraftGuide = draftGuide => {
    this.draftGuide = draftGuide;
  };

  @action
  setIsSaving = isSaving => {
    this.isSaving = isSaving;
  };

  constructor() {
    makeObservable(this);
  }
}
