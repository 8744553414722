import {inject, observer} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import Raven from 'raven-js';

//lodash
import get from 'lodash/get';

//helpers
import notification from 'utils/notification-utils';

//mutations
import {RestoreVersion} from 'api/guide/mutations';

//queries
import {GetDraftGuide} from 'api/guide/queries';

//options
import {restoreVersionOptions} from 'api/guide/mutation-options';
import {getDraftGuideOptions} from 'api/guide/query-options';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';

//messages
import messages from './messages';
import views from 'config/views';

@inject('store')
@graphql(RestoreVersion, restoreVersionOptions)
@graphql(GetDraftGuide, {...getDraftGuideOptions, name: 'draftGuideData'})
@observer
class SaveAsDraftDialogComponent extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {draftGuideData, store} = nextProps;
    const {draftGuideForCms, loading: draftLoading} = draftGuideData;
    const isDraftGuideReady = Boolean(!draftLoading && draftGuideForCms);
    const {saveAsDraftDialog} = store;
    if (isDraftGuideReady) {
      saveAsDraftDialog.setDraftGuide(draftGuideForCms);
    }
  }
  saveAsDraft = async () => {
    const {intl, restoreVersionMutation, store} = this.props;
    const {formatMessage} = intl;
    const {saveAsDraftDialog: dialog} = store;

    dialog.setIsSaving(true);

    try {
      const {data} = await restoreVersionMutation({
        versionId: dialog.currentVersionId
      });
      if (data.restoreVersion !== true) {
        throw new Error(`Unexpected response from API: ${data.restoreVersion}`);
      }
      notification.success(formatMessage(messages.saveAsDraftSuccess));
      this.goToDraft();
    } catch (e) {
      Raven.captureException(e);
      notification.error(formatMessage(messages.saveAsDraftError));
      dialog.onCancel?.();
    } finally {
      dialog.setIsSaving(false);
    }
  };

  goToDraft = () => {
    const {store} = this.props;
    const {router, saveAsDraftDialog: dialog} = store;
    const {currentRoute, params} = router;
    const {id, topicId, instructionId, stepId} = params;
    if (currentRoute.path === views.editGuide.path) {
      router.goTo(views.editGuide, {id: params.id});
    } else if (currentRoute.path === views.editInstruction.path) {
      router.goTo(views.editInstruction, {id, topicId, instructionId, stepId});
    }
    dialog.onConfirm?.();
  };

  render() {
    const {intl, store} = this.props;
    const {formatMessage} = intl;
    const {saveAsDraftDialog: dialog} = store;

    const rawDraftDate = get(dialog, 'draftGuide.lastEditedAt');
    const draftDate = moment(rawDraftDate).format('l LT');

    const versionId = get(store.router, 'queryParams.v', null);
    const showGoToDraft = ['review', 'live-review'].includes(versionId);

    const title = showGoToDraft ? formatMessage(messages.goToDraft) : formatMessage(messages.saveAsDraft);

    const message = showGoToDraft
      ? {...messages.goToDraftWarning}
      : {...messages.saveAsDraftWarning, values: {draftDate}};

    const action = showGoToDraft
      ? {onClick: this.goToDraft, label: formatMessage(messages.goToDraft)}
      : {onClick: this.saveAsDraft, label: formatMessage(messages.saveAsDraft)};

    return (
      <Dialog
        actions={[
          {
            ...action,
            dataCy: 'confirm-set-as-draft',
            loading: dialog.isSaving
          }
        ]}
        isOpen={dialog.opened}
        onCancel={dialog.reset}
        title={title}
      >
        <FormattedMessage {...message} />
      </Dialog>
    );
  }
}

export default injectIntl(SaveAsDraftDialogComponent);
