import {Dropdown as DropdownAntd, Menu as $Menu} from 'antd';
import React from 'react';
import styled from 'styled-components';

//helpers
import injectScrollParent from 'decorators/injectScrollParent';

//components
import {Link as $Link} from 'utils/mobx';

//constants
const itemCss = `
  border-radius: 0 !important;
  color: inherit;
  font: inherit;
  padding: 0;
`;
const itemPadding = '8px 16px';

export const Dropdown = injectScrollParent(({getScrollParent, ...forwardedProps}) => (
  <DropdownAntd
    getPopupContainer={getScrollParent}
    placement="bottomLeft"
    trigger={['click']}
    {...forwardedProps}
    align={{
      overflow: {adjustX: true, adjustY: false}, // auto adjust position when sourceNode is overflowed
      ...forwardedProps?.align
    }}
  />
));

export const DropdownMenu = styled($Menu)`
  &.ant-dropdown-menu {
    border-radius: 2px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3524);
    color: #4d4d4d;
    font-size: 16px;
    min-width: 146px;
    padding: 4px 0;
  }
`;

export const DropdownSubmenu = styled($Menu.SubMenu)`
  .ant-dropdown-menu-submenu-title {
    ${itemCss};
    padding: ${itemPadding};

    &::after {
      border-color: transparent currentColor;
      border-style: solid;
      border-width: 4px 0 4px 4px;
      color: inherit;
      content: '';
      margin-top: -2px;
      right: 15px;
      top: 50%;
      transform: none;
    }
  }
`;

export const DropdownMenuItem = styled($Menu.Item)`
  &.ant-dropdown-menu-item {
    ${itemCss};
  }
`;

export const DropdownMenuItemGroup = styled($Menu.ItemGroup)``;

export const DropdownMenuDivider = styled($Menu.Divider)`
  margin: 4px 0;
`;

export const DropdownMenuLink = styled($Link)`
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  outline: 0;
  padding: ${itemPadding};
  text-align: left;
  width: 100%;

  .ant-dropdown-menu-item > &,
  .ant-dropdown-menu-submenu-title > & {
    color: inherit;
    margin: 0;
    padding: ${itemPadding};
    transition: none;
  }
`;

export const DropdownMenuButton = styled(DropdownMenuLink.withComponent('button')).attrs({
  type: 'button'
})``;

export const VersionsMenuButton = styled(DropdownMenuLink.withComponent('button')).attrs({
  type: 'button'
})`
  font-size: 16px;
`;
