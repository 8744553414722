import gql from 'graphql-tag';

export const Platform = gql`
  query platformForCms {
    platformForCms {
      name
      subdomain
      defaultDomain {
        slug
      }
      assetsFolder
      showStepNoteIconLabel
      tagCreationRoles
      webhookUrl
      domain {
        theme {
          logoMedia {
            id
            url
          }
          faviconMedia {
            id
            url
          }
          mainColor
          buttonColor
          fontFamily
          tipIconMedia {
            id
            url
          }
          warningIconMedia {
            id
            url
          }
          alternativeIconMedia {
            id
            url
          }
          fixIconMedia {
            id
            url
          }
          showHintLabels
          customFontRegularUrl
          customFontBoldUrl
          customFontItalicUrl
          customFontBoldItalicUrl
          customFontFallback
          icons {
            id
            svg
            labelTranslations
            type
            isEnabled
            type
          }
        }
      }
    }
  }
`;

export const AvailableLanguages = gql`
  query availableLanguages {
    availableLanguages {
      locale
      language
      short
    }
  }
`;

export const PlatformFlags = gql`
  query platformFlags {
    platformForCms {
      isContentProtected
      canManageCampaigns
      hasMultiDomainEnabled
      canColorContent
      canUseMediaInStepHints
      canUseBasicAnalytics
      canUseCustomFonts
      canUseLiveChat
      sso
      enforceAdminSSO
      guideApproval
      name
      canExportToPdf
      canUseAutoTranslations
      hasFeedbackEnabled
      hasBeekeeperIntegrationEnabled
      hasChecklistsEnabled
      canUseXmReality
      hasNativeApp
      nativeAppAutoRemovalInterval
      hasChecklistEscalation
      hasAiStepContentSuggestions
      hasSkillsEnabled
      hasConfigurableAlertsRecipients
      hasApiAccess
      hasChecklistsSignatureEnabled
      hasDispatchEnabled
      hasDispatchDocumentsSyncEnabled
    }
  }
`;

export const IconsList = gql`
  query icons($onlyEnabled: Boolean) {
    icons(onlyEnabled: $onlyEnabled) {
      id
      svg
      labelTranslations
      type
      isEnabled
    }
  }
`;

export const NoteIconCheckAffectedGuides = gql`
  query checkAffectedGuides($iconId: String!) {
    checkAffectedGuides(iconId: $iconId) {
      id
      title
      slug
    }
  }
`;

export const GET_TOKENS = gql`
  query tokens {
    tokens {
      jti
      hint
    }
  }
`;
