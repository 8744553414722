import styled from 'styled-components';
import $Button from 'ui-components/Button';
import $Field from 'ui-components/Field';

export const Button = styled($Button)``;

export const PickerRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 920px;
  align-items: center;
`;

export const Field = styled($Field)`
  width: 355px;
  margin-right: 30px;
`;

export const RemoveButton = styled(Button).attrs({
  iconId: 'remove',
  solidIcon: false
})`
  cursor: pointer;
  margin-bottom: ${p => (p.removeMargin ? 0 : 36)}px;
`;

export const AddTeamUsers = styled.div`
  padding: 24px;
`;
