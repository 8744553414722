import styled from 'styled-components';
import {Tabs} from 'antd';
import {commonPalette} from 'shared/styles/palette';

export const StepsTabsWrapper = styled.div`
  ${props => (!props.fullHeight ? 'height: calc(100% - 200px)' : '')};
  position: relative;
`;

export const StyledTabs = styled(Tabs)`
  ${p => !p.tabsOnly && 'height: 100% !important;'}

  .ant-tabs-nav .ant-tabs-tab-active {
    color: ${commonPalette.btnGreen};
  }

  .ant-tabs-content {
    // 52px is height of the tabs-nav + margin
    height: calc(100% - 52px);
  }

  .ant-tabs-content .ant-tabs-tabpane {
    // 120px is height of the buttonGroup at the bottom
    overflow-y: auto !important;
    overflow: -moz-scrollbars-vertical;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      border-radius: 3px;
      width: 4px;
      background-color: ${commonPalette.borderGrey};
    }
    ::-webkit-scrollbar-thumb {
      -webkit-appearance: none;
      border-radius: 3px;
      background-color: ${commonPalette.txLightGrey};
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: 0 0 16px;
    margin-right: 32px;
  }

  .ant-tabs-tab {
    color: ${commonPalette.txGrey};
    &:hover {
      color: ${commonPalette.btnGreen} !important;
    }
  }

  .ant-tabs-ink-bar {
    background-color: ${commonPalette.btnGreen};
    height: 3px;
    border-radius: 200px 200px 0 0;
  }

  .ant-tabs-ink-bar-animated {
    background-color: ${commonPalette.btnGreen};
  }
`;
