import {refreshDomains, refreshDomain} from 'api/domain/refresh-queries';

export const createDomainOptions = {
  props: ({mutate}) => ({
    createDomainMutation: variables =>
      mutate({
        variables
      })
  })
};

export const editDomainOptions = {
  props: ({mutate}) => ({
    editDomainMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshDomains()]
      })
  })
};

export const archiveDomainOptions = {
  props: ({mutate}) => ({
    archiveDomainMutation: variables =>
      mutate({
        variables,
        refetchQueries: [refreshDomains()]
      })
  })
};

export const addTeamsToDomainOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    addTeamsToDomainMutation: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshDomain(id)]
      });
    }
  })
};

export const removeTeamFromDomainOptions = {
  props: ({mutate, ownProps: {store}}) => ({
    removeTeamFromDomainMutation: variables => {
      const id = store.router.params.id;
      return mutate({
        variables,
        refetchQueries: [refreshDomain(id)]
      });
    }
  })
};
