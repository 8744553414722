export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth, maxHeight, rotationAngle) => {
  // very hacky way, should be calculated how it fits best
  // -> http://polymathprogrammer.com/2012/02/22/optimal-width-height-after-image-rotation/
  if (!rotationAngle || rotationAngle === 180 || rotationAngle === 360) {
    const scale = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return {
      scale,
      width: srcWidth * scale,
      height: srcHeight * scale,
      rotationWidth: srcWidth * scale,
      rotationHeight: srcHeight * scale,
      translatedX: (maxWidth - srcWidth * scale) / 2,
      translatedY: (maxHeight - srcHeight * scale) / 2
    };
  } else {
    const scale = Math.min(maxHeight / srcWidth, maxWidth / srcHeight);
    return {
      scale,
      width: srcWidth * scale,
      height: srcHeight * scale,
      rotationWidth: srcHeight * scale,
      rotationHeight: srcWidth * scale,
      translatedX: (maxWidth - srcHeight * scale) / 2,
      translatedY: (maxHeight - srcWidth * scale) / 2
    };
  }
};

export const calculateRotatedXyScale = (x, y, rotation) => {
  const rotated = {
    x,
    y
  };
  if (rotation === 90) {
    rotated.y = x;
    rotated.x = 1 - y;
  } else if (rotation === 180) {
    rotated.y = 1 - y;
    rotated.x = 1 - x;
  } else if (rotation === 270) {
    rotated.x = y;
    rotated.y = 1 - x;
  }
  return rotated;
};
export const calculateRotatedMeta = (metadata, imageRotation, imageWidth, imageHeight) => {
  const rotatedMeta = {
    w: metadata.crop.w,
    h: metadata.crop.h,
    x: metadata.crop.x,
    y: metadata.crop.y
  };
  if (imageRotation === 90) {
    rotatedMeta.w = metadata.crop.h;
    rotatedMeta.h = metadata.crop.w;
    rotatedMeta.x = imageHeight - metadata.crop.h - metadata.crop.y;
    rotatedMeta.y = metadata.crop.x;
  } else if (imageRotation === 180) {
    rotatedMeta.x = imageWidth - metadata.crop.w - metadata.crop.x;
    rotatedMeta.y = imageHeight - metadata.crop.h - metadata.crop.y;
  } else if (imageRotation === 270) {
    rotatedMeta.w = metadata.crop.h;
    rotatedMeta.h = metadata.crop.w;
    rotatedMeta.x = metadata.crop.y;
    rotatedMeta.y = imageWidth - metadata.crop.w - metadata.crop.x;
  }
  return rotatedMeta;
};

export const scaleObjectValues = (obj = {}, scale, round = false) => {
  const scaled = {};
  Object.keys(obj).forEach(key => {
    const scaledVal = obj[key] / scale;
    scaled[key] = round ? Math.round(scaledVal) : scaledVal;
  });
  return scaled;
};
