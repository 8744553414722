import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectLanguage: {
    id: 'EditCampaign.languageSelect',
    defaultMessage: 'Choose language'
  },
  addNewGuideLanguage: {
    id: 'addNewGuideLanguage',
    defaultMessage: 'Add new Guide language'
  },
  add: {
    id: 'common.actions.add',
    defaultMessage: 'Add'
  }
});

export default messages;
