import xor from 'lodash/xor';
import isEmpty from 'lodash/isEmpty';

const haveSameMembers = (array0, array1) => isEmpty(xor(array0, array1));

const getApproverIdsFromCards = state => {
  const {teamMembershipCardsData} = state;
  return teamMembershipCardsData.filter(({isEmpty}) => !isEmpty).map(({selectedUserId}) => selectedUserId);
};

const getSerializedInternalApproverIds = state => getApproverIdsFromCards(state).join(',');

const getApproverUserIds = users => users.filter(({isApprover}) => isApprover).map(({id}) => id);

const getSerializedUserIds = users => users.map(({id}) => id).join(',');

const getSerializedApproverIds = users => getApproverUserIds(users).join(',');

const userIdsDiffer = ({state, newUsers}) => {
  const {users} = state;
  const userIds = users.map(({id}) => id);
  const newUserIds = newUsers.map(({id}) => id);

  return !haveSameMembers(userIds, newUserIds);
};

const approverIdsDiffer = ({state, newUsers}) => {
  const approverIds = getApproverIdsFromCards(state);
  const newUsersApproverIds = getApproverUserIds(newUsers);

  return !haveSameMembers(approverIds, newUsersApproverIds);
};

const getShouldUpdateUsers = ({state, newUsers}) =>
  userIdsDiffer({state, newUsers}) || approverIdsDiffer({state, newUsers});

const getShouldCallOnRequireGuideApprovalChange = ({state, requireGuideApproval}) =>
  state.requireGuideApproval !== requireGuideApproval;

const getCanAddMoreEmptyCards = state => {
  const {users, teamMembershipCardsData} = state;
  const numUsers = users.length;
  const numTeamMembershipCards = teamMembershipCardsData.length;

  return state.requireGuideApproval && numTeamMembershipCards < numUsers;
};

const getIsDeletable = ({state, cardPosition}) => {
  const {teamMembershipCardsData} = state;
  const teamMembershipCard = teamMembershipCardsData[cardPosition];
  const isPopulated = teamMembershipCard && !teamMembershipCard.isEmpty;
  const numPopulatedCards = teamMembershipCardsData.filter(({isEmpty}) => !isEmpty).length;

  const isDeletable = !isPopulated || numPopulatedCards > 1;
  return isDeletable;
};

const getCardUserOptions = ({teamMembershipCards, teamMembershipCard, users}) => {
  const {selectedUserId: cardSelectedUserId} = teamMembershipCard;
  const otherSelectedUserIds = teamMembershipCards
    .filter(({isEmpty, selectedUserId}) => !isEmpty && cardSelectedUserId !== selectedUserId)
    .map(({selectedUserId}) => selectedUserId);
  const candidateUsers = users.filter(({id}) => id === cardSelectedUserId || !otherSelectedUserIds.includes(id));

  return candidateUsers.map(({id, name}) => ({userId: id, displayName: name}));
};

const getUserOptionAdder = ({teamMembershipCards, users}) => {
  const addOptions = teamMembershipCard => ({
    ...teamMembershipCard,
    usersOptions: getCardUserOptions({teamMembershipCards, teamMembershipCard, users})
  });
  return addOptions;
};

const getCanSelectApprovalOrder = state => {
  const {requireGuideApproval} = state;
  const hasMoreThan1Approver = getApproverIdsFromCards(state).length > 1;
  return requireGuideApproval && hasMoreThan1Approver;
};

export {
  getApproverIdsFromCards,
  getSerializedInternalApproverIds,
  getSerializedUserIds,
  getSerializedApproverIds,
  getShouldUpdateUsers,
  getShouldCallOnRequireGuideApprovalChange,
  getCanAddMoreEmptyCards,
  getIsDeletable,
  getUserOptionAdder,
  getCanSelectApprovalOrder
};
