import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {pick} from 'lodash';

//styles
import {UploadAndChangeButton, MediaPicker, ChangeButton} from './styles';

@inject('store')
class MediaPickerWithButtonComponent extends Component {
  mediaPickerProps = [
    'acceptedTypes',
    'allowedFormats',
    'allowVideos',
    'field',
    'form',
    'galleryConfig',
    'instructionId',
    'onOpen',
    'previewType',
    'setCoverUrl',
    'setMetadata',
    'updateMedia',
    'showUpdateNotifications',
    'iconName',
    'iconSize',
    'backgroundUrl',
    'backgroundMetadata',
    'type'
  ];

  render() {
    const {label} = this.props;

    return (
      <UploadAndChangeButton>
        <MediaPicker {...pick(this.props, this.mediaPickerProps)} />
        {label && <ChangeButton onClick={this.openDialog}>{label}</ChangeButton>}
      </UploadAndChangeButton>
    );
  }

  openDialog = () => {
    const {onOpen, store} = this.props;
    const {mediaPickerDialog: dialog} = store;

    dialog.open({...pick(this.props, this.mediaPickerProps)});

    if (typeof onOpen === 'function') {
      onOpen();
    }
  };
}

export default MediaPickerWithButtonComponent;
