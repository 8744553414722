import Modal from 'react-modal';
import styled from 'styled-components';

//helpers
import {noSelect} from 'shared/utils/sc-utils';
import {commonPalette} from 'shared/styles/palette';

//components
import $ButtonGroup from 'ui-components/ButtonGroup';

const dialogPadding = 20;

export const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 320px;
  outline: 0;
  padding: 20px;
  pointer-events: none;
`;

export const Dialog = styled.article`
  background: #fff;
  border-radius: 2px;
  border: 1px solid #ebedee;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: ${({isFixed}) => (isFixed ? '5% auto' : 'auto')};
  max-height: ${({isFixed}) => (isFixed ? '80%' : '100%')};
  pointer-events: auto;
  padding: ${dialogPadding}px;
  overflow: hidden;
  -webkit-box-shadow: ${({boxShadow}) => (boxShadow ? '0px 0px 10px rgba(0, 0, 0, 0.5)' : '')};

  ${p =>
    p.size === 'small' &&
    `
    width: 560px;
  `}

  ${p =>
    p.size === 'medium' &&
    `
    max-width: 875px;
    min-width: 560px;
    width: 100%;
  `}

  ${p =>
    p.size === 'large' &&
    `
    max-width: 1400px;
    min-width: 880px;
    width: 100%;
  `}

  ${p =>
    p.width &&
    `
    width: ${p.width}px;
  `}

  ${p =>
    p.overflow &&
    `
    overflow: ${p.overflow};
  `}

  @media (max-width: 720px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  ${noSelect}
  flex-shrink: 0;
  font-size: 22px;
  line-height: 28px;
  padding-bottom: ${({customHeaderStyle}) =>
    customHeaderStyle?.paddingBottom ? customHeaderStyle.paddingBottom : 40}px;
  display: flex;
  align-items: center;
  word-break: break-all;

  > svg {
    margin-right: 10px;
    color: ${commonPalette.orange};
  }
`;

export const Body = styled.main`
  color: ${commonPalette.txGrey};
  line-height: 21px;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: -${dialogPadding}px;
  padding-right: ${dialogPadding}px;
  ${p =>
    p.overflow &&
    `
    overflow: ${p.overflow};
  `}
`;

export const StyledButtonGroup = styled($ButtonGroup).attrs({
  shouldAllowWrapping: true
})`
  flex-shrink: 0;
  margin-top: ${({buttonGroupStyle}) => (buttonGroupStyle?.marginTop ? buttonGroupStyle.marginTop : 40)}px;
`;

export const Divider = styled.hr`
  border-color: #ebedee;
  border-style: solid;
  border-width: 1px 0 0;
  margin: 40px 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: ${({leftBottomComponent}) => (leftBottomComponent ? 'space-between' : 'flex-end')};
`;
