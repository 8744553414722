import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';
//components
import FontAwesomeIcon from '../FontAwesomeIcon';

export const SearchBar = styled.div`
  width: ${({width}) => width || 160}px;
  border-bottom: 1px solid ${({isFocused}) => (isFocused ? commonPalette.sgGreen : '#000')};
  display: flex;
  height: 20px;
  align-items: center;
  line-height: 17px;
  margin: 0 30px 0 0;
  justify-content: space-between;
  padding-right: 10px;
  ${props =>
    props.noBorder
      ? 'border-bottom: none'
      : `border-bottom: 1px solid ${props.isFocused ? commonPalette.sgGreen : commonPalette.txBlack};`};

  > svg {
    font-size: 10px;
    ${props => (!props.noBorder && props.isFocused ? `color: ${commonPalette.sgGreen};` : '')};
  }

  @media (max-width: 720px) {
    width: 100%;
    margin: 0;
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;

  ::placeholder {
    color: #b6b6b6;
  }
`;

export const SearchIcon = styled(FontAwesomeIcon).attrs({
  actionId: 'search'
})`
  font-size: 10px;
`;
