import React, {useEffect, useState} from 'react';

import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import uniqBy from 'lodash/uniqBy';

import Select from 'ui-components/Select';

import messages from './messages';
import {usePaginatedTeamsLiteQuery} from './query-utils';

const ParentTeamSelector = props => {
  // check apollo pagination docs for other ways of storing the results without keeping state (future refactoring)
  const [possibleParents, setPossibleParents] = useState<any[]>([]);
  const [finishedFetching, setFinishedFetching] = useState(false);

  const {
    intl: {formatMessage},
    teamPage: {form, onSelectParentTeam, team: currentTeam, searchTerm, setSearchTerm, offset, setOffset, limit}
  } = props;

  const teamsPaginatedQuery = usePaginatedTeamsLiteQuery(props);
  const {data, loading} = teamsPaginatedQuery;

  const refetch = () => {
    teamsPaginatedQuery.refetch({
      offset,
      limit,
      searchTerm
    });
  };

  const reset = () => {
    setFinishedFetching(false);
    setPossibleParents([]);
    setOffset(0);
    setSearchTerm('');
  };

  // disable current team and its children
  const shouldBeDisabled = (team: any) => {
    if (!currentTeam) return false;
    return team.id === currentTeam.id || team.parent?.id === currentTeam.id;
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    // if current team has a parent, set it as first, as it may be at bottom of list only available after refetch
    if (currentTeam?.parent) {
      setPossibleParents([currentTeam.parent]);
    }
  }, [currentTeam]);

  useEffect(() => {
    if (data?.teamsPaginated?.results) {
      // concat query pages together
      setPossibleParents(
        uniqBy(
          possibleParents.concat(
            data.teamsPaginated.results?.map(t => ({
              ...t,
              disabled: shouldBeDisabled(t)
            }))
          ),
          'id'
        )
      );

      // stop fetching if we have reached the end of the list
      if (data?.teamsPaginated?.totalCount < offset) {
        setFinishedFetching(true);
      }
    }
  }, [data, loading]);

  useEffect(() => {
    refetch();
  }, [offset]);

  // on new search term, reset list and refetch from 0
  useEffect(() => {
    setFinishedFetching(false);
    setPossibleParents([]);
    if (offset === 0) refetch();
    else setOffset(0);
  }, [searchTerm]);

  const addToOffset = () => {
    setOffset(offset + limit);
  };

  return (
    <div>
      <p style={{marginBottom: '4px'}}>{formatMessage(messages.belongsToTeam)}</p>
      <Select
        placeholder={formatMessage(messages.selectBelongsToTeam)}
        options={possibleParents}
        onChange={onSelectParentTeam}
        field="parentId"
        form={form}
        id="parentId"
        testId="parentId-options"
        onScrollToBottom={!finishedFetching && addToOffset}
        showSearch={true}
        onSearch={setSearchTerm}
        allowClear={true}
        dataCy="parent-team-selector"
      />
    </div>
  );
};

export default injectIntl(inject('store')(observer(ParentTeamSelector)));
