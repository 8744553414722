import React, {Component} from 'react';

import {FormattedMessage} from 'components/FormattedComponents';

import Dialog from 'ui-components/Dialog';

import messages from '../messages';
import {dialogStyle, StyledText, ListWrapper, ListLink} from '../styles';
import {openTeamWithDialogClosed, openTeamsWithDialogClosed} from '../utils';

class HasPendingApprovalsDialog extends Component {
  render() {
    const {
      teams,
      isOpen,
      onCancel,
      isArchiving,
      user: {fullName}
    } = this.props;

    const title = isArchiving ? (
      <FormattedMessage {...messages.cannotArchiveUser} />
    ) : (
      <FormattedMessage {...messages.cannotDeleteUser} />
    );
    const subtitle = (
      <StyledText>
        <FormattedMessage
          {...messages.hasPendingApprovals}
          values={{
            name: <b>{fullName}</b>
          }}
        />
      </StyledText>
    );
    const content = (
      <ListWrapper>
        {teams.map(team => (
          <ListLink key={team.id} onClick={() => openTeamWithDialogClosed(team.id)} target="_blank">
            {team.name}
          </ListLink>
        ))}
      </ListWrapper>
    );

    const actions = [
      {
        label: <FormattedMessage {...messages.goToTeams} />,
        onClick: openTeamsWithDialogClosed,
        disabled: false
      },
      {
        label: <FormattedMessage {...messages.close} />,
        onClick: onCancel,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel
        onCancel={onCancel}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {subtitle}
        {content}
      </Dialog>
    );
  }
}

export default HasPendingApprovalsDialog;
