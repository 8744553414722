import {defineMessages} from 'react-intl';

const messages = defineMessages({
  canNotTranslateGuide: {
    id: 'canNotTranslateGuide',
    defaultMessage:
      "We could not translate this Guide. Please try again. If the Guide can't be translated automatically, please contact us through the chat."
  },
  tryAgain: {
    id: 'tryAgain',
    defaultMessage: 'Try again'
  },
  close: {
    id: 'common.actions.close',
    defaultMessage: 'Close'
  },
  somethingWentWrong: {
    id: 'pdfErrorHeader',
    defaultMessage: 'Something went wrong'
  },
  canNotAddLanguage: {
    id: 'canNotAddLanguage',
    defaultMessage:
      'We could not add a new language. Please try again. If the error still persists please contact us through the chat.'
  },
  canNotSelectLanguage: {
    id: 'canNotSelectLanguage',
    defaultMessage:
      'We could not retrieve the languages. Please try again. If the error still persists please contact us through the chat.'
  },
  cannotReviewGuideInLanguage: {
    id: 'cannotReviewGuideInLanguage',
    defaultMessage:
      "Your guide has been auto-translated but it's not possible to review the guide in {translatedLanguage} right now."
  }
});

export default messages;
