import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {get} from 'lodash';

//data
import {GetDomainsForGuide} from 'api/guide/queries';
import {domainsForGuidesOptions} from 'api/guide/query-options';
import {ShareToBeekeeper} from 'api/guide/mutations';
import {shareToBeekeeperOptions} from 'api/guide/mutation-options';

//helpers
import {posthogCapture} from 'shared/utils/posthog-utils';
import {downloadCanvas} from 'utils/media';
import notification from 'utils/notification-utils';

//components
import {FormattedMessage} from 'components/FormattedComponents';
import Dialog, {Divider} from 'ui-components/Dialog';
import Button from 'ui-components/Button';
import Select from 'ui-components/Select';

//styled-components
import {ClipboardButton, QrRow, TextBox, SectionTitle, StyledQRCode, StyledButton} from './styles';

//messages
import messages from './messages';
import {buildShareImage} from './buildShareImage';

// event tracking
import {trackEvent} from 'utils/tracking/event-tracking';
import {EVENT_TYPES} from 'api/tracking/constants';

@inject('store')
@graphql(GetDomainsForGuide, domainsForGuidesOptions)
@graphql(ShareToBeekeeper, shareToBeekeeperOptions)
@observer
class ShareDialog extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      guideDomainsQuery: {guideForCms, loading},
      store: {shareDialog}
    } = nextProps;
    if (!loading && guideForCms) {
      const {domains} = guideForCms;
      shareDialog.setDomains(domains);
    }
  }

  notifyPosthog = (...args) => {
    const {store} = this.props;
    if (get(store, 'analytics.posthogIsLoaded', false)) {
      posthogCapture(...args);
    }
  };

  render() {
    const {intl, store, shareToBeekeeperMutation} = this.props;
    const {formatMessage} = intl;
    const {
      shareDialog: dialog,
      platform: {hasMultiDomainEnabled, hasBeekeeperIntegrationEnabled, isContentProtected},
      auth: {user}
    } = store;

    const {domains, setSelectedDomain, selectedDomain, embedCode, qrCodeUrl, shareUrl, title, opened} = dialog;

    const showDomainPicker = (hasMultiDomainEnabled && domains.length > 1 && !!selectedDomain) || false;
    return (
      <Dialog actions={() => []} isOpen={opened} onCancel={dialog.reset} size="small" title={title}>
        <div>
          {showDomainPicker === true && (
            <div>
              <SectionTitle>
                <FormattedMessage {...messages.selectDomain} />
              </SectionTitle>
              <Select
                underlined
                dataCy="domain-picker"
                allowClear={false}
                label={formatMessage(messages.workspace)}
                options={domains.slice()}
                selectedValue={selectedDomain.id}
                onChange={setSelectedDomain}
              />
              <Divider />
            </div>
          )}
          <SectionTitle>
            <FormattedMessage {...messages.qrCode} />
          </SectionTitle>
          <QrRow>
            <StyledQRCode value={qrCodeUrl} />
            <StyledButton label={formatMessage(messages.downloadQrCode)} onClick={this.downloadQrCode} />
          </QrRow>
          <Divider />
          <SectionTitle>
            <FormattedMessage {...messages.url} />
          </SectionTitle>
          <TextBox>{shareUrl}</TextBox>
          <ClipboardButton
            component="a"
            data-clipboard-text={shareUrl}
            data-cy="data-clipboard-text"
            onSuccess={() => {
              this.notifyPosthog('Copy Guide Url', {$set: {email: user.email}});
              notification.success(formatMessage(messages.copyUrlSuccess));
            }}
          >
            <Button underlined solidIcon={false} iconId="duplicate" label={formatMessage(messages.copyUrl)} />
          </ClipboardButton>
          {!isContentProtected && (
            <div>
              <Divider />
              <SectionTitle>
                <FormattedMessage {...messages.embedCode} />
              </SectionTitle>
              <TextBox>{embedCode}</TextBox>
              <ClipboardButton
                component="a"
                data-clipboard-text={embedCode}
                onSuccess={() => {
                  this.notifyPosthog('Copy Guide Embed', {$set: {email: user.email}});
                  notification.success(formatMessage(messages.copyEmbedCodeSuccess));
                }}
              >
                <Button underlined solidIcon={false} iconId="duplicate" label={formatMessage(messages.copyEmbedCode)} />
              </ClipboardButton>
            </div>
          )}
          {hasBeekeeperIntegrationEnabled && <Divider />}
          {hasBeekeeperIntegrationEnabled && (
            <SectionTitle>
              <FormattedMessage {...messages.beekeeper} />
            </SectionTitle>
          )}
          {hasBeekeeperIntegrationEnabled && (
            <Button
              onClick={() =>
                dialog.shareToBeekeeper({
                  mutation: shareToBeekeeperMutation,
                  translations: {
                    success: formatMessage(messages.shareToBeekeeperSuccess),
                    error: formatMessage(messages.shareToBeekeeperError)
                  }
                })
              }
              underlined
              label={formatMessage(messages.shareToBeekeeper)}
            />
          )}
        </div>
      </Dialog>
    );
  }

  downloadQrCode = async () => {
    const {store} = this.props;
    const {shareDialog: dialog} = store;
    const {qrCodeFilename, itemTitle, type, qrCodeUrl} = dialog;

    const canvas = await buildShareImage({
      title: itemTitle,
      type,
      qrCodeUrl
    });

    downloadCanvas(canvas, qrCodeFilename);

    trackEvent(EVENT_TYPES.DOWNLOAD_QR_CODE, {
      title: itemTitle,
      type,
      qrCodeUrl,
      qrCodeFilename
    });
  };

  selectAll = inputElement => {
    inputElement.setSelectionRange(0, inputElement.value.length);
  };
}

export default injectIntl(ShareDialog);
