import {action, observable, makeObservable} from 'mobx';
import Deferred from 'es6-deferred';

/**
 * Usage:
 * dialog.check()
 *. then((result) => {
 *   // User answered save/discard and continue
 *   // Do your thing
 *   console.log(result); // {answer: 'save'/'discard', result: 'some result from the save action'}
 *  })
 *. catch(() => {
 *   // User answered cancel
 *  });
 */
export default class UnsavedChangesDialog {
  @observable opened = false;
  @observable isSaving = false;
  deferred = null;
  saveAction = null;
  onCancel = null;
  onDiscard = null;

  @action
  setSaving = value => {
    this.isSaving = value;
  };

  @action
  check = () => {
    if (!this.saveAction) {
      return Promise.resolve();
    }
    this.open();

    this.deferred = new Deferred();
    return this.deferred.promise;
  };

  @action
  save = () => {
    const {saveAction} = this;
    this.setSaving(true);
    return saveAction().then(result => {
      this.reset();
      this.deferred.resolve({answer: 'save', result});
    });
  };

  @action
  discard = () => {
    if (typeof this.onDiscard === 'function') {
      this.onDiscard();
    }

    this.reset();
    this.deferred.resolve({answer: 'discard'});
  };

  @action
  cancel = () => {
    if (typeof this.onCancel === 'function') {
      this.onCancel();
    }

    this.opened = false;
    this.deferred.reject({answer: 'cancel'});
  };

  @action
  open = () => {
    this.opened = true;
  };

  @action
  reset = () => {
    this.setSaving(false);
    this.opened = false;
    this.saveAction = null;
    this.onCancel = null;
    this.onDiscard = null;
  };

  constructor() {
    makeObservable(this);
  }
}
