import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const fields = ['icon', 'translations', 'translations[].locale', 'translations[].translation'];

const rules = {
  icon: 'string|required|min:3',
  'translations[].locale': 'string|required|min:3',
  'translations[].translation': 'string|required|min:3'
};

export default new MobxReactForm({fields, rules}, {plugins});
