import styled from 'styled-components';

export const StyledLink = styled.a`
  color: white;
  font-weight: bold;
  text-decoration: underline;
  &:hover {
    color: white;
    font-weight: bold;
    text-decoration: underline;
  }
`;
