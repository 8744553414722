import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {inject, observer} from 'mobx-react';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';

import {SetNativeAppAutoRemovalInterval} from 'api/platform/mutations';
import {PlatformFlags} from 'api/platform/queries';
import {setNativeAppAutoRemovalIntervalOptions} from 'api/platform/mutation-options';

import Checkbox from 'components/Checkbox';

import {guideRemovalIntervals} from './constants';
import messages from './messages';
import {
  SectionWrapper,
  RemoveGuidesSection,
  Header,
  AutoRemovalSection,
  StyledSpan,
  StyledRemoveGuidesSelect
} from './styles';

@inject('store')
@graphql(SetNativeAppAutoRemovalInterval, setNativeAppAutoRemovalIntervalOptions)
@graphql(PlatformFlags)
@observer
class AutoRemovalGuidesSettings extends Component {
  componentDidMount = () => {
    const {
      intl: {formatMessage},
      store: {
        platformSettingsPage: {setTranslations}
      }
    } = this.props;

    const translations = mapValues(
      pick(messages, [
        'changedDaysSuccessMessage',
        'changedDaysErrorMessage',
        'autoRemovalTurnedOnSuccess',
        'autoRemovalTurnedOffSuccess',
        'autoRemovalTurnedOnError',
        'autoRemovalTurnedOffError'
      ]),
      message => formatMessage(message)
    );
    setTranslations(translations);
  };

  componentDidUpdate = () => {
    const {
      data: {platformForCms},
      store: {
        platformSettingsPage: {setExistingNativeAppAutoRemovalInterval}
      }
    } = this.props;

    if (platformForCms) {
      setExistingNativeAppAutoRemovalInterval(platformForCms.nativeAppAutoRemovalInterval);
    }
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        platformSettingsPage: {autoRemoveGuides, onTimeIntervalChange, autoRemoveGuidesLoading}
      },
      guidesAutoRemovalForm,
      setNativeAppAutoRemovalInterval: setNativeAppAutoRemovalIntervalMutation
    } = this.props;
    const {autoRemoval, automaticallyRemoveGuides, removeGuides, afterDownload} = messages;

    return (
      <SectionWrapper>
        <AutoRemovalSection>
          <Header>{formatMessage(autoRemoval)}</Header>
          <Checkbox
            checked={autoRemoveGuides}
            onChange={() => onTimeIntervalChange(setNativeAppAutoRemovalIntervalMutation, true)}
            caption={formatMessage(automaticallyRemoveGuides)}
            dataTestId={'guides-removal-checkbox'}
            disabled={autoRemoveGuidesLoading}
          />
        </AutoRemovalSection>
        <RemoveGuidesSection>
          <StyledSpan disabled={!autoRemoveGuides || autoRemoveGuidesLoading}>{formatMessage(removeGuides)}</StyledSpan>
          <StyledRemoveGuidesSelect
            allowClear={false}
            options={guideRemovalIntervals}
            field={'autoRemovalInterval'}
            form={guidesAutoRemovalForm}
            onChange={() => onTimeIntervalChange(setNativeAppAutoRemovalIntervalMutation)}
            disabled={!autoRemoveGuides || autoRemoveGuidesLoading}
            testId={'guides-removal-time-interval'}
          />
          <StyledSpan disabled={!autoRemoveGuides || autoRemoveGuidesLoading}>
            {formatMessage(afterDownload)}
          </StyledSpan>
        </RemoveGuidesSection>
      </SectionWrapper>
    );
  }
}

export default injectIntl(AutoRemovalGuidesSettings);
