import {observable, action, computed, autorun, makeObservable} from 'mobx';
import store from 'stores/store';

class Steps {
  constructor({steps, preview, template, editMode = false}) {
    makeObservable(this);
    this.steps = steps;
    this.preview = preview;
    this.template = template;
    this.editMode = editMode;
  }

  @observable currentStepIndex = 0;

  @action
  goToTab = activeKey => {
    const {router} = store;
    const {currentRoute, params} = router;

    if (activeKey === null) return;

    this.currentStepIndex = activeKey;
    const tab = this.steps[activeKey].path;

    params?.id ? router.goTo(currentRoute, {tab, id: params.id}) : router.goTo(currentRoute, {tab});
  };

  @action
  goToPreview = () => {
    const {router} = store;
    const {currentRoute, params} = router;
    const tab = 'preview';

    params.id ? router.goTo(currentRoute, {tab, id: params.id}) : router.goTo(currentRoute, {tab});
  };

  @action.bound nextTab() {
    this.goToTab(this.currentStepIndex + 1);
  }

  @action.bound previousTab() {
    this.goToTab(this.currentStepIndex - 1);
  }

  @action
  resolveStepFromPath = tab => {
    if (!tab) return;
    if (this.preview && this.preview.key === tab) {
      return;
    }
    if (this.template && this.template.key === tab) {
      return;
    }
    this.steps.forEach((step, index) => {
      if (step.path === tab) {
        if (!this.template || this.template.disabled) {
          if (!this.editMode && index > this.currentStepIndex && !this.steps[this.currentStepIndex].checked) {
            this.goToTab(this.currentStepIndex);
          } else {
            this.currentStepIndex = index;
            step.checked = false;
          }
        } else {
          this.goToTab(index);
          step.checked = true;
        }
      } else if (index <= this.currentStepIndex && (step.form ? step.form.isValid : true)) {
        step.checked = true;
      } else if (index < this.currentStepIndex && !this.editMode) {
        this.goToTab(this.currentStepIndex);
        step.checked = true;
      } else {
        step.checked = false;
      }
    });
  };

  @computed get currentStep() {
    const {router} = store;
    const tab = router?.params?.tab;
    if (this.preview && this.preview.key === tab) return this.preview;
    if (this.template && this.template.key === tab) return this.template;
    return this.steps[this.currentStepIndex];
  }

  startListeningToRouteChange = () => {
    this.disposeAutorun = autorun(() => {
      const {
        router: {params}
      } = store;
      this.resolveStepFromPath(params?.tab);
    });
  };

  stopListeningToRouteChange = () => {
    this.disposeAutorun();
  };

  reset = () => {
    this.currentStepIndex = 0;
    this.steps.forEach(step => (step.checked = false));
  };
}

export default Steps;
