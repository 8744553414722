import styled from 'styled-components';

import {guideCardMetrics} from 'styles/metrics';

const containerMargin = 101;
const cardMargin = 12;

export const EmptyCard = styled.div`
  background: #f2f2f2;
  border-radius: ${guideCardMetrics.smallBorderRadius}px;
  height: 300px;
  margin: 12px 0;

  @media (min-width: 1650px) {
    width: 265px;
  }

  @media (max-width: 1649px) {
    width: 205px;
  }

  @media (max-width: 1020px) {
    width: ${({width}) => (width - containerMargin) / 3 - cardMargin * 2}px;
  }

  @media (max-width: 830px) {
    width: ${({width}) => (width - containerMargin) / 2 - cardMargin * 2}px;
  }

  @media (max-width: 570px) {
    width: ${({width}) => width - containerMargin - cardMargin * 2}px;
  }
`;
