import {defineMessages} from 'react-intl';

const messages = defineMessages({
  adoption: {
    id: 'Analytics.adoption',
    defaultMessage: 'Adoption'
  },
  usage: {
    id: 'Menu.usage',
    defaultMessage: 'Usage'
  },
  platformAdoption: {
    id: 'Analytics.platformAdoption',
    defaultMessage: 'Platform Adoption'
  },
  workspaceAdoption: {
    id: 'Analytics.workspaceAdoption',
    defaultMessage: 'Workspace Adoption'
  },
  usageTop10: {
    id: 'Menu.top10',
    defaultMessage: 'Top 10'
  },
  usageGuides: {
    id: 'common.title.allGuides',
    defaultMessage: 'All Guides'
  },
  usageInstructions: {
    id: 'Menu.allInstructions',
    defaultMessage: 'All Instructions'
  },
  checklists: {
    id: 'checklists',
    defaultMessage: 'Checklists'
  }
});

export default messages;
