import styled from 'styled-components';
import Field from 'ui-components/Field';

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 87px;
`;

export const StyledField = styled(Field)`
  margin-top: ${({noRecipients}) => (noRecipients ? '10px' : '')};
`;
