import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';
import SearchBar from 'ui-components/Layout/SearchBar';
import CampaignCard from 'components/CampaignCard';
import {FormattedMessage} from 'components/FormattedComponents';
import LoadingMessage from 'components/LoadingMessage';
//styled-components
import {CampaignsList, CampaignCardWrapper} from './styles';
import {NoResultsFound} from 'styles/styled-components/shared-components';

//data
import {Campaigns} from 'api/campaign/queries';
import {campaignsOptions} from 'api/campaign/query-options';

//messages
import messages from './messages';

import {filterCampaigns, sortCampaigns} from './utils';

@inject('store')
@graphql(Campaigns, campaignsOptions)
@observer
class CampaignsListView extends Component {
  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      auth: {user},
      router
    } = store;
    const {canManageCampaigns} = user;
    if (!canManageCampaigns) {
      router.goTo(views.root, {});
    }
  }

  componentDidMount() {
    const {store} = this.props;
    const {app} = store;

    const actionMenuItems = [
      {
        id: 'create',
        params: {tab: 'template'},
        view: 'newCampaign'
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  render() {
    const {
      store: {
        campaignsPage: {setSearchTerm, searchTerm}
      },
      data: {campaigns, loading, error},
      intl: {formatMessage}
    } = this.props;

    const sortedCampaigns = sortCampaigns(campaigns);
    const filteredCampaigns = filterCampaigns(sortedCampaigns, searchTerm);

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.signoffsNew)}
          subtitle={`${formatMessage(messages.campaignSubtitle)} ${formatMessage(messages.extraCampaignSubtitle)}`}
          right={
            <SearchBar
              placeholder={formatMessage(messages.searchByTitle)}
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
              }}
            />
          }
        />
        <CampaignsList>
          {filteredCampaigns.length > 0 ? (
            filteredCampaigns.map(campaign => (
              <CampaignCardWrapper key={campaign.id} data-cy="sign-off-card">
                <CampaignCard campaign={campaign} />
              </CampaignCardWrapper>
            ))
          ) : loading ? (
            <LoadingMessage hasFailed={error}>
              <FormattedMessage {...messages.loading} />
            </LoadingMessage>
          ) : (
            <NoResultsFound>
              <FormattedMessage {...messages.noSignOffs} />
            </NoResultsFound>
          )}
        </CampaignsList>
      </Container>
    );
  }
}

export default injectIntl(CampaignsListView);
