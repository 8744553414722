import {defineMessages} from 'react-intl';

const messages = defineMessages({
  action: {
    id: 'EditTopic.action',
    defaultMessage: 'Remove topic'
  },
  archiveFailure: {
    id: 'EditTopic.archiveFailure',
    defaultMessage: 'Error removing topic.'
  },
  archiveSuccess: {
    id: 'EditTopic.archiveSuccess',
    defaultMessage: 'Topic has been removed.'
  },
  collapseTitle: {
    id: 'EditTopic.collapseTitle',
    defaultMessage: 'Show topic collapsed'
  },
  confirmation: {
    id: 'EditTopic.confirmation',
    defaultMessage: 'Are you sure you want to remove your topic ‘{title}’?'
  },
  delete: {
    id: 'common.actions.remove',
    defaultMessage: 'Remove'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  error: {
    id: 'common.notifications.error',
    defaultMessage: 'Error'
  },
  hideTitle: {
    id: 'EditTopic.hideTitle',
    defaultMessage: 'Hide title'
  },
  translate: {
    id: 'common.actions.translate',
    defaultMessage: 'Translate'
  },
  showTitle: {
    id: 'EditTopic.showTitle',
    defaultMessage: 'Show title'
  },
  success: {
    id: 'common.notifications.success',
    defaultMessage: 'Success'
  },
  updateError: {
    id: 'EditTopic.updateError',
    defaultMessage: 'Error updating topic.'
  },
  updateSuccess: {
    id: 'EditTopic.updateSuccess',
    defaultMessage: 'Topic has been updated.'
  },
  options: {
    id: 'EditTopic.options',
    defaultMessage: 'Options'
  },
  moveTo: {
    id: 'common.actions.moveTo',
    defaultMessage: 'Move to...'
  },
  moveTopicTitle: {
    id: 'EditTopic.moveTopicTitle',
    defaultMessage: 'Move this topic to...'
  },
  moveTopicButton: {
    id: 'EditTopic.moveTopicButton',
    defaultMessage: 'Yes, move this topic'
  },
  moveTopicSuccess: {
    id: 'EditTopic.moveTopicSuccess',
    defaultMessage: 'Topic moved successfully to '
  },
  moveTopicFailure: {
    id: 'EditTopic.moveTopicFailure',
    defaultMessage: 'Failed to move topic'
  },
  share: {
    id: 'common.actions.share',
    defaultMessage: 'Share'
  },
  shareTopic: {
    id: 'shareTopic',
    defaultMessage: 'Share Topic'
  },
  defaultLanguagesMustMatch: {
    id: 'defaultLanguagesMustMatch',
    defaultMessage: 'Unable to move content. Make sure default languages are the same everywhere.'
  }
});

export default messages;
