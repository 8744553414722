import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {ApplyCampaign} from 'api/campaign/mutations';
import {applyCampaignOptions} from 'api/campaign/mutation-options';

import CampaignPreview from 'components/CampaignPreview';
import Dialog from 'ui-components/Dialog';

import messages from './messages';

@inject('store')
@graphql(ApplyCampaign, applyCampaignOptions)
@observer
class CampaignPreviewDialog extends Component {
  onApply = () => {
    const {
      store: {
        applyCampaignDialog: {setSelectedCampaign, submit},
        campaignPreviewDialog: {campaign, reset}
      },
      applyCampaignToInstructionsMutation
    } = this.props;

    setSelectedCampaign(campaign);
    reset();
    submit(applyCampaignToInstructionsMutation);
  };

  render() {
    const {
      intl: {formatMessage},
      store: {
        campaignPreviewDialog: {opened, close}
      }
    } = this.props;

    return (
      <Dialog
        actions={[
          {
            label: formatMessage(messages.apply),
            onClick: this.onApply,
            disabled: false
          }
        ]}
        isOpen={Boolean(opened)}
        onCancel={close}
        dialogStyle={{width: 900}}
        customCancelButtonText={messages.close}
      >
        <CampaignPreview />
      </Dialog>
    );
  }
}

export default injectIntl(CampaignPreviewDialog);
