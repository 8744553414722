import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

//styled-components
import {Field, Grid, GridItem, GridItemGrowing, SectionDescription, SectionLabel, StyledButton} from './styles';
import SkillsDrawer from 'components/SkillsDrawer';

//messages
import messages from './messages';

import {SkillLevels} from 'api/skill-matrix/queries';
import {skillLevelsOptions} from 'api/skill-matrix/query-options';

import SelectedSkills from './SelectedSkills';

import {bindField} from 'shared/utils/input-utils';
@inject('store')
@graphql(SkillLevels, skillLevelsOptions)
@observer
class SkillProfileCreateGeneral extends Component {
  componentDidUpdate(prevProps) {
    const {
      skillLevelsQuery,
      store: {
        skillProfilePage: {setAvailableSkillLevels}
      }
    } = this.props;
    const {loading: loadingSkillLevels, skillLevels} = skillLevelsQuery;

    if (prevProps.skillLevelsQuery !== this.props.skillLevelsQuery && !loadingSkillLevels) {
      setAvailableSkillLevels(skillLevels);
    }
  }

  handleSkillsChange(selected) {
    const {
      store: {
        skillProfilePage: {setSelectedSkills, generalStepForm}
      }
    } = this.props;

    setSelectedSkills(selected);
    generalStepForm.update({
      skills: selected
    });
  }

  render() {
    const {
      store: {
        skillProfilePage: {generalStepForm, isSkillsDrawerOpened, openSkillsDrawer, closeSkillsDrawer}
      },
      intl: {formatMessage}
    } = this.props;

    return (
      <>
        <Grid>
          <GridItem>
            <SectionLabel>{formatMessage(messages.generalSettings)}</SectionLabel>
          </GridItem>
          <GridItem>
            <Field
              field="name"
              form={generalStepForm}
              size="small"
              {...bindField(generalStepForm, 'name')}
              autofocus={true}
              title={formatMessage(messages.skillProfileName)}
              placeholder={formatMessage(messages.enter)}
            />
          </GridItem>
        </Grid>
        <Grid>
          <GridItem>
            <SectionLabel>{formatMessage(messages.addSkills)}</SectionLabel>
            <SectionDescription>{formatMessage(messages.skillProfilesDescription)}</SectionDescription>
          </GridItem>
          <GridItemGrowing>
            <SelectedSkills />
            <StyledButton iconId="add" onClick={openSkillsDrawer} label={formatMessage(messages.addSkills)} />
          </GridItemGrowing>
        </Grid>

        <SkillsDrawer
          isOpen={isSkillsDrawerOpened}
          onClose={closeSkillsDrawer}
          onChange={selectedSkills => this.handleSkillsChange(selectedSkills)}
        />
      </>
    );
  }
}

export default injectIntl(SkillProfileCreateGeneral);
