import {defineMessages} from 'react-intl';

const messages = defineMessages({
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  back: {
    id: 'common.actions.back',
    defaultMessage: 'Back'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  next: {
    id: 'common.actions.next',
    defaultMessage: 'Next'
  },
  createSuccess: {
    id: 'NewTheme.createSuccess',
    defaultMessage: 'Theme has been created!'
  },
  default: {
    id: 'EditTheme.default',
    defaultMessage: '(default)'
  },
  nameAndLogo: {
    id: 'EditTheme.nameAndLogo',
    defaultMessage: 'Name & Logo'
  },
  colors: {
    id: 'Themes.colors',
    defaultMessage: 'Colors'
  },
  stepNoteIcons: {
    id: 'EditTheme.stepNoteIcons',
    defaultMessage: 'Step Note Icons'
  },
  fontSettings: {
    id: 'EditTheme.fontSettings',
    defaultMessage: 'Font'
  },
  notFound: {
    id: 'EditTheme.notFound',
    defaultMessage: 'Theme not found.'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  }
});

export default messages;
