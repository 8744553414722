import React, {Component} from 'react';
import {inject} from 'mobx-react';
import {DropTarget} from 'react-dnd';

//components
import InstructionItemPlaceholder from '../InstructionItemPlaceholder';

//drag-and-drop
import {DND_TYPE, target} from './dnd';

@inject('store')
@DropTarget(DND_TYPE, target, connect => ({
  connectDropTarget: connect.dropTarget()
}))
class DroppableInstructionItemPlaceholderComponent extends Component {
  render() {
    const {connectDropTarget, ...forwardedProps} = this.props;
    return <InstructionItemPlaceholder {...forwardedProps} hostRef={connectDropTarget} />;
  }
}

export default DroppableInstructionItemPlaceholderComponent;
