import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';
import {colors} from 'views/SkillProfiles/styles';
import $Icon from 'ui-components/Layout/Icon';

const LIST_GROUP_ITEM_HEIGHT = 46;

export const DrawerWrapper = styled.div`
  background: ${commonPalette.white};
  position: relative;
  max-width: 90vw;
  width: 500px;
`;

export const DrawerTitle = styled.h2`
  font-size: 24px;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 40px;
  margin-bottom: 10px;
`;

export const DrawerDescription = styled.p`
  color: var(--neutrals-grey, ${commonPalette.txGrey});
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 32px;
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;

export const BackIcon = styled($Icon).attrs(() => ({
  name: 'chevron-left',
  size: 32
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const SearchBarContainer = styled.div`
  position: relative;
  margin-bottom: 32px;

  > input {
    border: 1px solid ${colors.backgroundGrey};
    border-radius: 2px;
    display: block;
    padding: 10px 16px 10px 44px;
    width: 100%;

    :focus {
      outline: none;
      border: 1px solid #298784;
    }
  }

  > svg {
    color: ${colors.iconGrey};
    height: 18px;
    position: absolute;
    left: 16px;
    top: 12px;
  }
`;

export const ListContainer = styled.div`
  background: ${commonPalette.lightGrey};
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 12px;
  max-width: 600px;
  margin-bottom: 18px;
`;

export const ListGroup = styled.div`
  border-radius: 2px;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
  padding: 0 16px;
`;

export const ListGroupTitleItem = styled.div`
  border-bottom: 1px solid #ededed;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 0;
  align-items: flex-start;
  justify-content: space-between;

  > p {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ChevronIcon = styled($Icon).attrs(() => ({
  name: 'chevron-right',
  size: 16
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 3px;
  margin-right: 5px;
  margin-top: 2px;
  transform: rotate(${props => (props.open ? '90deg' : '0deg')});
  transition: transform 0.3s ease-out;
`;

export const ListGroupItemsContainer = styled.div`
  overflow: hidden;
  padding-left: 32px;
  transition: height 0.3s ease-out;
  height: ${props => (props.opened ? `${props.itemCount * LIST_GROUP_ITEM_HEIGHT}px` : 0)};
`;

export const ListGroupItem = styled.div`
  border-bottom: 1px solid #ededed;
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 0;
  height: ${LIST_GROUP_ITEM_HEIGHT}px;

  :last-child {
    border-bottom: none;
  }

  > p {
    flex-grow: 1;
  }
`;

export const PlusIcon = styled($Icon).attrs({
  name: 'plus',
  size: 20
})`
  color: ${commonPalette.txGrey};
  cursor: pointer;
`;

export const CheckIconContainer = styled.div`
  align-items: center;
  background: #298784;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  width: 20px;
`;

export const CheckIcon = styled($Icon).attrs({
  name: 'checkmark',
  size: 14
})`
  color: ${commonPalette.white};
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledCheckbox = styled.input`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid var(--grey-400, #dce0e8);
  background: var(--white, ${commonPalette.white});
`;

export const GrayText = styled.span`
  color: ${commonPalette.txGrey};
`;
