import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Button from 'ui-components/Button';
import InfoBox from 'ui-components/InfoBox';

import {WebhookUrlContainer, MainContentContainer, UrlFieldContainer, UrlField, StyledIcon} from './styles';

const WebhookUrlSelector = ({currentWebhookUrl, messages, onSubmit}) => {
  const {descriptions, webhookUrlFieldLabel, submitButtonLabel} = messages;

  const [state, setState] = useState({
    webhookUrl: currentWebhookUrl,
    isSaveButtonEnabled: false
  });
  const {webhookUrl, isSaveButtonEnabled} = state;

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      isSaveButtonEnabled: currentWebhookUrl !== webhookUrl
    }));
  }, [currentWebhookUrl]);

  const setWebhookUrl = newUrl => {
    setState({
      webhookUrl: newUrl,
      isSaveButtonEnabled: currentWebhookUrl !== newUrl
    });
  };

  return (
    <WebhookUrlContainer>
      <MainContentContainer>
        <InfoBox content={descriptions.introText} type="informational" />
        <UrlFieldContainer>
          <UrlField
            inputId="webhook-url-input"
            type="text"
            name="webhook-url"
            title={webhookUrlFieldLabel}
            placeholder="https://webhook-address.com"
            value={webhookUrl || ''}
            onChange={event => {
              setWebhookUrl(event.target.value);
            }}
          />
          <Button
            label={submitButtonLabel}
            disabled={!isSaveButtonEnabled}
            onClick={() => {
              onSubmit(webhookUrl);
            }}
          />
        </UrlFieldContainer>
      </MainContentContainer>
    </WebhookUrlContainer>
  );
};

WebhookUrlSelector.propTypes = {
  currentWebhookUrl: PropTypes.string,
  messages: PropTypes.shape({
    descriptions: PropTypes.shape({
      introText: PropTypes.string
    }),
    webhookUrlFieldLabel: PropTypes.string,
    submitButtonLabel: PropTypes.string.isRequired
  }),
  onSubmit: PropTypes.func.isRequired
};

export default WebhookUrlSelector;
