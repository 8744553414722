import styled from 'styled-components';
import flex from 'styles/flex';

//components
import $Select from 'ui-components/Select';
import $UploadFont from 'components/UploadFont';

export const Form = styled.div`
  min-width: 350px;
  padding: 16px;
`;

export const TitleAndLink = styled.div`
  ${flex.horizontal};
  ${flex.centerHorizontalV};
  margin-bottom: 12px;
`;

export const FontFamilySelector = styled.div``;

export const UploadFont = styled($UploadFont)`
  margin-bottom: 25px;
`;

export const Select = styled($Select)`
  width: 256px;
  background-color: white;

  select::-ms-expand {
    display: none;
  }
`;
