import {DataItem, Sort} from './types';
import orderBy from 'lodash/orderBy';
import deburr from 'lodash/deburr';

export const getSortedData = (data: DataItem[], sorting: Sort = 'asc'): DataItem[] => {
  if (!data) return [];
  return orderBy(data, i => deburr(i.name.toLowerCase()), [sorting]);
};

export const calculateOrderIndex = (depth: number, index: number, parentIndex: string): string => {
  return depth === 1 ? `${index + 1}` : `${parentIndex}.${index + 1}`;
};
