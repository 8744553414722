import styled from 'styled-components';
import {Button as $Button} from 'styles/styled-components/shared-components';
import {Row as $Row} from 'styles/grid';
import flex from 'styles/flex';
import {flexGrid} from 'styles/vars';

export const CampaignsList = styled.div`
  width: 100%;
  ${flexGrid(3, 1, '(min-width: 1170px)', 1)}
  ${flexGrid(2, 1, '(max-width: 1169px)', 1)}
`;

export const CampaignCardWrapper = styled.div``;

export const Row = styled($Row)`
  ${flex.centerVerticalH};
`;

export const StatusButton = styled($Button)`
  margin-left: 15px;
`;

export const GoToReportLink = styled.div`
  cursor: pointer;
`;
