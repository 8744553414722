import PropTypes from 'prop-types';
import React, {Component} from 'react';

//styles
import {InstructionItemPlaceholder} from './styles';

class InstructionItemPlaceholderComponent extends Component {
  static propTypes = {
    hostRef: PropTypes.func
  };

  render() {
    const {hostRef} = this.props;
    return <InstructionItemPlaceholder ref={hostRef} />;
  }
}

export default InstructionItemPlaceholderComponent;
