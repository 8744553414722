import styled from 'styled-components';

export const ImageOverlayWrapper = styled.div``;

export const Container = styled.div`
  position: relative;
`;

export const Image = styled.div`
  position: relative;
  top: 0;
  left: 0;
`;

export const Label = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #939798;
  margin-bottom: 3px;
`;

export const OverlayCanvas = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  filter: brightness(100%);
`;

export const Canvas = styled.div`
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  filter: brightness(40%);
`;

export const ShapeButton = styled.button`
  padding: 5px;
  margin-right: 5px;
`;

export const HiddenIFrame = styled.iframe`
  border: 0;
  height: 100%;
  position: absolute;
  visibility: hidden;
  width: 100%;
`;
