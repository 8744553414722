import styled from 'styled-components';

export const SausagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  margin-bottom: 30px;
`;

export const LegendsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const LevelsOverview = styled.div`
  display: flex;
  gap: 25px;
  margin-top: 64px;
  flex-direction: column;
`;

export const LevelsOverviewHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const LevelsOverviewHeader = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const LevelsOverviewSausagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
`;

export const LevelsOverviewLegendsContainer = styled.div`
  display: flex;
  gap: 16px;
`;
