import gql from 'graphql-tag';

export const EditSkillLevel = gql`
  mutation editSkillLevel($id: String!, $name: String!, $description: String) {
    editSkillLevel(id: $id, name: $name, description: $description) {
      result
      error
    }
  }
`;

export const CreateSkill = gql`
  mutation createSkill($name: String!, $expirationMonths: Int, $skillTasksIds: [String], $tagsIds: [String]) {
    createSkill(name: $name, expirationMonths: $expirationMonths, skillTasksIds: $skillTasksIds, tagsIds: $tagsIds) {
      id
      name
    }
  }
`;

export const EditSkillMutation = gql`
  mutation editSkill($skill: SkillEdit, $skillTaskIds: [String], $tagsIds: [String]) {
    editSkill(skill: $skill, skillTaskIds: $skillTaskIds, tagsIds: $tagsIds) {
      id
      name
    }
  }
`;

export const SaveSkillProfile = gql`
  mutation saveSkillProfile($name: String!, $skills: [SkillsSkillProfileAdd], $teams: [SkillProfileTeamInput]) {
    saveSkillProfile(name: $name, skills: $skills, teams: $teams) {
      id
      name
    }
  }
`;

export const UpdateSkillProfile = gql`
  mutation updateSkillProfile($skillProfile: SkillProfileUpdate, $skills: [SkillsSkillProfileAdd]) {
    updateSkillProfile(skillProfile: $skillProfile, skills: $skills) {
      id
      name
    }
  }
`;

export const UpdateSkillProfileTeams = gql`
  mutation updateSkillProfileTeams($id: String!, $teams: [SkillProfileTeamInput]!) {
    updateSkillProfileTeams(id: $id, teams: $teams) {
      result
      error
    }
  }
`;

export const DeleteSkill = gql`
  mutation deleteSkill($id: String!) {
    deleteSkill(id: $id) {
      result
      error
    }
  }
`;
