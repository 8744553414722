import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';

import {CheckUserDeletion, User} from 'api/user/queries';
import {checkUserDeletionOptions, getUserQueryOptions} from 'api/user/query-options';

import OnlyPlatformAdminDialog from './OnlyPlatformAdminDialog';
import HasPendingApprovalsDialog from './HasPendingApprovalsDialog';
import OnlyApproverDialog from './OnlyApproverDialog';
import HasAssignedGuidesDialog from './HasAssignedGuidesDialog';
import CanDeleteUserDialog from './CanDeleteUserDialog';
import LoadingUserDialog from './LoadingUserDialog';
import ErrorFetchingUserDialog from './ErrorFetchingUserDialog';

import {deleteUserConstraints} from './constants';

const {
  USER_IS_ONLY_PLATFORM_ADMIN,
  USER_HAS_PENDING_APPROVAL,
  USER_IS_ONLY_APPROVER_IN_TEAM,
  USER_HAS_GUIDES_ASSIGNED_TO
} = deleteUserConstraints;

@inject('store')
@graphql(CheckUserDeletion, checkUserDeletionOptions)
@graphql(User, getUserQueryOptions)
@observer
class DeleteUserDialog extends Component {
  render() {
    const {
      store: {
        deleteUserDialog: {opened, close, userId}
      },
      checkUserDeletionQuery: {
        error: errorCheckUserDeletion,
        loading: loadingCheckUserDeletion,
        checkUserDeletionConstraints
      },
      getUserQuery: {error: errorGetUser, loading: loadingGetUser, user}
    } = this.props;

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const showDialog = params.hideDialog ? false : opened;

    if (loadingCheckUserDeletion || loadingGetUser) {
      return <LoadingUserDialog isOpen={showDialog} />;
    }

    if (errorCheckUserDeletion || errorGetUser) {
      return <ErrorFetchingUserDialog isOpen={showDialog} onCancel={close} />;
    }

    if (!checkUserDeletionConstraints.hasConstraint) {
      return <CanDeleteUserDialog isOpen={showDialog} user={user} />;
    }

    const {kind, payload} = checkUserDeletionConstraints.userDeletionConstraint;
    const teams = payload ? payload.teams : [];
    const guides = payload ? payload.guides : [];

    const mappedDialogs = {
      [USER_IS_ONLY_PLATFORM_ADMIN]: <OnlyPlatformAdminDialog isOpen={showDialog} onCancel={close} />,
      [USER_HAS_PENDING_APPROVAL]: (
        <HasPendingApprovalsDialog isOpen={showDialog} teams={teams} user={user} onCancel={close} />
      ),
      [USER_IS_ONLY_APPROVER_IN_TEAM]: (
        <OnlyApproverDialog isOpen={showDialog} teams={teams} user={user} onCancel={close} />
      ),
      [USER_HAS_GUIDES_ASSIGNED_TO]: (
        <HasAssignedGuidesDialog isOpen={showDialog} guides={guides} user={user} onCancel={close} userId={userId} />
      )
    };

    return mappedDialogs[kind];
  }
}

export default DeleteUserDialog;
