import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'overrideSignOff.title',
    defaultMessage: 'Some instructions already have sign-offs'
  },
  apply: {
    id: 'common.actions.apply',
    defaultMessage: 'Apply'
  },
  keepCampaignsOption: {
    id: 'overrideSignOff.keepSignOffsOption',
    defaultMessage: 'Apply and keep existing sign-offs'
  },
  overrideCampaignsOption: {
    id: 'overrideSignOff.overrideSignOffsOption',
    defaultMessage: 'Apply to all and override existing sign-offs'
  }
});

export default messages;
