import {defineMessages} from 'react-intl';

const messages = defineMessages({
  actions: {
    id: 'Users.actions',
    defaultMessage: 'Actions'
  }
});

export default messages;
