import styled from 'styled-components';

//helpers
import {commonPalette} from 'shared/styles/palette';

//components
import {Card} from 'styles/styled-components/card-styled-components';

export const CampaignTemplateCard = styled(Card)`
  background: #fff;
  margin: 0 18px 18px 0;
  max-width: 280px;

  // Reset the Card component's styles:
  height: auto;
  min-height: 0;

  ${p =>
    p.isSelected &&
    `
    background: ${commonPalette.btnGreen};
    color: #fff;
  `}
`;

export const Main = styled.div`
  font-size: 16px;
  line-height: 24px;
  padding: 16px;
`;

export const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: bold;
`;

export const Description = styled.div`
  opacity: 0.6;
`;
