import {action, observable, computed, makeObservable} from 'mobx';

import {CUSTOM_FILTER_OPTIONS} from 'shared/enums';
import notification from 'utils/notification-utils';
import {removeEntityId} from 'utils/filters';

const {TAG, TEAM, ASSIGNEE, WORKSPACE} = CUSTOM_FILTER_OPTIONS;

export default class ArchiveDialog {
  @action
  setStore = store => {
    this.store = store;
  };

  @observable opened = false;

  translations = {};
  metadata = {};

  @action
  archive = async archiveTranslations => {
    const {mutation, params, onArchive, metadata} = this;
    const translations = {...archiveTranslations, ...this.translations};

    try {
      const res = await mutation(params);
      const {data} = res;

      if (data.archiveTeam && !data.archiveTeam.result) {
        notification.error(translations.archiveFailure);
        this.opened = false;
        return;
      }

      if (typeof onArchive === 'function') {
        onArchive(data, translations, metadata);
      }

      if (this.type === 'user') {
        removeEntityId(ASSIGNEE, params.id, this.store);
      }

      if (this.type === 'team') {
        removeEntityId(TEAM, params.id, this.store);
      }

      if (this.type === 'tag') {
        removeEntityId(TAG, params.id, this.store);
      }

      if (this.type === 'workspace') {
        removeEntityId(WORKSPACE, params.id, this.store);
      }

      // notifications are handled in disableUserCallback so we skip for this case
      if (!(data.disableUserWithResult || data.removeTeamMember)) {
        notification.success(translations.archiveSuccess);
      }

      this.opened = false;
      this.reset();
      const {tags} = await this.store.tagManagementPage.fetchData();
      this.store.tagManagementPage.setTags(tags);
    } catch (e) {
      notification.error(translations.archiveFailure);
      this.opened = false;
    }
  };

  @action
  setEnableSubmit = val => {
    this.enableSubmit = val;
  };

  @action
  open = ({params, mutation, translations, onArchive, itemPrimary, itemSecondary, style, content, type, metadata}) => {
    this.params = params;
    this.mutation = mutation;
    this.translations = translations;
    this.opened = true;
    this.onArchive = onArchive;
    this.itemPrimary = itemPrimary;
    this.itemSecondary = itemSecondary;
    this.content = content;
    this.style = style;
    this.type = type;
    this.metadata = metadata;
  };

  @action
  reset = () => {
    this.opened = false;
    this.params = null;
    this.mutation = null;
    this.translations = {};
    this.onArchive = null;
    this.itemPrimary = null;
    this.itemSecondary = null;
    this.content = null;
    this.style = {};
    this.type = null;
    this.metadata = {};
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get enableSubmit() {
    if (this.type == 'team') {
      return this.store.reassignGuideDialog.enableArchiveSubmit;
    }

    return true;
  }
}
