import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';
import mutationNames from 'api/theme/constants';
import {graphql} from '@apollo/client/react/hoc/graphql';

// components
import StepsTabs from 'components/StepsTabs';
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';

//data
import {ThemeForCms} from 'api/theme/queries';
import {TeamsLite} from 'api/team/queries';
import {IconsList} from 'api/platform/queries';
import {teamsLiteOptions} from 'api/team/query-options';
import {EditTheme} from 'api/theme/mutations';
import {editThemeOptions} from 'api/theme/mutation-options';
import {getThemeOptions} from 'api/theme/query-options';
import {iconsListEnabledOptions} from 'api/platform/query-options';

import {FormattedMessage} from 'shared/components/FormattedComponents';

//helpers
import views from 'config/views';

//messages
import messages from './messages';

@inject('store')
@graphql(EditTheme, editThemeOptions)
@graphql(ThemeForCms, getThemeOptions)
@graphql(TeamsLite, teamsLiteOptions)
@graphql(IconsList, iconsListEnabledOptions)
@observer
class EditThemeView extends Component {
  static defaultProps = {
    store: null,
    data: null
  };

  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {
        user: {canManageThemes, isPlatformAdmin}
      },
      themePage,
      router
    } = store;

    if (!canManageThemes) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, [
        'nameAndLogo',
        'colors',
        'stepNoteIcons',
        'fontSettings',
        'actionSuccess',
        'actionFailure',
        'default'
      ]),
      message => formatMessage(message)
    );

    themePage.setTranslations(translations);
    themePage.setNameAndLogoForm(isPlatformAdmin);
    themePage.setSteps(true);
    themePage.stepsStore.startListeningToRouteChange();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store: {
        themePage: {setSelectedTheme, setTeams, setPlatformIcons}
      },
      data: {themeForCms: theme, loading},
      teamsQuery: {teams = [], loading: teamsLoading},
      iconsListEnabledQuery: {icons = [], loading: loadingIcons}
    } = nextProps;

    if (!loading && theme) {
      setSelectedTheme(theme);
    }

    if (!teamsLoading && teams) {
      setTeams(teams);
    }

    if (!loadingIcons && icons) {
      setPlatformIcons(icons);
    }
  }

  componentWillUnmount() {
    const {
      store: {themePage}
    } = this.props;
    themePage.reset();
    themePage.stepsStore.stopListeningToRouteChange();
  }

  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const editThemeMutation = this.props[mutationNames.EDIT_THEME];
    const {themePage} = store;
    const {stepsStore, editTheme} = themePage;

    const formSubmit = () => {
      editTheme(editThemeMutation, theme.id);
      store.router.goTo(views.themes, {});
    };

    const {
      data: {themeForCms: theme, loading, error}
    } = this.props;

    if (!loading && !theme) {
      return (
        <Container>
          <FormattedMessage {...messages.notFound} />
        </Container>
      );
    }

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <StepsTabs
          mode={'edit theme'}
          loading={loading}
          error={error || null}
          stepsStore={stepsStore}
          page={themePage}
          formSubmit={formSubmit}
          cancel={() => store.router.goTo(views.themes, {})}
        />
      </Container>
    );
  }
}

export default injectIntl(EditThemeView);
