import React from 'react';
import {inject} from 'mobx-react';
import Dialog from 'ui-components/Dialog';
import {injectIntl} from 'react-intl';
import RadioButton from 'shared/components/RadioButton';
import Spinner from 'shared/components/Spinner';
import {LoadingContainer, SpinnerWrapper} from './styles';
import messages from './messages';

const SelectDefaultLanguageDialog = ({store, isOpen, intl: {formatMessage}}) => {
  const {isLoading, onSave, reset, translationRate, selectedLocale, setSelectedLocale} =
    store.selectDefaultGuideLanguageDialog;
  return (
    <Dialog
      title={formatMessage(messages.selectGuideLanguage)}
      isOpen={isOpen}
      actions={[
        {
          loading: Boolean(isLoading && translationRate.length),
          disabled: isLoading,
          label: formatMessage(messages.save),
          onClick: () => onSave(formatMessage)
        }
      ]}
      onCancel={reset}
    >
      {isLoading && translationRate.length === 0 ? (
        <LoadingContainer>
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        </LoadingContainer>
      ) : (
        <>
          {translationRate?.map(({locale, language, completion}) => (
            <RadioButton
              key={locale}
              caption={`${language} (${formatMessage(messages.translationPercentage, {
                percentage: completion > 100 ? 100 : completion
              })})`}
              checked={locale === selectedLocale}
              disabled={completion < 100}
              onChange={() => setSelectedLocale(locale)}
            />
          ))}
        </>
      )}
    </Dialog>
  );
};

export default injectIntl(inject('store')(SelectDefaultLanguageDialog));
