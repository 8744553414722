import withScrolling from 'react-dnd-scrollzone';
import styled from 'styled-components';

//helpers
import scrollParent from 'decorators/scrollParent';
import flex from 'styles/flex';
import {dropShadow} from 'styles/sidebar';

//components
import $StickySidebar from 'ui-components/Layout/StickySidebar';

export const Wrapper = styled.div`
  ${flex.horizontal};
  flex-grow: 1;
  margin-left: ${p => {
    if (p.canCollapse) return 56;
    if (window.innerWidth <= 820) return 200;
    return 230;
  }}px;
`;

export const StyledStickySidebar = withScrolling(
  scrollParent()(styled($StickySidebar).attrs({
    wrapperStyle: {
      flexShrink: 0,
      position: 'absolute',
      left: 0,
      width: `${window.innerWidth <= 820 ? 200 : 230}px !important`
    }
  })`
    ${dropShadow};
    z-index: 1;
    padding: ${p => (p.padding ? '24px 30px 40px' : '0px')};

    button {
      margin-top: 1rem;
    }

    ${p =>
      !p.isOpen &&
      `
      padding: 0px;
      width: 56px;
      cursor: pointer;
      transition: all 0.2s;
      overflow: hidden; // sorry, this is just too ugly on Windows
      &:hover {
        background: rgba(0,0,0,.1);
      }
      h3 {
        transform: rotate(-90deg);
        display: block;
        height: 32px;
        white-space: nowrap;
        transform-origin: 100% 100%;
        position: absolute;
        top: 0; right: 12px;
      }
    `}
  `)
);

export const TitleWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const MainColumn = styled.div`
  ${flex.allowOverflowX};
  ${flex.vertical};
  flex-grow: 1;
  padding: ${p => (p.padding ? '24px 30px 40px' : '0px')};
`;
