import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  contentChanges: {
    id: 'contentChanges',
    defaultMessage: 'Content Changes'
  },
  publishedVersions: {
    id: 'publishedVersions',
    defaultMessage: 'Published Versions'
  },
  approvalUpdates: {
    id: 'approvalUpdates',
    defaultMessage: 'Approval Updates'
  }
});

export default messages;
