import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Button from 'ui-components/Button';
import Icon from 'ui-components/Icon';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import SearchBar from 'ui-components/Layout/SearchBar';

//styled-components
import Container from 'ui-components/Layout/Container';

// utils
import notification from 'utils/notification-utils';

//data
import {Domains} from 'api/domain/queries';
import {domainsOptions} from 'api/domain/query-options';
import {ArchiveDomain} from 'api/domain/mutations';
import {archiveDomainOptions} from 'api/domain/mutation-options';

//messages
import messages from './messages';
import {DataTable, ArchiveButton, ButtonWrapper, HeaderCell, NameWrapper, Name, Url, StyledLink} from './styles';
import {HeaderWrapper, CellWrapper, StyledText} from 'ui-components/Layout/DataTable/styles';
@inject('store')
@graphql(Domains, domainsOptions)
@graphql(ArchiveDomain, archiveDomainOptions)
@observer
class WorkspacesViewComponent extends Component {
  state = {
    searchTerm: ''
  };

  UNSAFE_componentWillMount() {
    const {store} = this.props;
    const {
      auth: {user},
      router,
      editDomainPage
    } = store;
    const {canManageDomains} = user;
    if (!canManageDomains) {
      router.goTo(views.root, {});
    }
    editDomainPage.setPreviousView(router.currentRoute.path);
  }

  componentDidMount() {
    const {store} = this.props;
    const {
      auth: {user},
      app,
      platform
    } = store;
    const {hasMultiDomainEnabled} = platform;
    const {isPlatformAdmin} = user;

    const actionMenuItems = [
      {
        id: 'create',
        view: 'newWorkspace'
      }
    ];

    if (isPlatformAdmin && hasMultiDomainEnabled) {
      app.setActionMenuItems(actionMenuItems);
    }
  }

  componentWillUnmount() {
    const {
      store: {app}
    } = this.props;
    app.resetActionMenuItems();
  }

  archive({value: id, original}) {
    const {
      archiveDomainMutation,
      intl: {formatMessage},
      store
    } = this.props;
    const {archiveDialog} = store;

    if (original.isDefault) {
      // This shouldn't happen it's only a double check
      return notification.error(formatMessage(messages.archiveDefaultDomain));
    }

    const translations = {
      action: formatMessage(messages.archiveWorkspace),
      confirmation: formatMessage(messages.archiveWorkspaceConfirm),
      body: formatMessage(messages.archiveWorkspaceBody),
      archiveSuccess: formatMessage(messages.archiveWorkspaceSuccess),
      archiveFailure: formatMessage(messages.archiveWorkspaceFailure)
    };

    archiveDialog.open({
      params: {id},
      mutation: archiveDomainMutation,
      translations,
      itemPrimary: original.name,
      itemSecondary: `https://${original.slug}-${window.location.hostname}`
    });
  }

  updateSearchTerm = e => {
    this.setState({searchTerm: e.target.value.toLowerCase().trim()});
  };

  render() {
    const {
      domainsQuery: {domains = [], loading, error},
      intl: {formatMessage},
      store
    } = this.props;
    const {
      auth: {user},
      platform: {sso: ssoEnabled}
    } = store;
    const {isIPA, isPlatformAdmin} = user;
    const {searchTerm} = this.state;

    const filteredDomains = domains && domains.filter(domain => domain.name.toLowerCase().includes(searchTerm));

    const noWorkspacesMessage =
      !loading && !domains.length
        ? formatMessage(messages.noWorkspaces)
        : searchTerm
        ? formatMessage(messages.noFilteredWorkspaces, {searchTerm})
        : null;

    if (loading || error) {
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }
    const columns = [
      {
        Header: <HeaderWrapper paddingLeft>{formatMessage(messages.name)}</HeaderWrapper>,
        accessor: 'name',
        resizable: true,
        Cell: ({original: {name, slug, isDefault}}) => {
          const url = isDefault ? `https://${window.location.hostname}` : `https://${slug}-${window.location.hostname}`;
          return (
            <CellWrapper paddingLeft>
              <NameWrapper>
                <Name title={name}>{name}</Name>
                <Url>
                  <StyledLink title={url} href={url} target="_blank">
                    {url}
                  </StyledLink>
                </Url>
              </NameWrapper>
            </CellWrapper>
          );
        },
        width: 225
      },
      {
        resizable: true,
        Header: () => {
          return (
            <HeaderCell>
              <StyledText marginLeft={20}>
                {formatMessage(messages.managementTeam)}
                <Icon style={{marginLeft: 8}} popoverContent={formatMessage(messages.managementTeamDescription)} />
              </StyledText>
            </HeaderCell>
          );
        },
        accessor: 'managementTeam.name',
        width: 215,
        Cell: ({value}) => (
          <CellWrapper>
            <StyledText marginLeft={20}>
              <span title={value}>{value}</span>
            </StyledText>
          </CellWrapper>
        )
      },
      {
        resizable: true,
        Header: () => {
          return (
            <HeaderCell>
              {formatMessage(messages.defaultTeam)}
              <Icon style={{marginLeft: 8}} popoverContent={formatMessage(messages.defaultTeamDescription)} />
            </HeaderCell>
          );
        },
        accessor: 'defaultTeam.name',
        show: ssoEnabled,
        width: 190
      },
      {
        accessor: 'id',
        Cell: ({value: id}) => (
          <ButtonWrapper>
            <Button
              underlined
              label={formatMessage(messages.teams)}
              onClick={e => {
                e.stopPropagation();
                store.router.goTo(views.workspaceTeams, {id});
              }}
            />
          </ButtonWrapper>
        ),
        sortable: false,
        width: 100
      },
      {
        accessor: 'id',
        Cell: x => (
          <Button
            underlined
            label={'Reset Failed Logins'}
            onClick={async event => {
              event.stopPropagation();

              if (!x.value) {
                return;
              }
              try {
                const resetUrl = `${window.location.origin}/ipa/redis/login_fail*${x.value}*`;
                await fetch(resetUrl, {method: 'DELETE'});
                notification.success('Reset Login failed succeeded.');
              } catch (e) {
                notification.error('Failed to reset failed login attempts.');
              }
            }}
          />
        ),
        sortable: false,
        show: isIPA || isPlatformAdmin,
        width: 150
      },
      {
        accessor: 'id',
        Cell: workspace => {
          const {
            original: {isDefault}
          } = workspace;
          return (
            !isDefault && (
              <ArchiveButton
                data-cy="archive-domain"
                onClick={e => {
                  e.stopPropagation();
                  this.archive(workspace);
                }}
              />
            )
          );
        },
        sortable: false,
        style: {marginRight: 0},
        width: 24
      }
    ];

    return (
      <Container>
        <ViewTitle
          title={formatMessage(messages.title)}
          subtitle={formatMessage(messages.workspaceDescription)}
          right={<SearchBar placeholder={formatMessage(messages.searchWorkspaces)} onChange={this.updateSearchTerm} />}
        />
        <DataTable
          data={filteredDomains}
          columns={columns}
          noDataMessage={noWorkspacesMessage}
          hoverEffect
          defaultSorted={[
            {
              id: 'name',
              desc: false
            }
          ]}
          getTrProps={(_, rowInfo) => ({
            onClick: () => store.router.goTo(views.editWorkspace, {id: rowInfo.original.id})
          })}
        />
      </Container>
    );
  }
}

export default injectIntl(WorkspacesViewComponent);
