import {refreshTagsList} from 'api/tag/refresh-queries';

export const getSaveTranslationsForTagsOptions = {
  props: ({
    mutate,
    ownProps: {
      store: {platform}
    }
  }) => ({
    saveTranslationsForTagsMutation: variables => {
      const locale = platform.getLanguage();
      return mutate({
        variables,
        refetchQueries: [refreshTagsList(locale)]
      });
    }
  })
};
