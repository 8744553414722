import React, {useCallback} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import DataTable from 'ui-components/Layout/DataTable';
import {CellWrapper, HeaderWrapper} from 'ui-components/Layout/DataTable/styles';
import SmartSkillsLegendItem from 'components/SmartSkillsLegendItem';
import SmartSkillsSausageBar from 'components/SmartSkillsSausageBar';
import views from 'config/views';
import {SausagesContainer, LoaderContainer, StyledDiv} from './styles';
import {capitalize, sumBy} from 'lodash';
import {LEGEND_LEVEL_COLOR_MAP} from './constants';
import messages from './messages';
import {PaginatedSkillMatrix, SkillLevels} from 'api/skill-matrix/queries';
import {useLazyQuery, useQuery} from '@apollo/client';
import Pagination from 'ui-components/Pagination';
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'shared/components/FormattedComponents';
import {MatrixDataTeam} from './types';
import {maxNumberOfRowsPerPage} from 'ui-components/Layout/DataTable/constants';
import {usePaginationParameters} from './utils-hook';
const pageSize = maxNumberOfRowsPerPage;
const LoadingState = () => (
  <LoaderContainer>
    <LoadingMessage></LoadingMessage>
  </LoaderContainer>
);

const ErrorState = () => {
  return <FormattedMessage {...messages.error} />;
};

const LevelOverviewComponent = props => {
  const {
    store,
    intl: {formatMessage}
  } = props;

  const skillLevelsQuery = useQuery(SkillLevels);
  const {loading: loadingSkillLevels} = skillLevelsQuery;
  const [getSkillMatrixQuery, {loading, error, data}] = useLazyQuery(PaginatedSkillMatrix, {
    fetchPolicy: 'network-only'
  });

  const fetchData: Parameters<typeof usePaginationParameters>[0] = useCallback(
    ({pageIndex, sorted}) => {
      getSkillMatrixQuery({
        variables: {
          limit: pageSize,
          offset: pageIndex * pageSize,
          sortBy: sorted.map(sortByItem => {
            return {field: sortByItem.id, order: sortByItem.desc ? 'desc' : 'asc'};
          })
        }
      });
    },
    [getSkillMatrixQuery]
  );

  const {pageIndex, setPageIndex, sorted, setSorted} = usePaginationParameters(fetchData);

  if (loading || loadingSkillLevels) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState />;
  }

  const onClickTeam = id => {
    store.router.goTo(views.smartSkillsTeam, {teamId: id});
  };

  const sortedLevels = () => {
    if (loading || loadingSkillLevels) {
      return [];
    }

    const NO_LEVEL_POSITION = -1;

    const allLevels: Array<{name: string; position: number}> = [];
    skillLevelsQuery.data?.skillLevels.forEach(level => {
      if (level) {
        allLevels.push({name: level?.name, position: level?.position});
      }
    });
    allLevels.push({name: 'NO LEVEL', position: NO_LEVEL_POSITION});

    allLevels.sort((a, b) => {
      if (a.name === 'NO LEVEL') return 1;
      if (b.name === 'NO LEVEL') return NO_LEVEL_POSITION;
      return b.position - a.position;
    });

    const levelsMap = new Map(allLevels.map(level => [level.name, level]));

    const uniqueSortedLevels: Array<{name: string; position: number}> = Array.from(levelsMap.values());
    const mappedUniqueSortedLevels = uniqueSortedLevels.map(level => level.name);
    return mappedUniqueSortedLevels.map((level, index) => (
      <SmartSkillsLegendItem
        key={level}
        color={LEGEND_LEVEL_COLOR_MAP[index]}
        outline={level === 'NO LEVEL'}
        label={capitalize(level)}
      />
    ));
  };

  const columns = [
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.teamName)}</HeaderWrapper>,
      accessor: 'name',
      Cell: ({value}) => (
        <CellWrapper paddingLeft data-cy={value.split(' ').join('-')}>
          <span title={value}>{value}</span>
        </CellWrapper>
      ),
      width: 250
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.members)}</HeaderWrapper>,
      accessor: 'members',
      Cell: ({value}) => (
        <CellWrapper paddingLeft data-cy={value}>
          <span title={value}>{value}</span>
        </CellWrapper>
      ),
      width: 110,
      sortable: false
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.skills)}</HeaderWrapper>,
      accessor: 'skills',
      Cell: ({value}) => (
        <CellWrapper paddingLeft data-cy={value}>
          <span title={value}>{value}</span>
        </CellWrapper>
      ),
      width: 110,
      sortable: false
    },
    {
      Header: <HeaderWrapper paddingLeft>{formatMessage(messages.levelsDistribution)}</HeaderWrapper>,
      accessor: 'levels',
      Cell: ({value, original: {totalSkillsAssigned}}) => {
        if (!value) {
          return;
        }

        const definedLevels = value.filter(({level}) => level?.id);
        const totalDefinedLevels = sumBy(definedLevels, 'count');
        const sortedLevels = definedLevels.sort((a, b) => (a.level?.position ?? -1) < (b.level?.position ?? -1));

        // Push new level "No Level" with id = null
        sortedLevels.push({
          count: totalSkillsAssigned - totalDefinedLevels,
          level: {
            id: null
          }
        });

        return (
          <CellWrapper paddingLeft data-cy={value}>
            <SausagesContainer>
              {sortedLevels.map(({count, level}, index) => {
                const percentage = ((count / totalSkillsAssigned) * 100).toFixed();
                return (
                  <SmartSkillsSausageBar
                    key={level.id}
                    color={LEGEND_LEVEL_COLOR_MAP[index]}
                    value={percentage}
                    percentage={percentage}
                    outline={!level?.id}
                  />
                );
              })}
            </SausagesContainer>
          </CellWrapper>
        );
      },
      sortable: false
    }
  ];

  const skillMatrix: MatrixDataTeam[] = data?.paginatedSkillMatrix?.results?.map(matrix => {
    return {
      id: matrix.id,
      name: matrix.name,
      members: matrix.members,
      skills: matrix.skills?.skillsCount || 0,
      levels: matrix.skills?.levels,
      totalSkillsAssigned: matrix.skills?.totalSkillsAssigned
    };
  });

  const totalPages = Math.ceil(data?.paginatedSkillMatrix.totalCount / pageSize);

  if (!skillMatrix?.length) {
    return <div>{formatMessage(messages.noData)}</div>;
  }

  return (
    <>
      <StyledDiv>{sortedLevels()}</StyledDiv>
      <DataTable
        columns={columns}
        hoverEffect
        paddingRows={5}
        getTrGroupProps={(_, rowInfo) => ({
          onClick: () => onClickTeam(rowInfo.original.id)
        })}
        rowBottomBorder={false}
        data={skillMatrix || []}
        defaultSorted={sorted}
        onSortedChange={setSorted}
      />
      {totalPages > 1 && (
        <Pagination
          totalPages={totalPages}
          currentPage={pageIndex}
          setPageIndex={setPageIndex}
          prevPageTitle={formatMessage(messages.prevPage)}
          nextPageTitle={formatMessage(messages.nextPage)}
        />
      )}
    </>
  );
};
export default inject('store')(injectIntl(LevelOverviewComponent));
