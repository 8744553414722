import React from 'react';
import {observable, action, makeObservable} from 'mobx';

//components
import WebhookUrlSetting from 'components/WebhookUrlSetting';
import Recipients from 'components/Recipients';

//form
import AlertRecipientForm from 'stores/forms/alert-recipients-form';

//models
import Steps from 'stores/models/steps';

class ChecklistAlertsPage {
  @observable translations = {};
  @observable stepsStore;
  @observable store = null;
  form = AlertRecipientForm;

  @action
  setStore = store => {
    this.store = store;
  };

  @action
  setTranslations = translations => (this.translations = translations);

  @action
  setSteps = editMode => {
    const basicStep = [
      {
        key: 'recipients',
        path: 'recipients',
        component: <Recipients />,
        checked: false
      }
    ];
    const additionalStep = [
      {
        key: 'platformAlerts',
        path: 'platform-alerts',
        component: <WebhookUrlSetting />,
        checked: false
      }
    ];
    this.stepsStore = new Steps({
      steps: [...basicStep, ...additionalStep],
      editMode
    });
  };

  constructor() {
    makeObservable(this);
    this.setSteps(true);
  }
}

export default ChecklistAlertsPage;
