import React, {useState} from 'react';
import {inject} from 'mobx-react';
import {injectIntl} from 'react-intl';
import moment from 'moment';

//helpers
import {DATE_FORMAT, TIME_FORMAT, expandableEventsList} from 'config/constants';

//components
import Icon from 'ui-components/Icon';
import DetailedCard from 'components/GuideActivityEvent/DetailedCard';
import type Store from 'stores/store';

import {GuideActivityEvent, Info, Label, TimeLabel, ChevronIcon, Message} from './styles';
import {isCheckEvent, isChecklistEvent, isGuideEvent, isInstructionEvent, isStepEvent, isTopicEvent} from './utils';
import TopicEvent from './EventTypes/TopicEvent';
import ChecklistInstructionEvent from './EventTypes/ChecklistInstructionEvent';
import StepCheckEvent from './EventTypes/StepCheckEvent';
import GuideEvent from './EventTypes/GuideEvent';
import {ActivityEvent} from './types';

export type GuideActivityEventProps = {
  activity: ActivityEvent;
  showDivider: boolean;
  store?: typeof Store;
};

type StepNoteData = {
  stepNoteContent?: string;
  stepNoteBefore?: string;
  stepNoteAfter?: string;
};

const hasStringContent = (activity: ActivityEvent, stepNoteData: StepNoteData): boolean => {
  const {content, before, after, beforeMedia, afterMedia, beforeNoteMedia, afterNoteMedia} = activity;
  const {stepNoteBefore, stepNoteAfter, stepNoteContent} = stepNoteData;
  return (
    !!content ||
    !!before ||
    !!after ||
    !!beforeMedia ||
    !!afterMedia ||
    !!stepNoteBefore ||
    !!stepNoteAfter ||
    !!stepNoteContent ||
    !!beforeNoteMedia ||
    !!afterNoteMedia
  );
};

const hasArrayElements = (activity: ActivityEvent): boolean => {
  const {reviewers, approvers, workspaces, beforeWorkspaces, afterWorkspaces} = activity;
  return !!(
    reviewers?.length ||
    approvers?.length ||
    workspaces?.length ||
    beforeWorkspaces?.length ||
    afterWorkspaces?.length
  );
};

const getStepNoteData = (activity: ActivityEvent): StepNoteData => {
  const {eventType, beforeNote, afterNote} = activity;
  let stepNoteContent;
  let stepNoteBefore;
  let stepNoteAfter;

  if (eventType === 'step_note_content_updated') {
    if (!beforeNote) {
      stepNoteContent = afterNote?.content;
    }
    stepNoteBefore = beforeNote?.content;
    stepNoteAfter = afterNote?.content;
  }

  return {stepNoteContent, stepNoteBefore, stepNoteAfter};
};

const GuideActivityEventComponent = (props: GuideActivityEventProps) => {
  const {activity, showDivider, store} = props;
  const {
    platform: {
      developmentFeatureFlags: {activityLogLinking: isActivityLogLinkingEnabled}
    },
    versionHistoryDrawer: drawer,
    router
  } = store as typeof Store;

  const [cardOpened, setCardOpened] = useState(false);

  const {
    eventType,
    comment,
    content,
    before,
    after,
    beforeMedia,
    afterMedia,
    beforeNoteMedia,
    afterNoteMedia,
    beforeWorkspaces,
    afterWorkspaces,
    reviewers,
    approvers,
    guideApproval,
    workspaces,
    producedAt
  } = activity;

  const stepNoteData = getStepNoteData(activity);
  const {stepNoteContent, stepNoteBefore, stepNoteAfter} = stepNoteData;
  const time = producedAt && moment(producedAt).format(TIME_FORMAT);
  const date = producedAt && moment(producedAt).format(DATE_FORMAT);
  const hasContent = hasStringContent(activity, stepNoteData) || hasArrayElements(activity);
  const showChevron = expandableEventsList.includes(eventType) && hasContent;

  const Chevron = () => (
    <ChevronIcon hidden={!showChevron} open={cardOpened} onClick={() => showChevron && setCardOpened(!cardOpened)} />
  );

  const TimeComponent = () => (
    <TimeLabel>
      <Icon iconId="date" solid={false} />
      <Label>{date}</Label>
      <Icon iconId="time" solid={false} />
      <Label>{time}</Label>
    </TimeLabel>
  );

  const InfoComponent = ({children}) => (
    <Info>
      <Label>
        {children}
        {comment && <Message>{comment}</Message>}
      </Label>
    </Info>
  );

  return (
    <GuideActivityEvent eventType={eventType} showDivider={showDivider}>
      {isTopicEvent(eventType) && (
        <TopicEvent
          activity={activity}
          router={router}
          canLink={isActivityLogLinkingEnabled}
          checkTopicLinkIsValid={drawer.checkTopicLinkIsValid}
          Chevron={Chevron}
          TimeAndDate={TimeComponent}
          Info={InfoComponent}
        />
      )}
      {(isInstructionEvent(eventType) || isChecklistEvent(eventType)) && (
        <ChecklistInstructionEvent
          activity={activity}
          router={router}
          canLink={isActivityLogLinkingEnabled}
          checkInstructionLinkIsValid={drawer.checkInstructionLinkIsValid}
          Chevron={Chevron}
          TimeAndDate={TimeComponent}
          Info={InfoComponent}
        />
      )}
      {(isStepEvent(eventType) || isCheckEvent(eventType)) && (
        <StepCheckEvent
          activity={activity}
          router={router}
          canLink={isActivityLogLinkingEnabled}
          checkStepLinkIsValid={drawer.checkStepLinkIsValid}
          Chevron={Chevron}
          TimeAndDate={TimeComponent}
          Info={InfoComponent}
        />
      )}
      {isGuideEvent(eventType) && (
        <GuideEvent
          activity={activity}
          store={store as typeof Store}
          Chevron={Chevron}
          TimeAndDate={TimeComponent}
          Info={InfoComponent}
        />
      )}

      {cardOpened && (
        <DetailedCard
          eventType={eventType}
          content={content ?? stepNoteContent}
          before={before ?? stepNoteBefore}
          after={after ?? stepNoteAfter}
          beforeMedia={beforeMedia ?? beforeNoteMedia?.media}
          afterMedia={afterMedia ?? afterNoteMedia?.media}
          beforeWorkspaces={beforeWorkspaces}
          afterWorkspaces={afterWorkspaces}
          reviewers={reviewers}
          approvers={approvers}
          guideApproval={guideApproval}
          workspaces={workspaces}
        />
      )}
    </GuideActivityEvent>
  );
};

export default inject('store')(injectIntl(GuideActivityEventComponent));
