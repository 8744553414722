import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

//helpers
import {CAMPAIGN_QUESTION_TYPE_ENUMS} from 'shared/enums';

//styled-components
import {
  CampaignTemplateView,
  CampaignTemplateTypeContainer,
  CampaignTemplateType,
  StartWithoutTemplateLink,
  CampaignTemplateQuestionContainer
} from './styles';
import CampaignTemplateCard from 'components/CampaignForm/CampaignTemplateCard';
import Icon from 'ui-components/Layout/Icon';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

const questionTypeOptionToMessage = {
  training: messages.training,
  process: messages.processConfirmation,
  feedback: messages.feedback
};

const questionKindToMessage = {
  [CAMPAIGN_QUESTION_TYPE_ENUMS.NUMBER]: messages.number,
  [CAMPAIGN_QUESTION_TYPE_ENUMS.PASS_FAIL]: messages.passFail,
  [CAMPAIGN_QUESTION_TYPE_ENUMS.STAR]: messages.starRating,
  [CAMPAIGN_QUESTION_TYPE_ENUMS.TEXT]: messages.multipleChoice,
  [CAMPAIGN_QUESTION_TYPE_ENUMS.YES_NO]: messages.yesNo
};

@inject('store')
@observer
class CampaignTemplateComponent extends Component {
  render() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {newCampaignPage} = store;
    const {
      questionTemplateTypeOptions,
      questionTemplateType,
      chooseTemplateType,
      startWithoutTemplate,
      questionTemplateOptions,
      questionTemplate,
      chooseTemplate
    } = newCampaignPage;

    const resetTemplate = true;

    return (
      <CampaignTemplateView>
        <CampaignTemplateTypeContainer>
          <b>
            <FormattedMessage {...messages.categories} />
          </b>
          {questionTemplateTypeOptions.map(option => (
            <CampaignTemplateType
              key={option}
              isSelected={questionTemplateType === option}
              onClick={() => chooseTemplateType(option)}
            >
              <FormattedMessage {...questionTypeOptionToMessage[option]} />
            </CampaignTemplateType>
          ))}
        </CampaignTemplateTypeContainer>
        <CampaignTemplateQuestionContainer>
          {questionTemplateOptions.map(option => (
            <CampaignTemplateCard
              key={option.value}
              description={formatMessage(questionKindToMessage[option.question.kind])}
              imageUrl={option.imageUrl}
              isSelected={questionTemplate === option.value}
              onClick={() => chooseTemplate(option)}
              title={option.name}
            />
          ))}
        </CampaignTemplateQuestionContainer>
        <StartWithoutTemplateLink onClick={() => startWithoutTemplate(resetTemplate)}>
          <FormattedMessage {...messages.startWithoutTemplate} />
          <Icon name="arrow-right" />
        </StartWithoutTemplateLink>
      </CampaignTemplateView>
    );
  }
}

export default injectIntl(CampaignTemplateComponent);
