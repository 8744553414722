import {defineMessages} from 'react-intl';

const messages = defineMessages({
  checklistRecipients: {
    id: 'checklistRecipients',
    defaultMessage: 'You can assign recipients for individual Checklists when editing a Guide.'
  },
  recipientName: {
    id: 'recipientName',
    defaultMessage: 'Recipient Name'
  },
  deliveryMethods: {
    id: 'deliveryMethods',
    defaultMessage: 'Delivery Methods'
  },
  noRecipients: {
    id: 'noRecipients',
    defaultMessage: 'No recipients have been added yet.'
  },
  actions: {
    id: 'actions',
    defaultMessage: 'Actions'
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Edit'
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Delete'
  },
  errorLoadingRecipients: {
    id: 'errorLoadingRecipients',
    defaultMessage: 'Error loading Alert Recipients'
  }
});

export default messages;
