export const feedbacksListOptions = {
  options: ({store: {guideFeedbackDialog, platform}}) => {
    return {
      fetchPolicy: 'network-only',
      variables: {
        guideId: guideFeedbackDialog.guideId,
        locale: platform.getLanguage()
      }
    };
  }
};
