import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const fields = [
  {
    name: 'mainColor',
    rules: 'string|hex',
    value: '#000000'
  },
  {
    name: 'buttonColor',
    rules: 'string|hex',
    value: '#000000'
  }
];

export default new MobxReactForm({fields}, {plugins});
