import styled from 'styled-components';
import $ElementResizeListener from 'shared/components/ElementResizeListener';
import $Icon from 'ui-components/Layout/Icon';

//helpers
import typefaces from 'shared/styles/typefaces';

//components
import $UnderlinedTextbox from 'shared/components/Layout/Form/UnderlinedTextbox';

export const HeaderCard = styled($ElementResizeListener)`
  padding: 25px;
  min-width: 445px;
  width: 70%;
  background-color: white;
  border: 1px solid #ececec;
  border-radius: 5px;
  z-index: 1;
`;

export const StyledTitleUnderlinedTextbox = styled($UnderlinedTextbox)`
  ${typefaces.instructionDetails.title};
`;

export const FieldWrapper = styled.div`
  display: flex;
`;

export const ChecklistIcon = styled($Icon).attrs({
  name: 'checklist',
  size: 38
})`
  flex-shrink: 0;
  margin: -6px 10px -6px -10px;
`;
