import React, {useMemo} from 'react';

import {
  ListGroupItemsContainer,
  ListGroupItem,
  StyledBeforeText,
  StyledAfterText,
  FlexColumn,
  FlexRow,
  Close,
  Checkmark,
  StyledImageWrapper
} from './styles';

import {FormattedMessage} from 'components/FormattedComponents';
import CloudinaryPicture from 'shared/components/CloudinaryPicture';
import messages from './messages';
import {GUIDE_APPROVAL_TYPES} from 'config/enums';
import {isCreationEvent, isUpdateEvent, isMediaUpdateEvent} from '../utils';
import Workspaces from './Workspaces';
import {Media, Person, Workspace} from '../types';

interface DetailedCardProps {
  before?: string;
  after?: string;
  eventType: string;
  content?: string;
  beforeMedia?: Media;
  afterMedia?: Media;
  beforeWorkspaces?: Workspace[];
  afterWorkspaces?: Workspace[];
  reviewers?: Person[];
  approvers?: Person[];
  workspaces?: Workspace[];
  guideApproval?: GUIDE_APPROVAL_TYPES;
}

const getWorkspacesUpdates = (beforeWorkspaces?: Workspace[], afterWorkspaces?: Workspace[]) => {
  const beforeWorkspacesNames: string[] = beforeWorkspaces?.map(workspace => workspace.name) ?? [];
  const afterWorkspacesNames: string[] = afterWorkspaces?.map(workspace => workspace.name) ?? [];
  const unassigned = beforeWorkspacesNames.filter(workspace => !afterWorkspacesNames.includes(workspace)) ?? [];
  const assigned = afterWorkspacesNames.filter(workspace => !beforeWorkspacesNames.includes(workspace)) ?? [];
  return {unassigned, assigned};
};

const getApprovals = (reviewers?: Person[], approvers?: Person[]) => {
  return reviewers?.map(reviewer => {
    return [reviewer.fullName, approvers?.some(approver => approver.fullName === reviewer.fullName)];
  });
};

const DetailedCard = (props: DetailedCardProps) => {
  const {
    before,
    after,
    eventType,
    content,
    beforeMedia,
    afterMedia,
    beforeWorkspaces,
    afterWorkspaces,
    reviewers,
    approvers,
    workspaces,
    guideApproval
  } = props;

  const workspacesUpdated = useMemo(
    () => getWorkspacesUpdates(beforeWorkspaces, afterWorkspaces),
    [beforeWorkspaces, afterWorkspaces]
  );

  const approvals = useMemo(() => getApprovals(reviewers, approvers), [reviewers, approvers]);

  return (
    <ListGroupItemsContainer>
      <ListGroupItem>
        <FlexColumn>
          {isCreationEvent(eventType) && content && (
            <FlexRow>
              <StyledAfterText dangerouslySetInnerHTML={{__html: content}} />
            </FlexRow>
          )}
          {isUpdateEvent(eventType) && before && after && (
            <>
              <FlexRow>
                <Close />
                <StyledBeforeText dangerouslySetInnerHTML={{__html: before}} />
              </FlexRow>
              <FlexRow>
                <Checkmark />
                <StyledAfterText dangerouslySetInnerHTML={{__html: after}} />
              </FlexRow>
            </>
          )}

          {isMediaUpdateEvent(eventType) && (
            <FlexRow>
              {beforeMedia?.url && (
                <FlexRow>
                  <Close />
                  <StyledImageWrapper>
                    <CloudinaryPicture src={beforeMedia?.url} sizes="calc(50vw / 8 - 38px)" />
                  </StyledImageWrapper>
                </FlexRow>
              )}
              {afterMedia?.url && (
                <FlexRow>
                  <Checkmark />
                  <StyledImageWrapper>
                    <CloudinaryPicture src={afterMedia?.url} sizes="calc(50vw / 8 - 38px)" />
                  </StyledImageWrapper>
                </FlexRow>
              )}
            </FlexRow>
          )}
          {eventType === 'guide_published' && (
            <FlexRow>
              <StyledAfterText>
                <FormattedMessage
                  {...messages.publishedGuideToWorkspace}
                  values={{
                    workspaceCount: workspaces?.length,
                    workspaces: <Workspaces workspaces={workspaces} />
                  }}
                />
              </StyledAfterText>
            </FlexRow>
          )}

          {eventType === 'guide_workspaces_updated' && (
            <FlexColumn>
              {workspacesUpdated.unassigned.length > 0 && (
                <>
                  <FormattedMessage {...messages.unassignedFromWorkspaces} />
                  {workspacesUpdated.unassigned.map(workspace => (
                    <FlexRow key={workspace}>
                      <Close />
                      <StyledAfterText>{workspace}</StyledAfterText>
                    </FlexRow>
                  ))}
                </>
              )}
              {workspacesUpdated.assigned?.length > 0 && (
                <>
                  <FormattedMessage {...messages.assignedToWorkspaces} />
                  {workspacesUpdated.assigned.map(workspace => (
                    <FlexRow key={workspace}>
                      <Checkmark />
                      <StyledAfterText>{workspace}</StyledAfterText>
                    </FlexRow>
                  ))}
                </>
              )}
            </FlexColumn>
          )}
          {['guide_approval_partially_approved', 'guide_approval_requested'].includes(eventType) && (
            <FlexColumn>
              {approvals?.map(([reviewer, isApproved], index) => (
                <FlexRow key={reviewer}>
                  {isApproved ? <Checkmark /> : <Close hidden />}
                  <StyledAfterText>
                    {guideApproval === GUIDE_APPROVAL_TYPES.SEQUENTIAL && <>{index + 1}. </>}
                    {reviewer}
                  </StyledAfterText>
                </FlexRow>
              ))}
            </FlexColumn>
          )}
          {eventType === 'guide_approval_approved_and_published' && (
            <StyledAfterText>
              <FormattedMessage
                {...messages.approvalApprovedAndPublishedTo}
                values={{
                  workspacesCount: workspaces?.length,
                  workspaces: <Workspaces workspaces={workspaces} />
                }}
              />
            </StyledAfterText>
          )}
        </FlexColumn>
      </ListGroupItem>
    </ListGroupItemsContainer>
  );
};

export default DetailedCard;
