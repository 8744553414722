import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  activateGuideApproval: {
    id: 'TeamUsers.activateGuideApproval',
    defaultMessage: 'Activate guide approval'
  },
  guideApprovalRequirementExplanationV2: {
    id: 'TeamUsers.guideApprovalRequirementExplanationV2',
    defaultMessage: "Tick this box to ensure that this Team's Guides are approved before publishing"
  },
  setApproverOrder: {
    id: 'TeamUsers.setApproverOrder',
    defaultMessage: 'Set approval order'
  },
  guidesApprovedInOrderV2: {
    id: 'TeamUsers.guidesApprovedInOrderV2',
    defaultMessage: 'Set users in the order you want them to review and approve guides.'
  },
  addApprover: {
    id: 'TeamUsers.addApprover',
    defaultMessage: 'Add approver'
  },
  approver: {
    id: 'common.field.approver',
    defaultMessage: 'Approver'
  },
  select: {
    id: 'TeamUsers.select',
    defaultMessage: 'Select'
  },
  confirmAction: {
    id: 'common.actions.confirm',
    defaultMessage: 'Confirm'
  },
  confirmChange: {
    id: 'common.actions.confirmChange',
    defaultMessage: 'Confirm change'
  },

  updateGuideApprovalSettingsSuccess: {
    id: 'TeamUsers.updateGuideApprovalSettingsSuccess',
    defaultMessage: 'Guide approval settings saved'
  },
  updateGuideApprovalSettingsFailure: {
    id: 'TeamUsers.updateGuideApprovalSettingsFailure',
    defaultMessage: "Couldn't save guide approval settings"
  },
  guidesPendingApprovalWarning: {
    id: 'TeamUsers.confirmRoleChangeGuideApprovalCheck',
    defaultMessage:
      'There are one or more guides pending approval in this team. All approval requests will be canceled. Approval requesters and guide assignees will be notified.'
  }
});

export default messages;
