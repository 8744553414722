import {defineMessages} from 'react-intl';

const messages = defineMessages({
  addNewStepNoteIcon: {
    id: 'StepNoteIconDialog.addNewStepNoteIcon',
    defaultMessage: 'Add new Step Note'
  },
  editStepNoteIcon: {
    id: 'StepNoteIconDialog.editStepNoteIcon',
    defaultMessage: 'Edit Step Note'
  },
  uploadIcon: {
    id: 'StepNoteIconDialog.uploadIcon',
    defaultMessage: 'Upload Step Note Icon'
  },
  dropboxCaptionOnlySvg: {
    id: 'StepNoteIconDialog.dropboxCaptionOnlySvg',
    defaultMessage: 'Only files in SVG format are supported'
  },
  dropboxCaptionThemeColors: {
    id: 'StepNoteIconDialog.dropboxCaptionThemeColors',
    defaultMessage: "This Step Note Icon will always be displayed in the Workspace's Theme Color"
  },
  defineNameCaption: {
    id: 'StepNoteIconDialog.defineNameCaption',
    defaultMessage: 'Define Step Note Name & Translation'
  },
  dropboxContentSvgOnly: {
    id: 'StepNoteIconDialog.dropboxContentSvgOnly',
    defaultMessage: 'SVG Only'
  },
  dropboxContentCaption: {
    id: 'StepNoteIconDialog.dropboxContentCaption',
    defaultMessage: 'You can also upload files by'
  },
  dropboxContentClickHere: {
    id: 'StepNoteIconDialog.dropboxContentClickHere',
    defaultMessage: 'clicking here.'
  },
  tooLarge: {
    id: 'StepNoteIconDialog.tooLarge',
    defaultMessage: 'The SVG Icon exceeds 100kb'
  },
  extensionForbidden: {
    id: 'StepNoteIconDialog.extensionForbidden',
    defaultMessage: 'Only SVG is supported'
  },
  headerLanguages: {
    id: 'StepNoteIconDialog.headerLanguages',
    defaultMessage: 'Languages'
  },
  headerTranslations: {
    id: 'StepNoteIconDialog.headerTranslations',
    defaultMessage: 'Step Note Name'
  }
});

export default messages;
