import styled from 'styled-components';

import Icon from 'ui-components/Icon';

export const DetailsSection = styled.div`
  margin: 20px 0;
`;

export const DetailsRow = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const DetailsLeftCell = styled.div`
  flex: 2;
  color: #a3a3a3;
`;

export const DetailsRightCell = styled.div`
  flex: 5;
  color: #000;
  ${({display}) => display && `display: ${display};`}
  flex-wrap: wrap;
`;

export const StyledTag = styled.div`
  height: 24px;
  max-width: 250px;
  background: #eee;
  position: relative;
  display: inline-block;
  margin: 0 10px 5px 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 15px;

  &:before {
    content: '';
    width: 10px;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 12px solid #eee;
    border-left: 10px solid #fff;
  }

  &:after {
    content: '';
    width: 10px;
    height: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 12px solid #eee;
    border-left: 10px solid #fff;
  }
`;

export const StyledWorkspace = styled.div`
  margin-right: 15px;
`;

export const StyledIcon = styled(Icon)`
  margin: ${({marginTop}) => (marginTop ? marginTop : 3)}px ${({marginRight}) => (marginRight ? marginRight : 10)}px 0 0;
  color: #a3a3a3;
`;

export const WorkspaceWrapper = styled.div`
  display: flex;
`;

export const WrapperSpinner = styled.div`
  text-align: center;
`;

export const StyledErrorDetails = styled.p`
  text-align: center;
  font-size: 18px;
`;

export const dialogStyle = {background: 'none', border: 'none'};
