import {defineMessages} from 'react-intl';

const messages = defineMessages({
  createUser: {
    id: 'NewUser.createUser',
    defaultMessage: 'Create User'
  },
  email: {
    id: 'common.forms.email',
    defaultMessage: 'Email'
  },
  fullName: {
    id: 'EditUser.fullName',
    defaultMessage: 'Full name'
  },
  password: {
    id: 'common.forms.password',
    defaultMessage: 'Password'
  },
  confirmPassword: {
    id: 'common.forms.confirmPassword',
    defaultMessage: 'Confirm password'
  },
  passwordMismatch: {
    id: 'common.forms.passwordMismatch',
    defaultMessage: 'The passwords must match'
  },
  passwordMustHave8Characters: {
    id: 'common.forms.passwordMustHave8Characters',
    defaultMessage: 'Password must have at least 8 characters'
  },
  passwordMustHaveAtLeast1Number: {
    id: 'common.forms.passwordMustHaveAtLeast1Number',
    defaultMessage: 'Password must have at least 1 number'
  },
  passwordMustHaveAtLeast1UppercaseLetter: {
    id: 'common.forms.passwordMustHaveAtLeast1UppercaseLetter',
    defaultMessage: 'Password must have at least 1 uppercase letter'
  },
  passwordMustHaveAtLeast1LowercaseLetter: {
    id: 'common.forms.passwordMustHaveAtLeast1LowercaseLetter',
    defaultMessage: 'Password must have at least 1 lowercase letter'
  },
  passwordMustHaveAtLeast1NonAlphanumericCharacter: {
    id: 'common.forms.passwordMustHaveAtLeast1NonAlphanumericCharacter',
    defaultMessage: 'Password must have at least 1 non-alphanumeric character (!@$&, etc.)'
  },
  role: {
    id: 'EditUser.role',
    defaultMessage: 'Role'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  createSuccess: {
    id: 'EditUser.createSuccess',
    defaultMessage: 'User has been created.'
  },
  createFailure: {
    id: 'EditUser.createFailure',
    defaultMessage: 'Failed to create user.'
  },
  createFailureMaxEditors: {
    id: 'EditUser.createFailureMaxEditors',
    defaultMessage: 'Users. Oh no! Unfortunately, you can’t add another admin with your current subscription.'
  },
  contactSupport: {
    id: 'EditUser.contactSupport',
    defaultMessage: 'Please contact support to raise your limit.'
  },
  createNewUser: {
    id: 'EditUser.createNewUser',
    defaultMessage: 'Create New User'
  },
  addNewUserToTeam: {
    id: 'EditUser.addNewUserToTeam',
    defaultMessage: 'Add User to a Team'
  },
  createAndInvite: {
    id: 'createAndInvite',
    defaultMessage: 'Create & Invite User'
  },
  chatMessage: {
    id: 'chatMessageEditorLimit',
    defaultMessage:
      'Hi Swipee,\nI’m hitting the limit on our editors created. Could you tell me more on how I can upgrade my platform?'
  }
});

export default messages;
