import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {mapValues, pick} from 'lodash';
import views from 'config/views';

// components
import ViewTitle from 'ui-components/Layout/ViewTitle';
import Container from 'ui-components/Layout/Container';
import StepsTabs from 'components/StepsTabs';

//messages
import messages from './messages';

@inject('store')
@observer
class PlatformSettingsViewComponent extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {
      platformSettingsPage,
      auth: {
        user: {isPlatformAdmin}
      },
      router
    } = store;

    if (!isPlatformAdmin) {
      router.goTo(views.root, {});
    }

    const translations = mapValues(
      pick(messages, [
        'general',
        'stepNotes',
        'skillLevels',
        'alerts',
        'apiAccess',
        'toggleStepNoteNameSuccess',
        'toggleStepNoteNameFailure',
        'tagCreationRoleUpdateSuccess',
        'tagCreationRoleUpdateFailure',
        'webhookUrlUpdateSuccess',
        'webhookUrlUpdateFailure'
      ]),
      message => formatMessage(message)
    );

    platformSettingsPage.setTranslations(translations);
    platformSettingsPage.setSteps(true);
  }

  render() {
    const {
      intl: {formatMessage},
      store
    } = this.props;

    const {platformSettingsPage} = store;
    const {stepsStore} = platformSettingsPage;

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.title)} />
        <StepsTabs
          mode={'edit'}
          loading={false}
          error={null}
          stepsStore={stepsStore}
          page={platformSettingsPage}
          hideButtons={true}
        />
      </Container>
    );
  }
}

export default injectIntl(PlatformSettingsViewComponent);
