import styled from 'styled-components';

export const HiddenImage = styled.img`
  position: absolute;
`;

export const ResizeRect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const CropRect = styled.div`
  position: absolute;
  border-radius: 16px;
`;

export const ResizeHandle = styled.div`
  position: absolute;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  &:hover {
    background: #ededed;
  }
  &:after {
    content: '';
    position: absolute;
  }
`;

export const ResizeHandleH = styled(ResizeHandle)`
  width: 8px;
  height: 56px;
  cursor: ew-resize;
`;
export const ResizeHandleV = styled(ResizeHandle)`
  width: 56px;
  height: 8px;
  cursor: ns-resize;
`;

export const FocusPointHandle = styled.div`
  position: absolute;
  left: 0%;
  top: 0%;
  /* background: #ffffff; */
  border-radius: 100%;
  border: 4px solid #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  height: 16px;
  width: 16px;
`;

export const PreviewOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
`;
