import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {toJS} from 'mobx';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {clone} from 'lodash';
import {TEAM_MEMBER_ROLE} from 'shared/enums';

//styled-components
import {BreakLine, CreateUserPage, Field, PickerColumn, PickerRow, RemoveButton} from './styles';

//queries
import {Teams} from 'api/team/queries';
import {teamsOptions} from 'api/team/query-options';
import {SkillRoles} from 'api/skill-matrix/queries';
import {SkillRolesOptions} from 'api/skill-matrix/query-options';

//components
import RolePicker from 'components/RolePicker';
import TeamPicker from 'components/TeamPicker';
import Button from 'ui-components/Button';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';
import LoadingMessage from 'components/LoadingMessage';

const PLATFORM_ROLES = [TEAM_MEMBER_ROLE.ADMIN, TEAM_MEMBER_ROLE.EDITOR, TEAM_MEMBER_ROLE.VIEWER];
const PLATFORM_ROLES_VIEWER = [TEAM_MEMBER_ROLE.VIEWER];
@inject('store')
@graphql(Teams, teamsOptions)
@graphql(SkillRoles, SkillRolesOptions)
@observer
class NewUserTeamRoles extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      teamsQuery: {teams = [], loading},
      store
    } = nextProps;
    const {newUserPage} = store;

    if (!loading && teams) {
      const managableTeams = teams.filter(team => team.canManageTeam);
      newUserPage.setTeams(managableTeams);
    }
  }

  onChangeTeam = teamRole => {
    const {store} = this.props;
    const {newUserPage} = store;
    if (teamRole) {
      newUserPage.updateCurrentPickerValidity(teamRole.form.isValid);
    }
  };

  onChangeRole = teamRole => {
    const {store} = this.props;
    const {newUserPage} = store;
    if (teamRole) {
      newUserPage.updateCurrentPickerValidity(teamRole.form.isValid);
    }
  };

  render() {
    const {
      store,
      skillRolesQuery: {loading: loadingSkillRoles, skillRoles},
      intl: {formatMessage}
    } = this.props;
    const {
      newUserPage: page,
      platform: {hasSkillsEnabled}
    } = store;
    const {
      teamRoles,
      selectedIds,
      addTeamRolePicker,
      isAddTeamRoleEnabled,
      removeTeamRolePicker,
      teams,
      isCurrentPickerValid,
      form
    } = page;
    const showDeleteButton = toJS(teamRoles).length > 1;
    const sortedSkillRoles = (clone(skillRoles) || []).sort((a, b) => a.name.localeCompare(b.name));
    const skillRoleList = [{id: null, name: formatMessage(messages.noJobTitle)}, ...sortedSkillRoles];
    const isUsingPhone = form.values().contactMethod === 'PHONE_NUMBER';
    const roles = (isUsingPhone ? PLATFORM_ROLES_VIEWER : PLATFORM_ROLES).map(role => {
      return {name: role, id: role};
    });

    if (loadingSkillRoles) {
      return (
        <LoadingMessage>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );
    }

    if (!teams.length) {
      return <FormattedMessage {...messages.noAvailableTeams} />;
    }
    return (
      <CreateUserPage>
        {teamRoles &&
          teamRoles.map((teamRole, index) => {
            return (
              <PickerColumn key={`${teamRole.teamId}-${index}`}>
                <Field
                  size="small"
                  inputComponent={
                    <TeamPicker
                      allowClear={false}
                      field="teamId"
                      filterOption={selectedIds}
                      form={teamRole.form}
                      label={formatMessage(messages.selectTeam)}
                      onChange={() => this.onChangeTeam(teamRole)}
                      teams={teams.slice()}
                    />
                  }
                />
                {teamRole.form.values().teamId && (
                  <PickerRow>
                    <Field
                      size="small"
                      inputComponent={
                        <RolePicker
                          allowClear={false}
                          field="role"
                          roles={roles}
                          form={teamRole.form}
                          label={formatMessage(messages.selectRole)}
                          onChange={() => this.onChangeRole(teamRole)}
                        />
                      }
                    />
                    {showDeleteButton && <RemoveButton onClick={() => removeTeamRolePicker(index)} />}
                  </PickerRow>
                )}
                {teamRole.form.values().teamId && hasSkillsEnabled && (
                  <PickerRow>
                    <Field
                      size="small"
                      inputComponent={
                        <RolePicker
                          roles={skillRoleList}
                          placeholder={formatMessage(messages.noJobTitle)}
                          allowClear={false}
                          field="skillRoleId"
                          form={teamRole.form}
                          label={formatMessage(messages.selectJobTitle)}
                        />
                      }
                    />
                    {showDeleteButton && <RemoveButton onClick={() => removeTeamRolePicker(index)} />}
                  </PickerRow>
                )}
                <BreakLine />
              </PickerColumn>
            );
          })}
        {isCurrentPickerValid && (
          <Button
            underlined
            disabled={!isAddTeamRoleEnabled}
            iconId="add"
            onClick={addTeamRolePicker}
            label={formatMessage(messages.addToAnotherTeam)}
          />
        )}
      </CreateUserPage>
    );
  }
}

export default injectIntl(NewUserTeamRoles);
