import {defineMessages} from 'react-intl';

const messages = defineMessages({
  learningActivities: {
    id: 'learningActivities',
    defaultMessage: 'Learning Activities'
  },
  learningActivityName: {
    id: 'learningActivityName',
    defaultMessage: 'Learning Activity Name'
  },
  appliedToSkills: {
    id: 'appliedToSkills',
    defaultMessage: 'Applied to Skills'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  assignToSkills: {
    id: 'assignToSkills',
    defaultMessage: 'Assign to Skills'
  },
  delete: {
    id: 'common.actions.delete',
    defaultMessage: 'Delete'
  },
  searchByName: {
    id: 'searchByName',
    defaultMessage: 'Search by name'
  },
  sortBy: {
    id: 'common.actions.sortBy',
    defaultMessage: 'Sort by'
  },
  name: {
    id: 'common.forms.name',
    defaultMessage: 'Name'
  },
  createLearningActivity: {
    id: 'createLearningActivity',
    defaultMessage: 'Create Learning Activity'
  },
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  noLearningActivitiesFound: {
    id: 'noLearningActivitiesFoundNew',
    defaultMessage:
      "Oops! It looks like there aren't any learning activities matching your search options. Try broadening your search to find something relevant"
  },
  error: {
    id: 'common.errors.pageload',
    defaultMessage: "Couldn't load the page. Please try again."
  },
  filterByTag: {
    id: 'TagManagement.title',
    defaultMessage: 'Tags'
  },
  tenTags: {
    id: 'tenTags',
    defaultMessage: 'Select up to 10 tags.'
  },
  filtersTitle: {
    id: 'MediaGallery.filter',
    defaultMessage: 'Filter'
  },
  deleteLearningActivity: {
    id: 'deleteLearningActivity',
    defaultMessage: 'Delete Learning Activity?'
  },
  confirmDelete: {
    id: 'confirmDeleteLearningActivity',
    defaultMessage:
      'This item is used by {count} {count, plural, one {Skill Profile} other {Skill Profiles}}. If you delete it, it will be removed from all related Skills and Skill Profiles.'
  },
  errorDeletingLearningActivity: {
    id: 'errorDeletingLearningActivity',
    defaultMessage: 'Error deleting Learning Activity'
  },
  successDeletingLearningActivity: {
    id: 'successDeletingLearningActivity',
    defaultMessage: 'Learning Activity successfully deleted'
  }
});

export default messages;
