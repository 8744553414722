import {defineMessages} from 'react-intl';

const messages = defineMessages({
  saveTranslation: {
    id: 'TranslateTagsView.save',
    defaultMessage: 'Save translation'
  },
  saveSuccess: {
    id: 'TranslateTagsView.saveSuccess',
    defaultMessage: 'Translations have been saved.'
  },
  saveFailure: {
    id: 'TranslateTagsView.saveFailure',
    defaultMessage: 'Failed to save translations.'
  },
  defaultLanguage: {
    id: 'common.forms.defaultLanguage',
    defaultMessage: 'Default Language'
  },
  noLanguageSelected: {
    id: 'TranslateTagsView.noLanguageSelected',
    defaultMessage: 'No language selected'
  },
  backToOverview: {
    id: 'common.actions.backToOverview',
    defaultMessage: 'Back to overview'
  }
});

export default messages;
