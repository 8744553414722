import {defineMessages} from 'react-intl';

const messages = defineMessages({
  noFeedback: {
    id: 'Feedback.noFeedback',
    defaultMessage: 'No feedback given yet.'
  },
  followUpQuestion: {
    id: 'FollowUpQuestion.title',
    defaultMessage: 'Follow up question'
  },
  answer: {
    id: 'EditCampaign.answer',
    defaultMessage: 'Answer'
  },
  user: {
    id: 'EditCampaign.user',
    defaultMessage: 'User'
  }
});

export default messages;
