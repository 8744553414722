import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import views from 'config/views';

//lodash
import {mapValues, pick} from 'lodash';

//components
import StepsTabs from 'components/StepsTabs';
import Container from 'ui-components/Layout/Container';
import ViewTitle from 'ui-components/Layout/ViewTitle';

//messages
import messages from './messages';

//mutations
import {CreateCampaign} from 'api/campaign/mutations';

//options
import {createCampaignOptions} from 'api/campaign/mutation-options';

@inject('store')
@graphql(CreateCampaign, createCampaignOptions)
@observer
class NewCampaignView extends Component {
  UNSAFE_componentWillMount() {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {newCampaignPage: campaignPage} = store;
    campaignPage.stepsStore.startListeningToRouteChange();

    const translations = mapValues(
      pick(messages, ['preview', 'template', 'questions', 'createSuccess', 'createFailure']),
      message => formatMessage(message)
    );

    campaignPage.setTranslations(translations);
    this.setActionMenuItems();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      store: {
        newCampaignPage: campaignPage,
        platform: {availableLanguages, multipleLocales}
      }
    } = nextProps;

    campaignPage.reset();

    if (!multipleLocales) campaignPage.setDefaultLocale(availableLanguages[0].locale);
  }

  componentDidMount() {
    this.setActionMenuItems();
  }

  componentDidUpdate() {
    this.setActionMenuItems();
  }

  goToPreview = () => {
    const {
      store: {newCampaignPage: campaignPage}
    } = this.props;
    campaignPage.stepsStore.goToPreview();
  };

  exitPreview = () => {
    history.back();
  };

  setActionMenuItems = () => {
    const {
      store: {app, newCampaignPage: campaignPage}
    } = this.props;
    const isPreview = campaignPage.stepsStore.currentStep.key === 'preview';

    const actionMenuItems = [
      {
        id: 'preview',
        disabled: isPreview,
        onClick: this.goToPreview
      },
      {
        id: 'back',
        disabled: !isPreview,
        onClick: this.exitPreview,
        secondary: true
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  componentWillUnmount() {
    const {newCampaignPage: campaignPage, app} = this.props.store;
    campaignPage.reset();
    campaignPage.stepsStore.stopListeningToRouteChange();
    app.resetActionMenuItems();
  }

  render() {
    const {
      intl: {formatMessage},
      store,
      createCampaignMutation
    } = this.props;
    const {newCampaignPage: campaignPage} = store;
    const {stepsStore, submit} = campaignPage;
    const {currentStep} = stepsStore;

    const isPreview = currentStep.key === 'preview';

    const formSubmit = async () => {
      await submit(createCampaignMutation);
      store.router.goTo(views.campaigns, {});
    };

    if (!currentStep) {
      return <span />;
    }

    if (isPreview && currentStep) return currentStep.component;

    return (
      <Container>
        <ViewTitle title={formatMessage(messages.newCampaignTitle)} />
        {!isPreview && (
          <StepsTabs
            canSubmit={campaignPage.isValid}
            mode={'create'}
            stepsStore={stepsStore}
            page={campaignPage}
            formSubmit={formSubmit}
            cancel={() => store.router.goTo(views.campaigns, {})}
          />
        )}
      </Container>
    );
  }
}

export default injectIntl(NewCampaignView);
