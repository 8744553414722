import styled, {css} from 'styled-components';
import flex from 'styles/flex';
import {cond, noSelect} from 'shared/utils/sc-utils';
import {idealTextColor} from 'shared/utils/color-utils';
import {swallowingStyled} from 'styles/styled-components/utils';
import {borderRadius} from 'styles/sizes';

//components
import Icon from 'react-fontawesome';

export const ButtonStyle = css`
  text-align: center;
  outline: 0;
  border: none;
  transition: all 200ms linear;
  font-size: 16px;
  line-height: 16px;
  background-color: #323232;
  color: white;
  cursor: pointer;
  border-radius: ${borderRadius}px;
  font-weight: 300;
  min-width: 108px;
  padding: 0 20px;
  height: 40px;
  ${noSelect} ${p =>
    cond(
      p.type,
      `
    ${cond(
      p.type === 'primary',
      `
      background-color: ${p.theme.buttonColor};
      color: ${idealTextColor(p.theme.buttonColor)};
    `
    )}

    ${cond(
      p.type === 'danger',
      `
      background-color: #c76767;
      color: white;
    `
    )}
  `
    )}
  ${p =>
    cond(
      p.disabled,
      `
    color: rgba(0, 0, 0, 0.25);
    background-color: #eaeaea;
    border-color: #d9d9d9;
    cursor: not-allowed;
  `
    )};
`;

export const Button = styled.button`
  ${ButtonStyle};
`;

export const DraftLabel = styled.div`
  padding: 0px 8px;
  border-radius: ${borderRadius}px;
  background-color: #d6d6d6;
  display: inline-block;
  float: left;
  font-size: 12px;
  line-height: 24px;
`;

export const NotTranslatedLabel = styled(DraftLabel).attrs({
  className: 'not-translated-label'
})`
  color: #ffffff;
  background: #cc0000;
`;

export const NotTranslatedContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
`;

export const NoResultsFound = styled.div.attrs({
  className: 'no-results-found'
})`
  font-size: 16px;
  ${p => cond(p.withPadding, 'padding: 15px;')};
`;

export const CircleNumber = styled.div`
  ${flex.horizontal} ${flex.centerHorizontal} transition: all 200ms linear;
  opacity: 1;
  width: 24px;
  height: 24px;
  background-color: ${p => p.theme.mainColor};
  color: ${p => idealTextColor(p.theme.mainColor)};
  border-radius: 100%;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  top: 25px;
  left: -12px;
  z-index: 80;
`;

export const EmbeddedHtml = styled.div`
  p {
    margin: 5px 0;
  }

  ul {
    list-style: disc;
    margin-left: 20px;
  }

  ol {
    list-style: decimal;
    margin-left: 20px;
  }
`;

export const RotatingArrowIcon = swallowingStyled(Icon, {
  swallowProps: ['opened']
})`
  transition: 150ms all linear;
  color: #323232;
  margin-right: 8px;

  ${p => cond(p.opened, 'transform: rotate(90deg);')};
`;

export const TransparentButton = styled.button`
  outline: none;
  background: none;
  border: solid 1px #c9cbcb;
  font-size: 16px;
  color: #323232;
  padding: 8px 15px;
  border-radius: ${borderRadius}px;
  cursor: pointer;
  transition: all 150ms linear;

  &:hover {
    background-color: #ececec;
  }
`;

export const Panel = styled.div`
  background: #ffffff;
  padding: 32px;
  display: inline-block;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.3), 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
`;
export const GridPanel = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  > section {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:last-child {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
`;
