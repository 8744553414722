import {defineMessages} from 'react-intl';

const messages = defineMessages({
  editTitle: {
    id: 'SkillProfile.editTitle',
    defaultMessage: 'Edit Skill Profile'
  },
  createSkillProfile: {
    id: 'createSkillProfile',
    defaultMessage: 'Create Skill Profile'
  },
  cancel: {
    id: 'common.actions.cancel',
    defaultMessage: 'Cancel'
  },
  save: {
    id: 'common.actions.save',
    defaultMessage: 'Save'
  },
  createSuccess: {
    id: 'SkillProfile.createSuccess',
    defaultMessage: 'Skill Profile has been created.'
  },
  createFailure: {
    id: 'SkillProfile.createFailure',
    defaultMessage: 'Failed to create the Skill Profile.'
  },
  updateSuccess: {
    id: 'SkillProfile.updateSuccess',
    defaultMessage: 'Skill Profile has been updated.'
  },
  updateFailure: {
    id: 'SkillProfile.updateFailure',
    defaultMessage: 'Failed to update the Skill Profile.'
  },
  assignTo: {
    id: 'SkillProfile.assignTo',
    defaultMessage: 'Assign to...'
  },
  general: {
    id: 'SkillProfile.general',
    defaultMessage: 'General Information'
  },
  skillProfileAlreadyExists: {
    id: 'SkillProfile.skillProfileAlreadyExists',
    defaultMessage: 'Skill Profile with this name already exists.'
  },
  skillLevelMustBeSet: {
    id: 'SkillProfile.skillLevelMustBeSet',
    defaultMessage: 'Required skill level must be set before saving.'
  }
});

export default messages;
