import axios from 'axios';
import views from 'config/views';
import store from 'stores/store';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import {client} from 'utils/apollo-client';
import gql from 'graphql-tag';
import phone from 'phone';
import {getPasswordComplexityChecker} from './password-complexity';

export const getCurrentEntity = (
  router,
  instructionInGuideId,
  creatingInstructionInGuide,
  creatingTopicInGuide,
  guideInTranslation,
  topicInTranslation,
  instructionInTranslation
) => {
  let entity;
  const currentViewId = router.currentRoute.path;
  const isNewDomainView = currentViewId === views.newWorkspace.path;
  const isEditDomainView = currentViewId === views.editWorkspace.path;
  const isNewGuideView = currentViewId === views.newGuide.path;
  const isEditGuideView = currentViewId === views.editGuide.path;
  const isEditInstructionView = currentViewId === views.editInstruction.path;

  if (isEditGuideView || isNewGuideView || guideInTranslation) {
    entity = 'guide';
    if (instructionInGuideId) {
      entity = 'instruction';
    } else if (creatingInstructionInGuide) {
      entity = 'instruction';
    } else if (creatingTopicInGuide) {
      entity = 'topic';
    }
  } else if (topicInTranslation) {
    entity = 'topic';
  } else if (isEditInstructionView || instructionInTranslation) {
    entity = 'instruction';
  } else if (currentViewId === views.newTag.path || currentViewId === views.editTag.path) {
    entity = 'tag';
  } else if (currentViewId === views.newUser.path || currentViewId === views.editUser.path) {
    entity = 'user';
  } else if (isEditDomainView || isNewDomainView) {
    entity = 'domain';
  }

  if (!entity) {
    throw new Error('getCurrentEntity could not retrieve entity.');
  }
  return entity;
};

export const getCurrentEntityId = (router, instructionInGuideid, topicDialogOpened, topicId, translationObjectId) => {
  let id = '';

  const currentViewId = router.currentRoute.id;
  const isEditGuideView = currentViewId === views.editGuide.id;
  const isEditInstructionView = currentViewId === views.editInstruction.id;

  if (isEditGuideView) {
    const guideId = router.params.id;
    if (topicDialogOpened) {
      id = topicId;
    } else {
      id = instructionInGuideid || guideId;
    }
  } else if (isEditInstructionView) {
    id = router.params.instructionId;
  } else if (currentViewId === views.editTag.id) {
    const tagId = store.tagManagementPage.editedTagId;
    id = tagId;
  } else if (currentViewId === views.editUser.id) {
    const userId = store.editUserPage.editedUserId;
    id = userId;
  } else if (translationObjectId) {
    id = translationObjectId;
  }

  return id;
};

export const checkUniqueSlug = (slug, passes, entity, id) => {
  const isGuideEditView = store.router.currentRoute.path === views.editGuide.path;

  const guideId = id || store.router.params.id;
  const domainId = store.router.params.id;

  const instructionId = get(store.router, 'params.instructionId');

  const instructionInGuideId = get(store.editInstructionPage, 'instructionId');

  const topicInGuideId = get(store.editTopicPage, 'topicId');

  const topicInEditInstructionInlineId = get(store.editInstructionPage, 'topicId');

  const topicId = get(store.router, 'params.topicId', null);

  const creatingInstructionInGuide = store.editInstructionPage.mode !== null;
  const creatingTopicInGuide = store.newTopicPage.opened || store.editTopicPage.isEditing;
  const {guideInTranslation, topicInTranslation, instructionInTranslation, translationObjectId} =
    store.translationsPage;

  if (!entity) {
    entity = getCurrentEntity(
      store.router,
      instructionInGuideId,
      creatingInstructionInGuide,
      creatingTopicInGuide,
      guideInTranslation,
      topicInTranslation,
      instructionInTranslation
    );
  }

  let query =
    'query ValidSlug($type: SlugType!, $id: String, $slug: String!, $guideId: String, $topicId: String) {isValidSlug(type: $type, id: $id, slug: $slug, guideId: $guideId, topicId: $topicId)}';
  const variables = {slug};
  const operationName = 'ValidSlug';

  if (entity === 'domain') {
    variables.type = 'domain';
    variables.slug = slug;
    variables.id = domainId;
  } else if (entity === 'tag') {
    query = `query ${entity}($slug: String, $locale: Locale!) {${entity}(slug: $slug, locale: $locale) {id}}`;
    variables.locale = store.platform.getLanguage();
  } else if (entity === 'guide') {
    variables.type = entity;
    variables.id = guideId;
  } else if (entity === 'topic') {
    variables.type = entity;
    variables.id = topicInTranslation ? topicId : topicInGuideId;
    variables.guideId = guideId;
  } else if (entity === 'instruction') {
    variables.type = entity;
    variables.id = instructionInTranslation
      ? translationObjectId
      : isGuideEditView
      ? instructionInGuideId
      : instructionId;
    variables.topicId = topicInTranslation ? topicId : isGuideEditView ? topicInEditInstructionInlineId : topicId;
  }

  return axios
    .post('/graphql', {
      query,
      variables,
      operationName
    })
    .then(({data}) => {
      const {isValidSlug} = data.data;
      if (typeof isValidSlug === 'boolean') {
        return passes(isValidSlug, isValidSlug ? undefined : 'The slug must be unique.');
      }

      // This would be joined later to isValidSlug query
      const entityId = get(data, `data.${entity}.id`);
      const currentId = getCurrentEntityId(
        store.router,
        instructionInGuideId,
        creatingTopicInGuide,
        topicId,
        translationObjectId
      );
      const isEditingCurrentEntity = currentId === entityId;
      const isPassing = !entityId || isEditingCurrentEntity;

      passes(isPassing, isPassing ? undefined : 'The slug must be unique.');
    });
};

export const checkUniqueSlugDebounced = debounce(checkUniqueSlug, 300);

export const checkUniqueEmail = (email, passes) => {
  const entity = getCurrentEntity(store.router);

  axios
    .post('/graphql', {
      query: `#graphql
        query user {
          user(email: "${email}"){
            id
          }
        }`,
      variables: '{}'
    })
    .then(({data}) => {
      const entityId = get(data, `data.${entity}.id`);
      const currentId = getCurrentEntityId(store.router, '');
      const isEditingCurrentEntity = currentId === entityId;
      const isPassing = !entityId || isEditingCurrentEntity;

      passes(isPassing, isPassing ? undefined : 'Email already exists.');
    });
};

export const checkUniquePhoneNumber = (phone, passes) => {
  const entity = getCurrentEntity(store.router);
  axios
    .post('/graphql', {
      query: `#graphql
        query user {
          user(phoneNumber: "${phone}"){
            id
          }
        }`
    })
    .then(({data}) => {
      const entityId = get(data, `data.${entity}.id`);
      const currentId = getCurrentEntityId(store.router, '');
      const isEditingCurrentEntity = currentId === entityId;
      const isPassing = !entityId || isEditingCurrentEntity;

      passes(isPassing, isPassing ? undefined : 'Phone number already exists.');
    });
};

export const checkUniqueTeamName = (name, passes) => {
  const teamId = store.router.params.id;
  const query = `#graphql
    query isValidTeamName($name: String, $teamId: String) {
      isValidTeamName(name: $name, teamId: $teamId)
    }
  `;
  const variables = {
    name: smartTrim(name),
    teamId
  };

  axios
    .post('/graphql', {
      query,
      variables
    })
    .then(({data}) => {
      const {isValidTeamName} = data.data;
      if (typeof isValidTeamName === 'boolean') {
        passes(isValidTeamName, isValidTeamName ? undefined : 'Team name already exists.');
      }
    });
};

export const checkUppercaseOnEmail = (email, passes) => {
  const isPassing = !/[A-Z]\w*/.test(email);
  passes(isPassing, isPassing ? undefined : "Email can't have uppercase.");
};

export const checkTextLimit = (name, attr, passes) => {
  const values = attr.split(',');
  const isPassing = name.length >= values[0] && name.length <= values[1];
  passes(isPassing, isPassing ? undefined : 'Please stay within the character limit');
};

// get rid of empty strings added by mobx-form
export const sanitizeNestedFields = (arr, field) => arr.filter(t => t[field] !== '');

export {getPasswordComplexityChecker};

export const isValidSkillRoleNameQuery = `#graphql
query isValidSkillRoleName($name: String!, $id: String) {
  isValidSkillRoleName(name: $name, id: $id)
}
`;

export const checkUniqueJobTitleName = (name, passes) => {
  const jobTitleId = store.router.params.id;

  const variables = {
    name: smartTrim(name),
    id: jobTitleId
  };

  axios.post('/graphql', {query: isValidSkillRoleNameQuery, variables}).then(({data}) => {
    const {isValidSkillRoleName} = data.data;
    if (typeof isValidSkillRoleName === 'boolean') {
      passes(isValidSkillRoleName, isValidSkillRoleName ? undefined : 'Job title already exists.');
    }
  });
};

/**
 * Trims string and replaces multiple spaces inside with one
 *
 * @param str {string}
 * @returns {string}
 */
export const smartTrim = str => {
  return (str || '').trim().replace(/\s\s+/g, ' ');
};

export const isValidSkillProfileNameQuery = gql`
  #graphql
  query isValidSkillProfileName($name: String!, $id: String) {
    isValidSkillProfileName(name: $name, id: $id)
  }
`;

export const checkUniqueSkillProfileName = (name, passes) => {
  let skillProfileId;
  if (store.router.params.id !== 'new') {
    skillProfileId = store.router.params.id;
  }

  const variables = {name: smartTrim(name), id: skillProfileId};

  client.query({fetchPolicy: 'network-only', query: isValidSkillProfileNameQuery, variables}).then(({data}) => {
    const {isValidSkillProfileName} = data;
    if (typeof isValidSkillProfileName === 'boolean') {
      passes(
        isValidSkillProfileName,
        isValidSkillProfileName ? undefined : 'Skill Profile with this name already exists.'
      );
    }
  });
};

export const isValidRecipientNameQuery = gql`
  #graphql
  query isValidRecipientName($name: String!, $id: String) {
    isValidRecipientName(name: $name, id: $id)
  }
`;

export const checkUniqueRecipientName = (name, passes) => {
  let recipientId;
  if (store.router.params.id !== 'new') {
    recipientId = store.router.params.id;
  }

  const variables = {
    name: smartTrim(name),
    id: recipientId
  };

  client.query({fetchPolicy: 'network-only', query: isValidRecipientNameQuery, variables}).then(({data}) => {
    const {isValidRecipientName} = data;
    if (typeof isValidRecipientName === 'boolean') {
      passes(isValidRecipientName, isValidRecipientName ? undefined : 'Recipient name already exists.');
    }
  });
};

export const checkValidPhoneNumber = (value, passes) => {
  if (!value || value.length < 5) {
    passes(true);
  }
  const parsedPhoneNumber = phone(value);
  if (!parsedPhoneNumber.isValid) {
    passes(false, 'Please enter a valid phone number');
  }
  passes(true);
};
