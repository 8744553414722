import styled from 'styled-components';
import {borderRadius} from 'styles/vars';
import {commonPalette} from 'shared/styles/palette';
import {noSelect} from 'shared/utils/sc-utils';

//components
import $Link from 'shared/components/Link';

const commonButtonStyles = `
  ${noSelect}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  height: 100%;
  background: none;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`;

const primaryColors = `
  background-color: ${commonPalette.sgGreen};
  color: #FFF;

  &:disabled {
    background-color: ${commonPalette.lightGreen};
  }

  &:not(:disabled):hover, &:focus {
    background-color: ${commonPalette.btnHoverGreen};
  }
`;

const secondaryColors = `
  background-color: #FFF;
  color: ${commonPalette.sgGreen};
  border: 1px solid ${commonPalette.borderGrey};

  &:disabled {
    color: ${commonPalette.lightGreen};
  }

  &:not(:disabled):hover, &:focus {
    background-color: ${commonPalette.hoverGrey};  
  }
`;

const underlinedStyle = `
  color: ${commonPalette.txBlack};
  text-decoration: underline;
  background: none;
  justify-content: flex-start;

  &:hover, :focus {
    color: ${commonPalette.btnGreen};
    text-decoration: underline;
  }

  &:disabled {
    color: ${commonPalette.txLightGrey};
  }
`;

const roundStyle = `
  z-index: 9999;
  border-radius: 100%;
  box-shadow: 0 5px 6px 0 rgba(24,69,76,0.5);
`;

const iconPosition = p => {
  if (!p.icon || p.round) return '';

  return `
    flex-direction: ${p.iconOnRight ? 'row-reverse' : 'row'};
    
    > svg {
      ${p.iconOnRight ? 'margin-left' : 'margin-right'} : 10px;
    }
  `;
};

export const getButtonStyles = p => {
  if (!p.round && p.icon && !p.label) return commonButtonStyles;

  if (p.underlined)
    return `
    ${commonButtonStyles}
    ${iconPosition(p)}
    ${underlinedStyle}
  `;

  if (p.round)
    return `
    ${commonButtonStyles}
    ${roundStyle}
    ${p.secondary ? secondaryColors : primaryColors};
    height: ${p.size}px;
    width: ${p.size}px;
    font-size: ${p.size < 60 ? '18px' : '22px'};
  `;

  if (!p.underlined && !p.round)
    return `
    ${commonButtonStyles}
    ${iconPosition(p)}
    ${p.secondary ? secondaryColors : primaryColors};
    height: 40px;
    min-width: 92px;
    padding: 0 16px;
    border-radius: ${borderRadius.default}px;
    text-transform: uppercase;
    ${p.transition && 'transition: all 0.2s ease-in-out;'}
  `;
};

export const StyledButton = styled.button`
  ${p => getButtonStyles(p)}
`;

export const StyledAnchor = StyledButton.withComponent('a');

export const StyledLink = StyledButton.withComponent($Link);

export const Label = styled.div``;
