import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {SetPasswordView, AlreadyLoggedIn} from './styles';

//components
import SetPasswordForm from 'components/SetPasswordForm';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@observer
class SetPasswordComponent extends Component {
  render() {
    const {store} = this.props;

    return (
      <SetPasswordView>
        {store.auth.user ? (
          <AlreadyLoggedIn>
            <FormattedMessage {...messages.alreadyLoggedIn} />
          </AlreadyLoggedIn>
        ) : (
          <SetPasswordForm />
        )}
      </SetPasswordView>
    );
  }
}

export default SetPasswordComponent;
