import styled from 'styled-components';
import {commonPalette} from 'shared/styles/palette';

export const Subtitle = styled.p`
  margin-top: 10px;
  color: ${commonPalette.txGrey};
  margin-bottom: 8px;
`;

export const Infobox = styled.div`
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  background: ${commonPalette.lightGrey};
  margin-bottom: 32px;

  svg {
    margin-right: 12px;
  }
`;
