import styled from 'styled-components';
import Icon from 'ui-components/Layout/Icon';
import {commonPalette} from 'shared/styles/palette';

export const Header = styled.h2`
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 32px;
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
`;

export const BackIcon = styled(Icon).attrs(() => ({
  name: 'chevron-left',
  size: 32
}))`
  color: ${commonPalette.txGrey};
  margin-bottom: 5px;
  margin-right: 2px;
`;

export const StyledText = styled.div`
  color: ${commonPalette.txBlack};
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 20px;
`;
