import React, {Component} from 'react';
import {inject} from 'mobx-react';

//styles
import {Submenu, SelectedIndicator, SubmenuItemWrapper, SubmenuItem} from './styles';

@inject('store')
class SubmenuComponent extends Component {
  render() {
    const {sections, selectedSubMenuItem, selectedSubmenuItemIndex} = this.props;

    return (
      <Submenu>
        <SelectedIndicator index={selectedSubmenuItemIndex} />
        {sections.map(section => (
          <SubmenuItemWrapper key={section.id}>
            <SubmenuItem data-cy={section.id} item={section} selected={selectedSubMenuItem.id === section.id} />
          </SubmenuItemWrapper>
        ))}
      </Submenu>
    );
  }
}

export default SubmenuComponent;
