import {defineMessages} from 'react-intl';

const messages = defineMessages({
  thickness: {
    id: 'ShapeRow.thickness',
    defaultMessage: 'Thickness'
  },
  color: {
    id: 'ShapeRow.color',
    defaultMessage: 'Color'
  },
  deleteShape: {
    id: 'ShapeRow.deleteShape',
    defaultMessage: 'Delete Shape'
  }
});

export default messages;
