import React from 'react';
import {isEmpty} from 'lodash';

import {FormattedMessage} from 'components/FormattedComponents';
import GuideActivityEvent from 'components/GuideActivityEvent';

import messages from './messages';

const Activities = ({activities}) => {
  return (
    <div>
      {activities?.map((activity, index) => {
        const key = `${activity.producedAt} - ${activity.eventType} - ${index}`;
        return <GuideActivityEvent key={key} activity={activity} showDivider={index < activities.length - 1} />;
      })}
      {!activities && <FormattedMessage {...messages.notAvailable} />}
      {activities && isEmpty(activities) && <FormattedMessage {...messages.noFilteredActivities} />}
    </div>
  );
};

export default Activities;
