import {action, observable, makeObservable} from 'mobx';
import Raven from 'raven-js';

class GuideDetailsDialog {
  @observable opened = false;
  @observable guide;
  @observable guideId;
  @observable error;
  @observable loading = true;

  @action
  reset = () => {
    this.opened = false;
    this.loading = true;
    this.error = null;
  };

  @action open = guideId => {
    this.opened = true;
    this.guideId = guideId;
  };

  @action setGuide = guide => {
    this.guide = guide;
    this.setLoading(false);
  };

  @action setError = error => {
    Raven.captureException(error);
    this.error = error;
    this.setLoading(false);
  };

  @action setLoading = loading => {
    this.loading = loading;
  };

  constructor() {
    makeObservable(this);
  }
}

export default GuideDetailsDialog;
