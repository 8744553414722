import gql from 'graphql-tag';

export const Themes = gql`
  query themes {
    themes {
      id
      name
      logoMedia {
        id
        url
      }
      faviconMedia {
        id
        url
      }
      mainColor
      buttonColor
      fontFamily
      warningIconMedia {
        id
        url
      }
      alternativeIconMedia {
        id
        url
      }
      fixIconMedia {
        id
        url
      }
      tipIconMedia {
        id
        url
      }
      showHintLabels
      customFontRegularUrl
      customFontBoldUrl
      customFontItalicUrl
      customFontBoldItalicUrl
      customFontFallback
      icons {
        id
        svg
        labelTranslations
        type
        isEnabled
      }
    }
  }
`;

export const Theme = gql`
  query theme($id: String!) {
    theme(id: $id) {
      id
      name
      teamId
      logoMedia {
        id
        url
      }
      faviconMedia {
        id
        url
      }
      mainColor
      buttonColor
      fontFamily
      warningIconMedia {
        id
        url
      }
      alternativeIconMedia {
        id
        url
      }
      fixIconMedia {
        id
        url
      }
      tipIconMedia {
        id
        url
      }
      showHintLabels
      customFontRegularUrl
      customFontBoldUrl
      customFontItalicUrl
      customFontBoldItalicUrl
      customFontFallback
      icons {
        id
        svg
        labelTranslations
        type
        isEnabled
      }
    }
  }
`;

export const ThemeForCms = gql`
  query themeForCms($id: String!) {
    themeForCms(id: $id) {
      id
      name
      teamId
      logoMedia {
        id
        url
      }
      faviconMedia {
        id
        url
      }
      mainColor
      buttonColor
      fontFamily
      customFontRegularUrl
      customFontBoldUrl
      customFontItalicUrl
      customFontBoldItalicUrl
      customFontFallback
      icons {
        id
        svg
      }
    }
  }
`;

export const ThemesLite = gql`
  query themesLite {
    themes {
      id
      name
      mainColor
      buttonColor
    }
  }
`;
