import React, {Component} from 'react';

//styled-components
import {CenteredContainer} from './styles';

class Container extends Component {
  render() {
    const {children, className, noPaddingLeft, style} = this.props;

    return (
      <CenteredContainer noPaddingLeft={noPaddingLeft} className={className} style={style}>
        {children}
      </CenteredContainer>
    );
  }
}

export default Container;
