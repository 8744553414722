import {defineMessages} from 'react-intl';

const messages = defineMessages({
  updateStepSuccess: {
    id: 'EditStep.updateSuccess',
    defaultMessage: 'Step has been updated.'
  },
  updateCheckSuccess: {
    id: 'updateCheckSuccess',
    defaultMessage: 'Check has been updated.'
  },
  updateStepError: {
    id: 'EditStep.updateError',
    defaultMessage: 'Error updating step.'
  },
  updateCheckError: {
    id: 'updateCheckError',
    defaultMessage: 'Error updating check.'
  },
  editDetails: {
    id: 'EditStep.editDetails',
    defaultMessage: 'Edit details'
  },
  editCheck: {
    id: 'EditStep.editCheck',
    defaultMessage: 'Edit check'
  },
  editStep: {
    id: 'EditStep.editStep',
    defaultMessage: 'Edit step'
  }
});

export default messages;
