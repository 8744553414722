import {action, observable, computed, toJS, makeObservable} from 'mobx';
import get from 'lodash/get';
import omit from 'lodash/omit';
import store from 'stores/store';
import {client} from 'utils/apollo-client';

//queries
import {MediaGallery as MediaGalleryQuery} from 'api/media/queries';

//utils
import {isVideoUrl, getExtensionFromUrl} from 'shared/utils/media-utils';
import {applyMetadata, rotateFocusPoint, rotateCropRectangle} from 'shared/utils/image-utils';
import {IMAGE_CONFIG, VIDEO_CONFIG} from 'shared/media-config';

//helpers
import {MEDIA_GALLERY_FILTER_LEVEL, MEDIA_PREVIEW_DEVICE, MEDIA_PREVIEW_TYPE, MEDIA_GALLERY_STEPS} from 'config/enums';
import toInteger from 'lodash/toInteger';

class MediaPickerDialog {
  @observable step = MEDIA_GALLERY_STEPS.UPLOAD;
  @observable opened = false;
  @observable loading = false;
  @observable previewDevice = MEDIA_PREVIEW_DEVICE.DESKTOP;
  @observable previewType = MEDIA_PREVIEW_TYPE.FULL_IMAGE;
  @observable galleryLevel = MEDIA_GALLERY_FILTER_LEVEL.GUIDE;
  @observable mediaGallery = [];
  @observable selectedMedia = null;
  @observable isUnassignedMedia = null;
  @observable canvasDimensions = null;
  @observable rotation = 0;
  @observable contain = false;
  @observable backgroundColor = '#FFFFFF';
  @observable colorPickerOpen = false;
  @observable originalSize = null;
  @observable overlayShapes = [];
  @observable originalSelectedMediaId = null;
  @observable cursor = null;
  @observable fetchingNewImages = false;
  @observable savedMediaIds = [];
  @observable totalFilesNumber = 0;
  @observable currentFileNumber = 0;
  @observable type = null;
  form = null;
  field = null;
  allowVideos = false;
  allowedFormats = null;
  acceptedTypes = null;
  instructionId = null;
  galleryConfig = null;
  galleryRefetch = null;
  lastSavedMediaId = undefined;
  mediaId = undefined;
  setCoverUrl = undefined;
  setMetadata = undefined;
  updateMedia = undefined;
  showUpdateNotifications = undefined;
  shouldShowOverlayShapes = undefined;
  refs = {
    existingMediaScrollingContainer: null
  };

  constructor() {
    makeObservable(this);
  }

  setRef(key, ref) {
    this.refs = {
      ...this.refs,
      [key]: ref
    };
  }

  @action setStep = step => (this.step = step);

  @action setCursor = cursor => (this.cursor = cursor);

  @action setFetchingNewImages = fetchingNewImages => (this.fetchingNewImages = fetchingNewImages);

  @action
  setCanvasDimensions = dimensions => {
    this.canvasDimensions = dimensions;
  };

  @action setOriginalSize = size => (this.originalSize = size);

  @action
  saveMedia = (mutate, success, error) => {
    return mutate({
      id: this.selectedMedia.id,
      mediaEdit: {
        id: this.selectedMedia.id,
        metadata: this.selectedMedia.metadata,
        fileName: this.selectedMedia.fileName
      }
    })
      .then(res => {
        success?.(res);
      })
      .catch(err => {
        error?.(err);
      });
  };

  @action
  rotateImage = () => {
    if (this.rotation === 270) {
      this.rotation = 0;
    } else {
      this.rotation = this.rotation + 90;
    }

    this.extendSelectedMediaMetadata({rotation: this.rotation});
  };

  @action
  toggleContain = () => {
    this.contain = !this.contain;
    this.extendSelectedMediaMetadata({contain: this.contain});
  };

  @action addShape = shape => {
    this.overlayShapes.unshift(shape);
    this.updateOverlay();
  };

  @action updateShapeData = (position, data) => {
    const modifiedShape = this.overlayShapes[position];
    modifiedShape.data = data;

    this.overlayShapes.splice(position, 1, modifiedShape);

    this.updateOverlay(modifiedShape);
  };

  @action setThickness = (position, thickness) => {
    const modifiedShape = this.overlayShapes[position];
    modifiedShape.thickness = toInteger(thickness);

    this.overlayShapes.splice(position, 1, modifiedShape);

    this.updateOverlay(modifiedShape);
  };

  @action setShapeColor = (position, color) => {
    const modifiedShape = this.overlayShapes[position];

    if (color.rgb.a !== 1) modifiedShape.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    else modifiedShape.color = color.hex.toUpperCase();

    this.overlayShapes.splice(position, 1, modifiedShape);

    this.updateOverlay(modifiedShape);
  };

  @action deleteShape = position => {
    this.overlayShapes.splice(position, 1);
    this.updateOverlay();
  };

  @action handleBackSpace = () => {
    const {mediaOverlayDialog} = store;
    if (mediaOverlayDialog.selectedShape) {
      const shapePosition = this.overlayShapes.findIndex(({id}) => id === mediaOverlayDialog.selectedShape.id);
      this.deleteShape(shapePosition);
    }
  };

  @action moveShape = (sourcePosition, targetPosition) => {
    const sourceShape = this.overlayShapes[sourcePosition];

    this.overlayShapes.splice(sourcePosition, 1);
    this.overlayShapes.splice(targetPosition, 0, sourceShape);

    this.updateOverlay(sourceShape);
  };

  @action updateOverlay = modifiedShape => {
    this.extendSelectedMediaMetadata({overlays: this.overlayShapes});
    store.mediaOverlayDialog.updateKonva(modifiedShape);
  };

  @action
  setSelectedMediaMetadata = metadata => {
    if (!this.selectedMedia) {
      return;
    }

    this.setSelectedMedia({...this.selectedMedia, metadata});
  };

  @action
  setSelectedMediaFileName = fileName => {
    if (!this.selectedMedia) {
      return;
    }

    this.setSelectedMedia({...this.selectedMedia, fileName});
  };

  @action
  extendSelectedMediaMetadata = metadata => {
    if (!this.selectedMedia) {
      return;
    }
    if (this.selectedMedia.metadata?.rotation && metadata.focus) {
      const clockwise = false;
      metadata.focus = rotateFocusPoint(metadata.focus, this.selectedMedia.metadata.rotation, clockwise);
    }

    if (this.selectedMedia.metadata?.rotation && metadata.crop) {
      const {width, height} = this.originalSize;
      metadata.crop = rotateCropRectangle(metadata.crop, width, height, this.selectedMedia.metadata.rotation);
    }
    const extendedMetadata = {...this.selectedMedia.metadata, ...metadata};

    this.setSelectedMediaMetadata(extendedMetadata);
    if (this.isUnassignedMedia) {
      const unassignedMedia = JSON.parse(localStorage.getItem('unassignedMedia')) || [];
      unassignedMedia.find(media => media.id === this.selectedMedia.id).metadata = extendedMetadata;
      window.localStorage.setItem('unassignedMedia', JSON.stringify(unassignedMedia));
    }
  };

  @action
  showColorPicker = () => {
    this.colorPickerOpen = !this.colorPickerOpen;
  };

  @action
  setLoading = loading => (this.loading = loading);

  @action
  reset = () => {
    if (this.loading) {
      return;
    }
    this.opened = false;
    this.previewDevice = MEDIA_PREVIEW_DEVICE.DESKTOP;
    this.previewType = MEDIA_PREVIEW_TYPE.FULL_IMAGE;
    this.form = null;
    this.field = null;
    this.allowVideos = false;
    this.allowedFormats = null;
    this.acceptedTypes = null;
    this.galleryLevel = MEDIA_GALLERY_FILTER_LEVEL.GUIDE;
    this.instructionId = null;
    this.galleryConfig = null;
    this.mediaGallery = [];
    this.selectedMedia = null;
    this.galleryRefetch = null;
    this.savedMediaIds = [];
    this.lastSavedMediaId = undefined;
    this.setCoverUrl = undefined;
    this.step = MEDIA_GALLERY_STEPS.UPLOAD;
    this.updateMedia = undefined;
    this.showUpdateNotifications = undefined;
    this.shouldShowOverlayShapes = undefined;
    this.rotation = 0;
    this.contain = false;
    this.backgroundColor = '#FFFFFF';
    this.overlayShapes = [];
    this.originalSelectedMediaId = null;
    this.type = null;
    this.currentFileNumber = 0;
    this.totalFilesNumber = 0;
    this.isUnassignedMedia = null;
  };

  @action open = ({
    form,
    field,
    allowVideos = false,
    allowedFormats,
    acceptedTypes,
    previewType = MEDIA_PREVIEW_TYPE.FULL_IMAGE,
    instructionId,
    galleryConfig,
    setCoverUrl,
    setMetadata,
    updateMedia,
    showUpdateNotifications,
    shouldShowOverlayShapes,
    mediaId,
    type
  }) => {
    this.form = form;
    this.field = field;
    this.allowVideos = allowVideos;
    this.opened = true;
    this.allowedFormats = allowedFormats;
    this.acceptedTypes = acceptedTypes;
    this.previewDevice = MEDIA_PREVIEW_DEVICE.DESKTOP;
    this.previewType = previewType;
    this.instructionId = instructionId;
    this.galleryConfig = galleryConfig;
    this.setCoverUrl = setCoverUrl;
    this.setMetadata = setMetadata;
    this.updateMedia = updateMedia;
    this.showUpdateNotifications = showUpdateNotifications;
    this.shouldShowOverlayShapes = shouldShowOverlayShapes;
    this.type = type;

    this.mediaId = mediaId;
  };

  @action removeMedia = () => {
    if (this.isUnassignedMedia) {
      const selectedMediaId = get(this.selectedMedia, 'id', null);

      let unassignedMedia = JSON.parse(window.localStorage.getItem('unassignedMedia'));
      unassignedMedia = unassignedMedia.filter(item => item.id !== selectedMediaId);
      window.localStorage.setItem('unassignedMedia', JSON.stringify(unassignedMedia));
    }

    this.selectedMedia = null;
    if (this.form && this.field) this.form.$(this.field).sync(null);
  };

  @action resetToOriginalMedia = () => {
    this.reset();
  };

  @action propagateSelectedMedia = () => {
    const id = get(this.selectedMedia, 'id', null);
    const metadata = get(this.selectedMedia, 'metadata', null);
    const coverUrl = get(this.selectedMedia, 'url', null);

    if (this.form && this.field) {
      this.form.$(this.field).sync(id);
    }

    this.setMetadata?.(metadata);
    this.setCoverUrl?.(coverUrl);

    this.reset();
  };

  @action
  setPreviewDevice = previewDevice => {
    this.previewDevice = previewDevice;
  };

  @action
  setPreviewType = previewType => {
    this.previewType = previewType;
  };

  @action
  setBackgroundColor = value => {
    if (!value) this.backgroundColor = 'none';
    else if (value.rgb.a !== 1)
      this.backgroundColor = `rgba(${value.rgb.r}, ${value.rgb.g}, ${value.rgb.b}, ${value.rgb.a})`;
    else this.backgroundColor = value.hex.toUpperCase();
    this.extendSelectedMediaMetadata({backgroundColor: this.backgroundColor});
  };

  @action setGalleryLevel = galleryLevel => (this.galleryLevel = galleryLevel);

  @action setMediaGallery = (mediaGallery, refetchQuery) => {
    this.mediaGallery = mediaGallery;
    this.galleryRefetch = refetchQuery;
    if (this.form || this.mediaId) {
      const mediaId = this.mediaId ? this.mediaId : this.form.$(this.field).value;
      this.originalSelectedMediaId = this.selectedMedia ? mediaId : null;
    }
    if (this.selectedMedia && this.form) {
      this.form.$(this.field).sync(this.selectedMedia.id);
      this.setSelectedMedia(this.selectedMedia);
    }
  };

  @action setTotalFilesNumber = number => {
    this.totalFilesNumber = number;
  };

  @action setCurrentFileNumber = number => {
    this.currentFileNumber = number;
  };

  @action setIsUnassignedMedia = val => {
    this.isUnassignedMedia = val;
  };

  @computed get acceptedExtensions() {
    if (this.allowedFormats) {
      return this.allowedFormats;
    }

    if (this.allowVideos) {
      return [...IMAGE_CONFIG.extensions, ...VIDEO_CONFIG.extensions];
    }

    return IMAGE_CONFIG.extensions;
  }

  @computed get mediaAllowedFormat() {
    return this.mediaGallery.filter(media => {
      const extension = getExtensionFromUrl(media.url);
      return this.acceptedExtensions.includes(extension);
    });
  }

  @action setSelectedMedia = selectedMediaArg => {
    const selectedMedia = {...selectedMediaArg};
    //make sure to render exif data correctly
    //https://support.cloudinary.com/hc/en-us/community/posts/200787252-Portrait-image-saved-as-a-landscape-image-orientation
    const aExifRegex = '/upload/a_exif/';
    if (selectedMedia.url && !selectedMedia.url.includes(aExifRegex)) {
      selectedMedia.url = selectedMedia.url.replace('/upload/', aExifRegex);
    }

    this.selectedMedia = selectedMedia;
    const {metadata} = this.selectedMedia;

    this.rotation = metadata?.rotation ? metadata.rotation : 0;
    this.contain = metadata?.contain ? metadata.contain : false;
    this.backgroundColor = metadata?.backgroundColor ? metadata.backgroundColor : '#FFFFFF';
    this.overlayShapes = metadata?.overlays ? metadata.overlays : [];

    if (this.form) {
      this.form.$(this.field).sync(this.selectedMedia.id);
    }
  };

  @action overlayImage = () => {
    store.mediaOverlayDialog.reset();
    this.step = MEDIA_GALLERY_STEPS.OVERLAY;
  };

  @action editImage = () => {
    this.step = MEDIA_GALLERY_STEPS.EDIT;
  };

  @computed
  get upload() {
    return this.step === MEDIA_GALLERY_STEPS.UPLOAD;
  }

  @computed
  get crop() {
    return this.step === MEDIA_GALLERY_STEPS.CROP;
  }

  @computed
  get overlay() {
    return this.step === MEDIA_GALLERY_STEPS.OVERLAY;
  }

  @computed
  get focus() {
    return this.step === MEDIA_GALLERY_STEPS.FOCUS;
  }

  @computed
  get edit() {
    return this.step === MEDIA_GALLERY_STEPS.EDIT;
  }

  @computed get isSelectedMediaVideo() {
    return this.selectedMedia && isVideoUrl(this.selectedMedia.mediaResource?.url);
  }

  isMediaVideo = media => {
    return media && isVideoUrl(media.mediaResource?.url);
  };

  getMediaUrlWithTranformationsOmitted = transformationsToOmit => {
    const metadata = omit(this.selectedMedia.metadata, transformationsToOmit);
    return applyMetadata(this.selectedMedia.url, metadata);
  };

  fetchMediaGalleryItemById = async id => {
    const {data} = await client.query({
      query: MediaGalleryQuery,
      variables: {
        savedMediaIds: [id]
      },
      fetchPolicy: 'network-only'
    });

    return get(data, 'media[0]', null);
  };

  saveMediaResource = (saveMedia, url, editMedia, params) => {
    return saveMedia({url}).then(async ({data}) => {
      const {id: mediaId} = data.saveMedia;
      this.savedMediaIds.push(mediaId);
      this.lastSavedMediaId = mediaId;

      this.selectedMedia = {id: mediaId};
      this.setSelectedMediaMetadata({contain: true});

      this.setSelectedMediaFileName(params?.public_id);
      this.saveMedia(editMedia);

      const uploadedMediaItem = await this.fetchMediaGalleryItemById(mediaId);
      const unassignedMedia = JSON.parse(localStorage.getItem('unassignedMedia')) || [];

      unassignedMedia.push(uploadedMediaItem);
      window.localStorage.setItem('unassignedMedia', JSON.stringify(unassignedMedia));
      this.setIsUnassignedMedia(true);
      this.setSelectedMedia(uploadedMediaItem);

      if (uploadedMediaItem !== null) {
        this.setMediaGallery([...toJS(this.mediaGallery)]);

        const {
          refs: {existingMediaScrollingContainer}
        } = this;

        if (existingMediaScrollingContainer !== null) {
          existingMediaScrollingContainer.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth'
          });
        }
      }
    });
  };
}

export default MediaPickerDialog;
