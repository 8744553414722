import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import {dvrWithAsync} from 'config/validator-config';
import {checkUniqueSkillProfileName} from 'utils/validation-utils';
import {debounce} from 'lodash';

const asyncRules = {
  checkUniqueSkillProfileName: debounce((value, attr, key, passes) => {
    return checkUniqueSkillProfileName(value, passes);
  }, 500)
};
const fields = [
  {
    name: 'name',
    rules: 'required|string|checkUniqueSkillProfileName',
    value: ''
  },
  {
    name: 'skills',
    rules: 'array',
    value: []
  }
];

const plugins = dvrWithAsync(asyncRules);

export default new MobxReactForm({fields}, {plugins});
