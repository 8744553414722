const normalizeNumber = (value: number | string): number | string => {
  const number = Number(value);

  if (isNaN(number)) {
    return value;
  }

  return number;
};

export const EN_DASH = '\u2013';

export const formatRange = (from: number | string, to: number | string): string => {
  from = normalizeNumber(from);
  to = normalizeNumber(to);

  if (to === undefined || from === to) {
    return String(from);
  }

  if (typeof from === 'string' || typeof to === 'string') {
    return `${from} ${EN_DASH} ${to}`;
  }

  return `${from}${EN_DASH}${to}`;
};

export const displayChecklistScore = (checklistType = 'BINARY', score) => {
  if (checklistType === 'BINARY') {
    return `${score}%`;
  }
  return score;
};
