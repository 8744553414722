import {defineMessages} from 'react-intl';

const messages = defineMessages({
  signInOptions: {
    id: 'PlatformSettings.signInOptions',
    defaultMessage: 'Sign in options'
  },
  companyAccount: {
    id: 'PlatformSettings.companyAccount',
    defaultMessage: 'Company account (Single Sign On)'
  },
  emailAndPassword: {
    id: 'PlatformSettings.emailAndPassword',
    defaultMessage: 'Email and password'
  },
  emailOptionTurnedOnSuccess: {
    id: 'emailOptionTurnedOnSuccess',
    defaultMessage: 'Email and password option turned on succesfully'
  },
  emailOptionTurnedOffSuccess: {
    id: 'emailOptionTurnedOffSuccess',
    defaultMessage: 'Email and password option turned off succesfully'
  },
  emailOptionTurnedOnError: {
    id: 'emailOptionTurnedOnError',
    defaultMessage: 'There has been an error while trying to turn Email and password option on'
  },
  emailOptionTurnedOffError: {
    id: 'emailOptionTurnedOffError',
    defaultMessage: 'There has been an error while trying to turn Email and password option off'
  }
});

export default messages;
