import {defineMessages} from 'react-intl';

const messages = defineMessages({
  loading: {
    id: 'common.notifications.loading',
    defaultMessage: 'Loading...'
  },
  teamNotFound: {
    id: 'common.notifications.teamNotFound',
    defaultMessage: 'Team not found.'
  },
  fullName: {
    id: 'common.forms.fullName',
    defaultMessage: 'Full name'
  },
  role: {
    id: 'common.forms.role',
    defaultMessage: 'Role'
  },
  jobTitle: {
    id: 'common.forms.jobTitle',
    defaultMessage: 'Job Title'
  },
  noJobTitle: {
    id: 'common.forms.noJobTitle',
    defaultMessage: 'No Job Title'
  },
  adminDescription: {
    id: 'common.description.adminDescription',
    defaultMessage: 'Admin: can manage Users in their Team(s) and set up Workspaces managed by their Team(s).'
  },
  editorDescription: {
    id: 'common.description.editorDescription',
    defaultMessage:
      'Editor: can create guides for their Team(s) and publish in any Workspace(s) that their Team(s) have access to.'
  },
  viewerDescription: {
    id: 'common.description.viewerDescription',
    defaultMessage: 'Viewer: can view published guides on Workspace(s) that their Team(s) have access to.'
  },
  save: {
    id: 'common.forms.save',
    defaultMessage: 'Save'
  },
  cancel: {
    id: 'common.forms.cancel',
    defaultMessage: 'Cancel'
  },
  edit: {
    id: 'common.actions.edit',
    defaultMessage: 'Edit'
  },
  removeTeamMemberSuccess: {
    id: 'TeamUsers.removeTeamMemberSuccess',
    defaultMessage: 'User has been removed from team'
  },
  removeTeamMemberFailure: {
    id: 'TeamUsers.removeTeamMemberFailure',
    defaultMessage: 'Could not remove user from team'
  },
  updateTeamMemberSuccess: {
    id: 'TeamUsers.updateTeamMemberSuccess',
    defaultMessage: 'Team member role has been updated'
  },
  updateTeamMemberFailure: {
    id: 'TeamUsers.updateTeamMemberFailure',
    defaultMessage: 'Could not update team member role'
  },
  teamUpdateSuccess: {
    id: 'teamHasBeenUpdatedSuccess',
    defaultMessage: 'The team has been updated'
  },
  teamUpdateFailure: {
    id: 'TeamUsers.teamUpdateFailure',
    defaultMessage: 'Could not update the team name'
  },
  backToOverview: {
    id: 'common.actions.backToOverview',
    defaultMessage: 'Back to overview'
  },
  removeTeamMember: {
    id: 'TeamUsers.removeTeamMember',
    defaultMessage: 'Remove User from this Team'
  },
  removeTeamMemberConfirm: {
    id: 'TeamUsers.removeTeamMemberConfirm',
    defaultMessage: 'The user will no longer be able to access the content owned by this team.'
  },
  turnOnGuideApproval: {
    id: 'UserRole.turnOnGuideApproval',
    defaultMessage: 'Turn on Guide Approval for this Team'
  },
  turnOnGuideApprovalSuccess: {
    id: 'TeamUsers.turnOnGuideApprovalSuccess',
    defaultMessage: 'Guide Approval has been turned on'
  },
  turnOffGuideApprovalSuccess: {
    id: 'TeamUsers.turnOffGuideApprovalSuccess',
    defaultMessage: 'Guide Approval has been turned off'
  },
  turnOnGuideApprovalFailure: {
    id: 'TeamUsers.turnOnGuideApprovalFailure',
    defaultMessage: 'Could not turn on Guide Approval'
  },
  turnOffGuideApprovalFailure: {
    id: 'TeamUsers.turnOffGuideApprovalFailure',
    defaultMessage: 'Could not turn off Guide Approval'
  },
  approver: {
    id: 'common.field.approver',
    defaultMessage: 'Approver'
  },
  guideApprovalDescription: {
    id: 'common.description.guideApprovalDescription',
    defaultMessage: 'Guide Approval requires all content to be approved by an Approver before publishing'
  },
  noUsers: {
    id: 'TeamUsers.noUsers',
    defaultMessage: 'No Users have been added to this Team.'
  },
  addAnotherUser: {
    id: 'UserRole.addAnotherUser',
    defaultMessage: 'Add another user'
  },
  confirmRoleChange: {
    id: 'TeamUsers.confirmRoleChange',
    defaultMessage: 'Confirm role change'
  },
  removeUser: {
    id: 'TeamUsers.removeUser',
    defaultMessage: 'Remove user'
  },
  guidesPendingApprovalWarning: {
    id: 'TeamUsers.confirmRoleChangeGuideApprovalCheck',
    defaultMessage:
      'There are one or more guides pending approval in this team. All approval requests will be canceled. Approval requesters and guide assignees will be notified.'
  },
  confirmAction: {
    id: 'common.actions.confirm',
    defaultMessage: 'Confirm'
  },
  cannotRemoveUser: {
    id: 'TeamUsers.cannotRemoveUser',
    defaultMessage: 'Cannot remove user'
  },
  cannotChangeRole: {
    id: 'TeamUsers.cannotChangeRole',
    defaultMessage: 'Cannot change role'
  },
  onlyApproverInSingleTeam: {
    id: 'TeamUsers.onlyApproverInSingleTeam',
    defaultMessage:
      '{fullName} is the only approver in this team. Select at least one other approver or turn off guide approval and try again.'
  }
});

export default messages;
