import {defineMessages} from 'react-intl';

const messages = defineMessages({
  skillProfiles: {
    id: 'skillProfiles',
    defaultMessage: 'Skill Profiles'
  },
  noSkillProfiles: {
    id: 'noSkillProfiles',
    defaultMessage: 'No Skill Profiles here... yet!'
  },
  skillProfilesDescription: {
    id: 'skillProfilesDescription',
    defaultMessage: 'Group skills and then assign them to Teams and Job Titles.'
  },
  createSkillProfile: {
    id: 'createSkillProfile',
    defaultMessage: 'Create Skill Profile'
  },
  skillProfile: {
    id: 'skillProfile',
    defaultMessage: 'Skill Profile'
  },
  skill: {
    id: 'skill',
    defaultMessage: 'Skill'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  teams: {
    id: 'common.title.teams',
    defaultMessage: 'Teams'
  },
  jobTitles: {
    id: 'jobTitles',
    defaultMessage: 'Job Titles'
  },
  requiredLevel: {
    id: 'SmartSkills.requiredLevel',
    defaultMessage: 'Required Level'
  },
  repeatEvery: {
    id: 'repeatEvery',
    defaultMessage: 'Assessment Frequency'
  },
  searchByName: {
    id: 'searchByName',
    defaultMessage: 'Search by name'
  },
  sortBy: {
    id: 'common.actions.sortBy',
    defaultMessage: 'Sort by'
  },
  nameAz: {
    id: 'common.forms.name',
    defaultMessage: 'Name (A-Z)'
  },
  mostRecent: {
    id: 'mostRecent',
    defaultMessage: 'Most Recent'
  },
  nextPage: {
    id: 'SmartSkills.nextPage',
    defaultMessage: 'Next user page'
  },
  prevPage: {
    id: 'SmartSkills.prevPage',
    defaultMessage: 'Previous user page'
  },
  none: {
    id: 'common.none',
    defaultMessage: 'None'
  },
  everySixMonths: {
    id: 'everySixMonths',
    defaultMessage: 'Every 6 months'
  },
  everyOneYear: {
    id: 'everyOneYear',
    defaultMessage: 'Every 1 year'
  },
  everyTwoYears: {
    id: 'everyTwoYears',
    defaultMessage: 'Every 2 years'
  },
  noSkillProfilesFound: {
    id: 'noSkillProfilesFound',
    defaultMessage: 'No Skill Profiles found'
  },
  error: {
    id: 'common.errors.pageload',
    defaultMessage: "Couldn't load the page. Please try again."
  },
  unableToLoadSkillLevels: {
    id: 'SmartSkills.unableToLoadSkillLevels',
    defaultMessage: 'Unable to load required level details'
  }
});

export default messages;
