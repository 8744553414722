import styled from 'styled-components';

//components
import $ViewTitle from 'ui-components/Layout/ViewTitle';

import {commonPalette} from 'shared/styles/palette';

export const EditInstructionMenu = styled.div`
  background-color: ${commonPalette.white};
`;

export const Header = styled.header`
  padding: 26px 26px 34px;
`;

export const StyledViewTitle = styled($ViewTitle)`
  padding-bottom: 43px;
`;
