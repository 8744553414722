import React from 'react';
import {injectIntl} from 'react-intl';
import {observer} from 'mobx-react';

import Dialog from 'ui-components/Dialog';
import InfoBox from 'ui-components/InfoBox';

import messages from './messages';
import {Select} from 'ui-components';
import {useQuery} from '@apollo/client';
import {ChecklistAlertRecipientsForSelectQuery, AlertRecipientsForSelectQuery} from 'api/alerts-recipients/queries';
import Spinner from 'shared/components/Spinner';
import {useStore} from 'hooks/useStore';
import {SpinnerWrapper, StyledField} from './styles';

const useAlertRecipients = () => {
  const {data, loading, error} = useQuery<
    {
      alertRecipients: {results: {id: string; name: string}[]};
    },
    {
      filters?: {
        name?: {
          contains: string;
        };
      };
    }
  >(AlertRecipientsForSelectQuery, {
    fetchPolicy: 'network-only'
  });

  return {
    alertRecipients: data?.alertRecipients.results,
    loading,
    error
  };
};

const useChecklistAlertRecipients = (checklistId?: string) => {
  const {data, loading, error} = useQuery<{
    checklistAlertRecipients: {results: {id: string; name: string}[]};
  }>(ChecklistAlertRecipientsForSelectQuery, {
    variables: {
      checklistId
    },
    fetchPolicy: 'network-only',
    skip: !checklistId
  });

  return {
    checklistAlertRecipients: data?.checklistAlertRecipients.results,
    loading,
    error
  };
};

type ConfigureAlertRecipientsDialogProps = ReactIntl.InjectedIntlProps;

interface DialogContentsProps {
  alertRecipients?: {
    id: string;
    name: string;
  }[];
  setSelectedRecipients: (selectedRecipients: string[]) => void;
  selectedIds: string[];
  title: string;
  placeholder: string;
  saving: boolean;
}

const DialogContents: React.FC<DialogContentsProps> = ({
  alertRecipients,
  setSelectedRecipients,
  selectedIds,
  title,
  placeholder,
  saving
}) => {
  return (
    <>
      <StyledField
        title={title}
        noRecipients={alertRecipients?.length === 0}
        inputComponent={
          <Select
            allowClear
            showSearch
            testId="alert-recipient-picker"
            dataCy="alert-recipient-picker"
            optionFormatter="name"
            options={alertRecipients}
            onChange={setSelectedRecipients}
            placeholder={placeholder}
            selectedValues={selectedIds}
            disabled={saving}
          />
        }
        className="short"
      />
    </>
  );
};

export const ChecklistAlertRecipientsDialog: React.FC<ConfigureAlertRecipientsDialogProps> = props => {
  const {
    intl: {formatMessage}
  } = props;

  const {
    checklistAlertRecipientsDialog: {opened, save, saving, checklist, cancel, setSelectedRecipients, selectedRecipients}
  } = useStore();

  const {alertRecipients} = useAlertRecipients();

  const onSave = async () => {
    await save({
      error: formatMessage(messages.error, {checklistName: checklist?.title}),
      success: formatMessage(messages.success, {checklistName: checklist?.title})
    });
  };

  const actions = [
    {
      label: saving ? '' : formatMessage(messages.save),
      onClick: onSave,
      loading: saving,
      disabled: !selectedRecipients || saving
    }
  ];

  const {checklistAlertRecipients, loading: checklistLoading} = useChecklistAlertRecipients(checklist?.id);

  const selectedIds = selectedRecipients || checklistAlertRecipients?.map(recipient => recipient.id) || [];

  const loading = checklistLoading && !checklistAlertRecipients;

  return (
    <Dialog
      size={'small'}
      actions={actions}
      isOpen={opened}
      onCancel={cancel}
      title={formatMessage(messages.title)}
      bodyStyle={{overflow: 'visible'}}
      dialogStyle={{overflow: 'visible'}}
    >
      {loading ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <>
          {alertRecipients?.length === 0 && <InfoBox content={formatMessage(messages.noRecipients)} type="warning" />}
          <DialogContents
            alertRecipients={alertRecipients}
            setSelectedRecipients={setSelectedRecipients}
            selectedIds={selectedIds}
            title={formatMessage(messages.subtitle)}
            placeholder={formatMessage(messages.alertRecipientsPlaceholder)}
            saving={saving}
          />
        </>
      )}
    </Dialog>
  );
};

export default injectIntl(observer(ChecklistAlertRecipientsDialog));
