import {defineMessages} from 'react-intl';

const messages = defineMessages({
  requestingApproval: {
    id: 'RequestApproval.requestingApproval',
    defaultMessage: 'Requesting Approval'
  },
  requestApprovalBody: {
    id: 'RequestApproval.requestApprovalBody',
    defaultMessage: 'Please inform approver(s) about the reason of your request and/or the changes made'
  },
  publishingRequiresApproval: {
    id: 'RequestApproval.publishingRequiresApproval',
    defaultMessage: 'Publishing requires approval'
  },
  requestApprovalBodyOldVersion: {
    id: 'RequestApproval.requestApprovalBodyOldVersion',
    defaultMessage: "This guide will be submitted to your team's approver(s) in order to be published."
  },
  requestApproval: {
    id: 'common.actions.requestApproval',
    defaultMessage: 'Request approval'
  }
});

export default messages;
