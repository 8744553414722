import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import mutationNames from 'api/team/constants';
import {clone, mapValues, pick} from 'lodash';
import views from 'config/views';
import {TEAM_MEMBER_ROLE} from 'shared/enums';

//styles
import {ContentWrapper, DataTable, RemoveButton, TeamNameField} from './styles';
import {CellWrapper, HeaderWrapper} from 'ui-components/Layout/DataTable/styles';

// queries
import {Team} from 'api/team/queries';
import {SkillRoles} from 'api/skill-matrix/queries';
import {SkillRolesOptions} from 'api/skill-matrix/query-options';

//mutations
import {EditTeam, RemoveTeamMember, UpdateTeamUserRoleWithResult} from 'api/team/mutations';

//options
import {teamOptions} from 'api/team/query-options';
import {editTeamOptions, removeTeamMemberOptions, updateTeamUserRoleOptions} from 'api/team/mutation-options';

//components
import LoadingMessage from 'components/LoadingMessage';
import {FormattedMessage} from 'components/FormattedComponents';
import RolePicker from 'components/RolePicker';
import ButtonGroup from 'ui-components/ButtonGroup';
import Icon from 'ui-components/Icon';
import Container from 'ui-components/Layout/Container';
import Button from 'ui-components/Button';

//messages
import messages from './messages';

const getIsLastApprover = ({teamMembers, teamMemberData}) => {
  const otherTeamMembers = teamMembers.filter(({user: {id}}) => id !== teamMemberData.user.id);
  const numOtherApprovers = otherTeamMembers.filter(({isApprover}) => isApprover).length;
  return numOtherApprovers === 0;
};

@inject('store')
@graphql(SkillRoles, SkillRolesOptions)
@graphql(Team, teamOptions)
@graphql(RemoveTeamMember, removeTeamMemberOptions)
@graphql(UpdateTeamUserRoleWithResult, updateTeamUserRoleOptions)
@graphql(EditTeam, editTeamOptions)
@observer
class TeamUsersView extends Component {
  UNSAFE_componentWillMount() {
    const {
      store,
      intl: {formatMessage}
    } = this.props;
    const {editTeamPage} = store;

    const translations = mapValues(
      pick(messages, [
        'updateTeamMemberSuccess',
        'updateTeamMemberFailure',
        'teamUpdateSuccess',
        'teamUpdateFailure',
        'turnOnGuideApprovalSuccess',
        'turnOffGuideApprovalSuccess',
        'turnOnGuideApprovalFailure',
        'turnOffGuideApprovalFailure',
        'addAnotherUser',
        'removeUser',
        'confirmRoleChange',
        'guidesPendingApprovalWarning',
        'cannotChangeRole'
      ]),
      message => formatMessage(message)
    );

    editTeamPage.setTranslations(translations);
    editTeamPage.setEditMode(false);
  }

  componentDidMount() {
    const {
      data: {team, loading},
      store
    } = this.props;
    const {editTeamPage, app} = store;
    const canManageTeam = team?.canManageTeam;

    if (!loading) {
      editTeamPage.setTeam(team);
      canManageTeam ? this.setActionMenu() : app.resetActionMenuItems();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      data: {team, loading, error},
      store
    } = nextProps;

    if (error || (!loading && !team)) {
      store.router.goTo(views.teams, {});
    }
  }

  componentDidUpdate() {
    const {
      data: {team, loading},
      store
    } = this.props;
    const {editTeamPage, app} = store;
    const canManageTeam = team?.canManageTeam;

    if (!loading) {
      editTeamPage.setTeam(team);
      canManageTeam ? this.setActionMenu() : app.resetActionMenuItems();
    }
  }

  setActionMenu = () => {
    const {store} = this.props;
    const {app} = store;
    const {id} = store.router.params;

    const actionMenuItems = [
      {
        id: 'create',
        params: {id},
        view: 'newTeamUsers'
      }
    ];

    app.setActionMenuItems(actionMenuItems);
  };

  onRolePickerChange = async ({teamMembers, teamMemberData, selectedRole}) => {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {editTeamPage} = store;
    const {updateTeamMemberRole, checkTeamHasGuidesPendingApproval} = editTeamPage;
    const updateRoleMutation = this.props[mutationNames.UPDATE_TEAM_USER];

    const confirmCancellingPendingApprovals = true;

    const formattedTranslations = {
      onlyApproverInSingleTeamWarning: formatMessage(messages.onlyApproverInSingleTeam, {
        fullName: teamMemberData.user.fullName
      })
    };

    const isApproverSetToViewer = selectedRole === TEAM_MEMBER_ROLE.VIEWER && teamMemberData.isApprover;
    if (isApproverSetToViewer) {
      const isLastApprover = getIsLastApprover({teamMembers, teamMemberData});
      if (isLastApprover) {
        store.warningDialog.open({
          content: {
            title: formatMessage(messages.cannotRemoveUser),
            message: formatMessage(messages.onlyApproverInSingleTeam, {fullName: teamMemberData.user.fullName})
          }
        });
      } else {
        await checkTeamHasGuidesPendingApproval({
          callback: updateTeamMemberRole.bind(
            this,
            updateRoleMutation,
            teamMemberData.user,
            selectedRole,
            teamMemberData.skillRole?.id,
            teamMemberData.isApprover,
            formattedTranslations,
            confirmCancellingPendingApprovals
          ),
          content: {
            title: formatMessage(messages.confirmRoleChange),
            message: formatMessage(messages.guidesPendingApprovalWarning),
            buttonText: formatMessage(messages.confirmAction)
          }
        });
      }
    } else {
      await updateTeamMemberRole(
        updateRoleMutation,
        teamMemberData.user,
        selectedRole,
        teamMemberData.skillRole?.id,
        teamMemberData.isApprover,
        formattedTranslations
      );
    }
  };

  onSkillRolePickerChange = async ({teamMembers, teamMemberData, selectedSkillRole}) => {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {editTeamPage} = store;
    const {updateTeamMemberRole, checkTeamHasGuidesPendingApproval} = editTeamPage;
    const updateRoleMutation = this.props[mutationNames.UPDATE_TEAM_USER];

    const confirmCancellingPendingApprovals = true;

    const formattedTranslations = {
      onlyApproverInSingleTeamWarning: formatMessage(messages.onlyApproverInSingleTeam, {
        fullName: teamMemberData.user.fullName
      })
    };

    const isApproverSetToViewer = selectedSkillRole === TEAM_MEMBER_ROLE.VIEWER && teamMemberData.isApprover;
    if (isApproverSetToViewer) {
      const isLastApprover = getIsLastApprover({teamMembers, teamMemberData});
      if (isLastApprover) {
        store.warningDialog.open({
          content: {
            title: formatMessage(messages.cannotRemoveUser),
            message: formatMessage(messages.onlyApproverInSingleTeam, {fullName: teamMemberData.user.fullName})
          }
        });
      } else {
        await checkTeamHasGuidesPendingApproval({
          callback: updateTeamMemberRole.bind(
            this,
            updateRoleMutation,
            teamMemberData.user,
            teamMemberData.role,
            selectedSkillRole,
            teamMemberData.isApprover,
            formattedTranslations,
            confirmCancellingPendingApprovals
          ),
          content: {
            title: formatMessage(messages.confirmRoleChange),
            message: formatMessage(messages.guidesPendingApprovalWarning),
            buttonText: formatMessage(messages.confirmAction)
          }
        });
      }
    } else {
      await updateTeamMemberRole(
        updateRoleMutation,
        teamMemberData.user,
        teamMemberData.role,
        selectedSkillRole,
        teamMemberData.isApprover,
        formattedTranslations
      );
    }
  };

  onRemoveButtonClick = async ({teamMembers, teamMemberData}) => {
    const {
      intl: {formatMessage},
      store
    } = this.props;
    const {editTeamPage} = store;
    const {checkTeamHasGuidesPendingApproval} = editTeamPage;

    const confirmCancellingPendingApprovals = true;

    if (teamMemberData.isApprover) {
      const isLastApprover = getIsLastApprover({teamMembers, teamMemberData});

      if (isLastApprover) {
        store.warningDialog.open({
          content: {
            title: formatMessage(messages.cannotRemoveUser),
            message: formatMessage(messages.onlyApproverInSingleTeam, {fullName: teamMemberData.user.fullName})
          }
        });
      } else {
        await checkTeamHasGuidesPendingApproval({
          callback: this.removeMember.bind(this, teamMemberData.user, confirmCancellingPendingApprovals),
          content: {
            title: formatMessage(messages.removeUser),
            message: formatMessage(messages.guidesPendingApprovalWarning),
            buttonText: formatMessage(messages.confirmAction)
          }
        });
      }
    } else {
      this.removeMember(teamMemberData.user);
    }
  };

  removeMember = (user, confirmCancellingPendingApprovals = false) => {
    const {
      intl: {formatMessage},
      data: {team},
      store
    } = this.props;
    const removeTeamMemberMutation = this.props[mutationNames.REMOVE_TEAM_USER];
    const {
      editTeamPage,
      archiveDialog,
      auth: {user: me}
    } = store;

    const translations = {
      action: formatMessage(messages.removeTeamMember),
      confirmation: formatMessage(messages.removeTeamMemberConfirm),
      archiveSuccess: formatMessage(messages.removeTeamMemberSuccess),
      archiveFailure: formatMessage(messages.removeTeamMemberFailure),
      cannotRemoveUser: formatMessage(messages.cannotRemoveUser),
      onlyApproverInSingleTeam: formatMessage(messages.onlyApproverInSingleTeam, {fullName: user.fullName}),

      // repeating strings under different obj properties so the code makes more sense
      removeTeamMemberSuccess: formatMessage(messages.removeTeamMemberSuccess),
      removeTeamMemberFailure: formatMessage(messages.removeTeamMemberFailure)
    };

    archiveDialog.open({
      params: {teamId: team.id, userId: user.id, me, confirmCancellingPendingApprovals},
      mutation: removeTeamMemberMutation,
      translations,
      itemPrimary: user.fullName, // use metadata object instead for passing data around
      itemSecondary: user.email, // use metadata object instead for passing data around
      metadata: {user},
      onArchive: editTeamPage.removeTeamMemberDialogCallback
    });
  };

  componentWillUnmount() {
    const {
      store: {app, editTeamPage}
    } = this.props;
    app.resetActionMenuItems();
    editTeamPage.reset();
  }

  render() {
    const {
      intl: {formatMessage},
      data: {loading: loadingTeam, error, team},
      skillRolesQuery: {loading: loadingSkillRoles, skillRoles},
      store,
      editTeam,
      viewMode
    } = this.props;

    const {
      editTeamPage,
      addUserToTeamDialog,
      platform: {hasSkillsEnabled}
    } = store;

    const {updateTeam, resetForm, form} = editTeamPage;
    const showLoading = loadingTeam || loadingSkillRoles || error;
    const canManageTeam = team?.canManageTeam;
    const teamMembers = team?.teamMembers;
    const editTeamMutation = this.props[mutationNames.EDIT_TEAM];
    const sortedSkillRoles = (clone(skillRoles) || []).sort((a, b) => a.name.localeCompare(b.name));
    const skillRoleList = [{id: null, name: formatMessage(messages.noJobTitle)}, ...sortedSkillRoles];

    const columns = [
      {
        Header: <HeaderWrapper>{formatMessage(messages.fullName)}</HeaderWrapper>,
        accessor: 'user.fullName',
        resizable: true,
        Cell: ({value}) => (
          <CellWrapper>
            <span title={value}>{value}</span>
          </CellWrapper>
        )
      },
      {
        Header: (
          <HeaderWrapper>
            {formatMessage(messages.role)}
            <Icon
              style={{marginLeft: 8}}
              popoverContent={[
                formatMessage(messages.adminDescription),
                formatMessage(messages.editorDescription),
                formatMessage(messages.viewerDescription)
              ]}
            />
          </HeaderWrapper>
        ),
        accessor: 'role',
        resizable: true,
        Cell: ({value: currentRole, original: teamMemberData}) => {
          if (canManageTeam && !!teamMemberData?.user?.email) {
            return (
              <CellWrapper>
                <RolePicker
                  dataCy={`role-picker-${currentRole}`}
                  allowClear={false}
                  selectedValue={currentRole}
                  style={{
                    position: 'absolute',
                    width: 160,
                    overflow: 'visible',
                    Selector: {height: 30},
                    Input: {width: '80%'}
                  }}
                  onChange={selectedRole => {
                    this.onRolePickerChange({
                      teamMembers,
                      teamMemberData,
                      selectedRole
                    });
                  }}
                />
              </CellWrapper>
            );
          } else {
            return <CellWrapper>{currentRole}</CellWrapper>;
          }
        },
        sortable: false,
        ...(hasSkillsEnabled ? {width: 180} : null)
      },
      hasSkillsEnabled && {
        Header: <HeaderWrapper>{formatMessage(messages.jobTitle)}</HeaderWrapper>,
        accessor: 'skillRole.id',
        resizable: true,
        Cell: ({value: currentSkillRole, original: teamMemberData}) => {
          if (canManageTeam) {
            return (
              <CellWrapper>
                <RolePicker
                  roles={skillRoleList}
                  placeholder={formatMessage(messages.noJobTitle)}
                  dataCy={`skill-role-picker-${currentSkillRole}`}
                  allowClear={false}
                  selectedValue={currentSkillRole}
                  style={{
                    position: 'absolute',
                    width: 200,
                    overflow: 'visible',
                    Selector: {height: 30},
                    Input: {width: '80%'}
                  }}
                  onChange={selectedSkillRole => {
                    this.onSkillRolePickerChange({
                      teamMembers,
                      teamMemberData,
                      selectedSkillRole
                    });
                  }}
                />
              </CellWrapper>
            );
          } else {
            return <CellWrapper>{teamMemberData?.skillRole?.name}</CellWrapper>;
          }
        },
        sortable: false,
        width: 220
      },
      {
        Cell: ({original: teamMemberData}) => {
          if (canManageTeam) {
            return (
              <CellWrapper>
                <RemoveButton
                  onClick={() => {
                    this.onRemoveButtonClick({teamMembers, teamMemberData});
                  }}
                  data-testid={'team-user-remove-button'}
                />
              </CellWrapper>
            );
          }
        },
        sortable: false,
        width: 30
      }
    ].filter(Boolean);

    if (showLoading)
      return (
        <LoadingMessage hasFailed={error}>
          <FormattedMessage {...messages.loading} />
        </LoadingMessage>
      );

    return (
      <Container noPaddingLeft={editTeam}>
        <ContentWrapper>
          {!editTeam && (
            <TeamNameField
              form={form}
              field="name"
              readOnly={!canManageTeam}
              onSubmit={() => updateTeam(editTeamMutation)}
              onCancel={() => resetForm()}
            />
          )}
          <DataTable
            data={teamMembers}
            columns={columns}
            noDataMessage={formatMessage(messages.noUsers)}
            hoverEffect
            defaultSorted={[
              {
                id: 'user.fullName',
                desc: false
              }
            ]}
          />

          {!viewMode && canManageTeam && (
            <div>
              <Button
                underlined
                style={{}}
                disabled={false}
                iconId="add"
                onClick={addUserToTeamDialog.open}
                label={formatMessage(messages.addAnotherUser)}
                dataCy="add-another-user"
              />
            </div>
          )}
        </ContentWrapper>
        <ButtonGroup
          bottomFixed
          style={{paddingRight: 50}}
          secondary={{
            label: formatMessage(messages.backToOverview),
            onClick: () => store.router.goTo(views.teams, {})
          }}
        />
      </Container>
    );
  }
}

export default injectIntl(TeamUsersView);
