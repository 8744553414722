import React, {Component} from 'react';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';
import {
  MainWrapper,
  Wrapper,
  SubWrapper,
  StyledText,
  StyledSubText,
  StyledInfo,
  StyledList,
  FieldWrapper
} from './styles';
import messages from './messages';
import Field from 'ui-components/Field';
import {bindField} from 'shared/utils/input-utils';
import {getCurrentHostname} from 'shared/utils/host-utils';
import {client} from 'utils/apollo-client';
import {GetDomainsForGuide} from 'api/guide/queries';
import Icon from 'ui-components/Icon';
import {parseGuideLink} from './utils';
import InfoBox from 'ui-components/InfoBox';
import TagPicker from 'components/TagPicker';

@inject('store')
@observer
class GeneralInformation extends Component {
  componentWillUnmount() {
    const {
      store: {learningActivityGeneralInfo}
    } = this.props;
    learningActivityGeneralInfo.reset();
  }

  componentDidMount() {
    const {
      store: {learningActivityGeneralInfo}
    } = this.props;
    const values = learningActivityGeneralInfo.newLearningActivityForm.values();
    if (values.resourceUrl) {
      this.getWorkspaces(values.resourceUrl);
    }
  }

  getWorkspaces = value => {
    const {
      store: {learningActivityGeneralInfo}
    } = this.props;

    if (value.includes(getCurrentHostname()) && value.includes('/share') && !value.includes('/admin')) {
      const guideId = parseGuideLink(value);
      if (guideId) {
        client
          .query({
            query: GetDomainsForGuide,
            variables: {id: guideId},
            fetchPolicy: 'network-only'
          })
          .then(
            ({
              data: {
                guideForCms: {domains}
              }
            }) => {
              learningActivityGeneralInfo.updateValidGuideId(true);
              learningActivityGeneralInfo.updateWorkspaces(domains);
            }
          )
          .catch(() => {
            learningActivityGeneralInfo.updateValidGuideId(false);
            learningActivityGeneralInfo.updateWorkspaces([]);
          });
      } else {
        learningActivityGeneralInfo.updateValidGuideId(false);
        learningActivityGeneralInfo.updateWorkspaces([]);
      }
    } else {
      learningActivityGeneralInfo.updateValidGuideId(true);
      learningActivityGeneralInfo.updateWorkspaces([]);
    }
  };

  linkFieldOptions = {
    onChangeReaction: e => {
      const {value} = e.target;
      this.getWorkspaces(value);
    }
  };

  render() {
    const {
      store: {
        learningActivityGeneralInfo,
        platform: {
          developmentFeatureFlags: {learningActivityTags}
        }
      },
      intl: {formatMessage}
    } = this.props;
    const {selectedTagsIds, setSelectedTags, localSelectedTags, setLocalSelectedTags} = learningActivityGeneralInfo;
    const workspacesWrapper = () => {
      if (learningActivityGeneralInfo.workspaces?.length) {
        return (
          <Wrapper hasBorderTop={true}>
            <SubWrapper>
              <StyledText>{formatMessage(messages.workspacesTitle)}</StyledText>
              <StyledSubText maxWidth={true}>{formatMessage(messages.workspacesDesc)}</StyledSubText>
            </SubWrapper>
            <SubWrapper>
              <StyledInfo>
                <Icon iconId={'info'} />
                {formatMessage(messages.workspacesWarning)}
              </StyledInfo>
              <StyledList>
                {learningActivityGeneralInfo.workspaces.map(workspace => (
                  <li key={workspace.name}>{workspace.name}</li>
                ))}
              </StyledList>
            </SubWrapper>
          </Wrapper>
        );
      }
    };

    return (
      <MainWrapper>
        <Wrapper>
          <SubWrapper>
            <StyledText>{formatMessage(messages.generalInformation)}</StyledText>
          </SubWrapper>
          <SubWrapper>
            <Field
              styles={{Title: {marginTop: '20px'}}}
              autofocus={true}
              size="small"
              title={formatMessage(messages.learningName)}
              placeholder={formatMessage(messages.name)}
              {...bindField(learningActivityGeneralInfo.newLearningActivityForm, 'title')}
              field="title"
            />
            <Field
              title={formatMessage(messages.description)}
              styles={{Input: {height: '80px', padding: '10px 16px'}}}
              inputType="textarea"
              field="content"
              placeholder={formatMessage(messages.descPlaceholder)}
              form={learningActivityGeneralInfo.newLearningActivityForm}
              hideError={true}
            />
            <Field
              size="small"
              title={formatMessage(messages.link)}
              placeholder={formatMessage(messages.linkPlaceholder)}
              {...bindField(learningActivityGeneralInfo.newLearningActivityForm, 'resourceUrl', this.linkFieldOptions)}
              {...(!learningActivityGeneralInfo.validGuideId && {
                forcedError: formatMessage(messages.incorrectGuide)
              })}
            />
          </SubWrapper>
        </Wrapper>
        {workspacesWrapper()}
        {learningActivityTags && (
          <Wrapper hasBorderTop={true} hasBorderBottom={true}>
            <SubWrapper>
              <StyledText>{formatMessage(messages.labels)}</StyledText>
            </SubWrapper>
            <FieldWrapper hasMoreSelectedTags={selectedTagsIds.length > 6}>
              {selectedTagsIds.length === 10 && (
                <InfoBox content={formatMessage(messages.max10TagsOnLearning)} type="informational" />
              )}
              <Field
                size="small"
                title={formatMessage(messages.tags)}
                className="tag-selection"
                hideError={true}
                inputComponent={
                  <TagPicker
                    className="tag-picker"
                    selectedTagsIds={selectedTagsIds}
                    setSelectedTags={setSelectedTags}
                    disableCreate={true}
                    isSkillTagsPicker={true}
                    localSelectedTags={localSelectedTags}
                    setLocalSelectedTags={setLocalSelectedTags}
                  />
                }
              />
            </FieldWrapper>
          </Wrapper>
        )}
      </MainWrapper>
    );
  }
}

export default injectIntl(GeneralInformation);
