import {action, observable, makeObservable} from 'mobx';

import notification from 'utils/notification-utils';

class RemoveCampaignDialog {
  @observable opened = false;
  @observable translations;

  @action open = () => {
    const {
      applyCampaignDialog: {toggleDialogVisibility}
    } = this.store;

    toggleDialogVisibility();
    this.opened = true;
  };

  @action setTranslations = translations => {
    this.translations = translations;
  };

  @action close = () => {
    const {
      applyCampaignDialog: {toggleDialogVisibility}
    } = this.store;

    this.reset();
    toggleDialogVisibility();
  };

  @action
  reset = () => {
    this.opened = false;
  };

  submit = removeCampaignFromInstructionsMutation => {
    const {
      editGuidePage,
      editInstructionDetailsPage,
      applyCampaignDialog: {instructions, selectedCampaign}
    } = this.store;

    const {id: guideId, versionId: guideVersionId} = editGuidePage.guide || editInstructionDetailsPage.guide;
    const instructionIds = instructions.map(instruction => instruction.id);

    removeCampaignFromInstructionsMutation({
      instructionIds,
      guideId,
      guideVersionId
    })
      .then(() => {
        notification.success(
          selectedCampaign ? this.translations.campaignRemoveSuccess : this.translations.campaignsRemoveSuccess
        );
      })
      .catch(() => {
        notification.error(
          selectedCampaign ? this.translations.campaignRemoveError : this.translations.campaignsRemoveError
        );
      });

    this.reset();
  };

  setStore = store => {
    this.store = store;
  };

  constructor() {
    makeObservable(this);
  }
}

export default RemoveCampaignDialog;
