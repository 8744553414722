import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc/graphql';
import {mapValues, pick} from 'lodash';
import {injectIntl} from 'react-intl';
import {inject, observer} from 'mobx-react';

import {FormattedMessage} from 'components/FormattedComponents';
import Dialog from 'ui-components/Dialog';

import {DeleteUser} from 'api/user/mutations';
import {deleteUserOptions} from 'api/user/mutation-options';

import messages from '../messages';
import {dialogStyle, StyledHighlightedInfo, deleteUserButtonStyle} from '../styles';
@inject('store')
@graphql(DeleteUser, deleteUserOptions)
@observer
class CanDeleteUserDialog extends Component {
  componentDidMount = () => {
    const {
      user: {teams},
      intl: {formatMessage},
      store: {
        deleteUserDialog: {setTranslations, setTeamIds}
      }
    } = this.props;

    const teamsIds = teams && teams.map(team => team.team.id);
    const translations = mapValues(pick(messages, ['userDeletedSuccess', 'userDeletedError']), message =>
      formatMessage(message)
    );

    setTeamIds(teamsIds);
    setTranslations(translations);
  };

  render() {
    const {
      user: {fullName},
      isOpen,
      deleteUserMutation,
      store: {
        deleteUserDialog: {deleteUser, close}
      }
    } = this.props;

    const title = <FormattedMessage {...messages.deleteUser} />;
    const content = (
      <StyledHighlightedInfo>
        <FormattedMessage
          {...messages.areYouSureDeleteUser}
          values={{
            name: <b>{fullName}</b>
          }}
        />
      </StyledHighlightedInfo>
    );

    const actions = [
      {
        label: <FormattedMessage {...messages.deleteUser} />,
        onClick: () => deleteUser(deleteUserMutation),
        disabled: false,
        style: deleteUserButtonStyle
      },
      {
        label: <FormattedMessage {...messages.cancel} />,
        onClick: close,
        disabled: false
      }
    ];

    return (
      <Dialog
        actions={actions}
        isOpen={isOpen}
        title={title}
        dialogStyle={dialogStyle}
        titleIconId="warning"
        showCancel
        onCancel={close}
        customHeaderStyle={{paddingBottom: 24}}
        buttonGroupStyle={{marginTop: 24}}
      >
        {content}
      </Dialog>
    );
  }
}

export default injectIntl(CanDeleteUserDialog);
