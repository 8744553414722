import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const fields = [
  'instruction',
  'mediaId',
  'notes',
  'notes[].iconId',
  'notes[].content',
  'notes[].mediaId',
  'notes[].mandatory'
];

const rules = {
  instruction: 'string',
  mediaId: 'string',
  'notes[].iconId': 'string|required',
  'notes[].content': 'string|required',
  'notes[].mediaId': 'string',
  'notes[].mandatory': 'boolean'
};

const defaults = {
  'notes[].mandatory': false
};

const types = {
  'notes[].mandatory': 'checkbox'
};

export default () => new MobxReactForm({fields, rules, defaults, types}, {plugins});
