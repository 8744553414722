import React from 'react';
import {StyledWrapper, StyledChips, CloseIcon} from './styles';

interface Tag {
  title: string;
  id: string;
}

interface Props {
  items: Tag[];
  removeItem: any;
}

const FiltersChips: React.FC<Props> = ({items, removeItem}) => {
  return (
    <StyledWrapper>
      {items?.map(item => (
        <StyledChips key={item.id}>
          {item.title}
          <CloseIcon data-cy="close" name="close" size={10} onClick={() => removeItem(item)} />
        </StyledChips>
      ))}
    </StyledWrapper>
  );
};

export default FiltersChips;
