import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {injectIntl} from 'react-intl';
import {graphql} from '@apollo/client/react/hoc/graphql';
import filter from 'lodash/filter';

//data
import {ArchiveGuide, PublishGuide, SetGuideToDraft} from 'api/guide/mutations';
import {archiveGuideFromList, publishGuideFromList, setGuideToDraftFromList} from 'api/guide/mutation-options';

//styled-components
import {GuidesList, GuidesListItem, GuidesListItemWrapper, StyledNoResultsFound} from './styles';

//components
import GuideCard from 'components/GuideCard';
import {FormattedMessage} from 'components/FormattedComponents';

//messages
import messages from './messages';

@inject('store')
@graphql(ArchiveGuide, archiveGuideFromList)
@graphql(PublishGuide, publishGuideFromList)
@graphql(SetGuideToDraft, setGuideToDraftFromList)
@observer
class GuidesListComponent extends Component {
  render() {
    const {
      guides = [],
      tagId,
      loading,
      archiveGuideFromListMutation,
      publishGuideFromListMutation,
      setGuideToDraftFromListMutation,
      hasCustomFilters,
      nextOffset,
      searchTerm
    } = this.props;

    //computed
    const filteredGuides = tagId && tagId !== 'all' ? filter(guides, guide => guide.tag.id === tagId) : guides;

    const showTopEmptyCards = loading && (hasCustomFilters || searchTerm.length > 0);
    const showBottomEmptyCards = loading || (loading && nextOffset > 0);
    const showNoGuides = filteredGuides.length === 0 && !loading;

    return (
      <GuidesList>
        {showNoGuides && (
          <StyledNoResultsFound>
            <FormattedMessage {...messages.noGuidesFound} />
          </StyledNoResultsFound>
        )}
        {showTopEmptyCards && getEmptyCards()}
        {!showTopEmptyCards &&
          filteredGuides.map(guide => (
            <GuidesListItemWrapper key={guide.id}>
              <GuidesListItem>
                <GuideCard
                  data-cy="guide-card"
                  guide={guide}
                  archive={archiveGuideFromListMutation}
                  publish={publishGuideFromListMutation}
                  unpublish={setGuideToDraftFromListMutation}
                />
              </GuidesListItem>
            </GuidesListItemWrapper>
          ))}
        {showBottomEmptyCards && getEmptyCards()}
      </GuidesList>
    );
  }
}

const getEmptyCards = (numberOfCards = 10) => {
  const emptyCardsArray = [];

  for (let i = 0; i < numberOfCards; i++) {
    emptyCardsArray.push(
      <GuidesListItem key={i}>
        <GuideCard isEmptyCard={true} />
      </GuidesListItem>
    );
  }

  return emptyCardsArray;
};

export default injectIntl(GuidesListComponent);
