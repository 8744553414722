const colors = {
  white: '#ffffff',
  black: '#000000',
  transparent: 'rgba(0,0,0,0)',
  teal: '#44cbbd',
  primary: '#0066a1',
  primaryDark: '#005892',
  lightGray: '#f3f2f2',
  linkColor: '#387eb0',
  red: '#da3230',
  darkRed: '#d82924',
  grays: {
    gray1: '#eeeeee'
  }
};

export default colors;
