import {GUIDE_APPROVAL_TYPES} from 'config/enums';

type ChecklistEventType = typeof checklistEventTypes[number];
type InstructionEventType = typeof instructionEventTypes[number];
type TopicEventType = typeof topicEventTypes[number];
type StepEventType = typeof stepEventTypes[number];
type CheckEventType = typeof checkEventTypes[number];
type GuideEventType = typeof guideEventTypes[number];

export type ActivityEventTypes =
  | ChecklistEventType
  | InstructionEventType
  | TopicEventType
  | StepEventType
  | CheckEventType
  | GuideEventType;

export type Workspace = {
  name: string;
};

export type Media = {
  url: string;
  icon?: {type: string; id: string};
  media?: {url: string};
  __typename?: string;
};

export type Person = {fullName: string};

type GuideVersion = {
  guideVersionId: string;
  guideId: string;
  title: string;
};

type DisplaySettings = {
  visible: boolean;
  collapsible: boolean;
};

type StepNoteMetadata = {
  icon?: {type: string};
  content?: string;
};

export type ActivityEvent = {
  eventType: ActivityEventTypes;
  guideId: string;
  topicId?: string;
  stepId?: string;
  instructionId?: string;
  comment?: string;
  producedBy: Person;
  newAssignee?: Person;
  versionId: string;
  producedAt: string;
  parentGuide?: any;
  workspaces?: Workspace[];

  topicTitle?: string;
  instructionTitle?: string;
  beforeVersion?: GuideVersion;
  afterVersion?: GuideVersion;
  tags?: string[];
  guide?: any;
  publishedVersion?: GuideVersion;
  translations?: string;
  displaySettings?: DisplaySettings;
  count?: number;

  content?: string;
  before?: string;
  after?: string;
  position?: number;
  beforeNote?: StepNoteMetadata;
  afterNote?: StepNoteMetadata;
  beforePosition?: number;
  afterPosition?: number;
  beforeMedia?: Media;
  afterMedia?: Media;
  beforeNoteMedia?: Media;
  afterNoteMedia?: Media;
  beforeWorkspaces?: Workspace[];
  afterWorkspaces?: Workspace[];
  reviewers?: Person[];
  approvers?: Person[];
  guideApproval?: GUIDE_APPROVAL_TYPES;
};

export const checklistEventTypes = [
  'checklist_content_updated',
  'checklist_set_to_review',
  'checklist_set_to_draft',
  'checklist_archived',
  'checklist_published',
  'checklist_cloned',
  'checklist_moved',
  'checklist_created',
  'checklist_moved_to_topic',
  'checklist_visual_updated',
  'checklist_moved_to',
  'checklist_moved_from',
  'checklist_tags_added',
  'checklist_tags_removed'
] as const;

export const instructionEventTypes = [
  'instruction_content_updated',
  'instruction_set_to_review',
  'instruction_set_to_draft',
  'instruction_archived',
  'instruction_published',
  'instruction_cloned',
  'instruction_moved',
  'instruction_created',
  'instruction_sign_off_enabled',
  'instruction_sign_off_disabled',
  'instruction_moved_to_topic',
  'instruction_moved_to',
  'instruction_moved_from',
  'instruction_visual_updated',
  'instruction_tags_added',
  'instruction_tags_removed'
] as const;

export const topicEventTypes = [
  'topic_content_updated',
  'topic_removed',
  'topic_moved',
  'topic_moved_to',
  'topic_moved_from',
  'topic_created',
  'guide_topics_repositioned',
  'topic_settings_updated'
] as const;

export const stepEventTypes = [
  'step_content_updated',
  'step_visual_updated',
  'step_created',
  'step_duplicated',
  'step_note_content_updated',
  'step_note_visual_updated',
  'instruction_steps_repositioned',
  'step_removed',
  'step_archived'
] as const;

export const checkEventTypes = [
  'check_content_updated',
  'check_visual_updated',
  'check_created',
  'check_duplicated',
  'checklist_checks_repositioned',
  'check_removed',
  'check_archived'
] as const;

export const guideEventTypes = [
  'guide_set_to_draft',
  'guide_set_to_review',
  'guide_documents_repositioned',
  'guide_archived',
  'guide_cloned',
  'guide_approval_requested',
  'guide_approval_withdrawn',
  'guide_approval_approved',
  'guide_approval_rejected',
  'guide_live_version_rejected',
  'guide_published',
  'guide_content_updated',
  'guide_commented',
  'guide_reassigned',
  'guide_created',
  'guide_live_version_approved',
  'guide_approval_cancelled',
  'guide_checklists_repositioned',
  'guide_version_restored_as_draft',
  'guide_republished',
  'guide_newer_version_published',
  'guide_visual_updated',
  'guide_translations_updated',
  'guide_translations_added',
  'guide_draft_auto_created',
  'guide_default_language_updated',
  'guide_tags_added',
  'guide_tags_removed',
  'guide_workspaces_updated',
  'guide_approval_partially_approved',
  'guide_approval_approved_and_published',
  'guide_alert_recipients_added',
  'guide_alert_recipients_removed'
] as const;
