import styled from 'styled-components';

import {commonPalette} from 'shared/styles/palette';

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 15px 25px 0;
`;

export const ClickableText = styled.p`
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
`;

export const StyledText = styled.span`
  color: ${commonPalette.txLightGrey};
`;
