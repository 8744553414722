import {defineMessages} from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'skillsDrawer.title',
    defaultMessage: 'Add Skills'
  },
  description: {
    id: 'skillsDrawer.description',
    defaultMessage: 'Select one or more of the following Skills for the Skill Profile.'
  },
  searchBySkillName: {
    id: 'skillsDrawer.searchBySkillName',
    defaultMessage: 'Search by Skill name'
  },
  noSkillsFound: {
    id: 'skillsDrawer.noSkillsFound',
    defaultMessage: 'No Skills found'
  },
  selected: {
    id: 'selected',
    defaultMessage: 'selected'
  },
  error: {
    id: 'skills.error',
    defaultMessage: "Couldn't load the page. Please try again."
  },
  filtersTitle: {
    id: 'MediaGallery.filter',
    defaultMessage: 'Filter'
  },
  tenTags: {
    id: 'tenTags',
    defaultMessage: 'Select up to 10 tags.'
  },
  filterByTag: {
    id: 'TagManagement.title',
    defaultMessage: 'Tags'
  }
});

export default messages;
