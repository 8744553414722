import React from 'react';
import {action, observable, makeObservable} from 'mobx';
import store from 'stores/store';
import views from 'config/views';

//helpers
import notification from 'utils/notification-utils';

class MoveToDialog {
  @observable opened = false;
  @observable guides = [];
  @observable selectedGuide;
  @observable id;
  translations;
  moveToMutation;

  @action
  reset = () => {
    this.opened = false;
    this.guides = [];
    this.selectedGuide = null;
    this.id = null;
    this.translations = null;
    this.moveToMutation = null;
  };

  @action open = ({id, translations, moveToMutation}) => {
    this.opened = true;
    this.id = id;
    this.translations = translations;
    this.moveToMutation = moveToMutation;
  };

  @action setGuides = guides => (this.guides = guides);

  @action setSelectedGuide = id => {
    this.selectedGuide = id;
  };

  @action moveTo = () => {
    const guide = this.guides.find(guide => guide.id === this.selectedGuide);
    const guideId = guide.id;
    this.moveToMutation({
      id: this.id,
      guideId
    })
      .then(() => {
        const message = (
          <div>
            <div>{this.translations.moveToSuccess}</div>
            <div>
              {' '}
              <a
                onClick={() => {
                  store.router.goTo(views.root, {});
                  store.router.goTo(views.editGuide, {id: guideId});
                }}
              >
                {guide.title}
              </a>
            </div>
          </div>
        );
        notification.success(message);
        this.reset();
      })
      .catch(error => {
        if (error.message === 'Default languages must match') {
          notification.error(this.translations.defaultLanguagesMustMatch);
        } else {
          notification.error(this.translations.moveToFailure);
        }
        this.reset();
      });
  };

  constructor() {
    makeObservable(this);
  }
}

export default MoveToDialog;
