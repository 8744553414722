import {action, observable, computed, makeObservable} from 'mobx';

//helpers
import TeamPickerForm from 'stores/models/teampicker';
import notification from 'utils/notification-utils';

class WorkspaceTeamsPage {
  translations = {};
  @observable loading = false;
  @observable selectedTeams = [new TeamPickerForm()];
  @observable teams = [];
  @observable domain;

  @action
  reset = () => {
    this.selectedTeams = [new TeamPickerForm()];
    this.loading = false;
  };

  @action setTeams = val => (this.teams = val);

  @action setDomain = val => (this.domain = val);

  @action setLoading = val => (this.loading = val);

  constructor() {
    makeObservable(this);
  }

  @computed
  get availableTeams() {
    const domainTeams = this.domain && this.domain.teams;
    const domainTeamIds = domainTeams && domainTeams.map(team => team.id);
    const availableTeams = this.teams.filter(team => domainTeamIds && !domainTeamIds.includes(team.id));
    return availableTeams;
  }

  @computed
  get canAddAvailableTeams() {
    return this.availableTeams.length > this.selectedTeams.length;
  }

  @computed
  get selectedIds() {
    return this.selectedTeams.map(team => team.form.$('teamId').value);
  }

  @action
  addTeamPicker = () => {
    this.selectedTeams.push(new TeamPickerForm());
  };

  @action
  removeTeamPicker = async (e, index) => {
    await this.selectedTeams.splice(index, 1);
  };

  @computed
  get isSelectionValid() {
    return this.selectedTeams.every(team => team.form.isValid);
  }

  @action
  submit = async (addTeamsToDomainMutation, domainId, goToWorkspaceTeamsList) => {
    const teamIds = this.selectedIds;
    this.setLoading(true);
    try {
      await addTeamsToDomainMutation({teamIds, domainId});
      notification.success(this.translations.addTeamSuccess);
      goToWorkspaceTeamsList();
      this.reset();
    } catch (e) {
      this.setLoading(false);
      notification.error(this.translations.addTeamFailure);
      console.error(e);
    }
  };

  setTranslations(translations) {
    this.translations = translations;
  }
}

export default WorkspaceTeamsPage;
