import {defineMessages} from 'react-intl';

const messages = defineMessages({
  assessSkill: {
    id: 'SmartSkills.user.assessSkill',
    defaultMessage: 'Assess skill'
  },
  gap: {
    id: 'SmartSkills.gap',
    defaultMessage: 'Gap'
  },
  levelDistribution: {
    id: 'SmartSkills.user.levelDistribution',
    defaultMessage: 'Level Distribution'
  },
  levelsOverview: {
    id: 'SmartSkills.user.levelsOverview',
    defaultMessage: 'Skill Levels Overview'
  },
  skills: {
    id: 'SmartSkills.skills',
    defaultMessage: 'Skills'
  },
  statsApproved: {
    id: 'SmartSkills.stats.approved',
    defaultMessage: 'Approved'
  }
});

export default messages;
