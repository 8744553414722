import styled from 'styled-components';

export const TranslateTagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonGroupWrapper = styled.div`
  margin: 20px 0;
`;
