import {Form as MobxReactForm} from 'shared/utils/mobx-react-form';
import validatorjs from 'validatorjs';
import {NOTIFICATION_RECIPIENTS} from 'config/enums';
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = {dvr: dvr(validatorjs)};

const notificationRecipientsValuesString = Object.values(NOTIFICATION_RECIPIENTS).join(',');
const fields = [
  {
    name: 'notificationRecipients',
    rules: `required|in:${notificationRecipientsValuesString}`,
    value: NOTIFICATION_RECIPIENTS.ALL_IN_TEAM
  },
  {
    name: 'reminderCycle',
    rules: 'required|string',
    value: '0'
  }
];

export default new MobxReactForm({fields}, {plugins});
