import styled from 'styled-components';

export const Option = styled.div`
  padding: 5px;
`;

export const OptionText = styled.div`
  font-size: 16px;
  padding-bottom: 5px;
  margin: -1px 0 0 10px;
`;

export const Label = styled.label`
  display: flex;
`;
