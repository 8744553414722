import styled from 'styled-components';
import $Icon from 'react-fontawesome';
import flex from 'styles/flex';
import {cond} from 'shared/utils/sc-utils';
import {borderRadius} from 'styles/sizes';

export const instructionCardClassName = 'c-guide-card__overlay';

const showOverlayAndHideNumber = `
 .${instructionCardClassName}{
    opacity: 1;
  }
`;

const scaleCard = `
  transform: scale(1.02);
`;

export const Card = styled.div`
  ${flex.vertical};
  ${p =>
    cond(
      p.isDragging,
      `
    opacity: 0.6;
    border: 2px dashed gray;
  `
    )};

  text-decoration: none;
  color: black;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0) 0px 1px 6px, rgba(0, 0, 0, 0.14) 0px 1px 4px;
  position: relative;
  border-radius: ${borderRadius}px;
  overflow: hidden;
  transition: all 150ms linear;
  z-index: 0;
  cursor: pointer;
  min-height: 288px;

  &:hover {
    ${showOverlayAndHideNumber};
    ${scaleCard};
    box-shadow: rgba(0, 0, 0, 0) 0px 1px 10px, rgba(0, 0, 0, 0.2) 0px 1px 10px;
  }

  ${p =>
    p.forceHover === true &&
    `
    ${showOverlayAndHideNumber}
    ${scaleCard}
  `};
`;

export const Top = styled.div`
  ${flex.vertical};
  ${p =>
    cond(
      p.image,
      `
    background: url('${p.image}') ${p.focus ? `${p.focus.kx * 100}% ${p.focus.ky * 100}%` : '50% 50%'} / ${
        p.contain ? 'contain' : 'cover'
      } no-repeat;
    background-color: ${p.backgroundColor};
    background-size : ${p.contain ? 'contain' : 'cover'};
    background-position: ${p.focus ? `${p.focus.kx * 100}% ${p.focus.ky * 100}%` : '50% 50%'};
    width: 100%;
    height: 0;
    padding-bottom: 75%;
  `
    )};
  position: relative;
  display: block;
`;

export const Bottom = styled.div`
  background-color: white;
  box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.19);
  padding: ${p => (p.withNumber ? '20px 15px 10px 15px' : '10px 15px')};
  overflow: visible;
  position: relative;
  flex: 1 1 auto;
  ${flex.vertical};
  min-height: 40%;
`;

export const Title = styled.div.attrs({
  className: 'card-title'
})`
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 11px;
  flex: 1 1 auto;
`;

export const Subtitle = styled.div`
  font-size: 13px;
  line-height: 14px;
  color: #ffffff;
  margin-bottom: 6px;
`;

export const Titles = styled.div`
  overflow: hidden;
  flex: 1 1 auto;
  ${flex.vertical} padding-bottom: 7px;
  border-bottom: 1px solid #eeeeee;
`;

export const LinkAndDraft = styled.div`
  ${flex.horizontal};
  ${flex.centerHorizontalV};
  ${flex.spaceBetween};
  width: 100%;
  min-height: 24px;
  margin-top: 10px;
`;

export const LinkIcon = styled($Icon)`
  color: #939798;
`;
