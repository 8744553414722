import {action, observable, computed, makeObservable} from 'mobx';
import omit from 'lodash/omit';

//helpers
import {TOPIC_TITLE_TYPE} from 'config/enums';
import notification from 'utils/notification-utils';

//form
import EditTopicForm from 'stores/forms/edit-topic-form';

class EditTopicPage {
  form = new EditTopicForm();

  //observables
  @observable loading = false;
  @observable opened = false;
  @observable translations = {};
  @observable topicId = null;

  //actions
  @action reset = () => {
    this.loading = false;
    this.opened = false;
    this.translations = {};
    this.topicId = null;

    this.form.reset();
  };

  @action setLoading = val => (this.loading = val);

  @action submit = updateTopicMutation => {
    this.setLoading(true);
    const topic = omit(this.form.values(), ['hide', 'id']);

    updateTopicMutation({topic, id: this.form.values().id})
      .then(() => {
        this.stopEditing();
        notification.success(this.translations.updateSuccess);
      })
      .catch(() => {
        notification.error(this.translations.updateError);
      });
  };

  @action cancel = () => {
    this.stopEditing();
  };

  @action startEditing = (topic, translations) => {
    const {id, slug, title} = topic;
    this.topicId = topic.id;
    this.translations = translations;

    this.form.update({
      id,
      slug,
      title
    });
  };

  @action stopEditing = () => {
    this.topicId = null;
    this.setLoading(false);
    this.form.reset();
  };

  @action setTranslations = translations => {
    this.translations = translations;
  };

  constructor() {
    makeObservable(this);
  }

  //computed
  @computed get saveButtonEnabled() {
    return this.loading === false && this.form.isValid;
  }

  @computed get isEditing() {
    return !!this.topicId;
  }

  getInitialTitleOption(topic) {
    if (!topic || (!topic.collapsible && topic.visible)) {
      return TOPIC_TITLE_TYPE.VISIBLE;
    }

    if (topic.collapsible && topic.visible) {
      return TOPIC_TITLE_TYPE.COLLAPSIBLE;
    }

    if (!topic.collapsible && !topic.visible) {
      return TOPIC_TITLE_TYPE.HIDDEN;
    }

    return TOPIC_TITLE_TYPE.VISIBLE;
  }
}

export default EditTopicPage;
