import Validator from 'validatorjs';
import dvr from 'mobx-react-form/lib/validators/DVR';

Validator.register(
  'is-slug',
  v => v.length && !/[ "'/%<>~?+=,.():;\[\]{}!@#$%^&*_A-Z]/.test(v), //eslint-disable-line
  'The slug syntax is not valid.'
);

Validator.register('is-workspace-slug', v => /^[a-z0-9]+$/.test(v), 'The slug syntax is not valid.');

Validator.register(
  'hex',
  v => {
    const color = v.indexOf('#') === 0 ? v : `#${v}`;
    if (color.length !== 7) {
      return false;
    }
    return /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(color);
  },
  'The hex color is not valid.'
);

export const dvrWithAsync = asyncRules => ({
  dvr: dvr({
    package: Validator,
    extend: ({validator}) => {
      Object.keys(asyncRules).forEach(key => validator.registerAsync(key, asyncRules[key]));
    }
  })
});
