import styled from 'styled-components';

export const ImageContent = styled.div`
  align-self: flex-start;
  text-decoration: underline;
`;

export const ImageWrapper = styled.div`
  padding: 5px;
  max-width: 400px;
`;

export const StyledLink = styled.a``;
