import styled from 'styled-components';

export const SausagesContainer = styled.div`
  display: flex;
  gap: 4px;
  width: 100%;
`;

export const LoaderContainer = styled.div`
  margin-top: 200px;
`;

export const StyledDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  height: 44px;
  margin: 32px 0 34px 0;
`;
