import {observable, action, computed, toJS, makeObservable} from 'mobx';
import store from 'stores/store';
import views from 'config/views';
import debounce from 'lodash/debounce';
import {client} from 'utils/apollo-client';
import Raven from 'raven-js';

//helpers
import TagForm from 'stores/forms/tag-form';
import notification from 'utils/notification-utils';
import {sanitizeSimpleObject} from 'utils/object-utils';

//lodash
import {find, omit, uniq, isEmpty} from 'lodash';

import {TagsPaginated, TagsByIds} from 'api/tag/queries';

class TagManagementPage {
  @observable tags = [];
  @observable newTags = [];
  @observable selectedTagsIds = [];
  @observable loading = false;
  @observable uiTranslations = {};
  @observable editedTagId;
  form = TagForm;
  @observable mergedTagContent = {};
  @observable isContentEmpty = true;
  @observable isBadge = false;
  @observable searchTerm = '';
  @observable tagsLoading = false;
  @observable page = 0;
  @observable limit = 50;
  @observable totalCount = 0;
  @observable sorted = [{id: 'title', desc: false}];
  @observable nextOffset = null;
  @observable localSelectedTags = [];

  @action setLocalSelectedTags = tags => {
    this.localSelectedTags = tags;
  };

  @action
  setPageIndex = async page => {
    this.page = page;
    const {tags} = await this.fetchData();
    this.setTags(tags);
  };

  @action
  setSorted = async val => {
    this.sorted = val;
    const {tags} = await this.fetchData();
    this.setTags(tags);
  };

  @action
  onSearch = debounce(async () => {
    this.page = 0;
    const {tags} = await this.fetchData();
    this.setTags(tags);
  }, 300);

  @action
  fetchData = async () => {
    this.setTagsLoading(true);
    try {
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: TagsPaginated,
        variables: {
          offset: this.page * this.limit,
          limit: this.limit,
          sortBy: this.sorted.map(sortByItem => {
            return {field: sortByItem.id, order: sortByItem.desc ? 'desc' : 'asc'};
          }),
          filters: {
            title: {
              contains: this.searchTerm
            }
          }
        }
      });
      const {tagsPaginated} = response.data;
      const {results: tags, totalCount, nextOffset} = tagsPaginated;
      this.setTagsLoading(false);
      this.setTotalCount(totalCount);
      this.setNextOffset(nextOffset);
      return {tags, totalCount};
    } catch (error) {
      this.setTagsLoading(false);
      Raven.captureException(error);
      notification.error(this.uiTranslations.errorLoadingTags);
    }
  };

  fetchTagsByIds = async tagIds => {
    this.setTagsLoading(true);
    try {
      const response = await client.query({
        fetchPolicy: 'network-only',
        query: TagsByIds,
        variables: {
          tagIds
        }
      });
      const {tagsByIds} = response.data;
      this.setTagsLoading(false);
      return {tagsByIds};
    } catch (error) {
      this.setTagsLoading(false);
      Raven.captureException(error);
      notification.error(this.uiTranslations.errorLoadingTags);
      return {tagsByIds: []};
    }
  };

  @action
  setNextOffset = val => (this.nextOffset = val);

  @action
  fetchMoreTags = async () => {
    if (this.nextOffset) {
      this.page = this.page + 1;
      const {tags} = await this.fetchData();
      return {tags};
    }
    return {tags: false};
  };

  @action
  setSearchTerm = searchTerm => (this.searchTerm = searchTerm);

  @action
  setTags = tags => {
    this.tags = tags;
  };

  @action
  setTagsLoading = val => (this.tagsLoading = val);

  @action
  setTotalCount = val => (this.totalCount = val);

  @action
  goToTagsList = () => {
    store.router.goTo(views.tags, {});
  };

  @action
  editTag = id => {
    store.router.goTo(views.editTag, {id});
  };

  @action
  setLoading = val => (this.loading = val);

  @action
  setUiTranslations = translations => {
    this.uiTranslations = translations;
  };

  @action
  toggleBadge = () => {
    this.isBadge = !this.isBadge;
  };

  @action
  createTag = async ({createTagMutation}) => {
    let tag = this.form.values();
    const {title, defaultLocale} = tag;
    const existingTitleInLocale = this.tags
      ? this.tags.filter(tag => tag.title.trim() === title.trim() && tag.defaultLocale === defaultLocale)
      : [];

    if (isEmpty(existingTitleInLocale)) {
      tag = {...tag, badge: this.isBadge};
      tag = sanitizeSimpleObject(tag, ['title']);

      this.setLoading(true);
      try {
        await createTagMutation({tag});
        notification.success(this.uiTranslations.createSuccess);
        this.goToTagsList();
        this.reset();
      } catch (e) {
        this.setLoading(false);
        console.error(e);
        notification.error(this.uiTranslations.createFailure);
      }
    } else {
      this.setLoading(false);
      notification.error(this.uiTranslations.duplicateTitle);
    }
  };

  constructor() {
    makeObservable(this);
  }

  @computed
  get selectedTagsContent() {
    const content = this.selectedTagsIds.slice().map(id => {
      const tag = toJS(this.tags.slice().find(tag => tag.id === id));
      return tag && tag.translations ? tag.translations : {};
    });
    return toJS(content);
  }

  @computed
  get pages() {
    if (!this.tags && !this.tagsLoading) return null;
    return Math.ceil(this.totalCount / this.limit);
  }

  @computed
  get availableLocales() {
    if (!this.selectedTagsContent) return [];
    const availableLocales = [];
    this.selectedTagsContent.map(t => {
      const locales = Object.keys(t);
      return availableLocales.push(...locales);
    });
    return uniq(availableLocales);
  }

  @computed
  get availableLanguages() {
    return this.availableLocales.map(locale => {
      return {
        name: this.getLanguage(locale),
        id: locale
      };
    });
  }

  @computed
  get selectedTagsData() {
    const contents = this.selectedTagsContent;
    if (contents.length < 2) return [];
    const data = this.availableLocales.map(locale => {
      return {
        locale: locale,
        language: this.getLanguage(locale),
        firstTag: contents[0][locale] ? contents[0][locale].title : '-',
        secondTag: contents[1][locale] ? contents[1][locale].title : '-'
      };
    });
    return data;
  }

  @action
  updateMergedTagContent = ({value, original: {locale}}) => {
    this.mergedTagContent[locale] = {title: value};
    const totalItemsToSelect = this.selectedTagsData.length;
    if (Object.keys(this.mergedTagContent).length === totalItemsToSelect) {
      this.isContentEmpty = false;
    } else {
      this.isContentEmpty = true;
    }
  };

  @action
  mergeTags = async ({mergeTagsMutation}) => {
    const tag = {
      ids: [this.selectedTagsIds[0], this.selectedTagsIds[1]],
      defaultLocale: this.defaultLocale,
      content: this.mergedTagContent
    };

    this.setLoading(true);

    try {
      await mergeTagsMutation({tag});
      notification.success(this.uiTranslations.mergeSuccess);
      this.goToTagsList();
      this.reset();
    } catch (e) {
      this.setLoading(false);
      console.error(e);
      notification.error(this.uiTranslations.mergeFailure);
    }
  };

  @action
  startEdit = tag => {
    const {title, defaultLocale, badge} = tag;
    this.isBadge = badge;
    this.form.update({
      title,
      defaultLocale,
      badge
    });
    this.editedTagId = tag.id;
  };

  @action
  updateTag = async ({updateTagMutation}) => {
    let tag = this.form.values();
    tag = {...tag, badge: this.isBadge};
    tag = sanitizeSimpleObject(tag, ['title']);

    const existingTitleInLocale = this.tags
      ? this.tags.filter(t => t.title === tag.title && t.defaultLocale === tag.defaultLocale)
      : [];

    this.setLoading(true);

    if (isEmpty(existingTitleInLocale)) {
      try {
        await updateTagMutation({
          id: this.editedTagId,
          tag
        });
        notification.success(this.uiTranslations.updateSuccess);
        this.goToTagsList();
        this.reset();
      } catch (e) {
        console.error(e);
        notification.error(this.uiTranslations.updateFailure);
      }
    } else {
      this.setLoading(false);
      notification.error(this.uiTranslations.duplicateTitle);
    }
  };

  @action
  setSelectedTags = (tags, newTag) => {
    this.selectedTagsIds = tags;
    if (newTag) {
      this.newTags.push(newTag);
    }

    if (this.selectedTagsIds.length < 2) {
      this.mergedTagContent = {};
      this.isContentEmpty = true;
    }
  };

  @computed
  get disableMerge() {
    return this.selectedTagsIds.length !== 2 || this.isContentEmpty || !this.defaultLocale;
  }

  @action
  reset = () => {
    this.form.reset();
    this.loading = false;
    this.editedTagId = null;
    this.selectedTagsIds = [];
    this.mergedTagContent = {};
  };

  @computed
  get showDefaultLanguagePicker() {
    const {
      platform: {multipleLocales}
    } = store;
    return multipleLocales && this.selectedTagsIds.length === 2;
  }

  @computed
  get tagsWithLanguage() {
    return (
      this.tags &&
      this.tags.map(tag => {
        return {
          ...omit(tag, ['translations', 'defaultLocale']),
          defaultLanguage: this.getLanguage(tag.defaultLocale)
        };
      })
    );
  }

  @computed
  get defaultLocale() {
    return this.form.$('defaultLocale').value;
  }

  @action setDefaultLocale = locale => {
    this.form.$('defaultLocale').sync(locale);
  };

  getLanguage = locale => {
    const {
      platform: {availableLanguages}
    } = store;

    if (!availableLanguages) return locale;
    const languageDetails = find(availableLanguages, {locale});

    return languageDetails ? languageDetails.language : locale;
  };
}

export default TagManagementPage;
