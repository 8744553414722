import React, {Component} from 'react';

//helpers
import {styleable} from 'shared/decorators';

//styled-components
import {Heading, Subtitle, ViewTitle, Left, Right} from './styles';

@styleable
class ViewTitleComp extends Component {
  render() {
    const {title, subtitle, right} = this.props;
    return (
      <ViewTitle>
        <Left>
          <Heading>{title}</Heading>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </Left>
        {right && <Right>{right}</Right>}
      </ViewTitle>
    );
  }
}

export default ViewTitleComp;
